import { analytics, analyticsTypes } from '@trova-trip/trova-common';
import { useEffect, useRef, useContext } from 'react';
import { debounce, isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import { TrackEventParameters } from './analytics.types';
import NavigationContext from '../navigation/NavigationContext';
import posthog from 'posthog-js';
import { ProfileStoreSelector } from '../state/profile';
import { UserHostStoreSelector } from '../state/hosts';
import { useSelector } from 'react-redux';
import ProfileReader from 'util/profile/ProfileReader';
import useGetUserLinks from 'util/hooks/useGetUserLinks';

export type UserTrackingEvent = {
    trackUserEvent: (props: TrackEventParameters) => void;
};

export const useAnalyzeRouterPage = (): void => {
    const location = useLocation();
    const trackPageRef = useRef(debounce(() => analytics.trackPage(), 100));

    useEffect(() => {
        trackPageRef.current();
    }, [location.pathname]);
};

/**
 * Analytics: Identifies the current user and populates required fields such as user links and host object.
 * Does not identify the same user twice (unless page is reloaded).
 */
export const useAnalyzeIdentity = (): void => {
    const lastUserTracked = useRef<analyticsTypes.ImpersonateableUser>();
    const currentUser = useSelector(
        (state: ProfileStoreSelector) => state.profile.current,
    );
    const currentHost = useSelector(
        (state: UserHostStoreSelector) => state.userHosts.current,
    );
    const links = useGetUserLinks();
    const profileReader = ProfileReader.create(currentUser);

    useEffect(() => {
        if (lastUserTracked.current !== currentUser) {
            if (isEmpty(links)) return;

            let traits: Record<string, any> = { ...links };

            if (profileReader.isHost()) {
                if (!currentHost) return;
                traits = { ...traits, hostObject: currentHost };
            }

            lastUserTracked.current = currentUser;
            analytics.setUser(currentUser).identifyUser(traits);
            posthog.identify(currentUser.id);
        }
    }, [currentUser, currentHost, links, profileReader]);
};

export const useTrackingEvent = (): UserTrackingEvent => {
    const { profile } = useContext(NavigationContext);

    const trackUserEvent = (props: TrackEventParameters): void => {
        analytics.setUser(profile).trackEvent(props);
    };

    return {
        trackUserEvent,
    };
};
