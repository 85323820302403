import { models } from '@trova-trip/trova-models';
import {
    TypedUseSelectorHook,
    useSelector as useReduxSelector,
} from 'react-redux';
import { ManageBookingState } from '../features/manageBooking/types';
import { TransferViewModel as TransferState } from '../features/transfer/types';
import { UserGroundTransfersSelector } from '../userGroundTransfers';

type Trip = models.trips.Trip;
type Itinerary = models.itineraries.Itinerary;
type TripRequest = models.tripRequest.TripRequest;
type TripDetails = models.tripDetails.TripDetails;
type BaseUser = models.users.BaseUser;
type Traveler = models.travelers.TravelerDTO;
type Booking = models.bookings.Booking;
type Currency = models.currencies.Currency;
type Category = models.categories.Category;
type User = models.users.User;
type SavedBooking = models.bookings.SavedBooking[];

interface ListResponse<T> {
    records: T[];
    total: number;
    limit: number;
    page: number;
    pages: number;
}
interface ReducerInterface<T> {
    fetching: boolean;
    firstTime: boolean;
    current?: T;
    list?: ListResponse<T>;
}

export interface RootStore {
    // Models:
    userTrips: ReducerInterface<Trip>;
    userItinerary: ReducerInterface<Itinerary>;
    userTripRequests: ReducerInterface<TripRequest>;
    userTripDetails: ReducerInterface<TripDetails>;
    userTripsTraveler: ReducerInterface<Traveler>;
    userCurrencies: ReducerInterface<Currency>;
    userBookings: ReducerInterface<SavedBooking>;
    userGroundTransfers: UserGroundTransfersSelector;
    profile: ReducerInterface<BaseUser>;
    bookings: ReducerInterface<Booking>;
    categories: ReducerInterface<Category>;
    users: ReducerInterface<User>;

    // Features:
    manageBooking: ManageBookingState;
    transfer: TransferState;
}

export const useSelector: TypedUseSelectorHook<RootStore> = useReduxSelector;
