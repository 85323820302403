/* eslint-disable */
const configuration = ($) => (fieldName) => {
    const gumletUrl = window.GUMLET_CONFIG.hosts[0].gumlet;

    return {
        getDefault() {
            return {
                limit: 1,
                fileMaxSize: 10,
                extensions: ["jpg", "jpeg", "png"],
                changeInput: ' ',
                theme: 'gallery', // will only work for the gallery theme ATM. Additional css files needed to support other themes
                enableApi: true,
                inputNameBrackets: false,
                listInput: true,
            };
        },
        getSorter() {
            return {
                onSort: function(list, listEl, parentEl, newInputEl, inputEl) {                    
                    list.forEach(item => item.uploaded = true);
                    document.dispatchEvent(new CustomEvent('image:sort'));
                }
            }
        },
        getTemplate(classes, showActions) {
            let styleToAdd = '';
            let actionsTemplate = '';

            if (classes === 'fileuploader-drag-and-drop-fill-space') {
                styleToAdd = `style="display: none;"`
            }

            if (showActions) {
                actionsTemplate = '<div class="actions-holder">' +
                    '<button type="button" class="fileuploader-action fileuploader-action-sort" title="${captions.sort}"><i class="fileuploader-icon-sort"></i></button>' +
                    '<button type="button" class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i class="fileuploader-icon-remove"></i></button>' +
                '</div>'
            }

            return {
                box: '<div class="fileuploader-items">' +
                    '<ul class="fileuploader-items-list">' +
                        `<li class="fileuploader-input ${ classes }"><button type="button" class="fileuploader-input-inner"><i class="fileuploader-icon-main"></i> <span>` + '${captions.feedback}</span></button></li>' +
                    '</ul>' +
                '</div>',
                item: `<li class="fileuploader-item ${ classes }" ${styleToAdd}>` +
                    '<div class="fileuploader-item-inner">' +
                        `${actionsTemplate}` +
                        '<div class="thumbnail-holder">' +
                            '${image}' +
                            '<div class="progress-holder"><span></span>${progressBar}</div>' +
                        '</div>' +
                        '<div class="content-holder"><h5 title="${name}">${name}</h5><br></div>' +
                        '<div class="type-holder">${icon}</div>' +
                    '</div>' +
                '</li>',
                item2: '<li class="fileuploader-item file-main-${data.isMain}' + ` ${ classes }" ${styleToAdd}>` +
                    '<div class="fileuploader-item-inner">' +
                        `${actionsTemplate}` +
                        '<div class="thumbnail-holder">' +
                            '${image}' +
                        '</div>' +
                        '<div class="content-holder"><h5 title="${name}">${name}</h5><br></div>' +
                        '<div class="type-holder">${icon}</div>' +
                    '</div>' +
                '</li>',
            }
        },
        getThumbnailsTemplate(classes, showActions) {
            return {...this.getTemplate(classes, showActions), ...{
                itemPrepend: false,
                startImageRenderer: true,
                canvasImage: false,
                synchronImages: false,
                removeConfirmation: true,
                onItemShow: function(item, listEl, parentEl, newInputEl, inputEl) {
                    var api = $.fileuploader.getInstance(inputEl),
                        color = api.assets.textToColor(item.format),
                        $plusInput = listEl.find('.fileuploader-input');

                    // put input first in the list
                    $plusInput.prependTo(listEl);

                    // color the icon and the progressbar with the format color
                    item.html.find('.type-holder .fileuploader-item-icon')[api.assets.isBrightColor(color) ? 'addClass' : 'removeClass']('is-bright-color').css('backgroundColor', color);
                },
                onImageLoaded: function(item, listEl, parentEl, newInputEl, inputEl) {
                    var api = $.fileuploader.getInstance(inputEl);
                
                    // add icon
                    item.image.find('.fileuploader-item-icon i').html('')
                        .addClass('fileuploader-icon-' + (['image', 'video', 'audio'].indexOf(item.format) > -1 ? item.format : 'file'));

                    // check the image size
                    if (item.format === 'image' && item.upload && !item.imU) {
                        if (item.reader.node && (item.reader.width < 100 || item.reader.height < 100)) {
                            alert(api.assets.textParse(api.getOptions().captions.imageSizeError, item));
                            return item.remove();
                        }

                        item.image.hide();
                        item.reader.done = true;
                        item.upload.send();
                    }

                },
                onItemRemove: function(html) {
                    html.fadeOut(250);	
                },
            }
        }
        },
        getDragAndDrop() {
            return {
                container: '.fileuploader-theme-gallery .fileuploader-input'
            }
        },
        getUploadConfig({ apiUrl, isSortable }) {
            return {
                url: `${apiUrl}?type=upload&fieldName=${fieldName}`,
                data: null,
                type: 'POST',
                enctype: 'multipart/form-data',
                start: true,
                synchron: true,
                chunk: 4,
                isSortable,
                beforeSend: function(item) {
                // check the image size first (onImageLoaded)
                    if (item.format === 'image' && !item.reader.done)
                        return false;

                    // add editor to upload data after editing
                    if (item.editor && (typeof item.editor.rotation != "undefined" || item.editor.crop)) {
                        item.imU = true;
                        item.upload.data.name = item.name;
                        item.upload.data.id = item.data.listProps.id;
                        item.upload.data._editor = JSON.stringify(item.editor);
                    }

                    item.html.find('.fileuploader-action-success').removeClass('fileuploader-action-success');
                },
                onSuccess: function(result, item) {
                    var data = {};
                    try {
                        data = result.data;
                    } catch (e) {
                        data.hasWarnings = true;
                    }

                    // if success update the information
                    if (data && data.isSuccess && data.files.length) {
                        if (!item.data.listProps)
                            item.data.listProps = {};
                        item.title = data.files[0].title;
                        item.name = data.files[0].name;
                        item.size = data.files[0].size;
                        item.size2 = data.files[0].size2;
                        item.url = data.url;
                        item.data.url = data.files[0].url;
                        item.data.listProps.id = data.files[0].id;

                        document.dispatchEvent(new CustomEvent('image:uploaded', {"detail": data.url}));

                        item.html.find('.content-holder h5').attr('title', item.name).text(item.name);
                        item.html.find('.content-holder span').text(item.size2);
                        item.html.find('.gallery-item-dropdown [download]').attr('href', item.data.url);
                    }

                    // if warnings
                    if (data && data.hasWarnings) {
                        for (var warning in data.warnings) {
                            alert(data.warnings[warning]);
                        }

                        item.html
                            .removeClass(
                                'upload-successful'
                            )
                            .addClass('upload-failed');
                        return this.onError ? this.onError(item) : null;
                    }

                    delete item.imU;
                    item.html.find('.fileuploader-action-remove').addClass('fileuploader-action-success');

                    var that = this;
                    setTimeout(function() {
                        item.html.find('.progress-holder').hide();

                        (() => that.isSortable ? item.html.find('.fileuploader-action-sort').removeClass('is-hidden') : null)()

                        item.html.find('.fileuploader-action-popup, .fileuploader-item-image').show();
                        item.html.find('.fileuploader-action-settings').removeClass('is-hidden');
                    }, 400);
                },
                onError: function(item) {
                    item.html.find('.progress-holder, .fileuploader-action-popup, .fileuploader-item-image').hide();

                    // add retry button
                    (() => item.upload.status !== 'cancelled' && !item.imU && !item.html.find('.fileuploader-action-retry').length ? item.html.find('.actions-holder').prepend(
                        '<button type="button" class="fileuploader-action fileuploader-action-retry" title="Retry"><i class="fileuploader-icon-retry"></i></button>'
                    ) : null)()
                },
                onProgress: function(data, item) {
                    var $progressBar = item.html.find('.progress-holder');

                    if ($progressBar.length) {
                        $progressBar.show();
                        $progressBar.find('span').text(data.percentage >= 99 ? 'Uploading...' : data.percentage + '%');
                        $progressBar.find('.fileuploader-progressbar .bar').height(data.percentage + '%');
                    }

                    item.html.find('.fileuploader-action-popup, .fileuploader-item-image').hide();
                }
            }
        },
        getAfterRenderFn() {
            return (listEl, parentEl, newInputEl, inputEl) => {
                var api = $.fileuploader.getInstance(inputEl),
                    $plusInput = listEl.find('.fileuploader-input');

                // bind input click
                $plusInput.on('click', function() {
                    api.open();
                });
            
                // set drop container
                api.getOptions().dragDrop.container = $plusInput;

                // bind dropdown buttons
                $('body').on('click', function(e) {
                    var $target = $(e.target),
                        $item = $target.closest('.fileuploader-item'),
                        item = api.findFile($item);

                    // toggle dropdown
                    $('.gallery-item-dropdown').hide();
                    if ($target.is('.fileuploader-action-settings') || $target.parent().is('.fileuploader-action-settings')) {
                        $item.find('.gallery-item-dropdown').show(150);
                    }
                });
            }
        },
        getOnRemoveFn() {
            return item => {                
                item.deleted = true;
                item.uploaded = true;
                document.dispatchEvent(new CustomEvent('image:deleted', {"detail": item.file}));
            }
        },
        getCaptions() {
            return $.extend(true, {}, $.fn.fileuploader.languages['en'], {
                feedback: 'Drag & Drop',
                setting_asMain: 'Use as main',
                setting_download: 'Download',
                setting_edit: 'Edit',
                setting_open: 'Open',
                setting_rename: 'Rename',
                rename: 'Enter the new file name:',
                renameError: 'Please enter another name.',
                imageSizeError: 'The image ${name} is too small.',
            });
        },
        preloadFiles(files = []) {
            if (!(files instanceof Array)) {
                files = new Array(files);
            }

            const preloadedFiles = [];
            if (files.length) {
                files.map((file, index) => {
                    const name = index === 0 ? `Featured` : `${fieldName.replace(/^\w/, c => c.toUpperCase())} - ${index}`
                    if (file && file !== "" && !Array.isArray(file)) {
                        preloadedFiles.push({
                            name,
                            size: 1024,
                            type: `image`,
                            file: `${file}`,
                            data: {
                                originalFileName: file,
                                thumbnail: `//${gumletUrl}${file}`,
                                readerSkip: false,
                                readerForce: true,
                            },
                        })
                    }
                });
            }

            return preloadedFiles;
        },
    };
};

export default configuration;
