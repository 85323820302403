import { Table, TableHead, TableBody, Row, Cell } from '@trova-trip/trova-components';
import BookingsTableRow from '../BookingsTables/BookingsTableRow';
import { FormattedBookingOrder } from '../BookingsTables/BookingsTables';

interface DialogTableProps {
    data: FormattedBookingOrder[];
}

const DialogTable = ({ data }: DialogTableProps): JSX.Element => {
    return (
        <Table>
            <TableHead>
                <Row>
                    <Cell>Name</Cell>
                    <Cell>Email</Cell>
                    <Cell>Order #</Cell>
                    <Cell>Gender</Cell>
                    <Cell>Age</Cell>
                    <Cell>Instagram</Cell>
                    <Cell>Location</Cell>
                </Row>
            </TableHead>
            <TableBody>
                {data.map((order, index) => {
                    return (
                        <BookingsTableRow
                            key={order.orderId + index}
                            order={order}
                            minimized
                        />
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default DialogTable;
