import { ComponentProps, useMemo } from 'react';
import {
    theme,
    Header,
    Icon,
    IconName,
    BadgeProps,
} from '@trova-trip/trova-components';
import { ProductHeader } from '../../../../../common/components/ProductHeader';
import { useCurrentItineraryTierInfoForUser } from '../../../../../common/hooks/useCurrentHostItineraryTier';
import { getHeaderDataFromItinerary } from '../../../../../utils';
import { useSelector } from '../../../../../../state/hooks';
import headerImage from '../../../../../../assets/img/trovatrip-arial-isaland-2.jpg';
import { getBadgePropsFromTier } from '../ItineraryTierBadge/ItineraryTierBadge';
import useHostRecommendations, {
    ItineraryRecommendationOption,
} from '../../../../../host/hooks/useHostRecommendations';

interface GetBadgesParams {
    recommendation?: ItineraryRecommendationOption;
    tier: { name: string; number: number; isLocked: boolean } | undefined;
}

const ITINERARY_BADGES: { [key in string]: BadgeProps } = {
    recommended: {
        children: undefined,
        colorScheme: 'red',
        icon: 'star',
        iconVariant: 'filled',
        variant: 'rounded',
    },
};

const getBadges = ({ recommendation, tier }: GetBadgesParams) => {
    const badges: BadgeProps[] = [];

    if (tier) {
        badges.push(getBadgePropsFromTier(tier));
    }

    if (recommendation?.itinerary?.active) {
        badges.push({
            ...ITINERARY_BADGES.recommended,
            children: recommendation.topRecommended
                ? 'TOP RECOMMENDED'
                : undefined,
            iconMarginRight: recommendation.topRecommended ? undefined : 0,
        });
    }
    return badges.length > 0 ? badges : undefined;
};

const ItinerariesCatalogHeader = (): JSX.Element => {
    const itinerary = useSelector((state) => state.userItinerary.current);
    const { getItineraryRecommendation } = useHostRecommendations();
    const tier = useCurrentItineraryTierInfoForUser();

    const recommendation = getItineraryRecommendation(itinerary?.id || '');

    const props = useMemo((): ComponentProps<typeof Header> => {
        if (!itinerary) {
            return {
                backgroundImage: headerImage,
                title: 'Host Your Next Adventure',
                subtitle: '',
                backButtonConfig: { show: false },
            };
        }

        const headerData = getHeaderDataFromItinerary(itinerary, headerImage);

        return {
            backgroundImage: headerData.itineraryHeaderPhoto,
            title: headerData.itineraryName,
            subtitle: `${headerData.itineraryLength} Days`,
            icon: (
                <Icon name={IconName.Location} color={theme.colors.red[400]} />
            ),
            iconDescription: `${headerData.itineraryCountry}, ${headerData.itineraryDestination}`,
            badges: getBadges({ recommendation, tier }),
            badgePlacement:
                tier || recommendation
                    ? Header.BADGE_PLACEMENT.TOP_LEFT
                    : Header.BADGE_PLACEMENT.BOTTOM_RIGHT,
        };
    }, [itinerary, tier]);
    return <ProductHeader {...props} />;
};

export default ItinerariesCatalogHeader;
