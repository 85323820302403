import { useEffect } from 'react';
import { useSelector } from '../../../state/hooks';
import useUserTripsTravelerActions from './useUserTripsTravelerActions';

/**
 * Hook to fetch the user's trips traveler using the current trip on the store and
 * to set it on the global store. The traveler will be fetched only if:
 *
 * - There is actually a current trip on the store
 * - The traveler wasn't fetched before or is not hydrated on the store
 * - The current user is `active`
 */
const useUserTripsTravelerFetcher = (): void => {
    const { currentTrip, tripTravelerUser } = useSelector((state) => ({
        tripTravelerUser: state.userTripsTraveler.list?.records?.[0],
        currentTrip: state.userTrips.current,
    }));

    const { loadUserTripsTraveler } = useUserTripsTravelerActions();

    useEffect(() => {
        const hasCurrentTripTravelerUser = !!tripTravelerUser;

        if (currentTrip || !hasCurrentTripTravelerUser) {
            loadUserTripsTraveler(currentTrip?.id);
        }
    }, [currentTrip, tripTravelerUser?.id]);
};

export default useUserTripsTravelerFetcher;
