import { useState } from 'react';
import { ButtonWithAnalytics } from '../../../../../../../components/ButtonWithAnalytics';
import { DownloadAudienceDataDialog } from '../DownloadAudienceDataDialog';

interface ExportDataButtonProps {
    totalAudience: number;
    surveyResponses: number;
}

const analyticsData = {
    eventName: 'Host clicked Download Data on audience tab',
    type: 'onClick',
};

const ExportDataButton = ({
    totalAudience,
    surveyResponses,
}: ExportDataButtonProps): JSX.Element => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onToggleModal = () => {
        setIsModalOpen((prevState) => !prevState);
    };

    return (
        <>
            <ButtonWithAnalytics
                rightIcon='fileDownload'
                variant='tertiary'
                isDisabled={totalAudience === 0}
                onClick={onToggleModal}
                analyticsData={analyticsData}
            >
                Export Data
            </ButtonWithAnalytics>
            <DownloadAudienceDataDialog
                isOpen={isModalOpen}
                onCloseDialog={onToggleModal}
                totalAudience={totalAudience}
                surveyResponses={surveyResponses}
            />
        </>
    );
};

export default ExportDataButton;
