import React, { useState, useRef, useCallback, SyntheticEvent } from 'react';
import {
    filterOptionsFromValue,
    generateDropdownOptionsFromObject,
} from '../../../../util/form/dropdown';
import { ComponentWidth, Dropdown } from '@trova-trip/trova-components';

export interface SimpleAutoCompleteDropdownPropsType {
    name: string;
    label: string;
    value: string;
    error?: string | string[];
    dropDownOptionKey?: string;
    onChange: (event: SyntheticEvent, name: string, value: string) => void;
    size: ComponentWidth;
    required?: boolean;
    disabled?: boolean;
}

const SimpleAutoCompleteDropdown: React.FC<SimpleAutoCompleteDropdownPropsType> =
    (props) => {
        const {
            name,
            label,
            value,
            onChange,
            size,
            error,
            children,
            required,
            disabled,
        } = props;

        const initialOptions = useRef(
            generateDropdownOptionsFromObject(
                children as Record<string, string>,
            ),
        );
        const [currentOptions, setCurrentOptions] = useState(
            initialOptions.current,
        );
        const handleSearch = useCallback(
            (event: SyntheticEvent, name: string, value: string) => {
                const newOptions = filterOptionsFromValue(
                    value,
                    initialOptions.current,
                );
                setCurrentOptions(newOptions);
            },
            [initialOptions.current],
        );

        return (
            <Dropdown
                disabled={disabled}
                name={name}
                label={label}
                value={value || ''}
                onChange={onChange}
                onSearch={handleSearch}
                size={size}
                error={error}
                required={required}
            >
                {currentOptions}
            </Dropdown>
        );
    };

export default SimpleAutoCompleteDropdown;
