import { Grid, Heading, Stack } from '@trova-trip/trova-components';
import { ROUTE_TOKENS } from 'applications/common/constants';
import Layout from 'components/Layout';
import Product from '../../../../navigation/Product';
import ExploreHeroImage from './components/ExploreHeroImage';
import TripCategoryLinkCard from './components/TripCategoryLinkCard';
import { tripCategories } from './utils';

const { TRAVELER_APP_PRODUCT_ROUTE } = ROUTE_TOKENS;

const Explore = (): JSX.Element => (
    <Product
        path={TRAVELER_APP_PRODUCT_ROUTE.EXPLORE}
        label='Explore'
        icon='explore'
    >
        <Layout margin='1rem auto'>
            <ExploreHeroImage />
            <Stack spacing={10} direction='column'>
                <Heading as='h1' marginTop={20}>
                    Choose your own adventure
                </Heading>
                <Grid>
                    {tripCategories.map((category, index) => (
                        <Grid.Item
                            key={index}
                            columnSpan={{ base: 12, sm: 6, md: 4, lg: 3 }}
                        >
                            <TripCategoryLinkCard {...category} />
                        </Grid.Item>
                    ))}
                </Grid>
            </Stack>
        </Layout>
    </Product>
);

export default Explore;
