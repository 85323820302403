import React, { Suspense } from 'react';
import Loading from '../components/Loading';

const withSuspensefulLoader = Component => {
    const SuspensfulLoader = props => (
        <Suspense fallback={<Loading />}>
            <Component {...props} />
        </Suspense>
    );
    return SuspensfulLoader;
};

export default withSuspensefulLoader;
