/* eslint-disable react/no-unescaped-entities */

import { Grid, Text } from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';

const HostDashGettingStartedAudienceWidget = () => {
    return (
        <Grid.Item data-grid-item='host-dash-getting-started' columnSpan={12}>
            <Text>
                Set up your <strong>Profile Page</strong> and share it with your
                audience! This is where people go for information about you,
                your trips, and where they'll take your <strong>Survey</strong>{' '}
                to share where they want to go, their travel budgets, and more.
            </Text>
            <Grid.Item
                maxWidth='lg'
                marginLeft='auto'
                marginRight='auto'
                columnSpan={12}
                marginTop={6}
            >
                <Button
                    size='lg'
                    variant='secondary'
                    as='a'
                    linkProps={{
                        href: 'https://help.trovatrip.com/en/?q=how+to+survey+your+community',
                        target: '_blank',
                    }}
                    rightIcon='offsite'
                >
                    Learn about Surveys
                </Button>
                <Button
                    as='a'
                    linkProps={{
                        href: '/app/host/public-profile',
                    }}
                    marginLeft={4}
                    size='lg'
                    maxWidth={60}
                    rightIcon='share'
                >
                    Customize Profile Page
                </Button>
            </Grid.Item>
        </Grid.Item>
    );
};
export default HostDashGettingStartedAudienceWidget;
