import { ComponentWidth, Label, Stack } from '@trova-trip/trova-components';
import { DatePicker } from '@trova-trip/trova-components/build/next';

interface DatePickerFieldProps {
    name: string;
    value: string;
    onChange: (args: unknown) => void;
    error?: string | string[];
    isDisabled?: boolean;
    disabledDates?: Array<[Date, Date]>;
}

const DatePickerField = ({
    name,
    value,
    onChange,
    error,
    isDisabled,
    disabledDates,
}: DatePickerFieldProps) => {
    const handleChange = (_, __, date: string): void => {
        onChange({
            metadata: {
                name,
                value: date,
            },
        });
    };

    return (
        <Stack direction='column' spacing={2}>
            <Label
                id={`field-${name}-label`}
                htmlFor={`field-${name}`}
                isRequired
            >
                Start Date
            </Label>
            <DatePicker
                name={name}
                size={ComponentWidth.Flexible}
                value={value}
                onChange={handleChange}
                required
                error={error}
                errorVariant='default'
                inputMask='99/99/9999'
                disabled={isDisabled}
                dateTimeInputProps={
                    disabledDates
                        ? {
                              selectableDates: {
                                  dates: disabledDates,
                                  isSelectable: false,
                              },
                          }
                        : undefined
                }
            />
        </Stack>
    );
};

export default DatePickerField;
