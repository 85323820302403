import { HostAudienceData } from '../../../common/types';
import AudienceContent from './AudienceContent';

const AudienceContentWidget = ({
    hostAudience,
    audienceRoute,
}: {
    hostAudience: HostAudienceData;
    audienceRoute: string;
}) => {
    return (
        <AudienceContent
            data-grid-item-name='host-dash-audience-content-widget'
            hostAudience={hostAudience}
            audienceRoute={audienceRoute}
        />
    );
};

export default AudienceContentWidget;
