import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import state from './state';
import { Provider } from 'react-redux';
import 'assets/scss/material-dashboard-pro-react.scss?v=1.5.0';

import { PostHog } from './analytics';
PostHog.initializeWithSegment();

ReactDOM.render(
    <Provider store={state}>
        <App />
    </Provider>,
    document.getElementById(`root`),
);
