import {
    coreUtils,
    PricingCalculator,
    TripPricing,
} from '@trova-trip/trova-common';
import { models } from '@trova-trip/trova-models';
import cloneDeep from 'lodash/cloneDeep';
import { tripHasItineraryInventoryItem } from '../../../../../applications/common/helpers';

type Trip = models.trips.Trip;

const { formatDateTime } = coreUtils.dateUtils;

type CostPerThreshold = PricingCalculator.CostPerThreshold;
type CostByDayThresholds = PricingCalculator.CostByDayThresholds;
type OptionalCostThresholds = PricingCalculator.OptionalCostThresholds;

export type ValidityDayPeriod = {
    dayRange: Date[];
    costPerThreshold: CostPerThreshold[];
};

const validityDaysFormatted = (
    validityDays: CostByDayThresholds[],
): ValidityDayPeriod[] => {
    if (validityDays.length === 0) {
        return [];
    }

    const firstValidityDay = { ...validityDays[0] } as CostByDayThresholds;

    const validityDaysGrouped: ValidityDayPeriod[] = [
        {
            dayRange: [firstValidityDay.day],
            costPerThreshold: [...firstValidityDay.costPerThreshold],
        },
    ];

    validityDays.slice(1).forEach((day) => {
        const currentPeriod =
            validityDaysGrouped[validityDaysGrouped.length - 1];

        const isCostsSameAsCurrentPeriod = day.costPerThreshold.every(
            (threshold, index) =>
                threshold.pricePerTraveler ===
                currentPeriod.costPerThreshold[index].pricePerTraveler,
        );

        if (isCostsSameAsCurrentPeriod) {
            currentPeriod.dayRange.push(day.day);
        } else {
            validityDaysGrouped.push({
                dayRange: [day.day],
                costPerThreshold: [...day.costPerThreshold],
            });
        }
    });

    return validityDaysGrouped;
};

const combineMultipleItemThresholds = (
    multipleThresholds: { costPerThreshold: CostPerThreshold[] }[],
): CostPerThreshold[] => {
    if (multipleThresholds.length === 0) {
        return [];
    }

    const costThresholds = multipleThresholds.map(
        (threshold) => threshold.costPerThreshold,
    );

    const baseThresholds = cloneDeep(costThresholds[0]);

    costThresholds.slice(1).forEach((costThreshold) => {
        costThreshold.forEach((threshold, index) => {
            baseThresholds[index].pricePerTraveler +=
                threshold.pricePerTraveler;
        });
    });

    return baseThresholds;
};

const completeMissingThresholdsWithNull = (
    rowThresholds: CostPerThreshold[],
    totalThresholds: number[],
) => {
    const completeThresholds: any[] = [...rowThresholds];
    completeThresholds.length = totalThresholds.length;
    completeThresholds.fill(
        { pricePerTraveler: null },
        rowThresholds.length,
        totalThresholds.length,
    );
    return completeThresholds;
};

const formatCalculatorThresholds = (
    calculatorThresholds: CostPerThreshold[],
) => {
    return calculatorThresholds.map((threshold) => ({
        price: threshold.pricePerTraveler,
        numberOfTravelers: threshold.numberTravelers,
    }));
};

const buildValidityPeriodsPricing = (costsByDay: CostByDayThresholds[]) => {
    const costsByDayFormatted = validityDaysFormatted(costsByDay);

    const validityPeriods = costsByDayFormatted.map((validityDays) => {
        const { dayRange, costPerThreshold } = validityDays;

        /**
         * These dates need to be parsed and formatted in UTC, as they're expected
         * to represent trip dates in the local time of the trip.
         */
        const firstDay = formatDateTime(dayRange[0], 'PP', 'UTC');
        const lastDay = formatDateTime(
            dayRange[dayRange?.length - 1],
            'PP',
            'UTC',
        );

        return {
            label: `${firstDay} - ${lastDay}`,
            subtitle: `${dayRange.length} days`,
            value: costPerThreshold,
        };
    });

    return validityPeriods;
};

const buildHostOptionalServiceSubtitle = (
    optionalService: OptionalCostThresholds,
): string => {
    const { quantity, price } = optionalService;
    return `${quantity} x $${price}`;
};

const buildHostOptionalsPricing = (
    hostOptionals: OptionalCostThresholds[],
    isService: boolean = false,
): Pricing[] =>
    hostOptionals
        .filter((optionalCost) =>
            isService
                ? HostOptionalsLabels[optionalCost.name]
                : !HostOptionalsLabels[optionalCost.name],
        )
        .map((optional) => {
            return {
                label: isService
                    ? HostOptionalsLabels[optional.name]
                    : optional.name,
                subtitle: buildHostOptionalServiceSubtitle(optional),
                value: optional.costPerThreshold,
            };
        });

export interface Pricing {
    label: string;
    subtitle?: string;
    value: CostPerThreshold[];
}
export interface PricingBreakdown {
    validityDayPeriodsPrice: Pricing[];
    suggestedPrice: Pricing;
    operatorSubtotalPrice: Pricing;
    baseCostsBreakdown: Pricing[];
    travelerCostsBreakdown: Pricing[];
    variableCostsBreakdown: Pricing[];
    operatorCostsBreakdown: Pricing[];
    travelerThresholds: number[];
}

export enum HostOptionalsLabels {
    'Pre-trip Transfer' = 'Before Trip Host Transfer',
    'Post-trip Transfer' = 'After Trip Host Transfer',
    'Pre-trip Accommodation' = 'Before Trip Host Accommodation',
    'Pre-trip Double Accommodation' = 'Before Trip Host Double Accommodation',
    'Post-trip Accommodation' = 'After Trip Host Accommodation',
    'Post-trip Double Accommodation' = 'After Trip Host Double Accommodation',
}

const getPricingBreakdown = (
    tripCosts: TripPricing,
    trip?: Trip,
): PricingBreakdown => {
    const {
        suggestedCosts,
        costsByDay,
        baseCosts,
        hostSelectedOptionalsCosts,
        companionsCosts,
        workshopsCosts,
        servicesTotalCosts,
        singleSupplementFeeCosts,
        travelerTotalCosts,
        platformFees,
        currentCosts,
        operatorTotalCosts,
        singleSupplementCostAdjustments,
        hostGroundTransferCost,
    } = tripCosts;
    const hasItineraryInventoryItem = tripHasItineraryInventoryItem(trip);
    const optionalHostServices = buildHostOptionalsPricing(
        hostSelectedOptionalsCosts,
        true,
    );
    const optionalHostActivities = buildHostOptionalsPricing(
        hostSelectedOptionalsCosts,
    );
    const validityDayPeriodsPrice = buildValidityPeriodsPricing(costsByDay);

    const hasHostTransferCost = hostGroundTransferCost.every(
        (cost) => cost.pricePerTraveler > 0,
    );

    const optionalHostTransferCost: Pricing[] | undefined = hasHostTransferCost
        ? [
              {
                  label: 'Host Private Transfer Inclusion',
                  value: hostGroundTransferCost,
              },
          ]
        : undefined;

    const optionalSuggestedPrice: Pricing[] | undefined =
        !hasItineraryInventoryItem
            ? [
                  {
                      label: 'Suggested Price',
                      value: suggestedCosts,
                  },
              ]
            : undefined;

    const travelerThresholds = travelerTotalCosts.map(
        (threshold) => threshold.numberTravelers,
    );

    const suggestedPrice: Pricing = {
        label: 'Suggested Price',
        value: suggestedCosts,
    };

    const operatorSubtotalPrice = {
        label: 'Operator Subtotal',
        value: operatorTotalCosts,
    };

    const baseCostsBreakdown = [
        ...validityDayPeriodsPrice,
        {
            label: hasItineraryInventoryItem
                ? 'Base Cost from Inventory'
                : 'Base Costs From Validity Periods',
            value: baseCosts.costPerThreshold,
        },
        ...(!hasItineraryInventoryItem
            ? [
                  {
                      label: 'Additional FOC',
                      value: combineMultipleItemThresholds(companionsCosts),
                  },
              ]
            : []),
        ...(optionalSuggestedPrice || []),
        {
            label: 'Your Price',
            value: currentCosts,
        },
    ];

    const variableCostsBreakdown = [
        ...(hasItineraryInventoryItem
            ? [
                  {
                      label: `Additional FOC x ${companionsCosts[0].quantity}`,
                      value: combineMultipleItemThresholds(companionsCosts),
                  },
                  {
                      label: 'FOC Single Supplement',
                      value: singleSupplementCostAdjustments,
                  },
              ]
            : []),
        ...optionalHostServices,
        ...optionalHostActivities,
        {
            label: 'Workshop Space',
            value: combineMultipleItemThresholds(workshopsCosts),
        },
        {
            label: 'Variable Costs',
            value: servicesTotalCosts,
        },
    ];

    const operatorCostsBreakdown = [
        ...(!hasItineraryInventoryItem
            ? [
                  {
                      label: 'Single Supplement Adjustments',
                      value: singleSupplementCostAdjustments,
                  },
              ]
            : []),
        operatorSubtotalPrice,
    ];

    const travelerCostsBreakdown = [
        {
            label: 'Single Supplement',
            value: singleSupplementFeeCosts,
        },
        {
            label: 'Platform Fee',
            value: platformFees,
        },
        ...(optionalHostTransferCost || []),
        {
            label: 'Traveler Price',
            value: travelerTotalCosts,
        },
    ];

    return {
        travelerThresholds,
        operatorSubtotalPrice,
        suggestedPrice,
        validityDayPeriodsPrice,
        baseCostsBreakdown,
        variableCostsBreakdown,
        travelerCostsBreakdown,
        operatorCostsBreakdown,
    };
};

export {
    combineMultipleItemThresholds,
    completeMissingThresholdsWithNull,
    formatCalculatorThresholds,
    getPricingBreakdown,
    validityDaysFormatted,
};
