import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '@trova-trip/trova-components';
import { Dialog } from '@trova-trip/trova-components/build/next';

const ServicesModalWrapper = ({ children, show, onClose }) => (
    <Dialog
        isOpen={show}
        onClose={onClose}
        closeOnOverlayClick={true}
        position='bottom'
        backgroundColor={theme.colors.blueGray[200]}
    >
        <Dialog.Header
            title='Add to the itinerary'
            subtitle='Search or tap your service to select the day you prefer to add it.'
        />
        {children}
    </Dialog>
);

ServicesModalWrapper.propTypes = {
    children: PropTypes.node,
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

export default ServicesModalWrapper;
