import { ReactNode, useContext } from 'react';
import { BaseBox, Stack, useBreakpoint } from '@trova-trip/trova-components';
import { MainContent } from '@trova-trip/trova-components/build/next';
import useSidebarVisibility from '../hooks/useSidebarVisibility';
import { StyledApplication } from '../ApplicationStyles';
import NavigationContext from '../NavigationContext';
import NavBar from './Navbar';
import Sidebar from './Sidebar/Sidebar';
import { SidebarProps } from './Sidebar/Sidebar.types';
import ProfileNavigationDrawer from './ProfileNavigationDrawer/ProfileNavigationDrawer';
import BottomNavBar from './BottomNavBar';

export interface MainContentProps {
    children: ReactNode;
    sidebarProps: Omit<SidebarProps, 'menuItems'>;
    pageLoading?: boolean;
}

const MainContentComponent = ({
    children,
    sidebarProps,
    pageLoading = false,
}: MainContentProps): JSX.Element => {
    const { products, profile, navigationContent, showLayout } =
        useContext(NavigationContext);
    const { isPathWithoutSidebar } = useSidebarVisibility();
    const hideSidebar = useBreakpoint(['base', 'lg']);
    const isSidebarVisible =
        !pageLoading && !hideSidebar && !isPathWithoutSidebar;

    return (
        <>
            {showLayout ? (
                <BaseBox backgroundColor='blueGray.50' width='full'>
                    <Stack
                        direction='column'
                        spacing={0}
                        align='center'
                        justify='center'
                        width='full'
                    >
                        <BaseBox
                            width='full'
                            backgroundColor='neutral.white'
                            data-navbar-container
                        >
                            <NavBar />
                        </BaseBox>
                        <MainContent
                            gap={{
                                base: 0,
                                lg: 6,
                            }}
                            paddingTop={{
                                base: 0,
                                lg: 6,
                            }}
                            sidebarElement={
                                isSidebarVisible && (
                                    <Sidebar
                                        menuItems={navigationContent}
                                        {...sidebarProps}
                                    />
                                )
                            }
                            paddingBottom={24}
                        >
                            {children}
                        </MainContent>
                        <ProfileNavigationDrawer profile={profile} />
                        <BottomNavBar />
                    </Stack>
                </BaseBox>
            ) : (
                <StyledApplication>
                    <div data-content-simple>{children}</div>
                </StyledApplication>
            )}
        </>
    );
};

export default MainContentComponent;
