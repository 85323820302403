import { Grid } from '@material-ui/core';
import { constants, models } from '@trova-trip/trova-models';
import {
    HandleHostSelectedOptionalServicesArgs,
    HostSelectedOptionalService,
} from '../TripRequestForm/TripRequestForm.types';
import TransferToggleInput from './components/TransferToggleInput';

type Service = models.services.Service;
type AirportTransfer = models.services.AirportTransfer;
type ItineraryInventoryItem =
    models.itineraryInventoryItems.ItineraryInventoryItem;
type PrePostTransfersSelection = models.tripRequest.PrePostTransfersSelection;
const ServiceTiming = constants.services.ServiceTiming;
const ServiceType = constants.services.ServiceType;

type HostSelectedOptionalServicesFormProps = {
    selectedOptionalServices: HostSelectedOptionalService[];
    handleHostSelectedOptionalServicesChange: (
        service: HandleHostSelectedOptionalServicesArgs,
    ) => void;
    optionalServices?: Service[];
    isReadOnly?: boolean;
    itineraryInventoryItem: ItineraryInventoryItem | undefined;
    prePosTransfersSelection: PrePostTransfersSelection;
};

const HostSelectedOptionalServicesForm = ({
    selectedOptionalServices,
    optionalServices = [],
    handleHostSelectedOptionalServicesChange,
    isReadOnly = false,
    itineraryInventoryItem,
    prePosTransfersSelection,
}: HostSelectedOptionalServicesFormProps): JSX.Element => {
    const transfers = optionalServices.filter(
        (service): service is AirportTransfer =>
            service.type === ServiceType.AIRPORT_TRANSFER && !!service.price,
    );

    const { beforeTrip: preTransfer, afterTrip: postTransfer } =
        prePosTransfersSelection;

    const handleTransferChange = (
        value: boolean,
        service: Service,
        isPreTransfer: boolean,
    ) => {
        handleHostSelectedOptionalServicesChange({
            service: service,
            name: isPreTransfer ? 'beforeTrip' : 'afterTrip',
            value,
        });
    };

    const getTransferServiceValue = (transfer: Service): boolean => {
        return transfer._id
            ? !!selectedOptionalServices.some(
                  (service) => service.id === transfer._id,
              )
            : false;
    };

    const shouldDisplayTransfer = (transfer: AirportTransfer): boolean => {
        if (transfer.timing === ServiceTiming.PRE_TRIP) {
            return itineraryInventoryItem?.preTransferPrice !== undefined;
        }
        if (transfer.timing === ServiceTiming.POST_TRIP) {
            return itineraryInventoryItem?.postTransferPrice !== undefined;
        }
        return false;
    };

    return (
        <>
            {transfers.filter(shouldDisplayTransfer).map((transfer) => {
                const isPreTransfer =
                    transfer.timing === ServiceTiming.PRE_TRIP;

                const itineraryTransferValue = isPreTransfer
                    ? preTransfer
                    : postTransfer;

                const value = itineraryInventoryItem
                    ? itineraryTransferValue
                    : getTransferServiceValue(transfer);
                return (
                    <Grid item xs={12} key={transfer._id}>
                        <TransferToggleInput
                            name={transfer._id || ''}
                            handleTransferChange={(e, name, value) =>
                                handleTransferChange(
                                    value,
                                    transfer,
                                    isPreTransfer,
                                )
                            }
                            isReadOnly={isReadOnly}
                            title={transfer.name || ''}
                            description={transfer.description ?? ''}
                            value={value}
                        />
                    </Grid>
                );
            })}
        </>
    );
};

export default HostSelectedOptionalServicesForm;
