import {
    BaseBox,
    Stack,
    Text,
    Textarea,
    TextareaSize,
    TextProps,
    UseFormSaverReturn,
} from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import { useState } from 'react';
import { FieldNames } from '../../../EditEmailModal';
import clamp from 'lodash/clamp';
import { constants } from '@trova-trip/trova-models';

type FormBodyProps = {
    formSaver: UseFormSaverReturn;
    emailSubject?: string;
    updateEmailPreview: (blurb: string) => void;
    isPreviewLoading: boolean;
};

enum ButtonStates {
    DIRTY = 'dirty',
    SAVED = 'saved',
}

const { PROMOTE_EMAIL_COPY_MAXIMUM_CHARACTERS } = constants.emails;
const getRemainingCharacters = (text: string): number => {
    const remainingCharacters =
        PROMOTE_EMAIL_COPY_MAXIMUM_CHARACTERS - text.length;
    return clamp(remainingCharacters, 0, PROMOTE_EMAIL_COPY_MAXIMUM_CHARACTERS);
};

const FormBody = ({
    formSaver,
    emailSubject,
    updateEmailPreview,
    isPreviewLoading,
}: FormBodyProps): JSX.Element => {
    const hostBlurb = formSaver.formValues.get[FieldNames.HOST_BLURB] as string;
    const [appliedHostBlurb, setAppliedHostBlurb] = useState<string>(hostBlurb);

    const getButtonCurrentState = (): ButtonStates => {
        return appliedHostBlurb === hostBlurb
            ? ButtonStates.SAVED
            : ButtonStates.DIRTY;
    };

    const getMaxCharactersIndicatorColor = (): TextProps['color'] => {
        return getRemainingCharacters(hostBlurb) <= 0
            ? 'red.trova'
            : 'blueGray.500';
    };

    return (
        <Stack direction='column' spacing={6}>
            <BaseBox width='full'>
                <Text color='blueGray.650' fontWeight='medium'>
                    Subject Line
                </Text>
                <Text color='blueGray.600'>{emailSubject}</Text>
            </BaseBox>
            <BaseBox width='full'>
                <Text color='blueGray.650' fontWeight='medium'>
                    Customizable Email Copy
                </Text>
                <Textarea
                    name={FieldNames.HOST_BLURB}
                    size={TextareaSize.Flexible}
                    value={hostBlurb}
                    onChange={formSaver.formValues.set[FieldNames.HOST_BLURB]}
                    maxLength={PROMOTE_EMAIL_COPY_MAXIMUM_CHARACTERS}
                    disabled={isPreviewLoading}
                />
                <Stack justify='space-between' marginTop={2}>
                    <Text as='p' fontSize='sm' color='blueGray.500'>
                        This is the editable text on this email template
                    </Text>
                    <Text
                        as='p'
                        fontSize='sm'
                        color={getMaxCharactersIndicatorColor()}
                    >
                        {getRemainingCharacters(hostBlurb)}/
                        {PROMOTE_EMAIL_COPY_MAXIMUM_CHARACTERS}
                    </Text>
                </Stack>
            </BaseBox>
            {getButtonCurrentState() === ButtonStates.DIRTY ? (
                <Button
                    onClick={(): void => {
                        updateEmailPreview(hostBlurb);
                        setAppliedHostBlurb(hostBlurb);
                    }}
                    colorScheme='red'
                >
                    Apply Changes
                </Button>
            ) : (
                <Button colorScheme='teal' leftIcon='check'>
                    Applied
                </Button>
            )}
        </Stack>
    );
};

export default FormBody;
