import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Card, theme } from '@trova-trip/trova-components';

export const showMoreResults = () =>
    css`
        text-align: center;
        margin-top: 0.8rem;
    `;

export const innerCard = () => css`
    margin: -1rem;
`;

export const StyledCard = styled(Card)`
    margin-bottom: 0.8rem;

    @media (max-width: ${theme.breakpoints.sm}) {
        padding: 0;
        margin-bottom: 1.75rem;
        background-color: transparent;
    }
`;
