import { Dialog } from '@trova-trip/trova-components/build/next';
import ExportDataContent from './ExportDataContent';
import useAudienceDataExport, {
    DownloadStatus,
} from '../../../hooks/useAudienceDataExport';
import { ExportDataResponseContent } from './ExportDataResponseContent';

interface DownloadAudienceDataDialogProps {
    onCloseDialog: () => void;
    totalAudience: number;
    surveyResponses: number;
    isOpen: boolean;
}

const DownloadAudienceDataDialog = ({
    onCloseDialog,
    totalAudience,
    surveyResponses,
    isOpen,
}: DownloadAudienceDataDialogProps): JSX.Element => {
    const {
        isExtendedDownloadData,
        toggleExtendDownloadDate,
        resetDownloadDataState,
        requestStatus,
        exportDataClickHandler,
    } = useAudienceDataExport();

    const isRequestFinished =
        requestStatus === DownloadStatus.SUCCESS ||
        requestStatus === DownloadStatus.FAILURE;

    const onCloseHandler = () => {
        onCloseDialog();
        resetDownloadDataState();
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onCloseHandler}
            size='md'
            showCloseButton={false}
            height={{ base: 'xl', md: '2xl' }}
            width={{ base: 'full', md: 'md' }}
        >
            {isRequestFinished ? (
                <ExportDataResponseContent
                    isExportSuccess={requestStatus === DownloadStatus.SUCCESS}
                    onClickExport={exportDataClickHandler}
                    onClickClose={onCloseHandler}
                />
            ) : (
                <ExportDataContent
                    isRequestPending={requestStatus === DownloadStatus.PENDING}
                    totalAudience={totalAudience}
                    surveyResponses={surveyResponses}
                    onClickExport={exportDataClickHandler}
                    onClickCancel={onCloseHandler}
                    onToggleExtendData={toggleExtendDownloadDate}
                    isDataExtended={isExtendedDownloadData}
                />
            )}
        </Dialog>
    );
};

export default DownloadAudienceDataDialog;
