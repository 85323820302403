/* eslint-disable @typescript-eslint/no-unused-vars */
import { socialLabels } from '../../../../host/components/SocialMediaAccounts';
import {
    PhylloEnvironments,
    PhylloEventHandlers,
    PhylloEvents,
    PhylloInitializeResponse,
} from '../../../../../global.typings';
import { useCallback, useRef, useState } from 'react';
import {
    initializePhylloUser,
    SocialConnectResponse,
} from '../../../../../apis/socialConnect';
import { APP_ENVIRONMENT } from '../../../../../util/appUtils';
import { appConstants } from '@trova-trip/trova-common';
import {
    PhylloConnectionFailureReason,
    PhylloErrorKeys,
} from '../../../../common/types/phyllo';

interface ConnectedAccount {
    id: string;
    userId: string;
}

type Platform = keyof typeof socialLabels;
export type PhylloStatus = 'idle' | 'initializing-sdk' | 'in-process';
type PlatformStatuses = Record<Platform, PhylloStatus>;
type PhylloSubscriberFns = { [K in PhylloEvents]?: PhylloEventHandlers[K] };
type PhylloPlatformInitializations = Map<Platform, PhylloInitializeResponse>;
type ConnectedAccounts = Record<Platform, ConnectedAccount>;

const PhylloEnvironmentMap: Record<
    appConstants.Environment,
    PhylloEnvironments
> = {
    [appConstants.Environment.Production]: 'production',
    [appConstants.Environment.ProductionClone]: 'production',
    [appConstants.Environment.Staging]: 'staging',
    [appConstants.Environment.QA]: 'sandbox',
    [appConstants.Environment.Development]: 'sandbox',
    [appConstants.Environment.Local]: 'sandbox',
};

export interface UsePhylloReturn {
    error: PhylloErrorKeys | undefined;
    /**
     * The current status of the Phyllo SDK
     */
    statusByPlatform: PlatformStatuses;
    /**
     * Initializes the Phyllo SDK for the given platforms
     * @param platforms The platforms to initialize
     * @param subscribers The subscribers to attach to each of the initialized platforms
     * @returns The initialized Phyllo platform objects
     */
    init: (
        platforms: Platform[],
        subscribers?: PhylloSubscriberFns,
    ) => Promise<PhylloPlatformInitializations | undefined>;
    /**
     * Opens the Phyllo SDK for the given platform
     * @param platform The platform to open
     */
    open: (platform: Platform) => void;
    /**
     * List of connected accounts
     */
    connectedAccounts: ConnectedAccounts;
}

const usePhyllo = (): UsePhylloReturn => {
    const [error, setError] = useState<PhylloErrorKeys | undefined>();
    const [statusByPlatform, setStatusByPlatform] = useState<PlatformStatuses>({
        instagram: 'idle',
        tiktok: 'idle',
        youtube: 'idle',
        facebook: 'idle',
    });
    const [connectedAccounts, setConnectedAccounts] =
        useState<ConnectedAccounts>({} as ConnectedAccounts);
    const phylloUserData = useRef<SocialConnectResponse | undefined>();
    const platformInitializations = useRef<PhylloPlatformInitializations>(
        new Map(),
    );

    /**
     * Sets the status of a given platform
     * @param platform The platform to set the status for
     * @param newStatus The new status to set
     */
    const setPlatformStatus = (
        platform: Platform,
        newStatus: PhylloStatus,
    ): void => {
        setStatusByPlatform((prev) => ({
            ...prev,
            [platform]: newStatus,
        }));
    };

    /**
     * Removes a connected account from the connected accounts list
     * @param platform The platform to remove the connected account for
     */
    const removeConnectedAccount = (platform: Platform): void => {
        setConnectedAccounts((prev) => {
            const newConnectedAccounts = { ...prev };
            delete newConnectedAccounts[platform];
            return newConnectedAccounts;
        });
    };

    /**
     * Fetches the needed Phyllo user data for the current user and stores it in a ref
     * @returns The Phyllo Init user data
     */
    const getPhylloUserData = useCallback(async (): Promise<
        SocialConnectResponse | undefined
    > => {
        if (phylloUserData.current) {
            return phylloUserData.current;
        }
        const response = await initializePhylloUser();
        if (!response?.success || !response?.data) {
            console.error('Failed to fetch Phyllo user data: ', response);
            setError(PhylloErrorKeys.FAILED_USER_DATA);
            return;
        }
        phylloUserData.current = response.data;
        return response.data;
    }, []);

    /**
     * Attaches the given subscribers to the given platform
     * @param platform
     * @param subscribers
     * @param initResponse
     * @param userData
     */
    const attachSubscribers = useCallback(
        (
            platform: Platform,
            userData: SocialConnectResponse,
            initResponse: PhylloInitializeResponse,
            subscribers?: PhylloSubscriberFns,
        ): void => {
            // Need to pass handlers to all possible events, even if we don't use them, and also declare all given parameters in order to avoid Phyllo SDK error.
            const eventHandlers: PhylloEventHandlers = {
                accountConnected: (accountId, workplatformId, userId) => {
                    setConnectedAccounts((prev) => ({
                        ...prev,
                        [platform]: {
                            id: userData.socialEngagementChannelIds[platform],
                            userId: userData.socialEngagementUserId,
                        },
                    }));
                    setPlatformStatus(platform, 'idle');
                    setError(undefined);
                    subscribers?.accountConnected?.(
                        accountId,
                        workplatformId,
                        userId,
                    );
                },
                connectionFailure: (reason, workplatformId, userId) => {
                    console.error(
                        '@Trova: Failed to connect with Phyllo => ',
                        reason,
                    );
                    if (
                        reason !==
                        PhylloConnectionFailureReason.CONNECTION_ABANDONED
                    ) {
                        setError(PhylloErrorKeys.FAILED_ACCOUNT_CONNECT);
                    }
                    setPlatformStatus(platform, 'idle');
                    removeConnectedAccount(platform);
                    subscribers?.connectionFailure?.(
                        reason,
                        workplatformId,
                        userId,
                    );
                },
                exit: (reason, userId) => {
                    setPlatformStatus(platform, 'idle');
                    subscribers?.exit?.(reason, userId);
                },
                accountDisconnected: (accountId, workplatformId, userId) => {
                    removeConnectedAccount(platform);
                    subscribers?.accountDisconnected?.(
                        accountId,
                        workplatformId,
                        userId,
                    );
                },
                tokenExpired: (userId) => {
                    subscribers?.tokenExpired?.(userId);
                },
            };
            Object.keys(eventHandlers).forEach((event) => {
                initResponse.on(event as PhylloEvents, eventHandlers[event]);
            });
        },
        [],
    );

    /**
     * Initializes the given platform with the given subscribers
     * @param platform
     * @param subscribers
     * @param userData
     * @returns The Phyllo Initialize Response
     */
    const initializePlatform = useCallback(
        (
            platform: Platform,
            userData: SocialConnectResponse,
            subscribers?: PhylloSubscriberFns,
        ): PhylloInitializeResponse | undefined => {
            if (platformInitializations.current.has(platform)) {
                return platformInitializations.current.get(
                    platform,
                ) as PhylloInitializeResponse;
            }
            setPlatformStatus(platform, 'initializing-sdk');
            const {
                socialEngagementChannelIds,
                socialEngagementUserId,
                token,
            } = userData;

            const initResponse = window.PhylloConnect.initialize({
                workPlatformId: socialEngagementChannelIds[platform],
                token: token,
                clientDisplayName: 'TrovaTrip',
                environment: PhylloEnvironmentMap[APP_ENVIRONMENT],
                userId: socialEngagementUserId,
                redirect: false,
                singleAccount: true,
            });

            if (!initResponse) {
                console.error('@Trova: Failed to initialize Phyllo SDK');
                setError(PhylloErrorKeys.FAILED_INITIALIZE_SDK);
                return;
            }

            attachSubscribers(platform, userData, initResponse, subscribers);
            setPlatformStatus(platform, 'idle');
            platformInitializations.current.set(platform, initResponse);
            return initResponse;
        },
        [attachSubscribers],
    );

    const init = useCallback(
        async (
            platforms: Platform[],
            subscribers?: PhylloSubscriberFns,
        ): Promise<PhylloPlatformInitializations | undefined> => {
            try {
                // Initialize Phyllo SDK
                const phylloUserData = await getPhylloUserData();
                if (!phylloUserData) {
                    return;
                }

                const phylloInitObjects =
                    new Map() as PhylloPlatformInitializations;

                platforms.forEach((platform) => {
                    const initResponse = initializePlatform(
                        platform,
                        phylloUserData,
                        subscribers,
                    );
                    if (!initResponse) {
                        return;
                    }
                    phylloInitObjects.set(platform, initResponse);
                });

                return phylloInitObjects;
            } catch (error) {
                console.error(
                    '@Trova: Failed to initialize Phyllo SDK => ',
                    error,
                );
            }
        },
        [initializePlatform, getPhylloUserData],
    );

    const open = (platform: Platform): void => {
        if (!platformInitializations.current?.has(platform)) {
            return;
        }
        setPlatformStatus(platform, 'in-process');
        platformInitializations.current.get(platform)?.open();
    };

    return {
        error,
        statusByPlatform,
        init,
        open,
        connectedAccounts,
    };
};

export default usePhyllo;
