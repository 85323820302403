import { Badge, BadgeProps } from '@trova-trip/trova-components';
import { TierInfo } from 'applications/types';

interface ItineraryTierBadgeProps {
    tier: TierInfo;
}

const BADGE_COLOR: Record<string | number, BadgeProps['colorScheme']> = {
    1: 'green',
    2: 'purple',
    3: 'blue',
    locked: 'dark',
};

export const getBadgePropsFromTier = (tier: TierInfo): BadgeProps => {
    const { number, name, isLocked } = tier;

    const icon: BadgeProps['icon'] = isLocked ? 'lock' : undefined;
    const colorScheme = isLocked ? BADGE_COLOR.locked : BADGE_COLOR[number];

    return { variant: 'rounded', colorScheme, icon, children: name };
};

const ItineraryTierBadge = ({ tier }: ItineraryTierBadgeProps): JSX.Element => {
    return <Badge {...getBadgePropsFromTier(tier)} />;
};

export default ItineraryTierBadge;
