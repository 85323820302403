import {
    Heading,
    Image,
    Stack,
    Text,
    useToast,
} from '@trova-trip/trova-components';
import {
    Button,
    ButtonProps,
    Table,
} from '@trova-trip/trova-components/build/next';
import { useCallback } from 'react';
import carImg from '../../../../../assets/img/car.svg';
import {
    BookedGroundTransferJourney,
    Journey,
} from '../../../../../state/features/transfer/types';
import { isDriverDataAvailable } from '../../../../common/helpers';
import useClipboard from '../../../hooks/useClipboard';
import useIsLargeScreen from '../../../hooks/useIsLargeScreen';
import InfoRow from './shared/InfoRow';

interface CompanyDetailsProps {
    company: Journey['transferCompany'];
}

const CompanyDetails = ({
    company,
}: CompanyDetailsProps): JSX.Element | null => {
    if (!company) return null;

    return (
        <>
            <Text color='blueGray.500' marginBottom={6}>
                Once a driver is assigned, their contact information will be
                shared here.
            </Text>
            <CompanyInformationTable company={company} />
        </>
    );
};

interface CompanyInformationProps {
    company: Journey['transferCompany'];
}

const CompanyInformationTable = ({
    company,
}: CompanyInformationProps): JSX.Element | null => {
    const toast = useToast();
    const { copyTextToClipboard } = useClipboard();
    const isDesktop = useIsLargeScreen();

    const onCopyClick = useCallback(() => {
        copyTextToClipboard(company?.phoneNumber);

        toast({
            title: 'Number copied',
            description: `The company number was successfully copied`,
            status: 'success',
            isClosable: true,
        });
    }, [company?.phoneNumber]);

    if (!company) return null;

    const buttonProps: Omit<ButtonProps, 'children'> = isDesktop
        ? {
              variant: 'tertiary',
              onClick: onCopyClick,
          }
        : {
              as: 'a',
              variant: 'tertiary',
              linkProps: {
                  target: '_blank',
                  href: `tel:${company?.phoneNumber}`,
              },
          };

    return (
        <Stack
            direction='column'
            align='stretch'
            width='full'
            paddingBottom={{ base: 4, md: 8 }}
        >
            <Text color='blueGray.500' fontWeight='bold'>
                Company Information
            </Text>
            <Table variant='simple' borderOutline={false}>
                <Table.Body>
                    <InfoRow title='Name' value={company.name} />
                    <Table.Row>
                        <Table.Cell paddingX={0}>
                            <Text fontSize='sm' color='blueGray.700'>
                                Contact
                            </Text>
                        </Table.Cell>
                        <Table.Cell
                            paddingX={0}
                            textAlign={{ base: 'right', md: 'left' }}
                        >
                            <Button {...buttonProps}>
                                {company.phoneNumber}
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </Stack>
    );
};

interface DriverDetailsProps {
    driver: Journey['driver'];
    company: Journey['transferCompany'];
}

const DriverDetails = ({
    driver,
    company,
}: DriverDetailsProps): JSX.Element | null => {
    const toast = useToast();
    const isDesktop = useIsLargeScreen();
    const { copyTextToClipboard } = useClipboard();

    const onCopyClick = useCallback(() => {
        copyTextToClipboard(driver?.phoneNumber);

        toast({
            title: 'Number copied',
            description: `The driver number was successfully copied`,
            status: 'success',
            isClosable: true,
        });
    }, [driver?.phoneNumber]);

    if (!driver) return null;

    return (
        <Stack
            width='full'
            flexGrow={1}
            paddingBottom={2}
            direction='column'
            justify='space-between'
            spacing={12}
        >
            <Stack width='full'>
                <Stack direction='row' width='full' justify='space-between'>
                    <Image src={carImg} alt='vehicle' data-gumlet='false' />
                    <Stack direction='column' align='right'>
                        <Text textAlign='right'>{driver.name}</Text>
                        <Heading
                            as='h2'
                            textTransform='uppercase'
                            fontFamily='poppins'
                            textAlign='right'
                        >
                            {driver.vehicleLicensePlate}
                        </Heading>
                        <Text textAlign='right'>
                            {driver.vehicleMake} {driver.vehicleModel}
                        </Text>
                    </Stack>
                </Stack>
                <CompanyInformationTable company={company} />
            </Stack>
            {isDesktop ? (
                <Stack alignSelf='flex-end'>
                    <Button
                        size='md'
                        leftIcon='copy'
                        onClick={onCopyClick}
                    >{`Copy Driver's number`}</Button>
                </Stack>
            ) : null}
        </Stack>
    );
};

interface RideDetailsProps {
    journey: BookedGroundTransferJourney;
}

const RideDetails = ({ journey }: RideDetailsProps): JSX.Element => {
    const driverDataIsAvailable = isDriverDataAvailable(journey.driver);

    if (driverDataIsAvailable) {
        return (
            <DriverDetails
                driver={journey.driver}
                company={journey.transferCompany}
            />
        );
    }

    return <CompanyDetails company={journey.transferCompany} />;
};

export default RideDetails;
