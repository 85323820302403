import PropTypes from 'prop-types';
import Fileuploader from './FileUploader/FileUploader.js';

const ALLOWED_IMAGE_EXTENSIONS = `jpg, jpeg, png`;
const MAX_FILE_SIZE = `10`;

const ImageUploaderInput = ({
    onChange,
    value,
    imageAmountLimit,
    name,
    theme,
    classes,
    uploadUrl,
    sortable,
    label,
    disabled = false,
}) => (
    <Fileuploader
        name={name}
        limit={imageAmountLimit}
        fileMaxSize={MAX_FILE_SIZE}
        extensions={ALLOWED_IMAGE_EXTENSIONS}
        sortable={sortable}
        onChange={onChange}
        value={value}
        theme={theme}
        classes={classes}
        uploadUrl={uploadUrl}
        label={label}
        disabled={disabled}
    />
);

ImageUploaderInput.propTypes = {
    imageAmountLimit: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    uploadUrl: PropTypes.string.isRequired,
    sortable: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    onChange: PropTypes.func,
    theme: PropTypes.oneOf([`gallery`]),
    classes: PropTypes.string,
    label: PropTypes.string,
};

export default ImageUploaderInput;
