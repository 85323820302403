import { Button, ButtonProps } from '@trova-trip/trova-components/build/next';
import useTripRoute, {
    TRIP_ACTION,
} from 'applications/common/hooks/trips/useTripRoute';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { TripStoreSelector } from 'state/trips';

interface TripReviewPageButtonProps {
    text: string;
    variant?: ButtonProps['variant'];
    icon?: ButtonProps['rightIcon'];
    isDisabled?: boolean;
}

const TripReviewPageButton = ({
    text,
    icon,
    isDisabled,
    variant = 'primary',
}: TripReviewPageButtonProps): JSX.Element => {
    const trip = useSelector(
        ({ userTrips }: TripStoreSelector) => userTrips.current,
    );
    const history = useHistory();

    const configurationRoute = useTripRoute({
        id: trip.id,
        action: TRIP_ACTION.PREVIEW,
    });

    const handleClick = (): void => {
        history.push(configurationRoute);
    };

    return (
        <Button
            variant={variant}
            rightIcon={icon}
            onClick={handleClick}
            isDisabled={isDisabled}
            size={{ base: 'sm', md: 'md' }}
        >
            {text}
        </Button>
    );
};

export default TripReviewPageButton;
