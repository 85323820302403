import FetchState from './utils/FetchState';
import { get, post } from './utils/api';

const login = FetchState.create(`authentication/login`, false);

login.setCustomPreprocessor((response) => {
    if (response.loggedIn) {
        return true;
    }
    throw new Error(response.error);
});

const logoutState = FetchState.create(`authentication/logout`, false);

const logout = (successCallback: () => void): Promise<void> => {
    return logoutState.createRecord(
        {},
        {
            successCallback: () => {
                successCallback();
                return undefined;
            },
        },
    )(() => undefined);
};

const changePassword = FetchState.create(
    `authentication/change-password`,
    false,
);

const checkUserActivation = (email) =>
    get(
        `authentication/check-user-activation?email=${encodeURIComponent(
            email,
        )}`,
    );

const resetUserToken = FetchState.create(`authentication/reset-user-token`);

const resetPassword = FetchState.create(`authentication/request-new-password`);

const getUserFromToken = (token) => get(`activation/profile?token=${token}`);
const getUserFromPasswordToken = (token) =>
    get(`authentication/profile-password-reset?token=${token}`);

const activateUser = (token, data) =>
    post(`authentication/activate-user?token=${token}`, data);

const logUserIn = (data) => post(`authentication/login`, data);

const signupHost = (data) => post(`authentication/signup/host`, data);

const loginReducer = login.getReducer();

export {
    login,
    loginReducer,
    logout,
    logUserIn,
    signupHost,
    changePassword,
    checkUserActivation,
    resetUserToken,
    resetPassword,
    getUserFromToken,
    getUserFromPasswordToken,
    activateUser,
};
