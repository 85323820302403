import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
import { Table, BaseBox, Text } from '@trova-trip/trova-components';

export const ListItem = ({ children }) => {
    return (
        <BaseBox marginTop={4}>
            <Text size={'md'} fontWeight={'bold'}>
                {children}
            </Text>
        </BaseBox>
    );
};

export const ListItemValue = ({ children, textProps }) => {
    return (
        <BaseBox marginTop={{ xs: 1, sm: 4, md: 4, lg: 4 }}>
            <Text {...textProps} size={'md'}>
                {children}
            </Text>
        </BaseBox>
    );
};

export const StyledMainContainerGrid = styled(Grid)({
    paddingBottom: `2rem`,
});
export const PaddedGrid = styled(Grid)({ paddingBottom: `1rem` });

export const StyledSectionContainerGrid = styled(Grid)({
    marginBottom: `4rem`,
});

export const StyledTable = styled(Table)`
    margin: 1rem 0 0.5rem 0;

    > table {
        background-color: white;
        width: 100% !important;

        > thead {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            border-bottom: 1px solid #c5c8cd;
            tr:first-child {
                border-radius: inherit;
                th {
                    padding: 16px;
                    line-height: 1.2rem;
                    border-radius: inherit;
                }
            }
            tr:last-child {
                border-radius: inherit;
                th {
                    padding: 16px;
                    border-radius: inherit;
                }
            }
        }
    }
`;
