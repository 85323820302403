import InlineToggleInput from '../../InlineToggleInput/InlineToggleInput';

type TransferToggleInputProps = {
    name: string;
    title: string;
    value: boolean;
    description: string;
    isReadOnly: boolean;
    handleTransferChange: (
        e: React.SyntheticEvent,
        name: string,
        value: boolean,
    ) => void;
};

const TransferToggleInput = ({
    name,
    title,
    handleTransferChange,
    value,
    description,
    isReadOnly,
}: TransferToggleInputProps) => {
    return (
        <InlineToggleInput
            key={name}
            title={title}
            onChange={handleTransferChange}
            name={name}
            value={value}
            label={value ? 'Yes' : 'No'}
            description={description}
            disabled={isReadOnly}
        />
    );
};

export default TransferToggleInput;
