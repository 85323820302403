import FetchState from './utils/FetchState';
import { get, post } from './utils/api';
import { models } from '@trova-trip/trova-models';

const itineraries = FetchState.create(`api/admin/itineraries`);
const itinerariesMetadata = FetchState.create(`api/metadata/itineraries`);
type SuggestedSellPriceRequest = models.itineraries.SuggestedSellPriceRequest;

export type ItineraryStoreSelector = {
    userItinerary: { current: models.itineraries.Itinerary };
};

const publishItinerary = (itineraryId: string): Promise<void> =>
    post(
        `api/admin/files/website/itineraries/${itineraryId}/publish`,
        undefined,
    );

const availability = (
    itineraryId: string,
    includeInventoryItemsInUse?: boolean,
) => {
    let url = `api/me/itinerary/${itineraryId}/availability`;

    if (includeInventoryItemsInUse) {
        url += '?includeInUse=true';
    }

    return get(url);
};

const getSuggestedSellPrice = (
    itineraryId: string,
    data: Pick<
        SuggestedSellPriceRequest,
        'minimumPrice' | 'tripStartDate' | 'selectedPackage'
    >,
) => {
    const queryParams = new URLSearchParams({
        selectedPackage: data.selectedPackage,
        tripStartDate: data.tripStartDate.toString(),
        minimumPrice: data.minimumPrice.toString(),
    });
    return get(
        `api/me/itineraries/${itineraryId}/suggested-sell-price?${queryParams.toString()}`,
    );
};

const itinerariesMetadataReducer = itinerariesMetadata.getReducer();
const itinerariesReducer = itineraries.getReducer();

export {
    itineraries,
    itinerariesMetadata,
    publishItinerary,
    availability,
    getSuggestedSellPrice,
    itinerariesReducer,
    itinerariesMetadataReducer,
};
