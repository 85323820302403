import { coreUtils } from '@trova-trip/trova-common';
import { constants, models } from '@trova-trip/trova-models';
import isSameDay from 'date-fns/isSameDay';

const { getZeroUTCResetDateObj } = coreUtils.dateUtils;

type Trip = models.trips.Trip;
type TripRequest = models.tripRequest.TripRequest;

type PackageLevel = constants.itinerary.PackageLevel;
type ItineraryPackage = models.itineraries.ItineraryPackage;
type Availability = models.itineraries.Availability;

type ItineraryInventoryItem =
    models.itineraryInventoryItems.ItineraryInventoryItem;
type SavedItineraryInventoryItem =
    models.itineraryInventoryItems.SavedItineraryInventoryItem;

/**
 * Finds the inventory item with the date given from the items.
 *
 * @param items - The array to find the inventory item
 * @param date - The date to filter
 * @returns {ItineraryInventoryItem | undefined}
 */
const findInventoryItemByStartDate = (
    items: ItineraryInventoryItem[],
    date: Date | string,
): ItineraryInventoryItem | undefined => {
    return items.find((item) => {
        return isSameDay(
            getZeroUTCResetDateObj(item.startDate),
            getZeroUTCResetDateObj(date),
        );
    });
};

/**
 * Finds the inventory item with the ID given from the items.
 *
 * @param items - The array to find the inventory item
 * @param id - The ID to filter
 * @returns {ItineraryInventoryItem | undefined}
 */
const findInventoryItemById = (
    items: SavedItineraryInventoryItem[],
    id: string | undefined,
): SavedItineraryInventoryItem | undefined => {
    if (!id) {
        return;
    }
    return items.find((item) => item._id === id);
};

/**
 * Finds the inventory items with the package given from the items.
 *
 * @param items
 * @param packageLevel
 * @returns {ItineraryInventoryItem[]}
 */
const findInventoryItemsByPackage = (
    items: ItineraryInventoryItem[],
    packageLevel: PackageLevel,
): ItineraryInventoryItem[] => {
    return items.filter((item) => item.packageLevel === packageLevel);
};

/**
 * Returns all the start dates of the given items.
 *
 * @param items - The items to get the start dates
 * @param dateType - Date type: Date or String
 * @returns {Date[] | string[]}
 */
const getStartDatesOfInventoryItems = <T extends 'Date' | 'ISOString'>(
    items: ItineraryInventoryItem[],
    dateType: T = 'Date' as T,
): T extends 'Date' ? Date[] : string[] => {
    return items.map((item) => {
        const date = new Date(item.startDate);
        return dateType === 'Date' ? date : date.toISOString();
    }) as T extends 'Date' ? Date[] : string[];
};

/**
 * Checks whether an itinerary package is enabled based on its flag, and whether
 * the itinerary inventory is enabled by having available items.
 *
 * @param itineraryPackage - The package to check if it's enabled
 * @param availability - The availability to check if there are available items
 * @param isInventoryEnabled - The flag to define if the itinerary inventory is enabled
 * @returns {boolean}
 */
const checkIfPackageIsAvailable = (
    itineraryPackage: ItineraryPackage | undefined,
    availability: Availability,
    isInventoryEnabled: boolean,
): boolean => {
    if (!itineraryPackage) {
        return false;
    }

    let isEnabled = itineraryPackage.enabled;

    if (isInventoryEnabled && isEnabled) {
        const { itineraryInventoryItems = [] } = availability || {};
        isEnabled = itineraryInventoryItems.length > 0;
    }

    return isEnabled;
};

/**
 * Checks if the inventory item has already pending or confirmed requests meaning that
 * is in use.
 *
 * @param item - The item to check if it's in use.
 * @returns {boolean}
 */
const checkIfInventoryItemIsInUse = (item: ItineraryInventoryItem): boolean => {
    const { quantityPending, quantityConfirmed } = item;
    return quantityPending > 0 || quantityConfirmed > 0;
};

/**
 * Checks if the status of the item is reserved
 *
 * @param item
 * @returns boolean
 */
const checkIfInventoryItemIsReserved = (
    item: ItineraryInventoryItem,
): boolean => {
    const { quantityConfirmed, quantityPending, quantityAvailable } = item;
    return (
        quantityPending > 0 &&
        quantityAvailable - (quantityConfirmed + quantityPending) === 0
    );
};

/**
 * Checks if the status of the item is confirmed
 *
 * @param item
 * @returns boolean
 */
const checkIfInventoryItemIsConfirmed = (
    item: ItineraryInventoryItem,
): boolean => {
    const { quantityConfirmed, quantityAvailable } = item;
    return quantityAvailable === quantityConfirmed;
};

/**
 * Check if the trip has an itinerary inventory item.
 *
 * @param trip
 * @returns `true` if the trip has an itinerary inventory item, `false` otherwise.
 */
const tripHasItineraryInventoryItem = (
    trip: Trip | TripRequest | undefined,
): boolean => {
    if (!trip) {
        return false;
    }

    return !!trip.itineraryInventoryItem;
};

export {
    checkIfPackageIsAvailable,
    checkIfInventoryItemIsInUse,
    checkIfInventoryItemIsReserved,
    checkIfInventoryItemIsConfirmed,
    findInventoryItemById,
    findInventoryItemByStartDate,
    findInventoryItemsByPackage,
    getStartDatesOfInventoryItems,
    tripHasItineraryInventoryItem,
};
