import {
    getHostAudienceData,
    getHostEarnings,
    getHostImportantDates,
    getHostProgress,
    getHostUpcomingTrips,
    getProgressResponsesReview,
    HostApiResponse,
    HostResponsesReview,
} from '../../../../../../state/hosts';
import {
    HostAudienceData,
    HostEarnings,
    HostImportantDates,
    HostUpcomingTrips,
    WidgetNames,
    WidgetTypes,
} from '../../common/types';
import { useSelector } from '../../../../../../state/hooks';
import { useCallback, useReducer } from 'react';
import { models } from '@trova-trip/trova-models';

type HomeProgress = models.hosts.HostHomeProgressResponse;

const widgetGetters = {
    [WidgetNames.UPCOMING_TRIPS]: getHostUpcomingTrips,
    [WidgetNames.EARNINGS]: getHostEarnings,
    [WidgetNames.IMPORTANT_DATES]: getHostImportantDates,
    [WidgetNames.AUDIENCE]: getHostAudienceData,
    [WidgetNames.PROGRESS]: getHostProgress,
    [WidgetNames.REVIEWS]: getProgressResponsesReview,
} as const;

type WidgetData =
    | HostAudienceData
    | HostEarnings
    | HostImportantDates
    | HostUpcomingTrips
    | HomeProgress
    | HostResponsesReview;

export interface UseHomeWidgetDataReturn<T> {
    load: () => Promise<{ success: boolean }>;
    data: T | undefined;
    isLoading: boolean;
    error: boolean;
}

type HomeState<T> = Omit<UseHomeWidgetDataReturn<T>, 'load'>;

type HomeReducer<T> = (
    state: HomeState<T>,
    payload: Partial<HomeState<T>>,
) => HomeState<T>;

const widgetDataReducer = (state, data) => ({
    ...state,
    ...data,
});

export type LoadResponse = Promise<{ success: boolean }>;

const useHomeWidgetData = <T extends WidgetData>(
    widgetId: WidgetTypes,
): UseHomeWidgetDataReturn<T> => {
    const { current: currentUser } = useSelector((state) => state.profile);

    const initialState = {
        isLoading: false,
        error: false,
        data: undefined,
    };

    const [homeWidgetData, setHomeWidgetData] = useReducer(
        widgetDataReducer as HomeReducer<T>,
        initialState,
    );

    const load = useCallback(async (): LoadResponse => {
        setHomeWidgetData({ isLoading: true });
        if (!currentUser?.id) {
            setHomeWidgetData({ isLoading: false, error: true });
            return { success: false };
        }
        const data = (await widgetGetters[widgetId](
            currentUser.id,
        )) as HostApiResponse<T>;
        if (data?.success) {
            setHomeWidgetData({ data: data?.data, isLoading: false });
        } else {
            setHomeWidgetData({ isLoading: false, error: true });
        }
        return { success: !!data?.success };
    }, [currentUser?.id, widgetId]);

    return {
        load,
        ...homeWidgetData,
    };
};

export default useHomeWidgetData;
