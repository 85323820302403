import { Stack, Text, useBreakpointValue } from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';
import OnboardingStepHeader from './common/OnboardingStepHeader';

type User = models.users.User;

type WelcomeProps = {
    profile: User;
};

const WelcomePage = ({ profile }: WelcomeProps) => {
    const showLogo = useBreakpointValue({ base: false, md: true });

    return (
        <Stack
            direction='column'
            spacing={{ base: 4, md: 8 }}
            marginBottom={{ md: 6 }}
        >
            <OnboardingStepHeader
                title={
                    <>
                        Hi {profile?.firstName} 👋🏼 <br /> Welcome to TrovaTrip!
                    </>
                }
                lead='TrovaTrip is your seamless solution to planning, selling and managing group travel. We’re excited to have you here!'
                showTrovaTripLogo={showLogo}
            />
            <Text fontSize={{ base: 'sm', md: 'base' }} color='blueGray.600'>
                Become a Host in 3 easy steps.
            </Text>
        </Stack>
    );
};

export default WelcomePage;
