import { Grid } from '@material-ui/core';
import { FormattedBookingOrder } from '../BookingsTables/BookingsTables';
import { Heading, Text, BaseBox } from '@trova-trip/trova-components';
import { styledContainer } from './BookingsTables.styles';
import TravelersSelectableTable from './TravelersSelectableTable';

interface WaitlistedTableProps {
    data: FormattedBookingOrder[];
    onCancelBookings: (
        ordersId: string[],
        resetSelectedOrders: () => void,
    ) => void;
}

const WaitlistedTable = ({
    data,
    onCancelBookings,
}: WaitlistedTableProps): JSX.Element => {
    return (
        <Grid container spacing={32} className={styledContainer}>
            <Grid item xs={12} sm={11}>
                <Heading as={'h4'}>Waitlisted</Heading>
                <BaseBox marginTop={4}>
                    <Text>
                        Use the table below to view and reject the travelers
                        that have booked this trip.
                    </Text>
                </BaseBox>
            </Grid>
            <TravelersSelectableTable
                data={data}
                confirmAction={{
                    visible: false,
                }}
                rejectAction={{
                    callback: onCancelBookings,
                }}
                emptyMessage='Once you start receiving bookings, you can manage travelers here.'
            />
        </Grid>
    );
};

export default WaitlistedTable;
