import { useSelector } from 'state/hooks';

export interface LockedStates {
    tierLocked: boolean;
    incompleteProfile: boolean;
}

const useHasAccountManager = (): boolean => {
    const { current: currentUser } = useSelector((state) => state.profile);

    const hasAccountManager = !!currentUser?.accountManager;
    return hasAccountManager;
};

export default useHasAccountManager;
