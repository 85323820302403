import FetchState from './utils/FetchState';
import { models } from '@trova-trip/trova-models';

type TripRequest = models.tripRequest.TripRequest;

const userTripRequests = FetchState.create(`api/me/trip-requests`);

const userTripRequestsReducer = userTripRequests.getReducer();

export type UserTripRequestStoreSelector = {
    userTripRequests: {
        current: TripRequest;
        list?: { records?: TripRequest[] };
    };
};

export { userTripRequests, userTripRequestsReducer };
