import { Label, Stack, Text } from '@trova-trip/trova-components';
import { NumberInput } from '@trova-trip/trova-components/build/next';
import capitalize from 'lodash/capitalize';
import noop from 'lodash/noop';

type HoursType = 'available' | 'requested';

interface WorkshopHoursFieldProps {
    type: HoursType;
    value?: number;
}

const WorkshopHoursField = ({ value, type }: WorkshopHoursFieldProps) => {
    const capitalizedType = capitalize(type);

    const label = `No. Hours ${capitalizedType}`;
    const fieldName = `hours${capitalizedType}ReadOnly`;

    return (
        <Stack direction='column' spacing={2} width='full' align='search'>
            <Label
                id={`field-${fieldName}-label`}
                htmlFor={`field-${fieldName}`}
            >
                <Text fontSize='sm' fontWeight='medium' color='blueGray.dark'>
                    {label}
                </Text>
            </Label>
            <NumberInput
                isReadOnly
                name={fieldName}
                value={value}
                onChange={noop}
            />
        </Stack>
    );
};

export default WorkshopHoursField;
