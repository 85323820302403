import { matchPath, useLocation } from 'react-router-dom';
import {
    isTabComplete,
    isTransferCompleteStatus,
} from '../../../../../state/features/transfer/utils';
import { useSelector } from '../../../../../state/hooks';
import {
    getTransferPath,
    isHost,
    isTraveler,
    shouldEnableTransferFlowForUser,
} from '../../../helpers';
import useTripRootPathByUserGroup from '../../../hooks/trips/useTripRootPathByUserGroup';
import useNavigateToTransfer from '../../../hooks/useNavigateToTransfer';
import { useNavigate } from '../../../hooks/useNavigation';
import { TransferTabName, paths } from '../config';
import useTransfer from './useTransfer';

type UseTransferTabValidationReturn = {
    validateNavigation: () => void;
    redirectOnMount: () => void;
    isTabActive: (tabName: TransferTabName) => boolean;
    isTabEnabled: (tabName: TransferTabName) => boolean;
    activeTabName: TransferTabName;
};

const useTransferTabValidation = (): UseTransferTabValidationReturn => {
    const {
        globalState: { user, trip, booking, traveler },
        state: {
            metadata: { tabs, status },
        },
    } = useTransfer();

    const { itinerary } = useSelector((state) => ({
        itinerary: state.userItinerary.current,
    }));

    const location = useLocation();

    const tripRootPath = useTripRootPathByUserGroup({
        group: user?.group,
        tripId: trip.id,
    });

    const navigateToRootPath = useNavigate(tripRootPath, {
        preserveCurrentPath: false,
    });

    const navigateTo = useNavigateToTransfer();

    const isHostUser = isHost(user);
    const isTravelerUser = isTraveler(user);

    const isTabActive = (tabName: TransferTabName): boolean => {
        if (!trip.id || !user?.group) {
            return false;
        }
        const path = getTransferPath(trip.id, user?.group, tabName);

        return !!matchPath(path, location.pathname);
    };

    const isTabEnabled = (tabName: TransferTabName): boolean => {
        const isVehicleTabEnabled =
            isTabComplete(tabs.travelDetail.status) && isTravelerUser;

        const isContactTabEnabled = isTabComplete(tabs.vehicle.status);

        const isPaymentTabEnabled =
            isTabComplete(tabs.contact.status) && isTravelerUser;

        const isSuccessTabEnabled = isTransferCompleteStatus(status);

        const tabStatusMap = {
            travel_detail: true,
            vehicle: isVehicleTabEnabled,
            contact: isContactTabEnabled,
            payment: isPaymentTabEnabled,
            success: isSuccessTabEnabled,
        };

        return tabStatusMap[tabName];
    };

    const getActiveTabName = (): TransferTabName => {
        const tabNames = Object.keys(paths.tab) as TransferTabName[];

        const activeTabName = tabNames.find((tabName) => {
            return isTabActive(tabName);
        });

        return activeTabName || 'root';
    };

    const activeTabName = getActiveTabName();

    const redirectOnMount = (): void => {
        const transferFlowIsEnabledForUser = shouldEnableTransferFlowForUser({
            itinerary,
            trip,
            booking,
            traveler,
        }).assertion;

        if (!transferFlowIsEnabledForUser) {
            return navigateToRootPath();
        }
    };

    const validateNavigation = () => {
        if (isTabEnabled(activeTabName)) {
            return;
        }

        if (activeTabName === 'success') {
            return navigateToRootPath();
        }

        if (
            isHostUser &&
            (activeTabName === 'vehicle' || activeTabName === 'payment')
        ) {
            const tabToNavigateTo = isTabComplete(tabs.travelDetail.status)
                ? activeTabName
                : 'root';

            return navigateTo(tabToNavigateTo);
        }

        return navigateTo('root');
    };

    return {
        validateNavigation,
        redirectOnMount,
        isTabActive,
        isTabEnabled,
        activeTabName,
    };
};

export default useTransferTabValidation;
