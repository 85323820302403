import {
    AccordionHeaderProps,
    BaseBoxProps,
    Stack,
} from '@trova-trip/trova-components';
import {
    Button,
    Card,
    MainContent,
} from '@trova-trip/trova-components/build/next';
import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import useIsLargeScreen from '../../hooks/useIsLargeScreen';
import MobileFloatingAccordion, {
    MobileFloatingAccordionPosition,
} from '../MobileFloatingAccordion';

interface BackButtonProps {
    path: string;
    description: string;
}

interface MobileAccordionProps {
    header: Pick<AccordionHeaderProps, 'title' | 'rightElement'>;
    position?: MobileFloatingAccordionPosition;
    padding?: BaseBoxProps['padding'];
}

interface PageCardProps {
    children: React.ReactNode;
    sidebarContent?: React.ReactNode;
    mobileProps?: {
        accordion: MobileAccordionProps;
    };
    navigateBackTo?: BackButtonProps;
    topRightAddOn?: ReactNode;
    marginTop?: BaseBoxProps['marginTop'];
    padding?: BaseBoxProps['padding'];
    sidebarPadding?: number;
    showMobileTopBar?: boolean;
}

interface MobilePageCardProps
    extends Pick<
        PageCardProps,
        | 'children'
        | 'marginTop'
        | 'padding'
        | 'navigateBackTo'
        | 'topRightAddOn'
    > {
    panel?: React.ReactNode;
    accordionProps?: MobileAccordionProps;
}

interface TopBarProps
    extends Pick<PageCardProps, 'navigateBackTo' | 'topRightAddOn'> {}

const MobilePageCard = (props: MobilePageCardProps): JSX.Element => {
    const {
        children,
        accordionProps,
        panel,
        padding,
        marginTop = 3,
        navigateBackTo,
        topRightAddOn,
    } = props;

    const showAccordion = panel && accordionProps;

    return (
        <Card marginTop={marginTop} padding={padding}>
            {navigateBackTo ? (
                <TopBar
                    navigateBackTo={navigateBackTo}
                    topRightAddOn={topRightAddOn}
                />
            ) : null}

            {showAccordion ? (
                <MobileFloatingAccordion panel={panel} {...accordionProps} />
            ) : null}

            <Stack direction='column' flex={2}>
                {children}
            </Stack>
        </Card>
    );
};

const Sidebar = ({ children, padding = 6 }) => (
    <Stack
        background='blueGray.offWhite'
        direction='column'
        borderRadius='xl'
        align='stretch'
        wrap='nowrap'
        padding={padding}
        flex={1}
    >
        {children}
    </Stack>
);

const BackButton = ({ path, description }: BackButtonProps): JSX.Element => {
    const history = useHistory();

    return (
        <Button
            variant='tertiary'
            leftIcon='arrow-left'
            color='blueGray.650'
            onClick={() => history.push(path)}
        >
            {description}
        </Button>
    );
};

const TopBar = ({ navigateBackTo, topRightAddOn }: TopBarProps) => {
    if (!navigateBackTo && !topRightAddOn) {
        return null;
    }

    return (
        <Stack
            spacing={0}
            justify='space-between'
            align='center'
            marginBottom={4}
        >
            {navigateBackTo ? (
                <BackButton
                    path={navigateBackTo.path}
                    description={navigateBackTo.description}
                />
            ) : null}
            {topRightAddOn}
        </Stack>
    );
};

const PageCard = (props: PageCardProps): JSX.Element => {
    const {
        children,
        sidebarContent,
        mobileProps,
        navigateBackTo,
        topRightAddOn,
        padding,
        marginTop,
        sidebarPadding,
        showMobileTopBar = false,
    } = props;

    const isDesktop = useIsLargeScreen();

    if (!isDesktop) {
        return (
            <MobilePageCard
                accordionProps={mobileProps?.accordion}
                panel={sidebarContent}
                marginTop={marginTop}
                padding={padding}
                navigateBackTo={showMobileTopBar ? navigateBackTo : undefined}
                topRightAddOn={showMobileTopBar ? topRightAddOn : undefined}
            >
                {children}
            </MobilePageCard>
        );
    }

    return (
        <Card marginTop={marginTop} padding={padding}>
            <TopBar
                navigateBackTo={navigateBackTo}
                topRightAddOn={topRightAddOn}
            />
            <MainContent.Inner
                sidebarElement={
                    sidebarContent ? (
                        <Sidebar padding={sidebarPadding}>
                            {sidebarContent}
                        </Sidebar>
                    ) : null
                }
                sidebarConfig={{
                    sidebarColumnWidth: 288,
                }}
            >
                <Stack direction='column' flex={2}>
                    {children}
                </Stack>
            </MainContent.Inner>
        </Card>
    );
};

export default PageCard;
