import { constants, models } from '@trova-trip/trova-models';
import { ApiResponse, BookingRepriceUpdate } from '../../../apis/types';
import { Action } from '../../store/types';

// --------------------------------------------
// CORE TYPES:
// --------------------------------------------

type Quote = models.groundTransfers.Quote;

type QuoteApiRequest = models.groundTransfers.GroundTransferQuoteRequest;

type QuotesApiRequest = models.groundTransfers.GroundTransfersQuotesRequest;
type QuotesApiResponse =
    ApiResponse<models.groundTransfers.GroundTransfersQuotesResponse>;

type BookRequestQuote = models.groundTransfers.BookRequestQuote;
type BookApiRequest = models.groundTransfers.GroundTransfersBookRequest;
type BookApiResponse =
    ApiResponse<models.groundTransfers.GroundTransfersBookResponse>;

type GroundTransferApiResponse =
    ApiResponse<models.groundTransfers.SavedGroundTransfer>;
type GroundTransferAddOn = models.groundTransfers.GroundTransferAddOn;
type GroundTransferBookingStatus = constants.groundTransfers.GroundTransferBookingStatus;

type AddOn = models.bookings.AddOn;
type BaseUser = models.users.BaseUser;
type InsurancePolicyAddOn = models.insurance.InsurancePolicyAddOn;
type AddressLocation = models.addresses.AddressLocation;
type Journey = models.groundTransfers.Journey;
type JourneyStatus = models.groundTransfers.Journey['status'];
type SavedBooking = models.bookings.SavedBooking;
type SavedGroundTransfer = models.groundTransfers.SavedGroundTransfer;
type Trip = models.trips.Trip;
type User = models.users.User;
type TravelerType = constants.travelers.TravelerType;
type UserTripsTraveler = models.travelers.TravelerDTO & {
    type: TravelerType;
};
type Traveler = models.groundTransfers.Traveler;
type GroundTransferCancelRequest =
    models.groundTransfers.GroundTransferCancelRequest;
type UpdateGroundTransfersRequest =
    models.groundTransfers.UpdateGroundTransferParams;

type BookerInfo = Omit<Traveler, 'flightNumber'>;

const CancellationType = constants.groundTransfers.CancellationType;

// --------------------------------------------
// API TYPES:
// --------------------------------------------

type RepriceInsuranceApiRequest = {
    bookingId: string;
    travelAmount: BookingRepriceUpdate['travelAmount'];
    additionalParticipants: BookingRepriceUpdate['additionalParticipants'];
    addOns: BookingRepriceUpdate['addOns'];
    application: 'admin' | 'traveler';
};

type GetEstimatedDurationRequest = {
    [key in JourneyStage]?: {
        origin: google.maps.Place;
        destination: google.maps.Place;
    };
};

type EstimatedDurationByStage = {
    [key in JourneyStage]: {
        estimatedDuration: string;
    };
};

type GetEstimatedDurationResponse = ApiResponse<EstimatedDurationByStage>;

type GroundTransfersOperationsCommonApiRequest = {
    groundTransferId: string;
};

type CancelGroundTransferApiRequest =
    GroundTransfersOperationsCommonApiRequest & {
        cancelRequest?: GroundTransferCancelRequest;
    };

type UpdateGroundTransferApiRequest =
    GroundTransfersOperationsCommonApiRequest & {
        updateRequest: UpdateGroundTransfersRequest;
    };

type GroundTransfersOperationsApiResponse = ApiResponse<SavedGroundTransfer>;

// -------------------------------------------
// BOOKED TRANSFER TYPES:
//--------------------------------------------

type BookedGroundTransferJourney = {
    id: Journey['_id'];
    code: Journey['code'];
    type: Journey['type'];
    origin: Journey['origin'];
    destination: Journey['destination'];
    date: Date;
    status: GroundTransferBookingStatus;
    cancellationDetails: Journey['cancellationDetails'];
    cancellationType: SavedGroundTransfer['cancellationType'];
    price: number;
    passengers: number;
    transferCompany?: Journey['transferCompany'];
    driver?: Journey['driver'];
    luggage: number;
    traveler: Journey['traveler'];
    vehicleCategory: Journey['vehicleCategory'];
};

type BookedTransferJourneysByStage = {
    [key in JourneyStage]?: BookedGroundTransferJourney[];
};

// -------------------------------------------
// VIEW MODEL TYPES:
//--------------------------------------------

type Location = {
    /**
     * This ID comes from an API response, such as Google API.
     */
    id: string;

    name: string;
    description: string;

    /**
     * The types also comes from an API response.
     */
    types: string[];
};

type JourneyStage = 'arrival' | 'departure';

type QuotesByStage = {
    stage: JourneyStage;
    quotes: Quote[];
};

type Luggage = {
    hand?: number;
    checked?: number;
};

/**
 * Explanation of what each status represents:
 *
 * - `empty`: initial status and when there is no data
 * - `populated`: status after data has been fetched successfully
 * - `loading`: status when there is fetching happening
 * - `stale`: status when the data is old and a refetch is required
 * - `need-retry`: status when an error occurs and it should retry to fetch
 * - `error`: status when all the attempts of the "need-retry" status failed.
 */
type QuoteStatus =
    | 'empty'
    | 'populated'
    | 'loading'
    | 'stale'
    | 'need-retry'
    | 'error';

/**
 * Explanation of what each status represents:
 *
 * - `missing-travel-details`: initial status when there is no travel details data
 * - `missing-quote-selection`: status when the travel details data is complete except the selected quote
 * - `complete`: status when all the data is complete
 */
type TransferDetailStatus =
    | 'missing-travel-details'
    | 'missing-quote-selection'
    | 'complete';

/**
 * Represents the core details related to a transfer.
 */
type TravelDetail = {
    /**
     * Where the person is going to be picked up by
     * the vehicle.
     */
    pickup?: Location;
    pickupDateTime?: Date;

    /**
     * Where the person is going to be dropped off by
     * the vehicle.
     */
    dropOff?: Location;
    /**
     * The estimated duration of the journey.
     */
    estimatedDuration?: string;
    flightNumber?: string;
    passengers?: number;
    luggage?: Luggage;
};

/**
 * Represents detailed information about a transfer, including the core properties
 * from `TravelDetail` and the available vehicle quotes, user-selected quote,
 * and metadata related to quotes and overall data status.
 */
type TransferDetail = TravelDetail & {
    /**
     * The available vehicles to offer to the user to
     * select.
     */
    quotes: Quote[];

    /**
     * The selected vehicle by the user.
     */
    selectedQuote?: Quote;

    /**
     * Metadata related to the quotes such as the
     * current status of them and how many api calls
     * occurred with success & error.
     */
    quotesMetadata: {
        status: QuoteStatus;
        apiErrorCount: number;
        apiSuccessCount: number;
    };

    metadata: {
        status: TransferDetailStatus;
    };
};

type DetailOptionalProperties = 'luggage' | 'estimatedDuration';

type TravelDetailProperties = keyof TravelDetail;
type RequiredTravelDetailsProperties = Exclude<
    TravelDetailProperties,
    DetailOptionalProperties
>;

type TransferDetailProperties = keyof TransferDetail;
type RequiredTransferDetailProperties = Exclude<
    TransferDetailProperties,
    DetailOptionalProperties
>;

type RequiredTravelDetail = TravelDetail &
    Required<Pick<TravelDetail, RequiredTravelDetailsProperties>>;
type RequiredTransferDetail = TransferDetail &
    Required<Pick<TransferDetail, RequiredTransferDetailProperties>>;

type ContactDetail = {
    firstName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
    termsAndConditions?: boolean;
};

/**
 * Explanation of what each status represents:
 *
 * - `idle`: status when the payment hasn't started yet
 * - `in-progress`: status when the payment is in progress
 * - `payment_successful`: status after the payment is successfully made
 * - `payment_error`: error after the payment went wrong
 */
type PaymentStatus =
    | 'idle'
    | 'in-progress'
    | 'payment_successful'
    | 'payment_error';

type PaymentDetail = {
    status: PaymentStatus;
    insurance: InsuranceDetail;
};

/**
 * Details about the payment + the computed transfer total
 * cost based on the state.
 */
type ComputedPaymentDetail = PaymentDetail & {
    total: number;
};

/**
 * Explanation of what each status represents:
 *
 * - `empty`: status when the insurance hasn't been repriced yet
 * - `repriced`: status when the insurance has been repriced
 * - `stale`: status when the insurance has been repriced but the transfer details have changed
 */
type InsuranceStatus = 'empty' | 'repriced' | 'stale';

type InsuranceDetail = {
    adjustment: number;
    repricedAddOn?: InsurancePolicyAddOn;
    status: InsuranceStatus;
};

/**
 * Explanation of what each status represents:
 *
 * - `complete`: status when the tab required information is completed
 * - `incomplete`: status when some tab required information is missing
 */
type TabStatus = 'complete' | 'incomplete';

type TabMetadata = {
    status: TabStatus;
};

/**
 * Explanation of what each error type represents:
 *
 * - `payment`: error that occurs when the user tries to pay the transfer amount.
 * - `booking`: error that occurs when the user tries to create the transfer booking.
 * - `insurance-repricing`: error that occurs when the app tries to reprice the insurance.
 * - `cancel`: error that occurs when the user tries to cancel the transfer.
 */
type TransferErrorType =
    | 'payment'
    | 'booking'
    | 'insurance-repricing'
    | 'cancel'
    | 'update';

type TransferError = {
    type: TransferErrorType;
    message: string;
};

/**
 * Explanation of what each reason represents:
 *
 * - `ground-transfer-not-bookable`: check the function `isGroundTransferBookable()` to know all the conditions for this reason.
 * - `admin-technical-itinerary-not-approved`: the trip property `adminTechnicalItineraryApproved` is `false`.
 * - `trip-is-complete`: the trip status is `complete`.
 * - `booking-has-pending-refund`: the booking has a pending refund, meaning that it's `dueAmount` is lower than 0.
 * - `booking-is-cancelled`: the booking status is `cancelled`.
 */
type TransferNotAvailableReason =
    | 'ground-transfer-not-bookable'
    | 'admin-technical-itinerary-not-approved'
    | 'trip-is-complete'
    | 'booking-has-pending-refund'
    | 'booking-is-cancelled';

/**
 * Explanation of what each status represents:
 *
 * - `started`: status when the user just started the transfer flow without any interaction yet
 * - `in-progress`: status when the transfer flow is in progress
 * - `saving`: status when the transfer is being saved and booked
 * - `updating`: status when the transfer is being updated
 * - `updated`: status when the transfer was successfully updated
 * - `repricing-insurance`: status when the insurance is being repriced
 * - `complete`: status when the transfer was successfully created
 * - `cancelling`: status when the transfer is being cancelled
 * - `cancelled`: status when the transfer was successfully cancelled
 * - `error`: status when the transfer couldn't be created due to some error
 */
type TransferStatus =
    | 'started'
    | 'in-progress'
    | 'saving'
    | 'updating'
    | 'updated'
    | 'repricing-insurance'
    | 'complete'
    | 'cancelling'
    | 'cancelled'
    | 'error';

type TransferViewModel = {
    arrival: TransferDetail;
    departure: TransferDetail;
    contact: ContactDetail;
    payment: PaymentDetail;
    metadata: {
        status: TransferStatus;
        error?: TransferError;
        tabs: {
            travelDetail: TabMetadata;
            vehicle: TabMetadata;
            contact: TabMetadata;
            payment: TabMetadata;
        };
    };
};

// --------------------------------------------
// ACTIONS TYPES:
// --------------------------------------------

type SetTransferDetailPayload = { data: TravelDetail; stage: JourneyStage };
type SetTransferDetailAction = Action<
    'set_transfer_detail',
    SetTransferDetailPayload
>;

type SetContactActionPayload = { data: ContactDetail };
type SetContactAction = Action<'set_contact', SetContactActionPayload>;

type SetEstimatedDurationPayload = {
    stage: JourneyStage;
    estimatedDuration?: string;
};
type SetEstimatedDurationAction = Action<
    'set_estimated_duration',
    SetEstimatedDurationPayload
>;

type SelectQuoteActionPayload = { quote: Quote; stage: JourneyStage };
type SelectQuoteAction = Action<'select_quote', SelectQuoteActionPayload>;

type SetPaymentActionPayload = {
    data: Pick<PaymentDetail, 'status'> & {
        errorMessage?: string;
    };
};
type SetPaymentAction = Action<'set_payment', SetPaymentActionPayload>;

type FetchQuotesActionPayload = {
    arrival?: RequiredTravelDetail;
    departure?: RequiredTravelDetail;
};
type FetchQuotesAction = Action<'fetch_quotes', FetchQuotesActionPayload>;

type ApiQuotesResponseActionPayload = {
    quotesByStages: QuotesByStage[];
    isError?: boolean;
};

type ApiQuotesResponseAction = Action<
    'api_quotes_response',
    ApiQuotesResponseActionPayload
>;

type SaveTransferAction = Action<'save_transfer'>;

type ApiSaveTransferResponseActionPayload = {
    success: boolean;
    errorMessage?: string;
};
type ApiSaveTransferResponseAction = Action<
    'api_save_transfer_response',
    ApiSaveTransferResponseActionPayload
>;

type GroundTransfersOperation = 'book' | 'update' | 'cancel';

type GroundTransferOperationType<T extends GroundTransfersOperation> = {
    operation: T;
};

type RepriceInsuranceBookPayload = GroundTransferOperationType<'book'> & {
    booking: SavedBooking | undefined;
    total: number;
};

type RepriceInsuranceCancelPayload = GroundTransferOperationType<'cancel'> & {
    booking: SavedBooking | undefined;
    journeyId: string;
};

type RepriceInsuranceActionPayload =
    | RepriceInsuranceBookPayload
    | RepriceInsuranceCancelPayload;
type RepriceInsuranceAction = Action<'reprice_insurance'>;

type ApiRepriceInsuranceResponseActionPayload = {
    insurance: Pick<InsuranceDetail, 'adjustment' | 'repricedAddOn'>;
    errorMessage?: string;
};
type ApiRepriceInsuranceResponseAction = Action<
    'api_reprice_insurance_response',
    ApiRepriceInsuranceResponseActionPayload
>;

type CancelTransferAction = Action<'cancel_transfer'>;

type UpdateTransferAction = Action<'update_transfer'>;

type ApiTransferOperationResponseActionPayload = {
    operation: GroundTransfersOperation;
    success: boolean;
    errorMessage?: string;
};

type ApiTransferOperationsResponseAction = Action<
    'api_transfer_operations_response',
    ApiTransferOperationResponseActionPayload
>;

type ResetStateAction = Action<'reset_state'>;

type TransferAction =
    | SetTransferDetailAction
    | SetContactAction
    | SetEstimatedDurationAction
    | FetchQuotesAction
    | ApiQuotesResponseAction
    | SelectQuoteAction
    | SetPaymentAction
    | SaveTransferAction
    | ApiSaveTransferResponseAction
    | RepriceInsuranceAction
    | ApiRepriceInsuranceResponseAction
    | CancelTransferAction
    | UpdateTransferAction
    | ApiTransferOperationsResponseAction
    | ResetStateAction;

export { CancellationType };

export type {
    AddOn,
    AddressLocation,
    ApiQuotesResponseAction,
    ApiQuotesResponseActionPayload,
    ApiRepriceInsuranceResponseAction,
    ApiRepriceInsuranceResponseActionPayload,
    ApiSaveTransferResponseAction,
    ApiSaveTransferResponseActionPayload,
    ApiTransferOperationResponseActionPayload,
    ApiTransferOperationsResponseAction,
    BaseUser,
    BookApiRequest,
    BookApiResponse,
    BookRequestQuote,
    BookedGroundTransferJourney,
    BookedTransferJourneysByStage,
    BookerInfo,
    CancelGroundTransferApiRequest,
    CancelTransferAction,
    ComputedPaymentDetail,
    ContactDetail,
    DetailOptionalProperties,
    EstimatedDurationByStage,
    FetchQuotesAction,
    FetchQuotesActionPayload,
    GetEstimatedDurationRequest,
    GetEstimatedDurationResponse,
    GroundTransferAddOn,
    GroundTransferApiResponse,
    GroundTransfersOperation,
    GroundTransfersOperationsApiResponse,
    InsuranceDetail,
    InsurancePolicyAddOn,
    InsuranceStatus,
    Journey,
    JourneyStage,
    JourneyStatus,
    Location,
    Luggage,
    PaymentDetail,
    PaymentStatus,
    Quote,
    QuoteApiRequest,
    QuoteStatus,
    QuotesApiRequest,
    QuotesApiResponse,
    QuotesByStage,
    RepriceInsuranceActionPayload,
    RepriceInsuranceApiRequest,
    RequiredTransferDetail,
    RequiredTravelDetail,
    SaveTransferAction,
    SavedBooking,
    SelectQuoteAction,
    SelectQuoteActionPayload,
    SetContactAction,
    SetContactActionPayload,
    SetEstimatedDurationPayload,
    SetPaymentAction,
    SetPaymentActionPayload,
    SetTransferDetailAction,
    SetTransferDetailPayload,
    TabMetadata,
    TabStatus,
    TransferAction,
    TransferDetail,
    TransferDetailStatus,
    TransferError,
    TransferErrorType,
    TransferNotAvailableReason,
    TransferStatus,
    TransferViewModel,
    TravelDetail,
    Traveler,
    TravelerType,
    Trip,
    UpdateGroundTransferApiRequest,
    User,
    UserTripsTraveler,
};
