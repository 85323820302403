import React from 'react';
import PropTypes from 'prop-types';
import {
    Input,
    ComponentWidth,
    Cell,
    IconButton,
    Stack,
} from '@trova-trip/trova-components';

const CostThresholdsCell = ({
    index,
    numberOfTravelers,
    deleteTier,
    updateHandler,
}) => {
    return (
        <Cell>
            <Stack spacing={4} align='center' direction='row' wrap='nowrap'>
                <Input
                    name={`costThresholds[${index}].numberOfTravelers`}
                    size={ComponentWidth.ExtraSmall}
                    value={numberOfTravelers}
                    onChange={updateHandler}
                />
                <IconButton
                    icon='trash'
                    iconColor='red.coral'
                    iconVariant='filled'
                    variant='outlined'
                    onClick={() => deleteTier(index)}
                />
            </Stack>
        </Cell>
    );
};

CostThresholdsCell.propTypes = {
    index: PropTypes.number,
    numberOfTravelers: PropTypes.number,
    deleteTier: PropTypes.func,
    updateHandler: PropTypes.func,
};

export default CostThresholdsCell;
