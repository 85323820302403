import { useEffect } from 'react';
import { Route, useHistory, Switch } from 'react-router-dom';
import NavigationContext from './NavigationContext';
import { TabContext } from './Navigation.types';

interface TabProps {
    children?: React.ReactNode;
    path: string;
    label: string;
    hideNavigation?: boolean;
    disabled?: boolean;
}

interface ConnectedTabProps extends TabProps {
    context: TabContext;
}

const TabContent = ({ children }): JSX.Element => children || null;

const ConnectedTab = ({
    context: { addTab, removeTab, productPath },
    children,
    path,
    label,
    hideNavigation,
    disabled = false,
}: ConnectedTabProps): JSX.Element => {
    const history = useHistory();
    const {
        location: { pathname },
    } = history;

    useEffect(() => {
        if (!hideNavigation) {
            addTab({ path, label, disabled });
        } else {
            removeTab(path);
        }
    }, [path, label, addTab, hideNavigation, disabled]);

    return (
        <>
            <Switch>
                <Route
                    path={`${productPath}${path}`}
                    exact={true}
                    render={(): JSX.Element => {
                        return (
                            <TabContent key={pathname}>{children}</TabContent>
                        );
                    }}
                />
                <Route
                    path={`${productPath}/new${path}`}
                    exact={true}
                    render={(): JSX.Element => {
                        return (
                            <TabContent key={pathname}>{children}</TabContent>
                        );
                    }}
                />
                <Route
                    path={`${productPath}/:id${path}`}
                    render={(): JSX.Element => {
                        return (
                            <TabContent key={pathname}>{children}</TabContent>
                        );
                    }}
                />
            </Switch>
        </>
    );
};

const Tab = (props: TabProps): JSX.Element => (
    <NavigationContext.Consumer>
        {(context): JSX.Element => (
            <ConnectedTab {...props} context={context} />
        )}
    </NavigationContext.Consumer>
);

export default Tab;
