import { useTrackingEvent } from '../../../../analytics/hooks';
import { useSelector } from '../../../../state/hooks';
import {
    CreateItineraryInventoryItemInput,
    ItineraryInventoryItem,
} from '../ReviewPricing/Itinerary/ItineraryInventoryForm/types';

interface UseAnalyticsReturn {
    trackStartItineraryInventoryCreation: () => void;
    trackStartItineraryInventoryEdition: (
        itineraryInventoryItem: ItineraryInventoryItem,
    ) => void;
    trackDeleteItineraryInventory: (
        itineraryInventoryItem: ItineraryInventoryItem,
    ) => void;
    trackEditItineraryInventory: (
        itineraryInventoryItem: ItineraryInventoryItem,
    ) => void;
    trackCreateItineraryInventory: (
        itineraryInventoryItemPayload: ItineraryInventoryItem,
    ) => void;
    trackCreateItineraryInventoryError: (
        payload: CreateItineraryInventoryItemInput,
        errorMessage: string,
    ) => void;
}

const EventName = {
    START_ITINERARY_INVENTORY_CREATION:
        'Operator Started to Create an Itinerary Inventory',
    START_ITINERARY_INVENTORY_EDITION:
        'Operator Started to Edit Itinerary Inventory',
    DELETE_ITINERARY_INVENTORY: 'Operator Deleted Itinerary Inventory',
    CREATE_ITINERARY_INVENTORY: 'Operator Created Itinerary Inventory',
    EDIT_ITINERARY_INVENTORY: 'Operator Edited Itinerary Inventory',
    CREATE_INVENTORY_ERROR:
        'Operator Was Errored When Trying to Create Itinerary Inventory',
} as const;

const useAnalytics = (): UseAnalyticsReturn => {
    const { itinerary } = useSelector((state) => ({
        itinerary: state.userItinerary.current,
    }));
    const { trackUserEvent } = useTrackingEvent();

    const { id: itineraryId } = itinerary || {};

    const trackStartItineraryInventoryCreation = (): void => {
        trackUserEvent({
            eventName: EventName.START_ITINERARY_INVENTORY_CREATION,
            properties: {
                itineraryId,
            },
        });
    };

    const trackStartItineraryInventoryEdition = (
        itineraryInventoryItem: ItineraryInventoryItem,
    ): void => {
        trackUserEvent({
            eventName: EventName.START_ITINERARY_INVENTORY_EDITION,
            properties: {
                itineraryId,
                itineraryInventoryAttributes: itineraryInventoryItem,
            },
        });
    };

    const trackDeleteItineraryInventory = (
        itineraryInventoryItem: ItineraryInventoryItem,
    ): void => {
        trackUserEvent({
            eventName: EventName.DELETE_ITINERARY_INVENTORY,
            properties: {
                itineraryId,
                itineraryInventoryAttributes: itineraryInventoryItem,
            },
        });
    };

    const trackCreateItineraryInventory = (
        itineraryInventoryItem: ItineraryInventoryItem,
    ): void => {
        trackUserEvent({
            eventName: EventName.CREATE_ITINERARY_INVENTORY,
            properties: {
                itineraryId,
                itineraryInventoryAttributes: itineraryInventoryItem,
            },
        });
    };

    const trackEditItineraryInventory = (
        itineraryInventoryItem: ItineraryInventoryItem,
    ): void => {
        trackUserEvent({
            eventName: EventName.EDIT_ITINERARY_INVENTORY,
            properties: {
                itineraryId,
                itineraryInventoryAttributes: itineraryInventoryItem,
            },
        });
    };

    const trackCreateItineraryInventoryError = (
        itineraryInventoryItem: CreateItineraryInventoryItemInput,
        errorMessage: string,
    ): void => {
        trackUserEvent({
            eventName: EventName.CREATE_INVENTORY_ERROR,
            properties: {
                itineraryId,
                errorMessage,
                itineraryInventoryAttributes: itineraryInventoryItem,
            },
        });
    };

    return {
        trackStartItineraryInventoryCreation,
        trackDeleteItineraryInventory,
        trackCreateItineraryInventory,
        trackEditItineraryInventory,
        trackCreateItineraryInventoryError,
        trackStartItineraryInventoryEdition,
    };
};

export default useAnalytics;
