import { constants, models } from '@trova-trip/trova-models';
import { Card, theme } from '@trova-trip/trova-components';
import ServiceCard from 'applications/common/components/services/ServiceCard';
import TripServiceForm from 'applications/host/components/TripServiceForm/TripServiceForm';
import { styledCard } from './TripServicesList.styles';
import { getServiceTitleLabel } from '../../../common/components/services/LibraryServiceFormatter';

const { ServiceType } = constants.services;
type Service = models.services.Service;
type WorkshopSpace = models.services.WorkshopSpace;

interface TripServicesListItemProps {
    service: Service;
    handleOpen: ({ serviceId }: { serviceId?: string }) => void;
    handleSubmit: (service: Service) => void;
    handleDelete: (service: Service) => void;
    handleCancel: (service: Service) => void;
    readOnly: boolean;
    isEditing?: boolean;
    serviceDayIndex?: string;
}

const TripServicesListItem = (
    props: TripServicesListItemProps,
): JSX.Element => {
    const {
        service,
        readOnly,
        isEditing,
        handleOpen,
        handleSubmit,
        handleDelete,
        handleCancel,
        serviceDayIndex,
    } = props;

    const cardTitle = getServiceTitleLabel(service.type);
    const isGathering = service.type === ServiceType.INFORMAL_GATHERING;

    const shouldBeCollapsed = (
        service: models.services.Service,
        editing: boolean | undefined,
    ): boolean => {
        if ((service as WorkshopSpace).acceptedByHost === false) return false;
        else if (editing) return false;
        else return true;
    };

    return (
        <Card
            className={styledCard}
            backgroundColor={theme.colors.neutral.white}
            key={service._id}
        >
            {!readOnly ? (
                <ServiceCard
                    title={cardTitle}
                    service={service}
                    collapsed={shouldBeCollapsed(service, isEditing)}
                    actionsBarConfig={{
                        remove: {
                            shouldRender: isGathering,
                            handler: handleDelete,
                        },
                        toggleChildren: {
                            shouldRender: true,
                            handler: handleOpen,
                        },
                    }}
                >
                    <TripServiceForm
                        service={service}
                        serviceDayIndex={serviceDayIndex}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                    />
                </ServiceCard>
            ) : (
                <ServiceCard title={cardTitle} service={service} />
            )}
        </Card>
    );
};

export default TripServicesListItem;
