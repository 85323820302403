import { Stack, Text } from '@trova-trip/trova-components';
import React from 'react';

interface FormControlProps {
    label: React.ReactNode;
    field: React.ReactNode;
}

const FormControl = ({ label, field }: FormControlProps): JSX.Element => (
    <Stack direction='column' align='stretch' spacing={2}>
        <Text as='span' fontWeight='medium'>
            {label}
        </Text>
        {field}
    </Stack>
);

export default FormControl;
