import { models } from '@trova-trip/trova-models';
import {
    DefaultProps,
    trackCancelGroundTransfersBookingEvent,
    trackUpdateGroundTransfersContactInformationEvent,
} from '../utils/analytics.utils';
import useTransfer from './useTransfer';

type Itinerary = models.itineraries.Itinerary;

const useTransferOperationsAnalytics = () => {
    const {
        globalState: { trip, booking, user },
    } = useTransfer();

    const defaultProps: DefaultProps = {
        tripId: trip.id,
        tripName: trip.name,
        bookingId: booking?._id,
        userId: user.id,
        userGroup: user.group,
        itineraryId: (trip.itinerary as Itinerary)?.id,
    };

    const withDefaultProps = <T extends DefaultProps, R>(
        originalFn: (props: T) => R,
    ) => {
        return (extraProps: Omit<T, keyof DefaultProps>) => {
            const props = { ...defaultProps, ...extraProps } as T;
            return originalFn(props);
        };
    };

    return {
        trackCancelGroundTransfersBookingEvent: withDefaultProps(
            trackCancelGroundTransfersBookingEvent,
        ),
        trackUpdateGroundTransfersContactInformationEvent: withDefaultProps(
            trackUpdateGroundTransfersContactInformationEvent,
        ),
    };
};

export default useTransferOperationsAnalytics;
