import { BaseBoxProps } from '@trova-trip/trova-components';
import { Button, ButtonProps } from '@trova-trip/trova-components/build/next';
import StickyContentBase from '../../../host/components/StickyContent/StickyContentBase';

interface StickyContentExternalNavProps {
    title?: string;
    description?: string;
    routeToUrl: string;
    buttonProps?: ButtonProps;
    marginTop?: BaseBoxProps['marginTop'];
}

const StickyContentExternalNav = (
    props: StickyContentExternalNavProps,
): JSX.Element => {
    const { description, title, buttonProps, routeToUrl, marginTop } = props;
    const { children: buttonText } = buttonProps || {};

    return (
        <StickyContentBase
            title={title}
            description={description}
            marginTop={marginTop}
            action={
                <Button
                    as='a'
                    linkProps={{
                        rel: 'noreferrer',
                        href: routeToUrl,
                        target: '_blank',
                    }}
                    size={{ base: 'sm', md: 'md' }}
                    rightIcon='offsite'
                    {...buttonProps}
                >
                    {buttonText}
                </Button>
            }
        />
    );
};

export default StickyContentExternalNav;
