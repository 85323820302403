import { css } from '@emotion/css';
import { lookup } from '@trova-trip/country-data';
import { PricingCalculator } from '@trova-trip/trova-common';
import {
    BaseBox,
    IconButton,
    Skeleton,
    Text,
} from '@trova-trip/trova-components';
import { Button, Table } from '@trova-trip/trova-components/build/next';
import { useState } from 'react';
import { useSelector } from '../../../../state/hooks';
import { tripHasItineraryInventoryItem } from '../../../common/helpers';
import useCurrencyCode from '../../../common/hooks/currencies/useCurrencyCode';
import TripCostThresholdsCell from '../../tabs/ReviewPricing/Trip/TripCostThresholdsCell';
import useTripCosts from '../../tabs/hooks/useTripCosts';
import { useUpdateHandler as useCostThresholdsUpdateHandler } from '../../tabs/hooks/useValidityPeriods';
import PricingRow from './common/PricingRow';
import YourPriceInput from './common/YourPriceInput';
import {
    OnTiersChange,
    SpotsCapacity,
    TripCostThreshold,
} from './common/types';
import PricingTableModal from './components/PricingTableModal/PricingTableModal';
import { formatCalculatorThresholds, getPricingBreakdown } from './utils';

type CostPerThreshold = PricingCalculator.CostPerThreshold;
type FixedTripCosts = PricingCalculator.FixedTripCosts;
interface ThresholdsRowProps {
    thresholds: number[];
    onTiersChange: OnTiersChange;
    disabled: boolean;
}

const ThresholdsRow = ({
    thresholds,
    onTiersChange,
    disabled,
}: ThresholdsRowProps): JSX.Element => {
    return (
        <Table.Row>
            <Table.Cell />
            {thresholds.map((threshold, thresholdIndex) => (
                <Table.Cell key={String(thresholdIndex) + String(threshold)}>
                    <TripCostThresholdsCell
                        index={thresholdIndex}
                        numberOfTravelers={threshold}
                        onTiersChange={onTiersChange}
                        disabled={disabled}
                    />
                </Table.Cell>
            ))}
            <BaseBox position='relative' top={1}>
                <IconButton
                    onClick={(): void => onTiersChange('add')}
                    icon='plus'
                    aria-label='add-tier'
                />
            </BaseBox>
        </Table.Row>
    );
};

interface OperatorPriceRowProps {
    costThresholds: CostPerThreshold[];
    suggestedCosts: CostPerThreshold[];
    onCostChange: (event: any, target: any, value: any) => void;
    isDisabled: boolean;
    currencyCode?: string;
    hasItineraryInventoryItem?: boolean;
}

const OperatorPriceRow = ({
    costThresholds,
    suggestedCosts,
    onCostChange,
    currencyCode,
    isDisabled,
    hasItineraryInventoryItem,
}: OperatorPriceRowProps): JSX.Element => {
    return (
        <Table.Row>
            <Table.Cell>
                <Text fontSize='xs' fontWeight='bold' letterSpacing='wide'>
                    Your Price
                </Text>
            </Table.Cell>
            {costThresholds.map((threshold, thresholdIndex) => {
                const suggestedCost =
                    suggestedCosts[thresholdIndex]?.pricePerTraveler;
                return (
                    <Table.Cell
                        key={
                            String(thresholdIndex) +
                            String(threshold.numberTravelers)
                        }
                    >
                        <YourPriceInput
                            threshold={threshold}
                            suggestedCost={suggestedCost}
                            index={thresholdIndex}
                            isDisabled={isDisabled}
                            currencyCode={currencyCode}
                            onChange={onCostChange}
                            hasItineraryInventoryItem={
                                hasItineraryInventoryItem
                            }
                        />
                    </Table.Cell>
                );
            })}
        </Table.Row>
    );
};

interface PricingTableProps {
    costThresholds: any[];
    singleSupplement: number;
    updateFormCostThresholds: (costThresholds: TripCostThreshold[]) => void;
    updateSingleSupplement: (value: number) => void;
    singleSupplementFee: number;
    spotsCapacity: SpotsCapacity;
    fixedCosts: FixedTripCosts;
    currentCurrency: string;
    disableInputs: boolean;
}

const PricingTable = ({
    costThresholds,
    updateFormCostThresholds,
    spotsCapacity,
    currentCurrency,
    disableInputs = false,
}: PricingTableProps): JSX.Element => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const trip = useSelector((state) => state.userTrips.current);
    const { tripCosts, addTier, updateTier, removeTier } = useTripCosts({
        spotsCapacity,
    });

    const currencyCode = useCurrencyCode(currentCurrency);
    const [currencyInfo] = lookup.currencies({ code: currencyCode });

    const costUpdateHandler = useCostThresholdsUpdateHandler(
        costThresholds,
        updateFormCostThresholds,
        spotsCapacity,
    );

    const onChangeCostThresholdWrapper = (event, name, value): void => {
        const correctedValue = Number(value) || 0;
        const wrappedEvent = {
            ...event,
            target: { value: correctedValue, name, type: `number` },
        };
        costUpdateHandler(wrappedEvent);
    };

    const onTiersChangeHandler: OnTiersChange = (type, data) => {
        if (!tripCosts) {
            return;
        }
        switch (type) {
            case 'add':
                addTier();
                break;
            case 'remove':
                data && removeTier(data.index);
                break;
            case 'update':
                data &&
                    updateTier(data.index, data.newValue || spotsCapacity.min);
                break;
        }
        const formattedCostThresholds = formatCalculatorThresholds(
            tripCosts.currentCosts,
        );
        updateFormCostThresholds(formattedCostThresholds);
    };

    if (!tripCosts) {
        return (
            <Skeleton
                width='full'
                height={60}
                borderRadius='xl'
                isLoaded={!!tripCosts}
            />
        );
    }

    const pricingBreakdown = getPricingBreakdown(tripCosts, trip);

    const { currentCosts, suggestedCosts } = tripCosts;
    const {
        suggestedPrice,
        travelerCostsBreakdown,
        travelerThresholds,
        operatorSubtotalPrice,
    } = pricingBreakdown;

    const travelerPrice =
        travelerCostsBreakdown?.[travelerCostsBreakdown.length - 1];

    return (
        <>
            <BaseBox position='relative'>
                <Table variant='simple' borderOutline size='md'>
                    <Table.Head>
                        <ThresholdsRow
                            thresholds={travelerThresholds}
                            onTiersChange={onTiersChangeHandler}
                            disabled={disableInputs}
                        />
                    </Table.Head>
                    <Table.Body>
                        {!tripHasItineraryInventoryItem(trip) ? (
                            <PricingRow
                                pricing={suggestedPrice}
                                currencyInfo={currencyInfo}
                            />
                        ) : null}
                        <OperatorPriceRow
                            costThresholds={currentCosts}
                            suggestedCosts={suggestedCosts}
                            onCostChange={onChangeCostThresholdWrapper}
                            isDisabled={disableInputs}
                            currencyCode={currencyCode}
                            hasItineraryInventoryItem={tripHasItineraryInventoryItem(
                                trip,
                            )}
                        />
                        <PricingRow
                            pricing={operatorSubtotalPrice}
                            currencyInfo={currencyInfo}
                        />
                        <PricingRow
                            pricing={travelerPrice}
                            currencyInfo={currencyInfo}
                        />
                    </Table.Body>
                </Table>
                <Button
                    variant='tertiary'
                    position={{ base: 'static', md: 'absolute' }}
                    className={css({ top: -50, right: 0 })}
                    size='sm'
                    rightIcon='fullscreen'
                    onClick={(): void => setIsModalOpen(true)}
                    marginTop={4}
                    isFullWidth={{ base: true, md: false }}
                >
                    View Pricing Breakdown
                </Button>
            </BaseBox>
            <PricingTableModal
                pricingBreakdown={pricingBreakdown}
                onClose={(): void => setIsModalOpen(false)}
                isOpen={isModalOpen}
                currencyInfo={currencyInfo}
            />
        </>
    );
};

export default PricingTable;
