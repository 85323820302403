import { coreUtils } from '@trova-trip/trova-common';
import {
    Heading,
    Stack,
    StatusIndicator,
    Text,
    theme,
} from '@trova-trip/trova-components';
import { Card, Table } from '@trova-trip/trova-components/build/next';
import { constants, models } from '@trova-trip/trova-models';
import { format } from 'date-fns';

type BookingPayment = models.bookings.BookingPayment;
type PaymentType = constants.bookings.PaymentType;
type PaymentStatus = constants.bookings.PaymentStatus;
const PaymentTypeEnum = constants.bookings.PaymentType;
const PaymentStatusEnum = constants.bookings.PaymentStatus;

interface TransactionHistoryTableProps {
    payments: BookingPayment[];
    paidAmount: number;
    dueAmount: number;
}

interface TextHeaderProps {
    children: string;
}

interface PaymentRowsProps {
    payments: BookingPayment[];
}

const { formatUSD } = coreUtils.currencyUtils;

const filterPaymentsByStatus = (
    payments: BookingPayment[],
    status: PaymentStatus,
) => payments.filter((payment) => payment.status === status);

const displayPaymentType = (type: PaymentType) => {
    if (
        [PaymentTypeEnum.CREDIT_CARD, PaymentTypeEnum.PAYMENT_INTENT].includes(
            type,
        )
    ) {
        return 'PAYMENT';
    }

    return type;
};

const TextHeader = ({ children }: TextHeaderProps): JSX.Element => (
    <Text as='span' size='sm' textTransform='capitalize' fontWeight='bold'>
        {children}
    </Text>
);

const PaymentRows = ({ payments }: PaymentRowsProps): JSX.Element => (
    <>
        {payments.map(({ type, amount, date, _id }) => (
            <Table.Row key={_id}>
                <Table.Cell>{displayPaymentType(type)}</Table.Cell>
                <Table.Cell>
                    {format(new Date(date), 'MMM dd, yyyy')}
                </Table.Cell>
                <Table.Cell>
                    <StatusIndicator color={theme.colors.seaGreen.trova} />{' '}
                    Success
                </Table.Cell>
                <Table.Cell textAlign='right'>
                    {formatUSD(amount, 2)}
                </Table.Cell>
            </Table.Row>
        ))}
    </>
);

const RefundRow = (): JSX.Element => (
    <Table.Row>
        <Table.Cell>REFUND</Table.Cell>
        <Table.Cell>--</Table.Cell>
        <Table.Cell>
            <StatusIndicator color={theme.colors.orange[600]} /> Requested
        </Table.Cell>
        <Table.Cell textAlign='right'>--</Table.Cell>
    </Table.Row>
);

const TransactionHistoryTable = ({
    payments = [],
    paidAmount,
    dueAmount,
}: TransactionHistoryTableProps) => {
    const refundRequested = dueAmount < 0;

    return (
        <Card width='full' marginTop={6}>
            <Stack direction='column' spacing={4} width='full' align='stretch'>
                <Heading
                    as='h4'
                    fontWeight='medium'
                    size={{ base: 'xl', lg: '2xl' }}
                >
                    Transaction History
                </Heading>

                <Table variant='simple' borderOutline={false}>
                    <Table.Head>
                        <Table.Row>
                            <Table.Header>
                                <TextHeader>Transaction Type</TextHeader>
                            </Table.Header>
                            <Table.Header>
                                <TextHeader>Date</TextHeader>
                            </Table.Header>
                            <Table.Header>
                                <TextHeader>Status</TextHeader>
                            </Table.Header>
                            <Table.Header textAlign='right'>
                                <TextHeader>Amount</TextHeader>
                            </Table.Header>
                        </Table.Row>
                    </Table.Head>

                    <Table.Body>
                        <PaymentRows
                            payments={filterPaymentsByStatus(
                                payments,
                                PaymentStatusEnum.SUCCESS,
                            )}
                        />
                        {refundRequested ? <RefundRow /> : null}
                    </Table.Body>
                </Table>

                <Stack
                    justify='flex-end'
                    width='full'
                    flex={1}
                    paddingRight={6}
                >
                    <Text color='blueGray.650' fontSize='lg' fontWeight='bold'>
                        Total: {formatUSD(paidAmount, 2)}
                    </Text>
                </Stack>
            </Stack>
        </Card>
    );
};

export default TransactionHistoryTable;
