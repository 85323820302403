import {
    ComponentWidth,
    FormDataObjectType,
    Grid,
    TextareaSize,
    useFormSaver,
} from '@trova-trip/trova-components';
import { Card } from '@trova-trip/trova-components/build/next';
import { models } from '@trova-trip/trova-models';
import includes from 'lodash/includes';
import noop from 'lodash/noop';
import set from 'lodash/set';
import Danger from '../../../../../components/Typography/Danger';
import { ServiceTypesKeys } from '../../../../../config/constants';
import { getDropdownOptionsByKey } from '../../../../../util/form/dropdown';
import {
    getColorFromServiceType,
    getServiceTitleLabel,
} from '../LibraryServiceFormatter';
import ServiceTitle from '../ServiceTitle';
import { ServiceFormPermissions, fieldNamesByServiceType } from '../helpers';
import {
    PaddedCheckbox,
    PaddedDropdown,
    PaddedInput,
    PaddedNumberInput,
    PaddedTextarea,
    PaddedTimePicker,
    StyledCurrency,
    StyledServiceFileInput,
} from './Form.components';
import ActionButtons from './Shared/ActionButtons';
import SaveToLibraryCheckbox from './Shared/SaveToLibraryCheckbox';

type ServiceType = models.services.ServiceType;

const DEFAULT_TRANSFER = {
    saveToLibrary: false,
    type: ServiceTypesKeys.TRANSFER,
    airportTransfer: false,
    numberOfTransfers: 0,
    flightWindow: null,
    length: 60,
};

interface TransferCardFormProps {
    serviceToEdit?: models.services.Transfer | null;
    closeForm: () => void;
    updateItinerary: (data: any) => void;
    errorText?: string;
    currencyCode?: string;
    permissions?: ServiceFormPermissions | undefined;
    disabledFields?: string[];
}

const TransferCardForm = (props: TransferCardFormProps): JSX.Element => {
    const {
        updateItinerary,
        serviceToEdit,
        closeForm,
        errorText,
        currencyCode,
        disabledFields,
    } = props;

    const isNew = !serviceToEdit;

    const initialValues = (
        isNew ? DEFAULT_TRANSFER : serviceToEdit
    ) as FormDataObjectType;

    const formSaver = useFormSaver({
        onSubmit: async (values) => {
            updateItinerary(values);
        },
        initialValues,
    });

    const { formValues, handleFormSubmit, formState } = formSaver;

    const handleFormChange = (event: React.SyntheticEvent): void => {
        const { value, name } = event.target as HTMLInputElement;
        formValues.set[name](value);
    };

    const handleCheckboxChange = (event: React.SyntheticEvent): void => {
        const { name } = event.target as HTMLInputElement;
        const isChecked = !formValues.get.nested(name);

        formValues.set.nested(name, isChecked);
    };

    const handleDropdownChangeEvent = (event, name, value): void => {
        const wrappedEvent = { ...event, target: { value, name } };
        handleFormChange(wrappedEvent);
    };

    const onRemoveAction = () => {
        formValues.set.nested('deleted', true);
        updateItinerary({
            ...formState,
            deleted: true,
        });
    };

    const onChangeNumberWrapper = (event, name, value): void => {
        const wrappedEvent = {
            ...event,
            target: { value, name, type: `number` },
        };
        handleFormChange(wrappedEvent);
    };

    const onChangeFlightWindowWrapper = (event, name) => {
        const value = event;
        const [nestedFieldName, startOrEnd] = name.split('.');
        const newFlightWindow = {
            ...(formValues.get[nestedFieldName] as Record<string, any>),
        };
        set(newFlightWindow, startOrEnd, value);
        formValues.set.nested(nestedFieldName, newFlightWindow);
    };

    const serviceType = formValues.get.type as ServiceType;

    const { transfer: fieldNames } = fieldNamesByServiceType;

    const isAirportTransfer = formValues.get[
        fieldNames.airportTransfer
    ] as boolean;

    return (
        <Card backgroundColor='neutral.white'>
            <ServiceTitle
                title={getServiceTitleLabel(serviceType)}
                color={getColorFromServiceType(serviceType)}
            />
            <PaddedInput
                name={fieldNames.name}
                label='Name *'
                size={ComponentWidth.Medium}
                value={formValues.get[fieldNames.name] as string}
                onChange={handleFormChange}
                disabled={includes(disabledFields, fieldNames.name)}
            />
            <PaddedInput
                name={fieldNames.fromCity}
                label='From City or Airport Code *'
                size={ComponentWidth.Medium}
                value={formValues.get[fieldNames.fromCity] as string}
                onChange={handleFormChange}
                disabled={includes(disabledFields, fieldNames.fromCity)}
            />
            <PaddedInput
                name={fieldNames.toCity}
                label='To City or Airport Code *'
                size={ComponentWidth.Medium}
                value={formValues.get[fieldNames.toCity] as string}
                onChange={handleFormChange}
                disabled={includes(disabledFields, fieldNames.toCity)}
            />

            {formValues.get.price !== undefined ? (
                <StyledCurrency
                    name={fieldNames.price}
                    label='Price *'
                    size={ComponentWidth.Small}
                    value={formValues.get[fieldNames.price] as number}
                    onChange={handleFormChange}
                    currencyType={currencyCode}
                    disabled={includes(disabledFields, fieldNames.price)}
                />
            ) : null}

            <PaddedDropdown
                name={fieldNames.vehicleType}
                label='Vehicle Type'
                size={ComponentWidth.Large}
                value={formValues.get[fieldNames.vehicleType] as string}
                onChange={handleDropdownChangeEvent}
                disabled={includes(disabledFields, fieldNames.vehicleType)}
                onSearch={noop}
            >
                {getDropdownOptionsByKey(`VehicleType`)}
            </PaddedDropdown>
            <PaddedNumberInput
                name={fieldNames.length}
                label='Length in Minutes *'
                value={(formValues.get[fieldNames.length] || 60) as number}
                onChange={onChangeNumberWrapper}
                disabled={includes(disabledFields, fieldNames.length)}
            />
            <PaddedTextarea
                name={fieldNames.description}
                placeholder='Vehicle details...'
                label='Vehicle Details'
                size={TextareaSize.Medium}
                value={formValues.get[fieldNames.description] as string}
                onChange={handleFormChange}
                expandable={true}
                disabled={includes(disabledFields, fieldNames.description)}
            />
            <PaddedCheckbox
                name={fieldNames.airportTransfer}
                detail='Airport Transfer'
                value={isAirportTransfer}
                onChange={handleCheckboxChange}
                disabled={includes(disabledFields, fieldNames.airportTransfer)}
            />
            {isAirportTransfer ? (
                <>
                    <PaddedNumberInput
                        name={fieldNames.numberOfTransfers}
                        label='Number of Transfers'
                        value={
                            (formValues.get[fieldNames.numberOfTransfers] ||
                                0) as number
                        }
                        onChange={onChangeNumberWrapper}
                        disabled={includes(
                            disabledFields,
                            fieldNames.numberOfTransfers,
                        )}
                    />
                    <PaddedTimePicker
                        name={fieldNames.flightWindowStart}
                        label='Flight Window Start Time (24h)'
                        placeholder='0:00'
                        value={
                            formValues.get[
                                fieldNames.flightWindowStart
                            ] as string
                        }
                        size={ComponentWidth.Medium}
                        onChange={(value) => {
                            onChangeFlightWindowWrapper(
                                value,
                                fieldNames.flightWindowStart,
                            );
                        }}
                        disabled={includes(
                            disabledFields,
                            fieldNames.flightWindowStart,
                        )}
                    />
                    <PaddedTimePicker
                        name={fieldNames.flightWindowEnd}
                        label='Flight Window End Time (24h)'
                        placeholder='0:00'
                        value={
                            formValues.get[fieldNames.flightWindowEnd] as string
                        }
                        size={ComponentWidth.Medium}
                        onChange={(value) => {
                            onChangeFlightWindowWrapper(
                                value,
                                fieldNames.flightWindowEnd,
                            );
                        }}
                        disabled={includes(
                            disabledFields,
                            fieldNames.flightWindowEnd,
                        )}
                    />
                </>
            ) : null}
            <StyledServiceFileInput
                className=''
                detail=''
                error=''
                key=''
                name={fieldNames.images}
                value={formValues.get[fieldNames.images]}
                onChange={handleFormChange}
                label='Upload images'
                maxFiles='10'
                disabled={includes(disabledFields, fieldNames.images)}
            />
            <SaveToLibraryCheckbox
                value={formValues.get[fieldNames.saveToLibrary] as boolean}
                onChange={handleFormChange}
                disabled={includes(disabledFields, fieldNames.saveToLibrary)}
            />
            {errorText ? (
                <Grid>
                    <Grid.Item columnSpan={12}>
                        <Danger>{errorText}</Danger>
                    </Grid.Item>
                </Grid>
            ) : null}
            <ActionButtons
                serviceType={serviceType}
                closeForm={closeForm}
                submitForm={() => handleFormSubmit(undefined)}
                onRemoveAction={onRemoveAction}
                isNewEntity={isNew}
                permissions={props.permissions}
            />
        </Card>
    );
};

export default TransferCardForm;
