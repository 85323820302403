import { useHistory } from 'react-router';
import { useSelector } from 'state/hooks';
import PricingTab from './tabs/PricingTab/PricingTab';
import BookingsTab from './tabs/BookingsTab/BookingsTab';
import ServicesAgreement from '../../tabs/ServicesAgreement/ServicesAgreement';
import Configuration from '../../tabs/Configuration/Configuration';
import ReviewTripTab from './tabs/ReviewTripTab/ReviewTripTab';
import PromoteTab from './tabs/PromoteTab/PromoteTab';
import SuccessPage from 'components/SuccessPage/SuccessPage';
import RequestTab from './tabs/RequestTab/RequestTab';
import TravelInfoTab from './tabs/TravelInfoTab/TravelInfoTab';
import SuccessPageMainContent from './components/SuccessPageMainContent';
import StickyContentTripPageStatus from 'applications/host/components/StickyContent/StickyContentTripPageStatus';
import useModelStatusMatcher from '../../../common/hooks/useModelStatusMatcher';
import getQueryParam from '../../../../util/getQueryParam';
import {
    HostTripApproved,
    PendingTrovaApprovalTrip,
    modelTypes,
    TripStatuses,
} from '../../../../config/constants';
import { StatefulModel } from '../../../../applications/types';
import useMobileIntercomStyles from 'applications/host/hooks/useMobileIntercomStyles';
import OnboardingStepper from './components/OnboardingStepper';

const TripsTabsPanel = (): JSX.Element => {
    const {
        userTrips: { current: currentTrip },
        userTripRequests: { current: currentTripRequest },
        userTripDetails: { current: currentTripDetails },
    } = useSelector((state) => state);

    const history = useHistory();
    const model = getQueryParam(history, 'model');

    const isCurrentTripAvailable = Boolean(currentTrip && currentTripDetails);
    const isCurrentTripRequestAvailable = Boolean(currentTripRequest?.id);
    const isTripRequest = model === modelTypes.TRIP_REQUEST;
    const isTripPendingTrovaApproval = PendingTrovaApprovalTrip.has(
        currentTrip?.status || '',
    );
    const isNavigationHidden = isTripRequest || isTripPendingTrovaApproval;

    const isTripHostApproved = useModelStatusMatcher({
        model: StatefulModel.TRIP,
        matchingStatuses: HostTripApproved,
    });

    const isTripComplete = currentTrip?.status == TripStatuses.COMPLETE;

    const isTabDisabled = !isTripHostApproved && !isTripComplete;

    const defaultTabProps = {
        disabled: false,
        hideNavigation: isNavigationHidden,
    };

    const bookingsTabProps = {
        disabled: isTabDisabled,
        hideNavigation: isNavigationHidden,
    };

    const travelInfoTabProps = {
        disabled: isTabDisabled,
        hideNavigation: isNavigationHidden,
    };

    const promoteTabProps = {
        disabled: isTabDisabled,
        hideNavigation: isNavigationHidden,
    };

    const showOnboardingStepper = currentTrip
        ? // @ts-ignore
          !currentTrip.hasHostCompletedPromoteTutorial &&
          currentTrip.status === TripStatuses.HOST_APPROVED
        : false;

    useMobileIntercomStyles();

    return (
        <>
            {isCurrentTripRequestAvailable && <RequestTab />}

            {isCurrentTripAvailable && (
                <>
                    <Configuration {...defaultTabProps} />
                    <PricingTab {...defaultTabProps} />
                    <ReviewTripTab {...defaultTabProps} />
                    <PromoteTab {...promoteTabProps} />
                    <BookingsTab {...bookingsTabProps} />
                    <TravelInfoTab {...travelInfoTabProps} />
                    <ServicesAgreement />
                    <SuccessPage
                        mainContent={<SuccessPageMainContent />}
                        stickyContent={<StickyContentTripPageStatus />}
                    />
                    <OnboardingStepper isOpen={showOnboardingStepper} />
                </>
            )}
        </>
    );
};

export default TripsTabsPanel;
