import { noop } from 'lodash';
import Product from '../../../../navigation/Product';
import HomeContainer from './HomeContainer';
import { buildHomeRoute } from './common/utils';
import { Redirect } from 'react-router-dom';
import { HOST_APP_PRODUCT_ROUTE } from '../../../common/constants/routesTokens';

export const HOME_ROUTES = {
    dashboard: buildHomeRoute('DASHBOARD', true),
    progress: buildHomeRoute('PROGRESS', true),
};

interface HomeProps {
    showDashboard: boolean;
}

const Home = (props: HomeProps) => {
    const { showDashboard } = props;
    const path = showDashboard ? HOME_ROUTES.dashboard : HOME_ROUTES.progress;

    return (
        <Product
            path={HOST_APP_PRODUCT_ROUTE.HOME}
            label={'Home'}
            icon='home'
            loadNavigationContent={noop}
            root={<Redirect to={path} />}
        >
            <HomeContainer showDashboard={showDashboard} />
        </Product>
    );
};

export default Home;
