import { businessConstants } from '@trova-trip/trova-common';
import { ButtonProps, Link } from '@trova-trip/trova-components/build/next';
import { useCurrentItineraryTierInfoForUser } from 'applications/common/hooks/useCurrentHostItineraryTier';
import useModelStatusMatcher from 'applications/common/hooks/useModelStatusMatcher';
import { StatefulModel } from 'applications/types';
import NavigationContext from 'navigation/NavigationContext';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ItineraryTierBadge from '../../ItineraryTierBadge/ItineraryTierBadge';
import CardWithActions, {
    CardAction,
} from '../../../../../../common/components/CardWithActions';
import { constants } from '@trova-trip/trova-models';

const { itinerary: { TierSurveysRequired } } = constants;

const getTierDescription = (tier: number): string => {
    switch (tier) {
        case 3:
            return `Expert itineraries are specialized trips for proven, highly interested audiences. To help ensure Host success, ${TierSurveysRequired[3]} qualified survey responses are required before these trips can be requested.`;
        case 2:
            return `Experienced Tier trips require highly interested audiences. For Hosts to be successful we require ${TierSurveysRequired[2]} qualified survey responses before these trips can be requested.`;
        case 1:
        default:
            return `Our most popular itineraries, Starter itineraries are great choices for Hosts new to group travel. These plug-and-play itineraries are designed to allow for a quick and easy launch, at a reasonable price point to entice Travelers. At least ${TierSurveysRequired[1]} survey responses are required for a trip to before a Starter trip can be requested.`;
    }
};

const ItineraryTierLockedCard = (): JSX.Element | null => {
    const tier = useCurrentItineraryTierInfoForUser();

    const isHostQualified = useModelStatusMatcher({
        model: StatefulModel.PROFILE,
        matchingStatuses: businessConstants.QualifiedHostStatuses,
    });

    const history = useHistory();
    const { applicationPath: appPath } = useContext(NavigationContext) || {};

    if (!tier) {
        return null;
    }

    const title = `${tier.name} Tier Unavailable`;
    const description = getTierDescription(tier.number);

    const handlePrimaryActionClick = (): void => {
        history.push(`${appPath}/audience`);
    };

    const handleSecondaryActionClick = (): void => {
        history.push(`${appPath}/itineraries`);
    };

    const actions: CardAction[] = [
        {
            label: 'Continue Surveying',
            onClick: handlePrimaryActionClick,
            variant: 'primary' as ButtonProps['variant'],
        },
    ];

    if (isHostQualified) {
        actions.push({
            label: 'View Itineraries',
            onClick: handleSecondaryActionClick,
            variant: 'secondary' as ButtonProps['variant'],
        });
    }

    return (
        <CardWithActions
            backgroundColor='neutral.97'
            topAddon={<ItineraryTierBadge tier={tier} />}
            title={title}
            description={
                <>
                    {description} You can learn more about itinerary tiers{' '}
                    <Link
                        href='https://trovatrip.com/host/itinerary-tiers'
                        isExternal
                    >
                        here
                    </Link>
                    .
                </>
            }
            actions={actions}
            footer={
                <>
                    Think you are ready to host this trip?{' '}
                    <Link
                        href='mailto:hi@trovatrip.com'
                        size={{ base: 'sm', md: 'base' }}
                        variant='paragraph-link'
                        color='red.trova'
                    >
                        Ask us
                    </Link>{' '}
                    about other ways to qualify.
                </>
            }
        />
    );
};

export default ItineraryTierLockedCard;
