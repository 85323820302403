import React, { useEffect, useRef } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { analytics } from '@trova-trip/trova-common';
import ProfileReader from '../util/profile/ProfileReader';
import Operator from './operator';
import Traveler from './traveler';
import { profile } from '../state/profile';
import Loading from '../components/Loading';
import { applicationRootPath } from '../navigation/NavigationContext';
import Host from './host';
import { useAnalyzeIdentity } from '../analytics/hooks';
import ImpersonationBanner from './common/components/ImpersonationBanner';

const ApplicationsRoot = ({ profile, getProfile }) => {
    // TODO group all routes that require a profile then remove this
    useEffect(() => {
        if (!profile.current && !profile.fetching) {
            getProfile();
        }
    }, [getProfile, profile]);

    useAnalyzeIdentity();

    if (!profile.current) {
        return <Loading />;
    }

    const { current: currentProfile } = profile;
    const profileReader = ProfileReader.create(currentProfile);

    return (
        <>
            {currentProfile?.impersonate ? (
                <ImpersonationBanner profile={currentProfile} />
            ) : null}
            <Operator profile={currentProfile} />
            <Traveler profile={currentProfile} />
            <Host profile={currentProfile} />
            <Route
                component={({ match: { isExact } }) => {
                    if (profileReader.isOperator() && isExact) {
                        return (
                            <Redirect to={`${applicationRootPath}/operator`} />
                        );
                    } else if (profileReader.isTraveler() && isExact) {
                        return (
                            <Redirect to={`${applicationRootPath}/traveler`} />
                        );
                    } else if (profileReader.isHost() && isExact) {
                        return <Redirect to={`${applicationRootPath}/host`} />;
                    }
                    return null;
                }}
            />
        </>
    );
};

ApplicationsRoot.propTypes = {
    profile: PropTypes.shape(),
    getProfile: PropTypes.func,
};

const mapStateToProps = ({ profile }) => ({
    profile,
});

const mapDispatchToProps = (dispatch) => ({
    getProfile() {
        dispatch(profile.getRecord());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsRoot);
