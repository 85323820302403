import { Grid } from '@material-ui/core';
import {
    FormDataObjectType,
    FormSaver,
    useFormSaver,
    useToast,
} from '@trova-trip/trova-components';
import ProfileFormFields from './ProfileFormFields';
import ProfileFormImageField from './ProfileFormImageField';
import useProfileFormAPI from '../../../hooks/useProfileFormAPI';
import {
    ProfileFieldsNames,
    ProfileFormResponse,
} from '../../../types/ProfileForm.types';
import { validationSchemas } from '@trova-trip/trova-models';
import { Button } from '@trova-trip/trova-components/build/next';
import { getProfileSectionToastConfig } from '../../../utils/address.utils';
import ContentForUserGroup from '../../../../../../../applications/common/components/ContentForUserGroup';
import { profileFieldVisibility } from './utils';

const { ProfileFormValidationSchema } = validationSchemas;

const ProfileForm = (): JSX.Element => {
    const {
        initialValues,
        handleSubmit: onSubmit,
        isLoading,
    } = useProfileFormAPI();

    const toast = useToast();

    const handleSubmit = async (data): Promise<ProfileFormResponse> =>
        await onSubmit(data);

    const onFormSubmit = async (data): Promise<void> => {
        const response = await handleSubmit(data);
        const isSuccess = !!response.profile;
        const toastConfig = getProfileSectionToastConfig({
            section: 'profile',
            isSuccess,
        });
        toast(toastConfig);
    };

    const formSaver = useFormSaver({
        onSubmit: onFormSubmit,
        validationSchema: {
            schema: ProfileFormValidationSchema,
            validateFieldOnChange: false,
        },
        initialValues: initialValues.profile as unknown as FormDataObjectType,
    });

    return (
        <FormSaver name='profile-form' onSubmit={formSaver.handleFormSubmit}>
            <Grid container spacing={32}>
                <Grid item xs={12} lg={6}>
                    <ProfileFormFields formSaver={formSaver} />
                </Grid>
                <Grid item xs={12} lg={1} />
                <ContentForUserGroup
                    matchingUserGroup={
                        profileFieldVisibility[
                            ProfileFieldsNames.PROFILE_PICTURE_URL
                        ]
                    }
                    render={(): JSX.Element => (
                        <Grid item xs={12} lg={5}>
                            <ProfileFormImageField
                                label={'Profile Picture'}
                                name={ProfileFieldsNames.PROFILE_PICTURE_URL}
                                value={
                                    formSaver.formValues.get
                                        .profilePictureUrl as string
                                }
                                onChange={
                                    formSaver.formValues.set.profilePictureUrl
                                }
                            />
                        </Grid>
                    )}
                />
                <Grid item xs={12}>
                    <Button
                        isDisabled={!formSaver.isFormDirty || isLoading}
                        type='submit'
                        variant='primary'
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </FormSaver>
    );
};

export default ProfileForm;
