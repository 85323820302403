import { useHistory } from 'react-router-dom';

interface UseNavigateConfig {
    preserveCurrentPath: boolean;
}

export const useNavigate = (to: string, config?: UseNavigateConfig) => {
    const history = useHistory();

    const { preserveCurrentPath = true } = config || {};

    let nextPath = to;

    if (preserveCurrentPath) {
        const {
            location: { pathname },
        } = history;
        const toWithInitialSlash = to.charAt(0) === '/' ? to : `/${to}`;
        const pathnameWithoutEndSlash = pathname.replace(/\/$/, '');
        nextPath = `${pathnameWithoutEndSlash}${toWithInitialSlash}`;
    }

    return () => {
        history.push(nextPath);
    };
};
