import React from 'react';
import PropTypes from 'prop-types';
import Application from '../../navigation/Application';
import Trips from './products/Trips';
import Explore from './products/Explore';
import Transfer from '../common/products/transfer';
import BookNewTripLinkButton from './components/BookNewTripLinkButton/BookNewTripLinkButton';
import style from '@emotion/styled';
import Account from '../common/products/account';
import useLoadNavigationContent from './hooks/useLoadNavigationContent';

const AppContentWrapper = style.div` 
    div[data-testid="card"] button[data-ui-button-type="Primary"]{
        max-width: 180px;
    }
`;

const Content = ({ profile }) => {
    const loadNavigationContent = useLoadNavigationContent();

    return (
        <AppContentWrapper data-ui-app-page='traveler'>
            <Trips
                profile={profile}
                loadNavigationContent={loadNavigationContent}
            />
            <Explore />
            <Transfer loadNavigationContent={loadNavigationContent} />
            <Account />
        </AppContentWrapper>
    );
};

const Traveler = ({ profile }) => (
    <Application
        profile={profile}
        path='/traveler'
        sidebarProps={{
            buttonRender: () => <BookNewTripLinkButton marginY={8} />,
            type: 'traveler',
        }}
    >
        <Content profile={profile} />
    </Application>
);

Traveler.propTypes = {
    profile: PropTypes.shape(),
};

export default Traveler;
