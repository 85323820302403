import { PUBLIC_PROFILE_DIALOG_LOCATION } from '../../../../../hooks/usePublicProfileAnalytics';
import AudienceCollectionCard from './AudienceCollectionCard';
import { EmailCollectionInfo } from './AudienceCollectionCards.types';

const COLLECTION_COPY = {
    available:
        'Continue to grow your email list using your collection link. Promotional emails for your trips will be sent to your total audience list.',
    locked: 'Submit your first trip request to gain access to your email collection link. This tool is used to continue growing your audience list after surveying.',
};

const EmailCollectionCard = (props: EmailCollectionInfo): JSX.Element => {
    const { total, link, isLocked = false } = props;

    return (
        <AudienceCollectionCard
            title='Email Collection'
            description={
                isLocked ? COLLECTION_COPY.locked : COLLECTION_COPY.available
            }
            isLocked={isLocked}
            responses={total}
            copyConfig={{
                link: link,
                label: 'Email Collection Link',
            }}
            location={PUBLIC_PROFILE_DIALOG_LOCATION.emailCollectionCard}
        />
    );
};

export default EmailCollectionCard;
