import { useEffect } from 'react';
import { useSelector } from '../../../state/hooks';
import useUserBookingsActions from './useUserBookingsActions';

/**
 * Hook to fetch the user's booking using the current trip on the store and
 * to set it on the global store. The booking will be fetched only if:
 *
 * - There is actually a current trip on the store
 * - The booking wasn't fetched before or is not hydrated on the store
 * - The current user is `active`
 */
const useBookingFetcher = (): void => {
    const { currentTrip, user, userBooking } = useSelector((state) => ({
        currentTrip: state.userTrips.current,
        user: state.profile.current,
        userBooking: state.userBookings.current?.[0],
    }));

    const { loadUserBookings } = useUserBookingsActions();

    const isActiveUser = user?.active;

    useEffect(() => {
        const hasCurrentBooking = !!userBooking;

        if (currentTrip?.id && !hasCurrentBooking && isActiveUser) {
            loadUserBookings(currentTrip.id);
        }
    }, [currentTrip?.id, isActiveUser, userBooking]);
};

export default useBookingFetcher;
