import React from 'react';
import { Heading, Text, BaseBox } from '@trova-trip/trova-components';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { capitalize } from '../../../utils';
import { coreUtils, logger } from '@trova-trip/trova-common';

import {
    ListItem,
    ListItemValue,
    PaddedGrid,
    StyledMainContainerGrid,
    StyledSectionContainerGrid,
} from './HostSummary.components';
import useGetHostSummaryData from '../hooks/useGetHostSummaryData';
import { useServicesByDayWithSelectedFilter } from '../../../../scenes/Portal/hooks/useServicesByDay';
import WorkshopRequests from './WorkShopRequests';

const { tripUtils } = coreUtils;

const OptionalActivities = ({ servicesByDay, selectedServicesIds }) => {
    if (!selectedServicesIds || selectedServicesIds.length === 0) {
        return null;
    }

    const services = useServicesByDayWithSelectedFilter(
        servicesByDay,
        selectedServicesIds,
    );

    if (!services || !services.some((serviceDay) => serviceDay?.length > 0)) {
        return null;
    }

    return (
        <PaddedGrid item xs={12}>
            <Grid item xs={12}>
                <SectionTitle>Optional Activities</SectionTitle>
            </Grid>
            {services.map((serviceDay, index) => {
                if (!serviceDay) {
                    return null;
                }

                return serviceDay.map((serviceDayActivity) => {
                    const { numberOptingIn } = serviceDayActivity;

                    if (numberOptingIn <= 0) {
                        return null;
                    }

                    const activityDisplayName =
                        serviceDayActivity.service.name ||
                        serviceDayActivity.service.description;

                    return (
                        <Grid item xs={12} key={index}>
                            <ListItem>
                                {activityDisplayName} ({numberOptingIn})
                            </ListItem>
                        </Grid>
                    );
                });
            })}
        </PaddedGrid>
    );
};

const formatCompanionsByAge = (companions = [], host) => {
    const companionsByAge = {
        '18+': [host],
        '13-17': [],
        '3-12': [],
        '0-2': [],
    };

    let totalCompanionsIncludingHost = 1;

    companions.forEach((companion) => {
        companionsByAge[companion.ageRange].push(companion);
        totalCompanionsIncludingHost += 1;
    });

    return [companionsByAge, totalCompanionsIncludingHost];
};

const companionByAgeTitleComponent = (
    companionAgeGroup,
    companionsByAgeKey,
) => {
    return (
        <React.Fragment>
            <Grid item xs={3} sm={3} lg={2}>
                <ListItemValue textProps={{ fontWeight: 'bold' }}>
                    &nbsp; &nbsp; {companionAgeGroup}: &nbsp;
                </ListItemValue>
            </Grid>
            <Grid item xs={9} sm={9} lg={10}>
                <ListItemValue>{companionsByAgeKey}</ListItemValue>
            </Grid>
        </React.Fragment>
    );
};

export const SectionTitle = ({ children }) => {
    return (
        <BaseBox marginTop={10}>
            <Heading as={'h5'}>{children}</Heading>
        </BaseBox>
    );
};

const RoomsConfigurationListItem = ({ configRooms }) => {
    return (
        <React.Fragment>
            <Grid item xs={12} sm={3} lg={2}>
                <ListItem>Room configuration</ListItem>
            </Grid>
            <Grid item xs={12} sm={9} lg={10}>
                <ListItemValue>
                    {configRooms.map((room) => (
                        <Text marginTop='1'>
                            {room.quantity}x {room.roomType} bed room
                        </Text>
                    ))}
                </ListItemValue>
            </Grid>
        </React.Fragment>
    );
};

const RoomsQuantityListItem = ({ roomsQuantity }) => {
    return (
        <React.Fragment>
            <Grid item xs={12} sm={3} lg={2}>
                <ListItem>Number of rooms</ListItem>
            </Grid>
            <Grid item xs={12} sm={9} lg={10}>
                <ListItemValue>{roomsQuantity}</ListItemValue>
            </Grid>
        </React.Fragment>
    );
};

const HostSummary = ({ data }) => {
    const history = useHistory();
    const displayData = useGetHostSummaryData(history, data);

    if (!displayData) {
        return <Grid item />;
    }

    const {
        quantityHostRooms,
        workshops,
        servicesByDay,
        hostSelectedOptionalServices,
        startDate,
        host,
        additionalRequests,
        companions,
        hostRooms,
        selectedPackage,
        dietaryRestriction,
        selectedWorkshopSpaces,
        workshopDetails,
    } = displayData;

    const formattedStartDate = moment(startDate).utc().format(`ll`);
    const [companionsByAge, totalCompanionsIncludingHost] =
        formatCompanionsByAge(companions, host);

    let configRooms;
    if (hostRooms?.length) {
        try {
            configRooms = tripUtils.getRoomsConfigForOperators(hostRooms);
        } catch (error) {
            logger.error(error?.message);
        }
    }

    // Show if there's at least one matching service ID between these two service data structures.
    const optionalActivitiesWillBeShown =
        hostSelectedOptionalServices?.length > 0 &&
        servicesByDay?.length > 0 &&
        hostSelectedOptionalServices.some(([serviceId]) =>
            servicesByDay.some((day) =>
                day.some((service) => service._id === serviceId),
            ),
        );

    // Only show this section if at least one subsection is being shown.
    const shouldDisplayOptionalServices =
        workshops?.length > 0 ||
        workshopDetails?.length > 0 ||
        selectedWorkshopSpaces?.length > 0 ||
        optionalActivitiesWillBeShown ||
        additionalRequests;

    return (
        <StyledMainContainerGrid container>
            <StyledSectionContainerGrid container xs={12}>
                <Grid item xs={12}>
                    <Heading as={'h4'}>Summary of host request</Heading>
                </Grid>
                <Grid item xs={12} sm={3} lg={2}>
                    <ListItem>Host</ListItem>
                </Grid>
                <Grid item xs={12} sm={9} lg={10}>
                    <ListItemValue>
                        {host && `${host.lastName}, ${host.firstName}`}
                    </ListItemValue>
                </Grid>
                {companions?.length > 0 && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <ListItem>FOC(s):</ListItem>
                        </Grid>
                        {Object.keys(companionsByAge).map(
                            (companionsByAgeKey) => {
                                if (
                                    companionsByAge[companionsByAgeKey].length >
                                    0
                                ) {
                                    return companionByAgeTitleComponent(
                                        companionsByAgeKey,
                                        companionsByAge[companionsByAgeKey]
                                            .length,
                                    );
                                }
                            },
                        )}
                        {companionByAgeTitleComponent(
                            'Total FOC',
                            totalCompanionsIncludingHost,
                        )}
                    </React.Fragment>
                )}
                {!isEmpty(configRooms) ? (
                    <RoomsConfigurationListItem configRooms={configRooms} />
                ) : !!quantityHostRooms ? (
                    <RoomsQuantityListItem roomsQuantity={quantityHostRooms} />
                ) : null}
                <Grid item xs={12} sm={3} lg={2}>
                    <ListItem>Itinerary</ListItem>
                </Grid>
                <Grid item xs={12} sm={9} lg={10}>
                    <ListItemValue>
                        {displayData?.itinerary?.name || 'Itinerary'}
                    </ListItemValue>
                </Grid>
                {selectedPackage ? (
                    <>
                        <Grid item xs={12} sm={3} lg={2}>
                            <ListItem>Package</ListItem>
                        </Grid>
                        <Grid item xs={12} sm={9} lg={10}>
                            <ListItemValue>
                                {capitalize(selectedPackage)}
                            </ListItemValue>
                        </Grid>
                    </>
                ) : null}
                <Grid item xs={12} sm={3} lg={2}>
                    <ListItem>Requested start date</ListItem>
                </Grid>
                <Grid item xs={12} sm={9} lg={10}>
                    <ListItemValue>{formattedStartDate}</ListItemValue>
                </Grid>
                {dietaryRestriction ? (
                    <>
                        <Grid item xs={12} sm={3} lg={2}>
                            <ListItem>Trip Dietary Requirement</ListItem>
                        </Grid>
                        <Grid item xs={12} sm={9} lg={10}>
                            <ListItemValue>{dietaryRestriction}</ListItemValue>
                        </Grid>
                        <Grid item xs={6} sm={6} lg={6}>
                            <ListItemValue>
                                Requirement means that our Hosts would like to
                                ensure this option is available for every meal,
                                standard options should be available as well.
                                Individual traveler preferences will be shared
                                in the PAX manifest.
                            </ListItemValue>
                        </Grid>
                    </>
                ) : null}
            </StyledSectionContainerGrid>

            {shouldDisplayOptionalServices ? (
                <StyledSectionContainerGrid container xs={12}>
                    <Grid item xs={12} marginTop={10}>
                        <Heading as={'h4'}>
                            Host Selected Optional Services
                        </Heading>
                    </Grid>

                    <WorkshopRequests
                        workshops={workshops}
                        selectedWorkshops={selectedWorkshopSpaces}
                        workshopDetails={workshopDetails}
                    />

                    <OptionalActivities
                        selectedServicesIds={hostSelectedOptionalServices}
                        servicesByDay={servicesByDay}
                    />

                    {additionalRequests && (
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <SectionTitle>Additional Requests</SectionTitle>
                            </Grid>

                            <BaseBox marginTop={4}>
                                <Text as='pre' whiteSpace='pre-line'>
                                    {additionalRequests}
                                </Text>
                            </BaseBox>
                        </Grid>
                    )}
                </StyledSectionContainerGrid>
            ) : null}
        </StyledMainContainerGrid>
    );
};

export default HostSummary;
