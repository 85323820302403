import {
    ActionSheetHeaderProps,
    Drawer,
    DrawerHeaderProps,
    Heading as HeadingTC,
    Stack,
} from '@trova-trip/trova-components';
import { Button, ButtonProps } from '@trova-trip/trova-components/build/next';
import { useRef } from 'react';
import FullScreenActionSheet from '../../../../../common/components/FullScreenActionSheet';
import useIsLargeScreen from '../../../../../common/hooks/useIsLargeScreen';
import { PricingValues } from '../ItineraryInventoryCard';
import type {
    InventoryItemFormSubmitFn,
    ItineraryInventoryFormProps,
} from '../ItineraryInventoryForm/ItineraryInventoryForm';
import ItineraryInventoryForm from '../ItineraryInventoryForm/ItineraryInventoryForm';
import { ItineraryInventoryFormValues } from '../ItineraryInventoryForm/types';

const Heading = () => (
    <HeadingTC as='h5' fontWeight='bold'>
        Inventory Detail
    </HeadingTC>
);

const headerProps = {
    borderBottom: '1px solid',
    borderColor: 'blueGray.200',
    closeIconProps: { variant: 'ghost' },
};

interface ItineraryInventoryPopUpProps {
    isOpen: boolean;
    onClose: () => void;
    onFormSubmit: InventoryItemFormSubmitFn;
    initialFormValues: ItineraryInventoryFormValues;
    pricingValues: PricingValues;
    existingStartDates: Date[];
    isLoading: boolean;
    errorMessage: string | undefined;
    formContainerRef: React.RefObject<HTMLDivElement>;
    isInventoryItemInUse?: boolean;
}

const ItineraryInventoryPopUp = ({
    isOpen,
    onClose,
    pricingValues,
    existingStartDates,
    isLoading,
    initialFormValues,
    onFormSubmit,
    errorMessage,
    formContainerRef,
    isInventoryItemInUse,
}: ItineraryInventoryPopUpProps): JSX.Element => {
    const isDesktop = useIsLargeScreen({ includeTabletResolution: true });
    const formRef = useRef<HTMLFormElement>(null);

    const handleSaveClick = () => formRef.current?.requestSubmit();

    const isUpdate = !!initialFormValues.inventoryItem.id;

    const actions: ButtonProps[] = [
        {
            children: 'Cancel',
            variant: 'secondary',
            onClick: onClose,
            size: { xs: 'md', md: 'lg' },
            isFullWidth: { xs: true, md: false },
            isDisabled: isLoading,
        },
        {
            children: isUpdate ? 'Update' : 'Save',
            variant: 'primary',
            type: 'submit',
            onClick: handleSaveClick,
            isLoading,
            isDisabled: isLoading,
            size: { xs: 'md', md: 'lg' },
            isFullWidth: { xs: true, md: false },
        },
    ];

    const formProps: ItineraryInventoryFormProps = {
        initialValues: initialFormValues,
        onSubmit: onFormSubmit,
        formRef,
        pricingValues,
        existingStartDates,
        errorMessage,
        isInventoryItemInUse,
    };

    if (isDesktop) {
        return (
            <Drawer isOpen={isOpen} onClose={onClose} size='xl'>
                <Drawer.Header {...(headerProps as DrawerHeaderProps)}>
                    <Heading />
                </Drawer.Header>

                <Drawer.Body>
                    <ItineraryInventoryForm
                        {...formProps}
                        ref={formContainerRef}
                    />
                </Drawer.Body>
                <Drawer.Footer textAlign='right'>
                    <Stack spacing={4}>
                        {actions.map((buttonProps, idx) => (
                            <Button key={idx} {...buttonProps}>
                                {buttonProps.children}
                            </Button>
                        ))}
                    </Stack>
                </Drawer.Footer>
            </Drawer>
        );
    }

    return (
        <FullScreenActionSheet
            isOpen={isOpen}
            onClose={onClose}
            headerProps={{
                ...(headerProps as ActionSheetHeaderProps),
                children: <Heading />,
            }}
            content={
                <ItineraryInventoryForm {...formProps} ref={formContainerRef} />
            }
            bodyProps={{
                padding: 3,
            }}
            footerProps={{
                marginX: 4,
                marginY: 2,
            }}
            actions={actions}
        />
    );
};

export default ItineraryInventoryPopUp;
