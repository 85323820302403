import React, { Component, Requireable } from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    theme,
    ComponentWidth,
    TextareaSize,
} from '@trova-trip/trova-components';
import set from 'lodash/set';
import includes from 'lodash/includes';
import { Grid } from '@material-ui/core';
import Danger from '../../../../../components/Typography/Danger';
import { ServiceTypesKeys } from '../../../../../config/constants';
import ServiceTitle from '../ServiceTitle';
import FormSaver from '../../../../../util/form/FormSaver';
import ActionButtons from './Shared/ActionButtons';
import SaveToLibraryCheckbox from './Shared/SaveToLibraryCheckbox';
import { PaddedInput, PaddedTextarea, StyledCurrency } from './Form.components';
import {
    getColorFromServiceType,
    getServiceTitleLabel,
} from '../LibraryServiceFormatter';
import { ServiceFormPermissions, fieldNamesByServiceType } from '../helpers';

interface IAirportTransferCardFormProps {
    serviceToEdit: object;
    closeForm: () => void;
    updateItinerary: (e: Event) => void;
    errorText: string;
    currencyCode: string;
    disabledFields: string[];
    permissions?: ServiceFormPermissions;
}

interface IAirportTransferCardFormState {
    isNew: boolean;
}

class AirportTransferCardForm extends Component<
    IAirportTransferCardFormProps,
    IAirportTransferCardFormState
> {
    static propTypes: {
        serviceToEdit: Requireable<object>;
        closeForm: Requireable<(e: Event) => void>;
        updateItinerary: Requireable<(e: Event) => void>;
        errorText: Requireable<string>;
        currencyCode: Requireable<string>;
        disabledFields: Requireable<string[]>;
    };
    formSaver: FormSaver;

    constructor(props) {
        super(props);
        const { updateItinerary, serviceToEdit } = this.props;
        const defaultData = serviceToEdit
            ? serviceToEdit
            : {
                  saveToLibrary: false,
                  type: ServiceTypesKeys.TRANSFER,
                  airportTransfer: false,
                  numberOfTransfers: 0,
                  flightWindow: {},
                  length: 60,
              };
        this.state = { isNew: !serviceToEdit };
        this.formSaver = new FormSaver(
            this,
            (data) => {
                updateItinerary(data);
            },
            defaultData,
        );
    }

    handleDropdownChangeEvent = (event, name, value) => {
        const wrappedEvent = { ...event, target: { value, name } };
        this.formSaver.handleChange(wrappedEvent);
    };

    onRemoveAction = () => {
        this.formSaver.updateValue(`deleted`, true, () => {
            const serviceData = this.formSaver.getData();
            this.props.updateItinerary(serviceData);
        });
    };

    onChangeNumberWrapper = (event, name, value) => {
        const wrappedEvent = {
            ...event,
            target: { value, name, type: `number` },
        };
        this.formSaver.handleChange(wrappedEvent);
    };

    onChangeFlightWindowWrapper = (event, name) => {
        const value = event;
        const newFlightWindow = { ...this.formSaver.getData(`flightWindow`) };
        set(newFlightWindow, name, value);
        this.formSaver.updateValue(`flightWindow`, newFlightWindow);
    };

    render() {
        const {
            closeForm,
            errorText,
            currencyCode,
            disabledFields,
            permissions,
        } = this.props;
        const isAirPortTransfer = this.formSaver.getData(`airportTransfer`);
        const { start: flightWindowStart, end: flightWindowEnd } =
            this.formSaver.getData(`flightWindow`) || {};
        const serviceType = this.formSaver.getData(`type`);

        const { airportTransfer: fieldNames } = fieldNamesByServiceType;

        return (
            <Card backgroundColor={theme.colors.neutral.white}>
                <ServiceTitle
                    title={getServiceTitleLabel(serviceType)}
                    color={getColorFromServiceType(serviceType)}
                />
                <PaddedInput
                    name={fieldNames.name}
                    label='Name *'
                    size={ComponentWidth.Medium}
                    value={this.formSaver.getData(fieldNames.name)}
                    onChange={this.formSaver.handleChange}
                    disabled={includes(disabledFields, fieldNames.name)}
                />
                <PaddedInput
                    name={fieldNames.airport}
                    label='From City or Airport Code *'
                    size={ComponentWidth.Medium}
                    value={this.formSaver.getData(fieldNames.airport)}
                    onChange={this.formSaver.handleChange}
                    disabled={includes(disabledFields, fieldNames.airport)}
                />

                {this.formSaver.hasKey(fieldNames.price) && (
                    <StyledCurrency
                        name={fieldNames.price}
                        label='Price *'
                        size={ComponentWidth.Small}
                        value={this.formSaver.getData(fieldNames.price)}
                        onChange={this.formSaver.handleChange}
                        currencyType={currencyCode}
                        disabled={includes(disabledFields, fieldNames.price)}
                    />
                )}

                <PaddedTextarea
                    name={fieldNames.description}
                    placeholder='Description...'
                    label='Description'
                    size={TextareaSize.Medium}
                    value={this.formSaver.getData(fieldNames.description)}
                    onChange={this.formSaver.handleChange}
                    expandable={true}
                    disabled={includes(disabledFields, fieldNames.description)}
                />
                <SaveToLibraryCheckbox
                    value={this.formSaver.getData(fieldNames.saveToLibrary)}
                    onChange={this.formSaver.handleChange}
                    disabled={includes(
                        disabledFields,
                        fieldNames.saveToLibrary,
                    )}
                />
                {errorText && (
                    <Grid item xs={12}>
                        <Danger>{errorText}</Danger>
                    </Grid>
                )}
                <ActionButtons
                    serviceType={this.formSaver.getData(`type`)}
                    closeForm={closeForm}
                    submitForm={this.formSaver.handleFormSubmit}
                    onRemoveAction={this.onRemoveAction}
                    isNewEntity={this.state.isNew}
                    permissions={permissions}
                />
            </Card>
        );
    }
}

AirportTransferCardForm.propTypes = {
    serviceToEdit: PropTypes.object,
    closeForm: PropTypes.func,
    updateItinerary: PropTypes.func,
    errorText: PropTypes.string,
    currencyCode: PropTypes.string,
    disabledFields: PropTypes.array,
};
export default AirportTransferCardForm;
