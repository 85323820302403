import { css } from '@emotion/css';
import { theme } from '@trova-trip/trova-components';

export const ItineraryGridContainerStyle = css`
    display: grid;
    grid-template-columns: 0.25fr 1fr;
    gap: 20px 3%;
    grid-auto-flow: row;
    grid-template-areas:
        '. .'
        '. .';

    && {
        max-width: 960px;
    }
    @media screen and (min-width: ${theme.breakpoints
            .lg}) and (max-width: ${theme.breakpoints.xl}) {
        grid-template-columns: 0.25fr 0.7fr;
    }

    @media screen and (max-width: ${theme.breakpoints.md}) {
        grid-template-columns: 1fr;
        gap: 20px 0;
    }
`;
export const ItineraryGridHeaderColStyle = css`
    grid-column: span 2 / auto;
`;

export const ItineraryGridDAWrapperStyle = css`
    @media screen and (min-width: ${theme.breakpoints
            .md}) and (max-width: ${theme.breakpoints.lg}) {
        max-width: 500px;
    }
`;
