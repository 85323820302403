import { BaseBox, Chip, Stack } from '@trova-trip/trova-components';

interface ChipsDisplayProps {
    list?: string[];
    onRemove?: (value: string) => void;
}

const ChipsDisplay = ({
    list = [],
    onRemove,
}: ChipsDisplayProps): JSX.Element => {
    const onCloseChip = (chip: string) => {
        if (onRemove) {
            onRemove(chip);
        }
    };

    return (
        <Stack spacing={0}>
            {list.map((chip) => (
                <BaseBox padding={1}>
                    <Chip
                        key={chip}
                        label={chip}
                        onClose={() => onCloseChip(chip)}
                        isCloseable={Boolean(onRemove)}
                    />
                </BaseBox>
            ))}
        </Stack>
    );
};

export default ChipsDisplay;
