import React from 'react';
import './App.css';
import './config/logger.config';
import Main from './scenes/Main';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@trova-trip/trova-components';

const App: React.FC = () => {
    return (
        <ThemeProvider>
            <div className='App'>
                <BrowserRouter>
                    <Main />
                </BrowserRouter>
            </div>
        </ThemeProvider>
    );
};

export default App;
