import { businessConstants } from '@trova-trip/trova-common';
import { theme } from '@trova-trip/trova-components';
import { constants } from '@trova-trip/trova-models';
import useModelStatusMatcher from '../../../../../common/hooks/useModelStatusMatcher';
import { StatefulModel } from '../../../../../types';

const tierNames = constants.itinerary.TierNames;
const tiersSurveysRequired = constants.itinerary.TierSurveysRequired;

const tierColors = {
    1: theme.colors.seaGreen[700],
    2: theme.colors.purple[700],
    3: theme.colors.blue[700],
};

type CalculateNextTier = (
    hostTier: keyof typeof tierNames,
    isHostQualified: boolean,
) => number;

const calculateNextTier: CalculateNextTier = (hostTier, isHostQualified) => {
    const isExpertHost = hostTier === 3;
    const isMinimumTier = hostTier === 1;

    switch (true) {
        case isExpertHost:
            return hostTier;
        case isMinimumTier && !isHostQualified:
            return hostTier;
        default:
            return hostTier + 1;
    }
};

interface TierInfo {
    name: string;
    requiredResponses: number;
    color: string;
}

export interface UseHostTierInfoReturn {
    currentTier: Omit<TierInfo, 'color'>;
    nextTier: TierInfo;
}

const useHostTierInfo = (
    hostTier: keyof typeof tierNames,
): UseHostTierInfoReturn => {
    const defaultResponse = {
        currentTier: {
            name: tierNames[1],
            requiredResponses: tiersSurveysRequired[1],
        },
        nextTier: {
            name: tierNames[1],
            requiredResponses: tiersSurveysRequired[1],
            color: tierColors[1],
        },
    };
    if (!hostTier) {
        return defaultResponse;
    }

    const isHostQualified = useModelStatusMatcher({
        model: StatefulModel.PROFILE,
        matchingStatuses: businessConstants.QualifiedHostStatuses,
    });

    const currentTierName = tierNames[hostTier];
    const currentTierRequiredResponses = tiersSurveysRequired[hostTier];

    const nextTier = calculateNextTier(hostTier, isHostQualified);
    const nextTierName = tierNames[nextTier] as string;
    const nextTierRequiredResponses = tiersSurveysRequired[nextTier];
    const nextTierColor = tierColors[nextTier];

    return {
        currentTier: {
            name: currentTierName,
            requiredResponses: currentTierRequiredResponses,
        },
        nextTier: {
            name: nextTierName,
            requiredResponses: nextTierRequiredResponses,
            color: nextTierColor,
        },
    };
};

export default useHostTierInfo;
