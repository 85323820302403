import { coreUtils } from '@trova-trip/trova-common';
import {
    BaseBox,
    Icon,
    IconName,
    Skeleton,
    Spinner,
    Stack,
    theme,
} from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { TRAVELER_APP_MANAGE_BOOKING_ROUTES } from '../../../applications/common/constants/routesTokens';
import headerImage from '../../../assets/img/trovatrip-arial-isaland-2.jpg';
import TabDetailsInformation from '../../../components/TabDetailsInformation/TabDetailsInformation';
import { TravelerTripBadgeColors } from '../../../config/constants';
import { NavigationCategory } from '../../../interfaces/Navigation.types';
import Product from '../../../navigation/Product';
import { includesPathname } from '../../../navigation/common/helpers';
import { useSelector } from '../../../state/hooks/useSelector';
import { userTrips } from '../../../state/userTrips';
import { ProductHeader } from '../../common/components/ProductHeader';
import ProductRootWithMobileNavigationView from '../../common/components/ProductRootWithMobileNavigationView';
import { ROUTE_TOKENS } from '../../common/constants';
import {
    getBookingStatusFromTripStatus,
    isSecondaryHost,
    sortTripsByPriority,
} from '../../common/helpers';
import useUserTripsTravelerActions from '../../common/hooks/useUserTripsTravelerActions';
import useUserTripsTravelerFetcher from '../../common/hooks/useUserTripsTravelerFetcher';
import TravelerTerms from '../components/TravelerWaiver';
import {
    useBookingFetcher,
    useGroundTransferFetcher,
    useUserBookingsActions,
} from '../hooks';
import Itinerary from '../tabs/Itinerary/Itinerary';
import ManageBooking from '../tabs/ManageBooking';
import { useInitViewModel } from '../tabs/ManageBooking/hooks';
import {
    isPaymentPage,
    isSuccessPage,
} from '../tabs/ManageBooking/utils/route.utils';
import Preparation from '../tabs/Preparation/Preparation';
import TripForm from '../tabs/TripForm/TripForm';
import TripOverview from '../tabs/TripOverview/TripOverview';

const { getBookingStatus } = coreUtils.bookingUtils;

const { TRAVELER_APP_MAIN_TAB_ROUTE, TRAVELER_APP_PRODUCT_ROUTE } =
    ROUTE_TOKENS;

type SavedBooking = models.bookings.SavedBooking;
type TripModel = models.trips.Trip;
type UserProfile = models.users.User;

interface TripWithBooking extends TripModel {
    booking?: SavedBooking;
}

export const shouldDisplayTripsLoader = (
    isTripLoading: boolean,
    isTripTravelerUserLoading: boolean,
): boolean => {
    const isPaymentOrSuccessRoute = isPaymentPage() || isSuccessPage();
    const isPayBalanceOptionsRoute = includesPathname(
        TRAVELER_APP_MANAGE_BOOKING_ROUTES.PAY_BALANCE,
    );
    return (
        (isTripLoading || isTripTravelerUserLoading) &&
        !isPaymentOrSuccessRoute &&
        !isPayBalanceOptionsRoute
    );
};

const useModelData = () => {
    const { getRecord: getTrip, clearCurrentRecord: clearTrip } =
        userTrips.useDispatch();
    const { clearUserBookings } = useUserBookingsActions();

    const fetchModelData = useCallback(
        (id) => {
            clearTrip();
            clearUserBookings();
            getTrip(id);
        },
        [getTrip],
    );
    const { isCurrentTripLoading, trip } = useSelector((state) => ({
        trip: state.userTrips.current,
        isCurrentTripLoading: state.userTrips.fetching,
    }));

    //@ts-expect-error
    const booking = trip && trip.booking;

    const modelData = {
        ...trip,
        isCurrentTripLoading,
        booking: booking && {
            ...booking,
            status:
                getBookingStatus(booking) ||
                getBookingStatusFromTripStatus(trip.status),
        },
    };
    return { fetchModelData, modelData, clearTrip };
};

const getDataFromModel = (modelData) => {
    const isCurrentTripLoading = modelData
        ? modelData.isCurrentTripLoading
        : true;

    const tripId = modelData ? modelData.id : ``;
    const tripName = modelData ? modelData.name : ``;
    const tripCountry = modelData ? modelData.country : ``;
    const tripDestination = modelData ? modelData.destination : ``;
    const tripPreparationURL = modelData ? modelData.preparationListUrl : ``;
    const tripHeaderPhoto = modelData?.itinerary?.photos?.hero
        ? modelData.itinerary.photos.hero
        : headerImage;

    const bookingStatus = modelData?.booking?.status;
    const bookingOrderId = modelData?.booking?.orderId;
    const badgeColors = bookingStatus && TravelerTripBadgeColors[bookingStatus];
    const bookingStatusText = startCase(bookingStatus);

    return {
        isCurrentTripLoading,
        tripId,
        tripHeaderPhoto,
        tripName,
        tripCountry,
        tripDestination,
        tripPreparationURL,
        bookingOrderId,
        badgeColors,
        bookingStatusText,
    };
};

const redirectToMainTab = (trip: TripModel): JSX.Element => {
    const productPath = TRAVELER_APP_PRODUCT_ROUTE.TRIPS.replace('/', '');
    return (
        <Redirect
            to={`${productPath}/${trip.id}${TRAVELER_APP_MAIN_TAB_ROUTE}`}
        />
    );
};

const RootTrip = (): JSX.Element => {
    const [rootTrip, setRootTrip] = useState<TripModel>();
    const trips = useSelector((state) => state.userTrips.list?.records);
    const currentTraveler = useSelector(
        (state) => state.userTripsTraveler.list?.records?.[0],
    );

    // @ts-expect-error
    // type on the root store does not match with what it actually returns.
    const { status: travelerStatus } = currentTraveler || {};

    useEffect(() => {
        const getRootTrip = async () => {
            const prioritizedTrips: TripWithBooking[] | undefined =
                trips &&
                sortTripsByPriority(trips as TripWithBooking[], travelerStatus);
            const defaultTrip =
                prioritizedTrips && !isEmpty(prioritizedTrips)
                    ? prioritizedTrips[0]
                    : undefined;
            setRootTrip(defaultTrip);
        };
        getRootTrip();
    }, [trips]);

    return rootTrip ? (
        redirectToMainTab(rootTrip)
    ) : (
        <TabDetailsInformation
            title='Welcome!'
            description='Just click on a Trip to start reviewing'
        />
    );
};

const HeaderLoader = (): JSX.Element => (
    <BaseBox
        width={{ base: '120vw', lg: 'full' }}
        height={200}
        overflow={{ base: 'visible', lg: 'initial' }}
        marginLeft={{ base: -20, lg: 'initial' }}
    >
        <Skeleton height={200} width='full' borderRadius={{ lg: '3xl' }} />
    </BaseBox>
);

interface TripProps {
    profile: UserProfile;
    loadNavigationContent: () => NavigationCategory[];
}

const Trips = (props: TripProps): JSX.Element => {
    const { profile, loadNavigationContent } = props;
    const { fetchModelData, modelData, clearTrip } = useModelData();

    useBookingFetcher();

    useGroundTransferFetcher();

    useUserTripsTravelerFetcher();

    useInitViewModel();

    const { clearUserTripsTraveler } = useUserTripsTravelerActions();

    const { tripTravelerUser, isTripTravelerUserLoading } = useSelector(
        (state) => ({
            tripTravelerUser: state.userTripsTraveler.list?.records?.[0],
            isTripTravelerUserLoading: state.userTripsTraveler.fetching,
        }),
    );

    //@ts-expect-error
    const { travelerWaiver, type: userType } = tripTravelerUser || {};

    const isImpersonating =
        profile.impersonate &&
        Boolean(profile.impersonate.toString() !== 'user');

    const { dateSigned, minorDisclaimer } = travelerWaiver || {};
    const isSignedTravelerWaiver =
        dateSigned || minorDisclaimer || isImpersonating ? true : false;

    const showManageBooking = !isSecondaryHost(userType);
    const {
        isCurrentTripLoading,
        tripHeaderPhoto,
        tripName,
        tripCountry,
        tripDestination,
        tripPreparationURL,
        bookingOrderId,
        badgeColors,
        bookingStatusText,
    } = getDataFromModel(modelData);

    const renderHeaderSubTitle = () => {
        const hasTripDestinationInfo =
            tripCountry?.trim().length > 0 &&
            tripDestination?.trim().length > 0;
        return hasTripDestinationInfo
            ? {
                  icon: (
                      <Icon
                          name={IconName.Location}
                          color={theme.colors.red[400]}
                      />
                  ),
                  iconDescription: `${tripCountry}, ${tripDestination}`,
              }
            : {};
    };
    const renderTermsAgreement = ({ model }): JSX.Element | null => {
        return model.id && !isSignedTravelerWaiver ? (
            <TravelerTerms trip={model} />
        ) : null;
    };
    const renderTravelerPageTabs = ({
        model,
        isLoading,
    }): JSX.Element | null => {
        const shouldRender = tripTravelerUser && isSignedTravelerWaiver;

        if (isLoading) {
            return (
                <Stack
                    width='full'
                    height='40vh'
                    justify='center'
                    align='center'
                >
                    <Spinner />
                </Stack>
            );
        }

        return shouldRender ? (
            <>
                <TripOverview
                    tripData={{
                        ...model,
                        tripTravelerUser,
                    }}
                    profile={profile}
                />
                {showManageBooking ? (
                    <ManageBooking userType={userType} />
                ) : null}
                <Itinerary data={model} />
                <Preparation url={tripPreparationURL} />
                <TripForm
                    tripId={model.id}
                    passportRequired={model.passportRequired}
                    user={tripTravelerUser}
                    profile={profile}
                    onSubmit={clearUserTripsTraveler}
                />
            </>
        ) : (
            renderTermsAgreement({ model })
        );
    };

    const clearModelData = (): void => {
        if (!modelData._id) return;
        clearTrip();
    };

    const shouldDisplayLoader = shouldDisplayTripsLoader(
        isCurrentTripLoading,
        isTripTravelerUserLoading,
    );

    return (
        <Product
            path={TRAVELER_APP_PRODUCT_ROUTE.TRIPS}
            label='My Trips'
            icon='plane'
            loadNavigationContent={loadNavigationContent}
            fetchModelData={fetchModelData}
            clearModelData={clearModelData}
            header={
                shouldDisplayLoader ? (
                    <HeaderLoader />
                ) : (
                    <ProductHeader
                        backgroundImage={tripHeaderPhoto}
                        title={tripName}
                        badges={
                            bookingStatusText
                                ? [
                                      {
                                          color: badgeColors?.text,
                                          backgroundColor:
                                              badgeColors?.background,
                                          children: bookingStatusText,
                                      },
                                  ]
                                : undefined
                        }
                        detail={bookingOrderId}
                        {...renderHeaderSubTitle()}
                    />
                )
            }
            root={
                <ProductRootWithMobileNavigationView rootView={<RootTrip />} />
            }
        >
            {renderTravelerPageTabs({
                model: modelData,
                isLoading: shouldDisplayLoader,
            })}
        </Product>
    );
};

export default Trips;
