import { ButtonWithAnalytics } from '../../TripRequestForm/TripRequestForm';

type AddRoomButtonProps = {
    handleAdd: () => void;
};

const AddRoomButton = ({ handleAdd }: AddRoomButtonProps) => (
    <ButtonWithAnalytics
        as='button'
        variant='secondary'
        size='md'
        rightIcon='plus'
        onClick={handleAdd}
        analyticsData={{
            type: 'onClick',
            eventName: 'Host Added Room',
        }}
    >
        Add a Room
    </ButtonWithAnalytics>
);

export default AddRoomButton;
