import { models } from '@trova-trip/trova-models';
import { useSelector } from '../../../../../state/hooks';
import type { DefaultProps } from '../types';
import {
    trackClearCartEvent,
    trackModifyBookingEvent,
    trackUpdateCartEvent,
    trackViewManageBookingTabEvent,
    trackViewPortalCheckoutPageEvent,
} from '../utils/analytics.utils';

type BaseItinerary = models.itineraries.BaseItinerary;

const useAnalytics = () => {
    const { currentTrip, currentBooking, userProfile } = useSelector(
        (state) => ({
            currentTrip: state.userTrips?.current,
            currentBooking: state.userBookings?.current?.[0],
            userProfile: state.profile?.current,
        }),
    );

    const defaultProps: DefaultProps = {
        userId: userProfile?.id,
        tripName: currentTrip?.name,
        tripId: currentTrip?.id,
        bookingId: currentBooking?._id,
        itineraryId: (currentTrip?.itinerary as BaseItinerary)?.id,
    };

    const withDefaultProps = <T extends DefaultProps, R>(
        originalFn: (props: T) => R,
    ) => {
        return (extraProps: Omit<T, keyof DefaultProps>) => {
            const props = { ...defaultProps, ...extraProps } as T;
            return originalFn(props);
        };
    };

    return {
        trackViewManageBookingTabEvent: withDefaultProps(
            trackViewManageBookingTabEvent,
        ),
        trackUpdateCartEvent: withDefaultProps(trackUpdateCartEvent),
        trackViewPortalCheckoutPageEvent: withDefaultProps(
            trackViewPortalCheckoutPageEvent,
        ),
        trackClearCartEvent: withDefaultProps(trackClearCartEvent),
        trackModifyBookingEvent: withDefaultProps(trackModifyBookingEvent),
    };
};

export default useAnalytics;
