import { Grid, Hide } from '@trova-trip/trova-components';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import useHostTierInfo from '../../../dashboard/hooks/useHostTierInfo';
import { HostAudienceData } from '../../types';
import ResponsesTabs from './ResponsesTabs';
import {
    NextItineraryTier,
    QualifiedResponses,
    TotalResponses,
} from './sections';

interface AudienceMobileContentProps {
    hostAudience: HostAudienceData;
    audienceRoute: string;
}

const AudienceContent = (props: AudienceMobileContentProps) => {
    const { hostAudience, audienceRoute } = props;
    const { responses, tier } = hostAudience;

    const hostTierInfo = useHostTierInfo(tier);
    const history = useHistory();

    const QualifiedResponsesElement = useMemo(
        () => (
            <QualifiedResponses
                hostTierInfo={hostTierInfo}
                qualifiedResponses={responses.qualified}
                onClick={() => history.push(audienceRoute)}
            />
        ),
        [hostTierInfo, responses.qualified, audienceRoute],
    );

    const TotalResponsesElement = useMemo(
        () => (
            <TotalResponses
                totalResponses={responses.total}
                onClick={() => history.push(audienceRoute)}
            />
        ),
        [responses.total, audienceRoute],
    );

    const mobileTabs = [
        {
            label: 'Qualified Responses',
            element: QualifiedResponsesElement,
        },
        {
            label: 'Total Responses',
            element: TotalResponsesElement,
        },
    ];

    return (
        <>
            <Hide breakpoint={{ min: 'md' }}>
                <Grid.Item columnSpan={12}>
                    <ResponsesTabs tabs={mobileTabs} />
                </Grid.Item>
            </Hide>
            <Hide breakpoint={{ max: 'md' }}>
                <Grid.Item columnSpan={8} height='full'>
                    {QualifiedResponsesElement}
                </Grid.Item>
                <Grid.Item height='full' columnSpan={4}>
                    {TotalResponsesElement}
                </Grid.Item>
            </Hide>
            <Grid.Item columnSpan={12}>
                <NextItineraryTier
                    hostTierInfo={hostTierInfo}
                    progressValue={responses.qualified}
                />
            </Grid.Item>
        </>
    );
};

export default AudienceContent;
