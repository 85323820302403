import yup from 'yup';

type YupValidationError = yup.ValidationError;
type ValidationError = Record<string, string>;

const formatErrors = (
    error: YupValidationError,
): ValidationError | undefined => {
    if (!error.inner) return;

    const fieldErrors = error.inner.reduce(
        (errorMap: ValidationError, error: YupValidationError) => {
            const { path, message } = error;
            return { ...errorMap, [path as string]: message };
        },
        {},
    );
    return fieldErrors;
};

export { formatErrors };
export type { YupValidationError, ValidationError };
