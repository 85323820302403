import { Stack } from '@trova-trip/trova-components';
import { IconProps } from '@trova-trip/trova-components/build/next';
import { models } from '@trova-trip/trova-models';
import { isTransfersEnabled } from '../../../../../../config/constants';
import Tab from '../../../../../../navigation/Tab';
import { useSelector } from '../../../../../../state/hooks/useSelector';
import PrePostAccommodationCard from '../../../../../common/components/PrePostAccommodationCard';
import {
    checkHostAccommodationsSelection,
    getTripRelatedStringDates,
    isTripCancelled,
    isTripComplete,
} from '../../../../../common/helpers';
import { useUserTripsTravelerFetcher } from '../../../../../common/hooks';
import { UpcomingServicesCard } from '../../../../../common/products/transfer/components';
import ScheduledTransferCard from '../../../../../common/products/transfer/components/ScheduledTransferCard';
import TripForm from '../../../../components/TripForm/TripForm';
import { BaseTabProps } from '../Tabs.types';
import TravelerInfoTabStickyLayout from './TravelerInfoTabStickyLayout';

type Trip = models.trips.Trip;

type AccommodationsCardProps = { trip: Trip };

const AccommodationsCard = ({ trip }: AccommodationsCardProps) => {
    const { hasPreAccommodation, hasPostAccommodation } =
        checkHostAccommodationsSelection(trip);

    const shouldDisplayCard = hasPreAccommodation || hasPostAccommodation;

    if (!shouldDisplayCard) {
        return null;
    }

    const { preTripDate, postTripDate } = getTripRelatedStringDates(trip);

    const iconProps: IconProps = {
        as: 'checkedCircle',
        display: 'flex',
        color: 'teal.trova',
        size: 'lg',
    };

    const preAccommodationProps = hasPreAccommodation
        ? {
              description: 'Arriving one night before the trip starts.',
              date: preTripDate,
              iconProps,
          }
        : undefined;

    const postAccommodationProps = hasPostAccommodation
        ? {
              description: 'Staying one night after the trip ends.',
              date: postTripDate,
              iconProps,
          }
        : undefined;

    return (
        <PrePostAccommodationCard
            mode='read-only'
            preAccommodation={preAccommodationProps}
            postAccommodation={postAccommodationProps}
        />
    );
};

const TravelInfoTab = (props: BaseTabProps): JSX.Element | null => {
    const { hideNavigation, disabled } = props;

    const { trip, itinerary, user, tripTravelerUser } = useSelector(
        (state) => ({
            trip: state.userTrips.current,
            itinerary: state.userItinerary.current,
            user: state.profile.current,
            tripTravelerUser: state.userTripsTraveler.list?.records?.[0],
        }),
    );

    useUserTripsTravelerFetcher();

    const userCountryCode = tripTravelerUser?.passportInformation?.nationality;

    if (!trip) {
        return null;
    }

    const showUpcomingServicesCard =
        isTransfersEnabled && !isTripComplete(trip) && !isTripCancelled(trip);

    return (
        <Tab
            label={'Travel Info'}
            path={'/travel-info'}
            disabled={disabled}
            hideNavigation={hideNavigation}
        >
            <Stack spacing={6} direction='column'>
                {showUpcomingServicesCard ? (
                    <UpcomingServicesCard
                        trip={trip}
                        itinerary={itinerary}
                        userCountryCode={userCountryCode}
                        user={user}
                    />
                ) : null}

                <AccommodationsCard trip={trip} />

                <ScheduledTransferCard />
                <TravelerInfoTabStickyLayout>
                    {tripTravelerUser ? <TripForm /> : null}
                </TravelerInfoTabStickyLayout>
            </Stack>
        </Tab>
    );
};

export default TravelInfoTab;
