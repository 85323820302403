import { BaseBox, Label, Stack, Text } from '@trova-trip/trova-components';
import { Textarea } from '@trova-trip/trova-components/build/next';
import { Dispatch, ReactNode, SetStateAction, SyntheticEvent } from 'react';
import * as yup from 'yup';
import ProfileImageUploader from '../../../../common/components/ProfileImageUploader';
import { Bio as BioType } from '../types';
import OnboardingStepHeader from './common/OnboardingStepHeader';

type BioProps = {
    bio: BioType;
    setBio: Dispatch<SetStateAction<BioType>>;
};

const bioChars = {
    max: 350,
};

const bioSchema = yup.object().shape({
    profilePictureUrl: yup.string().optional(),
    bio: yup.string().notRequired().max(bioChars.max),
});

const isValidBioData = (bio: BioType): boolean => {
    try {
        bioSchema.validateSync(bio);
        return true;
    } catch (error) {
        return false;
    }
};

interface FormControlProps {
    name: keyof BioType;
    label: string;
    field: ReactNode;
}

const FormControl = ({ name, label, field }: FormControlProps): JSX.Element => (
    <Stack direction='column' align='stretch' spacing={2}>
        <Stack align='center' spacing={2}>
            <Label id={`label-${name}`} htmlFor={name}>
                {label}
            </Label>
        </Stack>
        {field}
    </Stack>
);

const Form = (props: BioProps): JSX.Element => {
    const { bio, setBio } = props;

    const handleFieldChange = (event: SyntheticEvent) => {
        const { name, value } = event.target as HTMLInputElement;
        setBio({
            ...bio,
            [name]: value,
        });
    };

    return (
        <Stack direction='column' align='stretch' spacing={4}>
            <FormControl
                name='profilePictureUrl'
                label='Upload Profile Picture'
                field={
                    <ProfileImageUploader
                        name='profilePictureUrl'
                        label='Tap to Upload'
                        variant='horizontal'
                        value={bio.profilePictureUrl as string}
                        onChange={handleFieldChange}
                    />
                }
            />
            <FormControl
                name='bio'
                label='Profile Bio'
                field={
                    <Textarea
                        name='bio'
                        value={bio.bio as string}
                        onChange={handleFieldChange}
                        maxLength={bioChars.max}
                        placeholder='Introduce yourself to your travelers...'
                    />
                }
            />
        </Stack>
    );
};

const Bio = (props: BioProps): JSX.Element => (
    <Stack
        direction='column'
        spacing={{ base: 4, md: 6 }}
        width={{ base: 'full', md: '80%' }}
    >
        <OnboardingStepHeader
            title='Create Your Profile'
            lead='Add your profile picture and bio to introduce yourself on your public profile page.'
        />
        <BaseBox width='full'>
            <Form {...props} />
        </BaseBox>
    </Stack>
);

export { isValidBioData };
export type { BioProps };
export default Bio;
