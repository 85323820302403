import { TripStoreSelector } from 'state/trips';
import { useSelector } from 'react-redux';
import { coreUtils, appConstants } from '@trova-trip/trova-common';
import { APP_ENVIRONMENT } from 'applications/utils';

const useTripPageUrl = (): string => {
    const trip = useSelector(
        ({ userTrips }: TripStoreSelector) => userTrips.current,
    );
    const { tripPath } = trip;
    const environment = APP_ENVIRONMENT as appConstants.Environment;
    const tripUrl = coreUtils.tripPageUtils.getTrovaTripPageUrl(tripPath, environment);
    return tripUrl;
};

export default useTripPageUrl;
