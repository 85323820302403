/**
 * The type of the navigation event that took place.
 */
export enum NavigationEventType {
    /**
     * Navigation to a create page took place.
     */
    NEW = 'NEW',
    /**
     * Navigation to a product's root page took place.
     */
    ROOT = 'ROOT',
    /**
     * Navigation to a product's detail page took place.
     */
    DETAILS = 'DETAILS',
}

export type NavigationEventUserData = Record<string, unknown>;

/**
 * An event emitted when the user navigates to a page within a product.
 */
export interface NavigationEvent {
    /**
     * They type of navigation event.
     */
    type: NavigationEventType;
    /**
     * The parameters specific to the type of navigation event.
     */
    params: NavigationEventParams;
    /**
     * Any additional parameters specified by the user.
     */
    userData: Record<string, unknown>;
}

/**
 * The base parameters of a navigation event.
 */
export interface NavigationEventParams {
    path: string;
}

/**
 * The parameters for a navigation details event.
 */
export interface DetailsNavigationEventParams extends NavigationEventParams {
    id: string;
}

/**
 * A DetailsNavigationEvent represents a navigation event to a product's details page.
 */
export interface DetailsNavigationEvent extends NavigationEvent {
    params: DetailsNavigationEventParams;
}

/**
 * A NewNavigationEvent represents a navigation event to a create page.
 */
export interface NewNavigationEvent extends NavigationEvent {}

/**
 * A RootNavigationEvent represents a navigation event to a product's root page.
 */
export interface RootNavigationEvent extends NavigationEvent {}

class NavigationEventBuilder {
    /**
     * Create a DetailsNavigationEvent.
     * @param path - the path that triggered the navigation event.
     * @param id - the id of the product that was navigated to.
     * @param userData - optional user data.
     * @returns
     */
    public details(
        path: string,
        id: string,
        userData: NavigationEventUserData = {},
    ): DetailsNavigationEvent {
        return {
            type: NavigationEventType.DETAILS,
            params: { path: path, id },
            userData: userData,
        };
    }

    /**
     * Create a RootNavigationEvent.
     * @param path - the path that triggered the navigation event.
     * @param userData - optional user data.
     * @returns
     */
    public root(
        path: string,
        userData: NavigationEventUserData = {},
    ): RootNavigationEvent {
        return {
            type: NavigationEventType.ROOT,
            params: { path: path },
            userData,
        };
    }

    /**
     * Create a NewNavigationEvent.
     * @param path - the path that triggered the navigation event.
     * @param userData - optional user data.
     * @returns
     */
    public new(
        path: string,
        userData: NavigationEventUserData = {},
    ): NewNavigationEvent {
        return {
            type: NavigationEventType.NEW,
            params: { path: path },
            userData,
        };
    }
}

export default new NavigationEventBuilder();
