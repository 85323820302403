import { appConstants, coreUtils } from '@trova-trip/trova-common';
import { Alert, AlertProps, Grid, Stack } from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import noop from 'lodash/noop';
import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import TabDetailsInformation from '../../../../components/TabDetailsInformation/TabDetailsInformation';
import Tab from '../../../../navigation/Tab';
import { useSelector } from '../../../../state/hooks';
import useDynamicContent from '../../../../util/hooks/useDynamicContent';
import LeaveConfirmationPrompt from '../../../common/components/LeaveConfirmationPrompt';
import TabPageDescription from '../../../common/components/TabPageDescription';
import {
    TRAVELER_APP_MANAGE_BOOKING_ROUTES,
    TRAVELER_APP_TAB_ROUTE,
} from '../../../common/constants/routesTokens';
import {
    doesBookingHaveInsurance,
    hasDifferentTransferDatesFromTrip,
    isPrimaryTraveler,
} from '../../../common/helpers';
import useBeforeUnloadPrompt from '../../../common/hooks/useBeforeUnloadPrompt';
import useIsLargeScreen from '../../../common/hooks/useIsLargeScreen';
import ScheduledTransferCard from '../../../common/products/transfer/components/ScheduledTransferCard';
import AddOnsList from './components/AddonsList';
import CancelBooking from './components/CancelBooking';
import InsuranceSection from './components/InsuranceSection';
import PayBalanceOptionsPage from './components/PayBalanceOptionsPage';
import PaymentPage from './components/PaymentPage';
import SuccessPage from './components/SuccessPage';
import TransactionHistoryTable from './components/TransactionHistoryTable';
import TravelersInfoTable from './components/TravelersInfoTable';
import TripSummary from './components/TripSummary';
import {
    useManageBooking,
    useManageBookingNavigation,
    useTripSummaryContent,
} from './hooks';
import useAnalytics from './hooks/useAnalytics';
import {
    BookingWithTrip,
    MANAGE_BOOKING_ADDON_STATUS,
    ServiceTiming,
    Trip,
} from './types';
import {
    checkAccommodationStatus,
    isUserInsuranceCancelled,
} from './utils/check.utils';

const { pluralize } = coreUtils.objectUtils;

const { DEFAULT_BOOKINGS_DEADLINE } = appConstants;

interface ManageBookingProps {
    userType: string;
}

interface HeaderProps {
    isBookingEditable: boolean;
    bookingsDeadline: number | undefined;
}

const TAB_LABEL = 'Manage Booking';

const getBypassConfirmPaths = (tripId?: string): string[] => [
    `${tripId}${TRAVELER_APP_TAB_ROUTE.MANAGE_BOOKING}`,
    `${tripId}${TRAVELER_APP_TAB_ROUTE.TRIP_OVERVIEW}`,
    `${tripId}${TRAVELER_APP_TAB_ROUTE.ITINERARY}`,
    `${tripId}${TRAVELER_APP_TAB_ROUTE.PREPARATION}`,
    `${tripId}${TRAVELER_APP_TAB_ROUTE.TRIP_FORM}`,
    `${tripId}${TRAVELER_APP_MANAGE_BOOKING_ROUTES.PAYMENT}`,
    `${tripId}${TRAVELER_APP_MANAGE_BOOKING_ROUTES.SUCCESS}`,
];

const Header = ({
    isBookingEditable,
    bookingsDeadline = DEFAULT_BOOKINGS_DEADLINE,
}: HeaderProps): JSX.Element => {
    const {
        actions: { openTripSummary },
    } = useManageBooking();
    const { pendingChanges } = useTripSummaryContent();

    const isDesktop = useIsLargeScreen({ includeTabletResolution: true });

    const showPendingChangesButton = isDesktop && pendingChanges;

    const TAB_PAGE_DESCRIPTION = `Review ${
        isBookingEditable ? 'and edit' : ''
    } your accommodations, add-ons, and
    transfers before your trip. Your booking will be final
    ${bookingsDeadline} days before the start date.`;

    return (
        <Grid>
            <Grid.Item columnSpan={12}>
                <Stack align='center' justify='space-between'>
                    <TabDetailsInformation title={TAB_LABEL} />
                    {showPendingChangesButton ? (
                        <Button
                            size='lg'
                            leftIcon='shoppingCart'
                            onClick={() => openTripSummary()}
                        >
                            {pluralize(pendingChanges, 'Change', 'Changes')}
                        </Button>
                    ) : null}
                </Stack>
                <TabPageDescription>{TAB_PAGE_DESCRIPTION}</TabPageDescription>
            </Grid.Item>
        </Grid>
    );
};

interface ManageBookingAlertProps
    extends Partial<Pick<AlertProps, 'title' | 'status' | 'description'>> {}

const ManageBookingAlert = (props: ManageBookingAlertProps): JSX.Element => {
    const { title = '', status = 'info', description = '' } = props;
    return (
        <Alert
            title={title}
            onCloseAlert={noop}
            isClosable={false}
            variant='inline'
            status={status}
            description={description}
        />
    );
};

const RefundPendingAlert = ({ userType }): JSX.Element | null => {
    const {
        state: {
            tripSummary: {
                bookingPaymentData: { dueAmount },
            },
        },
    } = useManageBooking();

    const isPrimaryUser = isPrimaryTraveler(userType);

    if (!isPrimaryUser || dueAmount >= 0) return null;

    return (
        <ManageBookingAlert
            title='Edits to booking unavailable'
            description='We are currently processing your refund. Please check back when your refund is complete to make any other changes to your booking.'
        />
    );
};

const DifferentTransferDatesFormTripAlert = (): JSX.Element | null => {
    const {
        state: {
            addOns: { accommodations },
        },
    } = useManageBooking();

    const { trip, userGroundTransfers } = useSelector((state) => ({
        trip: state.userTrips?.current,
        userGroundTransfers: state.userGroundTransfers.list,
    }));

    const isPreAccommodationBooked = checkAccommodationStatus(
        MANAGE_BOOKING_ADDON_STATUS.CONFIRMED,
        accommodations[ServiceTiming.PRE_TRIP],
    );

    const isPostAccommodationBooked = checkAccommodationStatus(
        MANAGE_BOOKING_ADDON_STATUS.CONFIRMED,
        accommodations[ServiceTiming.POST_TRIP],
    );

    const shouldShowAlert = hasDifferentTransferDatesFromTrip({
        trip,
        userGroundTransfers,
        shouldCheckPreTripDate: isPreAccommodationBooked,
        shouldCheckPostTripDate: isPostAccommodationBooked,
    });

    if (!shouldShowAlert) return null;

    return (
        <ManageBookingAlert
            status='warning'
            description='Your selected transfer date is different than your accommodation date, please be sure you are reserving a transfer on the correct date.'
        />
    );
};

const RequiredInsuranceAlert = (): JSX.Element | null => {
    const {
        globalState: { trip, user },
        state: {
            addOns: { insurance: insuranceAddOns },
        },
    } = useManageBooking();

    const insuranceIsRequired = trip?.travelerInsuranceRequired;

    const insuranceIsCancelled = isUserInsuranceCancelled(
        insuranceAddOns,
        user,
    );

    const shouldShowAlert = insuranceIsRequired && insuranceIsCancelled;

    if (!shouldShowAlert) return null;

    return (
        <ManageBookingAlert
            title='Insurance required for this trip'
            status='warning'
            description='You will need to purchase insurance from a third party to go on this trip.'
        />
    );
};

interface AlertsGroupProps {
    userType: string;
}

const AlertsGroup = (props: AlertsGroupProps): JSX.Element => {
    const { userType } = props;
    return (
        <Stack direction='column' spacing={6} marginTop={6}>
            <RefundPendingAlert userType={userType} />
            <DifferentTransferDatesFormTripAlert />
            <RequiredInsuranceAlert />
        </Stack>
    );
};

const ManageBooking = ({
    userType,
}: ManageBookingProps): JSX.Element | null => {
    const { currentTrip, currentBooking, tripDetails, userProfile } =
        useSelector((state) => ({
            currentTrip: state.userTrips?.current,
            currentBooking: state.userBookings?.current?.[0],
            tripDetails: state.userTripDetails?.current,
            userProfile: state.profile?.current,
        }));

    const { status: tripStatus } = currentTrip || {};

    const booking = {
        ...currentBooking,
        trip: currentTrip || ({} as Trip),
    } as BookingWithTrip;

    const {
        actions: { setIsLoading },
        state: {
            isLoading,
            isBookingEditable,
            isManageBookingFlowFinished,
            currentTripId,
        },
    } = useManageBooking();

    const { pendingChanges } = useTripSummaryContent();

    const { getManageBookingTabPath } = useManageBookingNavigation();

    const { trackViewManageBookingTabEvent } = useAnalytics();

    const isBookingAvailable = !!booking?._id;
    const isAllDataAvailable =
        !!currentBooking && !!currentTrip && !!tripDetails;

    useEffect(() => {
        setIsLoading(!isAllDataAvailable);
    }, [isAllDataAvailable]);

    const shouldTrackViewManageBookingTabEvent =
        isBookingAvailable && isAllDataAvailable && !!userProfile?.id;

    useEffect(() => {
        shouldTrackViewManageBookingTabEvent &&
            trackViewManageBookingTabEvent({ tripStatus: currentTrip?.status });
    }, [shouldTrackViewManageBookingTabEvent]);

    const isPrimaryUser = isPrimaryTraveler(userType);

    const { shouldShowPaymentsHistory, shouldShowCancelBookingButton } =
        useDynamicContent(isPrimaryUser, tripStatus, currentBooking);

    const shouldShowInsuranceSection = doesBookingHaveInsurance(currentBooking);

    useBeforeUnloadPrompt(!!pendingChanges && !isManageBookingFlowFinished);

    const shouldShowScheduledTransferCard = !!currentBooking;

    const tabContent = (
        <>
            <Header
                isBookingEditable={isBookingEditable}
                bookingsDeadline={booking.trip.bookingsDeadline}
            />

            <AlertsGroup userType={userType} />

            <AddOnsList booking={booking} trip={booking.trip} />

            {shouldShowScheduledTransferCard ? <ScheduledTransferCard /> : null}

            {shouldShowInsuranceSection ? (
                <InsuranceSection userType={userType} />
            ) : null}

            <TravelersInfoTable booking={booking} />

            {shouldShowPaymentsHistory ? (
                <TransactionHistoryTable
                    payments={booking.payments}
                    paidAmount={booking.paidAmount}
                    dueAmount={booking.dueAmount}
                />
            ) : null}

            {shouldShowCancelBookingButton ? <CancelBooking /> : null}

            <TripSummary />
        </>
    );

    return (
        <Tab
            label={TAB_LABEL}
            path={TRAVELER_APP_TAB_ROUTE.MANAGE_BOOKING}
            disabled={isLoading}
        >
            <LeaveConfirmationPrompt
                when={!!pendingChanges}
                bypassConfirmPaths={getBypassConfirmPaths(currentTripId)}
            />

            <Switch>
                <Route
                    path={getManageBookingTabPath('pay-balance')}
                    component={PayBalanceOptionsPage}
                />
                <Route
                    path={getManageBookingTabPath('payment')}
                    component={PaymentPage}
                />
                <Route
                    path={getManageBookingTabPath('success')}
                    component={SuccessPage}
                />

                <Route>{isAllDataAvailable ? tabContent : null}</Route>
            </Switch>
        </Tab>
    );
};

export default ManageBooking;
