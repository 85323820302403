import { Stack } from '@trova-trip/trova-components';
import StickyContentTripPageStatus from 'applications/host/components/StickyContent/StickyContentTripPageStatus';
import StickyContentLayout from 'components/StickyContentLayout/StickyContentLayout';
import Tab from '../../../../navigation/Tab';
import GeneralDetailsForm from '../../components/GeneralDetailsForm/GeneralDetailsForm';
import InformalGatherings from '../../components/InformalGatherings/InformalGatherings';
import WorkshopDetails from '../../components/WorkshopDetails/WorkshopDetails';
import { BaseTabProps } from '../../products/trips/tabs/Tabs.types';

const Configuration = (props: BaseTabProps): JSX.Element => {
    const { hideNavigation, disabled } = props;
    return (
        <Tab
            label={'Configuration'}
            path={'/configuration'}
            hideNavigation={hideNavigation}
            disabled={disabled}
        >
            <StickyContentLayout
                stickyContent={<StickyContentTripPageStatus />}
                mainContent={
                    <StickyContentLayout observableContent>
                        <Stack direction='column' spacing={14}>
                            <GeneralDetailsForm />
                            <WorkshopDetails />
                            <InformalGatherings />
                        </Stack>
                    </StickyContentLayout>
                }
            />
        </Tab>
    );
};

export default Configuration;
