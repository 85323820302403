import { css } from '@emotion/css';
import { theme } from '@trova-trip/trova-components';

export const styledStatusColumn = css`
    && {
        display: flex;
        align-items: center;
        font-family: ${theme.fonts.roboto};
        font-weight: ${theme.fontWeights.bold};

        > svg {
            overflow: visible;
        }

        > span {
            margin-left: 8px;
        }
    }
`;

export const stylePaidColumn = css`
    && {
        display: flex;
        align-items: center;
        > span {
            margin-left: 8px;
        }
    }
`;
