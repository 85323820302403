import styled from '@emotion/styled';
import { theme } from '@trova-trip/trova-components';

export const StyledContentContainer = styled.div`
    && {
        button {
            padding: 0;
            margin-bottom: 5px;

            div {
                padding: 0;
            }
        }
    }

    h2 {
        margin-bottom: 15px;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        max-width: 60%;
        margin-right: 34px;

        && {
            button {
                min-height: 25px;
                margin-bottom: 20px;
            }
        }

        div {
            display: flex;
            align-items: flex-end;
        }

        h2 {
            margin-right: 25px;
            margin-bottom: 0px;
        }

        p {
            color: ${theme.colors.neutral.black};
        }

        button > div {
            font-size: 16px;
        }
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        align-items: flex-end;
        max-width: none;

        h2 {
            margin-right: 32px;
        }
    }
`;

export const StyledActionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    @media (min-width: ${theme.breakpoints.md}) {
        justify-content: stretch;
        margin-top: 0;
    }
`;

export const StyledMainContainer = styled.div`
    margin-top: -5px;
    text-align: center;

    @media (min-width: ${theme.breakpoints.md}) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        max-width: none;
    }
`;
