import { useState, useEffect } from 'react';
import useCurrencies from './useCurrencies';

const useCurrencyCode = currentCurrencyId => {
    const [currencyState, setCurrencyState] = useState();
    const { currencies } = useCurrencies();

    const getCurrencyCodeFromId = () => {
        const currentCurrency = currencies.find(
            currency => currency.id === currentCurrencyId
        );
        if (currentCurrency && currentCurrency.code) {
            setCurrencyState(currentCurrency.code);
        }
    };

    useEffect(
        () => {
            if (currencies) {
                getCurrencyCodeFromId();
            }
        },
        [currencies, currentCurrencyId]
    );

    return currencyState;
};

export default useCurrencyCode;
