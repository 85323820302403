import { useSelector } from '../../../state/hooks/useSelector';
import { userTripsTraveler } from '../../../state/tripsTraveler';

interface UseUserTripsTravelerActionsReturn {
    /**
     * Fetches user trips traveler for a provided trip and user and sets them on the global store.
     *
     * @param tripId - The id of the trip for which travelers are to be fetched.
     * @return void
     */
    loadUserTripsTraveler: (tripId: string | undefined) => void;

    /**
     * Clears the current user trips traveler from the global store.
     */
    clearUserTripsTraveler: () => void;
}

/**
 * Hook to get the functions to interact with the `userTripsTraveler` global store slice.
 *
 * @returns UseUserTripsTravelerActionsReturn - An object containing functions to fetch and clear user trips traveler.
 */
const useUserTripsTravelerActions = (): UseUserTripsTravelerActionsReturn => {
    const trip = useSelector((state) => state.userTrips.current);

    const {
        listRecords: listTripTraveler,
        clearCurrentList: clearUserTripsTraveler,
    } = userTripsTraveler.useDispatch.bind(trip)();

    const loadUserTripsTraveler = (tripId: string | undefined): void => {
        listTripTraveler(undefined, { tokens: { tripId } });
    };

    return {
        loadUserTripsTraveler,
        clearUserTripsTraveler,
    };
};

export default useUserTripsTravelerActions;
