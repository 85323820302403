import {
    SectionCard,
    Stack,
    useReveal,
    useToast,
} from '@trova-trip/trova-components';
import { Button, Menu } from '@trova-trip/trova-components/build/next';
import isSameDay from 'date-fns/isSameDay';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from '../../../../../state/hooks';
import { checkIfInventoryItemIsInUse } from '../../../../common/helpers';
import useIsLargeScreen from '../../../../common/hooks/useIsLargeScreen';
import useItineraryInventory from '../../../../common/hooks/useItineraryInventory';
import useAnalytics from '../../hooks/useAnalytics';
import { ItineraryInventoryPopUp } from '../Itinerary/ItineraryInventoryPopUp';
import { InventoryItemFormSubmitFn } from './ItineraryInventoryForm';
import { getInitialValues, scrollTo } from './ItineraryInventoryForm/helpers';
import {
    BaseItinerary,
    CreateItineraryInventoryItemInput,
    ItineraryInventoryItem,
    UpdateItineraryInventoryItemInput,
} from './ItineraryInventoryForm/types';
import ItineraryInventoryTable, {
    SelectAction,
} from './ItineraryInventoryTable';

interface PricingValues {
    minSpots: number;
    maxSpots: number;
    currencyCode: string;
}

interface ItineraryInventoryCardProps {
    pricingValues: PricingValues;
    onInventoryItemLoad: (items: ItineraryInventoryItem[]) => void;
}

type ItineraryInventoryFilters = {
    year?: number;
};

const ItineraryInventoryCard = ({
    pricingValues,
    onInventoryItemLoad,
}: ItineraryInventoryCardProps): JSX.Element => {
    const itinerary = useSelector((store) => store.userItinerary.current);

    const formContainerRef = useRef<HTMLDivElement | null>(null);

    const [selectedInventoryItem, setSelectedInventoryItem] = useState<
        ItineraryInventoryItem | undefined
    >(undefined);

    const [filters, setFilters] = useState<ItineraryInventoryFilters>({
        year: undefined,
    });

    const [errorMessage, setErrorMessage] = useState<string | undefined>(
        undefined,
    );

    const {
        trackStartItineraryInventoryCreation,
        trackCreateItineraryInventoryError,
        trackStartItineraryInventoryEdition,
    } = useAnalytics();

    const {
        fetchInventoryItems,
        createInventoryItem,
        updateInventoryItem,
        deleteInventoryItem,
        getInventoryItemById,
        getInventoryYearsAvailable,
        getInventoryItemsStartDates,
        filterItineraryItemsByYear,
        formatInventoryItemsToDisplay,
        isLoading,
        inventoryItems,
    } = useItineraryInventory();

    const {
        isOpen: isPopUpOpen,
        triggerClose: triggerPopUpClose,
        triggerOpen: triggerPopUpOpen,
    } = useReveal();

    const toast = useToast();
    const isMobile = !useIsLargeScreen({ includeTabletResolution: true });

    useEffect(() => {
        fetchInventoryItems();
    }, []);

    useEffect(() => {
        if (!filters.year && inventoryItems.length > 0) {
            onInventoryItemLoad(inventoryItems);
            const yearsAvailable = getInventoryYearsAvailable(inventoryItems);
            setFilters({ year: yearsAvailable[0] });
        }
    }, [inventoryItems]);

    const showSuccessToast = (action: 'create' | 'update' | 'delete'): void => {
        toast({
            title: `Itinerary Saved`,
            description: `The inventory item has been ${action}d`,
            status: 'success',
            isClosable: true,
        });
    };

    const create = async (payload: CreateItineraryInventoryItemInput) => {
        try {
            await createInventoryItem(payload);

            const inventoryItemYear = new Date(
                payload.startDate,
            ).getUTCFullYear();
            const inventoryItemYearChanged = inventoryItemYear !== filters.year;

            if (inventoryItemYearChanged) {
                setFilters({
                    ...filters,
                    year: inventoryItemYear,
                });
            }
        } catch (e) {
            throw new Error(e);
        }
    };

    const update = async (payload: UpdateItineraryInventoryItemInput) => {
        try {
            if (!selectedInventoryItem?._id) {
                return;
            }
            await updateInventoryItem(selectedInventoryItem._id, payload);
        } catch (e) {
            throw new Error(e);
        }
    };

    const deleteItem = async (): Promise<void> => {
        if (!selectedInventoryItem?._id) {
            return;
        }

        try {
            await deleteInventoryItem(selectedInventoryItem._id);
            showSuccessToast('delete');
        } catch (e) {
            throw new Error(e);
        }
    };

    const handleFormSubmit: InventoryItemFormSubmitFn = async ({
        action,
        payload,
    }) => {
        try {
            if (action === 'create') {
                await create(payload);
            }
            if (action === 'update') {
                await update(payload);
            }
            triggerPopUpClose();
            showSuccessToast(action);
        } catch (e) {
            setErrorMessage(e.message);
            scrollTo(formContainerRef, 'bottom');

            if (action === 'create') {
                trackCreateItineraryInventoryError(payload, e);
            }
        }
    };

    const handleItemSelection = (id: string, to: SelectAction) => {
        const item = getInventoryItemById(inventoryItems, id);

        switch (to) {
            case 'update':
                handleOpenPopUpOpen();
                setSelectedInventoryItem(item);
                if (item) {
                    trackStartItineraryInventoryEdition(item);
                }
                break;

            case 'duplicate':
                const itemWithoutId = {
                    ...item,
                    _id: undefined,
                } as ItineraryInventoryItem;
                setSelectedInventoryItem(itemWithoutId);
                handleOpenPopUpOpen();
                break;

            case 'delete':
                setSelectedInventoryItem(item);
                break;
        }
    };

    const handleAddInventory = (): void => {
        setSelectedInventoryItem(undefined);
        handleOpenPopUpOpen();
        trackStartItineraryInventoryCreation();
    };

    const handleOpenPopUpOpen = () => {
        setErrorMessage(undefined);
        triggerPopUpOpen();
    };

    const inventoryItemsByYear = filterItineraryItemsByYear(
        inventoryItems,
        filters.year,
    );

    const inventoryItemsToDisplay = formatInventoryItemsToDisplay(
        inventoryItemsByYear,
        pricingValues.currencyCode,
    );

    const yearOptions = getInventoryYearsAvailable(inventoryItems).map(
        (year) => ({
            label: year.toString(),
            value: year.toString(),
            onClick: (): void =>
                setFilters({
                    ...filters,
                    year,
                }),
        }),
    );

    let startDates = getInventoryItemsStartDates();
    if (selectedInventoryItem && selectedInventoryItem._id) {
        startDates = startDates.filter(
            (date) =>
                !isSameDay(
                    new Date(date),
                    new Date(selectedInventoryItem.startDate),
                ),
        );
    }

    const initialFormValues = getInitialValues(
        itinerary as BaseItinerary,
        pricingValues,
        selectedInventoryItem,
    );

    const showYearsMenu = filters.year && yearOptions.length > 1;

    const headerAddon = (
        <Stack
            align='center'
            spacing={4}
            justify='space-between'
            marginTop={isMobile ? 4 : 0}
        >
            {showYearsMenu ? (
                <Menu
                    label={(filters.year as number).toString()}
                    rightIcon='arrow-down'
                    options={yearOptions.filter(
                        (option) =>
                            option.value !==
                            (filters.year as number).toString(),
                    )}
                />
            ) : null}
            <Button
                variant='secondary'
                rightIcon='plus'
                size='sm'
                onClick={handleAddInventory}
            >
                Add Inventory
            </Button>
        </Stack>
    );

    const isInventoryItemInUse =
        selectedInventoryItem &&
        checkIfInventoryItemIsInUse(selectedInventoryItem);

    return (
        <SectionCard
            size='lg'
            title='Itinerary Inventory'
            headerAddon={!isMobile ? headerAddon : undefined}
        >
            {isMobile ? headerAddon : null}
            <ItineraryInventoryTable
                inventoryItems={inventoryItemsToDisplay}
                onItemSelect={handleItemSelection}
                onItemDelete={deleteItem}
                isLoading={isLoading}
            />
            <ItineraryInventoryPopUp
                isOpen={isPopUpOpen}
                pricingValues={pricingValues}
                existingStartDates={startDates}
                initialFormValues={initialFormValues}
                onFormSubmit={handleFormSubmit}
                onClose={triggerPopUpClose}
                isLoading={isLoading}
                errorMessage={errorMessage}
                formContainerRef={formContainerRef}
                isInventoryItemInUse={isInventoryItemInUse}
            />
        </SectionCard>
    );
};

export type { PricingValues };

export default ItineraryInventoryCard;
