import { useCallback } from 'react';
import { libraryServices } from '../../../../state/library-services';

const useFetchLibraryServices = () => {
    const { search: searchLibraryServices } = libraryServices.useDispatch();

    const fetchLibraryServices = useCallback(
        async (amountOfResults, serviceType, searchText) => {
            searchLibraryServices(amountOfResults, serviceType, searchText);
        },
        [searchLibraryServices]
    );

    return { fetchLibraryServices };
};

export default useFetchLibraryServices;
