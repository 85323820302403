import { constants, models, validationSchemas } from '@trova-trip/trova-models';
import { coreUtils } from '@trova-trip/trova-common';
import { useEffect, useRef, useState } from 'react';
import { userTrips } from 'state/userTrips';
import {
    filterViewOnlyServicesByDay,
    getPriorityForType,
    orderServicesByDay as orderServicesByDayByPriority,
} from '../../../../applications/common/helpers';
import { ServiceTypesKeys as ServiceTypes } from '../../../../config/constants';
import {
    ServicesByDayUIModel,
    ServiceUIModel,
} from '../../../../interfaces/UiModels.types';
import { useSelector } from '../../../../state/hooks/useSelector';
import { services } from '../../../../state/services';

const {
    collectionUtils: { InMemoryListManager },
} = coreUtils;

const { ServiceType } = constants.services;

type InMemoryListManager = coreUtils.collectionUtils.InMemoryListManager;
type TransientItemType = coreUtils.collectionUtils.TransientItemType;

type Service = models.services.Service;
type Trip = models.trips.Trip;

type UseServicesByDayInitialState = {
    servicesByDay: ServicesByDayUIModel;
};

type AddServiceData = Partial<Service> & { isEditing?: boolean };

const { ServiceFormSchema, GatheringFormSchema } = validationSchemas;

const anyServiceSchema = ServiceFormSchema;
const gatheringSchema = anyServiceSchema.concat(GatheringFormSchema);

interface UseServicesByDayReturn {
    servicesByDay: ServicesByDayUIModel;
    getServiceByKey: (key: symbol) => ServiceUIModel | undefined;
    isServiceValid: (service: ServiceUIModel) => Promise<boolean>;
    toggleEditing: (key: symbol) => void;
    add: (data: AddServiceData, dayIndex: number) => void;
    update: (
        service: Service,
        key: symbol,
    ) => Promise<ServiceUIModel | undefined>;
    create: (
        service: Service,
        key: symbol,
    ) => Promise<ServiceUIModel | undefined>;
    remove: (key: symbol) => Promise<boolean>;
}

const useServicesByDay = (
    initialState: UseServicesByDayInitialState,
): UseServicesByDayReturn => {
    const { servicesByDay: initialServicesByDay = [] } = initialState;

    const { updateRecord, createRecord } = services.useDispatch();
    const { getRecord: refreshTripRecord } = userTrips.useDispatch();

    const currentTrip = useSelector((state) => state.userTrips.current);
    const { updateRecord: updateTrip } =
        userTrips.useDispatch.bind(currentTrip)();

    const managers = [...Array(initialServicesByDay.length)].map(
        () => new InMemoryListManager(),
    );
    const serviceManagersByDay = useRef<InMemoryListManager[]>(managers);
    const [servicesByDay, setServicesByDay] = useState<ServicesByDayUIModel>(
        [],
    );

    useEffect(() => {
        const { current: managers } = serviceManagersByDay;
        if (managers.length === 0 || initialServicesByDay.length === 0) return;
        managers.forEach((manager, index) => {
            manager.clearAll();
            manager.pushItems(initialServicesByDay[index]);
        });
        updateServicesByDayWithLatestState();
    }, []);

    const updateServicesByDayWithLatestState = (): ServicesByDayUIModel => {
        const { current: managers } = serviceManagersByDay;
        const servicesByDayMapped = managers.map((manager) =>
            manager
                .listItems()
                .map(({ $tkey, $tmodel }) => ({ ...$tmodel, $tkey })),
        );
        const newServicesByDay = orderServicesByDay(
            servicesByDayMapped as ServicesByDayUIModel,
        );
        setServicesByDay(newServicesByDay);
        return newServicesByDay;
    };

    const getServiceByKey = (key: symbol): ServiceUIModel | undefined => {
        const day = servicesByDay
            .flatMap((days) => days)
            .find((day) => day.$tkey === key);
        return day?.service;
    };

    const getManagerByKey = (key: symbol): InMemoryListManager | undefined => {
        const { current: managers } = serviceManagersByDay;
        const dayIndex = getDayIndexByKey(key);
        return managers[dayIndex];
    };

    const getManagerAndDayByKey = (
        key: symbol,
    ): { manager?: InMemoryListManager; day?: TransientItemType } => {
        const manager = getManagerByKey(key);
        const day = manager?.getItem(key);
        return { manager, day };
    };

    const getDayIndexByKey = (key: symbol): number => {
        return servicesByDay.findIndex(
            (days) => !!days.find((day) => day.$tkey === key),
        );
    };

    const orderServicesByDay = (
        servicesByDay: ServicesByDayUIModel,
    ): ServicesByDayUIModel => {
        const orderedByPriority = orderServicesByDayByPriority(servicesByDay);
        const ordered = orderedByPriority.map((days) => {
            const firstSaves = days.filter((day) => day.isFirstSave);
            return [...days.filter((day) => !day.isFirstSave), ...firstSaves];
        });
        return ordered;
    };

    const isServiceValid = async (
        service: ServiceUIModel,
    ): Promise<boolean> => {
        const isGathering = service.type === ServiceTypes.INFORMAL_GATHERING;
        const schema = isGathering ? gatheringSchema : anyServiceSchema;
        try {
            await schema.validate(service, { abortEarly: true });
            return true;
        } catch {
            return false;
        }
    };

    const updateServicesByDayOfTrip = async (
        servicesByDay: ServicesByDayUIModel,
    ): Promise<Trip | undefined> => {
        if (!currentTrip) return;
        const filteredServicesByDay =
            filterViewOnlyServicesByDay(servicesByDay);
        const response: Trip = await updateTrip(currentTrip.id, {
            servicesByDay: filteredServicesByDay,
        });
        refreshTripRecord(currentTrip.id);
        return response;
    };

    const toggleEditing = (key: symbol): void => {
        const { manager, day } = getManagerAndDayByKey(key);
        if (!manager || !day) return;
        const { $tmodel } = day;
        manager.updateItem(key, {
            ...day,
            $tmodel: {
                ...$tmodel,
                isEditing: !$tmodel.isEditing,
            },
        });
        updateServicesByDayWithLatestState();
    };

    const add = (data: AddServiceData, dayIndex: number): void => {
        const { current: managers } = serviceManagersByDay;
        const { isEditing = false, ...serviceData } = data;
        const newService = getNewServiceToAdd(serviceData, dayIndex);
        const manager = managers[dayIndex];
        manager.addItem({
            duration: 1,
            readOnly: false,
            service: newService,
            isEditing,
            priority: getPriorityForType(newService.type),
            isFirstSave: true,
        });
        updateServicesByDayWithLatestState();
    };

    const create = async (
        data: ServiceUIModel,
        key: symbol,
    ): Promise<ServiceUIModel | undefined> => {
        const { manager, day } = getManagerAndDayByKey(key);
        if (!manager || !day) return;

        const { $tmodel } = day;
        const dayIndex = getDayIndexByKey(key);
        const selectedDayIndex = data.dayIndex;

        const persistedService = await _create(data);

        const newDay = {
            ...day,
            $tmodel: {
                ...$tmodel,
                service: { ...$tmodel.service, ...persistedService },
                isFirstSave: false,
            },
        };

        if (Number(dayIndex) === Number(selectedDayIndex)) {
            manager.updateItem(key, newDay);
        } else {
            const { current: managers } = serviceManagersByDay;
            const managerOfSelectedDay = managers[selectedDayIndex];
            manager.deleteItem(key);
            managerOfSelectedDay.addItem({
                ...newDay.$tmodel,
                isEditing: false,
            });
        }

        const updatedServicesByDay = updateServicesByDayWithLatestState();
        await updateServicesByDayOfTrip(updatedServicesByDay);

        return { ...persistedService };
    };

    const _create = (service: ServiceUIModel): Promise<ServiceUIModel> => {
        const { type, name, description } = service;
        return new Promise<ServiceUIModel>((resolve, reject) => {
            createRecord({ type, name, description }, type, {
                successCallback: (newService: ServiceUIModel) => {
                    resolve({ ...newService, ...service, _id: newService.id });
                },
                errorCallback: (error: string) => {
                    reject(new Error(error));
                },
            });
        });
    };

    const update = async (
        service: ServiceUIModel,
        key: symbol,
    ): Promise<ServiceUIModel | undefined> => {
        const { manager, day } = getManagerAndDayByKey(key);
        if (!manager || !day) return;

        const updatedService = await _update(service);
        const oldService = day.$tmodel.service;

        const dayHasChanged =
            Number(updatedService.dayIndex) !== Number(oldService.dayIndex);
        const { $tmodel } = day;

        const newDay = {
            ...day,
            $tmodel: {
                ...$tmodel,
                service: { ...$tmodel.service, ...updatedService },
            },
        };

        if (dayHasChanged) {
            const { current: managers } = serviceManagersByDay;
            const managerOfNewDay = managers[updatedService.dayIndex];
            manager.deleteItem(key);
            managerOfNewDay.addItem({ ...newDay.$tmodel, isEditing: false });
        } else {
            manager.updateItem(key, newDay);
        }

        const updatedServicesByDay = updateServicesByDayWithLatestState();
        if (dayHasChanged) {
            await updateServicesByDayOfTrip(updatedServicesByDay);
        }

        return { ...updatedService };
    };

    const _update = (service: ServiceUIModel): Promise<ServiceUIModel> => {
        const { _id: id, type } = service;
        return new Promise<ServiceUIModel>((resolve, reject) => {
            // eslint-disable-next-line
            const { serviceLibrary, operator, ...filteredData } = service;
            updateRecord(id, type, filteredData, {
                successCallback: (updatedService: Service) => {
                    resolve({ ...updatedService, ...service });
                },
                errorCallback: (error: string) => {
                    reject(new Error(error));
                },
            });
        });
    };

    const remove = async (key: symbol): Promise<boolean> => {
        const { manager, day } = getManagerAndDayByKey(key);
        if (!manager || !day) return false;

        const deletedDay = manager.getItem(key);
        if (!deletedDay) return false;

        const shouldUpdateTrip = !deletedDay.$tmodel.isFirstSave;

        const result = manager.deleteItem(key);

        const updatedServicesByDay = updateServicesByDayWithLatestState();
        if (shouldUpdateTrip) {
            await updateServicesByDayOfTrip(updatedServicesByDay);
        }

        return result;
    };

    const getNewServiceToAdd = (
        data: Partial<ServiceUIModel>,
        dayIndex: number,
    ): ServiceUIModel => {
        const defaultType = ServiceType.WORKSHOP_SPACE;
        // @ts-ignore
        return {
            name: '',
            description: '',
            serviceLibrary: '',
            createdDate: new Date(),
            operator: '',
            user: '',
            type: defaultType,
            dayIndex,
            viewOnly: false,
            deleted: false,
            ...data,
        };
    };

    return {
        servicesByDay,
        getServiceByKey,
        isServiceValid,
        toggleEditing,
        add,
        create,
        update,
        remove,
    };
};

export default useServicesByDay;
