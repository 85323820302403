import { useState, useLayoutEffect } from 'react';
import { get } from '../../state/utils/api';

const FEATURES_API_ENDPOINT = `api/me/features`;

const storedFeatures = {};

const useGetFeatureValue = feature => {
    const [featureStatus, setFeatureStatus] = useState();

    useLayoutEffect(() => {
        const existingFeature = storedFeatures[feature];
        if (existingFeature !== undefined) {
            setFeatureStatus(existingFeature);
            return;
        }

        get(FEATURES_API_ENDPOINT, {
            name: feature,
        }).then(response => {
            storedFeatures[feature] = response.data;
            setFeatureStatus(response.data);
        });
    }, [feature]);

    return featureStatus;
};

export default useGetFeatureValue;
