import { TripStatuses } from '../../../../config/constants';
import { useSelector } from '../../../../state/hooks';

const getEditableStatusForTrip = (
    status: string,
): { isEditable: boolean; fieldExceptions: string[] } => {
    switch (status) {
        case TripStatuses.CLOSED:
            return { isEditable: false, fieldExceptions: ['servicesByDay'] };

        case TripStatuses.CREATED:
            return { isEditable: true, fieldExceptions: [] };

        case TripStatuses.PARTNER_APPROVED:
        case TripStatuses.TROVA_PRICING_APPROVED:
        case TripStatuses.LIVE:
        case TripStatuses.READY_TO_CONFIRM:
        case TripStatuses.CONFIRMED:
        case TripStatuses.HOST_APPROVED:
            return {
                isEditable: false,
                fieldExceptions: [
                    'optionalAccommodations.singleSupplement.quantity',
                ],
            };
        case TripStatuses.CANCELLED:
        case TripStatuses.COMPLETE:
        default:
            return { isEditable: false, fieldExceptions: [] };
    }
};

const isTripFieldEditable = (
    tripStatus: string,
    fieldName: string,
): boolean => {
    const tripEditableStatus = getEditableStatusForTrip(tripStatus);

    if (!tripEditableStatus.isEditable) {
        return tripEditableStatus.fieldExceptions
            ? tripEditableStatus.fieldExceptions.includes(fieldName)
            : false;
    }

    return true;
};

const useOperatorCanUpdateTrip = (): boolean => {
    const trip = useSelector(({ userTrips }) => userTrips.current);

    if (!trip) {
        return false;
    }

    const { isEditable } = getEditableStatusForTrip(trip.status);

    return isEditable;
};

export {
    getEditableStatusForTrip,
    isTripFieldEditable,
    useOperatorCanUpdateTrip,
};
