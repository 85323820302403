import FetchState from './utils/FetchState';
import { put, get, post } from './utils/api';
import { models } from '@trova-trip/trova-models';
import { ApiResponse } from '@trova-trip/trova-common';

export type TripDetailsStoreSelector = {
    userTripDetails: { current: models.tripDetails.TripDetails };
};

export type TripStoreSelector = {
    userTrips: { current: models.trips.TripDTO };
};

type UserTripsApiResponse<T> = Pick<ApiResponse, 'success' | 'error'> & {
    data: T;
};

type TripInventoryResponse = UserTripsApiResponse<models.trips.Inventory>;

const userTrips = FetchState.create(`api/me/trips`);
const userTripDetails = FetchState.create(`api/me/trip-details`);

const userTripsReducer = userTrips.getReducer();
const userTripDetailsReducer = userTripDetails.getReducer();

const userAutomaticReminders = (tripId) =>
    get(`api/me/trip-details/${tripId}/automaticReminders`, undefined);

const updateAutomaticReminders = (tripId, data) =>
    post(`api/me/trip-details/${tripId}/automaticReminders`, {
        tripId,
        ...data,
    });

const confirmPendingBookings = (tripId) =>
    put(`api/me/trip-details/${tripId}/confirmBookings`, undefined);

const getUserTripInventory = (tripId: string): Promise<TripInventoryResponse> =>
    get(`api/me/trips/${tripId}/inventory`, undefined);

const updateUserTripSingleSupplementInventory = (
    tripId: string,
    total: number,
): Promise<models.trips.Trip> =>
    put(`api/me/trips/${tripId}/inventory/single-supplement`, undefined, {
        total,
    });

export {
    userTrips,
    userTripsReducer,
    userTripDetails,
    userTripDetailsReducer,
    userAutomaticReminders,
    confirmPendingBookings,
    updateAutomaticReminders,
    getUserTripInventory,
    updateUserTripSingleSupplementInventory,
};
