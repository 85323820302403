import { models, constants } from '@trova-trip/trova-models';
import { coreUtils } from '@trova-trip/trova-common';
import { Grid, Stack, Text } from '@trova-trip/trova-components';
import RoomTwoBedsField from '../RoomTwoBedsField';
import RoomSharedBedsField from '../RoomSharedBedsField';
import RoomPrivateField from '../RoomPrivateField';

export type CheckoutAddOn = models.checkout.CheckoutAddOn;
type OccupancyType = coreUtils.bookingUtils.OccupancyType;
export const { Occupancy } = constants.services;

type RoomSelectionProps = {
    singleSupplementUnitPrice?: number;
    roomsOccupancies: OccupancyType[];
    travelersQuantity: number;
};

interface RoomSelectionRowProps {
    index: number;
    roomOccupancy: OccupancyType;
    showRoomPrivateField: boolean;
    singleSupplementUnitPrice?: number;
}

const getFieldName = (index: number): string => `roomsOccupancies[${index}]`;

const RoomSelectionRow = (props: RoomSelectionRowProps): JSX.Element => {
    const {
        index,
        roomOccupancy,
        showRoomPrivateField,
        singleSupplementUnitPrice,
    } = props;

    const showPrivateRoomUpgradeDisclaimer =
        showRoomPrivateField && singleSupplementUnitPrice;

    return (
        <Stack direction='column' spacing={2}>
            <Text fontSize='base' fontWeight='medium' color='blueGray.800'>
                Room {index + 1}
            </Text>
            {showPrivateRoomUpgradeDisclaimer ? (
                <Text>
                    Private room upgrade is subject to change based on
                    availability.
                </Text>
            ) : null}
            <Grid width='100%'>
                <Grid.Item columnSpan={{ base: 12, md: 6 }}>
                    <RoomTwoBedsField
                        name={getFieldName(index)}
                        value={roomOccupancy === Occupancy.twin}
                    />
                </Grid.Item>
                <Grid.Item columnSpan={{ base: 12, md: 6 }}>
                    {showRoomPrivateField ? (
                        <RoomPrivateField
                            name={getFieldName(index)}
                            amount={singleSupplementUnitPrice ?? 0}
                            value={roomOccupancy === Occupancy.single}
                        />
                    ) : (
                        <RoomSharedBedsField
                            name={getFieldName(index)}
                            value={roomOccupancy === Occupancy.double}
                        />
                    )}
                </Grid.Item>
            </Grid>
        </Stack>
    );
};

const RoomSelection = (props: RoomSelectionProps): JSX.Element => {
    const { singleSupplementUnitPrice, roomsOccupancies, travelersQuantity } =
        props;

    return (
        <Stack spacing={6} direction='column' align='stretch'>
            {roomsOccupancies?.map((roomOccupancy, index) => {
                const isLastRoom = index === roomsOccupancies.length - 1;
                const isNumberOfTravelersInLastRoomEven =
                    travelersQuantity % 2 === 1;

                const showRoomPrivateField =
                    isLastRoom &&
                    isNumberOfTravelersInLastRoomEven &&
                    !!singleSupplementUnitPrice;

                return (
                    <RoomSelectionRow
                        key={index}
                        index={index}
                        roomOccupancy={roomOccupancy}
                        showRoomPrivateField={showRoomPrivateField}
                        singleSupplementUnitPrice={singleSupplementUnitPrice}
                    />
                );
            })}
        </Stack>
    );
};

export default RoomSelection;
