import {
    ComponentWidth,
    FormDataObjectType,
    Grid,
    TextareaSize,
    useFormSaver,
} from '@trova-trip/trova-components';
import { Card } from '@trova-trip/trova-components/build/next';
import { models } from '@trova-trip/trova-models';
import includes from 'lodash/includes';
import Danger from '../../../../../components/Typography/Danger';
import { ServiceTypesKeys } from '../../../../../config/constants';
import {
    getColorFromServiceType,
    getServiceTitleLabel,
} from '../LibraryServiceFormatter';
import ServiceTitle from '../ServiceTitle';
import { ServiceFormPermissions, fieldNamesByServiceType } from '../helpers';
import {
    PaddedCheckbox,
    PaddedInput,
    PaddedNumberInput,
    PaddedTextarea,
    PaddedTimePicker,
    StyledCurrency,
    StyledServiceFileInput,
} from './Form.components';
import ActionButtons from './Shared/ActionButtons';
import SaveToLibraryCheckbox from './Shared/SaveToLibraryCheckbox';
import { validationSchema } from './Shared/validationSchema';

type ServiceType = models.services.ServiceType;

interface ActivityCardFormProps {
    productName: 'itinerary' | 'trip';
    serviceToEdit?: models.services.Activity | null;
    closeForm: () => void;
    updateItinerary: (data: any) => void;
    errorText?: string;
    currencyCode?: string;
    disabledFields?: string[];
    permissions?: ServiceFormPermissions;
}

const DEFAULT_ACTIVITY = {
    saveToLibrary: false,
    type: ServiceTypesKeys.ACTIVITY,
    includedActivity: true,
    length: 60,
    bookable: true,
};

const ActivityCardForm = (props: ActivityCardFormProps): JSX.Element => {
    const {
        updateItinerary,
        serviceToEdit,
        closeForm,
        productName,
        errorText,
        currencyCode,
        disabledFields,
        permissions,
    } = props;

    const isNew = !serviceToEdit;

    const defaultData = !isNew ? serviceToEdit : DEFAULT_ACTIVITY;

    const formSaver = useFormSaver({
        onSubmit: async (values) => {
            updateItinerary(values);
        },
        initialValues: defaultData as FormDataObjectType,
        validationSchema: {
            schema: validationSchema,
        },
    });

    const { formValues } = formSaver;

    const handleFormChange = (event: React.SyntheticEvent): void => {
        const { value, name } = event.target as HTMLInputElement;
        formValues.set[name](value);
    };

    const handleCheckboxChange = (event: React.SyntheticEvent): void => {
        const { name } = event.target as HTMLInputElement;
        const isChecked = !formSaver.formValues.get.nested(name);
        formSaver.formValues.set.nested(name, isChecked);
    };

    const onRemoveAction = (): void => {
        formSaver.formValues.set.nested('deleted', true);
        updateItinerary({
            ...formSaver.formState,
            deleted: true,
        });
    };

    const onChangeNumberWrapper = (event, name, value): void => {
        const wrappedEvent = {
            ...event,
            target: { value, name },
        };
        handleFormChange(wrappedEvent);
    };

    const handleCurrencyChange = (event: React.SyntheticEvent): void => {
        const { value, name } = event.target as HTMLInputElement;
        const numberValue = Number(value);

        const newValue = numberValue < 0 || isNaN(numberValue) ? '0' : value;

        formValues.set[name](newValue);
    };

    const { activity: fieldNames } = fieldNamesByServiceType;

    const isActivityIncluded = formValues.get[fieldNames.includedActivity];
    const serviceType = formValues.get[fieldNames.type] as ServiceType;

    return (
        <Card backgroundColor='neutral.white'>
            <ServiceTitle
                title={getServiceTitleLabel(serviceType)}
                color={getColorFromServiceType(serviceType)}
            />
            <PaddedInput
                name={fieldNames.name}
                placeholder='Name'
                label='Name *'
                size={ComponentWidth.Medium}
                value={formValues.get[fieldNames.name] as string}
                onChange={handleFormChange}
                disabled={includes(disabledFields, fieldNames.name)}
            />
            <PaddedInput
                name={fieldNames.location}
                placeholder='Address'
                label='Location *'
                size={ComponentWidth.Medium}
                value={formValues.get[fieldNames.location] as string}
                onChange={handleFormChange}
                disabled={includes(disabledFields, fieldNames.location)}
            />
            <PaddedTextarea
                name={fieldNames.description}
                placeholder='Description'
                label='Description *'
                size={TextareaSize.Medium}
                value={formValues.get[fieldNames.description] as string}
                error={formSaver.formErrors?.[fieldNames.description]}
                onChange={handleFormChange}
                expandable={true}
                disabled={includes(disabledFields, fieldNames.description)}
            />
            <PaddedTimePicker
                name={fieldNames.startTime}
                placeholder='HH:MM'
                label='Start time'
                size={ComponentWidth.Small}
                value={formValues.get[fieldNames.startTime] as string}
                onChange={(value) =>
                    formValues.set[fieldNames.startTime](value as string)
                }
                disabled={includes(disabledFields, fieldNames.startTime)}
            />
            <PaddedNumberInput
                name={fieldNames.length}
                label='Length in minutes *'
                value={(formValues.get[fieldNames.length] || 60) as number}
                onChange={onChangeNumberWrapper}
                disabled={includes(disabledFields, fieldNames.length)}
            />
            <PaddedCheckbox
                name={fieldNames.includedActivity}
                detail='Included activity'
                value={isActivityIncluded as boolean}
                onChange={handleCheckboxChange}
                disabled={includes(disabledFields, fieldNames.includedActivity)}
            />
            {!isActivityIncluded ? (
                <>
                    <StyledCurrency
                        name={fieldNames.price}
                        label='Price *'
                        size={ComponentWidth.Small}
                        value={formValues.get[fieldNames.price] as number}
                        onChange={handleCurrencyChange}
                        currencyType={currencyCode}
                        disabled={includes(disabledFields, fieldNames.price)}
                    />
                    {productName === 'trip' ? (
                        <PaddedInput
                            name={fieldNames.cutoffDate}
                            placeholder='MM/DD/YYYY'
                            label='Cutoff date'
                            size={ComponentWidth.Small}
                            value={
                                formValues.get[fieldNames.cutoffDate] as string
                            }
                            onChange={handleFormChange}
                            disabled={includes(
                                disabledFields,
                                fieldNames.cutoffDate,
                            )}
                        />
                    ) : (
                        <PaddedNumberInput
                            name={fieldNames.cutoffLeadTime}
                            label='Cut off Lead Time (days)*'
                            value={
                                (formValues.get[fieldNames.cutoffLeadTime] ||
                                    0) as number
                            }
                            onChange={onChangeNumberWrapper}
                            disabled={includes(
                                disabledFields,
                                fieldNames.cutoffLeadTime,
                            )}
                        />
                    )}
                    <PaddedNumberInput
                        name={fieldNames.minPax}
                        label='Minimum Travelers *'
                        value={
                            (formValues.get[fieldNames.minPax] || 0) as number
                        }
                        onChange={onChangeNumberWrapper}
                        disabled={includes(disabledFields, fieldNames.minPax)}
                    />
                    <PaddedCheckbox
                        name={fieldNames.bookable}
                        detail='Bookable activity'
                        value={formValues.get[fieldNames.bookable] as boolean}
                        onChange={handleCheckboxChange}
                        disabled={includes(disabledFields, fieldNames.bookable)}
                    />
                </>
            ) : null}
            <StyledServiceFileInput
                className=''
                detail=''
                error=''
                key=''
                name={fieldNames.images}
                value={formValues.get[fieldNames.images]}
                onChange={handleFormChange}
                label={'Upload images'}
                maxFiles={'10'}
                disabled={includes(disabledFields, fieldNames.images)}
            />
            <SaveToLibraryCheckbox
                value={formValues.get[fieldNames.saveToLibrary] as boolean}
                onChange={handleCheckboxChange}
                disabled={includes(disabledFields, fieldNames.saveToLibrary)}
            />
            {errorText && (
                <Grid>
                    <Grid.Item columnSpan={12}>
                        <Danger>{errorText}</Danger>
                    </Grid.Item>
                </Grid>
            )}
            <ActionButtons
                serviceType={serviceType}
                closeForm={closeForm}
                submitForm={() => formSaver.handleFormSubmit(undefined)}
                onRemoveAction={onRemoveAction}
                isNewEntity={isNew}
                permissions={permissions}
            />
        </Card>
    );
};

export default ActivityCardForm;
