import { Occupancy } from '../../../../types';
import BaseRoomOptionField, {
    BaseRoomOptionItem,
} from '../BaseRoomOptionField';
import { priceIncludedRoomOptionItem } from '../BaseRoomOptionField/BaseRoomOptionField';

const items: BaseRoomOptionItem[] = [
    {
        icon: 'trovaTwoBeds',
        text: 'Shared room with a roommate',
    },
    priceIncludedRoomOptionItem,
];

type RoomTwoBedsFieldProps = {
    value: boolean;
    name: string;
};

const RoomTwoBedsField = ({
    value,
    name,
}: RoomTwoBedsFieldProps): JSX.Element => (
    <BaseRoomOptionField
        title='Two beds'
        name={name}
        value={value}
        items={items}
        type={Occupancy.twin}
    />
);

export default RoomTwoBedsField;
