import {
    ConfirmationCard,
    ConfirmationCardProps,
} from '@trova-trip/trova-components/build/custom';
import PageCard from '../../../../../common/components/PageCard';
import useIsLargeScreen from '../../../../../common/hooks/useIsLargeScreen';
import TripSummaryContent from '../TripSummaryContent';
import WithRouteValidation from '../WithRouteValidation';
import { useManageBooking, useManageBookingNavigation } from '../../hooks';
import { coreUtils } from '@trova-trip/trova-common';
import { ConfirmationMode } from '../../../../../../state/features/manageBooking/types';

const { formatUSD } = coreUtils.currencyUtils;

const getSuccessPageContent = (
    confirmationMode: ConfirmationMode | null,
    paidAmount: number,
    total: number,
): Pick<
    ConfirmationCardProps,
    'title' | 'subtitle' | 'description' | 'actions' | 'subtitleProps'
> => {
    const commonSuccessPageProps: Pick<
        ConfirmationCardProps,
        'subtitle' | 'subtitleProps'
    > = {
        subtitle: formatUSD(total, 2),
        subtitleProps: {
            color: total < 0 ? 'alerts.error.accent' : 'blueGray.650',
        },
    };

    switch (confirmationMode) {
        case 'negativeBalance':
            return {
                ...commonSuccessPageProps,
                title: 'Trip Balance Updated',
                description:
                    'Your booking has been updated and your trip balance has been reduced.',
            };
        case 'refundNeeded':
            return {
                ...commonSuccessPageProps,
                title: 'Refund Processing',
                description:
                    'This amount will be refunded to the original payment method. Records of all activities are listed in the Transaction History table in the Manage Booking tab.',
            };
        case 'zeroBalance':
            return {
                ...commonSuccessPageProps,
                title: 'Changes Confirmed',
                description: 'Your booking has been updated.',
            };
        default:
            return {
                subtitle: formatUSD(paidAmount, 2),
                subtitleProps: {
                    color: 'blueGray.650',
                },
                title: 'Payment Complete',
                description: 'Your booking has been updated.',
            };
    }
};

const SuccessPage = (): JSX.Element => {
    const {
        state: {
            confirmationMode,
            tripSummary: {
                bookingPaymentData: { paidAmount },
                totalWithInsurance,
            },
        },
    } = useManageBooking();

    const { getManageBookingTabPath } = useManageBookingNavigation();

    const isDesktop = useIsLargeScreen({ includeTabletResolution: true });

    const { title, subtitle, description, subtitleProps } =
        getSuccessPageContent(confirmationMode, paidAmount, totalWithInsurance);

    return (
        <PageCard
            sidebarContent={
                <TripSummaryContent
                    showTitle={true}
                    showInsuranceDisclaimer={false}
                    showTotal={isDesktop}
                />
            }
            sidebarPadding={3}
        >
            <ConfirmationCard
                title={title}
                subtitle={subtitle}
                subtitleProps={subtitleProps}
                description={description}
                icon='verified'
                height={{
                    base: 'calc(100vh - 13.75rem)',
                    sm: '60vh',
                    lg: 'auto',
                }}
                actions={[
                    {
                        as: 'a',
                        children: 'Return to Manage Booking',
                        isFullWidth: true,
                        linkProps: {
                            href: getManageBookingTabPath('root'),
                        },
                        variant: 'primary',
                    },
                ]}
            />
        </PageCard>
    );
};

export default WithRouteValidation(SuccessPage);
