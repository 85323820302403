import React, { useCallback } from 'react';
import { APP_ENVIRONMENT, MICRO_APP_LOCAL_URI } from '../../../utils';
import useHostRecommendations from '../../hooks/useHostRecommendations';

import MicroAppHostWrapper, {
    TrovaTripMicroAppsConfig,
} from '../../../../components/MicroAppHostWrapper/MicroAppHostWrapper';
import { appConstants } from '@trova-trip/trova-common';
import CatalogRecommendedItinerariesBanner from './components/CatalogRecommendedItinerariesBanner';

interface WrappedTrovaCatalogProps {
    catalogProps?: Record<string, any>;
    onItinerarySelected: (
        itineraryId: string,
        isInstantApprovalAvailable: boolean,
    ) => void;
}

export const WrappedTrovaCatalog: React.FC<WrappedTrovaCatalogProps> = ({
    catalogProps = {},
    onItinerarySelected,
}): JSX.Element => {
    const renderId = 'mfehost:trova-catalog';
    const uriLocationOfMicroAppScript =
        (APP_ENVIRONMENT as appConstants.Environment) ===
        appConstants.Environment.Local
            ? MICRO_APP_LOCAL_URI
            : TrovaTripMicroAppsConfig.TROVA_CATALOG.uri(
                  APP_ENVIRONMENT as appConstants.Environment,
              );

    const { getItineraryRecommendation, recommendations } =
        useHostRecommendations();
    const onItineraryCardRender = useCallback(
        (args): void => {
            const recommendation = args.itineraryId
                ? getItineraryRecommendation(args.itineraryId)
                : null;

            if (args.configureRenderProps) {
                args.configureRenderProps({
                    recommendation,
                });
            }
        },
        [recommendations],
    );

    return (
        <>
            <MicroAppHostWrapper
                header={
                    <CatalogRecommendedItinerariesBanner
                        recommendations={recommendations}
                        getItineraryRecommendation={getItineraryRecommendation}
                    />
                }
                renderTargetId={renderId}
                microAppLocationUri={uriLocationOfMicroAppScript}
                microAppModuleType={
                    TrovaTripMicroAppsConfig.TROVA_CATALOG.moduleType
                }
                onModuleImported={(moduleObject) => {}}
                catalogType='itineraries'
                actionSubscriptions={{
                    onCatalogListItemSelected: (args) => {
                        if (!args.data) return;
                        const itinerary = JSON.parse(args.data);

                        if (!itinerary) return;

                        onItinerarySelected(
                            itinerary.id,
                            itinerary.isInstantApprovalAvailable,
                        );
                    },
                    onItineraryCardRender,
                }}
                microAppProps={{
                    ...catalogProps,
                    injectGlobalCss: false,
                }}
            />
        </>
    );
};
