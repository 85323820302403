import React from 'react';
import { Heading, HeadingProps } from '@trova-trip/trova-components';

interface WidgetHeadingProps extends HeadingProps {
    children: React.ReactNode;
}

const WidgetHeading = (props: WidgetHeadingProps) => {
    const { children, ...rest } = props;

    return (
        <Heading
            fontWeight='medium'
            lineHeight='short'
            fontSize={{ base: 'lg', md: '2xl' }}
            color='blueGray.650'
            marginBottom={{ base: 4, md: 4 }}
            {...rest}
        >
            {children}
        </Heading>
    );
};

export default WidgetHeading;
