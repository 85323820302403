import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import {
    ApiQuotesResponseActionPayload,
    ApiRepriceInsuranceResponseActionPayload,
    ApiSaveTransferResponseActionPayload,
    ApiTransferOperationResponseActionPayload,
    FetchQuotesActionPayload,
    InsuranceDetail,
    JourneyStage,
    Quote,
    QuoteStatus,
    SelectQuoteActionPayload,
    SetContactActionPayload,
    SetEstimatedDurationPayload,
    SetPaymentActionPayload,
    SetTransferDetailPayload,
    TabMetadata,
    TabStatus,
    TransferAction,
    TransferDetail,
    TransferError,
    TransferErrorType,
    TransferStatus,
    TransferViewModel,
} from './types';
import {
    MAX_API_ERROR_COUNT,
    areQuotesInError,
    areQuotesPopulated,
    doesTransferDetailHaveEmptyQuoteResponse,
    getTransferDetailStatus,
    isContactComplete,
    isInsuranceStatusRepriced,
    isTransferDetailStatusMissingQuoteSelection,
    isTravelDetailComplete,
    quoteHasChanged,
    transferDetailHasChanges,
    transformTransferDetailToTravelDetail,
} from './utils';

const initialTransferDetail: TransferDetail = {
    pickup: undefined,
    pickupDateTime: undefined,
    dropOff: undefined,
    estimatedDuration: undefined,
    quotes: [],
    selectedQuote: undefined,
    quotesMetadata: {
        status: 'empty',
        apiErrorCount: 0,
        apiSuccessCount: 0,
    },
    flightNumber: undefined,
    passengers: undefined,
    luggage: {
        checked: undefined,
        hand: undefined,
    },
    metadata: {
        status: 'missing-travel-details',
    },
};

const initialTabMetadata: TabMetadata = { status: 'incomplete' };

const initialState: TransferViewModel = {
    arrival: cloneDeep(initialTransferDetail),
    departure: cloneDeep(initialTransferDetail),
    contact: {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phone: undefined,
        termsAndConditions: undefined,
    },
    payment: {
        status: 'idle',
        insurance: {
            adjustment: 0,
            repricedAddOn: undefined,
            status: 'empty',
        },
    },
    metadata: {
        status: 'started',
        error: undefined,
        tabs: {
            travelDetail: initialTabMetadata,
            vehicle: initialTabMetadata,
            contact: initialTabMetadata,
            payment: initialTabMetadata,
        },
    },
};

const transferReducer = (
    state: TransferViewModel = initialState,
    action: TransferAction,
): TransferViewModel => {
    switch (action.type) {
        case 'set_transfer_detail':
            return handleSetTransferDetailAction(state, action.payload);

        case 'set_contact':
            return handleSetContactAction(state, action.payload);

        case 'set_estimated_duration':
            return handleSetEstimatedDuration(state, action.payload);

        case 'select_quote':
            return handleSelectQuoteAction(state, action.payload);

        case 'fetch_quotes':
            return handleFetchQuotesAction(state, action.payload);

        case 'api_quotes_response':
            return handleApiQuotesResponseAction(state, action.payload);

        case 'set_payment':
            return handleSetPaymentAction(state, action.payload);

        case 'save_transfer':
            return handleSaveTransfer(state);

        case 'api_save_transfer_response':
            return handleApiSaveTransferResponse(state, action.payload);

        case 'reprice_insurance':
            return handleRepriceInsurance(state);

        case 'api_reprice_insurance_response':
            return handleApiRepriceInsuranceResponse(state, action.payload);

        case 'cancel_transfer':
            return handleCancelTransfer(state);

        case 'update_transfer':
            return handleUpdateTransfer(state);

        case 'api_transfer_operations_response':
            return handleApiTransferOperationsResponse(state, action.payload);

        case 'reset_state':
            return initialState;

        default:
            return state;
    }
};

/**
 * ### Main Behavior
 * Updates the information of the transfer detail depending on the `stage` received.
 *
 * #### Side effects:
 * - Updates the `travelDetail` tab status to complete if every
 * property of the payload is complete.
 *
 * - Updates the `metadata` status to `missing-quote-selection`
 * if every property of the payload is complete.
 *
 * - Updates the quotes status to `stale` if there are quotes
 * populated already on the state and there is actually any
 * change on the travel details.
 *
 * - Checks if the quotes have an `error` status or an empty API
 * response to define the new status as `need-retry`.
 *
 * - Cleans the selected quote if the quotes status is `stale`.
 *
 * - Updates the global transfer status to `in-progress`.
 *
 * - Updates the insurance status to `stale` if there are any changes on transfer details.
 *
 * - Updates the `vehicle` tab status to `incomplete` if the quotes went stale and now
 * the selected quote is `undefined`.
 *
 * @param state
 * @param payload
 * @returns TransferViewModel
 */
const handleSetTransferDetailAction = (
    state: TransferViewModel,
    payload: SetTransferDetailPayload,
): TransferViewModel => {
    const { data, stage } = payload;

    const isTabComplete = isTravelDetailComplete(data);

    const currentTransferDetail = state[stage];

    const newTransferDetail: TransferDetail = {
        ...currentTransferDetail,
        ...data,
    };

    const hasChanges = transferDetailHasChanges(
        currentTransferDetail,
        newTransferDetail,
    );

    const updatedTransferDetail = updateTransferDetail(
        stage,
        state,
        newTransferDetail,
        hasChanges,
    );

    const updatedInsuranceDetail = updateInsuranceDetail(state, hasChanges);

    const isMissingQuote = isTransferDetailStatusMissingQuoteSelection(
        updatedTransferDetail.metadata.status,
    );

    const newState: TransferViewModel = {
        ...state,
        [stage]: updatedTransferDetail,
        payment: {
            ...state.payment,
            insurance: updatedInsuranceDetail,
        },
        metadata: {
            ...state.metadata,
            status: 'in-progress',
            tabs: {
                ...state.metadata.tabs,
                travelDetail: {
                    status: isTabComplete ? 'complete' : 'incomplete',
                },
                vehicle: {
                    ...state.metadata.tabs.vehicle,
                    status: isMissingQuote
                        ? 'incomplete'
                        : state.metadata.tabs.vehicle.status,
                },
            },
        },
    };

    return newState;
};

/**
 * ### Main Behavior
 * Updates the transfer details by the given journey stage
 * comparing the current state. It also does:
 *
 * - Checks if there is any actual change to define as `stale`
 * the quotes if they are already `populated`.
 *
 * - Cleans the selected quote if the quotes status is `stale`.
 *
 * - Checks if the quotes have an `error` status or an empty API
 * response to define the new status as `need-retry`.
 *
 * - Updates the `metadata` status.
 *
 * @param stage
 * @param currentState
 * @param newTransferDetail
 * @returns TransferDetail
 */
const updateTransferDetail = (
    stage: JourneyStage,
    currentState: TransferViewModel,
    newTransferDetail: TransferDetail,
    hasChanges: boolean,
): TransferDetail => {
    const currentTransferDetail = currentState[stage];

    const quotesStatus = currentTransferDetail.quotesMetadata.status;
    const quotesArePopulated = areQuotesPopulated(quotesStatus);
    const quotesAreInError = areQuotesInError(quotesStatus);

    const quotesApiResponseIsEmpty =
        doesTransferDetailHaveEmptyQuoteResponse(newTransferDetail);

    const quotesAreStale = quotesArePopulated && hasChanges;

    let newQuotesStatus: QuoteStatus = quotesStatus;

    if (quotesAreStale) {
        newQuotesStatus = 'stale';
    }

    if (quotesAreInError || quotesApiResponseIsEmpty) {
        newQuotesStatus = 'need-retry';
    }

    const updatedTransferDetail: TransferDetail = {
        ...newTransferDetail,
        quotesMetadata: {
            ...currentTransferDetail.quotesMetadata,
            status: newQuotesStatus,
        },
        selectedQuote: quotesAreStale
            ? undefined
            : currentTransferDetail.selectedQuote,
    };

    return {
        ...updatedTransferDetail,
        metadata: {
            status: getTransferDetailStatus(updatedTransferDetail),
        },
    };
};

/**
 * ### Main Behavior
 * Updates the insurance status to `stale` if there are any arrival or departure
 * transfer detail changes cleans the `repricedAddOn`
 * and the `adjustment` if the status is `repriced`.
 *
 * @param state
 * @param transferDetailHasChanges
 * @returns InsuranceDetail
 */
const updateInsuranceDetail = (
    state: TransferViewModel,
    transferDetailHasChanges: boolean,
): InsuranceDetail => {
    const currentStatus = state.payment.insurance.status;
    const shouldUpdateToStale =
        isInsuranceStatusRepriced(currentStatus) && transferDetailHasChanges;

    if (shouldUpdateToStale) {
        return {
            adjustment: 0,
            repricedAddOn: undefined,
            status: 'stale',
        };
    }

    return state.payment.insurance;
};

/**
 * ### Main Behavior
 * Updates the contact information.
 *
 * #### Side effects:
 * - Updates the `contact` tab status to complete if every property
 * of the payload is complete.
 *
 * @param state
 * @param payload
 * @returns TransferViewModel
 */
const handleSetContactAction = (
    state: TransferViewModel,
    payload: SetContactActionPayload,
): TransferViewModel => {
    const { data } = payload;
    const isTabComplete = isContactComplete(data);

    return {
        ...state,
        contact: data,
        metadata: {
            ...state.metadata,
            tabs: {
                ...state.metadata.tabs,
                contact: {
                    status: isTabComplete ? 'complete' : 'incomplete',
                },
            },
        },
    };
};

/**
 * ### Main Behavior
 * Sets the estimated duration for the transfer detail depending
 * on the journey stage.
 *
 * @param state
 * @param payload
 * @returns TransferViewModel
 */
const handleSetEstimatedDuration = (
    state: TransferViewModel,
    payload: SetEstimatedDurationPayload,
): TransferViewModel => {
    const { estimatedDuration, stage } = payload;

    return {
        ...state,
        [stage]: {
            ...state[stage],
            estimatedDuration,
        },
    };
};

/**
 * ### Main Behavior
 * Sets the quote for the arrival transfer detail.
 *
 * #### Side effects:
 * - Updates the `vehicle` tab status to complete if the state
 * doesn't includes a departure travel detail or it includes it
 * and there is a quote selected for the departure too.
 *
 * - Updates the arrival `metadata` status to `complete` if every property
 * of the payload is complete.
 *
 * - Updates the insurance status to `stale` if the quote changes.
 *
 * @param state
 * @param quote
 * @returns TransferViewModel
 */
const selectArrivalQuote = (
    state: TransferViewModel,
    quote: Quote,
): TransferViewModel => {
    const { departure } = state;
    const departureQuote = departure.selectedQuote;

    const baseDeparture = transformTransferDetailToTravelDetail(departure);

    // If this variable is "true" it means that the user included and completed the
    // travel details about the departure transfer. By default the departure is not
    // included and it's optional.
    const includesDeparture = isTravelDetailComplete(baseDeparture);

    const hasQuoteChanged = quoteHasChanged(state.arrival.selectedQuote, quote);

    const updatedInsuranceDetail = updateInsuranceDetail(
        state,
        hasQuoteChanged,
    );

    const tabStatus: TabStatus =
        includesDeparture && !departureQuote ? 'incomplete' : 'complete';

    const newArrival: TransferDetail = {
        ...state.arrival,
        selectedQuote: quote,
    };

    return {
        ...state,
        arrival: {
            ...newArrival,
            metadata: {
                status: getTransferDetailStatus(newArrival),
            },
        },
        metadata: {
            ...state.metadata,
            tabs: {
                ...state.metadata.tabs,
                vehicle: {
                    status: tabStatus,
                },
            },
        },
        payment: {
            ...state.payment,
            insurance: updatedInsuranceDetail,
        },
    };
};

/**
 * ### Main Behavior
 * Sets the quote for the departure transfer detail.
 *
 * #### Side effects:
 * - Updates the `vehicle` tab status to complete if the state
 * doesn't includes a arrival travel detail or it includes it
 * and there is a quote selected for the arrival too.
 *
 * - Updates the departure `metadata` status to `complete` if every property
 * of the payload is complete.
 *
 * - Updates the insurance status to `stale` if the quote changes.
 *
 * @param state
 * @param quote
 * @returns TransferViewModel
 */
const selectDepartureQuote = (
    state: TransferViewModel,
    quote: Quote,
): TransferViewModel => {
    const { arrival } = state;
    const arrivalQuote = arrival.selectedQuote;

    const baseArrival = transformTransferDetailToTravelDetail(arrival);

    // If this variable is "true" it means that the user included and completed the
    // travel details about the arrival transfer.
    const includesArrival = isTravelDetailComplete(baseArrival);

    const hasQuoteChanged = quoteHasChanged(
        state.departure.selectedQuote,
        quote,
    );

    const updatedInsuranceDetail = updateInsuranceDetail(
        state,
        hasQuoteChanged,
    );

    const tabStatus: TabStatus =
        includesArrival && !arrivalQuote ? 'incomplete' : 'complete';

    const newDeparture: TransferDetail = {
        ...state.departure,
        selectedQuote: quote,
    };

    return {
        ...state,
        departure: {
            ...newDeparture,
            metadata: {
                status: getTransferDetailStatus(newDeparture),
            },
        },
        metadata: {
            ...state.metadata,
            tabs: {
                ...state.metadata.tabs,
                vehicle: {
                    status: tabStatus,
                },
            },
        },
        payment: {
            ...state.payment,
            insurance: updatedInsuranceDetail,
        },
    };
};

/**
 * ### Main Behavior
 * Sets the quote for the departure or arrival transfer detail.
 *
 * #### Side effects:
 * - Check `selectArrivalQuote()` and `selectDepartureQuote()` for more information.
 *
 * @param state
 * @param payload
 * @returns TransferViewModel
 */
const handleSelectQuoteAction = (
    state: TransferViewModel,
    payload: SelectQuoteActionPayload,
): TransferViewModel => {
    const { quote, stage } = payload;
    return stage === 'arrival'
        ? selectArrivalQuote(state, quote)
        : selectDepartureQuote(state, quote);
};

/**
 * ### Main Behavior
 * Updates the payment status.
 *
 * #### Side effects:
 * - Updates the `payment` tab status to complete if the status is successful.
 *
 * - Updates the global transfer status to `error` if the response was an error.
 *
 * - Updates the global transfer status to `in-progress` if the payment status is `in-progress`.
 *
 * - Updates the global transfer error with the given error message.
 *
 * - Cleans the global transfer error if the payment status is `in-progress`.
 *
 * @param state
 * @param payload
 * @returns TransferViewModel
 */
const handleSetPaymentAction = (
    state: TransferViewModel,
    payload: SetPaymentActionPayload,
): TransferViewModel => {
    const { data } = payload;
    const { status, errorMessage = '' } = data;

    const hasError = status === 'payment_error';
    const isInProgress = status === 'in-progress';
    const isTabComplete = status === 'payment_successful';

    const newTransferStatus = isInProgress
        ? 'in-progress'
        : hasError
        ? 'error'
        : state.metadata.status;

    const newTransferError = isInProgress
        ? undefined
        : hasError
        ? ({ type: 'payment', message: errorMessage } as TransferError)
        : state.metadata.error;

    return {
        ...state,
        payment: {
            ...state.payment,
            ...data,
        },
        metadata: {
            ...state.metadata,
            status: newTransferStatus,
            error: newTransferError,
            tabs: {
                ...state.metadata.tabs,
                payment: {
                    status: isTabComplete ? 'complete' : 'incomplete',
                },
            },
        },
    };
};

/**
 * ### Main Behavior
 * Updates the quote status to `loading` optionally
 * to the arrival and the departure transfer details.
 *
 * #### Side effects:
 * - None.
 *
 * @param state
 * @param payload
 * @returns TransferViewModel
 */
const handleFetchQuotesAction = (
    state: TransferViewModel,
    payload: FetchQuotesActionPayload,
): TransferViewModel => {
    const { departure, arrival } = payload;

    let departureSlice = state.departure;
    let arrivalSlice = state.arrival;

    if (departure) {
        departureSlice = {
            ...state.departure,
            quotesMetadata: {
                ...state.departure.quotesMetadata,
                status: 'loading',
            },
        };
    }

    if (arrival) {
        arrivalSlice = {
            ...state.arrival,
            quotesMetadata: {
                ...state.arrival.quotesMetadata,
                status: 'loading',
            },
        };
    }

    return {
        ...state,
        arrival: arrivalSlice,
        departure: departureSlice,
    };
};

/**
 * ### Main Behavior
 * Updates the quotes of the corresponding stage (arrival or departure).
 *
 * #### Side effects:
 * - Updates the quote status to `populated` if there is not any error and
 * the quotes array has at least 1 item.
 *
 * - Updates the quote status to `empty` if there is not any error and
 * the quotes array has 0 items.
 *
 * - Updates the quote status to `need-retry` if there is an error and
 * the attempts to call the api with error are less than 3 times, otherwise
 * it will update it as `error`.
 *
 * - Updates the quote status to "error" if the maximum number of attempts
 * of calling the API fails.
 *
 * - Updates the quotes `apiErrorCount` depending if there is any error.
 *
 * - Updates the quotes `apiSuccessCount` depending if the response doesn't
 * have errors or it's empty.
 *
 * - Resets the quotes `apiErrorCount` each time there is a successful response.
 *
 * @param state
 * @param payload
 * @returns TransferViewModel
 */
const handleApiQuotesResponseAction = (
    state: TransferViewModel,
    payload: ApiQuotesResponseActionPayload,
): TransferViewModel => {
    const { quotesByStages, isError } = payload;

    const newState = quotesByStages.reduce((prevState, { stage, quotes }) => {
        const { apiErrorCount, apiSuccessCount } = state[stage].quotesMetadata;

        const responseHasData = quotes.length > 0;
        let quotesStatus: QuoteStatus = responseHasData ? 'populated' : 'empty';

        let quoteApiSuccessCount = apiSuccessCount;
        let quoteApiErrorCount = apiErrorCount;

        if (isError) {
            // We let the app to retry the fetch when it fails up to a maximum of 3 times.
            const nextApiErrorCount = apiErrorCount + 1;
            quotesStatus =
                nextApiErrorCount < MAX_API_ERROR_COUNT
                    ? 'need-retry'
                    : 'error';
            quoteApiErrorCount = nextApiErrorCount;
        } else {
            quoteApiSuccessCount += 1;
            // We reset the API error count when we have a successful response
            quoteApiErrorCount = 0;
        }

        return {
            ...prevState,
            [stage]: {
                ...prevState[stage],
                quotes: sortBy(quotes, 'price'),
                quotesMetadata: {
                    status: quotesStatus,
                    apiErrorCount: quoteApiErrorCount,
                    apiSuccessCount: quoteApiSuccessCount,
                },
            },
        };
    }, state);

    return newState;
};

/**
 * ### Main Behavior
 * Updates the transfer status to `saving`.
 *
 * #### Side effects:
 * - Clears the global transfer error.
 *
 * @param state
 * @returns TransferViewModel
 */
const handleSaveTransfer = (state: TransferViewModel): TransferViewModel => {
    return {
        ...state,
        metadata: {
            ...state.metadata,
            status: 'saving',
            error: undefined,
        },
    };
};

/**
 * ### Main Behavior
 * Updates the global transfer status to `complete` if the response
 * was successful or `error` if it wasn't.
 *
 * #### Side effects:
 * - Updates the global transfer error if the response is not successful.
 *
 * @param state
 * @returns TransferViewModel
 */
const handleApiSaveTransferResponse = (
    state: TransferViewModel,
    payload: ApiSaveTransferResponseActionPayload,
): TransferViewModel => {
    const { success, errorMessage = '' } = payload;

    return {
        ...state,
        payment: {
            ...state.payment,
            status: !success ? 'idle' : state.payment.status,
        },
        metadata: {
            ...state.metadata,
            status: success ? 'complete' : 'error',
            error: !success
                ? {
                      type: 'booking',
                      message: errorMessage,
                  }
                : state.metadata.error,
        },
    };
};

const handleRepriceInsurance = (
    state: TransferViewModel,
): TransferViewModel => {
    return {
        ...state,
        metadata: {
            ...state.metadata,
            status: 'repricing-insurance',
        },
    };
};

const handleApiRepriceInsuranceResponse = (
    state: TransferViewModel,
    payload: ApiRepriceInsuranceResponseActionPayload,
): TransferViewModel => {
    const { errorMessage, insurance } = payload;

    const hasError = !!errorMessage;

    return {
        ...state,
        payment: {
            ...state.payment,
            insurance: {
                ...state.payment.insurance,
                ...insurance,
                status: !hasError ? 'repriced' : 'empty',
            },
        },
        metadata: {
            ...state.metadata,
            status: !hasError ? 'in-progress' : 'error',
            error: hasError
                ? {
                      type: 'insurance-repricing',
                      message: errorMessage,
                  }
                : state.metadata.error,
        },
    };
};

/**
 * ### Main Behavior
 * Updates the transfer status to `cancelling`.
 *
 * #### Side effects:
 * - Clears the global transfer error.
 *
 * @param state
 * @returns TransferViewModel
 */
const handleCancelTransfer = (state: TransferViewModel): TransferViewModel => {
    return {
        ...state,
        metadata: {
            ...state.metadata,
            status: 'cancelling',
            error: undefined,
        },
    };
};

/**
 * ### Main Behavior
 * Updates the transfer status to `updating`.
 *
 * #### Side effects:
 * - Clears the global transfer error.
 *
 * @param state
 * @returns TransferViewModel
 */
const handleUpdateTransfer = (state: TransferViewModel): TransferViewModel => {
    return {
        ...state,
        metadata: {
            ...state.metadata,
            status: 'updating',
            error: undefined,
        },
    };
};

/**
 * ### Main Behavior
 * - Updates the global transfer status to `cancelled` if the response was successful and the operation was `cancel`.
 * - Updates the global transfer status to `updated` if the response was successful and the operation was `update`.
 *
 * #### Side effects:
 * - Updates the global transfer error if the response is not successful.
 *
 * @param state
 * @returns TransferViewModel
 */
const handleApiTransferOperationsResponse = (
    state: TransferViewModel,
    payload: ApiTransferOperationResponseActionPayload,
): TransferViewModel => {
    const { success, errorMessage = '', operation } = payload;

    const statusOnSuccess: TransferStatus =
        operation === 'cancel' ? 'cancelled' : 'updated';

    const errorType: TransferErrorType =
        operation === 'cancel' ? 'cancel' : 'update';

    return {
        ...state,
        metadata: {
            ...state.metadata,
            status: success ? statusOnSuccess : 'error',
            error: !success
                ? {
                      type: errorType,
                      message: errorMessage,
                  }
                : state.metadata.error,
        },
    };
};

export { initialState, initialTransferDetail };
export default transferReducer;
