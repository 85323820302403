import { Dialog } from '@trova-trip/trova-components/build/next';

interface DeleteItineraryInventoryConfirmDialogProps {
    onDelete: () => void;
    closeDeleteDialog: () => void;
    isDeleteDialogOpen: boolean;
    isLoading: boolean;
}

const DeleteItineraryInventoryConfirmDialog = ({
    onDelete,
    closeDeleteDialog,
    isDeleteDialogOpen,
    isLoading,
}: DeleteItineraryInventoryConfirmDialogProps): JSX.Element => {
    return (
        <Dialog isOpen={isDeleteDialogOpen} onClose={closeDeleteDialog}>
            <Dialog.Header title='Delete Inventory Item' />
            <Dialog.Body>
                Are you sure you want to delete this inventory item?
            </Dialog.Body>
            <Dialog.Footer
                actions={[
                    {
                        children: 'Cancel',
                        variant: 'secondary',
                        onClick: closeDeleteDialog,
                        isDisabled: isLoading,
                    },
                    {
                        children: 'Delete',
                        onClick: onDelete,
                        isLoading: isLoading,
                        isDisabled: isLoading,
                    },
                ]}
            />
        </Dialog>
    );
};

export default DeleteItineraryInventoryConfirmDialog;
