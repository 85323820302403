import { models } from '@trova-trip/trova-models';
import { post } from './utils/api';
import ServiceTypeFetchState from './utils/ServiceTypeFetchState';

type Service = models.services.Service;

const services = ServiceTypeFetchState.create(`api/me/services`);
const servicesReducer = services.getReducer();

const updateServiceOnTrip = async (
    serviceId: string,
    type: string,
    recordData: Omit<Service, 'operator' | 'serviceLibrary'>,
    tripId: string,
): Promise<Service> => {
    const response = await post(
        `api/me/trips/${tripId}/service/${type}/${serviceId}`,
        recordData,
    );
    if (!response.success) {
        throw new Error(response.error);
    }
    return response.data;
};

export { services, servicesReducer, updateServiceOnTrip };
