import styled from '@emotion/styled';
import { theme } from '@trova-trip/trova-components';
import { Variants } from './ProfileImageUploader';

const css = String.raw;

const roundedVariantStyles = css`
    .fileuploader-theme-gallery {
        .fileuploader-items-list {
            flex-flow: column-reverse;
        }

        .fileuploader-items-list .fileuploader-item {
            width: 7.5rem;
            height: 7.5rem;

            &.upload-loading {
                margin-bottom: ${theme.space[2]};
            }

            .progress-holder {
                border-radius: 50%;
            }
        }

        .fileuploader-items {
            .fileuploader-item {
                img {
                    width: 7.5rem;
                    height: 7.5rem;
                    border-radius: 50%;
                }
            }

            .fileuploader-action-remove {
                background: transparent;
                color: ${theme.colors.blueGray[700]};

                &:hover {
                    background: ${theme.colors.blueGray[50]};
                }
            }
        }

        .fileuploader-item-inner .thumbnail-holder {
            .fileuploader-item-icon {
                border-radius: 50%;
            }
        }

        .fileuploader-item-inner .thumbnail-holder {
            width: 7.5rem;
            height: 7.5rem;
        }
    }
`;

const horizontalVariantStyles = css`
    .fileuploader-theme-gallery {
        .fileuploader-items-list {
            flex-flow: row;
        }

        .fileuploader-input-inner {
            height: 8.125rem;
        }

        .fileuploader-items-list .fileuploader-item {
            width: 8.125rem;
            height: 8.125rem;

            &.upload-loading {
                margin-bottom: 0;
            }
        }

        .fileuploader-items {
            .fileuploader-input {
                display: block;
            }

            .fileuploader-item {
                margin-left: ${theme.space[3]};

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .fileuploader-action-remove {
                width: 1.5rem;
                height: 1.5rem;
                background: ${theme.colors.blueGray[100]};
                color: ${theme.colors.neutral.black};
                top: 2px;
                right: -5px;

                &:hover {
                    background: ${theme.colors.blueGray[200]};
                }

                i {
                    line-height: 1.5rem;
                }
            }

            .fileuploader-action.fileuploader-action-remove.fileuploader-action-success:hover {
                background: ${theme.colors.blueGray[100]};
                box-shadow: none;
                color: ${theme.colors.neutral.black};
            }
        }

        .fileuploader-item-inner .thumbnail-holder {
            width: 8.125rem;
            height: 8.125rem;
        }
    }
`;

const StyledImageUploaderContainer = styled.div<{
    hasImage: boolean;
    variant: Variants;
}>`
    .fileuploader-theme-gallery {
        padding: 0;

        .fileuploader-items-list {
            display: flex;
            justify-content: center;
            margin: 0;
        }

        .fileuploader-input-inner {
            min-height: 8.125rem;
            font-weight: ${theme.fontWeights.regular};
            color: ${theme.colors.blueGray[400]};
            border-color: ${theme.colors.blueGray[300]};

            &:hover {
                color: ${theme.colors.blueGray[500]};
                border-color: ${theme.colors.blueGray[500]};
                box-shadow: none;
            }

            .fileuploader-icon-main {
                font-size: 1.75rem;
                color: ${theme.colors.red.trova};
            }
        }

        .fileuploader-items-list .fileuploader-item {
            .fileuploader-item-image {
                position: relative;
            }

            .progress-holder {
                background: ${theme.colors.blueGray.offWhite};
                border: 1px solid ${theme.colors.blueGray[100]};

                span {
                    color: ${theme.colors.blueGray[400]};
                    font-weight: ${theme.fontWeights.medium};
                }

                .bar {
                    background: transparent;
                    box-shadow: none;
                }
            }
        }

        .fileuploader-items {
            .fileuploader-input {
                display: ${(props): string =>
                    props.hasImage ? 'none' : 'block'};
                width: 100%;
                border-radius: ${theme.radii.md};
                margin: 0;
            }

            .fileuploader-item-inner {
                background: transparent;
                box-shadow: none;
            }

            .fileuploader-item {
                margin: 0;
                img {
                    position: relative;
                    object-fit: cover;
                }

                .actions-holder {
                    top: 0;
                    right: -10px;
                }
            }

            .fileuploader-action-remove {
                box-shadow: none;

                i {
                    font-size: 1rem;
                }
            }
        }

        .fileuploader-item-inner .thumbnail-holder {
            padding: 0;

            .fileuploader-item-icon {
                background-color: ${theme.colors.blueGray[500]} !important;
            }
        }

        .type-holder,
        .content-holder,
        .fileuploader-items .fileuploader-item .fileuploader-action-sort {
            display: none;
        }
    }

    ${({ variant }) => variant === 'rounded' && roundedVariantStyles}
    ${({ variant }) => variant === 'horizontal' && horizontalVariantStyles}
`;

export default StyledImageUploaderContainer;
