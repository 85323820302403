import { Stack, Image, BaseBox, Heading } from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import heroImg from '../../../../../assets/img/explore.png';
import { TRIP_CATALOG_PAGE_URI } from 'applications/utils';

const HeroContent = (): JSX.Element => (
    <Stack
        position='absolute'
        justify='space-between'
        align='center'
        direction='column'
        width='100%'
        height='100%'
        paddingTop={{ base: 6, md: 14 }}
        paddingBottom={6}
        paddingX={4}
        zIndex='3'
    >
        <Heading
            as='h1'
            size={{ base: '4xl', md: '5xl' }}
            color='neutral.white'
            textAlign='center'
        >
            Come see the world with TrovaTrip.
        </Heading>
        <BaseBox>
            <Button
                as='a'
                variant='primary'
                rightIcon='offsite'
                isFullWidth
                linkProps={{
                    href: TRIP_CATALOG_PAGE_URI,
                    target: '_blank',
                    rel: 'noreferrer',
                }}
            >
                See Trips
            </Button>
        </BaseBox>
    </Stack>
);

const ExploreHeroImage = (): JSX.Element => (
    <BaseBox
        position='relative'
        width='100%'
        borderRadius='lg'
        overflow='hidden'
        minHeight='29.05rem'
    >
        <Image
            src={heroImg}
            alt='Explore Image'
            position='absolute'
            fit='cover'
            width='100%'
            height='100%'
            zIndex='1'
        />
        <BaseBox
            position='absolute'
            width='100%'
            height='100%'
            background='linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.2) 100%)'
            zIndex='2'
        />
        <HeroContent />
    </BaseBox>
);

export default ExploreHeroImage;
