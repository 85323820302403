import styled from '@emotion/styled';
import ImageUpload from 'components/CustomUpload/ImageUpload';
import { LabelSpan } from '../../../../../../../components/TrovaFileInput/TrovaFileInput.styles';

interface SingleImageUploadProps {
    name: string;
    value: string;
    onChange: (...args: any[]) => void;
    label?: string;
}

const API_UPLOAD_URL = '/api/me/files/new-upload-image';

const StyledImageUploadContainer = styled.div`
    .fileuploader-items {
        margin-top: 3px;
    }
    .fileuploader-items-list {
        display: flex;
        justify-content: center;
        flex-flow: column-reverse;
    }
    .fileuploader-theme-gallery .fileuploader-items .fileuploader-items-list {
        max-width: 420px;
    }
    .fileuploader-theme-gallery .fileuploader-items .fileuploader-item,
    .fileuploader-theme-gallery .fileuploader-input {
        width: 100%;
    }
    .type-holder,
    .content-holder,
    .fileuploader-items .fileuploader-item .fileuploader-action-sort {
        display: none;
    }
    .fileuploader-theme-gallery .fileuploader-items-list .fileuploader-item {
        margin: 0;
    }
    .fileuploader-input-inner {
        max-width: none;
        min-width: 0;
    }
`;

const ProfileFormImageField = ({
    name,
    label,
    value,
    onChange,
}: SingleImageUploadProps): JSX.Element => {
    return (
        <StyledImageUploadContainer>
            {label && <LabelSpan>{label}</LabelSpan>}
            <ImageUpload
                name={name}
                value={value}
                onChange={onChange}
                imageAmountLimit='1'
                sortable={false}
                uploadUrl={API_UPLOAD_URL}
            />
        </StyledImageUploadContainer>
    );
};

export default ProfileFormImageField;
