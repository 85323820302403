import { LoggerProvider } from '@trova-trip/trova-common';
import { APP_ENVIRONMENT } from '../util/appUtils';

const LOGGER_LEVEL = process.env.LOGGER_LEVEL || 'info';

// Configure Logger
LoggerProvider.configure({
    app: 'maven client',
    hostname: window.location.hostname,
    env: APP_ENVIRONMENT,
    level: LOGGER_LEVEL,
});
