import { useMemo } from 'react';
import { StatefulModel } from 'applications/types';
import useGetCurrentStatefulModelStatus from './useGetCurrentStatefulModelStatus';

interface useModelStatusMatcherProps {
    model: StatefulModel;
    matchingStatuses: Set<string>;
}

const useModelStatusMatcher = (props: useModelStatusMatcherProps): boolean => {
    const { model, matchingStatuses } = props;
    const currentModelStatus = useGetCurrentStatefulModelStatus({ model });
    return useMemo((): boolean => {
        return matchingStatuses.has(currentModelStatus);
    }, [matchingStatuses, currentModelStatus]);
};

export default useModelStatusMatcher;
