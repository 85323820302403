import _ from 'lodash';
import moment from 'moment';

const setValidityPeriods = (data, packageLevel) => {
    const packageValidityPeriods = data.packages[packageLevel]?.validityPeriods;

    const newValidityPeriods = _.groupBy(
        packageValidityPeriods,
        (validityPeriod) =>
            `${validityPeriod.startDate},${validityPeriod.endDate}`,
    );

    const validityPeriods = Object.values(newValidityPeriods).reduce(
        (accumulator, validityPeriods) => {
            if (isOverlapping(validityPeriods)) {
                return [...accumulator, ...validityPeriods];
            }

            const validityPeriod = validityPeriods.reverse().reduce(
                (mergeAccumulator, item) =>
                    _.mergeWith(
                        mergeAccumulator,
                        item,
                        (objValue, srcValue) => {
                            if (_.isArray(objValue)) {
                                return [...objValue, ...srcValue];
                            }
                        },
                    ),
                {},
            );

            return [...accumulator, validityPeriod];
        },
        [],
    );

    return { ...data, validityPeriods };
};

const getValidityPeriods = (packages, packageLevel) => {
    const packageValidityPeriods = packages[packageLevel].validityPeriods;
    const validityPeriods = packageValidityPeriods.reduce(
        (accumulator, validityPeriod) => {
            const newCostThresholds = _.groupBy(
                validityPeriod.costThresholds,
                (costThreshold) =>
                    costThreshold.daysOfTheWeek.sort((a, b) => a - b),
            );

            const newValidityPeriods = Object.values(newCostThresholds)
                .filter((values) => values)
                .map((costThresholds) => ({
                    ...validityPeriod,
                    costThresholds,
                }));

            return [...accumulator, ...newValidityPeriods];
        },
        [],
    );

    return validityPeriods;
};

const isOverlapping = (validityPeriods) => {
    if (validityPeriods.length > 1) {
        return validityPeriods
            .map((validityPeriod) => validityPeriod.costThresholds)
            .reduce((accumulator, next, index, costThresholds) => {
                const previous = index > 0 ? costThresholds[index - 1] : [];

                if (previous.length) {
                    return previous[0].daysOfTheWeek.some((previousDay) =>
                        next[0].daysOfTheWeek.some(
                            (nextDay) => previousDay === nextDay,
                        ),
                    );
                }

                return false;
            }, false);
    }

    return false;
};

const hasMissingDays = (validityPeriods) => {
    const groupedRows = validityPeriods.filter((groupedRow) =>
        groupedRow.costThresholds.some((row) => row.rowIndex),
    );

    const groupedDaysOfWeek = groupedRows
        .reduce(
            (reduce, item) => [
                ...reduce,
                item.costThresholds.flatMap((m) =>
                    m.daysOfTheWeek.map((r) => Number(r)),
                ),
            ],
            [],
        )
        .map((m) => [...new Set(m)].sort((a, b) => a - b));

    return groupedDaysOfWeek.some(
        (days) => !_.isEqual(days, [0, 1, 2, 3, 4, 5, 6]),
    );
};

const includeDaysInRange = (startDate, endDate) => {
    const daysInRange = _.range(
        moment(endDate).add(1, `d`).diff(startDate, `d`),
    )
        .reduce(
            (acc, day) => [...acc, moment(startDate).add(day, `d`).day()],
            [],
        )
        .sort((a, b) => a - b);

    return [...new Set(daysInRange)];
};

const groupByDates = (validityPeriods, index) => {
    const { startDate, endDate } = validityPeriods[index];
    return validityPeriods.filter(
        (validityPeriod) =>
            validityPeriod.startDate === startDate &&
            validityPeriod.endDate === endDate,
    );
};

const insertRowAtIndex = (validityPeriods, row, index) => {
    const clonedValidityPeriods = _.cloneDeep(validityPeriods);
    clonedValidityPeriods.splice(index + 1, 0, row);
    return clonedValidityPeriods;
};

const updateNextRow = (validityPeriods, updateValidityPeriods, index, day) => {
    const clonedValidityPeriods = _.cloneDeep(validityPeriods);
    const nextPeriod = clonedValidityPeriods[index + 1];
    const costThresholds = nextPeriod.costThresholds.map((costThreshold) => ({
        ...costThreshold,
        daysOfTheWeek: [...costThreshold.daysOfTheWeek, day],
    }));

    clonedValidityPeriods.splice(index + 1, 1, {
        ...nextPeriod,
        costThresholds,
    });
    updateValidityPeriods(clonedValidityPeriods);
};

const deleteEmptyRows = (validityPeriods, deleteRow) => {
    validityPeriods.map((validityPeriod, index) => {
        validityPeriod.costThresholds.map((costThreshold) => {
            if (!costThreshold.daysOfTheWeek.length) {
                deleteRow(index);
            }
        });
    });
};

const isDayInRange = (startDate, endDate, day, value) => {
    if (!includeDaysInRange(startDate, endDate).includes(Number(day))) {
        return value.filter((valueDay) => Number(valueDay) !== Number(day));
    }
};

const updateItineraryStatus = (itinerary, status) => {
    return { ...itinerary, status: status };
};

/**
 * Remove generated "id" properties from validity periods
 * @param validityPeriods
 */
const removeIdsFromValidityPeriods = (validityPeriods) => {
    return validityPeriods.map(({ id, costThresholds, ...validityPeriod }) => ({
        ...validityPeriod,
        costThresholds: costThresholds.map(
            ({ id, ...costThreshold }) => costThreshold,
        ),
    }));
};

/**
 * Remove UI-specific properties from itinerary for submission to the API
 * @param itinerary
 */
const prepareItineraryForSubmission = (itinerary) => {
    const packages = Object.entries(itinerary.packages).reduce(
        (acc, [packageLevel, packageData]) => {
            acc[packageLevel] = {
                ...packageData,
                validityPeriods: removeIdsFromValidityPeriods(
                    packageData.validityPeriods,
                ),
            };
            return acc;
        },
        {},
    );

    return {
        ...itinerary,
        packages,
    };
};

export {
    setValidityPeriods,
    getValidityPeriods,
    includeDaysInRange,
    groupByDates,
    isOverlapping,
    hasMissingDays,
    insertRowAtIndex,
    isDayInRange,
    updateNextRow,
    deleteEmptyRows,
    updateItineraryStatus,
    prepareItineraryForSubmission,
};
