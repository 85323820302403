import {
    Text,
    theme,
    Card,
    BaseBox,
    Heading,
} from '@trova-trip/trova-components';
import CardTitleHeader from '../../../../common/components/CardTitleHeader';
import { StyledCardBody } from '../../TripCards/TripCards.components';

const StatusCard = ({ statusPayload }) => {
    const statusText = 'Booking Status';

    return (
        <Card backgroundColor={theme.colors.neutral.white}>
            <CardTitleHeader>{statusText}</CardTitleHeader>
            <StyledCardBody>
                <BaseBox marginBottom={4}>
                    <Heading
                        as={'h3'}
                        color={statusPayload.color}
                        textTransform={'capitalize'}
                    >
                        {statusPayload.status}
                    </Heading>
                </BaseBox>

                    <Text>{statusPayload.message}</Text>
                
            </StyledCardBody>
        </Card>
    );
};

export default StatusCard;
