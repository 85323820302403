import { constants, models } from '@trova-trip/trova-models';

const { ServiceTiming, ServiceType, AccommodationOccupancy } =
    constants.services;
const { PackageLevel } = constants.itinerary;

type BaseItinerary = models.itineraries.BaseItinerary;
type Service = models.services.Service;
type Accommodation = models.services.Accommodation;
type PackageLevel = constants.itinerary.PackageLevel;

interface HasPrePostAirportTransferReturn {
    hasPreTransfer: boolean;
    hasPostTransfer: boolean;
}

/**
 * Check if the itinerary has pre or post airport transfer services.
 *
 * @param itinerary
 * @returns An object indicating if there are pre or post airport transfer services.
 */
const hasPreOrPostAirportTransfer = (
    itinerary: BaseItinerary | undefined,
): HasPrePostAirportTransferReturn => {
    let hasPreTransfer = false;
    let hasPostTransfer = false;

    if (!itinerary || !itinerary.additionalOptionalServices) {
        return { hasPreTransfer, hasPostTransfer };
    }

    itinerary.additionalOptionalServices.some((additionalOptionalService) => {
        const service = additionalOptionalService as Service;
        const isPreTrip = service.timing === ServiceTiming.PRE_TRIP;
        const isPostTrip = service.timing === ServiceTiming.POST_TRIP;
        const isBookable = service.bookable;

        if (service.type === ServiceType.AIRPORT_TRANSFER) {
            if (isPreTrip && isBookable) {
                hasPreTransfer = true;
            }
            if (isPostTrip && isBookable) {
                hasPostTransfer = true;
            }
        }
        return hasPreTransfer && hasPostTransfer;
    });

    return { hasPreTransfer, hasPostTransfer };
};

/**
 * Check if the itinerary has ground transfer service.
 *
 * @param itinerary
 * @returns boolean
 */
const hasGroundTransfer = (itinerary: BaseItinerary | undefined): boolean => {
    if (!itinerary || !itinerary.additionalOptionalServices) {
        return false;
    }

    return itinerary.additionalOptionalServices.some(
        (additionalOptionalService) => {
            const service = additionalOptionalService as Service;
            const isBookable = service.bookable;
            return service.type === ServiceType.GROUND_TRANSFER && isBookable;
        },
    );
};

type HasPreOrPostAccommodationReturn = {
    [ServiceTiming.PRE_TRIP]: {
        [AccommodationOccupancy.SINGLE]: boolean;
        [AccommodationOccupancy.DOUBLE]: boolean;
    };
    [ServiceTiming.POST_TRIP]: {
        [AccommodationOccupancy.SINGLE]: boolean;
        [AccommodationOccupancy.DOUBLE]: boolean;
    };
};

/**
 * Check if the itinerary has pre or/and post accommodation services.
 *
 * @param itinerary
 * @returns {HasPreOrPostAccommodationReturn}
 */
const hasPreOrPostAccommodation = (
    itinerary: BaseItinerary | undefined,
): HasPreOrPostAccommodationReturn => {
    const defaultResult = {
        [ServiceTiming.PRE_TRIP]: {
            [AccommodationOccupancy.SINGLE]: false,
            [AccommodationOccupancy.DOUBLE]: false,
        },
        [ServiceTiming.POST_TRIP]: {
            [AccommodationOccupancy.SINGLE]: false,
            [AccommodationOccupancy.DOUBLE]: false,
        },
    };

    if (!itinerary || !itinerary.additionalOptionalServices) {
        return defaultResult;
    }

    const result = itinerary.additionalOptionalServices.reduce(
        (acc, optionalService) => {
            const { timing, bookable, type } = optionalService as Service;

            if (type === ServiceType.ACCOMMODATION) {
                const { occupancy } = optionalService as Accommodation;
                if (timing === ServiceTiming.PRE_TRIP && bookable) {
                    acc[ServiceTiming.PRE_TRIP][occupancy] = true;
                }
                if (timing === ServiceTiming.POST_TRIP && bookable) {
                    acc[ServiceTiming.POST_TRIP][occupancy] = true;
                }
            }

            return acc;
        },
        defaultResult,
    );

    return result;
};

const getEnabledPackagesLevels = (itinerary: BaseItinerary): PackageLevel[] => {
    return Object.entries(itinerary.packages)
        .filter(([_, value]) => value.enabled)
        .map(([key]) => PackageLevel[key.toUpperCase()]);
};

const isInventoryEnabled = (itinerary: BaseItinerary | undefined): boolean =>
    Boolean(itinerary?.itineraryInventoryEnabled);

export {
    getEnabledPackagesLevels,
    hasGroundTransfer,
    hasPreOrPostAccommodation,
    hasPreOrPostAirportTransfer,
    isInventoryEnabled,
};
