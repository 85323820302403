import {
    Heading,
    Image,
    Stack,
    Text,
    useToast,
} from '@trova-trip/trova-components';
import {
    Button,
    ButtonProps,
    Dialog,
    FeedbackMessage,
} from '@trova-trip/trova-components/build/next';
import mapImg from '../../../../../assets/img/map.svg';
import { useSelector } from '../../../../../state/hooks';
import FullScreenActionSheet from '../../../../common/components/FullScreenActionSheet';
import useIsLargeScreen from '../../../../common/hooks/useIsLargeScreen';
import { useManageBooking } from '../hooks';
import {
    isInsuranceCancelledStatus,
    isInsuranceCancellingStatus,
    isInsuranceErrorStatus,
} from '../../../../../state/features/manageBooking/utils';
import { useUserBookingsActions } from '../../../hooks';
import { useEffect } from 'react';

const insuranceRequiredCopy =
    'Remember, travel insurance is mandatory for your upcoming trip.';
const insuranceNotRequiredCopy =
    "While insurance isn't mandatory for your destination, it's always wise to have coverage during your trip.";

interface ContentProps {
    onClose: () => void;
}

const Content = (props: ContentProps): JSX.Element => {
    const { onClose } = props;
    const currentTrip = useSelector((state) => state.userTrips.current);

    const {
        state: {
            insurance: { status, error },
        },
    } = useManageBooking();

    const isInsuranceRequired = currentTrip?.travelerInsuranceRequired;

    const description = `Keep in mind, this decision cannot be undone. ${
        isInsuranceRequired ? insuranceRequiredCopy : insuranceNotRequiredCopy
    }`;

    const shouldDisplayError = isInsuranceErrorStatus(status);

    if (shouldDisplayError) {
        const actions: ButtonProps[] = onClose
            ? [
                  {
                      as: 'button',
                      children: 'Back',
                      variant: 'secondary',
                      onClick: onClose,
                  },
              ]
            : [];

        return (
            <Stack width='full' justify='center'>
                <FeedbackMessage
                    type='error'
                    size='sm'
                    direction='vertical'
                    title={error?.title}
                    message={error?.description}
                    actions={actions}
                    showContactSupport
                />
            </Stack>
        );
    }

    return (
        <>
            <Stack direction='column'>
                <Heading as='h6'>Are you sure you want to cancel?</Heading>

                <Text paddingY={2} color='blueGray.500'>
                    {description}
                </Text>
            </Stack>

            <Stack
                width='full'
                justify={{ base: 'center', lg: 'start' }}
                paddingTop={4}
            >
                <Image
                    src={mapImg}
                    alt='vehicle'
                    data-gumlet='false'
                    height={{ base: 'auto', lg: '14rem' }}
                />
            </Stack>
        </>
    );
};

interface CancelInsurancePolicyProps {
    isOpen: boolean;
    isError: boolean;
    isCancelling: boolean;
    addOnId: string | undefined;
    onClose: () => void;
    onCancel: () => void;
}

interface CancelInsurancePolicyPopupProps
    extends Pick<
        CancelInsurancePolicyProps,
        'isOpen' | 'onClose' | 'addOnId'
    > {}

const CancelInsurancePolicyDialog = ({
    isOpen,
    isError,
    isCancelling,
    onClose,
    onCancel,
}: CancelInsurancePolicyProps): JSX.Element => {
    const actionButtons = !isError ? (
        <Stack
            spacing={{ base: 0, lg: 4 }}
            width='full'
            justify='flex-end'
            paddingBottom={2}
        >
            <Button variant='secondary' onClick={onClose}>
                Keep Policy
            </Button>

            <Button
                isFullWidth={{ base: true, lg: false }}
                variant='primary'
                onClick={onCancel}
                isLoading={isCancelling}
            >
                Cancel Policy
            </Button>
        </Stack>
    ) : null;

    return (
        <Dialog isOpen={isOpen} onClose={onClose} minWidth='50vw'>
            <Dialog.Header title='' />
            <Dialog.Body width='100%'>
                <Stack
                    direction='column'
                    minHeight={{ base: 'calc(100vh - 6rem)', lg: 'full' }}
                    justify='space-between'
                >
                    <Content onClose={onClose} />
                    {actionButtons}
                </Stack>
            </Dialog.Body>
        </Dialog>
    );
};

const CancelInsurancePolicyActionSheet = ({
    isOpen,
    isError,
    isCancelling,
    onClose,
    onCancel,
}: CancelInsurancePolicyProps): JSX.Element => {
    const actions: ButtonProps[] = !isError
        ? [
              {
                  children: 'Keep Policy',
                  isFullWidth: true,
                  variant: 'secondary',
                  onClick: onClose,
              },
              {
                  children: 'Cancel Policy',
                  isFullWidth: true,
                  variant: 'primary',
                  isLoading: isCancelling,
                  onClick: onCancel,
              },
          ]
        : [];

    return (
        <FullScreenActionSheet
            onClose={onClose}
            content={<Content onClose={onClose} />}
            actions={actions}
            isOpen={isOpen}
        />
    );
};

const CancelInsurancePolicyPopup = (
    props: CancelInsurancePolicyPopupProps,
): JSX.Element => {
    const { addOnId } = props;

    const {
        actions: { cancelInsurance, resetInsuranceState },
        state: {
            insurance: { status },
        },
    } = useManageBooking();

    const { currentBooking } = useSelector((state) => ({
        currentBooking: state.userBookings?.current?.[0],
    }));

    const { clearUserBookings } = useUserBookingsActions();

    const toast = useToast();

    const isDesktop = useIsLargeScreen();

    const shouldDisplayError = isInsuranceErrorStatus(status);

    const handleCancelInsurance = (): void => {
        cancelInsurance({
            bookingId: currentBooking?._id || '',
            addOnId,
        });
    };

    useEffect(() => {
        if (isInsuranceCancelledStatus(status)) {
            resetInsuranceState();
            clearUserBookings();

            toast({
                description:
                    'The insurance policy has been successfully cancelled.',
                title: 'Success',
                status: 'success',
                isClosable: true,
                position: 'top-right',
            });
        }
    }, [status]);

    const commonProps = {
        ...props,
        isError: shouldDisplayError,
        isCancelling: isInsuranceCancellingStatus(status),
        onCancel: handleCancelInsurance,
    };

    if (isDesktop) return <CancelInsurancePolicyDialog {...commonProps} />;

    return <CancelInsurancePolicyActionSheet {...commonProps} />;
};

export default CancelInsurancePolicyPopup;
