import { Heading, Text } from '@trova-trip/trova-components';
import { LinkCard, Icon, IconProps } from '@trova-trip/trova-components/build/next';

interface TripCategoryLinkCardProps {
    url: string;
    icon: IconProps['as'];
    name: string;
    description: string;
}

const TripCategoryLinkCard = ({
    url,
    icon,
    name,
    description,
}: TripCategoryLinkCardProps): JSX.Element => (
    <LinkCard
        linkProps={{
            href: url,
            target: '_blank',
        }}
        height='100%'
    >
        <Icon display='inline-flex' as={icon} size='xl' color='red.trova' />
        <Heading as='h6' marginBottom={1} marginTop={2}>
            {name}
        </Heading>
        <Text color='blueGray.500'>{description}</Text>
    </LinkCard>
);

export default TripCategoryLinkCard;
