import NavigationContext from 'navigation/NavigationContext';
import { useContext } from 'react';
import { useLocation } from 'react-router';

export enum TRIP_ACTION {
    CONFIGURATION = 'configuration',
    PRICING = 'pricing',
    ITINERARY = 'itinerary',
    BOOKINGS = 'bookings',
    PREVIEW = 'preview',
    SUCCESS = 'success',
    SERVICES_AGREEMENT = 'services-agreement',
    PROMOTE = 'promote',
    TRIP_OVERVIEW = 'trip-overview',
}

interface UseTripRouteConfig {
    id?: string;
    action: TRIP_ACTION;
    preserveQueryParams?: boolean;
    queryParams?: string;
}

const useTripRoute = (config: UseTripRouteConfig): string => {
    const location = useLocation();
    const { applicationPath = '' } = useContext(NavigationContext) || {};
    const {
        id = ':tripId',
        preserveQueryParams = false,
        action,
        queryParams = '',
    } = config;

    const query = preserveQueryParams ? location.search : queryParams;
    return `${applicationPath}/trips/${id}/${action}${query}`;
};

export default useTripRoute;
