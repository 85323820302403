import { isProfileAddressEnabled } from '../../../../../config/constants';
import { useSelector } from '../../../../../state/hooks';
import { useCurrentItineraryTierInfoForUser } from '../../../../common/hooks/useCurrentHostItineraryTier';

export interface LockedStates {
    tierLocked: boolean;
    incompleteProfile: boolean;
}

const useItineraryDetailsLockedStates = (): LockedStates => {
    const tier = useCurrentItineraryTierInfoForUser();
    const { current: currentUser } = useSelector((state) => state.profile);

    const profileCompletionStatus = currentUser?.profileCompletionStatus;

    const lockedStates: LockedStates = {
        tierLocked: !!tier?.isLocked,
        incompleteProfile:
            isProfileAddressEnabled && !profileCompletionStatus?.aggregated,
    };

    return lockedStates;
};

export default useItineraryDetailsLockedStates;
