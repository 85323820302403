import { models } from '@trova-trip/trova-models';
import { BaseBox, Hide, Stack, Text } from '@trova-trip/trova-components';
import { Icon, IconProps } from '@trova-trip/trova-components/build/next';
import moment from 'moment';
import { useMemo } from 'react';
import TripStatusBadge from './TripStatusBadge';
import { coreUtils } from '@trova-trip/trova-common';

type UpcomingTripStatus = models.hostHomeTab.UpcomingTripStatus;
interface TripSpotsLeftProps {
    spotsLeft: number;
    isSoldOut: boolean;
}
interface TripInfoProps {
    trip: models.hostHomeTab.UpcomingTrip;
}

const tripStatuses = models.hostHomeTab.TripStatuses;
const {
    tripUtils: { getHomeTripStatus },
} = coreUtils;
const { pluralize } = coreUtils.objectUtils;

const TripSpotsLeft = ({ spotsLeft, isSoldOut }: TripSpotsLeftProps) => {
    const iconProps: IconProps = isSoldOut
        ? { as: 'checkedCircle', variant: 'outlined' }
        : { as: 'groups', variant: 'filled' };

    const label = isSoldOut
        ? 'Sold Out'
        : `${pluralize(spotsLeft, 'spot', 'spots')} left`;

    return (
        <Hide breakpoint={{ max: 'md' }}>
            <Stack align='center'>
                <Icon
                    {...iconProps}
                    size='lg'
                    color='blueGray.500'
                    display='flex'
                />
                <Text fontSize='base' color='blueGray.500'>
                    {label}
                </Text>
            </Stack>
        </Hide>
    );
};

const TripInfo = (props: TripInfoProps) => {
    const { trip } = props;
    const { name, isRequest, startDate, endDate, spotsLeft, status } = trip;

    const badgeStatus = isRequest
        ? tripStatuses.Requested
        : getHomeTripStatus(status as UpcomingTripStatus);

    const isTripClosed = badgeStatus === tripStatuses.Closed;
    const isSoldOut = spotsLeft === 0;

    // TODO all date formatting should be done in core date utils
    const datesFormatted = useMemo(
        () => ({
            start: moment(startDate).utc().format('ll'),
            end: moment(endDate).utc().format('ll'),
        }),
        [startDate, endDate],
    );

    const hideSpotsLeft = isTripClosed && !isSoldOut;

    return (
        <Stack
            justify='space-between'
            align='stretch'
            direction='column'
            height='full'
            flexGrow={1}
            padding={{ base: 2, md: 4 }}
            wrap='nowrap'
        >
            <Stack justify='space-between' wrap='nowrap'>
                <BaseBox maxWidth={{ base: '50%', md: '70%' }}>
                    <Text
                        fontSize={{ base: 'base', lg: 'xl' }}
                        fontWeight='bold'
                        noOfLines={2}
                    >
                        {name}
                    </Text>
                </BaseBox>
                <TripStatusBadge tripStatus={badgeStatus} />
            </Stack>
            <Stack direction='column' spacing={1}>
                <Text fontSize={{ base: 'sm', lg: 'lg' }} color='blueGray.500'>
                    {datesFormatted.start} - {datesFormatted.end}
                </Text>
                {!hideSpotsLeft ? (
                    <TripSpotsLeft
                        spotsLeft={spotsLeft}
                        isSoldOut={isSoldOut}
                    />
                ) : null}
            </Stack>
        </Stack>
    );
};

export default TripInfo;
