import { Card, Grid } from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';
import { shouldDisplayPostLaunchInfo } from 'applications/host/utils';
import { useMemo } from 'react';
import { LaunchDateForm } from './Forms';
import PostLaunchInfo from './Forms/PostLaunchInfo';

interface LaunchDateCardProps {
    currentTrip: models.trips.Trip;
    onSubmit: (
        values: any,
        successMessage: string,
        errorMessage: string,
    ) => Promise<any>;
}

const LaunchDateCard = (props: LaunchDateCardProps) => {
    const { currentTrip, onSubmit } = props;

    const isTripLiveAndPostLaunch = useMemo(
        () => shouldDisplayPostLaunchInfo(currentTrip),
        [currentTrip],
    );

    return (
        <Grid.Item columnSpan={{ base: '*', md: 10 }}>
            <Card backgroundColor='white'>
                {isTripLiveAndPostLaunch ? (
                    <PostLaunchInfo currentTrip={currentTrip} />
                ) : (
                    <LaunchDateForm
                        currentTrip={currentTrip}
                        onSubmit={onSubmit}
                    />
                )}
            </Card>
        </Grid.Item>
    );
};

export default LaunchDateCard;
