import { Grid } from '@material-ui/core';
import { coreUtils } from '@trova-trip/trova-common';
import { BaseBox, Currency, Heading, Text } from '@trova-trip/trova-components';
import { useSelector } from '../../../../state/hooks/useSelector';

const { formatUSD } = coreUtils.currencyUtils;
const { getMaxEarningsAdvanceLimit } = coreUtils.hostUtils;

interface FlightAdvanceInputProps {
    flightAdvance: number;
    onChange: (eventValue: number) => void;
    minimumEarnings: number;
    disabled?: boolean;
    errorMessage: string;
}

const FlightAdvanceInput: React.FC<FlightAdvanceInputProps> = ({
    flightAdvance,
    onChange,
    minimumEarnings,
    errorMessage,
    disabled = false,
}) => {
    const currentUser = useSelector((state) => state.profile.current);

    const maxEarningsAdvanceLimit =
        getMaxEarningsAdvanceLimit(currentUser) || 0;

    const earningsAdvanceLimit = Math.min(
        minimumEarnings,
        maxEarningsAdvanceLimit,
    );

    return (
        <Grid container spacing={32}>
            <Grid item xs={12} sm={10}>
                <BaseBox marginY={4}>
                    <Heading as='h4'>Earnings Advance</Heading>
                </BaseBox>
                <Text>
                    {`Once your trip is confirmed, you can receive a portion of your earnings within 90 days of your trip start date. Your advance can’t exceed ${formatUSD(
                        earningsAdvanceLimit,
                    )}.`}
                </Text>
            </Grid>
            <Grid item xs={6}>
                <Currency
                    name='flightStipend'
                    value={flightAdvance}
                    onChange={(_, __, value = 0): void => onChange(value)}
                    error={errorMessage}
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
};

export default FlightAdvanceInput;
