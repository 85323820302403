import React, { useCallback, useEffect, useRef, useState } from 'react';
import qs from 'query-string';
import { matchPath, useLocation } from 'react-router-dom';

const hasTabChanged = (tab, disabled) => tab.disabled !== disabled;

export const usePathLabels = () => {
    const [pathLabels, setPathLabels] = useState([]);
    const pathLabelRef = useRef(new Map());
    const addPathLabel = useCallback(
        ({ path, label, icon, disabled = false }) => {
            if (
                !pathLabelRef.current.has(path) ||
                hasTabChanged(pathLabelRef.current.get(path), disabled)
            ) {
                pathLabelRef.current.set(path, { path, label, icon, disabled });
                setPathLabels(Array.from(pathLabelRef.current.values()));
            }
        },
        [setPathLabels],
    );
    const removePathLabel = useCallback((path) => {
        if (pathLabelRef.current.has(path)) {
            pathLabelRef.current.delete(path);
            setPathLabels(Array.from(pathLabelRef.current.values()));
        }
    });
    return [pathLabels, addPathLabel, removePathLabel];
};

const createQueryString = (query = {}) => {
    const queryList = Object.entries(query);
    if (!queryList.length) {
        return ``;
    }
    return `?${queryList.map(([key, value]) => `${key}=${value}`).join(`&`)}`;
};

const addLinkToNavigationContent = (navigationContent = [], path, [firstTab]) =>
    navigationContent.map((content) => ({
        ...content,
        items: content.items.map((item) => ({
            ...item,
            link:
                item.link ||
                `${defaultLink(
                    `${path}/${item.id}`,
                    firstTab,
                )}${createQueryString(item.query)}`,
        })),
    }));

export const useLoadNavigationData = (
    loadNavigationContent,
    setNavigationContent,
    productPath,
    tabs,
    setSidebarComponent,
) => {
    const [localSidebarContent, setLocalSidebarContent] = useState();
    const { pathname } = useLocation();

    const isProductRoute = !!matchPath(pathname, productPath);

    const loadData = useCallback(async () => {
        if (loadNavigationContent && isProductRoute) {
            const navigationContent = await loadNavigationContent();
            if (React.isValidElement(navigationContent)) {
                setSidebarComponent(navigationContent);
                return;
            }
            setLocalSidebarContent(navigationContent);
        }
    }, [pathname, loadNavigationContent, setSidebarComponent, isProductRoute]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        if (localSidebarContent && isProductRoute) {
            const sidebarContentWithLinks = addLinkToNavigationContent(
                localSidebarContent,
                productPath,
                tabs,
            );
            setNavigationContent(sidebarContentWithLinks);
        }
    }, [
        setNavigationContent,
        productPath,
        tabs,
        localSidebarContent,
        isProductRoute,
    ]);
    return loadData;
};
const defaultLink = (link, firstTab) =>
    firstTab ? `${link}${firstTab.path}` : link;

export const useFetchModelData = (fetchModelData, modelId, queryString) => {
    useEffect(() => {
        if (modelId && fetchModelData) {
            fetchModelData(modelId, qs.parse(queryString));
        }
    }, [modelId, fetchModelData, queryString]);
};
