import { ToastProps } from '@trova-trip/trova-components';
import { GeoLocationComboBoxField } from '@trova-trip/trova-components/build/custom';
import { Option, findOptionFromValue } from '../../../../../util/form/dropdown';
import {
    AddressFieldsNames,
    AddressFormModel,
    User,
} from '../types/ProfileForm.types';

interface FindSubdivisionOptionConfig {
    countryCode?: string;
    value?: string;
}

const { getSubdivisionComboBoxOptions } = GeoLocationComboBoxField;

const findSubdivisionOption = ({
    countryCode,
    value,
}: FindSubdivisionOptionConfig): Option | undefined => {
    if (!countryCode || !value) {
        return;
    }
    const subdivisionsOptions = getSubdivisionComboBoxOptions(countryCode);
    return findOptionFromValue(value, subdivisionsOptions);
};

const preProcessAddressData = (
    addressFormData: AddressFormModel,
): User['address'][0] => ({
    ...addressFormData,
    [AddressFieldsNames.SUBDIVISION_CODE]: addressFormData.subdivisionCode
        ?.value as string,
    [AddressFieldsNames.COUNTRY_CODE]: addressFormData.countryCode
        ?.value as string,
});

interface GetProfileSectionToastConfigParams {
    isSuccess: boolean;
    section: 'profile' | 'address';
}

const getProfileSectionToastConfig = ({
    isSuccess,
    section,
}: GetProfileSectionToastConfigParams): ToastProps => ({
    description: isSuccess
        ? `Your ${section} was updated successfully.`
        : `There was an error saving your ${section}. Please try again.`,
    title: isSuccess ? 'Success' : 'Error',
    status: isSuccess ? 'success' : 'error',
    isClosable: true,
    position: 'top-right',
});

export {
    findSubdivisionOption,
    getProfileSectionToastConfig,
    preProcessAddressData,
};
