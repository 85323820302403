import {
    Breadcrumb as BreadcrumbTC,
    BreadcrumbProps,
} from '@trova-trip/trova-components';
import { TransferTabName } from '../../config';
import { useTransfer, useTransferTabValidation } from '../../hooks';
import { isHost } from '../../../../helpers';
import useNavigateToTransfer from '../../../../hooks/useNavigateToTransfer';

type TransferBreadcrumbItem = { label: string; tabName: TransferTabName };

const Breadcrumb = () => {
    const {
        globalState: { user },
    } = useTransfer();

    const navigateTo = useNavigateToTransfer();

    const { isTabActive, isTabEnabled, activeTabName } =
        useTransferTabValidation();

    const baseItems: TransferBreadcrumbItem[] = [
        { label: 'Travel Detail', tabName: 'travel_detail' },
        { label: 'Vehicle', tabName: 'vehicle' },
        { label: 'Contact', tabName: 'contact' },
        { label: isHost(user) ? 'Booking' : 'Payment', tabName: 'payment' },
    ];

    const items: BreadcrumbProps['items'] = baseItems.map(
        ({ label, tabName }) => ({
            label,
            tabName,
            onClick: () => navigateTo(tabName),
            isActive: isTabActive(tabName),
            isDisabled: !isTabEnabled(tabName),
        }),
    );

    return activeTabName !== 'success' ? <BreadcrumbTC items={items} /> : null;
};

export default Breadcrumb;
