import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
import {
    Checkbox,
    Number as NumberInput,
    Currency,
    BaseBox,
    Text,
    Heading,
    FormGroup
} from '@trova-trip/trova-components';
import { css } from '@emotion/css';

export const PaddedHeading = (props) => {
    const { children, as, marginTop = 0, paddingTop = 0 } = props;

    return (
        <BaseBox marginTop={marginTop} paddingTop={paddingTop}>
            <Heading as={as}>{children}</Heading>
        </BaseBox>
    );
};

export const PaddedText = ({ children }) => {
    return (
        <BaseBox paddingTop={6}>
            <Text>{children}</Text>
        </BaseBox>
    );
};

export const PaddedCurrency = styled(Currency)({
    padding: `.5rem 0`,
});

export const PaddedNumberInput = styled(NumberInput)({
    padding: `.5rem 0`,
});

export const PaddedCheckbox = styled(Checkbox)({
    padding: `1rem 0 2rem 0`,
});

export const StyledContainerGrid = styled(Grid)({
    margin: `4px`,
    paddingBottom: `2rem`,
});

export const FullWidthFormGroup = styled(FormGroup)({
    width: '100%',
});

export const buttonsContainer = css`
    && {
        margin-top: 1rem;
    }
`;
