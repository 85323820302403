import { Table, Currency } from '@trova-trip/trova-components/build/next';
import { Currency as CurrencyInfo } from '@trova-trip/country-data';
import { noop } from 'lodash';
import { Text } from '@trova-trip/trova-components';

interface PriceCellProps {
    price: number;
    justifyRight?: boolean;
    currencyInfo?: CurrencyInfo;
}

const PriceCell = (props: PriceCellProps) => {
    const { price, currencyInfo, justifyRight } = props;

    return (
        <Table.Cell isNumeric={justifyRight} minWidth='52'>
            {price && currencyInfo ? (
                <Currency
                    name='currency'
                    onChange={noop}
                    value={price}
                    isReadOnly={true}
                    display={currencyInfo}
                />
            ) : (
                <Text fontWeight='medium' fontSize='sm'>
                    -
                </Text>
            )}
        </Table.Cell>
    );
};

export default PriceCell;
