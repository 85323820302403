import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import NavigationContext from '../../../../../navigation/NavigationContext';
import { useSelector } from '../../../../../state/hooks';
import { getManageBookingPath } from '../../../../common/helpers';
import { ManageBookingTabName } from '../config';

const useManageBookingNavigation = () => {
    const { currentTrip } = useSelector((state) => ({
        currentTrip: state.userTrips.current,
    }));

    const history = useHistory();

    const { applicationPath: appPath } = useContext(NavigationContext) || {};

    const getManageBookingTabPath = (tab: ManageBookingTabName): string => {
        return getManageBookingPath(currentTrip?.id || '', appPath, tab);
    };

    const navigateTo = (tab: ManageBookingTabName): void => {
        const path = getManageBookingTabPath(tab);
        history.push(path);
    };

    return {
        navigateTo,
        getManageBookingTabPath,
    };
};

export default useManageBookingNavigation;
