import { Label, Stack, Text, useToast } from '@trova-trip/trova-components';
import {
    Button,
    NumberInput,
    Table,
} from '@trova-trip/trova-components/build/next';
import { models } from '@trova-trip/trova-models';
import { useState } from 'react';
import { AdditionalOptionalService } from '../../../../applications/types';
import { useSelector } from '../../../../state/hooks';
import { updateUserTripSingleSupplementInventory } from '../../../../state/userTrips';
import useCurrencyCode from '../../../common/hooks/currencies/useCurrencyCode';
import { capitalize } from '../../../utils';

type Service = models.services.Service;
type onServiceChange = (event, id, type) => void;

interface ServicesSection {
    title: string;
    optionalServices: AdditionalOptionalService[];
}

const Title = ({ children }: { children?: string }): JSX.Element => {
    return (
        <Table.Row>
            <Table.Cell paddingY='4' paddingLeft='4'>
                <Text fontWeight='bold'>{children}</Text>
            </Table.Cell>
            <Table.Cell />
            <Table.Cell />
        </Table.Row>
    );
};

const ServiceTitle = ({ children }: { children?: string }): JSX.Element => {
    return (
        <Table.Cell paddingY='4' paddingLeft='10'>
            <Text fontSize='sm'>{children}</Text>
        </Table.Cell>
    );
};

interface ServiceQuantityCellProps {
    optionalService: AdditionalOptionalService;
    onChange: onServiceChange;
    isDisabled?: boolean;
}

const ServiceQuantityCell = ({
    optionalService,
    onChange,
    isDisabled,
}: ServiceQuantityCellProps): JSX.Element => {
    const currentTrip = useSelector((state) => state.userTrips.current);
    const [value, setValue] = useState(optionalService.quantity);
    const [isLoading, setIsLoading] = useState(false);

    const toast = useToast();

    if (!optionalService.quantity) return <Table.Cell />;

    const fieldName = optionalService.name
        ? `${optionalService.name}-quantity`
        : 'quantity';

    const hasTotalSpotsValueChanged = value !== optionalService.quantity;

    const handleSaveTotalSpotsClick = async (): Promise<void> => {
        if (!currentTrip?.id || !value) return;

        setIsLoading(true);

        try {
            await updateUserTripSingleSupplementInventory(
                currentTrip?.id,
                value,
            );

            toast({
                description:
                    'Single Supplement total spots updated successfully.',
                title: 'Success',
                status: 'success',
                isClosable: true,
            });
        } catch (error) {
            toast({
                description:
                    'There was an error updating the Single Supplement total spots. Please try again.',
                title: 'Error',
                status: 'error',
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Table.Cell textAlign='left'>
            <Stack
                spacing={3}
                direction='row'
                align='center'
                justify={{ xs: 'flex-start', md: 'flex-end' }}
                minWidth={{ xs: 80, md: 44 }}
            >
                <Label
                    id='available'
                    htmlFor={`field-${fieldName}`}
                    isDisabled={isDisabled}
                >
                    Total Spots
                </Label>

                <NumberInput
                    value={optionalService.quantity}
                    onChange={(event) => {
                        setValue(
                            parseFloat(
                                (event.target as HTMLInputElement).value,
                            ),
                        );
                        onChange(
                            event,
                            optionalService.id || optionalService._id,
                            optionalService.type,
                        );
                    }}
                    name={fieldName}
                    size='sm'
                    isDisabled={isDisabled}
                    maxWidth='20'
                    min={0}
                />

                <Stack minWidth={8}>
                    {hasTotalSpotsValueChanged ? (
                        <Button
                            variant='tertiary'
                            color='red.coral'
                            size='sm'
                            onClick={handleSaveTotalSpotsClick}
                            isLoading={isLoading}
                        >
                            Save
                        </Button>
                    ) : null}
                </Stack>
            </Stack>
        </Table.Cell>
    );
};

interface ServicePriceCellProps {
    onChange: onServiceChange;
    isDisabled?: boolean;
    currencyCode: string;
    optionalService: Service;
}

const ServicePriceCell = ({
    onChange,
    optionalService,
    isDisabled,
    currencyCode,
}: ServicePriceCellProps): JSX.Element => (
    <Table.Cell textAlign='end'>
        <Stack justify='end' minWidth={44}>
            <NumberInput
                value={optionalService.price}
                onChange={(event) =>
                    onChange(
                        event,
                        optionalService.id || optionalService._id,
                        optionalService.type,
                    )
                }
                name={optionalService.name || ''}
                size='sm'
                rightAddon={{
                    text: currencyCode,
                }}
                isDisabled={isDisabled}
                maxWidth='44'
            />
        </Stack>
    </Table.Cell>
);

type ServicesRowProps = {
    service?: ServicesSection;
    onChange: onServiceChange;
    isDisabled?: boolean;
    currencyCode: string;
    shouldDisableField?: (fieldName: string) => boolean;
};

const ServiceRow = ({
    service,
    onChange,
    isDisabled,
    currencyCode,
    shouldDisableField,
}: ServicesRowProps): JSX.Element => {
    return (
        <>
            <Title>{service?.title}</Title>
            {service?.optionalServices?.map((optionalService) => {
                const title = capitalize(
                    optionalService?.label || optionalService?.name || '',
                );

                return (
                    <Table.Row key={optionalService.id}>
                        <ServiceTitle>{title}</ServiceTitle>
                        <ServiceQuantityCell
                            onChange={onChange}
                            optionalService={optionalService}
                            isDisabled={
                                shouldDisableField &&
                                shouldDisableField(
                                    'optionalAccommodations.singleSupplement.quantity',
                                )
                            }
                        />
                        <ServicePriceCell
                            onChange={onChange}
                            optionalService={optionalService}
                            isDisabled={isDisabled}
                            currencyCode={currencyCode}
                        />
                    </Table.Row>
                );
            })}
        </>
    );
};

type ServicesTableProps = {
    services?: ServicesSection[];
    currency: string;
    onChange: onServiceChange;
    isDisabled?: boolean;
    shouldDisableField?: (fieldName: string) => boolean;
};

const ServicesTable = ({
    services = [],
    onChange,
    currency,
    isDisabled,
    shouldDisableField,
}: ServicesTableProps): JSX.Element => {
    const currencyCode = useCurrencyCode(currency);

    return (
        <Table variant='simple' borderOutline size='md'>
            <Table.Body>
                {services.map((service) => {
                    return service?.optionalServices?.length > 0 ? (
                        <ServiceRow
                            service={service}
                            onChange={onChange}
                            currencyCode={currencyCode || 'USD'}
                            isDisabled={isDisabled}
                            shouldDisableField={shouldDisableField}
                        />
                    ) : null;
                })}
            </Table.Body>
        </Table>
    );
};

export default ServicesTable;
