import { LineChart, Line, LabelList } from 'recharts';
import { Card, Text, theme } from '@trova-trip/trova-components';
import CustomLabel from './CustomLabel';
import { styledLineChart } from './BookingsChart.styles';
import { isEmpty } from 'lodash';
import BookingsEmptyMessage from '../BookingsEmptyMessage/BookingsEmptyMessage';

interface BookingsChartProps {
    data: ChartItemValue[];
}

export interface ChartItemValue {
    name: string;
    bookings: number;
}

const BookingsChart = ({ data }: BookingsChartProps): JSX.Element => {
    const title = 'Bookings to Date';

    if (isEmpty(data)) {
        return (
            <BookingsEmptyMessage
                title={title}
                message={
                    'Once you start receiving bookings, you can manage travelers here.'
                }
            />
        );
    }

    return (
        <Card backgroundColor={theme.colors.neutral.white}>
            <Text fontWeight={'bold'}>{title}</Text>
            <LineChart
                className={styledLineChart}
                width={800}
                height={200}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 15,
                }}
            >
                <Line type='natural' dataKey='bookings' stroke='#8884d8'>
                    <LabelList content={<CustomLabel data={data} />} />
                </Line>
            </LineChart>
        </Card>
    );
};

export default BookingsChart;
