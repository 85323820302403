import { Dispatch, SetStateAction, useState } from 'react';
import { ToastProps, useToast } from '@trova-trip/trova-components';
import { Dialog, DialogProps } from '@trova-trip/trova-components/build/next';
import { useSelector } from '../../../../state/hooks/useSelector';
import { logError } from '../../../../applications/common/helpers';
import { userTrips } from '../../../../state/userTrips';

type ApproveTechnicalItineraryModalProps = {
    isOpen: DialogProps['isOpen'];
    setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const errorMessage: ToastProps = {
    title: 'Unable to process request',
    description:
        'Something went wrong approving the Technical Itinerary. Please try again.',
    status: 'error',
};

const successMessage: ToastProps = {
    title: 'Technical Itinerary approved',
    description: 'The Technical Itinerary was successfully approved',
    status: 'success',
};

const ApproveTechnicalItineraryModal = ({
    isOpen,
    setIsOpen,
}: ApproveTechnicalItineraryModalProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const trip = useSelector((state) => state.userTrips.current);
    const { updateRecord: updateTrip } = userTrips.useDispatch.bind(trip)();

    const toast = useToast();

    const handleCancel = (): void => setIsOpen(false);

    const handleSubmit = async (): Promise<void> => {
        if (!trip) return;

        const { id } = trip;

        let response: ToastProps = successMessage;

        setIsLoading(true);
        try {
            await updateTrip(id, { operatorTechnicalItineraryApproved: true });
            setIsOpen(false);
        } catch (error) {
            response = errorMessage;
            logError(error);
        }
        toast({ ...response, isClosable: true });
        setIsLoading(false);
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={handleCancel}
            closeOnOverlayClick={true}
            showCloseButton={true}
        >
            <Dialog.Header title='Approve Technical Itinerary' />
            <Dialog.Body>
                Submitting the technical itinerary will finalize accommodations,
                meals, and activities for the trip.
            </Dialog.Body>
            <Dialog.Footer
                actions={[
                    {
                        children: 'Cancel',
                        variant: 'secondary',
                        onClick: handleCancel,
                    },
                    {
                        children: 'Submit',
                        type: 'submit',
                        isDisabled: isLoading,
                        onClick: handleSubmit,
                    },
                ]}
            />
        </Dialog>
    );
};

export default ApproveTechnicalItineraryModal;
