import React from 'react';
import IframeTravelerWaiver from './IframedTravelerWaiver';
import PropTypes from 'prop-types';

const TravelerTerms = ({ trip }) => <IframeTravelerWaiver trip={trip} />;

TravelerTerms.propTypes = {
    history: PropTypes.object,
    trip: PropTypes.object,
};
export default TravelerTerms;
