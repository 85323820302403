import { BaseBox } from '@trova-trip/trova-components';
import TabDetailsInformation from 'components/TabDetailsInformation/TabDetailsInformation';
import { ReactNode } from 'react';

interface TabPageProps {
    title: string;
    description?: React.ReactNode;
    content: ReactNode;
}

const TabPage = ({
    title,
    description,
    content,
}: TabPageProps): JSX.Element => {
    return (
        <>
            <BaseBox marginBottom={4}>
                <TabDetailsInformation title={title} />
                <BaseBox marginTop={4}>{description}</BaseBox>
            </BaseBox>
            <BaseBox marginTop={10}>{content}</BaseBox>
        </>
    );
};

export default TabPage;
