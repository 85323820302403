import { cloneDeep, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { models, constants } from '@trova-trip/trova-models';
import {
    CompanionFormResponse,
    CompanionFormMap,
    FormCompanion,
} from './CompanionsForm.types';
import { FormDataObjectType } from '@trova-trip/trova-components';
import {
    findTripHostCompanions,
    createTripHostCompanions,
} from 'state/tripsTraveler';
import { userTrips } from 'state/userTrips';

const TripStatus = constants.trips.TRIP_STATUS;

interface GetCompanionsInformationReturn {
    formCompanions: FormCompanion[];
    editMode: boolean;
}

interface UseCompanionFormAPIReturn {
    initialValues: CompanionFormMap;
    handleSubmit: (
        tripId: string,
        data: FormDataObjectType,
    ) => Promise<CompanionFormResponse>;
    parseDateChange: (newDate: moment.Moment) => string;
}

const useGetCompanionsInformation = (): GetCompanionsInformationReturn => {
    const [formCompanions, setFormCompanions] = useState<FormCompanion[]>([]);
    const [editMode, setEditMode] = useState(false);
    const {
        userTrips: { current: currentTrip },
    } = useSelector(
        (state: { userTrips: { current: models.trips.Trip } }) => state,
    );
    const { id: tripId, companions, status } = currentTrip || {};

    const isTripEditable = ![TripStatus.COMPLETE, TripStatus.CLOSED].includes(
        status,
    );
    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            const companionsAreNotSubmitted = companions?.every((companion) =>
                isEmpty(companion.traveler),
            );
            if (companions && companionsAreNotSubmitted) {
                setFormCompanions(cloneDeep(companions));
                setEditMode(isTripEditable);
            } else {
                const existingCompanionsResult = await findTripHostCompanions(
                    tripId,
                );
                if (existingCompanionsResult.success) {
                    setFormCompanions(existingCompanionsResult.data);
                    setEditMode(false);
                }
            }
        };
        fetchData();
    }, [tripId, editMode, companions, isTripEditable]);

    return { formCompanions, editMode };
};

const useCompanionFormAPI = (): UseCompanionFormAPIReturn => {
    const { getRecord: refreshTripRecord } = userTrips.useDispatch();

    const handleSubmit = async (
        tripId: string,
        data: FormDataObjectType,
    ): Promise<CompanionFormResponse> => {
        const companionsToSubmit = Object.values(data);
        const response = await createTripHostCompanions(tripId, {
            companions: companionsToSubmit,
        });
        if (response.success) {
            refreshTripRecord(tripId);
        }
        return { response };
    };

    const { formCompanions } = useGetCompanionsInformation();

    const getFormattedDateOfBirth = (dateOfBirth: string): string => {
        const formattedDateOfBirth = moment(dateOfBirth).utc();
        return formattedDateOfBirth.isValid()
            ? formattedDateOfBirth.format('MM/DD/YYYY')
            : '';
    };

    const getInitialValues = (): CompanionFormMap => {
        const formattedCompanions: CompanionFormMap = {};
        if (formCompanions?.length) {
            formCompanions.forEach((companion) => {
                const { dateOfBirth } = companion;
                formattedCompanions[`${companion._id}`] = {
                    ...companion,
                    dateOfBirth: dateOfBirth
                        ? getFormattedDateOfBirth(dateOfBirth)
                        : '',
                };
            });
        }
        return formattedCompanions;
    };

    const initialValues = getInitialValues();

    const parseDateChange = (date: moment.Moment): string => {
        const offset = date.utcOffset();
        const utcDate = moment(date).utc();
        const compensatedDate =
            offset < 0
                ? utcDate.subtract(-offset, 'minutes')
                : utcDate.add(offset, 'minutes');

        // Return an ISO 8601 string
        return compensatedDate.format();
    };

    return {
        initialValues,
        handleSubmit,
        parseDateChange,
    };
};

export { useGetCompanionsInformation, useCompanionFormAPI };
