import { useMemo, useState } from 'react';
import { models } from '@trova-trip/trova-models';
import {
    BadgeProps,
    BaseBox,
    Drawer,
    Heading,
} from '@trova-trip/trova-components';
import useCurrentUserGroupMatcher from '../../../applications/common/hooks/useCurrentUserGroupMatcher';
import { getAccountPath } from '../../../applications/common/products/account/utils/routes.utils';
import { UserGroup } from '../../../applications/types';
import { useLogoutAndRedirect } from '../hooks';
import ProfileNavigationDrawerItem, {
    ProfileNavigationDrawerItemType,
} from './ProfileNavigationDrawerItem';
import OperatorContactInfoDialog from '../OperatorContactInfoDialog/OperatorContactInfoDialog';
import useProfileNavigationDrawer from './useProfileNavigationDrawer';
import { useHistory } from 'react-router-dom';
import useProfileCompletionStatus, {
    ProfileCompletionStatuses,
} from '../../../applications/host/hooks/useProfileCompletionStatus';
import {
    isProfileAddressEnabled,
    isPublicHostProfileEnabled,
} from '../../../config/constants';
import { basePublicProfileRoute } from '../../../applications/host/products/publicProfile/PublicProfile';
import usePublicProfileAnalytics, {
    PUBLIC_PROFILE_TRACKING_EVENTS,
} from '../../../applications/host/hooks/usePublicProfileAnalytics';

interface ProfileNavigationDrawerProps {
    profile: models.users.User;
}

const navigationItemBadgeConfigs: Record<
    Exclude<ProfileCompletionStatuses, ProfileCompletionStatuses.COMPLETE>,
    BadgeProps
> = {
    [ProfileCompletionStatuses.INCOMPLETE]: {
        children: 'Incomplete',
        colorScheme: 'warning',
    },
    [ProfileCompletionStatuses.REQUIRED]: {
        children: 'Required',
        colorScheme: 'error',
    },
} as const;

const ProfileNavigationDrawer = ({ profile }: ProfileNavigationDrawerProps) => {
    const { isDrawerOpen, toggleDrawer } = useProfileNavigationDrawer();
    const logOutUser = useLogoutAndRedirect();
    const isCurrentUserHost = useCurrentUserGroupMatcher(UserGroup.HOST);
    const isCurrentUserOperator = useCurrentUserGroupMatcher(
        UserGroup.OPERATOR,
    );
    const [showOperatorInfoDialog, setShowOperatorInfoDialog] = useState(false);
    const history = useHistory();
    const { addressStatus, publicProfileStatus } = useProfileCompletionStatus();
    const { trackPublicProfileEvent } = usePublicProfileAnalytics();
    const { firstName, lastName } = profile;

    const showAccountBadge = isCurrentUserHost && isProfileAddressEnabled;
    const showPublicProfile = isPublicHostProfileEnabled && isCurrentUserHost;

    const handleLogout = () => {
        logOutUser();
        toggleDrawer();
    };

    const handleOpenOperatorInfoDialog = () => {
        setShowOperatorInfoDialog(true);
        toggleDrawer();
    };

    const navigateTo = (route: string) => {
        history.push(route || '/');
        toggleDrawer();
    };

    const accountPath = getAccountPath(profile);

    const navigationItems: ProfileNavigationDrawerItemType[] = useMemo(
        () => [
            {
                title: 'Public Profile',
                onItemClicked: () => {
                    trackPublicProfileEvent(
                        PUBLIC_PROFILE_TRACKING_EVENTS.clickedPublicProfileInAvatarDrawer,
                    );
                    navigateTo(basePublicProfileRoute);
                },
                isVisible: showPublicProfile,
                badgeConfig: showAccountBadge
                    ? navigationItemBadgeConfigs[publicProfileStatus]
                    : undefined,
            },
            {
                title: 'Account',
                onItemClicked: () => navigateTo(accountPath),
                badgeConfig: showAccountBadge
                    ? navigationItemBadgeConfigs[addressStatus]
                    : undefined,
            },
            {
                title: 'Refer a Host',
                onItemClicked: () => navigateTo('/app/host/referral'),
                isVisible: isCurrentUserHost,
            },

            {
                title: 'Change Password',
                onItemClicked: () => navigateTo('/change-password'),
            },
            {
                title: 'Get Help',
                onItemClicked: handleOpenOperatorInfoDialog,
                isVisible: isCurrentUserOperator,
            },
            {
                title: 'Log Out',
                onItemClicked: handleLogout,
            },
        ],
        [
            isCurrentUserHost,
            handleLogout,
            handleOpenOperatorInfoDialog,
            isCurrentUserOperator,
            toggleDrawer,
        ],
    );

    return (
        <>
            <Drawer
                placement='right'
                size='xs'
                isOpen={isDrawerOpen}
                onClose={toggleDrawer}
            >
                <Drawer.Header
                    borderTop='1px solid'
                    borderBottom='1px solid'
                    borderColor='blueGray.200'
                >
                    <Heading as='h5'>{`${firstName} ${lastName}`}</Heading>
                </Drawer.Header>
                <Drawer.Body padding={0}>
                    <BaseBox
                        borderBottom='1px solid'
                        borderColor='blueGray.200'
                    >
                        {navigationItems.map((items, index) => (
                            <ProfileNavigationDrawerItem
                                key={index}
                                item={items}
                            />
                        ))}
                    </BaseBox>
                </Drawer.Body>
            </Drawer>
            <OperatorContactInfoDialog
                open={showOperatorInfoDialog}
                onClose={() => setShowOperatorInfoDialog(false)}
            />
        </>
    );
};

export default ProfileNavigationDrawer;
