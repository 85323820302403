import { models } from '@trova-trip/trova-models';

const emptySurveyData: models.survey.AudienceSurveySubmissionsData = {
    metrics: {
        responses: {
            type: 'total',
            data: 0,
        },
        qualifiedResponses: {
            type: 'total',
            data: 0,
        },
        topDestinations: {
            type: 'text',
            data: 'N/A',
        },
        budgetAverage: {
            type: 'total',
            data: 0,
        },
        budgetMedian: {
            type: 'total',
            data: 0,
        }
    },
    graphs: {
        popularDestinations: [],
        timeOfYear: [],
        budget: [],
        age: [],
        gender: [],
    },
};

export { emptySurveyData };
