import { Alert, BaseBox, Grid } from '@trova-trip/trova-components';
import noop from 'lodash/noop';
import TripRequestStartDateCard from './components/CalendarCard/TripRequestStartDateCard';
import { Button } from '@trova-trip/trova-components/build/next';
import { useHistory } from 'react-router-dom';
import { applicationRootPath } from '../../../../navigation/NavigationContext';
import { ROUTE_TOKENS } from '../../../common/constants';
import { HOST_APP_PRODUCT_ROUTE } from 'applications/common/constants/routesTokens';

const catalogPath = `${applicationRootPath}${ROUTE_TOKENS.HOST_APP_ROUTE}${HOST_APP_PRODUCT_ROUTE.ITINERARIES}`;

const EmptyRequestForm = () => {
    const history = useHistory();

    const navigate = () => history.push(catalogPath);

    return (
        <Grid>
            <Grid.Item columnSpan={12}>
                <Alert
                    status='info'
                    variant='inline'
                    title='There are no dates available for this itinerary.'
                    description=''
                    onCloseAlert={noop}
                    marginBottom={8}
                />
                <TripRequestStartDateCard
                    editMode={false}
                    disabledDates={[]}
                    enabledDates={[]}
                    instantApprovalDates={[]}
                    onChange={noop}
                    tripLength={0}
                    showInstantApproval={false}
                />
                <BaseBox textAlign='center' paddingY={8}>
                    <Button
                        variant='secondary'
                        size='sm'
                        leftIcon='arrow-left'
                        onClick={navigate}
                    >
                        Go back
                    </Button>
                </BaseBox>
            </Grid.Item>
        </Grid>
    );
};

export default EmptyRequestForm;
