/*issue with CRA4 and React 17: https://github.com/emotion-js/emotion/issues/2041*/
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { ComponentWidth } from '@trova-trip/trova-components';
import PropTypes from 'prop-types';

import { StyledFileInput } from './TrovaFileInput.components';
import {
    mainContainer,
    labelContainer,
    ErrorSpan,
    LabelSpan,
    DetailSpan,
} from './TrovaFileInput.styles';

const TROVA_IMAGE_UPLOAD_URL = `/api/me/files/new-upload-image`;

const TrovaFileInput = ({
    onChange,
    error,
    size = ComponentWidth.Medium,
    label,
    detail,
    className,
    maxFiles,
    key,
    name,
    value,
    disabled = false,
}) => {
    return (
        <div css={mainContainer()} className={className}>
            {label && (
                <div css={labelContainer()}>
                    <LabelSpan>{label}</LabelSpan>
                </div>
            )}
            {detail && <DetailSpan>{detail}</DetailSpan>}
            <StyledFileInput
                key={key}
                name={name}
                onChange={onChange}
                error={error}
                size={size}
                maxFiles={maxFiles}
                sortable={false}
                value={value}
                uploadUrl={TROVA_IMAGE_UPLOAD_URL}
                disabled={disabled}
            />
            {error && typeof error === `string` && (
                <ErrorSpan>{error}</ErrorSpan>
            )}
            {error && Array.isArray(error) && (
                <ErrorSpan>
                    {error.reduce((a, b) => {
                        return a.concat(`, `).concat(b);
                    })}
                </ErrorSpan>
            )}
        </div>
    );
};
TrovaFileInput.propTypes = {
    onChange: PropTypes.func,
    error: PropTypes.string,
    size: PropTypes.string,
    label: PropTypes.string,
    detail: PropTypes.string,
    className: PropTypes.string,
    maxFiles: PropTypes.string,
    key: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.object,
};
export default TrovaFileInput;
