import { Field, Grid } from '@trova-trip/trova-components';
import { GeoLocationComboBoxField } from '@trova-trip/trova-components/build/custom';
import {
    AddressFieldsNames,
    AddressFormModel,
} from '../../../types/ProfileForm.types';

interface AddressFormFieldsProps {
    values: AddressFormModel;
    handleChange: (event) => void;
}

interface AddressFieldWrapperProps {
    children: React.ReactElement;
}

const AddressFieldWrapper = ({
    children,
}: AddressFieldWrapperProps): JSX.Element => (
    <Grid.Item columnSpan={{ base: 12, md: 6 }}>{children}</Grid.Item>
);

const AddressFormFields = (props: AddressFormFieldsProps): JSX.Element => {
    const { values, handleChange } = props;

    return (
        <>
            <AddressFieldWrapper>
                <Field
                    as='text'
                    name={AddressFieldsNames.STREET_LINE_ONE}
                    label='Address Line 1'
                    helperText='Street Address, P.O. Box'
                    isRequired
                />
            </AddressFieldWrapper>
            <AddressFieldWrapper>
                <Field
                    as='text'
                    name={AddressFieldsNames.STREET_LINE_TWO}
                    label='Address Line 2'
                    helperText='Apartment, suite, unit, building, floor'
                />
            </AddressFieldWrapper>
            <AddressFieldWrapper>
                <GeoLocationComboBoxField
                    name={AddressFieldsNames.COUNTRY_CODE}
                    type='countries'
                    label='Country'
                    isRequired
                />
            </AddressFieldWrapper>
            <AddressFieldWrapper>
                <GeoLocationComboBoxField
                    label='State/Region/Province'
                    type='subdivisions'
                    name={AddressFieldsNames.SUBDIVISION_CODE}
                    countryCode={values.countryCode?.value || ''}
                    handleChange={handleChange}
                    isDisabled={!values.countryCode?.value}
                    isRequired
                />
            </AddressFieldWrapper>
            <AddressFieldWrapper>
                <Field
                    as='text'
                    name={AddressFieldsNames.CITY}
                    label='City'
                    isRequired
                />
            </AddressFieldWrapper>
            <AddressFieldWrapper>
                <Field
                    as='text'
                    name={AddressFieldsNames.POSTAL_CODE}
                    label='Zip/Postal Code'
                    isRequired
                />
            </AddressFieldWrapper>
        </>
    );
};

export default AddressFormFields;
