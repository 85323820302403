import { css } from '@emotion/css';

export const styledEmptyMessage = css`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    min-height: 150px;

    header {
        position: absolute;
        top: 0.9rem;
        left: 1.2rem;
    }

    p {
        text-align: center;
    }
`;
