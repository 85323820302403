import {
    BaseBox,
    Grid,
    Heading,
    Image,
    Stack,
    Skeleton,
} from '@trova-trip/trova-components';
import { Card, Link } from '@trova-trip/trova-components/build/next';
import { useTripPageScreenshot } from 'applications/common/hooks/trips/useTripPageScreenshot';
import {
    TRIP_PAGE_STATUS,
    useTripPageStatus,
} from 'applications/common/hooks/trips/useTripPageStatus';
import useTripPageUrl from 'applications/common/hooks/trips/useTripPageUrl';
import ListItemStripBar from 'components/ListItemStripBar';
import React, { useMemo, Suspense } from 'react';
import defaultImage from '../../../../../../assets/img/default-trip-page-screenshot.png';
import { useTripStatusItems } from './useTripStatusItems';

const contentMap = {
    [TRIP_PAGE_STATUS.LAUNCH_NOT_SCHEDULED]: React.lazy(
        () => import('./contents/LaunchNotScheduledContent'),
    ),
    [TRIP_PAGE_STATUS.PRE_LAUNCH_NOT_BOOKABLE]: React.lazy(
        () => import('./contents/PreLaunchContent'),
    ),
    [TRIP_PAGE_STATUS.PRE_LAUNCH_BOOKABLE]: React.lazy(
        () => import('./contents/PreLaunchContent'),
    ),
    [TRIP_PAGE_STATUS.LIVE]: React.lazy(() => import('./contents/LiveContent')),
    [TRIP_PAGE_STATUS.CONFIRMED_BOOKABLE]: React.lazy(
        () => import('./contents/ConfirmedBookableContent'),
    ),
    [TRIP_PAGE_STATUS.CONFIRMED_SOLD_OUT]: React.lazy(
        () => import('./contents/ConfirmedSoldOutContent'),
    ),
    [TRIP_PAGE_STATUS.CLOSED]: React.lazy(
        () => import('./contents/ClosedContent'),
    ),
    default: null,
};

const ContentFallback = (): JSX.Element => (
    <Stack direction='column' spacing={8}>
        <Skeleton height='2.5rem' width='80%' />
        <Stack direction='column' spacing={3} width='100%'>
            <Skeleton height='1.5rem' width='70%' />
            <Skeleton height='1.5rem' width='60%' />
            <Skeleton height='1.5rem' width='50%' />
        </Stack>
        <Skeleton height='3rem' width='30%' />
    </Stack>
);

const TripPageCard = (): JSX.Element => {
    const tripPageStatus = useTripPageStatus();
    const tripPageUrl = useTripPageUrl();

    const tripPageScreenshot = useTripPageScreenshot({
        width: 1080,
        height: 930,
    });

    const CurrentContent = useMemo(() => {
        return tripPageStatus ? contentMap[tripPageStatus] : contentMap.default;
    }, [tripPageStatus]);

    const statusItems = useTripStatusItems();

    return (
        <Card>
            <Stack direction='column' spacing={5}>
                <Heading as='h4'>Trip Page</Heading>
                <Stack direction='column' spacing={5}>
                    <Grid>
                        <Grid.Item
                            columnSpan={{ base: 12, md: 4 }}
                            textAlign='center'
                        >
                            <Stack
                                direction='column'
                                spacing={2}
                                align='center'
                                minWidth={{ xl: '18.75rem' }}
                            >
                                <Image
                                    src={tripPageScreenshot}
                                    fallbackSrc={defaultImage}
                                    alt='Trip Page'
                                    boxShadow='1'
                                    borderRadius='base'
                                    data-gumlet='false'
                                />
                                <Link
                                    href={tripPageUrl}
                                    size={{ base: 'sm', md: 'base' }}
                                    rightIcon='offsite'
                                    color='neutral.60'
                                    isExternal
                                >
                                    Open Trip Page
                                </Link>
                            </Stack>
                        </Grid.Item>
                        <Grid.Item columnSpan={{ base: 12, md: 8 }}>
                            <Suspense fallback={<ContentFallback />}>
                                {CurrentContent && <CurrentContent />}
                            </Suspense>
                        </Grid.Item>
                    </Grid>
                    <BaseBox
                        height='1px'
                        width='100%'
                        backgroundColor='neutral.95'
                    />
                    <ListItemStripBar
                        items={statusItems}
                        activeItemText='Current status'
                    />
                </Stack>
            </Stack>
        </Card>
    );
};

export default TripPageCard;
