import { models } from '@trova-trip/trova-models';

type User = models.users.User;
type Host = models.hosts.Host;
type SocialUserInitResult = models.socialEngagement.SocialUserInitResult;
type SocialChannels = models.users.SocialChannels;
type PreviousExperience = models.hosts.PreviousExperience;
type Bio = Pick<User, 'bio' | 'profilePictureUrl'>;
interface Socials extends Pick<SocialUserInitResult, 'socialEngagementUserId'> {
    channels: SocialChannels;
    isManualConnect: boolean;
}

export enum STEP_NAME {
    WELCOME,
    INTERESTS,
    SOCIALS,
    EXPERIENCE,
    BIO,
    CONGRATS,
}

export enum AnalyticsEventNames {
    HostStartedOnboarding = 'Host Started Onboarding Flow',
    HostCompletedInterests = 'Host Completed Interests',
    HostCompletedSocial = 'Host Completed Social',
    HostClickedOnConnectSocialManually = 'Host Clicked on Connect Manually',
    HostClickedOnSocialAccountConnect = 'Host Clicked on Connect Account',
    HostGotSocialAccountConnectError = 'Host Got Connect Account Error',
    HostCompletedBioStep = 'User Completed Create Your Profile onboarding step',
    HostSkippedBioStep = 'User Skipped Create Your Profile onboarding step',
}

export type {
    User,
    Host,
    PreviousExperience,
    Bio,
    Socials,
    SocialUserInitResult,
};
