import { analyticsTypes } from '@trova-trip/trova-common';
import { models } from '@trova-trip/trova-models';
import { TripSummarySections } from '../../../../../state/features/manageBooking/types';
import { ManageBookingAddOn, Trip } from './types';

type PaymentOptionType = models.payments.PaymentOptionType;

export enum AnalyticsEvent {
    VIEW_MANAGE_BOOKING_TAB = 'view-manage-booking-tab',
    UPDATE_CART = 'update-cart',
    VIEW_PORTAL_CHECKOUT_PAGE = 'view-portal-checkout-page',
    CLEAR_CART = 'clear-cart',
    MODIFY_BOOKING = 'modify-booking',
    PURCHASE_SERVICE = 'purchase-service',
    REMOVE_SERVICE = 'remove-service',
}

export const AnalyticsEventNameMap: Record<AnalyticsEvent, string> = {
    [AnalyticsEvent.VIEW_MANAGE_BOOKING_TAB]: 'User Viewed Manage Booking Tab',
    [AnalyticsEvent.UPDATE_CART]: 'User Updated Cart',
    [AnalyticsEvent.VIEW_PORTAL_CHECKOUT_PAGE]:
        'User Viewed Portal Checkout Page',
    [AnalyticsEvent.CLEAR_CART]: 'User Cleared Cart',
    [AnalyticsEvent.MODIFY_BOOKING]: 'User Modified Booking',
    [AnalyticsEvent.PURCHASE_SERVICE]: 'User Purchased Service',
    [AnalyticsEvent.REMOVE_SERVICE]: 'User Removed Service',
};

interface DefaultProps {
    userId: string | undefined;
    tripName: string | undefined;
    tripId: string | undefined;
    bookingId: string | undefined;
    itineraryId: string | undefined;
}

type TrackEventProperties = analyticsTypes.TrackEventParameters['properties'];

interface TrackEventProps<T extends DefaultProps> {
    eventName: string;
    payload?: T;
}

interface ViewManageBookingTabPayload extends DefaultProps {
    tripStatus: Trip['status'] | undefined;
}

type AddedAndRemovedSectionsType = Pick<
    TripSummarySections,
    'added' | 'removed' | 'roomSelection'
>;

interface PendingAddonsProperties {
    sections: AddedAndRemovedSectionsType;
    singleSupplement: ManageBookingAddOn | undefined;
    cartBalance: number;
}

interface UpdateCartPayload extends DefaultProps, PendingAddonsProperties {}

interface ViewPortalCheckoutPagePayload
    extends DefaultProps,
        PendingAddonsProperties {
    tripStatus: Trip['status'] | undefined;
}

interface ClearCartPayload extends DefaultProps, PendingAddonsProperties {}

interface ModifyBookingPayload extends DefaultProps, PendingAddonsProperties {
    paidBalance?: number;
    paymentOption?: PaymentOptionType;
}

export type {
    AddedAndRemovedSectionsType,
    ClearCartPayload,
    DefaultProps,
    ModifyBookingPayload,
    TrackEventProperties,
    TrackEventProps,
    UpdateCartPayload,
    ViewManageBookingTabPayload,
    ViewPortalCheckoutPagePayload,
};
