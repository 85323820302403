import {
    BaseBox,
    Grid,
    Hide,
    Stack,
    ToastProps,
    useToast,
} from '@trova-trip/trova-components';
import { useRef, useState } from 'react';
import Audience from '../common/components/Audience/Audience';
import { ImportantDates, UpcomingTrips } from './components';

export const fetchErrorToast: ToastProps = {
    title: 'Error retrieving your data',
    description: 'There was an error while we were getting your data',
    status: 'error',
    isClosable: true,
    position: 'top-right',
};

const HomeWidgets = (): JSX.Element => {
    // @ts-ignore
    const [firstColumnHeight, setFirstColumnHeight] = useState<
        number | undefined
    >();
    const toast = useToast();
    const firstColumnRef = useRef<HTMLDivElement>(null);
    const toastErrorShown = useRef<boolean>(false);

    const updateColumnHeight = () => {
        if (firstColumnRef.current) {
            setFirstColumnHeight(firstColumnRef?.current.clientHeight);
        }
    };

    const showErrorToast = () => {
        if (!toastErrorShown.current) {
            toastErrorShown.current = true;
            toast(fetchErrorToast);
        }
    };

    return (
        <>
            <Grid
                width='full'
                marginX='auto'
                gap={6}
                marginTop={{ base: 4, md: 24, lg: 8 }}
            >
                <Grid.Item
                    rowStart={{ base: 'auto', md: 1 }}
                    columnSpan={{ base: 12, md: 7, lg: 8 }}
                >
                    <BaseBox ref={firstColumnRef}>
                        <Stack
                            wrap='nowrap'
                            direction='column'
                            align='stretch'
                            spacing={6}
                        >
                            <UpcomingTrips
                                onError={showErrorToast}
                                onLoad={updateColumnHeight}
                            />
                            <Hide breakpoint={{ max: 'md' }}>
                                <Audience
                                    onError={showErrorToast}
                                    onLoad={updateColumnHeight}
                                />
                            </Hide>
                        </Stack>
                    </BaseBox>
                </Grid.Item>
                <Grid.Item
                    columnSpan={{ base: 12, md: 5, lg: 4 }}
                    rowStart={{ md: 1 }}
                    rowSpan={1}
                    overflow='hidden'
                    height={{
                        base: 'full',
                        md: firstColumnHeight,
                    }}
                >
                    <ImportantDates
                        onError={showErrorToast}
                        onLoad={updateColumnHeight}
                    />
                </Grid.Item>
                <Hide breakpoint={{ min: 'md' }}>
                    <Grid.Item columnSpan={12} rowSpan={1} rowStart={{ md: 2 }}>
                        <Audience onError={showErrorToast} />
                    </Grid.Item>
                </Hide>
            </Grid>
        </>
    );
};

export default HomeWidgets;
