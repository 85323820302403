import posthog from 'posthog-js';

const initializeWithSegment = (): void => {
    window.analytics.ready(initializePosthog);
};

const initializePosthog = () => {
    const apiKey = process.env.REACT_APP_POSTHOG_API_KEY || '';
    posthog.init(apiKey, {
        api_host: 'https://app.posthog.com',
        autocapture: false,
        capture_pageview: false,
        segment: window.analytics,
    });
};

export { initializeWithSegment };
