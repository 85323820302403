import styled from '@emotion/styled';
import { Table, theme } from '@trova-trip/trova-components';

export const StyledMainContainerTable = styled.div({
    margin: `3rem 0 .3rem 0`,
    width: '100%',
});

export const StyleTable = styled(Table)`
    overflow-y: hidden;
    border: 1px solid ${theme.colors.blueGray[200]};

    .blackout-th {
        display: flex !important;
        align-items: center;
        gap: 4px;
    }
`;
