import { APP_ENVIRONMENT } from '../../utils';
import {
    BaseBox,
    Text,
    IconButton,
    useBreakpoint,
} from '@trova-trip/trova-components';

// In most cases, APP_ENVIRONMENT returns the name we want. Special case for production clone
const envName =
    APP_ENVIRONMENT === 'production' && process.env.CLONED_DATABASE
        ? 'clone'
        : APP_ENVIRONMENT;

const ImpersonationBanner = (props): JSX.Element | null => {
    const hideBanner = useBreakpoint(['base', 'lg']);

    return !hideBanner ? (
        <BaseBox
            padding={3}
            backgroundColor='teal.trova'
            textAlign='center'
            display={'flex'}
            css={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
            <div />

            <Text color='neutral.white' fontWeight='bold'>
                Impersonating: {props.profile.firstName}{' '}
                {props.profile.lastName} ({props.profile.email} -{' '}
                {props.profile.id}) - {envName.toUpperCase()}
            </Text>

            <IconButton
                aria-label='close'
                icon='close'
                iconColor='neutral.white'
                iconVariant='outlined'
                variant='ghost'
                onClick={() => (window.location.pathname = '/login')}
            />
        </BaseBox>
    ) : null;
};

export default ImpersonationBanner;
