import { isEqual, isBefore, isAfter } from 'date-fns';

interface ShowDuringTimeRangeProps {
    start: Date;
    end: Date;
    children: JSX.Element;
}

const ShowDuringTimeRange = ({
    start,
    end,
    children,
}: ShowDuringTimeRangeProps): JSX.Element | null => {
    const current = new Date();

    const isCurrentlyInTimeRange =
        (isEqual(current, start) || isAfter(current, start)) &&
        (isEqual(current, end) || isBefore(current, end));

    return isCurrentlyInTimeRange ? children : null;
};

export default ShowDuringTimeRange;
