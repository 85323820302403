import { coreUtils } from '@trova-trip/trova-common';
import { constants, models } from '@trova-trip/trova-models';
import isNil from 'lodash/isNil';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import actionCreator from '../../../../../state/features/manageBooking/actions';
import { repriceInsuranceAddOns } from '../../../../../state/features/manageBooking/utils';
import { RootStore, useSelector } from '../../../../../state/hooks';
import { UpdateBookingParams } from '../../../../../state/userBookings';
import {
    BaseUser,
    BookingWithTrip,
    MANAGE_BOOKING_ADDON_STATUS,
    SavedBooking,
    Traveler,
    TravelerType,
    Trip,
    UserTripsTraveler,
} from '../types';
import { getUpdateBookingData } from '../utils/transform.utils';
import useAnalytics from './useAnalytics';
import useManageBookingNavigation from './useManageBookingNavigation';
import {
    doesBookingHaveInsurance,
    getBookingStatusFromTripStatus,
} from '../../../../common/helpers';
import { SingleSupplementAvailability } from '../../../../../state/features/manageBooking/types';
import { getSingleSupplementAvailability } from '../utils/extract.utils';

const { isBookingEditable: checkIsBookingEditable, getBookingStatus } =
    coreUtils.bookingUtils;

type BookingStatus = constants.bookings.BookingStatuses;
type BookingCustomerProfile = models.bookings.BookingCustomerProfile;
type PaymentOptionType = models.payments.PaymentOptionType;

type UpdateBookingHandlers = {
    confirmBooking: () => void;
    updateBooking: (
        payment?: UpdateBookingParams['payment'],
        paymentOptionType?: PaymentOptionType,
    ) => Promise<void>;
};

type UseManageBookingState = RootStore['manageBooking'] & {
    isBookingEditable: boolean;
    bookingHasInsurance: boolean;
    serviceAvailability: {
        singleSupplement: SingleSupplementAvailability;
    };
};

export type UseManageBookingReturnType = {
    state: UseManageBookingState;
    actions: ReturnType<typeof actionCreator> & UpdateBookingHandlers;
    globalState: {
        traveler: UserTripsTraveler;
        trip: Trip;
        user: BaseUser;
        booking: SavedBooking;
    };
};

const getTravelerType = (
    profileEmail: string | undefined,
    bookingCustomerEmail: string,
): Traveler => {
    if (profileEmail === bookingCustomerEmail) {
        return TravelerType.TRAVELER_PRIMARY;
    }
    return TravelerType.TRAVELER_SECONDARY;
};

const useManageBooking = (): UseManageBookingReturnType => {
    const dispatch = useDispatch();

    const { navigateTo } = useManageBookingNavigation();

    const { trackModifyBookingEvent } = useAnalytics();

    const {
        currentTrip,
        currentBooking,
        userProfile,
        manageBooking,
        traveler,
    } = useSelector((state) => ({
        currentTrip: state.userTrips?.current,
        currentBooking: state.userBookings?.current?.[0],
        tripDetails: state.userTripDetails?.current,
        userProfile: state.profile.current,
        manageBooking: state.manageBooking,
        traveler: state.userTripsTraveler.list
            ?.records?.[0] as UserTripsTraveler,
    }));

    const {
        tripSummary: {
            total,
            sections: { added, removed, roomSelection },
        },
        addOns: {
            accommodations: { singleSupplement },
        },
        insurance: { repricedAddOns: insuranceRepricedAddOns },
        confirmationMode,
        serviceInventory: {
            singleSupplement: { available: singleSupplementSupply },
        },
        travelersQuantity,
    } = manageBooking;

    const actions = actionCreator(dispatch);

    const { processBookingUpdate, processConfirmBooking } = actions;

    const {
        _id: bookingId,
        additionalParticipants,
        travelAmount,
        dueAmount,
        status: currentBookingStatus,
    } = currentBooking || {};

    const bookingWithTrip = {
        ...currentBooking,
        trip: currentTrip || ({} as Trip),
    } as BookingWithTrip;

    const bookingHasInsurance = doesBookingHaveInsurance(bookingWithTrip);

    const { status: tripStatus, rezdyTripId } = currentTrip || {};

    const bookingPrimaryTraveler =
        currentBooking?.customer as BookingCustomerProfile;

    const travelerType = getTravelerType(
        userProfile?.email,
        bookingPrimaryTraveler?.email,
    );

    const bookingStatus =
        getBookingStatus(currentBooking) ||
        ((!!currentTrip &&
            getBookingStatusFromTripStatus(
                currentTrip.status,
            )) as BookingStatus) ||
        currentBookingStatus;

    const isBookingEditable =
        !!tripStatus &&
        !!bookingStatus &&
        !!currentTrip &&
        !isNil(dueAmount) &&
        checkIsBookingEditable({
            travelerType,
            tripStatus,
            bookingStatus,
            rezdyTripId,
            dueAmount,
        });

    const params = useMemo(
        () =>
            getUpdateBookingData(
                manageBooking,
                (currentBooking || {}) as SavedBooking,
            ),
        [manageBooking, currentBooking],
    );

    const insuranceParams = {
        insuranceParams: bookingHasInsurance
            ? {
                  additionalParticipants: additionalParticipants ?? [],
                  travelAmount: travelAmount ?? 0,
                  status: bookingStatus,
              }
            : null,
    };

    const hasInsuranceRepricedAddOn =
        !!insuranceRepricedAddOns && insuranceRepricedAddOns?.length > 0;

    const shouldRepriceInsuranceAddOns =
        bookingHasInsurance && hasInsuranceRepricedAddOn;

    const addOnsToUpdate = shouldRepriceInsuranceAddOns
        ? repriceInsuranceAddOns(params.addOns, insuranceRepricedAddOns)
        : params.addOns;

    const singleSupplementAvailability = getSingleSupplementAvailability(
        travelersQuantity,
        singleSupplementSupply,
    );

    const isSingleSupplementPendingAddition =
        singleSupplement?.status ===
        MANAGE_BOOKING_ADDON_STATUS.PENDING_ADDITION;

    const standardParams = {
        bookingId: bookingId as string,
        singleSupplementAvailability,
        isSingleSupplementPendingAddition,
        travelersRooms: params.travelersRooms,
        addOns: addOnsToUpdate,
        onSuccessNavigateTo: navigateTo,
    };

    /** To be called on the Confirm changes step. Takes care of insurance repricing
     * and updating the booking if appropriate (when no payment is needed).
     */
    const confirmBooking = async (): Promise<void> => {
        await processConfirmBooking({
            ...standardParams,
            ...insuranceParams,
            confirmationMode,
            addOnsToRequoteInsurance: params.addOns,
            onSuccessTrackEvent: (): void =>
                trackModifyBookingEvent({
                    sections: { added, removed, roomSelection },
                    singleSupplement,
                    cartBalance: total,
                    paidBalance: total,
                }),
        });
    };

    /** To be called on the Payments page to update a booking after payment is confirmed.
     * `confirmBooking` should have already been called on the Confirm changes step so
     * insurance repricing is not repeated.
     */
    const updateBooking = async (
        payment?: UpdateBookingParams['payment'],
        paymentOptionType?: PaymentOptionType,
    ): Promise<void> => {
        await processBookingUpdate({
            ...standardParams,
            payment,
            onSuccessTrackEvent: (): void =>
                trackModifyBookingEvent({
                    sections: { added, removed, roomSelection },
                    singleSupplement,
                    cartBalance: total,
                    paidBalance: payment?.paymentAmount,
                    paymentOption: paymentOptionType,
                }),
        });
    };

    const serviceAvailability = {
        singleSupplement: singleSupplementAvailability,
    };

    const manageBookingState = {
        serviceAvailability,
        ...manageBooking,
        isBookingEditable,
        bookingHasInsurance,
    };

    const manageBookingActions = {
        ...actions,
        confirmBooking,
        updateBooking,
    };

    const globalState = {
        traveler,
        trip: currentTrip,
        user: userProfile,
        booking: currentBooking,
    } as UseManageBookingReturnType['globalState'];

    return {
        state: manageBookingState,
        actions: manageBookingActions,
        globalState,
    };
};

export default useManageBooking;
