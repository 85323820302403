import { analytics, coreUtils, logger } from '@trova-trip/trova-common';
import {
    Stack,
    Stepper,
    StepperButtonProps,
    useSteps,
    useToast,
} from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApiResponse, isErrorApiResponse } from '../../../../apis/types';
import NavigationContext from '../../../../navigation/NavigationContext';
import Product from '../../../../navigation/Product';
import { onboardHost } from '../../../../state/hosts';
import { profile as profileState } from '../../../../state/profile';
import createOnboardingStep, { StepItem } from './createOnboardingStep.util';
import { isValidBioData } from './steps/Bio';
import OnboardingWrapper from './steps/common/OnboardingWrapper/OnboardingWrapper';
import {
    AnalyticsEventNames,
    Bio,
    Host,
    Socials,
    STEP_NAME,
    User,
} from './types';
import { trackEvent } from '../../../../analytics/analytics.utils';
import {
    addHttpsProtocolToPersonalSite,
    formatSocialChannels,
    truncatePersonalSite,
} from '../../../common/helpers';
import {
    useHostOnboardingReducer,
    OnboardingStepperFields,
} from './hooks/useHostOnboardingReducer';
import { noop } from 'lodash';
import usePhyllo from './hooks/usePhyllo';
import { isHostPhylloOnboardingEnabled } from 'config/constants';
import { socialConnectChannels } from './steps/SocialMedia/SocialConnect';
import { PhylloEventHandlers } from '../../../../global.typings';
import { OnboardHost } from '@trova-trip/trova-models/dist/validationSchemas';

type OnboardingProps = {
    profile: User;
    host: Host | null;
};

const CONGRATS_STEP = 5;

const onboardingEvents = {
    0: AnalyticsEventNames.HostStartedOnboarding,
    1: AnalyticsEventNames.HostCompletedInterests,
    2: AnalyticsEventNames.HostCompletedSocial,
    3: AnalyticsEventNames.HostCompletedBioStep,
    4: AnalyticsEventNames.HostSkippedBioStep,
};

const { getPagePath } = coreUtils.hostUtils;

type TrackOnboardingEventParams = {
    profile: User;
    activeStep: number;
    interests: string[];
    socials: Socials;
    bio: Bio;
    skipSaveBioInfo?: boolean;
};

const trackOnboardingEvent = ({
    profile,
    activeStep,
    interests,
    socials,
    bio,
    skipSaveBioInfo,
}: TrackOnboardingEventParams): void => {
    try {
        let values;

        let currentStep = activeStep;

        const hostSkippedBioStep = !isValidBioData(bio) || skipSaveBioInfo;
        // track the skipped step with a mapped value if there's no bio or profile picture
        if (currentStep === 3 && hostSkippedBioStep) {
            currentStep = 4;
        }

        switch (currentStep) {
            case 1:
                values = interests;
                break;
            case 2:
                values = socials;
                break;
            case 3:
                values = bio;
                break;
            default:
                values = undefined;
                break;
        }

        analytics.setUser(profile).trackEvent({
            eventName: onboardingEvents[currentStep],
            properties: {
                hostId: profile.id,
                creationSource: profile.creationSource,
                ...(values ? { values } : {}),
            },
        });
    } catch (error) {
        logger.error(error?.message);
    }
};

const errorToast = (toast) => {
    toast({
        title: 'Error saving Host',
        description: '',
        status: 'error',
        isClosable: true,
    });
};

const Onboarding = ({ profile, host }: OnboardingProps): JSX.Element | null => {
    const history = useHistory();
    const toast = useToast();
    const { init, ...phylloData } = usePhyllo();
    const [isPhylloLoading, setIsPhylloLoading] = useState<boolean>(false);
    const { state, setState, isStepValid } = useHostOnboardingReducer();

    const interests = state[STEP_NAME.INTERESTS].value;
    const socials = state[STEP_NAME.SOCIALS].value;
    const bio = state[STEP_NAME.BIO].value;

    const phylloAccountConnectHandler: PhylloEventHandlers['accountConnected'] =
        (_, __, userId) => {
            setState(STEP_NAME.SOCIALS, {
                ...socials,
                socialEngagementUserId: userId,
            });
        };

    const initializePhyllo = useCallback(async (): Promise<void> => {
        setIsPhylloLoading(true);
        await init(socialConnectChannels, {
            accountConnected: phylloAccountConnectHandler,
        });
        setIsPhylloLoading(false);
    }, [init]);

    useEffect(() => {
        if (isHostPhylloOnboardingEnabled) {
            initializePhyllo();
        }
    }, [initializePhyllo]);

    const [isCreatingHost, setIsCreatingHost] = useState<boolean>(false);

    const { updateRecord: updateUser } = profileState.useDispatch();
    const context = useContext(NavigationContext);

    const { setShowLayout } = context;

    useEffect(() => {
        setShowLayout(false);
        return (): void => {
            setShowLayout(true);
        };
    }, [setShowLayout]);

    const steps: StepItem[] = useMemo(
        () => [
            createOnboardingStep({
                stepName: STEP_NAME.WELCOME,
                stepState: profile,
                setStepState: noop,
            }),
            createOnboardingStep({
                stepName: STEP_NAME.INTERESTS,
                stepState: interests,
                setStepState: (value) => setState(STEP_NAME.INTERESTS, value),
                errors: state[STEP_NAME.INTERESTS].errors,
            }),
            createOnboardingStep({
                stepName: STEP_NAME.SOCIALS,
                stepState: socials,
                setStepState: (value) => setState(STEP_NAME.SOCIALS, value),
                errors: state[STEP_NAME.SOCIALS].errors,
                phyllo: { ...phylloData, isLoading: isPhylloLoading },
            }),
            createOnboardingStep({
                stepName: STEP_NAME.BIO,
                stepState: bio,
                setStepState: (value) => setState(STEP_NAME.BIO, value),
                errors: state[STEP_NAME.BIO].errors,
            }),
        ],
        [state, isPhylloLoading, phylloData],
    );

    const { nextStep, setStep, activeStep, lastActivatedStep } = useSteps({
        initialStep: 0,
    });

    const handleNextStep = (skipSaveBioInfo = false): void => {
        trackOnboardingEvent({
            profile,
            activeStep,
            interests,
            socials,
            bio,
            skipSaveBioInfo,
        });

        if (activeStep === 2) {
            if (!socials.socialEngagementUserId) {
                setState(STEP_NAME.SOCIALS, {
                    ...socials,
                    channels: formatSocialChannels(
                        socials.channels,
                        truncatePersonalSite,
                    ),
                });
            }
        }
        const { name } = currentStep;
        if (!isStepValid(name as OnboardingStepperFields)) {
            return;
        }

        if (activeStep === steps.length - 1) {
            handleSaveHost(skipSaveBioInfo);
        } else {
            nextStep();
        }
    };

    const handleSaveHost = async (skipSaveBioInfo) => {
        try {
            const responses: ApiResponse<unknown>[] = [];
            setIsCreatingHost(true);

            const { channels, socialEngagementUserId, isManualConnect } =
                socials;

            const onboardingData: OnboardHost = {
                _id: host?._id,
                categoryInterests: interests,
                pagePath: getPagePath(profile as models.users.BaseUser),
                ...(isManualConnect
                    ? {
                          socialChannels: formatSocialChannels(
                              channels,
                              addHttpsProtocolToPersonalSite,
                          ),
                      }
                    : {
                          socialEngagementUserId,
                      }),
            };

            if (!skipSaveBioInfo && isValidBioData(bio)) {
                const userResponse = await updateUser(undefined, bio);
                responses.push(userResponse);
            }

            const hostResponse = await onboardHost(onboardingData);
            responses.push(hostResponse);

            const errorResponse = responses.find((res) => {
                if (isErrorApiResponse(res)) {
                    return true;
                }
                return false;
            });

            if (errorResponse) {
                setIsCreatingHost(false);
                errorToast(toast);
            } else {
                trackEvent({
                    eventName: 'Host Completed Onboarding Flow',
                });
                history.push('/');
            }
        } catch (error) {
            setIsCreatingHost(false);
            errorToast(toast);
        }
    };

    const currentStep = steps[activeStep];
    const showSkipButton = !!currentStep?.skipButton;

    const nextButtonProps: StepperButtonProps = {
        text: currentStep?.nextButton?.text,
        onClick: () => handleNextStep(),
        isDisabled: currentStep?.nextButton?.isDisabled || isCreatingHost,
        isLoading: isCreatingHost,
    };

    const skipButtonProps: StepperButtonProps | undefined = showSkipButton
        ? {
              ...currentStep.skipButton,
              onClick: () => handleNextStep(true),
              isDisabled: currentStep.skipButton?.isDisabled || isCreatingHost,
          }
        : undefined;

    return (
        <Product path='/onboarding' label={'Onboarding'} icon='group'>
            <OnboardingWrapper showMobileImage={activeStep !== CONGRATS_STEP}>
                <Stack
                    direction='column'
                    spacing={4}
                    marginTop={{ base: 12, md: 0 }}
                >
                    <Stepper
                        activeStep={activeStep}
                        lastActivatedStep={lastActivatedStep}
                        setActiveStep={setStep}
                    >
                        <Stepper.Steps>
                            {steps.map((step) => step)}
                        </Stepper.Steps>
                        <Stepper.FooterNavBar
                            nextButton={nextButtonProps}
                            skipButton={skipButtonProps}
                        />
                    </Stepper>
                </Stack>
            </OnboardingWrapper>
        </Product>
    );
};

export default Onboarding;
