import {
    BaseBox,
    BaseComboBoxOption,
    Chip,
    ComboBox,
    ComboBoxProps,
    Stack,
    Text,
} from '@trova-trip/trova-components';
import { languages } from '@trova-trip/country-data';
import { EditFieldProps, PreviewProps } from '../../utils/types';
import ChipsDisplay from '../ChipsDisplay';
import { models } from '@trova-trip/trova-models';

type Language = models.hostProfiles.HostProfileLanguage;
interface LanguagesPreviewProps extends PreviewProps<'languages'> {}

const LANGUAGE_OPTIONS: BaseComboBoxOption[] = languages.all
    .filter((language) => language?.alpha2 !== '')
    .map((language) => ({
        label: language.name,
        value: language.alpha2,
    }));

const convertLanguagesToOptions = (value: Language[]) =>
    value.map((item: Language) => {
        return {
            label: item.name as string,
            value: item.code as string,
        };
    });

const convertOptionsToLanguages = (list: BaseComboBoxOption[]): Language[] =>
    list.map((item: BaseComboBoxOption) => {
        return {
            name: item.label,
            code: item.value,
        };
    });

export const LanguagesPreview = ({
    languages = [],
}: LanguagesPreviewProps): JSX.Element => {
    return !languages.length ? (
        <Text>No languages selected.</Text>
    ) : (
        <Stack spacing={0}>
            {languages.map((language) => (
                <BaseBox padding={1} key={language.code}>
                    <Chip label={language?.name || ''} icon='chatBubble' />
                </BaseBox>
            ))}
        </Stack>
    );
};

export const LanguagesEditField = ({
    fieldData = [],
    onChange,
}: EditFieldProps<'languages'>): JSX.Element => {
    const onLanguageSelect: ComboBoxProps['onChange'] = (event) => {
        const eventData = event.metadata.value as BaseComboBoxOption[];
        const languageList = convertOptionsToLanguages(eventData);

        onChange(languageList);
    };

    const onLanguageRemove = (language: string) => {
        const newData = fieldData.filter(({ name }) => name !== language);
        onChange(newData);
    };

    const listFields = fieldData.map(({ name }) => name);
    return (
        <Stack direction='column'>
            <ChipsDisplay
                list={listFields as string[]}
                onRemove={onLanguageRemove}
            />
            <Stack width='full'>
                <ComboBox
                    isSearchable
                    isMulti
                    isClearable={false}
                    name='languages'
                    options={LANGUAGE_OPTIONS}
                    value={convertLanguagesToOptions(fieldData)}
                    onChange={onLanguageSelect}
                    maxWidth='full'
                    controlShouldRenderValue={false}
                    backspaceRemovesValue={false}
                />
            </Stack>
        </Stack>
    );
};
