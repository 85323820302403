import {
    Accordion,
    BaseBox,
    Stack,
    SidebarItem,
} from '@trova-trip/trova-components';
import { Icon } from '@trova-trip/trova-components/build/next';
import NavigationContext from 'navigation/NavigationContext';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';

const MobileRootNavigationView = (): JSX.Element => {
    const history = useHistory();
    const { navigationContent, sidebarComponent } =
        useContext(NavigationContext);

    const handleItemClick = useCallback(
        (link) => history.push(link),
        [history],
    );

    return (
        <BaseBox
            position='fixed'
            left='0'
            right='0'
            top='0'
            height='100%'
            background='neutral.white'
            paddingTop={{
                lg: 28,
                base: 20,
            }}
            paddingX={4}
            overflow='auto'
            zIndex='900'
        >
            {sidebarComponent}
            <Accordion allowMultiple>
                <Stack
                    direction='column'
                    spacing={4}
                    align='stretch'
                    width='100%'
                >
                    {navigationContent.map(({ categoryLabel, items }) => (
                        <Accordion.Item key={categoryLabel}>
                            {({ isExpanded }): JSX.Element => (
                                <AccordionItemContent
                                    title={categoryLabel}
                                    isExpanded={isExpanded}
                                    items={items}
                                    onItemClick={handleItemClick}
                                />
                            )}
                        </Accordion.Item>
                    ))}
                </Stack>
            </Accordion>
        </BaseBox>
    );
};

const AccordionItemContent = ({
    title,
    isExpanded,
    items,
    onItemClick,
}): JSX.Element => (
    <>
        <Accordion.Header
            text={title}
            fontWeight='medium'
            color='blueGray.700'
            textTransform='uppercase'
            fontSize='lg'
            rightElement={
                <Icon
                    as={isExpanded ? 'arrow-down' : 'arrow-right'}
                    size='lg'
                />
            }
        />
        <Accordion.Panel>
            <BaseBox marginTop={2}>
                {items.map(
                    ({ id, link, subtitle, title, statusText, labelItems }) => (
                        <SidebarItem
                            title={title}
                            subtitle={subtitle}
                            key={id}
                            onClick={(): void => onItemClick(link)}
                            statusText={statusText}
                            labelItems={labelItems}
                        />
                    ),
                )}
            </BaseBox>
        </Accordion.Panel>
    </>
);

export default MobileRootNavigationView;
