import { Stack, Text } from '@trova-trip/trova-components';
import { Icon } from '@trova-trip/trova-components/build/next/index';
import { getShortTimeZone } from 'applications/host/utils';

const TimePickerLabel = (): JSX.Element => (
    <Stack align='center' justify='space-between'>
        <Text as='span' color='blueGray.400' fontSize='xs'>
            Times are in:
        </Text>
        <Stack align='center' spacing={1}>
            <Text as='span' color='blueGray.500' fontSize='xs'>
                {getShortTimeZone()}
            </Text>
            <Icon as='earth' display='inline-flex' color='blueGray.500' />
        </Stack>
    </Stack>
);

export default TimePickerLabel;
