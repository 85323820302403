import { coreUtils } from '@trova-trip/trova-common';
import { models } from '@trova-trip/trova-models';

export interface User {
    accountManager: string;
    email: string;
    normalizedEmail: string;
    firstName: string;
    lastName: string;
    bio: string;
    phoneNumber: string;
    dateOfBirth: Date;
    profilePictureUrl: string;
    active: boolean;
    acceptedTerms: Date[];
    group: string;
    paypalEmail: string;
    displayName: string;
    instagramHandle: string;
    websiteUrl: string;
    concurrentTrips: {
        pending: number;
        live: number;
    };
    status: string;
    impersonate?: boolean;
}

export interface Operator {
    name: string;
    description: string;
    users: Array<string | User>;
    contact: {
        phone: string;
        emails: string[];
        address: string;
    };
    logoUrl: string;
    tripAdvisorUrl: string;
    partnerTermsUrl: string;
    cancellationPolicy: string;
    currency: string | Currency;
}

export interface Currency {
    name: string;
    code: string;
    exchangeRate: number;
}
export interface Service {
    description: string;
    user: string | User;
    operator: string | Operator;
    serviceLibrary: string;
    type:
        | 'accommodation'
        | 'info'
        | 'transfer'
        | 'airportTransfer'
        | 'meal'
        | 'workshop-space'
        | 'activity'
        | 'legacyFullDay';
}

export type AdditionalOptionalService = models.services.Service & {
    label?: string;
    quantity?: number;
};

export interface Itinerary {
    id: string;
    name: string;
    tripSummary: string;
    owner: string | User;
    status: string;
    country: string;
    destination: string;
    pricing: string;
    categories: string[];
    tags: string[];
    operator: string | Operator;
    mapUrl: string;
    cities: string[];
    logistics: {
        accommodationTypes: string;
        transportationTypes: string;
        arrivalDetails: string;
        departureDetails: string;
    };
    inclusions: string[];
    exclusions: string[];
    workshops: Array<{
        title: string;
        description: string;
    }>;
    photos: {
        hero: string;
        itineraryPhotos: Array<{
            photo: string;
            tags: string[];
        }>;
    };
    numberFree: number;
    tourLeaderIncluded: boolean;
    tourLeaderPrice: number;
    startingPrice: number;
    preparationListUrl: string;
    minimumSpots: number;
    maximumSpots: number;
    itineraryUrl: string;
    availabilityTravelDetails: Array<{
        month: string;
        travelExperience: string;
    }>;
    tripLeadTime: number;
    publishRequest: Date;
    lastPublishDate: Date;
    createdDate: Date;
    modifiedDate: Date;
    bookingsDeadline: number;
    emailCampaigns: Map<string, number>;
    transactionalEmails: Map<string, boolean>;
    currency: string | Currency;
    servicesByDay: Array<
        Array<{ service: Service | string; duration: number }>
    >;
    validityPeriods: Array<{
        startDate: Date;
        endDate: Date;
        costThresholds: Array<{
            // 0 = Sunday - 6 = Saturday
            daysOfTheWeek: Array<0 | 1 | 2 | 3 | 4 | 5 | 6>;
            numberOfTravelers: number;
            price: number;
        }>;
        blackoutStartDate: {
            isBlackout: boolean;
            // 0 = Sunday - 6 = Saturday
            daysOfTheWeek: Array<0 | 1 | 2 | 3 | 4 | 5 | 6>;
        };
        instantApprovalAllowed: boolean;
        additionalCosts: {
            singleSupplement: {
                price: number;
            };
        };
    }>;
    yearOverYearIncrease: number;
    additionalOptionalServices: {
        accommodations: {
            beforeTrip: {
                double: number;
                single: number;
            };
            afterTrip: {
                double: number;
                single: number;
            };
        };
        transfers: {
            afterTrip: number;
            beforeTrip: number;
        };
    };
    passportRequired: boolean;
}

export interface PassportInformation {
    firstName: string;
    lastName: string;
    passportRequired?: boolean;
    expirationDate: string;
    issueDate: string;
    nationality: string;
    passportNumber: string;
    dateOfBirth?: string;
    gender?: string;
}

export interface Traveler {
    booking: string;
    disclosuresAndPreferences?: {
        description: string;
        preferences?: string[] | Record<string, never>;
        roommateGenderPreferences?: string[] | Record<string, never>;
    };
    emergencyContact: {
        email: string;
        name: string;
        phone: string;
        relationship: string;
    };
    flightInformation: {
        arrivalDetails: {
            airline: string;
            date: string;
            flightNumber: string;
            time: string;
        };
        departureDetails: {
            airline: string;
            date: string;
            flightNumber: string;
            time: string;
        };
    };
    passportInformation: PassportInformation;
    endOfTripPlans: string;
    relatedTravelers: Traveler[];
    specialRequest: {
        description: string;
        roomMate: string;
    };
    travelInsurance: {
        carrier: string;
        policyNumber: string;
    };
    travelerWaiver: {
        dateSigned: string;
        minorDisclaimer: boolean;
        waiverUrl: string;
    };
    trip: string | { name: string; id: string };
    type: string;
    user: string | User;
}

export enum StatefulModel {
    TRIP,
    ITINERARY,
    TRIP_REQUEST,
    PROFILE,
}

export enum UserGroup {
    ADMIN = 'admin',
    HOST = 'host',
    TRIP_MANAGER = 'tripManager',
    ACCOUNT_MANAGER = 'accountManager',
    OPERATOR = 'operator',
    TRAVELER = 'traveler',
}

export type TierInfo = ReturnType<
    typeof coreUtils.itineraryUtils.getItineraryTierInfoForUser
>;
