import { useToast } from '@trova-trip/trova-components';
import { Button, ButtonProps } from '@trova-trip/trova-components/build/next';
import useClipboard from 'applications/common/hooks/useClipboard';
import { MouseEvent as ReactMouseEvent, useCallback } from 'react';

export interface CopyTextButtonProps extends ButtonProps {
    textToCopy?: string;
    onCopyTitle?: string;
    onCopyMessage?: string;
    icon?: ButtonProps['rightIcon'] | false;
}

const CopyTextButton = (props: CopyTextButtonProps): JSX.Element => {
    const toast = useToast();
    const { copyTextToClipboard } = useClipboard();

    const {
        children,
        textToCopy,
        onCopyTitle = 'Text copied',
        onCopyMessage = 'The text was successfully copied',
        onClick,
        icon = 'copy',
        ...restButtonProps
    } = props;

    const handleClick = useCallback(
        (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
            copyTextToClipboard(textToCopy);
            toast({
                title: onCopyTitle,
                description: onCopyMessage,
                status: 'success',
                isClosable: true,
            });
            onClick?.(event);
        },
        [textToCopy, onCopyTitle, onCopyMessage],
    );
    const rightIcon = icon ? icon : undefined;
    return (
        <Button
            rightIcon={rightIcon}
            onClick={handleClick}
            {...restButtonProps}
        >
            {children}
        </Button>
    );
};

export default CopyTextButton;
