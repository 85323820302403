import { Dispatch } from 'redux';
import { repriceInsurance } from '../../../apis/insurance';
import {
    bookGroundTransfer,
    cancelGroundTransfer,
    getEstimatedDuration,
    getQuotes,
    updateGroundTransfer,
} from '../../../apis/transfer';
import {
    BookingRepriceQuoteResponse,
    isErrorApiResponse,
    isSuccessApiResponse,
} from '../../../apis/types';
import { repriceInsuranceAddOns } from '../manageBooking/utils';
import {
    AddOn,
    ApiQuotesResponseAction,
    ApiQuotesResponseActionPayload,
    ApiRepriceInsuranceResponseAction,
    ApiRepriceInsuranceResponseActionPayload,
    ApiSaveTransferResponseAction,
    ApiSaveTransferResponseActionPayload,
    ApiTransferOperationsResponseAction,
    ApiTransferOperationResponseActionPayload,
    BookApiRequest,
    BookApiResponse,
    CancelGroundTransferApiRequest,
    FetchQuotesActionPayload,
    GetEstimatedDurationRequest,
    GetEstimatedDurationResponse,
    GroundTransfersOperation,
    GroundTransfersOperationsApiResponse,
    InsurancePolicyAddOn,
    QuotesApiResponse,
    QuotesByStage,
    RepriceInsuranceActionPayload,
    SelectQuoteActionPayload,
    SetContactActionPayload,
    SetPaymentActionPayload,
    SetTransferDetailPayload,
    TransferAction,
    UpdateGroundTransferApiRequest,
} from './types';
import {
    addSimulatedGroundTransferAddOnToBooking,
    deleteGroundTransferAddOn,
    transformTravelDetailsToQuotesRequest,
} from './utils';
import { constants } from '@trova-trip/trova-models';

const { BookingStatuses } = constants.bookings;

const actionCreator = (dispatch: Dispatch<TransferAction>) => ({
    setArrival: (payload: Omit<SetTransferDetailPayload, 'stage'>) => {
        dispatch({
            type: 'set_transfer_detail',
            payload: {
                data: payload.data,
                stage: 'arrival',
            },
        });
    },

    setDeparture: (payload: Omit<SetTransferDetailPayload, 'stage'>) => {
        dispatch({
            type: 'set_transfer_detail',
            payload: {
                data: payload.data,
                stage: 'departure',
            },
        });
    },

    setContact: (payload: SetContactActionPayload) => {
        dispatch({ type: 'set_contact', payload });
    },

    selectQuote: (payload: SelectQuoteActionPayload) => {
        dispatch({ type: 'select_quote', payload });
    },

    fetchQuotes: async (payload: FetchQuotesActionPayload) => {
        dispatch({ type: 'fetch_quotes', payload });

        const { arrival, departure } = payload;
        const preparedRequest = transformTravelDetailsToQuotesRequest(
            arrival,
            departure,
        );

        const arrivalExists = !!arrival;
        const departureExists = !!departure;

        const estimatedDurationRequest: GetEstimatedDurationRequest = {};

        if (arrivalExists) {
            estimatedDurationRequest['arrival'] = {
                origin: { placeId: arrival.pickup?.id },
                destination: { placeId: arrival.dropOff?.id },
            };
        }

        if (departureExists) {
            estimatedDurationRequest['departure'] = {
                origin: { placeId: departure.pickup?.id },
                destination: { placeId: departure.dropOff?.id },
            };
        }

        try {
            const quotesResponse: QuotesApiResponse = await getQuotes(
                preparedRequest,
            );

            if (isErrorApiResponse(quotesResponse)) {
                return dispatchApiQuotesErrorResponse(
                    dispatch,
                    arrivalExists,
                    departureExists,
                );
            }

            dispatchApiQuotesSuccessResponse(dispatch, quotesResponse);
        } catch (e) {
            dispatchApiQuotesErrorResponse(
                dispatch,
                arrivalExists,
                departureExists,
            );
        }

        try {
            const estimatedDurationResponse = await getEstimatedDuration(
                estimatedDurationRequest,
            );

            if (isErrorApiResponse(estimatedDurationResponse)) {
                return dispatchApiEstimatedDurationErrorResponse(
                    dispatch,
                    arrivalExists,
                    departureExists,
                );
            }

            dispatchApiEstimatedDurationSuccessResponse(
                dispatch,
                estimatedDurationResponse,
            );
        } catch (e) {
            dispatchApiEstimatedDurationErrorResponse(
                dispatch,
                arrivalExists,
                departureExists,
            );
        }
    },

    setPayment: (payload: SetPaymentActionPayload) => {
        dispatch({ type: 'set_payment', payload });
    },

    saveTransfer: async (payload: BookApiRequest) => {
        dispatch({ type: 'save_transfer' });

        try {
            const response: BookApiResponse = await bookGroundTransfer(payload);

            if (isErrorApiResponse(response)) {
                return dispatch(saveTransferResponse({ success: false }));
            }

            dispatch(saveTransferResponse({ success: !!response }));
        } catch (e) {
            dispatch(saveTransferResponse({ success: false }));
        }
    },

    repriceInsurance: async (payload: RepriceInsuranceActionPayload) => {
        dispatch({ type: 'reprice_insurance' });

        const { booking, operation } = payload;

        let repriceAddOns: AddOn[] = [];

        if (operation === 'cancel') {
            const addOns = booking?.addOns ?? [];
            const addOnsForInsuranceRepricing = deleteGroundTransferAddOn(
                addOns,
                payload.journeyId,
            );
            repriceAddOns = repriceInsuranceAddOns(
                addOns,
                addOnsForInsuranceRepricing,
            );
        }

        if (operation === 'book') {
            repriceAddOns = addSimulatedGroundTransferAddOnToBooking(
                booking,
                payload.total,
            );
        }

        try {
            const response: BookingRepriceQuoteResponse =
                await repriceInsurance(
                    booking?._id as string,
                    booking?.travelAmount ?? 0,
                    booking?.additionalParticipants ?? [],
                    repriceAddOns,
                    booking?.status ?? BookingStatuses.PENDING,
                    'traveler',
                );

            if (response.error) {
                return dispatchApiRepriceInsuranceErrorResponse(dispatch);
            }

            dispatchApiRepriceInsuranceSuccessResponse(dispatch, response);
        } catch (e) {
            dispatchApiRepriceInsuranceErrorResponse(dispatch);
        }
    },

    cancelTransfer: async (payload: CancelGroundTransferApiRequest) => {
        dispatchTransferOperation(dispatch, payload, 'cancel');
    },

    updateTransfer: async (payload: UpdateGroundTransferApiRequest) => {
        dispatchTransferOperation(dispatch, payload, 'update');
    },

    resetState: () => {
        dispatch({ type: 'reset_state' });
    },
});

const saveTransferResponse = (
    payload: ApiSaveTransferResponseActionPayload,
): ApiSaveTransferResponseAction => {
    return { type: 'api_save_transfer_response', payload };
};

const apiQuotesResponse = (
    payload: ApiQuotesResponseActionPayload,
): ApiQuotesResponseAction => {
    return { type: 'api_quotes_response', payload };
};

const saveRepriceInsuranceResponse = (
    payload: ApiRepriceInsuranceResponseActionPayload,
): ApiRepriceInsuranceResponseAction => {
    return { type: 'api_reprice_insurance_response', payload };
};

const saveTransferOperationResponse = (
    payload: ApiTransferOperationResponseActionPayload,
): ApiTransferOperationsResponseAction => {
    return { type: 'api_transfer_operations_response', payload };
};

const dispatchApiQuotesSuccessResponse = (
    dispatch: Dispatch<TransferAction>,
    response: QuotesApiResponse,
) => {
    if (!isSuccessApiResponse(response)) {
        return;
    }

    const {
        data: { arrivalQuotes, departureQuotes },
    } = response;

    const quotesByStages: QuotesByStage[] = [];

    if (arrivalQuotes) {
        quotesByStages.push({
            stage: 'arrival',
            quotes: arrivalQuotes,
        });
    }

    if (departureQuotes) {
        quotesByStages.push({
            stage: 'departure',
            quotes: departureQuotes,
        });
    }

    dispatch(apiQuotesResponse({ quotesByStages }));
};

const dispatchApiQuotesErrorResponse = (
    dispatch: Dispatch<TransferAction>,
    includesArrival?: boolean,
    includesDeparture?: boolean,
) => {
    const quotesByStages: QuotesByStage[] = [];

    if (includesArrival) {
        quotesByStages.push({
            stage: 'arrival',
            quotes: [],
        });
    }

    if (includesDeparture) {
        quotesByStages.push({
            stage: 'departure',
            quotes: [],
        });
    }

    dispatch(apiQuotesResponse({ quotesByStages, isError: true }));
};

const dispatchApiEstimatedDurationSuccessResponse = (
    dispatch: Dispatch<TransferAction>,
    response: GetEstimatedDurationResponse,
) => {
    if (!isSuccessApiResponse(response)) {
        return;
    }

    const {
        data: { arrival, departure },
    } = response;

    if (arrival) {
        dispatch({
            type: 'set_estimated_duration',
            payload: {
                stage: 'arrival',
                estimatedDuration: arrival.estimatedDuration,
            },
        });
    }

    if (departure) {
        dispatch({
            type: 'set_estimated_duration',
            payload: {
                stage: 'departure',
                estimatedDuration: departure.estimatedDuration,
            },
        });
    }
};

const dispatchApiEstimatedDurationErrorResponse = (
    dispatch: Dispatch<TransferAction>,
    includesArrival?: boolean,
    includesDeparture?: boolean,
) => {
    if (includesArrival) {
        dispatch({
            type: 'set_estimated_duration',
            payload: { stage: 'arrival', estimatedDuration: undefined },
        });
    }

    if (includesDeparture) {
        dispatch({
            type: 'set_estimated_duration',
            payload: { stage: 'departure', estimatedDuration: undefined },
        });
    }
};

const dispatchApiRepriceInsuranceSuccessResponse = (
    dispatch: Dispatch<TransferAction>,
    response: BookingRepriceQuoteResponse,
) => {
    if (!response.data) return;

    const {
        data: { insuranceChange, repricedAddOns },
    } = response;

    dispatch(
        saveRepriceInsuranceResponse({
            insurance: {
                adjustment: insuranceChange,
                repricedAddOn: repricedAddOns?.[0] as InsurancePolicyAddOn,
            },
        }),
    );
};

const dispatchApiRepriceInsuranceErrorResponse = (
    dispatch: Dispatch<TransferAction>,
) => {
    dispatch(
        saveRepriceInsuranceResponse({
            insurance: {
                adjustment: 0,
                repricedAddOn: undefined,
            },
            errorMessage:
                'We encountered an issue while connecting to our insurance provider.',
        }),
    );
};

const dispatchTransferOperation = async (
    dispatch: Dispatch<TransferAction>,
    payload: CancelGroundTransferApiRequest | UpdateGroundTransferApiRequest,
    operation: GroundTransfersOperation,
): Promise<ApiTransferOperationsResponseAction | void> => {
    const operationTypeMap: Record<
        Exclude<GroundTransfersOperation, 'book'>,
        Extract<TransferAction['type'], 'cancel_transfer' | 'update_transfer'>
    > = {
        cancel: 'cancel_transfer',
        update: 'update_transfer',
    };

    dispatch({ type: operationTypeMap[operation] });

    const operationError = operation === 'cancel' ? 'cancelling' : 'updating';

    const errorMessage = `We encountered an issue while ${operationError} your ride. Please try again later.`;

    try {
        let response = {} as GroundTransfersOperationsApiResponse;

        if (operation === 'cancel') {
            response = await cancelGroundTransfer(
                payload as CancelGroundTransferApiRequest,
            );
        }

        if (operation === 'update') {
            response = await updateGroundTransfer(
                payload as UpdateGroundTransferApiRequest,
            );
        }

        if (isErrorApiResponse(response)) {
            return dispatch(
                saveTransferOperationResponse({
                    operation,
                    success: false,
                    errorMessage,
                }),
            );
        }

        dispatch(
            saveTransferOperationResponse({ success: !!response, operation }),
        );
    } catch (e) {
        dispatch(
            saveTransferOperationResponse({
                success: false,
                errorMessage,
                operation,
            }),
        );
    }
};

export default actionCreator;
