import { constants, models } from '@trova-trip/trova-models';
import some from 'lodash/some';
import { AdditionalOptionalService } from '../../../../../applications/types';
import { getGroupedAccommodation } from '../../../../common/helpers';
import { HostOptionalsLabels } from '../../../components/PricingTable/utils';
import { getEditableStatusForTrip } from '../../utils/OperatorEditableFields';

type Service = models.services.Service;
type Accommodation = models.services.Accommodation;
type ServicesByDay = models.services.ServicesByDay;
type Trip = models.trips.Trip;
type Inventory = models.trips.Inventory;

const PreTripServices = constants.trips.PRE_TRIP_SERVICES;
const ServiceType = constants.services.ServiceType;

const getOptionalOnTripServices = (servicesByDay: ServicesByDay): Service[] => {
    const optionalOnTripServices = servicesByDay
        .flat()
        .map(({ service }) => service as Service)
        .filter((service) => {
            return (
                service.type === ServiceType.ACTIVITY &&
                !service.includedActivity
            );
        });

    return optionalOnTripServices;
};

const getAdditionalOptionalServices = (
    additionalOptionalServices: Service[],
): { accommodationsServices: Service[]; transferServices: Service[] } => {
    const accommodationsServices: Accommodation[] = [];
    const transferServices: AdditionalOptionalService[] = [];
    additionalOptionalServices.forEach((additionalOptionalService) => {
        const isAirportTransfer =
            additionalOptionalService.type === ServiceType.AIRPORT_TRANSFER;

        if (isAirportTransfer) {
            transferServices.push({
                ...additionalOptionalService,
                label:
                    additionalOptionalService.name ===
                    PreTripServices.TRANSFER_NAME
                        ? 'Before Trip'
                        : 'After Trip',
            });
        } else if (
            additionalOptionalService.type === ServiceType.ACCOMMODATION &&
            additionalOptionalService.name &&
            HostOptionalsLabels[additionalOptionalService.name]
        ) {
            accommodationsServices.push({
                ...additionalOptionalService,
                occupancy: additionalOptionalService.occupancy,
            });
        }
    });

    return {
        accommodationsServices,
        transferServices,
    };
};

const getTwinPrePostServices = (
    servicesToUpdate: Service[],
    currentServices: Service[],
) => {
    const {
        preTripAccommodationsServices: { double: preDouble },
        postTripAccommodationServices: { double: postDouble },
    } = getGroupedAccommodation(servicesToUpdate);
    const {
        preTripAccommodationsServices: { twin: preTwin },
        postTripAccommodationServices: { twin: postTwin },
    } = getGroupedAccommodation(currentServices);

    const newPreTwin = preDouble
        ? [{ _id: preTwin._id, price: preDouble.price }]
        : [];
    const newPostTwin = postDouble
        ? [{ _id: postTwin._id, price: postDouble.price }]
        : [];

    return [...newPreTwin, ...newPostTwin];
};

const getTripAdditionalCosts = (trip: Trip) => {
    const { additionalCosts, status } = trip;

    const singleSupplement = additionalCosts?.singleSupplement;
    const isTripEditable = getEditableStatusForTrip(status).isEditable;

    if (!isTripEditable && !singleSupplement?.quantity) {
        return additionalCosts;
    }

    return {
        ...additionalCosts,
        singleSupplement: {
            ...singleSupplement,
            quantity:
                singleSupplement?.quantity ||
                constants.services.SINGLE_SUPPLEMENT_DEFAULT_AVAILABLE_QUANTITY,
        },
    };
};

const hasZeroTravelersInValidityPeriods = (formData): boolean =>
    some(formData.packages, (pkg) => {
        return some(pkg.validityPeriods, (period) => {
            return some(period.costThresholds, (threshold) => {
                return parseInt(threshold.numberOfTravelers) === 0;
            });
        });
    });

const canSingleSupplementQuantityBeUpdated = (
    trip: Trip,
    inventory: Inventory,
): boolean => {
    const updatedTotal = trip.additionalCosts?.singleSupplement.quantity || 0;

    return updatedTotal >= inventory.singleSupplement.booked;
};

export {
    canSingleSupplementQuantityBeUpdated,
    getAdditionalOptionalServices,
    getOptionalOnTripServices,
    getTripAdditionalCosts,
    getTwinPrePostServices,
    hasZeroTravelersInValidityPeriods,
};
