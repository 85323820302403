import { BigDecimal, coreUtils } from '@trova-trip/trova-common';
import { constants } from '@trova-trip/trova-models';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import minBy from 'lodash/minBy';
import omit from 'lodash/omit';
import { areAllPropsFilled } from '../../../applications/common/helpers/common';
import {
    AddOn,
    BaseUser,
    BookRequestQuote,
    BookerInfo,
    ContactDetail,
    DetailOptionalProperties,
    GroundTransferAddOn,
    InsuranceStatus,
    JourneyStage,
    Quote,
    QuoteApiRequest,
    QuoteStatus,
    QuotesApiRequest,
    RequiredTransferDetail,
    RequiredTravelDetail,
    SavedBooking,
    TabStatus,
    TransferDetail,
    TransferDetailStatus,
    TransferError,
    TransferStatus,
    TransferViewModel,
    TravelDetail,
    User,
} from './types';

const { generateShortId } = coreUtils.idGeneratorUtils;

const { AddOnTypes } = constants.bookings;
const { JourneyType } = constants.groundTransfers;

/**
 * Represents the maximum API calls with error that could be handled.
 */
const MAX_API_ERROR_COUNT = 3;

const isTravelDetailComplete = (
    data: TravelDetail,
): data is RequiredTravelDetail => {
    const requiredData = omit<TravelDetail, DetailOptionalProperties>(
        data,
        'luggage',
        'estimatedDuration',
    );
    return areAllPropsFilled(requiredData);
};

const isTransferDetailComplete = (
    data: TransferDetail,
): data is RequiredTransferDetail => {
    const requiredData = omit<TransferDetail, DetailOptionalProperties>(
        data,
        'luggage',
        'estimatedDuration',
    );
    return areAllPropsFilled(requiredData);
};

/**
 * Returns `true` if the transfer detail metadata status
 * is `complete` OR `missing-quote-selection`.
 *
 * @param dataOrStatus
 * @returns boolean
 */
const isTransferDetailCompleteOrWithoutQuote = (
    dataOrStatus: TransferDetail | TransferDetailStatus,
): boolean => {
    const status =
        typeof dataOrStatus === 'string'
            ? dataOrStatus
            : dataOrStatus.metadata.status;

    return (
        isTransferDetailStatusComplete(status) ||
        isTransferDetailStatusMissingQuoteSelection(status)
    );
};

/**
 * Returns `true` if the transfer detail metadata status
 * is `complete` OR `missing-travel-details`
 *
 * @param dataOrStatus
 * @returns boolean
 */
const isTransferDetailCompleteOrWithoutTravelDetails = (
    dataOrStatus: TransferDetail | TransferDetailStatus,
): boolean => {
    const status =
        typeof dataOrStatus === 'string'
            ? dataOrStatus
            : dataOrStatus.metadata.status;

    return (
        isTransferDetailStatusMissingTravelDetail(status) ||
        isTransferDetailStatusComplete(status)
    );
};

const isContactComplete = (
    data: ContactDetail,
): data is Required<ContactDetail> => {
    if (!data.termsAndConditions) {
        return false;
    }
    return areAllPropsFilled(data);
};

/**
 * Transforms a travel detail object by removing fields related to quotes,
 * resulting in a simplified travel detail object without quote information
 * and without transfer detail metadata.
 *
 * @param data - The original transfer detail.
 *
 * @returns TravelDetail - The transformed travel detail object.
 */
const transformTransferDetailToTravelDetail = (
    data: TransferDetail,
): TravelDetail => {
    const travelDetail = omit(data, [
        'quotes',
        'quotesMetadata',
        'selectedQuote',
        'metadata',
        'estimatedDuration',
    ]);
    return travelDetail;
};

/**
 * Transforms a single travel detail object, which can represent
 * either a departure or arrival, into the format expected by the
 * server for requesting quotes.
 *
 * @param data - The travel detail data to be transformed.
 *
 * @returns QuoteApiRequest - The transformed request object for obtaining quotes.
 */
const transformTravelDetailToQuoteRequest = (
    data: RequiredTravelDetail,
): QuoteApiRequest => {
    return {
        carryOnLuggage: data.luggage?.hand || 0,
        checkedLuggage: data.luggage?.checked || 0,
        originPlaceId: data.pickup.id,
        destinationPlaceId: data.dropOff.id,
        passengers: data.passengers,
        pickupDateTime: formatQuotePickupDateTime(data.pickupDateTime),
        flightNumber: data.flightNumber,
    };
};

/**
 * Transforms arrival and departure travel detail objects into the format
 * expected by the server for requesting quotes. You can provide an arrival
 * detail and an optional departure detail.
 *
 * @param arrival - The travel detail data for the arrival.
 * @param departure - The optional travel detail data for the departure.
 *
 * @returns QuotesApiRequest - The transformed request object for obtaining quotes,
 * including both arrival and departure details if provided.
 */
const transformTravelDetailsToQuotesRequest = (
    arrival?: RequiredTravelDetail,
    departure?: RequiredTravelDetail,
): QuotesApiRequest => ({
    arrival: arrival ? transformTravelDetailToQuoteRequest(arrival) : undefined,
    departure: departure
        ? transformTravelDetailToQuoteRequest(departure)
        : undefined,
});

/**
 * Returns a transfer detail status depending on the given data.
 *
 * Check the documentation of the `TransferDetailStatus` type to know more
 * about each one.
 *
 * @param transferDetails
 * @returns TravelDetailStatus
 */
const getTransferDetailStatus = (
    transferDetails: TransferDetail,
): TransferDetailStatus => {
    const travelDetails =
        transformTransferDetailToTravelDetail(transferDetails);

    const isComplete =
        !isEmpty(transferDetails) && isTravelDetailComplete(travelDetails);

    if (!isComplete) {
        return 'missing-travel-details';
    }

    const hasSelectedQuote = !!transferDetails.selectedQuote;
    return hasSelectedQuote ? 'complete' : 'missing-quote-selection';
};

const isTransferDetailStatusMissingTravelDetail = (
    status: TransferDetailStatus,
): status is 'missing-travel-details' => status === 'missing-travel-details';

const isTransferDetailStatusMissingQuoteSelection = (
    status: TransferDetailStatus,
): status is 'missing-quote-selection' => status === 'missing-quote-selection';

const isTransferDetailStatusComplete = (
    status: TransferDetailStatus,
): status is 'complete' => status === 'complete';

const areQuotesEmpty = (status: QuoteStatus): status is 'empty' =>
    status === 'empty';

const areQuotesPopulated = (status: QuoteStatus): status is 'populated' =>
    status === 'populated';

const areQuotesLoading = (status: QuoteStatus): status is 'loading' =>
    status === 'loading';

const areQuotesStale = (status: QuoteStatus): status is 'stale' =>
    status === 'stale';

const doQuotesNeedRetry = (status: QuoteStatus): status is 'need-retry' =>
    status === 'need-retry';

const areQuotesInError = (status: QuoteStatus): status is 'error' =>
    status === 'error';

const isTabComplete = (status: TabStatus): status is 'complete' =>
    status === 'complete';

const isArrival = (stage: JourneyStage): stage is 'arrival' =>
    stage === 'arrival';

const isDeparture = (stage: JourneyStage): stage is 'departure' =>
    stage === 'departure';

const isTransferPaymentError = (error?: TransferError): boolean =>
    error?.type === 'payment';

const isTransferBookingError = (error?: TransferError): boolean =>
    error?.type === 'booking';

const isInsuranceRepriceError = (error?: TransferError): boolean =>
    error?.type === 'insurance-repricing';

const isTransferCancelError = (error?: TransferError): boolean =>
    error?.type === 'cancel';

const isTransferUpdateError = (error?: TransferError): boolean =>
    error?.type === 'update';

const didTransferDetailFetchQuotesSuccessfully = (
    data: TransferDetail,
): boolean => {
    return data.quotesMetadata.apiSuccessCount > 0;
};

/**
 * Returns `true` if there was a successfully quotes response but empty
 * on a transfer detail.
 *
 * @param data
 * @returns boolean
 */
const doesTransferDetailHaveEmptyQuoteResponse = (
    data: TransferDetail,
): boolean => {
    const didFetchQuotesSuccessfully =
        didTransferDetailFetchQuotesSuccessfully(data);
    const quotesAreEmpty = areQuotesEmpty(data.quotesMetadata.status);
    return didFetchQuotesSuccessfully && quotesAreEmpty;
};
const isInsuranceStatusEmpty = (status: InsuranceStatus): status is 'empty' =>
    status === 'empty';

const isInsuranceStatusRepriced = (
    status: InsuranceStatus,
): status is 'repriced' => status === 'repriced';

const isInsuranceStatusStale = (status: InsuranceStatus): status is 'stale' =>
    status === 'stale';

const isTransferErrorStatus = (status: TransferStatus): status is 'error' =>
    status === 'error';

const isTransferRepricingInsuranceStatus = (
    status: TransferStatus,
): status is 'repricing-insurance' => status === 'repricing-insurance';

const isTransferSavingStatus = (status: TransferStatus): status is 'saving' =>
    status === 'saving';

const isTransferCompleteStatus = (
    status: TransferStatus,
): status is 'complete' => status === 'complete';

const isTransferCancellingStatus = (
    status: TransferStatus,
): status is 'cancelling' => status === 'cancelling';

const isTransferCancelledStatus = (
    status: TransferStatus,
): status is 'cancelled' => status === 'cancelled';

const isTransferUpdatingStatus = (
    status: TransferStatus,
): status is 'updating' => status === 'updating';

const isTransferUpdatedStatus = (status: TransferStatus): status is 'updated' =>
    status === 'updated';

interface ShouldFetchQuotesReturn {
    /**
     * `true` if arrival and/or departure quotes should be fetched, `false` otherwise
     */
    shouldFetch: boolean;

    /**
     * `true` if only arrival quotes should be fetched, `false` otherwise
     */
    shouldFetchArrival: boolean;

    /**
     * `true` if only departure quotes should be fetched, `false` otherwise
     */
    shouldFetchDeparture: boolean;
}

/**
 * Determines whether quotes should be fetched based on the given transfer detail
 * quotes status and transfer detail information.
 *
 * @param data - The transfer detail.
 * @returns boolean
 */
const transferDetailShouldFetchQuotes = (data: TransferDetail): boolean => {
    const { status } = data.quotesMetadata;

    const isDataComplete = isTransferDetailCompleteOrWithoutQuote(data);

    const quotesAreStale = areQuotesStale(status);
    const quotesNeedRetry = doQuotesNeedRetry(status);
    const quotesAreEmpty = areQuotesEmpty(status);

    if (isDataComplete && quotesNeedRetry) {
        return true;
    }

    if (doesTransferDetailHaveEmptyQuoteResponse(data)) {
        return false;
    }

    return isDataComplete && (quotesAreStale || quotesAreEmpty);
};

/**
 * Determines whether quotes should be fetched based on their statuses and travel detail information
 *
 * @param arrival - The arrival transfer detail.
 * @param departure - The departure transfer detail.
 * @returns ShouldFetchQuotesReturn
 */
const shouldFetchQuotes = (
    arrival: TransferDetail,
    departure: TransferDetail,
): ShouldFetchQuotesReturn => {
    const shouldFetchArrival = transferDetailShouldFetchQuotes(arrival);
    const shouldFetchDeparture = transferDetailShouldFetchQuotes(departure);

    return {
        shouldFetch: shouldFetchArrival || shouldFetchDeparture,
        shouldFetchArrival,
        shouldFetchDeparture,
    };
};

/**
 * Get the quote with the lowest price from an array of quotes.
 *
 * @param quotes
 * @returns The quote with the lowest price.
 */

const getLowestPriceQuote = (quotes: Quote[]): Quote => {
    return minBy(quotes, 'price')!;
};

/**
 * Formats the pickup date and time to a string with the format 'yyyy-MM-ddTHH:mm:ss'
 * which is expected by the server in order to compare this date to the one from the
 * provider.
 *
 * @param pickupDateTime
 *
 * @return the formatted pickup date and time, or an empty string if `pickupDateTime` is `undefined`.
 */
const formatQuotePickupDateTime = (pickupDateTime: Date | undefined) =>
    pickupDateTime
        ? formatInTimeZone(pickupDateTime, 'UTC', "yyyy-MM-dd'T'HH:mm:ss")
        : '';

/**
 * Builds an array of book request quotes to be sent to the server, based on the given
 * arrival and departure selected quotes.
 *
 * @param arrival
 * @param departure
 * @return An array of book request quotes or an empty array if no quotes are selected.
 */
const getTransferBookRequestQuotes = (
    arrival: TransferDetail,
    departure: TransferDetail,
): BookRequestQuote[] => {
    const quotes: BookRequestQuote[] = [];

    const { selectedQuote: arrivalSelectedQuote } = arrival;

    const { selectedQuote: departureSelectedQuote } = departure;

    if (arrivalSelectedQuote) {
        quotes.push({
            quoteId: arrivalSelectedQuote.externalId,
            type: JourneyType.ARRIVAL,
            quoteRequest: transformTravelDetailToQuoteRequest(
                arrival as RequiredTravelDetail,
            ),
        });
    }

    if (departureSelectedQuote) {
        quotes.push({
            quoteId: departureSelectedQuote.externalId,
            type: JourneyType.DEPARTURE,
            quoteRequest: transformTravelDetailToQuoteRequest(
                departure as RequiredTravelDetail,
            ),
        });
    }

    return quotes;
};

/**
 * Calculates the total cost depending on the selected quotes of
 * the given state.
 *
 * @param state
 * @returns number
 */
const calculateTotal = (state: TransferViewModel): number => {
    const {
        arrival,
        departure,
        payment: { insurance },
    } = state;

    const arrivalQuotePrice = arrival.selectedQuote?.price || 0;
    const departureQuotePrice = departure.selectedQuote?.price || 0;
    const insuranceAdjustment = insurance?.adjustment || 0;

    const total = BigDecimal.of(arrivalQuotePrice)
        .plus(departureQuotePrice)
        .plus(insuranceAdjustment);

    return total.toNumber();
};

/**
 * Adds a simulated ground transfer add-on to the booking.
 *
 * @param booking
 * @param transferAmount
 * @returns An array of add-ons, including the simulated ground transfer add-on.
 */
const addSimulatedGroundTransferAddOnToBooking = (
    booking: SavedBooking | undefined,
    transferAmount: number,
): AddOn[] => {
    if (!booking) {
        return [];
    }

    const simulatedGroundTransferAddOn: AddOn = {
        addOnId: generateShortId(),
        user: (booking?.customer as User)._id,
        type: AddOnTypes.GROUND_TRANSFER,
        deleted: false,
        quantity: 1,
        unitPriceWithFee: transferAmount,
    };

    return [...(booking?.addOns ?? []), simulatedGroundTransferAddOn];
};

/**
 * Checks if the transfer detail has changes comparing the current transfer detail.
 *
 * @param currentTransferDetail
 * @param newTransferDetail
 * @returns True if the transfer detail has changes, false otherwise.
 */
const transferDetailHasChanges = (
    currentTransferDetail: TransferDetail,
    newTransferDetail: TransferDetail,
): boolean => {
    return !isEqual(
        transformTransferDetailToTravelDetail(currentTransferDetail),
        transformTransferDetailToTravelDetail(newTransferDetail),
    );
};
/**
 * Returns `true` if the current quote have changed comparing the new quote.
 *
 * @param currentQuote
 * @param newQuote
 * @returns boolean
 */
const quoteHasChanged = (currentQuote?: Quote, newQuote?: Quote): boolean => {
    return currentQuote?.externalId !== newQuote?.externalId;
};

/**
 * Marks the given ground transfer add-on as deleted, if it exists.
 *
 * @param addOns
 * @param groundTransferId
 * @return The updated list of add-ons
 */
const deleteGroundTransferAddOn = (
    addOns: SavedBooking['addOns'],
    groundTransferId: string,
): SavedBooking['addOns'] => {
    const transferAddOnToDelete = addOns?.find(
        (addOn) =>
            (addOn as GroundTransferAddOn).groundTransfer ===
                groundTransferId && !addOn.deleted,
    );

    if (!transferAddOnToDelete) return addOns;

    const addOnsWithoutChanges = addOns?.filter(
        (addOn) => addOn.addOnId !== transferAddOnToDelete.addOnId,
    );

    return [
        ...(addOnsWithoutChanges ?? []),
        { ...transferAddOnToDelete, deleted: true },
    ];
};

/**
 * Get the booker info from the contact or user.
 *
 * @param contact
 * @param user
 * @returns The booker info.
 */
const getBookerInfo = (contact: ContactDetail, user: BaseUser): BookerInfo => {
    return {
        firstName: contact.firstName || user.firstName,
        lastName: contact.lastName || user.lastName,
        email: contact.email || user.email,
        // This empty string fallback is only to avoid typescript issue, if the user doesn`t have the phone, it should be filled on the contact as is a required field.
        phone: contact.phone || user.phoneNumber || '',
    };
};

export {
    MAX_API_ERROR_COUNT,
    addSimulatedGroundTransferAddOnToBooking,
    areQuotesEmpty,
    areQuotesInError,
    areQuotesLoading,
    areQuotesPopulated,
    areQuotesStale,
    calculateTotal,
    deleteGroundTransferAddOn,
    doQuotesNeedRetry,
    doesTransferDetailHaveEmptyQuoteResponse,
    getBookerInfo,
    getLowestPriceQuote,
    getTransferBookRequestQuotes,
    getTransferDetailStatus,
    isArrival,
    isContactComplete,
    isDeparture,
    isInsuranceRepriceError,
    isInsuranceStatusEmpty,
    isInsuranceStatusRepriced,
    isInsuranceStatusStale,
    isTabComplete,
    isTransferBookingError,
    isTransferCancelError,
    isTransferCancelledStatus,
    isTransferCancellingStatus,
    isTransferCompleteStatus,
    isTransferDetailComplete,
    isTransferDetailCompleteOrWithoutQuote,
    isTransferDetailCompleteOrWithoutTravelDetails,
    isTransferDetailStatusComplete,
    isTransferDetailStatusMissingQuoteSelection,
    isTransferDetailStatusMissingTravelDetail,
    isTransferErrorStatus,
    isTransferPaymentError,
    isTransferRepricingInsuranceStatus,
    isTransferSavingStatus,
    isTransferUpdateError,
    isTransferUpdatingStatus,
    isTransferUpdatedStatus,
    isTravelDetailComplete,
    quoteHasChanged,
    shouldFetchQuotes,
    transferDetailHasChanges,
    transformTransferDetailToTravelDetail,
    transformTravelDetailToQuoteRequest,
    transformTravelDetailsToQuotesRequest,
};
