import { useState } from 'react';
import { constants, models } from '@trova-trip/trova-models';
import { BaseBox, Stack, Text } from '@trova-trip/trova-components';
import { Button, Icon } from '@trova-trip/trova-components/build/next';
import ApproveTechnicalItineraryModal from '././ApproveTechnicalItineraryModal';

type Trip = models.trips.Trip;

interface SubmitTechnicalItineraryProps {
    trip: Trip;
}

interface SubmitTechnicalItineraryModalProps {
    tripStatus: constants.trips.TRIP_STATUS;
}

const SubmitTechnicalItineraryModal = ({
    tripStatus,
}: SubmitTechnicalItineraryModalProps): JSX.Element | null => {
    const [
        showApproveTechnicalItineraryModal,
        setShowApproveTechnicalItineraryModal,
    ] = useState(false);
    const TripStatuses = constants.trips.TRIP_STATUS;
    const isTripConfirmed = tripStatus === TripStatuses.CONFIRMED;
    const isTripClosed = tripStatus === TripStatuses.CLOSED;

    const showModal = isTripConfirmed || isTripClosed;

    const toggleApproveTechnicalItineraryModal = (): void =>
        setShowApproveTechnicalItineraryModal(
            (previousValue) => !previousValue,
        );

    return showModal ? (
        <BaseBox alignSelf='center'>
            <Button
                variant='secondary'
                onClick={toggleApproveTechnicalItineraryModal}
            >
                Submit Technical Itinerary
            </Button>
            <ApproveTechnicalItineraryModal
                isOpen={showApproveTechnicalItineraryModal}
                setIsOpen={setShowApproveTechnicalItineraryModal}
            />
        </BaseBox>
    ) : (
        <></>
    );
};

export const SubmitTechnicalItinerary = ({
    trip,
}: SubmitTechnicalItineraryProps): JSX.Element => {
    const { status, operatorTechnicalItineraryApproved } = trip;

    const isTechnicalItineraryApproved = operatorTechnicalItineraryApproved;

    return isTechnicalItineraryApproved ? (
        <Stack direction='row' align='center' alignSelf='center'>
            <Icon as='check' display='inline-flex' />
            <Text>Technical Itinerary Submitted</Text>
        </Stack>
    ) : (
        <SubmitTechnicalItineraryModal tripStatus={status} />
    );
};
