import { PricingCalculator } from '@trova-trip/trova-common';
import {
    ComponentWidth,
    Currency,
    Stack,
    Text,
} from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import React, { useState } from 'react';

type CostPerThreshold = PricingCalculator.CostPerThreshold;

interface YourPriceInputProps {
    threshold: CostPerThreshold;
    suggestedCost: number;
    isDisabled: boolean;
    index: number;
    currencyCode: string | undefined;
    hasItineraryInventoryItem?: boolean;
    onChange: (
        event: React.SyntheticEvent<Element, Event>,
        name: string | undefined,
        value: number | undefined,
    ) => void;
}

const YourPriceInput = (props: YourPriceInputProps) => {
    const {
        threshold,
        isDisabled,
        index,
        currencyCode,
        onChange,
        suggestedCost,
        hasItineraryInventoryItem,
    } = props;
    const { pricePerTraveler } = threshold;

    const [value, setValue] = useState(pricePerTraveler);

    const onChangeHandler = (event, name, fieldValue) => {
        setValue(fieldValue);
        onChange(event, name, fieldValue);
    };

    const onBlurHandler = (event) => {
        if (!Number(event.target.value)) setValue(0);
    };

    const onResetHandler = () => {
        setValue(suggestedCost);
    };

    const hasCurrencyValueChanged = value !== suggestedCost;

    const priceDifference = {
        value: Math.abs(value - suggestedCost),
        descriptor: value > suggestedCost ? 'over' : 'under',
    };

    const priceDifferenceMessage = `$${priceDifference.value} ${priceDifference.descriptor} suggested price`;

    return (
        <Stack direction='column'>
            <Stack spacing={2} align='center'>
                <Currency
                    name={`[${index}].price`}
                    size={ComponentWidth.ExtraSmall}
                    value={value}
                    currencyPosition='right'
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                    currencyType={currencyCode}
                    disabled={isDisabled}
                    decimalPlaces={0}
                />
                {!hasItineraryInventoryItem && hasCurrencyValueChanged ? (
                    <Button
                        variant='tertiary'
                        color='red.coral'
                        size='sm'
                        onClick={onResetHandler}
                    >
                        Reset
                    </Button>
                ) : null}
            </Stack>
            {!hasItineraryInventoryItem && hasCurrencyValueChanged ? (
                <Text size='xs' color='blueGray.500' letterSpacing='wide'>
                    {priceDifferenceMessage}
                </Text>
            ) : null}
        </Stack>
    );
};

export default YourPriceInput;
