import { models } from '@trova-trip/trova-models';
import FetchState from './utils/FetchState';

export type HostItineraryRecommendations =
    models.itineraryRecommendations.ItineraryRecommendationOutput;

export type UserHostsRecommendations = {
    firstTime?: boolean;
    fetching?: boolean;
    list: HostItineraryRecommendations[];
};

export type ItineraryRecommendationHostSelector = {
    userHostsRecommendations: { list: HostItineraryRecommendations[] };
};

const itineraryRecommendations = FetchState.create(
    `api/admin/itinerary-recommendations`,
);
const itineraryRecommendationsMetadata = FetchState.create(
    `api/metadata/itinerary-recommendations`,
);
const userHostsRecommendations = FetchState.create(
    `api/me/itinerary-recommendations`,
);

const itineraryRecommendationsMetadataReducer =
    itineraryRecommendationsMetadata.getReducer();
const itineraryRecommendationsReducer = itineraryRecommendations.getReducer();
const userHostsRecommendationsReducer = userHostsRecommendations.getReducer();

export type ItineraryRecommendationStoreSelector = {
    operators: {
        current: models.itineraryRecommendations.ItineraryRecommendation;
    };
};

export {
    itineraryRecommendations,
    itineraryRecommendationsMetadata,
    itineraryRecommendationsReducer,
    itineraryRecommendationsMetadataReducer,
    userHostsRecommendations,
    userHostsRecommendationsReducer,
};
