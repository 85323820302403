import {
    BaseBox,
    Checkbox,
    Stack,
    Text,
    UseFormSaverReturn,
} from '@trova-trip/trova-components';
import { FieldNames } from '../../EditEmailModal';

type FormHeaderProps = {
    formSaver: UseFormSaverReturn;
    scheduledDateLabel?: string;
};
const FormHeader = ({
    formSaver,
    scheduledDateLabel,
}: FormHeaderProps): JSX.Element => {
    return (
        <Stack align='flex-end' justify='space-between' marginBottom={6}>
            <BaseBox>
                <Text
                    color='blueGray.650'
                    fontWeight='medium'
                    lineHeight='normal'
                >
                    Scheduled for
                </Text>
                {scheduledDateLabel && (
                    <Text color='blueGray.600'>{scheduledDateLabel}</Text>
                )}
            </BaseBox>
            <Checkbox
                name='active'
                detail='Active'
                value={formSaver.formValues.get[FieldNames.ACTIVE] as boolean}
                onChange={(_, __, value): void =>
                    formSaver.formValues.set.nested(FieldNames.ACTIVE, !value)
                }
            />
        </Stack>
    );
};

export default FormHeader;
