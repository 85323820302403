import { FormSaver, UseFormSaverReturn } from '@trova-trip/trova-components';
import React from 'react';
import FormBody from './FormBody/FormBody';
import FormFooter from './FormFooter';
import FormHeader from './FormHeader';

type FormProps = {
    children: React.ReactNode;
    formSaver: UseFormSaverReturn;
};

const Form = ({ children, formSaver }: FormProps): JSX.Element => {
    return (
        <FormSaver
            onSubmit={formSaver.handleFormSubmit}
            name='trip-details-launch-date-form'
        >
            {children}
        </FormSaver>
    );
};

Form.Body = FormBody;
Form.Header = FormHeader;
Form.Footer = FormFooter;

export default Form;
