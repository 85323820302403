import { Heading, BaseBox, Grid } from '@trova-trip/trova-components';
import { Link } from '@trova-trip/trova-components/build/next';

const Documents = ({ agreementUrl }): JSX.Element => {
    return (
        <Grid>
            <Grid.Item columnSpan={{ xs: 12, sm: 10 }}>
                <BaseBox marginY={4}>
                    <Heading as={'h4'}>Documents</Heading>
                </BaseBox>
                <Link
                    href={agreementUrl}
                    size={{ base: 'sm', md: 'base' }}
                    rightIcon='offsite'
                    color='neutral.50'
                    isExternal
                >
                    Service Agreement
                </Link>
            </Grid.Item>
        </Grid>
    );
};

export default Documents;
