export const END_OF_TRIP_PLANS = {
    RETURNING_HOME: 'Returning home',
    CONTINUING_TRAVELS: 'Continuing travels',
    UNDECIDED: 'Undecided',
};

export const PREFERENCES = {
    earlyBird: 'EARLY_BIRD',
    nightOwl: 'NIGHT_OWL',
    adrenalineJunkie: 'ADENALINE_JUNKIE',
    leisureEnthusiast: 'LEISURE_ENTHUSIAST',
    planner: 'PLANNER',
    wanderer: 'WANDERER',
    lifeOfTheParty: 'LIFE_OF_THE_PARTY',
    loverOfAloneTime: 'LOVER_OF_ALONE_TIME',
};
