import { BaseBox, Heading, Stack, Text } from '@trova-trip/trova-components';
import travelerImg from '../../../../../../../assets/img/lottie/traveler.gif';
import { AnimatedImage, NextButton } from '../shared';
import BaseContent from './BaseContent';

const NextStep = ({ title, description }): JSX.Element => (
    <BaseBox>
        <Heading
            as='h6'
            fontSize={{ base: 'base', md: 'lg' }}
            textTransform='uppercase'
            color='teal.trova'
        >
            {title}
        </Heading>
        <Text>{description}</Text>
    </BaseBox>
);

const CongratsContent = (): JSX.Element => {
    return (
        <BaseContent
            title='Congratulations! You’re Almost Ready to Host This Trip.'
            leftContent={
                <AnimatedImage
                    src={travelerImg}
                    alt='Animated Traveler Image'
                    maxWidth={{
                        base: '13.625rem',
                        md: '21.25rem',
                        xl: '33.75rem',
                    }}
                />
            }
            rightContent={
                <Stack direction='column' spacing={6}>
                    <Text>
                        Now that you’ve signed your agreement we’ve created your
                        trip page where your audience will book your trip! Only
                        a few steps left.
                    </Text>
                    <Heading as='h5' size={{ base: 'lg', md: 'xl' }}>
                        Next Steps:
                    </Heading>
                    <Stack direction='column' spacing={5}>
                        <NextStep
                            title='1 Set your launch date & time'
                            description='This is when your trip will be bookable by travelers. Your trip launch email will also send to your audience list to notify them your trip is live.'
                        />
                        <NextStep
                            title='2 Promote your trip page pre-launch'
                            description='Copy your trip page link from the promote tab and share it with your audience to collect additional emails before launching your trip. The more emails you collect the higher chance your trip will confirm.'
                        />
                        <NextStep
                            title='3 Announce your launch date'
                            description='Announce your launch date on Instagram stories and other social platforms to get your audience excited and prepared to book your trip.'
                        />
                    </Stack>
                </Stack>
            }
            footerContent={<NextButton />}
        />
    );
};

export default CongratsContent;
