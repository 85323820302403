import {
    appConstants,
    businessConstants,
    coreUtils,
} from '@trova-trip/trova-common';
import {
    BaseBox,
    Heading,
    List,
    Stack,
    Text,
} from '@trova-trip/trova-components';
import {
    Button,
    ButtonProps,
    IconProps,
} from '@trova-trip/trova-components/build/next';
import { models } from '@trova-trip/trova-models';
import { useEffect, useState } from 'react';
import { useTrackingEvent } from '../../../../../../analytics/hooks';
import PageCard from '../../../../../common/components/PageCard';
import useTripRootPathByUserGroup from '../../../../../common/hooks/trips/useTripRootPathByUserGroup';
import { useNavigate } from '../../../../../common/hooks/useNavigation';
import { APP_ENVIRONMENT } from '../../../../../utils';
import { useManageBooking, useManageBookingNavigation } from '../../hooks';
import CancelBookingDialog from '../CancelBookingDialog';

type PaymentsAppUrlParams = coreUtils.bookingUtils.PaymentsAppURLParams;

const { getPaymentsAppURL, canPayWithAffirm } = coreUtils.bookingUtils;
const { getDaysUntilTripStarts, getFormattedTripRefundableDate } =
    coreUtils.tripUtils;
const PaymentOptionType = models.payments.PaymentOptionType;

interface PayBalanceOption {
    option: 'affirm' | 'custom';
    title: string;
    amount: {
        number: string;
        unit?: string;
    };
    features: Array<{
        icon: IconProps['as'];
        description: string;
    }>;
    buttonCopy: string;
}

const payBalance: PayBalanceOption[] = [
    {
        option: 'affirm',
        title: 'Pay with Installments',
        amount: {
            number: 'Flexible Plans',
        },
        features: [
            {
                icon: 'watchLater',
                description: 'Buy now and pay later',
            },
            {
                icon: 'attachMoney',
                description: 'Flexible pricing plans',
            },
        ],
        buttonCopy: 'Pay with Affirm',
    },
    {
        option: 'custom',
        title: 'Custom Payment Amount',
        amount: {
            number: `$${businessConstants.DEFAULT_MINIMUM_PAYMENT_AMOUNT}`,
            unit: 'min.',
        },
        features: [
            {
                icon: 'trovaTwoBeds',
                description: 'Choose your payment amount',
            },
            {
                icon: 'attachMoney',
                description: 'No interest payments',
            },
        ],
        buttonCopy: 'Pay Custom Amount',
    },
];

const iconProps: Partial<IconProps> = {
    display: 'inline-flex',
    size: 'sm',
    color: 'blueGray.650',
};

const PayBalanceOptions = () => {
    const {
        globalState: { booking, trip, user },
    } = useManageBooking();

    const { trackUserEvent } = useTrackingEvent();

    const payBalanceOptionsToShow = payBalance.filter((item) => {
        if (item.option === 'affirm') {
            const isUserFromUS = user.countryCode === 'US';
            return canPayWithAffirm(booking, booking.dueAmount) && isUserFromUS;
        }
        return true;
    });

    return (
        <Stack direction={{ base: 'column', md: 'row' }} spacing={5}>
            {payBalanceOptionsToShow.map((item) => {
                const { option, title, amount, features, buttonCopy } = item;

                const paymentOption =
                    option === 'affirm'
                        ? PaymentOptionType.AFFIRM
                        : PaymentOptionType.CUSTOM;

                const paymentUrlParams: PaymentsAppUrlParams = {
                    tripId: trip.id || '',
                    orderId: booking?.orderId || '',
                    env: APP_ENVIRONMENT as appConstants.Environment,
                    config: {
                        paymentOptions: {
                            selectedByDefault: paymentOption,
                        },
                    },
                };

                const paymentLink = getPaymentsAppURL(paymentUrlParams);

                const buttonProps: Partial<ButtonProps> = {
                    as: 'a',
                    isFullWidth: true,
                    linkProps: {
                        href: paymentLink,
                        target: '_blank',
                        rel: 'noreferrer',
                    },
                    onClick: () => {
                        trackUserEvent({
                            eventName:
                                'Traveler Selected Payment Option in Booking Cancel Flow',
                            properties: {
                                paymentOption,
                            },
                        });
                    },
                };

                return (
                    <Stack
                        key={title}
                        direction='column'
                        align='center'
                        paddingY={10}
                        paddingX={10}
                        border='1px'
                        borderColor='blueGray.300'
                        borderRadius='lg'
                        width='20rem'
                        height='21.25rem'
                    >
                        <Text fontWeight='medium'>{title}</Text>
                        <Stack paddingY={4} spacing={1} align='center'>
                            <Text
                                fontSize='4xl'
                                fontWeight='bold'
                                fontFamily='poppins'
                                color='teal.trova'
                                paddingBottom={1}
                            >
                                {amount.number}
                            </Text>
                            {amount.unit ? (
                                <Text alignSelf='flex-end'>{amount.unit}</Text>
                            ) : null}
                        </Stack>
                        <BaseBox paddingY={6}>
                            <List as='dl' spacing={2}>
                                {features.map(({ icon, description }) => (
                                    <List.Item
                                        key={description}
                                        bulletIcon={{
                                            as: icon,
                                            ...iconProps,
                                        }}
                                    >
                                        <Text fontSize='sm'>{description}</Text>
                                    </List.Item>
                                ))}
                            </List>
                        </BaseBox>
                        <Button {...buttonProps}>{buttonCopy}</Button>
                    </Stack>
                );
            })}
        </Stack>
    );
};

const PayBalancePage = (): JSX.Element => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const {
        globalState: { user, trip, booking },
    } = useManageBooking();

    const { trackUserEvent } = useTrackingEvent();

    const tripRootPath = useTripRootPathByUserGroup({
        group: user?.group,
        tripId: trip.id,
    });

    const { getManageBookingTabPath } = useManageBookingNavigation();

    const manageBooking = getManageBookingTabPath('root');
    const navigateToManageBooking = useNavigate(manageBooking, {
        preserveCurrentPath: false,
    });

    useEffect(() => {
        if (booking?.dueAmount <= 0) {
            return navigateToManageBooking();
        }
    }, [booking]);

    const daysTillTripStarts = getDaysUntilTripStarts(trip);
    const isTripWithin90Days = daysTillTripStarts && daysTillTripStarts <= 90;
    const tripRefundableDate = getFormattedTripRefundableDate(trip);

    const title = isTripWithin90Days
        ? 'This trip is within 90 days of departure'
        : `This trip is refundable until ${tripRefundableDate}`;

    const description = isTripWithin90Days ? (
        <Text paddingBottom={6} display='flex' alignItems='center' as='span'>
            As per our{' '}
            <Button
                as='a'
                variant='tertiary'
                marginX={1}
                onClick={() => {
                    trackUserEvent({
                        eventName: 'Traveler Viewed Cancelation Policy',
                    });
                }}
                linkProps={{
                    href: 'https://trovatrip.com/terms#cancellation',
                    target: '_blank',
                    rel: 'noreferrer',
                }}
            >
                cancelation policy
            </Button>{' '}
            this booking is now non-refundable.
        </Text>
    ) : (
        'Do you want to keep this booking and decide later?'
    );

    const handleContinueToCancelClick = () => {
        setIsDialogOpen(true);
        trackUserEvent({
            eventName: 'Traveler Continued to Cancel',
        });
    };

    return (
        <>
            <PageCard
                navigateBackTo={{
                    description: 'Back to trip',
                    path: tripRootPath,
                }}
                showMobileTopBar
            >
                <Stack
                    justify='center'
                    align='center'
                    width='full'
                    direction='column'
                    spacing={6}
                    flex={1}
                >
                    <Heading as='h2'>Are you sure?</Heading>
                    <Text>Let’s make this booking easier to pay for!</Text>
                    <PayBalanceOptions />
                </Stack>
                <Stack
                    direction='column'
                    align='center'
                    justify='center'
                    width='full'
                    flex={1}
                    paddingTop={6}
                >
                    <Text fontSize='xl' fontWeight='medium' textAlign='center'>
                        {title}
                    </Text>
                    <Text paddingBottom={6} textAlign='center'>
                        {description}
                    </Text>
                    <Button
                        variant='tertiary'
                        onClick={handleContinueToCancelClick}
                    >
                        Continue to Cancel
                    </Button>
                </Stack>
            </PageCard>
            <CancelBookingDialog
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
            />
        </>
    );
};

export default PayBalancePage;
