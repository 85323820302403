import { Grid } from '@material-ui/core';
import {
    ComponentWidth,
    Form,
    Loader,
    LoaderSize,
    NumberInput,
    Stack,
    Grid as TCGrid,
    Text,
    TextareaSize,
    Toggle,
    theme,
    useFormSaver,
    useToast,
} from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import { constants, models } from '@trova-trip/trova-models';
import isEqual from 'lodash/isEqual';
import { useState } from 'react';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import TrovaHeroFileInput from '../../../../components/TrovaFileInput/TrovaHeroFileInput';
import { ItineraryStatuses, modelTypes } from '../../../../config/constants';
import { useSelector } from '../../../../state/hooks/useSelector';
import { userItinerary } from '../../../../state/userItinerary';
import { userTrips } from '../../../../state/userTrips';
import { getDataFormatted } from '../../../../util/ModelDataHelper';
import { DropdownOptionKeys } from '../../../../util/form/dropdown';
import getQueryParam from '../../../../util/getQueryParam';
import { transformGooglePlacesOptionToAddressLocation } from '../../../common/helpers';
import AddressLocationField from '../../components/AddressLocationField';
import SearchableSingleDropDown from '../../components/Dropdown/SearchableSingleDropDown';
import ItineraryPackagesInputs from '../../components/ItineraryPackages/ItineraryPackagesInputs';
import { getItineraryPackagesSchema } from '../../components/ItineraryPackages/utils';
import {
    isTripFieldEditable,
    useOperatorCanUpdateTrip,
} from '../utils/OperatorEditableFields';
import { insuranceToggleWrapperStyles } from './ReviewDetails.styles';
import { StyledInput, StyledTextarea } from './ReviewDetailsForm.components';
import { createFormattedTrip } from './utils';

type CallbacksProps = {
    successCallback: (data: any) => void;
    errorCallback?: (error: string | string[]) => void;
};

type Itinerary = models.itineraries.Itinerary;
type TripRequest = models.tripRequest.TripRequest;

export interface ReviewDetailsFormProps {
    data: {} | Itinerary | TripRequest;
    saveModelData: (data: any, callbacks?: CallbacksProps) => void;
    updateModelData: (
        id: string,
        data: any,
        callbacks?: CallbacksProps,
    ) => void;
    redirectToNextTab: (data: any, flag?: boolean) => void;
    reloadNavigation: () => void;
}

const reviewDetailsFormSchema = yup.object().shape({
    name: yup.string().trim().required('A name is required'),
    country: yup.string().required('A country selection is required'),
    destination: yup.string().required('A destination is required'),
    tripSummary: yup
        .string()
        .trim()
        .required('A summary is required')
        .min(100, 'The trip summary must be at least 100 characters'),
    packages: yup.object().shape(getItineraryPackagesSchema()),
});

const ReviewDetailsForm = (props: ReviewDetailsFormProps): JSX.Element => {
    const [showSpinner, setShowSpinner] = useState<Boolean>(false);
    const [redirect, setRedirect] = useState<Boolean>(false);
    const [packagesUpdated, setPackagesUpdated] = useState<Boolean>(false);
    const {
        data,
        reloadNavigation,
        redirectToNextTab,
        updateModelData,
        saveModelData,
    } = props;
    const history = useHistory();

    const {
        profile: { current: profile },
        userTrips: { current: trip },
    } = useSelector((state) => state);

    // Success/Error Toast Logic
    const toast = useToast();

    const canUpdateTrip = useOperatorCanUpdateTrip();

    const showErrorMessage = (error) => {
        toast({
            title: 'Something went wrong',
            description: error,
            status: 'error',
            isClosable: true,
        });
        console.error('@Trova: Error submitting review details form: ', error);
    };

    const showSuccessMessage = (title, message) => {
        toast({
            title,
            description: message,
            status: 'success',
            isClosable: true,
        });
    };

    // Redux stuff
    const { getRecord: getItinerary } = userItinerary.useDispatch();
    const { createRecord: createTrip } = userTrips.useDispatch();

    // Data
    const model = getQueryParam(history, 'model');
    const defaultData = getDataFormatted(data, model);

    // Maybe change to showPackages
    const isTripOrRequest =
        model === modelTypes.TRIP || model === modelTypes.TRIP_REQUEST;

    const isPackageDisabled = isTripOrRequest;
    const isOperatorTechnicalItineraryApproved =
        trip?.operatorTechnicalItineraryApproved;
    const validationSchema = isPackageDisabled
        ? reviewDetailsFormSchema.omit(['packages'])
        : reviewDetailsFormSchema;

    // Submit save/update logic
    const saveTripRequest = (initData: any, formData: any) => {
        setShowSpinner(true);

        const {
            itinerary: { _id: itineraryId },
        } = initData;

        getItinerary(itineraryId, undefined, {
            successCallback: (response) => {
                const formattedTrip = createFormattedTrip(
                    response,
                    initData,
                    formData,
                );
                createTrip(formattedTrip, {
                    successCallback: (tripData) => {
                        showSuccessMessage(
                            'Trip request saved',
                            'The trip request was successfully saved',
                        );
                        reloadNavigation();
                        if (redirect) {
                            redirectToNextTab(tripData, true);
                        } else {
                            history.replace(
                                `/app/operator/trips/${tripData._id || tripData.id}/review-details?model=trip`,
                            );
                        }
                        setShowSpinner(false);
                    },
                    errorCallback: (error) => {
                        showErrorMessage(error);
                        setShowSpinner(false);
                    },
                });
            },
        });
    };

    const updateTrip = (initData: any, formData: any) => {
        const navigate = (data) => redirect && redirectToNextTab(data);

        if (!canUpdateTrip) {
            return navigate(initData);
        }

        const { id: tripId } = initData;

        updateModelData(tripId, formData, {
            successCallback: (responseData) => {
                showSuccessMessage(
                    'Trip saved',
                    'The trip was successfully saved',
                );
                navigate(responseData);
            },
            errorCallback: showErrorMessage,
        });
    };

    const saveOrUpdate = async (initData, formData, model) => {
        const { id: owner } = profile || {};

        switch (model) {
            case 'tripRequest': {
                saveTripRequest(initData, formData);
                break;
            }
            case 'trip': {
                updateTrip(initData, formData);
                break;
            }
            default: {
                const {
                    location: { pathname },
                } = history;
                if (pathname.includes('/new')) {
                    saveModelData(
                        {
                            ...formData,
                            status: ItineraryStatuses.INCOMPLETE,
                            owner,
                        },
                        {
                            successCallback: (response) => {
                                showSuccessMessage(
                                    'Itinerary saved',
                                    'The itinerary was successfully saved',
                                );
                                reloadNavigation();
                                if (redirect) {
                                    redirectToNextTab(response);
                                } else {
                                    history.push(
                                        `/app/operator/itineraries/${response.id}/review-details`,
                                    );
                                }
                            },
                            errorCallback: showErrorMessage,
                        },
                    );
                } else {
                    const { id } = initData;
                    updateModelData(id, formData, {
                        // TODO: should this take a data param?
                        successCallback: (responseData) => {
                            showSuccessMessage(
                                'Itinerary saved',
                                'The itinerary was successfully saved',
                            );
                            if (redirect) {
                                redirectToNextTab(responseData);
                            }
                        },
                        errorCallback: showErrorMessage,
                    });
                }
                break;
            }
        }
    };

    // Form Saver
    const formSaver = useFormSaver({
        onSubmit: async (formData) => {
            const [arrivalLocation, departureLocation] = await Promise.all([
                transformGooglePlacesOptionToAddressLocation(formData.arrivalLocation),
                transformGooglePlacesOptionToAddressLocation(formData.departureLocation)
            ]);

            const submitFormData = {
                ...formData,
                arrivalLocation,
                departureLocation,
            };

            saveOrUpdate(data, submitFormData, model);
        },
        validationSchema: {
            schema: validationSchema,
            onFormValidated: (validationResult) => {
                const errors = validationResult.errors || {};

                if (validationResult.errors !== null) {
                    showErrorMessage(errors[Object.keys(errors)[0]]);
                }
            },
        },
        initialValues: defaultData,
    });

    const {
        formValues: { get: formSaverGet, set: formSaverSet },
    } = formSaver;

    // Form Field Handling/Logic
    const shouldDisableField = (name) => {
        if (model === modelTypes.TRIP) {
            const status = formSaverGet.nested('status');
            return !isTripFieldEditable(status, name);
        }
        return false;
    };

    const handleChange = (event) => {
        const { name, value, type } = event.target;

        formSaverSet.nested(name, type === 'number' ? Number(value) : value);
    };

    const handleDropdownChangeEvent = (event, name, value) => {
        formSaverSet.nested(name, value);
    };

    const handleNumberWrapperChange = (event, name, value) => {
        formSaverSet.nested(name, value);
    };

    const handleLocationChange = (values, locationName: string) => {
        if (!isEqual(values[locationName], formSaverGet[locationName])) {
            formSaverSet.nested(locationName, values[locationName]);
        }
    };

    const shouldUseMaxLength =
        formSaverGet.name.length <=
        constants.itinerary.ITINERARY_NAME_MAX_LENGTH;

    /**
     * `Form` had to be added in order to be able to handle the `GeoLocationComboBoxField` inside `AddressLocationField`.
     * This field doesn't expose `value` and `onChange` as it is prepared to work with `Form` directly. In order to keep
     * the legacy fields working as well, we kept the `useFormSaver` hook to control them and use that to perform the actual
     * submit. Once this form is migrated to the newest components, only `Form` should be kept.
     */
    return (
        <Form
            name='review-details-form'
            onSubmit={() => {
                formSaver.handleFormSubmit(undefined);
            }}
            onChange={(values) => {
                handleLocationChange(values, 'arrivalLocation');
                handleLocationChange(values, 'departureLocation');
            }}
            initialValues={{
                arrivalLocation: formSaverGet.arrivalLocation,
                departureLocation: formSaverGet.departureLocation,
            }}
        >
            <Grid container spacing={24}>
                {showSpinner ? (
                    <Loader
                        size={LoaderSize.Md}
                        color={theme.colors.teal.trova}
                        fullscreen={true}
                    />
                ) : null}
                <Grid item xs={12} sm={5}>
                    <StyledInput
                        name='name'
                        placeholder='Name of itinerary'
                        label='Name of itinerary *'
                        detail={
                            shouldUseMaxLength
                                ? `${constants.itinerary.ITINERARY_NAME_MAX_LENGTH} characters length maximum`
                                : ''
                        }
                        value={formSaverGet.name as string}
                        onChange={(event): void => {
                            formSaverSet.name(
                                (event.target as HTMLInputElement).value,
                            );
                        }}
                        size={ComponentWidth.Flexible}
                        disabled={shouldDisableField('name')}
                        maxlength={
                            shouldUseMaxLength
                                ? constants.itinerary.ITINERARY_NAME_MAX_LENGTH
                                : undefined
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                    <SearchableSingleDropDown
                        name='destination'
                        label='Destination *'
                        value={formSaverGet.destination as string}
                        onChange={handleDropdownChangeEvent}
                        dropDownOptionKey={DropdownOptionKeys.Destinations}
                        disabled={shouldDisableField('destination')}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <SearchableSingleDropDown
                        name='country'
                        label='Country *'
                        value={formSaverGet.country as string}
                        onChange={handleDropdownChangeEvent}
                        dropDownOptionKey={DropdownOptionKeys.Countries}
                        disabled={shouldDisableField('country')}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                    <StyledTextarea
                        name='tripSummary'
                        placeholder='Trip Summary'
                        label='Trip Summary *'
                        detail='100 characters length minimum'
                        value={formSaverGet.tripSummary as string}
                        onChange={handleChange}
                        size={TextareaSize.Medium}
                        disabled={shouldDisableField('tripSummary')}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <NumberInput
                        name='lengthInDays'
                        label='Length in days'
                        value={(formSaverGet.lengthInDays as number) || 0}
                        onChange={handleNumberWrapperChange}
                        detail='Note: subtracting days will remove days from the end of the itinerary and all services will be lost.'
                        disabled={shouldDisableField('lengthInDays')}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <SearchableSingleDropDown
                        name='activityLevel'
                        label='Activity Level'
                        value={formSaverGet.activityLevel.toString()}
                        onChange={handleDropdownChangeEvent}
                        dropDownOptionKey={DropdownOptionKeys.ActivityLevels}
                        disabled={shouldDisableField('activityLevel')}
                    />
                </Grid>

                <Grid item xs={12} sm={5}>
                    <AddressLocationField
                        name='arrivalLocation'
                        label='Arrival Location'
                        placeholder='Recommended Arrival Airport'
                        isDisabled={
                            isOperatorTechnicalItineraryApproved ||
                            shouldDisableField('arrivalLocation')
                        }
                        types={['airport']}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <AddressLocationField
                        name='departureLocation'
                        label='Departure Location'
                        placeholder='Recommended Departure Airport'
                        isDisabled={
                            isOperatorTechnicalItineraryApproved ||
                            shouldDisableField('departureLocation')
                        }
                        types={['airport']}
                    />
                </Grid>

                {isTripOrRequest ? (
                    <Grid item xs={12} sm={5}>
                        <StyledInput
                            name='operatorCode'
                            placeholder='Operator Code'
                            label='Operator Code'
                            value={formSaverGet.operatorCode as string}
                            onChange={handleChange}
                            size={ComponentWidth.Flexible}
                            disabled={shouldDisableField('operatorCode')}
                        />
                    </Grid>
                ) : null}

                <Grid item xs={12}>
                    <TCGrid marginY={4}>
                        <TCGrid.Item columnSpan={{ base: 12, md: 5 }}>
                            <Stack
                                direction='row-reverse'
                                justify='space-between'
                                spacing={0}
                                paddingBottom={2}
                                wrap='nowrap'
                                marginRight={1}
                                className={insuranceToggleWrapperStyles}
                            >
                                <Toggle
                                    name='travelerInsuranceRequired'
                                    onChange={handleDropdownChangeEvent}
                                    value={
                                        !!formSaverGet.travelerInsuranceRequired ||
                                        false
                                    }
                                    detail='Require Travelers to have Insurance'
                                    disabled={shouldDisableField(
                                        'travelerInsuranceRequired',
                                    )}
                                />
                            </Stack>
                            <Text size='sm' color='gray.500'>
                                Turn this option on if this itinerary requires
                                travelers to have insurance.
                            </Text>
                        </TCGrid.Item>
                    </TCGrid>
                </Grid>

                <Grid item xs={12} md={5}>
                    <TrovaHeroFileInput
                        name='photos'
                        value={formSaverGet.nested('photos')}
                        onChange={handleChange}
                        label='Upload photos'
                        maxFiles='10'
                        isDisabled={shouldDisableField('photos')}
                    />
                </Grid>
                {!isTripOrRequest ? (
                    <Grid item xs={12} md={10} sm={10}>
                        <ItineraryPackagesInputs
                            updateValue={(updatedPackage) => {
                                formSaverSet.nested('packages', updatedPackage);
                                // formSaver is having trouble detecting changes to packages
                                setPackagesUpdated(true);
                            }}
                            selectedPackages={formSaverGet.nested('packages')}
                        />
                    </Grid>
                ) : null}
                <Grid item xs={12} sm='auto' md={12}>
                    <Stack direction={{ base: 'column', sm: 'row' }}>
                        <Button
                            variant='primary'
                            type='submit'
                            isFullWidth={{ base: true, sm: false }}
                            isDisabled={
                                !packagesUpdated && !formSaver.isFormDirty
                            }
                        >
                            Save Details
                        </Button>
                        <Button
                            variant='secondary'
                            isFullWidth={{ base: true, sm: false }}
                            type='submit'
                            onClick={(event) => {
                                setRedirect(true);
                            }}
                        >
                            Next
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </Form>
    );
};

export default ReviewDetailsForm;
