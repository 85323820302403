import * as yup from 'yup';
import { validationSchemas } from '@trova-trip/trova-models';

const { requiredMessage } = validationSchemas.validationMessages;

const validationSchema: yup.SchemaOf<{ description: string }> = yup.object({
    description: yup.string().trim().required(requiredMessage('Description')),
});

export { validationSchema };
