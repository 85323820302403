import { Heading, Stack, Text } from '@trova-trip/trova-components';
import { Dialog, Icon } from '@trova-trip/trova-components/build/next';
import GoBackButton from './GoBackButton';

interface ConfirmDialogContentProps {
    title: string;
    description: string;
}

interface ConfirmDialogProps {
    defaultContent: ConfirmDialogContentProps;
    successContent: ConfirmDialogContentProps;
    open: boolean;
    isSuccess: boolean;
    isLoading: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

const ConfirmDialog = (props: ConfirmDialogProps): JSX.Element => {
    const {
        open,
        isSuccess,
        isLoading,
        defaultContent,
        successContent,
        onConfirm,
        onCancel,
    } = props;

    const content = isSuccess ? (
        <>
            <Dialog.Body>
                <Stack
                    direction='column'
                    align='center'
                    justify='center'
                    spacing={4}
                >
                    <Stack align='center' spacing={3} marginTop={4}>
                        <Icon
                            display='inline-flex'
                            as='success'
                            variant='filled'
                            color='seaGreen.trova'
                            size='xl'
                        />
                        <Heading as='h4'>{successContent.title}</Heading>
                    </Stack>
                    <Text textAlign='center'>{successContent.description}</Text>
                </Stack>
            </Dialog.Body>
            <Dialog.Footer>
                <Stack width='100%' justify='flex-end'>
                    <GoBackButton arrowDirection='right' />
                </Stack>
            </Dialog.Footer>
        </>
    ) : (
        <>
            <Dialog.Header title={defaultContent.title} />
            <Dialog.Body>{defaultContent.description}</Dialog.Body>
            <Dialog.Footer
                actions={[
                    {
                        children: 'Cancel',
                        variant: 'secondary',
                        isDisabled: isLoading,
                        onClick: onCancel,
                    },
                    {
                        children: 'Confirm',
                        variant: 'primary',
                        isDisabled: isLoading,
                        onClick: onConfirm,
                    },
                ]}
            />
        </>
    );

    return (
        <Dialog isOpen={open} showCloseButton={false} size='lg'>
            {content}
        </Dialog>
    );
};

export default ConfirmDialog;
