import { Stack, Text } from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import { publishTestEmail } from '../../../../../../../../state/trips';
import Snackbar from '../../../../../../../../components/Snackbar/Snackbar';
import { useState } from 'react';
import { logError } from 'applications/common/helpers';
import { HostInfo } from 'applications/host/hooks/usePreviewEmail';

export type FormFooterProps = {
    tripId?: string;
    emailHookId?: string;
    hostInfo: HostInfo;
};

const FormFooter = ({
    tripId,
    emailHookId,
    hostInfo,
}: FormFooterProps): JSX.Element => {
    const [snackBar, setSnackBar] = useState({
        message: '',
        color: 'info',
        show: false,
    });

    const { email, firstName, lastName, displayName } = hostInfo;

    const sendTestEmail = async (): Promise<void> => {
        try {
            if (!tripId || !emailHookId || !firstName || !lastName) {
                return;
            }

            const hostParameters = {
                firstName: displayName || firstName,
                lastName: lastName,
            };
            await publishTestEmail(tripId, emailHookId, hostParameters);
            setSnackBar({
                message: 'Email sent',
                color: 'success',
                show: true,
            });
        } catch (error) {
            logError(error);
            setSnackBar({
                message:
                    'There was an error sending the email. Please try again.',
                color: 'danger',
                show: true,
            });
        }
    };

    return (
        <Stack
            spacing={0}
            align='center'
            direction='column'
            paddingTop={{ base: 5, md: 4 }}
            paddingBottom={{ base: 5, md: 4 }}
            borderTop={{
                md: '1px solid var(--tc-colors-blueGray-300)',
            }}
        >
            <Button onClick={sendTestEmail} variant='tertiary'>
                Send Test Email
            </Button>
            <Snackbar
                place='tr'
                color={snackBar.color}
                message={snackBar.message}
                open={snackBar.show}
                autoHideDuration={2000}
                onClose={(): void => {
                    setSnackBar({
                        message: '',
                        color: 'info',
                        show: false,
                    });
                }}
            ></Snackbar>
            <Text color='blueGray.500'>Sends to {email}</Text>
        </Stack>
    );
};

export default FormFooter;
