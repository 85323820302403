import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../rootReducer';
import { ROOT_ACTION_TYPES } from './types';

// Middleware for Redux
const middleware = [thunk];

// Enhancer for Redux DevTools Extension
const composeEnhancers =
    (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) ||
    compose;

// Create and configure the Redux store
const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware)),
);

export const resetAppState = (): void => {
    store.dispatch({ type: ROOT_ACTION_TYPES.RESET });
};

export default store;
