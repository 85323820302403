import { PricingCalculator, TripPricing } from '@trova-trip/trova-common';
import clamp from 'lodash/clamp';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from '../../../../state/hooks';

export type RemoveTier = (index: number) => void;
export type UpdateTier = (index: number, newValue: number) => void;
export type AddTier = () => void;

type UseTripCosts = (data: { spotsCapacity: { min: number; max: number } }) => {
    tripCosts: TripPricing | undefined;
    addTier: AddTier;
    removeTier: RemoveTier;
    updateTier: UpdateTier;
};

const useTripCosts: UseTripCosts = ({ spotsCapacity }) => {
    const { trip } = useSelector((state) => ({
        itinerary: state.userTripRequests.current?.itinerary,
        trip: state.userTrips.current,
    }));

    const tripCostsRef = useRef<TripPricing>();

    const buildTripCosts = useCallback(() => {
        const tripPricing = PricingCalculator.tripPricing()
            .setTripWithPopulateData(trip as PricingCalculator.TripCostDTO)
            .build();
        return tripPricing;
    }, [trip]);

    useEffect(() => {
        const tripPricing = buildTripCosts();
        tripCostsRef.current = tripPricing;
    }, [buildTripCosts]);

    const addTier: AddTier = () => {
        if (!tripCostsRef.current) {
            return;
        }
        const tripCosts = tripCostsRef.current;
        const costThresholds = tripCosts.currentCosts;
        const { numberTravelers } = costThresholds[costThresholds.length - 1];

        const newTravelersNumber = clamp(
            Number(numberTravelers) + 2,
            spotsCapacity.min,
            spotsCapacity.max,
        );

        tripCosts?.addTier(newTravelersNumber);
    };

    const removeTier: RemoveTier = (index) => {
        if (!tripCostsRef.current) {
            return;
        }
        const tripCosts = tripCostsRef.current;
        const costThresholds = tripCosts.currentCosts;
        if (costThresholds.length > 1) {
            tripCosts?.removeTier(costThresholds[index].numberTravelers);
        }
    };

    const updateTier: UpdateTier = (index, newValue) => {
        if (!tripCostsRef.current) {
            return;
        }
        const tripCosts = tripCostsRef.current;
        const costThresholds = tripCosts?.currentCosts;
        const clampedValue = clamp(
            newValue,
            spotsCapacity.min,
            spotsCapacity.max,
        );
        const isValuePresent =
            costThresholds.findIndex(
                (thres) => thres.numberTravelers === clampedValue,
            ) !== -1;

        if (!isValuePresent) {
            tripCosts?.removeTier(costThresholds[index].numberTravelers);
            tripCosts?.addTier(clampedValue);
        }
    };

    return {
        tripCosts: tripCostsRef.current,
        addTier,
        removeTier,
        updateTier,
    };
};

export default useTripCosts;
