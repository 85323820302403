import { useState } from 'react';
import {
    Stack,
    FileInput,
    Image,
    Badge,
    FileInputProps,
    Spinner,
    BaseBox,
    Text,
    useToast,
} from '@trova-trip/trova-components';
import profilePicturePlaceholder from '../../../../../../assets/img/host/home/public-profile/profile-picture-placeholder.svg';
import { PublicProfileFieldNames } from '../../utils/types';
import {
    ImageDataResponse,
    saveProfilePicture,
} from '../../../../../../state/hosts';
import usePublicProfileAnalytics, {
    PUBLIC_PROFILE_TRACKING_EVENTS,
} from '../../../../hooks/usePublicProfileAnalytics';
import useIsLargeScreen from '../../../../../common/hooks/useIsLargeScreen';
import { gumletDomain } from '../../../../../../config/constants';
interface ProfilePictureProps {
    fieldData?: string;
    onChange: (imageUrl: string) => void;
}

const ALL_IMAGE_FILE_TYPES: FileInputProps['accept'] = ['image/*'];

const IMAGE_MAX_FILE_SIZE = 2_000_000;

const VALID_IMAGE_SIZE = 520;

const createSquareImageLink = (imageDataUrl: string): string => {
    return `${gumletDomain}${imageDataUrl}?ar=1:1&mode=crop`;
};

const uploadImage = async (
    file: File,
): Promise<ImageDataResponse['data'] | undefined> => {
    try {
        const response = await saveProfilePicture(
            PublicProfileFieldNames.PROFILE_PICTURE,
            file,
        );
        if (response?.success) {
            return response.data;
        }
    } catch (error) {
        throw new Error(error);
    }
};

export const ProfilePicture = ({
    fieldData = '',
    onChange,
}: ProfilePictureProps): JSX.Element => {
    const { trackPublicProfileEvent } = usePublicProfileAnalytics();
    const toast = useToast();
    const isDesktop = useIsLargeScreen({ includeTabletResolution: true });
    const [isLoading, setIsLoading] = useState(false);
    const shouldRenderRequiredBadge = !fieldData;
    const gumletConfiguration = {
        'data-gumlet': fieldData ? 'true' : 'false',
    };

    const onSelectImage = async (files) => {
        if (!files.length) return;
        trackPublicProfileEvent(
            PUBLIC_PROFILE_TRACKING_EVENTS.clickedEditImage,
        );
        const [{ data: file }] = files;
        try {
            setIsLoading(true);
            const imageData = await uploadImage(file);

            if (imageData?.url) {
                onChange(imageData.url);
            }
        } catch (error) {
            console.log('Error uploading image: ', error);
            setIsLoading(false);
        }
    };

    const showErrorMessage = (error: Error) => {
        if (error.name === 'INVALID_FILE_SIZE') {
            toast({
                title: 'Image size is too large',
                description: 'Image must be under 2mb.',
                status: 'error',
                isClosable: true,
            });
        }
    };

    const isDimensionTextVisible = !fieldData && !isLoading;

    return (
        <Stack direction='column' align='center' spacing={4}>
            <BaseBox width='full' position='relative'>
                {isLoading ? (
                    <BaseBox
                        width='full'
                        height='full'
                        position='absolute'
                        background='neutral.white'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Spinner
                            size={isDesktop ? 'xl' : 'lg'}
                            color='teal.trova'
                        />
                    </BaseBox>
                ) : null}
                {isDimensionTextVisible ? (
                    <Text
                        display='flex'
                        position='absolute'
                        bottom={3}
                        justifyContent='center'
                        width='full'
                    >
                        {VALID_IMAGE_SIZE} x {VALID_IMAGE_SIZE}px
                    </Text>
                ) : null}
                <Image
                    {...gumletConfiguration}
                    width='full'
                    onLoad={() => setIsLoading(false)}
                    src={
                        fieldData
                            ? createSquareImageLink(fieldData)
                            : profilePicturePlaceholder
                    }
                    borderRadius={16}
                    fit='cover'
                />
            </BaseBox>
            <Stack align='center'>
                <FileInput
                    onChange={onSelectImage}
                    id='image-uploader'
                    name='image-uploader'
                    text='Edit Image'
                    onError={showErrorMessage}
                    accept={ALL_IMAGE_FILE_TYPES}
                    maxFileSize={IMAGE_MAX_FILE_SIZE}
                />
                {shouldRenderRequiredBadge ? (
                    <Badge variant='rounded' colorScheme='red'>
                        Required
                    </Badge>
                ) : null}
            </Stack>
        </Stack>
    );
};
