/*issue with CRA4 and React 17: https://github.com/emotion-js/emotion/issues/2041*/
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState } from 'react';
import PropTypes from 'prop-types';

import { Grid, Hidden } from '@material-ui/core';
import { constants } from '@trova-trip/trova-models';
import { DraggableContainer, Stack } from '@trova-trip/trova-components';

import { Button } from '@trova-trip/trova-components/build/next';

import Danger from '../../../../components/Typography/Danger';
import Tab from '../../../../navigation/Tab';
import TripDays from '../../../../components/TripDays/TripDays';
import ServicesSearch from '../../components/ServicesSearch/ServicesSearch';
import DailyActivities from '../../../../components/DailyActivities/DailyActivities';
import DraggableServices from '../../components/DraggableServices/DraggableServices';

import {
    PaddedColumn,
    scrollContainer,
    scrollContainerInner,
    StyledTabDetailsInformation,
} from './ConfigureServices.styles';
import {
    getDataFormatted,
    getTripDatesIfApplicable,
} from '../../../../util/ModelDataHelper';
import createDayRefName from '../../../../util/createDayRefName';
import scrollToElement from '../../../../util/scrollToElement';
import MobileServices from './mobileServices/MobileServices';
import { PreviewEntityButton } from './PreviewEntityButton';
import useGetServiceByDayRefIds from 'util/hooks/useGetServiceByDayRefIds';
import useFormattedServices from 'util/hooks/useFormattedServices';
import ConfigureServicesContextProvider from './ConfigureServicesContextProvider';
import TravelerManifestDocumentDownloadButton from 'applications/operator/components/Manifest/TravelerManifestDocumentDownloadButton';
import ContentForModelStatus from 'applications/common/components/ContentForModelStatus';
import { StatefulModel } from '../../../../applications/types';
import { SubmitTechnicalItinerary } from './SubmitTechnicalItinerary';

const ConfigureBaseServices = ({
    data,
    onNextButtonClick,
    cleanErrors,
    errorText,
    disabled,
    updateModelData,
    model,
    canUpdateServices = true,
}) => {
    const [showNewServicesModal, setShowNewServicesModal] = useState(false);
    const [showLibraryServicesModal, setShowLibraryServicesModal] =
        useState(false);
    const dayItemIds = useGetServiceByDayRefIds(data);

    const { servicesByDay, id, startDate } = getDataFormatted(data, model);

    const finalServicesByDay = useFormattedServices(servicesByDay);

    if (!finalServicesByDay) {
        return (
            <Tab
                label={`2. Review ${model}`}
                path={`/configure-services`}
                disabled={true}
            />
        );
    }

    const toggleShowNewServicesModal = () =>
        setShowNewServicesModal((previousValue) => !previousValue);

    const toggleShowLibraryServicesModal = () =>
        setShowLibraryServicesModal((previousValue) => !previousValue);

    const tripDates = getTripDatesIfApplicable(startDate, finalServicesByDay);

    const TripStatuses = constants.trips.TRIP_STATUS;

    const isTrip = model === 'trip';

    return (
        <Tab
            label={`2. Review ${model}`}
            path={`/configure-services`}
            disabled={disabled}
        >
            <DraggableContainer>
                <Grid container>
                    <Grid item xs={12}>
                        <Stack
                            spacing={0}
                            direction={{ base: 'column', md: 'row' }}
                            justify={{ base: 'space-between' }}
                            marginBottom={{ base: 10, md: 0 }}
                        >
                            <StyledTabDetailsInformation
                                title={`Review ${model}`}
                                description={`Time to build your itinerary; add your accommodation, activities and meals below.`}
                            />
                            <Stack
                                direction={{ base: 'column', md: 'row' }}
                                align={{ base: 'left', md: 'center' }}
                                spacing={6}
                            >
                                {isTrip ? (
                                    <ContentForModelStatus
                                        model={StatefulModel.TRIP}
                                        matchingStatuses={[
                                            TripStatuses.CONFIRMED,
                                            TripStatuses.CLOSED,
                                        ]}
                                        render={() => (
                                            <TravelerManifestDocumentDownloadButton
                                                tripId={data.id}
                                            />
                                        )}
                                    />
                                ) : null}
                                <PreviewEntityButton
                                    productName={model}
                                    entityId={id}
                                />
                                {isTrip ? (
                                    <SubmitTechnicalItinerary trip={data} />
                                ) : null}
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
                <ConfigureServicesContextProvider
                    servicesByDay={servicesByDay}
                    model={model}
                    id={id}
                >
                    <Grid container spacing={16}>
                        <Hidden mdDown>
                            <Grid item xs={4} md={2} lg={2}>
                                <TripDays
                                    servicesByDay={finalServicesByDay}
                                    tripDates={tripDates}
                                    dayItemIds={dayItemIds}
                                    scrollToDay={(dayIndex) => {
                                        const elementId =
                                            createDayRefName(dayIndex);
                                        scrollToElement(elementId);
                                    }}
                                    cleanErrors={cleanErrors}
                                />
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} sm={7} md={7} lg={6}>
                            <Hidden smUp>
                                <Stack
                                    direction={{ base: 'column', sm: 'row' }}
                                >
                                    <Button
                                        variant='primary'
                                        isFullWidth={{ base: true, sm: false }}
                                        onClick={toggleShowNewServicesModal}
                                    >
                                        Add new services
                                    </Button>
                                    <Button
                                        variant='primary'
                                        isFullWidth={{ base: true, sm: false }}
                                        onClick={toggleShowLibraryServicesModal}
                                    >
                                        Add from library service
                                    </Button>
                                </Stack>
                            </Hidden>
                            <PaddedColumn>
                                {finalServicesByDay &&
                                    finalServicesByDay.map((day, dayIndex) => (
                                        <DailyActivities
                                            key={dayIndex}
                                            productName={model}
                                            id={createDayRefName(dayIndex)}
                                            dayServices={day}
                                            dayIndex={dayIndex}
                                            date={tripDates[dayIndex]}
                                            currentCurrency={data.currency}
                                            tripLength={
                                                data.servicesByDay.length
                                            }
                                        />
                                    ))}
                                {errorText && (
                                    <Grid item xs={12}>
                                        <Danger>{errorText}</Danger>
                                    </Grid>
                                )}
                                <Stack
                                    direction={{ base: 'column', sm: 'row' }}
                                >
                                    <Button
                                        variant='primary'
                                        isFullWidth={{ base: true, sm: false }}
                                        onClick={() =>
                                            updateModelData(id, data)
                                        }
                                        isDisabled={!canUpdateServices}
                                    >
                                        Save Services
                                    </Button>
                                    <Button
                                        variant='secondary'
                                        isFullWidth={{ base: true, sm: false }}
                                        onClick={() =>
                                            onNextButtonClick(id, data)
                                        }
                                    >
                                        Next
                                    </Button>
                                </Stack>
                            </PaddedColumn>
                        </Grid>
                        <Hidden smUp>
                            <MobileServices
                                showNewServicesModal={showNewServicesModal}
                                showServicesSearchModal={
                                    showLibraryServicesModal
                                }
                                onCloseNewServicesModal={
                                    toggleShowNewServicesModal
                                }
                                onCloseLibraryServicesSearch={
                                    toggleShowLibraryServicesModal
                                }
                                numberOfDays={finalServicesByDay.length}
                                scrollToDay={(dayIndex) => {
                                    const elementId =
                                        createDayRefName(dayIndex);
                                    scrollToElement(elementId);
                                }}
                            />
                        </Hidden>
                        <Hidden xsDown>
                            <Grid item sm={5} md={5} lg={4}>
                                <div css={scrollContainer()}>
                                    <div css={scrollContainerInner()}>
                                        <DraggableServices
                                            isDisabled={!canUpdateServices}
                                        />
                                        <ServicesSearch />
                                    </div>
                                </div>
                            </Grid>
                        </Hidden>
                    </Grid>
                </ConfigureServicesContextProvider>
            </DraggableContainer>
        </Tab>
    );
};

ConfigureBaseServices.propTypes = {
    data: PropTypes.shape(),
    updateModelData: PropTypes.func,
    onNextButtonClick: PropTypes.func,
    errorText: PropTypes.string,
    cleanErrors: PropTypes.func,
    disabled: PropTypes.bool,
    model: PropTypes.oneOf(['trip', 'itinerary']),
    canUpdateServices: PropTypes.bool,
};

export default ConfigureBaseServices;
