import { Stack } from '@trova-trip/trova-components';
import { useSelector } from '../../../../../state/hooks';
import CheckList, { CheckListItem } from '../../../components/CheckList';
import OnboardingStepHeader from './common/OnboardingStepHeader';
import { constants } from '@trova-trip/trova-models';

const { itinerary: { TierSurveysRequired }} = constants;

const Congrats = () => {
    const { current: profile } = useSelector((state) => state.profile);

    const checkListItems: CheckListItem[] = [
        { title: 'Sign up as a Host', description: 'Done!', isActive: true },
        {
            title: 'Survey your Audience',
            description:
                'Understand your audience and their travel preferences',
        },
        {
            title: 'Qualify to Host a Trip',
            description: `Collect ${TierSurveysRequired[1]} qualified survey responses`,
        },
    ];

    return (
        <Stack direction='column' spacing={4}>
            <OnboardingStepHeader
                title={`Congratulations, ${profile?.firstName} 🎉`}
                lead='You’re one step closer to hosting trips with your audience. Now, let’s better understand where your audience wants to travel.'
                showTrovaTripLogo
            />
            <CheckList items={checkListItems} />
        </Stack>
    );
};

export default Congrats;
