import { useLayoutEffect, useState } from 'react';

interface MicroAppModuleProps {
    renderTarget: string;
    parentRequestObj?: {
        applyResetCss?: boolean;
        qsArgs?: {
            [key: string]: string;
        };
        slugs?: string[];
        [key: string]: any;
    };
    [key: string]: any;
}

type ModuleRenderMethod = (configuration: MicroAppModuleProps) => void;
export interface MicroAppModule {
    default: (configuration: MicroAppModuleProps) => void;
    RenderComponent?: ModuleRenderMethod;
    RenderComponentAs?: (
        configuration: MicroAppModuleProps,
    ) => ModuleRenderMethod;
}

interface UseMicroAppModuleParams {
    microAppScriptUrl: string;
    onModuleImported?: (module: MicroAppModule) => void;
}

const fetchMicroAppModule = async (
    microAppScriptUrl: string,
): Promise<MicroAppModule | undefined> => {
    try {
        const module = await import(
            /* webpackIgnore: true */
            microAppScriptUrl
        );
        return module.TrovaTripModule;
    } catch (error) {
        throw new Error(`Module fetch error: ${error}`);
    }
};

const useMicroAppModule = ({
    microAppScriptUrl,
    onModuleImported,
}: UseMicroAppModuleParams): MicroAppModule | undefined => {
    const [microAppModule, setMicroAppModule] = useState<MicroAppModule>();

    useLayoutEffect(() => {
        try {
            (async (): Promise<void> => {
                try {
                    const module = await fetchMicroAppModule(microAppScriptUrl);

                    if (!module) {
                        throw new Error("Module wasn't loaded correctly.");
                    }

                    if (onModuleImported) onModuleImported(module);
                    setMicroAppModule(module);
                } catch (error) {
                    console.error(error);
                }
            })();
        } catch (error) {
            console.error(error);
        }
    }, [microAppScriptUrl, onModuleImported]);

    return microAppModule;
};

export { useMicroAppModule };
