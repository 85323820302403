import React from 'react';
import styled from '@emotion/styled';
import { theme } from '@trova-trip/trova-components';

const StyledDanger = styled.div({
    fontFamily: theme.fonts.roboto,
    color: theme.colors.red.trova,
});

type FormErrorPropsType = {
    error: null | Object;
    formField: string;
};

const FormError: React.FC<FormErrorPropsType> = ({ error, formField }) => {
    return error && error[formField] ? (
        <StyledDanger>{error?.[formField]}</StyledDanger>
    ) : null;
};
export default FormError;
