export enum ROOT_ACTION_TYPES {
    RESET = 'RESET',
}

type Action<T, P = undefined> = {
    type: T;
} & (P extends undefined ? Record<string, unknown> : { payload: P });

type WithLoadingState = {
    isLoading: boolean;
};

export type { Action, WithLoadingState };
