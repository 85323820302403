import {
    BaseBox,
    Image,
    Stack,
    Text,
    theme,
} from '@trova-trip/trova-components';
import { Button, Card, Link } from '@trova-trip/trova-components/build/next';
import { models } from '@trova-trip/trova-models';
import { useEffect } from 'react';
import {
    trackClickedAndroidDownloadApp,
    trackClickedIosDownloadApp,
    trackViewedDownloadAppCard,
} from '../../../../../applications/common/analytics/traveler';
import { useNavigate } from '../../../../../applications/common/hooks/useNavigation';
import { capitalize } from '../../../../../applications/utils';
import TrovaAppIOS from '../../../../../assets/img/trova-app-apple-store.svg';
import TrovaAppGoogle from '../../../../../assets/img/trova-app-google-play.svg';
import {
    MobileAppReadyTravelerStatuses,
    MobileAppUrls,
    TravelerStatuses,
    TripStatuses,
} from '../../../../../config/constants';
import CardTitleHeader from '../../../../common/components/CardTitleHeader';
import BookNewTripLinkButton from '../../BookNewTripLinkButton/BookNewTripLinkButton';
import { StyledCardBody } from '../TripCards.components';

type Trip = models.trips.Trip;

interface WelcomeCardProps {
    trip: Trip;
    firstName: string;
    travelerStatus: string;
}

interface WelcomeCardContent {
    subtitle: string;
    cta: JSX.Element | null;
}

const getCardContent = (
    trip: Trip,
    travelerStatus: string,
    navigateToTripForm: () => void,
): WelcomeCardContent => {
    if (
        trip.status !== TripStatuses.COMPLETE &&
        travelerStatus === TravelerStatuses.WAIVER_COMPLETE
    ) {
        return {
            subtitle: 'Here is what to do next',
            cta: (
                <Button
                    variant='primary'
                    rightIcon='arrow-right'
                    onClick={navigateToTripForm}
                    isFullWidth
                >
                    Complete trip form
                </Button>
            ),
        };
    }

    if (
        !!trip.mobileAppEnabled &&
        trip.status === TripStatuses.CLOSED &&
        MobileAppReadyTravelerStatuses.has(travelerStatus)
    ) {
        return {
            subtitle:
                'Download the new TrovaTrip App and connect with your fellow travelers!',
            cta: <MobileAppLinks />,
        };
    }

    if (
        travelerStatus === TravelerStatuses.CANCELLED ||
        trip.status === TripStatuses.COMPLETE
    ) {
        return {
            subtitle: 'Here is what to do next',
            cta: <BookNewTripLinkButton isFullWidth />,
        };
    }

    return {
        subtitle: 'There is nothing for you to do.',
        cta: null,
    };
};

const MobileAppLinks = (): JSX.Element => {
    useEffect(() => {
        trackViewedDownloadAppCard();
    }, []);

    return (
        <Stack
            direction='row'
            justify='center'
            align='center'
            spacing={4}
            wrap='nowrap'
            paddingX={4}
        >
            <BaseBox onClick={(): void => trackClickedIosDownloadApp()}>
                <Link href={MobileAppUrls.ios}>
                    <Image src={TrovaAppIOS} data-gumlet='false' />
                </Link>
            </BaseBox>
            <BaseBox onClick={(): void => trackClickedAndroidDownloadApp()}>
                <Link href={MobileAppUrls.android}>
                    <Image src={TrovaAppGoogle} data-gumlet='false' />
                </Link>
            </BaseBox>
        </Stack>
    );
};

const WelcomeCard = ({
    trip,
    firstName,
    travelerStatus,
}: WelcomeCardProps): JSX.Element => {
    const TRIP_PATH = `/app/traveler/trips/${trip.id}`;
    const navigateToTripForm = useNavigate(`${TRIP_PATH}/trip-form`, {
        preserveCurrentPath: false,
    });

    const { cta, subtitle } = getCardContent(
        trip,
        travelerStatus,
        navigateToTripForm,
    );

    const header = firstName ? `Welcome ${capitalize(firstName)}!` : 'Welcome!';

    return (
        <Card backgroundColor={theme.colors.neutral.white} padding={4}>
            <CardTitleHeader>{header}</CardTitleHeader>
            <StyledCardBody>
                <BaseBox marginBottom={4}>
                    <BaseBox marginBottom={6}>
                        <Text>{subtitle}</Text>
                    </BaseBox>
                    {cta}
                </BaseBox>
            </StyledCardBody>
        </Card>
    );
};

export default WelcomeCard;
