import { useCallback } from 'react';

const useUpdateModelData = (updateModelData, renderError, renderSuccess) =>
    useCallback(
        (id, data) => {
            updateModelData(id, data, {
                successCallback: () => {
                    renderError(undefined);

                    if (renderSuccess && typeof renderSuccess === 'function') {
                        renderSuccess();
                    }
                },

                errorCallback: (error) => {
                    renderError(`Error - ${error}`);
                },
            });
        },
        [updateModelData, renderError, renderSuccess],
    );

export default useUpdateModelData;
