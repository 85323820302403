import { models } from '@trova-trip/trova-models';

enum AdminRecommendationTypes {
    TOP = 'top',
    SECONDARY = 'secondary',
}

export enum AdminRecommendationLocations {
    BANNER = 'banner',
    CATALOG = 'catalog',
    HOME = 'home',
    AUDIENCE = 'audience',
}

type AdminRecommendationTrackingData = {
    adminRecommendationType: AdminRecommendationTypes;
    adminUserRecommended: string;
    adminRecommendationLocation?: AdminRecommendationLocations;
};

export const getAdminRecommendationTrackingData = (
    recommendation:
        | models.itineraryRecommendations.ItineraryRecommendationOutput
        | undefined,
    recommendedItinerary?: models.itineraryRecommendations.ItineraryRecommendationOptionOutput,
    recommendationLocation?: AdminRecommendationLocations,
): AdminRecommendationTrackingData | undefined => {
    if (!recommendation || !recommendedItinerary) return undefined;

    const trackingData: AdminRecommendationTrackingData = {
        adminRecommendationType: recommendedItinerary?.topRecommended
            ? AdminRecommendationTypes.TOP
            : AdminRecommendationTypes.SECONDARY,
        ...(recommendationLocation && {
            adminRecommendationLocation: recommendationLocation,
        }),
        adminUserRecommended:
            `${recommendation?.recommendedBy?.firstName} ${recommendation?.recommendedBy?.lastName}`.trim(),
    };
    return trackingData;
};
