// @ts-nocheck
import {
    ComponentWidth,
    Grid,
    Heading,
    Stack,
} from '@trova-trip/trova-components';
import { Button, Card } from '@trova-trip/trova-components/build/next';
import { constants } from '@trova-trip/trova-models';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { withRouter } from 'react-router';
import getQueryParam from 'util/getQueryParam';
import Loading from '../../../../../components/Loading';
import Danger from '../../../../../components/Typography/Danger';
import { TripStatuses, modelTypes } from '../../../../../config/constants';
import { getUserTripInventory } from '../../../../../state/userTrips';
import FakeEvent from '../../../../../util/form/FakeEvent';
import FormSaver from '../../../../../util/form/FormSaver';
import ConfirmationDialog from '../../../../common/components/ConfirmationDialog';
import { getAccommodationServices } from '../../../../common/helpers';
import PricingTable from '../../../components/PricingTable/PricingTable';
import ServicesTable from '../../../components/ServicesTable/ServicesTable';
import { isTripFieldEditable } from '../../utils/OperatorEditableFields';
import { redirectToTab } from '../../utils/RedirectToTab';
import {
    PaddedCheckbox,
    PaddedNumberInput,
    buttonsContainer,
} from '../ReviewPricing.components';
import CardSection from '../common/CardSection';
import {
    canSingleSupplementQuantityBeUpdated,
    getAdditionalOptionalServices,
    getOptionalOnTripServices,
    getTripAdditionalCosts,
    getTwinPrePostServices,
} from '../common/ReviewPricing.helpers';
import TripDatesDisplay from './TripDatesDisplay';

const submitActions = {
    APPROVE: 'approve',
    SAVE: 'save',
};

const SINGLE_SUPPLEMENT = 'Single Supplement';
const APPROVABLE_STATUSES = [TripStatuses.CREATED];
const ServiceType = constants.services.ServiceType;

type TripReviewPricingFormProps = {
    data: any;
    profile: any;
    tripRequest: any;
    updateModelData: (
        id: string,
        data: any,
        callbacks: Record<string, (message: string) => void>,
    ) => void;
    renderError: (error: string) => void;
    errorText: string;
    cleanErrors: () => void;
    history: any;
    isSubmitDisabled?: boolean;
};

class TripReviewPricingForm extends React.Component<TripReviewPricingFormProps> {
    async updateTrip(data, formData): void {
        const { updateModelData, renderError } = this.props;
        this.setState({ isUpdateInProgress: true });
        renderError('');

        const { id } = data;

        try {
            const canUpdateSingleSupplement =
                await this.canUpdateSingleSupplementInventory(id, formData);

            if (!canUpdateSingleSupplement) {
                this.handleSubmitError(
                    `Single Supplement "Total Spots" can not be set lower than booked spots (${inventory.singleSupplement?.booked})`,
                );

                return;
            }

            updateModelData(id, formData, {
                successCallback: () => {
                    this.setState({
                        newSuccess: true,
                        isUpdateInProgress: false,
                    });
                },
                errorCallback: (error) => {
                    this.handleSubmitError(error);
                },
            });
        } catch (error) {
            this.handleSubmitError(error.message);
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            isConfirmationDialogOpen: false,
            isUpdateInProgress: false,
        };
        const { data } = this.props;

        this.originalOptionalServices = {
            ...getAdditionalOptionalServices(data.additionalOptionalServices),
            optionalOnTripServices: getOptionalOnTripServices(
                data.servicesByDay,
            ),
        };

        this.additionalCosts = getTripAdditionalCosts(data);

        this.formSaver = new FormSaver(
            this,
            (formData) => this.updateTrip(this.props.data, formData),
            {
                ...data,
                ...cloneDeep(this.originalOptionalServices),
                additionalCosts: cloneDeep(this.additionalCosts),
            },
        );
    }

    onChangeTermsAndConditions = (event, name, value) => {
        const fakeEvent = new FakeEvent(name, value);
        fakeEvent.target.type = 'checkbox';
        this.formSaver.handleChange(fakeEvent);
    };

    getServicesToUpdate = () => {
        const servicesToUpdate = [];

        const optionalOnTripServices = this.formSaver.getData(
            'optionalOnTripServices',
        );
        const accommodationsServices = this.formSaver.getData(
            'accommodationsServices',
        );
        const transferServices = this.formSaver.getData('transferServices');
        const currentServices = [
            ...optionalOnTripServices,
            ...accommodationsServices,
            ...transferServices,
        ];

        const originalServices = [
            ...this.originalOptionalServices.optionalOnTripServices,
            ...this.originalOptionalServices.accommodationsServices,
            ...this.originalOptionalServices.transferServices,
        ];

        currentServices.forEach((service, index) => {
            if (service.price !== originalServices[index].price) {
                servicesToUpdate.push({
                    _id: service.id || service._id,
                    price: service.price,
                    name: service.name,
                    type: service.type,
                });
            }
        });

        const prePostTwinServices = getTwinPrePostServices(
            servicesToUpdate,
            this.props.data.additionalOptionalServices,
        );

        return [...servicesToUpdate, ...prePostTwinServices];
    };

    handleSubmit = (event, submitAction) => {
        const servicesToUpdate = this.getServicesToUpdate();
        this.formSaver.updateValue('servicesToUpdate', servicesToUpdate, () => {
            if (submitAction === submitActions.APPROVE) {
                this.setState({ isConfirmationDialogOpen: true });
            } else if (submitAction === submitActions.SAVE) {
                this.formSaver.handleFormSubmit(event);
            }
        });
    };

    handleSubmitError = (errorMessage: string) => {
        const { renderError } = this.props;

        renderError(`Error - ${errorMessage}`);
        this.setState({ isUpdateInProgress: false });
    };

    handleDialogConfirm = (event) => {
        this.setState({ isConfirmationDialogOpen: false });
        this.formSaver.updateValue(
            'status',
            TripStatuses.PARTNER_APPROVED,
            () => this.formSaver.handleFormSubmit(event),
        );
    };

    componentWillUnmount() {
        this.props.cleanErrors();
    }

    updateCostThresholds = (costThresholds) => {
        this.formSaver.updateValue('costThresholds', costThresholds);
    };

    updateSingleSupplement = (value, fieldName) => {
        const additionalCosts = this.formSaver.getData('additionalCosts');

        if (fieldName.includes('quantity')) {
            additionalCosts.singleSupplement.quantity = value || '0';
        } else {
            additionalCosts.singleSupplement.price = value || 0;
        }

        this.formSaver.updateValue('additionalCosts', additionalCosts);
    };

    updateService = (price, id, collection) => {
        const currentServices = cloneDeep(this.formSaver.getData(collection));

        const serviceIndex = currentServices.findIndex(
            (service) => (service.id || service._id) === id,
        );

        if (serviceIndex === -1) return;

        currentServices[serviceIndex] = {
            ...currentServices[serviceIndex],
            price: price,
        };

        this.formSaver.updateValue(collection, currentServices);
    };

    handleAdditionalOptionalServiceChange = (event, id, type) => {
        const price = event.metadata.value;
        if (type === ServiceType.AIRPORT_TRANSFER) {
            this.updateService(price, id, 'transferServices');
        }
        if (type === ServiceType.ACCOMMODATION) {
            this.updateService(price, id, 'accommodationsServices');
        }
    };

    handleOptionalOnTripServiceChange = (
        event: FormFieldEvent<HTMLInputElement, NumberInputValue>,
        id: string,
    ) => {
        const fieldName = event.metadata.name;
        const value = event.metadata.value;

        if (id === SINGLE_SUPPLEMENT) {
            this.updateSingleSupplement(value, fieldName);
        } else {
            this.updateService(value, id, 'optionalOnTripServices');
        }
    };

    handleTrovaComponentsChange = (event, name, value) => {
        const fakeEvent = new FakeEvent(name, value);
        this.formSaver.handleChange(fakeEvent);
    };

    wasTripApprovedByOperator = () => {
        const tripStatus = this.formSaver.getData('status');
        return !APPROVABLE_STATUSES.includes(tripStatus);
    };

    shouldDisableField = (name) => {
        const model = getQueryParam(this.props.history, 'model');

        if (model === modelTypes.TRIP) {
            const status = this.formSaver.getData('status');
            return !isTripFieldEditable(status, name);
        }

        return false;
    };

    canUpdateSingleSupplementInventory = (id, formData) => {
        return new Promise((resolve, reject) => {
            getUserTripInventory(id)
                .then((response) => {
                    if (!response.success) {
                        const error = new Error(
                            'Trip inventory validation could not be completed',
                        );
                        return reject(error);
                    }

                    const inventory = response.data;
                    const canUpdateSingleSupplement =
                        canSingleSupplementQuantityBeUpdated(
                            formData,
                            inventory,
                        );

                    return resolve(canUpdateSingleSupplement);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    };

    render() {
        if (!this.formSaver) {
            return <Loading />;
        }

        if (this.state.newSuccess) {
            redirectToTab(this.props.history, 'success');
        }

        const { data } = this.props;
        const spotsCapacity = {
            min: this.formSaver.getData('minimumSpots'),
            max: this.formSaver.getData('maximumSpots'),
        };

        const currentCurrency = this.formSaver.getData('currency');
        const singleSupplement = this.formSaver.getData(`additionalCosts`)
            ? this.formSaver.getData(`additionalCosts`).singleSupplement.price
            : 0;
        const singleSupplementQuantity =
            this.formSaver.getData(`additionalCosts`)?.singleSupplement
                ?.quantity;

        const isTripAlreadyApproved = this.wasTripApprovedByOperator();

        const { preTripAccommodationsServices, postTripAccommodationServices } =
            getAccommodationServices(
                this.formSaver.getData('accommodationsServices'),
            );

        return (
            <Stack direction='column' spacing={6}>
                <CardSection title='Number of Travelers'>
                    <Stack spacing={{ sm: 0, md: 2 }}>
                        <PaddedNumberInput
                            name='minimumSpots'
                            label='Minimum'
                            size={ComponentWidth.Flexible}
                            value={this.formSaver.getData('minimumSpots') || 0}
                            onChange={this.handleTrovaComponentsChange}
                            disabled={this.shouldDisableField('minimumSpots')}
                        />
                        <PaddedNumberInput
                            name='maximumSpots'
                            placeholder='Placeholder'
                            label='Maximum'
                            size={ComponentWidth.Flexible}
                            value={this.formSaver.getData('maximumSpots') || 0}
                            onChange={this.handleTrovaComponentsChange}
                            disabled={this.shouldDisableField('maximumSpots')}
                        />
                    </Stack>
                </CardSection>

                <CardSection width='full' title='Pre and Post Services'>
                    <ServicesTable
                        currency={currentCurrency}
                        onChange={this.handleAdditionalOptionalServiceChange}
                        isDisabled={this.shouldDisableField(
                            'additionalOptionalServices',
                        )}
                        services={[
                            {
                                title: 'Pre-night Accommodation (price per room)',
                                optionalServices: preTripAccommodationsServices,
                            },
                            {
                                title: 'Post-night Accommodation (price per room)',
                                optionalServices: postTripAccommodationServices,
                            },
                            {
                                title: 'Transfers (Private)',
                                optionalServices:
                                    this.formSaver.getData('transferServices'),
                            },
                        ]}
                    />
                </CardSection>

                <CardSection width='full' title='On Trip Services'>
                    <ServicesTable
                        currency={currentCurrency}
                        onChange={this.handleOptionalOnTripServiceChange}
                        isDisabled={this.shouldDisableField(
                            'optionalOnTripServices',
                        )}
                        services={[
                            {
                                title: 'Optional Activities',
                                optionalServices: this.formSaver.getData(
                                    'optionalOnTripServices',
                                ),
                            },
                            {
                                title: 'Optional Accommodations',
                                optionalServices: [
                                    {
                                        id: SINGLE_SUPPLEMENT,
                                        label: 'Single Supplement',
                                        price: singleSupplement,
                                        quantity: singleSupplementQuantity,
                                    },
                                ],
                            },
                        ]}
                        shouldDisableField={this.shouldDisableField}
                    />
                </CardSection>

                <Grid width='full' marginTop={8}>
                    <Grid.Item as='article' columnSpan={{ base: 12 }}>
                        <Card>
                            <Stack
                                spacing={1}
                                direction='column'
                                marginBottom={4}
                            >
                                <Heading as={'h4'}>Trip Pricing</Heading>
                                <TripDatesDisplay
                                    startDate={data.startDate}
                                    lengthOfTrip={data.servicesByDay.length}
                                />
                            </Stack>
                            <PricingTable
                                costThresholds={this.formSaver.getData(
                                    `costThresholds`,
                                )}
                                singleSupplement={singleSupplement}
                                currentCurrency={currentCurrency}
                                updateFormCostThresholds={
                                    this.updateCostThresholds
                                }
                                updateSingleSupplement={
                                    this.updateSingleSupplement
                                }
                                spotsCapacity={spotsCapacity}
                                disableInputs={this.shouldDisableField(
                                    'costThresholds',
                                )}
                            />
                        </Card>
                    </Grid.Item>
                    <Grid.Item
                        columnSpan={{ base: 12, md: 10 }}
                        hidden={isTripAlreadyApproved}
                    >
                        <PaddedCheckbox
                            name='operatorTerms'
                            value={this.formSaver.getData('operatorTerms')}
                            detail='I confirm all trip details, including pricing and validity, are accurate and in accordance with our agreed upon terms.'
                            onChange={this.onChangeTermsAndConditions}
                        />
                    </Grid.Item>
                    <Grid.Item
                        columnSpan={{ base: 12, md: 10 }}
                        item
                        container
                        className={buttonsContainer}
                    >
                        {this.props.errorText && (
                            <Grid.Item xs={12} marginBottom={2}>
                                <Danger>{this.props.errorText}</Danger>
                            </Grid.Item>
                        )}
                        <Stack direction={{ base: 'column', sm: 'row' }}>
                            <Button
                                variant='primary'
                                isFullWidth={{ base: true, sm: false }}
                                onClick={(event) =>
                                    this.handleSubmit(event, submitActions.SAVE)
                                }
                                isDisabled={this.props.isSubmitDisabled}
                                isLoading={this.state.isUpdateInProgress}
                            >
                                Save Trip
                            </Button>
                            <Button
                                variant='secondary'
                                isFullWidth={{ base: true, sm: false }}
                                isDisabled={
                                    !this.formSaver.getData('operatorTerms') ||
                                    isTripAlreadyApproved
                                }
                                onClick={(event) =>
                                    this.handleSubmit(
                                        event,
                                        submitActions.APPROVE,
                                    )
                                }
                            >
                                Approve Trip
                            </Button>
                        </Stack>
                    </Grid.Item>
                    <ConfirmationDialog
                        onCancel={() => {
                            this.setState({ isConfirmationDialogOpen: false });
                        }}
                        onConfirmation={(event) => {
                            this.handleDialogConfirm(event);
                        }}
                        open={this.state.isConfirmationDialogOpen}
                        title='Approve Trip'
                        description="Once submitted, pricing and activities can't be altered."
                    />
                </Grid>
            </Stack>
        );
    }
}

export default withRouter(TripReviewPricingForm);
