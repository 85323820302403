import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ComponentWidth, Dropdown } from '@trova-trip/trova-components';
import useCurrencies from '../../../common/hooks/currencies/useCurrencies';

const useCurrenciesDropdownOptions = () => {
    const [currenciesOptions, setCurrenciesOptions] = useState();
    const { currencies } = useCurrencies();

    const getCurrenciesOptions = () => {
        const currenciesDropdownOptions = currencies.map(currency => ({
            value: currency.id,
            children: currency.name,
        }));
        setCurrenciesOptions(currenciesDropdownOptions);
    };

    useEffect(
        () => {
            if (currencies) {
                getCurrenciesOptions();
            }
        },
        [currencies]
    );

    return currenciesOptions;
};

const CurrenciesDropdown = ({ name, value, label, onChange, className }) => {
    const currenciesOptions = useCurrenciesDropdownOptions();
    if (!currenciesOptions) {
        return null;
    }
    return (
        <Dropdown
            className={className}
            onChange={onChange}
            name={name}
            value={value}
            placeholder="Currency"
            label={label}
            size={ComponentWidth.Flexible}
        >
            {currenciesOptions}
        </Dropdown>
    );
};
CurrenciesDropdown.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
};

export default CurrenciesDropdown;
