import { useSelector } from '../../../../../../state/hooks/useSelector';
import StickyContentExternalNav from '../../../../../common/components/StickyContent/StickyContentExternalNav';

const TravelerInfoTabStickyLayoutContent = (): JSX.Element => {
    const currentTrip = useSelector((state) => state.userTrips.current);
    const { preparationListUrl } = currentTrip || {};

    return (
        <>
            {preparationListUrl && (
                <StickyContentExternalNav
                    title={'Trip Preparation'}
                    description={'Download your trip preparation document.'}
                    routeToUrl={preparationListUrl}
                    buttonProps={{
                        children: 'View',
                    }}
                    marginTop={0}
                />
            )}
        </>
    );
};

export default TravelerInfoTabStickyLayoutContent;
