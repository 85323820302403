import { GridProps } from '@trova-trip/trova-components';
import {
    ItineraryRecommendationCardProps,
    RecommendationQuoteCardProps,
} from './components';

export enum RecommendationAreas {
    RECOMMENDATIONS = 'recommendations',
    QUOTE = 'quote',
}
interface RecommendationBannerLayoutProps {
    gap: GridProps['gap'];
    templateAreas: GridProps['templateAreas'];
    templateColumns: GridProps['templateColumns'];
    recommendationCardLayoutConfig?: ItineraryRecommendationCardProps['layoutConfig'];
    recommendedByCardVariant?: RecommendationQuoteCardProps['variant'];
    quoteCardContainer?: RecommendationQuoteCardProps['containerProps'];
    displayRecommendedBy?: boolean;
}
export type RecommendationBannerLayoutTypes =
    | 'home'
    | 'itineraryCatalog'
    | 'audience';

export const getBannerLayout = (
    amountOfRecommendations: number,
): Record<
    RecommendationBannerLayoutTypes,
    RecommendationBannerLayoutProps
> => ({
    home: {
        gap: { base: 2, md: 6 },
        templateAreas: `"${RecommendationAreas.RECOMMENDATIONS}" "${RecommendationAreas.QUOTE}"`,
        templateColumns: 'minmax(1fr)',
        recommendedByCardVariant: 'primary',
        quoteCardContainer: { padding: 3, paddingLeft: 4 },
        displayRecommendedBy: true,
    },
    audience: {
        gap: { base: 0 },
        templateAreas: `"${RecommendationAreas.RECOMMENDATIONS}" "${RecommendationAreas.QUOTE}"`,
        templateColumns: 'minmax(1fr)',
        displayRecommendedBy: false,
    },
    itineraryCatalog: {
        gap: { base: 2, md: 0 },
        templateAreas: {
            base: `"${RecommendationAreas.RECOMMENDATIONS}"
            "${RecommendationAreas.QUOTE}"`,
            md: `"${RecommendationAreas.QUOTE} ${RecommendationAreas.RECOMMENDATIONS}"`,
        },
        displayRecommendedBy: true,
        templateColumns: {
            base: 'minmax(1fr)',
            md:
                amountOfRecommendations > 1
                    ? 'minmax(0,320px) minmax(0,1fr)'
                    : 'minmax(0,1fr)  minmax(0,1fr)',
        },
        recommendationCardLayoutConfig: {
            base: {
                titleFontSize: 'base',
                imageSize: { width: 32, height: 24 },
            },
            md: {
                titleFontSize: 'xl',
                imageSize: {
                    width: 40,
                    height: 28,
                },
            },
        },
        recommendedByCardVariant: 'secondary',
        quoteCardContainer: {
            paddingY: { base: 3, md: 0 },
            paddingLeft: { base: 3, md: 0 },
            paddingRight: { base: 3, md: 3 },
        },
    },
});
