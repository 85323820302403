import { models } from '@trova-trip/trova-models';

export const WidgetNames = {
    UPCOMING_TRIPS: 'upcomingTrips',
    EARNINGS: 'earnings',
    AUDIENCE: 'audience',
    IMPORTANT_DATES: 'importantDates',
    PROGRESS: 'progress',
    REVIEWS: 'reviews'
} as const;

export type WidgetTypes = typeof WidgetNames[keyof typeof WidgetNames];

export interface WidgetCallbacks {
    onLoad?: () => void;
    onError?: () => void;
}

export type HostUpcomingTrips =
    models.hostHomeTab.HostHomeTabData['upcomingTrips'];
export type HostEarnings = models.hostHomeTab.HostHomeTabData['earnings'];
export type HostImportantDates =
    models.hostHomeTab.HostHomeTabData['tripImportantDates'];
export type HostAudienceData =
    models.hostHomeTab.HostHomeTabData['audienceData'];

export type WidgetComponentData =
    | HostUpcomingTrips
    | HostEarnings
    | HostImportantDates
    | HostAudienceData;
