import {
    BaseBox,
    IconSvgProps,
    Stack,
    SummaryDetailItem,
    SummaryDetailItemProps,
} from '@trova-trip/trova-components';
import { Location } from '../../../../../../state/features/transfer/types';

export type FromToProps = {
    description: SummaryDetailItemProps['description'];
    additionalInformation: string;
    icon: IconSvgProps['as'];
    types: Location['types'];
};

type FromToSummaryItemProps = {
    from: FromToProps;
    to: FromToProps;
};

type DetailItemProps = FromToProps & {
    showConnector?: boolean;
};

const VerticalConnector = (): JSX.Element => (
    <BaseBox
        position='absolute'
        left={2}
        top={7}
        height='calc(100% - 0.1rem)'
        borderLeft='1px dashed'
        borderColor='blueGray.300'
    />
);

const DetailItem = (props: DetailItemProps): JSX.Element => {
    const {
        description,
        additionalInformation = '',
        icon,
        showConnector,
        types = [],
    } = props;

    const isAirportType = types.includes('airport');
    const completeDescription = `${description}${
        additionalInformation && !isAirportType
            ? ` - ${additionalInformation}`
            : ''
    }`;

    return (
        <BaseBox
            position='relative'
            marginBottom={showConnector ? 6 : undefined}
        >
            <SummaryDetailItem
                leftIconProps={{
                    color: 'teal.trova',
                    as: icon,
                    spacing: 2,
                }}
                description={completeDescription}
                descriptionMaxLines={4}
            />
            {showConnector ? <VerticalConnector /> : null}
        </BaseBox>
    );
};

const FromToSummaryItem = (props: FromToSummaryItemProps): JSX.Element => {
    const { from, to } = props;

    return (
        <Stack direction='column' spacing={0}>
            <DetailItem
                description={from.description}
                additionalInformation={from.additionalInformation}
                types={from.types}
                icon={from.icon}
                showConnector
            />
            <DetailItem
                description={to.description}
                additionalInformation={to.additionalInformation}
                types={to.types}
                icon={to.icon}
            />
        </Stack>
    );
};

export default FromToSummaryItem;
