import { models } from '@trova-trip/trova-models';
import { SyntheticEvent } from 'react';
import { Option } from '../../../../../util/form/dropdown';
import { constants } from '@trova-trip/trova-models';

export const AddressFieldsNames = constants.address.AddressFieldsNames;

export type User = models.users.User;

export enum ProfileFieldsNames {
    PROFILE_PICTURE_URL = 'profilePictureUrl',
    DISPLAY_NAME = 'displayName',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    EMAIL = 'email',
    PAYPAL_EMAIL = 'paypalEmail',
    PHONE_NUMBER = 'phoneNumber',
    WEBSITE_URL = 'websiteUrl',
    INSTAGRAM_HANDLE = 'instagramHandle',
    GENDER = 'gender',
    BIO = 'bio',
    EMAIL_SIGNATURE = 'emailSignature',
}

export interface ProfileForm {
    profile: ProfileFormModel;
    address: AddressFormModel;
}

export interface ProfileHostFormModel {
    displayName: string;
    paypalEmail: string;
    websiteUrl: string;
    emailSignature: string;
}

export interface ProfileFormModel extends ProfileHostFormModel {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    instagramHandle: string;
    profilePictureUrl: string;
    bio: string;
    gender: string;
}

export interface AddressFormModel {
    [AddressFieldsNames.STREET_LINE_ONE]: string;
    [AddressFieldsNames.STREET_LINE_TWO]: string;
    [AddressFieldsNames.CITY]: string;
    [AddressFieldsNames.SUBDIVISION_CODE]: Option | undefined;
    [AddressFieldsNames.POSTAL_CODE]: string;
    [AddressFieldsNames.COUNTRY_CODE]: Option | undefined;
}

export interface ProfileFormResponse {
    profile?: User;
    error?: string;
}

export interface ProfileFormTextFieldProps {
    label: string;
    name: string;
    value: string;
    onChange: (event: SyntheticEvent<Element, Event>) => void;
    required?: boolean;
    error?: string[];
    placeholder?: string;
    type?: 'input' | 'textarea';
    disabled?: boolean;
}
