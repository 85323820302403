import { useEffect } from 'react';
import { getManageBookingViewModel } from '../viewmodel';
import { BookingWithTrip, Trip } from '../types';
import { useSelector } from '../../../../../state/hooks';
import useManageBooking from './useManageBooking';

const useInitViewModel = (): void => {
    const {
        actions: {
            initViewModel,
            setBookingPaymentData,
            verifySingleSupplementInventory,
            resetManageBookingState,
        },
        state,
    } = useManageBooking();

    const { currentBooking, currentTrip } = useSelector((state) => ({
        currentBooking: state.userBookings?.current?.[0],
        currentTrip: state.userTrips?.current,
    }));

    const booking = {
        ...currentBooking,
        trip: currentTrip || ({} as Trip),
    } as BookingWithTrip;

    const isBookingAvailable = !!booking?._id;
    const isBookingWithTripAvailable =
        isBookingAvailable && !!booking?.trip?.id;

    /**
     * We need to reset the state when the trip id changes
     * to avoid fetching the previous trip related data
     * on navigating to a new trip.
     */
    useEffect(() => {
        resetManageBookingState();
    }, [booking?.trip?.id]);

    useEffect(() => {
        if (!isBookingWithTripAvailable) return;

        const viewModel = getManageBookingViewModel(booking);
        initViewModel(viewModel);

        if (state?.currentTripId) {
            verifySingleSupplementInventory({ state });
        }

        setBookingPaymentData({
            dueAmount: booking.dueAmount,
        });
    }, [booking?._id, booking?.trip?.id, state?.currentTripId]);
};

export default useInitViewModel;
