import {
    BaseBox,
    SummaryItem,
    SummaryTotalItem,
} from '@trova-trip/trova-components';
import JourneyStageSummarySection from '../JourneyStageSummarySection';
import useIsLargeScreen from '../../../../hooks/useIsLargeScreen';
import { useTransfer } from '../../hooks';
import { isTransferDetailCompleteOrWithoutQuote } from '../../../../../../state/features/transfer/utils';
import { isHost, isTraveler } from '../../../../helpers';

const TotalPriceSummaryItem = (): JSX.Element | null => {
    const {
        state: {
            payment: { total },
        },
    } = useTransfer();

    if (total === 0) return null;

    return (
        <BaseBox marginTop={5}>
            <SummaryTotalItem
                description='Total Price'
                price={total}
                color='blueGray.800'
            />
        </BaseBox>
    );
};

const InsuranceAdjustment = () => {
    const {
        state: {
            payment: { insurance },
        },
    } = useTransfer();

    return (
        <BaseBox
            marginTop={5}
            paddingTop={4}
            borderTop='1px solid'
            borderColor='blueGray.300'
        >
            <SummaryItem
                description='Insurance price update'
                price={insurance.adjustment}
            />
        </BaseBox>
    );
};

const SidebarContent = () => {
    const isDesktop = useIsLargeScreen();

    const {
        state: {
            arrival,
            departure,
            payment: { insurance },
        },
        globalState: { user },
    } = useTransfer();

    const isHostUser = isHost(user);

    const shouldShowDivider =
        isTransferDetailCompleteOrWithoutQuote(arrival) &&
        isTransferDetailCompleteOrWithoutQuote(departure);

    const shouldDisplayTotalPrice = !isHostUser && isDesktop;
    const shouldDisplayInsuranceAdjustment =
        isTraveler(user) && insurance.adjustment > 0;

    return (
        <BaseBox height='full'>
            <JourneyStageSummarySection stage='arrival' />
            <JourneyStageSummarySection
                stage='departure'
                showDivider={shouldShowDivider}
            />
            {shouldDisplayInsuranceAdjustment ? <InsuranceAdjustment /> : null}
            {shouldDisplayTotalPrice ? <TotalPriceSummaryItem /> : null}
        </BaseBox>
    );
};

export default SidebarContent;
