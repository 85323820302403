// TODO move this file
import { StyledDropdown } from '../../tabs/ReviewDetails/ReviewDetailsForm.components';
import { ComponentWidth } from '@trova-trip/trova-components';
import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    DropdownOptionKeys,
    filterOptionsFromValue,
    getDropdownOptionsByKey,
} from '../../../../util/form/dropdown';

const SearchableSingleDropDown = ({
    name,
    label,
    value,
    onChange,
    size = ComponentWidth.Medium,
    dropDownOptionKey,
    children,
    disabled,
}) => {
    const initialOptions = useRef(
        children || getDropdownOptionsByKey(dropDownOptionKey),
    );

    const [currentOptions, setCurrentOptions] = useState(
        initialOptions.current,
    );
    const handleSearch = useCallback(
        (event, name, value) => {
            const newOptions = filterOptionsFromValue(
                value,
                initialOptions.current,
            );
            setCurrentOptions(newOptions);
        },
        [initialOptions.current],
    );

    return (
        <StyledDropdown
            name={name}
            label={label}
            value={value || ``}
            onChange={onChange}
            onSearch={handleSearch}
            size={size}
            disabled={disabled}
        >
            {currentOptions}
        </StyledDropdown>
    );
};

SearchableSingleDropDown.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    size: PropTypes.oneOf(Object.values(ComponentWidth)),
    children: PropTypes.array,
    dropDownOptionKey: PropTypes.oneOf(Object.values(DropdownOptionKeys)),
    disabled: PropTypes.bool,
};

export default SearchableSingleDropDown;
