import { shouldDisplayLaunchDateForm } from 'applications/host/utils';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';

import { models, constants } from '@trova-trip/trova-models';

import Snackbar from '../../../../components/Snackbar/Snackbar';
import { userTrips, userTripDetails } from '../../../../state/userTrips';
import { TripDetailsForm } from './Forms';
import { Alert, Grid, Stack } from '@trova-trip/trova-components';
import LaunchDateCard from './LaunchDateCard';
import { useTrackingEvent } from '../../../../analytics/hooks';

const {
    trips: { TRIP_STATUS },
} = constants;

type Trip = models.trips.Trip;
type TripDetails = models.tripDetails.TripDetails;
type TripStoreSelector = { userTrips: { current: Trip } };
type TripDetailsStoreSelector = { userTripDetails: { current: TripDetails } };

const GeneralDetailsForm = (): JSX.Element => {
    const currentTrip = useSelector(
        (state: TripStoreSelector) => state.userTrips.current,
    );
    const currentTripDetails = useSelector(
        (state: TripDetailsStoreSelector) => state.userTripDetails.current,
    );

    const { updateRecord: updateTrip } =
        userTrips.useDispatch.bind(currentTrip)();
    const { getRecord: getTripDetails } =
        userTripDetails.useDispatch.bind(currentTripDetails)();

    const { id, status } = currentTrip;

    const [snackBar, setSnackBar] = useState({
        message: '',
        color: 'info',
        show: false,
    });

    const isLaunchDateFormEnabled: boolean =
        shouldDisplayLaunchDateForm(currentTrip);

    const { trackUserEvent } = useTrackingEvent();

    const onSubmit = async (
        values,
        successMessage: string,
        errorMessage: string,
    ): Promise<any> => {
        try {
            const updatedFieldKeys = Object.keys(values).filter(
                (key) => !isEqual(values[key], currentTrip[key]),
            );
            trackUserEvent({
                eventName: ' Host Updated Trip',
                properties: {
                    tripId: currentTrip?.id,
                    updatedFields: updatedFieldKeys,
                },
            });
            const response = await updateTrip(id, values);
            getTripDetails(id);
            setSnackBar({
                message: successMessage,
                color: 'success',
                show: true,
            });
            return response;
        } catch (error) {
            setSnackBar({
                message: errorMessage,
                color: 'danger',
                show: true,
            });
            console.error('@Trova: Error submitting trip form.', error);
            return { pageErrors: [error] };
        }
    };

    const isTripClosed = status === TRIP_STATUS.CLOSED;

    return (
        <>
            <Stack direction='column' spacing={12} align='stretch'>
                {isTripClosed || isLaunchDateFormEnabled ? (
                    <Grid marginTop={{ base: 3, md: 0 }}>
                        {isTripClosed ? (
                            <Grid.Item columnSpan={{ base: '*', md: 10 }}>
                                <Alert
                                    status='info'
                                    variant='inline'
                                    title='Trip is Closed'
                                    description='Trips are no longer editable in the closed status.'
                                    onCloseAlert={noop}
                                />
                            </Grid.Item>
                        ) : null}
                        {isLaunchDateFormEnabled ? (
                            <LaunchDateCard
                                onSubmit={onSubmit}
                                currentTrip={currentTrip}
                            />
                        ) : null}
                    </Grid>
                ) : null}
                <TripDetailsForm
                    onSubmit={onSubmit}
                    currentTrip={currentTrip}
                />
            </Stack>
            <Snackbar
                place='tr'
                color={snackBar.color}
                message={snackBar.message}
                open={snackBar.show}
                autoHideDuration={4000}
                onClose={(): void => {
                    setSnackBar({
                        message: '',
                        color: 'info',
                        show: false,
                    });
                }}
            />
        </>
    );
};

export default GeneralDetailsForm;
