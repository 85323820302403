import { useEffect, useState } from 'react';
import { models } from '@trova-trip/trova-models';
import AudienceApi from '../../../../../apis/audience';

type AudienceDTO = models.survey.AudienceSurveySubmissionsDTO;

export const useGetSurvey = (): AudienceDTO | null => {
    const [surveyData, setSurveyData] = useState<AudienceDTO | null>(null);
    const api = AudienceApi.forHost();

    useEffect(() => {
        (async (): Promise<void> => {
            const response = await api.getAudienceResearch();
            setSurveyData(response);
        })();
    }, []);

    return surveyData;
};
