import { Stack, Heading, Text } from '@trova-trip/trova-components';
import AssessmentIcon from '@material-ui/icons/Assessment';

const MetricNotPopulated = (): JSX.Element => {
    return (
        <Stack paddingY={16} paddingX={0} align='center' justify='center'>
            <Text as='p' textAlign='center' fontWeight='bold'>
                Not enough data to display.
            </Text>
        </Stack>
    );
};

const PopularDestinationNotPopulated = (): JSX.Element => {
    return (
        <>
            <AssessmentIcon style={{ fontSize: 60 }} />
            <Heading as='h4'>
                As you collect survey responses, they will populate here.
            </Heading>
        </>
    );
};

export { MetricNotPopulated, PopularDestinationNotPopulated };
