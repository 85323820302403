import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import StickyContentLayout from 'components/StickyContentLayout/StickyContentLayout';
import { models } from '@trova-trip/trova-models';
import { Grid } from '@material-ui/core';

import Tab from '../../../../navigation/Tab';
import TabDetailsInformation from 'components/TabDetailsInformation/TabDetailsInformation';
import IFramedServicesAgreement from '../../components/IFramedServicesAgreement/IFramedServicesAgreement';
import ServicesAgreementStickyLayoutContent from './ServicesAgreementStickyLayoutContent';
import useTripRoute, {
    TRIP_ACTION,
} from 'applications/common/hooks/trips/useTripRoute';
import { useTrackingEvent } from '../../../../analytics/hooks';

const ServicesAgreement = (): JSX.Element => {
    const history = useHistory();
    const currentTrip = useSelector(
        (state: { userTrips: { current: models.trips.Trip } }) =>
            state.userTrips.current,
    );

    const successPageRoute = useTripRoute({
        id: currentTrip.id,
        action: TRIP_ACTION.SUCCESS,
        preserveQueryParams: true,
    });

    const { trackUserEvent } = useTrackingEvent();

    const onSuccess = (): void => {
        trackUserEvent({
            eventName: 'Host Accepted Agreement',
            properties: {
                tripId: currentTrip?.id,
                prices: {
                    initial: currentTrip.prices?.initial,
                    remaining: currentTrip.prices?.remainingPrice,
                },
            },
        });

        history.push(successPageRoute);
    };

    return (
        <Tab
            label={'Services Agreement'}
            path={'/services-agreement'}
            hideNavigation
        >
            <StickyContentLayout
                stickyContent={<ServicesAgreementStickyLayoutContent />}
                mainContent={
                    <StickyContentLayout observableContent>
                        <Grid container spacing={32}>
                            <Grid item xs={12}>
                                <Grid container spacing={16}>
                                    <Grid item xs={12}>
                                        <TabDetailsInformation
                                            title='Services Agreement'
                                            description={`Important. In order to complete trip
                                            setup, you must agree to the
                                            Services Agreement.`}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <IFramedServicesAgreement
                                    onSuccess={onSuccess}
                                />
                            </Grid>
                        </Grid>
                    </StickyContentLayout>
                }
            />
        </Tab>
    );
};

export default ServicesAgreement;
