import { SyntheticEvent } from 'react';
import { Grid } from '@material-ui/core';
import {
    Alert,
    Heading,
    SectionCard,
    Stack,
    Text,
    Textarea,
    TextareaSize,
} from '@trova-trip/trova-components';
import noop from 'lodash/noop';

export interface AdditionalRequestsProps {
    name: string;
    value?: string;
    onChange: (event: SyntheticEvent, name: string, value: string) => void;
    instantApprovalAllowed?: boolean;
    disabled: boolean;
}

const AdditionalRequests = ({
    name,
    value,
    onChange,
    instantApprovalAllowed = false,
    disabled,
}: AdditionalRequestsProps): JSX.Element => {
    const isInstantApprovalDisabled =
        instantApprovalAllowed && !disabled && value && value.length;

    return (
        <Grid item xs={12}>
            <SectionCard size='md'>
                <Heading as='h5'>Additional Request Details</Heading>
                <Stack
                    marginTop='2'
                    direction='column'
                    wrap='nowrap'
                    align='stretch'
                    width='full'
                    spacing='4'
                >
                    <Text color='blueGray.650'>
                        This request form is only for itinerary-specific items
                        such as: formal workshop details (time of day, length of
                        workshop, props needed), removal of an activity, or
                        requests to include optional activities in the price for
                        all Travelers. We can not guarantee requests, and
                        requests typically result in delays or increased price.
                    </Text>
                    <Textarea
                        onChange={onChange}
                        name={name}
                        value={value}
                        size={TextareaSize.Flexible}
                        disabled={disabled}
                    />
                    {isInstantApprovalDisabled ? (
                        <Alert
                            title='Instant Approval Turned Off'
                            onCloseAlert={noop}
                            isClosable={false}
                            variant='inline'
                            status='warning'
                            description='Adding additional requests will remove Instant Approval from
                            your trip request.'
                        />
                    ) : null}
                </Stack>
            </SectionCard>
        </Grid>
    );
};

export default AdditionalRequests;
