import StickyContentLayout from 'components/StickyContentLayout/StickyContentLayout';
import React from 'react';
import Tab from '../../navigation/Tab';
import { styledContentWrapper } from './SuccessPage.styles';

interface SuccessPageProps {
    mainContent: React.ReactNode;
    stickyContent: React.ReactNode;
}

const SuccessPage = ({
    stickyContent,
    mainContent,
}: SuccessPageProps): JSX.Element => {
    return (
        <Tab disabled label={'Success'} path={'/success'} hideNavigation>
            <div className={styledContentWrapper}>
                <StickyContentLayout
                    stickyContent={stickyContent}
                    mainContent={
                        <StickyContentLayout observableContent>
                            {mainContent}
                        </StickyContentLayout>
                    }
                />
            </div>
        </Tab>
    );
};

export default SuccessPage;
