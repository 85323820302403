import { Heading, Text } from '@trova-trip/trova-components';
import { Dialog } from '@trova-trip/trova-components/build/next';
import { StyledOperatorContactInfoDialogList } from './OperatorContactInfoDialog.styles';

export interface OperatorContactInfoDialogProps {
  open: boolean;
  onClose: () => void;
}

const OperatorContactInfoDialog = ({
  open,
  onClose,
}: OperatorContactInfoDialogProps): JSX.Element => {
  return (
    <Dialog isOpen={open} onClose={onClose}>
      <Dialog.Header title='Contact Information' />
      <Dialog.Body>
        <StyledOperatorContactInfoDialogList>
          <li>
            <Heading as={'h5'}>Trovatrip Office Hours are</Heading>
            <Text size={'sm'}> 8:30 - 17:30 PST</Text>
          </li>
          <li>
            <Heading as={'h5'}>Where to send invoices:</Heading>
            <Text size={'sm'}>invoice@trovatrip.com</Text>
          </li>
          <li>
            <Heading as={'h5'}>
              Contact our operations team:
            </Heading>
            <Text size={'sm'}>experience@trovatrip.com</Text>
          </li>
          <li>
            <Heading as={'h5'}>
              For trips currently operating ONLY- 24 hour
              emergency number:
            </Heading>
            <Text size={'sm'}>
              <a href="tel:+18882489884">+1 (888) 248-9884</a>.
            </Text>
          </li>

          <li>
            <Heading as={'h5'}>
              This is our emergency information:
            </Heading>
            <Text size={'sm'}>
              - If a Yellow or Red level incident occurs please contact
            </Text>
            <Text size={'sm'}>
              <a href="tel:+18882489884">+1 (888) 248-9884</a> <b>IMMEDIATELY</b>.
            </Text>
            <Text size={'sm'}>
              - For a Green level incident, please email
              experience@trovatrip.com within 24 hours with a
              completed incident form documenting the nature of
              the event. An Incident Form template link can be
              found <a href="https://docs.google.com/document/d/1zZMOs0QHPyD-7xyj7xcbkNVyYM98iW_XJLcOzQXIlpA/edit?usp=sharing">here</a>.
              Please download a copy and keep it in your files.
            </Text>
          </li>
        </StyledOperatorContactInfoDialogList>
      </Dialog.Body>
    </Dialog>
  );
};

export default OperatorContactInfoDialog;
