import {
    Accordion,
    AccordionHeaderProps,
    BaseBoxProps,
    Stack,
} from '@trova-trip/trova-components';
import { Icon, IconProps } from '@trova-trip/trova-components/build/next';
import { useEffect, useState } from 'react';
import MobileAccordionBackdrop from '../MobileAccordionBackdrop';

export type MobileFloatingAccordionPosition = 'bottom' | 'top';
export interface MobileFloatingAccordionProps {
    panel: React.ReactNode;
    header: Omit<AccordionHeaderProps, 'children'>;
    padding?: BaseBoxProps['padding'];
    position?: MobileFloatingAccordionPosition;
}

const calculateTopPosition = (navbarHeight: number) => {
    return window.scrollY < navbarHeight ? navbarHeight - window.scrollY : 0;
};

const MobileFloatingAccordion = ({
    panel,
    header,
    padding = 4,
    position = 'bottom',
}: MobileFloatingAccordionProps): JSX.Element => {
    const [top, setTop] = useState<BaseBoxProps['top']>(undefined);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (position !== 'top') {
            return;
        }

        const handleScroll = () => {
            const dataAttr = '[data-navbar-container]';
            const navbarHeight = document.querySelector(dataAttr)?.clientHeight;
            const position = calculateTopPosition(navbarHeight || 0);
            setTop(`${position}px`);
        };

        handleScroll();

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleAccordion = () => {
        setIsOpen((isOpen) => !isOpen);
    };

    const { rightElement, ...headerProps } = header;

    const positionProps =
        position === 'top'
            ? { top, left: 0, right: 0 }
            : { bottom: 16, left: 0, right: 0 };

    const arrowIcons: Record<string, IconProps['as']> = {
        open: position === 'bottom' ? 'arrow-down' : 'arrow-up',
        closed: position === 'bottom' ? 'arrow-up' : 'arrow-down',
    };

    return (
        <>
            <Accordion
                allowToggle
                index={isOpen ? 0 : -1}
                display={{ lg: 'none' }}
                position='fixed'
                onChange={toggleAccordion}
                background='neutral.white'
                zIndex={1100}
                {...positionProps}
            >
                <Accordion.Item>
                    {({ isExpanded }) => (
                        <>
                            <Accordion.Panel padding={padding}>
                                {panel}
                            </Accordion.Panel>
                            <Accordion.Header
                                text={header.title}
                                fontSize='lg'
                                fontWeight='medium'
                                color='blueGray.650'
                                padding={3}
                                height='4rem'
                                borderBottom='2px'
                                borderColor='blueGray.100'
                                boxShadow='5'
                                rightElement={
                                    <Stack align='center'>
                                        {rightElement}
                                        <Icon
                                            display='inline-flex'
                                            size='md'
                                            as={
                                                isExpanded
                                                    ? arrowIcons.open
                                                    : arrowIcons.closed
                                            }
                                        />
                                    </Stack>
                                }
                                {...headerProps}
                            />
                        </>
                    )}
                </Accordion.Item>
            </Accordion>
            <MobileAccordionBackdrop
                display={isOpen}
                onClick={toggleAccordion}
            />
        </>
    );
};

export default MobileFloatingAccordion;
