import {
    ActionSheet,
    Heading,
    IconButton,
    Stack,
    ActionSheetHeaderProps,
    ActionSheetBodyProps,
    ActionSheetFooterProps,
} from '@trova-trip/trova-components';
import { Button, ButtonProps } from '@trova-trip/trova-components/build/next';
import { ReactElement } from 'react';

interface FullScreenActionSheetProps {
    isOpen: boolean;
    onClose: () => void;
    onBack?: () => void;
    headerProps?: ActionSheetHeaderProps;
    bodyProps?: Omit<ActionSheetBodyProps, 'children'>;
    footerProps?: Omit<ActionSheetFooterProps, 'children'>;
    title?: string;
    content: ReactElement | undefined;
    actions?: ButtonProps[];
    titleRightAddOn?: ReactElement;
}

const FullScreenActionSheet = (
    props: FullScreenActionSheetProps,
): JSX.Element => {
    const {
        isOpen,
        onClose,
        onBack,
        headerProps,
        bodyProps,
        footerProps,
        title,
        content,
        actions,
        titleRightAddOn,
    } = props;

    return (
        <ActionSheet isOpen={isOpen} onClose={onClose} minHeight='100vh'>
            {headerProps ? (
                <ActionSheet.Header {...headerProps} showCloseButton={true}>
                    {headerProps.children}
                </ActionSheet.Header>
            ) : null}
            <ActionSheet.Body
                display='flex'
                flexDirection='column'
                {...bodyProps}
            >
                <Stack
                    direction='column'
                    align='stretch'
                    spacing={3}
                    flexGrow={1}
                    width='full'
                >
                    <Stack width='full' justify='space-between'>
                        {onBack ? (
                            <IconButton
                                aria-label='Back'
                                icon='arrow-left'
                                variant='ghost'
                                size='sm'
                                onClick={onBack}
                            />
                        ) : null}
                        {!headerProps ? (
                            <Stack marginRight={0} marginLeft='auto'>
                                <IconButton
                                    aria-label='Close'
                                    icon='close'
                                    variant='ghost'
                                    size='sm'
                                    onClick={onClose}
                                />
                            </Stack>
                        ) : null}
                    </Stack>
                    <Stack justify='space-between' align='center' spacing={0}>
                        {title ? <Heading as='h6'>{title}</Heading> : null}
                        {titleRightAddOn}
                    </Stack>
                    {content}
                </Stack>
            </ActionSheet.Body>
            {actions ? (
                <ActionSheet.Footer
                    display='block'
                    padding={3}
                    {...footerProps}
                >
                    <Stack direction='column' spacing={3}>
                        {actions.map((action, index) => (
                            <Button key={index} {...action} />
                        ))}
                    </Stack>
                </ActionSheet.Footer>
            ) : null}
        </ActionSheet>
    );
};

export default FullScreenActionSheet;
