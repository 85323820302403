import {
    Card,
    ComponentWidth,
    FormDataObjectType,
    Grid,
    TextareaSize,
    theme,
    useFormSaver,
} from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';
import includes from 'lodash/includes';
import noop from 'lodash/noop';
import Danger from '../../../../../components/Typography/Danger';
import { MealOptions, ServiceTypesKeys } from '../../../../../config/constants';
import { getDropdownOptionsByKey } from '../../../../../util/form/dropdown';
import {
    getColorFromServiceType,
    getServiceTitleLabel,
} from '../LibraryServiceFormatter';
import ServiceTitle from '../ServiceTitle';
import { ServiceFormPermissions, fieldNamesByServiceType } from '../helpers';
import {
    PaddedDropdown,
    PaddedMultipleDropdown,
    PaddedTextarea,
    StyledServiceFileInput,
} from './Form.components';
import ActionButtons from './Shared/ActionButtons';
import SaveToLibraryCheckbox from './Shared/SaveToLibraryCheckbox';
import { validationSchema } from './Shared/validationSchema';

type ServiceType = models.services.ServiceType;
type MealOption = keyof typeof MealOptions;

const DEFAULT_MEAL = {
    saveToLibrary: false,
    type: ServiceTypesKeys.MEAL,
};

interface MealCardFormProps {
    serviceToEdit?: models.services.SavedMeal;
    closeForm: () => void;
    updateItinerary: (data: any) => void;
    errorText?: string;
    permissions?: ServiceFormPermissions;
    disabledFields?: string[];
}

const MealCardForm = (props: MealCardFormProps): JSX.Element => {
    const {
        updateItinerary,
        serviceToEdit,
        disabledFields,
        closeForm,
        errorText,
        permissions,
    } = props;

    const isNew = !serviceToEdit;

    const defaultData = isNew ? DEFAULT_MEAL : serviceToEdit;

    const formSaver = useFormSaver({
        onSubmit: async (values) => {
            updateItinerary(values);
        },
        initialValues: defaultData as FormDataObjectType,
        validationSchema: {
            schema: validationSchema,
        },
    });

    const { formValues, handleFormSubmit, formState, formErrors } = formSaver;

    const handleFormChange = (event: React.SyntheticEvent): void => {
        const { value, name } = event.target as HTMLInputElement;
        formValues.set[name](value);
    };

    const handleCheckboxChange = (event: React.SyntheticEvent): void => {
        const { name } = event.target as HTMLInputElement;
        const isChecked = !formValues.get.nested(name);
        formValues.set.nested(name, isChecked);
    };

    const handleDropdownChangeEvent = (event, name, value): void => {
        const wrappedEvent = {
            ...event,
            target: { value, name },
        };
        handleFormChange(wrappedEvent);
    };

    const onRemoveAction = (): void => {
        formValues.set.nested('deleted', true);
        updateItinerary({
            ...formState,
            deleted: true,
        });
    };

    const { meal: fieldNames } = fieldNamesByServiceType;

    const serviceType = formValues.get[fieldNames.type] as ServiceType;

    return (
        <Card backgroundColor={theme.colors.neutral.white}>
            <ServiceTitle
                title={getServiceTitleLabel(serviceType)}
                color={getColorFromServiceType(serviceType)}
            />
            <PaddedDropdown
                name={fieldNames.mealType}
                label='Meal Type *'
                size={ComponentWidth.Medium}
                value={formValues.get[fieldNames.mealType] as string}
                onChange={handleDropdownChangeEvent}
                disabled={includes(disabledFields, fieldNames.mealType)}
                onSearch={noop}
            >
                {getDropdownOptionsByKey(`MealType`)}
            </PaddedDropdown>
            <PaddedMultipleDropdown
                name={fieldNames.options}
                label='Meal Options'
                size={ComponentWidth.Medium}
                value={
                    (formValues.get[fieldNames.options] || []) as MealOption[]
                }
                onChange={handleDropdownChangeEvent}
                disabled={includes(disabledFields, fieldNames.options)}
            >
                {getDropdownOptionsByKey(`MealOptions`)}
            </PaddedMultipleDropdown>
            <PaddedTextarea
                name={fieldNames.description}
                placeholder='Description'
                label='Description *'
                size={TextareaSize.Medium}
                value={formValues.get[fieldNames.description] as string}
                error={formErrors?.[fieldNames.description]}
                onChange={handleFormChange}
                expandable={true}
                disabled={includes(disabledFields, fieldNames.description)}
            />
            <StyledServiceFileInput
                className=''
                detail=''
                error=''
                key=''
                name={fieldNames.images}
                value={formValues.get[fieldNames.images]}
                onChange={handleFormChange}
                label={`Upload images`}
                maxFiles={`10`}
                disabled={includes(disabledFields, fieldNames.images)}
            />
            <SaveToLibraryCheckbox
                value={Boolean(formValues.get[fieldNames.saveToLibrary])}
                onChange={handleCheckboxChange}
                disabled={includes(disabledFields, fieldNames.saveToLibrary)}
            />
            {errorText ? (
                <Grid>
                    <Grid.Item columnSpan={12}>
                        <Danger>{errorText}</Danger>
                    </Grid.Item>
                </Grid>
            ) : null}
            <ActionButtons
                serviceType={serviceType}
                closeForm={closeForm}
                submitForm={() => handleFormSubmit(undefined)}
                onRemoveAction={onRemoveAction}
                isNewEntity={isNew}
                permissions={permissions}
            />
        </Card>
    );
};

export default MealCardForm;
