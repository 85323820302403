import { MouseEventHandler, useRef } from 'react';
import { useSelector } from 'react-redux';
import { noop } from 'lodash';
import { Grid, Hidden } from '@material-ui/core';
import { models } from '@trova-trip/trova-models';
import { Stack, useToast } from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import { PaddedColumn } from '../../../../../common/components/Itinerary.components';
import TabPageDescription from '../../../../../common/components/TabPageDescription';
import { usePrintPage } from '../../../../../common/hooks/usePrintPage';
import TripServicesList from '../../../../../host/components/TripServicesList/TripServicesList';
import TabDetailsInformation from '../../../../../../components/TabDetailsInformation/TabDetailsInformation';
import TripDays from '../../../../../../components/TripDays/TripDays';
import { ServicesByDayUIModel } from '../../../../../../interfaces/UiModels.types';
import Tab from '../../../../../../navigation/Tab';
import createDayRefName from '../../../../../../util/createDayRefName';
import useFormattedServices from '../../../../../../util/hooks/useFormattedServices';
import useGetServiceByDayRefIds from '../../../../../../util/hooks/useGetServiceByDayRefIds';
import { getTripDatesIfApplicable } from '../../../../../../util/ModelDataHelper';
import scrollToElement from '../../../../../../util/scrollToElement';
import ReviewTripTabStickyLayout from './ReviewTripTabStickyLayout';
import { useTrackingEvent } from '../../../../../../analytics/hooks';
import { ProfileStoreSelector } from '../../../../../../state/profile';
import { BaseTabProps } from '../Tabs.types';
import PrintableItineraryHeader from '../../../../../traveler/tabs/Itinerary/PrintableItineraryHeader/PrintableItineraryHeader';

export const ReviewTripTab: React.FC<BaseTabProps> = (props) => {
    const { hideNavigation, disabled } = props;
    const tripData = useSelector(
        (state: { userTrips: { current: models.trips.Trip } }) =>
            state.userTrips.current,
    );
    const { current: host } = useSelector(
        (state: ProfileStoreSelector) => state.profile,
    );

    const { servicesByDay, startDate } = tripData;

    const formattedServicesByDay: ServicesByDayUIModel = useFormattedServices(
        servicesByDay || [],
    );

    const tripDates = getTripDatesIfApplicable(startDate, servicesByDay);
    const dayItemIds = useGetServiceByDayRefIds(tripData);

    const scrollToDay = (dayIndex: number): void => {
        const elementId = createDayRefName(dayIndex);
        scrollToElement(elementId);
    };

    const { trackUserEvent } = useTrackingEvent();
    const toast = useToast();
    const dailyActivitiesRef = useRef(null);

    const showErrorToast = (error) =>
        toast({
            title: 'Something went wrong printing your itinerary. Please try again.',
            description: error,
            status: 'error',
            isClosable: true,
        });

    const documentTitle = `${tripData?.name}-${tripData?.destination}-${
        tripDates.length && tripDates[0]
    }.pdf`;

    const handlePrint: MouseEventHandler<HTMLButtonElement> | undefined =
        usePrintPage({
            ref: dailyActivitiesRef,
            documentTitle,
            onPrintError: showErrorToast,
            onAfterPrint: () =>
                trackUserEvent({
                    eventName: 'Host downloaded Itinerary PDF',
                    properties: {
                        userId: host?.id,
                        userStatus: host?.status,
                    },
                }),
        });

    return !servicesByDay ? (
        <Tab label='Itinerary' path='/itinerary' disabled />
    ) : (
        <Tab
            label='Itinerary'
            path='/itinerary'
            hideNavigation={hideNavigation}
            disabled={disabled}
        >
            <ReviewTripTabStickyLayout scrollToDay={scrollToDay}>
                <Grid container spacing={16}>
                    <Grid item xs={12} sm={10} md={10} lg={11}>
                        <Stack
                            direction='row'
                            justify='space-between'
                            align='center'
                            marginBottom={10}
                        >
                            <TabDetailsInformation title='Itinerary Overview' />
                            <Button
                                rightIcon='download'
                                variant='secondary'
                                size='sm'
                                backgroundColor='gray.100'
                                onClick={handlePrint}
                            >
                                Download PDF
                            </Button>
                        </Stack>

                        <TabPageDescription>
                            Below is your final itinerary.
                        </TabPageDescription>
                    </Grid>
                    <Grid container spacing={16}>
                        <Hidden mdDown>
                            <Grid item xs={4} sm='auto'>
                                <TripDays
                                    servicesByDay={formattedServicesByDay}
                                    tripDates={tripDates}
                                    dayItemIds={dayItemIds}
                                    scrollToDay={(dayIndex): void => {
                                        const elementId =
                                            createDayRefName(dayIndex);
                                        scrollToElement(elementId);
                                    }}
                                    cleanErrors={noop}
                                />
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} sm={10} lg={9}>
                            <PaddedColumn ref={dailyActivitiesRef}>
                                <PrintableItineraryHeader
                                    itinerary={tripData}
                                />
                                <TripServicesList
                                    tripServicesByDay={formattedServicesByDay}
                                    tripDates={tripDates}
                                />
                            </PaddedColumn>
                        </Grid>
                    </Grid>
                </Grid>
            </ReviewTripTabStickyLayout>
        </Tab>
    );
};

export default ReviewTripTab;
