import { Table } from '@trova-trip/trova-components/build/next';
import { Currency as CurrencyInfo } from '@trova-trip/country-data';
import { completeMissingThresholdsWithNull, Pricing } from '../utils';
import PriceCell from './PriceCell';
import TitleCell from './TitleCell';

interface PricingRowProps {
    pricing: Pricing;
    completeMissingCells?: boolean;
    travelerThresholds?: number[];
    justifyRight?: boolean;
    currencyInfo?: CurrencyInfo;
}

const PricingRow = (props: PricingRowProps) => {
    const {
        pricing,
        completeMissingCells = false,
        travelerThresholds,
        justifyRight = false,
        currencyInfo,
    } = props;

    const { value, label, subtitle } = pricing;

    const correctedValues =
        completeMissingCells && travelerThresholds
            ? completeMissingThresholdsWithNull(value, travelerThresholds)
            : value;

    return (
        <Table.Row>
            <TitleCell label={label} subtitle={subtitle} />
            {(correctedValues || []).map((threshold) => {
                return (
                    <PriceCell
                        key={`${threshold.numberOfTravelers}${threshold.pricePerTraveler}`}
                        price={threshold.pricePerTraveler}
                        currencyInfo={currencyInfo}
                        justifyRight={justifyRight}
                    />
                );
            })}
        </Table.Row>
    );
};

export default PricingRow;
