import { Stack, useReveal, useToast } from '@trova-trip/trova-components';
import { Button, Menu, Table } from '@trova-trip/trova-components/build/next';
import { useState } from 'react';
import useIsLargeScreen from '../../../../common/hooks/useIsLargeScreen';
import { DisplayInventoryItem } from '../../../../common/hooks/useItineraryInventory';
import DeleteItineraryInventoryConfirmDialog from './DeleteItineraryInventoryConfirmDialog';

type RowOption = {
    label: string;
    value: string;
    onClick: () => void;
};

type SelectAction = 'update' | 'delete' | 'duplicate';

type UseViewAllReturn = {
    onViewButtonClick: () => void;
    currentItems: DisplayInventoryItem[];
    isViewingAllItems: boolean;
    isViewButtonVisible: boolean;
};

const INITIAL_ITEMS_TO_SHOW = 25;

interface ItineraryInventoryTableProps {
    inventoryItems: DisplayInventoryItem[];
    isLoading: boolean;
    onItemSelect: (id: string, to: SelectAction) => void;
    onItemDelete: () => Promise<void> | void;
}

const MenuOptions = ({ options }: { options: RowOption[] }): JSX.Element => (
    <Menu
        options={options}
        variant='iconButton'
        justifyItems='flex-start'
        iconButtonProps={{
            variant: 'outlined',
            icon: 'more-horizontal',
            'aria-label': 'options',
        }}
    />
);

const EmptyCell = ({ isMenu = false }: { isMenu?: boolean }): JSX.Element =>
    isMenu ? <Table.Cell></Table.Cell> : <Table.Cell>-</Table.Cell>;

const MobileItineraryInventoryTable = ({
    displayInventoryItems,
    rowOptions,
}: {
    displayInventoryItems: DisplayInventoryItem[];
    rowOptions: (item: DisplayInventoryItem) => RowOption[];
}): JSX.Element => (
    <Table size='md' variant='striped'>
        <Table.Head>
            <Table.Row>
                <Table.Header>start date</Table.Header>
                <Table.Header>requests</Table.Header>
                <Table.Header>confirmed</Table.Header>
                <Table.Header></Table.Header>
            </Table.Row>
        </Table.Head>
        <Table.Body>
            {displayInventoryItems.length > 0 ? (
                displayInventoryItems.map((item) => (
                    <Table.Row key={item.id}>
                        <Table.Cell>{item.startDate}</Table.Cell>
                        <Table.Cell>{item.requests}</Table.Cell>
                        <Table.Cell>{item.confirmed}</Table.Cell>
                        <Table.Cell>
                            <MenuOptions options={rowOptions(item)} />
                        </Table.Cell>
                    </Table.Row>
                ))
            ) : (
                <Table.Row>
                    <EmptyCell />
                    <EmptyCell />
                    <EmptyCell />
                    <EmptyCell isMenu />
                </Table.Row>
            )}
        </Table.Body>
    </Table>
);

const DesktopItineraryInventoryTable = ({
    displayInventoryItems,
    rowOptions,
}: {
    displayInventoryItems: DisplayInventoryItem[];
    rowOptions: (item: DisplayInventoryItem) => RowOption[];
}): JSX.Element => (
    <Table size='md' variant='striped'>
        <Table.Head>
            <Table.Row>
                <Table.Header>start date</Table.Header>
                <Table.Header>package</Table.Header>
                <Table.Header>requests</Table.Header>
                <Table.Header>confirmed</Table.Header>
                <Table.Header>min price</Table.Header>
                <Table.Header>max price</Table.Header>
                <Table.Header></Table.Header>
            </Table.Row>
        </Table.Head>
        <Table.Body>
            {displayInventoryItems.length > 0 ? (
                displayInventoryItems.map((item) => (
                    <Table.Row key={item.id}>
                        <Table.Cell>{item.startDate}</Table.Cell>
                        <Table.Cell minWidth='20'>{item.package}</Table.Cell>
                        <Table.Cell>{item.requests}</Table.Cell>
                        <Table.Cell>{item.confirmed}</Table.Cell>
                        <Table.Cell>{item.minPrice}</Table.Cell>
                        <Table.Cell>{item.maxPrice}</Table.Cell>
                        <Table.Cell>
                            <MenuOptions options={rowOptions(item)} />
                        </Table.Cell>
                    </Table.Row>
                ))
            ) : (
                <Table.Row>
                    <EmptyCell />
                    <EmptyCell />
                    <EmptyCell />
                    <EmptyCell />
                    <EmptyCell />
                    <EmptyCell />
                    <EmptyCell isMenu />
                </Table.Row>
            )}
        </Table.Body>
    </Table>
);

const useViewAll = (
    inventoryItems: DisplayInventoryItem[],
): UseViewAllReturn => {
    const [isViewingAllItems, setIsViewingAllItems] = useState<boolean>(false);
    const isViewButtonVisible = inventoryItems.length > INITIAL_ITEMS_TO_SHOW;

    const currentItems = isViewingAllItems
        ? inventoryItems
        : inventoryItems.slice(0, INITIAL_ITEMS_TO_SHOW);

    const onViewButtonClick = (): void => setIsViewingAllItems((prev) => !prev);

    return {
        currentItems,
        onViewButtonClick,
        isViewingAllItems,
        isViewButtonVisible,
    };
};

const ItineraryInventoryTable = ({
    inventoryItems,
    isLoading,
    onItemDelete,
    onItemSelect,
}: ItineraryInventoryTableProps): JSX.Element => {
    const {
        isOpen: isDeleteDialogOpen,
        triggerClose: closeDeleteDialog,
        triggerOpen: openDeleteDialog,
    } = useReveal();

    const {
        currentItems,
        onViewButtonClick,
        isViewingAllItems,
        isViewButtonVisible,
    } = useViewAll(inventoryItems);

    const toast = useToast();

    const isMobile = !useIsLargeScreen({ includeTabletResolution: true });

    const rowOptions = (item: DisplayInventoryItem): RowOption[] => {
        return [
            {
                label: 'Edit',
                value: 'edit',
                onClick: (): void => onItemSelect(item.id, 'update'),
            },
            {
                label: 'Duplicate',
                value: 'duplicate',
                onClick: (): void => onItemSelect(item.id, 'duplicate'),
            },
            {
                label: 'Delete',
                value: 'delete',
                onClick: (): void => {
                    onItemSelect(item.id, 'delete');
                    openDeleteDialog();
                },
            },
        ];
    };

    const deleteItem = async (): Promise<void> => {
        try {
            await onItemDelete();
            closeDeleteDialog();
        } catch (e) {
            toast({
                title: `Cannot delete inventory item`,
                description: e.message,
                status: 'error',
                isClosable: true,
            });
            closeDeleteDialog();
        }
    };

    return (
        <>
            <Stack
                marginTop={4}
                direction='column'
                align='stretch'
                width='full'
            >
                {isMobile ? (
                    <MobileItineraryInventoryTable
                        displayInventoryItems={currentItems}
                        rowOptions={rowOptions}
                    />
                ) : (
                    <DesktopItineraryInventoryTable
                        displayInventoryItems={currentItems}
                        rowOptions={rowOptions}
                    />
                )}
                <DeleteItineraryInventoryConfirmDialog
                    onDelete={deleteItem}
                    closeDeleteDialog={closeDeleteDialog}
                    isDeleteDialogOpen={isDeleteDialogOpen}
                    isLoading={isLoading}
                />
            </Stack>
            {isViewButtonVisible ? (
                <Button
                    variant='tertiary'
                    rightIcon={isViewingAllItems ? 'arrow-up' : 'arrow-down'}
                    size='sm'
                    marginTop={4}
                    onClick={onViewButtonClick}
                    isFullWidth
                >
                    {isViewingAllItems ? 'View Less' : 'View All'}
                </Button>
            ) : null}
        </>
    );
};

export type { SelectAction };

export default ItineraryInventoryTable;
