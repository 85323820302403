import React, { useEffect, useMemo, useState } from 'react';
import { addDays, eachDayOfInterval, format, isEqual } from 'date-fns';
import {
    ActionSheet,
    BaseBox,
    Heading,
    SectionCard,
    Stack,
    TextInput,
    useBreakpoint,
    useReveal,
} from '@trova-trip/trova-components';
import {
    isInstantApproval,
    initializeCalendarDate,
    stripTimezone,
} from '../../tripRequestFormHelper';
import { Button } from '@trova-trip/trova-components/build/next';
import { FormInputNames } from '../../TripRequestForm.types';
import { startDateInput } from './TripRequestStartDateCard.styles';
import CalendarView from './CalendarView';
import { constants } from '@trova-trip/trova-models';

interface TripRequestStartDateCardProps {
    onChange: (
        event: string,
        name: string,
        isInstantApproval?: boolean,
    ) => void;
    startDate?: string;
    selectedDate?: string;
    disabledDates: string[];
    instantApprovalDates?: string[];
    tripLength: number;
    editMode: boolean;
    showInstantApproval: boolean;
    selectedPackage?: constants.itinerary.PackageLevel;
    enabledDates?: string[];
}

interface MobileWrapperProps {
    isDisabled: boolean;
    dateFormatted: string;
    isInstantApprovalSelected: boolean;
    children: React.ReactNode;
}

const title = 'Trip Start Date';

const MobileWrapper = ({
    isDisabled,
    dateFormatted,
    isInstantApprovalSelected,
    children,
}: MobileWrapperProps): JSX.Element => {
    const { isOpen, triggerOpen, triggerClose } = useReveal();

    const clickEvent = () => {
        !isDisabled && triggerOpen();
    };
    return (
        <>
            <BaseBox onClick={clickEvent} role='button'>
                <TextInput
                    isDisabled={isDisabled}
                    placeholder='Pick a date!'
                    onChange={() => {}}
                    name='tripDate'
                    value={dateFormatted}
                    rightAddon={{
                        icon: 'calendar',
                    }}
                    className={startDateInput(isInstantApprovalSelected)}
                />
            </BaseBox>
            <ActionSheet isOpen={isOpen} onClose={triggerClose}>
                <ActionSheet.Header>
                    <Heading as='h5'>{title}</Heading>
                </ActionSheet.Header>
                <ActionSheet.Body>{children}</ActionSheet.Body>
                <ActionSheet.Footer
                    justifyContent='right'
                    borderTop='1px solid'
                    borderColor='blueGray.200'
                >
                    <Button onClick={triggerClose} variant='secondary'>
                        Close
                    </Button>
                    <Button onClick={triggerClose} marginLeft={3}>
                        Apply
                    </Button>
                </ActionSheet.Footer>
            </ActionSheet>
        </>
    );
};

const TripRequestStartDateCard = ({
    startDate,
    selectedDate,
    onChange,
    instantApprovalDates = [],
    enabledDates,
    disabledDates,
    editMode,
    tripLength,
    showInstantApproval,
    selectedPackage,
}: TripRequestStartDateCardProps): JSX.Element => {
    const [date, setDate] = useState<Date | undefined>(
        initializeCalendarDate(selectedDate, editMode),
    );

    const isLargeScreen = useBreakpoint('sm');

    useEffect(() => {
        if (!selectedDate || !date) {
            return;
        }
        const stripTimezoneSelectedDate = stripTimezone(selectedDate);
        if (!isEqual(date, stripTimezoneSelectedDate)) {
            setDate(stripTimezoneSelectedDate);
        }
    }, [selectedDate, selectedPackage]);

    const instantApprovalFormatted = useMemo(() => {
        // do not create the instant approval dates if the Itinerary is not autopriced
        // even if the dates exists, in order to render instant approval both conditions must meet,
        if (!showInstantApproval) {
            return [];
        }

        return instantApprovalDates.map((dateString: string) => {
            const date = stripTimezone(dateString);

            return date;
        });
    }, [instantApprovalDates]);

    const tripDays = useMemo(() => {
        if (!date) {
            return [];
        }
        return eachDayOfInterval({
            start: date,
            end: addDays(date, tripLength - 1),
        });
    }, [date, tripLength]);

    const disabledDatesFormatted = useMemo(() => {
        return disabledDates.map((dateString: string) =>
            stripTimezone(dateString),
        );
    }, [disabledDates]);

    const enabledDatesFormatted = useMemo(() => {
        return enabledDates?.map((dateString: string) =>
            stripTimezone(dateString),
        );
    }, [enabledDates]);

    const dateFormatted = useMemo(() => {
        return date ? format(date, 'MM/dd/yyyy') : '';
    }, [date]);

    const isInstantApprovalSelected = useMemo(
        () =>
            date ? isInstantApproval(instantApprovalFormatted, date) : false,
        [date, instantApprovalFormatted],
    );

    const dayModifiers = {
        instantApprovalDates: instantApprovalFormatted,
        tripDays,
    };

    const dayModifiersTemplate = {
        instantApprovalDates: {
            color: 'teal.trova',
            selected: {
                color: 'neutral.white',
                backgroundColor: 'teal.trova',
            },
            hover: {
                color: 'teal.900',
                backgroundColor: 'teal.200',
            },
        },
        tripDays: {
            color: isInstantApprovalSelected ? 'teal.900' : 'red.trova',
            backgroundColor: isInstantApprovalSelected ? 'teal.200' : 'red.200',
            hover: {
                color: isInstantApprovalSelected ? 'teal.900' : 'red.trova',
                backgroundColor: isInstantApprovalSelected
                    ? 'teal.200'
                    : 'red.200',
            },
            selected: {
                color: isInstantApprovalSelected ? 'teal.100' : 'red.100',
                backgroundColor: isInstantApprovalSelected
                    ? 'teal.trova'
                    : 'red.trova',
            },
            disabled: {
                color: isInstantApprovalSelected ? 'teal.900' : 'red.trova',
                backgroundColor: isInstantApprovalSelected
                    ? 'teal.200'
                    : 'red.200',
            },
        },
    };

    const onDateChange = (date: Date) => {
        if (date) {
            const instantApprovalStatus = isInstantApproval(
                instantApprovalFormatted,
                date,
            );
            setDate(date);
            onChange(
                date.toString(),
                FormInputNames.START_DATE,
                instantApprovalStatus,
            );
        }
    };

    const calendarProps = {
        editMode,
        date,
        startDate: startDate ? stripTimezone(startDate) : new Date(),
        dayModifiers,
        dayModifiersTemplate,
        onChange: onDateChange,
        disabledDates: disabledDatesFormatted,
        enabledDates: enabledDatesFormatted,
        isLargeScreen,
        showInstantApproval,
        tripLength,
    };

    return (
        <SectionCard size='lg' title={editMode ? title : 'Selected Trip Dates'}>
            <Stack
                marginTop={{ base: 2, sm: 6 }}
                width='full'
                align='stretch'
                direction='column'
            >
                {!isLargeScreen ? (
                    <MobileWrapper
                        isDisabled={!editMode}
                        dateFormatted={dateFormatted}
                        isInstantApprovalSelected={isInstantApprovalSelected}
                    >
                        <CalendarView {...calendarProps} />
                    </MobileWrapper>
                ) : (
                    <CalendarView {...calendarProps} />
                )}
            </Stack>
        </SectionCard>
    );
};

export default TripRequestStartDateCard;
