import React from 'react';
import { analytics } from '@trova-trip/trova-common';
import { WithAnalyticsProps } from './analytics.types';

function withAnalytics<T extends WithAnalyticsProps = WithAnalyticsProps>(
    WrappedComponent: React.ComponentType<T>,
): React.ForwardRefExoticComponent<T> {
    const displayName =
        WrappedComponent.displayName || WrappedComponent.name || 'Component';

    const ComponentWithAnalytics = React.forwardRef(
        (props: any, ref: any): JSX.Element => {
            const newProps = { ...props };

            if (props.analyticsData?.type && props[props.analyticsData.type]) {
                newProps[props.analyticsData.type] = (...args: any): any => {
                    analytics.trackEvent({
                        eventName: props.analyticsData.eventName,
                        properties: props.analyticsData.properties,
                        context: props.analyticsData.context,
                    });
                    props[props.analyticsData.type](...args);
                };
            }

            return <WrappedComponent ref={ref} {...newProps} />;
        },
    );

    ComponentWithAnalytics.displayName = `withAnalytics(${displayName})`;

    return ComponentWithAnalytics;
}

export { withAnalytics as default };
