import { StepItem as StepperStepItem } from '@trova-trip/trova-components';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import { ComponentProps } from 'react';
import { ValidationError } from '../../utils/formErrors.utils';
import Bio, { BioProps, isValidBioData } from './steps/Bio';
import Congrats from './steps/Congrats';
import Interests, { InterestsProps } from './steps/Interests';
import { SocialMedia } from './steps/SocialMedia';
import Welcome from './steps/Welcome';
import { STEP_NAME, User } from './types';
import { SocialMediaProps } from './steps/SocialMedia/SocialMedia';
import { getValidationSchemaForStep } from './onboardingStepperErrors.util';

export type StepItem = StepperStepItem & {
    name: STEP_NAME;
};

interface OnboardingStepArgs<T = Record<string, never>> {
    stepName: STEP_NAME;
    stepState: T;
    setStepState: (value: T) => void;
    errors?: ValidationError;
}

interface WelcomeStep
    extends OnboardingStepArgs<ComponentProps<typeof Welcome>['profile']> {
    stepName: STEP_NAME.WELCOME;
}

interface SocialsStep
    extends OnboardingStepArgs<ComponentProps<typeof SocialMedia>['socials']> {
    stepName: STEP_NAME.SOCIALS;
    phyllo: SocialMediaProps['phyllo'];
}

interface InterestsStep
    extends OnboardingStepArgs<ComponentProps<typeof Interests>['interests']> {
    stepName: STEP_NAME.INTERESTS;
}

interface BioStep
    extends OnboardingStepArgs<ComponentProps<typeof Bio>['bio']> {
    stepName: STEP_NAME.BIO;
}

interface CongratsStep extends Partial<OnboardingStepArgs> {
    stepName: STEP_NAME.CONGRATS;
}

function createOnboardingStep(
    args: WelcomeStep | SocialsStep | InterestsStep | BioStep | CongratsStep,
): StepItem {
    const { stepName, stepState, setStepState } = args;

    switch (stepName) {
        case STEP_NAME.WELCOME:
            return createWelcomeStep(stepState);

        case STEP_NAME.INTERESTS:
            return createInterestsStep({
                interests: stepState,
                setInterests: setStepState,
            });

        case STEP_NAME.SOCIALS:
            return createSocialsStep({
                socials: stepState,
                onUpdateSocials: setStepState,
                errors: args.errors,
                phyllo: (args as SocialsStep).phyllo,
            });

        case STEP_NAME.BIO:
            return createBioStep({
                bio: stepState,
                setBio: setStepState,
            });

        case STEP_NAME.CONGRATS:
            return createCongratsStep();

        default:
            console.error('Invalid step name');
            return null as unknown as StepItem;
    }
}

const createCongratsStep = (): StepItem => {
    return {
        name: STEP_NAME.CONGRATS,
        element: <Congrats />,
        showNavigationBar: false,
        nextButton: {
            text: 'Start Surveying',
        },
    };
};

const createInterestsStep = ({
    interests,
    setInterests,
}: InterestsProps): StepItem => {
    return {
        name: STEP_NAME.INTERESTS,
        element: (
            <Interests interests={interests} setInterests={setInterests} />
        ),
        label: 'Interests',
        nextButton: {
            isDisabled: interests.length === 0,
        },
    };
};

const createWelcomeStep = (profile: User): StepItem => {
    return {
        name: STEP_NAME.WELCOME,
        element: <Welcome profile={profile} />,
        showNavigationBar: false,
        nextButton: {
            text: 'Get Started',
        },
    };
};

const createSocialsStep = (props: SocialMediaProps): StepItem => {
    const isNextButtonEnabled = getValidationSchemaForStep(
        STEP_NAME.SOCIALS,
    )?.isValidSync(props.socials);

    return {
        name: STEP_NAME.SOCIALS,
        element: <SocialMedia {...props} />,
        label: 'Socials',
        nextButton: {
            isDisabled: !isNextButtonEnabled,
            text: 'Next',
        },
    };
};

const createBioStep = (props: BioProps): StepItem => {
    const { bio } = props;

    const stepIsEmpty = every(bio, isEmpty);
    const stepIsComplete = !stepIsEmpty && isValidBioData(bio);

    return {
        name: STEP_NAME.BIO,
        element: <Bio {...props} />,
        label: 'Bio',
        nextButton: {
            isDisabled: !stepIsComplete,
            text: 'Complete',
        },
        skipButton: {
            isDisabled: false,
            text: 'Skip and Complete',
        },
    };
};

export default createOnboardingStep;
