import { Stack, Text } from '@trova-trip/trova-components';
import { Card, Icon } from '@trova-trip/trova-components/build/next';

const labels = {
    title: {
        upcomingTrips: 'You have no upcoming trips',
        importantDates: 'You have no important dates',
    },
    subtitle: 'Browse itineraries to request another trip',
};

interface WidgetEmptyCardProps {
    type: keyof typeof labels.title;
}

const WidgetEmptyCard = (props: WidgetEmptyCardProps) => {
    const { type } = props;
    const { title, subtitle } = labels;

    return (
        <Card backgroundColor='neutral.white' paddingX={4} padding={0}>
            <Stack
                spacing={{ base: 4, md: 2 }}
                direction={{ base: 'row', md: 'column' }}
                justify='center'
                wrap='nowrap'
                align={{ base: 'flex-start', md: 'center' }}
                paddingY={{ base: 3, md: 5 }}
            >
                <Icon as='calendar' size='md' color='blueGray.650' />
                <Stack
                    direction='column'
                    align={{ base: 'flex-start', md: 'center' }}
                >
                    <Text textAlign='center' fontSize='lg' fontWeight='bold'>
                        {title[type]}
                    </Text>
                    <Text
                        textAlign={{ base: 'start', md: 'center' }}
                        fontSize='base'
                        color='blueGray.500'
                    >
                        {subtitle}
                    </Text>
                </Stack>
            </Stack>
        </Card>
    );
};

export default WidgetEmptyCard;
