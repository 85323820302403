import { constants, models } from '@trova-trip/trova-models';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import flatMap from 'lodash/flatMap';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import React from 'react';
import {
    ServiceFormPermissions,
    fieldNamesByServiceType,
} from '../../applications/common/components/services/helpers';
import {
    filterViewOnlyServicesByDay,
    insertServiceAndSort,
    isOperator,
    isTripClosed,
    isTripCreated,
    orderServicesByDay,
} from '../../applications/common/helpers';
import { useSelector } from '../../state/hooks';
import { services, updateServiceOnTrip } from '../../state/services';
import { userItinerary } from '../../state/userItinerary';
import { userTrips } from '../../state/userTrips';

type Service = models.services.Service;
type ServicesByDayType = Array<Array<any>>;

const { OPERATOR_SERVICE_EDITABLE_KEYS } = constants.services;

enum ACTIONS {
    SHOW_FORM = 'showForm',
    SHOW_EDIT_FORM = 'showEditForm',
    CURRENT_SERVICE = 'currentService',
    CURRENT_SERVICE_ITEM = 'currentServiceItem',
    DAY_SELECTED = 'daySelected',
    ORDER_SELECTED = 'orderSelected',
    ERROR_TEXT = 'errorText',
    CLOSE_FORM = 'closeForm',
    HANDLE_DROP = 'handleDrop',
}

const reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SHOW_FORM:
            return { ...state, showForm: action.payload };
        case ACTIONS.CURRENT_SERVICE:
            return { ...state, currentService: action.payload };
        case ACTIONS.CURRENT_SERVICE_ITEM:
            return { ...state, currentServiceItem: action.payload };
        case ACTIONS.DAY_SELECTED:
            return { ...state, daySelected: action.payload };
        case ACTIONS.ORDER_SELECTED:
            return { ...state, orderSelected: action.payload };
        case ACTIONS.ERROR_TEXT:
            return { ...state, errorText: action.payload };
        case ACTIONS.CLOSE_FORM:
        case ACTIONS.SHOW_EDIT_FORM:
        case ACTIONS.HANDLE_DROP:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
};

const initialState = {
    showForm: false,
    showEditForm: false,
    currentService: null,
    currentServiceItem: null,
    daySelected: 0,
    orderSelected: 0,
    errorText: undefined,
};

const canTripBeSaved = (trip?: models.trips.Trip) => {
    if (!trip) return false;
    return (
        (isTripCreated(trip) || isTripClosed(trip)) &&
        !trip.operatorTechnicalItineraryApproved &&
        !trip.adminTechnicalItineraryApproved
    );
};

const useDailyActivities = (
    servicesByDay: ServicesByDayType,
    model: string,
    id: string,
) => {
    const [state, dispatch] = React.useReducer(reducer, {
        ...initialState,
    });
    const dispatchWrapper = (type: ACTIONS, payload) =>
        dispatch({ type, payload });

    const isTripModel = model === 'trip';
    const isItineraryModel = model === 'itinerary';

    const { createRecord: createService, updateRecord: updateService } =
        services.useDispatch();

    const { updateRecord: updateEntity, getRecord: getEntity } = isTripModel
        ? userTrips.useDispatch()
        : userItinerary.useDispatch();

    const { trip, user } = useSelector((state) => ({
        trip: state.userTrips.current,
        user: state.profile.current,
    }));

    const closeForm = () => {
        // this results in many renders
        // either bacth or create a single action for it
        dispatchWrapper(ACTIONS.CLOSE_FORM, {
            errorText: null,
            showForm: false,
            showEditForm: false,
            currentService: null,
            currentServiceItem: null,
        });
    };

    const updateServiceOnEntity = (servicesByDay: ServicesByDayType) => {
        updateEntity(
            id,
            {
                servicesByDay: filterViewOnlyServicesByDay(servicesByDay),
            },
            {
                successCallback: () => {
                    closeForm();
                },
                errorCallback: (error) => {
                    dispatchWrapper(ACTIONS.ERROR_TEXT, `Error - ${error}`);
                },
            },
        );
    };

    const addServiceTypeWrapper = (data: any, duration: number) => {
        const { type } = data;
        createService(data, type, {
            successCallback: (newService) => {
                const orderedServicesByDay = insertServiceAndSort(
                    servicesByDay,
                    state.daySelected,
                    newService,
                    duration,
                );
                updateServiceOnEntity(orderedServicesByDay);
                dispatchWrapper(ACTIONS.ERROR_TEXT, undefined);
            },
            errorCallback: (error) => {
                dispatchWrapper(ACTIONS.ERROR_TEXT, `Error - ${error}`);
            },
        });
    };

    // conditionally update the service on itinerary
    // only if duration is different or the service was deleted
    const shouldUpdateServiceOnEntity = (
        duration: number,
        deleted: boolean,
    ) => {
        const { daySelected, orderSelected } = state;
        const currentDayDuration =
            servicesByDay[daySelected][orderSelected]?.duration;

        return (duration && duration !== currentDayDuration) || deleted;
    };

    const updateServiceTypeWrapper = (data: Service, duration: number) => {
        const { type, _id: serviceId, deleted } = data;
        const successCallback = (updatedService: Service): void => {
            const shouldUpdateOnEntity = shouldUpdateServiceOnEntity(
                duration,
                deleted,
            );

            if (shouldUpdateOnEntity) {
                const clonedServicesByDay = cloneDeep(servicesByDay);
                const currentDay =
                    clonedServicesByDay[state?.daySelected][
                        state?.orderSelected
                    ];
                currentDay.duration = duration;
                currentDay.service = updatedService;
                updateServiceOnEntity(clonedServicesByDay);

                return;
            }

            getEntity(id);
            closeForm();
            dispatchWrapper(ACTIONS.ERROR_TEXT, undefined);
        };
        const errorCallback = (error: Error): void => {
            dispatchWrapper(ACTIONS.ERROR_TEXT, `Error - ${error}`);
        };
        if (isTripModel) {
            return updateServiceOnTrip(serviceId as string, type, data, id)
                .then(successCallback)
                .catch(errorCallback);
        }
        updateService(serviceId, type, data, {
            successCallback,
            errorCallback,
        });
    };

    const handleDropAndDropCards = (
        destinationDay: number,
        serviceIndexToReplace: number,
        itemDragged: { service: { orderOrigin: number; dayOrigin: number } },
    ) => {
        const {
            service: { orderOrigin, dayOrigin },
        } = itemDragged;

        if (
            destinationDay !== dayOrigin ||
            serviceIndexToReplace !== orderOrigin
        ) {
            const serviceMoved = servicesByDay[dayOrigin][orderOrigin];

            servicesByDay[dayOrigin].splice(orderOrigin, 1);

            servicesByDay[destinationDay].splice(
                serviceIndexToReplace,
                0,
                serviceMoved,
            );

            const orderedServicesByDay = orderServicesByDay(servicesByDay);

            updateEntity(id, {
                servicesByDay:
                    filterViewOnlyServicesByDay(orderedServicesByDay),
            });
        }
    };

    const handleDrop = (
        index: number,
        item: { service: any; serviceType: string },
    ) => {
        dispatchWrapper(ACTIONS.CURRENT_SERVICE, item.serviceType);
        let newService;
        if (item.service) {
            // eslint-disable-next-line
            newService = { ...item.service };
            delete newService._id;
        }
        dispatchWrapper(ACTIONS.HANDLE_DROP, {
            showForm: true,
            daySelected: index,
            currentServiceItem: newService,
        });
    };

    const shouldShowCreateForm = (day: number) =>
        state.showForm && state.daySelected === day;

    const openEditForm = (day: number, order: number, type: string) => {
        dispatchWrapper(ACTIONS.SHOW_EDIT_FORM, {
            showEditForm: true,
            daySelected: day,
            orderSelected: order,
            currentService: type,
        });
    };

    const shouldShowEditingForm = (day: number, order: number) =>
        state.showEditForm &&
        state.daySelected === day &&
        state.orderSelected === order;

    const getPermissions = (): ServiceFormPermissions => {
        const defaultPermissions: ServiceFormPermissions = {
            canSave: true,
            canRemove: true,
        };

        if (!isTripModel || !trip || !user || !isOperator(user)) {
            return defaultPermissions;
        }

        const canRemove = isTripCreated(trip);
        const canSave = canTripBeSaved(trip);

        return { canSave, canRemove };
    };

    /**
     * Returns an array containing disabled fields names of the trip services.
     * - For non-operator users, only disable workshop related fields
     * - For operator users:
     *   - Trip `created` and no technical checks - no fields disabled
     *   - Trip `closed` and no technical checks - some fields disabled
     *   - At least one technical check - all fields disabled
     *   - Other statuses - all fields disabled
     *
     * @returns {string[]} - Array of disabled field names.
     */
    const getTripModelDisabledField = (): string[] => {
        if (!isOperator(user)) {
            return [
                fieldNamesByServiceType.workshop.hoursAvailable,
                fieldNamesByServiceType.workshop.hoursRequested,
            ];
        }

        const allFieldNames = flatMap(fieldNamesByServiceType, (serviceType) =>
            values(serviceType),
        );

        if (!canTripBeSaved(trip)) {
            return allFieldNames;
        }

        const disabledFields = isTripClosed(trip)
            ? difference(allFieldNames, OPERATOR_SERVICE_EDITABLE_KEYS)
            : [];

        return uniq(disabledFields);
    };

    /**
     * Returns an array of disabled fields names depending on the
     * current model.
     *
     * @returns {string[]} - Array of disabled field names.
     */
    const getDisabledFields = (): string[] => {
        if (isItineraryModel) {
            // The itinerary model shouldn't have any disabled field at any time.
            return [];
        }

        if (isTripModel) {
            return getTripModelDisabledField();
        }

        return [];
    };

    return {
        handleDropAndDropCards,
        currentService: state.currentService,
        currentServiceItem: state.currentServiceItem,
        handleDrop,
        closeForm,
        shouldShowCreateForm,
        openForm: openEditForm,
        daySelected: state.daySelected,
        orderSelected: state.orderSelected,
        shouldShowEditingForm,
        addServiceTypeWrapper,
        updateServiceTypeWrapper,
        errorText: state.errorText,
        permissions: getPermissions(),
        disabledFields: getDisabledFields(),
    };
};

export default useDailyActivities;
