import { Socials, SocialKeys } from './SocialMediaAccounts.types';
import { IconProps } from '@trova-trip/trova-components/build/next';

const socialMediaIcons: Record<SocialKeys, IconProps['as']> = {
    facebook: 'trovaFacebook',
    instagram: 'trovaInstagram',
    tiktok: 'trovaTikTok',
    youtube: 'trovaYoutube',
    personalSite: 'trovaWebsite',
};

const socialDomains: Socials = {
    instagram: 'www.instagram.com/',
    tiktok: 'www.tiktok.com/@',
    facebook: 'www.facebook.com/',
    youtube: 'www.youtube.com/@',
    personalSite: '',
};

const socialInputLabels: Socials = {
    ...socialDomains,
    personalSite: 'https://',
};

const socialLabels = {
    instagram: 'Instagram',
    tiktok: 'TikTok',
    facebook: 'Facebook',
    youtube: 'YouTube',
};

const socialArray = Object.keys(socialDomains);

export {
    socialMediaIcons,
    socialArray,
    socialDomains,
    socialInputLabels,
    socialLabels,
};
