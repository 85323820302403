import { useCallback, useEffect, useMemo } from 'react';
import { models } from '@trova-trip/trova-models';
import { useSelector } from 'react-redux';
import { userHostsRecommendations } from '../../../state/itineraryRecommendations';
import type {
    HostItineraryRecommendations,
    ItineraryRecommendationHostSelector,
    UserHostsRecommendations,
} from '../../../state/itineraryRecommendations';
import TrovaTripAvatar from '../../../assets/img/trova-trip-avatar.png';

export type ItineraryRecommendationOption =
    models.itineraryRecommendations.ItineraryRecommendationOptionOutput;

export type UseHostRecommendationsReturn = {
    firstTime?: boolean;
    fetching?: boolean;
    recommendations: HostItineraryRecommendations | undefined;
    getItineraryRecommendation: (
        itineraryId: string,
    ) => ItineraryRecommendationOption | undefined;
};

const getDefaultRecommendedUser =
    (): HostItineraryRecommendations['recommendedBy'] => ({
        id: 'trova',
        firstName: 'TrovaTrip',
        lastName: '',
        profilePictureUrl: TrovaTripAvatar,
    });

/**
 * check if the recommendations options has the active flag turned on
 * @param recommendation
 * @returns booleans
 */
const areItinerariesActive = (
    recommendation: HostItineraryRecommendations,
): boolean =>
    recommendation?.options?.some(
        ({ itinerary }: ItineraryRecommendationOption) => itinerary?.active,
    );

/**
 * set the recommendedBy as a default trova value if and name if the trovarecommended is true
 * @returns  HostItineraryRecommendations;
 */
const formatRecommendation = (
    recommendation: HostItineraryRecommendations,
): HostItineraryRecommendations => {
    const formattedUser = recommendation?.trovaRecommended
        ? getDefaultRecommendedUser()
        : recommendation?.recommendedBy;

    return {
        ...recommendation,
        recommendedBy: formattedUser,
    };
};

const useHostRecommendations = (): UseHostRecommendationsReturn => {
    const stateRecommendations = useSelector(
        (
            state: ItineraryRecommendationHostSelector,
        ): UserHostsRecommendations => {
            return state?.userHostsRecommendations;
        },
    );

    const { listRecords: getRecommendations, clearFirstTime } =
        userHostsRecommendations.useDispatch();

    const recommendations = useMemo(() => {
        const currentRecommendation = (stateRecommendations?.list?.[0] ||
            {}) as HostItineraryRecommendations;

        if (currentRecommendation?.active) {
            const hasActiveItineraries = areItinerariesActive(
                currentRecommendation,
            );

            // if the recommendation is active and has active itineraries then we can show them
            if (hasActiveItineraries) {
                const formattedRecommendation = formatRecommendation(
                    currentRecommendation,
                );
                return formattedRecommendation;
            }
        }

        return undefined;
    }, [stateRecommendations]);

    const getItineraryRecommendation = useCallback(
        (itineraryId: string) => {
            if (recommendations && recommendations?.options) {
                return recommendations.options.find(
                    ({ itinerary }: ItineraryRecommendationOption) =>
                        itinerary &&
                        itinerary?.id === itineraryId &&
                        itinerary?.active,
                );
            }

            return undefined;
        },
        [recommendations],
    );

    useEffect(() => {
        getRecommendations();
        clearFirstTime();
    }, []);

    return {
        firstTime: stateRecommendations.firstTime,
        fetching: stateRecommendations.fetching,
        recommendations,
        getItineraryRecommendation,
    };
};

export default useHostRecommendations;
