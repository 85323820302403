import {
    BaseBox,
    FormSaver,
    Stack,
    Text,
    useFormSaver,
    useToast,
} from '@trova-trip/trova-components';
import { pick } from 'lodash';
import { useContext } from 'react';
import * as yup from 'yup';
import img from '../../../../../../../../assets/img/undraw/profile-data.png';
import useProfileFormAPI from '../../../../../../../common/products/account/hooks/useProfileFormAPI';
import ProfileImageUploader from '../../../../../../../common/components/ProfileImageUploader';
import { OnboardingStepperContext } from '../../OnboardingStepperContext';
import { AnimatedImage, FormControl, NextButton } from '../../shared';
import BaseContent from '../BaseContent';
import ProfileTextarea from './ProfileTextarea';

const ProfileValidationSchema = yup.object().shape(
    {
        profilePictureUrl: yup.string().optional(),
        bio: yup.string().when('bio', (bio) => {
            if (bio) {
                return yup
                    .string()
                    .min(100, 'Minimum 100 characters required')
                    .max(350, 'Maximum 350 characters allowed');
            } else {
                return yup.string().optional();
            }
        }),
    },
    [['bio', 'bio']],
);

const ProfileContent = (): JSX.Element => {
    const toast = useToast();

    const {
        handleSubmit: submitForm,
        initialValues,
        isLoading,
    } = useProfileFormAPI();

    const { goNextStep } = useContext(OnboardingStepperContext);

    const handleSubmit = async (data): Promise<void> => {
        const response = await submitForm(data);
        const isSuccess = !!response.profile;
        isSuccess ? handleSuccessfulSubmit() : handleErrorSubmit();
    };

    const handleSuccessfulSubmit = (): void => {
        toast({
            title: 'Profile saved',
            description: 'Your profile was updated successfully.',
            status: 'success',
            isClosable: true,
        });
        goNextStep();
    };

    const handleErrorSubmit = (): void => {
        toast({
            title: 'Something went wrong',
            description:
                'There was an error saving your profile. Please try again.',
            status: 'error',
            isClosable: true,
        });
    };

    const formSaver = useFormSaver({
        onSubmit: handleSubmit,
        validationSchema: {
            schema: ProfileValidationSchema,
        },
        initialValues: pick(initialValues.profile, [
            'profilePictureUrl',
            'bio',
        ]),
    });

    return (
        <BaseContent
            title='Finish Your Profile'
            leftContent={
                <AnimatedImage
                    src={img}
                    alt='Profile data'
                    marginLeft={{ xl: 16 }}
                    maxWidth={{
                        base: '10.5rem',
                        md: '21.25rem',
                        xl: '33.75rem',
                    }}
                />
            }
            rightContent={
                <BaseBox marginBottom={5} marginRight={{ xl: 20 }}>
                    <Stack direction='column' align='stretch' spacing={6}>
                        <Stack direction='column' align='stretch' spacing={4}>
                            <Text>
                                Please add your profile picture and bio before
                                scheduling your launch date. This information
                                introduces you to potential travelers on your
                                trip page.
                            </Text>
                        </Stack>
                        <FormSaver
                            name='profile-form'
                            onSubmit={formSaver.handleFormSubmit}
                        >
                            <Stack
                                direction='column'
                                align='stretch'
                                spacing={6}
                            >
                                <FormControl
                                    label='Profile Picture'
                                    field={
                                        <ProfileImageUploader
                                            name='profilePictureUrl'
                                            value={
                                                formSaver.formValues.get
                                                    .profilePictureUrl as string
                                            }
                                            onChange={
                                                formSaver.formValues.set
                                                    .profilePictureUrl
                                            }
                                        />
                                    }
                                />
                                <FormControl
                                    label='Profile Bio'
                                    field={
                                        <ProfileTextarea
                                            name='bio'
                                            placeholder='Introduce yourself to your travelers...'
                                            value={
                                                formSaver.formValues.get
                                                    .bio as string
                                            }
                                            error={formSaver.formErrors?.bio}
                                            onChange={
                                                formSaver.formValues.set.bio
                                            }
                                        />
                                    }
                                />
                            </Stack>
                        </FormSaver>
                    </Stack>
                </BaseBox>
            }
            footerContent={
                <NextButton
                    type='submit'
                    onClick={(): void => formSaver.handleFormSubmit(undefined)}
                    isDisabled={isLoading}
                />
            }
        />
    );
};

export default ProfileContent;
