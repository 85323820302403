import { coreUtils } from '@trova-trip/trova-common';
import { constants, models } from '@trova-trip/trova-models';
import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import every from 'lodash/every';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import { isTransfersEnabled } from '../../../config/constants';
import { applicationRootPath as rootPath } from '../../../navigation/NavigationContext';
import {
    BookedGroundTransferJourney,
    BookedTransferJourneysByStage,
    GroundTransferAddOn,
    InsuranceStatus,
    Journey,
    JourneyStage,
    Quote,
    TransferDetail,
    TransferNotAvailableReason,
    TravelerType,
    UserTripsTraveler,
} from '../../../state/features/transfer/types';
import { isTransferDetailCompleteOrWithoutQuote } from '../../../state/features/transfer/utils';
import { TransferTabName, paths } from '../products/transfer/config';
import { SelectableDateRange } from '../products/transfer/types/travelDetail';
import {
    doesBookingHaveInsurance,
    doesBookingHavePendingRefund,
    getGroundTransferIdsFromBooking,
    hasAddOnByTiming,
} from './booking';
import {
    getGroundTransferIdsFromTrip,
    getTripRelatedDates,
    isTripComplete,
} from './trip';
import { isHost, isSecondaryUser, isTraveler } from './user';

type BaseItinerary = models.itineraries.BaseItinerary;
type BaseUser = models.users.BaseUser;
type SavedBooking = models.bookings.SavedBooking;
type Trip = models.trips.Trip;
type Service = models.services.Service;
type UserGroupName = models.users.ValidGroupNames;
type JourneyStatus = models.groundTransfers.Journey['status'];
type GroundTransfer = models.groundTransfers.GroundTransfer;
type AirportTransfer = models.services.AirportTransfer;
type Itinerary = models.itineraries.Itinerary;
type HostGroundTransfer = models.trips.HostGroundTransfer;
type GroundTransferBookingStatus =
    constants.groundTransfers.GroundTransferBookingStatus;

const { AddOnTypes } = constants.bookings;
const { BookingStatuses } = constants.bookings;
const { getBookingStatus } = coreUtils.bookingUtils;
const { Group } = constants.user;
const { getZeroUTCResetDateObj } = coreUtils.dateUtils;
const { JourneyStatus, JourneyType, GroundTransferBookingStatus } =
    constants.groundTransfers;
const { ServiceTiming, ServiceType } = constants.services;
const { hasTripStarted } = coreUtils.tripUtils;

const getTransferPath = (
    tripId: string,
    userGroup: UserGroupName,
    tab: TransferTabName = 'root',
): string => {
    // Only users who are admins, hosts or travelers
    // are allowed to navigate to a transfer path.
    const validGroups: UserGroupName[] = [
        Group.ADMIN,
        Group.HOST,
        Group.TRAVELER,
    ];
    if (!validGroups.includes(userGroup)) {
        return rootPath;
    }
    const productPath = paths.product.replace(':tripId', tripId);
    const tabPath = paths.tab[tab];
    return `${rootPath}/${userGroup}${productPath}${tabPath}`;
};

/**
 * Calculates the selectable start and end dates for a transfer stage. For arrival, a week before from the start date,
 * to the start date is selectable. For departure, a week after the end date, to the end date is selectable. In case
 * the person doing the booking is a traveler, we need to check if they have pre and/or post accommodations booked
 * to account for those potentially extra days.
 *
 * @param stage - Could be either 'arrival' or 'departure'
 * @param startTripDate - This date should be localized to the system's timezone as it would be used for date pickers
 * @param tripDuration - In days
 * @param bookingAddOns - This parameter would be populated only in the context of the Traveler Portal
 *
 * @returns An array containing the selectable start and end dates or an empty array if no dates are available.
 */
const getTransferStageSelectableDates = (
    stage: JourneyStage,
    startTripDate: Date | undefined,
    tripDuration: number,
    bookingAddOns?: SavedBooking['addOns'],
): SelectableDateRange => {
    if (!startTripDate || !tripDuration) return [];

    const { endTripDate, preTripDate, postTripDate } = getTripRelatedDates(
        startTripDate,
        tripDuration,
    );

    if (!endTripDate || !preTripDate || !postTripDate) return [];

    const hasPreAccommodation = hasAddOnByTiming(
        bookingAddOns,
        ServiceTiming.PRE_TRIP,
    );
    const hasPostAccommodation = hasAddOnByTiming(
        bookingAddOns,
        ServiceTiming.POST_TRIP,
    );

    if (stage === 'arrival') {
        const currentDate = new Date();
        const userStartTripDate = hasPreAccommodation
            ? preTripDate
            : startTripDate;

        const sevenDaysBeforeUserStarTripDate = subDays(userStartTripDate, 7);
        const isCurrentDateCloserThanSevenDays = isAfter(
            currentDate,
            sevenDaysBeforeUserStarTripDate,
        );

        const firstSelectableDate = isCurrentDateCloserThanSevenDays
            ? startOfDay(currentDate)
            : sevenDaysBeforeUserStarTripDate;

        return [firstSelectableDate, userStartTripDate];
    }

    if (stage === 'departure') {
        const userEndTripDate = hasPostAccommodation
            ? postTripDate
            : endTripDate;

        return [userEndTripDate, addDays(userEndTripDate, 7)];
    }

    return [];
};

/**
 * Checks if the required data for the Transfer product is ready.
 *
 * @param user
 * @param trip
 * @param booking
 * @returns boolean
 */
const isTransferProductReady = (
    user?: BaseUser,
    trip?: Trip,
    booking?: SavedBooking,
): boolean => {
    if (!user) {
        return false;
    }
    const shouldBookingExist = isTraveler(user);
    const bookingExistsIfExpected =
        !shouldBookingExist || (shouldBookingExist && !!booking);
    return !!trip && bookingExistsIfExpected;
};

/**
 * Checks if the trip has a ground transfer service based on the optional services.
 * @param trip
 * @returns Returns `true` if the trip has a ground transfer service, it's bookable and not deleted, otherwise `false`.
 */
const tripHasGroundTransferService = (trip: Trip | undefined): boolean => {
    const additionalOptionalServices = trip?.additionalOptionalServices || [];

    return additionalOptionalServices.some(
        (service: Service) =>
            service.type === ServiceType.GROUND_TRANSFER &&
            service.bookable &&
            !service.deleted,
    );
};

/**
 * Checks if the itinerary has a host ground transfer cost.
 * @param itinerary - The itinerary details.
 * @returns Returns `true` if the itinerary has `hosGroundTransferCost`, otherwise `false`.
 */
const itineraryHasHostGroundTransferCost = (
    itinerary: BaseItinerary | undefined,
): boolean => {
    return !!itinerary?.hostGroundTransferCost;
};

/**
 * Checks if the trip has host ground transfer cost.
 *
 * @param trip
 * @returns Returns `true` if the trip has `hosGroundTransferCost` inside `additionalCosts`, otherwise `false`.
 */
const tripHasHostGroundTransferCost = (trip: Trip | undefined) => {
    return !!trip?.additionalCosts?.hostGroundTransferCost;
};

/**
 * Checks if ground transfer is enabled based on the transfer flag and the trip.
 *
 * @param trip
 * @returns Returns `true` if ground transfer feature flag is enabled and
 * the trip has `hostGroundTransferCost` or trip has ground transfer service, otherwise `false`.
 */
const isGroundTransferEnabled = (trip: Trip | undefined): boolean => {
    return (
        isTransfersEnabled &&
        (tripHasHostGroundTransferCost(trip) ||
            tripHasGroundTransferService(trip))
    );
};

/**
 * Checks if ground transfer is bookable based on the transfer flag, itinerary, trip and user type.
 *
 * @param trip
 * @param userType
 * @returns `true` if ground transfer is enabled, user is not secondary and trip has not started yet, otherwise `false`.
 */
const isGroundTransferBookable = (
    trip: Trip | undefined,
    userType?: TravelerType,
): boolean => {
    return (
        !!trip &&
        !isSecondaryUser(userType) &&
        isGroundTransferEnabled(trip) &&
        !hasTripStarted(trip)
    );
};

/**
 * Defines if the sidebar should be visible on the UI or not
 * depending on the given location and the transfer detail data.
 *
 * @param location
 * @param arrival
 * @param departure
 * @returns boolean
 */
const shouldShowTransferSidebar = (
    location: string,
    arrival?: TransferDetail,
    departure?: TransferDetail,
): boolean => {
    const tabsWithoutSummary = [paths.tab.travel_detail];

    if (tabsWithoutSummary.some((tab) => location.includes(tab))) {
        return false;
    }

    const arrivalHasRequiredData =
        arrival && isTransferDetailCompleteOrWithoutQuote(arrival);

    const departureHasRequiredData =
        departure && isTransferDetailCompleteOrWithoutQuote(departure);

    return !!(arrivalHasRequiredData || departureHasRequiredData);
};

/**
 * Checks if the booking has a ground transfer add-on for the given user
 *
 * @param user
 * @param booking
 * @returns Returns `true` if the booking has a ground transfer add-on for the given user, otherwise `false`.
 */
const bookingIncludesUserGroundTransferAddOn = (
    user: BaseUser,
    booking?: SavedBooking,
): boolean => {
    if (!booking?.addOns) return false;

    return booking.addOns?.some(
        (addOn) =>
            addOn.type === AddOnTypes.GROUND_TRANSFER &&
            !addOn.deleted &&
            addOn.user === user.id,
    );
};

/**
 * Check if the user (host or traveler) has a ground transfer booked.
 *
 * @param user
 * @param trip
 * @param booking
 * @returns Returns `true` if the user has a ground transfer booked, otherwise `false`.
 */
const userHasGroundTransferBooked = (
    user: BaseUser,
    trip: Trip,
    booking: SavedBooking | undefined,
): boolean => {
    const hostHasGroundTransfer = isHost(user) && !!trip.hostGroundTransfers;

    const travelerHasGroundTransfer =
        isTraveler(user) &&
        bookingIncludesUserGroundTransferAddOn(user, booking);

    return hostHasGroundTransfer || travelerHasGroundTransfer;
};

/**
 * Parses the vehicle category of a quote by replacing underscores with blank spaces and converting it to title case
 *
 * @param vehicleCategory
 * @returns The modified vehicle category.
 */
const parseQuoteVehicleCategory = (
    vehicleCategory: Quote['vehicleCategory'],
): Quote['vehicleCategory'] => {
    const lowerCaseCategory = vehicleCategory.toLowerCase();
    const capitalizedCategory = startCase(lowerCaseCategory);

    return capitalizedCategory;
};

/**
 * Retrieves the ground transfer service of a trip.
 *
 * @param trip
 * @returns The ground transfer service if found, otherwise `undefined`.
 */
const getGroundTransferServiceFromTrip = (trip: Trip): Service | undefined => {
    return trip.additionalOptionalServices?.find(
        (service: Service) => service.type === ServiceType.GROUND_TRANSFER,
    ) as Service;
};

/**
 * Retrieves the ground transfer booking status from a journey status.
 *
 * @param status
 * @returns The ground transfer status.
 */
const getTransferBookingStatusFromJourneyStatus = (
    status: JourneyStatus,
): GroundTransferBookingStatus => {
    const pendingStatusList = [
        JourneyStatus.NOT_PAID,
        JourneyStatus.AWAITING_APPROVAL,
        JourneyStatus.PENDING,
    ];

    const cancelledStatusList = [
        JourneyStatus.CANCELLED_FREE,
        JourneyStatus.CANCELLED_WITH_COSTS,
    ];

    const driverAssignedStatusList = [
        JourneyStatus.PLANNED,
        JourneyStatus.DRIVER_UNDERWAY,
        JourneyStatus.DRIVER_ARRIVED,
        JourneyStatus.JOURNEY_IN_PROGRESS,
    ];

    const journeyStatusToGroundTransferStatusMap = {
        [JourneyStatus.CONFIRMED]: GroundTransferBookingStatus.CONFIRMED,
        [JourneyStatus.COMPLETED]: GroundTransferBookingStatus.COMPLETED,
        [JourneyStatus.DRIVER_UNDERWAY]:
            GroundTransferBookingStatus.DRIVER_UNDERWAY,
    };

    if (pendingStatusList.includes(status)) {
        return GroundTransferBookingStatus.PENDING;
    }

    if (cancelledStatusList.includes(status)) {
        return GroundTransferBookingStatus.CANCELLED;
    }

    if (driverAssignedStatusList.includes(status)) {
        return GroundTransferBookingStatus.DRIVER_ASSIGNED;
    }

    return (
        journeyStatusToGroundTransferStatusMap[status] ||
        GroundTransferBookingStatus.PROCESSING
    );
};

/**
 * Get the booked ground transfers ids.
 *
 * @param booking
 * @param trip
 * @param user
 * @returns An array of ground transfers ids if the user booked transfers, an empty array otherwise.
 */
const getBookedGroundTransferIds = (
    booking: SavedBooking | undefined,
    trip: Trip | undefined,
    user: BaseUser | undefined,
): string[] => {
    const isTravelerUser = user && isTraveler(user);

    if (isTravelerUser) {
        return getGroundTransferIdsFromBooking(booking);
    }

    return getGroundTransferIdsFromTrip(trip);
};

/**
 * Maps the ground transfer ids from a ground transfers object.
 *
 * @param groundTransfers
 * @returns An array of ground transfer ids.
 */
const mapGroundTransferIds = (
    groundTransfers: GroundTransferAddOn[] | HostGroundTransfer[],
): string[] => {
    return groundTransfers.map(
        (item: GroundTransferAddOn | HostGroundTransfer) => {
            const groundTransfer = item.groundTransfer;
            return typeof groundTransfer === 'string'
                ? groundTransfer
                : groundTransfer._id;
        },
    );
};

/**
 * Parses the journey data from a ground transfer journey.
 *
 * @param groundTransfer
 * @returns The booked ground transfer journey data.
 */
const parseJourneyData = (
    groundTransfer: GroundTransfer,
): BookedGroundTransferJourney => {
    const { journey, _id, quoteRequest, cancellationType } = groundTransfer;

    return {
        id: _id,
        code: journey.code,
        type: journey.type,
        origin: journey.origin,
        destination: journey.destination,
        date: new Date(journey.pickup.localTime),
        status: getTransferBookingStatusFromJourneyStatus(journey.status),
        cancellationDetails: journey.cancellationDetails,
        cancellationType,
        transferCompany: journey.transferCompany,
        driver: journey.driver,
        price: journey.priceSummary.price,
        passengers: quoteRequest.passengers ?? 0,
        luggage:
            (quoteRequest.carryOnLuggage ?? 0) +
            (quoteRequest.checkedLuggage ?? 0),
        traveler: journey.traveler,
        vehicleCategory: journey.vehicleCategory,
    };
};

/**
 * Maps the booked ground transfers by stage.
 *
 * @param groundTransfers
 * @returns An object containing the booked ground transfers by stage or `undefined` if no ground transfers are found.
 */
const mapGroundTransfersByStage = (
    groundTransfersList?: GroundTransfer[],
): BookedTransferJourneysByStage | undefined => {
    if (isEmpty(groundTransfersList) || !groundTransfersList) return;

    const groundTransfersByStageMap = groundTransfersList.reduce(
        (groundTransfersMap, groundTransfer) => {
            const journeyData = parseJourneyData(groundTransfer);

            const isArrival = journeyData.type === JourneyType.ARRIVAL;

            if (isArrival) {
                groundTransfersMap.arrival = [
                    ...(groundTransfersMap.arrival || []),
                    journeyData,
                ];
            }

            const isDeparture = journeyData.type === JourneyType.DEPARTURE;

            if (isDeparture) {
                groundTransfersMap.departure = [
                    ...(groundTransfersMap.departure || []),
                    journeyData,
                ];
            }
            return groundTransfersMap;
        },
        {} as BookedTransferJourneysByStage,
    );

    return groundTransfersByStageMap;
};

/**
 * Checks if insurance should be repriced based on the booking and insurance status.
 *
 * @param booking
 * @param status
 *
 * @return `true` if insurance should be repriced, `false` otherwise.
 */
const shouldRepriceInsurance = (
    booking: SavedBooking | undefined,
    status: InsuranceStatus,
): boolean => {
    const isInsuranceEmpty = status === 'empty';
    const hasInsurance = doesBookingHaveInsurance(booking);

    return hasInsurance && isInsuranceEmpty;
};

type ShouldEnableTransferFlowForUserParams = {
    trip: Trip;
    itinerary?: Itinerary;
    booking?: SavedBooking;
    traveler?: UserTripsTraveler;
};

type TransferFlowDisabledReasonList = TransferNotAvailableReason[];

type ShouldEnableTransferFlowForUserReturn =
    | { assertion: true }
    | { assertion: false; reasons: TransferFlowDisabledReasonList };

/**
 * Determines if the transfer flow should be enabled for a user based on different conditions.
 *
 * @param params - these include itinerary, trip, booking and traveler
 *
 * @return an object containing the assertion, which could be `true` or `false` and the reasons if the assertion is `false`
 */
const shouldEnableTransferFlowForUser = (
    params: ShouldEnableTransferFlowForUserParams,
): ShouldEnableTransferFlowForUserReturn => {
    const { trip, booking, traveler } = params;

    const { type: userType } = traveler || {};

    const reasons: TransferFlowDisabledReasonList = [];

    if (!isGroundTransferBookable(trip, userType)) {
        reasons.push('ground-transfer-not-bookable');
    }

    if (doesBookingHavePendingRefund(booking)) {
        reasons.push('booking-has-pending-refund');
    }

    if (booking && getBookingStatus(booking) === BookingStatuses.CANCELLED) {
        reasons.push('booking-is-cancelled');
    }

    if (!trip.adminTechnicalItineraryApproved) {
        reasons.push('admin-technical-itinerary-not-approved');
    }

    if (isTripComplete(trip)) {
        reasons.push('trip-is-complete');
    }

    return reasons.length === 0
        ? { assertion: true }
        : { assertion: false, reasons };
};

type ShouldDisplayTransferItemParams = ShouldEnableTransferFlowForUserParams;

/**
 * Determines if the transfer item should be displayed for a user based on different conditions.
 *
 * @param params - these include itinerary, trip, booking and traveler
 *
 * @return `true` if the transfer item should be displayed, `false` otherwise.
 */
const shouldDisplayTransferItem = ({
    itinerary,
    trip,
    booking,
    traveler,
}: ShouldDisplayTransferItemParams): boolean => {
    /**
     * The following reasons disable, in one way or another, the access to the flow.
     *
     * These are different ways to disable the flow depending on the reason:
     *
     * `booking-has-pending-refund`: the CTA on the UI will be disabled
     * `admin-technical-itinerary-not-approved`: the CTA on the UI will be hidden
     */
    const validReasonsToShowTransferItem: TransferNotAvailableReason[] = [
        'admin-technical-itinerary-not-approved',
        'booking-has-pending-refund',
    ];

    const transferFlowIsEnabled = shouldEnableTransferFlowForUser({
        itinerary,
        trip,
        booking,
        traveler,
    });

    if (transferFlowIsEnabled.assertion) {
        return true;
    }

    // We only want to show the transfer item if all the reasons to show the item are valid.
    // If there is a reason to not show the item, we should return false.
    const allReasonsAreValid = transferFlowIsEnabled.reasons.every((reason) =>
        validReasonsToShowTransferItem.includes(reason),
    );

    return allReasonsAreValid;
};

/**
 * Get a booked ground transfer journey by id. The function expects the ID to match a journey on the booking.
 *
 * @param id - The id of the journey to retrieve.
 * @param journeysByStage
 * @returns The booked ground transfer journey with the specified id.
 */
const getJourneyById = (
    id: string,
    journeysByStage: BookedTransferJourneysByStage,
): BookedGroundTransferJourney => {
    const foundJourney = Object.values(journeysByStage)
        .flat()
        .find((journey) => journey.id === id);

    return foundJourney!;
};

/**
 * Get a ground transfer add-on from a booking by its ground transfer id.
 *
 * @param booking
 * @param groundTransferId
 * @returns The ground transfer add-on matching the provided ID or `undefined` if not found.
 */
const getGroundTransferAddOnById = (
    booking: SavedBooking | undefined,
    groundTransferId: string,
): GroundTransferAddOn | undefined =>
    booking?.addOns?.find((addOn) => {
        const groundTransferAddOn = addOn as GroundTransferAddOn;

        return groundTransferAddOn.groundTransfer === groundTransferId;
    }) as GroundTransferAddOn;

/**
 * Checks if required data for a driver is available.
 *
 * @param driver - The driver's contact information.
 * @returns Returns `true` if all required properties are available and not empty, otherwise `false`.
 */
const isDriverDataAvailable = (driver: Journey['driver']): boolean => {
    if (!driver) return false;

    const requiredProperties = [
        'name',
        'phoneNumber',
        'vehicleMake',
        'vehicleModel',
        'vehicleLicensePlate',
    ];

    return every(
        requiredProperties,
        (property) => !isEmpty(get(driver, property)),
    );
};

/**
 * Checks if the user is allowed to perform ground transfer operations (edition and cancellation)
 * based on the user type and journey status.
 *
 * @param userType
 * @param journeyStatus
 * @returns boolean
 */
const areGroundTransfersOperationsEnabled = (
    userType: TravelerType | undefined,
    journeyStatus: BookedGroundTransferJourney['status'],
): boolean =>
    !isSecondaryUser(userType) &&
    journeyStatus !== GroundTransferBookingStatus.CANCELLED;

interface HasDifferentTransferDatesFromTripParams {
    trip: Trip | undefined;
    shouldCheckPreTripDate: boolean;
    shouldCheckPostTripDate: boolean;
    userGroundTransfers: GroundTransfer[] | undefined;
}

/**
 * Get user ground transfers filtered by journey type.
 *
 * @param userGroundTransfers
 * @param type - The journey type to filter by.
 * @returns The filtered array of ground transfers.
 */
const getJourneysByType = (
    userGroundTransfers: GroundTransfer[],
    type: JourneyStage,
): GroundTransfer[] => {
    return userGroundTransfers.filter(
        (transfer) => transfer.journey.type === type,
    );
};

/**
 * Checks if there are different transfer dates from the trip.
 *
 * @param trip
 * @param shouldCheckPreTripDate - If `true`, it checks the difference between arrival pickup date and pre-trip date.
 * @param shouldCheckPostTripDate - If `true`, it checks the difference between departure pickup date and post-trip date.
 * @param userGroundTransfers - The transfers booked by the user.
 * @returns `true` if there are different transfer dates, `false` otherwise.
 */
const hasDifferentTransferDatesFromTrip = ({
    trip,
    shouldCheckPreTripDate,
    shouldCheckPostTripDate,
    userGroundTransfers,
}: HasDifferentTransferDatesFromTripParams): boolean => {
    if (!trip?.startDate || !userGroundTransfers) {
        return false;
    }

    const arrivalJourneys = getJourneysByType(
        userGroundTransfers,
        JourneyType.ARRIVAL,
    );
    const departureJourneys = getJourneysByType(
        userGroundTransfers,
        JourneyType.DEPARTURE,
    );

    const { preTripDate, postTripDate, endTripDate } = getTripRelatedDates(
        new Date(trip.startDate),
        trip?.servicesByDay?.length,
    );

    const checkedDatesAreDifferent = (
        groundTransfers: GroundTransfer[],
        pickupDate: Date | undefined,
    ): boolean => {
        if (!pickupDate) {
            return false;
        }

        return groundTransfers.some((groundTransfer) => {
            const pickupLocalTime = new Date(
                groundTransfer.journey.pickup.localTime,
            );

            return !isSameDay(
                getZeroUTCResetDateObj(pickupLocalTime),
                new Date(pickupDate),
            );
        });
    };

    let areArrivalDatesDifferent = false;
    let areDepartureDatesDifferent = false;

    if (shouldCheckPreTripDate) {
        // Check the difference between arrival pickup date and pre-trip date.
        areArrivalDatesDifferent = checkedDatesAreDifferent(
            arrivalJourneys,
            preTripDate,
        );
    } else {
        // Check the difference between arrival pickup date and start trip date.
        areArrivalDatesDifferent = checkedDatesAreDifferent(
            arrivalJourneys,
            new Date(trip.startDate),
        );
    }

    if (shouldCheckPostTripDate) {
        // Check the difference between departure pickup date and post-trip date.
        areDepartureDatesDifferent = checkedDatesAreDifferent(
            departureJourneys,
            postTripDate,
        );
    } else {
        // Check the difference between departure pickup date and end trip date.
        areDepartureDatesDifferent = checkedDatesAreDifferent(
            departureJourneys,
            endTripDate,
        );
    }

    const areDatesDifferent =
        areArrivalDatesDifferent || areDepartureDatesDifferent;

    return areDatesDifferent;
};

/**
 * Checks if required data for a company is available.
 *
 * @param company - The company's contact information.
 * @returns Returns `true` if all required properties are available and not empty, otherwise `false`.
 */
const isCompanyDataAvailable = (
    company: Journey['transferCompany'],
): boolean => {
    if (!company) return false;

    const requiredProperties = ['name', 'phoneNumber'];

    return every(
        requiredProperties,
        (property) => !isEmpty(get(company, property)),
    );
};

interface AirportTransferServices {
    preTripTransferServices: AirportTransfer[];
    postTripTransferServices: AirportTransfer[];
}

/**
 * Gets the pre and post airport transfer services from the additional optional services.
 * @param additionalOptionalPrePostServices
 * @returns An object containing the pre and post trip transfer services.
 */
const getAirportTransferServices = (
    additionalOptionalPrePostServices: Service[],
): AirportTransferServices => {
    const airportTransfers = additionalOptionalPrePostServices.filter(
        (service): service is AirportTransfer =>
            service.type === ServiceType.AIRPORT_TRANSFER,
    );

    const preTripTransferServices = airportTransfers.filter(
        (service) => service.timing === ServiceTiming.PRE_TRIP,
    );
    const postTripTransferServices = airportTransfers.filter(
        (service) => service.timing === ServiceTiming.POST_TRIP,
    );

    return {
        preTripTransferServices,
        postTripTransferServices,
    };
};

export {
    areGroundTransfersOperationsEnabled,
    getAirportTransferServices,
    getBookedGroundTransferIds,
    getGroundTransferAddOnById,
    getGroundTransferServiceFromTrip,
    getJourneyById,
    getTransferBookingStatusFromJourneyStatus,
    getTransferPath,
    getTransferStageSelectableDates,
    hasDifferentTransferDatesFromTrip,
    isCompanyDataAvailable,
    isDriverDataAvailable,
    isGroundTransferBookable,
    isGroundTransferEnabled,
    isTransferProductReady,
    itineraryHasHostGroundTransferCost,
    mapGroundTransferIds,
    mapGroundTransfersByStage,
    parseQuoteVehicleCategory,
    shouldDisplayTransferItem,
    shouldEnableTransferFlowForUser,
    shouldRepriceInsurance,
    shouldShowTransferSidebar,
    tripHasGroundTransferService,
    userHasGroundTransferBooked,
};
