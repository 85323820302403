import { Text } from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';
import {
    formatDateForPostLaunchInfo,
    getShortTimeZone,
} from 'applications/host/utils';
import { useMemo } from 'react';
import FormHeader from './FormHeader';

interface PostLaunchInfoProps {
    currentTrip: models.trips.Trip;
}

const PostLaunchInfo = (props: PostLaunchInfoProps) => {
    const { currentTrip } = props;
    const launchDate = currentTrip?.launchDate;

    if (!launchDate) {
        return null;
    }

    const dateFormatted = useMemo(
        () => formatDateForPostLaunchInfo(launchDate),
        [launchDate],
    );

    const userTimeZone = getShortTimeZone();

    if (!dateFormatted) {
        return null;
    }

    const { date, hour } = dateFormatted;
    const today = new Date();
    const launchDateParsed = new Date(launchDate);

    // Check whether launch date is in the future and update copy accordingly
    const launchCopy =
        launchDateParsed > today
            ? 'Your trip is live! Your scheduled promotion launch date is'
            : 'Your trip launched on';

    return (
        <>
            <FormHeader title='Launch Date' />
            <Text paddingRight={{ base: 12, md: 0 }}>
                {launchCopy}
                <b> {date} </b>
                at
                <b> {hour} </b>
                {userTimeZone}
            </Text>
        </>
    );
};

export default PostLaunchInfo;
