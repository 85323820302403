import { Stack, Text, TextProps } from '@trova-trip/trova-components';
import { Icon, IconProps } from '@trova-trip/trova-components/build/next';

type IconTextVariants = 'journey-preview' | 'booking-details';

interface IconTextProps {
    icon: IconProps['as'];
    text: string | number;
    variant?: IconTextVariants;
}

type VariantTextProps = Pick<TextProps, 'color' | 'fontWeight' | 'fontSize'>;

const variantTextProps: Record<IconTextVariants, VariantTextProps> = {
    'journey-preview': {
        color: 'blueGray.800',
        fontSize: 'sm',
        fontWeight: 'bold',
    },
    'booking-details': {
        color: 'blueGray.500',
        fontSize: 'base',
        fontWeight: 'regular',
    },
};

const IconText = ({
    icon,
    text,
    variant = 'booking-details',
}: IconTextProps): JSX.Element | null => {
    const iconColor =
        variant === 'booking-details' ? 'blueGray.500' : 'teal.trova';

    return (
        <Stack align='center' spacing={1}>
            <Icon as={icon} color={iconColor} size='sm' display='inline-flex' />
            <Text {...variantTextProps[variant]}>{text}</Text>
        </Stack>
    );
};

export default IconText;
