import React, { ReactElement, useMemo } from 'react';
import { Graph } from './Chart.types';
import { theme } from '@trova-trip/trova-components';
import useMediaQuery from '../../../../util/hooks/useMediaQuery';
import {
    ResponsiveContainer,
    BarChart,
    LabelList,
    XAxis,
    YAxis,
    Tooltip,
    Bar,
    Cell,
} from 'recharts';
import { truncate } from 'lodash';

interface TrovaBarChartProps {
    graphData: Graph;
    graphX: string;
    graphY: string;
    height?: number;
    xAxisHeight?: number;
    colorArray?: string[];
    valueLabel?: string;
}

const useTrimmedGraphData = (originalGraphData: Graph): Graph => {
    const mediumScreenOrAbove = useMediaQuery(`(min-width:${768}px)`);
    return useMemo(() => {
        const graphLength = mediumScreenOrAbove ? 15 : 5;
        return originalGraphData.slice(0, graphLength);
    }, [mediumScreenOrAbove, originalGraphData]);
};

const TopOfBarLabel = (props): ReactElement => {
    const { x, y, width, value } = props;
    const radius = 10;
    return (
        <g>
            <text
                x={x + width / 2}
                y={y - radius}
                fill={theme.colors.blueGray[650]}
                fontSize={theme.fontSizes.sm}
                fontFamily={theme.fonts.roboto}
                fontWeight={theme.fontWeights.bold}
                textAnchor="middle"
                dominantBaseline="middle"
            >
                {value}
            </text>
        </g>
    );
};

const xAxisLabelFormatter = (value: string): string => {
    return truncate(value, { length: 18, omission: '-' });
};

const TrovaBarChart = ({
    graphData,
    graphX,
    graphY,
    height = 400,
    colorArray = [theme.colors.teal[300]],
    xAxisHeight = 30,
    valueLabel,
}: TrovaBarChartProps): ReactElement => {
    const trimmedGraphData = useTrimmedGraphData(graphData);
    return (
        <ResponsiveContainer width='100%' height={height}>
            <BarChart
                data={trimmedGraphData}
                margin={{
                    top: 40,
                    right: 40,
                    left: 0,
                    bottom: 40,
                }}
            >
                <XAxis
                    dataKey={graphX}
                    angle={-45}
                    textAnchor="end"
                    height={xAxisHeight}
                    tickFormatter={xAxisLabelFormatter}
                    tickSize={4}
                    tickMargin={1}
                    interval={0}
                    style={{
                        fill: theme.colors.blueGray[650],
                        fontFamily: theme.fonts.roboto,
                        fontWeight: theme.fontWeights.bold,
                        fontSize: theme.fontSizes.sm,
                    }}
                />
                <YAxis
                    dataKey={graphY}
                    style={{
                        fill: theme.colors.neutral[60],
                        fontFamily: theme.fonts.roboto,
                        fontWeight: theme.fontWeights.bold,
                        fontSize: theme.fontSizes.sm,
                    }}
                    tickFormatter={(value: any): string => value}
                    tickMargin={5}
                    allowDecimals={false}
                />

                <Tooltip
                    separator={': '}
                    formatter={(value: any): string[] => {
                        const formattedName = '';
                        return [value, formattedName];
                    }}
                    contentStyle={{
                        borderRadius: '6px',
                        background: theme.colors.neutral.white,
                        color: theme.colors.blueGray[650],
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '4px',
                    }}
                />

                <Bar dataKey={graphY} fill={colorArray[0]}>
                    {valueLabel && (
                        <LabelList
                            dataKey={valueLabel}
                            content={TopOfBarLabel}
                        />
                    )}
                    {graphData.map((item, index) => {
                        return (
                            <Cell
                                key={`cell-${item.title}`}
                                fill={colorArray[index % colorArray.length]}
                            />
                        );
                    })}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export { TrovaBarChart as default };
