import { constants } from '@trova-trip/trova-models';

export type HostRoomType =
    | constants.tripRequest.HostRoomType
    | null
    | undefined;
type HostRoomBedQuantityType = 1 | 2;
export enum HostRoomHelperText {
    ONE_BED = 'This room will have 1 bed',
    TWO_BEDS = 'This room will have 2 single beds',
    SHARED_BED = 'This room will have 1 double bed',
}
export enum HostRoomIcon {
    ONE_BED = 'doubleBed',
    TWO_BEDS = 'trovaTwoBeds',
    SHARED_BED = 'kingSizeBed',
}
export type HostRoomInformationType = {
    bedQuantity: HostRoomBedQuantityType;
    icon: HostRoomIcon;
    helperText: HostRoomHelperText;
};
