import { css } from '@emotion/css';

export const styledCard = css`
    position: relative;
`;

export const styledSvgDot = css`
    position: absolute;
    right: 0;
    top: 0;
    height: 1rem;
    width: 1rem;
    margin: 1rem 0.5rem;
`;
