import { coreUtils } from '@trova-trip/trova-common';
import { Heading, LineItem, Stack } from '@trova-trip/trova-components';
import { Card } from '@trova-trip/trova-components/build/next';
import { useManageBooking } from '../../hooks';
import {
    Activities,
    BaseUser,
    ManageBookingAddOn,
    Service,
    ServicesByDay,
} from '../../types';
import { getTravelersNames } from '../../utils/transform.utils';
import { isAddOnSelected } from '../../utils/check.utils';
import {
    getActivityDate,
    sortActivitiesByDateThenName,
} from '../../utils/date.utils';
import {
    getAddOnStatusIconProps,
    getToggleAddOnActionType,
} from '../../utils/extract.utils';

const { formatUSD } = coreUtils.currencyUtils;

interface ActivitiesSectionProps {
    startDate: Date | undefined;
    servicesByDay: ServicesByDay | undefined;
    travelers: BaseUser[];
}

interface ActivityItemProps {
    activity: ManageBookingAddOn;
    servicesByDay?: ServicesByDay;
    startDate?: Date;
    travelerId: string;
    hideControl: boolean;
}

const areAllTravelerActivitiesEmpty = (activities: Activities): boolean => {
    return Object.keys(activities).every(
        (travelerId) => !Object.keys(activities[travelerId]).length,
    );
};

const ActivityItem = ({
    activity,
    servicesByDay,
    startDate,
    travelerId,
    hideControl,
}: ActivityItemProps): JSX.Element | null => {
    const {
        actions: { updateActivity },
    } = useManageBooking();
    const { status, addOnId, unitPriceWithFee } = activity;
    const { description, _id: id, name } = activity.service as Service;

    if (!name || !unitPriceWithFee || !id) {
        return null;
    }

    const activityDate = getActivityDate(id, startDate, servicesByDay);
    const isSelected = isAddOnSelected(status);

    const handleChange = (): void => {
        updateActivity({
            addOnId,
            travelerId,
            actionType: getToggleAddOnActionType(isSelected),
        });
    };

    return (
        <LineItem
            key={addOnId}
            id={addOnId}
            type='button'
            name='activity'
            title={name}
            description={description}
            descriptionMaxWidth='90%'
            onChange={handleChange}
            value={isSelected}
            descriptionMaxLines={2}
            hideControl={hideControl}
            headerProps={{
                icon: getAddOnStatusIconProps(status),
                price: formatUSD(unitPriceWithFee, 2),
                subtitle: activityDate,
            }}
        />
    );
};

const ActivitiesSection = ({
    startDate,
    servicesByDay,
    travelers,
}: ActivitiesSectionProps): JSX.Element | null => {
    const {
        state: {
            addOns: { activities },
            isBookingEditable,
        },
    } = useManageBooking();

    if (areAllTravelerActivitiesEmpty(activities)) {
        return null;
    }

    const travelersNames = getTravelersNames(travelers);

    const sortedActivities = sortActivitiesByDateThenName(
        activities,
        startDate,
        servicesByDay,
    );

    return (
        <Card width='full'>
            <Heading
                as='h4'
                fontWeight='medium'
                size={{ base: 'xl', lg: '2xl' }}
            >
                Trip Add-Ons
            </Heading>
            {Object.keys(activities).map((travelerId, index) => (
                <Stack
                    key={travelerId}
                    direction='column'
                    marginTop={6}
                    spacing={6}
                >
                    <Heading as='h6' fontWeight='medium'>
                        Traveler {index + 1} - {travelersNames[travelerId]}
                    </Heading>
                    {Object.keys(sortedActivities[travelerId]).map(
                        (activityId) => {
                            const activity =
                                sortedActivities[travelerId][activityId];

                            return (
                                <ActivityItem
                                    key={activityId}
                                    activity={activity}
                                    startDate={startDate}
                                    servicesByDay={servicesByDay}
                                    travelerId={travelerId}
                                    hideControl={!isBookingEditable}
                                />
                            );
                        },
                    )}
                </Stack>
            ))}
        </Card>
    );
};

export default ActivitiesSection;
