import { constants, models } from '@trova-trip/trova-models';
import { GroundTransferAddOn } from '../../../state/features/transfer/types';
import { mapGroundTransferIds } from './transfer';
import { filterInsuranceAddOns } from '../../traveler/utils';

type Service = models.services.Service;
type SavedBooking = models.bookings.SavedBooking;

const { ServiceTiming } = constants.services;
const { AddOnTypes } = constants.bookings;

/**
 * Checks whether the booking has at least one add-on by the given timing.
 *
 * @param addOns
 * @param timing - Can be one of PRE_TRIP, POST_TRIP or DURING_TRIP
 *
 * @returns true if the booking has at least one add-on by the given timing, false otherwise.
 */
const hasAddOnByTiming = (
    addOns: SavedBooking['addOns'],
    timing: typeof ServiceTiming[keyof typeof ServiceTiming],
) => {
    if (!addOns) {
        return false;
    }

    return addOns.some(
        (addOn) =>
            (addOn.service as Service)?.timing === timing && !addOn.deleted,
    );
};

/**
 * Returns `true` if the booking has a pending refund.
 *
 * @param booking
 * @returns boolean
 */
const doesBookingHavePendingRefund = (booking?: SavedBooking): boolean => {
    return !!(booking && booking.dueAmount < 0);
};

/**
 * Returns `true` if the booking has insurance included.
 *
 * @param booking
 * @returns boolean
 */
const doesBookingHaveInsurance = (booking?: SavedBooking): boolean => {
    if (!booking) {
        return false;
    }
    const bookingHaveInsurance = filterInsuranceAddOns(booking, true).map(
        (addOn) => {
            return addOn.insurancePolicy;
        },
    );

    return !!bookingHaveInsurance;
};

/**
 * Get the ground transfer ids from a booking.
 *
 * @param booking
 * @returns Returns an array of ground transfer ids or an empty array if no ground transfers are found.
 */
const getGroundTransferIdsFromBooking = (booking?: SavedBooking): string[] => {
    if (!booking) {
        return [];
    }

    const transferAddOns = booking.addOns?.filter(
        (addOn) => addOn.type === AddOnTypes.GROUND_TRANSFER && !addOn.deleted,
    ) as GroundTransferAddOn[];

    return mapGroundTransferIds(transferAddOns);
};

export {
    getGroundTransferIdsFromBooking,
    hasAddOnByTiming,
    doesBookingHavePendingRefund,
    doesBookingHaveInsurance,
};
