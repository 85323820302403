import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    UserHostStoreSelector,
    getHostPublicProfile,
} from '../../../../../state/hosts';
import {
    HostProfileFormValues,
    HostProfileResponse,
    PublicProfileFieldNames,
} from '../utils/types';
import PublicProfileForm from './PublicProfileForm';
import pick from 'lodash/pick';

const initialValues: HostProfileFormValues = {
    [PublicProfileFieldNames.BIO]: '',
    [PublicProfileFieldNames.COUNTRY]: {
        name: '',
        code: '',
    },
    [PublicProfileFieldNames.DISPLAY_NAME]: '',
    [PublicProfileFieldNames.INTERESTS]: [],
    [PublicProfileFieldNames.LANGUAGES]: [],
    [PublicProfileFieldNames.PROFILE_PICTURE]: '',
    [PublicProfileFieldNames.PRONOUNS]: [],
    [PublicProfileFieldNames.SOCIAL_CHANNELS]: {
        facebook: '',
        instagram: '',
        tiktok: '',
        youtube: '',
        personalSite: '',
    },
};

const getCurrentPublicProfile = async (
    hostId: string,
): Promise<HostProfileResponse | undefined> => {
    try {
        const { data } = await getHostPublicProfile(hostId);

        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

const formatHostResponseToHostFormValues = (
    hostProfileResponse?: HostProfileResponse,
): HostProfileFormValues => ({
    ...initialValues,
    ...pick(hostProfileResponse, Object.keys(initialValues)),
});

const EditPublicProfile = (): JSX.Element | null => {
    const currentHost = useSelector(
        (state: UserHostStoreSelector) => state.userHosts.current,
    );
    const { _id: hostId } = currentHost || {};
    const [initialValues, setInitialValues] = useState<HostProfileFormValues>();
    const [hostNameFallback, setHostNameFallback] = useState<string>('');

    useEffect(() => {
        if (hostId) {
            (async (): Promise<void> => {
                const currentPublicProfile = await getCurrentPublicProfile(
                    hostId,
                );
                setHostNameFallback(
                    [
                        currentPublicProfile?.firstName,
                        currentPublicProfile?.lastName,
                    ].join(' '),
                );
                const initialValues =
                    formatHostResponseToHostFormValues(currentPublicProfile);
                setInitialValues(initialValues);
            })();
        }
    }, [hostId]);

    if (!initialValues) return null;

    return (
        <PublicProfileForm
            initialValues={initialValues}
            hostId={hostId}
            hostNameFallback={hostNameFallback}
        />
    );
};

export default EditPublicProfile;
