import { appConstants, coreUtils } from '@trova-trip/trova-common';
import {
    BaseBox,
    BaseBoxProps,
    Card,
    Heading,
    Stack,
    Text,
    theme,
} from '@trova-trip/trova-components';
import { Button, Icon } from '@trova-trip/trova-components/build/next';
import { models } from '@trova-trip/trova-models';
import { BookingStatuses, TripStatuses } from '../../../../../config/constants';
import { isAvailableBookingBalanceDetails } from '../../../../../util/bookingUtils';
import CardTitleHeader from '../../../../common/components/CardTitleHeader';
import { APP_ENVIRONMENT } from '../../../../utils';

type Booking = models.bookings.Booking;
type Trip = models.trips.Trip;

const { formatDateTime } = coreUtils.dateUtils;
const { pluralize } = coreUtils.objectUtils;
const { getDaysUntilPaymentDueDate } = coreUtils.bookingUtils;

const getRemainingBalanceDescription = (
    booking: Booking,
): string | undefined => {
    const daysUntilPaymentDueDate = getDaysUntilPaymentDueDate(booking);

    if (!daysUntilPaymentDueDate) {
        return undefined;
    }

    return daysUntilPaymentDueDate > 0
        ? `Due in ${pluralize(daysUntilPaymentDueDate, 'day', 'days')}`
        : 'The payment is overdue.';
};

export interface BalanceCardProps {
    trip: Trip & { booking: Booking };
}

interface BaseCardProps {
    title: string;
    children: React.ReactNode;
    margin?: BaseBoxProps['margin'];
}

const {
    bookingUtils: { getBookingPaymentLink },
} = coreUtils;

const BaseCard = ({
    title,
    children,
    margin = '3rem 0 1rem 0',
}: BaseCardProps): JSX.Element => (
    <Card backgroundColor={theme.colors.neutral.white}>
        <CardTitleHeader>{title}</CardTitleHeader>
        <BaseBox textAlign='center' margin={margin}>
            {children}
        </BaseBox>
    </Card>
);

const PaymentLink = ({ booking }: { booking: Booking }) => {
    const paymentLink = getBookingPaymentLink(
        booking,
        APP_ENVIRONMENT as appConstants.Environment,
    );
    return (
        <Button
            as='a'
            isFullWidth
            linkProps={{
                href: paymentLink,
                target: '_blank',
                rel: 'noreferrer',
            }}
        >
            Make a Payment
        </Button>
    );
};

const DueBalanceCard = ({ trip }: BalanceCardProps): JSX.Element => {
    const booking = trip.booking;

    const showPaymentLink = isAvailableBookingBalanceDetails(
        trip.status,
        booking.status,
    );

    const isTripComplete = trip.status === TripStatuses.COMPLETE;
    const isBookingCancelled = booking.status === BookingStatuses.CANCELLED;
    const showDueAmount = !isTripComplete && !isBookingCancelled;

    const dueAmount = showDueAmount ? booking.dueAmount.toLocaleString() : 0;

    const paymentDueDate = formatDateTime(
        booking.paymentDueDate,
        'MMMM dd, yyyy',
    );

    const description = getRemainingBalanceDescription(booking);

    return (
        <BaseCard
            title='Remaining Balance'
            margin={showPaymentLink ? '3rem 0 1rem 0' : 0}
        >
            <Stack direction='column' align='center' spacing={4}>
                <Stack direction='column' align='center' spacing={0}>
                    <Heading as='h3' color='blueGray.650' marginBottom={2}>
                        ${dueAmount}
                    </Heading>
                    {showDueAmount ? (
                        <>
                            {description ? (
                                <Text color='blueGray.650' fontSize='sm'>
                                    {description}
                                </Text>
                            ) : null}
                            <Text color='blueGray.650' fontSize='sm'>
                                {paymentDueDate}
                            </Text>
                        </>
                    ) : null}
                </Stack>
                {showPaymentLink ? <PaymentLink booking={booking} /> : null}
            </Stack>
        </BaseCard>
    );
};

const PaymentCompleteCard = ({ trip }: BalanceCardProps): JSX.Element => {
    const { booking } = trip;
    const { paidAmount } = booking;

    return (
        <BaseCard title='Paid in Full'>
            <Stack
                spacing={{ base: 0, lg: 4 }}
                direction={{ lg: 'column' }}
                justify='space-between'
                align='center'
                paddingX={2}
            >
                <Stack spacing={2} align='center'>
                    <Icon
                        as='verified'
                        display='inline-flex'
                        color='teal.trova'
                        size='xl'
                    />
                    <Text
                        fontSize={{ base: 'xl', lg: '3xl' }}
                        fontWeight={{ base: 'medium', lg: 'bold' }}
                    >
                        Paid
                    </Text>
                </Stack>
                <Text size={{ base: 'base', lg: 'lg' }} color={'blueGray.500'}>
                    ${paidAmount}
                </Text>
            </Stack>
        </BaseCard>
    );
};

const BalanceCard = ({ trip }: BalanceCardProps): JSX.Element => {
    const { booking } = trip;
    const isPaymentComplete = booking.dueAmount <= 0;
    return isPaymentComplete ? (
        <PaymentCompleteCard trip={trip} />
    ) : (
        <DueBalanceCard trip={trip} />
    );
};

export default BalanceCard;
