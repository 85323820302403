import { SidebarItemProps } from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';

type Availability = models.itineraries.Availability;
type Itinerary = models.itineraries.Itinerary;
type ItineraryPackage = models.itineraries.ItineraryPackage;

/**
 * Helper functions for instantApproval label on trips and itineraries
 */
const getInstantApprovalLabel = (
    instantApprovalAllowed: boolean,
): SidebarItemProps['labelItems'] =>
    instantApprovalAllowed
        ? [
              {
                  leftIconProps: {
                      as: 'checkedCircle',
                      color: 'alerts.success.icon',
                  },
                  label: 'Instant Approval',
              },
          ]
        : [];

const isInstantApprovalAllowedForPackageAvailability = (
    packageAvailability: Availability,
) => {
    return !!packageAvailability.instantApprovalDates.length;
};

const isInstantApprovalAllowed = ({
    packages,
    itineraryInventoryEnabled,
}: Itinerary): boolean => {
    if (itineraryInventoryEnabled) {
        return packages['standard']?.enabled;
    } else {
        const hasValidityPeriodWithInstantApproval = Object.values(
            packages,
        ).some((currentPackage: ItineraryPackage) =>
            currentPackage.validityPeriods?.some(
                (elem) => elem.instantApprovalAllowed,
            ),
        );
        return hasValidityPeriodWithInstantApproval;
    }
};

export {
    getInstantApprovalLabel,
    isInstantApprovalAllowedForPackageAvailability,
    isInstantApprovalAllowed,
};
