import { models, constants } from '@trova-trip/trova-models';
import { theme } from '@trova-trip/trova-components';
import { useEffect, useState } from 'react';
import { useSelector } from 'state/hooks';
import { userTrips } from 'state/userTrips';

type Trip = models.trips.Trip;

type TripPublishStatus = Trip['publishStatus'];

interface TripPublishStatusBadge {
    text: string;
    color: string;
}

const EMPTY_STATUS_BADGE: TripPublishStatusBadge = {
    text: '',
    color: '',
};

const PENDING_STATUS_BADGE: TripPublishStatusBadge = {
    text: 'Pending',
    color: theme.colors.orange[900],
};

const IN_REVIEW_STATUS_BADGE: TripPublishStatusBadge = {
    text: 'In Review',
    color: theme.colors.orange[900],
};

const MARKETING_STATUS_BADGE: TripPublishStatusBadge = {
    text: 'Marketing',
    color: theme.colors.purple[400],
};

const LIVE_STATUS_BADGE: TripPublishStatusBadge = {
    text: 'Live!',
    color: theme.colors.seaGreen.trova,
};

interface usePublishSystemReturn {
    /**
     * The current publish status itself of the trip.
     */
    currentStatus?: TripPublishStatus;

    /**
     * Data about the current publish status of the trip.
     */
    currentStatusBadge: TripPublishStatusBadge;

    /**
     * If the trip is pending, i.e. has changes, it requests a trip publish
     * so an admin can do it after review it. And if it's not pending it
     * switches the trip to "live" status.
     */
    publish: () => Promise<Trip | undefined>;

    /**
     * Updates the publish status of the trip with the status passed to the function.
     */
    updatePublishStatus: (
        publishStatus: constants.trips.TRIP_PUBLISH_STATUS,
    ) => Promise<Trip | undefined>;

    /**
     * It means that the trip was published at least once.
     */
    isPublished: boolean;

    /**
     * It means that the trip has suffered changes and it needs to
     * be published.
     */
    isPending: boolean;

    /**
     * It means that the trip is in review after the publish request.
     * An admin has to confirm the updated trip and publish it.
     */
    isInReview: boolean;

    /**
     * It means that the trip is published and ready to go live.
     */
    isMarketing: boolean;

    /**
     * It means that the trip is live.
     */
    isLive: boolean;
}

const usePublishSystem = (): usePublishSystemReturn => {
    const trip = useSelector((store) => store.userTrips.current);
    const { updateRecord: updateTrip } = userTrips.useDispatch.bind(trip)();

    const [currentStatus, setCurrentStatus] = useState(trip?.publishStatus);

    const [currentStatusBadge, setCurrentStatusBadge] =
        useState<TripPublishStatusBadge>(EMPTY_STATUS_BADGE);

    useEffect(() => {
        setCurrentStatus(trip?.publishStatus);
    }, [trip?.publishStatus]);

    useEffect(() => {
        if (!currentStatus) return;
        switch (currentStatus) {
            case constants.trips.TRIP_PUBLISH_STATUS.MARKETING:
                return setCurrentStatusBadge(MARKETING_STATUS_BADGE);
            case constants.trips.TRIP_PUBLISH_STATUS.LIVE:
                return setCurrentStatusBadge(LIVE_STATUS_BADGE);
            case constants.trips.TRIP_PUBLISH_STATUS.IN_REVIEW:
                return setCurrentStatusBadge(IN_REVIEW_STATUS_BADGE);
            case constants.trips.TRIP_PUBLISH_STATUS.PENDING:
                return setCurrentStatusBadge(PENDING_STATUS_BADGE);
            default:
                return setCurrentStatusBadge(EMPTY_STATUS_BADGE);
        }
    }, [currentStatus]);

    const requestPublish = async (): Promise<Trip | undefined> => {
        if (!trip) return;
        const { id } = trip;
        const response: Trip = await updateTrip(id, {
            publishRequest: new Date().toISOString(),
        });
        return response;
    };

    const goLive = async (): Promise<Trip | undefined> => {
        if (!trip) return;
        const { id } = trip;
        const data: Partial<Trip> = {
            status: constants.trips.TRIP_STATUS.LIVE,
        };
        const response: Trip = await updateTrip(id, data);
        return response;
    };

    const publish = async (): Promise<Trip | undefined> => {
        if (!trip) return;
        return currentStatus === constants.trips.TRIP_PUBLISH_STATUS.PENDING
            ? requestPublish()
            : goLive();
    };

    const updatePublishStatus = async (
        publishStatus: constants.trips.TRIP_PUBLISH_STATUS,
    ): Promise<Trip | undefined> => {
        if (!trip) return;
        const { id } = trip;
        const data: Partial<Trip> = { publishStatus };
        const response: Trip = await updateTrip(id, data);
        return response;
    };

    return {
        currentStatus,
        currentStatusBadge,
        publish,
        updatePublishStatus,
        isPublished: !!trip?.lastPublishDate,
        isPending:
            currentStatus === constants.trips.TRIP_PUBLISH_STATUS.PENDING,
        isInReview:
            currentStatus === constants.trips.TRIP_PUBLISH_STATUS.IN_REVIEW,
        isMarketing:
            currentStatus === constants.trips.TRIP_PUBLISH_STATUS.MARKETING,
        isLive: currentStatus === constants.trips.TRIP_PUBLISH_STATUS.LIVE,
    };
};

export default usePublishSystem;
