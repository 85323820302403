import { StatefulModel } from 'applications/types';
import useModelStatusMatcher from '../hooks/useModelStatusMatcher';

interface ContentForModelStatusRenderData {
    predicate: boolean;
}
interface ContentForModelStatusProps {
    model: StatefulModel;
    matchingStatuses: Set<string> | string[];
    render: (data: ContentForModelStatusRenderData) => JSX.Element | null;
    forceRender?: boolean;
}

const ContentForModelStatus = (
    props: ContentForModelStatusProps,
): JSX.Element | null => {
    const { model, matchingStatuses, render, forceRender } = props;

    const predicate = useModelStatusMatcher({
        model,
        matchingStatuses: Array.isArray(matchingStatuses)
            ? new Set(matchingStatuses)
            : matchingStatuses,
    });

    return predicate || forceRender ? render({ predicate }) : null;
};

export default ContentForModelStatus;
