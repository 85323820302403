/****************************************************
 * Because why not have yet ANOTHER Constants file?
 *  Or we can start porting it all to Trova Core ::IDK::
 ****************************************************/
export enum HOST_TYPE {
    HOST_PRIMARY = 'host-primary',
    HOST_SECONDARY = 'host-secondary',
}
export enum TRAVELER_TYPE {
    TRAVELER_PRIMARY = 'traveler-primary',
    TRAVELER_SECONDARY = 'traveler-secondary',
}

export * as ROUTE_TOKENS from './routesTokens';
