import { coreUtils } from '@trova-trip/trova-common';
import { constants, models } from '@trova-trip/trova-models';
import { PassportInformation } from '../../types';
import { HOST_TYPE, TRAVELER_TYPE } from '../constants';
import { formatStringDate } from './date';

const { formatDateTime } = coreUtils.dateUtils;

type BaseUser = models.users.BaseUser;
type TravelerType = constants.travelers.TravelerType;

const isPrimaryTraveler = (type: string): boolean =>
    TRAVELER_TYPE.TRAVELER_PRIMARY === type;

const isSecondaryTraveler = (type: string): boolean =>
    TRAVELER_TYPE.TRAVELER_SECONDARY === type;

const isPrimaryHost = (type: string): boolean =>
    HOST_TYPE.HOST_PRIMARY === type;

const isSecondaryHost = (type: string): boolean =>
    HOST_TYPE.HOST_SECONDARY === type;

const isTraveler = (user: BaseUser): boolean => {
    return user.group === 'traveler';
};

const isHost = (user: BaseUser): boolean => {
    return user.group === 'host';
};

const isOperator = (user?: BaseUser): boolean => {
    return user?.group === 'operator';
};

/**
 * Checks if the user is a secondary traveler or host by type.
 *
 * @param type
 * @returns `true` if the user is a secondary traveler or host, `false` otherwise.
 */
const isSecondaryUser = (type: TravelerType | undefined): boolean => {
    if (!type) {
        return false;
    }

    return isSecondaryTraveler(type) || isSecondaryHost(type);
};

const formatPassportInfo = (
    passportInformation?: PassportInformation,
    profile?: BaseUser,
): PassportInformation => {
    const firstName =
        passportInformation?.firstName || profile?.firstName || '';
    const lastName = passportInformation?.lastName || profile?.lastName || '';
    const gender = passportInformation?.gender || profile?.gender || '';
    const dateOfBirth = passportInformation?.dateOfBirth
        ? new Date(passportInformation.dateOfBirth)
        : profile?.dateOfBirth;

    return {
        firstName,
        lastName,
        passportNumber: passportInformation?.passportNumber || '',
        nationality: passportInformation?.nationality || '',
        issueDate: formatStringDate(passportInformation?.issueDate?.toString()),
        expirationDate: formatStringDate(
            passportInformation?.expirationDate?.toString(),
        ),
        dateOfBirth: dateOfBirth
            ? formatDateTime(dateOfBirth, 'MM/dd/yyyy', 'UTC')
            : undefined,
        gender,
    };
};

export {
    formatPassportInfo,
    isHost,
    isOperator,
    isPrimaryHost,
    isPrimaryTraveler,
    isSecondaryHost,
    isSecondaryTraveler,
    isSecondaryUser,
    isTraveler,
};
