import GoBackButton from '../shared/GoBackButton';
import {
    StyledMainContainer,
    StyledContentContainer,
    StyledActionContainer,
} from './BannerContent.styles';
import { Heading, Text } from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';

interface PendingBannerContentProps {
    title: string;
    description: string;
    actionProps?: {
        text: string;
        onClick: () => void;
    };
    goBackRoute?: string;
}

export const BannerContent = (
    props: PendingBannerContentProps,
): JSX.Element => {
    const { title, description, goBackRoute, actionProps } = props;
    return (
        <StyledMainContainer>
            <StyledContentContainer>
                <GoBackButton route={goBackRoute} />
                <div>
                    <Heading as={'h2'}>{title}</Heading>
                    <Text>{description}</Text>
                </div>
            </StyledContentContainer>
            {actionProps ? (
                <StyledActionContainer>
                    <Button variant='primary' onClick={actionProps.onClick}>
                        {actionProps.text}
                    </Button>
                </StyledActionContainer>
            ) : null}
        </StyledMainContainer>
    );
};

export default BannerContent;
