import { css } from '@emotion/css';

export const tripDaysDiv = () => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const dailyButton = () => css`
    padding: 0.3rem 0rem;
    width: 100%;
    > div {
        min-width: 105px;
        align-items: flex-start;
    }
`;

export const stickyContainer = () => css`
    position: -webkit-sticky;
    position: sticky;
    top: 0.2rem;
`;
