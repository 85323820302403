import FetchState from './utils/FetchState';
import { post, put, get } from './utils/api';
import { models } from '@trova-trip/trova-models';

interface HostEmailParameters {
    firstName: string;
    lastName: string;
}

type Trip = models.trips.Trip;

export type TripStoreSelector = { userTrips: { current: Trip } };
export type AdminTripStoreSelector = { trips: { current: Trip } };

const trips = FetchState.create(`api/admin/trips`);
const tripsMetadata = FetchState.create(`api/metadata/trips`);

const tripsMetadataReducer = tripsMetadata.getReducer();
const tripsReducer = trips.getReducer();

const clearTripCache = (tripId) =>
    post(`api/admin/cache/clear/trip-details/${tripId}`, undefined);

const acceptTermsAndUpdateTrip = (tripId, refIframe) =>
    post(`api/me/trips/${tripId}/accept-terms`, refIframe);

const acceptTravelerTerms = (tripId, refIframe) =>
    post(`api/me/trips/${tripId}/accept-traveler-terms`, refIframe);

const confirmMinorDisclaimer = (tripId, refIframe) =>
    post(`api/me/trips/${tripId}/minor-disclaimer`, refIframe);

const publishTrip = (tripId) =>
    post(`api/admin/files/website/trips/${tripId}/publish`, undefined);

const confirmBooking = (tripId, bookingId) =>
    put(`api/me/trip-details/${tripId}`, undefined, bookingId);

const getTripCosts = (tripId: string) => get(`api/me/trips/${tripId}/costs`);

const encodeEmailParams = (host: HostEmailParameters): HostEmailParameters => {
    return {
        firstName: encodeURIComponent(host.firstName),
        lastName: encodeURIComponent(host.lastName),
    };
};

const getEmailPreview = (
    tripId: string,
    emailHookId: string,
    host: HostEmailParameters,
) => {
    const { firstName, lastName } = encodeEmailParams(host);
    return get(
        `/api/me/trips/${tripId}/email-preview/${emailHookId}?firstName=${firstName}&lastName=${lastName}`,
    );
};

const publishTestEmail = (
    tripId: string,
    emailHookId: string,
    host: HostEmailParameters,
) => {
    const { firstName, lastName } = encodeEmailParams(host);
    return post(
        `/api/me/trips/${tripId}/email-preview/${emailHookId}?firstName=${firstName}&lastName=${lastName}`,
        undefined,
    );
};

const listTripDetails = () => {
    return get('/public/trip-details');
};

export {
    trips,
    tripsMetadata,
    tripsReducer,
    tripsMetadataReducer,
    clearTripCache,
    acceptTermsAndUpdateTrip,
    acceptTravelerTerms,
    confirmMinorDisclaimer,
    publishTrip,
    confirmBooking,
    getTripCosts,
    getEmailPreview,
    publishTestEmail,
    listTripDetails,
};
