import { validationSchemas } from '@trova-trip/trova-models';
import * as yup from 'yup';
import { FieldName, FieldPrefix, PackageLevel } from './constants';
import { AccommodationPrice } from './types';

const {
    validationMessages: { requiredMessage },
} = validationSchemas;

const {
    CreateItineraryInventoryItemInputSchema: CreateSchema,
    UpdateItineraryInventoryItemInputSchema: UpdateSchema,
    accommodationPriceSchema: AccommodationSchema,
} = validationSchemas;

type CreateValidationSchemaArgs = {};
type UpdateValidationSchemaArgs = { quantityAvailable?: number };

type ValidationSchemaArgs =
    | { action: 'create'; args?: CreateValidationSchemaArgs }
    | { action: 'update'; args?: UpdateValidationSchemaArgs };

const withSanitizedNumber = (schema: yup.BaseSchema): yup.BaseSchema => {
    const sanitizedSchema = schema.transform((val, orig) =>
        orig == '' ? undefined : val,
    );
    return sanitizedSchema;
};

const sanitizedAccommodationSchema: yup.SchemaOf<AccommodationPrice> = yup
    .object()
    .shape({
        single: withSanitizedNumber(AccommodationSchema.fields.single),
        double: withSanitizedNumber(AccommodationSchema.fields.double),
    })
    .optional();

const generateValidationSchema = ({ action, args }: ValidationSchemaArgs) => {
    const Schema = action === 'create' ? CreateSchema : UpdateSchema;

    const { preTransferPrice, postTransferPrice } = Schema.fields;

    const newSchemaShape = {
        [FieldName.Id]: yup.string().optional(),
        [FieldName.PackageLevel]: yup
            .object()
            .shape({
                label: yup.string(),
                value: yup.string().oneOf(Object.values(PackageLevel)),
            })
            .nullable()
            .required(requiredMessage('Package')),
        [FieldName.PreTransferPrice]: withSanitizedNumber(preTransferPrice),
        [FieldName.PostTransferPrice]: withSanitizedNumber(postTransferPrice),
        [FieldName.PreAccPrice]: sanitizedAccommodationSchema,
        [FieldName.PostAccPrice]: sanitizedAccommodationSchema,
    };

    const fieldsToOmit = [
        FieldName.PackageLevel,
        FieldName.PreTransferPrice,
        FieldName.PostTransferPrice,
        FieldName.PreAccPrice,
        FieldName.PostAccPrice,
    ];

    if (action === 'update' && args?.quantityAvailable) {
        const extraValidation = yup
            .number()
            .min(args.quantityAvailable, 'Trip Quantity can only be increased');

        newSchemaShape[FieldName.QtyAvailable] =
            Schema.fields.quantityAvailable.concat(extraValidation);
        fieldsToOmit.push(FieldName.QtyAvailable);
    }

    const schema = Schema.omit(fieldsToOmit).concat(
        yup.object().shape(newSchemaShape),
    );

    return yup.object().shape({
        [FieldPrefix]: yup.object().concat(schema),
    });
};

const generateCreateValidationSchema = () => {
    return generateValidationSchema({ action: 'create' });
};

const generateUpdateValidationSchema = (args: UpdateValidationSchemaArgs) => {
    return generateValidationSchema({ action: 'update', args });
};

export { generateCreateValidationSchema, generateUpdateValidationSchema };
