import FetchState from './utils/FetchState';
import { get } from './utils/api';

const currencies = FetchState.create(`api/admin/currencies`);
const currenciesMetadata = FetchState.create(`api/metadata/currencies`);

const currenciesMetadataReducer = currenciesMetadata.getReducer();
const currenciesReducer = currencies.getReducer();

const userCurrencies = FetchState.create(`api/me/currencies`);
const userCurrenciesReducer = userCurrencies.getReducer();

const currenciesList = () =>
    get(`api/me/currencies`);

export {
    currencies,
    currenciesMetadata,
    currenciesMetadataReducer,
    currenciesReducer,
    currenciesList,
    userCurrencies,
    userCurrenciesReducer
};
