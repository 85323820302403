import ConfirmationDialog, {
    ConfirmationDialogProps,
} from '../../../common/components/ConfirmationDialog';

const confirmationDialogTextProps = {
    title: 'Confirm Itinerary for this Trip',
    description:
        "You are about to confirm pricing. Before you do, please make sure all trip details and inclusions are updated as per the Host's request.",
    cancelButtonProps: { children: 'Edit Itinerary' },
    confirmButtonProps: { children: 'Continue to Pricing' },
};

interface ReviewTripConfirmationModalProps
    extends Pick<
        ConfirmationDialogProps,
        'onCancel' | 'onConfirmation' | 'open'
    > {}

const ReviewTripConfirmationModal = ({
    onCancel,
    onConfirmation,
    open,
}: ReviewTripConfirmationModalProps) => {
    return (
        <ConfirmationDialog
            onCancel={onCancel}
            open={open}
            onConfirmation={onConfirmation}
            {...confirmationDialogTextProps}
        />
    );
};

export default ReviewTripConfirmationModal;
