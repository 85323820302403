import {
    BaseComboBoxOption,
    Chip,
    ComboBox,
    ComboBoxProps,
    Stack,
    Text,
} from '@trova-trip/trova-components';
import { GeoLocationComboBoxField } from '@trova-trip/trova-components/build/custom';
import {
    EditFieldProps,
    HostProfileResponse,
    PreviewProps,
} from '../../utils/types';
import ChipsDisplay from '../ChipsDisplay';

interface CountryPreviewProps extends PreviewProps<'country'> {}

const { getCountryComboBoxOptions } = GeoLocationComboBoxField;
const COUNTRY_OPTIONS = getCountryComboBoxOptions();

const convertCountryToOption = (
    country: HostProfileResponse['country'],
): BaseComboBoxOption => {
    return {
        label: country?.name || '',
        value: country?.code || '',
    };
};
export const CountryPreview = ({
    country,
}: CountryPreviewProps): JSX.Element => {
    const countryName = country?.name;
    return countryName ? (
        <Chip icon='location' label={countryName} key={country.code} />
    ) : (
        <Text>No country selected.</Text>
    );
};

export const CountryEditField = ({
    fieldData = {},
    onChange,
}: EditFieldProps<'country'>): JSX.Element => {
    const onCountrySelect: ComboBoxProps['onChange'] = (event) => {
        const value = event.metadata.value as BaseComboBoxOption;

        onChange({
            name: value.label,
            code: value.value,
        });
    };

    const onCountryRemove = () => {
        onChange(undefined);
    };

    const chipList = fieldData?.name ? [fieldData?.name] : [];
    return (
        <Stack direction='column'>
            <ChipsDisplay list={chipList} onRemove={onCountryRemove} />
            <Stack width='full'>
                <ComboBox
                    isSearchable
                    dropdownIndicator='search'
                    name='countries'
                    options={COUNTRY_OPTIONS}
                    value={convertCountryToOption(fieldData)}
                    onChange={onCountrySelect}
                    maxWidth='full'
                />
            </Stack>
        </Stack>
    );
};
