import { Grid } from '@material-ui/core';
import { coreUtils, PricingCalculator } from '@trova-trip/trova-common';
import {
    BaseBox,
    BaseComboBoxOption,
    ComponentWidth,
    Dropdown,
    Heading,
    IconButton,
    Input,
    Number as NumberInput,
    Text,
} from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import { constants, models } from '@trova-trip/trova-models';
import {
    findInventoryItemById,
    findInventoryItemByStartDate,
    itineraryHasHostGroundTransferCost,
} from '../../../common/helpers';
import isValid from 'date-fns/isValid';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import {
    ChangeEvent,
    SyntheticEvent,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import * as yup from 'yup';
import { analyticsTypes, withAnalytics } from '../../../../analytics';
import Snackbar from '../../../../components/Snackbar/Snackbar';
import { ServiceTypesKeys } from '../../../../config/constants';
import { DropdownOption } from '../../../../interfaces/FormComponents';
import FakeEvent from '../../../../util/form/FakeEvent';
import ConfirmationDialog from '../../../common/components/ConfirmationDialog';
import { useSuggestedSellPrice } from '../../hooks/useSuggestedSellPrice';
import { useTripRequestPricing } from '../../hooks/useTripRequestPricing';
import AccommodationsForm from '../AccommodationsForm';
import { getBedsQuantityForRooms } from '../AccommodationsForm/AccommodationsForm.helpers';
import HostSelectedOptionalServicesForm from '../HostSelectedOptionalServicesForm';
import AdditionalRequests from './AdditionalRequests';
import TripRequestStartDateCard from './components/CalendarCard/TripRequestStartDateCard';
import OptionalActivitiesInput from './OptionalActivitiesInput';
import { styledMainContainer } from './TripRequestForm.styles';
import {
    AvailabilityByPackage,
    COMPANION_AGE_RANGE,
    FormInputNames,
    HandleHostSelectedOptionalServicesArgs,
    HostSelectedOptionalService,
    InitialFormData,
    InitialTripRequestData,
    ItineraryInventoryItemViewModel,
    PackageLevel,
} from './TripRequestForm.types';
import DietaryRestrictionsComboBox from './TripRequestFormDietaryComboBox';
import {
    addService,
    checkIsInstantApprovalAvailable,
    getPricedServices,
    isInstantApproval,
    prepareAvailabilityViewModel,
    removeOldService,
    removeService,
    shouldValidateInstantApproval,
} from './tripRequestFormHelper';
import TripRequestPackagesContainer from './TripRequestPackagesContainer';
import { createTripData } from './utils/tripData';
import WorkshopsInput from './WorkshopsInput';
import PackageInventoryItemsCard from './components/PackageInventoryItemsCard';

const {
    collectionUtils: { InMemoryListManager },
    dateUtils: { getZeroUTCResetDateObj },
} = coreUtils;

type InMemoryListManager = coreUtils.collectionUtils.InMemoryListManager;
type TransientItemType = coreUtils.collectionUtils.TransientItemType;
type WorkshopSpace = models.services.WorkshopSpace;
type SuggestedSellPrice = models.itineraries.SuggestedSellPrice;
type ItineraryInventoryItem =
    models.itineraryInventoryItems.SavedItineraryInventoryItem;
export type HandleSelectInventoryItem = (
    item: ItineraryInventoryItemViewModel,
) => void;

const { ServiceType } = constants.services;
const packageLevels = constants.itinerary.PackageLevel;

interface ButtonWithAnalyticsProps
    extends analyticsTypes.WithAnalyticsProps,
        React.ComponentProps<typeof Button> {}

export const ButtonWithAnalytics =
    withAnalytics<ButtonWithAnalyticsProps>(Button);

interface RemoveButtonWithAnalyticsProps
    extends analyticsTypes.WithAnalyticsProps,
        React.ComponentProps<typeof IconButton> {}

export const RemoveButtonWithAnalytics =
    withAnalytics<RemoveButtonWithAnalyticsProps>(IconButton);

interface ConfirmationDialogWithAnalyticsProps
    extends analyticsTypes.WithAnalyticsProps,
        React.ComponentProps<typeof ConfirmationDialog> {}

const ConfirmationDialogWithAnalytics =
    withAnalytics<ConfirmationDialogWithAnalyticsProps>(ConfirmationDialog);

type Companion = models.common.Companion;
type HostRoom = models.tripRequest.HostRoom;
type Service = models.services.Service;

const formSchema = yup.object().shape({
    companions: yup
        .array()
        .of(
            yup.object().shape({
                ageRange: yup.string().required(),
                lastName: yup.string().required(),
                firstName: yup.string().required(),
            }),
        )
        .optional(),
});

const useThemeDropdownOptions = (payload: string[]): DropdownOption[] =>
    useMemo(() => {
        return payload.map((arrayItem: string) => {
            return {
                value: arrayItem,
                children: arrayItem,
            };
        });
    }, [payload]);

const { PackageFoodOptions } = constants.itinerary;

interface TripRequestFormProps {
    onChangePrice: (price: SuggestedSellPrice, fetching?: boolean) => void;
    onSubmit: (formData: InitialFormData) => void;
    travelersTierNumber: number;
    initialTripRequestData: InitialTripRequestData;
    itineraryAvailability: AvailabilityByPackage;
    showDialogBeforeSubmit: boolean;
    editMode: boolean;
    showAdditionalRequests?: boolean;
    isSubmitting?: boolean;
}

const ServiceDescription = (props: { children: string }): JSX.Element => {
    const { children } = props;
    return (
        <BaseBox marginTop={2} marginBottom={6}>
            <Text>{children}</Text>
        </BaseBox>
    );
};

const FormSectionTitle = (props: { children: string }): JSX.Element => {
    const { children } = props;
    return (
        <BaseBox marginTop={6}>
            <Heading as={'h4'}>{children}</Heading>
        </BaseBox>
    );
};

const TripRequestForm = ({
    onChangePrice,
    onSubmit,
    travelersTierNumber,
    initialTripRequestData,
    itineraryAvailability,
    showDialogBeforeSubmit,
    editMode,
    showAdditionalRequests = false,
    isSubmitting = false,
}: TripRequestFormProps): JSX.Element | null => {
    const [snackBar, setSnackBar] = useState({
        message: '',
        color: 'info',
        show: false,
    });

    const [errors, setErrors] = useState<any>({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const companionsManager = useRef(new InMemoryListManager());
    const [companionList, setCompanionList] = useState<TransientItemType[]>([]);
    const { itinerary, initialFormData } = initialTripRequestData;

    const { servicesByDay, additionalOptionalServices, packages } = itinerary;
    const [hostRooms, setHostRooms] = useState<HostRoom[]>(
        initialFormData.hostRooms,
    );

    const [instantApprovalStatus, setInstantApprovalStatus] =
        useState<boolean>(false);
    const tripLength: number | undefined = itinerary.servicesByDay?.length;

    // This ref stores the last selected start date of each package.
    const startDateMemo = useRef<Record<PackageLevel, string | undefined>>({
        [packageLevels.ECONOMY]: undefined,
        [packageLevels.STANDARD]: undefined,
        [packageLevels.PREMIUM]: undefined,
    });

    const updateCompanionListWithLatest = (): void => {
        const { current: manager } = companionsManager;
        const newList = manager.listItems();
        setCompanionList(newList);
    };

    useEffect(() => {
        const { current: manager } = companionsManager;
        manager.pushItems(initialFormData.companions);
        updateCompanionListWithLatest();
    }, [initialFormData.companions]);

    useEffect(() => {
        const { current: manager } = companionsManager;
        if (!manager) return;
        manager.clearAll();
    }, []);

    const availabilityViewModel = prepareAvailabilityViewModel(
        itinerary,
        itineraryAvailability,
    );
    const [formData, setFormData] = useState(initialFormData);
    const [roomsNumberError, setRoomsNumberError] = useState('');

    const { selectedPackage, dietaryRestriction } = formData;
    const {
        enabledDates,
        blackoutDates = [],
        firstAvailableDate,
        instantApprovalDates = [],
        isInventoryEnabled,
    } = availabilityViewModel[selectedPackage] || {};

    const selectedItineraryInventoryItem = useMemo<
        ItineraryInventoryItem | undefined
    >(() => {
        return findInventoryItemById(
            availabilityViewModel[selectedPackage]?.itineraryInventoryItems ||
                [],
            formData.itineraryInventoryItemId,
        );
    }, [
        availabilityViewModel,
        selectedPackage,
        formData.itineraryInventoryItemId,
    ]);

    const shouldRenderAdditionalHostServices = useMemo<boolean>(() => {
        if (itineraryHasHostGroundTransferCost(itinerary)) {
            return false;
        }

        if (selectedItineraryInventoryItem) {
            return Boolean(
                selectedItineraryInventoryItem.postTransferPrice ||
                    selectedItineraryInventoryItem.preTransferPrice,
            );
        }

        const pricedServices = getPricedServices(
            additionalOptionalServices as Service[],
        );
        return !!pricedServices.length;
    }, [additionalOptionalServices, selectedItineraryInventoryItem, itinerary]);

    const ageRangesDropdownOptions = useThemeDropdownOptions(
        Object.values(COMPANION_AGE_RANGE),
    );

    // Used to determine whether the currently selected package has instant approval
    const isInstantApprovalAvailable = checkIsInstantApprovalAvailable(
        availabilityViewModel[selectedPackage],
        itinerary,
    );

    const startDateFormatted = isValid(formData[FormInputNames.START_DATE])
        ? formData[FormInputNames.START_DATE].toISOString()
        : formData[FormInputNames.START_DATE];

    const initialTripStartDate =
        editMode &&
        isInstantApprovalAvailable &&
        !isInventoryEnabled &&
        selectedPackage === packageLevels.STANDARD
            ? instantApprovalDates[0]
            : startDateFormatted;

    useEffect(() => {
        // Check whether the selected date is instant approval
        if (isInstantApprovalAvailable && editMode) {
            setInstantApprovalStatus(
                isInstantApproval(
                    instantApprovalDates,
                    getZeroUTCResetDateObj(initialTripStartDate),
                ),
            );
        } else {
            setInstantApprovalStatus(false);
        }
    }, [initialTripStartDate, editMode]);

    const handleFormSubmit = (): void => {
        if (showDialogBeforeSubmit) {
            setIsModalOpen(true);
        } else {
            handleSubmit();
        }
    };

    const handleDialogConfirm = (): void => {
        handleSubmit();
        setIsModalOpen(false);
    };

    const handleValidation = async (): Promise<boolean> => {
        let isBedQuantityValid = true;
        let areCompanionsValid = true;
        const totalCompanionFOCs = companionList.length + 1;
        if (getBedsQuantityForRooms(hostRooms) !== totalCompanionFOCs) {
            isBedQuantityValid = false;
        }

        try {
            const companions = companionList.map(
                ({ $tmodel }) => $tmodel as Companion,
            );
            await formSchema.validate(
                { ...formData, companions },
                {
                    abortEarly: true,
                },
            );
        } catch (e) {
            const errors = [e].reduce((acc, error) => {
                const { path: name } = error.params;
                return { ...acc, [name]: 'This field is required' };
            }, {});
            setErrors(errors);
            areCompanionsValid = false;
        } finally {
            return isBedQuantityValid && areCompanionsValid;
        }
    };

    const handleSubmit = async (): Promise<void> => {
        const isFormValid = await handleValidation();
        const companions = companionList.map(
            ({ $tmodel }) => $tmodel as Companion,
        );

        const payload: InitialFormData = {
            ...formData,
            companions,
            hostRooms,
        };

        if (isFormValid) {
            onSubmit(payload);
        } else {
            setSnackBar({
                message:
                    'Some entries require your attention before continuing.',
                color: 'danger',
                show: true,
            });
        }
    };

    const companions: models.common.Companion[] = companionList.map(
        ({ $tmodel }) => $tmodel as Companion,
    );

    const tripData: models.tripRequest.TripRequest = createTripData(
        itinerary,
        formData[FormInputNames.HOST_SELECTED_OPTIONAL_SERVICES],
        formData[FormInputNames.START_DATE],
        companions,
        hostRooms,
        formData[FormInputNames.SELECTED_PACKAGE],
        formData[FormInputNames.PRE_POST_ACCOMMODATION_SELECTION],
        selectedItineraryInventoryItem,
        tripLength,
    );

    const tripRequestCostDTO: Omit<
        PricingCalculator.TripRequestCostDTO,
        'host'
    > = {
        ...tripData,
        itineraryInventoryItem: selectedItineraryInventoryItem,
        selectedWorkshopSpaces: formData[FormInputNames.WORKSHOPS].map(
            (workshop) => ({ ...workshop, activity: workshop.service }),
        ),
        tripLength,
    };

    const tripPrice = useTripRequestPricing({
        itinerary,
        tripRequestCostDTO,
        travelersTierNumber,
        packageAvailability: availabilityViewModel[tripData.selectedPackage],
    });

    const { suggestedSellPrice, fetching: fetchingSuggestedSellPrice } =
        useSuggestedSellPrice({
            itinerary,
            tripRequestCostDTO,
            tripPrice,
        });

    useEffect(() => {
        if (suggestedSellPrice) {
            onChangePrice(
                suggestedSellPrice as SuggestedSellPrice,
                fetchingSuggestedSellPrice,
            );
        }
    }, [onChangePrice, suggestedSellPrice, fetchingSuggestedSellPrice]);

    useEffect(() => {
        setFormData(initialFormData);
    }, [itinerary]);

    const dietaryOptions: BaseComboBoxOption[] = useMemo(
        () =>
            packages[selectedPackage].foodOptions
                .map((elem) => ({
                    label: elem,
                    value: elem,
                }))
                .filter((elem) => elem.value !== null),
        [packages, selectedPackage],
    );

    const optionalActivities =
        servicesByDay?.flatMap(
            (day: models.services.DayService[], index: number) =>
                day
                    .filter(({ service }) => {
                        const actualService =
                            service as models.services.Service;
                        return (
                            actualService.type === ServiceType.ACTIVITY &&
                            !actualService.includedActivity
                        );
                    })
                    .map((data) => ({ ...data, day: index + 1 })),
        ) || [];

    const workshopsByDay =
        servicesByDay?.map((day: models.services.DayService[]) =>
            day.filter(
                (
                    dayService,
                ): dayService is models.services.DayService<WorkshopSpace> => {
                    const { service } = dayService;
                    const actualService = service as models.services.Service;
                    return (
                        actualService.type === ServiceTypesKeys.WORKSHOP_SPACE
                    );
                },
            ),
        ) || [];

    const handleHostSelectedOptionalServices = ({
        service,
        oldServiceId,
        name,
        value,
    }: HandleHostSelectedOptionalServicesArgs): void => {
        setFormData((currentData) => {
            let services: HostSelectedOptionalService[] = removeOldService(
                oldServiceId,
                currentData,
            );

            const newServiceIndex = services.findIndex(
                (newService) => newService.id === service._id,
            );

            services =
                newServiceIndex === -1
                    ? addService(services, service, companions, hostRooms)
                    : removeService(services, service._id);

            return {
                ...currentData,
                hostSelectedOptionalServices: services,
            };
        });

        if (selectedItineraryInventoryItem) {
            setFormData((currentData) => ({
                ...currentData,
                prePostTransfersSelection: {
                    ...currentData.prePostTransfersSelection,
                    [name]: value,
                },
            }));
        }
    };

    const handleNumberInputChange = (
        _: ChangeEvent<HTMLInputElement>,
        name: string,
        value: number,
    ): void => {
        setFormData((currentData) => ({ ...currentData, [name]: value }));
    };

    const handleTextInputChange = (
        event: SyntheticEvent,
        inputName: string,
    ): void => {
        const value = (event.target as HTMLInputElement).value;
        setFormData((currentData) => {
            return { ...currentData, [inputName]: value };
        });
    };

    const handleStartDateInputChange = (
        event: Date | string,
        inputName: string,
        isInstantApproval?: boolean,
    ): void => {
        const value = typeof event === 'string' ? new Date(event) : event;

        const instantApprovalStatus =
            !!isInstantApproval &&
            checkIsInstantApprovalAvailable(
                availabilityViewModel[selectedPackage],
                itinerary,
            );

        const ISODate = value.toISOString();
        startDateMemo.current[selectedPackage] = ISODate;

        setInstantApprovalStatus(instantApprovalStatus);
        setFormData((currentData) => {
            return { ...currentData, [inputName]: value };
        });
    };

    const handleSelectInventoryItemByDate = (
        date: Date | string,
        packageLevel?: PackageLevel,
    ) => {
        if (!isInventoryEnabled) {
            return;
        }

        const currentPackage = packageLevel ?? selectedPackage;
        const currentAvailability = availabilityViewModel[currentPackage];

        const items = currentAvailability?.itineraryInventoryItems || [];
        const newItem = findInventoryItemByStartDate(items, date);

        setFormData((currentData) => {
            return {
                ...currentData,
                itineraryInventoryItemId: newItem?._id,
            };
        });
    };

    const handleSelectInventoryItem: HandleSelectInventoryItem = (item) => {
        setFormData((currentData) => {
            return {
                ...currentData,
                startDate: item.startDate.iso,
                itineraryInventoryItemId: item.id,
            };
        });
    };

    const handleServicesInputChange = (event: FakeEvent): void => {
        const target = event.target as HTMLInputElement;

        setFormData((currentData) => {
            return { ...currentData, [target.name]: target.value };
        });
    };

    const handleWorkShopSpacesChange = (value): void => {
        setFormData((currentData) => {
            return { ...currentData, selectedWorkshopSpaces: value };
        });
    };

    const handleRoomsChange = (
        _: ChangeEvent<HTMLInputElement>,
        name: string,
        value: number,
    ): void => {
        const numberOfHostsAndCompanions = companionList.length + 1;
        const numberOfRoomsErrorMessage = `${numberOfHostsAndCompanions} Room(s) Limit`;
        if (value && value > numberOfHostsAndCompanions) {
            setRoomsNumberError(numberOfRoomsErrorMessage);
        } else {
            setRoomsNumberError('');
            handleNumberInputChange(_, name, value);
        }
    };

    const handleAddCompanion = (): void => {
        const { current: manager } = companionsManager;
        manager.addItem({
            firstName: '',
            lastName: '',
            ageRange: '',
        });
        updateCompanionListWithLatest();
    };

    const handleChangeCompanion = (
        name: string,
        value: string,
        key: symbol,
    ): void => {
        const { current: manager } = companionsManager;
        const companion = manager.getItem(key);
        if (!companion) return;
        manager.updateItem(key, {
            ...companion,
            $tmodel: {
                ...companion?.$tmodel,
                [name]: value,
            },
        });
        updateCompanionListWithLatest();
    };

    const handleRemoveCompanion = (key): void => {
        const { current: manager } = companionsManager;
        manager.deleteItem(key);
        updateCompanionListWithLatest();
    };

    const handlePackageSelected = (nextPackage: PackageLevel) => {
        // If instant approval, replace first available date with earliest instant approval date
        const instantApprovalDates =
            availabilityViewModel[nextPackage].instantApprovalDates;

        let nextDate = instantApprovalDates.length
            ? instantApprovalDates[0]
            : availabilityViewModel[nextPackage].firstAvailableDate;

        if (isInventoryEnabled) {
            const dateInMemo = startDateMemo.current[nextPackage];
            nextDate = dateInMemo ?? nextDate;
        }

        handleSelectInventoryItemByDate(nextDate, nextPackage);

        setFormData((currentData) => ({
            ...currentData,
            [FormInputNames.SELECTED_PACKAGE]: nextPackage,
            [FormInputNames.START_DATE]: new Date(nextDate),
            dietaryRestriction: PackageFoodOptions.NONE,
        }));
    };

    return (
        <Grid container spacing={32} className={styledMainContainer}>
            <TripRequestPackagesContainer
                tripRequestCostDTO={tripRequestCostDTO}
                onPackageSelected={handlePackageSelected}
                travelersTierNumber={travelersTierNumber}
                packages={packages}
                itinerary={itinerary}
                selectedPackage={selectedPackage}
                isDisabled={!editMode}
                itineraryAvailability={availabilityViewModel}
            />
            <Grid item xs={12}>
                {isInventoryEnabled ? (
                    <PackageInventoryItemsCard
                        handleSelectInventoryItem={handleSelectInventoryItem}
                        selectedInventoryItemId={
                            formData.itineraryInventoryItemId
                        }
                        availabilityViewModel={availabilityViewModel}
                        selectedPackage={selectedPackage}
                        tripLength={tripLength}
                    />
                ) : (
                    <TripRequestStartDateCard
                        startDate={firstAvailableDate as string}
                        selectedDate={initialTripStartDate}
                        editMode={editMode}
                        disabledDates={blackoutDates as string[]}
                        enabledDates={enabledDates}
                        instantApprovalDates={instantApprovalDates as string[]}
                        onChange={(event, name, isInstantApproval): void => {
                            handleStartDateInputChange(
                                event,
                                name,
                                isInstantApproval,
                            );
                        }}
                        tripLength={tripLength || 1}
                        showInstantApproval={isInstantApprovalAvailable}
                        selectedPackage={selectedPackage}
                    />
                )}
            </Grid>
            <Grid item xs={12} sm={6} lg={5} xl={3}>
                <DietaryRestrictionsComboBox
                    dietaryOptions={dietaryOptions}
                    editMode={editMode}
                    dietaryRestriction={dietaryRestriction}
                    setFormData={(value) =>
                        setFormData((formData) => ({
                            ...formData,
                            ...value,
                        }))
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Heading as={'h4'}>Companions</Heading>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={32}>
                    {companionList.length === 0 && (
                        <Grid item xs={12} sm={6}>
                            <Text>
                                Add any companion travelers you&apos;d like to
                                accompany you on your trip. Everything included
                                on the itinerary is included for you and your
                                companions, but keep in mind that adding
                                companions will increase the base cost of your
                                trip.
                            </Text>
                        </Grid>
                    )}
                    {companionList.map(
                        ({ $tkey, $tmodel: companion }, index) => (
                            <Grid item key={$tkey.toString()} xs={12}>
                                <Grid
                                    container
                                    alignItems='center'
                                    spacing={32}
                                >
                                    <Grid item xs={'auto'}>
                                        <Input
                                            name='firstName'
                                            type='firstName'
                                            value={companion.firstName}
                                            onChange={(event): void => {
                                                const target =
                                                    event.target as HTMLInputElement;
                                                handleChangeCompanion(
                                                    target.name,
                                                    target.value,
                                                    $tkey,
                                                );
                                            }}
                                            error={
                                                errors?.[
                                                    `companions[${index}].firstName`
                                                ]
                                            }
                                            label='First Name'
                                            size={ComponentWidth.Small}
                                            disabled={!editMode}
                                        />
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <Input
                                            name='lastName'
                                            type='lastName'
                                            value={companion.lastName}
                                            onChange={(event): void => {
                                                const target =
                                                    event.target as HTMLInputElement;
                                                handleChangeCompanion(
                                                    target.name,
                                                    target.value,
                                                    $tkey,
                                                );
                                            }}
                                            error={
                                                errors[
                                                    `companions[${index}].lastName`
                                                ]
                                            }
                                            label='Last Name'
                                            placeholder=''
                                            size={ComponentWidth.Small}
                                            disabled={!editMode}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={'auto'}>
                                        <Dropdown
                                            label={'Age Range'}
                                            name={'ageRange'}
                                            onSearch={noop}
                                            searchable={false}
                                            placeholder={'Select'}
                                            size={ComponentWidth.Small}
                                            disabled={!editMode}
                                            onChange={(
                                                _,
                                                name,
                                                value,
                                            ): void => {
                                                handleChangeCompanion(
                                                    name as string,
                                                    value as string,
                                                    $tkey,
                                                );
                                            }}
                                            error={
                                                errors?.[
                                                    `companions[${index}].ageRange`
                                                ]
                                            }
                                            value={companion.ageRange}
                                        >
                                            {ageRangesDropdownOptions}
                                        </Dropdown>
                                    </Grid>
                                    <Grid item xs='auto'>
                                        {editMode && (
                                            <RemoveButtonWithAnalytics
                                                onClick={() => {
                                                    handleRemoveCompanion(
                                                        $tkey,
                                                    );
                                                }}
                                                icon='trash'
                                                aria-label='Remove companion'
                                                iconVariant='filled'
                                                iconColor='red.coral'
                                                size='md'
                                                variant='solid'
                                                marginTop={5}
                                                analyticsData={{
                                                    type: 'onClick',
                                                    eventName:
                                                        'Host Removed Companion',
                                                }}
                                            ></RemoveButtonWithAnalytics>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        ),
                    )}
                </Grid>
                <Grid container spacing={32}>
                    <Grid item xs={'auto'}>
                        {editMode && (
                            <ButtonWithAnalytics
                                variant='secondary'
                                rightIcon='plus'
                                onClick={handleAddCompanion}
                                marginTop={4}
                                analyticsData={{
                                    type: 'onClick',
                                    eventName: 'Host Added Companion',
                                }}
                            >
                                Add Companion
                            </ButtonWithAnalytics>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            {initialFormData.hostRooms.length > 0 ? (
                <Grid item xs={12}>
                    <AccommodationsForm
                        itinerary={itinerary}
                        onHostRoomsChange={setHostRooms}
                        initialValues={initialFormData.hostRooms}
                        totalCompanionFOCs={companionList.length + 1}
                        isReadOnly={!editMode}
                        prePostAccommodationsSelection={
                            formData[
                                FormInputNames.PRE_POST_ACCOMMODATION_SELECTION
                            ]
                        }
                        setFormData={setFormData}
                        itineraryInventoryItem={selectedItineraryInventoryItem}
                    />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <NumberInput
                        label={'Total Rooms Requested'}
                        name={FormInputNames.QUANTITY_OF_HOSTS_ROOMS}
                        value={formData[FormInputNames.QUANTITY_OF_HOSTS_ROOMS]}
                        onChange={handleRoomsChange}
                        minValue={1}
                        error={roomsNumberError}
                        disabled={!editMode}
                    />
                </Grid>
            )}
            {!isEmpty(workshopsByDay?.flat()) && (
                <Grid item xs={12}>
                    <FormSectionTitle>Reserve workshop space</FormSectionTitle>
                    <Grid item xs={12} sm={6}>
                        <ServiceDescription>
                            Personalize your trip by offering optional workshops
                            for your travelers. If you choose to include a
                            workshop, there is an additional cost.
                        </ServiceDescription>
                    </Grid>

                    <WorkshopsInput
                        workShops={workshopsByDay}
                        selectedWorkshopSpaces={
                            formData[FormInputNames.WORKSHOPS]
                        }
                        onChange={handleWorkShopSpacesChange}
                        editMode={editMode}
                    />
                </Grid>
            )}
            {!isEmpty(optionalActivities) && (
                <Grid item xs={12}>
                    <FormSectionTitle>Optional Activities</FormSectionTitle>
                    <Grid item xs={12} sm={6}>
                        <ServiceDescription>
                            Optional activities are offered to travelers for an
                            additional fee. As the Host, you can choose to
                            participate as well, free of charge.
                        </ServiceDescription>
                    </Grid>
                    <OptionalActivitiesInput
                        optionalActivities={optionalActivities}
                        hostSelectedOptionalActivities={
                            formData[
                                FormInputNames.HOST_SELECTED_OPTIONAL_SERVICES
                            ]
                        }
                        numberOfHostsAndCompanions={companionList.length + 1}
                        onChange={handleServicesInputChange}
                        editMode={editMode}
                    />
                </Grid>
            )}
            {shouldRenderAdditionalHostServices ? (
                <>
                    <Grid item xs={12}>
                        <FormSectionTitle>
                            Additional Host Services
                        </FormSectionTitle>
                        <Grid item xs={12} sm={6}>
                            <ServiceDescription>
                                Select what services you will need before and/or
                                after your trip.
                            </ServiceDescription>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <HostSelectedOptionalServicesForm
                            selectedOptionalServices={
                                formData.hostSelectedOptionalServices
                            }
                            handleHostSelectedOptionalServicesChange={
                                handleHostSelectedOptionalServices
                            }
                            isReadOnly={!editMode}
                            optionalServices={
                                additionalOptionalServices as Service[]
                            }
                            itineraryInventoryItem={
                                selectedItineraryInventoryItem
                            }
                            prePosTransfersSelection={
                                formData[
                                    FormInputNames.PRE_POST_TRANSFER_SELECTION
                                ]
                            }
                        />
                    </Grid>
                </>
            ) : null}

            {showAdditionalRequests ? (
                <Grid item xs={12}>
                    <AdditionalRequests
                        onChange={handleTextInputChange}
                        name={FormInputNames.ADDITIONAL_REQUESTS}
                        value={formData.additionalRequests}
                        disabled={!editMode}
                        instantApprovalAllowed={shouldValidateInstantApproval(
                            selectedPackage,
                            instantApprovalStatus,
                            availabilityViewModel[selectedPackage],
                        )}
                    />
                </Grid>
            ) : null}

            <Grid item xs={12}>
                <Button
                    variant='primary'
                    onClick={handleFormSubmit}
                    isDisabled={isSubmitting || !editMode}
                    isLoading={isSubmitting}
                >
                    Reserve
                </Button>
            </Grid>
            <ConfirmationDialogWithAnalytics
                onCancel={(): void => {
                    setIsModalOpen(false);
                }}
                onConfirmation={handleDialogConfirm}
                open={isModalOpen}
                title={'Ready to request your trip?'}
                description={
                    'This is an official request for you TrovaTrip. Please make sure ' +
                    'dates, itinerary, companions, add-ons, and price are correct.'
                }
                analyticsData={{
                    type: 'onConfirmation',
                    eventName: 'Host Clicked Submit Trip Request',
                }}
            />
            <Snackbar
                place='tr'
                color={snackBar.color}
                message={snackBar.message}
                open={snackBar.show}
                autoHideDuration={4000}
                onClose={(): void => {
                    setSnackBar({
                        message: '',
                        color: 'info',
                        show: false,
                    });
                }}
            />
        </Grid>
    );
};

export default TripRequestForm;
