import { Button } from '@trova-trip/trova-components/build/next';
import { useWindowHostNameAndProtocol } from 'util/hooks/useWindowHostname';

interface TravelerManifestDocumentDownloadButtonProps {
    tripId: string;
    className?: string;
}

const TravelerManifestDocumentDownloadButton = ({
    tripId,
    className,
}: TravelerManifestDocumentDownloadButtonProps): JSX.Element => {
    const domain = useWindowHostNameAndProtocol();

    return (
        <div className={className}>
            <Button
                as='a'
                linkProps={{
                    href: `${domain}/api/me/trips/${tripId}/traveler-manifest`,
                    download: true,
                }}
                variant='tertiary'
                rightIcon='download'
            >
                Download Manifest
            </Button>
        </div>
    );
};

export default TravelerManifestDocumentDownloadButton;
