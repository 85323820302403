import { coreUtils } from '@trova-trip/trova-common';
import {
    BaseBox,
    Stack,
    Text,
    TextDivider,
} from '@trova-trip/trova-components';
import {
    Icon,
    IconProps,
    SelectableCard,
} from '@trova-trip/trova-components/build/next';
import { forwardRef } from 'react';
import { HandleSelectInventoryItem } from '../TripRequestForm';
import {
    AvailabilityViewModel,
    ItineraryInventoryItemStatus,
    ItineraryInventoryItemViewModel,
} from '../TripRequestForm.types';

const formatUSD = coreUtils.currencyUtils.formatUSD;

const StatusMap: Record<
    ItineraryInventoryItemStatus,
    {
        color: IconProps['color'];
        icon: IconProps['as'];
        text: string;
    }
> = {
    [ItineraryInventoryItemStatus.AVAILABLE]: {
        color: 'alerts.success.icon',
        icon: 'check',
        text: 'Available',
    },
    [ItineraryInventoryItemStatus.RESERVED]: {
        color: 'alerts.warning.icon',
        icon: 'accessTime',
        text: 'Reserved',
    },
    [ItineraryInventoryItemStatus.CONFIRMED]: {
        color: 'alerts.error.icon',
        icon: 'cancel',
        text: 'Confirmed',
    },
    [ItineraryInventoryItemStatus.UNKNOWN]: {
        color: 'gray',
        icon: 'help',
        text: 'Unknown',
    },
};

const Separator = () => <Text fontSize={{ xs: 'lg', md: '3xl' }}>-</Text>;

const PriceDetail = ({ price }): JSX.Element => {
    return (
        <Stack
            direction='column'
            wrap='nowrap'
            align='flex-end'
            spacing={0}
            paddingX={{ md: 3 }}
            minWidth={{ xs: '46px', md: '100px' }}
        >
            <Text color='blueGray.500' fontSize={{ xs: 'xs', md: 'sm' }}>
                Starting at
            </Text>
            <Text
                color='blueGray.800'
                fontSize={{ xs: 'base', md: 'lg' }}
                fontWeight='medium'
            >
                {formatUSD(price)}
            </Text>
        </Stack>
    );
};

interface DateDetailProps {
    weekday: string;
    date: string;
}

const DateDetail = ({ weekday, date }: DateDetailProps): JSX.Element => (
    <Stack
        direction='column'
        wrap='nowrap'
        spacing={0}
        paddingX={{ md: 3 }}
        minWidth={{ xs: '46px', md: '100px' }}
        align='flex-start'
    >
        <Text
            color='blueGray.500'
            fontSize={{ xs: 'xs', md: 'sm' }}
            display={{
                xs: 'none',
                md: 'block',
            }}
        >
            {weekday}
        </Text>
        <Text
            color='blueGray.800'
            fontSize={{ xs: 'sm', md: 'lg' }}
            fontWeight='medium'
        >
            {date}
        </Text>
    </Stack>
);

interface DatesProps {
    startDate: ItineraryInventoryItemViewModel['startDate'];
    endDate: ItineraryInventoryItemViewModel['endDate'];
}

const Dates = ({ startDate, endDate }: DatesProps): JSX.Element => {
    return (
        <Stack
            direction='row'
            align='center'
            wrap='nowrap'
            spacing={{
                xs: 1,
                sm: 2,
                md: 4,
            }}
            minWidth={{ xs: '40%', md: undefined }}
        >
            <DateDetail
                weekday={startDate.weekday}
                date={startDate.formatted}
            />
            <Separator />
            <DateDetail weekday={endDate.weekday} date={endDate.formatted} />
        </Stack>
    );
};

interface IconWithTextProps {
    icon: IconProps['as'];
    color: IconProps['color'];
    text: string;
}

const IconWithText = ({
    color,
    icon,
    text,
}: IconWithTextProps): JSX.Element => {
    return (
        <Stack align='center' spacing={1} wrap='nowrap'>
            <Icon
                as={icon}
                color={color}
                display='inline-flex'
                size={{
                    xs: 'xss',
                    md: 'md',
                }}
            />
            <Text
                fontSize={{
                    xs: 'xs',
                    md: 'sm',
                }}
                color='blueGray.650'
            >
                {text}
            </Text>
        </Stack>
    );
};

const Status = ({ status }) => <IconWithText {...StatusMap[status]} />;

interface InstantApprovalProps {
    isVisible: boolean;
    variant?: 'icon' | 'iconAndLabel';
}

const InstantApproval = ({
    isVisible,
    variant = 'icon',
}: InstantApprovalProps): JSX.Element | null => {
    if (!isVisible) return null;

    return variant === 'iconAndLabel' ? (
        <BaseBox
            display={{
                xs: 'none',
                md: 'flex',
            }}
        >
            <Stack
                align='center'
                spacing={{
                    xs: 0,
                    md: 1,
                }}
                wrap='nowrap'
            >
                <Icon
                    as='bolt'
                    color='teal.trova'
                    display='inline-flex'
                    size={{
                        xs: 'xss',
                        md: 'md',
                    }}
                />
                <Text
                    fontSize={{
                        xs: 'xs',
                        md: 'sm',
                    }}
                    color='blueGray.650'
                >
                    Instant Approval
                </Text>
            </Stack>
        </BaseBox>
    ) : (
        <Icon
            as='bolt'
            color='teal.trova'
            display={{
                base: 'inline-flex',
                md: 'none',
            }}
            size={{
                xs: 'xss',
                md: 'md',
            }}
        />
    );
};

interface InventoryItemProps {
    item: ItineraryInventoryItemViewModel;
    onSelect: HandleSelectInventoryItem;
    isSelected: boolean;
}

const InventoryItem = ({
    onSelect,
    isSelected,
    item,
}: InventoryItemProps): JSX.Element => {
    const { startDate, endDate, status, price, isInstantApproval } = item;
    const isNotAvailable = status !== ItineraryInventoryItemStatus.AVAILABLE;

    return (
        <SelectableCard
            onClick={(): void => onSelect(item)}
            width='full'
            spacing={{ base: 'lg', md: 'xl' }}
            colorScheme='light'
            isSelected={isSelected}
            isDisabled={isNotAvailable}
        >
            <Stack justify='space-between' wrap='nowrap' align='center'>
                <Stack
                    justify={{ xs: 'space-between', md: 'flex-start' }}
                    align='center'
                    wrap='nowrap'
                    spacing={{
                        xs: 0,
                        md: 12,
                    }}
                >
                    <Stack align='center' spacing={1}>
                        <Dates startDate={startDate} endDate={endDate} />
                        <InstantApproval isVisible={!!isInstantApproval} />
                    </Stack>
                    <Status status={status} />
                    <InstantApproval
                        variant='iconAndLabel'
                        isVisible={!!isInstantApproval}
                    />
                </Stack>
                <PriceDetail price={price} />
            </Stack>
        </SelectableCard>
    );
};

interface InventoryItemListProps {
    selectedInventoryItemId: string | undefined;
    inventoryItemsByYear: AvailabilityViewModel['itineraryInventoryItemsByYear'];
    handleSelectInventoryItem: HandleSelectInventoryItem;
    handleYearDividerRef: (ref: HTMLDivElement | null) => void;
}

const InventoryItemsList = forwardRef<HTMLDivElement, InventoryItemListProps>(
    (
        {
            selectedInventoryItemId,
            inventoryItemsByYear,
            handleYearDividerRef,
            handleSelectInventoryItem,
        },
        ref,
    ): JSX.Element => {
        return (
            <BaseBox
                marginTop={4}
                overflow='scroll'
                maxHeight='400px'
                ref={ref}
                position='relative'
            >
                {Object.entries(inventoryItemsByYear).map(
                    ([year, items], index) => (
                        <Stack
                            key={year}
                            direction='column'
                            wrap='nowrap'
                            width='full'
                            spacing={4}
                            marginTop={index > 0 ? 4 : 0}
                        >
                            {index > 0 ? (
                                <BaseBox
                                    ref={(ref): void =>
                                        handleYearDividerRef(ref)
                                    }
                                    width='full'
                                    data-year={year}
                                >
                                    <TextDivider width='full' margin={0}>
                                        {year}
                                    </TextDivider>
                                </BaseBox>
                            ) : null}
                            {items.map((item) => (
                                <InventoryItem
                                    key={item.id}
                                    onSelect={handleSelectInventoryItem}
                                    isSelected={
                                        item.id === selectedInventoryItemId
                                    }
                                    item={item}
                                />
                            ))}
                        </Stack>
                    ),
                )}
            </BaseBox>
        );
    },
);

InventoryItemsList.displayName = 'InventoryItemsList';

export default InventoryItemsList;
