import { BaseBox, BaseBoxProps, Checkbox } from '@trova-trip/trova-components';
import React, { ComponentProps } from 'react';
import { fieldNamesByServiceType } from '../../helpers';

type CheckboxProps = ComponentProps<typeof Checkbox>;

interface SaveToLibraryCheckboxProps
    extends Pick<CheckboxProps, 'value' | 'onChange' | 'disabled'>,
        Pick<BaseBoxProps, 'paddingTop'> {
    name?: string;
}

const SaveToLibraryCheckbox = ({
    name = fieldNamesByServiceType.activity.saveToLibrary,
    value,
    onChange,
    paddingTop = 4,
    disabled,
}: SaveToLibraryCheckboxProps) => {
    return (
        <BaseBox paddingTop={paddingTop}>
            <Checkbox
                name={name}
                label='Save to library'
                detail='Save to library'
                value={value}
                onChange={onChange}
                disabled={disabled}
            />
        </BaseBox>
    );
};
export default SaveToLibraryCheckbox;
