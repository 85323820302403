import { BaseBox, Grid, Stack, Text } from '@trova-trip/trova-components';
import { Icon } from '@trova-trip/trova-components/build/next';

export interface CheckListItem {
    title: string;
    description: string;
    isActive?: boolean;
}

export interface CheckListProps {
    items: CheckListItem[];
}

export const CheckList = ({ items }: CheckListProps) => (
    <BaseBox>
        {items.map((item, index) => (
            <Grid gap={2} key={index}>
                <Grid.Item rowStart={1} rowEnd={2} columnSpan={1}>
                    <Stack direction='column' align='center' marginTop={2}>
                        <Icon
                            display='inline-flex'
                            as='success'
                            variant='filled'
                            color={item.isActive ? 'teal.300' : 'neutral.90'}
                            size='md'
                        />
                        {items.length !== index + 1 && (
                            <BaseBox
                                marginLeft={4}
                                height='2.625rem'
                                borderLeft='.0625rem dashed'
                                borderColor='blueGray.300'
                            ></BaseBox>
                        )}
                    </Stack>
                </Grid.Item>
                <Grid.Item rowStart={1} rowEnd={1} columnSpan={11}>
                    <Stack direction='column' spacing={1} marginTop={1}>
                        <Text size='lg' fontWeight='medium'>
                            {item.title}
                        </Text>
                        <Text size='sm' color='blueGray.600'>
                            {item.description}
                        </Text>
                    </Stack>
                </Grid.Item>
            </Grid>
        ))}
    </BaseBox>
);

export default CheckList;
