import React from 'react';
import PropTypes from 'prop-types';
import TrovaImageUpload from '../../../components/CustomUpload/TrovaImageUpload';
import UtilityButton from './UtilityButton';
import ImageUpload from '../../../components/CustomUpload/ImageUpload';
import useGetFeatureValue from '../../../util/hooks/useGetFeatureValue';
import InputLabel from '@material-ui/core/InputLabel';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

const USE_NEW_IMAGE_UPLOADER_FEATURE = `USE_NEW_IMAGE_UPLOADER`;

const FileInput = ({
    label,
    name,
    value,
    onChange,
    onClickRemove,
    maxFiles,
    sortable,
    className = ``,
    uploadUrl,
    ...otherProps
}) => {
    const newImageUploader = useGetFeatureValue(USE_NEW_IMAGE_UPLOADER_FEATURE);

    if (newImageUploader === undefined) {
        return null;
    }

    if (newImageUploader) {
        return (
            <Card className={className}>
                <CardBody className='card-body'>
                    <InputLabel htmlFor={name}>{label}</InputLabel>
                    <ImageUpload
                        name={name}
                        value={value}
                        onChange={onChange}
                        imageAmountLimit={maxFiles}
                        sortable={sortable}
                        uploadUrl={uploadUrl}
                        {...otherProps}
                    />
                </CardBody>
            </Card>
        );
    }

    return (
        <React.Fragment>
            <TrovaImageUpload
                label={label}
                name={name}
                value={value}
                onChange={onChange}
                imageAmountLimit={maxFiles}
                {...otherProps}
            />
            {onClickRemove && (
                <UtilityButton onClick={onClickRemove} color={`danger`}>
                    --Remove
                </UtilityButton>
            )}
        </React.Fragment>
    );
};

FileInput.propTypes = {
    sortable: PropTypes.bool,
    label: PropTypes.string,
    classes: PropTypes.shape(),
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    onChange: PropTypes.func,
    onClickRemove: PropTypes.func,
    maxFiles: PropTypes.string,
    className: PropTypes.string,
    uploadUrl: PropTypes.string,
};
export default FileInput;
