import { models, constants } from '@trova-trip/trova-models';
import usePublishSystem from 'applications/common/hooks/trips/usePublishSystem';
import { createContext } from 'react';

type Trip = models.trips.Trip;

interface TripContext {
    triggerChangeOnTripPublishData: () => Promise<Trip | undefined>;
}

export const TripContext = createContext<TripContext>({} as TripContext);

const TripsContextProvider: React.FC = ({ children }): JSX.Element => {
    const { updatePublishStatus } = usePublishSystem();

    const triggerChangeOnTripPublishData = (): Promise<Trip | undefined> => {
        return updatePublishStatus(constants.trips.TRIP_PUBLISH_STATUS.PENDING);
    };

    return (
        <TripContext.Provider value={{ triggerChangeOnTripPublishData }}>
            {children}
        </TripContext.Provider>
    );
};

export default TripsContextProvider;
