import Tab from '../../../../../navigation/Tab';
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ItineraryReviewPricingForm from './ItineraryReviewPricingForm';
import TabDetailsInformation from '../../../../../components/TabDetailsInformation/TabDetailsInformation';
import ItineraryReviewPricingNewForm from './ItineraryReviewPricingNewForm';

const ItineraryReviewPricing = ({
    data,
    saveModelData,
    updateModelData,
    renderError,
    cleanErrors,
    errorText,
    disabled,
}) => {
    if (!data) {
        return (
            <Tab
                label={`3. Review pricing`}
                path={`/review-pricing`}
                disabled={true}
            >
                <div />
            </Tab>
        );
    }

    const { itineraryInventoryEnabled } = data;

    return (
        <Tab
            label={`3. Review pricing`}
            path={`/review-pricing`}
            disabled={disabled}
        >
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <TabDetailsInformation
                        title={
                            itineraryInventoryEnabled
                                ? 'Review Trip Pricing'
                                : 'Review pricing'
                        }
                        description={
                            itineraryInventoryEnabled
                                ? 'Check the price of your trip: Review the price is correct for the selected itinerary and trip dates.'
                                : 'Build your price. Add seasonality, date restrictions and passenger parameters below.'
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    {itineraryInventoryEnabled ? (
                        <ItineraryReviewPricingNewForm />
                    ) : (
                        <ItineraryReviewPricingForm
                            data={data}
                            saveModelData={saveModelData}
                            updateModelData={updateModelData}
                            renderError={renderError}
                            cleanErrors={cleanErrors}
                            errorText={errorText}
                        />
                    )}
                </Grid>
            </Grid>
        </Tab>
    );
};

ItineraryReviewPricing.propTypes = {
    data: PropTypes.shape(),
    saveModelData: PropTypes.func,
    updateModelData: PropTypes.func,
    renderError: PropTypes.func,
    errorText: PropTypes.string,
    cleanErrors: PropTypes.func,
    disabled: PropTypes.bool,
};

export default ItineraryReviewPricing;
