import StickyContentLayout from 'components/StickyContentLayout/StickyContentLayout';
import ReviewTripTabStickyLayoutContent from './ReviewTripTabStickyLayoutContent';

type ReviewTripTabStickyLayoutProps = {
    scrollToDay: (dayIndex: number) => void;
    children: React.ReactNode;
};

const ReviewTripTabStickyLayout: React.FC<ReviewTripTabStickyLayoutProps> = ({
    scrollToDay,
    children,
}: ReviewTripTabStickyLayoutProps): JSX.Element => (
    <StickyContentLayout
        stickyContent={
            <ReviewTripTabStickyLayoutContent scrollToDay={scrollToDay} />
        }
        mainContent={
            <StickyContentLayout observableContent>
                {children}
            </StickyContentLayout>
        }
    />
);

export default ReviewTripTabStickyLayout;
