import { constants, models } from '@trova-trip/trova-models';
import { Button } from '@trova-trip/trova-components/build/next';
import ConfirmationDialog from 'applications/common/components/ConfirmationDialog';
import useServicesByDay from 'applications/common/hooks/services/useServicesByDay';
import { TripContext } from 'applications/host/products/trips/TripsContext';
import { StyledSection } from 'components/DailyActivities/DailyActivities.components';
import DaySectionTitle from 'components/DailyActivities/DayTitle';
import Snackbar from 'components/Snackbar/Snackbar';
import { useCallback, useContext, useMemo, useState } from 'react';
import createDayRefName from 'util/createDayRefName';
import { ServiceTypesKeys } from '../../../../config/constants';
import { ServicesByDayUIModel } from '../../../../interfaces/UiModels.types';
import { styledAddButtonWrapper } from './TripServicesList.styles';
import TripServicesListItem from './TripServicesListItem';

type Service = models.services.Service;
type WorkshopSpace = models.services.WorkshopSpace;
type ServiceType = constants.services.ServiceType;
const { ServiceType } = constants.services;

interface TripServicesListProps {
    tripServicesByDay: ServicesByDayUIModel;
    tripDates: string[];
}

const TripServicesList = ({
    tripServicesByDay,
    tripDates,
}: TripServicesListProps): JSX.Element => {
    const { triggerChangeOnTripPublishData } = useContext(TripContext);

    const mappedServicesByDay: ServicesByDayUIModel = useMemo(() => {
        return tripServicesByDay.map((days, dayIndex) => {
            return days.map(({ service, ...dayRest }) => {
                const readOnly = ![
                    ServiceType.WORKSHOP_SPACE,
                    ServiceType.INFORMAL_GATHERING,
                ].includes(service.type);
                const isEditing =
                    (service as WorkshopSpace).acceptedByHost === false;
                return {
                    ...dayRest,
                    service: {
                        ...service,
                        dayIndex,
                    },
                    isEditing,
                    readOnly,
                };
            });
        });
    }, [tripServicesByDay]);

    const {
        servicesByDay,
        getServiceByKey,
        isServiceValid,
        remove,
        toggleEditing,
        add,
        update,
        create,
    } = useServicesByDay({
        servicesByDay: mappedServicesByDay,
    });

    const [gatheringForDelete, setGatheringForDelete] = useState<symbol | null>(
        null,
    );

    const snackBarInitialState = {
        message: '',
        color: 'info',
        show: false,
    };
    const [snackBar, setSnackBar] = useState(snackBarInitialState);

    const getSuccessMessage = (
        serviceType: ServiceType,
        action: 'create' | 'update',
    ): string => {
        const actionText = action === 'create' ? 'created' : 'saved';
        switch (serviceType) {
            case ServiceTypesKeys.WORKSHOP_SPACE:
                return `Workshop ${actionText}!`;
            case ServiceTypesKeys.INFORMAL_GATHERING:
                return `Gathering ${actionText}!`;
            default:
                return `Service ${actionText}!`;
        }
    };

    const handleCancel = useCallback(
        async (key): Promise<void> => {
            const service = getServiceByKey(key);
            if (!service) return;
            const isValid = await isServiceValid(service);
            isValid ? toggleEditing(key) : remove(key);
        },
        [getServiceByKey, isServiceValid, toggleEditing, remove],
    );

    const handleOpen = useCallback(
        (key: symbol): void => {
            toggleEditing(key);
        },
        [toggleEditing],
    );

    const editingInProgress = servicesByDay.some((day) =>
        day.some((service) => service.isEditing),
    );

    const handleAddGathering = useCallback(
        (dayIndex: number): void => {
            if (editingInProgress) {
                return setSnackBar({
                    message:
                        'Please finish editing your in-progress item before creating a new one.',
                    color: 'danger',
                    show: true,
                });
            }

            const data = {
                type: ServiceTypesKeys.INFORMAL_GATHERING as ServiceType,
                isEditing: true,
            };
            add(data, dayIndex);
        },
        [add, editingInProgress],
    );

    const createService = useCallback(
        async (service: Service, key: symbol): Promise<void> => {
            try {
                const created = await create(service, key);
                if (!created) return;
                const successMessage = getSuccessMessage(
                    created.type,
                    'create',
                );
                setSnackBar({
                    message: successMessage,
                    color: 'success',
                    show: true,
                });
                toggleEditing(key);
            } catch (error) {
                setSnackBar({
                    message: error.message,
                    color: 'danger',
                    show: true,
                });
            }
        },
        [toggleEditing, create],
    );

    const updateService = useCallback(
        async (service: Service, key: symbol): Promise<void> => {
            try {
                const validatedService = { ...service, acceptedByHost: true };
                const updated = await update(validatedService, key);
                if (!updated) return;
                const successMessage = getSuccessMessage(
                    updated.type,
                    'update',
                );
                setSnackBar({
                    message: successMessage,
                    color: 'success',
                    show: true,
                });
                toggleEditing(key);
            } catch (error) {
                setSnackBar({
                    message: error.message,
                    color: 'danger',
                    show: true,
                });
            }
        },
        [toggleEditing, update],
    );

    const handleSubmit = useCallback(
        async (data: Service, key: symbol, isFirstSave: boolean) => {
            if (isFirstSave) {
                await createService(data, key);
            } else {
                await updateService(data, key);
            }
            await triggerChangeOnTripPublishData();
        },
        [createService, updateService, triggerChangeOnTripPublishData],
    );

    const handleDelete = useCallback((service: Service, key: symbol): void => {
        const isGathering =
            service.type === ServiceTypesKeys.INFORMAL_GATHERING;
        if (!isGathering) return;
        setGatheringForDelete(key);
    }, []);

    const handleDeleteConfirm = useCallback(async () => {
        if (!gatheringForDelete) {
            return;
        }
        await remove(gatheringForDelete);
        setGatheringForDelete(null);
        await triggerChangeOnTripPublishData();
    }, [gatheringForDelete, remove, triggerChangeOnTripPublishData]);

    return (
        <>
            {servicesByDay.map((days, dayIdx) => {
                const dayNumber = dayIdx + 1;
                return (
                    <>
                        <StyledSection key={dayIdx}>
                            <DaySectionTitle
                                id={createDayRefName(dayIdx)}
                                dayNumber={dayNumber}
                                date={tripDates[dayIdx]}
                            />
                            {days.map((day) => {
                                return (
                                    <TripServicesListItem
                                        key={day.$tkey.toString()}
                                        {...day}
                                        serviceDayIndex={day.service.dayIndex.toString()}
                                        handleOpen={(): void => {
                                            handleOpen(day.$tkey);
                                        }}
                                        handleDelete={(data): void => {
                                            handleDelete(data, day.$tkey);
                                        }}
                                        handleSubmit={(data): void => {
                                            handleSubmit(
                                                data,
                                                day.$tkey,
                                                day.isFirstSave || false,
                                            );
                                        }}
                                        handleCancel={(): void => {
                                            handleCancel(day.$tkey);
                                        }}
                                    />
                                );
                            })}
                            <div className={styledAddButtonWrapper}>
                                <Button
                                    variant='secondary'
                                    rightIcon='plus'
                                    onClick={(): void =>
                                        handleAddGathering(dayIdx)
                                    }
                                >
                                    Add Gathering
                                </Button>
                            </div>
                        </StyledSection>
                    </>
                );
            })}
            <Snackbar
                place='tr'
                color={snackBar.color}
                message={snackBar.message}
                open={snackBar.show}
                autoHideDuration={3500}
                onClose={(): void => {
                    setSnackBar(snackBarInitialState);
                }}
            />
            <ConfirmationDialog
                open={!!gatheringForDelete}
                onConfirmation={handleDeleteConfirm}
                onCancel={(): void => {
                    setGatheringForDelete(null);
                }}
                title={'Delete Gathering?'}
                confirmButtonProps={{ children: 'Confirm' }}
                description={
                    'Deleting a gathering will permanently remove it from your trip. If you delete a gathering and change your mind, you can add new gatherings to your trip before signing the service agreement.'
                }
            />
        </>
    );
};

export default TripServicesList;
