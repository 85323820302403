import styled from '@emotion/styled';
import { theme } from '@trova-trip/trova-components';

export const PaddedColumn = styled.div`
    padding-bottom: calc(100vh - 172px);

    @media (max-width: ${theme.breakpoints.lg}) {
        padding-bottom: 0;
    }
`;
