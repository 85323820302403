import { countries } from '@trova-trip/country-data';
import { theme } from '@trova-trip/trova-components';
import { ButtonProps } from '@trova-trip/trova-components/build/next';
import { constants } from '@trova-trip/trova-models';
import {
    HOST_APP_PRODUCT_ROUTE,
    HOST_APP_ROUTE,
    TRAVELER_APP_MANAGE_BOOKING_ROUTES,
} from '../applications/common/constants/routesTokens';
import { applicationRootPath } from '../navigation/NavigationContext';
import { getTravelerPathByProductAndTab } from '../applications/traveler/utils';
import { appConstants } from '@trova-trip/trova-common';
import { APP_ENVIRONMENT } from '../applications/utils';

type configMetadata = {
    showHeader?: boolean;
    showTabBar?: boolean;
};

const { TrovaTripAppsDomainMap } = appConstants;

type ApplicationsRoutesMetadata = Record<string, configMetadata>;

const TrovaTripDomains =
    TrovaTripAppsDomainMap[
        APP_ENVIRONMENT as keyof typeof TrovaTripAppsDomainMap
    ];

export const TripStatuses = {
    CREATED: 'created',
    PARTNER_APPROVED: 'partner-approved',
    TROVA_PRICING_APPROVED: 'trova-pricing-approved',
    HOST_APPROVED: 'host-approved',
    LIVE: 'live',
    EARLY_CONFIRMED: 'early-confirmed',
    READY_TO_CONFIRM: 'ready-to-confirm',
    CONFIRMED: 'confirmed',
    CLOSED: 'closed',
    CANCELLED: 'cancelled',
    COMPLETE: 'complete',
};

export const TripRequestStatuses = {
    PENDING: 'pending',
    TRIP_CREATED: 'trip-created',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
};

export const ItineraryStatuses = {
    CREATED: 'created',
    IN_REVIEW: 'in-review',
    COMPLETE: 'complete',
    INCOMPLETE: 'incomplete',
};

export const TravelerTripStatuses = {
    CONFIRMED: 'confirmed',
    PENDING: 'pending',
    COMPLETE: 'complete',
    WAITLISTED: 'waitlist',
    CANCELLED: 'cancelled',
    AWAITING: 'awaiting',
    CLOSED: 'closed',
};

export const TravelerTripNavigationCategories = {
    [TravelerTripStatuses.CONFIRMED]: { label: 'Confirmed', priority: 1 },
    [TravelerTripStatuses.PENDING]: { label: 'Pending', priority: 2 },
    [TravelerTripStatuses.WAITLISTED]: { label: 'Waitlisted', priority: 3 },
    [TravelerTripStatuses.COMPLETE]: { label: 'Complete', priority: 4 },
    [TravelerTripStatuses.CANCELLED]: { label: 'Cancelled', priority: 5 },
    [TravelerTripStatuses.AWAITING]: { label: 'Awaiting', priority: 6 },
    [TravelerTripStatuses.CLOSED]: { label: 'Closed', priority: 7 },
};

export const HostNavigationCategoriesKeys = {
    requests: 'REQUESTS',
    pendingTrips: 'PENDING_TRIPS',
    liveTrips: 'LIVE_TRIPS',
    completedTrips: 'COMPLETED_TRIPS',
    cancelledTrips: 'CANCELLED_TRIPS',
};

export const HostNavigationCategories = {
    [HostNavigationCategoriesKeys.requests]: { label: 'Requests', priority: 1 },
    [HostNavigationCategoriesKeys.pendingTrips]: {
        label: 'Pending Trips',
        priority: 2,
    },
    [HostNavigationCategoriesKeys.liveTrips]: {
        label: 'Live Trips',
        priority: 3,
    },
    [HostNavigationCategoriesKeys.completedTrips]: {
        label: 'Completed Trips',
        priority: 4,
    },
    [HostNavigationCategoriesKeys.cancelledTrips]: {
        label: 'Cancelled Trips',
        priority: 5,
    },
};

export const HostNavigationCategoriesTripStatuses = {
    [HostNavigationCategoriesKeys.requests]: new Set([
        TripStatuses.CREATED,
        TripStatuses.PARTNER_APPROVED,
    ]),
    [HostNavigationCategoriesKeys.pendingTrips]: new Set([
        TripStatuses.TROVA_PRICING_APPROVED,
        TripStatuses.HOST_APPROVED,
        TripStatuses.EARLY_CONFIRMED,
    ]),
    [HostNavigationCategoriesKeys.liveTrips]: new Set([
        TripStatuses.LIVE,
        TripStatuses.READY_TO_CONFIRM,
        TripStatuses.CONFIRMED,
        TripStatuses.CLOSED,
    ]),
    [HostNavigationCategoriesKeys.completedTrips]: new Set([
        TripStatuses.COMPLETE,
    ]),
    [HostNavigationCategoriesKeys.cancelledTrips]: new Set([
        TripStatuses.CANCELLED,
    ]),
};

export const HostNavigationCategoriesTripRequestStatuses = {
    [HostNavigationCategoriesKeys.requests]: new Set([
        TripRequestStatuses.PENDING,
    ]),
};

export const TravelerNavigationCategoriesTripStatuses = {
    [TravelerTripStatuses.PENDING]: new Set([
        TripStatuses.LIVE,
        TripStatuses.READY_TO_CONFIRM,
        TripStatuses.EARLY_CONFIRMED,
    ]),
    [TravelerTripStatuses.COMPLETE]: new Set([TripStatuses.COMPLETE]),
    [TravelerTripStatuses.CONFIRMED]: new Set([
        TripStatuses.CONFIRMED,
        TripStatuses.CLOSED,
    ]),
    [TravelerTripStatuses.CANCELLED]: new Set([TripStatuses.CANCELLED]),
};

export const TravelerTripBadgeColors = {
    [TravelerTripStatuses.CONFIRMED]: {
        background: [theme.colors.travelerTripStatus.confirmed],
        text: [theme.colors.neutral.white],
    },
    [TravelerTripStatuses.PENDING]: {
        background: [theme.colors.travelerTripStatus.pending],
        text: [theme.colors.neutral.white],
    },
    [TravelerTripStatuses.COMPLETE]: {
        background: [theme.colors.travelerTripStatus.complete],
        text: [theme.colors.neutral.white],
    },
    [TravelerTripStatuses.WAITLISTED]: {
        background: [theme.colors.travelerTripStatus.waitlist],
        text: [theme.colors.neutral.white],
    },
    [TravelerTripStatuses.CANCELLED]: {
        background: [theme.colors.travelerTripStatus.cancelled],
        text: [theme.colors.blueGray[650]],
    },
    [TravelerTripStatuses.AWAITING]: {
        background: [theme.colors.travelerTripStatus.awaiting],
        text: [theme.colors.neutral.white],
    },
};

export const DropdownTitles = {
    PENDING_TRIP_REQUEST: 'Pending Trip Request',
    PLANNED_TRIP: 'Planned Trip',
    LIVE_TRIP: 'Live Trip',
    PAST_TRIP: 'Past Trip',
};

export const ServiceTypesKeys = {
    ACCOMMODATION: 'accommodation',
    INFO: 'info',
    AIRPORT_TRANSFER: 'airportTransfer',
    TRANSFER: 'transfer',
    MEAL: 'meal',
    WORKSHOP_SPACE: 'workshop-space',
    ACTIVITY: 'activity',
    LEGACY_FULL_DAY: 'legacyFullDay',
    INFORMAL_GATHERING: 'informalGathering',
};

export const ServiceTypesLabels = {
    accommodation: 'Accommodation',
    info: 'Info',
    airportTransfer: 'AirportTransfer',
    transfer: 'Transfer',
    legacyFullDay: 'Legacy Full Day',
    meal: 'Meal',
    'workshop-space': 'Workshop Space',
    activity: 'Activity',
    informalGathering: 'Informal Gathering',
    insurancePlan: 'Insurance Plan',
    groundTransfer: 'Ground Transfer',
};

export const LibraryServiceTypesLabels = {
    accommodationLibrary: ServiceTypesLabels.accommodation,
    infoLibrary: ServiceTypesLabels.info,
    transferLibrary: ServiceTypesLabels.transfer,
    mealLibrary: ServiceTypesLabels.meal,
    'workshop-spaceLibrary': ServiceTypesLabels['workshop-space'],
    activityLibrary: ServiceTypesLabels.activity,
};

export const LibraryServiceTypesLabelsDraggable = {
    accommodationLibrary: ServiceTypesKeys.ACCOMMODATION,
    transferLibrary: ServiceTypesKeys.TRANSFER,
    workshopSpaceLibrary: ServiceTypesKeys.WORKSHOP_SPACE,
    activityLibrary: ServiceTypesKeys.ACTIVITY,
    infoLibrary: ServiceTypesKeys.INFO,
    mealLibrary: ServiceTypesKeys.MEAL,
};

export const ServiceTypesColors = {
    [ServiceTypesLabels.accommodation]: theme.colors.orange[900],
    [ServiceTypesLabels.activity]: theme.colors.seaGreen.trova,
    [ServiceTypesLabels.info]: theme.colors.teal[300],
    [ServiceTypesLabels.transfer]: theme.colors.red.coral,
    [ServiceTypesLabels['workshop-space']]: theme.colors.purple[400],
    [ServiceTypesLabels.meal]: theme.colors.blue[600],
    [ServiceTypesLabels.legacyFullDay]: theme.colors.neutral[60],
    [ServiceTypesLabels.informalGathering]: theme.colors.purple[700],
};

export const createCountriesDropdownOptions = () => {
    const COUNTRIES = [...countries.all].sort((a, b) =>
        a.name.localeCompare(b.name),
    );
    return COUNTRIES.reduce(
        (object, { alpha2: key, name: value }) => ({
            ...object,
            [key]: value,
        }),
        {},
    );
};

export const MealOptions = {
    vegetarian: 'Vegetarian',
    vegan: 'Vegan',
    glutenFree: 'Gluten-free',
};

export const TripCategories =
    constants.itinerary.CategoriesListItemsSorted.reduce((accum, current) => {
        accum[current.value] = current.label;
        return accum;
    }, {});

export const HostBedTypes = {
    Single: 'Single',
    Double: 'Double',
    None: 'None',
};

export const DropdownOptions = {
    AccommodationTypes: {
        hotel: 'Hotel',
        resort: 'Resort',
        retreat: 'Retreat',
        guesthouse: 'Guesthouse',
        tent: 'Tent',
        glamping: 'Tent - Glamping',
        villa: 'Villa',
        apartment: 'Apartment',
        hostel: 'Hostel',
        bedBreakfast: 'Bed & Breakfast',
        boat: 'Boat - Liveaboard',
        other: 'Other',
        campsite: 'Tent - Campsite',
        backpacking: 'Tent - Backpacking',
    },
    RoomCategory: {
        deluxe: 'Deluxe',
        superior: 'Superior',
        suite: 'Suite',
    },
    Occupancy: {
        twin: 'Twin',
        single: 'Single',
        double: 'Double',
        triple: 'Triple',
        quadruple: 'Quadruple',
        dormitory: 'Dormitory',
        other: 'Other',
    },
    StarRating: { 'n/a': 'n/a', 2: '2', 3: '3', 4: '4', 5: '5' },
    TripCategories,
    MealType: {
        breakfast: 'Breakfast',
        lunch: 'Lunch',
        dinner: 'Dinner',
        snack: 'Snack',
    },
    MealOptions,
    VehicleType: {
        private: 'Ground - Private',
        shared: 'Ground - Shared',
        boat: 'Ferry/Cruise',
        train: 'Train',
        flight: 'Flight',
        other: 'Other',
    },
    Currencies: {
        USD: 'United State Dollars',
        ARS: 'Argentinian Pesos',
        EUR: 'Euros',
    },
    Countries: createCountriesDropdownOptions(),
    Destinations: {
        Africa: 'Africa',
        Asia: 'Asia',
        'Australia-Oceania': 'Australia & Oceania',
        'Central-America': 'Central America',
        Europe: 'Europe',
        'North-America': 'North America',
        'South-America': 'South America',
    },
    DaysOfTheWeek: {
        1: 'M',
        2: 'Tu',
        3: 'W',
        4: 'Th',
        5: 'F',
        6: 'Sa',
        0: 'Su',
    },
    ServiceTypesLabels,
    HostBedTypes,
    ActivityLevels: {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
    },
};

export const StarRating = ['n/a', 2, 3, 4, 5];

export const TripPublishValidStatuses = new Set([
    TripStatuses.HOST_APPROVED,
    TripStatuses.LIVE,
    TripStatuses.READY_TO_CONFIRM,
    TripStatuses.CONFIRMED,
    TripStatuses.CLOSED,
    TripStatuses.COMPLETE,
]);

export const HostTripRequestsUnresolved = new Set([
    TripRequestStatuses.PENDING,
    TripRequestStatuses.TRIP_CREATED,
]);

export const HostEnabledToConfirmBookings = new Set([
    TripStatuses.READY_TO_CONFIRM,
    TripStatuses.CONFIRMED,
]);

export const OperatorTripsPending = new Set([
    TripStatuses.CREATED,
    TripStatuses.PARTNER_APPROVED,
]);

export const PendingTrip = new Set([
    TripStatuses.CREATED,
    TripStatuses.PARTNER_APPROVED,
]);

export const PlannedTrip = new Set([
    TripStatuses.TROVA_PRICING_APPROVED,
    TripStatuses.HOST_APPROVED,
]);

export const LiveTrip = new Set([
    TripStatuses.LIVE,
    TripStatuses.READY_TO_CONFIRM,
    TripStatuses.CONFIRMED,
    TripStatuses.CLOSED,
]);

export const PastTrip = new Set([TripStatuses.COMPLETE]);

export const HostTripApproved = new Set([
    TripStatuses.HOST_APPROVED,
    TripStatuses.LIVE,
    TripStatuses.READY_TO_CONFIRM,
    TripStatuses.EARLY_CONFIRMED,
    TripStatuses.CONFIRMED,
    TripStatuses.CLOSED,
]);

export const HostEarningsStatuses = new Set([
    TripStatuses.EARLY_CONFIRMED,
    TripStatuses.READY_TO_CONFIRM,
    TripStatuses.CONFIRMED,
    TripStatuses.CLOSED,
]);

export const PendingTripHostApproval = new Set([
    TripStatuses.CREATED,
    TripStatuses.PARTNER_APPROVED,
    TripStatuses.TROVA_PRICING_APPROVED,
    TripStatuses.HOST_APPROVED,
]);

export const PriceTripTabStatuses = new Set([
    TripStatuses.TROVA_PRICING_APPROVED,
    TripStatuses.HOST_APPROVED,
    TripStatuses.LIVE,
    TripStatuses.READY_TO_CONFIRM,
    TripStatuses.CONFIRMED,
]);

export const EditTripStatuses = new Set([
    TripStatuses.HOST_APPROVED,
    TripStatuses.LIVE,
    TripStatuses.READY_TO_CONFIRM,
    TripStatuses.CONFIRMED,
    TripStatuses.CLOSED,
    TripStatuses.COMPLETE,
]);

export const ReviewTravelersStatuses = new Set([
    TripStatuses.LIVE,
    TripStatuses.READY_TO_CONFIRM,
    TripStatuses.CONFIRMED,
    TripStatuses.CLOSED,
    TripStatuses.CANCELLED,
    TripStatuses.COMPLETE,
]);

export const MostRecentlyActiveTrip = new Set([
    TripStatuses.CREATED,
    TripStatuses.CONFIRMED,
    TripStatuses.CLOSED,
    TripStatuses.COMPLETE,
]);

export const ReviewItineraryButtonStatuses = new Set([
    TripStatuses.HOST_APPROVED,
    TripStatuses.CONFIRMED,
    TripStatuses.CLOSED,
    TripStatuses.CANCELLED,
    TripStatuses.COMPLETE,
]);

export const FlightStipendDisabledStatuses = new Set([
    TripStatuses.CONFIRMED,
    TripStatuses.CLOSED,
    TripStatuses.CANCELLED,
    TripStatuses.COMPLETE,
]);

export const HostApprovedActiveTrip = new Set([
    TripStatuses.HOST_APPROVED,
    TripStatuses.LIVE,
    TripStatuses.READY_TO_CONFIRM,
    TripStatuses.CONFIRMED,
]);

export const InactiveTrip = new Set([
    TripStatuses.CLOSED,
    TripStatuses.CANCELLED,
    TripStatuses.COMPLETE,
]);

export const PendingTrovaApprovalTrip = new Set([
    TripStatuses.CREATED,
    TripStatuses.PARTNER_APPROVED,
]);

export const FlightAdvanceDisabled = new Set([
    TripStatuses.CONFIRMED,
    TripStatuses.CLOSED,
    TripStatuses.CANCELLED,
    TripStatuses.COMPLETE,
]);

export const ServiceTypes = {
    ACCOMMODATION: 'Acoommodation',
    ACTIVITY: 'Activity',
    AIRPORT_TRANSFER: 'Airport Transfer',
    INFO: 'Info',
    MEAL: 'Meal',
    TRANSFER: 'Transfer',
    WORKSHOP_SPACE: 'Workshop Space',
    LEGACY_FULL_DAY: 'Legacy Full Day',
};

export const ServiceTypesOptions = new Set([
    ServiceTypes.ACCOMMODATION,
    ServiceTypes.ACTIVITY,
    ServiceTypes.AIRPORT_TRANSFER,
    ServiceTypes.INFO,
    ServiceTypes.MEAL,
    ServiceTypes.TRANSFER,
    ServiceTypes.WORKSHOP_SPACE,
]);

/**
 * @deprecated To be removed.
 * We are in the process of deprecating rezdy.
 */
export const REZDY_STATUSES = {
    PENDING: 'PENDING_SUPPLIER',
    PENDING_CUSTOMER: 'PENDING_CUSTOMER',
    CANCELLED: 'CANCELLED',
    WAITLIST: 'ON_HOLD',
    CONFIRMED: 'CONFIRMED',
};

export const BookingStatuses = {
    PENDING: 'pending',
    AWAITING: 'awaiting',
    CANCELLED: 'cancelled',
    WAITLIST: 'waitlist',
    CONFIRMED: 'confirmed',
};

export const BookingStatusesNames = {
    [BookingStatuses.PENDING]: 'Pending',
    [BookingStatuses.AWAITING]: 'Awaiting Payment',
    [BookingStatuses.WAITLIST]: 'Waitlisted',
    [BookingStatuses.CONFIRMED]: 'Confirmed',
    [BookingStatuses.CANCELLED]: 'Cancelled',
};

export const BookingStatusesColors = {
    [BookingStatuses.PENDING]: theme.colors.orange[900],
    [BookingStatuses.AWAITING]: theme.colors.yellow[500],
    [BookingStatuses.WAITLIST]: theme.colors.purple[400],
    [BookingStatuses.CONFIRMED]: theme.colors.seaGreen.trova,
};

export const OTHER_CANCEL_REASON = { description: 'Other', value: 'Other' };
export const TROVA_TERMS_URL = 'https://trovatrip.com/terms';
export const TROVA_BOOKING_TERMS_URL = 'https://trovatrip.com/terms#booking';
export const ItineraryServices: Array<{
    serviceName: string;
    serviceType: string;
    colorScheme: ButtonProps['colorScheme'];
}> = [
    {
        serviceName: 'Transfer',
        serviceType: 'transfer',
        colorScheme: 'red',
    },
    {
        serviceName: 'Activity',
        serviceType: 'activity',
        colorScheme: 'green',
    },
    {
        serviceName: 'Accommodations',
        serviceType: 'accommodation',
        colorScheme: 'orange',
    },
    {
        serviceName: 'Info',
        serviceType: 'info',
        colorScheme: 'teal',
    },
    {
        serviceName: 'Workshop Space',
        serviceType: 'workshop-space',
        colorScheme: 'purple',
    },
    {
        serviceName: 'Meals',
        serviceType: 'meal',
        colorScheme: 'blue',
    },
];

export const TROVA_MAIL_TO = 'hi@trovatrip.com';

export const modelTypes = {
    TRIP: 'trip',
    TRIP_REQUEST: 'tripRequest',
};

export const UserStatuses = {
    CREATED: 'created',
    READY_TO_QUALIFY: 'ready-to-qualify',
    QUALIFYING: 'qualifying',
    QUALIFIED: 'qualified',
    PLANNING: 'planning',
    LAUNCHED: 'launched',
    CONFIRMED: 'confirmed',
    RENEWED: 'renewed',
};

export const TravelerStatuses = {
    CREATED: `created`,
    WAIVER_COMPLETE: `waiver-complete`,
    TRIP_FORM_COMPLETE: `trip-form-complete`,
    READY_TO_TRAVEL: `ready-to-travel`,
    COMPLETE: `complete`,
    FEEDBACK_COMPLETE: `feedback-complete`,
    CANCELLED: `cancelled`,
};

export const QualifyingHostAndBeyond = new Set([
    UserStatuses.QUALIFYING,
    UserStatuses.QUALIFIED,
    UserStatuses.PLANNING,
    UserStatuses.LAUNCHED,
    UserStatuses.CONFIRMED,
    UserStatuses.RENEWED,
]);

export const HostResurveyStatuses = new Set([
    UserStatuses.CONFIRMED,
    UserStatuses.RENEWED,
]);

export const MobileAppReadyTravelerStatuses = new Set([
    TravelerStatuses.TRIP_FORM_COMPLETE,
    TravelerStatuses.READY_TO_TRAVEL,
]);

export const TROVA_HOME_SURVEY_VIDEO = 'https://vimeo.com/816352580';

const travelerPath = getTravelerPathByProductAndTab('TRIPS', 'MANAGE_BOOKING');

export const APPLICATION_ROUTES_METADATA: ApplicationsRoutesMetadata = {
    [`${applicationRootPath}${HOST_APP_ROUTE}${HOST_APP_PRODUCT_ROUTE.ITINERARIES}`]:
        {
            showHeader: false,
        },
    [`${travelerPath}${TRAVELER_APP_MANAGE_BOOKING_ROUTES.PAYMENT}`]: {
        showHeader: false,
        showTabBar: false,
    },
    [`${travelerPath}${TRAVELER_APP_MANAGE_BOOKING_ROUTES.SUCCESS}`]: {
        showHeader: false,
        showTabBar: false,
    },
    [`${travelerPath}${TRAVELER_APP_MANAGE_BOOKING_ROUTES.PAY_BALANCE}`]: {
        showHeader: false,
        showTabBar: false,
    },
};

const serviceType = constants.services.ServiceType;

export const gumletDomain = TrovaTripDomains.images;

export const FIXED_BOOKABLE_SERVICES = [
    serviceType.GROUND_TRANSFER,
    serviceType.INSURANCE_PLAN,
];

export const MobileAppUrls = {
    android: TrovaTripDomains.mobileApp.android,
    ios: TrovaTripDomains.mobileApp.ios,
};

export const DEBOUNCE_TIMER = 200;

/* FEATURE FLAGS */
export const isProfileAddressEnabled = Boolean(
    process.env.REACT_APP_PROFILE_ADDRESS_M1,
);

export const isTransfersEnabled = Boolean(process.env.REACT_APP_TRANSFERS);

export const isPublicHostProfileEnabled = Boolean(
    process.env.REACT_APP_PUBLIC_HOST_PROFILE,
);

export const isSuggestedSellPriceEnabled = Boolean(
    process.env.REACT_APP_SUGGESTED_SELL_PRICE,
);

export const isHostPhylloOnboardingEnabled = Boolean(
    process.env.REACT_APP_HOST_PHYLLO_ONBOARDING,
);
