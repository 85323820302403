import { EditFieldProps, PreviewProps } from '../../utils/types';
import {
    BaseBox,
    Key,
    Stack,
    Text,
    useKeyboardEvents,
} from '@trova-trip/trova-components';
import { Icon } from '@trova-trip/trova-components/build/next';
import SocialMediaAccounts, {
    Socials,
    socialMediaIcons,
} from '../../../../components/SocialMediaAccounts';
import { socialDomains } from '../../../../components/SocialMediaAccounts/SocialMediaAccounts.constants';
import {
    formatSocialChannels,
    truncatePersonalSite,
} from '../../../../../common/helpers';

const keysToHandle = [Key.Spacebar, Key.Enter];

const handleClick = (url: string) => {
    window.open(url, '_blank');
};

interface SocialChannelsPreviewProps extends PreviewProps<'socialChannels'> {}

export const SocialChannelsPreview = ({
    socialChannels = {},
}: SocialChannelsPreviewProps): JSX.Element | null => {
    const { handleKeyboardEvent } = useKeyboardEvents<HTMLDivElement>();

    const availableSocialChannels = Object.keys(socialChannels).filter(
        (channel) => socialChannels[channel],
    );

    if (!availableSocialChannels) {
        return null;
    }

    const formattedSocialChannels = formatSocialChannels(
        socialChannels,
        truncatePersonalSite,
    );

    return (
        <Stack direction='column' spacing={2}>
            {availableSocialChannels.map((channel) => {
                const icon = socialMediaIcons[channel];
                const userHandle = formattedSocialChannels[channel];
                const url = `https://${socialDomains[channel]}${userHandle}`;
                return (
                    <BaseBox
                        key={channel}
                        onClick={() => handleClick(url)}
                        onKeyDown={handleKeyboardEvent(keysToHandle, () =>
                            handleClick(url),
                        )}
                        role='link'
                        cursor='pointer'
                        tabIndex={0}
                    >
                        <Stack align='center' wrap='nowrap'>
                            <Icon
                                size='lg'
                                as={icon}
                                color='teal.trova'
                                display='inline-flex'
                            />
                            <Text fontWeight='medium' noOfLines={1}>
                                {userHandle}
                            </Text>
                            <Icon as='link' display='inline-flex' />
                        </Stack>
                    </BaseBox>
                );
            })}
        </Stack>
    );
};

export const SocialChannelsEditField = ({
    fieldData = {},
    onChange,
    errors,
}: EditFieldProps<'socialChannels'>): JSX.Element => {
    const onChangeHandler = (data: Socials) => {
        const isSocialsFieldEmpty = Object.values(data).every(
            (value) => !value,
        );
        onChange(data, isSocialsFieldEmpty);
    };
    return (
        <SocialMediaAccounts
            socials={fieldData}
            onUpdateSocials={onChangeHandler}
            errors={errors}
        />
    );
};
