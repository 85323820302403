import React from 'react';
import PropTypes from 'prop-types';
import { RingLoader } from 'react-spinners';
import withStyles from '@material-ui/core/styles/withStyles';
import loadingStyle from '../assets/jss/trova/loadingStyle';
import classNames from 'classnames';

const Loading = ({ classes, isEmptyPage = true }) => {
    const loadingClasses = classNames({
        [classes.loadingContainer]: isEmptyPage,
    });

    return (
        <div className={loadingClasses}>
            <RingLoader color={`#00acc1`} loading={true} />
        </div>
    );
};

Loading.propTypes = {
    classes: PropTypes.shape(),
    isEmptyPage: PropTypes.bool,
};

export default withStyles(loadingStyle)(Loading);
