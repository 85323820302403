import { Route, Switch } from 'react-router-dom';
import { applicationRootPath } from '../../../../navigation/NavigationContext';
import {
    HOST_APP_PRODUCT_ROUTE,
    HOST_APP_ROUTE,
    HOST_PUBLIC_PROFILE_ROUTES,
} from '../../../common/constants/routesTokens';
import Product from '../../../../navigation/Product';
import { MicroAppPublicProfileWrapper, EditPublicProfile } from './components';

export const basePublicProfileRoute = `${applicationRootPath}${HOST_APP_ROUTE}${HOST_APP_PRODUCT_ROUTE.PUBLIC_PROFILE}`;
export const editPublicProfileRoute = `${basePublicProfileRoute}${HOST_PUBLIC_PROFILE_ROUTES.EDIT}`;

const PublicProfile = (): JSX.Element => {
    return (
        <Product
            path={HOST_APP_PRODUCT_ROUTE.PUBLIC_PROFILE}
            hideNavigation={true}
        >
            <Switch>
                <Route path={editPublicProfileRoute}>
                    <EditPublicProfile />
                </Route>
                <Route path={basePublicProfileRoute}>
                    <MicroAppPublicProfileWrapper />
                </Route>
            </Switch>
        </Product>
    );
};

export default PublicProfile;
