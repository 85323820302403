import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { noSidebarPaths } from '../common/noSidebarPaths';
import useIsLargeScreen from '../../applications/common/hooks/useIsLargeScreen';

interface UseSidebarVisibilityReturn {
    sidebarVisibility: boolean;
    isPathWithoutSidebar: boolean;
    setSidebarVisibility: Dispatch<SetStateAction<boolean>>;
}

const useSidebarVisibility = (): UseSidebarVisibilityReturn => {
    const isLargeScreen = useIsLargeScreen();
    const [sidebarVisibility, setSidebarVisibility] = useState(isLargeScreen);
    const isPathWithoutSidebar = !!useRouteMatch(noSidebarPaths);

    useEffect(() => {
        const isSidebarEnabled = isLargeScreen && !isPathWithoutSidebar;
        setSidebarVisibility(isSidebarEnabled);
    }, [isLargeScreen, isPathWithoutSidebar]);

    return { sidebarVisibility, setSidebarVisibility, isPathWithoutSidebar };
};

export default useSidebarVisibility;
