import React, { Dispatch, SetStateAction } from 'react';
import { constants, models } from '@trova-trip/trova-models';
import { BaseBox, Stack, StackProps, Text } from '@trova-trip/trova-components';
import BedQuantityComboBox from './BedQuantityComboBox';
import RemoveRoomButton from './RemoveRoomButton';
import AddRoomButton from './AddRoomButton';
import { getBedsQuantityForRooms } from '../AccommodationsForm.helpers';

type HostRoomType = constants.tripRequest.HostRoomType;
type HostRoom = models.tripRequest.HostRoom;

export type BedQuantityInputProps = {
    totalCompanionFOCs: number;
    onChange: (rooms: HostRoom[]) => void;
    isReadOnly: boolean;
    rooms: HostRoom[];
    setRooms: Dispatch<SetStateAction<HostRoom[]>>;
    width: StackProps['width'];
};

const BedQuantityInput = (props: BedQuantityInputProps): JSX.Element => {
    const { rooms, setRooms, onChange, isReadOnly, totalCompanionFOCs, width } =
        props;

    const bedsQuantityForRooms = getBedsQuantityForRooms(rooms);

    const handleRemoveRoom = (roomIndex: number): void => {
        const newRooms = rooms.filter((_, index) => index !== roomIndex);
        handleUpdateRooms(newRooms);
    };

    const handleChangeRoom = (value: HostRoomType, roomIndex: number): void => {
        const newRooms = rooms.map((room, index) =>
            index === roomIndex ? { roomType: value } : room,
        );
        handleUpdateRooms(newRooms);
    };

    const handleAddRoom = (): void => {
        const newRoom: HostRoom = {
            roomType: constants.tripRequest.HostRoomType.ONE_BED,
        };
        const newRooms = rooms.concat(newRoom);
        handleUpdateRooms(newRooms);
    };

    const handleUpdateRooms = (newRooms: HostRoom[]): void => {
        onChange(newRooms);
        setRooms(newRooms);
    };

    return (
        <Stack
            direction='column'
            justify='space-around'
            spacing={4}
            width={width}
        >
            {rooms.map(({ roomType }, index) => {
                return (
                    <Stack
                        key={index}
                        spacing={2}
                        align='start'
                        wrap='nowrap'
                        width='full'
                    >
                        <BaseBox flexGrow={1}>
                            <BedQuantityComboBox
                                roomNumber={index + 1}
                                handleChange={(value): void =>
                                    handleChangeRoom(value, index)
                                }
                                isDisabled={isReadOnly}
                                roomType={roomType}
                            />
                        </BaseBox>
                        {!isReadOnly ? (
                            <RemoveRoomButton
                                handleRemove={(): void =>
                                    handleRemoveRoom(index)
                                }
                            />
                        ) : null}
                    </Stack>
                );
            })}
            {!isReadOnly ? <AddRoomButton handleAdd={handleAddRoom} /> : null}
            {bedsQuantityForRooms !== totalCompanionFOCs ? (
                <Text size='sm' color='red.trova'>
                    {`This configuration has ${bedsQuantityForRooms} beds for ${totalCompanionFOCs} companions. Please configure to ${totalCompanionFOCs} beds or shared beds.`}
                </Text>
            ) : null}
        </Stack>
    );
};

export default BedQuantityInput;
