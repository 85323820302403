import { coreUtils } from '@trova-trip/trova-common';
import { constants, models } from '@trova-trip/trova-models';
import { getGroupedAccommodation } from '../../../common/helpers';
import {
    HostRoomHelperText,
    HostRoomIcon,
    HostRoomInformationType,
    HostRoomType,
} from './AccommodationsForm.types';

type HostRoom = models.tripRequest.HostRoom;
type Accommodation = models.services.Accommodation;
type Service = models.services.Service;
type OperatorRoomConfig = models.tripRequest.OperatorRoomConfig;
type HostSelectedOptionalServices = models.trips.HostSelectedOptionalServices;
type AccommodationPrice = models.itineraryInventoryItems.AccommodationPrice;
const OperatorHostRoomType = constants.tripRequest.OperatorHostRoomType;

const getRoomsConfigForOperators =
    coreUtils.tripUtils.getRoomsConfigForOperators;

export const roomTypeInformation = new Map<
    HostRoomType,
    HostRoomInformationType
>([
    [
        constants.tripRequest.HostRoomType.TWO_BEDS,
        {
            bedQuantity: 2,
            icon: HostRoomIcon.TWO_BEDS,
            helperText: HostRoomHelperText.TWO_BEDS,
        },
    ],
    [
        constants.tripRequest.HostRoomType.ONE_BED,
        {
            bedQuantity: 1,
            icon: HostRoomIcon.ONE_BED,
            helperText: HostRoomHelperText.ONE_BED,
        },
    ],
    [
        constants.tripRequest.HostRoomType.SHARED_BED,
        {
            bedQuantity: 2,
            icon: HostRoomIcon.SHARED_BED,
            helperText: HostRoomHelperText.SHARED_BED,
        },
    ],
]);

export const getBedsQuantityForRooms = (rooms: HostRoom[]): number => {
    return rooms.reduce((acc, room) => {
        return acc + (roomTypeInformation.get(room.roomType)?.bedQuantity || 0);
    }, 0);
};

const getAccommodationByRoomConfig = (
    roomConfig: OperatorRoomConfig,
    accommodations: Accommodation[],
): Service | undefined => {
    const accommodation = accommodations.find(
        ({ occupancy }) => occupancy?.toLowerCase() === roomConfig.roomType,
    );

    return accommodation;
};

type SelectedAccommodationsServices = {
    preTripSelectedAccommodationServices: HostSelectedOptionalServices[];
    postTripSelectedAccommodationServices: HostSelectedOptionalServices[];
};

const createSelectedOptionalService = (
    service: Service,
    roomQuantity: number,
): HostSelectedOptionalServices | null => {
    if (!service) return null;

    return {
        activity: {
            price: service.price || 0,
            name: service.name || '',
        },
        numberOptingIn: roomQuantity,
    };
};

export const getSelectedPrePostAccommodations = (
    services: Service[],
    rooms: HostRoom[],
): SelectedAccommodationsServices => {
    const { preTripAccommodationsServices, postTripAccommodationServices } =
        getGroupedAccommodation(services);
    const roomsConfigs = getRoomsConfigForOperators(rooms);

    const selectedAccommodationsServices = roomsConfigs.reduce(
        (currentServices, roomConfig) => {
            const preAccommodationService = createSelectedOptionalService(
                preTripAccommodationsServices[roomConfig.roomType],
                roomConfig.quantity,
            );
            const postAccommodationService = createSelectedOptionalService(
                postTripAccommodationServices[roomConfig.roomType],
                roomConfig.quantity,
            );

            const {
                preTripSelectedAccommodationServices,
                postTripSelectedAccommodationServices,
            } = currentServices;

            if (preAccommodationService) {
                preTripSelectedAccommodationServices.push(
                    preAccommodationService,
                );
            }
            if (postAccommodationService) {
                postTripSelectedAccommodationServices.push(
                    postAccommodationService,
                );
            }

            return currentServices;
        },
        {
            preTripSelectedAccommodationServices: [],
            postTripSelectedAccommodationServices: [],
        } as SelectedAccommodationsServices,
    );

    return selectedAccommodationsServices;
};

const calculateStandardAccommodationTotalPrice = (
    totalPrice: number,
    roomConfig: OperatorRoomConfig,
    accommodations: Accommodation[],
): number => {
    const selectedAccommodation = getAccommodationByRoomConfig(
        roomConfig,
        accommodations,
    );
    const price = selectedAccommodation?.price || 0;
    return totalPrice + price * roomConfig.quantity;
};

const calculateInventoryAccommodationTotalPrice = (
    totalPrice: number,
    roomConfig: OperatorRoomConfig,
    inventoryAccommodationsPrice: AccommodationPrice | undefined,
): number => {
    if (!inventoryAccommodationsPrice) return totalPrice;

    const normalizedRoomType =
        roomConfig.roomType === OperatorHostRoomType.TWIN
            ? OperatorHostRoomType.DOUBLE
            : roomConfig.roomType;

    const inventoryPrice =
        inventoryAccommodationsPrice[normalizedRoomType] || 0;

    if (!inventoryPrice) return totalPrice;

    return totalPrice + inventoryPrice * roomConfig.quantity;
};

type AccommodationCostReducer = (
    totalPrice: number,
    roomConfig: OperatorRoomConfig,
) => number;

export const getSelectedAccommodationsCost = (
    rooms: HostRoom[],
    inventoryAccomodationPrice: AccommodationPrice | undefined,
    accommodations: Accommodation[],
): number => {
    const roomsConfigs = getRoomsConfigForOperators(rooms);

    const reducer: AccommodationCostReducer = inventoryAccomodationPrice
        ? (totalPrice, roomConfig) =>
              calculateInventoryAccommodationTotalPrice(
                  totalPrice,
                  roomConfig,
                  inventoryAccomodationPrice,
              )
        : (totalPrice, roomConfig) =>
              calculateStandardAccommodationTotalPrice(
                  totalPrice,
                  roomConfig,
                  accommodations,
              );

    return roomsConfigs.reduce(reducer, 0);
};
