import FetchState from './utils/FetchState';
import { models } from '@trova-trip/trova-models';
import { get } from './utils/api';

type Category = models.categories.Category;

export type CategoryStoreSelector = {
    category: { current: { records: Category }; fetching?: boolean };
};

const categories = FetchState.create(`api/me/categories/host-interests`);

const categoriesReducer = categories.getReducer();

export { categories, categoriesReducer };
