import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { models } from '@trova-trip/trova-models';
import Tab from '../../../../navigation/Tab';
import TabDetailsInformation from '../../../../components/TabDetailsInformation/TabDetailsInformation';
import CreateRequestForm from 'applications/host/components/TripRequestForm/CreateRequestForm';
import TripPricingCard from '../../components/TripPricingCard/TripPricingCard';
import { ItineraryStoreSelector } from '../../../../state/itineraries';
import { MainContent } from '@trova-trip/trova-components/build/next';
import { isInventoryEnabled } from '../../../../applications/common/helpers';
import { Link } from '@trova-trip/trova-components/build/next';
import { businessConstants } from '@trova-trip/trova-common';
import useModelStatusMatcher from '../../../../applications/common/hooks/useModelStatusMatcher';
import { StatefulModel } from '../../../../applications/types';

type SuggestedSellPrice = models.itineraries.SuggestedSellPrice;

interface TripRequestProps {
    refreshSidebarContent: () => void;
}

const useTabDescription = (isInventoriedItinerary: boolean) => {
    const isQualified = useModelStatusMatcher({
        model: StatefulModel.PROFILE,
        matchingStatuses: businessConstants.QualifiedHostStatuses,
    });

    if (isInventoriedItinerary) {
        if (isQualified) {
            return `Limited dates available ⚠️ changes cannot be made after you submit a trip request so please double check the details!`;
        } else {
            return (
                <>
                    Limited dates available ⚠️ Hosts are required to{' '}
                    <Link
                        isExternal
                        href='https://help.trovatrip.com/en/articles/9709553-prepare-to-launch-your-trip '
                    >
                        launch
                    </Link>{' '}
                    this trip within 14 days of itinerary reservation or it will
                    be released. After launch, Hosts are required to get 2
                    Traveler bookings in 14 days otherwise trip will be canceled
                    and itinerary dates will be released.
                </>
            );
        }
    } else {
        return `Please note that your trip request will be sent to our Operating partners for review and approval — changes cannot be made after you submit a trip request so please double check the details!`;
    }
};

const TripRequest = ({
    refreshSidebarContent,
}: TripRequestProps): JSX.Element => {
    const [fetchingPrice, setFetchingPrice] = useState<boolean>();
    const [estimatedPrice, setEstimatedPrice] = useState<SuggestedSellPrice>();

    const {
        userItinerary: { current: currentItinerary },
    } = useSelector((state: ItineraryStoreSelector) => state);

    const isInventoriedItinerary = isInventoryEnabled(currentItinerary);

    const tabDescription = useTabDescription(isInventoriedItinerary);

    const { maximumSpots = 20 } =
        useSelector(
            (state: ItineraryStoreSelector) => state.userItinerary.current,
        ) || {};

    const [travelersTierNumber, setTravelersTierNumber] =
        useState(maximumSpots);

    useEffect(() => {
        if (!currentItinerary) {
            setEstimatedPrice(undefined);
        }
    }, [currentItinerary]);

    useEffect(() => {
        setTravelersTierNumber(maximumSpots);
    }, [maximumSpots]);

    return (
        <Tab disabled label={'Trip Request'} path={'/request'} hideNavigation>
            <MainContent.Inner
                marginTop={12}
                sidebarElement={
                    <TripPricingCard
                        title='Suggested Sell Price'
                        value={estimatedPrice}
                        isLoading={fetchingPrice}
                    />
                }
                sidebarConfig={{
                    offsetTop: 5,
                }}
            >
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TabDetailsInformation
                            title='Reserve This Trip'
                            description={tabDescription}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CreateRequestForm
                            onChangePrice={(price, fetching): void => {
                                setFetchingPrice(fetching);
                                setEstimatedPrice(price);
                            }}
                            travelersTierNumber={travelersTierNumber}
                            refreshSidebarContent={refreshSidebarContent}
                        />
                    </Grid>
                </Grid>
            </MainContent.Inner>
        </Tab>
    );
};

export default TripRequest;
