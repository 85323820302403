import { models } from '@trova-trip/trova-models';

type BookingRepriceUpdate = models.bookings.BookingRepriceUpdate;
type BookingRepriceQuote = models.bookings.BookingRepriceQuote;

interface BookingRepriceQuoteResponse {
    success: boolean;
    data?: BookingRepriceQuote;
    error?: string;
}

type SuccessApiResponse<T> = {
    success: true;
    data: T;
};

type ErrorApiResponse = {
    success: false;
    error: string;
};

type ApiResponse<T> = SuccessApiResponse<T> | ErrorApiResponse;

const isApiResponse = <T>(
    possibleResponse: any,
): possibleResponse is ApiResponse<T> => {
    return (
        isSuccessApiResponse(possibleResponse) ||
        isErrorApiResponse(possibleResponse)
    );
};

const isSuccessApiResponse = <T>(
    response: ApiResponse<T>,
): response is SuccessApiResponse<T> => {
    return 'data' in response;
};

const isErrorApiResponse = <T>(
    response: ApiResponse<T>,
): response is ErrorApiResponse => {
    return 'error' in response;
};

export { isApiResponse, isSuccessApiResponse, isErrorApiResponse };

export type {
    BookingRepriceUpdate,
    BookingRepriceQuote,
    BookingRepriceQuoteResponse,
    ApiResponse,
};
