import { BaseBox, Stack, Text } from '@trova-trip/trova-components';
import { Icon, IconProps } from '@trova-trip/trova-components/build/next';
import { Link } from 'react-router-dom';

interface BottomNavBarLinkProps {
    icon: IconProps['as'];
    label: string;
    path: string;
    isActive: boolean;
}

export const BottomNavBarLink = ({
    icon,
    label,
    path,
    isActive,
}: BottomNavBarLinkProps): JSX.Element => (
    <Link to={path} title={label}>
        <Stack width='4.375rem' spacing={0} direction='column' align='center'>
            <BaseBox
                display='inline-flex'
                borderRadius='.625rem'
                padding={1}
                background={isActive ? 'gradients.teal.100' : 'blueGray.25'}
            >
                <Icon
                    display='inline-flex'
                    as={icon}
                    size='lg'
                    variant={isActive ? 'filled' : 'outlined'}
                    color={isActive ? 'teal.700' : 'blueGray.400'}
                />
            </BaseBox>
            <Text
                fontSize='xxs'
                textTransform='uppercase'
                fontWeight='medium'
                color={isActive ? 'blueGray.500' : 'blueGray.400'}
            >
                {label}
            </Text>
        </Stack>
    </Link>
);
