import { BaseBoxProps } from '@trova-trip/trova-components';
import useTripPageUrl from 'applications/common/hooks/trips/useTripPageUrl';
import CopyTextButton from 'components/CopyTextButton';
import StickyContentBase from './StickyContentBase';

interface StickyContentTripPageLinkProps {
    title?: string;
    description?: string;
    subtitle?: string;
    marginTop?: BaseBoxProps['marginTop'];
}

const StickyContentTripPageLink = ({
    title,
    description,
    subtitle,
    marginTop,
}: StickyContentTripPageLinkProps): JSX.Element => {
    const tripPageUrl = useTripPageUrl();
    return (
        <StickyContentBase
            title={title}
            description={description}
            subtitle={subtitle}
            marginTop={marginTop}
            action={
                <CopyTextButton
                    onCopyTitle='Link copied'
                    onCopyMessage='The trip page was successfully copied'
                    size={{ base: 'sm', md: 'md' }}
                    textToCopy={tripPageUrl}
                >
                    Copy Trip Page
                </CopyTextButton>
            }
        />
    );
};

export default StickyContentTripPageLink;
