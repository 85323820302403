import PropTypes from 'prop-types';

import ImageUploaderInput from '../ImageUploaderInput.js';

const ImageUpload = ({
    name,
    onChange,
    value,
    imageAmountLimit,
    sortable,
    uploadUrl,
    classes,
    label,
    disabled = false,
}) => (
    <ImageUploaderInput
        key={name}
        name={name}
        imageAmountLimit={imageAmountLimit}
        onChange={onChange}
        value={value}
        uploadUrl={uploadUrl}
        sortable={sortable}
        classes={classes}
        label={label}
        disabled={disabled}
    />
);

ImageUpload.propTypes = {
    uploadUrl: PropTypes.string.isRequired,
    sortable: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    name: PropTypes.string,
    imageAmountLimit: PropTypes.string,
    classes: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
};

export default ImageUpload;
