import Tab from 'navigation/Tab';
import { BaseTabProps } from '../Tabs.types';
import TripPageCard from '../../components/TripPageCard/TripPageCard';
import { Heading, Stack } from '@trova-trip/trova-components';
import Layout from 'components/Layout';
import EmailCampaignsTable from './EmailCampaignsTable';
import EditEmailModal from '../../components/EditEmailModal';
import { useState } from 'react';

const PromoteTab = (props: BaseTabProps): JSX.Element => {
    const { hideNavigation, disabled } = props;
    const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false);
    const [currentEmailName, setCurrentEmailName] = useState<string>('');

    const handleEmailEdit = (emailName?: string): void => {
        if (!emailName) return;
        setCurrentEmailName(emailName);
        setIsEmailModalOpen(true);
    };

    return (
        <Tab
            label={'Promote'}
            path={'/promote'}
            disabled={disabled}
            hideNavigation={hideNavigation}
        >
            <Layout>
                <Stack
                    direction='column'
                    spacing={8}
                    align='stretch'
                    maxWidth='100%'
                >
                    <Heading as='h2'>Promote Trip</Heading>
                    <TripPageCard />
                    <EmailCampaignsTable onEditEmailClick={handleEmailEdit} />
                    <EditEmailModal
                        isOpen={isEmailModalOpen}
                        emailCampaignName={currentEmailName}
                        setIsOpen={setIsEmailModalOpen}
                    />
                </Stack>
            </Layout>
        </Tab>
    );
};

export default PromoteTab;
