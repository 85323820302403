import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Grid } from '@trova-trip/trova-components';
import InfoCard from './InfoCard';
import BookingsChart from '../BookingsChart/BookingsChart';
import { models } from '@trova-trip/trova-models';
import { ChartItemValue } from '../BookingsChart/BookingsChart';
import { BookingStatusesColors, BookingStatuses } from 'config/constants';

type tripDetailsSelector = {
    userTripDetails: { current: models.tripDetails.TripDetails };
};

const getFormattedChartData = (
    chartData: models.tripDetails.ChartData,
): ChartItemValue[] => {
    const formattedChartData: ChartItemValue[] = [];

    chartData.labels.forEach((label, index) => {
        const formattedLabel = moment(label, 'MM/DD').format('MMM DD');

        const itemValue: ChartItemValue = {
            name: formattedLabel,
            bookings: chartData.series[0][index],
        };

        formattedChartData.push(itemValue);
    });

    return formattedChartData;
};

const BookingsTopCards = (): JSX.Element => {
    const [tripDetails, setTripDetails] =
        useState<models.tripDetails.TripDetails>();

    const currentTripDetails = useSelector(
        (state: tripDetailsSelector) => state.userTripDetails.current,
    );

    useEffect(() => {
        setTripDetails(currentTripDetails);
    }, [setTripDetails, currentTripDetails]);

    const bookingsChartData =
        tripDetails?.chartData && getFormattedChartData(tripDetails.chartData);

    const {
        pendingTravelers = 0,
        onHoldTravelers = 0,
        confirmedTravelers = 0,
    } = tripDetails || {};

    return (
        <Grid width='full'>
            {tripDetails && (
                <>
                    <Grid.Item
                        columnSpan={{
                            base: 12,
                            lg: 4,
                        }}
                    >
                        <InfoCard
                            title='Pending'
                            value={pendingTravelers.toString()}
                            color={
                                BookingStatusesColors[BookingStatuses.PENDING]
                            }
                        />
                    </Grid.Item>
                    <Grid.Item
                        columnSpan={{
                            base: 12,
                            lg: 4,
                        }}
                    >
                        <InfoCard
                            title='Waitlisted'
                            value={onHoldTravelers.toString()}
                            color={
                                BookingStatusesColors[BookingStatuses.WAITLIST]
                            }
                        />
                    </Grid.Item>
                    <Grid.Item
                        columnSpan={{
                            base: 12,
                            lg: 4,
                        }}
                    >
                        <InfoCard
                            title='Confirmed'
                            value={confirmedTravelers.toString()}
                            color={
                                BookingStatusesColors[BookingStatuses.CONFIRMED]
                            }
                        />
                    </Grid.Item>
                    <Grid.Item columnSpan={12}>
                        {bookingsChartData && (
                            <BookingsChart data={bookingsChartData} />
                        )}
                    </Grid.Item>
                </>
            )}
        </Grid>
    );
};

export default BookingsTopCards;
