import { models } from '@trova-trip/trova-models';
import {
    HOST_APP_ROUTE,
    OPERATOR_APP_ROUTE,
    TRAVELER_APP_ROUTE,
} from 'applications/common/constants/routesTokens';
import ProfileReader from 'util/profile/ProfileReader';
import { applicationRootPath } from '../../../../../navigation/NavigationContext';
import { PRODUCT_PATH } from '../config';

type User = models.users.User;

/**
 * Gets the appropriate application route based on the user's profile.
 *
 * @param user - The user for whom to determine the application route.
 * @returns {string} The application route associated with the user's profile.
 */
const getAppRouteByUser = (user: User): string => {
    const profileReader = ProfileReader.create(user);
    if (profileReader.isOperator()) {
        return OPERATOR_APP_ROUTE;
    }
    if (profileReader.isTraveler()) {
        return TRAVELER_APP_ROUTE;
    }
    return HOST_APP_ROUTE;
};

/**
 * Gets the account path for a user based on their profile.
 *
 * @param user - The user for whom to generate the account path.
 * @returns {string} The account path for the user.
 */
const getAccountPath = (user: User): string => {
    const appRoute = getAppRouteByUser(user);
    return `${applicationRootPath}${appRoute}${PRODUCT_PATH}`;
};

export { getAccountPath };
