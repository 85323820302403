import PropTypes from 'prop-types';
import { useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import ReviewTripConfirmationModal from '../../components/ReviewTripConfirmationModal/ReviewTripConfirmationModal';
import { useOperatorCanUpdateTrip } from '../utils/OperatorEditableFields';
import { redirectToTab } from '../utils/RedirectToTab';
import ConfigureBaseServices from './ConfigureBaseServices';
import useUpdateModelData from './useUpdateModelData';
import { OPERATOR_APP_TAB_ROUTE } from '../../../common/constants/routesTokens';

const CONFIRMATION_DIALOG_INITIAL_DATA = {
    show: false,
    tripId: null,
    tripData: {},
};

const model = 'trip';

const ConfigureTripServices = ({
    data,
    renderSuccess,
    renderError,
    updateModelData,
    onConfirmation,
    cleanErrors,
    errorText,
    disabled,
}) => {
    const history = useHistory();

    const [confirmationDialogData, setConfirmationDialogData] = useReducer(
        (prev, curr) => ({ ...prev, ...curr }),
        CONFIRMATION_DIALOG_INITIAL_DATA,
    );

    const canUpdateTrip = useOperatorCanUpdateTrip();

    const updateTripData = useUpdateModelData(
        updateModelData,
        renderError,
        renderSuccess,
    );

    const navigateToNextTab = () => {
        const nexTab = `${OPERATOR_APP_TAB_ROUTE.REVIEW_PRICING}?model=${model}`;
        redirectToTab(history, nexTab);
    };

    const onNextButtonClick = (id, data) => {
        if (!canUpdateTrip) {
            return navigateToNextTab();
        }
        setConfirmationDialogData({ show: true, tripId: id, tripData: data });
    };

    const onConfirmationModalAccepted = () => {
        const { tripId, tripData } = confirmationDialogData;

        onConfirmation(tripId, tripData);

        setConfirmationDialogData({ show: false });
    };

    return (
        <>
            <ConfigureBaseServices
                data={data}
                model={model}
                onNextButtonClick={onNextButtonClick}
                cleanErrors={cleanErrors}
                errorText={errorText}
                disabled={disabled}
                updateModelData={updateTripData}
                canUpdateServices={canUpdateTrip}
            />
            <ReviewTripConfirmationModal
                open={confirmationDialogData.show}
                onConfirmation={onConfirmationModalAccepted}
                onCancel={() => setConfirmationDialogData({ show: false })}
            />
        </>
    );
};

ConfigureTripServices.propTypes = {
    data: PropTypes.shape(),
    renderSuccess: PropTypes.func,
    renderError: PropTypes.func,
    updateModelData: PropTypes.func,
    onConfirmation: PropTypes.func,
    errorText: PropTypes.string,
    cleanErrors: PropTypes.func,
    disabled: PropTypes.bool,
};

export default ConfigureTripServices;
