import {
    Grid,
    Heading,
    Image,
    Stack,
    Text,
} from '@trova-trip/trova-components';
import {
    Button,
    ButtonProps,
    Card,
} from '@trova-trip/trova-components/build/next';
import { models } from '@trova-trip/trova-models';
import {
    doesBookingHavePendingRefund,
    isHost,
    isTraveler,
    shouldDisplayTransferItem,
} from '../../../../../../applications/common/helpers';
import transferImg from '../../../../../../assets/img/transfer-ride.svg';
import visaImg from '../../../../../../assets/img/travel-visa.svg';
import useNavigateToEVisaPage from '../../../../../common/hooks/useNavigateToEVisaPage';
import CardTitleHeader from '../../../../components/CardTitleHeader';
import useNavigateToTransfer from '../../../../hooks/useNavigateToTransfer';
import { useTransfer } from '../../hooks';

type Trip = models.trips.Trip;
type Itinerary = models.itineraries.Itinerary;
type BaseUser = models.users.BaseUser;
type SavedBooking = models.bookings.SavedBooking;

interface ServiceItemProps {
    imageProps: {
        src: string;
        alt: string;
    };
    title: string;
    description: string;
    actionProps: {
        children: ButtonProps['children'];
        onClick?: ButtonProps['onClick'];
        isVisible?: boolean;
        isDisabled?: ButtonProps['isDisabled'];
    };
    extraInformation?: string;
    showContactSupport?: boolean;
}

const hostNonActionableDescription =
    "Free transfers for Hosts will be available for booking closer to your trip's start date. Once accommodation addresses are locked in, you'll be able to reserve a vehicle and set your pickup times to complete your booking.";
const hostActionableDescription =
    'Schedule your free transportation by adding your flight details, contact info, and pickup times to complete your booking.';
const travelerNonActionableDescription =
    "Transportation to and from the airport will be available for booking closer to your trip's start date. Once accommodation addresses are locked in, you'll be able to select a vehicle, set your pickup time, and submit payment.";
const travelerActionableDescription =
    'Book transportation from and to the airport now by entering your flight numbers and timing preferences, choosing a vehicle, and submitting payment to reserve your ride.';

const StaticServiceItemInfo: Record<
    'eVisa' | 'transfer',
    Omit<ServiceItemProps, 'actionProps'>
> = {
    eVisa: {
        imageProps: {
            src: visaImg,
            alt: 'Travel Visa',
        },
        title: 'Check if you need a travel Visa',
        description:
            'Some destinations require international travelers to have a visa. Find out if you need a visa for your trip, and apply today.',
    },
    transfer: {
        imageProps: {
            src: transferImg,
            alt: 'Schedule Ride',
        },
        title: 'Schedule rides from the airport',
        description: travelerActionableDescription,
    },
};

const getTransferServiceItem = (
    trip: Trip,
    user: BaseUser | undefined,
    booking: SavedBooking | undefined,
    onClick: () => void,
): ServiceItemProps => {
    const isCtaVisible = trip.adminTechnicalItineraryApproved;
    const isHostUser = user ? isHost(user) : false;
    const isTravelerUser = user ? isTraveler(user) : false;
    const bookingHasPendingRefund = doesBookingHavePendingRefund(booking);

    let transferItem: ServiceItemProps = {
        ...StaticServiceItemInfo.transfer,
        actionProps: {
            children: 'Book Transfer',
            onClick,
            isVisible: isCtaVisible,
            isDisabled: bookingHasPendingRefund,
        },
    };

    if (isHostUser) {
        transferItem = {
            ...transferItem,
            description: isCtaVisible
                ? hostActionableDescription
                : hostNonActionableDescription,
        };
    }

    if (isTravelerUser) {
        transferItem = {
            ...transferItem,
            description: isCtaVisible
                ? travelerActionableDescription
                : travelerNonActionableDescription,
        };
    }

    if (isTravelerUser && bookingHasPendingRefund) {
        transferItem = {
            ...transferItem,
            extraInformation:
                'We are processing your refund. Please check back later or contact support.',
            showContactSupport: true,
        };
    }

    return transferItem;
};

const ServiceItem = ({
    imageProps,
    title,
    description,
    actionProps,
    extraInformation,
    showContactSupport,
}: ServiceItemProps) => {
    const { isVisible: isActionVisible = true, ...buttonProps } = actionProps;

    return (
        <Stack
            direction='column'
            spacing={4}
            height='full'
            justify='space-between'
        >
            <Stack direction='column' minHeight={{ md: '3xs' }}>
                <Image marginBottom={4} data-gumlet='false' {...imageProps} />
                <Heading
                    as='h6'
                    size={{ base: 'lg', md: 'xl' }}
                    fontWeight='medium'
                >
                    {title}
                </Heading>
                <Text>{description}</Text>

                {extraInformation ? (
                    <Text
                        fontWeight='medium'
                        fontSize='sm'
                        color='neutral.30'
                        paddingTop={2}
                    >
                        {extraInformation}
                    </Text>
                ) : null}
            </Stack>

            <Stack align='center' spacing={4}>
                {isActionVisible ? (
                    <Button
                        variant='secondary'
                        rightIcon='arrow-right'
                        {...buttonProps}
                    />
                ) : null}

                {showContactSupport ? (
                    <Button
                        as='a'
                        variant='tertiary'
                        size='sm'
                        leftIcon='offsite'
                        linkProps={{
                            href: 'https://trovatrip.com/company/contact-us',
                            target: '_blank',
                            rel: 'noreferrer',
                        }}
                    >
                        Contact Support
                    </Button>
                ) : null}
            </Stack>
        </Stack>
    );
};

interface UpcomingServicesCardProps {
    trip: Trip;
    itinerary: Itinerary | undefined;
    user: BaseUser | undefined;
    userCountryCode?: string;
    booking?: SavedBooking | undefined;
}

const UpcomingServicesCard = (props: UpcomingServicesCardProps) => {
    const { trip, userCountryCode, itinerary, user, booking } = props;

    const {
        globalState: { traveler },
    } = useTransfer();

    const navigateToEVisa = useNavigateToEVisaPage({
        trip,
        userCountryCode,
    });

    const navigateToTransfer = useNavigateToTransfer(trip);

    const serviceItems: ServiceItemProps[] = [
        {
            ...StaticServiceItemInfo.eVisa,
            actionProps: {
                children: 'Apply for a Visa',
                onClick: navigateToEVisa,
            },
        },
    ];

    const transferItemShouldBeVisible = shouldDisplayTransferItem({
        itinerary,
        trip,
        booking,
        traveler,
    });

    if (transferItemShouldBeVisible) {
        const transferItem = getTransferServiceItem(trip, user, booking, () =>
            navigateToTransfer('travel_detail'),
        );

        serviceItems.push(transferItem);
    }

    return (
        <Card>
            <CardTitleHeader>Upcoming For You</CardTitleHeader>
            <Grid marginTop={6}>
                {serviceItems.map((itemProps, index) => (
                    <Grid.Item
                        key={index}
                        columnSpan={{
                            base: 12,
                            md: 6,
                        }}
                        marginBottom={6}
                    >
                        <ServiceItem {...itemProps} />
                    </Grid.Item>
                ))}
            </Grid>
        </Card>
    );
};

export default UpcomingServicesCard;
