import { BaseBoxProps } from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import { useHistory } from 'react-router';
import StickyContentBase from './StickyContentBase';

interface StickyContentSimpleNavProps {
    title?: string;
    description?: string;
    routeToUrl?: string;
    buttonText?: string;
    marginTop?: BaseBoxProps['marginTop'];
}

const StickyContentSimpleNav = (
    props: StickyContentSimpleNavProps,
): JSX.Element => {
    const history = useHistory();
    const { description, title, routeToUrl, buttonText, marginTop } = props;

    return (
        <StickyContentBase
            title={title}
            description={description}
            marginTop={marginTop}
            action={
                <Button
                    variant='primary'
                    rightIcon='arrow-right'
                    size={{ base: 'sm', md: 'md' }}
                    onClick={(): void | string =>
                        routeToUrl && history.push(routeToUrl)
                    }
                >
                    {buttonText}
                </Button>
            }
        />
    );
};

export default StickyContentSimpleNav;
