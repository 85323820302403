import React from 'react';
import { Button } from '@trova-trip/trova-components/build/next';
import { useHistory } from 'react-router-dom';
import { Alert, BaseBox, Hide, Stack } from '@trova-trip/trova-components';
import {
    basePublicProfileRoute,
    editPublicProfileRoute,
} from '../PublicProfile';
import { usePublicProfileUrl } from '../../../hooks/usePublicProfileUrl';
import usePublicProfileAnalytics, {
    PUBLIC_PROFILE_TRACKING_EVENTS,
} from '../../../hooks/usePublicProfileAnalytics';

interface PublicProfileFormNavbarProps {
    isLoading: boolean;
    isDisabled: boolean;
}

interface PublicProfileFormMobileSaveProps {
    isFormSubmitFailed: boolean;
    isFormSubmitting: boolean;
    userCanSubmit: boolean;
    errorAlertCloseHandler: () => void;
    formErrorMessage?: string;
}

export const PublicProfileMicroAppNavbar = (): JSX.Element => {
    const { push } = useHistory();
    const publicProfileUrl = usePublicProfileUrl();
    const { trackPublicProfileEvent } = usePublicProfileAnalytics();

    const onClickPublicProfileButton = () => {
        trackPublicProfileEvent(
            PUBLIC_PROFILE_TRACKING_EVENTS.viewPublicProfile,
        );
        window.open(publicProfileUrl, '_blank', 'noopener');
    };

    const onClickEditProfileButton = () => {
        trackPublicProfileEvent(PUBLIC_PROFILE_TRACKING_EVENTS.editProfile);
        push(editPublicProfileRoute);
    };

    return (
        <Stack
            justify={{ base: 'center', md: 'end' }}
            align='center'
            wrap='nowrap'
            position={{ base: 'fixed', md: 'inherit' }}
            bottom={16}
            left={0}
            width='full'
            spacing={6}
            background={{ base: 'neutral.white', md: 'none' }}
            paddingX={{ base: 4, md: 0 }}
            paddingTop={{ base: 4, md: 0 }}
            paddingBottom={{ base: 4, md: 6 }}
            zIndex={1300}
        >
            <Button
                onClick={onClickPublicProfileButton}
                variant='tertiary'
                rightIcon='offsite'
                isFullWidth={{ base: true, md: false }}
            >
                View Public Profile
            </Button>
            <Button
                onClick={onClickEditProfileButton}
                variant='secondary'
                isFullWidth={{ base: true, md: false }}
            >
                Edit Profile
            </Button>
        </Stack>
    );
};

export const PublicProfileFormNavbar = ({
    isLoading,
    isDisabled,
}: PublicProfileFormNavbarProps): JSX.Element => {
    const { push } = useHistory();

    return (
        <Stack
            width='full'
            align='end'
            justify='space-between'
            paddingTop={{ base: 6, lg: 0 }}
        >
            <Button
                onClick={(): void => push(basePublicProfileRoute)}
                variant='tertiary'
                color='blueGray.650'
                leftIcon='arrow-left'
            >
                Back to Profile
            </Button>
            <Hide breakpoint={{ max: 'md' }}>
                <Button
                    type='submit'
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                >
                    Save
                </Button>
            </Hide>
        </Stack>
    );
};

export const PublicProfileFormMobileSave = ({
    isFormSubmitFailed,
    isFormSubmitting,
    userCanSubmit,
    errorAlertCloseHandler,
    formErrorMessage = '',
}: PublicProfileFormMobileSaveProps) => (
    <BaseBox
        position='fixed'
        width='full'
        bottom='0'
        left='0'
        backgroundColor='neutral.white'
        zIndex={1250}
        borderBottom='1px'
        borderColor='blueGray.200'
        boxShadow='5'
    >
        <BaseBox paddingBottom={16}>
            <Stack spacing={2} padding={4} align='center'>
                <BaseBox flex='1 1 auto'>
                    {isFormSubmitFailed || formErrorMessage ? (
                        <Alert
                            title={
                                formErrorMessage
                                    ? 'Required Fields are Missing'
                                    : 'Error'
                            }
                            variant='inline'
                            description={
                                formErrorMessage ||
                                'Profile form failed to save.'
                            }
                            status='error'
                            size='sm'
                            isClosable={!formErrorMessage}
                            onCloseAlert={errorAlertCloseHandler}
                            marginBottom={2}
                        />
                    ) : null}
                    <Button
                        isFullWidth
                        type='submit'
                        isLoading={isFormSubmitting}
                        isDisabled={!userCanSubmit}
                        marginTop={2}
                    >
                        Save
                    </Button>
                </BaseBox>
            </Stack>
        </BaseBox>
    </BaseBox>
);
