import useHostRecommendations from '../../../../hooks/useHostRecommendations';
import { Heading } from '@trova-trip/trova-components';
import { Card } from '@trova-trip/trova-components/build/next';
import RecommendationBannerWrapper from '../../../../components/RecommendationBanner/RecommendationBannerWrapper';
import { AdminRecommendationLocations } from '../../../../utils/trackingEvents.utils';

const AudienceRecommendedItineraries = (): JSX.Element | null => {
    const { recommendations, getItineraryRecommendation } =
        useHostRecommendations();

    return recommendations ? (
        <>
            <Heading as='h4' paddingTop={12} paddingBottom={4}>
                Recommended Itineraries
            </Heading>
            <Card
                backgroundColor='neutral.white'
                paddingY={4}
                paddingX={6}
                width='full'
            >
                <RecommendationBannerWrapper
                    trackingDataLocation={AdminRecommendationLocations.AUDIENCE}
                    layoutType='audience'
                    recommendations={recommendations}
                    getItineraryRecommendation={getItineraryRecommendation}
                />
            </Card>
        </>
    ) : null;
};

export default AudienceRecommendedItineraries;
