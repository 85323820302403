import { ApiRestClient } from '@trova-trip/trova-common';
import { config } from '../state/utils/api';
import { models } from '@trova-trip/trova-models';

// TODO: move this response wrapper form Maven server as a type to ApiResponse
type ApiResponseEnvelope<T = any> = {success:boolean; data: T};

const BASE_PATH = '/api/me';
class AudienceApiImpl extends ApiRestClient<ApiResponseEnvelope<models.survey.AudienceSurveySubmissionsDTO>> {
    constructor(path: string) {
        super({ ...config, path });
    }

    getAudienceResearch =
    async (): Promise<models.survey.AudienceSurveySubmissionsDTO> => {
        const response = await this.get(); // This is the APIResponse wrapping the Legacy 
        return (response.data as ApiResponseEnvelope<models.survey.AudienceSurveySubmissionsDTO>).data;
    };
}

const AudienceApi = {
    forHost: (): AudienceApiImpl =>
        new AudienceApiImpl(`${BASE_PATH}/audience`),
};

export default AudienceApi;
