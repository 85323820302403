import { models } from '@trova-trip/trova-models';
import { getItineraryReviewReport } from '../../../../apis/reviews';
import { useEffect, useState } from 'react';

type Itinerary = models.itineraries.BaseItinerary;

const useGetItineraryOverallRating = (
    itinerary: Itinerary,
): number | undefined => {
    const [itineraryOverallRating, setItineraryOverallRating] =
        useState<number>();

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            if (itinerary?.id) {
                const itineraryOverallRating = await getItineraryReviewReport(
                    itinerary.id,
                );

                setItineraryOverallRating(
                    itineraryOverallRating?.overallRating,
                );
            }
        };

        fetchData();
    }, [itinerary?.id]);

    return itineraryOverallRating;
};

export default useGetItineraryOverallRating;
