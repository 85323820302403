import { BaseBox, WithTooltip } from '@trova-trip/trova-components';
import { Icon } from '@trova-trip/trova-components/build/next';

const TooltipIconButton = WithTooltip(() => (
    <Icon
        aria-label='down-payment-info-button'
        as='help'
        display='flex'
        variant='outlined'
    />
));

const HelpTooltip = ({ text, ...rest }) => (
    <BaseBox
        display='flex'
        alignItems='center'
        justifyContent='center'
        onClick={(e) => e.stopPropagation()}
        {...rest}
    >
        <TooltipIconButton
            tooltipConfigProps={{
                placement: 'bottom',
                hasArrow: true,
                tooltip: text,
                tooltipToggleMode: 'click',
                closeOnEscape: true,
            }}
        />
    </BaseBox>
);

export default HelpTooltip;
