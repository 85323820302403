import { Redirect } from 'react-router';
import { findHighestPriorityCategoryItem } from '../../../../util/navigationUtils';
import { NavigationCategory } from '../../../../interfaces/Navigation.types';
interface ProductDefaultViewProps {
    navigationContent?: NavigationCategory[] | [];
}

export const ProductDefaultView = ({
    navigationContent,
}: ProductDefaultViewProps): JSX.Element | null => {
    if (!navigationContent) {
        return null;
    }
    const defaultItem = findHighestPriorityCategoryItem(navigationContent);
    const path = defaultItem?.link ? defaultItem.link : 'itineraries/';
    return <Redirect to={path} />;
};
