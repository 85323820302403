import { useMemo, useContext } from 'react';
import { BaseBox, Stack } from '@trova-trip/trova-components';
import {
    Button,
    Sidebar as SidebarComponent,
} from '@trova-trip/trova-components/build/next';
import { withAnalytics } from 'analytics';
import { capitalize } from '../../../applications/utils';
import { ButtonWithAnalyticsProps, SidebarProps } from './Sidebar.types';
import NavigationContext from '../../NavigationContext';
import { useFormatSidebarProps } from '../hooks';
import { getButtonSidebarProps } from './utils';
import { useHistory } from 'react-router-dom';

const SidebarButtonWithAnalytics =
    withAnalytics<ButtonWithAnalyticsProps>(Button);

const Sidebar = ({
    menuItems = [],
    sidebarComponent,
    ...rest
}: SidebarProps) => {
    const { showSidebarButton, profile } = useContext(NavigationContext);
    const { itemGroups } = useFormatSidebarProps(menuItems);
    const history = useHistory();
    const currentButtonProps = getButtonSidebarProps(history, rest);
    const initialOpenGroupIndex = useMemo(() => {
        const initialIndex = itemGroups.findIndex((group) => {
            return group.items.some((item) => item.isSelected);
        });
        return initialIndex === -1 ? 0 : initialIndex;
    }, [itemGroups]);

    return (
        <SidebarComponent>
            {showSidebarButton && currentButtonProps ? (
                <Stack justify='space-between' align='center'>
                    <SidebarButtonWithAnalytics
                        marginY={8}
                        {...currentButtonProps}
                        analyticsData={{
                            type: 'onClick',
                            eventName: `${capitalize(
                                profile?.group,
                            )} Clicked '${currentButtonProps?.children?.toString()}'`,
                        }}
                    >
                        {currentButtonProps?.children || ''}
                    </SidebarButtonWithAnalytics>
                </Stack>
            ) : null}

            {sidebarComponent ? (
                <BaseBox paddingY={4}>{sidebarComponent}</BaseBox>
            ) : null}

            {itemGroups.length !== 0 ? (
                <SidebarComponent.Menu
                    initialOpenGroupIndex={initialOpenGroupIndex}
                >
                    {itemGroups.map(({ title, items }) => (
                        <SidebarComponent.Group
                            key={title}
                            title={title}
                            items={items}
                        />
                    ))}
                </SidebarComponent.Menu>
            ) : null}
        </SidebarComponent>
    );
};

export default Sidebar;
