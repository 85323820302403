import { BaseBoxProps } from '@trova-trip/trova-components';
import { Button, ButtonProps } from '@trova-trip/trova-components/build/next';
import StickyContentBase from './StickyContentBase';

interface StickyContentSimpleActionProps {
    title?: string;
    subtitle?: string;
    description?: string;
    buttonProps: ButtonProps;
    marginTop?: BaseBoxProps['marginTop'];
}

const StickyContentSimpleAction = (
    props: StickyContentSimpleActionProps,
): JSX.Element => {
    const { description, title, buttonProps, subtitle, marginTop } = props;

    return (
        <StickyContentBase
            title={title}
            subtitle={subtitle}
            description={description}
            action={
                <Button
                    {...buttonProps}
                    size={{ base: 'sm', md: 'md' }}
                    variant='primary'
                />
            }
            marginTop={marginTop}
        />
    );
};

export default StickyContentSimpleAction;
