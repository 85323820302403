import StickyContentBase from 'applications/host/components/StickyContent/StickyContentBase';

const ServicesAgreementStickyLayoutContent = (): React.ReactElement => (
    <StickyContentBase
        title={'Trip Terms'}
        description={
            "These are the terms for hosting your trip with us. If you need to make a change, you'll still be able to update your pricing and trip details after accepting!"
        }
    />
);

export default ServicesAgreementStickyLayoutContent;
