import { useState, SyntheticEvent, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { constants, models } from '@trova-trip/trova-models';
import ServiceCard from '../../../common/components/services/ServiceCard';
import ConfirmationDialog from 'applications/common/components/ConfirmationDialog';
import {
    Input,
    Textarea,
    TextareaSize,
    Dropdown,
    Heading,
    Text,
    Grid,
    Stack,
    useToast,
} from '@trova-trip/trova-components';
import { Button, Card } from '@trova-trip/trova-components/build/next';
import { getServiceIndexById } from '../../../../util/bookingUtils';
import { getTripDatesIfApplicable } from '../../../../util/ModelDataHelper';
import { userTrips } from '../../../../state/userTrips';
import { services } from '../../../../state/services';
import FakeEvent from '../../../../util/form/FakeEvent';
import { useManageElementRefs } from '../../../../util/hooks/domHooks';
import { ServiceTypesKeys } from '../../../../config/constants';
import { DropdownOption } from '../../../../interfaces/FormComponents';
import { insertServiceAndSort } from '../../../common/helpers';
import { noop } from 'lodash';
import ButtonsContainer, {
    cancelButtonDefault,
    saveButtonDefault,
} from '../../../common/components/services/ButtonsContainer';
import { getServiceTitleLabel } from '../../../common/components/services/LibraryServiceFormatter';
import { ServicesByDayUIModel } from '../../../../interfaces/UiModels.types';
import { TripContext } from 'applications/host/products/trips/TripsContext';

const {
    services: { ServiceType },
    trips: { TRIP_STATUS },
} = constants;

const FormInputNames = {
    SERVICE_NAME: 'name',
    SERVICE_DESCRIPTION: 'description',
    SERVICE_DAY_INDEX: 'dayIndex',
};

const TEMPORARY_ID = 'TEMPORARY_ID';

type UiModelService = models.services.Service & {
    dayIndex: number;
    removed: boolean;
};
interface TripServicesUiModel {
    service: UiModelService;
    collapsed: boolean;
    title: string;
}

const createUiModels = (
    servicesByDay: models.services.ServicesByDay,
    tripDatesLabels: string[],
): TripServicesUiModel[] => {
    const uiModelList =
        servicesByDay?.flatMap(
            (day: models.services.DayService[], dayIndex) => {
                return day.map((item) => {
                    const service = item.service as models.services.Service;
                    const title = `${getServiceTitleLabel(
                        service.type,
                    )} - Day ${dayIndex + 1} - ${tripDatesLabels[dayIndex]}`;

                    return {
                        service: { ...service, dayIndex, removed: false },
                        collapsed: true,
                        title,
                    };
                });
            },
        ) || [];

    return uiModelList;
};

const updateModelData = (
    servicesByDay: ServicesByDayUIModel,
    updatedService: TripServicesUiModel['service'],
    originalDayIndex: number,
    serviceIndex: number,
): models.services.DayService[][] => {
    const updatedModel = [...servicesByDay];
    originalDayIndex > -1 &&
        updatedModel[originalDayIndex].splice(serviceIndex, 1);

    if (!updatedService.removed) {
        const updatedOrderedModel = insertServiceAndSort(
            updatedModel,
            updatedService.dayIndex,
            updatedService,
            1,
        );

        return updatedOrderedModel;
    }

    return updatedModel;
};

const InformalGatherings = (): JSX.Element => {
    const currentTrip = useSelector(
        (state: { userTrips: { current: models.trips.Trip } }) =>
            state.userTrips.current,
    );
    const { updateRecord: updateTripEntity, getRecord: getTrip } =
        userTrips.useDispatch.bind(currentTrip)();
    const { createRecord: createService, updateRecord: updateServiceEntity } =
        services.useDispatch();

    const { triggerChangeOnTripPublishData } = useContext(TripContext);

    const toast = useToast();

    const [shouldScroll, setShouldScroll] = useState(false);

    useEffect(() => {
        if (shouldScroll) {
            scrollToElement(TEMPORARY_ID);
            setShouldScroll(false);
        }
    }, [shouldScroll]);

    const { servicesByDay, startDate, status } = currentTrip;

    const tripDates = getTripDatesIfApplicable(
        startDate,
        servicesByDay,
        'dddd, MMMM DD, YYYY',
    );

    const uiModelData = createUiModels(
        servicesByDay as models.services.ServicesByDay,
        tripDates,
    );
    const [gatheringForDelete, setGatheringForDelete] = useState<
        TripServicesUiModel['service'] | null
    >(null);

    const [currentUiModelData, setCurrentUiModelData] =
        useState<TripServicesUiModel[]>(uiModelData);

    const { getElementRef: getServiceRef, scrollToElement } =
        useManageElementRefs(
            currentUiModelData.map(({ service }) => service._id as string),
        );

    useEffect(() => {
        setCurrentUiModelData(uiModelData);
    }, [currentTrip, setCurrentUiModelData]);

    const dayDropdownOptions: DropdownOption[] =
        servicesByDay?.map((_, dayIndex) => {
            return {
                value: dayIndex.toString(),
                children: `Day ${dayIndex + 1}- ${tripDates[dayIndex]}`,
            };
        }) || [];

    const removeEmptyService = (): void => {
        const updatedUiModelData = [...currentUiModelData];
        const serviceIndex = getServiceIndexById(
            TEMPORARY_ID,
            currentUiModelData,
        );
        updatedUiModelData[serviceIndex].service.removed = true;
        setCurrentUiModelData(updatedUiModelData);
    };

    const addEmptyService = (): void => {
        const emptyService: TripServicesUiModel['service'] = {
            _id: TEMPORARY_ID,
            name: '',
            description: '',
            type: ServiceType.INFORMAL_GATHERING,
            user: '',
            operator: '',
            serviceLibrary: '',
            createdDate: new Date(),
            dayIndex: 0,
            removed: false,
            deleted: false,
        };

        const newUiModelItem: TripServicesUiModel = {
            service: emptyService,
            collapsed: false,
            title: getServiceTitleLabel(
                ServiceTypesKeys.INFORMAL_GATHERING as models.services.ServiceType,
            ),
        };

        const serviceIndex = getServiceIndexById(
            TEMPORARY_ID,
            currentUiModelData,
        );

        if (serviceIndex > -1) {
            const updatedUiModelData = [...currentUiModelData];
            updatedUiModelData[serviceIndex] = newUiModelItem;

            setCurrentUiModelData(updatedUiModelData);
        } else {
            setCurrentUiModelData((currentState) => {
                return [...currentState, newUiModelItem];
            });
        }
        setShouldScroll(true);
    };

    const showErrorToast = (error) =>
        toast({
            title: 'Something went wrong',
            description: error,
            status: 'error',
            isClosable: true,
        });

    const collapseCard = (serviceId: string, collapsed: boolean): void => {
        const updatedUiModelData = [...currentUiModelData];
        const serviceIndex = getServiceIndexById(serviceId, currentUiModelData);

        updatedUiModelData[serviceIndex].collapsed = collapsed;

        setCurrentUiModelData(updatedUiModelData);
    };

    const handleAddGathering = (): void => {
        addEmptyService();
    };

    const handleEdit = (serviceId: string): void => {
        collapseCard(serviceId, false);
    };

    const handleCancel = (service: TripServicesUiModel['service']): void => {
        const serviceId = service._id as string;
        if (serviceId === TEMPORARY_ID) {
            removeEmptyService();
        } else {
            collapseCard(serviceId, true);
        }
    };

    const handleChange = (
        event: SyntheticEvent | FakeEvent,
        serviceId: string,
    ): void => {
        const eventTarget = event.target as HTMLInputElement;
        const updatedUiModelData = [...currentUiModelData];
        const serviceIndex = getServiceIndexById(serviceId, currentUiModelData);

        updatedUiModelData[serviceIndex].service[eventTarget.name] =
            eventTarget.value;

        setCurrentUiModelData(updatedUiModelData);
    };

    const updateTrip = (
        newServiceByDay: models.services.ServicesByDay,
        onSuccess: () => void,
    ): void => {
        updateTripEntity(
            currentTrip.id,
            {
                servicesByDay: newServiceByDay,
            },
            {
                successCallback: () => {
                    triggerChangeOnTripPublishData();
                    onSuccess();
                },
                errorCallback: (error) => {
                    showErrorToast(error);
                },
            },
        );
    };

    const updateService = (
        updatedServiceData: models.services.Service,
        onSuccess: (updatedService: models.services.Service) => void,
    ): void => {
        updateServiceEntity(
            updatedServiceData._id,
            updatedServiceData.type,
            updatedServiceData,
            {
                successCallback: (updatedService: models.services.Service) => {
                    triggerChangeOnTripPublishData();
                    onSuccess(updatedService);
                },
                errorCallback: (error) => {
                    showErrorToast(error);
                },
            },
        );
    };

    const createNewService = (
        service: models.services.Service,
        onSuccess: (newService: models.services.Service) => void,
    ): void => {
        createService(
            {
                type: service.type,
                name: service.name,
                description: service.description,
            },
            service.type,
            {
                successCallback: (newService: models.services.Service) => {
                    onSuccess(newService);
                },
                errorCallback: (error) => {
                    showErrorToast(error);
                },
            },
        );
    };

    const getServiceIndex = (
        serviceId: string,
    ): { originalDayIndex: number; serviceIndex: number } => {
        const originalDayIndex =
            uiModelData[getServiceIndexById(serviceId, uiModelData)]?.service
                .dayIndex;

        const serviceIndex =
            (servicesByDay &&
                getServiceIndexById(
                    serviceId,
                    (servicesByDay[originalDayIndex] as unknown as [
                        { service: models.services.Service },
                    ]) || [],
                )) ||
            0;

        return { originalDayIndex, serviceIndex };
    };

    const handleSave = (service: TripServicesUiModel['service']): void => {
        const { originalDayIndex, serviceIndex } = getServiceIndex(
            service._id as string,
        );

        const hasDayIndexChanged = originalDayIndex != service.dayIndex;

        if (service._id === TEMPORARY_ID) {
            createNewService(service, (newService) => {
                const updatedServicesByDay = updateModelData(
                    servicesByDay as ServicesByDayUIModel,
                    {
                        ...newService,
                        dayIndex: service.dayIndex,
                        removed: false,
                    },
                    -1,
                    serviceIndex,
                );

                updateTrip(updatedServicesByDay, () => {
                    getTrip(currentTrip.id);
                    collapseCard(service._id as string, true);
                    toast({
                        title: 'Gathering created',
                        description: 'The gathering was created successfully.',
                        status: 'success',
                        isClosable: true,
                    });
                });
            });
        } else if (hasDayIndexChanged) {
            updateService(service, (updatedService) => {
                const updatedServicesByDay = updateModelData(
                    servicesByDay as ServicesByDayUIModel,
                    {
                        ...updatedService,
                        dayIndex: service.dayIndex,
                        removed: false,
                    },
                    originalDayIndex,
                    serviceIndex,
                );

                updateTrip(updatedServicesByDay, () => {
                    collapseCard(service._id as string, true);
                    getTrip(currentTrip.id);
                    toast({
                        title: 'Gathering saved',
                        description: 'The gathering was saved successfully.',
                        status: 'success',
                        isClosable: true,
                    });
                });
            });
        } else {
            updateService(service, () => {
                collapseCard(service._id as string, true);
                getTrip(currentTrip.id);
                toast({
                    title: 'Gathering updated',
                    description: 'The gathering was updated successfully.',
                    status: 'success',
                    isClosable: true,
                });
            });
        }
    };

    const removeService = (service: TripServicesUiModel['service']): void => {
        const { originalDayIndex, serviceIndex } = getServiceIndex(
            service._id as string,
        );

        service.removed = true;

        const updatedServicesByDay = updateModelData(
            servicesByDay as ServicesByDayUIModel,
            service,
            originalDayIndex,
            serviceIndex,
        );

        updateTrip(updatedServicesByDay, () => {
            getTrip(currentTrip.id);
            toast({
                title: 'Gathering deleted',
                description: 'The gathering was deleted successfully.',
                status: 'success',
                isClosable: true,
            });
        });
    };

    const handleRemoveDialogConfirmation = (): void => {
        gatheringForDelete && removeService(gatheringForDelete);
        setGatheringForDelete(null);
    };

    return (
        <Grid>
            <Grid.Item columnSpan={{ base: '*', md: 10 }}>
                <Stack direction='column'>
                    <Heading as={'h4'}>Informal Gatherings</Heading>
                    <Text>
                        Informal gatherings allow you to bring your travelers
                        together in a casual setting and do not increase your
                        trip price. These gatherings require no provided
                        resources from our partner Operator, such as grabbing
                        coffee or an impromptu photo shoot at the beach.
                        Informal Gatherings are not a service provided by Trova
                        on your trip. Trova assumes no liability for Informal
                        Gatherings arranged by you with your travelers.
                    </Text>
                </Stack>
            </Grid.Item>
            <Grid.Item columnSpan={{ base: '*', md: 10 }}>
                <Stack direction='column' spacing={6} align='stretch'>
                    {currentUiModelData
                        .filter(
                            ({ service }) =>
                                (service as models.services.Service).type ===
                                    ServiceTypesKeys.INFORMAL_GATHERING &&
                                !service.removed,
                        )
                        .map((informalGathering) => {
                            const { service, collapsed, title } =
                                informalGathering;

                            return (
                                <Card
                                    backgroundColor='neutral.white'
                                    key={service._id}
                                >
                                    <ServiceCard
                                        //@ts-expect-error
                                        ref={getServiceRef(
                                            service._id as string,
                                        )}
                                        title={title}
                                        collapsed={collapsed}
                                        service={service}
                                        actionsBarConfig={{
                                            remove: {
                                                shouldRender: true,
                                                handler: (): void =>
                                                    setGatheringForDelete(
                                                        service,
                                                    ),
                                            },
                                            toggleChildren: {
                                                shouldRender: true,
                                                handler: ({
                                                    serviceId,
                                                }): void => {
                                                    handleEdit(
                                                        serviceId as string,
                                                    );
                                                },
                                            },
                                        }}
                                    >
                                        <Stack
                                            spacing={4}
                                            marginTop={4}
                                            direction='column'
                                            align='stretch'
                                        >
                                            <Input
                                                name={
                                                    FormInputNames.SERVICE_NAME
                                                }
                                                value={service.name}
                                                label={'Name'}
                                                onChange={(event): void => {
                                                    service._id &&
                                                        handleChange(
                                                            event,
                                                            service._id,
                                                        );
                                                }}
                                            />
                                            <Dropdown
                                                name={
                                                    FormInputNames.SERVICE_DAY_INDEX
                                                }
                                                value={service.dayIndex.toString()}
                                                label={'Day'}
                                                onSearch={noop}
                                                onChange={(
                                                    _,
                                                    eventName,
                                                    eventValue,
                                                ): void => {
                                                    service._id &&
                                                        handleChange(
                                                            new FakeEvent(
                                                                eventName,
                                                                eventValue,
                                                            ),
                                                            service._id,
                                                        );
                                                }}
                                            >
                                                {dayDropdownOptions}
                                            </Dropdown>
                                            <Textarea
                                                name={
                                                    FormInputNames.SERVICE_DESCRIPTION
                                                }
                                                value={service.description}
                                                label={'Description'}
                                                size={TextareaSize.Flexible}
                                                onChange={(event): void => {
                                                    service._id &&
                                                        handleChange(
                                                            event,
                                                            service._id,
                                                        );
                                                }}
                                            />
                                            <ButtonsContainer
                                                buttons={[
                                                    cancelButtonDefault({
                                                        onClick: () =>
                                                            handleCancel(
                                                                service,
                                                            ),
                                                    }),
                                                    saveButtonDefault({
                                                        text: 'Save',
                                                        onClick: () =>
                                                            handleSave(service),
                                                    }),
                                                ]}
                                            />
                                        </Stack>
                                    </ServiceCard>
                                </Card>
                            );
                        })}
                </Stack>
                <Button
                    variant='secondary'
                    rightIcon='plus'
                    isDisabled={status === TRIP_STATUS.CLOSED}
                    onClick={handleAddGathering}
                    marginTop={6}
                >
                    Gathering
                </Button>
                <ConfirmationDialog
                    open={!!gatheringForDelete}
                    title={'Delete Gathering?'}
                    description={
                        'Deleting a gathering will permanently remove it from your trip. If you delete a gathering and change your  mind, you can add new gatherings to your trip before signing the service agreement.'
                    }
                    confirmButtonProps={{ children: 'Confirm' }}
                    onConfirmation={handleRemoveDialogConfirmation}
                    onCancel={(): void => {
                        setGatheringForDelete(null);
                    }}
                />
            </Grid.Item>
        </Grid>
    );
};

export default InformalGatherings;
