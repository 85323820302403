import React from 'react';
import { Stack } from '@trova-trip/trova-components';
import {
    Button,
    ButtonProps as TCButtonProps,
} from '@trova-trip/trova-components/build/next';

interface ButtonProps {
    text?: string;
    onClick: () => void;
    className?: string;
    isDisabled?: boolean;
    variant?: TCButtonProps['variant'];
    icon?: TCButtonProps['rightIcon'];
    type?: TCButtonProps['type'];
}

export const cancelButtonDefault = (props: ButtonProps): ButtonProps => ({
    text: 'Cancel',
    variant: 'secondary',
    ...props,
});

export const saveButtonDefault = (props: ButtonProps): ButtonProps => ({
    text: 'Confirm',
    variant: 'primary',
    ...props,
});

export const removeButtonDefault = (props: ButtonProps): ButtonProps => ({
    variant: 'tertiary',
    icon: 'trash',
    ...props,
});

interface ButtonsContainerProps {
    buttons: ButtonProps[];
    className?: string;
}

const ButtonsContainer = (props: ButtonsContainerProps): JSX.Element => {
    const { className, buttons } = props;

    return (
        <Stack
            spacing={3}
            marginTop={8}
            justify={'flex-end'}
            className={className}
        >
            {buttons.map((button, buttonIndex) => {
                const { text, isDisabled = false, ...rest } = button;
                return (
                    <Button key={buttonIndex} isDisabled={isDisabled} {...rest}>
                        {text}
                    </Button>
                );
            })}
        </Stack>
    );
};

export default ButtonsContainer;
