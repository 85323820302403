import { GeoLocationComboBoxField } from '@trova-trip/trova-components/build/custom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { profile, ProfileStoreSelector } from '../../../../../state/profile';
import { findOptionFromValue } from '../../../../../util/form/dropdown';
import { logError } from '../../../helpers';
import {
    AddressFieldsNames,
    ProfileForm,
    ProfileFormModel,
    ProfileFormResponse,
    User,
} from '../types/ProfileForm.types';
import { findSubdivisionOption } from '../utils/address.utils';

const { getCountryComboBoxOptions } = GeoLocationComboBoxField;

const countriesOptions = getCountryComboBoxOptions();

interface UseProfileFormAPIReturn {
    initialValues: ProfileForm;
    handleSubmit: (
        data: ProfileFormModel | { address: User['address'] },
    ) => Promise<ProfileFormResponse>;
    isLoading: boolean;
}

const useProfileFormAPI = (): UseProfileFormAPIReturn => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const currentUser = useSelector(
        (state: ProfileStoreSelector) => state.profile.current,
    );

    const dispatch = profile.useDispatch();

    const handleSubmit = async (
        data: ProfileFormModel | { address: User['address'] },
    ): Promise<ProfileFormResponse> => {
        try {
            setIsLoading(true);
            const profile: User = await dispatch.updateRecord(undefined, data);
            return { profile };
        } catch (error) {
            logError(`Error submitting profile form. ${error}`);
            return { error };
        } finally {
            setIsLoading(false);
        }
    };

    const primaryAddress = currentUser.address?.[0];

    const initialValues: ProfileForm = {
        profile: {
            firstName: currentUser?.firstName ?? '',
            lastName: currentUser?.lastName ?? '',
            email: currentUser?.email ?? '',
            phoneNumber: currentUser?.phoneNumber ?? '',
            instagramHandle: currentUser?.instagramHandle ?? '',
            profilePictureUrl: currentUser?.profilePictureUrl ?? '',
            bio: currentUser?.bio ?? '',
            gender: currentUser?.gender ?? '',

            displayName: currentUser?.displayName ?? '',
            paypalEmail: currentUser?.paypalEmail ?? '',
            websiteUrl: currentUser?.websiteUrl ?? '',
            emailSignature: currentUser?.emailSignature ?? '',
        },
        address: {
            [AddressFieldsNames.STREET_LINE_ONE]:
                primaryAddress?.streetLineOne ?? '',
            [AddressFieldsNames.STREET_LINE_TWO]:
                primaryAddress?.streetLineTwo ?? '',
            [AddressFieldsNames.CITY]: primaryAddress?.city ?? '',
            [AddressFieldsNames.SUBDIVISION_CODE]: findSubdivisionOption({
                countryCode: primaryAddress?.countryCode,
                value: primaryAddress?.subdivisionCode,
            }),
            [AddressFieldsNames.POSTAL_CODE]: primaryAddress?.postalCode ?? '',
            [AddressFieldsNames.COUNTRY_CODE]: findOptionFromValue(
                primaryAddress?.countryCode || '',
                countriesOptions,
            ),
        },
    };

    return { initialValues, handleSubmit, isLoading };
};

export default useProfileFormAPI;
