import { Button, ButtonProps } from '@trova-trip/trova-components/build/next';
import { useContext } from 'react';
import { OnboardingStepperContext } from '../OnboardingStepperContext';

const NextButton = (props: Partial<ButtonProps>): JSX.Element => {
    const { children = 'Next', ...rest } = props;
    const { goNextStep } = useContext(OnboardingStepperContext);
    return (
        <Button
            rightIcon='arrow-right'
            onClick={goNextStep}
            marginTop={{ base: 6, xl: 0 }}
            {...rest}
        >
            {children}
        </Button>
    );
};

export default NextButton;
