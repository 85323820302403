import { css } from '@emotion/css';
import { BaseBox, Image, Stack, Hide } from '@trova-trip/trova-components';
import OnboardingImage1 from 'assets/img/host-onboarding/onboarding-stepper-1.png';
import OnboardingImage2 from 'assets/img/host-onboarding/onboarding-stepper-2.png';
import OnboardingImageMobile from 'assets/img/host-onboarding/onboarding-stepper-mobile.png';
import MobileLineLight from 'assets/img/host-onboarding/line-dark.svg';
import MobileLineDark from 'assets/img/host-onboarding/line-light.svg';
import React from 'react';

interface ImageOutlineProps {
    top?: number;
    left: number;
    rotation: number;
}

const ImageOutline = ({ top, left, rotation }: ImageOutlineProps) => (
    <BaseBox
        position='absolute'
        width='80%'
        height='80%'
        top={top}
        zIndex={0}
        left={left}
        borderRadius='2xl'
        border='1px'
        borderColor='teal.trova'
        className={css`
            transform: rotate(${rotation}deg);
        `}
    />
);

const StepImage = (props) => {
    const { showMobileImage } = props;
    return (
        <>
            {showMobileImage && (
                <Hide breakpoint={{ min: 'md' }}>
                    <BaseBox width='100vw' position='relative' marginBottom={8}>
                        <Image
                            width='full'
                            fit='contain'
                            src={OnboardingImageMobile}
                        />
                        <Image
                            position='absolute'
                            top={1}
                            left={0}
                            width='full'
                            src={MobileLineDark}
                            data-gumlet='false'
                        />
                        <Image
                            position='absolute'
                            top={1.5}
                            left={0}
                            width='full'
                            src={MobileLineLight}
                            data-gumlet='false'
                        />
                    </BaseBox>
                </Hide>
            )}
            <Hide breakpoint={{ max: 'md' }}>
                <Stack align='center' justify='center' wrap='nowrap'>
                    <BaseBox left={16} position='relative'>
                        <ImageOutline left={6} rotation={8.9} />
                        <Image
                            src={OnboardingImage1}
                            position='relative'
                            zIndex={100}
                        />
                    </BaseBox>
                    <BaseBox position='relative' right={8} top={8}>
                        <ImageOutline top={2} left={12} rotation={-3.8} />
                        <Image
                            src={OnboardingImage2}
                            position='relative'
                            zIndex={100}
                        />
                    </BaseBox>
                </Stack>
            </Hide>
        </>
    );
};

export default StepImage;
