import { SyntheticEvent, useState } from 'react';
import { Text, TextInput } from '@trova-trip/trova-components';
import { EditFieldProps, PreviewProps } from '../../utils/types';

interface DisplayNamePreviewProps extends PreviewProps<'displayName'> {}

export const DisplayNamePreview = ({
    displayName,
}: DisplayNamePreviewProps): JSX.Element => {
    return <Text>{displayName || 'No display name given.'}</Text>;
};

export const DisplayNameEditField = ({
    fieldData = '',
    onChange,
}: EditFieldProps<'displayName'>): JSX.Element => {
    const [displayName, setDisplayName] = useState<string>(fieldData as string);

    return (
        <TextInput
            name='displayName'
            value={displayName}
            size='md'
            placeholder='Chosen Name'
            onChange={(event: SyntheticEvent) => {
                const value = (event.target as HTMLInputElement).value;

                setDisplayName(value);
                onChange(value);
            }}
        />
    );
};
