import { constants } from '@trova-trip/trova-models';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import values from 'lodash/values';
import { BookingRepriceQuote } from '../../../apis/types';
import {
    Accommodations,
    AddOnManageMode,
    BookingAddOn,
    MANAGE_BOOKING_ADDON_STATUS,
    ManageBookingAddOn,
    ManageBookingViewModel,
    RoomOccupancies,
    Service,
} from '../../../applications/traveler/tabs/ManageBooking/types';
import {
    arePreAndPostAccommodationsAvailable,
    isUpgradeToPrivateRoomSelected as checkIsUpgradeToPrivateRoomSelected,
    getAddOnStatusAndType,
    isPendingStatusAddOn,
    isPrivateRoomUpgradeAvailable,
    isUpgradeToPrivateRoomSelected,
} from '../../../applications/traveler/tabs/ManageBooking/utils/check.utils';
import {
    filterAddOnsByStatus,
    getPreAnPostTransfersTimingSelected,
    getPreAndPostAccommodationTimingSelected,
    getRoomsOccupancies,
    getSelectedTravelersRooms,
} from '../../../applications/traveler/tabs/ManageBooking/utils/extract.utils';
import {
    calculateAddOnsSubTotal,
    calculatePriceAdjustmentsSubTotal,
    getPreAndPostAddOnsPriceAdjustments,
    getPricingFromState,
    toNegative,
} from '../../../applications/traveler/tabs/ManageBooking/utils/pricing.utils';
import {
    ConfirmationMode,
    InsuranceSectionStatus,
    ManageBookingError,
    ManageBookingState,
    OccupanciesFromRoomSelection,
    PreAndPostTransfersAddOnPrice,
    ServiceTimingType,
    ServiceInventoryStatus,
    SummaryItem,
    ToggleAccommodationStatusParams,
    ToggleActionType,
    ToggleActivitiesStatusParams,
    ToggleTransferStatusParams,
    UPDATE_ROOM_OCCUPANCIES,
    UpdateRoomOccupanciesStatusParams,
    UpdateSingleSupplementAddOnParams,
    UpdateTravelersRoomsStatusParams,
    ValidOccupanciesType,
} from './types';

const { Occupancy, ServiceTiming } = constants.services;

const validOccupancies = [
    Occupancy.single,
    Occupancy.double,
    Occupancy.twin,
] as const;

const validServiceTiming = [
    ServiceTiming.PRE_TRIP,
    ServiceTiming.POST_TRIP,
] as const;

const statusOnAddMap = {
    [MANAGE_BOOKING_ADDON_STATUS.AVAILABLE]:
        MANAGE_BOOKING_ADDON_STATUS.PENDING_ADDITION,
    [MANAGE_BOOKING_ADDON_STATUS.PENDING_REMOVAL]:
        MANAGE_BOOKING_ADDON_STATUS.CONFIRMED,
};

const statusOnRemoveMap = {
    [MANAGE_BOOKING_ADDON_STATUS.CONFIRMED]:
        MANAGE_BOOKING_ADDON_STATUS.PENDING_REMOVAL,
    [MANAGE_BOOKING_ADDON_STATUS.PENDING_ADDITION]:
        MANAGE_BOOKING_ADDON_STATUS.AVAILABLE,
};

const statusOnUpdateMap = {
    ...statusOnAddMap,
    ...statusOnRemoveMap,
};

const updateAddOnStatus = (
    actionType: ToggleActionType,
    status: MANAGE_BOOKING_ADDON_STATUS,
): MANAGE_BOOKING_ADDON_STATUS => {
    const updatedStatusMap = {
        [ToggleActionType.ADD]: statusOnAddMap[status],
        [ToggleActionType.REMOVE]: statusOnRemoveMap[status],
    };

    return updatedStatusMap[actionType] || status;
};

const isPreOrPostTripServiceTiming = (timing: ServiceTimingType): boolean => {
    return timing !== ServiceTiming.DURING_TRIP;
};

const getOccupancyMapFromRoomSelection = (
    roomSelection: ValidOccupanciesType[],
): OccupanciesFromRoomSelection => {
    return roomSelection.reduce(
        (occupancies, occupancy) => {
            if (!occupancies[occupancy]) {
                occupancies[occupancy] = 0;
            }

            occupancies[occupancy] = occupancies[occupancy] + 1;

            return occupancies;
        },
        {
            [Occupancy.twin]: 0,
            [Occupancy.single]: 0,
            [Occupancy.double]: 0,
        },
    );
};

const updateAccommodationStatus = (
    currentAddOn: ManageBookingAddOn,
    originalAddOn: ManageBookingAddOn,
    actionType: ToggleActionType,
): MANAGE_BOOKING_ADDON_STATUS => {
    const { quantity: currentQuantity, status: currentStatus } = currentAddOn;
    const { quantity: originalQuantity } = originalAddOn;
    const quantityHasChanged = currentQuantity !== originalQuantity;

    const isAddActionType = actionType === ToggleActionType.ADD;

    const updatedStatusByQuantity =
        currentQuantity > 1
            ? MANAGE_BOOKING_ADDON_STATUS.PENDING_REMOVAL
            : MANAGE_BOOKING_ADDON_STATUS.PENDING_ADDITION;

    const { isPendingRemovalAddOn: isPendingRemovalCurrentAddOn } =
        getAddOnStatusAndType(currentAddOn);

    const { isConfirmedAddOn: isAccommodationOriginallyConfirmed } =
        getAddOnStatusAndType(originalAddOn);

    /**
     * If the accommodation was originally confirmed and the quantity has changed,
     * the status should be updated to pending removal.
     */
    if (
        isAccommodationOriginallyConfirmed &&
        isPendingRemovalCurrentAddOn &&
        quantityHasChanged &&
        isAddActionType
    ) {
        return updatedStatusByQuantity;
    }

    return updateAddOnStatus(actionType, currentStatus);
};

const toggleAccommodationStatus = ({
    state,
    travelersQuantity,
    params,
}: ToggleAccommodationStatusParams): ManageBookingState['addOns']['accommodations'] => {
    const { accommodations } = state.addOns;

    const {
        addOns: { accommodations: originalAccommodations },
    } = state.originalState;

    const {
        actionType,
        timing,
        roomSelection = [],
        isUpgradeToPrivateRoomSelected,
        manageMode = 'default',
    } = params;

    if (!isPreOrPostTripServiceTiming(timing) || !accommodations) return {};

    const isRemoveActionType = actionType === ToggleActionType.REMOVE;

    /**
     * If the upgrade to private room is selected, all the occupancies should be
     * updated to single.
     */
    const occupanciesOnUpgrade = {
        [Occupancy.single]: travelersQuantity,
    };

    const occupanciesFromRoomSelection =
        getOccupancyMapFromRoomSelection(roomSelection);

    const isSingleTravelerBooking = travelersQuantity === 1;

    const occupanciesToUpdate =
        isUpgradeToPrivateRoomSelected || isSingleTravelerBooking
            ? occupanciesOnUpgrade
            : occupanciesFromRoomSelection;

    const occupanciesToUpdateKeys = Object.keys(occupanciesToUpdate).filter(
        (occupancy) => occupanciesToUpdate[occupancy] !== 0,
    );

    const shouldToggleAllOccupancies =
        isRemoveActionType && roomSelection.length === 0;

    const occupanciesToToggle = shouldToggleAllOccupancies
        ? validOccupancies
        : occupanciesToUpdateKeys;

    const updatedAccommodations = (
        occupanciesToToggle as ValidOccupanciesType[]
    ).reduce(
        (occupanciesMap, occupancy) => {
            const currentAccommodationAddOn =
                accommodations[timing]?.[occupancy];
            const originalAccommodationAddOn =
                originalAccommodations[timing]?.[occupancy];
            const currentStatus = currentAccommodationAddOn?.status;

            if (!currentStatus) return occupanciesMap;

            return {
                ...occupanciesMap,
                [timing]: {
                    ...occupanciesMap[timing],
                    [occupancy]: {
                        ...occupanciesMap[timing]?.[occupancy],
                        status: updateAccommodationStatus(
                            currentAccommodationAddOn,
                            originalAccommodationAddOn,
                            actionType,
                        ),
                        quantity: isRemoveActionType
                            ? occupanciesMap[timing][occupancy].quantity
                            : occupanciesToUpdate[occupancy],
                    },
                },
            };
        },
        { ...accommodations },
    );

    const accommodationsWithUpdatedManageMode = validOccupancies.reduce(
        (accommodationsWithManageMode, occupancy) => {
            return {
                ...accommodationsWithManageMode,
                [timing]: {
                    ...accommodationsWithManageMode[timing],
                    [occupancy]: {
                        ...accommodationsWithManageMode[timing]?.[occupancy],
                        manageMode,
                    },
                },
            };
        },
        { ...updatedAccommodations },
    );

    return accommodationsWithUpdatedManageMode;
};

const toggleActivityStatus = ({
    activities,
    params,
}: ToggleActivitiesStatusParams): ManageBookingState['addOns']['activities'] => {
    const { addOnId, travelerId, actionType } = params;

    if (!activities) return {};

    const currentStatus = activities[travelerId][addOnId].status;

    return {
        ...activities,
        [travelerId]: {
            ...activities[travelerId],
            [addOnId]: {
                ...activities[travelerId][addOnId],
                status: updateAddOnStatus(actionType, currentStatus),
                user: travelerId,
            },
        },
    };
};

const updateTransferAddOnStatus = (
    actionType: ToggleActionType,
    manageMode: AddOnManageMode,
    currentAddOn: ManageBookingAddOn,
): MANAGE_BOOKING_ADDON_STATUS => {
    const isAddActionType = actionType === ToggleActionType.ADD;

    const currentStatus = currentAddOn.status;

    if (manageMode === 'sideEffect') {
        return isAddActionType
            ? currentStatus
            : updateAddOnStatus(actionType, currentStatus);
    }

    return updateAddOnStatus(actionType, currentStatus);
};

const toggleTransferStatus = ({
    state,
    params,
}: ToggleTransferStatusParams): ManageBookingState['addOns']['transfers'] => {
    const { timing, actionType, manageMode = 'default' } = params;

    const transfersToUpdate = state?.addOns?.transfers;

    if (!transfersToUpdate) return {};

    const travelersIds = Object.keys(transfersToUpdate);

    const updatedTransfers = travelersIds.reduce(
        (transfersMap, travelerId) => {
            const currentAddOn = transfersToUpdate[travelerId][timing];
            const currentStatus = currentAddOn.status;

            if (!currentStatus) return transfersMap;

            return {
                ...transfersMap,
                [travelerId]: {
                    ...transfersMap[travelerId],
                    [timing]: {
                        ...transfersMap[travelerId][timing],
                        status: updateTransferAddOnStatus(
                            actionType,
                            manageMode,
                            currentAddOn,
                        ),
                        manageMode,
                    },
                },
            };
        },
        { ...transfersToUpdate },
    );

    return updatedTransfers;
};

const updateSingleSupplementStatus = (
    singleSupplement: ManageBookingAddOn,
    originalSingleSupplement: ManageBookingAddOn,
    hasSingleOccupancy: boolean,
): MANAGE_BOOKING_ADDON_STATUS => {
    const { status: currentStatus } = singleSupplement;

    const { isPendingRemovalAddOn, isAvailableAddOn } =
        getAddOnStatusAndType(singleSupplement);

    const { isConfirmedAddOn: isOriginallyConfirmedAddOn } =
        getAddOnStatusAndType(originalSingleSupplement);

    if ((isPendingRemovalAddOn || isAvailableAddOn) && !hasSingleOccupancy) {
        return currentStatus;
    }

    if (hasSingleOccupancy && isOriginallyConfirmedAddOn) {
        return MANAGE_BOOKING_ADDON_STATUS.CONFIRMED;
    }

    return statusOnUpdateMap[currentStatus];
};

const updateSingleSupplementAddOn = ({
    state,
    isUpgradeToPrivateRoomSelected,
}: UpdateSingleSupplementAddOnParams): ManageBookingState => {
    const { singleSupplement } = state.addOns.accommodations || {};
    const { travelersQuantity, travelersRooms, serviceInventory } = state;

    const { singleSupplement: originalSingleSupplement } =
        state.originalState.addOns.accommodations || {};

    if (!singleSupplement || !originalSingleSupplement) return state;

    const hasSingleSupplementAddOn = !!singleSupplement;

    const originalStatus = originalSingleSupplement?.status;

    const hasSingleOccupancy = getRoomsOccupancies(travelersRooms).some(
        (occupancy) => occupancy === Occupancy.single,
    );

    const upgradeWasOriginallySelected = checkIsUpgradeToPrivateRoomSelected(
        originalSingleSupplement,
        travelersQuantity,
    );

    let updatedSingleSupplement = {} as Accommodations['singleSupplement'];

    if (!isUpgradeToPrivateRoomSelected && hasSingleSupplementAddOn) {
        updatedSingleSupplement = {
            ...singleSupplement,
            quantity: hasSingleOccupancy ? 1 : 0,
            status: updateSingleSupplementStatus(
                singleSupplement,
                originalSingleSupplement,
                hasSingleOccupancy,
            ),
        };
    } else {
        updatedSingleSupplement = {
            ...singleSupplement,
            status: upgradeWasOriginallySelected
                ? originalStatus
                : MANAGE_BOOKING_ADDON_STATUS.PENDING_ADDITION,
            quantity: travelersQuantity,
        };
    }
    const hasConfirmationError = state.confirmation.error;

    const isUpgradeToPrivateRoomAvailable = isPrivateRoomUpgradeAvailable(
        serviceInventory.singleSupplement.available,
        travelersQuantity,
    );

    const shouldClearConfirmationError =
        hasConfirmationError &&
        !isUpgradeToPrivateRoomAvailable &&
        !isUpgradeToPrivateRoomSelected;

    return {
        ...state,
        addOns: {
            ...state.addOns,
            accommodations: {
                ...state.addOns.accommodations,
                singleSupplement: updatedSingleSupplement,
            },
        },
        confirmation: {
            ...state.confirmation,
            error: shouldClearConfirmationError
                ? null
                : state.confirmation.error,
        },
        /**
         * When the single supplement is updated, the services availability status
         * should be updated to needs verification.
         */
        serviceInventory: {
            ...state.serviceInventory,
            status: 'needs_verification',
        },
    };
};

const updateOccupancyStatus = (
    selectedOccupancy: boolean,
    status: MANAGE_BOOKING_ADDON_STATUS,
): MANAGE_BOOKING_ADDON_STATUS => {
    const updatedOccupancy = selectedOccupancy
        ? statusOnAddMap[status]
        : statusOnRemoveMap[status];

    return updatedOccupancy || status;
};

const updateOccupanciesStatusByRoom = (
    occupancies: RoomOccupancies,
    selectedOccupancy: ValidOccupanciesType,
    isSingleTravelerBooking: boolean,
    wasSingleSupplementOriginallyConfirmed: boolean,
): RoomOccupancies => {
    const occupanciesByRoomMap = (
        Object.keys(occupancies) as ValidOccupanciesType[]
    ).reduce(
        (occupanciesMap, occupancy) => {
            const currentStatus = occupancies[occupancy];
            const isOccupancySelected = selectedOccupancy === occupancy;
            const isSingleOccupancy = occupancy === Occupancy.single;

            const shouldToggleSingleOccupancy =
                wasSingleSupplementOriginallyConfirmed &&
                isSingleTravelerBooking &&
                isSingleOccupancy &&
                isOccupancySelected;

            if (!currentStatus) return occupanciesMap;

            const toggleSingleOccupancy = (
                isOccupancySelected: boolean,
            ): MANAGE_BOOKING_ADDON_STATUS => {
                if (isOccupancySelected) {
                    return MANAGE_BOOKING_ADDON_STATUS.CONFIRMED;
                }
                return MANAGE_BOOKING_ADDON_STATUS.AVAILABLE;
            };

            /**
             * If the single supplement was originally confirmed and the booking
             * is for a single traveler, when the single occupancy is selected,
             * the status should toggle between confirmed and available.
             */
            if (shouldToggleSingleOccupancy) {
                return {
                    ...occupanciesMap,
                    [occupancy]: toggleSingleOccupancy(isOccupancySelected),
                };
            }

            return {
                ...occupanciesMap,
                [occupancy]: updateOccupancyStatus(
                    isOccupancySelected,
                    currentStatus,
                ),
            };
        },
        { ...occupancies },
    );

    return occupanciesByRoomMap;
};

const updateTravelersRoomsStatus = ({
    state,
    roomSelection,
    isUpgradeToPrivateRoomSelected,
}: UpdateTravelersRoomsStatusParams): ManageBookingState => {
    const roomsIds = Object.keys(roomSelection);
    const isSingleTravelerBooking = state.travelersQuantity === 1;
    const wasSingleSupplementOriginallyConfirmed =
        state.originalState.addOns.accommodations.singleSupplement?.status ===
        MANAGE_BOOKING_ADDON_STATUS.CONFIRMED;

    const updatedTravelersRoomsMap = roomsIds.reduce(
        (roomsMap, roomId) => {
            const selectedOccupancy = roomSelection[roomId];
            const occupanciesByRoom = state.travelersRooms?.[roomId];

            /**
             * If upgrade to private room is selected, the travelers rooms should
             * keep the last configuration.
             */
            if (!occupanciesByRoom || isUpgradeToPrivateRoomSelected) {
                return roomsMap;
            }

            return {
                ...roomsMap,
                [roomId]: updateOccupanciesStatusByRoom(
                    occupanciesByRoom,
                    selectedOccupancy,
                    isSingleTravelerBooking,
                    wasSingleSupplementOriginallyConfirmed,
                ),
            };
        },
        { ...state.travelersRooms },
    );

    return {
        ...state,
        travelersRooms: updatedTravelersRoomsMap,
    };
};

const updateRoomOccupanciesStatus = ({
    state,
    payload,
}: UpdateRoomOccupanciesStatusParams): ManageBookingState => {
    const { roomSelection, isUpgradeToPrivateRoomSelected } = payload;

    const updatedTravelersRoomsState = updateTravelersRoomsStatus({
        state,
        roomSelection,
        isUpgradeToPrivateRoomSelected,
    });

    const updatedState = updateSingleSupplementAddOn({
        state: updatedTravelersRoomsState,
        isUpgradeToPrivateRoomSelected,
    });

    return updatedState;
};

type AddOnsKey = keyof ManageBookingState['addOns'];
type StateWithoutKey<Key extends AddOnsKey> = Omit<ManageBookingState, Key>;

/**
 * Removes the provided key from the add-ons state.
 *
 * @param addOns
 * @param key
 * @returns State without the provided key.
 */
const removeKeyFromAddOnsState = <Key extends AddOnsKey>(
    addOns: ManageBookingState['addOns'],
    key: Key,
): StateWithoutKey<Key> => {
    return omit(addOns, key) as StateWithoutKey<Key>;
};

/**
 * Extract and filter pending add-ons from a list of add-ons.
 *
 * @param addOns
 * @returns A list of pending add-ons.
 */
const extractAddOnsFromState = (
    addOns: ManageBookingState['addOns'],
): ManageBookingAddOn[] => {
    const addOnsWithoutInsurance = removeKeyFromAddOnsState(
        addOns,
        'insurance',
    );

    const extractedAddOns = flatMap(
        addOnsWithoutInsurance,
        (group: ManageBookingState['addOns']) =>
            flatMap(group, (subgroup, subgroupKey) => {
                if (subgroupKey === 'singleSupplement' && subgroup) {
                    return [subgroup];
                }

                if (subgroup) {
                    return values(subgroup);
                }

                return [];
            }) as ManageBookingAddOn[],
    );

    return extractedAddOns;
};

/**
 * Extract all rooms with a pending addition or pending removal occupancy.
 *
 * @param travelersRooms
 * @returns A list of pending travelers rooms.
 */
const extractPendingTravelersRooms = (
    travelersRooms: ManageBookingState['travelersRooms'],
): Array<Partial<RoomOccupancies>> => {
    return Object.values(travelersRooms).reduce((result, room) => {
        const hasPendingStatus = Object.values(room).some((status) =>
            isPendingStatusAddOn(status),
        );

        if (hasPendingStatus) {
            const filteredRoom = Object.fromEntries(
                Object.entries(room).filter(([, status]) =>
                    isPendingStatusAddOn(status),
                ),
            );
            return result.concat([filteredRoom]);
        }

        return result;
    }, [] as Array<Partial<RoomOccupancies>>);
};

/**
 * Update the room selection section of the trip summary.
 *
 * @param pendingTravelersRooms
 * @param singleSupplementAddOn
 * @param originalSingleSupplementAddOn
 * @param travelersQuantity
 * @returns A price adjustment item.
 */
const updateRoomSelectionSection = (
    pendingTravelersRooms: Array<Partial<RoomOccupancies>>,
    singleSupplementAddOn: Accommodations['singleSupplement'],
    originalSingleSupplementAddOn: Accommodations['singleSupplement'],
    travelersQuantity: number,
): SummaryItem | null => {
    const isUpgradeSelected = isUpgradeToPrivateRoomSelected(
        singleSupplementAddOn,
        travelersQuantity,
    );
    const hasPendingTravelerRooms =
        !!pendingTravelersRooms.length && !isUpgradeSelected;
    /**
     * The total single supplement is the difference between the current single supplement
     * price and the original single supplement price.
     */
    const totalSingleSupplement =
        (singleSupplementAddOn?.unitPriceWithFee || 0) *
            (singleSupplementAddOn?.quantity || 0) -
        (originalSingleSupplementAddOn?.unitPriceWithFee || 0) *
            (originalSingleSupplementAddOn?.quantity || 0);
    const roomSelectionItem = { description: 'Accommodation Update', price: 0 };
    if (totalSingleSupplement !== 0) {
        return {
            ...roomSelectionItem,
            price: totalSingleSupplement,
        };
    }
    if (hasPendingTravelerRooms && totalSingleSupplement === 0) {
        return roomSelectionItem;
    }
    return null;
};

const updateTripSummary = (
    state: ManageBookingState,
): ManageBookingState['tripSummary'] => {
    const extractedAddOns = extractAddOnsFromState(state.addOns);

    const pendingAddOns = filterAddOnsByStatus(extractedAddOns, [
        MANAGE_BOOKING_ADDON_STATUS.PENDING_ADDITION,
        MANAGE_BOOKING_ADDON_STATUS.PENDING_REMOVAL,
    ]);

    const pendingTravelersRooms = extractPendingTravelersRooms(
        state.travelersRooms,
    );

    const updatedTripSummarySections = pendingAddOns.reduce(
        (updatedSections, addOn) => {
            const {
                isAccommodationAddOn,
                isTransferAddOn,
                isActivityAddOn,
                isPendingAdditionAddOn,
                isPendingRemovalAddOn,
            } = getAddOnStatusAndType(addOn);

            /**
             * If the add-on is an accommodation, transfer or activity, it
             * should be added to the corresponding section.
             */
            if (isAccommodationAddOn || isActivityAddOn || isTransferAddOn) {
                return {
                    ...updatedSections,
                    added: isPendingAdditionAddOn
                        ? [...(updatedSections.added || []), addOn]
                        : updatedSections.added,
                    removed: isPendingRemovalAddOn
                        ? [...(updatedSections.removed || []), addOn]
                        : updatedSections.removed,
                };
            }
            return updatedSections;
        },
        { added: [], removed: [] },
    );

    /**
     * Update the room selection section of the trip summary.
     */
    const roomSelectionUpdated = updateRoomSelectionSection(
        pendingTravelersRooms,
        state.addOns.accommodations.singleSupplement,
        state.originalState.addOns.accommodations.singleSupplement,
        state.travelersQuantity,
    );

    /**
     * Update the price adjustments section of the trip summary.
     */
    const updatedPriceAdjustment = updatePriceAdjustments(state, pendingAddOns);

    /**
     * Calculates the subtotal and total of the trip summary.
     */
    const priceAdjustmentsSubTotal = calculatePriceAdjustmentsSubTotal(
        updatedPriceAdjustment,
    );

    const addedAddOnsSubTotal = calculateAddOnsSubTotal(
        updatedTripSummarySections.added,
    );

    const removedAddOnsSubTotal = toNegative(
        calculateAddOnsSubTotal(updatedTripSummarySections.removed),
    );

    const roomSelectionSubTotal = roomSelectionUpdated?.price || 0;

    const insuranceAdjustmentPrice =
        state.tripSummary.insuranceAdjustment?.price || 0;

    const total =
        addedAddOnsSubTotal +
        removedAddOnsSubTotal +
        priceAdjustmentsSubTotal +
        roomSelectionSubTotal;

    const totalWithInsurance = total + insuranceAdjustmentPrice;

    if (isEmpty(pendingAddOns) && !roomSelectionUpdated) {
        return {
            ...state.tripSummary,
            isOpen: false,
            sections: {
                ...state.tripSummary.sections,
                added: [],
                removed: [],
                roomSelection: null,
                priceAdjustments: [],
            },
        };
    }

    return {
        ...state.tripSummary,
        isOpen: true,
        sections: {
            ...state.tripSummary.sections,
            ...updatedTripSummarySections,
            roomSelection: roomSelectionUpdated,
            priceAdjustments: updatedPriceAdjustment,
        },
        total,
        totalWithInsurance,
    };
};

/**
 * Creates the payload to reset the room occupancy selection.
 *
 * @param originalState
 * @returns Payload to reset the room occupancy selection.
 */
const getUpdateRoomOccupancyPayloadOnReset = (
    originalState: ManageBookingViewModel,
): UPDATE_ROOM_OCCUPANCIES['payload'] => {
    const {
        addOns: { accommodations },
        travelersQuantity,
        travelersRooms,
    } = originalState;

    const { singleSupplement } = accommodations;

    const isUpgradeSelected = isUpgradeToPrivateRoomSelected(
        singleSupplement,
        travelersQuantity,
    );

    const isSingleTravelerBooking = travelersQuantity === 1;

    const isTravelersQuantityOdd = travelersQuantity % 2 !== 0;

    const selectedTravelersRooms = getSelectedTravelersRooms(
        travelersRooms,
        singleSupplement,
        travelersQuantity,
    );
    const originalRoomSelection = selectedTravelersRooms.map(
        (travelerRoom) => travelerRoom.roomType,
    );

    const timingsToUpdate = getPreAndPostAccommodationTimingSelected(
        originalState.addOns.accommodations,
    );

    const transfersTimingsToUpdate = getPreAnPostTransfersTimingSelected(
        originalState.addOns.transfers,
    );

    const accommodationsWereOriginallyAvailable =
        arePreAndPostAccommodationsAvailable(accommodations);

    return {
        roomSelection: originalRoomSelection,
        preAndPostRoomSelection: originalRoomSelection,
        currentPreAndPostRoomSelection: originalRoomSelection,
        timingsToUpdate,
        transfersTimingsToUpdate,
        isTravelersQuantityOdd,
        isUpgradeToPrivateRoomSelected:
            isUpgradeSelected && !isSingleTravelerBooking,
        accommodationsWereOriginallyAvailable,
    };
};

/**
 * Get the first pre and post transfers add-on price.
 *
 * @param addOns
 * @returns The first pre and post transfers add-on price.
 */
const getFirstPreAndPostTransfersAddOnPrice = (
    addOns: ManageBookingAddOn[],
): PreAndPostTransfersAddOnPrice => {
    const preTripTransferAddOn = addOns.find(
        (addOn) =>
            (addOn.service as Service)?.timing === ServiceTiming.PRE_TRIP,
    );
    const postTripTransferAddOn = addOns.find(
        (addOn) =>
            (addOn.service as Service)?.timing === ServiceTiming.POST_TRIP,
    );

    return {
        preTripPrice: preTripTransferAddOn?.unitPriceWithFee || 0,
        postTripPrice: postTripTransferAddOn?.unitPriceWithFee || 0,
    };
};

const isAddOnWithTimingSideEffect = (
    addOns: ManageBookingAddOn[],
    timing: ServiceTimingType,
): boolean => {
    const isSideEffect = addOns
        .filter((addOn) => (addOn.service as Service).timing === timing)
        .some((addOn) => addOn.manageMode !== 'default');

    return isSideEffect;
};

/**
 * Updates the price adjustments section of the trip summary.
 *
 * @param state
 * @param pendingAddOns
 * @returns The price adjustments section of the trip summary.
 */
const updatePriceAdjustments = (
    state: ManageBookingState,
    pendingAddOns: ManageBookingAddOn[],
): SummaryItem[] => {
    const {
        originalState,
        addOns,
        travelersQuantity,
        travelersRooms,
        defaultRoomOccupancy,
    } = state;
    const currentState = {
        addOns,
        travelersQuantity,
        travelersRooms,
        defaultRoomOccupancy,
    };

    const transferPendingAddons = pendingAddOns.filter((addOn) => {
        const { isTransferAddOn } = getAddOnStatusAndType(addOn);
        return isTransferAddOn;
    });

    const accommodationsPendingAddOns = pendingAddOns.filter((addOn) => {
        const { isAccommodationAddOn } = getAddOnStatusAndType(addOn);
        return isAccommodationAddOn;
    });

    const originalPricing = getPricingFromState(originalState);

    const currentPricing = getPricingFromState(currentState);

    const accommodationsWereOriginallyAvailable =
        arePreAndPostAccommodationsAvailable(
            originalState.addOns.accommodations,
        );

    const {
        preTripAccommodationsPrice: preTripAccommodationsAdjustment,
        postTripAccommodationsPrice: postTripAccommodationsAdjustment,
    } = getPreAndPostAddOnsPriceAdjustments(originalPricing, currentPricing);

    const { preTripPrice, postTripPrice } =
        getFirstPreAndPostTransfersAddOnPrice(transferPendingAddons);

    const isPreTripTransferAddOnSideEffect = isAddOnWithTimingSideEffect(
        transferPendingAddons,
        ServiceTiming.PRE_TRIP,
    );

    const isPostTripTransferAddOnSideEffect = isAddOnWithTimingSideEffect(
        transferPendingAddons,
        ServiceTiming.POST_TRIP,
    );

    const isPreAccommodationAddOnSideEffect = isAddOnWithTimingSideEffect(
        accommodationsPendingAddOns,
        ServiceTiming.PRE_TRIP,
    );

    const isPostAccommodationAddOnSideEffect = isAddOnWithTimingSideEffect(
        accommodationsPendingAddOns,
        ServiceTiming.POST_TRIP,
    );

    const preTripTransferAddOnAdjustment = isPreTripTransferAddOnSideEffect
        ? preTripPrice * travelersQuantity
        : 0;

    const postTripTransferAddOnAdjustment = isPostTripTransferAddOnSideEffect
        ? postTripPrice * travelersQuantity
        : 0;

    const preTripAccommodationAddOnAdjustment =
        isPreAccommodationAddOnSideEffect &&
        !accommodationsWereOriginallyAvailable.PRE_TRIP
            ? preTripAccommodationsAdjustment
            : 0;

    const postTripAccommodationAddOnAdjustment =
        isPostAccommodationAddOnSideEffect &&
        !accommodationsWereOriginallyAvailable.POST_TRIP
            ? postTripAccommodationsAdjustment
            : 0;

    const priceAdjustmentsItems = [
        {
            price: preTripAccommodationAddOnAdjustment,
            description: 'Pre-Trip Accommodation price has changed',
        },
        {
            price: postTripAccommodationAddOnAdjustment,
            description: 'Post-Trip Accommodation price has changed',
        },
        {
            price: toNegative(postTripTransferAddOnAdjustment),
            description: 'Post-Trip Transfer has been removed',
        },
        {
            price: toNegative(preTripTransferAddOnAdjustment),
            description: 'Pre-Trip Transfer has been removed',
        },
    ].filter((priceAdjustment) => priceAdjustment.price !== 0);

    return priceAdjustmentsItems as SummaryItem[];
};

const updateConfirmationMode = (
    tripSummary: ManageBookingState['tripSummary'],
): ConfirmationMode | null => {
    const { total } = tripSummary;

    const refundNeeded =
        total + (tripSummary.bookingPaymentData.dueAmount || 0) < 0;

    if (total === 0) {
        return 'zeroBalance';
    }

    if (total < 0) {
        if (refundNeeded) return 'refundNeeded';

        return 'negativeBalance';
    }

    return null;
};

const repriceInsuranceAddOns = (
    addOns: BookingAddOn[],
    repricedAddOns: BookingRepriceQuote['repricedAddOns'],
): BookingAddOn[] => {
    const newAddOns = addOns?.map((addOn) => {
        const possiblyRepricedAddOn = repricedAddOns?.find(
            (repricedAddOn) => repricedAddOn.addOnId === addOn.addOnId,
        );
        return possiblyRepricedAddOn || addOn;
    });
    return newAddOns;
};

const INSURANCE_API_ERROR: ManageBookingError = {
    title: 'We encountered an issue while connecting to our insurance provider.',
    description:
        'Please try again later or contact our support team for assistance.',
    type: 'insuranceReprice',
};

const UPDATE_BOOKING_ERROR: ManageBookingError = {
    title: 'We encountered an issue while updating your booking.',
    description:
        'Please try again later or contact our support team for assistance.',
    type: 'bookingUpdate',
};

const SINGLE_SUPPLEMENT_INVENTORY_ERROR: ManageBookingError = {
    title: 'Sorry, looks like we have run out of private rooms at this time.',
    description: 'Please remove the private room selection to continue.',
    type: 'singleSupplementInventory',
};

const isInsuranceCancelledStatus = (
    status: InsuranceSectionStatus,
): status is 'cancelled' => status === 'cancelled';

const isInsuranceCancellingStatus = (
    status: InsuranceSectionStatus,
): status is 'cancelling' => status === 'cancelling';

const isInsuranceErrorStatus = (
    status: InsuranceSectionStatus,
): status is 'error' => status === 'error';

const isServicesInventoryVerifyingStatus = (
    status: ServiceInventoryStatus,
): status is 'verifying' => status === 'verifying';

const isServiceInventoryNeedsVerificationStatus = (
    status: ServiceInventoryStatus,
): status is 'needs_verification' => status === 'needs_verification';

const isSingleSupplementInventoryError = (error: ManageBookingError): boolean =>
    error?.type === 'singleSupplementInventory';

export {
    INSURANCE_API_ERROR,
    SINGLE_SUPPLEMENT_INVENTORY_ERROR,
    UPDATE_BOOKING_ERROR,
    extractAddOnsFromState,
    getOccupancyMapFromRoomSelection,
    getUpdateRoomOccupancyPayloadOnReset,
    isInsuranceCancelledStatus,
    isInsuranceCancellingStatus,
    isInsuranceErrorStatus,
    isServiceInventoryNeedsVerificationStatus,
    isServicesInventoryVerifyingStatus,
    isSingleSupplementInventoryError,
    repriceInsuranceAddOns,
    toggleAccommodationStatus,
    toggleActivityStatus,
    toggleTransferStatus,
    updateAccommodationStatus,
    updateAddOnStatus,
    updateConfirmationMode,
    updateOccupancyStatus,
    updateRoomOccupanciesStatus,
    updateSingleSupplementAddOn,
    updateTransferAddOnStatus,
    updateTravelersRoomsStatus,
    updateTripSummary,
    validOccupancies,
    validServiceTiming,
};
