import { Heading, Text, Stack } from '@trova-trip/trova-components';

interface InfoItemProps {
    value: string | number;
    label: string;
}

const InfoItem = ({ value, label }: InfoItemProps) => {
    return (
        <Stack align='center' justify='center' direction='column' height='28'>
            <Heading as='h3' fontWeight='bold' fontSize='3xl' lineHeight='11'>
                {value}
            </Heading>
            <Text lineHeight='7' size='lg'>
                {label}
            </Text>
        </Stack>
    );
};

export default InfoItem;
