import { SummaryGroup, SummaryItemProps } from '@trova-trip/trova-components';
import map from 'lodash/map';
import sum from 'lodash/sum';
import {
    ManageBookingState,
    ResetRoomSelectionPayload,
    SummaryItem,
    ToggleAccommodationPayload,
    ToggleActivityPayload,
    ToggleTransferPayload,
} from '../../../../../state/features/manageBooking/types';
import {
    ManageBookingAddOn,
    ManageBookingViewModel,
    Service,
    ServiceTiming,
    ServiceTimingType,
    ServiceType,
} from '../types';
import { isUpgradeToPrivateRoomSelected } from './check.utils';
import {
    getAddOnsWithNoSideEffects,
    getPrePostPendingAddOns,
    getPrePostRoomsOccupancies,
    getToggleAddOnActionType,
} from './extract.utils';

export enum SummarySections {
    ADDED = 'ADDED',
    REMOVED = 'REMOVED',
}

const accommodationsTripSummaryItemsDescriptionMap = {
    [ServiceTiming.PRE_TRIP]: 'Pre-Trip Accommodation',
    [ServiceTiming.POST_TRIP]: 'Post-Trip Accommodation',
};

const transfersTripSummaryItemsDescriptionMap = {
    [ServiceTiming.PRE_TRIP]: 'Arrival Ride',
    [ServiceTiming.POST_TRIP]: 'Departure Ride',
};

type AddedAndRemovedActions = {
    updateTransfer: (payload: ToggleTransferPayload) => void;
    updateAccommodation: (payload: ToggleAccommodationPayload) => void;
    updateActivity: (payload: ToggleActivityPayload) => void;
};

interface GroupedActivity extends ManageBookingAddOn {
    quantity: number;
    addOnInfo: Array<{ addOnId: string; travelerId: string }>;
}

/**
 * Calculates the total number of pending changes.
 * @param tripSummarySection
 * @returns The total of pending changes or undefined if there are none.
 */
const getTripSummaryPendingChanges = (
    tripSummarySections: SummaryGroup[],
): number | undefined => {
    const sectionCounts = map(tripSummarySections, (section) => {
        return section?.items.length;
    });

    const totalPendingChanges = sum(sectionCounts);
    return totalPendingChanges === 0 ? undefined : totalPendingChanges;
};

/**
 * Switches the sign of the number depending on the `isRemoved` flag.
 * @param number
 * @returns Number with negative sign if `isRemoved` is `true`, number not modified otherwise.
 */
const switchSignIf = (isRemoved: boolean, number: number): number => {
    return isRemoved ? -number : number;
};

/**
 * Get summary items for pending activities add-ons.
 * @param sectionItems
 * @param isRemoved - `True` if the add-on was removed.
 * @param updateActivity
 * @returns  Summary items for activities.
 */
const getActivitiesTripSummaryItems = (
    sectionItems: ManageBookingAddOn[],
    isRemoved: boolean,
    updateActivity: AddedAndRemovedActions['updateActivity'],
): SummaryGroup['items'] => {
    const activityItems = sectionItems.filter((item) => {
        const itemService = item?.service as Service;
        return itemService?.type === ServiceType.ACTIVITY;
    });

    if (!activityItems.length) {
        return [];
    }

    const groupedActivities = activityItems.reduce(
        (groups: GroupedActivity[], item) => {
            const itemService = item?.service as Service;

            const activityGrouped = groups.find((group) => {
                const groupService = group.service as Service;
                return groupService.name === itemService?.name;
            });

            const priceOrDiscount = switchSignIf(
                isRemoved,
                item.unitPriceWithFee,
            );

            if (activityGrouped) {
                activityGrouped.unitPriceWithFee += priceOrDiscount;
                activityGrouped.quantity += 1;
                activityGrouped.addOnInfo.push({
                    addOnId: item.addOnId,
                    travelerId: item.user as string,
                });
            } else {
                groups.push({
                    ...item,
                    quantity: 1,
                    unitPriceWithFee: priceOrDiscount,
                    addOnInfo: [
                        {
                            addOnId: item.addOnId,
                            travelerId: item.user as string,
                        },
                    ],
                });
            }
            return groups;
        },
        [],
    );

    return groupedActivities.map((groupedItem: GroupedActivity) => {
        const groupedItemService = groupedItem?.service as Service;
        const { addOnInfo, quantity, unitPriceWithFee } = groupedItem;
        const description =
            quantity > 1
                ? `${groupedItemService.name} (${quantity})`
                : groupedItemService.name ?? '';

        return {
            as: 'item',
            description,
            price: unitPriceWithFee,
            actionProps: {
                icon: 'trash',
                'aria-label': 'Undo activity change',
                onClick: () => {
                    addOnInfo.forEach(({ addOnId, travelerId }) => {
                        updateActivity({
                            actionType: getToggleAddOnActionType(!isRemoved),
                            addOnId,
                            travelerId,
                        });
                    });
                },
            },
        };
    }) as SummaryGroup['items'];
};

/**
 * Groups and calculates total prices of pre-trip and post-trip add-ons.
 * @param addOnsItems - List of pre/post add-on items to group.
 * @param isRemoved - `True` if the add-on was removed.
 * @returns Grouped pre-trip and post-trip add-ons.
 */
const getGroupedPrePostAddOns = (
    addOnsItems: ManageBookingAddOn[],
    isRemoved: boolean,
): ManageBookingAddOn[] => {
    return addOnsItems.reduce((groups: ManageBookingAddOn[], item) => {
        const itemService = item?.service as Service;

        const preOrPostAddOn = groups.find((group) => {
            const groupService = group.service as Service;
            return groupService.timing === itemService?.timing;
        });

        const priceWithBalance = switchSignIf(isRemoved, item.unitPriceWithFee);

        if (preOrPostAddOn) {
            preOrPostAddOn.unitPriceWithFee += priceWithBalance;
        } else {
            groups.push({
                ...item,
                unitPriceWithFee: priceWithBalance,
            });
        }
        return groups;
    }, []);
};

/**
 * Gets added/removed summary sections and returns formatted accommodations based on the timing to summary items.
 *
 * @param sectionItems
 * @param isRemoved - `True` if the add-on was removed.
 * @param updateAccommodation
 * @param state - Manage booking state.
 * @returns Summary items for accommodations based on timing.
 */
const getAccommodationsSummaryItems = (
    sectionItems: ManageBookingAddOn[],
    isRemoved: boolean,
    updateAccommodation: AddedAndRemovedActions['updateAccommodation'],
    state: ManageBookingState,
): SummaryGroup['items'] => {
    const { preAccommodationPendingAddOns, postAccommodationPendingAddOns } =
        getPrePostPendingAddOns(sectionItems);

    if (
        !preAccommodationPendingAddOns.length &&
        !postAccommodationPendingAddOns.length
    ) {
        return [];
    }

    const groupedPreAccommodationsItems = getGroupedPrePostAddOns(
        preAccommodationPendingAddOns,
        isRemoved,
    );
    const groupedPostAccommodationsItems = getGroupedPrePostAddOns(
        postAccommodationPendingAddOns,
        isRemoved,
    );

    const {
        travelersRooms,
        travelersQuantity,
        addOns: { accommodations },
    } = state;

    const roomsSelection = getPrePostRoomsOccupancies({
        rooms: travelersRooms,
        travelersQuantity,
        singleSupplement: accommodations?.singleSupplement,
        defaultRoomOccupancy: state.defaultRoomOccupancy,
    });

    const selectedUpgradeToPrivateRoom = isUpgradeToPrivateRoomSelected(
        accommodations?.singleSupplement,
        travelersQuantity,
    );

    const formatAccommodationsAddOnsToSummaryItems = (
        groupedAccommodations: ManageBookingAddOn[],
        timing: ServiceTimingType,
    ): SummaryGroup['items'] => {
        return groupedAccommodations.map((groupedItem: ManageBookingAddOn) => {
            return {
                as: 'item',
                description:
                    accommodationsTripSummaryItemsDescriptionMap[timing],
                price: groupedItem.unitPriceWithFee * groupedItem.quantity,
                actionProps: {
                    icon: 'trash',
                    'aria-label': 'Undo accommodation change',
                    onClick: () =>
                        updateAccommodation({
                            actionType: getToggleAddOnActionType(!isRemoved),
                            transfersTimingsToUpdate: [timing],
                            timing,
                            roomSelection: roomsSelection,
                            isUpgradeToPrivateRoomSelected:
                                selectedUpgradeToPrivateRoom,
                        }),
                },
            };
        }) as SummaryGroup['items'];
    };

    const preAccommodationsTripSummaryItems =
        formatAccommodationsAddOnsToSummaryItems(
            groupedPreAccommodationsItems,
            ServiceTiming.PRE_TRIP,
        );
    const postAccommodationsTripSummaryItems =
        formatAccommodationsAddOnsToSummaryItems(
            groupedPostAccommodationsItems,
            ServiceTiming.POST_TRIP,
        );

    return [
        ...preAccommodationsTripSummaryItems,
        ...postAccommodationsTripSummaryItems,
    ];
};

/**
 * Gets added/removed summary sections and returns formatted transfers based on the timing to summary items.
 *
 * @param sectionItems
 * @param isRemoved - `True` if the add-on was removed.
 * @param updateTransfer
 * @returns Summary items for transfers based on timing.
 */
const getTransfersSummaryItems = (
    sectionItems: ManageBookingAddOn[],
    isRemoved: boolean,
    updateTransfer: AddedAndRemovedActions['updateTransfer'],
): SummaryGroup['items'] => {
    const { preTransferPendingAddOns, postTransferPendingAddOns } =
        getPrePostPendingAddOns(sectionItems);

    if (!preTransferPendingAddOns.length && !postTransferPendingAddOns.length) {
        return [];
    }

    const groupedPreTransferItems = getGroupedPrePostAddOns(
        preTransferPendingAddOns,
        isRemoved,
    );
    const groupedPostTransferItems = getGroupedPrePostAddOns(
        postTransferPendingAddOns,
        isRemoved,
    );

    const formatTransfersAddOnsToSummaryItems = (
        groupedTransfers: ManageBookingAddOn[],
        timing: ServiceTimingType,
    ): SummaryGroup['items'] => {
        return groupedTransfers.map((groupedItem: ManageBookingAddOn) => {
            return {
                as: 'item',
                description: transfersTripSummaryItemsDescriptionMap[timing],
                price: groupedItem.unitPriceWithFee,
                actionProps: {
                    icon: 'trash',
                    'aria-label': 'Undo transfer change',
                    onClick: () =>
                        updateTransfer({
                            actionType: getToggleAddOnActionType(!isRemoved),
                            timing,
                        }),
                },
            };
        }) as SummaryGroup['items'];
    };

    const preTransfersTripSummaryItems = formatTransfersAddOnsToSummaryItems(
        groupedPreTransferItems,
        ServiceTiming.PRE_TRIP,
    );
    const postTransfersTripSummaryItems = formatTransfersAddOnsToSummaryItems(
        groupedPostTransferItems,
        ServiceTiming.POST_TRIP,
    );

    return [...preTransfersTripSummaryItems, ...postTransfersTripSummaryItems];
};

/**
 * Convert a trip summary section (added/removed) into a summary group with formatted items
 *
 * @param section - List of ManageBookingAddOn items to convert (added/removed)
 * @param label - Of the section.
 * @param actions - To update the add-ons.
 * @param state - Manage booking state.
 * @returns An array of summary groups.
 */
const convertSectionsIntoTripSummaryItems = (
    section: ManageBookingAddOn[],
    label: SummarySections,
    actions: AddedAndRemovedActions,
    state: ManageBookingState,
): SummaryGroup[] => {
    if (!section) return [];

    const sectionWithoutSideEffects = getAddOnsWithNoSideEffects(section);

    const isRemoved = label === SummarySections.REMOVED;

    const transfersItems = getTransfersSummaryItems(
        sectionWithoutSideEffects,
        isRemoved,
        actions.updateTransfer,
    );
    const accommodationsItems = getAccommodationsSummaryItems(
        sectionWithoutSideEffects,
        isRemoved,
        actions.updateAccommodation,
        state,
    );
    const activitiesItems = getActivitiesTripSummaryItems(
        sectionWithoutSideEffects,
        isRemoved,
        actions.updateActivity,
    );

    const items = [
        ...transfersItems,
        ...accommodationsItems,
        ...activitiesItems,
    ];

    return [
        {
            label,
            items,
        },
    ] as SummaryGroup[];
};

/**
 * Generate summary groups for added and removed trip summary sections and filter out empty groups.
 *
 * @param added - The list of added add-ons.
 * @param removed - The list of removed add-ons.
 * @param actions - To update the add-ons.
 * @param state - Manage booking state.
 * @returns An array of summary groups representing added and removed items.
 */
const getAddedAndRemovedTripSummarySections = (
    added: ManageBookingAddOn[],
    removed: ManageBookingAddOn[],
    actions: AddedAndRemovedActions,
    state: ManageBookingState,
): SummaryGroup[] => {
    const groups = [
        ...convertSectionsIntoTripSummaryItems(
            added,
            SummarySections.ADDED,
            actions,
            state,
        ),
        ...convertSectionsIntoTripSummaryItems(
            removed,
            SummarySections.REMOVED,
            actions,
            state,
        ),
    ]?.filter((group) => group.items.length > 0);

    return groups;
};

/**
 * Generate a summary group for the room selection section.
 *
 * @param roomSelection - The room selection updates to be included in the SummaryGroup.
 * @param resetRoomSelection
 * @param originalState - The original state of the booking.
 * @returns The room selection as a SummaryGroup, or an empty array if the room selection is null.
 */
const getRoomSelectionSection = (
    roomSelection: SummaryItemProps | null,
    resetRoomSelection: (payload: ResetRoomSelectionPayload) => void,
    originalState: ManageBookingViewModel | undefined,
): SummaryGroup[] => {
    if (!roomSelection || !originalState) return [];

    return [
        {
            label: 'Room Selection',
            items: [
                {
                    as: 'item',
                    ...roomSelection,
                    actionProps: {
                        icon: 'trash',
                        'aria-label': 'Undo room selection change',
                        onClick: () => resetRoomSelection({ originalState }),
                    },
                },
            ],
        },
    ];
};

/**
 * Generate a summary group for the price adjustments section.
 *
 * @param priceAdjustments - The price adjustments to be included in the SummaryGroup.
 * @returns The price adjustments as a SummaryGroup, or an empty array if the room selection is null.
 */
const getPriceAdjustmentsSection = (
    priceAdjustments: SummaryItem[],
): SummaryGroup[] => {
    if (!priceAdjustments.length) return [];

    return [
        {
            label: 'Price Adjustments',
            items: priceAdjustments.map((item) => ({
                as: 'item',
                ...item,
            })),
        },
    ];
};

export type { AddedAndRemovedActions };
export {
    convertSectionsIntoTripSummaryItems,
    getAccommodationsSummaryItems,
    getActivitiesTripSummaryItems,
    getAddedAndRemovedTripSummarySections,
    getGroupedPrePostAddOns,
    getPriceAdjustmentsSection,
    switchSignIf,
    getRoomSelectionSection,
    getTransfersSummaryItems,
    getTripSummaryPendingChanges,
};
