import { lookup } from '@trova-trip/country-data';
import format from 'date-fns/format';
import every from 'lodash/every';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';
import {
    TravelerNavigationCategoriesTripStatuses,
    TravelerTripStatuses,
} from '../../../config/constants';

const logError = (error: string): void => console.error(`@TROVA: ${error}`);

const getCountryAlpha3 = (alpha2: string): string | undefined => {
    const countries = lookup.countries({ alpha2: alpha2.toUpperCase() });
    return countries.length ? countries[0].alpha3 : undefined;
};

const getBookingStatusFromTripStatus = (
    tripStatus: string,
): string | undefined => {
    const { COMPLETE, CONFIRMED, CANCELLED, PENDING } = TravelerTripStatuses;

    if (TravelerNavigationCategoriesTripStatuses[COMPLETE].has(tripStatus)) {
        return COMPLETE;
    }

    if (TravelerNavigationCategoriesTripStatuses[PENDING].has(tripStatus)) {
        return PENDING;
    }

    if (TravelerNavigationCategoriesTripStatuses[CONFIRMED].has(tripStatus)) {
        return CONFIRMED;
    }

    if (TravelerNavigationCategoriesTripStatuses[CANCELLED].has(tripStatus)) {
        return CANCELLED;
    }

    return undefined;
};

/**
 * Recursively checks if all properties within an object and its nested objects have values.
 *
 * @param obj - The object to be checked.
 * @returns boolean - Returns true if all properties have values, and false otherwise.
 */
const areAllPropsFilled = (obj: Record<any, any>): boolean => {
    return every(obj, (value) => {
        if (isPlainObject(value)) {
            return areAllPropsFilled(value);
        }
        return !isNil(value);
    });
};

const TIME_FORMATS = {
    DATE: 'MM/DD/YYYY',
    DATE_LONG: 'll',
    '24_HOURS': 'HH:mm',
    '12_HOURS': 'h:mm a',
};

type TimeDropdownOption = {
    label: string;
    value: string;
};

/**
 * Creates a ready-to-use array of objects for the Time Dropdown.
 * @param {number} startHour - Hour to be displayed as the first selectable option for the user (0 to 23). The times before the one specified here will be sliced and pushed to the end of the option's list.
 * @param {number} interval - Size of interval increments in minutes. Valid values are 1, 5, 10, 15, 30, and 60.
 * @returns {Array<TimeDropdownOption>}
 */
const createTimeDropdownOptions = (
    startHour: number = 6,
    interval: 1 | 5 | 10 | 15 | 30 | 60 = 15,
): TimeDropdownOption[] => {
    const options: TimeDropdownOption[] = [];
    const intervalCount: number = 60 / interval;

    const hours: number[] = Array(24)
        .fill(1)
        .map((_, index) => (startHour + index) % 24);

    hours.forEach((hour) => {
        for (
            let currentInterval = 0;
            currentInterval < intervalCount;
            currentInterval++
        ) {
            const timePeriod = new Date(
                0,
                0,
                0,
                hour,
                currentInterval * interval,
            );

            options.push({
                label: format(timePeriod, TIME_FORMATS['12_HOURS']),
                value: format(timePeriod, TIME_FORMATS['24_HOURS']),
            });
        }
    });

    return options;
};

const getTimeDropdownOptionFromDateTime = (
    dateTime: Date,
): TimeDropdownOption => ({
    value: format(dateTime, TIME_FORMATS['24_HOURS']),
    label: format(dateTime, TIME_FORMATS['12_HOURS']),
});

/**
 * Returns strongly typed keys of an object.
 *
 * @param obj Object that contains the properties and methods. This can be an object that you created or an existing Document Object Model (DOM) object.
 * @returns Array<keyof Obj> - An array of a given object's own enumerable property names.
 */
const objectKeys = <Obj extends Record<string, any>>(
    obj: Obj,
): Array<keyof Obj> => {
    return Object.keys(obj) as Array<keyof Obj>;
};

export type { TimeDropdownOption };

export {
    TIME_FORMATS,
    areAllPropsFilled,
    createTimeDropdownOptions,
    getBookingStatusFromTripStatus,
    getCountryAlpha3,
    getTimeDropdownOptionFromDateTime,
    logError,
    objectKeys,
};
