import { useEffect } from 'react';
import { useAnalytics, useManageBooking } from '../../hooks';
import ClearAllConfirmationModal from '../ClearAllConfirmationModal';
import TripSummaryAccordion from '../TripSummaryAccordion';
import TripSummaryDrawer from '../TripSummaryDrawer';
import isEmpty from 'lodash/isEmpty';

const TripSummary = () => {
    const {
        state: {
            tripSummary: {
                isConfirmationModalOpen,
                sections: { added, removed, roomSelection },
                total,
            },
            addOns: {
                accommodations: { singleSupplement },
            },
        },
        actions: { closeConfirmationModal },
    } = useManageBooking();

    const { trackUpdateCartEvent } = useAnalytics();

    useEffect(() => {
        if (isEmpty(added) && isEmpty(removed) && isEmpty(roomSelection))
            return;

        trackUpdateCartEvent({
            sections: { added, removed, roomSelection },
            singleSupplement,
            cartBalance: total,
        });
    }, [added?.length, removed?.length, roomSelection]);

    return (
        <>
            <TripSummaryDrawer />
            <TripSummaryAccordion />
            <ClearAllConfirmationModal
                open={isConfirmationModalOpen}
                onCancel={() => closeConfirmationModal()}
            />
        </>
    );
};

export default TripSummary;
