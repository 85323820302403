import { constants, models } from '@trova-trip/trova-models';

type Service = models.services.Service;
type Accommodation = models.services.Accommodation;
type Trip = models.trips.Trip;

const {
    PRE_TRIP_SERVICES,
    POST_TRIP_SERVICES,
    PRE_TRIP_ACCOMMODATION_SERVICES,
    POST_TRIP_ACCOMMODATION_SERVICES,
} = constants.trips;

const { ServiceType } = constants.services;

const getAccommodationServices = (
    additionalOptionalPrePostServices: Service[],
): {
    preTripAccommodationsServices: Accommodation[];
    postTripAccommodationServices: Accommodation[];
} => {
    const preTripAccommodationsServices: Accommodation[] = [];
    const postTripAccommodationServices: Accommodation[] = [];
    additionalOptionalPrePostServices.forEach((additionalOptionalService) => {
        if (
            additionalOptionalService.name &&
            additionalOptionalService.type === ServiceType.ACCOMMODATION
        ) {
            if (
                POST_TRIP_ACCOMMODATION_SERVICES.includes(
                    additionalOptionalService.name,
                )
            ) {
                postTripAccommodationServices.push(additionalOptionalService);
            }
            if (
                PRE_TRIP_ACCOMMODATION_SERVICES.includes(
                    additionalOptionalService.name,
                )
            ) {
                preTripAccommodationsServices.push(additionalOptionalService);
            }
        }
    });
    return { preTripAccommodationsServices, postTripAccommodationServices };
};

/**
 * Retrieves the list of accommodation services during a trip based on the given `servicesByDay`.
 *
 * @param servicesByDay
 *
 * @return The list of accommodation services during the trip.
 */
const getAccommodationServicesDuringTrip = (
    servicesByDay: Trip['servicesByDay'],
): Accommodation[] => {
    if (!servicesByDay) return [];

    const accommodations: Accommodation[] = [];

    servicesByDay.forEach((dayServices) => {
        const accommodationServices = dayServices.filter(
            ({ service }) =>
                (service as Service)?.type === ServiceType.ACCOMMODATION,
        );

        accommodationServices.forEach((accommodationService) => {
            accommodations.push(accommodationService.service as Accommodation);
        });
    });

    return accommodations;
};

type GroupedAccommodations = {
    single: Service;
    double: Service;
    twin: Service;
};

const groupAccommodations = (
    serviceName: Record<string, string>,
    groupedAccommodations: GroupedAccommodations,
    currentService: Service,
): GroupedAccommodations => {
    switch (currentService.name) {
        case serviceName.SINGLE_ACCOMMODATION_NAME:
            groupedAccommodations.single = currentService;
            break;
        case serviceName.DOUBLE_ACCOMMODATION_NAME:
            groupedAccommodations.double = currentService;
            break;
        case serviceName.TWIN_ACCOMMODATION_NAME:
            groupedAccommodations.twin = currentService;
            break;
        default:
            break;
    }
    return groupedAccommodations;
};

const getGroupedAccommodation = (services: Service[]) => {
    const { preTripAccommodationsServices, postTripAccommodationServices } =
        getAccommodationServices(services);

    const groupedPreTripAccommodationsServices =
        preTripAccommodationsServices.reduce(
            (current, next) =>
                groupAccommodations(PRE_TRIP_SERVICES, current, next),
            {} as GroupedAccommodations,
        );

    const groupedPostTripAccommodationsServices =
        postTripAccommodationServices.reduce(
            (current, next) =>
                groupAccommodations(POST_TRIP_SERVICES, current, next),
            {} as GroupedAccommodations,
        );
    return {
        preTripAccommodationsServices: groupedPreTripAccommodationsServices,
        postTripAccommodationServices: groupedPostTripAccommodationsServices,
    };
};

export {
    getAccommodationServices,
    getAccommodationServicesDuringTrip,
    getGroupedAccommodation,
};
