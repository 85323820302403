import { BaseBox, Stack } from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import WidgetEmptyCard from '../common/WidgetEmptyCard';
import WidgetHeading from '../common/WidgetHeading';
import UpcomingTripCard from './UpcomingTripCard/UpcomingTripCard';
import WidgetLoadingFallback from '../WidgetLoadingFallback/WidgetLoadingFallback';
import {
    HostUpcomingTrips,
    WidgetCallbacks,
    WidgetNames,
} from '../../../common/types';
import useHomeWidgetsData from '../../hooks/useHomeWidgetData';
import { buildHostProductRoute } from '../../../common/utils';
import { useTrackingEvent } from '../../../../../../../analytics/hooks';
import { useEffect } from 'react';

const labels = {
    title: 'Upcoming Trips',
    viewAll: 'View All',
};

interface UpcomingTripsProps extends WidgetCallbacks {}

const UpcomingTrips = (props: UpcomingTripsProps): JSX.Element => {
    const history = useHistory();
    const { trackUserEvent } = useTrackingEvent();
    const { onError, onLoad } = props;

    const { load, data: upcomingTrips } = useHomeWidgetsData<HostUpcomingTrips>(
        WidgetNames.UPCOMING_TRIPS,
    );

    useEffect(() => {
        load().then((res) => {
            if (res.success) {
                onLoad?.();
            } else {
                onError?.();
            }
        });
    }, []);

    if (!upcomingTrips) {
        return (
            <WidgetLoadingFallback
                renderCount={2}
                skeletonLoaderHeight='9.6rem'
            />
        );
    }
    const hasTrips = !isEmpty(upcomingTrips);
    const tripsPath = buildHostProductRoute('TRIPS');

    const goToTrips = (): void => {
        trackUserEvent({ eventName: 'Host Viewed All Trips' });
        history.push(tripsPath);
    };

    const hasFullTripsAmount = upcomingTrips?.length >= 3;

    return (
        <BaseBox position='relative'>
            <BaseBox position={{ base: 'static', md: 'absolute' }} top={-10}>
                <WidgetHeading>{labels.title}</WidgetHeading>
            </BaseBox>
            {hasTrips ? (
                <>
                    <Stack
                        spacing={5}
                        width='full'
                        align='stretch'
                        direction='column'
                    >
                        {upcomingTrips.map((trip, index) => (
                            <BaseBox
                                key={trip._id}
                                height={{ base: '5.8rem', md: '9.6rem' }}
                            >
                                <UpcomingTripCard
                                    trip={{ ...trip, trackingIndex: index + 1 }}
                                />
                            </BaseBox>
                        ))}
                    </Stack>
                    {hasFullTripsAmount ? (
                        <BaseBox width='full' marginTop={{ base: 3, md: 4 }}>
                            <BaseBox textAlign='center'>
                                <Button
                                    color='neutral.40'
                                    size={{ base: 'sm', md: 'md' }}
                                    variant='tertiary'
                                    onClick={goToTrips}
                                >
                                    {labels.viewAll}
                                </Button>
                            </BaseBox>
                        </BaseBox>
                    ) : null}
                </>
            ) : (
                <WidgetEmptyCard type='upcomingTrips' />
            )}
        </BaseBox>
    );
};

export default UpcomingTrips;
