// @ts-nocheck
import { Grid } from '@material-ui/core';
import { Stack } from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import React from 'react';

import { constants } from '@trova-trip/trova-models';
import Loading from '../../../../../components/Loading';
import Danger from '../../../../../components/Typography/Danger';
import FakeEvent from '../../../../../util/form/FakeEvent';
import FormSaver from '../../../../../util/form/FormSaver';
import { getAccommodationServices } from '../../../../common/helpers';
import ServicesTable from '../../../components/ServicesTable/ServicesTable';
import { redirectToTab } from '../../utils/RedirectToTab';
import {
    getValidityPeriods,
    prepareItineraryForSubmission,
    setValidityPeriods,
    updateItineraryStatus,
} from '../../utils/validityPeriodsHelper';
import {
    PaddedCheckbox,
    StyledContainerGrid,
} from '../ReviewPricing.components';
import CardSection from '../common/CardSection';
import {
    getAdditionalOptionalServices,
    getTwinPrePostServices,
} from '../common/ReviewPricing.helpers';
import { CurrencyInputs, TravelersInputs } from './Forms';
import ValidityPeriods from './ValidityPeriods/ValidityPeriods';
import { hasZeroTravelersInValidityPeriods } from '../common/ReviewPricing.helpers';

const { itineraryStatuses } = constants.itinerary;

type ItineraryReviewPricingFormProps = {
    data: any;
    profile: any;
    updateModelData: (
        id: string,
        data: any,
        callbacks: Record<string, (message: string) => void>,
    ) => void;
    renderError: (error: string) => void;
    errorText: string;
    cleanErrors: () => void;
    history: any;
};

class ItineraryReviewPricingForm extends React.Component<ItineraryReviewPricingFormProps> {
    updateItinerary(data, formData) {
        if (hasZeroTravelersInValidityPeriods(formData)) {
            this.props.renderError(
                'One or more of your validity periods has 0 travelers, please add travelers to continue.',
            );
            return; // Prevent submission
        }

        const itinerary = prepareItineraryForSubmission(formData);
        const newData = updateItineraryStatus(
            setValidityPeriods(itinerary, this.defaultPackage),
            itineraryStatuses[1],
        ); //status: 'in-review'

        const { id } = data;
        const { updateModelData, renderError } = this.props;
        updateModelData(id, newData, {
            successCallback: () => {
                this.setState({ newSuccess: true });
            },
            errorCallback: (error) => {
                renderError(`Error - ${error}`);
            },
        });
    }
    constructor(props) {
        super(props);
        this.state = {};
        const { data } = this.props;
        this.formSaver = new FormSaver(
            this,
            (formData) => this.updateItinerary(data, formData),
            data,
        );
        this.originalAdditionalOptionalServices = cloneDeep(
            data.additionalOptionalServices,
        );
    }

    componentWillUnmount() {
        this.props.cleanErrors();
    }

    updateValidityPeriods = (validityPeriods, packageElem) => {
        const packages = this.formSaver.getData(`packages`);
        const selectedPackage = {
            ...packages[packageElem],
            validityPeriods,
        };

        const updatedPackages = {
            ...packages,
            [packageElem]: selectedPackage,
        };

        this.formSaver.updateValue(`packages`, updatedPackages);
    };

    handleServiceChange = (event, id) => {
        const price = event.metadata.value;
        this.updateService(price, id);
    };

    getServicesToUpdate = () => {
        const servicesToUpdate = [];
        const currentServices = this.formSaver.getData(
            'additionalOptionalServices',
        );
        const originalServices = this.originalAdditionalOptionalServices;

        currentServices.forEach((service, index) => {
            if (service.price !== originalServices[index].price) {
                servicesToUpdate.push({
                    _id: service._id,
                    price: service.price,
                    name: service.name,
                    type: service.type,
                });
            }
        });

        const prePostTwinServices = getTwinPrePostServices(
            servicesToUpdate,
            currentServices,
        );

        return [...servicesToUpdate, ...prePostTwinServices];
    };

    updateService = (price, id) => {
        const currentServices = this.formSaver.getData(
            'additionalOptionalServices',
        );
        const serviceIndex = currentServices.findIndex(
            (service) => service._id === id,
        );

        if (serviceIndex === -1) return;

        const newServices = cloneDeep(currentServices);

        newServices[serviceIndex] = {
            ...newServices[serviceIndex],
            price: price,
        };

        this.formSaver.updateValue(
            'additionalOptionalServices',
            newServices,
            () => {
                const servicesToUpdate = this.getServicesToUpdate();
                this.formSaver.updateValue(
                    'servicesToUpdate',
                    servicesToUpdate,
                );
            },
        );
    };

    handleTrovaComponentsChange = (event, name, value) => {
        const fakeEvent = new FakeEvent(name, value);
        this.formSaver.handleChange(fakeEvent);
    };

    render() {
        if (!this.formSaver) {
            return <Loading />;
        }
        if (this.state.newSuccess) {
            redirectToTab(this.props.history, `success`);
        }
        const currentCurrency = this.formSaver.getData(`currency`);

        const { accommodationsServices, transferServices } =
            getAdditionalOptionalServices(
                this.formSaver.getData('additionalOptionalServices'),
            );

        const { preTripAccommodationsServices, postTripAccommodationServices } =
            getAccommodationServices(accommodationsServices);

        const itineraryPackages = this.formSaver.getData('packages');

        return (
            <Stack
                direction='column'
                spacing={6}
                overflowX={{
                    lg: 'visible',
                    base: 'auto',
                }}
                paddingBottom={20}
            >
                <Grid container spacing={24}>
                    <Grid item xs={12} lg={4}>
                        <TravelersInputs
                            formSaver={this.formSaver}
                            onChange={this.handleTrovaComponentsChange}
                        />
                    </Grid>
                    <Grid item xs={12} md>
                        <CurrencyInputs
                            formSaver={this.formSaver}
                            onChange={this.handleTrovaComponentsChange}
                        />
                    </Grid>
                </Grid>

                <StyledContainerGrid container>
                    <CardSection width='full' title={'Pre and Post Services'}>
                        <ServicesTable
                            currency={currentCurrency}
                            onChange={this.handleServiceChange}
                            services={[
                                {
                                    title: 'Pre-night Accommodation (price per room)',
                                    optionalServices:
                                        preTripAccommodationsServices,
                                },
                                {
                                    title: 'Post-night Accommodation (price per room)',
                                    optionalServices:
                                        postTripAccommodationServices,
                                },
                                {
                                    title: 'Transfers (Private)',
                                    optionalServices: transferServices,
                                },
                            ]}
                        />
                    </CardSection>

                    <Grid item xs={12}>
                        {Object.keys(itineraryPackages).map((packageElem) =>
                            itineraryPackages[packageElem].enabled ? (
                                <ValidityPeriods
                                    type={packageElem}
                                    validityPeriods={getValidityPeriods(
                                        itineraryPackages,
                                        packageElem,
                                    )}
                                    updateValidityPeriods={(validityPeriod) =>
                                        this.updateValidityPeriods(
                                            validityPeriod,
                                            packageElem,
                                        )
                                    }
                                    currentCurrency={currentCurrency}
                                />
                            ) : null,
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedCheckbox
                            name='termsAndConditions'
                            value={true}
                            label='Form header'
                            detail='I confirm all trip details, including pricing and validity, are accurate and in accordance with our agreed upon terms.'
                        />
                    </Grid>
                    {this.props.errorText && (
                        <Grid item xs={12}>
                            <Danger>{this.props.errorText}</Danger>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={`auto`}>
                        <Button
                            variant='primary'
                            isFullWidth={{ base: true, sm: false }}
                            onClick={this.formSaver.handleFormSubmit}
                        >
                            Approve Itinerary
                        </Button>
                    </Grid>
                </StyledContainerGrid>
            </Stack>
        );
    }
}

const mapStateToProps = ({ profile, userItinerary }) => ({
    profile: profile.current,
    itinerary: userItinerary.current,
});

export default connect(
    mapStateToProps,
    null,
)(withRouter(ItineraryReviewPricingForm));
