import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { microApps } from '@trova-trip/trova-common';
import { Environment } from '@trova-trip/trova-common/dist/app.constants';
import { APP_ENVIRONMENT } from '../../../util/appUtils';
import { UserHostStoreSelector } from '../../../state/hosts';

export const usePublicProfileUrl = () => {
    const currentHost = useSelector(
        (state: UserHostStoreSelector) => state.userHosts.current,
    );

    const url = useMemo(
        () =>
            microApps.TrovaTripMicroApps.TROVA_HOST_PROFILE.trovatripHostProfileUrl(
                APP_ENVIRONMENT as Environment,
            ) +
            '/' +
            currentHost?.pagePath,
        [currentHost],
    );

    return url;
};
