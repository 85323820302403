import { ChangeEvent, useState } from 'react';
import {
    Stack,
    Textarea,
    TextareaSize,
    Text,
} from '@trova-trip/trova-components';
import { MAX_BIO_LENGTH } from '../../utils/constants';
import { EditFieldProps, PreviewProps } from '../../utils/types';

interface BioPreviewProps extends PreviewProps<'bio'> {}

export const BioPreview = ({ bio }: BioPreviewProps): JSX.Element => {
    return <Text>{bio || 'No bio written.'}</Text>;
};

export const BioEditField = ({
    fieldData = '',
    onChange,
}: EditFieldProps<'bio'>): JSX.Element => {
    const [bio, setBio] = useState<string>(fieldData as string);

    return (
        <>
            <Textarea
                name='bio'
                maxLength={MAX_BIO_LENGTH}
                size={TextareaSize.Flexible}
                value={bio}
                placeholder='Tell potential travelers about yourself...'
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value;

                    setBio(value);
                    onChange(value);
                }}
            />
            <Stack width='full' justify='flex-end'>
                <Text size='sm' color='blueGray.400' fontWeight='bold'>
                    {bio.length}/{MAX_BIO_LENGTH}
                </Text>
            </Stack>
        </>
    );
};
