import { BaseBox, Heading, Text, Stack } from '@trova-trip/trova-components';

export interface ItemProps {
    title: string;
    briefDescription: string;
    description: string;
    label: string;
    isActive: boolean;
}

const Item = (props: ItemProps): JSX.Element => {
    const { title, briefDescription, description, label, isActive } = props;

    const titleElement = isActive ? (
        <Heading as='h5' fontWeight='medium'>
            {title}
        </Heading>
    ) : (
        <Heading
            as='h6'
            fontWeight='medium'
            textTransform='none'
            color='blueGray.500'
        >
            {title}
        </Heading>
    );

    const descriptionElement = isActive ? (
        <Text>{description}</Text>
    ) : (
        <Text size='sm' color='blueGray.500'>
            {briefDescription}
        </Text>
    );

    return (
        <BaseBox
            paddingTop={2}
            paddingX={2}
            paddingBottom={4}
            border='1px solid'
            borderColor={isActive ? 'teal.trova' : 'transparent'}
            borderRadius='lg'
        >
            <Stack direction='column' spacing={1}>
                <Stack spacing={4} align='center'>
                    {titleElement}
                    <Text size='sm' color='blueGray.500'>
                        {label}
                    </Text>
                </Stack>
                {descriptionElement}
            </Stack>
        </BaseBox>
    );
};

export default Item;
