import { Grid, Heading, Stack, Text } from '@trova-trip/trova-components';
import { Card } from '@trova-trip/trova-components/build/next';
import { models, constants } from '@trova-trip/trova-models';
import useServicesByDay from 'applications/common/hooks/services/useServicesByDay';
import { TripContext } from 'applications/host/products/trips/TripsContext';
import { isEmpty } from 'lodash';
import React, {
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import Snackbar from '../../../../components/Snackbar/Snackbar';
import { ServiceTypesKeys } from '../../../../config/constants';
import {
    DayServiceUIModel,
    ServicesByDayUIModel,
} from '../../../../interfaces/UiModels.types';
import { useManageElementRefs } from '../../../../util/hooks/domHooks';
import { getTripDatesIfApplicable } from '../../../../util/ModelDataHelper';
import { getServiceTitleLabel } from '../../../common/components/services/LibraryServiceFormatter';
import ServiceCard from '../../../common/components/services/ServiceCard';
import TripServiceForm from '../TripServiceForm/TripServiceForm';

const {
    trips: { TRIP_STATUS },
} = constants;

type Service = models.services.Service;
type Trip = models.trips.Trip;
type WorkshopSpace = models.services.WorkshopSpace;

interface DayServiceUIModelExtended extends DayServiceUIModel {
    title: string;
}

type WorkshopsUiModel = DayServiceUIModelExtended[][];

type tripSelector = {
    userTrips: { current: Trip };
};

const createUiModels = (
    servicesByDay: ServicesByDayUIModel,
    startDate: Date,
): WorkshopsUiModel => {
    const tripDates = getTripDatesIfApplicable(
        startDate,
        servicesByDay,
        'dddd, MMMM DD, YYYY',
    );

    return servicesByDay?.map((days, dayIndex) => {
        return days.map((dayService) => {
            const service = dayService.service;
            const title = `${getServiceTitleLabel(service.type)} - Day ${
                dayIndex + 1
            } - ${tripDates[dayIndex]}`;
            const isEditing = !(service as WorkshopSpace).acceptedByHost;

            return {
                ...dayService,
                service: {
                    ...service,
                    dayIndex,
                },
                isEditing,
                readOnly: false,
                title,
            };
        });
    });
};

export type workshopDetailsRefProps = {
    scrollToFirstOpenForm: () => void;
};

const WorkshopDetails = React.forwardRef<JSX.Element>((_, ref) => {
    const currentTrip = useSelector(
        (state: tripSelector) => state.userTrips.current,
    );

    const { triggerChangeOnTripPublishData } = useContext(TripContext);

    const [snackBar, setSnackBar] = useState({
        message: '',
        color: 'info',
        show: false,
    });

    const [currentFirstOpenForm, setCurrentFirstOpenForm] = useState<
        string | undefined
    >(undefined);

    const { servicesByDay, startDate, status } = currentTrip;

    const uiModelData = createUiModels(
        servicesByDay as ServicesByDayUIModel,
        startDate as Date,
    );

    const {
        servicesByDay: updatedServicesByDay,
        toggleEditing,
        update: updateServiceEntity,
    } = useServicesByDay({ servicesByDay: uiModelData });

    const workshops = (updatedServicesByDay as WorkshopsUiModel).flatMap(
        (day) => {
            return day
                .filter((dayService) => {
                    const service = dayService.service;
                    return service.type === ServiceTypesKeys.WORKSHOP_SPACE;
                })
                .map((item) => {
                    return item;
                });
        },
    );

    const { getElementRef: getServiceRef, scrollToElement } =
        useManageElementRefs(workshops.map(({ service }) => `${service._id}`));

    useImperativeHandle(
        ref, //@ts-expect-error
        (): workshopDetailsRefProps => ({
            scrollToFirstOpenForm: (): void => {
                currentFirstOpenForm && scrollToElement(currentFirstOpenForm);
            },
        }),
    );

    useEffect(() => {
        const firstOpenFormIndex = workshops.findIndex((item) => {
            return item.isEditing;
        });
        const firstOpenForm = workshops[firstOpenFormIndex];

        firstOpenForm
            ? setCurrentFirstOpenForm(`${firstOpenForm.service._id}`)
            : setCurrentFirstOpenForm(undefined);
    }, [workshops]);

    const updateService = useCallback(
        async (service: Service, key: symbol): Promise<void> => {
            const validatedService = { ...service, acceptedByHost: true };

            try {
                await updateServiceEntity(validatedService, key);
                await triggerChangeOnTripPublishData();
                setSnackBar({
                    message: 'Workshop saved!',
                    color: 'success',
                    show: true,
                });
                toggleEditing(key);
            } catch (error) {
                setSnackBar({
                    message:
                        'There was an error updating the workshop. Please try again.',
                    color: 'danger',
                    show: true,
                });
            }
        },
        [toggleEditing, updateServiceEntity, currentTrip.id],
    );

    const handleEdit = useCallback(
        (key: symbol) => toggleEditing(key),
        [toggleEditing],
    );

    const handleCancel = useCallback(
        (key: symbol, resetForm: () => void): void => {
            toggleEditing(key);
            resetForm();
        },
        [toggleEditing],
    );

    if (isEmpty(workshops)) {
        return <></>;
    }

    return (
        <Grid>
            <Grid.Item columnSpan={{ base: '*', md: 10 }}>
                <Stack direction='column'>
                    <Heading as={'h4'}>Workshop Details</Heading>
                    <Text>
                        Below are the formal workshops that you’ve included in
                        your trip, add details to excite your potential
                        travelers.
                    </Text>
                </Stack>
            </Grid.Item>
            <Grid.Item columnSpan={{ base: '*', md: 10 }}>
                <Stack direction='column' spacing={6} align='stretch'>
                    {workshops.map(({ service, isEditing, title, $tkey }) => {
                        return (
                            <div key={service._id} data-card-wrapper>
                                <Card backgroundColor='neutral.white'>
                                    <ServiceCard
                                        //@ts-expect-error
                                        ref={getServiceRef(`${service._id}`)}
                                        collapsed={!isEditing}
                                        title={title}
                                        service={service}
                                        actionsBarConfig={{
                                            toggleChildren: {
                                                shouldRender:
                                                    status !==
                                                    TRIP_STATUS.CLOSED,
                                                handler: (): void => {
                                                    handleEdit($tkey);
                                                },
                                            },
                                        }}
                                    >
                                        <TripServiceForm
                                            service={service}
                                            serviceDayIndex={service.dayIndex.toString()}
                                            onSubmit={(service): void => {
                                                updateService(service, $tkey);
                                            }}
                                            onCancel={(_, resetForm): void => {
                                                handleCancel($tkey, resetForm);
                                            }}
                                            isDisabledByFormDirty={false}
                                        />
                                    </ServiceCard>
                                </Card>
                            </div>
                        );
                    })}
                </Stack>
            </Grid.Item>

            <Snackbar
                place='tr'
                color={snackBar.color}
                message={snackBar.message}
                open={snackBar.show}
                autoHideDuration={4000}
                onClose={(): void => {
                    setSnackBar({ message: '', color: 'info', show: false });
                }}
            />
        </Grid>
    );
});

export default WorkshopDetails;
