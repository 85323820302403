import styled from '@emotion/styled';
import TrovaFileInput from '../../../../../components/TrovaFileInput/TrovaFileInput';

import {
    Input,
    Dropdown,
    Textarea,
    Checkbox,
    Number,
    MultipleDropdown,
    DatePicker,
    TimePicker,
    Currency,
    theme,
} from '@trova-trip/trova-components';

export const PaddedInput = styled(Input)({ paddingTop: `1rem` });

export const PaddedDropdown = styled(Dropdown)({
    paddingTop: `1rem`,
});

export const PaddedTextarea = styled(Textarea)({
    paddingTop: `1rem`,
});

export const PaddedCheckbox = styled(Checkbox)({
    paddingTop: `1rem`,
});

export const PaddedNumberInput = styled(Number)({
    paddingTop: `1rem`,
});

export const PaddedTimePicker = styled(TimePicker)({
    paddingTop: `1rem`,
});

export const PaddedMultipleDropdown = styled(MultipleDropdown)({
    paddingTop: `1rem`,
});

export const StyledServiceFileInput = styled(TrovaFileInput)`
    margin: 2rem 0 0 0;

    .fileuploader {
        margin: 1rem 0 0;
    }

    .fileuploader-theme-gallery .fileuploader-items {
        margin: -16px 0 0 -16px;

        .fileuploader-input {
            width: 100%;
            margin: 0 16px;
        }

        .fileuploader-input-inner,
        .fileuploader-input-inner:hover {
            border: 2px dashed ${theme.colors.neutral[80]};
        }

        .upload-failed {
            background: ${theme.colors.neutral.white};
        }

        .fileuploader-item {
            width: 100%;

            .fileuploader-item-image img,
            .fileuploader-item-image canvas {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform 400ms ease-out;
            }

            .fileuploader-item-inner {
                overflow: inherit;
                box-shadow: none;

                .actions-holder {
                    top: -6px;
                    right: -11px;

                    .fileuploader-action i {
                        font-weight: 700;
                    }
                }

                .thumbnail-holder {
                    border-radius: 6px;
                    padding-top: 67%;
                }

                .content-holder {
                    text-align: left;
                    padding: 8px;

                    h5 {
                        font-weight: inherit;
                        line-height: 16px;
                    }
                }
            }
        }
    }

    @media (min-width: ${theme.breakpoints.sm}) {
        .fileuploader-theme-gallery .fileuploader-items {
            .fileuploader-item {
                width: calc(50% - 16px);

                .fileuploader-item-inner {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                }
            }
        }
    }

    @media (min-width: ${theme.breakpoints.md}) {
        .fileuploader-theme-gallery .fileuploader-items {
            .fileuploader-input {
                margin: 0 0 1rem 16px;
            }

            .fileuploader-input-inner {
                width: 100%;
            }

            .fileuploader-item {
                .fileuploader-item-inner {
                    display: block;
                    width: auto;

                    .thumbnail-holder {
                        padding-top: 85%;
                    }
                }
            }
        }
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        .fileuploader-theme-gallery .fileuploader-items {
            .fileuploader-item {
                width: calc(33.3333% - 16px);
            }
        }
    }

    @media (min-width: ${theme.breakpoints.xl}) {
        .fileuploader-theme-gallery .fileuploader-items {
            .fileuploader-item {
                width: calc(25% - 16px);
            }
        }
    }
`;

export const StyledDatePicker = styled(DatePicker)({
    paddingTop: `1rem`,
});

export const StyledCurrency = styled(Currency)({
    paddingTop: `1rem`,
});
