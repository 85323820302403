import { models, constants } from '@trova-trip/trova-models';
import { EmailValues } from '../EditEmailModal';

type PromoteEmails = models.common.PromoteEmails;
type DripEmail = models.common.DripEmail;

const LAUNCH_EMAIL_NAME = constants.emailHooks.LAUNCH_EMAIL_NAME;

const findCurrentDripEmail = (
    promoteEmails: PromoteEmails | undefined,
    emailCampaignName: string | undefined,
): DripEmail | undefined => {
    if (!promoteEmails) return;

    if (emailCampaignName === LAUNCH_EMAIL_NAME) {
        const launchEmail = promoteEmails.launch;
        const drip: DripEmail = {
            active: launchEmail.active,
            hostBlurb: launchEmail.hostBlurb || '',
            subject: launchEmail.subject || '',
            emailHook: {
                emailHookId: 'launch',
                name: LAUNCH_EMAIL_NAME,
                dripSortOrder: 0,
                dripCampaignIdentifier:
                    constants.emailHooks.DRIP_CAMPAIGN.LAUNCH,
                dripIdentifier: 'launch',
                emailCampaignScheduledLabel: 'After Launch',
            },
        };
        return drip;
    }

    const promoteEmail = promoteEmails.drip.find(
        (drip) => drip.emailHook?.name === emailCampaignName,
    );

    return promoteEmail;
};

const buildPromoteEmailsWithEditedEmail = (
    data: EmailValues,
    promoteEmails: PromoteEmails,
    currentEmail: DripEmail,
): { promoteEmails: PromoteEmails } | null => {
    if (!promoteEmails) {
        return null;
    }

    if (currentEmail.emailHook.name === LAUNCH_EMAIL_NAME) {
        const launchEmail = { ...promoteEmails.launch, ...data };
        const editedTripValues = {
            promoteEmails: {
                ...promoteEmails,
                launch: launchEmail,
            },
        };
        return editedTripValues;
    }

    const dripEmails = [...promoteEmails.drip];
    const currentEmailIndex = dripEmails.findIndex(
        (email) => email.emailHook?.name === currentEmail.emailHook?.name,
    );

    dripEmails[currentEmailIndex] = {
        ...currentEmail,
        ...data,
    };

    const editedTripValues = {
        promoteEmails: {
            ...promoteEmails,
            drip: dripEmails,
        },
    };

    return editedTripValues;
};

export { findCurrentDripEmail, buildPromoteEmailsWithEditedEmail };
