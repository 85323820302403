import StickyContentSimpleAction from 'applications/host/components/StickyContent/StickyContentSimpleAction';
import { useSelector } from 'react-redux';
import { TripStoreSelector } from 'state/trips';
import { useWindowHostNameAndProtocol } from 'util/hooks/useWindowHostname';
import ContentForModelStatus from '../../../../../common/components/ContentForModelStatus';
import StickyContentTripEarnings from '../../../../../host/components/StickyContent/StickyContentTripEarnings';
import { StatefulModel } from '../../../../../types';
import { HostEarningsStatuses } from '../../../../../../config/constants';
import { TripDetailsStoreSelector } from '../../../../../../state/userTrips';

interface ContentWithPotentialEarningsProps {
    model: StatefulModel;
    earnings: number;
    potentialEarnings: number;
    matchingStatuses: Set<string>;
}

const ContentWithPotentialEarnings = ({
    model,
    matchingStatuses,
    earnings,
    potentialEarnings,
}: ContentWithPotentialEarningsProps): JSX.Element | null => {
    return (
        <ContentForModelStatus
            model={model}
            matchingStatuses={matchingStatuses}
            render={(): JSX.Element => (
                <StickyContentTripEarnings
                    title='Host Earnings'
                    earnings={earnings}
                    potentialEarnings={potentialEarnings}
                />
            )}
        />
    );
};

const BookingsTabStickyContent = (): JSX.Element => {
    const domain = useWindowHostNameAndProtocol();
    const currentTrip = useSelector(
        (state: TripStoreSelector) => state.userTrips.current,
    );
    const currentTripDetails = useSelector(
        (state: TripDetailsStoreSelector) => state.userTripDetails.current,
    );
    const shouldRenderEarningsStickyContent =
        currentTrip?.earningsReport?.approved?.date;

    const earningsReportUrl = `${domain}/api/me/trips/${currentTrip.id}/earnings-report`;
    const downloadEarningsReport = (): Window | null =>
        window.open(earningsReportUrl);

    return (
        <>
            {shouldRenderEarningsStickyContent ? (
                <StickyContentSimpleAction
                    title={'Earnings'}
                    description={
                        'Your trip is finalized, download your earnings report!'
                    }
                    buttonProps={{
                        onClick: downloadEarningsReport,
                        children: 'Download Report',
                        rightIcon: 'download',
                    }}
                />
            ) : (
                <ContentWithPotentialEarnings
                    model={StatefulModel.TRIP}
                    matchingStatuses={HostEarningsStatuses}
                    earnings={currentTripDetails.earnings || 0}
                    potentialEarnings={currentTripDetails.potentialEarnings ?? 0}
                />
            )}
        </>
    );
};

export default BookingsTabStickyContent;
