import { appConstants } from '@trova-trip/trova-common';
import {
    BaseBox,
    Grid,
    Heading,
    Hide,
    IconButton,
    IconButtonProps,
    Image,
    Stack,
    Text,
    useWebShare,
} from '@trova-trip/trova-components';
import { Button, Card } from '@trova-trip/trova-components/build/next';
import { useEffect, useState } from 'react';
import { useTrackingEvent } from '../../../../analytics/hooks';
import referAFriend from '../../../../assets/img/refer-a-friend.svg';
import useGetUserLinks from '../../../../util/hooks/useGetUserLinks';
import useClipboard from '../../../common/hooks/useClipboard';

const publicDomain = appConstants.TrovaTripAppsDomainMap.production.public;

const ShareLink = ({ link }) => {
    const [copied, setCopied] = useState(false);

    const { trackUserEvent } = useTrackingEvent();
    const { copyTextToClipboard } = useClipboard();
    const { share, canShare } = useWebShare();

    const dataToShare: Parameters<typeof canShare>[0] = {
        title: 'Public Referral URL',
        text: link,
    };

    const canShareLink = canShare(dataToShare);

    useEffect(() => {
        if (!copied) {
            return;
        }
        const timer = setTimeout(() => setCopied(false), 2000);
        return () => clearTimeout(timer);
    }, [copied]);

    const trackReferralCopyEvent = () => {
        trackUserEvent({
            eventName: 'Host Copied Referral Link',
        });
    };

    const handleCopyClick = () => {
        copyTextToClipboard(link);
        setCopied(true);
        trackReferralCopyEvent();
    };

    const handleShareClick = () => {
        share(dataToShare);
        trackReferralCopyEvent();
    };

    const sharedButtonProps: Omit<IconButtonProps, 'children' | 'icon'> = {
        iconColor: 'red.trova',
        'aria-label': 'Share Link',
        size: 'md',
        variant: 'outlined',
    };

    const copyIconProps: Pick<IconButtonProps, 'icon' | 'iconColor'> = copied
        ? {
              icon: 'checkedCircle',
              iconColor: 'seaGreen.trova',
          }
        : { icon: 'copy', iconColor: 'red.trova' };

    const shareButton = (
        <IconButton
            icon='share'
            onClick={handleShareClick}
            {...sharedButtonProps}
        />
    );

    const copyButton = (
        <IconButton
            onClick={handleCopyClick}
            {...sharedButtonProps}
            {...copyIconProps}
        />
    );

    return (
        <Stack wrap='nowrap'>
            <Text
                flexGrow={1}
                height='2.5rem'
                lineHeight='2.5rem'
                borderRadius='md'
                border='1px solid'
                borderColor='blueGray.300'
                paddingX={4}
                overflow='hidden'
                textOverflow='ellipsis'
                whiteSpace='nowrap'
            >
                {link}
            </Text>
            <BaseBox flexShrink={0}>
                {canShareLink ? (
                    <>
                        <Hide breakpoint={{ min: 'md' }}>{shareButton}</Hide>
                        <Hide breakpoint={{ max: 'md' }}>{copyButton}</Hide>
                    </>
                ) : (
                    copyButton
                )}
            </BaseBox>
        </Stack>
    );
};

const ReferralCard = (): JSX.Element => {
    const { referralLink } = useGetUserLinks();

    return (
        <Card
            paddingTop={{ base: 8, md: 10 }}
            paddingBottom={{ base: 6, md: 10 }}
            paddingX={{ base: 5, md: 16 }}
            marginTop={{ base: 4, md: 0 }}
        >
            <Grid alignItems='center'>
                <Grid.Item
                    columnSpan={{ base: 12, md: 4 }}
                    justifySelf='center'
                >
                    <Image
                        src={referAFriend}
                        height={{ base: '15rem', md: '18rem', lg: '24rem' }}
                        data-gumlet='false'
                    />
                </Grid.Item>
                <Grid.Item columnSpan={{ base: 12, md: 8 }}>
                    <BaseBox paddingLeft={{ base: 0, md: 10 }}>
                        <Heading
                            as='h3'
                            color='blueGray.650'
                            textAlign={{ base: 'center', md: 'left' }}
                            size={{ base: '3xl', md: '3xl' }}
                            marginBottom={{ base: 4, md: 2 }}
                        >
                            Refer a Host and Earn $500
                        </Heading>
                        <Text
                            as='p'
                            color='blueGray.500'
                            textAlign={{ base: 'center', md: 'left' }}
                            marginBottom={{ base: 3, md: 4 }}
                        >
                            Know someone who would be a great TrovaTrip Host?
                            Share your signup link and get $500 when their trip
                            confirms!
                        </Text>
                        <BaseBox marginBottom={{ base: 8, md: 4 }}>
                            <Text
                                as='p'
                                fontSize={{ base: 'base', md: 'lg' }}
                                color='blueGray.650'
                                fontWeight={{ base: 'medium', md: 'bold' }}
                                marginBottom={2}
                            >
                                Share Your Link
                            </Text>
                            <ShareLink link={referralLink} />
                        </BaseBox>
                        <Button
                            variant='tertiary'
                            isFullWidth={{ base: true, md: false }}
                            as='a'
                            linkProps={{
                                href: `${publicDomain}/host/host-referral-program`,
                                target: '_blank',
                            }}
                        >
                            Learn More
                        </Button>
                    </BaseBox>
                </Grid.Item>
            </Grid>
        </Card>
    );
};

export default ReferralCard;
