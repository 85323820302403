import { lookup } from '@trova-trip/country-data';
import { coreUtils } from '@trova-trip/trova-common';
import { models } from '@trova-trip/trova-models';
import capitalize from 'lodash/capitalize';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import { useState } from 'react';
import {
    createInventoryItem as create,
    CreateItineraryInventoryItemPayload,
    fetchInventoryItems as fetch,
    fetchInventoryItem as fetchOne,
    deleteInventoryItem as remove,
    updateInventoryItem as update,
    UpdateItineraryInventoryItemPayload,
} from '../../../apis/itineraryInventory';
import { isSuccessApiResponse } from '../../../apis/types';
import { useSelector } from '../../../state/hooks';
import { formatDateToString } from '../helpers';
import useAnalytics from '../../operator/tabs/hooks/useAnalytics';

const { formatUSD } = coreUtils.currencyUtils;

type ItineraryInventoryItem =
    models.itineraryInventoryItems.ItineraryInventoryItem;

type SavedItineraryInventoryItem =
    models.itineraryInventoryItems.SavedItineraryInventoryItem;

export interface DisplayInventoryItem {
    id: string;
    startDate: string;
    package: string;
    requests: number;
    confirmed: number;
    minPrice: string;
    maxPrice: string;
}

const useItineraryInventory = () => {
    const { itinerary } = useSelector((state) => ({
        itinerary: state.userItinerary.current,
    }));

    const [inventoryItems, setInventoryItems] = useState<
        ItineraryInventoryItem[]
    >([]);

    const [inventoryItem, setInventoryItem] = useState<
        SavedItineraryInventoryItem | undefined
    >();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {
        trackCreateItineraryInventory,
        trackEditItineraryInventory,
        trackDeleteItineraryInventory,
    } = useAnalytics();

    const { id: itineraryId } = itinerary || {};

    const fetchInventoryItem = async (
        inventoryItemId: string,
        itineraryId: string | undefined = itinerary?.id,
        invalidate?: boolean,
    ): Promise<SavedItineraryInventoryItem | undefined> => {
        if (!itineraryId) {
            return;
        }

        const shouldFetch = invalidate || !inventoryItem;
        if (!shouldFetch) {
            return inventoryItem;
        }

        setIsLoading(true);
        setInventoryItem(undefined);
        const response = await fetchOne(itineraryId, inventoryItemId);
        setIsLoading(false);

        if (isSuccessApiResponse(response)) {
            setInventoryItem(response.data);
            return response.data;
        }

        throw new Error(response.error);
    };

    const fetchInventoryItems = async (
        itineraryId: string | undefined = itinerary?.id,
        invalidate: boolean = false,
    ): Promise<ItineraryInventoryItem[]> => {
        if (!itineraryId) {
            return [];
        }

        const shouldFetch = invalidate || !inventoryItems.length;

        if (!shouldFetch) return inventoryItems;

        setIsLoading(true);
        const response = await fetch(itineraryId);
        setIsLoading(false);

        if (isSuccessApiResponse(response)) {
            setInventoryItems(response.data);
            return response.data;
        }

        throw new Error(response.error);
    };

    const createInventoryItem = async (
        data: CreateItineraryInventoryItemPayload['data'],
    ): Promise<ItineraryInventoryItem | undefined> => {
        if (!itineraryId) {
            return;
        }

        const itemToCreate = { ...data, itinerary: itineraryId };

        setIsLoading(true);
        const response = await create({
            itineraryId,
            data: itemToCreate,
        });
        setIsLoading(false);

        if (isSuccessApiResponse(response)) {
            const item = response.data;
            addItemToState(item);
            trackCreateItineraryInventory(response.data);

            return response.data;
        }

        throw new Error(response.error);
    };

    const updateInventoryItem = async (
        inventoryItemId: string,
        data: UpdateItineraryInventoryItemPayload['data'],
    ): Promise<ItineraryInventoryItem | undefined> => {
        if (!itineraryId) {
            return;
        }

        setIsLoading(true);
        const response = await update({
            itineraryId,
            inventoryItemId,
            data,
        });
        setIsLoading(false);

        if (isSuccessApiResponse(response)) {
            updateItemInState(inventoryItemId, response.data);
            trackEditItineraryInventory(response.data);
            return response.data;
        }

        throw new Error(response.error);
    };

    const deleteInventoryItem = async (
        inventoryItemId: string,
    ): Promise<ItineraryInventoryItem | undefined> => {
        if (!itineraryId) {
            return;
        }

        setIsLoading(true);
        const response = await remove({
            itineraryId,
            inventoryItemId,
        });
        setIsLoading(false);

        if (isSuccessApiResponse(response)) {
            removeItemFromState(inventoryItemId);

            const inventoryItem = inventoryItems.find(
                (item) => item._id === inventoryItemId,
            );

            if (inventoryItem) {
                trackDeleteItineraryInventory(inventoryItem);
            }

            return response.data;
        }

        throw new Error(response.error);
    };

    const addItemToState = (item: ItineraryInventoryItem): void => {
        setInventoryItems((prev) => [item, ...prev]);
    };

    const removeItemFromState = (itemId: string): void => {
        setInventoryItems(inventoryItems.filter((item) => item._id !== itemId));
    };

    const updateItemInState = (
        itemId: string,
        updatedItem: ItineraryInventoryItem,
    ): void => {
        setInventoryItems((prev) =>
            prev.map((item) => {
                if (item._id === itemId) {
                    return updatedItem;
                }
                return item;
            }),
        );
    };

    const getInventoryItemById = (
        items: ItineraryInventoryItem[],
        id: string,
    ): ItineraryInventoryItem | undefined => {
        return items.find((item) => item._id === id);
    };

    const getInventoryItemYear = (item: ItineraryInventoryItem): number => {
        return new Date(item.startDate).getUTCFullYear();
    };

    const filterItineraryItemsByYear = (
        items: ItineraryInventoryItem[],
        year?: number,
    ): ItineraryInventoryItem[] => {
        if (!year) {
            return items;
        }
        return items.filter((item) => getInventoryItemYear(item) === year);
    };

    const getInventoryYearsAvailable = (
        items: ItineraryInventoryItem[],
    ): number[] => {
        return items
            .reduce((years, item) => {
                const year = getInventoryItemYear(item);
                if (!years.includes(year)) {
                    years.push(year);
                }
                return years;
            }, [] as number[])
            .sort((a, b) => a - b);
    };

    const getInventoryItemsStartDates = (): Date[] => {
        return inventoryItems.map((item) => {
            return item.startDate;
        });
    };

    const formatPrice = (price: number, currencyCode: string): string => {
        const formattedPrice = formatUSD(price);
        const [currencyInfo] = lookup.currencies({ code: currencyCode });

        if (!currencyInfo) {
            return formattedPrice;
        }

        const { symbol } = currencyInfo;
        return formattedPrice.replace('$', symbol);
    };

    const formatInventoryItemToDisplay = (
        item: ItineraryInventoryItem,
        currencyCode: string,
    ): DisplayInventoryItem => {
        const {
            _id,
            startDate,
            packageLevel,
            quantityPending,
            quantityConfirmed,
            costSchedule,
        } = item;

        const minPrice = minBy(costSchedule, 'price')?.price || 0;
        const maxPrice = maxBy(costSchedule, 'price')?.price || 0;

        return {
            id: _id as string,
            startDate: formatDateToString(
                new Date(startDate),
                'MMM d, yyyy',
                'UTC',
            ),
            package: capitalize(packageLevel),
            requests: quantityPending,
            confirmed: quantityConfirmed,
            minPrice: formatPrice(minPrice, currencyCode),
            maxPrice: formatPrice(maxPrice, currencyCode),
        };
    };

    const formatInventoryItemsToDisplay = (
        items: ItineraryInventoryItem[],
        currencyCode: string,
    ): DisplayInventoryItem[] =>
        items
            .sort(
                (a, b) =>
                    new Date(a.startDate).getTime() -
                    new Date(b.startDate).getTime(),
            )
            .map((item) => {
                return formatInventoryItemToDisplay(item, currencyCode);
            });

    return {
        inventoryItem,
        inventoryItems,
        isLoading,
        fetchInventoryItem,
        fetchInventoryItems,
        createInventoryItem,
        updateInventoryItem,
        deleteInventoryItem,
        getInventoryItemById,
        getInventoryYearsAvailable,
        getInventoryItemsStartDates,
        filterItineraryItemsByYear,
        formatInventoryItemToDisplay,
        formatInventoryItemsToDisplay,
    };
};

export default useItineraryInventory;
