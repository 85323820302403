import {
    Card,
    ComponentWidth,
    FormDataObjectType,
    Grid,
    TextareaSize,
    theme,
    useFormSaver,
} from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';
import includes from 'lodash/includes';
import Danger from '../../../../../components/Typography/Danger';
import { ServiceTypesKeys } from '../../../../../config/constants';
import {
    getColorFromServiceType,
    getServiceTitleLabel,
} from '../LibraryServiceFormatter';
import ServiceTitle from '../ServiceTitle';
import { ServiceFormPermissions, fieldNamesByServiceType } from '../helpers';
import {
    PaddedInput,
    PaddedTextarea,
    StyledServiceFileInput,
} from './Form.components';
import ActionButtons from './Shared/ActionButtons';
import SaveToLibraryCheckbox from './Shared/SaveToLibraryCheckbox';
import { validationSchema } from './Shared/validationSchema';

type ServiceType = models.services.ServiceType;

interface InfoCardFormProps {
    closeForm: () => void;
    updateItinerary: (data: any) => void;
    serviceToEdit?: models.services.SavedInfo;
    errorText?: string;
    dayIndex: number;
    permissions?: ServiceFormPermissions;
    disabledFields?: string[];
}

const InfoCardForm = (props: InfoCardFormProps): JSX.Element => {
    const {
        updateItinerary,
        serviceToEdit,
        dayIndex,
        disabledFields,
        closeForm,
        errorText,
        permissions,
    } = props;

    const isNew = !serviceToEdit;

    const defaultData = !isNew
        ? serviceToEdit
        : {
              saveToLibrary: false,
              type: ServiceTypesKeys.INFO,
              title: `Day ${dayIndex + 1}`,
          };

    const formSaver = useFormSaver({
        onSubmit: async (values) => {
            updateItinerary(values);
        },
        initialValues: defaultData as FormDataObjectType,
        validationSchema: {
            schema: validationSchema,
        },
    });

    const { formValues, formErrors, formState } = formSaver;

    const handleFormChange = (event: React.SyntheticEvent): void => {
        const { value, name } = event.target as HTMLInputElement;
        formValues.set[name](value);
    };

    const handleCheckboxChange = (event: React.SyntheticEvent): void => {
        const { name } = event.target as HTMLInputElement;
        const isChecked = !formValues.get.nested(name);
        formValues.set.nested(name, isChecked);
    };

    const onRemoveAction = () => {
        formValues.set.nested('deleted', true);
        updateItinerary({
            ...formState,
            deleted: true,
        });
    };

    const { info: fieldNames } = fieldNamesByServiceType;

    const serviceType = formValues.get[fieldNames.type] as ServiceType;

    return (
        <Card backgroundColor={theme.colors.neutral.white}>
            <ServiceTitle
                title={getServiceTitleLabel(serviceType)}
                color={getColorFromServiceType(serviceType)}
            />
            <PaddedInput
                name={fieldNames.title}
                placeholder='Title'
                label='Title *'
                size={ComponentWidth.Medium}
                value={formValues.get[fieldNames.title] as string}
                onChange={handleFormChange}
                disabled={includes(disabledFields, fieldNames.title)}
            />
            <PaddedTextarea
                name={fieldNames.description}
                placeholder='Description'
                label='Description *'
                size={TextareaSize.Medium}
                value={formValues.get[fieldNames.description] as string}
                error={formErrors?.[fieldNames.description]}
                onChange={handleFormChange}
                expandable={true}
                disabled={includes(disabledFields, fieldNames.description)}
            />
            <StyledServiceFileInput
                className=''
                detail=''
                error=''
                key=''
                name={fieldNames.images}
                value={formValues.get[fieldNames.images]}
                onChange={handleFormChange}
                label={`Upload images`}
                maxFiles={`10`}
                disabled={includes(disabledFields, fieldNames.images)}
            />
            <SaveToLibraryCheckbox
                value={formValues.get[fieldNames.saveToLibrary] as boolean}
                onChange={handleCheckboxChange}
                disabled={includes(disabledFields, fieldNames.saveToLibrary)}
            />
            {errorText ? (
                <Grid>
                    <Grid.Item columnSpan={12}>
                        <Danger>{errorText}</Danger>
                    </Grid.Item>
                </Grid>
            ) : null}
            <ActionButtons
                serviceType={serviceType}
                closeForm={closeForm}
                submitForm={() => formSaver.handleFormSubmit(undefined)}
                onRemoveAction={onRemoveAction}
                isNewEntity={isNew}
                permissions={permissions}
            />
        </Card>
    );
};

export default InfoCardForm;
