import { appConstants } from '@trova-trip/trova-common';
import { postMessengerUtils } from '@trova-trip/trova-common/dist/esm/vendor-libs';
import { models } from '@trova-trip/trova-models';
import { APP_ENVIRONMENT } from '../../utils';

type PaymentSuccessPostMessage = models.payments.PaymentSuccessPostMessage;
type PaymentErrorPostMessage = models.payments.PaymentErrorPostMessage;

type PaymentAppPostMessages =
    | PaymentSuccessPostMessage
    | PaymentErrorPostMessage;

const { createPostMessenger } = postMessengerUtils;
const { TrovaTripAppsDomainMap } = appConstants;

const trovaTripPortalDomain = TrovaTripAppsDomainMap[APP_ENVIRONMENT].booking;

const PaymentPostMessageType = models.payments.PaymentPostMessageType;

const initializePaymentPostMessenger = () => {
    const pm = createPostMessenger<PaymentAppPostMessages>(
        trovaTripPortalDomain,
    );

    return pm;
};

export { initializePaymentPostMessenger, PaymentPostMessageType };

export type { PaymentSuccessPostMessage, PaymentErrorPostMessage };
