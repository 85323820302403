import {
    BaseBox,
    Heading,
    Stack,
    StatusIndicator,
    Text,
    TextProps,
    theme,
} from '@trova-trip/trova-components';
import { Card, Table } from '@trova-trip/trova-components/build/next';
import { getAgeFromBirthday } from '../../../../../../util/getAgeFromBirthday';
import { BookingWithTrip, User } from '../../types';
import styled from '@emotion/styled';

interface TravelersInfoTableProps {
    booking: BookingWithTrip;
}

interface PrimaryTravelerNameCellProps {
    firstName: string;
    lastName: string;
}

interface TextHeaderProps {
    children: string;
    padding?: TextProps['padding'];
}

const StyledNameCell = styled(Table.Cell)`
    position: relative;
    width: 44%;
`;

const PrimaryTravelerNameCell = ({
    firstName,
    lastName,
}: PrimaryTravelerNameCellProps): JSX.Element => {
    return (
        <StyledNameCell width={{ md: '48%' }}>
            <BaseBox
                backgroundColor='seaGreen.trova'
                display='inline-block'
                position='absolute'
                top='16px'
                left='14px'
                height='100%'
                padding='3px'
                margin='-16px 16px 16px -16px'
            />
            <Text fontSize='sm'>{`${firstName} ${lastName}`}</Text>
        </StyledNameCell>
    );
};

const TextHeader = ({ children, padding }: TextHeaderProps): JSX.Element => (
    <Table.Header padding={padding}>
        <Text as='span' size='sm' textTransform='capitalize' fontWeight='bold'>
            {children}
        </Text>
    </Table.Header>
);

const TravelersInfoTable = ({
    booking,
}: TravelersInfoTableProps): JSX.Element => {
    const { customer } = booking;
    const user = customer as User;
    const additionalParticipants = booking.additionalParticipants as User[];
    const travelerAge = getAgeFromBirthday(user.dateOfBirth);

    return (
        <Card marginTop={6}>
            <Stack align='center' justify='space-between'>
                <Heading
                    as='h4'
                    fontWeight='medium'
                    size={{ base: 'xl', lg: '2xl' }}
                >
                    Traveler Information
                </Heading>
                <Stack direction='row' align='center' spacing={2}>
                    <StatusIndicator color={theme.colors.seaGreen.trova} />{' '}
                    <Text color='blueGray.650' fontSize='sm'>
                        Primary
                    </Text>
                </Stack>
            </Stack>

            <Table variant='simple' borderOutline={false}>
                <Table.Head>
                    <Table.Row>
                        <TextHeader>Name</TextHeader>
                        <TextHeader>Age</TextHeader>
                        <TextHeader padding={2}>Gender</TextHeader>
                        <TextHeader>Email</TextHeader>
                    </Table.Row>
                </Table.Head>

                <Table.Body>
                    <Table.Row>
                        <PrimaryTravelerNameCell
                            firstName={user.firstName}
                            lastName={user.lastName}
                        />
                        <Table.Cell>{travelerAge}</Table.Cell>
                        <Table.Cell padding={2}>{user.gender}</Table.Cell>
                        <Table.Cell>{user.email}</Table.Cell>
                    </Table.Row>

                    {additionalParticipants.map(
                        ({
                            _id,
                            email,
                            firstName,
                            lastName,
                            gender,
                            dateOfBirth,
                        }) => (
                            <Table.Row key={_id}>
                                <Table.Cell>
                                    {firstName} {lastName}
                                </Table.Cell>
                                <Table.Cell>
                                    {getAgeFromBirthday(dateOfBirth)}
                                </Table.Cell>
                                <Table.Cell padding={2}>{gender}</Table.Cell>
                                <Table.Cell>{email}</Table.Cell>
                            </Table.Row>
                        ),
                    )}
                </Table.Body>
            </Table>
        </Card>
    );
};

export default TravelersInfoTable;
