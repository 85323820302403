import {
    Image,
    Stack,
    Text,
    Heading,
    Grid,
} from '@trova-trip/trova-components';
import {
    Dialog,
    Button,
    Link,
    IconProps,
} from '@trova-trip/trova-components/build/next';
import exportingDataImageError from '../../../../../../../../assets/img/audience-summary/exporting-data-error.svg';

const responseFailureCopy = {
    header: 'Oops! We encountered an error while exporting your data',
    primaryButton: {
        text: 'Try Again',
        icon: 'refresh' as IconProps['as'],
    },
    secondaryButton: {
        text: 'Cancel',
    },
};

const description = (
    <>
        Please try again or contact{' '}
        <Link href='mailto:hi@trovatrip.com'>our support team</Link> for
        assistance.
    </>
);

interface FailureContentProps {
    onClickPrimary: () => void;
    onClickSecondary: () => void;
}

const FailureContent = ({
    onClickPrimary,
    onClickSecondary,
}: FailureContentProps): JSX.Element => {
    const { primaryButton, secondaryButton, header } = responseFailureCopy;
    const { text: primaryText, icon: primaryIcon } = primaryButton;
    const { text: secondaryText } = secondaryButton;

    return (
        <>
            <Dialog.Body>
                <Grid gap={6}>
                    <Grid.Item
                        columnSpan={12}
                        justifySelf='center'
                        width={{ base: 52, md: 72 }}
                        height={{ base: 56, md: 80 }}
                    >
                        <Image
                            src={exportingDataImageError}
                            alt=''
                            width={{ base: 52, md: 72 }}
                            height={{ base: 56, md: 80 }}
                            marginTop={4}
                            marginBottom={6}
                            data-gumlet='false'
                        />
                    </Grid.Item>
                    <Grid.Item columnSpan={12}>
                        <Heading as='h5' paddingY={14}>
                            {header}
                        </Heading>
                        <Text as='p'>{description}</Text>
                    </Grid.Item>
                </Grid>
            </Dialog.Body>
            <Dialog.Footer>
                <Stack
                    justify={{ base: 'space-around', md: 'flex-end' }}
                    align='flex-end'
                    width='full'
                    paddingTop={{ base: 4, md: 0 }}
                    wrap='nowrap'
                    spacing={4}
                >
                    <Button
                        paddingX={{ base: 12, md: 4 }}
                        paddingY={2}
                        size='md'
                        variant='secondary'
                        onClick={onClickSecondary}
                    >
                        {secondaryText}
                    </Button>
                    <Button
                        paddingX={{ base: 8, md: 4 }}
                        paddingY={2}
                        rightIcon={primaryIcon}
                        onClick={onClickPrimary}
                    >
                        {primaryText}
                    </Button>
                </Stack>
            </Dialog.Footer>
        </>
    );
};

export default FailureContent;
