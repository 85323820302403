import React from 'react';
import CardSection from '../../common/CardSection';
import CurrenciesDropdown from '../../../../components/Dropdown/CurrenciesDropdown';
import { FormGroup, Stack } from '@trova-trip/trova-components';
import { NumberInput } from '@trova-trip/trova-components/build/next';
import { FullWidthFormGroup } from '../../ReviewPricing.components';
import FormSaver from '../../../../../../util/form/FormSaver';

type CurrencyInputsProps = {
    formSaver: FormSaver;
    onChange: (event: any, name: string, value?: number) => void;
};

const CurrencyInputs = (props: CurrencyInputsProps): JSX.Element => {
    const { formSaver, onChange } = props;

    return (
        <CardSection width='full' title='Currency'>
            <Stack
                spacing={{ xs: 4, md: 6 }}
                direction={{ xs: 'column', md: 'row' }}
                wrap='nowrap'
            >
                <FullWidthFormGroup label='Currency'>
                    <CurrenciesDropdown
                        onChange={onChange}
                        name='currency'
                        value={formSaver.getData('currency')}
                    />
                </FullWidthFormGroup>
                <FormGroup label='YoY % increase'>
                    <NumberInput
                        name='yearOverYearIncrease'
                        showButtons
                        size='lg'
                        maxWidth='44'
                        value={formSaver.getData('yearOverYearIncrease') || 0}
                        onChange={(event): void =>
                            onChange(
                                event,
                                event.metadata.name,
                                event.metadata.value,
                            )
                        }
                    />
                </FormGroup>
            </Stack>
        </CardSection>
    );
};

export default CurrencyInputs;
