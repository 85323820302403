import { constants, models } from '@trova-trip/trova-models';
import isArray from 'lodash/isArray';

const { ServiceType, ServiceTiming } = constants.services;

type Trip = models.trips.Trip;
type HostSelectedOptionalServices = models.trips.HostSelectedOptionalServices;

type ServiceTypeName = typeof ServiceType[keyof typeof ServiceType];
type ServiceTimingName = typeof ServiceTiming[keyof typeof ServiceTiming];

// This overrides adds the missing `type` and `timing` properties to the
// `activity` object that comes from the server but is not on the original type.
type HostSelectedOptionalService = HostSelectedOptionalServices & {
    activity: HostSelectedOptionalServices['activity'] & {
        type: ServiceTypeName;
        timing?: ServiceTimingName;
    };
};

type HostAccommodationsSelectionStatus = {
    hasPreAccommodation: boolean;
    hasPostAccommodation: boolean;
};

/**
 * Verifies whether the host of the provided trip has chosen pre and/or post-accommodations
 * on the trip request.
 *
 * @param trip
 * @returns An object indicating the host's accommodation selection status.
 */
const checkHostAccommodationsSelection = (
    trip: Trip,
): HostAccommodationsSelectionStatus => {
    // Type casting is required due to the conversion of the map object into an
    // array. This serialization is done in the file called mapsSerialization.js
    // and it overrides the original type that comes from Core.
    const hostSelectedServices =
        trip.hostSelectedOptionalServices as unknown as HostSelectedOptionalService[];

    if (!isArray(hostSelectedServices)) {
        return {
            hasPreAccommodation: false,
            hasPostAccommodation: false,
        };
    }

    const accommodationMap = hostSelectedServices?.reduce(
        (map, { activity }) => {
            const { type, timing } = activity;
            if (type === ServiceType.ACCOMMODATION && timing) {
                map[timing]?.push(activity);
            }
            return map;
        },
        { [ServiceTiming.PRE_TRIP]: [], [ServiceTiming.POST_TRIP]: [] },
    );

    const hasPre = accommodationMap[ServiceTiming.PRE_TRIP].length > 0;
    const hasPost = accommodationMap[ServiceTiming.POST_TRIP].length > 0;

    return {
        hasPreAccommodation: hasPre,
        hasPostAccommodation: hasPost,
    };
};

export { checkHostAccommodationsSelection };
