import {
    Stack,
    Divider,
    Heading,
    Badge,
    BaseBox,
    Alert,
    AlertProps,
} from '@trova-trip/trova-components';
import {
    Button,
    IconProps,
    Icon,
} from '@trova-trip/trova-components/build/next';
import { noop } from 'lodash';
import {
    socialLabels,
    socialMediaIcons,
} from '../../../../components/SocialMediaAccounts';
import {
    PhylloStatus,
    UsePhylloReturn,
} from '../../hooks/usePhyllo';
import { isFailedToFetchPhylloUserDataError } from '../../utils';
import { PhylloErrorKeys } from '../../../../../common/types/phyllo';

interface SocialConnectProps {
    connectedAccounts: UsePhylloReturn['connectedAccounts'];
    onConnect: (social: keyof typeof socialLabels) => void;
    statusByPlatform: UsePhylloReturn['statusByPlatform'];
    error: UsePhylloReturn['error'];
}

interface SocialConnectItemProps {
    icon: IconProps['as'];
    label: string;
    onConnect: () => void;
    isConnected?: boolean;
    isLoading?: boolean;
    isDisabled?: boolean;
}

export const socialConnectChannels = Object.keys(socialLabels) as Array<
    keyof typeof socialLabels
>;

const socialChannelViewModels = socialConnectChannels.map((social) => ({
    key: social,
    icon: socialMediaIcons[social],
    label: socialLabels[social],
}));

const loadingStatuses: PhylloStatus[] = ['in-process', 'initializing-sdk'];

const errorInfo: Record<
    PhylloErrorKeys,
    Pick<AlertProps, 'description' | 'title'>
> = {
    [PhylloErrorKeys.FAILED_ACCOUNT_CONNECT]: {
        title: 'Error connecting your account',
        description: 'Please try again or connect manually',
    },
    [PhylloErrorKeys.FAILED_INITIALIZE_SDK]: {
        title: 'Error initializing our social connection platform',
        description: 'Please try again or connect manually',
    },
    [PhylloErrorKeys.FAILED_USER_DATA]: {
        title: 'Error initializing our social connection platform',
        description: 'Please try again or connect manually',
    },
};

const SocialConnectItem = ({
    icon,
    label,
    onConnect,
    isConnected,
    isLoading,
    isDisabled,
}: SocialConnectItemProps): JSX.Element => (
    <Stack
        width='full'
        direction='row'
        align='center'
        justify='space-between'
        paddingY={3}
    >
        <Stack align='center' spacing={2}>
            <Icon color='teal.trova' size='lg' display='flex' as={icon} />
            <Heading lineHeight='base' fontWeight='regular' fontSize='lg'>
                {label}
            </Heading>
        </Stack>
        {isConnected ? (
            <BaseBox paddingY={2}>
                <Badge colorScheme='teal' variant='rounded'>
                    Connected
                </Badge>
            </BaseBox>
        ) : (
            <Button
                size='md'
                onClick={onConnect}
                isLoading={isLoading}
                isDisabled={isDisabled}
                variant='secondary'
            >
                Connect
            </Button>
        )}
    </Stack>
);

const SocialConnect = ({
    connectedAccounts,
    onConnect,
    statusByPlatform,
    error,
}: SocialConnectProps): JSX.Element => {
    const lastIndex = socialChannelViewModels.length - 1;

    const getConnectDisabledStatus = (
        key: keyof typeof socialLabels,
    ): boolean => {
        if (isFailedToFetchPhylloUserDataError(error)) {
            return true;
        }
        const hasLoadingSocials = Object.values(statusByPlatform).some(
            (status) => loadingStatuses.includes(status),
        );
        return (
            hasLoadingSocials &&
            !loadingStatuses.includes(statusByPlatform[key])
        );
    };

    return (
        <>
            <Stack width='full' direction='column' spacing={0} align='stretch'>
                {socialChannelViewModels.map(({ key, icon, label }, index) => {
                    const itemProps = {
                        isConnected: Object.keys(connectedAccounts).some(
                            (account: keyof typeof socialLabels) =>
                                account === key,
                        ),
                        isLoading: loadingStatuses.includes(
                            statusByPlatform[key],
                        ),
                        isDisabled: getConnectDisabledStatus(key),
                    };
                    return (
                        <>
                            <SocialConnectItem
                                key={key}
                                icon={icon}
                                label={label}
                                onConnect={(): void => onConnect(key)}
                                {...itemProps}
                            />
                            {index !== lastIndex ? <Divider /> : null}
                        </>
                    );
                })}
            </Stack>
            {error ? (
                <Alert
                    title={errorInfo[error].title}
                    description={errorInfo[error].description}
                    status='error'
                    isClosable={false}
                    onCloseAlert={noop}
                    marginTop={3}
                    marginBottom={5}
                    variant='inline'
                    size='md'
                />
            ) : null}
        </>
    );
};

export default SocialConnect;
