import { useState } from 'react';
import { useSelector } from '../../../../state/hooks';
import {
    Avatar,
    BaseBox,
    Heading,
    Stack,
    Text,
    useWebShare,
} from '@trova-trip/trova-components';
import { Dialog, DialogProps } from '@trova-trip/trova-components/build/next';
import { User } from '../../../types';
import { HostResurveyStatuses } from '../../../../config/constants';
import CopyTextButton from '../../../../components/CopyTextButton/CopyTextButton';
import useIsLargeScreen from '../../../common/hooks/useIsLargeScreen';
import usePublicProfileAnalytics, {
    PUBLIC_PROFILE_TRACKING_EVENTS,
    PUBLIC_PROFILE_DIALOG_LOCATION,
} from '../../hooks/usePublicProfileAnalytics';
import ShareOptionCard from './ShareOptionCard';
import focusOnTripsImage from '../../../../assets/img/focus-on-trips.svg';
import focusOnSurveyImage from '../../../../assets/img/focus-on-survey.svg';

interface SharePublicHostProfileDialogProps {
    link: string;
    message: string;
    isOpen: DialogProps['isOpen'];
    location?: PUBLIC_PROFILE_DIALOG_LOCATION;
    onClose: () => void;
}

enum LayoutVariants {
    focusOnTrips = 'focusOnTrips',
    focusOnSurvey = 'focusOnSurvey',
}

export interface LinkShareOption {
    id: LayoutVariants;
    heading: string;
    description: string;
    image: any;
}

const OPTION_MAP = {
    [LayoutVariants.focusOnTrips]: {
        param: '',
        trackingType: 'standard',
        image: focusOnTripsImage,
    },
    [LayoutVariants.focusOnSurvey]: {
        param: '?variant=survey',
        trackingType: 'resurvey',
        image: focusOnSurveyImage,
    },
};

const SharePublicHostProfileDialog = (
    props: SharePublicHostProfileDialogProps,
) => {
    const { link, message, isOpen, onClose, location } = props;
    const [selectedLinkOption, setSelectedLinkOption] =
        useState<LayoutVariants>(LayoutVariants.focusOnTrips);

    const isLargeScreen = useIsLargeScreen({ includeTabletResolution: true });
    const { share, canShare } = useWebShare();
    const webShareEnabled =
        !isLargeScreen &&
        canShare({
            title: 'Host Profile',
            text: link,
        });
    const { trackPublicProfileEvent } = usePublicProfileAnalytics();
    const { displayName, firstName, lastName, profilePictureUrl, status } =
        useSelector((state) => {
            return state.profile.current as User;
        });

    const hostName =
        displayName?.trim() || `${firstName?.trim()} ${lastName?.trim()}`;
    const isResurvey = HostResurveyStatuses.has(status);

    const linkShareOptions: LinkShareOption[] = [
        {
            id: LayoutVariants.focusOnTrips,
            heading: 'Share Profile',
            description: isLargeScreen
                ? 'Best for promoting upcoming trips and highlighting traveler reviews.'
                : 'Promote Upcoming Trips',
            image: OPTION_MAP[LayoutVariants.focusOnTrips].image,
        },
        {
            id: LayoutVariants.focusOnSurvey,
            heading: 'Resurvey Your Audience',
            description: isLargeScreen
                ? 'Best for collecting more survey responses to help you plan new trips.'
                : 'Survey is pinned on profile page.',
            image: OPTION_MAP[LayoutVariants.focusOnSurvey].image,
        },
    ];

    const trackLinkShareEvent = () => {
        const linkType = OPTION_MAP[selectedLinkOption].trackingType;

        trackPublicProfileEvent(
            PUBLIC_PROFILE_TRACKING_EVENTS.copyProfileLink,
            {
                location,
                type: linkType,
            },
        );
    };

    const onCopyButtonClicked = () => {
        trackLinkShareEvent();
        onClose();
    };

    const selectShareOption = (option: LinkShareOption) => {
        setSelectedLinkOption(option.id);

        if (webShareEnabled) {
            trackLinkShareEvent();
            share({
                title: `${hostName} - Host Profile`,
                url: link + OPTION_MAP[selectedLinkOption].param,
            });

            onClose();
        }
    };

    return (
        <Dialog isOpen={isOpen} onClose={onClose}>
            <Dialog.Header title='' />
            <Dialog.Body width='full'>
                <Stack
                    justify='center'
                    align='center'
                    direction='column'
                    spacing={0}
                    paddingBottom={5}
                >
                    <Avatar
                        name={hostName}
                        src={profilePictureUrl}
                        size={{ base: 'lg', md: 'xl' }}
                    />
                    <Heading
                        as={isLargeScreen ? 'h5' : 'h6'}
                        textAlign='center'
                        paddingY={2}
                    >
                        Share your public profile
                    </Heading>
                    <Text as='p' textAlign='center'>
                        {message}
                    </Text>
                    {isResurvey ? (
                        <Stack
                            direction='column'
                            spacing={{ base: 2, md: 4 }}
                            width='full'
                            paddingTop={{ base: 5, md: 7 }}
                        >
                            {linkShareOptions.map((option) => (
                                <ShareOptionCard
                                    key={option.id}
                                    shareOption={option}
                                    isSelected={
                                        webShareEnabled
                                            ? false
                                            : option.id === selectedLinkOption
                                    }
                                    onSelect={() => selectShareOption(option)}
                                />
                            ))}
                        </Stack>
                    ) : null}
                    <BaseBox
                        alignSelf={isResurvey ? 'end' : 'center'}
                        paddingTop={5}
                    >
                        {!webShareEnabled ? (
                            <CopyTextButton
                                onClick={onCopyButtonClicked}
                                textToCopy={
                                    link + OPTION_MAP[selectedLinkOption].param
                                }
                                onCopyTitle='Link Copied'
                                onCopyMessage='The profile link was successfully copied'
                            >
                                Copy Public Profile Link
                            </CopyTextButton>
                        ) : null}
                    </BaseBox>
                </Stack>
            </Dialog.Body>
        </Dialog>
    );
};

export default SharePublicHostProfileDialog;
