import { Field } from '@trova-trip/trova-components';
import { SelectableOptionCard } from '@trova-trip/trova-components/build/custom';
import { IconProps } from '@trova-trip/trova-components/build/next';
import { OccupancyType } from '../../../../types';

export type BaseRoomOptionItem = { icon?: IconProps['as']; text: string };

export const priceIncludedRoomOptionItem: BaseRoomOptionItem = {
    icon: 'attachMoney',
    text: 'Included in trip price',
};

export type BaseRoomOptionFieldProps = {
    amount?: number;
    items: BaseRoomOptionItem[];
    title: string;
    name: string;
    value: boolean;
    type: OccupancyType;
};

const BaseRoomOptionField = (props: BaseRoomOptionFieldProps): JSX.Element => {
    const { title, items, amount = 0, value, name, type } = props;

    return (
        <Field as='custom' name={name}>
            {({ field }) => (
                <SelectableOptionCard
                    title={title}
                    rightAddOn={{
                        as: 'price',
                        price: amount,
                    }}
                    body={{
                        as: 'list',
                        items: items.map(({ icon, text }) => ({
                            bulletIcon: icon ? { as: icon } : undefined,
                            children: text,
                        })),
                    }}
                    isSelected={value}
                    onClick={(event) =>
                        field.onChange({
                            ...(event as React.MouseEvent<HTMLInputElement>),
                            metadata: {
                                name: field.name,
                                value: type,
                            },
                        })
                    }
                />
            )}
        </Field>
    );
};

export default BaseRoomOptionField;
