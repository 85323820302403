import { ReactNode } from 'react';
import {
    BaseBox,
    Image,
    Stack,
    StackProps,
} from '@trova-trip/trova-components';

type RotationProps = {
    rotation: string;
    zIndex?: string;
    children?: ReactNode;
};

type OutlineProps = {
    rotation: string;
    dimensions?: {
        width: string;
        height: string;
    };
    offset: {
        top: string;
        left?: string;
        right?: string;
    };
};

interface OutlinedImageConfig {
    filename: string;
    alt: string;
    imageDimensions: {
        width: string;
        height: string;
    };
    rotationProps: RotationProps;
    outlineProps: OutlineProps;
}

export type ImageLayout = OutlinedImageConfig[];

type ImageGroupProps = Pick<
    StackProps,
    'justify' | 'align' | 'spacing' | 'wrap'
> & {
    imageLayout: ImageLayout;
};

const GUMLET_PATH = '/public/static/media/';

const RotationContainer = ({
    rotation,
    zIndex,
    children,
}: RotationProps): JSX.Element => (
    <BaseBox
        position='relative'
        zIndex={zIndex}
        css={{
            transform: `rotate(${rotation}deg)`,
        }}
    >
        {children}
    </BaseBox>
);

const ImageOutline = ({
    rotation,
    dimensions,
    offset,
}: OutlineProps): JSX.Element => (
    <BaseBox
        position='absolute'
        top={offset.top}
        left={offset.left || 'initial'}
        right={offset.right || 'initial'}
        width={dimensions?.width || '90%'}
        height={dimensions?.height || '90%'}
        borderRadius='xl'
        border='1px'
        borderColor='teal.trova'
        zIndex='-1'
        css={{
            transform: `rotate(${rotation}deg)`,
        }}
    />
);

const ImageGroup = ({
    imageLayout,
    justify = 'center',
    align = 'center',
    spacing,
    wrap = 'nowrap',
}: ImageGroupProps): JSX.Element => (
    <Stack justify={justify} align={align} spacing={spacing} wrap={wrap}>
        {imageLayout.map((layout: OutlinedImageConfig) => (
            <RotationContainer
                key={layout.filename}
                rotation={layout.rotationProps.rotation}
                zIndex={layout.rotationProps.zIndex}
            >
                <Image
                    src={`${GUMLET_PATH}${layout.filename}`}
                    alt={layout.alt}
                    width={layout.imageDimensions.width}
                    height={layout.imageDimensions.height}
                    borderRadius='xl'
                    fit='cover'
                />
                <ImageOutline
                    rotation={layout.outlineProps.rotation}
                    offset={layout.outlineProps.offset}
                    dimensions={layout.outlineProps.dimensions}
                />
            </RotationContainer>
        ))}
    </Stack>
);

export default ImageGroup;
