import { Heading, Image, Stack, Text } from '@trova-trip/trova-components';
import { Icon, SelectableCard } from '@trova-trip/trova-components/build/next';
import useIsLargeScreen from '../../../common/hooks/useIsLargeScreen';
import { LinkShareOption } from './SharePublicHostProfileDialog';

interface ShareOptionCardProps {
    shareOption: LinkShareOption;
    isSelected: boolean;
    onSelect: (id: LinkShareOption['id']) => void;
}

const ShareOptionCard = ({
    shareOption,
    isSelected,
    onSelect,
}: ShareOptionCardProps): JSX.Element => {
    const { id, heading, description, image } = shareOption;
    const isLargeScreen = useIsLargeScreen({ includeTabletResolution: true });

    return (
        <SelectableCard
            spacing={{ base: 'none', md: 'sm' }}
            isSelected={isSelected}
            onClick={() => {
                onSelect(id);
            }}
            width='full'
        >
            <Stack
                wrap='nowrap'
                padding={2}
                paddingRight={{ base: 2, md: 3 }}
                align='center'
                spacing={{ base: 0, md: 2 }}
            >
                <Image
                    src={image}
                    marginRight={2}
                    height={{ base: '52px', md: 'auto' }}
                    data-gumlet={false}
                />
                <Stack direction='column' spacing={0} flexGrow={1}>
                    <Heading as='h6' fontWeight='medium'>
                        {heading}
                    </Heading>
                    <Text fontSize='sm'>{description}</Text>
                </Stack>
                {!isLargeScreen ? (
                    <Icon as='send' color='neutral.white' size='lg' />
                ) : null}
            </Stack>
        </SelectableCard>
    );
};

export default ShareOptionCard;
