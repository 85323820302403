import * as yup from 'yup';
import { constants } from '@trova-trip/trova-models';
import { SelectedPackages } from './ItineraryPackage.types';
import { BaseComboBoxOption } from '@trova-trip/trova-components';

const { PackageLevel, PackageAccommodationLevel } = constants.itinerary;

export const getDefaultPackageName = (selectedPackages: SelectedPackages) => {
    const hiddenPackages = Object.keys(selectedPackages || []).filter(
        (elem) => selectedPackages[elem].enabled && elem,
    );
    const defaultPackageSelected = hiddenPackages.length === 1;

    if (defaultPackageSelected) {
        return hiddenPackages[0];
    }

    return false;
};

export const getItineraryPackagesSchema = () => {
    const packagesSchema = {};

    Object.keys(PackageLevel).forEach((elem) => {
        const packageType = elem.toLowerCase();
        packagesSchema[packageType] = yup.object().shape({
            enabled: yup.boolean(),
            accommodationLevels: yup.array().when('enabled', {
                is: true,
                then: yup
                    .array()
                    .required()
                    .min(
                        1,
                        `Accommodation level for ${packageType} package is required`,
                    )
                    .of(yup.string()),
            }),
            mealLevels: yup.array().when('enabled', {
                is: true,
                then: yup
                    .array()
                    .required()
                    .min(1, `Meal level for ${packageType} package is required`)
                    .of(yup.string()),
            }),
            foodOptions: yup.array(yup.string()),
        });
    });

    return packagesSchema;
};

export const formatPackageLevelText = (
    packageAccommodationLevel: string,
    text: string = 'stars',
) =>
    packageAccommodationLevel !== PackageAccommodationLevel.NA
        ? `${packageAccommodationLevel} ${text}`
        : packageAccommodationLevel;

export const setComboBoxOption = (options: Object): BaseComboBoxOption[] => {
    return Object.values(options).map((option) => ({
        label: option,
        value: option,
    }));
};
