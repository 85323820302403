import moment from 'moment';
import {
    Booking,
    BookingValidStatuses,
    CancellationDetails,
    SavedBooking,
} from '../interfaces/Booking.types';
import { TripValidStatuses } from '../interfaces/Trips.types';
import {
    TripStatuses,
    BookingStatuses,
    BookingStatusesColors,
} from '../config/constants';
import isEmpty from 'lodash/isEmpty';
import { models } from '@trova-trip/trova-models';
import { coreUtils } from '@trova-trip/trova-common';

const { getBookingStatus } = coreUtils.bookingUtils;

const DAYS_TO_DUE = 60;
interface CheckValidStatusesPropsType {
    tripStatus: TripValidStatuses;
    bookingStatus: BookingValidStatuses;
    validStatuses: string[];
}
const checkValidStatuses = ({
    tripStatus,
    bookingStatus,
    validStatuses,
}: CheckValidStatusesPropsType): boolean => {
    return (
        tripStatus !== TripStatuses.COMPLETE &&
        validStatuses.some((validStatus) => validStatus === bookingStatus)
    );
};

export const getBalanceDueDateFromTripStartDate = (startDate: Date): string =>
    `Due: ${
        startDate &&
        moment(startDate).subtract(DAYS_TO_DUE, 'days').format('MMMM DD, YYYY')
    }`;

export const getSpotsAmountFromBooking = (booking?: Booking): number => {
    if (!booking) {
        return 0;
    }
    return 1 + booking.additionalParticipants.length;
};

export const isAvailableBookingAddonsDetails = (
    tripStatus: TripValidStatuses,
    bookingStatus: BookingValidStatuses,
): boolean => {
    return checkValidStatuses({
        tripStatus: tripStatus,
        bookingStatus: bookingStatus,
        validStatuses: [
            BookingStatuses.CONFIRMED,
            BookingStatuses.PENDING,
            BookingStatuses.AWAITING,
            BookingStatuses.WAITLIST,
        ],
    });
};

export const isAvailableBookingBalanceDetails = (
    tripStatus: TripValidStatuses,
    bookingStatus: BookingValidStatuses,
): boolean => {
    return checkValidStatuses({
        tripStatus: tripStatus,
        bookingStatus: bookingStatus,
        validStatuses: [
            BookingStatuses.CONFIRMED,
            BookingStatuses.PENDING,
            BookingStatuses.AWAITING,
        ],
    });
};

export const isAvailableBookingSpotsDetails = (
    tripStatus: TripValidStatuses,
    bookingStatus: BookingValidStatuses,
): boolean => {
    return checkValidStatuses({
        tripStatus: tripStatus,
        bookingStatus: bookingStatus,
        validStatuses: [
            BookingStatuses.CONFIRMED,
            BookingStatuses.PENDING,
            BookingStatuses.AWAITING,
            BookingStatuses.WAITLIST,
        ],
    });
};

export const isAvailableBookingPaymentsHistory = (
    tripStatus: TripValidStatuses,
    bookingStatus: BookingValidStatuses,
): boolean => {
    return checkValidStatuses({
        tripStatus: tripStatus,
        bookingStatus: bookingStatus,
        validStatuses: [
            BookingStatuses.CONFIRMED,
            BookingStatuses.PENDING,
            BookingStatuses.AWAITING,
            BookingStatuses.CANCELLED,
        ],
    });
};

export const isAvailableBookingCancelButton = (
    tripStatus: TripValidStatuses,
    booking: SavedBooking,
    isPrimaryTraveler: boolean,
): boolean => {
    const bookingStatus = booking?.status;

    const areValidTripAndBookingStatuses = checkValidStatuses({
        tripStatus: tripStatus,
        bookingStatus: bookingStatus,
        validStatuses: [
            BookingStatuses.CONFIRMED,
            BookingStatuses.PENDING,
            BookingStatuses.AWAITING,
            BookingStatuses.WAITLIST,
        ],
    });

    const isBookingCancelled =
        getBookingStatus(booking) === BookingStatuses.CANCELLED;

    const isAvailableBookingCancelButton =
        areValidTripAndBookingStatuses &&
        isPrimaryTraveler &&
        !isBookingCancelled;

    return isAvailableBookingCancelButton;
};

export const isPaymentRefundable = (booking: Booking): boolean => {
    return booking.status !== BookingStatuses.CANCELLED;
};

export const isBookingCancelledByTraveler = (
    bookingCancellationDetails: CancellationDetails,
): boolean => !isEmpty(bookingCancellationDetails.refundMethodDescription);

export const filterBookingOrdersByStatus = (
    ordersList: models.tripDetails.BookingOrder[],
    statusSet: Set<string>,
): models.tripDetails.BookingOrder[] => {
    return ordersList?.filter((order) => statusSet.has(order.status || ''));
};

export const getServiceIndexById = (
    id: string,
    services: Array<{ service: models.services.Service }>,
): number => {
    return services.findIndex((dayService) => dayService.service._id === id);
};

export type BookingStatus = keyof typeof BookingStatuses;

export const getBookingStatusColor = (status: BookingStatus): string => {
    switch (status) {
        case BookingStatuses.CONFIRMED:
            return BookingStatusesColors[BookingStatuses.CONFIRMED];
        case BookingStatuses.AWAITING:
            return BookingStatusesColors[BookingStatuses.AWAITING];
        case BookingStatuses.PENDING:
            return BookingStatusesColors[BookingStatuses.PENDING];
        default:
            return BookingStatusesColors[BookingStatuses.WAITLIST];
    }
};
