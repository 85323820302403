export const imageLayouts = [
    [
        {
            filename: 'total-audience-1-1.png',
            alt: '',
            imageDimensions: {
                width: '28',
                height: '40',
            },
            rotationProps: {
                rotation: '6.82',
            },
            outlineProps: {
                rotation: '2.07',
                offset: {
                    top: '-2',
                    left: '-1',
                },
                dimensions: {
                    width: '95%',
                    height: '85%',
                },
            },
        },
        {
            filename: 'total-audience-1-2.png',
            alt: '',
            imageDimensions: {
                width: '32',
                height: '44',
            },
            rotationProps: {
                rotation: '-2.55',
                zIndex: '1',
            },
            outlineProps: {
                rotation: '-1.27',
                offset: {
                    top: '-2',
                    right: '-4',
                },
                dimensions: {
                    width: '95%',
                    height: '90%',
                },
            },
        },
        {
            filename: 'total-audience-1-3.png',
            alt: '',
            imageDimensions: {
                width: '28',
                height: '40',
            },
            rotationProps: {
                rotation: '4.43',
                zIndex: '2',
            },
            outlineProps: {
                rotation: '2.2',
                offset: {
                    top: '-3',
                    right: '-2',
                },
            },
        },
    ],
    [
        {
            filename: 'total-audience-2-1.png',
            alt: '',
            imageDimensions: {
                width: '28',
                height: '40',
            },
            rotationProps: {
                rotation: '2.88',
            },
            outlineProps: {
                rotation: '1.28',
                offset: {
                    top: '-2',
                    left: '-1',
                },
                dimensions: {
                    width: '100%',
                    height: '90%',
                },
            },
        },
        {
            filename: 'total-audience-2-2.png',
            alt: '',
            imageDimensions: {
                width: '32',
                height: '44',
            },
            rotationProps: {
                rotation: '-1.69',
                zIndex: '1',
            },
            outlineProps: {
                rotation: '1.68',
                offset: {
                    top: '-1',
                    right: '-1',
                },
            },
        },
        {
            filename: 'total-audience-2-3.png',
            alt: '',
            imageDimensions: {
                width: '28',
                height: '40',
            },
            rotationProps: {
                rotation: '-5.43',
                zIndex: '2',
            },
            outlineProps: {
                rotation: '1.15',
                offset: {
                    top: '-1',
                    right: '-1',
                },
            },
        },
    ],
    [
        {
            filename: 'total-audience-3-1.png',
            alt: '',
            imageDimensions: {
                width: '28',
                height: '40',
            },
            rotationProps: {
                rotation: '-5.33',
            },
            outlineProps: {
                rotation: '-1.74',
                offset: {
                    top: '-1',
                    left: '-1',
                },
                dimensions: {
                    width: '95%',
                    height: '88%',
                },
            },
        },
        {
            filename: 'total-audience-3-2.png',
            alt: '',
            imageDimensions: {
                width: '32',
                height: '44',
            },
            rotationProps: {
                rotation: '3.88',
                zIndex: '1',
            },
            outlineProps: {
                rotation: '1.57',
                offset: {
                    top: '2',
                    left: '4',
                },
                dimensions: {
                    width: '90%',
                    height: '92%',
                },
            },
        },
        {
            filename: 'total-audience-3-3.png',
            alt: '',
            imageDimensions: {
                width: '28',
                height: '40',
            },
            rotationProps: {
                rotation: '5.59',
                zIndex: '2',
            },
            outlineProps: {
                rotation: '-2.77',
                offset: {
                    top: '-1',
                    right: '-1',
                },
            },
        },
    ],
];
