import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { dailyButton, tripDaysDiv, stickyContainer } from './TripDays.styles';
import Scrollspy from 'react-scrollspy';

import MultilineButton from '../MultilineButton';

const TripDays = ({
    servicesByDay,
    dayItemIds,
    scrollToDay,
    tripDates,
    cleanErrors,
}) => {
    const [daySelected, setDaySelected] = useState(0);

    useEffect(() => {
        return () => cleanErrors();
    }, []);

    const highlightDay = (event) => {
        if (event) {
            const { value } = event.attributes.id;
            const dayToSelect = Number(value.split(`-`)[2]);
            setDaySelected(dayToSelect);
        }
    };

    return (
        <Scrollspy
            items={dayItemIds}
            onUpdate={highlightDay}
            className={stickyContainer()}
        >
            <div className={tripDaysDiv()}>
                {servicesByDay &&
                    servicesByDay.map((service, index) => {
                        // using index as key as days and day length are not
                        // mutable
                        const key = index;
                        return (
                            <div key={key} className={dailyButton()}>
                                <MultilineButton
                                    onClick={() => {
                                        scrollToDay(index);
                                        setDaySelected(index);
                                    }}
                                    subtext={tripDates[index]}
                                    isFullWidth={true}
                                    isSelected={index === daySelected}
                                >
                                    Day {index + 1}
                                </MultilineButton>
                            </div>
                        );
                    })}
            </div>
        </Scrollspy>
    );
};

TripDays.propTypes = {
    servicesByDay: PropTypes.array,
    tripDates: PropTypes.arrayOf(PropTypes.string),
    dayItemIds: PropTypes.arrayOf(PropTypes.string),
    scrollToDay: PropTypes.func,
};
export default TripDays;
