import { ComponentWidth } from '@trova-trip/trova-components';
import { constants } from '@trova-trip/trova-models';
import SimpleAutoCompleteDropdown, {
    SimpleAutoCompleteDropdownPropsType,
} from './SimpleAutoCompleteDropdown';

interface GenderDropdownProps
    extends Omit<SimpleAutoCompleteDropdownPropsType, 'label' | 'size'> {
    label?: string;
    size?: ComponentWidth;
}

const GendersEnum = constants.user.Gender;

const genders = Object.values(GendersEnum).reduce((options, gender) => {
    options[gender] = gender;
    return options;
}, {});

const GenderDropdown = (props: GenderDropdownProps): JSX.Element => {
    const { label = 'Gender', size = ComponentWidth.Flexible, ...rest } = props;

    return (
        <SimpleAutoCompleteDropdown label={label} size={size} {...rest}>
            {genders}
        </SimpleAutoCompleteDropdown>
    );
};

export default GenderDropdown;
