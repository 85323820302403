import { Button } from '@trova-trip/trova-components/build/next';
import useTripRoute, {
    TRIP_ACTION,
} from 'applications/common/hooks/trips/useTripRoute';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { TripStoreSelector } from 'state/trips';

interface GoBackButtonProps {
    arrowDirection?: 'left' | 'right';
    route?: string;
}

const GoBackButton = ({
    route,
    arrowDirection = 'left',
}: GoBackButtonProps): JSX.Element => {
    const trip = useSelector(
        ({ userTrips }: TripStoreSelector) => userTrips.current,
    );
    const history = useHistory();

    const defaultRoute = useTripRoute({
        id: trip.id,
        action: TRIP_ACTION.CONFIGURATION,
        preserveQueryParams: true,
    });

    const handleClick = (): void => {
        history.push(route || defaultRoute);
    };

    const isArrowDirectionRight = arrowDirection === 'right';

    return (
        <Button
            variant='tertiary'
            onClick={handleClick}
            rightIcon={isArrowDirectionRight ? 'arrow-right' : undefined}
            leftIcon={!isArrowDirectionRight ? 'arrow-left' : undefined}
            color='blueGray.dark'
        >
            Host Portal
        </Button>
    );
};

export default GoBackButton;
