import React from 'react';
import {
    Modal,
    ModalProps,
    Grid,
    Image,
    Heading,
    Text,
    BaseBox,
    Stack,
    Checklist,
} from '@trova-trip/trova-components';
import { constants, models } from '@trova-trip/trova-models';

import { Card } from '@trova-trip/trova-components/build/next';
import { useHistory } from 'react-router-dom';
import {
    SuccessDialogTypes,
    tripRequestDialogContent,
} from './tripRequestDialogContent';
import { useSelector } from '../../../../../../state/hooks';
import { ButtonWithAnalytics } from '../../../../../../components/ButtonWithAnalytics';

// Implemented this way because currently we have 2 different interfaces for the TripRequest model.
// We should merge both into in a future task one and remove this.
type TripRequest = models.tripRequest.BaseTripRequest & {
    host: models.tripRequest.TripRequest['host'];
};

export interface TripRequestSuccessDialogProps {
    isOpen: ModalProps['isOpen'];
    tripRequest?: TripRequest;
}

interface UseTripRequestSuccessDialogInfoReturn {
    isInstantApprovalTrip: boolean;
    hostName: string;
    tripId: TripRequest['trip'];
}

const { tripRequestStatuses } = constants.tripRequest;

const instantApprovalTripStatuses: Array<TripRequest['status']> = [
    tripRequestStatuses.ACCEPTED,
];

const useTripRequestSuccessDialogInfo = (
    currentTripRequest?: TripRequest,
): UseTripRequestSuccessDialogInfoReturn => {
    const {
        host,
        status,
        instantlyApproved,
        trovaAutoPriced,
        trip: tripId,
    } = currentTripRequest || {};

    const hostName = host?.displayName || host?.firstName || '';

    const isInstantApprovalTrip = !!(
        instantlyApproved &&
        trovaAutoPriced &&
        status &&
        instantApprovalTripStatuses.includes(status)
    );

    return {
        isInstantApprovalTrip,
        hostName,
        tripId,
    };
};

const TripRequestSuccessDialog = ({
    isOpen,
    tripRequest,
}: TripRequestSuccessDialogProps): JSX.Element => {
    const { isInstantApprovalTrip, tripId, hostName } =
        useTripRequestSuccessDialogInfo(tripRequest);

    const { tripRequestId, itineraryId } = useSelector((state) => ({
        tripRequestId: state.userTripRequests.current?.id,
        itineraryId: state.userItinerary.current?.id,
    }));
    const history = useHistory();
    const dialogInfo = isInstantApprovalTrip
        ? tripRequestDialogContent[SuccessDialogTypes.INSTANT_APPROVAL]
        : tripRequestDialogContent[SuccessDialogTypes.TRIP_REQUEST];

    const {
        illustration,
        description,
        checkListSteps,
        buttonText,
        analyticsOnClickEventName,
        getRedirectUrl,
    } = dialogInfo;

    return (
        <Modal isOpen={isOpen} isCentered margin={0}>
            <Card
                width='100vw'
                maxWidth='container.lg'
                padding={0}
                height={{
                    base: '100vh',
                    md: 'auto',
                }}
            >
                <Stack
                    direction='column'
                    spacing={0}
                    wrap='nowrap'
                    height='full'
                    align='stretch'
                    justify='space-between'
                >
                    <Grid
                        gap={{ base: 4, md: 6 }}
                        paddingX={{ base: 4, md: 6 }}
                        paddingY={{ base: 5, md: 20 }}
                    >
                        <Grid.Item
                            columnSpan={{ base: 12, md: 6 }}
                            alignSelf='center'
                            justifySelf='center'
                        >
                            <Image data-gumlet='false' src={illustration} />
                        </Grid.Item>
                        <Grid.Item columnSpan={{ base: 12, md: 6 }}>
                            <Stack
                                direction='column'
                                spacing={{ base: 4, md: 6 }}
                                wrap='nowrap'
                            >
                                <Heading as='h2'>
                                    Congrats {hostName} 🎉
                                </Heading>
                                <Text>{description}</Text>
                                <BaseBox padding={4}>
                                    <Checklist items={checkListSteps} />
                                </BaseBox>
                            </Stack>
                        </Grid.Item>
                    </Grid>
                    <Stack
                        justify='end'
                        paddingX={{ base: 4, md: 6 }}
                        paddingY={{ base: 5, md: 4 }}
                    >
                        <ButtonWithAnalytics
                            rightIcon='arrow-right'
                            size='lg'
                            isFullWidth={{ base: true, md: false }}
                            onClick={(): void =>
                                history.push(getRedirectUrl(tripId))
                            }
                            analyticsData={{
                                eventName: analyticsOnClickEventName,
                                type: 'onClick',
                                properties: {
                                    itineraryId,
                                    requestId: tripRequestId,
                                    tripId,
                                },
                            }}
                        >
                            {buttonText}
                        </ButtonWithAnalytics>
                    </Stack>
                </Stack>
            </Card>
        </Modal>
    );
};

export default TripRequestSuccessDialog;
