import React, { useContext, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NavigationContext from './NavigationContext';
import TabBar from './components/TabBar';
import {
    useLoadNavigationData,
    useFetchModelData,
    usePathLabels,
} from './hooks';
import NavigationEventBuilder from './NavigationEvent';
import { NavigationEventType } from './NavigationEvent';
import { APPLICATION_ROUTES_METADATA } from '../config/constants';

const FetchModelIdRender = ({ fetchModelData, modelId, search }) => {
    const { setModelId } = useContext(NavigationContext);
    useFetchModelData(fetchModelData, modelId, search);
    useEffect(() => setModelId(modelId), [modelId]);
    return null;
};

const ProductContent = ({
    header,
    productPath,
    root,
    children,
    modelId,
    tabs,
    clearModelData,
    fetchModelData,
    search,
}) => {
    const routesWithoutTabBar = Object.keys(APPLICATION_ROUTES_METADATA).filter(
        (key) => !APPLICATION_ROUTES_METADATA[key].showTabBar,
    );
    const isCurrentRouteWithoutTabBar = !!useRouteMatch({
        path: routesWithoutTabBar,
        exact: true,
    });

    const showTabBar =
        modelId && !!tabs?.length && !isCurrentRouteWithoutTabBar;

    const handleNavigationEvent = useCallback(
        (event) => {
            switch (event.type) {
                case NavigationEventType.ROOT:
                    clearModelData?.();
                    break;
                case NavigationEventType.NEW:
                    clearModelData?.();
                    break;
                default:
                    break;
            }
        },
        [clearModelData],
    );

    return (
        <React.Fragment>
            {header}
            {showTabBar && (
                <TabBar
                    productPath={productPath}
                    tabs={tabs}
                    modelId={modelId}
                    queryString={search}
                />
            )}
            <Switch>
                <Route
                    path={productPath}
                    exact
                    render={(props) => {
                        if (handleNavigationEvent) {
                            handleNavigationEvent(
                                NavigationEventBuilder.root(
                                    props.location.pathname,
                                ),
                            );
                        }
                        return root;
                    }}
                />
                <Route
                    path={`${productPath}/new/*`}
                    exact
                    render={(props) => {
                        if (handleNavigationEvent) {
                            handleNavigationEvent(
                                NavigationEventBuilder.new(
                                    props.location.pathname,
                                ),
                            );
                        }
                        return null;
                    }}
                />
                <Route
                    path={`${productPath}/:id/*`}
                    render={(props) => {
                        const id = props?.match?.params?.id;
                        if (id) {
                            return (
                                <FetchModelIdRender
                                    fetchModelData={fetchModelData}
                                    modelId={id}
                                    search={search}
                                />
                            );
                        }
                        return null;
                    }}
                />
            </Switch>
            {children}
        </React.Fragment>
    );
};

ProductContent.propTypes = {
    productPath: PropTypes.string,
    root: PropTypes.element,
    header: PropTypes.element,
    label: PropTypes.string,
    children: PropTypes.node,
    modelId: PropTypes.string,
    tabs: PropTypes.array,
    search: PropTypes.string,
};

const Product = ({
    path,
    root,
    header,
    label,
    loadNavigationContent,
    icon,
    fetchModelData,
    clearModelData,
    children,
    hideNavigation = false,
}) => {
    const context = useContext(NavigationContext);
    const {
        addProduct,
        setNavigationContent,
        modelId,
        search,
        applicationPath,
        setSidebarComponent,
    } = context;

    const productPath = `${applicationPath}${path}`;

    useEffect(() => {
        if (!hideNavigation) {
            addProduct({ path: productPath, label, icon });
        }
    }, [productPath, label, addProduct, hideNavigation]);

    const [tabs, addTab, removeTab] = usePathLabels();

    const reloadNavigation = useLoadNavigationData(
        loadNavigationContent,
        setNavigationContent,
        productPath,
        tabs,
        setSidebarComponent,
    );

    const providerValue = useMemo(
        () => ({
            ...context,
            tabs,
            addTab,
            productPath,
            reloadNavigation,
            removeTab,
        }),
        [context, productPath, tabs, addTab, reloadNavigation, removeTab],
    );

    return (
        <NavigationContext.Provider value={providerValue}>
            <Route
                path={productPath}
                render={() => (
                    <ProductContent
                        root={root}
                        header={header}
                        productPath={productPath}
                        fetchModelData={fetchModelData}
                        clearModelData={clearModelData}
                        modelId={modelId}
                        tabs={tabs}
                        search={search}
                    >
                        {children}
                    </ProductContent>
                )}
            />
        </NavigationContext.Provider>
    );
};

Product.propTypes = {
    path: PropTypes.string,
    root: PropTypes.element,
    header: PropTypes.element,
    label: PropTypes.string,
    icon: PropTypes.string,
    loadNavigationContent: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.element,
    ]),
    fetchModelData: PropTypes.func,
    clearModelData: PropTypes.func,
    children: PropTypes.node,
    hideNavigation: PropTypes.bool,
};

export default Product;
