import { MouseEventHandler, MutableRefObject } from 'react';
import { IReactToPrintProps, useReactToPrint } from 'react-to-print';

interface PrintPageProps {
    ref: MutableRefObject<null>;
    documentTitle: string;
    onPrintError?: IReactToPrintProps['onPrintError'];
    onAfterPrint?: () => void;
}

export const usePrintPage = (
    props: PrintPageProps,
): MouseEventHandler<HTMLButtonElement> | undefined => {
    const { ref, documentTitle, onPrintError, onAfterPrint } = props;

    const printError: IReactToPrintProps['onPrintError'] = (
        errorLocation,
        error,
    ) => {
        onPrintError
            ? onPrintError(errorLocation, error)
            : console.error(errorLocation, error);
    };

    return useReactToPrint({
        content: () => ref.current,
        documentTitle,
        removeAfterPrint: true,
        onPrintError: printError,
        onAfterPrint: onAfterPrint,
    });
};
