import { Heading } from '@trova-trip/trova-components';

const CardTitleHeader = (props: { children: string }): JSX.Element => {
    const { children } = props;
    return (
        <Heading as='h5' fontWeight='medium'>
            {children}
        </Heading>
    );
};

export default CardTitleHeader;
