import { Grid } from '@trova-trip/trova-components';
import { Route } from 'react-router-dom';
import HomeDashboard from './dashboard/HomeDashboard';
import { buildHomeRoute } from './common/utils';
import HomeLandingPage from './progress/HomeLandingPage';

interface HomeContainerProps {
    showDashboard: boolean;
}

const HomeContainer = ({ showDashboard }: HomeContainerProps) => {
    const containerSize = showDashboard ? 'container.2xl' : 'container.lg';

    return (
        <>
            <Grid
                maxWidth={containerSize}
                width='full'
                marginX='auto'
                gap={{ base: 2, md: 2 }}
                marginTop={{ md: 6, lg: 4 }}
            >
                <Grid.Item columnSpan={12}>
                    <Route path={buildHomeRoute('DASHBOARD', true)}>
                        <HomeDashboard />
                    </Route>
                </Grid.Item>
                {!showDashboard && (
                    <Grid.Item padding={0} columnSpan={12}>
                        <Route path={buildHomeRoute('PROGRESS', true)}>
                            <HomeLandingPage />
                        </Route>
                    </Grid.Item>
                )}
            </Grid>
        </>
    );
};

export default HomeContainer;
