import { Grid } from '@trova-trip/trova-components';
import {
    HostAudienceData,
    WidgetCallbacks,
    WidgetNames,
} from '../../../common/types';
import { buildHostProductRoute } from '../../../common/utils';
import { useEffect } from 'react';
import useHomeWidgetData from '../../../dashboard/hooks/useHomeWidgetData';
import WidgetLoadingFallback from '../../../dashboard/components/WidgetLoadingFallback/WidgetLoadingFallback';
import WidgetCard from '../../../dashboard/components/common/WidgetCard';
import HostDashGettingStartedAudienceWidget from './HostDashGettingStartedAudienceWidget';
import AudienceContentWidget from './AudienceContentWidget';
import CardHeadingWidget from './CardHeadingWidget';

interface AudienceProps extends WidgetCallbacks {}

const Audience = (props: AudienceProps) => {
    const { onError, onLoad } = props;

    const { data: hostAudience, load } = useHomeWidgetData<HostAudienceData>(
        WidgetNames.AUDIENCE,
    );

    useEffect(() => {
        load().then((res) => {
            if (res.success) {
                onLoad?.();
            } else {
                onError?.();
            }
        });
    }, []);

    if (!hostAudience) {
        return <WidgetLoadingFallback skeletonLoaderHeight='64' />;
    }
    const audienceRoute = buildHostProductRoute('AUDIENCE');

    return (
        <WidgetCard>
            <CardHeadingWidget
                audienceSurveyCount={hostAudience.responses.total}
            />
            <Grid
                marginTop={{ base: 0, md: 3 }}
                gap={6}
                alignItems='stretch'
                justifyItems='stretch'
            >
                {hostAudience.responses.total > 0 ? (
                    <AudienceContentWidget
                        hostAudience={hostAudience}
                        audienceRoute={audienceRoute}
                    />
                ) : (
                    <HostDashGettingStartedAudienceWidget />
                )}
            </Grid>
        </WidgetCard>
    );
};

export default Audience;
