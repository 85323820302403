import { createColorShade } from '@trova-trip/trova-components';
import { shuffle } from 'lodash';
import BarChart from '../../../../../../common/components/Charts/BarChart';
import PieChart from '../../../../../../common/components/Charts/PieChart';
import { MetricProps } from './Metric.types';

const textAmount = 'amount';
const textLabel = 'label';

export const Metric = ({
    graphData,
    chartType,
    color,
    shadeSteps,
    xAxisHeight,
    maxLabelLength,
}: MetricProps): JSX.Element => {
    const colorArray = shuffle(
        shadeSteps.map((shade) => createColorShade(color, shade)),
    );

    if (chartType === 'PieChart') {
        return (
            <PieChart
                graphData={graphData}
                fieldTitle={textLabel}
                fieldValue={textAmount}
                maxLabelLength={maxLabelLength}
                colorArray={colorArray}
            />
        );
    } else {
        return (
            <BarChart
                graphData={graphData}
                graphX={textLabel}
                graphY={textAmount}
                xAxisHeight={xAxisHeight}
                valueLabel={textAmount}
                colorArray={colorArray}
            />
        );
    }
};

export default Metric;
