import ContentForModelStatus from 'applications/common/components/ContentForModelStatus';
import { StatefulModel } from 'applications/types';
import TrovaHeroFileInput from 'components/TrovaFileInput/TrovaHeroFileInput';
import { HostTripApproved } from 'config/constants';
import { noop } from 'lodash';
import { useGetDropdownOptionsFromStringArray } from 'util/hooks/formHooks';
import {
    ComponentWidth,
    FormSaver,
    Input,
    MultipleDropdown,
    Textarea,
    TextareaSize,
    useFormSaver,
    Grid,
} from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import { models, validationSchemas, constants } from '@trova-trip/trova-models';

import { TripPageFields } from '../TripPageFields';
import FormHeader from './FormHeader';
import ReadOnlyPhotos from './ReadOnlyPhotos';

type Trip = models.trips.Trip;
type ItinerariesPhotos = models.itineraries.Photos;
type FormInitialValues = Pick<Trip, 'name' | 'tripSummary' | 'categories'> & {
    photos: ItinerariesPhotos;
};

type TripDetailsFormProps = {
    currentTrip: models.trips.Trip;
    onSubmit: (
        values: any,
        successMessage: string,
        errorMessage: string,
    ) => Promise<any>;
};

const {
    trips: { TRIP_STATUS, TRIP_NAME_MAX_LENGTH },
} = constants;

const FieldsNames = {
    name: 'name',
    categories: 'categories',
    summary: 'tripSummary',
    photos: 'photos',
};
const { TripDetailsFormSchema: schema } = validationSchemas;

const TripDetailsForm = ({
    currentTrip,
    onSubmit,
}: TripDetailsFormProps): JSX.Element => {
    const { name, categories, tripSummary, photos, status } = currentTrip;

    const initialValues: FormInitialValues = {
        name,
        tripSummary,
        categories: categories || [],
        photos: photos || { hero: '', itineraryPhotos: [] },
    };

    const themeDropdownOptions = useGetDropdownOptionsFromStringArray(
        categories as string[],
    );

    const formSaver = useFormSaver({
        onSubmit: (values) =>
            onSubmit(
                values,
                'Trip Details Saved!',
                'There was an error saving the trip details, please try again.',
            ),
        validationSchema: {
            schema: schema,
            validateFieldOnChange: false,
        },
        //@ts-expect-error
        initialValues,
    });

    const isTripClosed = status === TRIP_STATUS.CLOSED;

    const tripPhotos = formSaver.formValues.get
        .photos as unknown as ItinerariesPhotos;

    const shouldUseMaxLength = !(formSaver.formValues.get.name.length > TRIP_NAME_MAX_LENGTH);

    return (
        <FormSaver
            name='trip-details-config-form'
            onSubmit={formSaver.handleFormSubmit}
        >
            <Grid>
                <Grid.Item columnSpan={{ base: '*', md: 10 }}>
                    <FormHeader
                        title='Trip Details'
                        description='Edit your trip details, upload images, add workshop descriptions (if any) and informal gatherings to finalize your trip page.'
                    />
                </Grid.Item>
                <Grid.Item columnSpan={{ base: '*', md: 10 }}>
                    <Grid>
                        <ContentForModelStatus
                            model={StatefulModel.TRIP}
                            matchingStatuses={HostTripApproved}
                            render={(): JSX.Element => <TripPageFields />}
                        />
                        <Grid.Item columnSpan={{ base: 12, md: 6 }}>
                            <Input
                                disabled={isTripClosed}
                                label='Trip Name'
                                detail={shouldUseMaxLength ? `${TRIP_NAME_MAX_LENGTH} characters length maximum` : ''}
                                name={FieldsNames.name}
                                value={formSaver.formValues.get.name as string}
                                error={formSaver.formErrors?.name}
                                onChange={formSaver.formValues.set.name}
                                size={ComponentWidth.Flexible}
                                maxlength={shouldUseMaxLength ? TRIP_NAME_MAX_LENGTH : undefined}
                            />
                        </Grid.Item>
                        <Grid.Item columnSpan={{ base: 12, md: 6 }}>
                            <MultipleDropdown
                                disabled={isTripClosed}
                                name={FieldsNames.categories}
                                label={'Trip Theme'}
                                size={ComponentWidth.Flexible}
                                value={
                                    formSaver.formValues.get.categories as any
                                }
                                error={formSaver.formErrors?.categories}
                                onChange={(_, __, value): void => {
                                    formSaver.formValues.set.nested(
                                        'categories',
                                        value,
                                    );
                                }}
                                onSearch={noop}
                            >
                                {themeDropdownOptions}
                            </MultipleDropdown>
                        </Grid.Item>
                        <Grid.Item columnSpan='*'>
                            <Textarea
                                disabled={isTripClosed}
                                size={TextareaSize.Flexible}
                                label={'Trip Description'}
                                name={FieldsNames.summary}
                                value={
                                    formSaver.formValues.get
                                        .tripSummary as string
                                }
                                error={formSaver.formErrors?.tripSummary}
                                onChange={formSaver.formValues.set.tripSummary}
                                expandable={true}
                            />
                        </Grid.Item>
                        {isTripClosed ? (
                            <Grid.Item columnSpan='*'>
                                <ReadOnlyPhotos tripPhotos={tripPhotos} />
                            </Grid.Item>
                        ) : (
                            <>
                                <Grid.Item columnSpan='*'>
                                    <TrovaHeroFileInput
                                        name={FieldsNames.photos}
                                        value={tripPhotos}
                                        onChange={(event): void => {
                                            const value = event.target.value;
                                            formSaver.formValues.set.photos(
                                                value,
                                            );
                                        }}
                                        label={'Upload photos'}
                                        maxFiles={'10'}
                                    />
                                </Grid.Item>
                                <Grid.Item columnSpan='*'>
                                    <Button
                                        variant='primary'
                                        isDisabled={!formSaver.isFormDirty}
                                        type='submit'
                                    >
                                        Save Trip Details
                                    </Button>
                                </Grid.Item>
                            </>
                        )}
                    </Grid>
                </Grid.Item>
            </Grid>
        </FormSaver>
    );
};

export default TripDetailsForm;
