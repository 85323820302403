/*issue with CRA4 and React 17: https://github.com/emotion-js/emotion/issues/2041*/
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { ComponentWidth } from '@trova-trip/trova-components';
import React from 'react';
import useOnChangeForHeroAndItineraryPhotos from '../../util/hooks/useOnChangeForHeroAndItineraryPhotos';
import parseHeroAndItineraryPhotosForInput from '../../util/parseHeroAndItineraryPhotosForInput';
import { StyledFileInput } from './TrovaFileInput.components';
import { models } from '@trova-trip/trova-models';
import {
    DetailSpan,
    ErrorSpan,
    labelContainer,
    LabelSpan,
    mainContainer,
    trovaFileInputStyles,
} from './TrovaFileInput.styles';

interface TrovaHeroFileInputProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    name: string;
    value: models.itineraries.Photos;
    label: string;
    maxFiles: string;
    key?: string;
    className?: string;
    detail?: string;
    error?: string;
    buttonText?: string;
    feedbackText?: string;
    isDisabled?: boolean;
}

const TROVA_IMAGE_UPLOAD_URL = `/api/me/files/new-upload-image`;

const TrovaHeroFileInput = ({
    onChange,
    error,
    label,
    detail,
    className,
    maxFiles,
    key,
    name,
    value,
    isDisabled,
    buttonText = 'Add image',
    feedbackText = 'Or drag and drop',
}: TrovaHeroFileInputProps): JSX.Element => {
    return (
        <div css={mainContainer()} className={className}>
            {label && (
                <div css={labelContainer()}>
                    <LabelSpan>{label}</LabelSpan>
                </div>
            )}
            {detail && <DetailSpan>{detail}</DetailSpan>}
            <StyledFileInput
                css={trovaFileInputStyles({ buttonText, feedbackText })}
                key={key}
                name={name}
                onChange={useOnChangeForHeroAndItineraryPhotos(onChange)}
                error={error}
                size={ComponentWidth.Medium}
                maxFiles={maxFiles}
                sortable={true}
                value={parseHeroAndItineraryPhotosForInput(value)}
                uploadUrl={TROVA_IMAGE_UPLOAD_URL}
                disabled={isDisabled}
            />
            {error && typeof error === `string` && (
                <ErrorSpan>{error}</ErrorSpan>
            )}
            {error && Array.isArray(error) && (
                <ErrorSpan>
                    {error.reduce((a, b) => {
                        return a.concat(`, `).concat(b);
                    })}
                </ErrorSpan>
            )}
        </div>
    );
};

export default TrovaHeroFileInput;
