import {
    ComponentWidth,
    Input,
    Textarea,
    TextareaSize,
} from '@trova-trip/trova-components';
import { memo } from 'react';
import { ProfileFormTextFieldProps } from '../../../types/ProfileForm.types';

const ProfileFormTextField = memo(
    (props: ProfileFormTextFieldProps): JSX.Element => {
        const {
            name,
            label,
            value,
            error,
            onChange,
            required,
            type = 'input',
            placeholder = '',
            disabled,
        } = props;

        return type === 'input' ? (
            <Input
                disabled={disabled}
                label={label}
                name={name}
                value={value}
                placeholder={placeholder}
                error={error}
                onChange={onChange}
                required={required}
                size={ComponentWidth.Flexible}
            />
        ) : (
            <Textarea
                disabled={disabled}
                label={label}
                name={name}
                value={value}
                placeholder={placeholder}
                error={error}
                onChange={onChange}
                size={TextareaSize.Flexible}
                required={required}
                expandable={true}
            />
        );
    },
);

ProfileFormTextField.displayName = 'ProfileFormTextField';

export default ProfileFormTextField;
