import { Grid, Heading } from '@trova-trip/trova-components';
import { Card } from '@trova-trip/trova-components/build/next';
import ImageGroup, {
    ImageLayout,
} from '../../../../../../common/components/ImageGroup';
import { imageLayouts } from './image-config';
import ExportDataButton from './ExportDataButton';

interface TotalAudienceCardProps {
    totalAudience: number;
    surveyResponses: number;
}

const currentImageLayout: ImageLayout =
    imageLayouts[Math.floor(Math.random() * imageLayouts.length)];

const TotalAudienceCard = (props: TotalAudienceCardProps): JSX.Element => {
    const { totalAudience, surveyResponses } = props;

    return (
        <Card
            background='neutral.white'
            textAlign='center'
            height='full'
            padding={4}
        >
            <Grid>
                <Grid.Item columnSpan={12} justifySelf='flex-end'>
                    <ExportDataButton
                        surveyResponses={surveyResponses}
                        totalAudience={totalAudience}
                    />
                </Grid.Item>
                <Grid.Item columnSpan={12}>
                    <ImageGroup imageLayout={currentImageLayout} spacing='-1' />
                    <Heading as='h1'>{totalAudience}</Heading>
                    <Heading as='h6'>Total Audience</Heading>
                </Grid.Item>
            </Grid>
        </Card>
    );
};

export default TotalAudienceCard;
