import { useEffect, useState } from 'react';
import { BaseBox } from '@trova-trip/trova-components';
import { PublicProfileMicroAppNavbar } from './PublicProfileNavigation';
import { useMicroAppModule } from '../../../../../applications/common/hooks/useMicroAppsModule';
import { useSelector } from 'react-redux';
import { UserHostStoreSelector } from '../../../../../state/hosts';
import { appConstants, microApps } from '@trova-trip/trova-common';
import { APP_ENVIRONMENT } from '../../../../../applications/utils';
import { ProfileStoreSelector } from '../../../../../state/profile';
import Loading from '../../../../../components/Loading';

type Environment = appConstants.Environment;
const {
    TrovaTripMicroApps: {
        TROVA_HOST_PROFILE: { uri: getTrovaHostProfileScriptUri },
    },
} = microApps;
const microAppPath = getTrovaHostProfileScriptUri(
    APP_ENVIRONMENT as Environment,
);
const renderTargetId = 'trova-micro-app:trova-host-profile';

const MicroAppPublicProfileWrapper = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState(true);
    const [renderTargetExist, setRenderTargetExist] = useState(false);

    const currentHost = useSelector(
        (state: UserHostStoreSelector) => state.userHosts.current,
    );
    const currentUser = useSelector(
        (state: ProfileStoreSelector) => state.profile.current,
    );

    const { _id: hostId } = currentHost || {};

    const trovaHostProfileModule = useMicroAppModule({
        microAppScriptUrl: microAppPath,
        onModuleImported: () => setIsLoading(false),
    });

    useEffect(() => {
        if (!trovaHostProfileModule || !hostId) return;
        const { RenderComponent } = trovaHostProfileModule;
        if (RenderComponent && renderTargetExist) {
            RenderComponent({
                renderTarget: renderTargetId,
                parentRequestObj: {
                    qsArgs: {
                        hostid: hostId,
                    },
                    analyticsData: {
                        userId: currentUser?._id,
                        status: currentUser?.status,
                    },
                },
            });
        }
    }, [trovaHostProfileModule, hostId, renderTargetExist]);

    return (
        <BaseBox width='full' paddingTop={{ base: 4, md: 0 }}>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <PublicProfileMicroAppNavbar />
                    <BaseBox
                        paddingY={{ base: 0, md: 6 }}
                        paddingBottom={{ base: 16, md: 6 }}
                        width='full'
                        borderRadius='xl'
                        background='neutral.white'
                    >
                        <BaseBox
                            id={renderTargetId}
                            ref={(el) => setRenderTargetExist(!!el)}
                        />
                    </BaseBox>
                </>
            )}
        </BaseBox>
    );
};

export default MicroAppPublicProfileWrapper;
