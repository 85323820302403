import { useState, useCallback } from 'react';
import usePublishSystem from 'applications/common/hooks/trips/usePublishSystem';
import IFramedTripPreview from 'applications/host/components/IFramedTripPreview/IFramedTripPreview';
import TripFloatingBanner from 'applications/host/components/TripFloatingBanner/TripFloatingBanner';
import ConfirmDialog from './shared/ConfirmDialog';
import BannerContent from './BannerContent/BannerContent';

export const TripPreview = (): JSX.Element => {
    const { isPending, isMarketing, publish } = usePublishSystem();

    const [dialogsOpenState, setDialogsOpenState] = useState({
        publishDialog: false,
        goLiveDialog: false,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isRequestSucceeded, setIsRequestSucceeded] = useState(false);

    const handlePublishDialogToggle = useCallback(
        (action: 'open' | 'close'): void => {
            setDialogsOpenState({
                ...dialogsOpenState,
                publishDialog: action === 'open' ? true : false,
            });
        },
        [dialogsOpenState, setDialogsOpenState],
    );

    const handleDialogOnConfirm = useCallback(async (): Promise<void> => {
        setIsLoading(true);
        try {
            const response = await publish();
            response && setIsRequestSucceeded(true);
        } catch (error) {
            console.error(error.message);
        }
        setIsLoading(false);
    }, [publish, setIsRequestSucceeded]);

    const shouldShowFloatingBanner = isPending || isMarketing;

    return (
        <>
            <IFramedTripPreview
                queryParams={isMarketing ? { earlyBird: true } : {}}
            />

            {shouldShowFloatingBanner && (
                <TripFloatingBanner>
                    {isPending && (
                        <BannerContent
                            title='Trip Page Preview'
                            description='After reviewing your changes, you can request your Trip Page to be published.'
                            actionProps={{
                                text: 'Request Publish',
                                onClick: (): void =>
                                    handlePublishDialogToggle('open'),
                            }}
                        />
                    )}

                    {isMarketing && (
                        <BannerContent
                            title='Trip Page Preview'
                            description='Review your trip page before launching your trip'
                        />
                    )}
                </TripFloatingBanner>
            )}

            <ConfirmDialog
                open={dialogsOpenState.publishDialog}
                isSuccess={isRequestSucceeded}
                isLoading={isLoading}
                defaultContent={{
                    title: 'Publish Changes?',
                    description:
                        'After requesting a publish your trip page will go through a review process. Please allow up to 48 business hours for your changes to be approved.',
                }}
                successContent={{
                    title: 'Publish Requested!',
                    description:
                        'Please allow 48 business hours for changes to be published. While you wait, your page will still be available in its previous state.',
                }}
                onConfirm={handleDialogOnConfirm}
                onCancel={(): void => handlePublishDialogToggle('close')}
            />
        </>
    );
};

export default TripPreview;
