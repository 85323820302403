import { useBreakpoint } from '@trova-trip/trova-components';

interface UseIsLargeScreenConfig {
    includeTabletResolution: boolean;
}

const useIsLargeScreen = (
    config: UseIsLargeScreenConfig = { includeTabletResolution: false },
): boolean => {
    const { includeTabletResolution } = config;
    const breakpoint = includeTabletResolution ? 'md' : 'lg';
    return useBreakpoint(breakpoint);
};

export default useIsLargeScreen;
