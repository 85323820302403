import { css } from '@emotion/css';
import { theme } from '@trova-trip/trova-components';

export const PieChartCSS = css`
    padding: 0 ${theme.space[9]};

    .recharts-surface {
        overflow: inherit !important;
    }
`;
