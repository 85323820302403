import { useCallback } from 'react';
import { set } from 'lodash';
import {
    SelectedPackages,
    FoodOptionsValueType,
    FormFieldsNames,
} from './ItineraryPackage.types';
import { BaseComboBoxOption } from '@trova-trip/trova-components';

type UseItineraryPackageInputsReturn = (
    type: string,
    packageType: string,
    value: any,
) => void;

const formatItineraryInputsToFormData = (
    packages: SelectedPackages,
    path: string,
    value: any,
): SelectedPackages => {
    const updatedPackages = { ...packages };
    set(updatedPackages, path, value);

    return updatedPackages;
};

/**
 * this hook formats the data from the ReviewDetailsForm in order to be consumable for the TC inputs
 * and expose the inputs values to the ReviewDetailsForm through the updateValue function
 */
const useItineraryPackageInputs = (
    updateValue: (packages: SelectedPackages) => void,
    selectedPackages: SelectedPackages,
): UseItineraryPackageInputsReturn => {
    const updateFoodOptions = useCallback(
        (type, option: BaseComboBoxOption) => {
            if (!option.label) {
                return;
            }

            const alreadySelected = (
                selectedPackages[type]?.foodOptions || []
            ).includes(option.label as FoodOptionsValueType);

            const foodOptions = alreadySelected
                ? (selectedPackages[type]?.foodOptions || []).filter(
                      (elem) => (elem as FoodOptionsValueType) !== option.label,
                  )
                : [...selectedPackages[type]?.foodOptions, option.label];

            updateValue(
                formatItineraryInputsToFormData(
                    selectedPackages,
                    `${type}.foodOptions`,
                    foodOptions,
                ),
            );
        },
        [selectedPackages],
    );

    // this function listen to the input changes and update them to the formData
    const handleItineraryPackageValue = useCallback(
        (type: string, packageType: string, option: BaseComboBoxOption) => {
            switch (type) {
                case FormFieldsNames.ACCOMMODATION_LEVEL:
                    updateValue(
                        formatItineraryInputsToFormData(
                            selectedPackages,
                            `${packageType}.accommodationLevels`,
                            [option.value],
                        ),
                    );

                    break;
                case FormFieldsNames.MEAL_LEVEL:
                    updateValue(
                        formatItineraryInputsToFormData(
                            selectedPackages,
                            `${packageType}.mealLevels`,
                            [option.value],
                        ),
                    );

                    break;
                case FormFieldsNames.ENABLED:
                    updateValue(
                        formatItineraryInputsToFormData(
                            selectedPackages,
                            `${packageType}.enabled`,
                            option,
                        ),
                    );

                    break;
                case FormFieldsNames.FOOD_OPTIONS:
                    updateFoodOptions(packageType, option);
                    break;

                default:
                    console.error(
                        `ERROR, Input option ${type} not available for packages handling`,
                    );
                    break;
            }
        },
        [selectedPackages],
    );

    return handleItineraryPackageValue;
};

export default useItineraryPackageInputs;
