import React, { useEffect, useState } from 'react';
import {
    ActionSheet,
    Heading,
    Stack,
    Text,
    useBreakpoint,
} from '@trova-trip/trova-components';
import { Button, Dialog } from '@trova-trip/trova-components/build/next';
import { FIELD_MAP } from '../utils/constants';
import { FieldName, EditFieldProps, HostProfileResponse } from '../utils/types';
import { formatErrors, ValidationError } from '../../../utils/formErrors.utils';
import { validationSchema } from './PublicProfileForm';

interface EditPublicProfilePopupProps<Field extends FieldName> {
    /** fieldName is used to both determine the view we will
     * show, as well as whether to control the popup's visibility.
     * When null, it will be hidden. */
    fieldName: Field | null;
    formData?: HostProfileResponse[Field];
    onSave: (fieldName: string, data: HostProfileResponse[Field]) => void;
    onCancel: () => void;
}

interface ContentProps {
    subtitle?: string;
    text?: string;
    editData: EditFieldProps<FieldName>['fieldData'];
    onFormChange: (
        value: HostProfileResponse[FieldName],
        shouldDisableSave?: boolean,
    ) => void;
    EditField: React.ComponentType<EditFieldProps<FieldName>>;
    errors?: ValidationError;
}

interface FooterProps {
    isLargeScreen: boolean;
    isDisabled?: boolean;
    onFormSave: () => void;
    onCancel: () => void;
}

const Content = ({
    EditField,
    subtitle,
    text,
    editData,
    onFormChange,
    errors,
}: ContentProps): JSX.Element => {
    return (
        <Stack
            direction='column'
            paddingBottom={6}
            paddingTop={2}
            width='full'
            align='stretch'
        >
            {subtitle ? (
                <Heading as='h6' color='blueGray.650'>
                    {subtitle}
                </Heading>
            ) : null}
            {text ? <Text color='blueGray.650'>{text}</Text> : null}
            {EditField ? (
                <EditField
                    fieldData={editData}
                    onChange={onFormChange}
                    errors={errors}
                />
            ) : null}
        </Stack>
    );
};

const Footer = ({
    isLargeScreen,
    isDisabled = false,
    onFormSave,
    onCancel,
}: FooterProps): JSX.Element => {
    return (
        <Stack spacing={4} width='full' justify='flex-end' wrap='nowrap'>
            <Button
                variant='secondary'
                onClick={onCancel}
                isFullWidth={!isLargeScreen}
            >
                Cancel
            </Button>
            <Button
                isDisabled={isDisabled}
                variant='primary'
                onClick={onFormSave}
                isFullWidth={!isLargeScreen}
            >
                Done
            </Button>
        </Stack>
    );
};

const EditPublicProfilePopup = <Field extends FieldName>({
    fieldName,
    formData,
    onSave,
    onCancel,
}: EditPublicProfilePopupProps<Field>): JSX.Element => {
    const isLargeScreen = useBreakpoint('sm');
    const [disableSave, setDisableSave] = useState<boolean>(false);
    const [editData, setEditData] = useState<
        HostProfileResponse[Field] | undefined
    >(formData);
    const [errors, setErrors] = useState<ValidationError>();

    useEffect(() => {
        setDisableSave(false);
        setEditData(formData);
    }, [formData]);

    const onFormSave = () => {
        if (fieldName) {
            let dataToSubmit = editData;
            const { formatDataBeforeSubmit } = FIELD_MAP[fieldName];
            if (formatDataBeforeSubmit && editData) {
                dataToSubmit = formatDataBeforeSubmit(editData);
            }
            try {
                const currentValidationSchema =
                    validationSchema.fields?.[fieldName];

                if (currentValidationSchema) {
                    currentValidationSchema.validateSync(editData, {
                        abortEarly: false,
                    });
                }
                setErrors(undefined);
                onSave(fieldName, dataToSubmit as HostProfileResponse[Field]);
            } catch (errors) {
                const formattedErrors = formatErrors(errors);
                setErrors(formattedErrors);
                return;
            }
        }
    };

    const onFormChange = (
        value: HostProfileResponse[Field],
        shouldDisableSave?: boolean,
    ) => {
        setDisableSave(!!shouldDisableSave);
        setEditData(value);
    };

    const shouldShow = !!fieldName;

    let content, title;

    if (shouldShow) {
        title = FIELD_MAP[fieldName].title;
        const { description } = FIELD_MAP[fieldName];
        const { subtitle, text } = description || {};

        const EditField = FIELD_MAP[fieldName].editField as React.ComponentType<
            EditFieldProps<typeof fieldName>
        >;

        content = (
            <Content
                subtitle={subtitle}
                text={text}
                editData={editData}
                onFormChange={onFormChange}
                EditField={EditField}
                errors={errors}
            />
        );
    }

    const footer = (
        <Footer
            isDisabled={disableSave}
            isLargeScreen={isLargeScreen}
            onFormSave={onFormSave}
            onCancel={onCancel}
        />
    );

    if (isLargeScreen) {
        return (
            <Dialog
                isOpen={shouldShow}
                onClose={onCancel}
                position='centered'
                showCloseButton
                size='lg'
                minWidth={{ md: '36.5rem' }}
            >
                <Dialog.Header title={title} />
                <Dialog.Body width={{ sm: 'sm', md: 'full' }}>
                    {content}
                </Dialog.Body>
                <Dialog.Footer>{footer}</Dialog.Footer>
            </Dialog>
        );
    }

    return (
        <ActionSheet isOpen={shouldShow} onClose={onCancel}>
            <ActionSheet.Header>
                <Heading as='h5'>{title}</Heading>
            </ActionSheet.Header>
            <ActionSheet.Body>{content}</ActionSheet.Body>
            <ActionSheet.Footer>{footer}</ActionSheet.Footer>
        </ActionSheet>
    );
};

export default EditPublicProfilePopup;
