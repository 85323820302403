import { Occupancy } from '../../../../types';
import BaseRoomOptionField, {
    BaseRoomOptionItem,
} from '../BaseRoomOptionField';

const items: BaseRoomOptionItem[] = [
    {
        icon: 'kingSizeBed',
        text: 'Private room for one traveler',
    },
    { icon: 'attachMoney', text: 'Additional trip cost' },
];

type RoomPrivateFieldProps = {
    amount: number;
    value: boolean;
    name: string;
};

const RoomPrivateField = ({
    amount,
    value,
    name,
}: RoomPrivateFieldProps): JSX.Element => (
    <BaseRoomOptionField
        title='Private Room'
        name={name}
        amount={amount}
        value={value}
        items={items}
        type={Occupancy.single}
    />
);

export default RoomPrivateField;
