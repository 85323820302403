import React from 'react';
// used for making the prop types of this component
import PropTypes from 'prop-types';

// core components
import Button from '../CustomButtons/OldButton.jsx';
import defaultImage from '../../assets/img/image-placeholder.jpg';
import defaultAvatar from '../../assets/img/placeholder.jpg';

import { upload } from '../../state/utils/api';
import FakeEvent from '../../util/form/FakeEvent';
import Loading from '../Loading';

class TrovaImageUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
            uploadingImage: false,
        };
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleImageChange(event) {
        event.preventDefault();
        // let reader = new FileReader();
        const file = event.target.files[0];
        if (file === undefined) {
            return;
        }
        const { name, onChange, height, width, fit } = this.props;
        this.setState({ uploadingImage: true });
        const query = height || width ? { height, width } : {};
        if (fit) {
            query.fit = fit;
        }
        upload(
            `api/admin/files/upload-file`,
            {
                [name]: file,
            },
            query
        ).then(({ data, error }) => {
            if (error) {
                // TODO implement error
            }
            const { url } = data;
            onChange(new FakeEvent(name, url));
            this.setState({ uploadingImage: false });
        });
    }

    renderLoadingWhileUploading() {
        if (!this.state.uploadingImage) {
            return null;
        }
        return <Loading />;
    }

    handleClick() {
        // eslint-disable-next-line react/no-string-refs
        this.refs.fileInput.click();
    }

    render() {
        const {
            avatar,
            addButtonProps,
            changeButtonProps,
            label,
            value,
        } = this.props;
        const { uploadingImage } = this.state;
        return (
            <div className="fileinput text-center">
                {this.renderLoadingWhileUploading()}
                <input
                    type="file"
                    onChange={this.handleImageChange}
                    // eslint-disable-next-line react/no-string-refs
                    ref="fileInput"
                />
                <div className={'thumbnail' + (avatar ? ' img-circle' : '')}>
                    <img data-src={value || defaultImage} alt="..." />
                </div>
                <div>
                    {!this.props.value ? (
                        <Button
                            {...addButtonProps}
                            onClick={() => this.handleClick()}
                            disabled={uploadingImage}
                        >
                            {avatar ? 'Add Photo' : `Add ${label} Image`}
                        </Button>
                    ) : (
                        <span>
                            <Button
                                {...changeButtonProps}
                                onClick={() => this.handleClick()}
                                disabled={uploadingImage}
                            >
                                {`Change ${label}`}
                            </Button>
                        </span>
                    )}
                </div>
            </div>
        );
    }
}

TrovaImageUpload.propTypes = {
    avatar: PropTypes.bool,
    addButtonProps: PropTypes.object,
    changeButtonProps: PropTypes.object,
    label: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    fit: PropTypes.string,
};

export default TrovaImageUpload;
