import FetchState from './utils/FetchState';

const destinations = FetchState.create('api/admin/destinations');
const destinationsMetadata = FetchState.create('api/metadata/destinations');

const destinationsMetadataReducer = destinationsMetadata.getReducer();
const destinationsReducer = destinations.getReducer();

export {
    destinations,
    destinationsMetadata,
    destinationsReducer,
    destinationsMetadataReducer,
};
