import React from 'react';
import { css } from '@emotion/css';
import PropTypes from 'prop-types';
import ServiceCard from '../../applications/common/components/services/ServiceCard';
import Card from './Card';
import { getServiceTitleLabel } from '../../applications/common/components/services/LibraryServiceFormatter';
import { ServiceTypesKeys } from 'config/constants';

const styledMainContainer = css`
    position: relative;
`;

const DRAGGABLE_ELEMENT = `draggableCard`;

const DailyActivityCard = ({
    dayIndex,
    service,
    serviceIndex,
    handleDropAndDropCards,
    openForm,
    draggable = true,
    editMode,
}) => {
    const isServiceEditable =
        !(service.type === ServiceTypesKeys.INFORMAL_GATHERING) && editMode;
    return (
        <Card
            className={styledMainContainer}
            draggableContainer={draggable}
            key={service.id}
            dropTargetConfiguration={{
                accept: DRAGGABLE_ELEMENT,
                drop: (item) =>
                    handleDropAndDropCards(dayIndex, serviceIndex, item),
            }}
            dragTargetConfiguration={{
                item: {
                    type: DRAGGABLE_ELEMENT,
                    service: {
                        orderOrigin: serviceIndex,
                        dayOrigin: dayIndex,
                    },
                },
            }}
            onCollect={(item) => item}
        >
            <ServiceCard
                title={getServiceTitleLabel(service.type)}
                service={service}
                day={dayIndex}
                order={serviceIndex}
                actionsBarConfig={{
                    toggleChildren: {
                        shouldRender: isServiceEditable,
                        handler: ({ day, order, type }) =>
                            openForm(day, order, type),
                    },
                }}
            />
        </Card>
    );
};

DailyActivityCard.propTypes = {
    dayIndex: PropTypes.number,
    serviceIndex: PropTypes.number,
    service: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        location: PropTypes.string,
        type: PropTypes.string,
        images: PropTypes.array,
        mealType: PropTypes.string,
        options: PropTypes.array,
        viewOnly: PropTypes.bool,
    }),
    handleDropAndDropCards: PropTypes.func,
    openForm: PropTypes.func,
    draggable: PropTypes.bool,
    editMode: PropTypes.bool,
};

export default DailyActivityCard;
