import {
    BaseBox,
    Hide,
    Stack,
    UseFormSaverReturn,
} from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import { Form } from '../Form';
import styles from './Footer.module.scss';

type FooterProps = {
    isButtonDisabled: boolean;
    onSaveAndClose: UseFormSaverReturn['handleFormSubmit'];
    hostEmail?: string;
    tripId?: string;
    emailHookId?: string;
    hostFirstName?: string;
    hostLastName?: string;
    hostDisplayName?: string;
    onCloseClick: () => void;
};

const Footer = ({
    isButtonDisabled,
    hostEmail,
    tripId,
    emailHookId,
    hostFirstName,
    hostLastName,
    hostDisplayName,
    onSaveAndClose,
    onCloseClick,
}: FooterProps): JSX.Element => {
    const handleSaveAndClose = (event) => {
        onSaveAndClose(event);
    };

    const hostInfo = {
        firstName: hostFirstName,
        lastName: hostLastName,
        displayName: hostDisplayName,
        email: hostEmail,
    };

    return (
        <Stack
            direction='column'
            justify={{ base: 'stretch', md: 'end' }}
            align={{ base: 'stretch', md: 'end' }}
            className={styles.footerContainer}
            paddingX={{ base: 0, md: 6 }}
            paddingY={{ base: 0, md: 4 }}
            width='full'
            borderTop='1px'
            borderColor='blueGray.300'
            position={{ base: 'sticky', md: 'static' }}
            bottom={0}
            spacing={0}
        >
            <Hide breakpoint={{ min: 'md' }}>
                <BaseBox backgroundColor='blueGray.25'>
                    <Form.Footer
                        hostInfo={hostInfo}
                        tripId={tripId}
                        emailHookId={emailHookId}
                    />
                </BaseBox>
            </Hide>
            <Stack
                padding={{ base: 4, md: 0 }}
                align='center'
                wrap='nowrap'
                justify='end'
                spacing={10}
            >
                <Hide breakpoint={{ max: 'md' }}>
                    <Button onClick={onCloseClick} variant='tertiary'>
                        Cancel
                    </Button>
                </Hide>
                <Button
                    onClick={handleSaveAndClose}
                    isDisabled={isButtonDisabled}
                    isFullWidth={{ base: true, md: false }}
                >
                    Save & Close
                </Button>
            </Stack>
        </Stack>
    );
};

export default Footer;
