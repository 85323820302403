import { Dispatch, SetStateAction, SyntheticEvent, useState } from 'react';
import { Checkbox, Stack } from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import Iframe from 'iframe-resizer-react';
import ConfirmationDialog from './ConfirmationDialog';
import { css } from '@emotion/css';

/*We need to do this way because we need the Iframe in the DOM to catch the event 'message', 
regardless of whether we want to display it or not.*/

const styledIframeContainer = (
    isAgreementDisplayed: boolean,
): string | undefined => {
    if (!isAgreementDisplayed) {
        return css`
            display: none;
        `;
    }
};

interface AgreementConfirmationDialogProps {
    open: boolean;
    agreementUrl: string;
    onConfirmation: (e?: SyntheticEvent) => void;
    onCancel: () => void;
    isInAgreement: boolean;
    setIsInAgreement: Dispatch<SetStateAction<boolean>>;
}

const AgreementConfirmationDialog = ({
    open,
    agreementUrl,
    onConfirmation,
    onCancel,
    isInAgreement,
    setIsInAgreement,
}: AgreementConfirmationDialogProps): JSX.Element => {
    const [isAgreementDisplayed, setIsAgreementDisplayed] = useState(false);

    return (
        <ConfirmationDialog
            open={open}
            title={'Update Pricing'}
            description={`Your new pricing choices will be reflected on your updated trip agreement - pricing won't be in effect until you agree to the new terms.`}
            onConfirmation={onConfirmation}
            size='lg'
            onCancel={(): void => {
                setIsAgreementDisplayed(false);
                onCancel();
            }}
            confirmButtonProps={{
                isDisabled: !isInAgreement,
                children: 'Confirm',
            }}
            footerLeftContent={
                <Stack
                    spacing={1}
                    marginBottom={{ base: 4, md: 0 }}
                    align='center'
                >
                    <Checkbox
                        name={'agreement'}
                        detail={'I agree to updated'}
                        value={isInAgreement}
                        onChange={(): void => {
                            setIsInAgreement((currentState) => !currentState);
                        }}
                    />
                    <Button
                        variant='tertiary'
                        color='blue.500'
                        size='sm'
                        onClick={(): void => {
                            setIsAgreementDisplayed(true);
                        }}
                    >
                        terms of service
                    </Button>
                </Stack>
            }
        >
            <div className={styledIframeContainer(isAgreementDisplayed)}>
                <Iframe
                    src={agreementUrl}
                    frameBorder={0}
                    style={{
                        width: '100%',
                        overflow: 'hidden',
                        margin: '0 0 -8px 0',
                    }}
                />
            </div>
        </ConfirmationDialog>
    );
};

export default AgreementConfirmationDialog;
