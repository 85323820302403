import {
    BaseBox,
    Card,
    ComponentWidth,
    Form,
    FormDataObjectType,
    Grid,
    TextareaSize,
    theme,
    useFormSaver,
} from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';
import clamp from 'lodash/clamp';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import React, { useEffect, useState } from 'react';
import {
    transformAddressLocationToGooglePlacesOption,
    transformGooglePlacesOptionToAddressLocation,
} from '../../../../../applications/common/helpers';
import Danger from '../../../../../components/Typography/Danger';
import { ServiceTypesKeys } from '../../../../../config/constants';
import { getDropdownOptionsByKey } from '../../../../../util/form/dropdown';
import AddressLocationField from '../../../../operator/components/AddressLocationField';
import {
    getColorFromServiceType,
    getServiceTitleLabel,
} from '../LibraryServiceFormatter';
import ServiceTitle from '../ServiceTitle';
import { ServiceFormPermissions, fieldNamesByServiceType } from '../helpers';
import { validationSchema } from './Shared/validationSchema';
import {
    PaddedCheckbox,
    PaddedDropdown,
    PaddedInput,
    PaddedNumberInput,
    PaddedTextarea,
    StyledCurrency,
    StyledServiceFileInput,
} from './Form.components';
import ActionButtons from './Shared/ActionButtons';
import SaveToLibraryCheckbox from './Shared/SaveToLibraryCheckbox';

type ServiceType = models.services.ServiceType;

interface AccommodationCardFormProps {
    productName: 'itinerary' | 'trip';
    closeForm: () => void;
    updateItinerary: (data: any, nights?: number | string | null) => void;
    serviceToEdit: models.services.Accommodation;
    totalDays: number;
    dayIndex: number;
    errorText: string;
    currencyCode: string;
    disabledFields: string[];
    permissions?: ServiceFormPermissions;
}

const MINIMUM_ACCOMMODATION_DAYS = 1;
const DEFAULT_ACCOMMODATION = {
    starRating: 3,
    saveToLibrary: false,
    orSimilar: true,
    isPremium: false,
    type: ServiceTypesKeys.ACCOMMODATION,
    numberOfNights: MINIMUM_ACCOMMODATION_DAYS,
    addressLocation: undefined,
};

const DROPDOWN_OPTIONS = {
    accomodations: getDropdownOptionsByKey('AccommodationTypes'),
    occupancy: getDropdownOptionsByKey('Occupancy'),
    starRating: getDropdownOptionsByKey('StarRating'),
} as const;

// Helper functions
const calculateMaximumAccommodationDays = (
    dayIndex: number,
    totalDays: number,
): number => {
    // there is no stay on the last day;
    return totalDays - dayIndex;
};

const clampAccommodationDays = (
    day: number,
    maximumAccommodationDays: number,
): number => {
    return clamp(day, MINIMUM_ACCOMMODATION_DAYS, maximumAccommodationDays);
};

const AccommodationCardForm = (
    props: AccommodationCardFormProps,
): JSX.Element => {
    const {
        updateItinerary,
        serviceToEdit,
        dayIndex,
        totalDays,
        closeForm,
        errorText,
        currencyCode,
        disabledFields,
        productName,
        permissions,
    } = props;

    const isNew = isEmpty(serviceToEdit);
    const maximumAccommodationDays = calculateMaximumAccommodationDays(
        dayIndex,
        totalDays,
    );
    const [shouldDelete, setShouldDelete] = useState<boolean>(false);

    const defaultData = !isNew
        ? {
            ...serviceToEdit,
            numberOfNights: clampAccommodationDays(
                serviceToEdit.numberOfNights || 0,
                maximumAccommodationDays,
            ),
            addressLocation: transformAddressLocationToGooglePlacesOption(
                serviceToEdit.addressLocation,
            ),
        }
        : DEFAULT_ACCOMMODATION;

    const formSaver = useFormSaver({
        onSubmit: async (values) => {
            const addressLocation = await transformGooglePlacesOptionToAddressLocation(
                values.addressLocation,
            );
            const submitFormData = {
                ...values,
                addressLocation,
            };
            updateItinerary(submitFormData, values.numberOfNights as number);
        },
        initialValues: defaultData as FormDataObjectType,
        validationSchema: {
            schema: validationSchema,
        },
    });

    // Remove logic
    useEffect(() => {
        if (shouldDelete) {
            const serviceData = formSaver.formState;
            updateItinerary(serviceData);
        }
    }, [shouldDelete]);

    const serviceType: ServiceType = formSaver.formValues.get
        .type as ServiceType;

    // Form Change Handlers
    const handleFormChange = (event: React.SyntheticEvent) => {
        const { value, name } = event.target as HTMLInputElement;
        formSaver.formValues.set.nested(name, value);
    };

    const handleCheckboxChange = (event: React.SyntheticEvent) => {
        const { name } = event.target as HTMLInputElement;
        // toggle the value
        const isChecked = !formSaver.formValues.get.nested(name);
        formSaver.formValues.set.nested(name, isChecked);
    };

    const handleDropdownChangeEvent = (
        event: any,
        name: string,
        value: string,
    ) => {
        const wrappedEvent = {
            ...event,
            target: { value, name },
        } as React.SyntheticEvent;
        handleFormChange(wrappedEvent);
    };

    const handleNumberOfNightsChange = (
        event: any,
        name: string,
        value: number,
    ) => {
        // This handler is only used for the number of nights input
        const wrappedEvent = {
            ...event,
            target: {
                value: clampAccommodationDays(value, maximumAccommodationDays),
                name,
            },
        } as React.SyntheticEvent;

        handleFormChange(wrappedEvent);
    };

    const onRemoveAction = () => {
        formSaver.formValues.set.nested('deleted', true);
        setShouldDelete(true);
    };

    const handleLocationChange = (values) => {
        if (
            !isEqual(
                values.addressLocation,
                formSaver.formValues.get.addressLocation,
            )
        ) {
            formSaver.formValues.set.addressLocation(values.addressLocation);
        }
    };

    const { accommodation: fieldNames } = fieldNamesByServiceType;

    const addressLocationHelperText = formSaver.formValues.get.location
        ? 'We’ve updated our location functionality - search for and confirm the location here.'
        : undefined;

    /**
     * `Form` had to be added in order to be able to handle the `GeoLocationComboBoxField` inside `AddressLocationField`.
     * This field doesn't expose `value` and `onChange` as it is prepared to work with `Form` directly. In order to keep
     * the legacy fields working as well, we kept the `useFormSaver` hook to control them and use that to perform the actual
     * submit. Once this form is migrated to the newest components, only `Form` should be kept.
     */
    return (
        <Form
            name='review-details-form'
            onSubmit={() => {
                formSaver.handleFormSubmit(undefined);
            }}
            onChange={(values) => handleLocationChange(values)}
            initialValues={{
                addressLocation: formSaver.formValues.get.addressLocation,
            }}
        >
            <Card backgroundColor={theme.colors.neutral.white}>
                <ServiceTitle
                    title={getServiceTitleLabel(serviceType)}
                    color={getColorFromServiceType(serviceType)}
                />
                <PaddedInput
                    name={fieldNames.name}
                    placeholder='Accommodation Name'
                    label='Accommodation Name *'
                    size={ComponentWidth.Medium}
                    value={formSaver.formValues.get[fieldNames.name] as string}
                    onChange={handleFormChange}
                    disabled={includes(disabledFields, fieldNames.name)}
                />
                <PaddedDropdown
                    name={fieldNames.accommodationType}
                    placeholder='Select Accommodation Type'
                    label='Accommodation Type *'
                    size={ComponentWidth.Large}
                    value={
                        formSaver.formValues.get[
                            fieldNames.accommodationType
                        ] as string
                    }
                    onChange={handleDropdownChangeEvent}
                    onSearch={noop}
                    disabled={includes(
                        disabledFields,
                        fieldNames.accommodationType,
                    )}
                >
                    {DROPDOWN_OPTIONS.accomodations}
                </PaddedDropdown>
                {formSaver.formValues.get.price ? (
                    <StyledCurrency
                        name={fieldNames.price}
                        label='Price *'
                        size={ComponentWidth.Small}
                        value={
                            formSaver.formValues.get[fieldNames.price] as number
                        }
                        onChange={handleFormChange}
                        currencyType={currencyCode}
                        disabled={includes(disabledFields, fieldNames.price)}
                    />
                ) : null}
                <PaddedDropdown
                    name={fieldNames.occupancy}
                    placeholder='Select occupancy'
                    label='Quoted Occupancy *'
                    size={ComponentWidth.Medium}
                    value={
                        formSaver.formValues.get[fieldNames.occupancy] as string
                    }
                    onChange={handleDropdownChangeEvent}
                    onSearch={noop}
                    disabled={includes(disabledFields, fieldNames.occupancy)}
                >
                    {DROPDOWN_OPTIONS.occupancy}
                </PaddedDropdown>
                <PaddedDropdown
                    name={fieldNames.starRating}
                    placeholder='Select star rating'
                    label='Star Rating *'
                    size={ComponentWidth.Small}
                    value={
                        formSaver.formValues.get[
                            fieldNames.starRating
                        ] as string
                    }
                    onChange={handleDropdownChangeEvent}
                    onSearch={noop}
                    disabled={includes(disabledFields, fieldNames.starRating)}
                >
                    {DROPDOWN_OPTIONS.starRating}
                </PaddedDropdown>
                <PaddedNumberInput
                    name={fieldNames.numberOfNights}
                    placeholder='Number of nights'
                    label='No. of nights'
                    value={
                        formSaver.formValues.get[
                            fieldNames.numberOfNights
                        ] as number
                    }
                    onChange={handleNumberOfNightsChange}
                    disabled={includes(
                        disabledFields,
                        fieldNames.numberOfNights,
                    )}
                />
                {formSaver.formValues.get.location ? (
                    <PaddedInput
                        name={fieldNames.location}
                        placeholder='Accommodation address'
                        label='Location (Previous)'
                        size={ComponentWidth.Medium}
                        value={
                            formSaver.formValues.get[
                                fieldNames.location
                            ] as string
                        }
                        onChange={handleFormChange}
                        disabled
                    />
                ) : null}

                <BaseBox paddingY={2} width='70%'>
                    <AddressLocationField
                        name={fieldNames.addressLocation}
                        label='Location'
                        placeholder='Accommodation address'
                        helperText={addressLocationHelperText}
                        isDisabled={includes(
                            disabledFields,
                            fieldNames.addressLocation,
                        )}
                    />
                </BaseBox>

                <PaddedCheckbox
                    name={fieldNames.orSimilar}
                    detail='Or Similar'
                    value={
                        formSaver.formValues.get[
                            fieldNames.orSimilar
                        ] as boolean
                    }
                    onChange={handleCheckboxChange}
                    disabled={includes(disabledFields, fieldNames.orSimilar)}
                />
                <PaddedTextarea
                    name={fieldNames.description}
                    placeholder='Description'
                    label='Description *'
                    size={TextareaSize.Medium}
                    value={
                        formSaver.formValues.get[
                            fieldNames.description
                        ] as string
                    }
                    onChange={handleFormChange}
                    error={formSaver.formErrors?.[fieldNames.description]}
                    expandable
                    disabled={includes(disabledFields, fieldNames.description)}
                />
                {!(productName === 'itinerary') ? (
                    <PaddedInput
                        name={fieldNames.checkInDay}
                        label='Check In Day'
                        size={ComponentWidth.Small}
                        value={
                            formSaver.formValues.get[
                                fieldNames.checkInDay
                            ] as string
                        }
                        onChange={handleFormChange}
                        disabled={includes(
                            disabledFields,
                            fieldNames.checkInDay,
                        )}
                    />
                ) : null}
                <StyledServiceFileInput
                    className=''
                    detail=''
                    error=''
                    key=''
                    name={fieldNames.images}
                    value={formSaver.formValues.get[fieldNames.images]}
                    onChange={handleFormChange}
                    label='Upload images'
                    maxFiles='10'
                    disabled={includes(disabledFields, fieldNames.images)}
                />
                <SaveToLibraryCheckbox
                    value={
                        formSaver.formValues.get[
                            fieldNames.saveToLibrary
                        ] as boolean
                    }
                    onChange={handleFormChange}
                    disabled={includes(
                        disabledFields,
                        fieldNames.saveToLibrary,
                    )}
                />
                {errorText ? (
                    <Grid>
                        <Grid.Item columnSpan={12}>
                            <Danger>{errorText}</Danger>
                        </Grid.Item>
                    </Grid>
                ) : null}
                <ActionButtons
                    serviceType={formSaver.formValues.get.type as ServiceType}
                    closeForm={closeForm}
                    submitForm={() => formSaver.handleFormSubmit(undefined)}
                    onRemoveAction={onRemoveAction}
                    isNewEntity={isNew}
                    permissions={permissions}
                />
            </Card>
        </Form>
    );
};

export default AccommodationCardForm;
