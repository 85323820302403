import { coreUtils } from '@trova-trip/trova-common';
import { Field, useBreakpoint } from '@trova-trip/trova-components';

const { formatUSD } = coreUtils.currencyUtils;

type PrivateRoomUpgradeProps = {
    price: number;
    tripDates: string;
};

const PrivateRoomUpgrade = ({
    price,
    tripDates,
}: PrivateRoomUpgradeProps): JSX.Element => {
    const isLargeScreen = useBreakpoint('sm');

    return (
        <Field
            name='isUpgradeToPrivateRoomSelected'
            as='lineItem'
            type='switch'
            description='This will be an updated private room for the duration of the trip. Private room upgrade is subject to change based on availability.'
            descriptionMaxWidth='80%'
            headerProps={{
                price: `${formatUSD(price, 2)}`,
                subtitle: isLargeScreen ? tripDates : undefined,
            }}
            title='Upgrade to Private Room'
        />
    );
};

export default PrivateRoomUpgrade;
