import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Text, BaseBox, Heading } from '@trova-trip/trova-components';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import {
    StyledDownloadButton,
    StyledHeaderContainer,
    StyledItem,
    StyledPaginationContainer,
    StyledPDFContainer,
    StyledPaginationButton,
    shouldBeVisible,
} from './PDFViewer.components';

const usePDFViewer = () => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [fileAvailable, setFileAvailable] = useState(false);

    const handleSuccess = useCallback(({ numPages }) => {
        setNumPages(numPages);
        setFileAvailable(true);
    }, []);

    const handlePrevious = useCallback((pageNumber) => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    }, []);

    const handleNext = useCallback((pageNumber, numPages) => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    }, []);

    return {
        numPages,
        pageNumber,
        fileAvailable,
        handleSuccess,
        handlePrevious,
        handleNext,
    };
};

const PDFViewer = ({ title = '', description = '', url, downloadButton }) => {
    const {
        numPages,
        pageNumber,
        fileAvailable,
        handleSuccess,
        handlePrevious,
        handleNext,
    } = usePDFViewer();

    return (
        <>
            <StyledHeaderContainer>
                <StyledItem>
                    {title && <Heading as={'h3'}>{title}</Heading>}
                    {description && <Text size={'lg'}>{description}</Text>}
                </StyledItem>
                <StyledItem>
                    {downloadButton && fileAvailable && (
                        <a
                            href={url}
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <StyledDownloadButton>
                                Download PDF
                            </StyledDownloadButton>
                        </a>
                    )}
                </StyledItem>
            </StyledHeaderContainer>
            <StyledPDFContainer>
                <Document file={url} onLoadSuccess={handleSuccess}>
                    <Page pageNumber={pageNumber} />
                </Document>
                {fileAvailable && (
                    <StyledPaginationContainer>
                        <StyledPaginationButton
                            className={shouldBeVisible(pageNumber !== 1)}
                            onClick={() => handlePrevious(pageNumber)}
                        >
                            Previous
                        </StyledPaginationButton>
                        <BaseBox paddingX={15}>
                            <Text size={'lg'} fontWeight={'bold'}>
                                Page {pageNumber} of {numPages}
                            </Text>
                        </BaseBox>
                        <StyledPaginationButton
                            className={shouldBeVisible(pageNumber !== numPages)}
                            onClick={() => handleNext(pageNumber, numPages)}
                        >
                            Next
                        </StyledPaginationButton>
                    </StyledPaginationContainer>
                )}
            </StyledPDFContainer>
        </>
    );
};

PDFViewer.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    url: PropTypes.string.isRequired,
    downloadButton: PropTypes.bool,
};

export default PDFViewer;
