import {
    ManageBookingTabName,
    paths,
} from '../../traveler/tabs/ManageBooking/config';

const getManageBookingPath = (
    tripId: string,
    appPath: string,
    tab: ManageBookingTabName = 'root',
): string => {
    const productPath = paths.product.replace(':tripId', tripId);
    const tabPath = paths.tab[tab];

    return `${appPath}${productPath}${tabPath}`;
};

export { getManageBookingPath };
