import * as yup from 'yup';
import { JourneyFormFields } from '../components/EditJourneyForm';
import contactSchema from './contact';
import { transferFieldsValidation } from './travelDetail';

const { firstName, lastName, email, phone } = contactSchema.fields;
const { flightNumber } = transferFieldsValidation.fields;

const schema: yup.SchemaOf<JourneyFormFields> = yup.object({
    flightNumber,
    firstName,
    lastName,
    email,
    phone,
});

export default schema;
