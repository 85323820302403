import { includesPathname } from '../../../../../navigation/common/helpers';

/**
 * Checks if the current location it's the payment page.
 *
 * @returns boolean
 */
const isPaymentPage = () => {
    return includesPathname('/payment');
};

/**
 * Checks if the current location it's the success page.
 *
 * @returns boolean
 */
const isSuccessPage = () => {
    return includesPathname('/success');
};

export { isPaymentPage, isSuccessPage };
