import { Grid, Heading, Stack } from '@trova-trip/trova-components';
import { useSelector } from '../../../../../../../state/hooks';
import { FieldName } from '../constants';
import { fieldNameWithPrefix } from '../helpers';
import CurrencyField from './CurrencyField';
import HelpTooltip from './HelpTooltip';

interface TransferFieldsProps {
    currencyCode: string;
    isDisabled?: boolean;
}

const TransferFields = ({ currencyCode, isDisabled }: TransferFieldsProps) => {
    const itinerary = useSelector((state) => state.userItinerary.current);

    if (itinerary?.hostGroundTransferCost) {
        return null;
    }

    return (
        <>
            <Grid.Item columnSpan={12}>
                <Stack align='center' paddingTop={4}>
                    <Heading
                        as='h5'
                        fontWeight='medium'
                        fontSize={{ xs: 'base', md: 'lg' }}
                    >
                        Private Transfers
                    </Heading>
                    <HelpTooltip text='Please provide the price for private airport transfers. ' />
                </Stack>
            </Grid.Item>
            <Grid.Item columnSpan={6}>
                <CurrencyField
                    name={fieldNameWithPrefix(FieldName.PreTransferPrice)}
                    label='Pre-trip'
                    currencyCode={currencyCode}
                    isDisabled={isDisabled}
                />
            </Grid.Item>
            <Grid.Item columnSpan={6}>
                <CurrencyField
                    name={fieldNameWithPrefix(FieldName.PostTransferPrice)}
                    label='Post-trip'
                    currencyCode={currencyCode}
                    isDisabled={isDisabled}
                />
            </Grid.Item>
        </>
    );
};

export default TransferFields;
