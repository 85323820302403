import React, { useRef } from 'react';
import {
    Avatar,
    BaseBox,
    BaseBoxProps,
    Hide,
    Stack,
    Text,
    useTextCollapse,
} from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';

type RecommendationQuoteCardVariants = 'primary' | 'secondary';
export interface RecommendationQuoteCardProps {
    name?: string;
    profilePictureUrl?: string;
    quote?: string;
    variant?: RecommendationQuoteCardVariants;
    containerProps?: Partial<
        Pick<
            BaseBoxProps,
            | 'padding'
            | 'paddingX'
            | 'paddingY'
            | 'paddingBottom'
            | 'paddingLeft'
            | 'paddingRight'
            | 'paddingTop'
        >
    >;
}

const recommendationQuoteCardVariantsProps: Record<
    RecommendationQuoteCardVariants,
    Pick<BaseBoxProps, 'backgroundColor'>
> = {
    primary: {
        backgroundColor: 'blueGray.offWhite',
    },
    secondary: {
        backgroundColor: 'neutral.white',
    },
};

const MAXIMUM_DISPLAY_LINES = 4;

const RecommendationQuoteCard = ({
    name,
    profilePictureUrl,
    quote,
    variant = 'secondary',
    containerProps,
}: RecommendationQuoteCardProps): JSX.Element => {
    const quoteRef = useRef<HTMLDivElement>(null);
    const { shouldDisplayButton, isContentExpanded, toggleContentExpansion } =
        useTextCollapse(quoteRef, quote);

    return (
        <BaseBox
            borderRadius='base'
            {...recommendationQuoteCardVariantsProps[variant]}
            {...containerProps}
        >
            <Stack wrap='nowrap' spacing={4}>
                <Avatar size='md' src={profilePictureUrl} />
                <BaseBox>
                    <Text fontWeight='medium'>{name}&apos;s Suggestion</Text>
                    <Text
                        ref={quoteRef}
                        fontSize={{ base: 'base', md: 'sm' }}
                        lineHeight='normal'
                        noOfLines={{
                            base: undefined,
                            md: isContentExpanded
                                ? undefined
                                : MAXIMUM_DISPLAY_LINES,
                        }}
                        css={{ overflowWrap: 'anywhere' }}
                    >
                        {quote}
                    </Text>
                    <Hide breakpoint={{ max: 'md' }}>
                        {shouldDisplayButton ? (
                            <Button
                                variant='tertiary'
                                color='blueGray.600'
                                onClick={toggleContentExpansion}
                            >
                                {isContentExpanded ? 'Read Less' : 'Read More'}
                            </Button>
                        ) : null}
                    </Hide>
                </BaseBox>
            </Stack>
        </BaseBox>
    );
};

export default RecommendationQuoteCard;
