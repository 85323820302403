import { models } from '@trova-trip/trova-models';
import StickyContentBase from '../../../../components/StickyContent/StickyContentBase';
import TripPricingCard from '../../../../components/TripPricingCard/TripPricingCard';

type SuggestedSellPrice = models.itineraries.SuggestedSellPrice;
interface StickyContentProps {
    value?: SuggestedSellPrice;
    isRequestEditable: boolean;
}

const StickyContent = (props: StickyContentProps): JSX.Element => {
    const { value, isRequestEditable } = props;

    return (
        <div>
            {isRequestEditable ? (
                <TripPricingCard title='Cost Per Traveler' value={value} />
            ) : (
                <StickyContentBase
                    description={
                        'Your trip request is actively being worked on and is no longer editable. You will be able to configure your trip after your request is approved by our operator.'
                    }
                />
            )}
        </div>
    );
};

export default StickyContent;
