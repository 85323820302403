import { useReducer } from 'react';
import { formatErrors, ValidationError } from '../../../utils/formErrors.utils';
import { getValidationSchemaForStep } from '../onboardingStepperErrors.util';
import { Bio, Socials, STEP_NAME } from '../types';

type OnboardingStepperStateValue<TValue> = {
    value: TValue;
    errors?: ValidationError;
};

type OnboardingStepperState = {
    [STEP_NAME.INTERESTS]: OnboardingStepperStateValue<string[]>;
    [STEP_NAME.SOCIALS]: OnboardingStepperStateValue<Socials>;
    [STEP_NAME.BIO]: OnboardingStepperStateValue<Bio>;
};

type OnboardingStepperFields = keyof OnboardingStepperState;

type OnboardingStepperValues =
    OnboardingStepperState[OnboardingStepperFields]['value'];

enum ACTIONS {
    UPDATE_STATE = 'UPDATE_STATE',
    UPDATE_ERRORS = 'UPDATE_ERRORS',
}

type UpdatePayload = {
    [Key in OnboardingStepperFields]: {
        name: Key;
        value: OnboardingStepperState[Key]['value'];
    };
}[OnboardingStepperFields];

type ErrorPayload = {
    name: OnboardingStepperFields;
    value: ValidationError;
};

type Action =
    | { type: ACTIONS.UPDATE_STATE; payload: UpdatePayload }
    | { type: ACTIONS.UPDATE_ERRORS; payload: ErrorPayload };

interface UseHostOnboardingStateReturn {
    state: OnboardingStepperState;
    setState: <TStepName extends OnboardingStepperFields>(
        stepName: TStepName,
        value: OnboardingStepperState[TStepName]['value'],
    ) => void;
    isStepValid: <TStepName extends OnboardingStepperFields>(
        stepName: TStepName,
    ) => boolean;
}

const reducer = (
    state: OnboardingStepperState,
    action: Action,
): OnboardingStepperState => {
    switch (action.type) {
        case ACTIONS.UPDATE_STATE:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    value: action.payload.value,
                },
            };
        case ACTIONS.UPDATE_ERRORS:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    errors: action.payload.value,
                },
            };
        default:
            throw new Error('Invalid action type');
    }
};

const initialValues: OnboardingStepperState = {
    [STEP_NAME.INTERESTS]: {
        value: [],
    },
    [STEP_NAME.SOCIALS]: {
        value: {
            channels: {
                facebook: '',
                instagram: '',
                personalSite: '',
                tiktok: '',
                youtube: '',
            },
            socialEngagementUserId: '',
            isManualConnect: true,
        },
    },
    [STEP_NAME.BIO]: {
        value: { bio: '', profilePictureUrl: '' },
    },
};

const useHostOnboardingReducer = (): UseHostOnboardingStateReturn => {
    const [state, dispatch] = useReducer(reducer, initialValues);

    const setState = <TStepName extends OnboardingStepperFields>(
        stepName: TStepName,
        value: OnboardingStepperState[TStepName]['value'],
    ): void => {
        dispatch({
            type: ACTIONS.UPDATE_STATE,
            payload: {
                name: stepName,
                value,
            } as UpdatePayload,
        });
    };

    const setError = <TStepName extends OnboardingStepperFields>(
        stepName: TStepName,
        value: OnboardingStepperState[TStepName]['errors'],
    ): void => {
        dispatch({
            type: ACTIONS.UPDATE_ERRORS,
            payload: {
                name: stepName,
                value,
            } as ErrorPayload,
        });
    };

    const isStepValid = <TStepName extends OnboardingStepperFields>(
        stepName: TStepName,
    ): boolean => {
        const currentValidationSchema = getValidationSchemaForStep(stepName);

        if (!currentValidationSchema) return true;

        const { value } = state[stepName] || {};

        try {
            currentValidationSchema.validateSync(value, {
                abortEarly: false,
            });
            setError(stepName, undefined);
            return true;
        } catch (error) {
            const validationErrors = formatErrors(error);
            setError(stepName, validationErrors);
            return false;
        }
    };

    return {
        state,
        setState,
        isStepValid,
    };
};

export { useHostOnboardingReducer };
export type { OnboardingStepperValues, OnboardingStepperFields };
