import { coreUtils } from '@trova-trip/trova-common';
import { Text, TextProps } from '@trova-trip/trova-components';
import { isTraveler } from '../../../../helpers';
import { useTransfer } from '../../hooks';

const { formatUSD } = coreUtils.currencyUtils;

type JourneyPriceVariants = 'journey-preview' | 'booking-details';

interface JourneyPriceProps {
    price: number;
    variant?: JourneyPriceVariants;
}

const specificTextPropsFor: Record<
    JourneyPriceVariants,
    Pick<TextProps, 'fontSize' | 'fontWeight'>
> = {
    'journey-preview': {
        fontSize: 'sm',
        fontWeight: 'bold',
    },
    'booking-details': {
        fontSize: 'base',
        fontWeight: 'medium',
    },
};

const JourneyPrice = ({
    price,
    variant = 'booking-details',
}: JourneyPriceProps): JSX.Element | null => {
    const {
        globalState: { user },
    } = useTransfer();

    const isTravelerUser = isTraveler(user);

    if (!isTravelerUser) return null;

    return (
        <Text
            lineHeight='5'
            color='blueGray.800'
            {...specificTextPropsFor[variant]}
        >
            {formatUSD(price, 2)}
        </Text>
    );
};

export default JourneyPrice;
