import { models } from '@trova-trip/trova-models';
import { useEffect } from 'react';
import {
    isTabComplete,
    isTransferCompleteStatus,
    isTransferDetailStatusMissingQuoteSelection,
} from '../../../../../state/features/transfer/utils';
import { getGroundTransferServiceFromTrip } from '../../../helpers/transfer';
import {
    DefaultProps,
    trackBookGroundTransferEvent,
    trackCompleteContactStepEvent,
    trackCompleteTravelDetailStepEvent,
    trackCompleteVehicleStepEvent,
    trackPurchaseServiceEvent,
    trackStartGroundTransferBookingEvent,
} from '../utils/analytics.utils';
import useTransfer from './useTransfer';

type Itinerary = models.itineraries.Itinerary;

const useTransferFlowAnalytics = () => {
    const {
        globalState: { trip, booking, user },
        state: {
            arrival,
            departure,
            metadata: {
                status: transferStatus,
                tabs: {
                    travelDetail: { status: travelDetailTabStatus },
                    vehicle: { status: vehicleTabStatus },
                    contact: { status: contactTabStatus },
                },
            },
            payment: { total, insurance },
        },
    } = useTransfer();

    const defaultProps: DefaultProps = {
        tripId: trip.id,
        tripName: trip.name,
        bookingId: booking?._id,
        userId: user.id,
        userGroup: user.group,
        itineraryId: (trip.itinerary as Itinerary)?.id,
    };

    const groundTransferService = getGroundTransferServiceFromTrip(trip)!;

    useEffect(() => {
        if (transferStatus === 'started') {
            trackStartGroundTransferBookingEvent({
                ...defaultProps,
                tripStatus: trip.status,
            });
        }
    }, [transferStatus]);

    useEffect(() => {
        const isArrivalMissingQuoteSelection =
            isTransferDetailStatusMissingQuoteSelection(
                arrival.metadata.status,
            );

        const isDepartureMissingQuoteSelection =
            isTransferDetailStatusMissingQuoteSelection(
                departure.metadata.status,
            );

        if (
            isTabComplete(travelDetailTabStatus) &&
            (isArrivalMissingQuoteSelection || isDepartureMissingQuoteSelection)
        ) {
            trackCompleteTravelDetailStepEvent({
                ...defaultProps,
                groundTransferService,
                arrival,
                departure,
            });
        }
    }, [
        travelDetailTabStatus,
        arrival.metadata.status,
        departure.metadata.status,
    ]);

    useEffect(() => {
        if (isTabComplete(vehicleTabStatus)) {
            trackCompleteVehicleStepEvent({
                ...defaultProps,
                groundTransferService,
                arrival,
                departure,
                cartBalance: total,
            });
        }
    }, [vehicleTabStatus, total]);

    useEffect(() => {
        if (isTabComplete(contactTabStatus)) {
            trackCompleteContactStepEvent({
                ...defaultProps,
                groundTransferService,
                arrival,
                departure,
                cartBalance: total,
            });
        }
    }, [contactTabStatus]);

    useEffect(() => {
        const servicePrice = total - insurance.adjustment;

        if (isTransferCompleteStatus(transferStatus)) {
            trackBookGroundTransferEvent({
                ...defaultProps,
                groundTransferService,
                arrival,
                departure,
                cartBalance: total,
                paidBalance: total,
            });

            trackPurchaseServiceEvent({
                ...defaultProps,
                groundTransferService,
                servicePrice,
            });
        }
    }, [transferStatus]);
};

export default useTransferFlowAnalytics;
