import { models, constants } from '@trova-trip/trova-models';
import { VALID_ADDON_TYPES } from '../viewmodel/utils';

export const { ServiceType } = models.services;
export const { Occupancy, ServiceTiming } = constants.services;
export const { TravelerType } = constants.travelers;
export const { InsuranceStatus: InsuranceAddOnStatus } = constants.insurance;

type Trip = models.trips.Trip;
type Booking = models.bookings.Booking;
type BaseUser = models.users.BaseUser;
type User = models.users.User;
type DayServices = models.services.DayService;
type Service = models.services.Service & { added?: boolean };
type ServicesByDay = models.services.ServicesByDay;
type InsurancePolicyResponse = models.insurance.InsurancePolicyResponse;
type TripAddOn = models.bookings.BaseAddOn;
type BookingAddOn = models.bookings.AddOn;
type InsurancePolicyAddOnResponse =
    models.insurance.InsurancePolicyAddOnResponse;
type Accommodation = models.services.Accommodation;
type AirportTransfer = models.services.AirportTransfer;
type SavedBooking = models.bookings.SavedBooking;
type BookingWithTrip = Required<Omit<SavedBooking, 'trip'> & { trip: Trip }>;
type TravelerType = constants.travelers.TravelerType;
type UserTripsTraveler = models.travelers.TravelerDTO & {
    type: TravelerType;
};

export enum MANAGE_BOOKING_ADDON_STATUS {
    AVAILABLE = 'AVAILABLE',
    CONFIRMED = 'CONFIRMED',
    PENDING_ADDITION = 'PENDING_ADDITION',
    PENDING_REMOVAL = 'PENDING_REMOVAL',
}

type OccupancyType = typeof Occupancy[keyof typeof Occupancy];
type ServiceTimingType = typeof ServiceTiming[keyof typeof ServiceTiming];
type Traveler = typeof TravelerType[keyof typeof TravelerType];

type AddOnManageMode = 'default' | 'sideEffect' | 'secondarySideEffect';

type ManageBookingAddOn = Omit<models.bookings.AddOn, 'user'> & {
    status: MANAGE_BOOKING_ADDON_STATUS;
    user: models.bookings.AddOn['user'] | undefined;
    /**
     * This property is used to determine if the addon is managed by the user or by the system as a side effect
     *
     * "default" - The addon is managed by the user
     * "sideEffect" - The addon is managed by the system as a side effect of another addon.
     * "secondarySideEffect" - The addon is managed by the system as a secondary side effect of a sideEffect addon.
     */
    manageMode: AddOnManageMode;
};

type TravelersRoom = models.bookings.TravelersRoom;

type ManageBookingAddOnMap = {
    [addOnId: string]: ManageBookingAddOn;
};

type AccommodationsByTiming = {
    [Occupancy.single]: ManageBookingAddOn;
    [Occupancy.double]: ManageBookingAddOn;
    [Occupancy.twin]: ManageBookingAddOn;
};

type Accommodations = {
    [ServiceTiming.PRE_TRIP]?: AccommodationsByTiming;
    [ServiceTiming.POST_TRIP]?: AccommodationsByTiming;
    singleSupplement?: ManageBookingAddOn;
};

type Activities = {
    [userId: string]: ManageBookingAddOnMap;
};

type Transfers = {
    [userId: string]: {
        [ServiceTiming.PRE_TRIP]?: ManageBookingAddOn;
        [ServiceTiming.POST_TRIP]?: ManageBookingAddOn;
    };
};

type RoomOccupancies = {
    [Occupancy.single]: MANAGE_BOOKING_ADDON_STATUS;
    [Occupancy.double]: MANAGE_BOOKING_ADDON_STATUS;
    [Occupancy.twin]: MANAGE_BOOKING_ADDON_STATUS;
};

type TravelersRooms = {
    [roomId: number]: RoomOccupancies;
};

type ManageBookingViewModel = {
    currentTripId?: string;
    addOns: {
        accommodations: Accommodations;
        activities: Activities;
        transfers: Transfers;
        insurance: BookingAddOn[];
    };
    travelersRooms: TravelersRooms;
    travelersQuantity: number;
    defaultRoomOccupancy?: OccupancyType;
};

type AddOnType = typeof VALID_ADDON_TYPES[number];
type AddOnServiceType = typeof ServiceType[keyof typeof ServiceType];

type AddOnsPricing = {
    preTripTransferPrice: number;
    postTripTransferPrice: number;
    preTripAccommodationsPrice: number;
    postTripAccommodationsPrice: number;
};

export type {
    Accommodation,
    Accommodations,
    Activities,
    Transfers,
    TravelersRooms,
    ManageBookingViewModel,
    AirportTransfer,
    BaseUser,
    RoomOccupancies,
    AccommodationsByTiming,
    Booking,
    BookingAddOn,
    BookingWithTrip,
    DayServices,
    InsurancePolicyAddOnResponse,
    InsurancePolicyResponse,
    ManageBookingAddOn,
    ManageBookingAddOnMap,
    OccupancyType,
    SavedBooking,
    Service,
    ServiceTimingType,
    ServicesByDay,
    Traveler,
    TravelersRoom,
    Trip,
    TripAddOn,
    User,
    UserTripsTraveler,
    AddOnType,
    AddOnServiceType,
    AddOnsPricing,
    AddOnManageMode,
};
