import { models, constants } from '@trova-trip/trova-models';

export enum ItineraryInventoryItemStatus {
    AVAILABLE = 'available',
    RESERVED = 'reserved',
    CONFIRMED = 'confirmed',
    UNKNOWN = 'unknown',
}

export interface InventoryItemDateViewModel {
    weekday: string;
    iso: string;
    formatted: string;
}

type Companion = models.common.Companion;
type HostRoom = models.tripRequest.HostRoom;
type Service = models.services.Service;
type WorkshopSpace = models.services.WorkshopSpace;
type PrePostAccommodationsSelection =
    models.tripRequest.PrePostAccommodationsSelection;
type PrePostTransfersSelection = models.tripRequest.PrePostTransfersSelection;

export type PackageLevel = constants.itinerary.PackageLevel;
export type PackageFoodOptions = constants.itinerary.PackageFoodOptions;
export type PackageAvailability = models.itineraries.Availability;
export type ItineraryInventoryItemViewModel = {
    id: string;
    startDate: InventoryItemDateViewModel;
    endDate: InventoryItemDateViewModel;
    status: ItineraryInventoryItemStatus;
    isInstantApproval?: boolean;
    price: number;
};

export type AvailabilityByPackage<T = PackageAvailability> = Record<
    PackageLevel,
    T
>;

export type AvailabilityViewModel = PackageAvailability & {
    itineraryInventoryItemsByYear: Record<
        number,
        ItineraryInventoryItemViewModel[]
    >;

    // Flag that indicates if the inventory is enabled on the itinerary or not.
    isInventoryEnabled: boolean;

    // The dates that are enabled to pick as start date of the trip request. This property
    // will only have value if the inventory is enabled.
    enabledDates: string[];

    // Flag that indicates if the package is enabled by taking into account the inventory if it's enabled.
    isPackageEnabled: boolean;
};

export type AvailabilityViewModelByPackage =
    AvailabilityByPackage<AvailabilityViewModel>;

export const FormInputNames = {
    TRIP_THEME: 'category',
    START_DATE: 'startDate',
    QUANTITY_OF_HOSTS_ROOMS: 'quantityHostRooms',
    PRE_TRIP_ACCOMMODATIONS: 'accommodations.beforeTrip',
    POST_TRIP_ACCOMMODATIONS: 'accommodations.afterTrip',
    PRE_TRIP_TRANSFER: 'transfers.beforeTrip',
    POST_TRIP_TRANSFER: 'transfers.afterTrip',
    HOST_SELECTED_OPTIONAL_SERVICES: 'hostSelectedOptionalServices',
    ADDITIONAL_REQUESTS: 'additionalRequests',
    WORKSHOPS: 'selectedWorkshopSpaces',
    COMPANIONS: 'companions',
    HOST_ROOMS: 'hostRooms',
    SELECTED_PACKAGE: 'selectedPackage',
    DIETARY_RESTRICTIONS: 'dietaryRestrictions',
    PRE_POST_ACCOMMODATION_SELECTION: 'prePostAccommodationsSelection',
    PRE_POST_TRANSFER_SELECTION: 'prePostTransfersSelection',
};

export enum COMPANION_AGE_RANGE {
    BELOW_2 = '0-2',
    BETWEEN_3_AND_12 = '3-12',
    BETWEEN_13_AND_17 = '13-17',
    OVER_18 = '18+',
}
export type HandleHostSelectedOptionalServicesArgs = {
    service: Service;
    oldServiceId?: string;
    name: string;
    value: boolean;
};

export interface InitialFormData {
    basePrice: number;
    category?: string;
    tripLength: number;
    startDate: string;
    quantityHostRooms?: number;
    hostSelectedOptionalServices: HostSelectedOptionalService[];
    additionalRequests: string;
    selectedWorkshopSpaces: models.tripRequest.SelectedWorkshopSpace[];
    companions: Companion[];
    hostRooms: HostRoom[];
    selectedPackage: PackageLevel;
    dietaryRestriction?: PackageFoodOptions;
    prePostAccommodationsSelection: PrePostAccommodationsSelection;
    prePostTransfersSelection: PrePostTransfersSelection;
    itineraryInventoryItemId?: string;
}

export interface InitialTripRequestData {
    initialFormData: InitialFormData;
    itinerary: models.itineraries.Itinerary;
}

export type HostSelectedOptionalService = {
    numberOptingIn: number;
    service: Service;
    id: string;
};
export interface SelectedWorkshopSpaces {
    length: number;
    service: WorkshopSpace;
    id: string;
}
