import { AdminRecommendationLocations } from '../../../utils/trackingEvents.utils';
import CatalogHeaderBanner from './CatalogBanner';
import RecommendationBannerWrapper from '../../../components/RecommendationBanner/RecommendationBannerWrapper';
import { UseHostRecommendationsReturn } from '../../../hooks/useHostRecommendations';

interface CatalogRecommendedItinerariesBannerProps
    extends Pick<
        UseHostRecommendationsReturn,
        'getItineraryRecommendation' | 'recommendations'
    > {}

const CatalogRecommendedItinerariesBanner = ({
    recommendations,
    getItineraryRecommendation,
}: CatalogRecommendedItinerariesBannerProps) => {
    const hasRecommendations = !!recommendations;
    return hasRecommendations ? (
        <CatalogHeaderBanner
            mobileBannerProps={{
                containerProps: {
                    paddingBottom: 16,
                },
                title: 'Recommended Itineraries',
                iconProps: {
                    display: 'flex',
                    as: 'star',
                    variant: 'filled',
                    size: 'lg',
                    color: 'red.trova',
                },
            }}
        >
            <RecommendationBannerWrapper
                trackingDataLocation={AdminRecommendationLocations.BANNER}
                layoutType='itineraryCatalog'
                recommendations={recommendations}
                getItineraryRecommendation={getItineraryRecommendation}
            />
        </CatalogHeaderBanner>
    ) : null;
};

export default CatalogRecommendedItinerariesBanner;
