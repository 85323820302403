import {
    Row,
    Cell,
    StatusIndicator,
    Icon,
    IconName,
    theme,
} from '@trova-trip/trova-components';
import { CheckboxTdStyles } from './BookingsTables.styles';
import { FormattedBookingOrder } from './BookingsTables';
import { BookingStatus, getBookingStatusColor } from 'util/bookingUtils';
import { styledStatusColumn, stylePaidColumn } from './BookingsTableRow.styles';
import { BookingStatuses } from '../../../../config/constants';

interface BookingsTableRowProps {
    order: FormattedBookingOrder;
    minimized?: boolean;
    renderActionCell?: () => React.ReactNode;
}

const BookingsTableRow = ({
    order,
    minimized,
    renderActionCell,
}: BookingsTableRowProps): JSX.Element => {
    const { orderId, statusName, status, travelers, totalSpotsBooked } = order;
    const isMultipleRow = totalSpotsBooked > 1;

    const color = getBookingStatusColor(status as BookingStatus);

    return (
        <>
            {isMultipleRow && (
                <Row key={orderId}>
                    {!minimized && (
                        <Cell width={25} className={CheckboxTdStyles}>
                            {renderActionCell && renderActionCell()}
                        </Cell>
                    )}
                    {isMultipleRow && (
                        <Cell colSpan={8}>{travelers[0].lastName} Party</Cell>
                    )}
                </Row>
            )}
            {travelers.map((traveler, index) => {
                const {
                    completeName,
                    email,
                    gender,
                    age,
                    instagram,
                    location,
                    paidInFull,
                } = traveler;

                return (
                    <Row key={email + index}>
                        {!minimized &&
                            (isMultipleRow ? (
                                <Cell width={25} className={CheckboxTdStyles}>
                                    {''}
                                </Cell>
                            ) : (
                                <Cell width={25} className={CheckboxTdStyles}>
                                    {renderActionCell && renderActionCell()}
                                </Cell>
                            ))}
                        <Cell width={150}>{completeName}</Cell>
                        <Cell width={240}>{email}</Cell>
                        <Cell width={110}>{orderId}</Cell>
                        <Cell width={90}>{gender}</Cell>
                        <Cell width={50}>{age}</Cell>
                        <Cell width={130}>{instagram}</Cell>
                        <Cell width={90}>{location}</Cell>
                        {!minimized && (
                            <Cell width={150}>
                                <div className={styledStatusColumn}>
                                    <StatusIndicator color={color} />
                                    <span>{statusName}</span>
                                </div>
                            </Cell>
                        )}
                        <Cell width={150}>
                            {status !== BookingStatuses.WAITLIST && (
                                <div className={stylePaidColumn}>
                                    {paidInFull === 'Paid' && (
                                        <Icon
                                            name={IconName.OutlinedCheck}
                                            color={theme.colors.seaGreen.trova}
                                        />
                                    )}
                                    {paidInFull === 'Partial' && (
                                        <Icon
                                            name={IconName.HalfCircle}
                                            color={theme.colors.orange[900]}
                                        />
                                    )}
                                    {paidInFull === 'Unpaid' && (
                                        <Icon
                                            name={IconName.OutlinedCircle}
                                            color={theme.colors.neutral[60]}
                                        />
                                    )}
                                    <span>{paidInFull}</span>
                                </div>
                            )}
                        </Cell>
                    </Row>
                );
            })}
        </>
    );
};

export default BookingsTableRow;
