import NavigationContext from '../../NavigationContext';
import { useCallback, useContext } from 'react';

type UseProfileNavigationDrawerReturn = {
    isDrawerOpen: boolean;
    toggleDrawer: () => void;
};

const useProfileNavigationDrawer = (): UseProfileNavigationDrawerReturn => {
    const context = useContext(NavigationContext);
    const {
        drawer: { isDrawerOpen, setDrawerOpen, setDrawerClose },
    } = context;

    const toggleDrawer = useCallback(() => {
        isDrawerOpen ? setDrawerClose() : setDrawerOpen();
    }, [isDrawerOpen]);

    return {
        isDrawerOpen,
        toggleDrawer,
    };
};

export default useProfileNavigationDrawer;
