import React from 'react';
import { PublicProfileFieldNames, FieldMap } from './types';
import {
    BioPreview,
    BioEditField,
    DisplayNamePreview,
    DisplayNameEditField,
    InterestsPreview,
    InterestsEditField,
    LanguagesPreview,
    LanguagesEditField,
    CountryPreview,
    CountryEditField,
    PronounsPreview,
    PronounsEditField,
    SocialChannelsPreview,
    SocialChannelsEditField,
} from '../components/fields';
import { PUBLIC_PROFILE_TRACKING_EVENTS } from '../../../hooks/usePublicProfileAnalytics';
import {
    formatSocialChannels,
    truncatePersonalSite,
} from '../../../../common/helpers/socialChannels';

export const MAX_BIO_LENGTH = 350;

export const FIELD_MAP: FieldMap = {
    [PublicProfileFieldNames.BIO]: {
        title: 'Bio',
        description: {
            text: 'Write a short bio to introduce yourself.',
        },
        isRequired: true,
        preview: BioPreview,
        editField: BioEditField,
    },
    [PublicProfileFieldNames.DISPLAY_NAME]: {
        title: 'Display Name',
        description: {
            text: 'A display name is shown instead of your first and last name.',
        },
        preview: DisplayNamePreview,
        editField: DisplayNameEditField,
    },
    [PublicProfileFieldNames.INTERESTS]: {
        title: 'Interests',
        description: {
            text: 'Select at least one interest to personalize your TrovaTrip experience.',
        },
        preview: InterestsPreview,
        editField: InterestsEditField,
    },
    [PublicProfileFieldNames.LANGUAGES]: {
        title: 'Languages',
        description: {
            subtitle: 'What languages do you speak?',
            text: 'Languages will be shown on your profile so other people who speak them can connect with you.',
        },
        preview: LanguagesPreview,
        editField: LanguagesEditField,
    },
    [PublicProfileFieldNames.COUNTRY]: {
        title: 'Country',
        preview: CountryPreview,
        editField: CountryEditField,
    },
    [PublicProfileFieldNames.PRONOUNS]: {
        title: 'Pronouns',
        description: {
            text: 'Pronouns will be shown on your profile so other people in the Trova community can address you correctly. If you prefer not to share your pronouns, you can leave this blank.',
        },
        preview: PronounsPreview,
        editField: PronounsEditField,
    },
    [PublicProfileFieldNames.SOCIAL_CHANNELS]: {
        title: 'Social Channels',
        description: {
            text: 'Provide at least one social handle so we can find and connect with you.',
        },
        preview: SocialChannelsPreview,
        editField: SocialChannelsEditField,
        formatDataBeforeSubmit: (channels) =>
            formatSocialChannels(channels, truncatePersonalSite),
    },
};

export const FIELD_MAP_TRACKING_EVENTS: Record<
    PublicProfileFieldNames,
    PUBLIC_PROFILE_TRACKING_EVENTS
> = {
    [PublicProfileFieldNames.PROFILE_PICTURE]:
        PUBLIC_PROFILE_TRACKING_EVENTS.clickedEditImage,

    [PublicProfileFieldNames.BIO]:
        PUBLIC_PROFILE_TRACKING_EVENTS.clickedEditBioIcon,
    [PublicProfileFieldNames.DISPLAY_NAME]:
        PUBLIC_PROFILE_TRACKING_EVENTS.clickedEditDisplayNameIcon,
    [PublicProfileFieldNames.INTERESTS]:
        PUBLIC_PROFILE_TRACKING_EVENTS.clickedEditInterestsIcon,
    [PublicProfileFieldNames.LANGUAGES]:
        PUBLIC_PROFILE_TRACKING_EVENTS.clickedEditLanguagesIcon,
    [PublicProfileFieldNames.COUNTRY]:
        PUBLIC_PROFILE_TRACKING_EVENTS.clickedEditCountryIcon,
    [PublicProfileFieldNames.PRONOUNS]:
        PUBLIC_PROFILE_TRACKING_EVENTS.clickedEditPronounsIcon,
    [PublicProfileFieldNames.SOCIAL_CHANNELS]:
        PUBLIC_PROFILE_TRACKING_EVENTS.clickedEditSocialChannelsIcon,
};
