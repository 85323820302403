import { css } from '@emotion/css';

export const styledAutoConfirmToggle = css`
    display: flex;
    align-items: center;
    margin-left: 2rem;
`;

export const containerTableStyles = css`
    > div {
        margin: 0;
    }
`;

export const tableStyles = css`
    table {
        white-space: inherit;
    }
    && {
        thead > tr > th,
        td {
            padding: 16px 8px;
        }
        thead > tr > th:first-child,
        td:first-child {
            padding: 16px 8px 16px 16px;
        }
        thead > tr > th:last-child,
        td:last-child {
            padding: 16px 16px 16px 8px;
        }
    }
    th:nth-child(2),
    td:nth-child(2) {
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 150px;
    }
    th:nth-child(3),
    td:nth-child(3) {
        max-width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 240px;
    }
`;

export const CheckboxTdStyles = css`
    min-width: 51px;
`;

export const styledContainer = css`
    && {
        margin-top: 2rem;
    }
`;
