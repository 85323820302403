import { Grid, Heading } from '@trova-trip/trova-components';
import capitalize from 'lodash/capitalize';
import { AccommodationOccupancy } from '../constants';
import {
    fieldNameWithPrefix,
    getAccommodationFieldName,
    getAccommodationTimingPrefix,
} from '../helpers';
import { ServiceTiming } from '../types';
import CurrencyField from './CurrencyField';

interface AccommodationFieldsProps {
    timing: ServiceTiming;
    currencyCode: string;
    isDisabled?: boolean;
}

const AccommodationFields = ({
    timing,
    currencyCode,
    isDisabled,
}: AccommodationFieldsProps) => {
    const prefix = getAccommodationTimingPrefix(timing);
    const timingTitle = capitalize(`${prefix}-trip Accommodations`);

    const singleRoomName = getAccommodationFieldName(
        timing,
        AccommodationOccupancy.SINGLE,
    );
    const doubleRoomName = getAccommodationFieldName(
        timing,
        AccommodationOccupancy.DOUBLE,
    );

    return (
        <Grid.Item columnSpan={{ xs: 12, md: 6 }}>
            <Heading
                as='h5'
                fontWeight='medium'
                fontSize={{ xs: 'base', md: 'lg' }}
                paddingY={6}
            >
                {timingTitle}
            </Heading>
            <Grid>
                <Grid.Item columnSpan={6}>
                    <CurrencyField
                        name={fieldNameWithPrefix(singleRoomName)}
                        label='Single Room'
                        currencyCode={currencyCode}
                        isDisabled={isDisabled}
                    />
                </Grid.Item>
                <Grid.Item columnSpan={6}>
                    <CurrencyField
                        name={fieldNameWithPrefix(doubleRoomName)}
                        label='Double Room'
                        currencyCode={currencyCode}
                        isDisabled={isDisabled}
                    />
                </Grid.Item>
            </Grid>
        </Grid.Item>
    );
};

export default AccommodationFields;
