import addDays from 'date-fns/addDays';
import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';
import { formatDateToString } from '../../../../common/helpers';
import { Activities, Service, ServicesByDay } from '../types';

/**
 * Finds and formats the date when an activity is scheduled
 *
 * @param serviceId - The ID of the activity service to find the date for.
 * @param startDate - The start date of the trip.
 * @param servicesByDay - An array of services grouped by day.
 * @returns The formatted date (MMM dd) of the activity if found.
 */
const getActivityDate = (
    serviceId: string,
    startDate?: Date,
    servicesByDay?: ServicesByDay,
): string => {
    if (!serviceId || !servicesByDay) {
        return '';
    }

    let activityDate = '';

    servicesByDay.forEach((dayServices, dayIndex) => {
        dayServices.forEach((service) => {
            const activity = service.service as Service;
            if (activity._id === serviceId && startDate) {
                activityDate = formatDateToString(
                    addDays(new Date(startDate), dayIndex + 1),
                );
            }
        });
    });

    return activityDate;
};

/**
 * Sorts activities by date and name inside each traveler id key.
 *
 * @param activities
 * @param tripStartDate
 * @param servicesByDay - Of the trip.
 * @returns Sorted activities for each user.
 */
const sortActivitiesByDateThenName = (
    activities: Activities,
    tripStartDate: Date | undefined,
    servicesByDay: ServicesByDay | undefined,
): Activities =>
    mapValues(activities, (userActivities) =>
        mapValues(
            sortBy(userActivities, (activity) => [
                getActivityDate(
                    (activity.service as Service)?._id || '',
                    tripStartDate,
                    servicesByDay,
                ),
                (activity.service as Service).name,
            ]),
        ),
    );

export { getActivityDate, sortActivitiesByDateThenName };
