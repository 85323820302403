import { Currency } from '@trova-trip/country-data';
import { BaseBox } from '@trova-trip/trova-components';
import { Dialog } from '@trova-trip/trova-components/build/next';
import { useSelector } from 'state/hooks';
import { tripHasItineraryInventoryItem } from '../../../../../../applications/common/helpers';
import TripDatesDisplay from '../../../../tabs/ReviewPricing/Trip/TripDatesDisplay';
import { PricingBreakdown } from '../../utils';
import PricingTableExpanded from './PricingTableExpanded';

interface PricingTableModalProps {
    isOpen: boolean;
    onClose: () => void;
    pricingBreakdown: PricingBreakdown;
    currencyInfo: Currency;
}

const PricingTableModal = (props: PricingTableModalProps): JSX.Element => {
    const { isOpen, onClose, pricingBreakdown, currencyInfo } = props;
    const trip = useSelector((state) => state.userTrips.current);

    return (
        <Dialog
            width={{ base: '100vw', md: 'auto' }}
            height={{ base: '95vh', md: 'auto' }}
            position={{ base: 'centered', md: 'top' }}
            onClose={onClose}
            isOpen={isOpen}
            closeOnOverlayClick={true}
        >
            <Dialog.Header
                title={
                    tripHasItineraryInventoryItem(trip)
                        ? 'Price Breakdown'
                        : 'Suggested Price Breakdown'
                }
            />
            <Dialog.Body width='full' height={{ base: '80vh', md: 'auto' }}>
                <BaseBox position='relative' top={-5}>
                    {trip && trip.startDate && (
                        <TripDatesDisplay
                            startDate={trip?.startDate}
                            lengthOfTrip={trip?.servicesByDay?.length}
                            fontSize='sm'
                        />
                    )}
                </BaseBox>
                <PricingTableExpanded
                    pricingBreakdown={pricingBreakdown}
                    currencyInfo={currencyInfo}
                />
            </Dialog.Body>
        </Dialog>
    );
};

export default PricingTableModal;
