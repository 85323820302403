import { coreUtils } from '@trova-trip/trova-common';
import useModelStatusMatcher from 'applications/common/hooks/useModelStatusMatcher';
import { useGetHostDefaultTiers } from 'applications/host/hooks/useGetHostDefaultTiers';
import { StatefulModel } from 'applications/types';
import { UserStatuses } from 'config/constants';
import { NavigationCategory } from 'interfaces/Navigation.types';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { userItinerary } from 'state/userItinerary';
import getQueryParam from 'util/getQueryParam';
import Product from '../../../../navigation/Product';
import TripRequest from '../../tabs/TripRequest/TripRequest';
import ItinerariesCatalogHeader from './components/ItinerariesCatalogHeader';
import { QualifyingSidebar } from './QualifyingContent';
import { ItineraryDetails } from './tabs/ItineraryDetails';
import { WrappedTrovaCatalog } from './WrappedTrovaCatalog';
import { useSelector } from 'react-redux';
import { ProfileStoreSelector } from '../../../../state/profile';
import { useTrackingEvent } from '../../../../analytics/hooks';
import useHostRecommendations from '../../hooks/useHostRecommendations';
import {
    getAdminRecommendationTrackingData,
    AdminRecommendationLocations,
} from '../../utils/trackingEvents.utils';

const { getItineraryPageUrl } = coreUtils.itineraryUtils;

interface ItinerariesCatalogProps {
    qualifiedSidebarContent: NavigationCategory[] | undefined;
    refreshSidebarContent: () => void;
}

const preQualifiedStatuses = new Set([
    UserStatuses.CREATED,
    UserStatuses.READY_TO_QUALIFY,
    UserStatuses.QUALIFYING,
]);

const ItinerariesCatalog = ({
    qualifiedSidebarContent,
    refreshSidebarContent,
}: ItinerariesCatalogProps): JSX.Element => {
    const history = useHistory();
    const nextTier = getQueryParam(history, 'nextTier');
    const hostUnlockedTiers = useGetHostDefaultTiers(nextTier);
    const isQualifying: boolean = useModelStatusMatcher({
        matchingStatuses: preQualifiedStatuses,
        model: StatefulModel.PROFILE,
    });
    const currentUser = useSelector(
        (state: ProfileStoreSelector) => state.profile.current,
    );
    const { trackUserEvent } = useTrackingEvent();
    const { recommendations, getItineraryRecommendation } =
        useHostRecommendations();

    const loadNavigationContent = isQualifying
        ? QualifyingSidebar
        : (): NavigationCategory[] | undefined => qualifiedSidebarContent;

    //https://github.com/typescript-eslint/typescript-eslint/blob/v4.31.2/packages/eslint-plugin/docs/rules/unbound-method.md
    // if you do this userItinerary.useDispatch(), useDispatch may inadvertently bind the incorrect 'this'
    const { getRecord: getItinerary, clearCurrentRecord: clearItinerary } =
        userItinerary.useDispatch.bind(userItinerary)();
    const getItineraries = useCallback(
        (id) => getItinerary(id),
        [getItinerary],
    );

    const onItinerarySelected = (
        itineraryId: string,
        isInstantApprovalAvailable: boolean = false,
    ): void => {
        if (isInstantApprovalAvailable) {
            trackUserEvent({
                eventName: 'Host Selected Instant Approval Itinerary',
                properties: {
                    itineraryId: itineraryId,
                },
            });
        }
        const adminRecommendationTrackingData =
            getAdminRecommendationTrackingData(
                recommendations,
                getItineraryRecommendation(itineraryId),
                AdminRecommendationLocations.CATALOG,
            );

        if (adminRecommendationTrackingData) {
            trackUserEvent({
                eventName: 'Host Clicked Recommended Itinerary',
                properties: adminRecommendationTrackingData,
            });
        }

        const url = getItineraryPageUrl({
            type: 'portal',
            id: itineraryId,
            includeDomain: false,
        });

        history.push(url);
    };

    const clearModelData = (): void => {
        clearItinerary();
    };

    const filtersSelectedItems = {
        ['tier']: hostUnlockedTiers,
    };

    return (
        <Product
            path={`/itineraries`}
            fetchModelData={getItineraries}
            clearModelData={clearModelData}
            label={'Itineraries'}
            icon='route'
            loadNavigationContent={loadNavigationContent}
            header={<ItinerariesCatalogHeader />}
            root={
                <WrappedTrovaCatalog
                    onItinerarySelected={onItinerarySelected}
                    catalogProps={{
                        filtersSelectedItems: filtersSelectedItems,
                        currentUser: currentUser,
                    }}
                />
            }
        >
            <ItineraryDetails />

            <TripRequest refreshSidebarContent={refreshSidebarContent} />
        </Product>
    );
};

export default ItinerariesCatalog;
