import { Text, BaseBox } from '@trova-trip/trova-components';
import { Table } from '@trova-trip/trova-components/build/next';

type TitleCellProps = { label: string; subtitle?: string };

const TitleCell = (props: TitleCellProps) => {
    const { label, subtitle } = props;
    return (
        <Table.Cell>
            <BaseBox width={48}>
                <Text
                    as='pre'
                    fontSize='xs'
                    fontFamily='inherit'
                    letterSpacing='wide'
                    fontWeight='bold'
                    wordBreak='break-word'
                    whiteSpace='normal'
                >
                    {label}
                </Text>
                {subtitle ? (
                    <Text
                        size='xs'
                        letterSpacing='wide'
                        fontWeight='medium'
                        color='blueGray.500'
                    >
                        {subtitle}
                    </Text>
                ) : null}
            </BaseBox>
        </Table.Cell>
    );
};

export default TitleCell;
