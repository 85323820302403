import {
    MANAGE_BOOKING_ACTION,
    ManageBookingActions,
    ManageBookingState,
} from './types';
import {
    toggleAccommodationStatus,
    toggleActivityStatus,
    toggleTransferStatus,
    updateConfirmationMode,
    updateRoomOccupanciesStatus,
    updateTripSummary,
} from './utils';

const viewModelInitialState = {
    addOns: {
        activities: {},
        accommodations: {},
        transfers: {},
        insurance: [],
    },
    travelersRooms: {},
    travelersQuantity: 0,
};

const initialState: ManageBookingState = {
    ...viewModelInitialState,
    isLoading: false,
    isManageBookingFlowFinished: false,
    serviceInventory: {
        status: 'initial',
        singleSupplement: {
            available: 0,
        },
    },
    tripSummary: {
        isOpen: false,
        isConfirmationModalOpen: false,
        sections: {
            added: [],
            removed: [],
            roomSelection: null,
            priceAdjustments: [],
        },
        insuranceAdjustment: null,
        isLoading: false,
        total: 0,
        totalWithInsurance: 0,
        bookingPaymentData: {
            paidAmount: 0,
            dueAmount: 0,
        },
    },
    confirmation: {
        isDisabled: false,
        error: null,
    },
    insurance: {
        cancelAddOnId: undefined,
        status: 'initial',
        error: null,
        repricedAddOns: [],
    },
    confirmationMode: null,
    originalState: viewModelInitialState,
};

const manageBookingReducer = (
    state: ManageBookingState = initialState,
    action: ManageBookingActions,
): ManageBookingState => {
    switch (action.type) {
        case MANAGE_BOOKING_ACTION.INIT_VIEW_MODEL:
            return {
                ...initialState,
                ...action.payload,
                originalState: { ...action.payload },
            };

        case MANAGE_BOOKING_ACTION.RESET_STATE:
            return {
                ...state,
                ...state.originalState,
                currentTripId: undefined,
                tripSummary: initialState.tripSummary,
                confirmation: initialState.confirmation,
                confirmationMode: initialState.confirmationMode,
            };

        case MANAGE_BOOKING_ACTION.SET_IS_LOADING:
            return { ...state, isLoading: action.payload };

        case MANAGE_BOOKING_ACTION.TOGGLE_ACCOMMODATION:
            return {
                ...state,
                addOns: {
                    ...state.addOns,
                    accommodations: toggleAccommodationStatus({
                        state,
                        travelersQuantity: state.travelersQuantity,
                        params: action.payload,
                    }),
                },
            };

        case MANAGE_BOOKING_ACTION.TOGGLE_ACTIVITY:
            return {
                ...state,
                addOns: {
                    ...state.addOns,
                    activities: toggleActivityStatus({
                        activities: state.addOns.activities,
                        params: action.payload,
                    }),
                },
            };

        case MANAGE_BOOKING_ACTION.TOGGLE_TRANSFER:
            return {
                ...state,
                addOns: {
                    ...state.addOns,
                    transfers: toggleTransferStatus({
                        state,
                        params: action.payload,
                    }),
                },
            };

        case MANAGE_BOOKING_ACTION.UPDATE_ROOM_OCCUPANCIES:
            return updateRoomOccupanciesStatus({
                state,
                payload: action.payload,
            });

        case MANAGE_BOOKING_ACTION.TOGGLE_TRIP_SUMMARY_VISIBILITY:
            return {
                ...state,
                tripSummary: {
                    ...state.tripSummary,
                    isOpen: action.payload,
                },
            };

        case MANAGE_BOOKING_ACTION.UPDATE_TRIP_SUMMARY_SECTIONS: {
            const updatedTripSummary = updateTripSummary(state);

            return {
                ...state,
                tripSummary: updatedTripSummary,
                confirmationMode: updateConfirmationMode(updatedTripSummary),
            };
        }

        case MANAGE_BOOKING_ACTION.SET_INSURANCE_ADJUSTMENT:
            return {
                ...state,
                tripSummary: {
                    ...state.tripSummary,
                    insuranceAdjustment: action.payload.insuranceAdjustment,
                },
                insurance: {
                    ...state.insurance,
                    repricedAddOns: action.payload.repricedAddOns,
                },
            };

        case MANAGE_BOOKING_ACTION.TOGGLE_CONFIRMATION_MODAL:
            return {
                ...state,
                tripSummary: {
                    ...state.tripSummary,
                    isConfirmationModalOpen: action.payload,
                },
            };

        case MANAGE_BOOKING_ACTION.SET_BOOKING_PAYMENT_DATA:
            return {
                ...state,
                tripSummary: {
                    ...state.tripSummary,
                    bookingPaymentData: {
                        ...state.tripSummary.bookingPaymentData,
                        paidAmount: action.payload.paidAmount || 0,
                        dueAmount: action.payload.dueAmount,
                    },
                },
            };

        case MANAGE_BOOKING_ACTION.SET_CONFIRMATION_DATA:
            return {
                ...state,
                confirmation: {
                    isDisabled: action.payload.isDisabled || false,
                    error: action.payload.error,
                },
            };

        case MANAGE_BOOKING_ACTION.SET_TRIP_SUMMARY_IS_LOADING:
            return {
                ...state,
                tripSummary: {
                    ...state.tripSummary,
                    isLoading: action.payload,
                },
            };

        case MANAGE_BOOKING_ACTION.SET_CANCEL_INSURANCE_ADDON_ID:
            return {
                ...state,
                insurance: {
                    ...state.insurance,
                    cancelAddOnId: action.payload.addOnId,
                },
            };

        case MANAGE_BOOKING_ACTION.CANCEL_INSURANCE:
            return {
                ...state,
                insurance: {
                    ...state.insurance,
                    status: 'cancelling',
                },
            };

        case MANAGE_BOOKING_ACTION.SET_INSURANCE_DATA: {
            return {
                ...state,
                insurance: {
                    ...state.insurance,
                    status: action.payload.status,
                    error: action.payload.error,
                },
            };
        }

        case MANAGE_BOOKING_ACTION.SET_SINGLE_SUPPLEMENT_INVENTORY: {
            const { singleSupplement } = action.payload;
            return {
                ...state,
                serviceInventory: {
                    status: 'populated',
                    singleSupplement,
                },
            };
        }

        case MANAGE_BOOKING_ACTION.VERIFY_SERVICE_INVENTORY:
            return {
                ...state,
                serviceInventory: {
                    ...state.serviceInventory,
                    status: 'verifying',
                },
            };

        case MANAGE_BOOKING_ACTION.SET_IS_MANAGE_BOOKING_FLOW_FINISHED:
            return { ...state, isManageBookingFlowFinished: true };

        default:
            return state;
    }
};

export { initialState };
export default manageBookingReducer;
