import styled from '@emotion/styled';

export const StyledOperatorContactInfoDialogList = styled.ul`
    > li {
        margin-bottom: 1rem;
        h5 {
            font-size: 0.9rem;
        }
    }
`;
