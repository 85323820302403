import { Field, Grid } from '@trova-trip/trova-components';
import { FieldName } from '../constants';
import { fieldNameWithPrefix } from '../helpers';
import CurrencyField from './CurrencyField';

interface SingleSupplementFieldsProps {
    currencyCode: string;
    isDisabled?: boolean;
}

const SingleSupplementFields = ({
    currencyCode,
    isDisabled,
}: SingleSupplementFieldsProps) => (
    <>
        <Grid.Item columnSpan={6}>
            <CurrencyField
                isRequired
                name={fieldNameWithPrefix(FieldName.SsPrice)}
                label='Single Supplement'
                currencyCode={currencyCode}
                isDisabled={isDisabled}
            />
        </Grid.Item>
        <Grid.Item columnSpan={6}>
            <Field
                isRequired
                as='number'
                name={fieldNameWithPrefix(FieldName.SsQtyAvailable)}
                label='Single Supp. Quantity'
                min={0}
                isDisabled={isDisabled}
            />
        </Grid.Item>
    </>
);

export default SingleSupplementFields;
