import FetchState from './utils/FetchState';

const auditLogs = FetchState.create(`api/admin/audit-logs`);
const auditLogsMetadata = FetchState.create(`api/metadata/audit-logs`);

const auditLogsReducer = auditLogs.getReducer();
const auditLogsMetadataReducer = auditLogsMetadata.getReducer();

export {
    auditLogs,
    auditLogsMetadata,
    auditLogsReducer,
    auditLogsMetadataReducer,
};
