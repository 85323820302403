import { css } from '@emotion/css';
import styled from '@emotion/styled';
import {
    Checkbox,
    BaseBox,
    Dropdown,
    Input,
    theme,
    Heading,
} from '@trova-trip/trova-components';

export const SectionTitle = ({ children }) => {
    return (
        <BaseBox marginTop={12}>
            <Heading as={'h4'}>{children}</Heading>
        </BaseBox>
    );
};

export const SectionSubTitle = ({ children }) => {
    return (
        <BaseBox marginTop={4}>
            <Heading as={'h5'}>{children}</Heading>
        </BaseBox>
    );
};

export const StyledDanger = styled.div`
    font-family: ${theme.fonts.roboto};
    color: ${theme.colors.red.trova}; ;
`;

export const StyledInputHeight = styled(Input)`
    > div {
        height: inherit;
    }
`;

export const StyleCheckbox = styled(Checkbox)`
    margin-bottom: 0.75rem;
`;

export const StyledDropdown = styled(Dropdown)`
    width: auto;
    > div:last-child {
        input {
            width: 100%;
        }
    }
`;
