import { useMemo } from 'react';
import { BaseBox, Text, Stack } from '@trova-trip/trova-components';
import Item, { ItemProps } from './Item';

interface ListItemStripBarProps {
    items: ItemProps[];
    activeItemText: string;
    showItemLabelOnlyOnTheActive?: boolean;
}

const ListItemStripBar = (props: ListItemStripBarProps): JSX.Element => {
    const {
        items,
        activeItemText,
        showItemLabelOnlyOnTheActive = true,
    } = props;

    const itemsConfigured = useMemo(
        () =>
            items.map((item) => {
                if (!showItemLabelOnlyOnTheActive) return item;
                return { ...item, label: item.isActive ? item?.label : '' };
            }),
        [items, showItemLabelOnlyOnTheActive],
    );

    return (
        <Stack
            spacing={{ base: 0, md: 4 }}
            direction='row'
            align='center'
            justify='stretch'
            wrap='nowrap'
        >
            {itemsConfigured.map((item, index) => (
                <BaseBox
                    key={index}
                    display={{
                        base: item.isActive ? 'block' : 'none',
                        md: 'block',
                    }}
                >
                    <BaseBox minHeight='1rem' marginBottom={1}>
                        {item.isActive && (
                            <Text
                                size='sm'
                                textTransform='uppercase'
                                color='teal.800'
                            >
                                {activeItemText}
                            </Text>
                        )}
                    </BaseBox>
                    <Item {...item} />
                </BaseBox>
            ))}
        </Stack>
    );
};

export default ListItemStripBar;
