import React from 'react';
import { Text } from '@trova-trip/trova-components';

const Danger = ({ children }: { children: React.ReactNode }) => (
    <Text color='red.500' size='sm'>
        {children}
    </Text>
);

export default Danger;
