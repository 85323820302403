import { SummaryItem } from '../../../../../state/features/manageBooking/types';
import {
    Accommodations,
    AddOnsPricing,
    MANAGE_BOOKING_ADDON_STATUS,
    ManageBookingAddOn,
    ManageBookingViewModel,
    Occupancy,
    RoomOccupancies,
} from '../types';
import {
    getPrePostAccommodationsPrice,
    getTransfersAddOnsPrices,
    getPrePostRoomsOccupancies,
    getAddOnsWithNoSideEffects,
} from './extract.utils';
import { isUpgradeToPrivateRoomSelected as checkIsUpgradeToPrivateRoomSelected } from './check.utils';
import mapValues from 'lodash/mapValues';

/**
 * Gets the pricing for the pre and post addOns based on the state
 *
 * @param state
 * @returns An object with the pricing for the pre and post addOns
 */
const getPricingFromState = (state: ManageBookingViewModel): AddOnsPricing => {
    const {
        travelersRooms,
        travelersQuantity,
        addOns: { accommodations, transfers },
        defaultRoomOccupancy,
    } = state;

    const roomsOccupancies = getPrePostRoomsOccupancies({
        rooms: travelersRooms,
        travelersQuantity,
        singleSupplement: accommodations?.singleSupplement,
        defaultRoomOccupancy,
    });

    const isUpgradeToPrivateRoomSelected = checkIsUpgradeToPrivateRoomSelected(
        accommodations?.singleSupplement,
        travelersQuantity,
    );

    const {
        preTripPrice: preTripAccommodationsPrice,
        postTripPrice: postTripAccommodationsPrice,
    } = getPrePostAccommodationsPrice({
        isUpgradeToPrivateRoomSelected,
        accommodations,
        roomsOccupancies,
        travelersRooms,
        travelersQuantity,
    });

    const {
        preTripPrice: preTripTransferPrice,
        postTripPrice: postTripTransferPrice,
    } = getTransfersAddOnsPrices(transfers);

    return {
        preTripAccommodationsPrice,
        postTripAccommodationsPrice,
        preTripTransferPrice,
        postTripTransferPrice,
    };
};

/**
 * Gets the price adjustments for the pre and post addOns
 *
 * @param originalPricing
 * @param currentPricing
 * @returns An object with the price adjustments for the pre and post addOns
 */
const getPreAndPostAddOnsPriceAdjustments = (
    originalPricing: AddOnsPricing,
    currentPricing: AddOnsPricing,
): Partial<AddOnsPricing> => {
    const priceAdjustments = mapValues(originalPricing, (price, key) => {
        const currentPrice = currentPricing[key];
        return currentPrice - price;
    });

    return priceAdjustments;
};

const toNegative = (value: number): number => {
    return -value;
};

/**
 * Calculates the subtotal of the provided add-ons, taking into account only the add-ons with no side effects.
 *
 * @param addOns
 * @return the subtotal of the add-ons with no side effects
 */
const calculateAddOnsSubTotal = (addOns: ManageBookingAddOn[]): number => {
    const addOnsWithNoSideEffects = getAddOnsWithNoSideEffects(addOns);

    return (
        addOnsWithNoSideEffects?.reduce((subtotal, addOn) => {
            return subtotal + (addOn.unitPriceWithFee * addOn.quantity || 0);
        }, 0) || 0
    );
};

const calculatePriceAdjustmentsSubTotal = (
    priceAdjustments: SummaryItem[],
): number => {
    return priceAdjustments?.reduce((subtotal, priceAdjustment) => {
        return subtotal + (priceAdjustment.price || 0);
    }, 0);
};

/**
 * Calculates the price adjustment for the single supplement add-on
 *
 * @param room
 * @param singleSupplementAddOn
 * @returns The price adjustment for the single supplement add-on
 */
const calculateRoomSingleSupplementPriceAdjustment = (
    room: Partial<RoomOccupancies>,
    singleSupplementAddOn: Accommodations['singleSupplement'],
): number => {
    const roomOccupancies = Object.keys(room);
    const singleOccupancies = roomOccupancies.filter(
        (occupancy) => occupancy === Occupancy.single,
    );
    const shouldUseNegativeValue =
        room[Occupancy.single] === MANAGE_BOOKING_ADDON_STATUS.PENDING_REMOVAL;

    const unitPriceWithFee = singleSupplementAddOn?.unitPriceWithFee || 0;

    const priceAdjustment = shouldUseNegativeValue
        ? toNegative(unitPriceWithFee)
        : unitPriceWithFee;

    return singleOccupancies.length * priceAdjustment;
};

export {
    getPricingFromState,
    getPreAndPostAddOnsPriceAdjustments,
    toNegative,
    calculateAddOnsSubTotal,
    calculatePriceAdjustmentsSubTotal,
    calculateRoomSingleSupplementPriceAdjustment,
};
