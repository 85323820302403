import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import TripReviewPricingForm from './TripReviewPricingForm';
import TabDetailsInformation from '../../../../../components/TabDetailsInformation/TabDetailsInformation';
import Tab from '../../../../../navigation/Tab';
import { TripStatuses } from 'config/constants';
import TravelerManifestDocumentDownloadButton from '../../../../operator/components/Manifest/TravelerManifestDocumentDownloadButton';
import ContentForModelStatus from '../../../../common/components/ContentForModelStatus';
import { StatefulModel } from '../../../../../applications/types';
import { Stack } from '@trova-trip/trova-components';
import { useOperatorCanUpdateTrip } from '../../utils/OperatorEditableFields';

const TripReviewPricing = ({
    data,
    updateModelData,
    renderError,
    errorText,
    cleanErrors,
    disabled,
}) => {
    if (!data) {
        return (
            <Tab
                label={`3. Review Trip Pricing`}
                path={`/review-pricing`}
                disabled={true}
            >
                <div />
            </Tab>
        );
    }

    const canUpdateTrip = useOperatorCanUpdateTrip();

    return (
        <Tab
            label={`3. Review Trip Pricing`}
            path={`/review-pricing`}
            disabled={disabled}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Stack
                        direction={{ base: 'column', md: 'row' }}
                        justify={{ md: 'space-between' }}
                        align='flex-start'
                        spacing={0}
                    >
                        <TabDetailsInformation
                            title='Review Trip Pricing'
                            description='Check the price of your trip: Review the price is correct for the selected itinerary and trip dates'
                        />
                        <ContentForModelStatus
                            model={StatefulModel.TRIP}
                            matchingStatuses={[
                                TripStatuses.CONFIRMED,
                                TripStatuses.CLOSED,
                            ]}
                            render={() => (
                                <TravelerManifestDocumentDownloadButton
                                    tripId={data.id}
                                />
                            )}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <TripReviewPricingForm
                        data={data}
                        updateModelData={updateModelData}
                        renderError={renderError}
                        errorText={errorText}
                        cleanErrors={cleanErrors}
                        isSubmitDisabled={!canUpdateTrip}
                    />
                </Grid>
            </Grid>
        </Tab>
    );
};

TripReviewPricing.propTypes = {
    data: PropTypes.shape(),
    updateModelData: PropTypes.func,
    renderError: PropTypes.func,
    errorText: PropTypes.string,
    cleanErrors: PropTypes.func,
    disabled: PropTypes.bool,
};

export default TripReviewPricing;
