import { coreUtils } from '@trova-trip/trova-common';
import {
    BookingWithTrip,
    ManageBookingAddOn,
    ManageBookingViewModel,
} from '../types';
import {
    determineTravelersRooms,
    mapAccommodationsAddOns,
    mapActivitiesAddOns,
    mapDefaultRoomOccupancy,
    mapInsuranceAddOns,
    mapSingleSupplementAddOns,
    mapTransfersAddOns,
    removeDeletedAddOns,
    transformAddOnsToManageBookingAddOns,
} from './utils';

const {
    bookingUtils: { generateAddOnsFromTrip, isBookingEditableForTripStatus },
} = coreUtils;

export const getManageBookingViewModel = (
    booking: BookingWithTrip,
): ManageBookingViewModel => {
    const { trip } = booking;
    const bookingIsEditableFromTripStatus = isBookingEditableForTripStatus(
        trip.status,
    );

    let tripTransformedAddOns: ManageBookingAddOn[] = [];

    if (bookingIsEditableFromTripStatus) {
        const tripAddOns = generateAddOnsFromTrip(trip);
        tripTransformedAddOns =
            transformAddOnsToManageBookingAddOns(tripAddOns);
    }

    const filteredNonDeletedAddOns = removeDeletedAddOns(booking.addOns);
    const bookingTransformedAddOns = transformAddOnsToManageBookingAddOns(
        filteredNonDeletedAddOns,
    );

    const sharedMapParams = {
        tripAddOns: tripTransformedAddOns,
        bookingAddOns: bookingTransformedAddOns,
    };

    const sharedMapParamsWithBooking = {
        ...sharedMapParams,
        booking,
    };

    return {
        currentTripId: trip.id,
        addOns: {
            activities: mapActivitiesAddOns(sharedMapParamsWithBooking),
            accommodations: {
                ...mapAccommodationsAddOns(sharedMapParams),
                singleSupplement: mapSingleSupplementAddOns(sharedMapParams),
            },
            transfers: mapTransfersAddOns(sharedMapParamsWithBooking),
            insurance: mapInsuranceAddOns(booking),
        },
        travelersRooms: determineTravelersRooms(booking, trip),
        travelersQuantity: booking.totalSpotsBooked,
        defaultRoomOccupancy: mapDefaultRoomOccupancy(booking, trip),
    };
};
