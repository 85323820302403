import { Badge, theme } from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';

type UpcomingTripStatuses = models.hostHomeTab.TripStatuses;

type StatusBadgeColors = {
    [key in UpcomingTripStatuses]: {
        fontColor: string;
        bgColor: string;
    };
};

const UpcomingTripStatuses = models.hostHomeTab.TripStatuses;

const statusBadgeColors: StatusBadgeColors = {
    [UpcomingTripStatuses.Requested]: {
        fontColor: theme.colors.orange[900],
        bgColor: theme.colors.orange[200],
    },
    [UpcomingTripStatuses.Closed]: {
        fontColor: theme.colors.blueGray[650],
        bgColor: theme.colors.blueGray[100],
    },
    [UpcomingTripStatuses.Live]: {
        fontColor: theme.colors.blue[800],
        bgColor: theme.colors.blue[100],
    },
    [UpcomingTripStatuses.Pending]: {
        fontColor: theme.colors.alerts.success.accent,
        bgColor: theme.colors.alerts.success.bg,
    },
    [UpcomingTripStatuses.Confirmed]: {
        fontColor: theme.colors.purple[800],
        bgColor: theme.colors.purple[50],
    },
};

interface TripStatusBadgeProps {
    tripStatus: models.hostHomeTab.TripStatuses;
}

const TripStatusBadge = (props: TripStatusBadgeProps) => {
    const { tripStatus } = props;

    const colorSchema = statusBadgeColors[tripStatus];

    return (
        <Badge
            backgroundColor={colorSchema?.bgColor}
            color={colorSchema?.fontColor}
            variant='rounded'
        >
            {tripStatus}
        </Badge>
    );
};

export default TripStatusBadge;
