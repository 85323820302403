import { Stack, Grid } from '@trova-trip/trova-components';
import {
    ItineraryRecommendationCard,
    ItineraryRecommendationCardProps,
    RecommendationQuoteCard,
    RecommendationQuoteCardProps,
} from './components';
import {
    RecommendationAreas,
    RecommendationBannerLayoutTypes,
    getBannerLayout,
} from './recommendationBannerLayoutConfig';

interface RecommendationBannerProps {
    recommendations: Array<
        Omit<ItineraryRecommendationCardProps, 'layoutConfig'>
    >;
    layoutType: RecommendationBannerLayoutTypes;
    recommendedBy?: Omit<RecommendationQuoteCardProps, 'variant'>;
}

const RecommendationBanner = ({
    recommendations,
    recommendedBy: recommendedByCardProps,
    layoutType,
}: RecommendationBannerProps): JSX.Element => {
    const cardLayout = getBannerLayout(recommendations.length);
    const {
        recommendedByCardVariant,
        recommendationCardLayoutConfig,
        quoteCardContainer,
        displayRecommendedBy,
        ...cardLayoutProps
    } = cardLayout[layoutType];
    return (
        <Grid {...cardLayoutProps}>
            {displayRecommendedBy ? (
                <Grid.Item area={RecommendationAreas.QUOTE}>
                    <RecommendationQuoteCard
                        variant={recommendedByCardVariant}
                        containerProps={quoteCardContainer}
                        {...recommendedByCardProps}
                    />
                </Grid.Item>
            ) : null}

            <Grid.Item area={RecommendationAreas.RECOMMENDATIONS}>
                <Stack
                    wrap={{ base: 'wrap', md: 'nowrap' }}
                    direction={{ base: 'column', md: 'row' }}
                    spacing={{ base: 2, md: 4 }}
                >
                    {recommendations.map(
                        ({ imageSrc, name, isTopRecommended, onClick }) => {
                            return (
                                <ItineraryRecommendationCard
                                    layoutConfig={
                                        recommendationCardLayoutConfig
                                    }
                                    key={name}
                                    imageSrc={imageSrc}
                                    name={name}
                                    isTopRecommended={isTopRecommended}
                                    onClick={onClick}
                                />
                            );
                        },
                    )}
                </Stack>
            </Grid.Item>
        </Grid>
    );
};

export default RecommendationBanner;
