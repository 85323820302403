import {
    ButtonType,
    theme,
    ComponentWidth,
    Text,
    Grid,
} from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import useTripPageUrl from 'applications/common/hooks/trips/useTripPageUrl';
import CopyLink from '../CopyLink/CopyLink';
import usePublishSystem from 'applications/common/hooks/trips/usePublishSystem';

export const TripPageFields = (): JSX.Element => {
    const { isPublished } = usePublishSystem();
    const tripPageUrl = useTripPageUrl();

    const openTripPage = (): void => {
        window.open(tripPageUrl, '_blank')?.focus();
    };

    return (
        <>
            <Grid.Item columnSpan={6}>
                <Text
                    size='sm'
                    fontWeight='medium'
                    color='blueGray.dark'
                    marginBottom={2}
                >
                    Trip Page Link
                </Text>
                <CopyLink
                    label={tripPageUrl}
                    buttonType={ButtonType.Secondary}
                    buttonIconColor={theme.colors.red.coral}
                    size={ComponentWidth.Flexible}
                    disabled={!isPublished}
                />
            </Grid.Item>
            <Grid.Item columnSpan={6} alignSelf='end'>
                <Button
                    variant='secondary'
                    rightIcon='offsite'
                    onClick={openTripPage}
                    isDisabled={!isPublished}
                    marginBottom={2}
                >
                    Trip Page
                </Button>
            </Grid.Item>
        </>
    );
};
