import { ServiceResponse } from '../state/hosts';
import { post } from '../state/utils/api';

// This interface will be placed in our core/models library
export interface SocialConnectResponse {
    socialEngagementUserId: string;
    socialEngagementChannelIds: {
        instagram: string;
        tiktok: string;
        youtube: string;
        facebook: string;
    };
    token: string;
}

const initializePhylloUser = async (): Promise<
    ServiceResponse<SocialConnectResponse>
> =>
    post(
        '/api/me/host/socials/connect',
        {},
    ) as ServiceResponse<SocialConnectResponse>;

export { initializePhylloUser };
