import { Button } from '@trova-trip/trova-components/build/next';
import { Stack } from '@trova-trip/trova-components';
import { useManageBooking, useTripSummaryContent } from '../../hooks';

const TripSummaryControls = (): JSX.Element => {
    const {
        actions: { openConfirmationModal, confirmBooking },
        state: {
            confirmation: { isDisabled },
            tripSummary: { isLoading },
        },
    } = useManageBooking();

    const { pendingChanges } = useTripSummaryContent();

    const shouldDisplayClearAllButton = (pendingChanges || 0) > 1;

    return (
        <Stack
            width='full'
            spacing={3}
            direction='column'
            align='center'
            marginBottom={1}
        >
            <Button
                size={{ xs: 'md', md: 'lg' }}
                onClick={confirmBooking}
                isLoading={isLoading}
                isDisabled={isDisabled || isLoading}
                isFullWidth
            >
                Update Booking
            </Button>
            {shouldDisplayClearAllButton ? (
                <Button
                    size={{ xs: 'md', md: 'lg' }}
                    variant='tertiary'
                    color='blueGray.600'
                    onClick={() => openConfirmationModal()}
                >
                    Clear all
                </Button>
            ) : null}
        </Stack>
    );
};

export default TripSummaryControls;
