import { coreUtils } from '@trova-trip/trova-common';
import { TripStatuses as TRIP_STATUS } from 'config/constants';
import { useEffect, useState } from 'react';
import { useSelector } from 'state/hooks';

const { getZeroUTCResetDateObj } = coreUtils.dateUtils;

export enum TRIP_PAGE_STATUS {
    /**
     * This status means that the trip doesn't have scheduled the launch date.
     */
    LAUNCH_NOT_SCHEDULED = 'launch-not-scheduled',

    /**
     * This status means that the trip is on marketing stage yet (host-approved)
     * and it has already scheduled the launch date.
     */
    PRE_LAUNCH_NOT_BOOKABLE = 'pre-launch-not-bookable',

    /**
     * This status means that the trip is live and the launch date
     * hasn't arrived yet.
     */
    PRE_LAUNCH_BOOKABLE = 'pre-launch-bookable',

    /**
     * This status means that the trip is live and the launch date
     * has arrived or passed.
     */
    LIVE = 'live',

    /**
     * This status means that the trip is confirmed and the
     * confirmed travelers are less than the maximum spots available.
     */
    CONFIRMED_BOOKABLE = 'confirmed-bookable',

    /**
     * This status means that the trip is confirmed and the
     * confirmed and pending travelers have filled the available spots.
     */
    CONFIRMED_SOLD_OUT = 'confirmed-sold-out',

    /**
     * This status means that the trip is cancelled
     */
    CANCELLED = 'cancelled',

    /**
     * This status means that the trip is closed.
     */
    CLOSED = 'closed',
}

export const useTripPageStatus = (): TRIP_PAGE_STATUS | undefined => {
    const trip = useSelector((store) => store.userTrips.current);
    const tripDetails = useSelector((store) => store.userTripDetails.current);

    const [tripPageStatus, setTripPageStatus] = useState<TRIP_PAGE_STATUS>();

    useEffect(() => {
        if (!trip || !tripDetails) return;

        const { status, launchDate } = trip;
        const { confirmedTravelers, pendingTravelers, maximumSpots } =
            tripDetails;

        const isLaunchScheduled = !!launchDate;

        if (!isLaunchScheduled) {
            return setTripPageStatus(TRIP_PAGE_STATUS.LAUNCH_NOT_SCHEDULED);
        }

        if (isLaunchScheduled && status === TRIP_STATUS.HOST_APPROVED) {
            return setTripPageStatus(TRIP_PAGE_STATUS.PRE_LAUNCH_NOT_BOOKABLE);
        }

        if (isLaunchScheduled && status === TRIP_STATUS.LIVE) {
            const nowWithoutTime = getZeroUTCResetDateObj(new Date());
            const launchDateWithoutTime = getZeroUTCResetDateObj(
                launchDate as Date,
            );
            const isLaunchDateHere = launchDateWithoutTime <= nowWithoutTime;
            if (isLaunchDateHere) {
                return setTripPageStatus(TRIP_PAGE_STATUS.LIVE);
            } else {
                return setTripPageStatus(TRIP_PAGE_STATUS.PRE_LAUNCH_BOOKABLE);
            }
        }

        if (status === TRIP_STATUS.READY_TO_CONFIRM) {
            return setTripPageStatus(TRIP_PAGE_STATUS.LIVE);
        }

        if (status === TRIP_STATUS.CONFIRMED) {
            const totalTravelers = confirmedTravelers + pendingTravelers;
            const areAllSpotsFilled = totalTravelers >= maximumSpots;
            if (areAllSpotsFilled) {
                return setTripPageStatus(TRIP_PAGE_STATUS.CONFIRMED_SOLD_OUT);
            } else {
                return setTripPageStatus(TRIP_PAGE_STATUS.CONFIRMED_BOOKABLE);
            }
        }

        if (status === TRIP_STATUS.CANCELLED) {
            return setTripPageStatus(TRIP_PAGE_STATUS.CANCELLED);
        }

        if (status === TRIP_STATUS.CLOSED) {
            return setTripPageStatus(TRIP_PAGE_STATUS.CLOSED);
        }
    }, [trip, tripDetails]);

    return tripPageStatus;
};
