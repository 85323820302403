import { models } from '@trova-trip/trova-models';
import noop from 'lodash/noop';
import { useHistory } from 'react-router-dom';
import { useSelector } from '../../../state/hooks';
import { getTransferPath } from '../helpers';
import { TransferTabName } from '../products/transfer/config';
import useTripIdUrlParam from './trips/useTripIdUrlParam';

type Trip = models.trips.Trip;

const useNavigateToTransfer = (trip?: Trip) => {
    const history = useHistory();
    const user = useSelector((state) => state.profile.current);

    let tripId = useTripIdUrlParam();

    if (trip) {
        tripId = trip.id;
    }

    const navigate = (tabName: TransferTabName) => {
        if (!tripId || !user?.group) {
            return noop;
        }
        const path = getTransferPath(tripId, user?.group, tabName);
        history.push(path);
    };

    return navigate;
};

export default useNavigateToTransfer;
