import { Field, Grid } from '@trova-trip/trova-components';
import capitalize from 'lodash/capitalize';
import { useSelector } from '../../../../../../../state/hooks';
import { getEnabledPackagesLevels } from '../../../../../../common/helpers/itinerary';
import { FieldName } from '../constants';
import { fieldNameWithPrefix, getDisabledStartDates } from '../helpers';
import { BaseItinerary } from '../types';
import DatePickerField from './DatePickerField';

interface TripFieldsProps {
    isStartDateDisabled: boolean;
    isDisabled?: boolean;
}

const TripFields = ({ isStartDateDisabled, isDisabled }: TripFieldsProps) => {
    const itinerary = useSelector((state) => state.userItinerary.current);
    const enabledPackageLevels = getEnabledPackagesLevels(
        itinerary as BaseItinerary,
    );
    const disabledStartDates = getDisabledStartDates();

    return (
        <>
            <Grid.Item columnSpan={6}>
                <Field
                    as='custom'
                    name={fieldNameWithPrefix(FieldName.StartDate)}
                >
                    {({ field, meta }) => (
                        <DatePickerField
                            name={field.name}
                            value={field.value}
                            error={meta.error}
                            onChange={field.onChange}
                            isDisabled={isStartDateDisabled || isDisabled}
                            disabledDates={disabledStartDates}
                        />
                    )}
                </Field>
            </Grid.Item>
            <Grid.Item columnSpan={6}>
                <Field
                    isRequired
                    as='number'
                    name={fieldNameWithPrefix(FieldName.QtyAvailable)}
                    helperText='Number of departures starting on this date'
                    label='Trip Quantity'
                    min={0}
                />
            </Grid.Item>
            <Grid.Item columnSpan={{ xs: 12, md: 6 }}>
                <Field
                    isRequired
                    as='comboBox'
                    name={fieldNameWithPrefix(FieldName.PackageLevel)}
                    label='Package'
                    options={enabledPackageLevels.map((value) => ({
                        value: value,
                        label: capitalize(value),
                    }))}
                    isDisabled={isDisabled}
                />
            </Grid.Item>
        </>
    );
};

export default TripFields;
