import { Grid } from '@material-ui/core';
import { businessConstants } from '@trova-trip/trova-common';
import {
    BaseBox,
    ComponentWidth,
    DatePicker,
    Dropdown,
    Heading,
    Input,
    Text,
    TimePicker,
    UseFormSaverReturn,
} from '@trova-trip/trova-components';
import { noop } from 'lodash';
import moment, { Moment } from 'moment';
import { createCountriesDropdownOptions } from '../../../../config/constants';
import { generateDropdownOptionsFromObject } from '../../../../util/form/dropdown';
import GenderDropdown from '../../../common/components/Dropdown/GenderDropdown';
import SimpleAutoCompleteDropdown from '../../../common/components/Dropdown/SimpleAutoCompleteDropdown';
import { StyledInputHeight } from './TripForm.styles';
import { TRIP_FORM_FIELD_NAME } from '../../../common/types/tripForms.types';

const { END_OF_TRIP_PLANS } = businessConstants;

const endOfTripOptions = generateDropdownOptionsFromObject(END_OF_TRIP_PLANS);

interface TripFormFieldsProps {
    formSaver: UseFormSaverReturn;
    isPassportRequired: boolean;
    isDisabled: boolean;
}

const SectionTitle = (props: { children: string }): JSX.Element => {
    const { children } = props;
    return (
        <BaseBox marginTop={8}>
            <Heading as={'h4'}>{children}</Heading>
        </BaseBox>
    );
};

const SectionSubtitle = (props: { children: string }): JSX.Element => {
    const { children } = props;
    return (
        <BaseBox marginTop={4}>
            <Heading as={'h5'}>{children}</Heading>
        </BaseBox>
    );
};

const TripFormFields = (props: TripFormFieldsProps): JSX.Element => {
    const { formSaver, isPassportRequired, isDisabled } = props;
    const {
        formErrors,
        formValues: { get: formSaverGet, set: formSaverSet },
    } = formSaver;

    const handleDatePickerChange = (
        event: Moment | string,
        inputName: string,
    ): void => {
        const value =
            typeof event === 'string'
                ? moment(event, 'MM/DD/YYYY').utc()
                : event.utc();
        if (!value.isValid()) {
            return;
        }
        formSaverSet.nested(inputName, value.format('MM/DD/YYYY'));
    };

    const handleTimePickerChange = (
        event: Moment | string,
        inputName: string,
    ): void => {
        if (typeof event === 'string') {
            formSaverSet.nested(inputName, event);
        }
    };

    return (
        <>
            <Grid item xs={12}>
                <SectionTitle>Flight Information</SectionTitle>
            </Grid>
            <Grid item xs={12}>
                <SectionSubtitle>Arrival</SectionSubtitle>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Input
                            disabled={isDisabled}
                            name={TRIP_FORM_FIELD_NAME.FLIGHT_ARRIVAL_AIRLINE}
                            label='Airline'
                            size={ComponentWidth.Flexible}
                            value={
                                formSaverGet.nested(
                                    TRIP_FORM_FIELD_NAME.FLIGHT_ARRIVAL_AIRLINE,
                                ) as string
                            }
                            onChange={(event): void => {
                                formSaverSet.nested(
                                    (event.target as HTMLInputElement).name,
                                    (event.target as HTMLInputElement).value,
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Input
                            disabled={isDisabled}
                            name={TRIP_FORM_FIELD_NAME.FLIGHT_ARRIVAL_NUMBER}
                            label='Flight Number'
                            size={ComponentWidth.Flexible}
                            value={
                                formSaverGet.nested(
                                    TRIP_FORM_FIELD_NAME.FLIGHT_ARRIVAL_NUMBER,
                                ) as string
                            }
                            onChange={(event): void => {
                                formSaverSet.nested(
                                    (event.target as HTMLInputElement).name,
                                    (event.target as HTMLInputElement).value,
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <DatePicker
                            disabled={isDisabled}
                            name={TRIP_FORM_FIELD_NAME.FLIGHT_ARRIVAL_DATE}
                            placeholder='MM/DD/YYYY'
                            label='Date of Arrival'
                            size={ComponentWidth.Flexible}
                            value={formSaverGet.nested(
                                TRIP_FORM_FIELD_NAME.FLIGHT_ARRIVAL_DATE,
                            )}
                            onChange={(event): void => {
                                handleDatePickerChange(
                                    event,
                                    TRIP_FORM_FIELD_NAME.FLIGHT_ARRIVAL_DATE,
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <TimePicker
                            disabled={isDisabled}
                            name={TRIP_FORM_FIELD_NAME.FLIGHT_ARRIVAL_TIME}
                            placeholder='Time of Arrival'
                            label='Time of Arrival'
                            size={ComponentWidth.Flexible}
                            value={formSaverGet.nested(
                                TRIP_FORM_FIELD_NAME.FLIGHT_ARRIVAL_TIME,
                            )}
                            onChange={(event): void => {
                                handleTimePickerChange(
                                    event,
                                    TRIP_FORM_FIELD_NAME.FLIGHT_ARRIVAL_TIME,
                                );
                            }}
                            ampm
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <SectionSubtitle>Departure</SectionSubtitle>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={4} lg={3}>
                        <Input
                            disabled={isDisabled}
                            name={TRIP_FORM_FIELD_NAME.FLIGHT_DEPARTURE_AIRLINE}
                            label='Airline'
                            size={ComponentWidth.Flexible}
                            value={
                                formSaverGet.nested(
                                    TRIP_FORM_FIELD_NAME.FLIGHT_DEPARTURE_AIRLINE,
                                ) as string
                            }
                            onChange={(event): void => {
                                formSaverSet.nested(
                                    (event.target as HTMLInputElement).name,
                                    (event.target as HTMLInputElement).value,
                                );
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} lg={3}>
                        <Input
                            disabled={isDisabled}
                            name={TRIP_FORM_FIELD_NAME.FLIGHT_DEPARTURE_NUMBER}
                            label='Flight Number'
                            size={ComponentWidth.Flexible}
                            value={
                                formSaverGet.nested(
                                    TRIP_FORM_FIELD_NAME.FLIGHT_DEPARTURE_NUMBER,
                                ) as string
                            }
                            onChange={(event): void => {
                                formSaverSet.nested(
                                    (event.target as HTMLInputElement).name,
                                    (event.target as HTMLInputElement).value,
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <DatePicker
                            disabled={isDisabled}
                            name={TRIP_FORM_FIELD_NAME.FLIGHT_DEPARTURE_DATE}
                            placeholder='MM/DD/YYYY'
                            label='Date of Departure'
                            size={ComponentWidth.Flexible}
                            value={formSaverGet.nested(
                                TRIP_FORM_FIELD_NAME.FLIGHT_DEPARTURE_DATE,
                            )}
                            onChange={(event): void => {
                                handleDatePickerChange(
                                    event,
                                    TRIP_FORM_FIELD_NAME.FLIGHT_DEPARTURE_DATE,
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                        <TimePicker
                            disabled={isDisabled}
                            name={TRIP_FORM_FIELD_NAME.FLIGHT_DEPARTURE_TIME}
                            placeholder='Time of Departure'
                            label='Time of Departure'
                            size={ComponentWidth.Flexible}
                            ampm
                            value={formSaverGet.nested(
                                TRIP_FORM_FIELD_NAME.FLIGHT_DEPARTURE_TIME,
                            )}
                            onChange={(event): void => {
                                handleTimePickerChange(
                                    event,
                                    TRIP_FORM_FIELD_NAME.FLIGHT_DEPARTURE_TIME,
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={4}>
                        <Dropdown
                            disabled={isDisabled}
                            name={TRIP_FORM_FIELD_NAME.END_TRIP_PLANS}
                            label='What are your post-trip plans?'
                            onSearch={noop}
                            size={ComponentWidth.Flexible}
                            value={formSaverGet.endOfTripPlans as string}
                            onChange={(_, __, value): void => {
                                formSaver.formValues.set.nested(
                                    TRIP_FORM_FIELD_NAME.END_TRIP_PLANS,
                                    value,
                                );
                            }}
                        >
                            {endOfTripOptions}
                        </Dropdown>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <SectionTitle>Travel Insurance</SectionTitle>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={4}>
                        <Input
                            disabled={isDisabled}
                            name={
                                TRIP_FORM_FIELD_NAME.TRAVELER_INSURANCE_POLICY_NUMBER
                            }
                            placeholder='Policy #'
                            label='Travel Insurance Policy #'
                            size={ComponentWidth.Flexible}
                            value={formSaverGet.nested(
                                TRIP_FORM_FIELD_NAME.TRAVELER_INSURANCE_POLICY_NUMBER,
                            )}
                            onChange={(event): void => {
                                formSaverSet.nested(
                                    (event.target as HTMLInputElement).name,
                                    (event.target as HTMLInputElement).value,
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input
                            disabled={isDisabled}
                            name={
                                TRIP_FORM_FIELD_NAME.TRAVELER_INSURANCE_CARRIER
                            }
                            label='Travel Insurance Carrier'
                            size={ComponentWidth.Flexible}
                            value={formSaverGet.nested(
                                TRIP_FORM_FIELD_NAME.TRAVELER_INSURANCE_CARRIER,
                            )}
                            onChange={(event): void => {
                                formSaverSet.nested(
                                    (event.target as HTMLInputElement).name,
                                    (event.target as HTMLInputElement).value,
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <SectionTitle>Passport Information</SectionTitle>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Text>
                    Please complete your passport details. This helps us
                    maintain consistency with your legal identification.
                </Text>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={4}>
                        <Input
                            disabled={isDisabled}
                            name={TRIP_FORM_FIELD_NAME.PASSPORT_FIRST_NAME}
                            label='Passport First Name'
                            size={ComponentWidth.Flexible}
                            value={formSaverGet.nested(
                                TRIP_FORM_FIELD_NAME.PASSPORT_FIRST_NAME,
                            )}
                            onChange={(event): void => {
                                formSaverSet.nested(
                                    (event.target as HTMLInputElement).name,
                                    (event.target as HTMLInputElement).value,
                                );
                            }}
                            error={
                                (formErrors &&
                                    formErrors[
                                        TRIP_FORM_FIELD_NAME.PASSPORT_FIRST_NAME
                                    ]) ||
                                undefined
                            }
                            required={isPassportRequired}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input
                            disabled={isDisabled}
                            name={TRIP_FORM_FIELD_NAME.PASSPORT_LAST_NAME}
                            label='Passport Last Name'
                            size={ComponentWidth.Flexible}
                            value={formSaverGet.nested(
                                TRIP_FORM_FIELD_NAME.PASSPORT_LAST_NAME,
                            )}
                            onChange={(event): void => {
                                formSaverSet.nested(
                                    (event.target as HTMLInputElement).name,
                                    (event.target as HTMLInputElement).value,
                                );
                            }}
                            error={
                                (formErrors &&
                                    formErrors[
                                        TRIP_FORM_FIELD_NAME.PASSPORT_LAST_NAME
                                    ]) ||
                                undefined
                            }
                            required={isPassportRequired}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={4}>
                        <Input
                            disabled={isDisabled}
                            name={TRIP_FORM_FIELD_NAME.PASSPORT_NUMBER}
                            label='Passport Number'
                            size={ComponentWidth.Flexible}
                            value={formSaverGet.nested(
                                TRIP_FORM_FIELD_NAME.PASSPORT_NUMBER,
                            )}
                            onChange={(event): void => {
                                formSaverSet.nested(
                                    (event.target as HTMLInputElement).name,
                                    (event.target as HTMLInputElement).value,
                                );
                            }}
                            error={
                                (formErrors &&
                                    formErrors[
                                        TRIP_FORM_FIELD_NAME.PASSPORT_NUMBER
                                    ]) ||
                                undefined
                            }
                            required={isPassportRequired}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SimpleAutoCompleteDropdown
                            disabled={isDisabled}
                            name={TRIP_FORM_FIELD_NAME.PASSPORT_NATIONALITY}
                            label='Nationality'
                            size={ComponentWidth.Flexible}
                            value={formSaverGet.nested(
                                TRIP_FORM_FIELD_NAME.PASSPORT_NATIONALITY,
                            )}
                            onChange={(_, __, value): void => {
                                formSaver.formValues.set.nested(
                                    TRIP_FORM_FIELD_NAME.PASSPORT_NATIONALITY,
                                    value,
                                );
                            }}
                            error={
                                (formErrors &&
                                    formErrors[
                                        TRIP_FORM_FIELD_NAME
                                            .PASSPORT_NATIONALITY
                                    ]) ||
                                undefined
                            }
                            required={isPassportRequired}
                        >
                            {createCountriesDropdownOptions()}
                        </SimpleAutoCompleteDropdown>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={4}>
                        <DatePicker
                            disabled={isDisabled}
                            name={TRIP_FORM_FIELD_NAME.PASSPORT_ISSUE_DATE}
                            placeholder='MM/DD/YYYY'
                            label='Passport Issue Date'
                            size={ComponentWidth.Flexible}
                            value={formSaverGet.nested(
                                TRIP_FORM_FIELD_NAME.PASSPORT_ISSUE_DATE,
                            )}
                            onChange={(event): void => {
                                handleDatePickerChange(
                                    event,
                                    TRIP_FORM_FIELD_NAME.PASSPORT_ISSUE_DATE,
                                );
                            }}
                            error={
                                (formErrors &&
                                    formErrors[
                                        TRIP_FORM_FIELD_NAME.PASSPORT_ISSUE_DATE
                                    ]) ||
                                undefined
                            }
                            required={isPassportRequired}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <DatePicker
                            disabled={isDisabled}
                            name={TRIP_FORM_FIELD_NAME.PASSPORT_EXPIRATION_DATE}
                            placeholder='MM/DD/YYYY'
                            label='Passport Expiration Date'
                            size={ComponentWidth.Flexible}
                            value={formSaverGet.nested(
                                TRIP_FORM_FIELD_NAME.PASSPORT_EXPIRATION_DATE,
                            )}
                            onChange={(event): void => {
                                handleDatePickerChange(
                                    event,
                                    TRIP_FORM_FIELD_NAME.PASSPORT_EXPIRATION_DATE,
                                );
                            }}
                            error={
                                (formErrors &&
                                    formErrors[
                                        TRIP_FORM_FIELD_NAME
                                            .PASSPORT_EXPIRATION_DATE
                                    ]) ||
                                undefined
                            }
                            required={isPassportRequired}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={4}>
                        <DatePicker
                            disabled={isDisabled}
                            name={TRIP_FORM_FIELD_NAME.PASSPORT_DATE_OF_BIRTH}
                            placeholder='MM/DD/YYYY'
                            label='Date of Birth'
                            size={ComponentWidth.Flexible}
                            value={formSaverGet.nested(
                                TRIP_FORM_FIELD_NAME.PASSPORT_DATE_OF_BIRTH,
                            )}
                            onChange={(event): void => {
                                handleDatePickerChange(
                                    event,
                                    TRIP_FORM_FIELD_NAME.PASSPORT_DATE_OF_BIRTH,
                                );
                            }}
                            error={
                                (formErrors &&
                                    formErrors[
                                        TRIP_FORM_FIELD_NAME
                                            .PASSPORT_DATE_OF_BIRTH
                                    ]) ||
                                undefined
                            }
                            required={isPassportRequired}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <GenderDropdown
                            disabled={isDisabled}
                            label='Gender Marker'
                            name={TRIP_FORM_FIELD_NAME.PASSPORT_GENDER}
                            value={formSaverGet.nested(
                                TRIP_FORM_FIELD_NAME.PASSPORT_GENDER,
                            )}
                            onChange={(_, __, value): void => {
                                formSaver.formValues.set.nested(
                                    TRIP_FORM_FIELD_NAME.PASSPORT_GENDER,
                                    value,
                                );
                            }}
                            error={
                                (formErrors &&
                                    formErrors[
                                        TRIP_FORM_FIELD_NAME.PASSPORT_GENDER
                                    ]) ||
                                undefined
                            }
                            required={isPassportRequired}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <SectionTitle>Emergency Contact</SectionTitle>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={4}>
                        <Input
                            disabled={isDisabled}
                            name={TRIP_FORM_FIELD_NAME.EMERGENCY_CONTACT_NAME}
                            placeholder='Contact Name'
                            label='Emergency Contact Name'
                            size={ComponentWidth.Flexible}
                            value={formSaver.formValues.get.nested(
                                TRIP_FORM_FIELD_NAME.EMERGENCY_CONTACT_NAME,
                            )}
                            onChange={(event): void => {
                                formSaver.formValues.set.nested(
                                    (event.target as HTMLInputElement).name,
                                    (event.target as HTMLInputElement).value,
                                );
                            }}
                            error={
                                (formErrors &&
                                    formErrors[
                                        TRIP_FORM_FIELD_NAME
                                            .EMERGENCY_CONTACT_NAME
                                    ]) ||
                                undefined
                            }
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input
                            disabled={isDisabled}
                            name={
                                TRIP_FORM_FIELD_NAME.EMERGENCY_CONTACT_RELATIONSHIP
                            }
                            placeholder='Contact Relationship'
                            label='Emergency Contact Relationship'
                            size={ComponentWidth.Flexible}
                            value={formSaver.formValues.get.nested(
                                TRIP_FORM_FIELD_NAME.EMERGENCY_CONTACT_RELATIONSHIP,
                            )}
                            onChange={(event): void => {
                                formSaver.formValues.set.nested(
                                    (event.target as HTMLInputElement).name,
                                    (event.target as HTMLInputElement).value,
                                );
                            }}
                            error={
                                (formErrors &&
                                    formErrors[
                                        TRIP_FORM_FIELD_NAME
                                            .EMERGENCY_CONTACT_RELATIONSHIP
                                    ]) ||
                                undefined
                            }
                            required={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={4}>
                        <Input
                            disabled={isDisabled}
                            name={TRIP_FORM_FIELD_NAME.EMERGENCY_CONTACT_PHONE}
                            placeholder='(###) - ### - ####'
                            label='Emergency Contact Phone'
                            size={ComponentWidth.Flexible}
                            value={formSaver.formValues.get.nested(
                                TRIP_FORM_FIELD_NAME.EMERGENCY_CONTACT_PHONE,
                            )}
                            onChange={(event): void => {
                                formSaver.formValues.set.nested(
                                    (event.target as HTMLInputElement).name,
                                    (event.target as HTMLInputElement).value,
                                );
                            }}
                            error={
                                (formErrors &&
                                    formErrors[
                                        TRIP_FORM_FIELD_NAME
                                            .EMERGENCY_CONTACT_PHONE
                                    ]) ||
                                undefined
                            }
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input
                            disabled={isDisabled}
                            name={TRIP_FORM_FIELD_NAME.EMERGENCY_CONTACT_EMAIL}
                            placeholder='example@gmail.com'
                            label='Emergency Contact Email'
                            size={ComponentWidth.Flexible}
                            value={formSaver.formValues.get.nested(
                                TRIP_FORM_FIELD_NAME.EMERGENCY_CONTACT_EMAIL,
                            )}
                            onChange={(event): void => {
                                formSaver.formValues.set.nested(
                                    (event.target as HTMLInputElement).name,
                                    (event.target as HTMLInputElement).value,
                                );
                            }}
                            error={
                                (formErrors &&
                                    formErrors[
                                        TRIP_FORM_FIELD_NAME
                                            .EMERGENCY_CONTACT_EMAIL
                                    ]) ||
                                undefined
                            }
                            required={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <SectionTitle>Disclosures & Preferences</SectionTitle>
            </Grid>
            <Grid item xs={12} sm={8} md={8} lg={6}>
                <StyledInputHeight
                    disabled={isDisabled}
                    name={
                        TRIP_FORM_FIELD_NAME.DISCLOSURES_PREFERENCES_DESCRIPTION
                    }
                    label='Do you have any known food allergies or medical-related injuries we should be aware of? Please also note any dietary preferences you may have (vegan, pescatarian, etc).'
                    size={ComponentWidth.Flexible}
                    value={formSaver.formValues.get.nested(
                        TRIP_FORM_FIELD_NAME.DISCLOSURES_PREFERENCES_DESCRIPTION,
                    )}
                    onChange={(event): void => {
                        formSaver.formValues.set.nested(
                            (event.target as HTMLInputElement).name,
                            (event.target as HTMLInputElement).value,
                        );
                    }}
                />
            </Grid>
        </>
    );
};

export default TripFormFields;
