import { Grid, Text, TextInput } from '@trova-trip/trova-components';
import { ComponentProps } from 'react';
import {
    socialInputLabels,
    socialMediaIcons,
} from './SocialMediaAccounts.constants';
import { Socials, SocialKeys } from './SocialMediaAccounts.types';
import useIsLargeScreen from '../../../common/hooks/useIsLargeScreen';

const placeholders: Socials = {
    facebook: 'Account Name',
    instagram: 'Account Name',
    tiktok: 'Account Handle',
    youtube: 'Account Handle',
    personalSite: 'Personal Website',
};

interface SocialInputProps
    extends Omit<ComponentProps<typeof TextInput>, 'icon' | 'type'> {
    type: SocialKeys;
    error?: string;
}

const SocialInput = (props: SocialInputProps): JSX.Element => {
    const { type, value, error, ...inputProps } = props;
    const icon = socialMediaIcons[type];

    const isDesktop = useIsLargeScreen();

    const socialDomain = isDesktop
        ? socialInputLabels[type]
        : socialInputLabels[type]?.replace('www.', '');

    return (
        <Grid alignItems='center' width='full' paddingTop={4} gap={2}>
            <Grid.Item columnSpan={4} justifySelf='flex-end' alignSelf='center'>
                <Text fontSize={{ base: 'sm', md: 'base' }}>
                    {socialDomain}
                </Text>
            </Grid.Item>
            <Grid.Item columnSpan={8}>
                <TextInput
                    icon={{
                        as: icon,
                        color: value ? 'teal.trova' : 'blueGray.400',
                        position: 'right',
                        size: 'md',
                    }}
                    size='md'
                    value={value}
                    placeholder={placeholders[type]}
                    isInvalid={!!error}
                    {...inputProps}
                />
            </Grid.Item>
        </Grid>
    );
};

export default SocialInput;
