import {
    Badge,
    BaseBox,
    Heading,
    IconButton,
    Stack,
} from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';
import { FieldName } from '../utils/types';
import { FIELD_MAP, FIELD_MAP_TRACKING_EVENTS } from '../utils/constants';
import usePublicProfileAnalytics from '../../../hooks/usePublicProfileAnalytics';

interface FieldWrapperProps {
    fieldName: FieldName;
    formData?: models.hostProfiles.HostProfileResponse[FieldName];
    onEditButtonClick: (fieldName: string) => void;
}

const FieldWrapper = ({
    fieldName,
    formData,
    onEditButtonClick,
}: FieldWrapperProps): JSX.Element => {
    const { trackPublicProfileEvent } = usePublicProfileAnalytics();

    const onClick = () => {
        trackPublicProfileEvent(FIELD_MAP_TRACKING_EVENTS[fieldName]);
        onEditButtonClick(fieldName);
    };

    const {
        title,
        isRequired = false,
        preview: Preview,
    } = FIELD_MAP[fieldName];

    const previewProps = {
        [fieldName]: formData,
    };

    const shouldRenderRequiredBadge = isRequired && !formData;

    return (
        <Stack
            direction='column'
            paddingY={6}
            background='neutral.white'
            width='full'
        >
            <Stack
                spacing={0}
                align='center'
                justify='space-between'
                width='full'
                wrap='nowrap'
            >
                <Stack spacing={3} align='center'>
                    <Heading as='h4'>{title}</Heading>
                    {shouldRenderRequiredBadge ? (
                        <Badge variant='rounded' colorScheme='red'>
                            Required
                        </Badge>
                    ) : null}
                </Stack>
                <IconButton
                    aria-label={`Edit ${title}`}
                    icon='pencil'
                    variant='outlined'
                    iconColor='red.trova'
                    onClick={onClick}
                />
            </Stack>
            <BaseBox paddingY={2}>
                <Preview {...previewProps} />
            </BaseBox>
        </Stack>
    );
};

export default FieldWrapper;
