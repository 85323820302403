import { BaseComboBoxOption, OptionProps } from '@trova-trip/trova-components';
import { DropdownOptions } from '../../config/constants';

export type Option = BaseComboBoxOption;
export type LegacyOption = OptionProps;

type OptionReturnType<TLegacy> = TLegacy extends true
    ? LegacyOption[]
    : Option[];

export const DropdownOptionKeys = {
    AccommodationTypes: `AccommodationTypes`,
    RoomCategory: `RoomCategory`,
    Occupancy: `Occupancy`,
    StarRating: `StarRating`,
    TripCategories: `TripCategories`,
    MealType: `MealType`,
    MealOptions: `MealOptions`,
    VehicleType: `VehicleType`,
    // TODO should we delete?
    Currencies: `Currencies`,
    Countries: `Countries`,
    Destinations: `Destinations`,
    DaysOfTheWeek: `DaysOfTheWeek`,
    ServiceTypesLabels: `ServiceTypesLabels`,
    HostBedTypes: `HostBedTypes`,
    ActivityLevels: `ActivityLevels`,
} as const;

/**
 * @description Filters options from a list of options based on a search value matching the option's value or label
 * @param {string} searchValue - The value to search for
 * @param {Option[] | LegacyOption[]} optionList - The list of options to filter
 * @returns {Option | LegacyOption} - The filtered list of options
 */
const filterOptionsFromValue = <TOption extends Option | LegacyOption>(
    searchValue: string,
    optionList: TOption[],
): TOption[] =>
    optionList.filter((option) => {
        const labelField = 'children' in option ? 'children' : 'label';
        const lowerCasedSearchValue = searchValue.toLowerCase();
        const lowerCasedOptionValue = option.value.toLowerCase();
        const lowerCasedOptionLabel = option[labelField].toLowerCase();
        return (
            lowerCasedOptionLabel.includes(lowerCasedSearchValue) ||
            lowerCasedOptionValue.includes(lowerCasedSearchValue)
        );
    });

/**
 * @description Finds an option from a list of options based on a search value matching the option's value
 * @param {string} searchValue - The value to search for
 * @param {Option[] | LegacyOption[]} optionList - The list of options to filter
 * @returns {Option | LegacyOption | undefined} - The option that matches the search value
 */
const findOptionFromValue = <TOption extends Option | LegacyOption>(
    searchValue: string,
    optionList: TOption[],
): TOption | undefined =>
    optionList.find(({ value }) => {
        const lowerCaseSearchValue = searchValue.toLowerCase();
        return value.toLowerCase() === lowerCaseSearchValue;
    });

const rotateArray = <TArray extends unknown[]>(
    array: TArray,
    position: number,
): TArray => array.slice(position).concat(array.slice(0, position)) as TArray;

/**
 * @description Generates a list of options from an object of key value pairs
 * @param {Record<string, string>} options - The object of key value pairs to generate options from
 * @param {boolean} isLegacy - Whether or not the options are for legacy dropdowns
 * @returns {Option[] | LegacyOption[]} - The list of options
 * */
const generateDropdownOptionsFromObject = <TLegacy extends boolean = true>(
    options: Record<string, string>,
    isLegacy: TLegacy = true as TLegacy,
): OptionReturnType<TLegacy> => {
    const dropdownOptions = Object.entries(options).map(([value, label]) => ({
        value,
        ...(isLegacy ? { children: label } : { label }),
    })) as OptionReturnType<TLegacy>;
    return dropdownOptions;
};

/**
 * @description Gets a list of options from a identification key
 * @param {DropdownOptionKeys} dropDownOptionKey - The key to get the options from
 * @param {boolean} isLegacy - Whether or not the options are for legacy dropdowns
 * @returns {Option[] | LegacyOption[]} - The list of options
 * */
const getDropdownOptionsByKey = <TLegacy extends boolean = true>(
    dropDownOptionKey: typeof DropdownOptionKeys[keyof typeof DropdownOptionKeys],
    isLegacy: TLegacy = true as TLegacy,
): OptionReturnType<TLegacy> => {
    const options = DropdownOptions[dropDownOptionKey];
    const dropdownOptions = generateDropdownOptionsFromObject(
        options,
        isLegacy,
    );

    if (dropDownOptionKey === `DaysOfTheWeek`) {
        // rotate day-of-week options array cause it comes Sun - Sat and we need it to be Mon - Sun
        return rotateArray(dropdownOptions, 1);
    }
    return dropdownOptions;
};

export {
    filterOptionsFromValue,
    findOptionFromValue,
    generateDropdownOptionsFromObject,
    getDropdownOptionsByKey,
};
