/*issue with CRA4 and React 17: https://github.com/emotion-js/emotion/issues/2041*/
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
    DraggablePill,
    DraggableContainer,
    Stack,
    BaseBox,
    Text,
} from '@trova-trip/trova-components';
import { Card } from '@trova-trip/trova-components/build/next';

import { ItineraryServices } from 'config/constants';

const DraggableServices = ({ isDisabled }) => (
    <Card padding={4} backgroundColor='blueGray.200' marginBottom={4}>
        <Text
            size='sm'
            fontWeight='bold'
            color='blueGray.dark'
            marginLeft={2}
            marginBottom={2}
        >
            Add to the itinerary
        </Text>
        <Stack spacing={0}>
            <DraggableContainer>
                {ItineraryServices.map((service) => {
                    const { serviceName, serviceType, colorScheme } = service;
                    const item = {
                        type: `draggableButton`,
                        serviceType,
                    };
                    return (
                        <BaseBox key={serviceName} padding={1}>
                            <DraggablePill
                                dragTargetConfiguration={{
                                    item,
                                }}
                                onCollect={(item) => item}
                                colorScheme={colorScheme}
                                isDisabled={isDisabled}
                            >
                                {serviceName}
                            </DraggablePill>
                        </BaseBox>
                    );
                })}
            </DraggableContainer>
        </Stack>
    </Card>
);

export default DraggableServices;
