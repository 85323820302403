import { coreUtils } from '@trova-trip/trova-common';
import { models, constants } from '@trova-trip/trova-models';
import { useMemo } from 'react';
import { getAccommodationServices } from '../../common/helpers';
import { getPricedServices } from '../components/TripRequestForm/tripRequestFormHelper';

type Service = models.services.Service;
type HostRoom = models.tripRequest.HostRoom;
type ItineraryInventoryItem =
    models.itineraryInventoryItems.ItineraryInventoryItem;
type AccommodationPrice = models.itineraryInventoryItems.AccommodationPrice;

const getRoomsConfigForOperators =
    coreUtils.tripUtils.getRoomsConfigForOperators;
const OperatorHostRoomType = constants.tripRequest.OperatorHostRoomType;

const hasPricedAccommodations = (
    accommodationPrice: AccommodationPrice | undefined,
    rooms: HostRoom[],
): boolean => {
    if (!accommodationPrice) {
        return false;
    }

    const roomsConfigs = getRoomsConfigForOperators(rooms).map((room) => {
        return {
            ...room,
            roomType:
                room.roomType === OperatorHostRoomType.TWIN
                    ? OperatorHostRoomType.DOUBLE
                    : room.roomType,
        };
    });

    return roomsConfigs.every((room) => {
        const roomPrice = accommodationPrice[room.roomType];
        return roomPrice !== undefined && roomPrice > 0;
    });
};

export type AccommodationsAvailability = {
    arePreAccommodationsAvailable: boolean;
    arePostAccommodationsAvailable: boolean;
};

export const useAccommodationsAvailability = (
    additionalOptionalServices: Service[] = [],
    itineraryInventoryItem: ItineraryInventoryItem | undefined,
    roomConfig: HostRoom[],
): AccommodationsAvailability => {
    const availability: AccommodationsAvailability = useMemo(() => {
        const { postTripAccommodationServices, preTripAccommodationsServices } =
            getAccommodationServices(additionalOptionalServices);

        const pricedPreAccommodations = getPricedServices(
            preTripAccommodationsServices,
        );
        const pricedPostAccommodations = getPricedServices(
            postTripAccommodationServices,
        );

        return {
            arePreAccommodationsAvailable: itineraryInventoryItem
                ? hasPricedAccommodations(
                      itineraryInventoryItem?.preAccommodationPrice,
                      roomConfig,
                  )
                : !!pricedPreAccommodations.length,
            arePostAccommodationsAvailable: itineraryInventoryItem
                ? hasPricedAccommodations(
                      itineraryInventoryItem?.postAccommodationPrice,
                      roomConfig,
                  )
                : !!pricedPostAccommodations.length,
        };
    }, [additionalOptionalServices, itineraryInventoryItem, roomConfig]);

    return availability;
};
