import React from 'react';
import { useSelector } from 'react-redux';

import { Itinerary } from 'applications/types';
import Tab from 'navigation/Tab';
import IFramedItineraryDetails from './IFramedItineraryDetails';

export const ItineraryDetails: React.FC = () => {
    const itineraryId = useSelector(
        (state: { userItinerary: { current: Itinerary } }) =>
            state.userItinerary?.current?.id,
    );
    return itineraryId ? (
        <Tab
            label={`Itinerary Description`}
            path={`/description`}
            hideNavigation
        >
            <IFramedItineraryDetails itineraryId={itineraryId} />
        </Tab>
    ) : null;
};
