import FetchState from './utils/FetchState';
import { models } from '@trova-trip/trova-models';

type GroundTransfer = models.groundTransfers.GroundTransfer;

type UserGroundTransfersSelector = {
    list: GroundTransfer[] | undefined;
};

const userGroundTransfers = FetchState.create(`/api/me/ground-transfers`);

const userGroundTransfersReducer = userGroundTransfers.getReducer();

export { userGroundTransfers, userGroundTransfersReducer };
export type { UserGroundTransfersSelector };
