import styled from '@emotion/styled';

export const StyledParagraph = styled.p`
    font-size: 1rem;
    font-weight: 400;
    
`
export const PreparationContentWrapper = styled.div`
    margin-top: 20px;
`
