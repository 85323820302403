import { Redirect } from 'react-router';
import Product from '../../../../navigation/Product';
import Tab from '../../../../navigation/Tab';
import useAudience from './hooks/useAudience';
import { HOST_APP_PRODUCT_ROUTE } from '../../../common/constants/routesTokens';
import { AudienceSummary } from './components/AudienceSummary';
import { MainContent } from '@trova-trip/trova-components/build/next';
import AudienceMetrics from './tabs/AudienceMetrics';
import AudienceRecommendedItineraries from './components/AudienceRecommendedItineraries/AudienceRecommendedItineraries';

type AudienceProps = {
    isHostQualified: boolean;
};

const defaultTab = 'research';

const Audience = ({ isHostQualified }: AudienceProps): JSX.Element => {
    const {
        audienceSummaryData,
        isMetricPopulated,
        toggleQualifiedReport,
        audienceResponses,
        isQualifiedResponse,
    } = useAudience(isHostQualified);

    return (
        <Product
            path={HOST_APP_PRODUCT_ROUTE.AUDIENCE}
            label='Audience'
            icon='group'
            loadNavigationContent={(): null => null}
            root={<Redirect to={`/app/host/audience/${defaultTab}`} />}
        >
            <Tab label='Audience Research' path='/research' hideNavigation>
                <MainContent.Inner maxWidth='container.2lg' margin='0 auto'>
                    <AudienceSummary {...audienceSummaryData} />
                    {isHostQualified ? (
                        <AudienceRecommendedItineraries />
                    ) : null}
                    <AudienceMetrics
                        isHostQualified={isHostQualified}
                        surveyData={audienceResponses}
                        onToggleMetricsType={toggleQualifiedReport}
                        showQualifiedMetrics={isQualifiedResponse}
                        isMetricPopulated={isMetricPopulated}
                    />
                </MainContent.Inner>
            </Tab>
        </Product>
    );
};

export default Audience;
