import { useEffect, useState } from 'react';
import { getEmailPreview } from 'state/trips';
import { models, constants } from '@trova-trip/trova-models';

type Trip = models.trips.Trip;
type User = models.users.BaseUser;
const { PROMOTE_EMAIL_HOST_BLURB_PREVIEW_ID } = constants.emails;

export interface HostInfo {
    firstName: User['firstName'] | undefined;
    lastName: User['lastName'] | undefined;
    displayName: User['displayName'] | undefined;
    email: string | undefined;
}

export type UpdateEmailPreview = (blurb: string) => void;
type UsePreviewEmailReturn = {
    emailPreview: string;
    subject: string;
    isLoading: boolean;
    updateEmailPreview: UpdateEmailPreview;
};

const convertStringToHTML = (str: string): Document => {
    const parser = new DOMParser();
    const document = parser.parseFromString(str, 'text/html');

    return document;
};

const updatePreviewBlurb = (
    blurb: string,
    blurbId: string,
    previewString: string,
): string => {
    const emailPreviewTemplate: Document = convertStringToHTML(previewString);
    const blurbElement: HTMLElement | null =
        emailPreviewTemplate.getElementById(blurbId);

    if (blurbElement) {
        blurbElement.innerText = blurb;
    }

    const document: string = emailPreviewTemplate.documentElement.outerHTML;

    return document;
};

export const usePreviewEmail = (
    tripId: Trip['_id'],
    emailHookId: string | undefined,
    hostInfo: HostInfo,
): UsePreviewEmailReturn => {
    const [emailPreview, setEmailPreview] = useState<string>('');
    const [subject, setSubject] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { firstName, lastName, displayName } = hostInfo;

    useEffect(() => {
        if (!tripId || !emailHookId || !firstName || !lastName) return;

        const fetchEmailPreviewTemplate = async (): Promise<void> => {
            try {
                const hostParameters = {
                    firstName: displayName || firstName,
                    lastName: lastName,
                };
                const emailPreviewTemplate = await getEmailPreview(
                    tripId,
                    emailHookId,
                    hostParameters,
                );

                if (emailPreviewTemplate.success) {
                    const {
                        data: { previewHtml, subject },
                    } = emailPreviewTemplate;

                    setEmailPreview(previewHtml);
                    setSubject(subject);
                    setIsLoading(false);
                }
            } catch (error) {
                return;
            }
        };

        setIsLoading(true);
        setEmailPreview('');
        setSubject('');
        fetchEmailPreviewTemplate();
    }, [tripId, emailHookId, firstName, lastName]);

    const updateEmailPreview = (blurb: string): void => {
        const newPreview = updatePreviewBlurb(
            blurb,
            PROMOTE_EMAIL_HOST_BLURB_PREVIEW_ID,
            emailPreview,
        );
        setEmailPreview(newPreview);
    };

    return { emailPreview, isLoading, updateEmailPreview, subject };
};
