import {
    BaseBox,
    Heading,
    Stack,
    Text,
    useToast,
} from '@trova-trip/trova-components';
import {
    Button,
    Card,
    Icon,
    IconProps,
} from '@trova-trip/trova-components/build/next';
import { useSelector } from 'react-redux';
import { TripDetailsStoreSelector } from '../../../../state/userTrips';
import useClipboard from '../../../common/hooks/useClipboard';

export enum TripContactCardType {
    CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
    TRIP_SUPPORT = 'TRIP_SUPPORT',
}

interface TripContactCardProps {
    type: TripContactCardType;
}

interface ContactCardContent {
    icon: IconProps['as'];
    title: string;
    description: string;
    button: (onClick?: () => void) => JSX.Element;
}

const contactCardContentMap: {
    [key in TripContactCardType]: ContactCardContent;
} = {
    [TripContactCardType.CUSTOMER_SERVICE]: {
        icon: 'sentimentSatisfiedAlt',
        title: 'Customer Service',
        description:
            'Do you have questions about your account or a future trip?',
        button: () => (
            <Button
                as='a'
                variant='tertiary'
                size='sm'
                linkProps={{
                    href: 'https://trovatrip.com/company/contact-us',
                    target: '_blank',
                    rel: 'noreferrer',
                }}
            >
                Contact Us
            </Button>
        ),
    },
    [TripContactCardType.TRIP_SUPPORT]: {
        icon: 'phonelinkRing',
        title: 'On Trip Support',
        description: 'This is our emergency contact for this trip:',
        button: (onClick) => (
            <Button onClick={onClick} variant='tertiary' size='sm'>
                Copy Number
            </Button>
        ),
    },
};

const TripContactCard = ({
    type,
}: TripContactCardProps): JSX.Element | null => {
    const currentTripDetails = useSelector(
        (state: TripDetailsStoreSelector) => state.userTripDetails.current,
    );
    const toast = useToast();
    const { copyTextToClipboard } = useClipboard();

    const isTripSupport = type === TripContactCardType.TRIP_SUPPORT;
    const operatorPhone =
        currentTripDetails?.operatorContactInfo?.contact.phone;

    if (isTripSupport && !operatorPhone) return null;

    const onClick = (): void => {
        if (isTripSupport) {
            copyTextToClipboard(operatorPhone);

            toast({
                title: 'Phone number copied',
                description: `The operator phone number was successfully copied`,
                status: 'success',
                isClosable: true,
            });
        }
    };

    const { icon, title, description, button } = contactCardContentMap[type];

    return (
        <BaseBox width='full' marginBottom={4}>
            <Card width='full' padding={4}>
                <Stack direction='row' wrap='nowrap' align='center' spacing={4}>
                    <Stack direction='column'>
                        <Icon as={icon} size='lg' color='teal.trova' />
                    </Stack>
                    <Stack direction='column'>
                        <Heading as='h6' fontSize='base' fontWeight='medium'>
                            {title}
                        </Heading>
                        <Stack spacing={{ base: 0, sm: 1, md: 0 }}>
                            <Text
                                fontSize='sm'
                                color='blueGray.500'
                                wordBreak='keep-all'
                            >
                                {description}
                            </Text>
                            {isTripSupport ? (
                                <Text fontSize='sm' color='blueGray.500'>
                                    {operatorPhone}
                                </Text>
                            ) : null}
                        </Stack>
                        {button(onClick)}
                    </Stack>
                </Stack>
            </Card>
        </BaseBox>
    );
};

export default TripContactCard;
