import { appConstants } from '@trova-trip/trova-common';
import {
    BaseBox,
    BaseBoxProps,
    Hide,
    IconButton,
    Nav,
    Navbar as NavbarComponent,
    Stack,
    Text,
    WithTooltip,
    useReveal,
    useWebShare,
} from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import { constants } from '@trova-trip/trova-models';
import noop from 'lodash/noop';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTrackingEvent } from '../../analytics/hooks';
import useIsLargeScreen from '../../applications/common/hooks/useIsLargeScreen';
import SharePublicHostProfileDialog from '../../applications/host/components/SharePublicHostProfileDialog/SharePublicHostProfileDialog';
import useProfileCompletionStatus, {
    ProfileCompletionStatuses,
} from '../../applications/host/hooks/useProfileCompletionStatus';
import usePublicProfileAnalytics, {
    PUBLIC_PROFILE_DIALOG_LOCATION,
    PUBLIC_PROFILE_TRACKING_EVENTS,
} from '../../applications/host/hooks/usePublicProfileAnalytics';
import { usePublicProfileUrl } from '../../applications/host/hooks/usePublicProfileUrl';
import { User } from '../../applications/types';
import {
    HostResurveyStatuses,
    isProfileAddressEnabled,
    isPublicHostProfileEnabled,
} from '../../config/constants';
import { useSelector } from '../../state/hooks';
import { APP_ENVIRONMENT } from '../../util/appUtils';
import NavigationContext from '../NavigationContext';
import { includesPathname } from '../common/helpers';
import useProfileNavigationDrawer from './ProfileNavigationDrawer/useProfileNavigationDrawer';

const profileStatusToAvatarStatus = {
    [ProfileCompletionStatuses.INCOMPLETE]: 'warning',
    [ProfileCompletionStatuses.REQUIRED]: 'error',
} as const;

const { Group } = constants.user;

const BaseBoxWithTooltip = WithTooltip<BaseBoxProps>(BaseBox);

interface IntercomButtonProps {
    onClick?: () => void;
}

const IntercomButton = ({ onClick = noop }: IntercomButtonProps) => {
    const isProdEnv = APP_ENVIRONMENT === appConstants.Environment.Production;

    return (
        <BaseBoxWithTooltip
            id={isProdEnv ? 'intercom-cta' : undefined}
            aria-label='help'
            tooltipConfigProps={{
                tooltip: (
                    <Text
                        as='span'
                        size='sm'
                        fontWeight='bold'
                        color='neutral.white'
                    >
                        Help Center
                    </Text>
                ),
                placement: 'bottom',
            }}
            onClick={onClick}
        >
            <IconButton
                icon='help'
                aria-label='help'
                size='md'
                variant='ghost'
                iconColor='blueGray.650'
            />
        </BaseBoxWithTooltip>
    );
};

const Navbar = (): JSX.Element => {
    const { toggleDrawer } = useProfileNavigationDrawer();
    const { products, profile } = useContext(NavigationContext);
    const history = useHistory();
    const { trackUserEvent } = useTrackingEvent();
    const { trackPublicProfileEvent } = usePublicProfileAnalytics();
    const { aggregatedStatus } = useProfileCompletionStatus();
    const isLargeScreen = useIsLargeScreen();
    const { share } = useWebShare();
    const { isOpen, triggerOpen, triggerClose } = useReveal();

    const isHost = profile?.group === Group.HOST;
    const avatarStatus = profileStatusToAvatarStatus[aggregatedStatus];
    const showAvatarStatus = isHost && isProfileAddressEnabled;

    const { status } = useSelector((state) => {
        return state.profile.current as User;
    });

    const isResurvey = HostResurveyStatuses.has(status);

    const navigateToProduct = useCallback(
        (path) => {
            if (path?.endsWith('home')) {
                trackUserEvent({
                    eventName: 'Host Selected Global Home Button',
                });
            }
            history.push(path);
        },
        [history, trackUserEvent],
    );

    const publicProfileUrl = usePublicProfileUrl();

    // Check if we should use the mobile web share
    const shareProfile = () => {
        const data = {
            title: 'Public Profile URL',
            text: publicProfileUrl,
        };

        trackPublicProfileEvent(PUBLIC_PROFILE_TRACKING_EVENTS.shareProfile, {
            location: PUBLIC_PROFILE_DIALOG_LOCATION.globalNav,
        });

        if (!isLargeScreen && !isResurvey) {
            return share(data, triggerOpen);
        }

        triggerOpen();
    };

    const handleIntercomButtonClick = () => {
        trackUserEvent({
            eventName: 'Host clicked question mark icon in global nav',
        });
    };

    return (
        <NavbarComponent isFixed={false}>
            <Hide breakpoint={{ max: 'md' }}>
                <Nav>
                    {products.map(({ label, path }) => (
                        <Nav.Item
                            isActive={includesPathname(path)}
                            key={path}
                            onClick={(): void => navigateToProduct(path)}
                        >
                            {label}
                        </Nav.Item>
                    ))}
                </Nav>
            </Hide>
            <Nav itemsAlign='right' spacing={4}>
                {isHost ? (
                    <Nav.Item>
                        <Stack spacing={4} wrap='nowrap' align='center'>
                            {isPublicHostProfileEnabled ? (
                                <Button
                                    colorScheme='teal'
                                    variant='secondary'
                                    size='sm'
                                    onClick={shareProfile}
                                >
                                    Share Profile
                                </Button>
                            ) : null}
                            <IntercomButton
                                onClick={handleIntercomButtonClick}
                            />
                        </Stack>
                    </Nav.Item>
                ) : null}
                <Nav.Item>
                    <NavbarComponent.Avatar
                        onClick={toggleDrawer}
                        src={profile?.profilePictureUrl}
                        status={showAvatarStatus ? avatarStatus : undefined}
                    />
                </Nav.Item>
                {isPublicHostProfileEnabled ? (
                    <SharePublicHostProfileDialog
                        isOpen={isOpen}
                        location={PUBLIC_PROFILE_DIALOG_LOCATION.globalNav}
                        onClose={triggerClose}
                        message='Collect survey responses, grow your audience, and promote upcoming trips.'
                        link={publicProfileUrl}
                    />
                ) : null}
            </Nav>
        </NavbarComponent>
    );
};

export default Navbar;
