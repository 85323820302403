import { Heading, Stack } from '@trova-trip/trova-components';
import { Card, CardProps } from '@trova-trip/trova-components/build/next';

interface CardSectionProps extends CardProps {
    title: string;
    children: React.ReactNode;
}

const CardSection = ({ title, children, ...rest }: CardSectionProps) => {
    return (
        <Card {...rest}>
            <Stack direction='column' spacing={4} align={{ md: 'stretch' }}>
                <Heading as={'h4'}>{title}</Heading>
                {children}
            </Stack>
        </Card>
    );
};

export default CardSection;
