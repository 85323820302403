import { models } from '@trova-trip/trova-models';
import { ServiceTypesKeys } from '../../../config/constants';
import {
    DayServiceUIModel,
    ServicesByDayUIModel,
} from '../../../interfaces/UiModels.types';

const SERVICE_TYPE_PRIORITY = [
    [ServiceTypesKeys.INFO],
    Object.values(ServiceTypesKeys).filter(
        (type) =>
            ![ServiceTypesKeys.INFO, ServiceTypesKeys.ACCOMMODATION].includes(
                type,
            ),
    ),
    [ServiceTypesKeys.ACCOMMODATION],
];

const getPriorityForType = (serviceType: string) =>
    SERVICE_TYPE_PRIORITY.findIndex((types) => types.includes(serviceType));

const getPrioritizedServicesByDay = (
    servicesByDay: ServicesByDayUIModel,
): ServicesByDayUIModel =>
    servicesByDay.map((dayServices) =>
        dayServices.map((dayService) => ({
            ...dayService,
            priority: getPriorityForType(
                (dayService.service as models.services.Service).type,
            ),
        })),
    );

const sortServicesForDay = (dayServices: DayServiceUIModel[]) =>
    dayServices.sort(
        (serviceA, serviceB) => serviceA.priority - serviceB.priority,
    );

const orderServicesByDay = (
    servicesByDay: ServicesByDayUIModel,
): ServicesByDayUIModel => {
    const clonedServicesByDay = [...servicesByDay];
    const prioritizedServicesByDay =
        getPrioritizedServicesByDay(clonedServicesByDay);

    return prioritizedServicesByDay.map((dayServices) =>
        sortServicesForDay(dayServices),
    );
};

const insertServiceAndSort = (
    servicesByDay: ServicesByDayUIModel,
    daySelected: number,
    newService: models.services.Service,
    duration: number,
): ServicesByDayUIModel => {
    const prioritizedServicesByDay = getPrioritizedServicesByDay([
        ...servicesByDay,
    ]);

    const prioritizedNewService: DayServiceUIModel = {
        // @ts-expect-error
        service: (newService.id as string) || (newService._id as string),
        duration: duration || 1,
        priority: getPriorityForType(newService.type),
    };
    prioritizedServicesByDay[daySelected].push(prioritizedNewService);

    prioritizedServicesByDay[daySelected] = sortServicesForDay(
        prioritizedServicesByDay[daySelected],
    );

    return prioritizedServicesByDay;
};

const filterViewOnlyServicesByDay = (
    servicesByDay: ServicesByDayUIModel,
): ServicesByDayUIModel =>
    servicesByDay.map((day) => day.filter(({ service }) => !service.viewOnly));

export {
    getPriorityForType,
    orderServicesByDay,
    insertServiceAndSort,
    filterViewOnlyServicesByDay,
};
