import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { logout } from '../../state/authentication';

import {
    SidebarItemGroup,
    SidebarItemProps,
} from '@trova-trip/trova-components/build/next';
import {
    NavigationCategory,
    NavigationCategoryItem,
} from '../../interfaces/Navigation.types';

const doesMatchId = (id: string | undefined): boolean => {
    if (id) {
        const pathName = window.location.pathname.toLowerCase();
        return pathName.includes(id);
    }
    return false;
};

const getLabels = (
    labelItems: NavigationCategoryItem['labelItems'] = [],
): SidebarItemProps['labels'] =>
    labelItems?.map((labelItem) => ({
        text: labelItem.label,
        leftIconProps: labelItem.leftIconProps,
        color: labelItem.color,
    }));

export const useLogoutAndRedirect = (redirectTo?: string): (() => void) => {
    const history = useHistory();
    const logoutAndRedirect = useCallback(() => {
        logout(() => history.push(redirectTo || '/logout'));
    }, [history, redirectTo]);
    return logoutAndRedirect;
};

export const useFormatSidebarProps = (
    navigationContent: NavigationCategory[],
): { itemGroups: SidebarItemGroup[] } => {
    const history = useHistory();
    const getItems = useCallback(
        (items: NavigationCategoryItem[]): SidebarItemProps[] =>
            items.map((item) => {
                const { title, id, subtitle, link } = item;
                const sidebarItem: SidebarItemProps = {
                    title,
                    id,
                    subtitle,
                    isSelected: doesMatchId(id),
                    labels: getLabels(item.labelItems),
                };
                if (link) {
                    sidebarItem.onClick = () => history.push(link);
                }
                return sidebarItem;
            }),
        [],
    );

    const itemGroups: SidebarItemGroup[] = useMemo(() => {
        const mappedCategories = navigationContent.map((category) => {
            const newCategory: SidebarItemGroup = {
                title: category.categoryLabel,
                items: getItems(category.items),
            };
            return newCategory;
        });
        return mappedCategories;
    }, [navigationContent, history.location.pathname]);

    return { itemGroups };
};
