import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme, ComponentWidth } from '@trova-trip/trova-components';
import FileInput from '../../scenes/Admin/AdminForm/ImageFileInput';

const fileInputStyle = (props) =>
    css`
        box-shadow: none !important;
        margin: 0 !important;
        .fileuploader {
            padding: 0;
        }
        .card-body {
            padding: 0;
        }
        .fileuploader-theme-gallery
            .fileuploader-input-inner
            .fileuploader-icon-main {
            color: ${theme.colors.red.coral};
        }
        span {
            font-family: ${theme.fonts.roboto};
        }
        .fileuploader-theme-gallery
            .fileuploader-items
            .fileuploader-items-list {
            margin: 0;
        }
        .fileuploader-input {
            display: inline-table;
            margin: 16px;
        }
        .fileuploader-theme-gallery .fileuploader-input-inner {
            border: 1px solid ${theme.colors.neutral[80]};
        }
        .fileuploader-theme-gallery .fileuploader-item .type-holder {
            display: none;
        }
        ${!props.error &&
        `.fileuploader-theme-gallery .fileuploader-input-inner:hover {
        border: 1px solid ${theme.colors.blue[500]};
        }
            `}
        ${props.error &&
        props.error.length &&
        `.fileuploader-theme-gallery .fileuploader-input-inner {
        border: 1px solid ${theme.colors.red.trova};
        }
            `};
        ${props.size === ComponentWidth.ExtraSmall &&
        `.fileuploader-theme-gallery .fileuploader-input-inner {
                min-width: 64px; max-width:100px;}`};
        ${props.size === ComponentWidth.Small &&
        `.fileuploader-theme-gallery .fileuploader-input-inner {width: 157px;}`};
        ${props.size === ComponentWidth.Medium &&
        `.fileuploader-theme-gallery .fileuploader-input-inner {width: 250px;}`};
        ${props.size === ComponentWidth.Large &&
        `.fileuploader-theme-gallery .fileuploader-input-inner {width: 343px;}`};
        ${props.size === ComponentWidth.ExtraLarge &&
        `.fileuploader-theme-gallery .fileuploader-input-inner {width: 436px;}`};
    `;

export const StyledFileInput = styled(FileInput)(fileInputStyle);
