import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as yup from 'yup';

import {
    ComponentWidth,
    FormSaver,
    Grid,
    OptionProps,
    useFormSaver,
} from '@trova-trip/trova-components';
import {
    Button,
    DatePicker,
    Dialog,
    Dropdown,
} from '@trova-trip/trova-components/build/next';
import { models } from '@trova-trip/trova-models';

import noop from 'lodash/noop';
import { useTrackingEvent } from '../../../../../analytics/hooks';
import {
    createTimeDropdownOptions,
    TimeDropdownOption,
} from '../../../../common/helpers';
import {
    calculateDisabledDates,
    createLaunchDate,
    getLaunchDateInitialValues,
    getShortTimeZone,
    isLaunchDateValid,
    LaunchDateInitialValues,
    LaunchDateObject,
    launchDateSchemaShape,
    shouldDisplayLaunchDateFields,
} from '../../../utils';
import FormHeader from './FormHeader';

type LaunchDateFormProps = {
    currentTrip: models.trips.Trip;
    onSubmit: (
        values: any,
        successMessage: string,
        errorMessage: string,
    ) => Promise<any>;
};

const FieldsNames = {
    launchDate: 'launchDate',
    launchTime: 'launchTime',
};

const schema = yup
    .object()
    .shape(launchDateSchemaShape, [['launchDate', 'launchTime']]);

const LaunchDateForm = ({
    currentTrip,
    onSubmit,
}: LaunchDateFormProps): JSX.Element => {
    const { launchDate } = currentTrip;

    const areLaunchDateFieldsEnabled: boolean =
        shouldDisplayLaunchDateFields(currentTrip);
    const canUnscheduleLaunchDate = !!launchDate && areLaunchDateFieldsEnabled;

    const [isScheduleModalOpen, setIsScheduleModalOpen] =
        useState<boolean>(false);

    const disabledDates = calculateDisabledDates(currentTrip);

    const initialValues: LaunchDateInitialValues =
        getLaunchDateInitialValues(launchDate);

    const defaultTimeOptions: OptionProps[] = useMemo(
        () =>
            createTimeDropdownOptions(0, 10).map(({ value, label }) => ({
                value,
                children: label,
            })),
        [],
    );

    const { trackUserEvent } = useTrackingEvent();

    const handleFormSubmit = (values): Promise<any> => {
        const { launchDate, launchTime } = values;

        const launchISODate: Date | null = createLaunchDate(
            launchDate as string,
            launchTime as string,
        );

        let payload = {};

        if (canUnscheduleLaunchDate) {
            formSaver.reset();
            payload = { launchDate: null };
            trackUserEvent({
                eventName: 'Host Unscheduled Launch',
                properties: {
                    tripId: currentTrip?.id,
                    previousLaunchDateTime: launchISODate,
                },
            });
        } else {
            payload = {
                ...(launchISODate && {
                    launchDate: launchISODate,
                }),
            };
            trackUserEvent({
                eventName: 'Host Scheduled Launch (Config)',
                properties: {
                    tripId: currentTrip?.id,
                    scheduledDateTime: launchISODate,
                },
            });
        }

        return onSubmit(
            payload,
            `Trip Launch ${
                canUnscheduleLaunchDate ? 'Unscheduled' : 'Scheduled'
            }!`,
            `There was an error ${
                canUnscheduleLaunchDate ? 'unscheduling' : 'scheduling'
            } your trip launch date, please try again.`,
        );
    };

    const formSaver = useFormSaver({
        onSubmit: handleFormSubmit,
        validationSchema: {
            schema,
            validateFieldOnChange: false,
        },
        initialValues,
    });

    const shortTimeZone: string = getShortTimeZone();

    const launchDatePickerSelector = `${FieldsNames.launchDate}-date-picker`;
    const launchDateInputRef = useRef<HTMLInputElement | null>();
    const launchTimeInputRef = useRef<HTMLInputElement | null>();

    useEffect(() => {
        launchDateInputRef.current = document.querySelector(
            `.${launchDatePickerSelector} input[type = "text"]`,
        ) as HTMLInputElement;

        launchTimeInputRef.current = document.querySelector(
            `input[name = "${FieldsNames.launchTime}"]`,
        ) as HTMLInputElement;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const canSubmitForm = (): boolean => {
        const { launchDate, launchTime } = formSaver.formState;

        const isFormDirty = formSaver.isFormDirty;
        const isFormValid = schema.isValidSync({
            launchDate,
            launchTime,
        });

        const isDateInsideMinimumLaunchRange = isLaunchDateValid(
            formSaver.formState as LaunchDateObject,
            currentTrip,
        );

        return (
            (isDateInsideMinimumLaunchRange && isFormDirty && isFormValid) ||
            canUnscheduleLaunchDate
        );
    };

    const onDatePickerChange = (_, __, value): void => {
        // TODO: To be changed after updates in Trova components, as the component does not emit any event or value when the date entered is invalid or the input is empty.

        const inputValue: string | undefined =
            launchDateInputRef.current?.value;

        if (inputValue) {
            const isValidDate = moment(inputValue).isValid();

            // TODO: change after updating the component in Trova-components to disallow selecting non-selectable dates through key entry.
            const isSelectableDate = moment(inputValue).isAfter(
                moment().subtract(1, 'day'),
            );

            if (isValidDate && isSelectableDate) {
                formSaver.formValues.set.launchDate(value || '');
            } else {
                formSaver.formValues.set.launchDate('');
            }
        }
    };

    return (
        <FormSaver
            name='trip-details-launch-date-form'
            onSubmit={(event): void => {
                if (canSubmitForm()) {
                    return formSaver.handleFormSubmit(event);
                }

                return noop();
            }}
        >
            <FormHeader
                title='Launch Date'
                description='Set your launch date and time to schedule when your trip launches to your audience. Saving your launch date and time will schedule your trip to go live and be bookable by travelers at the given time. We will also send a trip launch email to your audience to help you gather bookings.'
            />
            <Grid marginTop={6}>
                <Grid.Item columnSpan={{ base: 12, sm: 4 }}>
                    <DatePicker
                        name={FieldsNames.launchDate}
                        className={launchDatePickerSelector}
                        disabled={!areLaunchDateFieldsEnabled}
                        // TODO: Ignored label type until the change in Trova-components is ready.
                        // @ts-expect-error
                        label={
                            <>
                                Launch Date{' '}
                                <span
                                    style={{
                                        color: 'var(--tc-colors-red-trova)',
                                    }}
                                >
                                    *
                                </span>
                            </>
                        }
                        size={ComponentWidth.Flexible}
                        error={formSaver.formErrors?.launchDate}
                        value={formSaver.formValues.get.launchDate as string}
                        onChange={onDatePickerChange}
                        dateTimeInputProps={{
                            selectableDates: {
                                dates: disabledDates,
                                isSelectable: false,
                            },
                        }}
                    />
                </Grid.Item>
                <Grid.Item columnSpan={{ base: 12, sm: 4 }}>
                    <Dropdown
                        name={FieldsNames.launchTime}
                        label={`Launch Time (${shortTimeZone})`}
                        required
                        size={ComponentWidth.Flexible}
                        disabled={!areLaunchDateFieldsEnabled}
                        error={formSaver.formErrors?.launchTime}
                        onChange={(_, __, value): void => {
                            formSaver.formValues.set.launchTime(value || '');
                        }}
                        value={formSaver.formValues.get.launchTime as string}
                        searchable={false}
                        onSearch={noop}
                    >
                        {defaultTimeOptions}
                    </Dropdown>
                </Grid.Item>
            </Grid>
            <Grid.Item marginTop={8} columnSpan='*'>
                <Button
                    variant={canUnscheduleLaunchDate ? 'tertiary' : 'primary'}
                    isDisabled={!canSubmitForm()}
                    onClick={(): void => {
                        setIsScheduleModalOpen(true);
                    }}
                >
                    {canUnscheduleLaunchDate
                        ? 'Unschedule Launch'
                        : 'Schedule Launch'}
                </Button>
            </Grid.Item>
            <Dialog
                isOpen={isScheduleModalOpen}
                onClose={(): void => setIsScheduleModalOpen(false)}
            >
                <Dialog.Header
                    title={
                        canUnscheduleLaunchDate
                            ? 'Unschedule Launch'
                            : 'Schedule Launch'
                    }
                />
                <Dialog.Body>
                    {canUnscheduleLaunchDate
                        ? 'Are you sure you want to clear the launch date and time for this trip?'
                        : 'Are you sure you want to schedule the launch date and time for this trip? An email will send to your audience on the selected date & time.'}
                </Dialog.Body>
                <Dialog.Footer
                    actions={[
                        {
                            children: 'Cancel',
                            variant: 'secondary',
                            onClick: (): void => setIsScheduleModalOpen(false),
                        },
                        {
                            type: 'submit',
                            children: canUnscheduleLaunchDate
                                ? 'Unschedule'
                                : 'Schedule',
                            onClick: (): void => {
                                handleFormSubmit(formSaver.formState);
                                setIsScheduleModalOpen(false);
                            },
                        },
                    ]}
                />
            </Dialog>
        </FormSaver>
    );
};

export default LaunchDateForm;
