import React from 'react';

const useServicesByDay = (servicesByDay) => {
    const [services, setServices] = React.useState(null);
    React.useEffect(() => {
        if (servicesByDay && !services) {
            const servicesToSet =
                servicesByDay &&
                servicesByDay.map((dayOfServices) =>
                    dayOfServices.filter(
                        (service) =>
                            service &&
                            service.service.type === `activity` &&
                            !(
                                service.service &&
                                service.service.includedActivity === true
                            ),
                    ),
                );
            setServices(servicesToSet);
        }
    }, [servicesByDay, services]);

    return services;
};

export default useServicesByDay;

export const useServicesByDayWithSelectedFilter = (
    servicesByDay,
    hostSelectedOptionalServices,
) => {
    const [services, setServices] = React.useState(null);
    const selectedServicesIdsMap = new Map(
        Array.isArray(hostSelectedOptionalServices)
            ? hostSelectedOptionalServices
            : [],
    );

    React.useEffect(() => {
        if (servicesByDay && selectedServicesIdsMap && !services) {
            const servicesToSet = servicesByDay.map((day) =>
                day
                    .filter(
                        ({ service }) =>
                            !!selectedServicesIdsMap.has(service._id),
                    )
                    .map((day) => ({
                        ...selectedServicesIdsMap.get(day.service._id),
                        ...day,
                    })),
            );
            setServices(servicesToSet);
        }
    }, [servicesByDay, services, selectedServicesIdsMap]);

    return services;
};
