import styled from '@emotion/styled';
import { theme } from '@trova-trip/trova-components';

export const StyledMicroAppHostWrapper = styled.div`
    .trova-micro-app-catalog-wrapper {
        * {
            font-family: ${theme.fonts.roboto}, sans-serif;
        }

        button[type='button'] {
            -webkit-appearance: button;
            font-weight: ${theme.fontWeights.medium};
            outline: none;
            border-style: none;
        }
    }
`;
