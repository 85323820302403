import { useState } from 'react';
import { Grid } from '@material-ui/core';
import { validationSchemas } from '@trova-trip/trova-models';
import {
    FormDataObjectType,
    FormSaver,
    useFormSaver,
} from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import { logError } from '../../../common/helpers';
import Snackbar from '../../../../components/Snackbar/Snackbar';
import { useSelector } from '../../../../state/hooks/useSelector';
import {
    updateTripTraveler,
    userTripsTraveler,
} from '../../../../state/tripsTraveler';
import { getInitialValues, updateTravelerModelData } from './TripForm.utils';
import TripFormFields from './TripFormFields';
import { TripStatuses } from '../../../../config/constants';

const { TravelerTripFormSchema } = validationSchemas;

const TripForm = (): JSX.Element => {
    const {
        userTrips: { current: currentTrip },
        userTripsTraveler: { list: travelersList },
        profile: { current: currentUser },
    } = useSelector((state) => state);

    const isDisabled = currentTrip?.status === TripStatuses.COMPLETE;

    const tripTravelerUser = travelersList?.records?.filter((traveler) => {
        return traveler.user === currentUser?.id;
    })?.[0];

    const { listRecords: listTripTraveler } =
        userTripsTraveler.useDispatch.bind(tripTravelerUser)();

    const formattedValues = getInitialValues(
        tripTravelerUser,
        currentTrip?.passportRequired,
        currentUser,
    );

    const [snackBar, setSnackBar] = useState({
        message: '',
        color: 'info',
        show: false,
    });

    const handleSubmit = async (data): Promise<void> => {
        const formattedModelData = updateTravelerModelData(data);
        try {
            const response = await updateTripTraveler(
                currentTrip?.id,
                tripTravelerUser?.id,
                formattedModelData,
            );
            if (!response.success) {
                throw new Error(response.error);
            }
            setSnackBar({
                message: 'Trip form saved!',
                color: 'success',
                show: true,
            });
            listTripTraveler(undefined, {
                tokens: { tripId: currentTrip?.id },
            });
        } catch (error) {
            setSnackBar({
                message:
                    'There was an error saving the trip form. Please try again.',
                color: 'danger',
                show: true,
            });
            logError(error);
        }
    };

    const formSaver = useFormSaver({
        onSubmit: async (formValues) => {
            await handleSubmit(formValues);
        },
        validationSchema: {
            schema: TravelerTripFormSchema,
        },
        initialValues: formattedValues as unknown as FormDataObjectType,
    });

    return (
        <Grid container spacing={24}>
            <Grid item xs={12}>
                <FormSaver
                    name='traveler-trip-form'
                    onSubmit={formSaver.handleFormSubmit}
                >
                    <Grid container spacing={24}>
                        <TripFormFields
                            isPassportRequired={
                                currentTrip?.passportRequired || false
                            }
                            isDisabled={isDisabled}
                            formSaver={formSaver}
                        />
                    </Grid>
                    <Button
                        variant='primary'
                        type='submit'
                        isDisabled={!formSaver.isFormDirty || isDisabled}
                        marginTop={8}
                    >
                        Confirm
                    </Button>
                </FormSaver>
            </Grid>
            <Snackbar
                place='tr'
                color={snackBar.color}
                message={snackBar.message}
                open={snackBar.show}
                autoHideDuration={4000}
                onClose={(): void => {
                    setSnackBar({ message: '', color: 'info', show: false });
                }}
            />
        </Grid>
    );
};

export default TripForm;
