import { useState, useRef, useEffect } from 'react';

type UseOptionSwitcherProps<Options extends Record<string, any>> = {
    options: Options;
    initialOption?: keyof Options;
};

type UseOptionSwitcherReturn<Options extends Record<string, any>> = {
    current: { value: Options[keyof Options]; key: keyof Options };
    history: Array<keyof Options>;
    setCurrent: (key: keyof Options) => void;
    backToPrevious: () => void;
    clearOptionHistory: () => void;
};

const useOptionSwitcher = <Options extends Record<string, any>>(
    props: UseOptionSwitcherProps<Options>,
): UseOptionSwitcherReturn<Options> => {
    const { initialOption, options } = props;

    const optionNames = Object.keys(options) as Array<keyof Options>;

    const defaultFirstOption = optionNames[0];

    const [option, setOption] = useState<keyof Options>(
        initialOption || defaultFirstOption,
    );
    const optionsHistory = useRef<Array<keyof Options>>([]);

    const currentOption = { value: options[option], key: option };

    const setCurrent = (key: keyof Options): void => {
        if (option === key) return;

        const updatedHistory = optionsHistory.current.concat(option);
        optionsHistory.current = updatedHistory;

        setOption(key);
    };

    const backToPrevious = (): void => {
        setOption(optionsHistory.current.pop() || defaultFirstOption);
    };

    const clearOptionHistory = (): void => {
        optionsHistory.current = [];
    };

    useEffect(() => {
        if (!currentOption) {
            setOption(defaultFirstOption);
            clearOptionHistory();
        }
    }, [currentOption]);

    useEffect(() => {
        return () => clearOptionHistory();
    }, []);

    return {
        current: currentOption,
        history: optionsHistory.current,
        setCurrent,
        backToPrevious,
        clearOptionHistory,
    };
};

export default useOptionSwitcher;
