import { coreUtils } from '@trova-trip/trova-common';
import { Text } from '@trova-trip/trova-components';
import { useLocation } from 'react-router-dom';
import PageCard from '../../../../components/PageCard';
import { isTraveler, shouldShowTransferSidebar } from '../../../../helpers';
import useTripRootPathByUserGroup from '../../../../hooks/trips/useTripRootPathByUserGroup';
import { useTransfer } from '../../hooks';
import SidebarContent from '../SidebarContent';
import Breadcrumb from '../Breadcrumb';

const { formatUSD } = coreUtils.currencyUtils;

const TabLayout = ({ children }) => {
    const { pathname } = useLocation();

    const {
        globalState: { user, trip },
        state: {
            arrival,
            departure,
            payment: { total },
        },
    } = useTransfer();

    const tripRootPath = useTripRootPathByUserGroup({
        group: user?.group,
        tripId: trip.id,
    });

    const showSidebar = shouldShowTransferSidebar(pathname, arrival, departure);
    const iTravelerUser = isTraveler(user);

    const accordionHeaderTitle = iTravelerUser ? 'Total Price' : 'Summary';

    return (
        <PageCard
            sidebarContent={showSidebar ? <SidebarContent /> : null}
            navigateBackTo={{
                description: 'Back to trip',
                path: tripRootPath,
            }}
            topRightAddOn={<Breadcrumb />}
            mobileProps={{
                accordion: {
                    position: 'top',
                    header: {
                        title: accordionHeaderTitle,
                        rightElement: iTravelerUser ? (
                            <Text fontWeight='bold' fontSize='lg'>
                                {formatUSD(total, 2)}
                            </Text>
                        ) : null,
                    },
                },
            }}
            marginTop={{ base: showSidebar ? 20 : 4, lg: 0 }}
            padding={{ base: 0, lg: 6 }}
        >
            {children}
        </PageCard>
    );
};

export default TabLayout;
