import { IconProps } from '@trova-trip/trova-components/build/next';
import { getTripCatalogUrl } from 'applications/utils';
import { appConstants } from '@trova-trip/trova-common';

const { TripCategoriesKeys } = appConstants;

interface TripCategory {
    url: string;
    icon: IconProps['as'];
    name: string;
    description: string;
}

export const tripCategories: TripCategory[] = [
    {
        icon: 'trovaCamera',
        name: 'Photography',
        description:
            'Learn photography and editing tricks from top creators around the world.',
        url: getTripCatalogUrl({
            categories: [TripCategoriesKeys.PHOTOGRAPHY],
        }),
    },
    {
        icon: 'trovaRainbow',
        name: 'LGBTQ+',
        description:
            'Proudly designed for LGBTQ+ safer travel, fun and inclusivity.',
        url: getTripCatalogUrl({ categories: [TripCategoriesKeys.LGBTQ] }),
    },
    {
        icon: 'trovaHeart',
        name: 'Body Positivity',
        description:
            'Dive into self-love and empowerment with a group of likeminded individuals.',
        url: getTripCatalogUrl({
            categories: [TripCategoriesKeys.POSITIVITY],
        }),
    },
    {
        icon: 'trovaDumbbell',
        name: 'Fitness',
        description:
            'Learn from fitness gurus and get your heartrate puming while on vacay.',
        url: getTripCatalogUrl({
            categories: [TripCategoriesKeys.FITNESS],
        }),
    },
    {
        icon: 'trovaSearch',
        name: 'Exploration',
        description:
            'Tours, treks, history or culture. How you explore the world is up to you!',
        url: getTripCatalogUrl({
            categories: [TripCategoriesKeys.EXPLORATION],
        }),
    },
    {
        icon: 'trovaPepper',
        name: 'Food',
        description: 'Eat your way around the globe on a foodie adventure.',
        url: getTripCatalogUrl({ categories: [TripCategoriesKeys.FOOD] }),
    },
    {
        icon: 'trovaHeartPulse',
        name: 'Health & Wellness',
        description:
            'Uplift yourself mentally, physically and spriritually on these getaways.',
        url: getTripCatalogUrl({ categories: [TripCategoriesKeys.HEALTH] }),
    },
    {
        icon: 'trovaYoga',
        name: 'Yoga',
        description:
            'Strengthen your practice by flowing with yogis in zen destinations.',
        url: getTripCatalogUrl({ categories: [TripCategoriesKeys.YOGA] }),
    },
];
