import { useCallback } from 'react';
import { SectionCard, Text, useToast } from '@trova-trip/trova-components';
import { Button, Icon } from '@trova-trip/trova-components/build/next';
import usePublicProfileAnalytics, {
    PUBLIC_PROFILE_DIALOG_LOCATION,
    PUBLIC_PROFILE_TRACKING_EVENTS,
} from '../../../../../hooks/usePublicProfileAnalytics';
import useClipboard from '../../../../../../common/hooks/useClipboard';
import { analytics } from '@trova-trip/trova-common';

interface AudienceCollectionCardProps {
    title: string;
    description: string;
    responses: number;
    isLocked?: boolean;
    copyConfig: {
        link: string;
        label: string;
    };
    location?: PUBLIC_PROFILE_DIALOG_LOCATION;
}

const AudienceCollectionCard = (
    props: AudienceCollectionCardProps,
): JSX.Element => {
    const {
        title,
        description,
        responses,
        isLocked = false,
        copyConfig: { link, label },
        location,
    } = props;
    const toast = useToast();
    const { copyTextToClipboard } = useClipboard();
    const { trackPublicProfileEvent } = usePublicProfileAnalytics();

    const onCopyClick = useCallback(() => {
        copyTextToClipboard(link);
        toast({
            title: 'Link copied',
            description: `The ${label.toLowerCase()} was successfully copied`,
            status: 'success',
            isClosable: true,
        });
        trackPublicProfileEvent(
            PUBLIC_PROFILE_TRACKING_EVENTS.copyProfileLink,
            {
                location,
                firstName: analytics.currentUser?.firstName,
                lastName: analytics.currentUser?.lastName,
            },
        );
    }, [link, label]);

    return (
        <SectionCard
            height='full'
            size='sm'
            headerAddon={
                !isLocked ? (
                    <Button
                        onClick={onCopyClick}
                        variant='tertiary'
                        rightIcon='copy'
                        color='red.coral'
                    >
                        Copy {label}
                    </Button>
                ) : (
                    <Icon as='lock' color='blueGray.500' />
                )
            }
            subtitle={
                <Text fontWeight='bold'>
                    {responses} <Text as='span'>emails</Text>
                </Text>
            }
            subtitlePosition='top'
            title={title}
        >
            {description}
        </SectionCard>
    );
};

export default AudienceCollectionCard;
