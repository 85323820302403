import MobileRootNavigationView from 'navigation/MobileRootNavigationView';
import { ReactNode } from 'react';
import useIsLargeScreen from '../hooks/useIsLargeScreen';

interface ProductRootWithMobileViewProps {
    rootView: ReactNode;
}

const ProductRootWithMobileNavigationView = ({
    rootView,
}: ProductRootWithMobileViewProps): JSX.Element => {
    const isLargeScreen = useIsLargeScreen();
    return isLargeScreen ? <>{rootView}</> : <MobileRootNavigationView />;
};

export default ProductRootWithMobileNavigationView;
