import { Modal } from '@trova-trip/trova-components';
import { Card } from '@trova-trip/trova-components/build/next';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'state/hooks';
import {
    CongratsContent,
    LaunchDateContent,
    ProfileContent,
    TripPageContent,
} from './contents';
import { OnboardingStepperContext } from './OnboardingStepperContext';

interface OnboardingStepperProps {
    isOpen: boolean;
}

const OnboardingStepper = ({ isOpen }: OnboardingStepperProps): JSX.Element => {
    const [currentStep, setCurrentStep] = useState(0);
    const host = useSelector((state) => state.profile.current);

    // Memoized with an empty array of dependencies on purpose to
    // show the profile step if the user goes back if the profile
    // step was shown in the first place.
    const showProfileStep = useMemo(
        () => !host?.bio || !host?.profilePictureUrl,
        [],
    );

    const CurrentContent = useMemo(() => {
        const content = [CongratsContent, TripPageContent, LaunchDateContent];
        if (showProfileStep) {
            content.splice(2, 0, ProfileContent);
        }
        return content[currentStep];
    }, [currentStep]);

    const goNextStep = useCallback((): void => {
        setCurrentStep((prevStep) => prevStep + 1);
    }, []);

    const goPrevStep = useCallback(
        (): void =>
            setCurrentStep((prevStep) => {
                const step = prevStep - 1;
                return step < 0 ? 0 : step;
            }),
        [],
    );

    return (
        <Modal isOpen={isOpen} isCentered={{ base: false, xl: true }}>
            <Card
                width={{ base: '90%', xl: '80rem' }}
                height={{ xl: '52.5rem' }}
                margin={{ base: '0 auto', xl: '0' }}
                paddingX={{ xl: 20 }}
                paddingY={{ xl: 16 }}
            >
                <OnboardingStepperContext.Provider
                    value={{ currentStep, goNextStep, goPrevStep }}
                >
                    <CurrentContent />
                </OnboardingStepperContext.Provider>
            </Card>
        </Modal>
    );
};

export default OnboardingStepper;
