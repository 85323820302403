import { coreUtils } from '@trova-trip/trova-common';
import { constants, models } from '@trova-trip/trova-models';
import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import subDays from 'date-fns/subDays';
import { getNavigationCategoryStatus } from '../../../applications/traveler/utils';
import { TravelerTripNavigationCategories } from '../../../config/constants';
import { formatDateToString } from './date';
import { mapGroundTransferIds } from './transfer';

type SavedBooking = models.bookings.SavedBooking;
type TravelerStatus = constants.travelers.TravelerStatus;
type Trip = models.trips.Trip;

const { createTripEndDate, getStartEndDates } = coreUtils.dateUtils;

const { TRIP_STATUS } = constants.trips;

const isTripConfirmed = (trip: Trip) => trip.status === TRIP_STATUS.CONFIRMED;
const isTripComplete = (trip: Trip) => trip.status === TRIP_STATUS.COMPLETE;
const isTripCancelled = (trip: Trip) => trip.status === TRIP_STATUS.CANCELLED;
const isTripCreated = (trip: Trip) => trip.status === TRIP_STATUS.CREATED;
const isTripClosed = (trip?: Trip) => trip?.status === TRIP_STATUS.CLOSED;

interface TripRelatedDates {
    preTripDate?: Date;
    postTripDate?: Date;
    endTripDate?: Date;
}

interface TripWithBooking extends Trip {
    booking?: SavedBooking;
}

/**
 * Calculates the pre and post trip dates based on the start date and trip duration.
 *
 * @param startDate
 * @param tripDuration - In days.
 *
 * @returns An object containing the pre and post trip dates or an empty object if all parameters are not provided.
 */
const getTripRelatedDates = (
    startDate: Date | undefined,
    tripDuration: number | undefined,
): TripRelatedDates => {
    if (!startDate || !tripDuration) {
        return {};
    }

    const preTripDate = subDays(startDate, 1);
    const endTripDate = createTripEndDate(startDate, tripDuration);
    const postTripDate = addDays(endTripDate, 1);

    return {
        endTripDate,
        preTripDate,
        postTripDate,
    };
};

type TripRelatedStringDates = {
    /**
     * `{startDate} - {endDate}`
     * @example: `Feb 1 - Apr 25`
     */
    tripDates: string;

    /**
     * @example: `Feb 1`
     */
    preTripDate: string;

    /**
     * @example: `Apr 25`
     */
    postTripDate: string;
};

/**
 * Calculates the during, pre and post trip dates based on the start date and trip duration.
 *
 * - If the trip `startDate` is `undefined` all the dates will be empty.
 *
 * - If the `tripDuration` is `undefined` the `tripDates` will not include
 *  the end date and the `postTripDate` will be empty.
 *
 * @param trip
 *
 * @returns TripStringDates - An object containing the during, pre and post trip dates
 */
const getTripRelatedStringDates = (trip: Trip): TripRelatedStringDates => {
    const { startDate, servicesByDay } = trip;
    const tripDuration = servicesByDay?.length;

    if (!startDate) {
        return { preTripDate: '', postTripDate: '', tripDates: '' };
    }

    const [formattedStartDate, formattedEndDate] = getStartEndDates(
        startDate.toString(),
        // Giving a value so there is no error but this is checked later
        tripDuration || 0,
        'MMM D',
    );

    const preTripDate = formatDateToString(
        subDays(new Date(formattedStartDate), 1),
        'MMM d',
    );

    if (!tripDuration) {
        return {
            tripDates: formattedStartDate,
            preTripDate,
            postTripDate: '',
        };
    }

    const postTripDate = formattedEndDate;
    const tripDates = `${formattedStartDate} - ${formattedEndDate}`;

    return { tripDates, preTripDate, postTripDate };
};

/**
 * Sorts an array of trips with booking data by priority.
 *
 * @param trips - With booking information.
 * @param travelerStatus
 * @return The array of trips sorted first by category priority and then by date inside each category.
 */
const sortTripsByPriority = (
    trips: TripWithBooking[],
    travelerStatus: TravelerStatus,
): TripWithBooking[] => {
    // We set the current date to compare with trip dates below
    const today = new Date();

    const sortedTripsByPriority = trips
        .map((trip) => {
            const tripStatus = getNavigationCategoryStatus(
                trip,
                trip.booking,
                travelerStatus,
            );

            const priority = tripStatus
                ? TravelerTripNavigationCategories[tripStatus]?.priority
                : undefined;

            // Doing a simple comparison with the date we generated above. The sorting will not be
            // precise in some cases but it should be close enough and it shouldn't matter for this purpose.
            const daysUntilTrip = trip.startDate
                ? differenceInDays(trip.startDate, today)
                : Number.MAX_SAFE_INTEGER;

            return { ...trip, priority, daysUntilTrip };
        })
        .sort((tripA, tripB) => {
            if (!tripA.priority && !tripB.priority) return 0;
            if (!tripA.priority) return 1;
            if (!tripB.priority) return -1;

            if (tripA.priority === tripB.priority) {
                return tripA.daysUntilTrip - tripB.daysUntilTrip;
            }

            return tripA.priority - tripB.priority;
        })
        .map(({ priority, daysUntilTrip, ...trip }) => trip);

    return sortedTripsByPriority;
};

/**
 * Get the host booked ground transfer ids from a trip.
 *
 * @param trip
 * @returns Returns an array of ground transfer ids or an empty array if no ground transfers are found.
 */
const getGroundTransferIdsFromTrip = (trip: Trip | undefined): string[] => {
    if (!trip || !trip.hostGroundTransfers) {
        return [];
    }

    const bookedGroundTransfers = trip.hostGroundTransfers.filter(
        (groundTransfer) => !groundTransfer.deleted,
    );

    return mapGroundTransferIds(bookedGroundTransfers);
};

export {
    getGroundTransferIdsFromTrip,
    getTripRelatedDates,
    getTripRelatedStringDates,
    isTripCancelled,
    isTripClosed,
    isTripComplete,
    isTripConfirmed,
    isTripCreated,
    sortTripsByPriority,
};
export type { TripWithBooking };
