import { Button, ButtonProps } from '@trova-trip/trova-components/build/next';
import { analyticsTypes, withAnalytics } from '../../analytics';

interface ButtonWithAnalyticsProps
    extends analyticsTypes.WithAnalyticsProps,
        ButtonProps {}

const ButtonWithAnalytics = withAnalytics<ButtonWithAnalyticsProps>(Button);

export default ButtonWithAnalytics;
