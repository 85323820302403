import { coreUtils } from '@trova-trip/trova-common';
import { constants, models } from '@trova-trip/trova-models';
import { useTrackingEvent } from '../../../analytics/hooks';
import { useSelector } from '../../../state/hooks';
import { getEVisaLink } from '../helpers';

type Trip = models.trips.Trip;

interface UseNavigateToEVisaPageParams {
    trip: Trip;
    userCountryCode?: string;
}

const { dateUtils } = coreUtils;
const { Group } = constants.user;

const useNavigateToEVisaPage = (params: UseNavigateToEVisaPageParams) => {
    const { trip, userCountryCode = '' } = params;

    const { trackUserEvent } = useTrackingEvent();
    const user = useSelector((state) => state.profile.current);

    const eVisaLink = getEVisaLink(trip, userCountryCode);

    const typeOfUser = user?.group === Group.HOST ? 'Host' : 'Traveler';

    const navigate = (): void => {
        trackUserEvent({
            eventName: `${typeOfUser} clicked on Apply for a Visa button`,
            properties: {
                tripId: trip.id,
                tripStatus: trip.status,
                date: dateUtils.formatDateTime(new Date(), 'MM-dd-yyyy'),
            },
        });
        window.location.href = eVisaLink;
    };

    return navigate;
};

export default useNavigateToEVisaPage;
