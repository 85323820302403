import Tab from '../../../../navigation/Tab';
import { useCallback, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router';
import TabDetailsInformation from '../../../../components/TabDetailsInformation/TabDetailsInformation';
import ReviewDetailsForm, { ReviewDetailsFormProps } from './ReviewDetailsForm';
import HostSummary from './HostSummary';
import getQueryParam from '../../../../util/getQueryParam';
import NavigationContext from '../../../../navigation/NavigationContext';
import TravelerManifestDocumentDownloadButton from '../../components/Manifest/TravelerManifestDocumentDownloadButton';
import ContentForModelStatus from '../../../common/components/ContentForModelStatus';
import { StatefulModel } from '../../../types';
import { Stack } from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';
import { TripStatuses } from '../../../../config/constants';
import type { History } from 'history';

type Itinerary = models.itineraries.Itinerary;
type TripRequest = models.tripRequest.TripRequest;

const useRedirectToNextTab = (history: History) => {
    const buildNewPath = (_pathname, model, data) => {
        if (['tripRequest', 'trip'].includes(model)) {
            return `/app/operator/trips/${data.id}/configure-services?model=trip`;
        }

        return `/app/operator/itineraries/${data.id}/configure-services`;
    };
    return useCallback(
        (data: { id: string }, replace: boolean = false) => {
            const {
                location: { pathname },
            } = history;
            const model = getQueryParam(history, 'model');
            const newPath = buildNewPath(pathname, model, data);

            if (replace) {
                history.replace(newPath);
            } else {
                history.push(newPath);
            }
        },
        [history],
    );
};

interface ReviewDetailsProps {
    data: { id?: string } | Itinerary | TripRequest;
    disabled: boolean;
    showHostSummary?: boolean;
    saveModelData: ReviewDetailsFormProps['saveModelData'];
    updateModelData: ReviewDetailsFormProps['updateModelData'];
}

const ReviewDetails = ({
    data,
    disabled,
    showHostSummary = false,
    saveModelData,
    updateModelData,
}: ReviewDetailsProps) => {
    const history = useHistory();
    const redirectToNextTab = useRedirectToNextTab(history);
    const { reloadNavigation } = useContext(NavigationContext);
    const model = getQueryParam(history, 'model');

    if (!data) {
        return (
            <Tab
                label='1. Review details'
                path='/review-details'
                disabled={true}
            >
                <div />
            </Tab>
        );
    }

    const isNew = history.location.pathname.includes('new');
    const showReviewDetailsForm = isNew || !!Object.keys(data).length;

    const showModelStatusContent = model === 'trip';
    const manifestDownloadButton =
        showModelStatusContent && data.id ? (
            <TravelerManifestDocumentDownloadButton
                tripId={data.id as string}
            />
        ) : null;

    return (
        <Tab
            label='1. Review details'
            path='/review-details'
            disabled={disabled}
        >
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Stack
                        direction={{ base: 'column', md: 'row' }}
                        justify={{ md: 'space-between' }}
                        align='flex-start'
                        spacing={0}
                    >
                        <TabDetailsInformation
                            title='Review details'
                            description='To get started, add the name, country, and general information about the trip.'
                        />
                        {showModelStatusContent ? (
                            <ContentForModelStatus
                                model={StatefulModel.TRIP}
                                matchingStatuses={[
                                    TripStatuses.CONFIRMED,
                                    TripStatuses.CLOSED,
                                ]}
                                render={() => manifestDownloadButton}
                            />
                        ) : null}
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    {showHostSummary && <HostSummary data={data} />}
                </Grid>
                <Grid item xs={12}>
                    {showReviewDetailsForm && (
                        <ReviewDetailsForm
                            data={data}
                            saveModelData={saveModelData}
                            updateModelData={updateModelData}
                            redirectToNextTab={redirectToNextTab}
                            reloadNavigation={reloadNavigation}
                        />
                    )}
                </Grid>
            </Grid>
        </Tab>
    );
};

export default ReviewDetails;
