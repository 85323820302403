import { Image, Stack } from '@trova-trip/trova-components';
import { LinkCard } from '@trova-trip/trova-components/build/next';
import { models } from '@trova-trip/trova-models';
import { useHistory } from 'react-router-dom';
import TripInfo from './TripInfo';
import { useTrackingEvent } from '../../../../../../../../analytics/hooks';
import { getTripLink } from '../../../../../../utils';
import { modelTypes } from '../../../../../../../../config/constants';

interface UpcomingTripCardProps {
    trip: models.hostHomeTab.UpcomingTrip & { trackingIndex: number };
}

const UpcomingTripCard = (props: UpcomingTripCardProps) => {
    const { trip } = props;
    const { status, name, trackingIndex, photos, isRequest, _id: id } = trip;
    const history = useHistory();
    const { trackUserEvent } = useTrackingEvent();

    const model = isRequest ? modelTypes.TRIP_REQUEST : modelTypes.TRIP;
    const tripRoute = getTripLink({ model, id, status });

    const hasPhoto = !!trip.photos?.hero;

    const goToTrip = (): void => {
        trackUserEvent({
            eventName: `Host Selected Upcoming Trip ${trackingIndex}`,
            properties: {
                tripName: name,
                tripStatus: status,
            },
        });
        history.push(tripRoute);
    };

    return (
        <LinkCard
            position='relative'
            width='full'
            height='full'
            spacing='none'
            overflow='hidden'
            onClick={goToTrip}
        >
            <Stack spacing={2} height='full' wrap='nowrap'>
                {hasPhoto ? (
                    <Image
                        fit='cover'
                        width={{ base: '25%', lg: '28%' }}
                        height='full'
                        src={photos?.hero}
                        flexShrink={0}
                    />
                ) : null}
                <TripInfo trip={trip} />
            </Stack>
        </LinkCard>
    );
};

export default UpcomingTripCard;
