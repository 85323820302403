/*issue with CRA4 and React 17: https://github.com/emotion-js/emotion/issues/2041*/
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useMemo, useState } from 'react';
import { BaseBox, TabList } from '@trova-trip/trova-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useWindowHostNameAndProtocol } from '../../util/hooks/useWindowHostname';

const TabBar = ({ tabs, productPath, modelId, queryString }) => {
    const host = useWindowHostNameAndProtocol();
    const history = useHistory();

    const [selectedIndex, setSelectedIndex] = useState(0);

    const tabItems = useMemo(
        () =>
            tabs.map(({ label, path, disabled = false }, index) => {
                const to = modelId
                    ? `${productPath}/${modelId}${path}${queryString}`
                    : `${productPath}${path}${queryString}`;

                const toUrl = new URL(to, host);
                const currentPathname = history.location.pathname;

                if (
                    toUrl.pathname.toLowerCase() ===
                    currentPathname.toLowerCase()
                ) {
                    setSelectedIndex(index);
                }

                return {
                    label,
                    isDisabled: disabled,
                    onClick: () => history.push(to),
                };
            }),
        [tabs, modelId, host, history.location.pathname, setSelectedIndex],
    );

    return (
        <BaseBox
            position='relative'
            marginX={{ base: -4, md: 0 }}
            marginBottom={{ base: 6, md: 0 }}
            paddingY={{ base: 1, md: 8 }}
        >
            <BaseBox
                display={{ base: 'block', md: 'none' }}
                position='absolute'
                top='0'
                backgroundColor='neutral.white'
                width='100%'
                height='calc(100% - .625rem)'
            />
            <TabList
                tabs={tabItems}
                index={selectedIndex}
                size={{ base: 'sm', md: 'md' }}
                colorScheme='red'
            />
        </BaseBox>
    );
};

TabBar.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string,
            label: PropTypes.string,
            disabled: PropTypes.bool,
        }),
    ),
    productPath: PropTypes.string.isRequired,
    modelId: PropTypes.string,
    queryString: PropTypes.string,
    history: PropTypes.shape({
        push: PropTypes.func,
        location: PropTypes.shape({ pathname: PropTypes.string }),
    }),
};

export default TabBar;
