import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { profile } from '../state/profile';
import { connect } from 'react-redux';
import Loading from '../components/Loading';
import ProfileReader from '../util/profile/ProfileReader';
import { applicationRootPath } from '../navigation/NavigationContext';
import { getDeepLinkDestination, getActionUrl } from 'util/deepLinker';

const Controller = ({ getProfile, profile, clearProfile }) => {
    useEffect(getProfile, []);

    if (!profile || profile.loading) {
        return <Loading />;
    }

    if (profile.error) {
        clearProfile();
        return <Redirect push to={`/login`} />;
    }

    const profileReader = ProfileReader.create(profile);

    if (profileReader.needChangePassword()) {
        return <Redirect push to={`/change-password`} />;
    }

    const destination = getDeepLinkDestination();
    if (destination) {
        return <Redirect push to={destination} />;
    }

    const action = getActionUrl();
    if (action) {
        switch (action.type) {
            case 'download':
                window.location.href = action.url;
                break;
            default:
                break;
        }   
    }

    if (profileReader.isAdmin()) {
        return <Redirect push to={`/admin/users`} />;
    }

    // Eventually ALL will redirect to /app app will dynamically load based on user
    if (
        profileReader.isImpersonating() ||
        profileReader.isOperator() ||
        profileReader.isTraveler() ||
        profileReader.isHost()
    ) {
        return <Redirect push to={applicationRootPath} />;
    }

    return <Redirect to={`/login`} />;
};

Controller.propTypes = {
    getProfile: PropTypes.func,
    clearProfile: PropTypes.func,
    profile: PropTypes.shape(),
};

const mapStateToProps = ({ profile }) => ({
    profile: profile.current,
});
const mapDispatchToProps = (dispatch) => ({
    getProfile() {
        dispatch(profile.getRecord());
    },
    clearProfile() {
        dispatch(profile.clearCurrentRecord());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Controller);
