import { StickyContentLayout } from '@trova-trip/trova-components';
import TravelerInfoTabStickyLayoutContent from './TravelerInfoTabStickyLayoutContent';

const TravelerInfoTabStickyLayout: React.FC = ({ children }): JSX.Element => (
    <StickyContentLayout
        stickyContent={<TravelerInfoTabStickyLayoutContent />}
        mainContent={
            <StickyContentLayout observableContent>
                {children}
            </StickyContentLayout>
        }
        offsetTop={16}
    />
);

export default TravelerInfoTabStickyLayout;
