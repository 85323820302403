import { coreUtils } from '@trova-trip/trova-common';
import { models } from '@trova-trip/trova-models';
import { TierInfo } from 'applications/types';
import { useSelector } from 'state/hooks';

const { getItineraryTierInfoForUser } = coreUtils.itineraryUtils;

type User = models.users.User;

export const useCurrentItineraryTierInfoForUser = (): TierInfo | undefined => {
    const { current: itinerary } = useSelector((state) => state.userItinerary);
    const { current: host } = useSelector((state) => state.profile);

    if (!itinerary || !host) return undefined;

    try {
        return getItineraryTierInfoForUser(itinerary, host as User);
    } catch (e) {
        return undefined;
    }
};
