import { Stack, TabList } from '@trova-trip/trova-components';
import { Card } from '@trova-trip/trova-components/build/next';
import React, { useState } from 'react';

interface ResponsesTab {
    label: string;
    element: React.ReactElement;
}

interface ResponsesTabsProps {
    tabs: ResponsesTab[];
}

const ResponsesTabs = (props: ResponsesTabsProps) => {
    const { tabs } = props;
    const [selectedTab, setSelectedTab] = useState<string>(tabs[0]?.label);

    const formattedTabs = tabs.map((tab) => ({
        label: tab.label,
        onClick: () => setSelectedTab(tab.label),
    }));

    const tabElement = tabs.find((tab) => tab.label === selectedTab)?.element;

    return (
        <Card padding={3}>
            <Stack direction='column' spacing={3}>
                <TabList
                    tabs={formattedTabs}
                    variant='soft-rounded'
                    colorScheme='blue'
                    size='xs'
                />
                {tabElement}
            </Stack>
        </Card>
    );
};

export default ResponsesTabs;
