import React from 'react';
import PropTypes from 'prop-types';

import ConfigureBaseServices from './ConfigureBaseServices';
import useUpdateModelData from './useUpdateModelData';

const ConfigureItineraryServices = ({
    data,
    renderError,
    renderSuccess,
    updateModelData,
    onNextButtonClick,
    cleanErrors,
    errorText,
    disabled,
}) => {
    const updateItineraryData = useUpdateModelData(
        updateModelData,
        renderError,
        renderSuccess,
    );

    return (
        <ConfigureBaseServices
            data={data}
            model={'itinerary'}
            onNextButtonClick={onNextButtonClick}
            cleanErrors={cleanErrors}
            errorText={errorText}
            disabled={disabled}
            updateModelData={updateItineraryData}
        />
    );
};

ConfigureItineraryServices.propTypes = {
    data: PropTypes.shape(),
    renderSuccess: PropTypes.func,
    renderError: PropTypes.func,
    updateModelData: PropTypes.func,
    onNextButtonClick: PropTypes.func,
    errorText: PropTypes.string,
    cleanErrors: PropTypes.func,
    disabled: PropTypes.bool,
};

export default ConfigureItineraryServices;
