import styled from '@emotion/styled';
import { css } from '@emotion/css';
import React from 'react';

export const ImageComponent = (props) => <img {...props} />;

export const messageContainer = () => css`
    padding: 2rem;
`;

export const StyledImage = styled(ImageComponent)({
    height: `auto`,
    width: `auto`,
    maxWidth: `320px`,
    margin: `0 0 1rem 2rem`,
});
