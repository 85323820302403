import { useSelector } from '../../../../../state/hooks';
import { getTransferPath } from '../../../../common/helpers';
import useTripIdUrlParam from '../../../hooks/trips/useTripIdUrlParam';
import { TransferTabName } from '../config';

/**
 * Returns a path of the Transfer flow only if:
 *
 * - A `tabName` is provided
 * - There is a tripId on the URL.
 * - There is an user and it has a valid user group.
 *
 * @param tabName
 * @returns string | undefined
 */
const useTransferPath = (tabName?: TransferTabName): string | undefined => {
    const user = useSelector((state) => state.profile.current);
    const tripId = useTripIdUrlParam();

    if (!tabName || !user?.group || !tripId) {
        return;
    }

    return getTransferPath(tripId, user.group, tabName);
};

export default useTransferPath;
