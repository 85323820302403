import { appConstants, coreUtils } from '@trova-trip/trova-common';
import { models } from '@trova-trip/trova-models';
import { windowHostName } from '../config/publish';

const { TripCategoriesKeys, TrovaTripAppsDomainMap } = appConstants;
const { formatUSD } = coreUtils.currencyUtils;
type ValueOf<T> = coreUtils.typeUtils.ValueOf<T>;

export const getHeaderDataFromItinerary = (
    itinerary: models.itineraries.Itinerary | undefined,
    defaultHeaderImage: string,
): {
    itineraryName: string;
    itineraryHeaderPhoto: string;
    itineraryCountry: string;
    itineraryDestination: string;
    itineraryLength?: number;
} => {
    const itineraryName = itinerary ? itinerary.name : '';
    const itineraryCountry = itinerary ? itinerary.country : ``;
    const itineraryDestination = itinerary ? itinerary.destination : ``;
    const itineraryLength = itinerary ? itinerary.servicesByDay?.length : 0;
    const itineraryHeaderPhoto = itinerary?.photos?.hero
        ? itinerary.photos.hero
        : defaultHeaderImage;

    return {
        itineraryName,
        itineraryHeaderPhoto,
        itineraryCountry,
        itineraryDestination,
        itineraryLength,
    };
};

export const pluralize = (
    count: number,
    singularNoun: string,
    pluralNoun: string,
): string => {
    return `${count} ${count === 1 ? singularNoun : pluralNoun}`;
};

/// TODO: this should be at the root level I think, like in an App.config
export const APP_ENVIRONMENT =
    process.env.ENVIRONMENT ||
    process.env.REACT_APP_ENVIRONMENT ||
    appConstants.Environment.Production;

export const API_URL = windowHostName(true);

export const MICRO_APP_LOCAL_URI =
    process.env.REACT_APP_MICRO_APP_LOCAL_URI || 'http://localhost:8080';

const trovaProductionPublicDomain =
    TrovaTripAppsDomainMap[APP_ENVIRONMENT].public;
export const TRIP_CATALOG_PAGE_URI = `${trovaProductionPublicDomain}/trips`;

export const getTripCatalogUrl = (options: {
    categories?: Array<ValueOf<typeof TripCategoriesKeys>>;
}): string => {
    const { categories } = options;
    let url = TRIP_CATALOG_PAGE_URI;

    if (categories) {
        url += '?filtersSelectedItems=categories:' + categories.join(',');
    }

    return url;
};

export const formatCurrency = (price: number): string => {
    const isNegative = price < 0;
    const formattedPrice = formatUSD(Math.abs(price));
    return isNegative ? `(${formattedPrice})` : formattedPrice;
};

export const capitalize = (inputString: string): string =>
    inputString && inputString.charAt(0).toUpperCase() + inputString.slice(1);

export const removeWhitespace = (inputString: string): string =>
    inputString.replace(/\s/g, '');

export const trimExtraWhitespace = (inputString: string): string =>
    inputString.trim().replace(/\s+/g, ' ');
