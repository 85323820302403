import React, { useMemo } from 'react';
import { BaseBox, Heading, Stack, Text } from '@trova-trip/trova-components';
import ItineraryPackageComponent from './ItineraryPackageComponent';
import FormSaver from 'util/form/FormSaver';
import {
    ItineraryPackageType,
    SelectedPackages,
} from './ItineraryPackage.types';
import useUpdateItineraryPackages from './useItineraryPackageInputs';
import {
    formatPackageLevelText,
    getDefaultPackageName,
    setComboBoxOption,
} from './utils';
import { constants } from '@trova-trip/trova-models';

const {
    PackageAccommodationLevel,
    PackageMealLevel,
    PackageFoodOptions,
    PackageLevel,
} = constants.itinerary;

export interface itineraryPackagesFormType {
    updateValue: FormSaver['updateValue'];
    selectedPackages: SelectedPackages;
}

const sharedPackagesOptions: Pick<
    ItineraryPackageType,
    'accommodationLevels' | 'mealLevels' | 'foodOptions'
> = {
    accommodationLevels: setComboBoxOption(PackageAccommodationLevel).map(
        ({ label, ...rest }) => ({
            label: formatPackageLevelText(label),
            ...rest,
        }),
    ),
    mealLevels: setComboBoxOption(PackageMealLevel),
    foodOptions: setComboBoxOption(PackageFoodOptions).filter(
        (elem) => elem.value !== PackageFoodOptions.NONE,
    ),
};

/**
 * DEFAULT ITINERARY PACKAGES DATA SET
 */
export const itineraryPackagesDefaultData = Object.keys(PackageLevel).map(
    (option) => {
        return {
            type: PackageLevel[option],
            enabled:
                PackageLevel[option] === PackageLevel.STANDARD ? true : false,
            ...sharedPackagesOptions,
        } as ItineraryPackageType;
    },
);

const ItineraryPackagesInputs = ({
    updateValue,
    selectedPackages,
}: itineraryPackagesFormType) => {
    const handleItineraryPackageValue = useUpdateItineraryPackages(
        updateValue,
        selectedPackages,
    );

    const packagesInputsGroup = useMemo(() => {
        const defaultPackageName = getDefaultPackageName(selectedPackages);
        return itineraryPackagesDefaultData.map((elem, idx) => {
            return (
                <BaseBox
                    width='100%'
                    borderTop='1px'
                    borderColor='gray.200'
                    paddingTop={7}
                    paddingBottom={5}
                    key={`${idx}-${elem}`}
                    marginTop={0}
                >
                    <ItineraryPackageComponent
                        updateValue={handleItineraryPackageValue}
                        onlyPackageAvailable={elem.type === defaultPackageName}
                        selectedValues={selectedPackages[elem.type]}
                        {...elem}
                    />
                </BaseBox>
            );
        });
    }, [selectedPackages]);

    return (
        <BaseBox
            width='100%'
            borderRadius={12}
            background='neutral.white'
            padding={6}
        >
            <Stack direction='column'>
                <BaseBox marginBottom={5}>
                    <Heading as='h4' marginBottom={2}>
                        Itinerary Packages
                    </Heading>
                    <Text as='p'>
                        Choose the itinerary packages that will be available to
                        Hosts. At least one package must be enabled.
                    </Text>
                </BaseBox>
                {packagesInputsGroup}
            </Stack>
        </BaseBox>
    );
};

export default ItineraryPackagesInputs;
