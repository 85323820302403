import { UserGroup } from '../../../../../../types';
import { isPublicHostProfileEnabled } from '../../../../../../../config/constants';
import { ProfileFieldsNames } from '../../../types/ProfileForm.types';

const conditionalPublicProfileHostUserGroup: UserGroup.HOST[] =
    isPublicHostProfileEnabled ? [] : [UserGroup.HOST];

const userGroupWithConditionalHost = new Set([
    ...conditionalPublicProfileHostUserGroup,
]);

const allUserGroupsWithConditionalHost = new Set([
    UserGroup.OPERATOR,
    UserGroup.TRAVELER,
    ...conditionalPublicProfileHostUserGroup,
]);

const profileFieldVisibility = {
    [ProfileFieldsNames.PROFILE_PICTURE_URL]: allUserGroupsWithConditionalHost,
    [ProfileFieldsNames.DISPLAY_NAME]: userGroupWithConditionalHost,
    [ProfileFieldsNames.WEBSITE_URL]: userGroupWithConditionalHost,
    [ProfileFieldsNames.INSTAGRAM_HANDLE]: allUserGroupsWithConditionalHost,
    [ProfileFieldsNames.BIO]: allUserGroupsWithConditionalHost,
};

export { profileFieldVisibility };
