import { BaseBox, Heading } from '@trova-trip/trova-components';
import {
    Button,
    ButtonProps,
    Link,
} from '@trova-trip/trova-components/build/next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getEmailLinkHref } from 'util/email.utils';
import { useTrackingEvent } from '../../../../../../analytics/hooks';
import { TROVA_MAIL_TO } from '../../../../../../config/constants';
import { TripDetails } from '../../../../../../interfaces/Trips.types';
import TabPageDescription from '../../../../../common/components/TabPageDescription';
import { useManageBooking, useManageBookingNavigation } from '../../hooks';
import CancelBookingDialog from '../CancelBookingDialog';

const TRANSFER_CANCELLATION_REQUEST_SUBJECT =
    'Cancellation request - trip transfer';

const CancelBooking = (): JSX.Element => {
    const [isCancelBookingDialogOpen, setIsCancelBookingDialogOpen] =
        useState<boolean>(false);
    const tripDetails: TripDetails = useSelector(
        (state: { userTripDetails: { current: TripDetails } }) =>
            state.userTripDetails?.current,
    );

    const {
        globalState: {
            booking: { dueAmount },
        },
        state: { tripSummary },
    } = useManageBooking();

    const { navigateTo } = useManageBookingNavigation();

    const { trackUserEvent } = useTrackingEvent();

    const emailLinkHref = getEmailLinkHref(
        TROVA_MAIL_TO,
        TRANSFER_CANCELLATION_REQUEST_SUBJECT,
    );

    const buttonProps: Partial<ButtonProps> = {
        variant: 'secondary',
        backgroundColor: 'transparent',
    };

    const handleCancelBookingClick = () => {
        if (dueAmount > 0) {
            navigateTo('pay-balance');
            trackUserEvent({
                eventName: 'Traveler Started Booking Cancel Flow',
            });
        }
        setIsCancelBookingDialogOpen(true);
    };

    return (
        <>
            {tripDetails.canCancel ? (
                <Button
                    marginTop={8}
                    marginBottom={{
                        base: tripSummary.isOpen ? 48 : 12,
                        lg: 12,
                    }}
                    paddingX={14}
                    onClick={handleCancelBookingClick}
                    {...buttonProps}
                >
                    Cancel Booking
                </Button>
            ) : (
                <BaseBox marginTop={14}>
                    <BaseBox marginBottom={4}>
                        <Heading as='h5'>Cancel Booking</Heading>
                    </BaseBox>

                    <TabPageDescription>
                        Click below to send us a request to cancel your booking.
                    </TabPageDescription>
                    <BaseBox marginTop={2}>
                        <Link
                            href={emailLinkHref}
                            isExternal
                            color='neutral.60'
                            rightIcon='offsite'
                        >
                            Email Support
                        </Link>
                    </BaseBox>
                </BaseBox>
            )}
            <CancelBookingDialog
                isDialogOpen={isCancelBookingDialogOpen}
                setIsDialogOpen={setIsCancelBookingDialogOpen}
            />
        </>
    );
};

export default CancelBooking;
