import StickyContentLayout from 'components/StickyContentLayout/StickyContentLayout';
import PricingTabStickyLayoutContent from './PricingTabStickyLayoutContent';

const PricingTabStickyLayout: React.FC = ({ children }): JSX.Element => (
    <StickyContentLayout
        stickyContent={<PricingTabStickyLayoutContent />}
        mainContent={
            <StickyContentLayout observableContent>
                {children}
            </StickyContentLayout>
        }
    />
);

export default PricingTabStickyLayout;
