import { useState, useEffect } from 'react';
import { useSelector } from 'state/hooks';
import Iframe from 'iframe-resizer-react';
import { userTrips } from '../../../../state/userTrips';
import { acceptTermsAndUpdateTrip } from '../../../../state/trips';
import { Grid } from '@material-ui/core';
import { Checkbox } from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import Snackbar from '../../../../components/Snackbar/Snackbar';
import useWindowHostName from '../../../../util/hooks/useWindowHostname';
import {
    TripStatuses,
    TripPublishValidStatuses,
} from '../../../../config/constants';
import { logError } from 'applications/common/helpers';
import { useTrackingEvent } from 'analytics/hooks';

interface IFramedServicesAgreementProps {
    onSuccess: () => void;
}

const IFramedServicesAgreement = ({
    onSuccess,
}: IFramedServicesAgreementProps): JSX.Element => {
    const host = useWindowHostName();
    const currentTrip = useSelector((store) => store.userTrips.current);
    const { updateRecord: updateTrip } =
        userTrips.useDispatch.bind(currentTrip)();
    const { id: tripId, status: tripStatus } = currentTrip || {};

    const [agree, setAgree] = useState(false);

    const [snackBar, setSnackBar] = useState({
        message: '',
        color: 'info',
        show: false,
    });

    const [agreementDocument, setAgreementDocument] = useState<
        string | undefined
    >(undefined);

    const { trackUserEvent } = useTrackingEvent();

    useEffect(() => {
        const setDocument = ({ data: { termsAccepted } }): void => {
            if (termsAccepted) {
                setAgreementDocument(termsAccepted);
            }
        };

        window.addEventListener(`message`, setDocument);
        return (): void => {
            window.removeEventListener(`message`, setDocument);
        };
    });

    const handleCheckboxChange = (): void => {
        setAgree((currentState) => !currentState);
    };

    const handleServiceResponseError = (error: string): void => {
        logError(error);
        setSnackBar({
            message: error,
            color: 'danger',
            show: true,
        });
    };

    const handleAgreementSubmit = async (): Promise<void> => {
        try {
            const acceptTermsResponse = await acceptTermsAndUpdateTrip(tripId, {
                terms: agreementDocument,
            });

            if (acceptTermsResponse.success) {
                const tripStatusToUpdate =
                    tripStatus && TripPublishValidStatuses.has(tripStatus)
                        ? tripStatus
                        : TripStatuses.HOST_APPROVED;
                const updateTripResponse = await updateTrip(tripId, {
                    status: tripStatusToUpdate,
                    publishRequest: new Date().toISOString(),
                });
                if (!updateTripResponse.success) {
                    handleServiceResponseError(updateTripResponse.error);
                }
                onSuccess();
            } else {
                handleServiceResponseError(acceptTermsResponse.error);
            }
            trackUserEvent({
                eventName: 'Host Accepted Agreement',
                properties: {
                    tripId: currentTrip?.id,
                    prices: {
                        initial: currentTrip?.prices?.initial,
                        remaining: currentTrip?.prices?.remainingPrice,
                    },
                },
            });
        } catch (error) {
            const { message } = error;
            handleServiceResponseError(message);
        }
    };

    const agreementUrl = `//${host}/api/me/pages/host-terms?tripId=${tripId}&hideFooterButtons=true&postTermsDocument=true`;

    return (
        <Grid container spacing={32}>
            <Grid container item spacing={16}>
                <Grid item xs={12} sm={8}>
                    <>
                        <Iframe
                            src={agreementUrl}
                            frameBorder={0}
                            style={{
                                width: '100%',
                                overflow: 'hidden',
                                margin: '0 0 -8px 0',
                            }}
                        />
                    </>
                </Grid>

                <Grid item xs={12}>
                    <Checkbox
                        name={'agreement'}
                        detail={'I agree to the services agreement.'}
                        value={agree}
                        onChange={handleCheckboxChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant='primary'
                        rightIcon='arrow-right'
                        isDisabled={!agree}
                        onClick={handleAgreementSubmit}
                    >
                        Continue
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Snackbar
                        place='tr'
                        color={snackBar.color}
                        message={snackBar.message}
                        open={snackBar.show}
                        autoHideDuration={4000}
                        onClose={(): void => {
                            setSnackBar({
                                message: '',
                                color: 'info',
                                show: false,
                            });
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default IFramedServicesAgreement;
