import { validationSchemas } from '@trova-trip/trova-models';
import * as yup from 'yup';
import { ContactDetail } from '../../../../../state/features/transfer/types';

const { nameValidation, phoneValidation } = validationSchemas;
const { requiredMessage, emailMessage } = validationSchemas.validationMessages;

const schema: yup.SchemaOf<Required<ContactDetail>> = yup.object({
    firstName: yup
        .string()
        .required(requiredMessage('First name'))
        .matches(nameValidation.regex, nameValidation.options('First name')),
    lastName: yup
        .string()
        .required(requiredMessage('Last name'))
        .matches(nameValidation.regex, nameValidation.options('Last name')),
    email: yup
        .string()
        .email(emailMessage())
        .required(requiredMessage('Email')),
    phone: yup
        .string()
        .required(requiredMessage('Phone'))
        .matches(phoneValidation.regex, phoneValidation.options),
    termsAndConditions: yup
        .boolean()
        .required(requiredMessage('Terms & conditions'))
        .oneOf([true], 'Please accept the terms and conditions'),
});

export default schema;
