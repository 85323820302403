import { analytics, analyticsTypes } from '@trova-trip/trova-common';

type TrackEventProperties = analyticsTypes.TrackEventParameters['properties'];

interface TrackEventProps<T> {
    eventName: string;
    payload?: T;
}

const trackEvent = <T extends Record<string, any>>(
    props: TrackEventProps<T>,
): void => {
    const { eventName, payload } = props;
    analytics.trackEvent({
        eventName,
        properties: payload as TrackEventProperties,
    });
};

export { trackEvent };
