import { useDispatch } from 'react-redux';
import actionCreator from '../../../../../state/features/transfer/actions';
import {
    BaseUser,
    BookedTransferJourneysByStage,
    ComputedPaymentDetail,
    SavedBooking,
    Trip,
    UserTripsTraveler,
} from '../../../../../state/features/transfer/types';
import { calculateTotal } from '../../../../../state/features/transfer/utils';
import { RootStore, useSelector } from '../../../../../state/hooks';
import {
    isTransferProductReady,
    mapGroundTransfersByStage,
} from '../../../helpers';
import {
    useUserBookingsActions,
    useGroundTransfersActions,
} from '../../../../traveler/hooks';

type TransferState = Omit<RootStore['transfer'], 'payment'> & {
    payment: ComputedPaymentDetail;
};

type UseTransferReturn = {
    globalState: {
        /**
         * The logged-in user
         */
        user: BaseUser;

        /**
         * The current trip
         */
        trip: Trip;

        /**
         * The current booking. This property will be defined
         * only if the user is a traveler.
         */
        booking?: SavedBooking;

        /**
         * The main traveler for the user trips.
         * This is separated from the user that contains the profile.
         */
        traveler?: UserTripsTraveler;

        /**
         * The current ground transfers booked by the user
         */
        groundTransfersByStage?: BookedTransferJourneysByStage;
    };

    globalActions: {
        /**
         * Clears the ground transfers list from the store
         */
        clearGroundTransfers: () => void;

        /**
         * Clears the user bookings from the store
         */
        clearUserBookings: () => void;
    };

    /**
     * The state from the global store + the computed payment total.
     */
    state: TransferState;

    /**
     * The actions to modify the state from the global store
     */
    actions: ReturnType<typeof actionCreator>;
};

const useTransfer = (): UseTransferReturn => {
    const globalState = useSelector((state) => ({
        user: state.profile.current,
        trip: state.userTrips.current,
        booking: state.userBookings?.current?.[0],
        groundTransfersByStage: mapGroundTransfersByStage(
            state.userGroundTransfers.list,
        ),
        traveler: state.userTripsTraveler.list?.records?.[0],
    })) as UseTransferReturn['globalState'];

    const state = useSelector((state) => state.transfer);

    const dispatch = useDispatch();
    const actions = actionCreator(dispatch);

    const { clearGroundTransfers } = useGroundTransfersActions();

    const { clearUserBookings } = useUserBookingsActions();

    const { user, trip, booking } = globalState;

    const globalActions = {
        clearGroundTransfers,
        clearUserBookings,
    };

    const shouldThrowError = !isTransferProductReady(user, trip, booking);

    if (shouldThrowError) {
        throw new Error(
            'The hook useTransfer should be used in a context where the trip & booking (just if the user is a traveler) are already defined on the store.',
        );
    }

    const total = calculateTotal(state);

    const finalState: TransferState = {
        ...state,
        payment: {
            ...state.payment,
            total,
        },
    };

    return { globalState, state: finalState, actions, globalActions };
};

export default useTransfer;
