import { Dialog } from '@trova-trip/trova-components/build/next';

export interface BookingsStepConfirmationDialogProps {
    title: string;
    subtitle: string;
    open: boolean;
    confirmStepButtonName: string;
    onConfirmStep: () => void;
    onClose: () => void;
}

const BookingsStepConfirmationDialog: React.FC<BookingsStepConfirmationDialogProps> =
    ({
        open,
        title,
        subtitle,
        confirmStepButtonName,
        onConfirmStep,
        onClose,
        children,
    }) => (
        <Dialog
            position='top'
            isOpen={open}
            size='lg'
            closeOnOverlayClick
            onClose={onClose}
        >
            <Dialog.Header title={title} subtitle={subtitle} />
            <Dialog.Body maxWidth='100%' overflow='auto'>
                {children}
            </Dialog.Body>
            <Dialog.Footer
                actions={[
                    {
                        children: confirmStepButtonName,
                        variant: 'primary',
                        onClick: onConfirmStep,
                    },
                    {
                        children: 'Cancel',
                        variant: 'secondary',
                        onClick: onClose,
                    },
                ]}
            />
        </Dialog>
    );

export default BookingsStepConfirmationDialog;
