import { useMemo } from 'react';
import { models } from '@trova-trip/trova-models';
import Iframe from 'iframe-resizer-react';
import { useSelector } from 'react-redux';
import { coreUtils } from '@trova-trip/trova-common';
import { API_URL } from 'applications/utils';

type QueryParams = {
    [key: string]: any;
};
interface IFramedTripPreviewProps {
    className?: string;
    queryParams?: QueryParams;
}

type StoreSelector = { userTrips: { current: models.trips.Trip } };

const { createTripPagePreviewUrl } = coreUtils.tripPageUtils;

export const IFramedTripPreview = (
    props: IFramedTripPreviewProps,
): JSX.Element => {
    const { className = '', queryParams: customQueryParams = {} } = props;
    const trip = useSelector((state: StoreSelector) => state.userTrips.current);
    const { id, operator, tripPath } = trip;

    const tripPreviewUrl = useMemo(
        () =>
            createTripPagePreviewUrl({
                id: id as string,
                operator: operator as string,
                tripPath,
                useLegacyPreviewUrl: false
            }, API_URL, customQueryParams),
        [trip, customQueryParams],
    );
    
    return (
        <Iframe
            src={tripPreviewUrl}
            frameBorder={0}
            className={className}
            scrolling
            style={{ width: '100%', height: '100%' }}
        />
    );
};

export default IFramedTripPreview;
