import { appConstants, coreUtils } from '@trova-trip/trova-common';
import { Heading } from '@trova-trip/trova-components';
import { constants } from '@trova-trip/trova-models';
import { useEffect } from 'react';
import { useSelector } from '../../../../../../state/hooks';
import PageCard from '../../../../../common/components/PageCard';
import PaymentsApp from '../../../../../common/components/PaymentsApp';
import useIsLargeScreen from '../../../../../common/hooks/useIsLargeScreen';
import { APP_ENVIRONMENT } from '../../../../../utils';
import {
    useAnalytics,
    useManageBooking,
    useManageBookingNavigation,
} from '../../hooks';
import ErrorAlert from '../ErrorAlert';
import TripSummaryContent from '../TripSummaryContent';
import WithRouteValidation from '../WithRouteValidation';

const { PaymentOptionType } = constants.bookings;
const { formatUSD } = coreUtils.currencyUtils;
const paymentOptions: coreUtils.bookingUtils.PaymentsAppPaymentOption[] = [
    PaymentOptionType.MINIMUM,
    PaymentOptionType.FULL,
];

const PaymentPage = (): JSX.Element => {
    const { currentTrip, currentBooking } = useSelector((state) => ({
        currentTrip: state.userTrips?.current,
        currentBooking: state.userBookings?.current?.[0],
    }));

    const {
        state: {
            tripSummary: {
                totalWithInsurance,
                sections: { added, removed, roomSelection },
            },
            addOns: {
                accommodations: { singleSupplement },
            },
            confirmation,
        },
        actions: { updateBooking },
    } = useManageBooking();

    const { trackViewPortalCheckoutPageEvent } = useAnalytics();

    const isDesktop = useIsLargeScreen();

    const { getManageBookingTabPath } = useManageBookingNavigation();

    useEffect(() => {
        trackViewPortalCheckoutPageEvent({
            tripStatus: currentTrip?.status,
            cartBalance: totalWithInsurance,
            sections: { added, removed, roomSelection },
            singleSupplement,
        });
    }, []);

    const isDueAmountZero = currentBooking?.dueAmount === 0;

    const paymentUrlParams: coreUtils.bookingUtils.PaymentsAppURLParams = {
        tripId: currentTrip?.id || '',
        orderId: currentBooking?.orderId || '',
        env: APP_ENVIRONMENT as appConstants.Environment,
        config: {
            contextType: 'updateBooking',
            additionalAmount: totalWithInsurance,
            sections: {
                show: isDueAmountZero ? ['none'] : ['paymentOptions'],
            },
            paymentOptions: {
                show: paymentOptions,
                order: paymentOptions,
                selectedByDefault: PaymentOptionType.MINIMUM,
            },
        },
    };

    return (
        <PageCard
            navigateBackTo={{
                path: getManageBookingTabPath('root'),
                description: 'Back to manage booking',
            }}
            sidebarContent={
                <TripSummaryContent
                    showTitle={true}
                    showInsuranceDisclaimer={false}
                    showTotal={isDesktop}
                />
            }
            mobileProps={{
                accordion: {
                    header: {
                        title: 'Total',
                        rightElement: (
                            <Heading as='h5' fontSize='lg'>
                                {formatUSD(totalWithInsurance, 2)}
                            </Heading>
                        ),
                    },
                    position: 'bottom',
                    padding: 0,
                },
            }}
            sidebarPadding={3}
        >
            <Heading as='h2'>Payment</Heading>
            <ErrorAlert
                error={confirmation.error}
                paddingTop={1}
                width='full'
                paddingX={4}
            />
            <PaymentsApp
                paymentUrlParams={paymentUrlParams}
                onSuccess={(data): void => {
                    updateBooking(data.payment, data.paymentOptionType);
                }}
            />
        </PageCard>
    );
};

export default WithRouteValidation(PaymentPage);
