export enum TRIP_FORM_FIELD_NAME {
    FLIGHT_ARRIVAL_AIRLINE = 'flightInformation.arrivalDetails.airline',
    FLIGHT_ARRIVAL_NUMBER = 'flightInformation.arrivalDetails.flightNumber',
    FLIGHT_ARRIVAL_DATE = 'flightInformation.arrivalDetails.date',
    FLIGHT_ARRIVAL_TIME = 'flightInformation.arrivalDetails.time',
    FLIGHT_DEPARTURE_AIRLINE = 'flightInformation.departureDetails.airline',
    FLIGHT_DEPARTURE_NUMBER = 'flightInformation.departureDetails.flightNumber',
    FLIGHT_DEPARTURE_DATE = 'flightInformation.departureDetails.date',
    FLIGHT_DEPARTURE_TIME = 'flightInformation.departureDetails.time',
    END_TRIP_PLANS = 'endOfTripPlans',
    TRAVELER_INSURANCE_POLICY_NUMBER = 'travelInsurance.policyNumber',
    TRAVELER_INSURANCE_CARRIER = 'travelInsurance.carrier',
    SPECIAL_REQUEST_DESCRIPTION = 'specialRequests.description',
    SPECIAL_REQUEST_ROOM_MATE = 'specialRequests.roomMate',
    PASSPORT_FIRST_NAME = 'passportInformation.firstName',
    PASSPORT_LAST_NAME = 'passportInformation.lastName',
    PASSPORT_NUMBER = 'passportInformation.passportNumber',
    PASSPORT_NATIONALITY = 'passportInformation.nationality',
    PASSPORT_ISSUE_DATE = 'passportInformation.issueDate',
    PASSPORT_EXPIRATION_DATE = 'passportInformation.expirationDate',
    PASSPORT_DATE_OF_BIRTH = 'passportInformation.dateOfBirth',
    PASSPORT_GENDER = 'passportInformation.gender',
    EMERGENCY_CONTACT_NAME = 'emergencyContact.name',
    EMERGENCY_CONTACT_RELATIONSHIP = 'emergencyContact.relationship',
    EMERGENCY_CONTACT_PHONE = 'emergencyContact.phone',
    EMERGENCY_CONTACT_EMAIL = 'emergencyContact.email',
    DISCLOSURES_PREFERENCES_DESCRIPTION = 'disclosuresAndPreferences.description',
    DISCLOSURES_PREFERENCES_EARLY_BIRD = 'disclosuresAndPreferences.preferences.earlyBird',
    DISCLOSURES_PREFERENCES_NIGHT_OWL = 'disclosuresAndPreferences.preferences.nightOwl',
    DISCLOSURES_PREFERENCES_ADRENALINE_JUNKIE = 'disclosuresAndPreferences.preferences.adrenalineJunkie',
    DISCLOSURES_PREFERENCES_LEISURE_ENTHUSIAST = 'disclosuresAndPreferences.preferences.leisureEnthusiast',
    DISCLOSURES_PREFERENCES_PLANNER = 'disclosuresAndPreferences.preferences.planner',
    DISCLOSURES_PREFERENCES_WANDERER = 'disclosuresAndPreferences.preferences.wanderer',
    DISCLOSURES_PREFERENCES_LIFE_OF_THE_PARTY = 'disclosuresAndPreferences.preferences.lifeOfTheParty',
    DISCLOSURES_PREFERENCES_LOVER_OF_ALONE_TIME = 'disclosuresAndPreferences.preferences.loverOfAloneTime',
    DISCLOSURES_PREFERENCES_ROOMMATE_GENDER_PREFERENCES = 'disclosuresAndPreferences.roommateGenderPreferences.{gender}',
}
