import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { Stack, Text, TextProps } from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import { NonPrintableButton } from './CollapsibleText.styles';

interface CollapsibleTextProps extends Omit<TextProps, 'children'> {
    children: string;
    lines?: number;
}

export const CollapsibleText = (props: CollapsibleTextProps): JSX.Element => {
    const { children, lines = 3, ...textProps } = props;
    const textRef = useRef<HTMLSpanElement>(null);

    const [isExpanded, setIsExpanded] = useState(false);
    const [showExpandButton, setShowExpandButton] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', debouncedTruncateText);
        window.addEventListener('resize', debouncedToggleButton);
        return (): void => {
            debouncedTruncateText.cancel();
            debouncedToggleButton.cancel();
            window.removeEventListener(`resize`, debouncedTruncateText);
            window.removeEventListener(`resize`, debouncedToggleButton);
        };
    }, []);

    useEffect(() => {
        truncateText();
        toggleButton();
    }, [children, lines]);

    useEffect(() => {
        isExpanded ? resetText() : truncateText();
    }, [isExpanded]);

    const getCurrentTextLines = useCallback(() => {
        if (!textRef.current) return 0;
        return textRef.current.getClientRects().length;
    }, []);

    const truncateText = useCallback(() => {
        const textNode = textRef.current?.firstChild;
        if (!textNode?.nodeValue || !textRef.current) return;
        textNode.nodeValue = children;
        const currentTextLines = getCurrentTextLines();
        if (currentTextLines <= lines) return;
        for (let i = 0; i < textNode.nodeValue.length; i++) {
            textNode.nodeValue = removeLastWord(textNode.nodeValue) + '...';
            if (textRef.current.getClientRects().length <= lines) {
                break;
            }
        }
    }, [children, lines]);

    const toggleButton = useCallback(() => {
        const textNode = textRef.current?.firstChild;
        if (!textNode?.nodeValue) return;
        const showButton = textNode.nodeValue !== children;
        setShowExpandButton(showButton);
    }, [children]);

    const debouncedTruncateText = useMemo(() => {
        return debounce(truncateText, 200);
    }, [truncateText]);

    const debouncedToggleButton = useMemo(() => {
        return debounce(toggleButton, 200);
    }, [toggleButton]);

    const resetText = (): void => {
        const textNode = textRef.current?.firstChild;
        if (!textNode) return;
        textNode.nodeValue = children;
    };

    const removeLastWord = (text): string =>
        text.substring(0, text.lastIndexOf(' '));

    return (
        <Stack direction='column'>
            <Text size='sm' {...textProps}>
                <span ref={textRef}>{children}</span>
            </Text>
            <Stack spacing={0} width='100%' justify='flex-end'>
                {showExpandButton && (
                    <Button
                        className={NonPrintableButton}
                        variant='tertiary'
                        color='neutral.60'
                        size='sm'
                        rightIcon={isExpanded ? 'arrow-up' : 'arrow-down'}
                        onClick={(): void =>
                            setIsExpanded((expanded) => !expanded)
                        }
                    >
                        View {isExpanded ? 'less' : 'more'}
                    </Button>
                )}
            </Stack>
        </Stack>
    );
};
