import {
    BaseBox,
    Heading,
    Image,
    Spinner,
    Stack,
    Text,
    useToast,
} from '@trova-trip/trova-components';
import {
    Button,
    ButtonProps,
    FeedbackMessage,
} from '@trova-trip/trova-components/build/next';
import { models } from '@trova-trip/trova-models';
import { useEffect } from 'react';
import {
    getGroundTransferAddOnById,
    getJourneyById,
    isTraveler,
    shouldRepriceInsurance,
} from '../../../../../applications/common/helpers';
import carImg from '../../../../../assets/img/car-2.svg';
import { CancelGroundTransferApiRequest } from '../../../../../state/features/transfer/types';
import {
    isInsuranceRepriceError,
    isTransferCancelError,
    isTransferCancelledStatus,
    isTransferCancellingStatus,
    isTransferErrorStatus,
    isTransferRepricingInsuranceStatus,
} from '../../../../../state/features/transfer/utils';
import { useTransfer, useTransferOperationsAnalytics } from '../hooks';

type Service = models.services.Service;

const InsuranceLoader = () => (
    <Stack justify='center' align='center' width='full' minHeight='sm'>
        <Spinner color='teal.trova' />
    </Stack>
);

interface CancelTransferProps {
    journeyId?: string;
    onBack?: () => void;
}

const CancelTransfer = ({
    journeyId,
    onBack,
}: CancelTransferProps): JSX.Element | null => {
    const toast = useToast();

    const { trackCancelGroundTransfersBookingEvent } =
        useTransferOperationsAnalytics();

    const {
        globalState: { booking, trip, user, groundTransfersByStage },
        globalActions: { clearGroundTransfers, clearUserBookings },
        actions: { repriceInsurance, cancelTransfer, resetState },
        state: {
            payment: { insurance },
            metadata: { status, error },
        },
    } = useTransfer();

    useEffect(() => {
        const insuranceShouldBeRepriced = shouldRepriceInsurance(
            booking,
            insurance.status,
        );

        if (insuranceShouldBeRepriced && journeyId) {
            repriceInsurance({ booking, operation: 'cancel', journeyId });
        }
    }, [insurance.status, booking, trip, journeyId]);

    useEffect(() => {
        // This will be executed after a successful cancellation
        if (isTransferCancelledStatus(status)) {
            resetState();
            clearGroundTransfers();
            clearUserBookings();

            toast({
                description: 'Your ride was cancelled successfully.',
                title: 'Success',
                status: 'success',
                isClosable: true,
                position: 'top-right',
            });
        }
    }, [status]);

    if (!journeyId || !groundTransfersByStage) return null;

    const isRepricingInsurance = isTransferRepricingInsuranceStatus(status);
    const isCancelling = isTransferCancellingStatus(status);
    const shouldDisplayError =
        isTransferErrorStatus(status) &&
        (isInsuranceRepriceError(error) || isTransferCancelError(error));

    if (isRepricingInsurance) {
        return (
            <BaseBox minHeight='sm' width='full'>
                <InsuranceLoader />
            </BaseBox>
        );
    }

    if (shouldDisplayError) {
        const actions: ButtonProps[] = onBack
            ? [
                  {
                      as: 'button',
                      children: 'Back',
                      variant: 'secondary',
                      onClick: onBack,
                  },
              ]
            : [];

        return (
            <Stack width='full' justify='center'>
                <FeedbackMessage
                    type='error'
                    size='sm'
                    direction='vertical'
                    message={error?.message}
                    actions={actions}
                    showContactSupport
                />
            </Stack>
        );
    }

    const journey = getJourneyById(journeyId, groundTransfersByStage);

    const handleCancel = (): void => {
        const cancelPayload: CancelGroundTransferApiRequest = {
            groundTransferId: journeyId,
            cancelRequest: isTraveler(user)
                ? { repricedInsuranceAddOn: insurance.repricedAddOn }
                : undefined,
        };

        cancelTransfer(cancelPayload);

        const cancelledAddOn = getGroundTransferAddOnById(booking, journeyId);
        const cancelledService = cancelledAddOn?.service;

        trackCancelGroundTransfersBookingEvent({
            groundTransferService: cancelledService as Service,
            journeyType: journey.type,
        });
    };

    return (
        <Stack
            direction='column'
            minHeight={{ base: 'calc(100vh - 6rem)', lg: 'full' }}
            justify='space-between'
        >
            <Stack
                direction='column'
                paddingTop={{ base: '6rem', lg: 'initial' }}
            >
                <Heading as='h6'>
                    Are you sure you want to cancel your ride?
                </Heading>

                <Text paddingY={2}>
                    You can rebook at anytime, up to 24 hours before your trip's
                    official start date. If you choose not to rebook, you will
                    be responsible for your own transportation.
                </Text>
            </Stack>

            <Stack width='full' justify={{ base: 'center', lg: 'start' }}>
                <Image
                    src={carImg}
                    alt='vehicle'
                    data-gumlet='false'
                    height={{ base: 'auto', lg: '14rem' }}
                />
            </Stack>

            <Stack
                spacing={{ base: 0, lg: 4 }}
                width='full'
                justify='flex-end'
                paddingBottom={2}
            >
                {onBack ? (
                    <Button
                        variant='secondary'
                        onClick={onBack}
                        isDisabled={isCancelling}
                    >
                        Back To Details
                    </Button>
                ) : null}

                <Button
                    isFullWidth={{ base: true, lg: false }}
                    variant='primary'
                    onClick={handleCancel}
                    isDisabled={isCancelling}
                    isLoading={isCancelling}
                >
                    Cancel Ride
                </Button>
            </Stack>
        </Stack>
    );
};

export default CancelTransfer;
