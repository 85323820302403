import { Text } from '@trova-trip/trova-components';
import { PrintableServiceTitleStyle } from './services.styles';

interface ServiceTitleProps {
    title: string;
    color: string;
}

const ServiceTitle: React.FC<ServiceTitleProps> = ({ title, color }) => (
    <Text
        color={color}
        fontWeight={'bold'}
        className={PrintableServiceTitleStyle}
    >
        {title}
    </Text>
);

export default ServiceTitle;
