import { coreUtils } from '@trova-trip/trova-common';
import {
    BaseBox,
    Heading,
    Stack,
    StackProps,
    Summary,
    SummaryGroup,
    SummaryItem,
    Text,
    TextProps,
} from '@trova-trip/trova-components';
import pick from 'lodash/pick';
import { useManageBooking, useTripSummaryContent } from '../../hooks';
import { isPaymentPage, isSuccessPage } from '../../utils/route.utils';

const { formatUSD } = coreUtils.currencyUtils;

interface TripSummaryContentProps {
    showTitle?: boolean;
    showTotal?: boolean;
    showDueToday?: boolean;
    showInsuranceDisclaimer?: boolean;
}

const TripSummaryContent = ({
    showTitle = false,
    showTotal = true,
    showInsuranceDisclaimer = true,
}: TripSummaryContentProps): JSX.Element | null => {
    const { pendingChanges } = useTripSummaryContent();

    if (!pendingChanges) return null;

    const isPaymentOrSuccessRoute = isPaymentPage() || isSuccessPage();

    return (
        <>
            {showTitle ? (
                <BaseBox
                    width='full'
                    paddingY={{ base: 4, lg: 0 }}
                    paddingX={{ base: 3, lg: 0 }}
                    borderBottom={{ base: '1px', lg: 0 }}
                    borderColor='blueGray.200'
                >
                    <Heading
                        as='h6'
                        fontSize={{ base: 'lg', lg: 'xl' }}
                        fontWeight={{ base: 'bold', lg: 'medium' }}
                    >
                        Summary
                    </Heading>
                </BaseBox>
            ) : null}

            {isPaymentOrSuccessRoute ? (
                <PaymentAndSuccessSummaryContent showTotal={showTotal} />
            ) : (
                <IndexSummaryContent
                    showInsuranceDisclaimer={showInsuranceDisclaimer}
                    showTotal={showTotal}
                />
            )}
        </>
    );
};

interface IndexSummaryContentProps {
    showInsuranceDisclaimer: boolean;
    showTotal: boolean;
}

const IndexSummaryContent = ({
    showInsuranceDisclaimer,
    showTotal,
}: IndexSummaryContentProps) => {
    const { sections } = useTripSummaryContent();

    return (
        <SummaryContentBox>
            <Summary groups={sections} paddingX={{ base: 3, lg: 0 }} />
            {showInsuranceDisclaimer ? (
                <BaseBox paddingX={{ base: 3, lg: 0 }}>
                    <InsuranceDisclaimer />
                </BaseBox>
            ) : null}
            {showTotal ? (
                <Total
                    fontSize={{
                        base: 'lg',
                        md: 'xl',
                    }}
                />
            ) : null}
        </SummaryContentBox>
    );
};

interface PaymentAndSuccessSummaryContentProps {
    showTotal: boolean;
}

const PaymentAndSuccessSummaryContent = ({
    showTotal,
}: PaymentAndSuccessSummaryContentProps) => {
    const { sections } = useTripSummaryContent();

    const sectionsWithoutAction = sections.map((section) => ({
        ...section,
        items: section.items.map((item) => {
            // Removes the actionProps from the item leaving it without icon button.
            return pick(item, 'price', 'description');
        }) as SummaryGroup['items'],
    }));

    return (
        <SummaryContentBox>
            <Summary
                groups={sectionsWithoutAction}
                paddingX={{ base: 3, lg: 0 }}
            />
            <InsuranceAdjustment />
            {showTotal ? (
                <Total fontSize='lg' paddingX={{ base: 3, lg: 0 }} />
            ) : null}
        </SummaryContentBox>
    );
};

const SummaryContentBox = (props: StackProps) => (
    <Stack
        direction='column'
        spacing={4}
        borderBottom={{ base: '1px', lg: 0 }}
        borderColor='blueGray.200'
        paddingBottom={{ base: 3, lg: 0 }}
        {...props}
    />
);

const BoxWithDivider = (props: StackProps) => (
    <Stack
        width='full'
        align='center'
        justify='space-between'
        borderTop='1px'
        borderColor='blueGray.200'
        paddingTop={4}
        {...props}
    />
);

const Total = ({
    fontSize,
    paddingX,
}: Pick<TextProps, 'fontSize' | 'paddingX'>) => {
    const {
        state: {
            tripSummary: { total, totalWithInsurance },
        },
    } = useManageBooking();

    const isPaymentOrSuccessRoute = isPaymentPage() || isSuccessPage();

    const totalToShow = isPaymentOrSuccessRoute ? totalWithInsurance : total;

    return (
        <BoxWithDivider paddingX={paddingX}>
            <Heading
                as='h5'
                fontSize={fontSize}
                fontWeight='medium'
                color='blueGray.650'
            >
                Total
            </Heading>
            <Text
                fontSize={fontSize}
                fontWeight='medium'
                color={totalToShow < 0 ? 'alerts.error.accent' : 'blueGray.650'}
            >
                {formatUSD(totalToShow, 2)}
            </Text>
        </BoxWithDivider>
    );
};

const InsuranceAdjustment = () => {
    const { insuranceAdjustment } = useTripSummaryContent();

    if (!insuranceAdjustment) {
        return null;
    }

    const label = 'Insurance Adjustment';

    return (
        <BaseBox width='full' paddingX={{ xs: 3, lg: 0 }}>
            <Text color='blueGray.500' marginTop={6} marginBottom={3}>
                {label.toUpperCase()}
            </Text>
            <SummaryItem {...insuranceAdjustment} />
        </BaseBox>
    );
};

const InsuranceDisclaimer = () => {
    const { bookingHasInsurance } = useTripSummaryContent();

    if (!bookingHasInsurance) {
        return null;
    }

    return (
        <Text width='full' color='blueGray.500'>
            Booking edits may affect insurance cost at checkout.
        </Text>
    );
};

export default TripSummaryContent;
