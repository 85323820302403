import { BookingStatuses } from 'config/constants';
import { SyntheticEvent } from 'react';
import { Grid } from '@material-ui/core';
import { Heading, Text, Toggle, BaseBox } from '@trova-trip/trova-components';
import { FormattedBookingOrder } from './BookingsTables';
import {
    styledAutoConfirmToggle,
    styledContainer,
} from './BookingsTables.styles';
import TravelersSelectableTable from './TravelersSelectableTable';

interface TravelersTableProps {
    data: FormattedBookingOrder[];
    bookingAutoConfirmation: boolean;
    onAutoConfirmationToggleChange: (event: SyntheticEvent) => void;
    onConfirmBookings: (
        ordersId: string[],
        resetSelectedOrders: () => void,
    ) => void;
    onCancelBookings: (
        ordersId: string[],
        resetSelectedOrders: () => void,
    ) => void;
}

const TravelersTable = ({
    data,
    bookingAutoConfirmation,
    onAutoConfirmationToggleChange,
    onConfirmBookings,
    onCancelBookings,
}: TravelersTableProps): JSX.Element => {
    return (
        <Grid container spacing={32} className={styledContainer}>
            <Grid item xs={12}>
                <Grid container item alignItems='center'>
                    <Grid item xs={'auto'}>
                        <Heading as={'h4'}>Travelers</Heading>
                    </Grid>
                    <Grid item xs={'auto'}>
                        <Toggle
                            className={styledAutoConfirmToggle}
                            detail={
                                'Auto-confirm pending travelers when the trip is confirmed'
                            }
                            name={'autoConfirmBookings'}
                            value={bookingAutoConfirmation}
                            onChange={onAutoConfirmationToggleChange}
                            disabled={data.length === 0}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <BaseBox marginTop={4}>
                        <Text>
                            Use the table below to view, confirm and reject the
                            travelers that have booked this trip.
                        </Text>
                    </BaseBox>
                </Grid>
            </Grid>
            <TravelersSelectableTable
                data={data}
                confirmAction={{
                    callback: onConfirmBookings,
                }}
                rejectAction={{
                    callback: onCancelBookings,
                }}
                orderStatusSelectable={BookingStatuses.PENDING}
                emptyMessage={
                    'Once you start receiving bookings, you can manage travelers here.'
                }
            />
        </Grid>
    );
};

export default TravelersTable;
