import { Heading, List, Stack } from '@trova-trip/trova-components';
import {
    Button,
    Dialog,
    DialogProps,
    SidebarItem,
} from '@trova-trip/trova-components/build/next';
import { ReactElement } from 'react';
import { BookedGroundTransferJourney } from '../../../../../state/features/transfer/types';
import { areGroundTransfersOperationsEnabled } from '../../../../common/helpers';
import { useTransfer } from '../hooks';
import { View, ViewName } from './RideOperationsPopup';
import JourneyStatusBadge from './shared/JourneyStatusBadge';
import { isTransferErrorStatus } from '../../../../../state/features/transfer/utils';

type Tab = {
    key: ViewName;
    title: View['title'];
};

interface RideOperationsBaseProps {
    activeTab: ViewName;
    journey: BookedGroundTransferJourney;
    onChangeTab: (tab: ViewName) => void;
    tabs: Tab[];
}

const RideOperationsSidebar = ({
    tabs,
    activeTab,
    journey,
    onChangeTab,
}: RideOperationsBaseProps): JSX.Element | null => {
    const {
        globalState: { traveler },
        actions: { resetState },
        state: {
            metadata: { status },
        },
    } = useTransfer();

    const areOperationsEnabled = areGroundTransfersOperationsEnabled(
        traveler?.type,
        journey.status,
    );

    const handleTabChange = (key: ViewName): void => {
        onChangeTab(key);

        if (isTransferErrorStatus(status)) {
            resetState();
        }
    };

    return (
        <Stack
            direction='column'
            paddingRight={6}
            borderRight='1px'
            borderColor='blueGray.200'
            justify='space-between'
            height='full'
            paddingBottom={2}
            marginRight={4}
        >
            <List as='ul' listStyleType='none' spacing={5}>
                {tabs.map(({ key, title = '' }) => (
                    <SidebarItem
                        key={key}
                        title={title}
                        subtitle=''
                        isSelected={key === activeTab}
                        onClick={() => handleTabChange(key)}
                    />
                ))}
            </List>
            {areOperationsEnabled ? (
                <Button
                    isFullWidth
                    variant='secondary'
                    as='button'
                    onClick={() => onChangeTab('cancel')}
                >
                    Cancel Ride
                </Button>
            ) : null}
        </Stack>
    );
};

interface RideOperationsHeaderProps {
    status: BookedGroundTransferJourney['status'];
    title?: string;
}

const RideOperationsHeader = ({ title, status }: RideOperationsHeaderProps) => (
    <Stack align='center' justify='space-between' width='full'>
        <Heading as='h6'>{title}</Heading>
        <JourneyStatusBadge status={status} />
    </Stack>
);

interface RideOperationsDialogProps extends RideOperationsBaseProps {
    content: ReactElement | undefined;
    isOpen: boolean;
    onClose: DialogProps['onClose'];
    title?: string;
}

const RideOperationsDialog = (
    props: RideOperationsDialogProps,
): JSX.Element => {
    const {
        isOpen,
        onClose,
        onChangeTab,
        journey,
        title,
        content,
        tabs,
        activeTab,
    } = props;

    const isCancelView = activeTab === 'cancel';

    return (
        <Dialog isOpen={isOpen} onClose={onClose} minWidth='60vw'>
            <Dialog.Header title='' />
            <Dialog.Body width='100%'>
                <Stack paddingTop={6} height='lg'>
                    {!isCancelView ? (
                        <RideOperationsSidebar
                            tabs={tabs}
                            onChangeTab={onChangeTab}
                            journey={journey}
                            activeTab={activeTab}
                        />
                    ) : null}
                    <Stack
                        direction='column'
                        width='full'
                        height='full'
                        flex={1}
                        spacing={4}
                    >
                        {!isCancelView ? (
                            <RideOperationsHeader
                                title={title}
                                status={journey.status}
                            />
                        ) : null}
                        {content}
                    </Stack>
                </Stack>
            </Dialog.Body>
        </Dialog>
    );
};

export default RideOperationsDialog;
