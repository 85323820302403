import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Button } from '@trova-trip/trova-components/build/next';
import { BaseBox } from '@trova-trip/trova-components';
import { windowHostName } from '../../../../config/publish';
import { getOperatorForUser } from '../../../../state/userOperators';

const windowHost = windowHostName();

interface PreviewEntityButtonProps {
    productName: string;
    entityId: string;
}

const useGetPreviewButtonFunction = (
    entityName: string,
    entityId: string,
): ((event: SyntheticEvent) => void) => {
    const [operatorId, setOperatorId] = useState<string>();

    useEffect(() => {
        getOperatorForUser().then((response) => {
            if (response.success) {
                setOperatorId(response.data.id);
            }
        });
    }, [setOperatorId]);

    let url: string;
    const baseUrl = `//${windowHost}/api/me/pages`;
    if (entityName === 'trip') {
        url = `${baseUrl}/trip?tripId=${entityId}&operatorId=${operatorId}&tripPreview=false`;
    } else {
        url = `${baseUrl}/itinerary?itineraryId=${entityId}&itineraryPreview=true`;
    }

    return () => {
        window.open(url, '_blank');
    };
};

export const PreviewEntityButton: React.FC<PreviewEntityButtonProps> = ({
    productName,
    entityId,
}) => {
    const onClick = useGetPreviewButtonFunction(productName, entityId);
    const capitalizedProductName =
        productName.charAt(0).toUpperCase() + productName.slice(1);

    return (
        <BaseBox alignSelf={{ base: 'left', md: 'center' }}>
            <Button variant='tertiary' rightIcon='eye' onClick={onClick}>
                Preview {capitalizedProductName}
            </Button>
        </BaseBox>
    );
};
