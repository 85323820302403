import { Heading } from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TripStatuses } from '../../../../../../config/constants';
import ContentForModelStatus from '../../../../../common/components/ContentForModelStatus';
import ShowDuringTimeRange from '../../../../../common/components/ShowDuringTimeRange';
import TripContactCard, {
    TripContactCardType,
} from '../../../../../common/components/TripContactCard';
import { getTripRelatedDates } from '../../../../../common/helpers/trip';
import useTripRoute, {
    TRIP_ACTION,
} from '../../../../../common/hooks/trips/useTripRoute';
import StickyContentSimpleAction from '../../../../../host/components/StickyContent/StickyContentSimpleAction';
import StickyContentSimpleNav from '../../../../../host/components/StickyContent/StickyContentSimpleNav';
import StickyContentTripPageLink from '../../../../../host/components/StickyContent/StickyContentTripPageLink';
import { StatefulModel } from '../../../../../types';

type WorkshopSpace = models.services.WorkshopSpace;
type TripStoreSelector = { userTrips: { current?: models.trips.Trip } };

interface ReviewTripTabStickyLayoutContentProps {
    scrollToDay: (dayIndex: number) => void;
}

const ReviewTripTabStickyLayoutContent = ({
    scrollToDay,
}: ReviewTripTabStickyLayoutContentProps): JSX.Element => {
    const model = StatefulModel.TRIP;

    const currentTrip = useSelector(
        (state: TripStoreSelector) => state.userTrips.current,
    );

    const routeToUrl = useTripRoute({
        action: TRIP_ACTION.SERVICES_AGREEMENT,
        id: currentTrip?.id,
        preserveQueryParams: true,
    });

    const firstUnfinishedDay = useMemo((): number => {
        if (!currentTrip || !currentTrip.servicesByDay) return -1;
        return currentTrip.servicesByDay?.findIndex((day) => {
            return day.some(
                (dayService) =>
                    typeof dayService.service !== 'string' &&
                    (dayService.service as WorkshopSpace)?.acceptedByHost !==
                        undefined &&
                    (dayService.service as WorkshopSpace)?.acceptedByHost !==
                        true,
            );
        });
    }, [currentTrip]);

    const { preTripDate, postTripDate } = getTripRelatedDates(
        currentTrip?.startDate ? new Date(currentTrip?.startDate) : undefined,
        currentTrip?.servicesByDay?.length,
    );
    const hasPreAndPostTripDates = !!preTripDate && !!postTripDate;

    return (
        <>
            <Heading as='h5' marginBottom={4}>
                How can we help?
            </Heading>
            <TripContactCard type={TripContactCardType.CUSTOMER_SERVICE} />
            {hasPreAndPostTripDates ? (
                <ShowDuringTimeRange start={preTripDate} end={postTripDate}>
                    <TripContactCard type={TripContactCardType.TRIP_SUPPORT} />
                </ShowDuringTimeRange>
            ) : null}
            <ContentForModelStatus
                model={model}
                matchingStatuses={[TripStatuses.TROVA_PRICING_APPROVED]}
                render={(): JSX.Element =>
                    firstUnfinishedDay > -1 ? (
                        <StickyContentSimpleAction
                            title='Next: Workshops'
                            description='Update your workshop descriptions for your travelers.'
                            buttonProps={{
                                onClick: () => scrollToDay(firstUnfinishedDay),
                                children: 'Configure Workshops',
                                rightIcon: 'arrow-right',
                            }}
                            marginTop={0}
                        />
                    ) : (
                        <StickyContentSimpleNav
                            title='Next: Agreement'
                            description='In order to finalize your trip, you need to accept the following service agreement.'
                            buttonText='Agreement'
                            routeToUrl={routeToUrl}
                            marginTop={0}
                        />
                    )
                }
            />
            <ContentForModelStatus
                model={model}
                matchingStatuses={[TripStatuses.HOST_APPROVED]}
                render={(): JSX.Element => (
                    <StickyContentTripPageLink
                        title={'Trip Page'}
                        description={
                            'Promote your trip by sharing this link with your audience!'
                        }
                        marginTop={0}
                    />
                )}
            />
        </>
    );
};

export default ReviewTripTabStickyLayoutContent;
