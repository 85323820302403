import { SummaryItemProps } from '@trova-trip/trova-components';
import { constants } from '@trova-trip/trova-models';
import { Dispatch } from 'redux';
import { BookingRepriceUpdate } from '../../../apis/types';
import { ManageBookingTabName } from '../../../applications/traveler/tabs/ManageBooking/config';
import {
    AddOnManageMode,
    BookingAddOn,
    ManageBookingAddOn,
    ManageBookingViewModel,
} from '../../../applications/traveler/tabs/ManageBooking/types';
import { Action, WithLoadingState } from '../../store/types';
import { UpdateBookingParams } from '../../userBookings';
import { validOccupancies } from './utils';
import { models } from '@trova-trip/trova-models';

const { ServiceTiming } = constants.services;

type ServiceTimingType = typeof ServiceTiming[keyof typeof ServiceTiming];

type ValidOccupanciesType = typeof validOccupancies[number];

type Inventory = models.trips.Inventory;
type AvailableInventoryQuantity = Pick<
    models.trips.InventoryQuantity,
    'available'
>;

export enum MANAGE_BOOKING_ACTION {
    INIT_VIEW_MODEL = 'INIT_VIEW_MODEL',
    RESET_STATE = 'RESET_STATE',
    SET_IS_LOADING = 'SET_IS_LOADING',
    TOGGLE_ACCOMMODATION = 'TOGGLE_ACCOMMODATION',
    TOGGLE_ACTIVITY = 'TOGGLE_ACTIVITY',
    TOGGLE_TRANSFER = 'TOGGLE_TRANSFER',
    UPDATE_ROOM_OCCUPANCIES = 'UPDATE_ROOM_OCCUPANCIES',
    TOGGLE_TRIP_SUMMARY_VISIBILITY = 'TOGGLE_TRIP_SUMMARY_VISIBILITY',
    UPDATE_TRIP_SUMMARY_SECTIONS = 'UPDATE_TRIP_SUMMARY_SECTIONS',
    SET_INSURANCE_ADJUSTMENT = 'SET_INSURANCE_ADJUSTMENT',
    TOGGLE_CONFIRMATION_MODAL = 'TOGGLE_CONFIRMATION_MODAL',
    SET_BOOKING_PAYMENT_DATA = 'SET_BOOKING_PAYMENT_DATA',
    SET_CONFIRMATION_DATA = 'SET_CONFIRMATION_DATA',
    SET_TRIP_SUMMARY_IS_LOADING = 'SET_TRIP_SUMMARY_IS_LOADING',
    SET_IS_MANAGE_BOOKING_FLOW_FINISHED = 'SET_IS_MANAGE_BOOKING_FLOW_FINISHED',
    SET_CANCEL_INSURANCE_ADDON_ID = 'SET_CANCEL_INSURANCE_ADDON_ID',
    CANCEL_INSURANCE = 'CANCEL_INSURANCE',
    SET_INSURANCE_DATA = 'SET_INSURANCE_DATA',
    SET_SINGLE_SUPPLEMENT_INVENTORY = 'SET_SINGLE_SUPPLEMENT_INVENTORY',
    VERIFY_SERVICE_INVENTORY = 'VERIFY_SERVICE_INVENTORY',
}

export enum ToggleActionType {
    ADD = 'add',
    REMOVE = 'remove',
}

type ToggleActionPayload = {
    actionType: ToggleActionType;
};

type ToggleAccommodationPayload = ToggleActionPayload & {
    timing: ServiceTimingType;
    transfersTimingsToUpdate?: ServiceTimingType[];
    isUpgradeToPrivateRoomSelected?: boolean;
    roomSelection?: ValidOccupanciesType[];
    manageMode?: AddOnManageMode;
};

type ToggleActivityPayload = ToggleActionPayload & {
    addOnId: string;
    travelerId: string;
};

type ToggleTransferPayload = ToggleActionPayload & {
    timing: ServiceTimingType;
    manageMode?: AddOnManageMode;
};

type OriginallyAvailableAccommodations = {
    [ServiceTiming.PRE_TRIP]: boolean;
    [ServiceTiming.POST_TRIP]: boolean;
};

type UpdateRoomOccupanciesPayload = {
    roomSelection: ValidOccupanciesType[];
    preAndPostRoomSelection: ValidOccupanciesType[];
    currentPreAndPostRoomSelection: ValidOccupanciesType[];
    timingsToUpdate: ServiceTimingType[];
    transfersTimingsToUpdate: ServiceTimingType[];
    isTravelersQuantityOdd: boolean;
    isUpgradeToPrivateRoomSelected: boolean;
    accommodationsWereOriginallyAvailable: OriginallyAvailableAccommodations;
};

type ResetRoomSelectionPayload = {
    originalState: ManageBookingState['originalState'];
};

type SetBookingPaymentDataPayload = {
    paidAmount?: number;
    dueAmount: number;
};

type SetConfirmationDataPayload = {
    error: ManageBookingError;
    isDisabled?: boolean;
};

type SetInsuranceAdjustmentPayload = {
    insuranceAdjustment: SummaryItem | null;
    repricedAddOns?: BookingAddOn[];
};

type SetInsuranceDataPayload = {
    status: InsuranceSectionStatus;
    error?: ManageBookingError;
};

type SetSingleSupplementInventoryPayload = {
    singleSupplement: AvailableServiceInventory['singleSupplement'];
};

type INIT_VIEW_MODEL = Action<
    MANAGE_BOOKING_ACTION.INIT_VIEW_MODEL,
    ManageBookingViewModel
>;

type RESET_STATE = Action<MANAGE_BOOKING_ACTION.RESET_STATE>;

type SET_IS_LOADING = Action<MANAGE_BOOKING_ACTION.SET_IS_LOADING, boolean>;

type TOGGLE_ACCOMMODATION = Action<
    MANAGE_BOOKING_ACTION.TOGGLE_ACCOMMODATION,
    ToggleAccommodationPayload
>;

type TOGGLE_ACTIVITY = Action<
    MANAGE_BOOKING_ACTION.TOGGLE_ACTIVITY,
    ToggleActivityPayload
>;

type TOGGLE_TRANSFER = Action<
    MANAGE_BOOKING_ACTION.TOGGLE_TRANSFER,
    ToggleTransferPayload
>;

type UPDATE_ROOM_OCCUPANCIES = Action<
    MANAGE_BOOKING_ACTION.UPDATE_ROOM_OCCUPANCIES,
    UpdateRoomOccupanciesPayload
>;

type TOGGLE_TRIP_SUMMARY_VISIBILITY = Action<
    MANAGE_BOOKING_ACTION.TOGGLE_TRIP_SUMMARY_VISIBILITY,
    boolean
>;

type UPDATE_TRIP_SUMMARY_SECTIONS =
    Action<MANAGE_BOOKING_ACTION.UPDATE_TRIP_SUMMARY_SECTIONS>;

type SET_INSURANCE_ADJUSTMENT = Action<
    MANAGE_BOOKING_ACTION.SET_INSURANCE_ADJUSTMENT,
    SetInsuranceAdjustmentPayload
>;

type TOGGLE_CONFIRMATION_MODAL = Action<
    MANAGE_BOOKING_ACTION.TOGGLE_CONFIRMATION_MODAL,
    boolean
>;

type SET_BOOKING_PAYMENT_DATA = Action<
    MANAGE_BOOKING_ACTION.SET_BOOKING_PAYMENT_DATA,
    SetBookingPaymentDataPayload
>;

type SET_CONFIRMATION_DATA = Action<
    MANAGE_BOOKING_ACTION.SET_CONFIRMATION_DATA,
    SetConfirmationDataPayload
>;

type SET_TRIP_SUMMARY_IS_LOADING = Action<
    MANAGE_BOOKING_ACTION.SET_TRIP_SUMMARY_IS_LOADING,
    boolean
>;

type SET_CANCEL_INSURANCE_ADDON_ID = Action<
    MANAGE_BOOKING_ACTION.SET_CANCEL_INSURANCE_ADDON_ID,
    SetCancelPopupOpenedIdPayload
>;

type CANCEL_INSURANCE = Action<MANAGE_BOOKING_ACTION.CANCEL_INSURANCE>;

type SET_INSURANCE_DATA = Action<
    MANAGE_BOOKING_ACTION.SET_INSURANCE_DATA,
    SetInsuranceDataPayload
>;

type VERIFY_SERVICE_INVENTORY =
    Action<MANAGE_BOOKING_ACTION.VERIFY_SERVICE_INVENTORY>;

type SET_SINGLE_SUPPLEMENT_INVENTORY = Action<
    MANAGE_BOOKING_ACTION.SET_SINGLE_SUPPLEMENT_INVENTORY,
    SetSingleSupplementInventoryPayload
>;

type SET_IS_MANAGE_BOOKING_FLOW_FINISHED =
    Action<MANAGE_BOOKING_ACTION.SET_IS_MANAGE_BOOKING_FLOW_FINISHED>;

type TripSummarySections = {
    added: ManageBookingAddOn[];
    removed: ManageBookingAddOn[];
    roomSelection: SummaryItem | null;
    priceAdjustments: SummaryItem[];
};

type ErrorType =
    | 'singleSupplementInventory'
    | 'insuranceReprice'
    | 'bookingUpdate';

type ManageBookingError = {
    title: string;
    description?: string;
    type?: ErrorType;
} | null;

type ConfirmationMode = 'negativeBalance' | 'zeroBalance' | 'refundNeeded';

type TripSummary = {
    isOpen: boolean;
    isConfirmationModalOpen: boolean;
    sections: TripSummarySections;
    insuranceAdjustment: SummaryItem | null;
    total: number;
    totalWithInsurance: number;
    bookingPaymentData: {
        paidAmount: number;
        dueAmount: number;
    };
    isLoading: boolean;
};

type SummaryItem = Pick<SummaryItemProps, 'description' | 'price'>;

type InsuranceSectionStatus = 'initial' | 'error' | 'cancelling' | 'cancelled';

type InsuranceSection = {
    cancelAddOnId: string | undefined;
    repricedAddOns: BookingAddOn[] | undefined;
    status: InsuranceSectionStatus;
    error?: ManageBookingError;
};

type SingleSupplementAvailability = {
    /**
     * Used to determine if the private room upgrade is available.
     * - This will be true if the single supplement supply is equal to or greater than the number of travelers.
     */
    isPrivateRoomUpgradeAvailable: boolean;
    /**
     * Used to determine if the private room is available.
     * - This will be true if the single supplement supply is greater than 0.
     */
    isPrivateRoomAvailable: boolean;
};

type ServiceInventoryStatus =
    | 'initial'
    | 'verifying'
    | 'populated'
    | 'needs_verification';

type AvailableServiceInventory = {
    [key in keyof Inventory]: AvailableInventoryQuantity;
};

type ServiceInventory = AvailableServiceInventory & {
    status: ServiceInventoryStatus;
};

type ManageBookingState = WithLoadingState &
    ManageBookingViewModel & {
        tripSummary: TripSummary;
        insurance: InsuranceSection;
        confirmationMode: ConfirmationMode | null;
        confirmation: {
            isDisabled: boolean;
            error: ManageBookingError;
        };
        originalState: ManageBookingViewModel;
        isManageBookingFlowFinished: boolean;
        serviceInventory: ServiceInventory;
    };

type ManageBookingActions =
    | INIT_VIEW_MODEL
    | RESET_STATE
    | SET_IS_LOADING
    | TOGGLE_ACCOMMODATION
    | TOGGLE_ACTIVITY
    | TOGGLE_TRANSFER
    | UPDATE_ROOM_OCCUPANCIES
    | TOGGLE_TRIP_SUMMARY_VISIBILITY
    | UPDATE_TRIP_SUMMARY_SECTIONS
    | SET_INSURANCE_ADJUSTMENT
    | TOGGLE_CONFIRMATION_MODAL
    | SET_BOOKING_PAYMENT_DATA
    | SET_CONFIRMATION_DATA
    | SET_TRIP_SUMMARY_IS_LOADING
    | SET_IS_MANAGE_BOOKING_FLOW_FINISHED
    | SET_CANCEL_INSURANCE_ADDON_ID
    | CANCEL_INSURANCE
    | SET_INSURANCE_DATA
    | VERIFY_SERVICE_INVENTORY
    | SET_SINGLE_SUPPLEMENT_INVENTORY;

type ActionCreatorReturnType = {
    initViewModel: (payload: INIT_VIEW_MODEL['payload']) => void;
    setIsLoading: (payload: SET_IS_LOADING['payload']) => void;
    openTripSummary: () => void;
    closeTripSummary: () => void;
    clearTripSummary: () => void;
    updateActivity: (payload: TOGGLE_ACTIVITY['payload']) => void;
    updateAccommodation: (payload: TOGGLE_ACCOMMODATION['payload']) => void;
    updateRoomSelection: (payload: UPDATE_ROOM_OCCUPANCIES['payload']) => void;
    updateTransfer: (payload: TOGGLE_TRANSFER['payload']) => void;
    resetRoomSelection: (payload: ResetRoomSelectionPayload) => void;
    openConfirmationModal: () => void;
    closeConfirmationModal: () => void;
    setBookingPaymentData: (
        payload: SET_BOOKING_PAYMENT_DATA['payload'],
    ) => void;
    processBookingUpdate: (payload: UpdateBookingPayload) => Promise<void>;
    processConfirmBooking: (payload: ConfirmBookingPayload) => Promise<void>;
    openCancelInsuranceModal: (payload: SetCancelPopupOpenedIdPayload) => void;
    closeCancelInsuranceModal: () => void;
    cancelInsurance: (payload: CancelInsurancePayload) => void;
    setIsManageBookingFlowFinished: () => void;
    verifySingleSupplementInventory: (
        payload: VerifySingleSupplementInventoryPayload,
    ) => void;
    resetInsuranceState: () => void;
    resetManageBookingState: () => void;
};

type ToggleAccommodationStatusParams = {
    state: ManageBookingState;
    travelersQuantity: ManageBookingState['travelersQuantity'];
    params: ToggleAccommodationPayload;
};

type ToggleActivitiesStatusParams = {
    activities: ManageBookingState['addOns']['activities'];
    params: ToggleActivityPayload;
};

type ToggleTransferStatusParams = {
    state: ManageBookingState;
    params: ToggleTransferPayload;
};

type UpdateRoomOccupanciesStatusParams = {
    state: ManageBookingState;
    payload: UpdateRoomOccupanciesPayload;
};

type UpdateSingleSupplementAddOnParams = {
    state: ManageBookingState;
    isUpgradeToPrivateRoomSelected: boolean;
};

type UpdateTravelersRoomsStatusParams = {
    state: ManageBookingState;
    roomSelection: ValidOccupanciesType[];
    isUpgradeToPrivateRoomSelected: boolean;
};

type OccupanciesFromRoomSelection = Record<ValidOccupanciesType, number>;

type PreAndPostTransfersAddOnPrice = {
    preTripPrice: number;
    postTripPrice: number;
};

type UpdateRoomSelectionComposedParams = {
    dispatch: Dispatch<ManageBookingActions>;
    payload: UPDATE_ROOM_OCCUPANCIES['payload'];
};

type UpdateBookingPayload = UpdateBookingParams & {
    bookingId: string;
    onSuccessNavigateTo: (to: ManageBookingTabName) => void;
    onSuccessTrackEvent?: (paidAmount: number) => void;
};

type InsuranceParams = {
    additionalParticipants: BookingRepriceUpdate['additionalParticipants'];
    travelAmount: BookingRepriceUpdate['travelAmount'];
    status: BookingRepriceUpdate['status'];
};

type ConfirmBookingPayload = Omit<
    UpdateBookingPayload,
    'payment' | 'trackModifyBookingEvent'
> & {
    confirmationMode: ConfirmationMode | null;
    /** Null means that there isn't insurance on the booking and we don't need to reprice insurance. */
    insuranceParams: InsuranceParams | null;
    /** If insurance repricing is needed, this will contain the add-ons with repriced insurance. */
    addOnsToRequoteInsurance: BookingAddOn[];
    singleSupplementAvailability: SingleSupplementAvailability;
    isSingleSupplementPendingAddition: boolean;
};

type RepriceInsurancePayload = Omit<UpdateBookingPayload, 'payment'> & {
    insuranceParams: InsuranceParams;
};

type CancelInsurancePayload = {
    bookingId: string | undefined;
    addOnId: string | undefined;
};

type SetCancelPopupOpenedIdPayload = {
    addOnId: CancelInsurancePayload['addOnId'];
};

type VerifySingleSupplementInventoryPayload = {
    state: ManageBookingState;
};

export type {
    Action,
    ActionCreatorReturnType,
    CANCEL_INSURANCE,
    CancelInsurancePayload,
    ConfirmBookingPayload,
    ConfirmationMode,
    INIT_VIEW_MODEL,
    InsuranceSectionStatus,
    ManageBookingActions,
    ManageBookingError,
    ManageBookingState,
    OccupanciesFromRoomSelection,
    PreAndPostTransfersAddOnPrice,
    RESET_STATE,
    RepriceInsurancePayload,
    ResetRoomSelectionPayload,
    SET_BOOKING_PAYMENT_DATA,
    SET_CANCEL_INSURANCE_ADDON_ID,
    SET_CONFIRMATION_DATA,
    SET_INSURANCE_ADJUSTMENT,
    SET_INSURANCE_DATA,
    SET_IS_LOADING,
    SET_IS_MANAGE_BOOKING_FLOW_FINISHED,
    SET_SINGLE_SUPPLEMENT_INVENTORY,
    SET_TRIP_SUMMARY_IS_LOADING,
    ServiceTimingType,
    ServiceInventoryStatus,
    SetCancelPopupOpenedIdPayload,
    SetInsuranceDataPayload,
    SingleSupplementAvailability,
    SummaryItem,
    TOGGLE_ACCOMMODATION,
    TOGGLE_ACTIVITY,
    TOGGLE_CONFIRMATION_MODAL,
    TOGGLE_TRANSFER,
    TOGGLE_TRIP_SUMMARY_VISIBILITY,
    ToggleAccommodationPayload,
    ToggleAccommodationStatusParams,
    ToggleActionPayload,
    ToggleActivitiesStatusParams,
    ToggleActivityPayload,
    ToggleTransferPayload,
    ToggleTransferStatusParams,
    TripSummarySections,
    UPDATE_ROOM_OCCUPANCIES,
    UPDATE_TRIP_SUMMARY_SECTIONS,
    UpdateBookingPayload,
    UpdateRoomOccupanciesStatusParams,
    UpdateRoomSelectionComposedParams,
    UpdateSingleSupplementAddOnParams,
    UpdateTravelersRoomsStatusParams,
    VERIFY_SERVICE_INVENTORY,
    ValidOccupanciesType,
    VerifySingleSupplementInventoryPayload,
    OriginallyAvailableAccommodations,
};
