import { combineReducers } from 'redux';
import manageBookingReducer from './features/manageBooking/reducer';
import transferReducer from './features/transfer/reducer';
import { loginReducer } from './authentication';
import { profileReducer } from './profile';
import { categoriesReducer } from './categories';
import { usersMetadataReducer, usersReducer } from './users';
import { itinerariesMetadataReducer, itinerariesReducer } from './itineraries';
import { defaultRecordReducer } from './defaultRecords';
import { tripsMetadataReducer, tripsReducer } from './trips';
import { operatorsMetadataReducer, operatorsReducer } from './operators';
import { userTripDetailsReducer, userTripsReducer } from './userTrips';
import { webhooksMetadataReducer, webhooksReducer } from './webhooks';
import {
    tripRequestsMetadataReducer,
    tripRequestsReducer,
} from './tripRequests';
import { userItineraryReducer } from './userItinerary';
import { userTripRequestsReducer } from './userTripRequests';
import {
    platformhooksMetadataReducer,
    platformhooksReducer,
} from './platformhooks';
import { bookingsMetadataReducer, bookingsReducer } from './bookings';
import { emailsMetadataReducer, emailsReducer } from './emails';
import { emailhooksMetadataReducer, emailhooksReducer } from './emailhooks';
import {
    currenciesMetadataReducer,
    currenciesReducer,
    userCurrenciesReducer,
} from './currencies';
import { servicesReducer } from './services';
import { libraryServicesReducer } from './library-services';
import { linksMetadataReducer, linksReducer, userLinksReducer } from './links';
import {
    travelersMetadataReducer,
    travelersReducer,
    userTravelersReducer,
} from './travelers';
import { listUsersMetadataReducer } from './listUsers';
import { userOperatorsReducer } from './userOperators';
import { userTripsTravelerReducer } from './tripsTraveler';
import { auditLogsMetadataReducer, auditLogsReducer } from './auditLogs';
import { hostsMetadataReducer, hostsReducer, userHostsReducer } from './hosts';
import {
    destinationsMetadataReducer,
    destinationsReducer,
} from './destinations';
import {
    itineraryRecommendationsReducer,
    itineraryRecommendationsMetadataReducer,
    userHostsRecommendationsReducer,
} from './itineraryRecommendations';
import { userBookingsReducer } from './userBookings';
import { userGroundTransfersReducer } from './userGroundTransfers';
import { ROOT_ACTION_TYPES } from './store/types';

const reducers = {
    // Models:
    login: loginReducer,
    profile: profileReducer,
    users: usersReducer,
    categories: categoriesReducer,
    usersMetadata: usersMetadataReducer,
    itineraries: itinerariesReducer,
    itinerariesMetadata: itinerariesMetadataReducer,
    defaultRecords: defaultRecordReducer,
    trips: tripsReducer,
    tripsMetadata: tripsMetadataReducer,
    operators: operatorsReducer,
    operatorsMetadata: operatorsMetadataReducer,
    userTrips: userTripsReducer,
    userTripDetails: userTripDetailsReducer,
    webhooks: webhooksReducer,
    webhooksMetadata: webhooksMetadataReducer,
    tripRequests: tripRequestsReducer,
    tripRequestsMetadata: tripRequestsMetadataReducer,
    userItinerary: userItineraryReducer,
    userTripRequests: userTripRequestsReducer,
    platformhooks: platformhooksReducer,
    platformhooksMetadata: platformhooksMetadataReducer,
    bookings: bookingsReducer,
    bookingsMetadata: bookingsMetadataReducer,
    emails: emailsReducer,
    emailsMetadata: emailsMetadataReducer,
    emailhooks: emailhooksReducer,
    emailhooksMetadata: emailhooksMetadataReducer,
    currencies: currenciesReducer,
    currenciesMetadata: currenciesMetadataReducer,
    userCurrencies: userCurrenciesReducer,
    services: servicesReducer,
    libraryServices: libraryServicesReducer,
    links: linksReducer,
    linksMetadata: linksMetadataReducer,
    userLinks: userLinksReducer,
    travelers: travelersReducer,
    travelersMetadata: travelersMetadataReducer,
    userTravelers: userTravelersReducer,
    listUsersMetadata: listUsersMetadataReducer,
    userOperators: userOperatorsReducer,
    userTripsTraveler: userTripsTravelerReducer,
    auditLogs: auditLogsReducer,
    auditLogsMetadata: auditLogsMetadataReducer,
    hosts: hostsReducer,
    hostsMetadata: hostsMetadataReducer,
    userHosts: userHostsReducer,
    destinations: destinationsReducer,
    destinationsMetadata: destinationsMetadataReducer,
    userBookings: userBookingsReducer,
    itineraryRecommendations: itineraryRecommendationsReducer,
    itineraryRecommendationsMetadata: itineraryRecommendationsMetadataReducer,
    userHostsRecommendations: userHostsRecommendationsReducer,
    userGroundTransfers: userGroundTransfersReducer,

    // Features:
    manageBooking: manageBookingReducer,
    transfer: transferReducer,
};

const reducer = combineReducers(reducers);

type RootReducerType = ReturnType<typeof reducer> | undefined;

const rootReducer = (state, action): RootReducerType => {
    if (action.type === ROOT_ACTION_TYPES.RESET) {
        state = undefined;
    }
    return reducer(state, action);
};

export default rootReducer;
