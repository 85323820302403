import FetchState from './utils/FetchState';
import { post, get, put, upload } from './utils/api';
import {
    HostAudienceData,
    HostImportantDates,
    HostUpcomingTrips,
    HostEarnings,
} from '../applications/host/products/home/common/types';
import {
    HostProfileRequest,
    HostProfileResponse,
} from '../applications/host/products/publicProfile/utils/types';
import { constants, models } from '@trova-trip/trova-models';
import { ApiResponse } from '@trova-trip/trova-common';

type Host = models.hosts.Host;
type HomeProgress = models.hosts.HostHomeProgressResponse;
export type HostProgressStepValues = constants.host.HostHomeProgress;
export type HostResponsesReview = models.hostHomeTab.ReviewResponses;
export type HostProgressSteps = models.common.ProgressStep<string, string>[];
export type HostItineraryRecommendations =
    models.itineraryRecommendations.ItineraryRecommendationOutput;

export interface HostApiResponse<T>
    extends Omit<ApiResponse, 'data' | 'error'> {
    data: T | undefined;
    error: string;
}

export type ServiceResponse<T> = Promise<HostApiResponse<T>>;

export type HostStoreSelector = {
    hosts: { current: Host; fetching?: boolean };
};

export type UserHostStoreSelector = {
    userHosts: {
        current: Host;
        fetching?: boolean;
    };
};

export type UserHostsRecommendations = {
    fetching?: boolean;
    list: HostItineraryRecommendations[];
};

interface ImageFile {
    date: string;
    extension: string;
    fieldName: string;
    file: string;
    format: string;
    name: string;
    oldname: string;
    oldtitle: string;
    replaced: boolean;
    size: number;
    size2: string;
    title: string;
    type: string;
    uploaded: boolean;
}

export interface ImageDataResponse {
    data: {
        files: ImageFile[];
        isSuccess: boolean;
        url: string;
    };
    success: boolean;
}

const hosts = FetchState.create(`api/admin/hosts`);
const hostsReducer = hosts.getReducer();

const userHosts = FetchState.create(`api/me/host/user`);
const userHostsReducer = userHosts.getReducer();

const hostsMetadata = FetchState.create(`api/metadata/hosts`);
const hostsMetadataReducer = hostsMetadata.getReducer();

const saveHost = (host) => post(`api/me/host/`, host);

const updateHost = (hostId: string, host: Partial<Host>) =>
    put('api/me/host', hostId, host);

const onboardHost = (host: Partial<Host>): ServiceResponse<void> =>
    post(`api/me/host/onboard`, host) as ServiceResponse<void>;

const getHostUpcomingTrips = (hostId: string) =>
    get(
        `api/me/host/${hostId}/upcoming-trips`,
    ) as ServiceResponse<HostUpcomingTrips>;

const getHostPublicProfile = (hostId: string) =>
    get(
        `api/me/host/${hostId}/public-profile`,
    ) as ServiceResponse<HostProfileResponse>;

const saveHostPublicProfile = (hostId: string, data: HostProfileRequest) =>
    post(
        `api/me/host/${hostId}/public-profile`,
        data,
    ) as ServiceResponse<HostProfileResponse>;

const getHostEarnings = (hostId: string) =>
    get(`api/me/host/${hostId}/earnings`) as ServiceResponse<HostEarnings>;

const getHostImportantDates = (hostId: string) =>
    get(
        `api/me/host/${hostId}/important-dates`,
    ) as ServiceResponse<HostImportantDates>;

const getHostAudienceData = (hostId: string) =>
    get(`api/me/host/${hostId}/audience`) as ServiceResponse<HostAudienceData>;

const getHostProgress = (hostId: string) =>
    get(`api/me/host/${hostId}/home-progress`) as ServiceResponse<HomeProgress>;

const updateHostProgress = (
    hostId: string,
    stepValue: { homeProgress: HostProgressStepValues | undefined },
) =>
    put(
        `api/me/host/${hostId}/home-progress`,
        '',
        stepValue,
    ) as ServiceResponse<HomeProgress>;

const getProgressResponsesReview = (hostId: string) =>
    get(
        `api/me/host/${hostId}/review-responses`,
    ) as ServiceResponse<HostResponsesReview>;

const getHostByUserId = (hostId: string) =>
    get(`api/admin/hosts/user/${hostId}`) as ServiceResponse<Host>;

const postHostAudienceDataExport = (isExtended: boolean) => {
    const extendedQueryParam = isExtended ? `?extended=${isExtended}` : '';
    return post(`api/me/audience/export${extendedQueryParam}`, undefined);
};

const saveProfilePicture = (fieldName: string, imageFile: File) => {
    return upload(
        `api/me/files/new-upload-image`,
        { [fieldName]: imageFile },
        {
            type: 'upload',
            fieldName,
        },
        '',
        { 'Content-Type': 'multipart/form-data' },
    );
};

export {
    hosts,
    hostsReducer,
    hostsMetadata,
    hostsMetadataReducer,
    saveHost,
    onboardHost,
    getHostAudienceData,
    getHostEarnings,
    getHostImportantDates,
    getHostUpcomingTrips,
    getHostProgress,
    updateHostProgress,
    getProgressResponsesReview,
    userHosts,
    userHostsReducer,
    getHostByUserId,
    postHostAudienceDataExport,
    getHostPublicProfile,
    saveHostPublicProfile,
    saveProfilePicture,
    updateHost,
};
