import { coreUtils } from '@trova-trip/trova-common';
import { Skeleton, Stack, StackProps } from '@trova-trip/trova-components';
import IframeResizer from 'iframe-resizer-react';
import { useEffect, useState } from 'react';
import {
    PaymentPostMessageType,
    PaymentSuccessPostMessage,
    initializePaymentPostMessenger,
} from '../helpers/payment';

type PaymentsAppUrlParams = coreUtils.bookingUtils.PaymentsAppURLParams;

const { getPaymentsAppURL } = coreUtils.bookingUtils;

interface PaymentsAppProps {
    paymentUrlParams: PaymentsAppUrlParams;
    onSuccess: (data: PaymentSuccessPostMessage['data']) => void;
    onLoad?: () => void;
    minHeight?: StackProps['height'];
    loaderPadding?: StackProps['padding'];
}

const PaymentsApp = ({
    paymentUrlParams,
    onSuccess,
    onLoad,
    minHeight = { base: '80vh', md: '50vh' },
    loaderPadding,
}: PaymentsAppProps): JSX.Element | null => {
    const [isLoading, setIsLoading] = useState(true);

    const paymentUrl = getPaymentsAppURL(paymentUrlParams);
    const pm = initializePaymentPostMessenger();

    useEffect(() => {
        const successListener = pm.on(
            PaymentPostMessageType.PaymentSuccess,
            ({ data }) => {
                onSuccess(data);
            },
        );

        return () => successListener.cancel();
    }, []);

    if (!paymentUrlParams.tripId || !paymentUrlParams.orderId) {
        return null;
    }

    return (
        <Stack
            direction='column'
            spacing={0}
            width='full'
            minHeight={minHeight}
        >
            {isLoading ? (
                <Stack width='full' padding={loaderPadding}>
                    <Skeleton
                        borderRadius='md'
                        width='full'
                        height={minHeight}
                    />
                </Stack>
            ) : null}
            <IframeResizer
                src={paymentUrl}
                style={{
                    minWidth: '100%',
                    display: isLoading ? 'none' : 'block',
                }}
                onInit={() => {
                    setIsLoading(false);

                    if (onLoad) onLoad();
                }}
            />
        </Stack>
    );
};

export default PaymentsApp;
