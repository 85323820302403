import { useState } from 'react';
import { postHostAudienceDataExport } from '../../../../../state/hosts';
import { useTrackingEvent } from '../../../../../analytics/hooks';

export enum DownloadStatus {
    SUCCESS = 'success',
    FAILURE = 'failure',
    PENDING = 'pending',
    NULL = 0,
}

type DownloadAudienceDataStatus =
    typeof DownloadStatus[keyof typeof DownloadStatus];

interface UseAudienceDataExportReturnProps {
    isExtendedDownloadData: boolean;
    requestStatus: DownloadAudienceDataStatus;
    toggleExtendDownloadDate: () => void;
    exportDataClickHandler: () => Promise<void>;
    resetDownloadDataState: () => void;
}

const analyticsData = {
    eventName: 'Host included survey responses in audience data download',
};

const exportHostAudienceData = async (
    isExtended: boolean,
): Promise<{ success: boolean }> => {
    const response = await postHostAudienceDataExport(isExtended);
    return { success: !!response?.success };
};

const useAudienceDataExport = (): UseAudienceDataExportReturnProps => {
    const [isExtendedDownloadData, setExtendDownloadData] =
        useState<boolean>(false);

    const [requestStatus, setRequestStatus] =
        useState<DownloadAudienceDataStatus>(DownloadStatus.NULL);

    const { trackUserEvent } = useTrackingEvent();

    const resetDownloadDataState = () => {
        setExtendDownloadData(false);
        setRequestStatus(DownloadStatus.NULL);
    };

    const toggleExtendDownloadDate = () => {
        setExtendDownloadData((prevState) => !prevState);
    };

    const exportDataClickHandler = async () => {
        setRequestStatus(DownloadStatus.PENDING);
        try {
            if (isExtendedDownloadData) {
                trackUserEvent(analyticsData);
            }
            const isRequestSuccessful = await exportHostAudienceData(
                isExtendedDownloadData,
            );
            if (isRequestSuccessful) {
                setRequestStatus(DownloadStatus.SUCCESS);
            }
        } catch (e) {
            console.error('error downloading data', e);
            setRequestStatus(DownloadStatus.FAILURE);
        }
    };

    return {
        isExtendedDownloadData,
        toggleExtendDownloadDate,
        resetDownloadDataState,
        requestStatus,
        exportDataClickHandler,
    };
};

export default useAudienceDataExport;
