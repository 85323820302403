import {
    Card,
    Heading,
    StatusIndicator,
    Text,
    theme,
    BaseBox,
} from '@trova-trip/trova-components';
import { styledSvgDot, styledCard } from './BookingsTopCards.styles';

interface InfoCardProps {
    title: string;
    value: string;
    color?: string;
}

const InfoCard = ({ title, value, color }: InfoCardProps): JSX.Element => {
    return (
        <Card
            className={styledCard}
            backgroundColor={theme.colors.neutral.white}
        >
            <Text fontWeight={'bold'}>{title}</Text>
            <BaseBox marginY={4}>
                <Heading as={'h2'}>{value}</Heading>
            </BaseBox>
            {color && (
                <StatusIndicator className={styledSvgDot} color={color} />
            )}
        </Card>
    );
};

export default InfoCard;
