import {
    BaseBox,
    InlineSelect,
    InlineSelectOption,
    Stack,
    Text,
    useFormSaver,
    useToast,
} from '@trova-trip/trova-components';
import {
    Button,
    DatePicker,
} from '@trova-trip/trova-components/build/next/index';
import { models } from '@trova-trip/trova-models';
import {
    createTimeDropdownOptions,
    logError,
} from '../../../../../../../common/helpers';
import useTripRoute, {
    TRIP_ACTION,
} from '../../../../../../../common/hooks/trips/useTripRoute';
import {
    calculateDisabledDates,
    createLaunchDate,
    launchDateSchemaShape,
} from '../../../../../../../host/utils';

import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'state/hooks';
import { userTrips } from 'state/userTrips';
import * as yup from 'yup';
import img from '../../../../../../../../assets/img/undraw/calendar-check.png';
import { AnimatedImage, NextButton } from '../../shared';
import FormControl from '../../shared/FormControl';
import BaseContent from '../BaseContent';
import TimePickerLabel from './TimePickerLabel';
import { useTrackingEvent } from '../../../../../../../../analytics/hooks';

type Trip = models.trips.Trip;

const schema = yup
    .object()
    .shape(launchDateSchemaShape, [['launchDate', 'launchTime']]);

const LaunchDateContent = (): JSX.Element => {
    const currentTrip = useSelector((state) => state.userTrips.current);
    const { updateRecord: updateTrip } =
        userTrips.useDispatch.bind(currentTrip)();

    const toast = useToast();
    const timeOptions = useMemo(() => createTimeDropdownOptions(0, 10), []);

    const history = useHistory();
    const promoteUrl = useTripRoute({
        id: currentTrip?.id,
        action: TRIP_ACTION.PROMOTE,
        preserveQueryParams: true,
    });

    const [isLoading, setIsLoading] = useState(false);

    const disabledDates = calculateDisabledDates(currentTrip as Trip);

    const { trackUserEvent } = useTrackingEvent();

    const handleSubmitLaunchDate = async (data): Promise<void> => {
        if (!currentTrip) return;

        const { id } = currentTrip;
        const { launchDate, launchTime } = data;

        const launchISODate: Date | null = createLaunchDate(
            launchDate as string,
            launchTime as string,
        );

        const payload: Pick<
            Trip,
            // @ts-ignore
            'launchDate' | 'hasHostCompletedPromoteTutorial'
        > = {
            ...(launchISODate && { launchDate: launchISODate }),
            hasHostCompletedPromoteTutorial: true,
        };

        setIsLoading(true);
        try {
            await updateTrip(id, payload);
            toast({
                title: 'Trip launch scheduled',
                description: 'Your launch date was scheduled successfully.',
                status: 'success',
                isClosable: true,
            });
            navigateToPromoteTab();
            trackUserEvent({
                eventName: 'Host Scheduled Launch (Stepper)',
                properties: {
                    tripId: currentTrip?.id,
                    scheduledDateTime: launchISODate,
                },
            });
        } catch (error) {
            handleErrorSubmit(error.message);
        }
        setIsLoading(false);
    };

    const handleSkipLaunchDate = async (): Promise<void> => {
        if (!currentTrip) return;

        const { id } = currentTrip;
        // @ts-ignore
        const payload: Pick<Trip, 'hasHostCompletedPromoteTutorial'> = {
            hasHostCompletedPromoteTutorial: true,
        };

        setIsLoading(true);
        try {
            await updateTrip(id, payload);
            navigateToPromoteTab();
            trackUserEvent({
                eventName: ' Host Skipped Launch Scheduling',
                properties: {
                    tripId: currentTrip?.id,
                },
            });
        } catch (error) {
            handleErrorSubmit(error.message);
        }
        setIsLoading(false);
    };

    const handleErrorSubmit = (error): void => {
        toast({
            title: 'Something went wrong',
            description: error,
            status: 'error',
            isClosable: true,
        });
        logError(error);
    };

    const navigateToPromoteTab = (): void => {
        history.push(promoteUrl);
    };

    const formSaver = useFormSaver({
        onSubmit: handleSubmitLaunchDate,
        validationSchema: {
            schema,
            validateFieldOnChange: false,
        },
        initialValues: {
            launchDate: '',
            launchTime: '',
        },
    });

    const canSubmitForm = (): boolean => {
        const launchDateFormValue: string = formSaver.formValues.get
            .launchDate as string;
        const launchTimeFormValue: string = formSaver.formValues.get
            .launchTime as string;

        const isFormDirty = formSaver.isFormDirty;
        const isFormValid = schema.isValidSync({
            launchDate: launchDateFormValue,
            launchTime: launchTimeFormValue,
        });

        return isFormDirty && isFormValid;
    };

    return (
        <BaseContent
            title='Trip Page Launch Date'
            leftContent={
                <AnimatedImage
                    src={img}
                    alt='Trip Launch Date'
                    marginLeft={{ xl: 16 }}
                    maxWidth={{
                        base: '10.5rem',
                        md: '21.25rem',
                        xl: '33.75rem',
                    }}
                />
            }
            rightContent={
                <BaseBox marginBottom={{ base: 6, xl: 0 }}>
                    <Stack
                        direction='column'
                        spacing={6}
                        marginRight={{ xl: 28 }}
                    >
                        <Text>
                            Choose the date and time to launch your trip page
                            and promotional email. Your trip will only become
                            bookable after launch.
                        </Text>
                        <Stack
                            direction={{ base: 'column', sm: 'row' }}
                            spacing={{ base: 5, sm: 4 }}
                            align={{ base: 'center', sm: 'stretch' }}
                            justify={{ sm: 'center', xl: 'stretch' }}
                            width={{ base: '100%' }}
                        >
                            <BaseBox marginTop='.125rem'>
                                <DatePicker
                                    name='launchDate'
                                    input={false}
                                    dateTimeInputProps={{
                                        selectableDates: {
                                            dates: disabledDates,
                                            isSelectable: false,
                                        },
                                    }}
                                    value={
                                        formSaver.formValues.get
                                            .launchDate as string
                                    }
                                    onChange={(_, __, value): void => {
                                        formSaver.formValues.set.launchDate(
                                            value as string,
                                        );
                                    }}
                                />
                            </BaseBox>
                            <FormControl
                                label={<TimePickerLabel />}
                                field={
                                    <InlineSelect
                                        name='launchTime'
                                        width={{
                                            base: '15.625rem',
                                            sm: '8rem',
                                        }}
                                        optionsToShow={7}
                                        value={
                                            formSaver.formValues.get
                                                .launchTime as string
                                        }
                                        onChange={
                                            formSaver.formValues.set.launchTime
                                        }
                                    >
                                        {timeOptions.map(({ value, label }) => (
                                            <InlineSelectOption
                                                key={value}
                                                value={value}
                                            >
                                                <Text
                                                    as='p'
                                                    textAlign='center'
                                                    textTransform='lowercase'
                                                >
                                                    {label}
                                                </Text>
                                            </InlineSelectOption>
                                        ))}
                                    </InlineSelect>
                                }
                            />
                        </Stack>
                    </Stack>
                </BaseBox>
            }
            footerContent={
                <>
                    <Button
                        variant='tertiary'
                        onClick={handleSkipLaunchDate}
                        isDisabled={isLoading}
                    >
                        Schedule Later
                    </Button>
                    <NextButton
                        isDisabled={!canSubmitForm() || isLoading}
                        onClick={(): void =>
                            formSaver.handleFormSubmit(undefined)
                        }
                    >
                        Schedule Launch
                    </NextButton>
                </>
            }
        />
    );
};

export default LaunchDateContent;
