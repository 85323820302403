import { coreUtils } from '@trova-trip/trova-common';
import { constants } from '@trova-trip/trova-models';

const FieldPrefix = 'inventoryItem' as const;

enum FieldName {
    Id = 'id',
    Itinerary = 'itinerary',
    StartDate = 'startDate',
    QtyAvailable = 'quantityAvailable',
    PackageLevel = 'packageLevel',
    SsPrice = 'singleSupplementPrice',
    SsQtyAvailable = 'singleSupplementQuantityAvailable',
    PreTransferPrice = 'preTransferPrice',
    PostTransferPrice = 'postTransferPrice',
    PreAccPrice = 'preAccommodationPrice',
    PostAccPrice = 'postAccommodationPrice',
    CostSchedule = 'costSchedule',
}

const { AccommodationOccupancy, ServiceTiming } = constants.services;
const { PackageLevel } = constants.itinerary;

const { generateShortId } = coreUtils.idGeneratorUtils;

const DefaultSSQuantity = 10;

export {
    AccommodationOccupancy,
    DefaultSSQuantity,
    FieldName,
    FieldPrefix,
    generateShortId,
    PackageLevel,
    ServiceTiming,
};
