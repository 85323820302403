import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';

const applicationTitles = new Map<string, string>([
    ['operator', 'Operator Portal'],
    ['host', 'Host Portal'],
    ['traveler', 'Traveler Portal'],
    ['login', 'Login'],
    ['signup', 'Signup'],
]);

const useApplicationTitle = (retainOnUnmount = false): void => {
    const { location } = useHistory();

    const defaultTitle = useRef(document.title);

    const [, application, applicationType] = location.pathname.split('/');

    const applicationName = applicationTitles.get(
        application === 'app' ? applicationType : application,
    );

    const applicationTitle = `TrovaTrip - ${applicationName}`;

    useEffect(() => {
        document.title = applicationTitle;
    }, [applicationTitle]);

    useEffect(() => {
        return (): void => {
            if (!retainOnUnmount) {
                document.title = defaultTitle.current;
            }
        };
    }, [retainOnUnmount]);
};

export default useApplicationTitle;
