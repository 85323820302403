import styled from '@emotion/styled';
import { theme } from '@trova-trip/trova-components';

export const StyledDetailsContainer = styled.div`
    background: #fff;
    margin-top: ${theme.space[8]};
`;

export const StyledTierInfoContainer = styled.div`
    position: relative;
    padding: ${theme.space[4]};
    padding-bottom: 0;
    margin: 0 auto;

    @media (min-width: ${theme.breakpoints.md}) {
        max-width: 750px;
        padding-top: ${theme.space[8]};
        padding-left: ${theme.space[8]};
        padding-right: ${theme.space[8]};
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        max-width: 980px;
        padding-top: ${theme.space[12]};
    }

    @media (min-width: ${theme.breakpoints.xl}) {
        max-width: 1415px;
        padding-top: ${theme.space[12]};
    }
`;
