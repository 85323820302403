import { BaseBox, Grid, Hide } from '@trova-trip/trova-components';
import React from 'react';
import WrapperImage from './WrapperImage';

interface OnboardingWrapperProps {
    showMobileImage: boolean;
    children: React.ReactNode;
}

const OnboardingWrapper = (props: OnboardingWrapperProps) => {
    const { children, showMobileImage } = props;
    return (
        <Grid
            justifyItems='center'
            alignItems={{ md: 'center' }}
            minHeight='100vh'
            maxWidth='1440px'
            marginX='auto'
            paddingX={{ md: 6 }}
        >
            <Grid.Item
                width='full'
                columnSpan={{ base: 12, md: 6 }}
                position='relative'
            >
                <WrapperImage showMobileImage={showMobileImage} />
                <Hide breakpoint={{ min: 'md' }}>
                    <BaseBox paddingX={4} marginTop={8}>
                        {children}
                    </BaseBox>
                </Hide>
            </Grid.Item>
            <Hide breakpoint={{ max: 'md' }}>
                <Grid.Item
                    width='full'
                    marginLeft={{ base: 0, md: 8 }}
                    marginTop={{ base: 0, md: 8 }}
                    position='relative'
                    columnSpan={{ base: 12, md: 6 }}
                >
                    {children}
                </Grid.Item>
            </Hide>
        </Grid>
    );
};

export default OnboardingWrapper;
