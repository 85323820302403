import { coreUtils } from '@trova-trip/trova-common';
import { theme } from '@trova-trip/trova-components';
import { constants, models } from '@trova-trip/trova-models';
import { TravelerTripStatuses, TripStatuses } from '../../config/constants';
import { applicationRootPath } from '../../navigation/NavigationContext';
import {
    TRAVELER_APP_PRODUCT_ROUTE,
    TRAVELER_APP_ROUTE,
    TRAVELER_APP_TAB_ROUTE,
} from '../common/constants/routesTokens';
import { getBookingStatusFromTripStatus } from '../common/helpers/common';
import { isPrimaryTraveler } from '../common/helpers/user';
import {
    BaseUser,
    BookingAddOn,
    BookingWithTrip,
    SavedBooking,
} from './tabs/ManageBooking/types';

const {
    insuranceUtils: { isInsurancePolicyAddOnResponse },
} = coreUtils;

type BookingStatus = constants.bookings.BookingStatuses;
type InsurancePolicyAddOnResponse =
    models.insurance.InsurancePolicyAddOnResponse;
type TravelerStatus = constants.travelers.TravelerStatus;
type Trip = models.trips.Trip;

const { BookingStatuses } = constants.bookings;
const TravelerStatuses = constants.travelers.TravelerStatus;

interface MessagePayload {
    message: string | string[];
    color: string;
    status: string;
    spots: number;
}

interface TravelerPathParams {
    tripId?: string;
}

const getMessageFromTrip = (trip: Trip): MessagePayload => {
    const spots = 1;
    const { minimumSpots, status } = trip;

    switch (trip.status) {
        case TripStatuses.COMPLETE: {
            return {
                message: 'The trip was completed!',
                color: theme.colors.blueGray[650],
                status: TripStatuses.COMPLETE,
                spots,
            };
        }
        case TripStatuses.TROVA_PRICING_APPROVED:
        case TripStatuses.HOST_APPROVED:
        case TripStatuses.LIVE:
        case TripStatuses.READY_TO_CONFIRM: {
            return {
                message: `The trip will be confirmed when ${minimumSpots} people book.`,
                color: theme.colors.orange[900],
                status: TravelerTripStatuses.PENDING,
                spots,
            };
        }

        case TripStatuses.CONFIRMED:
        case TripStatuses.CLOSED: {
            return {
                message: 'The trip is officially happening!',
                color: theme.colors.seaGreen.trova,
                status: TripStatuses.CONFIRMED,
                spots,
            };
        }
        case TripStatuses.CANCELLED: {
            return {
                message: 'The has been canceled',
                color: theme.colors.blueGray[650],
                status: TripStatuses.CANCELLED,
                spots,
            };
        }
        default: {
            return {
                message: `unknown status`,
                color: theme.colors.blueGray[650],
                status,
                spots,
            };
        }
    }
};

const getMessageFromTripWithBooking = (trip: any): MessagePayload => {
    const { booking } = trip;
    const spots = booking && 1 + booking?.additionalParticipants?.length;

    if (booking.status === BookingStatuses.CANCELLED) {
        return createMessagePayload(
            theme.colors.blueGray[650],
            BookingStatuses.CANCELLED,
            spots,
            trip.minimumSpots,
        );
    }

    if (trip.status === TripStatuses.COMPLETE) {
        return {
            message: 'The trip was completed!',
            color: theme.colors.blueGray[650],
            status: TravelerTripStatuses.COMPLETE,
            spots,
        };
    }

    switch (booking.status) {
        case BookingStatuses.CONFIRMED:
            return createMessagePayload(
                theme.colors.seaGreen.trova,
                TravelerTripStatuses.CONFIRMED,
                spots,
                trip.minimumSpots,
            );
        case BookingStatuses.PENDING:
            return createMessagePayload(
                theme.colors.orange[900],
                BookingStatuses.PENDING,
                spots,
                trip.minimumSpots,
            );
        case BookingStatuses.WAITLIST:
            return createMessagePayload(
                theme.colors.purple[400],
                BookingStatuses.WAITLIST,
                spots,
                trip.minimumSpots,
            );
        case BookingStatuses.AWAITING:
            return createMessagePayload(
                theme.colors.orange[900],
                BookingStatuses.AWAITING,
                spots,
                trip.minimumSpots,
            );
        default:
            return createMessagePayload(
                theme.colors.blueGray[650],
                booking.status,
                spots,
                trip.minimumSpots,
            );
    }
};

const createMessagePayload = (
    color: string,
    status: string,
    spots: number,
    minimumSpots: number,
): MessagePayload => ({
    message: getBookingStatusMessage(status, minimumSpots),
    color,
    status,
    spots,
});

const getBookingStatusMessage = (
    status: string,
    minimumSpots: number,
): string | string[] => {
    switch (status) {
        case BookingStatuses.CONFIRMED:
            return 'The trip is officially happening!';
        case BookingStatuses.CANCELLED:
            return 'Either you or the host cancelled the trip.';
        case BookingStatuses.PENDING:
            return `The trip will be confirmed when ${minimumSpots} people book.`;
        case BookingStatuses.WAITLIST:
            return 'You are on the waitlist. You will be contacted if someone drops out.';
        case BookingStatuses.AWAITING:
            return 'You are up on the waitlist! Send payment to secure your spot.';
        default:
            return `unknown status.`;
    }
};

/**
 * Filter insurance add-ons from booking.
 * - If `excludeDeleted` is true, it will return only the insurance add-ons that are not deleted.
 *
 * @param booking
 * @param excludeDeleted
 * @returns An array of insurance add-ons.
 */
const filterInsuranceAddOns = (
    booking: BookingWithTrip | SavedBooking,
    excludeDeleted?: boolean,
): InsurancePolicyAddOnResponse[] => {
    const insuranceAddOns =
        booking.addOns?.filter((addOn): addOn is InsurancePolicyAddOnResponse =>
            isInsurancePolicyAddOnResponse(addOn),
        ) || [];

    if (excludeDeleted) {
        return insuranceAddOns.filter((addOn) => !addOn.deleted);
    }

    return insuranceAddOns;
};

/**
 * Get insurance add-ons by user.
 * - If the user is the primary traveler, return all insurance add-ons.
 * - If the user is not the primary traveler, return the insurance add-ons for that user.
 *
 * @param insuranceAddOns
 * @param user
 * @param userType
 * @returns An array of insurance add-ons for the user.
 */
const getInsuranceAddOnsByUser = (
    insuranceAddOns: BookingAddOn[],
    user: BaseUser | undefined,
    userType: string | undefined,
): InsurancePolicyAddOnResponse[] | undefined => {
    if (!insuranceAddOns || !userType || !user) return;

    if (isPrimaryTraveler(userType)) {
        return insuranceAddOns as InsurancePolicyAddOnResponse[];
    }

    const userInsuranceAddOn = insuranceAddOns.filter(
        (addOn) => addOn.user === user.id,
    );

    return userInsuranceAddOn as InsurancePolicyAddOnResponse[];
};

/**
 * Get traveler's path based on product and tab.
 *
 * @param product - The product route.
 * @param tab - The tab route.
 * @param params - Parameters for the traveler route.
 * @returns {string} - The generated path for the traveler.
 */
const getTravelerPathByProductAndTab = (
    product: keyof typeof TRAVELER_APP_PRODUCT_ROUTE,
    tab: keyof typeof TRAVELER_APP_TAB_ROUTE,
    params?: TravelerPathParams,
): string => {
    const baseRoute = `${applicationRootPath}${TRAVELER_APP_ROUTE}`;
    const productRoute = TRAVELER_APP_PRODUCT_ROUTE[product];
    const tabRoute = TRAVELER_APP_TAB_ROUTE[tab];

    const tripId = params?.tripId || ':id';

    const productPath =
        product === 'TRIPS' ? `${productRoute}/${tripId}` : productRoute;

    return `${baseRoute}${productPath}${tabRoute}`;
};

/**
 * Calculates the navigation category status based on the trip, the booking and the traveler status.
 *
 * @param trip
 * @param booking
 * @param travelerStatus
 * @returns {BookingStatus} The navigation category status for the trip, which matches the booking status, corresponding to a category on the sidebar or undefined.
 */
const getNavigationCategoryStatus = (
    trip: Trip,
    booking: SavedBooking | undefined,
    travelerStatus: TravelerStatus = TravelerStatuses.CREATED,
): BookingStatus | undefined => {
    const tripStatus = trip.status;
    const bookingStatus = coreUtils.bookingUtils.getBookingStatus(booking);
    const isTripCompleted =
        tripStatus === TripStatuses.COMPLETE &&
        bookingStatus === BookingStatuses.CONFIRMED;

    if (travelerStatus === TravelerStatuses.CANCELLED) {
        return BookingStatuses.CANCELLED;
    }

    if (bookingStatus) {
        return isTripCompleted ? BookingStatuses.COMPLETE : bookingStatus;
    }

    return getBookingStatusFromTripStatus(trip.status) as BookingStatus;
};

export {
    filterInsuranceAddOns,
    getInsuranceAddOnsByUser,
    getMessageFromTrip,
    getMessageFromTripWithBooking,
    getNavigationCategoryStatus,
    getTravelerPathByProductAndTab,
};
