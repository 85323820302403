import React from 'react';
import { Text, Stack, theme } from '@trova-trip/trova-components';
import { Button, ButtonProps } from '@trova-trip/trova-components/build/next';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const defaultState = css`
    background: ${theme.colors.blueGray[200]};

    &:hover,
    &:active {
        background: ${theme.colors.blueGray[300]};
    }
`;

const StyledButton = styled(Button)<MultilineButtonProps>`
    align-items: flex-start;
    flex-direction: column;
    height: auto;
    min-height: 3.375rem;

    ${(props): string | false => !props.isSelected && defaultState.styles};
`;

interface MultilineButtonProps extends ButtonProps {
    subtext?: React.ReactNode;
    isSelected?: boolean;
}

const MultilineButton = (props: MultilineButtonProps): JSX.Element => {
    const { children, subtext, isSelected, ...rest } = props;
    return (
        <StyledButton
            isSelected={isSelected}
            paddingX={4}
            paddingY={2}
            {...rest}
        >
            <Stack direction='column' spacing={0}>
                <Text
                    lineHeight='5'
                    fontWeight='medium'
                    textTransform='uppercase'
                    color={isSelected ? 'neutral.white' : 'blueGray.650'}
                >
                    {children}
                </Text>
                {subtext && (
                    <Text
                        lineHeight='5'
                        fontSize='xs'
                        color={isSelected ? 'neutral.white' : 'blueGray.500'}
                    >
                        {subtext}
                    </Text>
                )}
            </Stack>
        </StyledButton>
    );
};

export default MultilineButton;
