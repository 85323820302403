import {
    Grid,
    Hide,
    Modal,
    ModalProps,
    Stack,
    ToastProps,
    useFormSaver,
    useToast,
} from '@trova-trip/trova-components';
import { Card } from '@trova-trip/trova-components/build/next';
import { models } from '@trova-trip/trova-models';
import { usePreviewEmail } from 'applications/host/hooks/usePreviewEmail';
import { Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'state/hooks';
import { userTripDetails, userTrips } from 'state/userTrips';
import { Footer, Form, Header, Preview } from './contents';
import styles from './EditEmailModal.module.scss';
import {
    buildPromoteEmailsWithEditedEmail,
    findCurrentDripEmail,
} from './utils';

type EditEmailModalProps = {
    isOpen: ModalProps['isOpen'];
    emailCampaignName?: string;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
};

type DripEmail = models.common.DripEmail;

export enum FieldNames {
    HOST_BLURB = 'hostBlurb',
    ACTIVE = 'active',
}

export interface EmailValues {
    [FieldNames.ACTIVE]: DripEmail['active'];
    [FieldNames.HOST_BLURB]: DripEmail['hostBlurb'];
}

const getToastOptions = (type: 'error' | 'success'): ToastProps => {
    const isSuccess = type === 'success';
    return {
        title: isSuccess ? 'Promote Email Saved' : 'Promote Email Not Saved',
        description: isSuccess
            ? 'Your Promote Email was saved successfully.'
            : 'An error has ocurred saving your Email, please try again.',
        status: isSuccess ? 'success' : 'error',
        isClosable: true,
        position: 'top-right',
    };
};

const defaultInitialValues: EmailValues = {
    [FieldNames.HOST_BLURB]: '',
    [FieldNames.ACTIVE]: false,
};

const EditEmailModal = ({
    isOpen,
    setIsOpen,
    emailCampaignName,
}: EditEmailModalProps): JSX.Element => {
    const { email, firstName, lastName, displayName } =
        useSelector((state) => state.profile.current) || {};
    const { promoteEmails, id: tripId } =
        useSelector((state) => state.userTrips.current) || {};

    const { updateRecord: updateTrip } = userTrips.useDispatch();
    const { getRecord: refreshCurrentTripDetails } =
        userTripDetails.useDispatch();

    const toast = useToast();

    const [isLoading, setIsLoading] = useState(false);

    const currentEmail = findCurrentDripEmail(promoteEmails, emailCampaignName);

    const hostInfo = {
        firstName,
        lastName,
        displayName,
        email,
    };

    const {
        emailPreview,
        isLoading: isPreviewLoading,
        updateEmailPreview,
        subject,
    } = usePreviewEmail(tripId, currentEmail?.emailHook?.emailHookId, hostInfo);

    const onEmailEditSubmit = async (data): Promise<void> => {
        try {
            if (promoteEmails?.drip && currentEmail) {
                const editedPromoteEmails = buildPromoteEmailsWithEditedEmail(
                    data,
                    promoteEmails,
                    currentEmail,
                );
                if (editedPromoteEmails) {
                    setIsLoading(true);
                    updateTrip(tripId, editedPromoteEmails, {
                        successCallback: () => {
                            refreshCurrentTripDetails(tripId);
                            toast(getToastOptions('success'));
                            setIsOpen(false);
                            setIsLoading(false);
                        },
                        errorCallback: () => {
                            toast(getToastOptions('error'));
                            setIsLoading(false);
                        },
                    });
                }
            }
        } catch (err) {
            toast(getToastOptions('error'));
            setIsLoading(false);
            return;
        }
    };

    const initialValues = {
        [FieldNames.HOST_BLURB]:
            currentEmail?.hostBlurb || defaultInitialValues['hostBlurb'],
        [FieldNames.ACTIVE]:
            !!currentEmail?.active || defaultInitialValues.active,
    };

    const formSaver = useFormSaver({
        initialValues,
        onSubmit: onEmailEditSubmit,
    });

    const scheduledDate = currentEmail?.scheduledHook?.scheduledDate;
    const emailCampaignScheduledLabel =
        currentEmail?.emailHook.emailCampaignScheduledLabel;
    const scheduledDateLabel: string | undefined =
        (scheduledDate && new Date(scheduledDate).toLocaleString()) ||
        emailCampaignScheduledLabel;

    const onClose = (): void => {
        formSaver.reset();
        updateEmailPreview(initialValues[FieldNames.HOST_BLURB]);
        setIsOpen(false);
    };

    return (
        <Modal isOpen={isOpen}>
            <Card
                width='100vw'
                maxWidth='container.xl'
                backgroundColor='blueGray.50'
                padding={0}
            >
                <Stack
                    minHeight='85vh'
                    direction='column'
                    align='stretch'
                    spacing={0}
                    justify='space-between'
                    height='100%'
                >
                    <Header
                        onCloseClick={onClose}
                        title={currentEmail?.emailHook.name}
                    />
                    <Grid className={styles.flexGrow}>
                        <Grid.Item
                            columnSpan={{ base: 12, md: 6 }}
                            paddingTop={8}
                            paddingBottom={4}
                            paddingLeft={6}
                            paddingRight={{ base: 6, md: 0 }}
                        >
                            <Stack
                                direction='column'
                                wrap='nowrap'
                                spacing={8}
                                align='stretch'
                                justify='space-between'
                                height='full'
                            >
                                <Form formSaver={formSaver}>
                                    <Form.Header
                                        formSaver={formSaver}
                                        scheduledDateLabel={scheduledDateLabel}
                                    />
                                    <Form.Body
                                        formSaver={formSaver}
                                        emailSubject={subject}
                                        updateEmailPreview={updateEmailPreview}
                                        isPreviewLoading={isPreviewLoading}
                                    />
                                </Form>
                                <Hide breakpoint={{ max: 'md' }}>
                                    <Form.Footer
                                        hostInfo={hostInfo}
                                        tripId={tripId}
                                        emailHookId={
                                            currentEmail?.emailHook.emailHookId
                                        }
                                    />
                                </Hide>
                            </Stack>
                        </Grid.Item>
                        <Grid.Item
                            columnSpan={{ base: 12, md: 6 }}
                            paddingY={8}
                            paddingX={10}
                            className={styles.previewContainer}
                        >
                            <Preview
                                emailPreview={emailPreview}
                                isPreviewLoading={isPreviewLoading}
                            />
                        </Grid.Item>
                    </Grid>
                    <Footer
                        isButtonDisabled={isLoading || !formSaver.isFormDirty}
                        hostEmail={email}
                        tripId={tripId}
                        emailHookId={currentEmail?.emailHook.emailHookId}
                        hostFirstName={firstName}
                        hostLastName={lastName}
                        onSaveAndClose={formSaver.handleFormSubmit}
                        onCloseClick={onClose}
                    />
                </Stack>
            </Card>
        </Modal>
    );
};

export default EditEmailModal;
