import { analytics } from '@trova-trip/trova-common';
import { models, utils } from '@trova-trip/trova-models';
import moment from 'moment';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTrackingEvent } from '../../../../analytics/hooks';
import useManageBooking from '../../../../applications/traveler/tabs/ManageBooking/hooks/useManageBooking';
import { OTHER_CANCEL_REASON } from '../../../../config/constants';
import {
    CancelBookingReason,
    CancelBookingRefundMethod,
    TripDetails,
} from '../../../../interfaces/Trips.types';
import { cancelBooking } from '../../../../state/bookings';
import { ProfileStoreSelector } from '../../../../state/profile';
import { userTrips } from '../../../../state/userTrips';
import { filterOptionsFromValue } from '../../../../util/form/dropdown';

type CancellationDetails = models.bookings.CancellationDetails;

interface ShouldShowCancelBookingModalState {
    successModal: boolean;
    firstAttemptModal: boolean;
}

const REFUND_METHOD_DROPDOWN_NAME = 'cancel-booking-refund-method';
const CANCEL_REASON_DROPDOWN_NAME = 'cancel-booking-reason';

const useGetCancelBookingDropdownOptions = (payload) =>
    useMemo(() => {
        return payload.map((arrayItem) => {
            return {
                value: arrayItem.description,
                children: arrayItem.description,
            };
        });
    }, [JSON.stringify(payload)]);

const getCancelReasonsWithOtherOption = (
    cancelReasons: CancelBookingReason[],
): CancelBookingReason[] => cancelReasons.concat([OTHER_CANCEL_REASON]);

const useCancelBooking = () => {
    const {
        globalState: { trip, booking },
    } = useManageBooking();

    const { trackUserEvent } = useTrackingEvent();

    const currentUser = useSelector(
        (state: ProfileStoreSelector) => state.profile.current,
    );

    const { getRecord: refreshTripRecord } = userTrips.useDispatch();

    const tripDetails: TripDetails = useSelector(
        (state: { userTripDetails: { current: TripDetails } }) =>
            state.userTripDetails?.current,
    );

    const refundMethods = tripDetails?.refundMethods;
    const cancelReasons = tripDetails?.cancelReasons;

    const cancelReasonsWithOtherOption = getCancelReasonsWithOtherOption(
        cancelReasons || [],
    );

    const initialCancelReasonDropdownOptions =
        useGetCancelBookingDropdownOptions(cancelReasonsWithOtherOption || []);
    const initialRefundMethodDropdownOptions =
        useGetCancelBookingDropdownOptions(refundMethods || []);

    const [refundMethodDropdownOptions, setRefundMethodDropdownOptions] =
        useState(initialRefundMethodDropdownOptions);
    const [cancelReasonDropdownOptions, setCancelReasonDropdownOptions] =
        useState(initialCancelReasonDropdownOptions);

    const [otherReasonsInputValue, setOtherReasonsInputValue] = useState('');
    const [cancelReasonValue, setCancelReasonValue] = useState('');
    const [refundMethodValue, setRefundMethodValue] = useState('');

    const [isFirstAttempt, setIsFirstAttempt] = useState(true);
    const [shouldShowAttemptMessage, setShouldShowAttemptMessage] =
        useState(false);

    const [shouldShowCancelBookingModal, setShouldShowCancelBookingModal] =
        useState<ShouldShowCancelBookingModalState>({
            successModal: false,
            firstAttemptModal: false,
        });

    const handleDropdownOnSearch = useCallback(
        (_: SyntheticEvent, name: string, searchValue: string): void => {
            switch (name) {
                case CANCEL_REASON_DROPDOWN_NAME:
                    setCancelReasonDropdownOptions(
                        filterOptionsFromValue(
                            searchValue,
                            initialCancelReasonDropdownOptions,
                        ),
                    );

                    break;
                case REFUND_METHOD_DROPDOWN_NAME:
                    setRefundMethodDropdownOptions(
                        filterOptionsFromValue(
                            searchValue,
                            initialRefundMethodDropdownOptions,
                        ),
                    );

                    break;

                default:
                    break;
            }
        },
        [
            initialCancelReasonDropdownOptions,
            initialRefundMethodDropdownOptions,
        ],
    );

    const handleOnSubmitCancelBookingModal = useCallback(
        async (
            cancellationReason: CancelBookingReason,
            cancellationRefundMethod: CancelBookingRefundMethod,
        ) => {
            if (booking?._id) {
                await submitCancelBooking(
                    booking._id,
                    cancellationReason,
                    cancellationRefundMethod,
                );
                refreshTripRecord(trip.id);
            }
        },
        [booking],
    );

    const handleCancelBookingModal = useCallback(
        (propToUpdate: Partial<ShouldShowCancelBookingModalState>): void => {
            setShouldShowCancelBookingModal((state) => ({
                ...state,
                ...propToUpdate,
            }));
        },
        [],
    );

    const handleCloseDialog = useCallback(() => {
        setIsFirstAttempt(true);
        setShouldShowAttemptMessage(false);
        handleCancelBookingModal({ firstAttemptModal: false });
        setCancelReasonValue('');
        setRefundMethodValue('');
    }, [handleCancelBookingModal]);

    const handleYesButtonClick = useCallback(async () => {
        if (isFirstAttempt) {
            setIsFirstAttempt(false);
            setShouldShowAttemptMessage(true);
        } else {
            const selectedCancelReason =
                cancelReasonValue === OTHER_CANCEL_REASON.value
                    ? otherReasonsInputValue
                    : cancelReasonValue;
            const cancellationReason = {
                description: selectedCancelReason,
                value: selectedCancelReason,
            };
            const cancellationRefundMethod = refundMethods.find(
                (refundMethod) =>
                    refundMethod.description === refundMethodValue,
            );
            if (cancellationRefundMethod) {
                await handleOnSubmitCancelBookingModal(
                    cancellationReason,
                    cancellationRefundMethod,
                );

                handleCancelBookingModal({ successModal: true });
                handleCloseDialog();

                const analyticsContext =
                    utils.analytics.context.createWithUser(currentUser);

                analytics.trackEvent({
                    eventName: 'Traveler Canceled Booking',
                    context: { ...analyticsContext },
                });
            }
        }
    }, [
        cancelReasonValue,
        refundMethodValue,
        isFirstAttempt,
        otherReasonsInputValue,
        handleCancelBookingModal,
        handleCloseDialog,
        handleOnSubmitCancelBookingModal,
        refundMethods,
        currentUser,
    ]);

    const handleOtherReasonsInputChange = useCallback((event) => {
        setOtherReasonsInputValue(event.target.value);
    }, []);

    const handleOnSearch = useCallback(
        (event: SyntheticEvent, name: string, searchValue: string) => {
            handleDropdownOnSearch(event, name, searchValue);
        },
        [handleDropdownOnSearch],
    );

    const submitCancelBooking = async (
        bookingId: string,
        cancellationReason: CancelBookingReason,
        cancellationRefundMethod: CancelBookingRefundMethod,
    ): Promise<void> => {
        if (bookingId) {
            const { description: refundDescription, refundAmount } =
                cancellationRefundMethod;
            const cancellationDetails: CancellationDetails = {
                reason: cancellationReason.description,
                refundMethodDescription: refundDescription,
                tripCreditAmount: refundAmount.tripCredit,
                refundAmount: refundAmount.refund,
            };

            const response = await cancelBooking(
                bookingId,
                cancellationDetails,
            );

            if (response.success) {
                trackUserEvent({ eventName: 'Traveler Canceled Booking' });
            }
        }
    };

    const daysTillTrip = moment(trip.startDate).diff(Date.now(), 'days', false);
    const isRefundMethodValueEmpty = refundMethodValue === '';
    const isCancelReasonValueEmpty = cancelReasonValue === '';
    const isCancelButtonDisabled =
        isRefundMethodValueEmpty || isCancelReasonValueEmpty;

    return {
        refundMethodDropdownOptions,
        cancelReasonDropdownOptions,
        otherReasonsInputValue,
        cancelReasonValue,
        setCancelReasonValue,
        refundMethodValue,
        setRefundMethodValue,
        handleOnSearch,
        handleDropdownOnSearch,
        handleYesButtonClick,
        handleCloseDialog,
        handleCancelBookingModal,
        handleOtherReasonsInputChange,
        handleOnSubmitCancelBookingModal,
        isCancelButtonDisabled,
        shouldShowAttemptMessage,
        shouldShowCancelBookingModal,
        submitCancelBooking,
        daysTillTrip,
    };
};

export default useCancelBooking;
