import { Grid } from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';
import InterestCard from './InterestCard';
import { useSelector } from '../../../../state/hooks/useSelector';

type Category = models.categories.Category;

type InterestsCardsProps = {
    selectedInterests: string[];
    onInterestClick: (hostInterest: Category) => void;
};

const InterestCards = ({
    selectedInterests,
    onInterestClick,
}: InterestsCardsProps): JSX.Element => {
    const {
        categories: { list: categoryList },
    } = useSelector((state) => state);

    return (
        <Grid gap={2}>
            {categoryList?.records.map((hostInterest) => {
                return hostInterest._id ? (
                    <Grid.Item
                        key={hostInterest._id}
                        columnSpan={{ base: 6, md: 4, xl: 3 }}
                    >
                        <InterestCard
                            isChecked={selectedInterests.includes(
                                hostInterest._id,
                            )}
                            hostInterest={hostInterest}
                            onClick={(): void => onInterestClick(hostInterest)}
                        />
                    </Grid.Item>
                ) : null;
            })}
        </Grid>
    );
};

export default InterestCards;
