import { Stack, Text } from '@trova-trip/trova-components';
import { useTripPageScreenshot } from 'applications/common/hooks/trips/useTripPageScreenshot';
import defaultImage from '../../../../../../../assets/img/default-trip-page-screenshot-x2.png';
import { AnimatedImage, NextButton } from '../shared';
import BaseContent from './BaseContent';

const BoldSpan = ({ children }): JSX.Element => (
    <Text as='span' fontWeight='bold'>
        {children}
    </Text>
);

const TripPageContent = (): JSX.Element => {
    const tripPageScreenshot = useTripPageScreenshot({
        width: 540,
        height: 1200,
    });

    return (
        <BaseContent
            title='Let’s Review Your Trip Page'
            leftContent={
                <AnimatedImage
                    src={tripPageScreenshot}
                    fallbackSrc={defaultImage}
                    alt='Trip Page'
                    maxWidth={{
                        base: '17.5rem',
                        md: '21.25rem',
                        xl: '33.75rem',
                    }}
                    boxShadow='3'
                    borderRadius='lg'
                    data-gumlet='false'
                />
            }
            rightContent={
                <Stack direction='column' spacing={5}>
                    <Text fontWeight='bold' color='teal.trova'>
                        Voilà! Your trip page is ready to be scheduled for
                        launch.
                    </Text>
                    <Text>
                        This is where your travelers will come to book spots on
                        your trip.{' '}
                        <BoldSpan>It’s essential to promote your trip</BoldSpan>{' '}
                        and build excitement around your launch date.
                    </Text>
                    <Text>
                        <BoldSpan>70%</BoldSpan> of hosts who book the minimum
                        travelers required do it <BoldSpan>in 2 weeks</BoldSpan>{' '}
                        or less, so get the word out to your audience early!
                    </Text>
                    <Text>
                        Don’t worry,{' '}
                        <BoldSpan>
                            {' '}
                            travelers won’t be able to book spots on your trip
                            until your launch date.
                        </BoldSpan>{' '}
                        So if you change the details of your trip you can
                        preview the trip page anytime in the Promote tab.
                    </Text>
                </Stack>
            }
            footerContent={<NextButton />}
        />
    );
};

export default TripPageContent;
