import React, { SyntheticEvent, useMemo } from 'react';
import {
    FormGroup,
    ComboBox,
    BaseComboBoxOption,
} from '@trova-trip/trova-components';
import { constants } from '@trova-trip/trova-models';

import { FormInputNames } from './TripRequestForm.types';
import { FoodOptionsValueType } from '../../../operator/components/ItineraryPackages/ItineraryPackage.types';

const { PackageFoodOptions } = constants.itinerary;

interface DietaryRestrictionsComboBoxProps {
    dietaryRestriction?: FoodOptionsValueType;
    dietaryOptions: BaseComboBoxOption[];
    editMode?: boolean;
    setFormData: (value: Object) => void;
}

const defaultPackageFoodOption = {
    value: PackageFoodOptions.NONE,
    label: 'None',
};

const DietaryRestrictionsComboBox = ({
    dietaryOptions,
    editMode,
    dietaryRestriction,
    setFormData,
}: DietaryRestrictionsComboBoxProps) => {
    const defaultDietaryOptions = useMemo(
        () => [defaultPackageFoodOption, ...dietaryOptions],
        [dietaryOptions],
    );
    const dietaryRestrictionSelected = dietaryRestriction
        ? (defaultDietaryOptions || []).filter(
              (elem) => elem.value === dietaryRestriction,
          )
        : defaultPackageFoodOption;

    const handleDietaryRestrictionChange = (event: SyntheticEvent) => {
        const eventValue: string = (event.target as HTMLInputElement).value;
        const { value } = JSON.parse(eventValue);

        setFormData({
            dietaryRestriction: value,
        });
    };

    const helperText =
        'Preference means that our operators will ensure this option is available for every included meal. Your trip will not be branded by Trova based on the selected dietary requirements. (i.e. if vegan options requested, do not promote trip as vegan)';

    return (
        <FormGroup label='Trip Dietary Preferences' helperText={helperText}>
            <ComboBox
                name={FormInputNames.DIETARY_RESTRICTIONS}
                options={defaultDietaryOptions}
                value={dietaryRestrictionSelected}
                onChange={handleDietaryRestrictionChange}
                isDisabled={!editMode || dietaryOptions.length < 1}
            />
        </FormGroup>
    );
};

export default DietaryRestrictionsComboBox;
