import FetchState from './utils/FetchState';

const links = FetchState.create(`api/admin/links`);
const linksMetadata = FetchState.create(`api/metadata/links`);
const userLinks = FetchState.create(`api/me/links`);

const linksMetadataReducer = linksMetadata.getReducer();
const linksReducer = links.getReducer();
const userLinksReducer = userLinks.getReducer();

export { links, linksMetadata, userLinks, linksReducer, linksMetadataReducer, userLinksReducer };
