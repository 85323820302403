import useManageBooking from './useManageBooking';
import { getPricingFromState } from '../utils/pricing.utils';
import { AddOnsPricing } from '../types';

const usePricingData = (): AddOnsPricing => {
    const {
        state: {
            addOns,
            travelersQuantity,
            travelersRooms,
            defaultRoomOccupancy,
        },
    } = useManageBooking();

    const currentState = {
        addOns,
        travelersQuantity,
        travelersRooms,
        defaultRoomOccupancy,
    };

    const currentPricing = getPricingFromState(currentState);

    return currentPricing;
};

export default usePricingData;
