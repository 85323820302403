import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { DraggableTarget, theme } from '@trova-trip/trova-components';

export const StyledDraggableTarget = styled(DraggableTarget)`
    align-items: center;
    justify-content: center;
    height: 132px;
    > div {
        min-width: 120px;
    }
`;

export const StyledSection = styled.div`
    margin: 0 0 2rem 0;
    scroll-margin-top: 0.5rem;

    @media (max-width: ${theme.breakpoints.lg}) {
        &:first-child {
            margin: 1.5rem 0 2rem 0;
        }
    }
`;

export const dayTitle = () => css`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 0.5rem 0 1rem;
`;
export const dayParagraph = () => css`
    flex-grow: 1;
`;

export const date = () => css`
    margin-left: 0.5rem;
    font-family: ${theme.fonts.roboto};
    font-weight: ${theme.fontWeights.regular};
    font-size: 16px;
    line-height: 19px;
`;
