import { clamp, get, set } from 'lodash';
import moment from 'moment';
import { useCallback } from 'react';
import { nanoid } from 'nanoid';
import {
    includeDaysInRange,
    groupByDates,
    insertRowAtIndex,
    updateNextRow,
    deleteEmptyRows,
    isDayInRange,
} from '../utils/validityPeriodsHelper';

const useAddRow = (validityPeriods, updateValidityPeriods) =>
    useCallback(
        (row) => {
            const startDate = row.startDate || moment();
            const endDate = row.endDate || moment().add(365, `days`);
            const daysOfTheWeek =
                row.daysOfTheWeek || includeDaysInRange(startDate, endDate);

            if (validityPeriods && validityPeriods.length) {
                const lastIndex = validityPeriods.length - 1;
                const costThresholds = validityPeriods[
                    lastIndex
                ].costThresholds.map((costThreshold) => ({
                    daysOfTheWeek,
                    numberOfTravelers: costThreshold.numberOfTravelers,
                    price: 0,
                }));
                const newRow = {
                    id: nanoid(),
                    startDate,
                    endDate,
                    additionalCosts: { singleSupplement: { price: 0 } },
                    costThresholds,
                };

                const newValidityPeriods = row.cloneIndex
                    ? insertRowAtIndex(validityPeriods, newRow, row.cloneIndex)
                    : [...validityPeriods, newRow];
                updateValidityPeriods(newValidityPeriods);
            } else {
                // eslint-disable-next-line
                validityPeriods = [
                    {
                        startDate,
                        endDate,
                        additionalCosts: { singleSupplement: { price: 0 } },
                        costThresholds: [
                            {
                                daysOfTheWeek,
                                numberOfTravelers: 2,
                                price: 0,
                            },
                        ],
                    },
                ];
                updateValidityPeriods(validityPeriods);
            }
        },
        [validityPeriods, updateValidityPeriods],
    );

const useDeleteRow = (validityPeriods, updateValidityPeriods) =>
    useCallback(
        (deleteIndex) => {
            const newValidityPeriods = [...validityPeriods];
            newValidityPeriods.splice(deleteIndex, 1);
            updateValidityPeriods(newValidityPeriods);
        },
        [validityPeriods, updateValidityPeriods],
    );

const useAddTier = (validityPeriods, updateValidityPeriods) =>
    useCallback(() => {
        const numberOfTravelersIndex =
            validityPeriods[0].costThresholds.length - 1;
        const { numberOfTravelers } =
            validityPeriods[0].costThresholds[numberOfTravelersIndex];

        const newCostThreshold = (costThreshold) => ({
            id: nanoid(),
            daysOfTheWeek: validityPeriods[0].costThresholds[0].daysOfTheWeek,
            numberOfTravelers:
                (costThreshold && costThreshold.numberOfTravelers) ||
                Number(numberOfTravelers) + 2,
            price: (costThreshold && Number(costThreshold.price)) || 0,
        });

        const validityPeriodsCopy = [...validityPeriods];
        const newValidityPeriods = validityPeriodsCopy.map((validityPeriod) => {
            // find and replace empty thresholds with valid values
            const validThresholds = validityPeriod.costThresholds.map(
                (costThreshold) =>
                    !costThreshold._id || !costThreshold.id
                        ? newCostThreshold(costThreshold)
                        : costThreshold,
            );

            validityPeriod.costThresholds = [
                ...validThresholds,
                newCostThreshold(),
            ];
            return validityPeriod;
        });

        updateValidityPeriods(newValidityPeriods);
    }, [validityPeriods, updateValidityPeriods]);

const useDeleteTier = (validityPeriods, updateValidityPeriods) =>
    useCallback(
        (deleteIndex) => {
            const newValidityPeriods = [...validityPeriods];
            newValidityPeriods.map((validityPeriod) =>
                validityPeriod.costThresholds.splice(deleteIndex, 1),
            );

            updateValidityPeriods(newValidityPeriods);
        },
        [updateValidityPeriods, validityPeriods],
    );

const useUpdateHandler = (
    validityPeriods,
    updateValidityPeriods,
    spotsCapacity,
) =>
    useCallback(
        (event) => {
            const { name, type } = event.target;
            let { value } = event.target;
            if (type === `checkbox`) {
                value = !get(validityPeriods, name, false);
            }
            if (moment.isMoment(value)) {
                value = value;
            }
            const newValidityPeriods = [...validityPeriods];
            set(newValidityPeriods, name, value);
            updateValidityPeriods(newValidityPeriods);
        },
        [validityPeriods, updateValidityPeriods],
    );

const useUpdateTierHandler = (
    validityPeriods,
    updateValidityPeriods,
    spotsCapacity,
) =>
    useCallback(
        (data, customPrice) => {
            const { value, index } = data;
            const clampedValue = clamp(
                Number(value),
                spotsCapacity.min,
                spotsCapacity.max,
            );
            const newValidityPeriods = [...validityPeriods];

            newValidityPeriods[index].numberOfTravelers = clampedValue;
            if (customPrice) {
                newValidityPeriods[index].price = customPrice;
            }
            updateValidityPeriods(newValidityPeriods);
        },
        [validityPeriods, updateValidityPeriods],
    );

const useUpdateCostThresholdsHandler = (
    validityPeriods,
    updateValidityPeriods,
) =>
    useCallback(
        (event) => {
            const { name, value } = event.target;
            const newValidityPeriods = [...validityPeriods];
            newValidityPeriods.map((validityPeriod) =>
                set(validityPeriod, name, value),
            );
            updateValidityPeriods(newValidityPeriods);
        },
        [validityPeriods, updateValidityPeriods],
    );

const useUpdateDaysOfTheWeekHandler = (
    validityPeriods,
    updateValidityPeriods,
    validateDaysOfWeek,
) =>
    useCallback(
        (event, name, value) => {
            const { name: day } = event.target;
            const validityPeriodIndex = name;
            const validityPeriod = validityPeriods[validityPeriodIndex];
            const { startDate, endDate } = validityPeriod;
            const valuesInRange = isDayInRange(startDate, endDate, day, value);
            if (valuesInRange) {
                value = valuesInRange;
            }
            const newCostThresholds = validityPeriod.costThresholds.map(
                (costThreshold) => ({
                    ...costThreshold,
                    daysOfTheWeek: value,
                }),
            );
            validityPeriod.costThresholds = newCostThresholds;
            const newValidityPeriods = [...validityPeriods];
            newValidityPeriods[validityPeriodIndex] = validityPeriod;
            updateValidityPeriods(newValidityPeriods);
            validateDaysOfWeek(event, name);
        },
        [validityPeriods, updateValidityPeriods, validateDaysOfWeek],
    );

const useUpdateDateTimeHandler = (updateHandler) =>
    useCallback(
        (event, value, name) => {
            const wrappedEvent = { ...event, target: { value, name } };
            updateHandler(wrappedEvent);
        },
        [updateHandler],
    );

const useValidateDaysOfWeek = (
    validityPeriods,
    updateValidityPeriods,
    addRow,
    deleteRow,
) =>
    useCallback(
        (event, index) => {
            const { name: day, checked } = event.target;
            const validityPeriod = validityPeriods[index];
            const { startDate, endDate } = validityPeriod;
            const groupedRows = groupByDates(validityPeriods, index);
            const isLastIndex = index === validityPeriods.length - 1;
            const addNewRow = () =>
                addRow({
                    cloneIndex: index,
                    startDate,
                    endDate,
                    daysOfTheWeek: [day],
                });

            if (!checked) {
                if (groupedRows.length === 1) {
                    addNewRow();
                }

                if (groupedRows.length > 1) {
                    if (isLastIndex) {
                        addNewRow();
                    } else {
                        updateNextRow(
                            validityPeriods,
                            updateValidityPeriods,
                            index,
                            day,
                        );
                    }
                }
            }

            if (checked) {
                groupedRows.map((validityPeriod) =>
                    validityPeriod.costThresholds.map((costThreshold) => {
                        costThreshold.daysOfTheWeek =
                            costThreshold.daysOfTheWeek.filter(
                                (daysOfTheWeek) =>
                                    Number(daysOfTheWeek) !== Number(day),
                            );
                        return costThreshold;
                    }),
                );

                validityPeriod.costThresholds.map((costThreshold) => {
                    costThreshold.daysOfTheWeek =
                        costThreshold.daysOfTheWeek.concat(day);
                    return costThreshold;
                });
            }

            deleteEmptyRows(validityPeriods, deleteRow);
        },
        [validityPeriods, updateValidityPeriods, addRow, deleteRow],
    );

export {
    useAddRow,
    useDeleteRow,
    useAddTier,
    useDeleteTier,
    useUpdateHandler,
    useUpdateCostThresholdsHandler,
    useUpdateDaysOfTheWeekHandler,
    useUpdateDateTimeHandler,
    useUpdateTierHandler,
    useValidateDaysOfWeek,
};
