import {
    Grid,
    Field,
    FieldProps,
    GridItemProps,
} from '@trova-trip/trova-components';

type GridItemFieldProps<FieldName> = FieldProps &
    Pick<GridItemProps, 'columnSpan' | 'columnEnd' | 'columnStart'> & {
        name?: FieldName;
    };

const GridItemField = <FieldItem extends string>({
    columnSpan = { base: 12, xl: 6 },
    columnEnd,
    columnStart,
    ...fieldProps
}: GridItemFieldProps<FieldItem>): JSX.Element => (
    <Grid.Item
        columnSpan={columnSpan}
        columnEnd={columnEnd}
        columnStart={columnStart}
    >
        <Field {...fieldProps} />
    </Grid.Item>
);

export default GridItemField;
export type { GridItemFieldProps };
