import FetchState from './utils/FetchState';
import { get, post } from './utils/api';

const userTripsTraveler = FetchState.create(`api/me/trips/:tripId/travelers`);
const userTripsTravelerReducer = userTripsTraveler.getReducer();

const updateTripTraveler = (tripId, travelerId, refIframe) =>
    post(`api/me/trips/${tripId}/travelers/${travelerId}`, refIframe);

const findTripHostCompanions = (tripId) =>
    get(`api/me/trips/${tripId}/host-companions`);

const createTripHostCompanions = (tripId, data) =>
    post(`api/me/trips/${tripId}/host-companions`, data);

const createAdminTripHostCompanions = (tripId, data) =>
    post(`api/admin/trips/${tripId}/host-companions`, data);

export {
    userTripsTraveler,
    userTripsTravelerReducer,
    updateTripTraveler,
    createTripHostCompanions,
    findTripHostCompanions,
    createAdminTripHostCompanions,
};
