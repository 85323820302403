import FetchState from './utils/FetchState';

const emailhooks = FetchState.create(`api/admin/emailHooks`);
const emailhooksMetadata = FetchState.create(`api/metadata/emailHooks`);
const emailhooksMetadataReducer = emailhooksMetadata.getReducer();
const emailhooksReducer = emailhooks.getReducer();

export {
    emailhooks,
    emailhooksMetadata,
    emailhooksReducer,
    emailhooksMetadataReducer,
};
