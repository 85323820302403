/*issue with CRA4 and React 17: https://github.com/emotion-js/emotion/issues/2041*/
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useRef, useState, Fragment } from 'react';
import { debounce } from 'lodash';
import {
    ComponentWidth,
    Icon,
    IconName,
    Input,
    DraggableCard,
    theme,
    Text,
    BaseBox,
    Dropdown,
} from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
    showMoreResults as showMoreResultsCss,
    StyledCard,
} from './ServicesSearch.styles';
import { getDropdownOptionsByKey } from '../../../../util/form/dropdown';
import LibraryServiceCard from './LibraryServiceCard';
import useFetchLibraryServices from './useFetchLibraryServices';
import { LibraryServiceTypesLabelsDraggable } from '../../../../config/constants';
import DaysDropdown from '../../tabs/ConfigureService/mobileServices/DaysDropdown.tsx';

const API_CALL_WAIT_TIME = 700;
const DRAGGABLE_ELEMENT = `draggableLibraryServiceCard`;

const ServiceInstancesSearch = ({
    onDaySelected,
    numberOfDays,
    onClose,
    scrollToDay,
    className = '',
}) => {
    const [searchText, setSearchText] = useState(``);
    const [serviceType, setServiceType] = useState();
    const [amountOfResults, setAmountOfResults] = useState(3);
    const results = useSelector((state) =>
        state.libraryServices.search ? state.libraryServices.search : [],
    );

    const { fetchLibraryServices } = useFetchLibraryServices();
    const debounceFetchLibraryServices = useRef(
        debounce(async (amountOfResults, serviceType, searchText) => {
            fetchLibraryServices(amountOfResults, serviceType, searchText);
        }, API_CALL_WAIT_TIME),
    );

    useEffect(() => {
        debounceFetchLibraryServices.current(
            amountOfResults,
            serviceType,
            searchText,
        );
    }, [serviceType, searchText, amountOfResults]);

    const handleChange = (event) => {
        event.preventDefault();
        const { value } = event.target;
        setSearchText(value);
    };

    const handleDropdownChangeEvent = (event, name, value) => {
        event.preventDefault();
        setServiceType(value);
    };

    const serviceTypeOptions = () => {
        const allTypes = [{ value: ``, children: `All Types` }];
        return allTypes.concat(getDropdownOptionsByKey(`ServiceTypesLabels`));
    };

    const showMoreResults = () => {
        setAmountOfResults(amountOfResults + 3);
    };

    function getFromServiceType(type) {
        return LibraryServiceTypesLabelsDraggable[type] || type;
    }

    const [showDaysDropdown, setShowDaysDropdown] = useState(
        Array(amountOfResults).fill(false),
    );
    const onClickDraggableCard = (index) => {
        setShowDaysDropdown((prevValue) => {
            const newValue = [...prevValue];
            newValue[index] = true;
            return newValue;
        });
    };

    return (
        <StyledCard
            backgroundColor={theme.colors.blueGray[200]}
            className={className}
        >
            <BaseBox paddingTop={3} paddingBottom={2}>
                <Text fontWeight={'bold'} size={'sm'} color={'blueGray.dark'}>
                    Search previously used
                </Text>
            </BaseBox>
            <Input
                name='searchText'
                value={searchText}
                placeholder='Service to search...'
                size={ComponentWidth.Flexible}
                icon={
                    <Icon
                        name={IconName.Search}
                        color={theme.colors.red.coral}
                    />
                }
                onChange={handleChange}
            />
            <BaseBox marginTop={2}>
                <Dropdown
                    name='serviceType'
                    value={serviceType}
                    placeholder='Select a service type...'
                    size={ComponentWidth.Flexible}
                    onChange={handleDropdownChangeEvent}
                >
                    {serviceTypeOptions()}
                </Dropdown>
            </BaseBox>
            <BaseBox paddingTop={4}>
                <Text fontWeight={'bold'} size={'sm'} color={'blueGray.650'}>
                    {results.length} results
                </Text>
            </BaseBox>
            {!!results.length &&
                results.map((service, index) => {
                    service.type = getFromServiceType(service.type);
                    const item = {
                        type: DRAGGABLE_ELEMENT,
                        service,
                        serviceType: getFromServiceType(service.type),
                    };
                    return (
                        <Fragment key={service._id}>
                            <DraggableCard
                                dropTargetConfiguration={{
                                    accept: `string`,
                                    drop: (item) => item,
                                    item,
                                }}
                                dragTargetConfiguration={{
                                    item: {
                                        type: DRAGGABLE_ELEMENT,
                                        service,
                                        serviceType: getFromServiceType(
                                            service.type,
                                        ),
                                    },
                                }}
                                onCollect={(item) => item}
                            >
                                <LibraryServiceCard
                                    key={service._id}
                                    service={service}
                                    onServiceCardClicked={() =>
                                        onClickDraggableCard(index)
                                    }
                                />
                            </DraggableCard>
                            <DaysDropdown
                                numberOfDays={numberOfDays}
                                onDaySelect={(dayNumber) => {
                                    onDaySelected(dayNumber, item);
                                    onClose();
                                    scrollToDay(dayNumber);
                                }}
                                show={showDaysDropdown[index]}
                            />
                        </Fragment>
                    );
                })}

            <div css={showMoreResultsCss()}>
                <Button
                    variant='tertiary'
                    rightIcon='plus'
                    onClick={showMoreResults}
                >
                    Show More Results
                </Button>
            </div>
        </StyledCard>
    );
};

ServiceInstancesSearch.propTypes = {
    numberOfDays: PropTypes.number,
    onDaySelected: PropTypes.func,
    onClose: PropTypes.func,
    scrollToDay: PropTypes.func,
};

export default ServiceInstancesSearch;
