import {
    Card,
    Chip,
    FormGroup,
    Heading,
    Stack,
    Switch,
    Text,
} from '@trova-trip/trova-components';
import { useEffect, useRef, useState } from 'react';
import {
    AvailabilityViewModelByPackage,
    PackageLevel,
} from '../TripRequestForm.types';
import { getInventoryItemViewModelsByYear } from '../tripRequestFormHelper';
import InventoryItemsList from './InventoryItemsList';
import { HandleSelectInventoryItem } from '../TripRequestForm';

interface YearChipsProps {
    years: string[];
    onYearChipClick: (year: string) => void;
    activeYear: string | null;
}

const YearChips = ({
    years,
    activeYear,
    onYearChipClick,
}: YearChipsProps): JSX.Element => {
    return (
        <Stack direction='row' spacing={3}>
            {years.map((year) => (
                <Chip
                    key={year}
                    label={year}
                    colorScheme='teal'
                    isActive={activeYear === year}
                    onClick={(): void => onYearChipClick(year)}
                    paddingX={4}
                />
            ))}
        </Stack>
    );
};

interface ShowAllProps {
    value: boolean;
    onChange: () => void;
}

const ShowAll = ({ value, onChange }: ShowAllProps) => (
    <FormGroup
        direction='row'
        label={
            <Text
                as='span'
                cursor='pointer'
                marginTop='-.125rem'
                marginRight='-.25rem'
                onClick={onChange}
            >
                show all dates
            </Text>
        }
    >
        <Switch
            name='showAllInventoryItems'
            value={value}
            onChange={onChange}
        />
    </FormGroup>
);

interface PackageInventoryItemsCardProps {
    availabilityViewModel: AvailabilityViewModelByPackage;
    selectedPackage: PackageLevel;
    tripLength: number | undefined;
    handleSelectInventoryItem: HandleSelectInventoryItem;
    selectedInventoryItemId: string | undefined;
}

interface InventoryItemsFilters {
    year: string | null;
    showAll: boolean;
}

const PackageInventoryItemsCard = ({
    selectedInventoryItemId,
    availabilityViewModel,
    selectedPackage,
    tripLength,
    handleSelectInventoryItem,
}: PackageInventoryItemsCardProps): JSX.Element => {
    const [filters, setFilters] = useState<InventoryItemsFilters>({
        year: null,
        showAll: false,
    });
    const yearDividerRefs = useRef<HTMLDivElement[]>([]);
    const inventoryItemsListRef = useRef<HTMLDivElement | null>(null);

    const showAvailableOnly = !filters.showAll;

    const inventoryItemsByYear = getInventoryItemViewModelsByYear(
        availabilityViewModel[selectedPackage].itineraryInventoryItems,
        tripLength,
        showAvailableOnly,
    );

    const setYear = (year: string): void => {
        setFilters((previousFilters) => ({ ...previousFilters, year }));
    };

    useEffect(() => {
        const firstAvailableYear = Object.keys(inventoryItemsByYear)[0];
        if (firstAvailableYear !== filters.year) {
            setYear(firstAvailableYear);
        }
        // we're using showAll filter as a dependency because we want to reset the selected year when the items list changes (showAll is toggled), as available years may change
    }, [filters.showAll]);

    const handleYearDividerRef = (ref: HTMLDivElement | null): void => {
        if (!ref) {
            return;
        }
        const previousRefIndex = yearDividerRefs.current.findIndex(
            (dividerRef) =>
                dividerRef.getAttribute('data-year') ===
                ref.getAttribute('data-year'),
        );
        if (previousRefIndex > -1) {
            yearDividerRefs.current[previousRefIndex] = ref;
        } else {
            yearDividerRefs.current.push(ref);
        }
    };

    const onYearChipClick = (year: string): void => {
        const yearDivider = yearDividerRefs.current.find(
            (ref) => ref.getAttribute('data-year') === year,
        );
        inventoryItemsListRef.current?.scrollTo({
            behavior: 'smooth',
            top: yearDivider?.offsetTop || 0,
        });
        setYear(year);
    };

    const toggleShowAll = (): void => {
        setFilters((previousFilters) => ({
            ...previousFilters,
            showAll: !previousFilters.showAll,
        }));
    };

    return (
        <Card backgroundColor='white'>
            <Heading as='h4' textTransform='capitalize'>
                {selectedPackage} Dates and Prices
            </Heading>
            <Stack justify='space-between' align='center' marginTop={4}>
                <YearChips
                    years={Object.keys(inventoryItemsByYear)}
                    activeYear={filters.year}
                    onYearChipClick={onYearChipClick}
                />
                <ShowAll value={filters.showAll} onChange={toggleShowAll} />
            </Stack>
            <InventoryItemsList
                ref={inventoryItemsListRef}
                inventoryItemsByYear={inventoryItemsByYear}
                handleYearDividerRef={handleYearDividerRef}
                handleSelectInventoryItem={handleSelectInventoryItem}
                selectedInventoryItemId={selectedInventoryItemId}
            />
        </Card>
    );
};

export default PackageInventoryItemsCard;
