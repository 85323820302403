import { SyntheticEvent } from 'react';
import { Grid } from '@material-ui/core';
import { Currency } from '@trova-trip/trova-components';
import TabDetailsInformation from 'components/TabDetailsInformation/TabDetailsInformation';

interface PricingInputsProps {
    initialPrice: number;
    remainingPrice: number;
    onChange: (
        event: SyntheticEvent,
        inputName: string,
        eventValue: number,
    ) => void;
    onBlur: (event: SyntheticEvent) => void;
    disabled?: boolean;
}

const PricingInputs: React.FC<PricingInputsProps> = (props) => {
    const {
        initialPrice,
        remainingPrice,
        onChange,
        onBlur,
        disabled = false,
    } = props;

    return (
        <Grid container spacing={32}>
            <Grid item xs={12} sm={10}>
                <TabDetailsInformation
                    title='Pricing'
                    description={`It's time to price your trip by setting your early bird and
                    regular trip price. Typically, hosts set their early bird
                    price $100-200 below the regular price to create urgency for
                    travelers to book sooner. Fill out the early bird and
                    regular price fields below to view your potential earnings.`}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Grid container item spacing={32}>
                    <Grid item xs={6} sm={'auto'}>
                        <Currency
                            name={'prices.initial'}
                            label={'Early bird price'}
                            value={initialPrice}
                            onChange={onChange}
                            onBlur={onBlur}
                            disabled={disabled}
                            decimalPlaces={0}
                        />
                    </Grid>
                    <Grid item xs={6} sm={'auto'}>
                        <Currency
                            name={'prices.remainingPrice'}
                            label={'Regular price'}
                            value={remainingPrice}
                            onChange={onChange}
                            onBlur={onBlur}
                            disabled={disabled}
                            decimalPlaces={0}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PricingInputs;
