import styled from '@emotion/styled';

export const StyledCardContainerGrid = styled.div({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gridGap: '1rem',
    marginBottom: '1rem',
    '> div': {
        display: 'grid',
    },
});

export const StyledCardBody = styled.div({
    textAlign: 'center',
    margin: '3rem 0 1rem 0',
});
