import { css } from '@emotion/css';
import { BaseBox, Stack, Text } from '@trova-trip/trova-components';
import { Card } from '@trova-trip/trova-components/build/next';
import { models } from '@trova-trip/trova-models';
import moment from 'moment';

interface ImportantDateCardProps {
    importantDate: models.hostHomeTab.TripImportantDate;
    color: string;
}

const ImportantDateCard = (props: ImportantDateCardProps) => {
    const { importantDate, color } = props;

    const { date, description, tripName } = importantDate;

    return (
        <Card
            backgroundColor='neutral.white'
            height='full'
            padding={{ base: 4, md: 0 }}
            width='full'
        >
            <Stack spacing={4} wrap='nowrap' height='full' align='center'>
                <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight='bold'>
                    {moment(date).format('MM/DD')}
                </Text>
                <BaseBox
                    flexShrink={0}
                    backgroundColor={color}
                    width='3px'
                    className={css({ alignSelf: 'stretch' })}
                />
                <Stack direction='column' spacing={0.5}>
                    <Text fontSize='sm' color='blueGray.500' noOfLines={1}>
                        {tripName}
                    </Text>
                    <Text
                        fontSize={{ base: 'sm', md: 'base' }}
                        fontWeight='medium'
                    >
                        {description}
                    </Text>
                </Stack>
            </Stack>
        </Card>
    );
};

export default ImportantDateCard;
