const buildPath = (pathname, nextTabPathName) => {
    return `${pathname
        .split(`/`)
        .slice(0, -1)
        .join(`/`)}/${nextTabPathName}`;
};

const redirectToTab = (history, nextTabPathName) => {
    const {
        location: { pathname },
    } = history;

    const newPath = buildPath(pathname, nextTabPathName);

    history.push(newPath);
};

export { redirectToTab };
