import { TRIP_CATALOG_PAGE_URI } from '../../../utils';
import { Button, ButtonProps } from '@trova-trip/trova-components/build/next';

const BookNewTripLinkButton = (
    props: Omit<ButtonProps, 'children'>,
): JSX.Element => (
    <Button
        as='a'
        variant='primary'
        rightIcon='offsite'
        isFullWidth
        linkProps={{
            href: TRIP_CATALOG_PAGE_URI,
            target: '_blank',
            rel: 'noreferrer',
        }}
        {...props}
    >
        Book new trip
    </Button>
);

export default BookNewTripLinkButton;
