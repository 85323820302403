import { FormDataValueType } from '@trova-trip/trova-components';
import { GooglePlacesOption } from '@trova-trip/trova-components/build/custom';
import { models } from '@trova-trip/trova-models';
import GoogleService from '../services/google';

type AddressLocation = models.addresses.AddressLocation;

/**
 * Checks if the given location object is complete.
 *
 * @param location
 * @return Returns `true` if the location is complete, otherwise `false.
 */
const isLocationComplete = (location: AddressLocation | undefined): boolean => {
    if (!location) return false;

    const { name, placeId, types } = location;

    return !!(name && placeId && types && types.length);
};

/**
 * Transforms an object of type `FormDataValueType` with the shape of `GooglePlacesOption`
 * to an `AddressLocation` to be sent to the server.
 *
 * @param location
 * @return The transformed `AddressLocation` or `null`.
 */
const transformGooglePlacesOptionToAddressLocation = async (
    location: FormDataValueType | null | undefined,
): Promise<AddressLocation | null> => {
    if (!location) return null;
    const googleLocation = location as unknown as GooglePlacesOption;

    const latLng = await getLatLngByGooglePlaceId(googleLocation.value);

    return {
        name: googleLocation.label,
        placeId: googleLocation.value,
        types: [googleLocation.type],
        geoLocation: latLng
            ? {
                lat: latLng.lat(),
                lng: latLng.lng(),
            }
            : undefined,
    };
};

/**
 * Transforms an `AddressLocation` object to a `GooglePlacesOption` object.
 *
 * @param location
 * @return The transformed `GooglePlacesOption` or `undefined` if the location is not complete.
 */
const transformAddressLocationToGooglePlacesOption = (
    location: AddressLocation | undefined,
): GooglePlacesOption | undefined => {
    if (!location) return undefined;

    if (!isLocationComplete(location)) return undefined;

    const googleLocation: GooglePlacesOption = {
        label: location.name,
        description: '',
        value: location.placeId,
        type: location.types[0],
    };

    return googleLocation;
};

/**
 * Transforms an `AddressLocation` object to a `FormDataValueType` with the shape
 * of `GooglePlacesOption`.
 *
 * @param location
 * @return The transformed `FormDataValueType`, or `null` if the location is invalid or undefined.
 */
const transformAddressLocationToFormDataValueType = (
    location: AddressLocation | undefined,
): FormDataValueType | undefined => {
    if (!location) return undefined;

    const googleLocation =
        transformAddressLocationToGooglePlacesOption(location);

    if (!googleLocation) return undefined;

    return googleLocation as unknown as FormDataValueType;
};

/**
 * Get latitude and longitude from a place ID.
 *
 * @param placeId
 * @return The latitude and longitude of the place ID.
 */
const getLatLngByGooglePlaceId = async (
    placeId: string,
): Promise<google.maps.LatLng | null> => {
    const googleService = new GoogleService({
        apiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
    });

    return googleService.getLatLngFromPlaceId(placeId);
};

export {
    transformAddressLocationToFormDataValueType,
    transformAddressLocationToGooglePlacesOption,
    transformGooglePlacesOptionToAddressLocation,
    getLatLngByGooglePlaceId,
};
