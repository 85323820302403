import { models } from '@trova-trip/trova-models';
import { theme, Icon, IconName, IconSvg } from '@trova-trip/trova-components';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory, useLocation, matchPath } from 'react-router';
import { getTripDataFromModel } from 'util/ModelDataHelper';
import {
    modelTypes,
    PendingTrovaApprovalTrip,
} from '../../../../config/constants';
import NavigationContext from '../../../../navigation/NavigationContext';
import Product from '../../../../navigation/Product';
import { userLinks } from '../../../../state/links';
import { userTripRequests } from '../../../../state/userTripRequests';
import { userTripDetails, userTrips } from '../../../../state/userTrips';
import useTripRoute, {
    TRIP_ACTION,
} from '../../../common/hooks/trips/useTripRoute';
import { ProductDefaultView } from '../../components/ProductDefaultView/ProductDefaultView';
import TripPreview from './preview/TripPreview';
import TripsTabsPanel from './TripsTabsPanel';
import TripsContextProvider from './TripsContext';
import { ProductHeader } from 'applications/common/components/ProductHeader';
import ProductRootWithMobileNavigationView from 'applications/common/components/ProductRootWithMobileNavigationView';
import { NavigationCategory } from '../../../../interfaces/Navigation.types';
import {
    useGroundTransferFetcher,
    useGroundTransfersActions,
} from '../../../traveler/hooks';

type storeSelector = {
    userTrips: { current: models.trips.Trip };
    userTripDetails: { current: models.tripDetails.TripDetails };
    userTripRequests: { current: models.tripRequest.TripRequest };
    userLinks: { current: models.links.Link[] };
};
interface TripsProps {
    navigationContent: NavigationCategory[] | undefined;
}

const Trips = ({ navigationContent }: TripsProps): JSX.Element => {
    const context = useContext(NavigationContext);
    const { setShowLayout } = context;
    const history = useHistory();

    const location = useLocation();
    const previewRoute = useTripRoute({ action: TRIP_ACTION.PREVIEW });
    const isTripPreview = !!matchPath(location.pathname, previewRoute);

    const {
        userTrips: { current: currentTrip },
        userTripDetails: { current: currentTripDetails },
        userTripRequests: { current: currentTripRequest },
        userLinks: { current: currentUserLinks },
    } = useSelector((state: storeSelector) => state);

    const { getRecord: getTrip, clearCurrentRecord: clearCurrentTrip } =
        userTrips.useDispatch.bind(currentTrip)();
    const { getRecord: getTripRequest } =
        userTripRequests.useDispatch.bind(currentTripRequest)();
    const { getRecord: getTripDetails } =
        userTripDetails.useDispatch.bind(currentTripDetails)();
    const { getRecord: getUserLinks } =
        userLinks.useDispatch.bind(currentUserLinks)();

    const { clearGroundTransfers } = useGroundTransfersActions();

    useGroundTransferFetcher();

    const [currentModel, setCurrentModel] = useState<string | undefined>();

    useEffect(() => {
        const showLayout = !isTripPreview;
        setShowLayout(showLayout);
        return (): void => {
            setShowLayout(true);
        };
    }, [setShowLayout, isTripPreview]);

    const handleFetchModelData = useCallback(
        (id, { model }) => {
            if (model === modelTypes.TRIP) {
                clearCurrentTrip();
                clearGroundTransfers();
                getTrip(id);
                getTripDetails(id);
            } else {
                getTripRequest(id, { withExtendedData: true });
            }
            getUserLinks();
            setCurrentModel(model);
        },
        [
            getTrip,
            getTripRequest,
            getTripDetails,
            getUserLinks,
            clearCurrentTrip,
            clearGroundTransfers,
        ],
    );

    const isTripPendingTrovaApproval = PendingTrovaApprovalTrip.has(
        currentTrip?.status || '',
    );

    useEffect(() => {
        if (isTripPendingTrovaApproval) {
            getTripRequest(currentTrip.tripRequest.id, {
                withExtendedData: true,
            });
        }
    }, [currentTrip, getTripRequest, isTripPendingTrovaApproval]);

    const modelData =
        currentModel === modelTypes.TRIP ? currentTrip : currentTripRequest;

    const {
        tripCountry,
        tripDestination,
        tripHeaderPhoto,
        tripName,
        tripsDates,
        tripActivityLevel,
        tripInsuranceRequired,
    } = getTripDataFromModel(modelData, history);

    const [tripStartDate, tripEndDate] = tripsDates;

    const header = !isTripPreview ? (
        <ProductHeader
            backgroundImage={tripHeaderPhoto}
            title={tripName}
            subtitle={`${tripStartDate} - ${tripEndDate} ${
                tripActivityLevel ? `, ${tripActivityLevel}` : ''
            }`}
            listBanners={[
                {
                    icon: (
                        <Icon
                            name={IconName.Location}
                            color={theme.colors.red[400]}
                        />
                    ),
                    iconDescription: `${tripCountry}, ${tripDestination}`,
                },
                tripInsuranceRequired && {
                    icon: (
                        <IconSvg
                            as='healthAndSafety'
                            variant='outlined'
                            color={theme.colors.red[400]}
                        />
                    ),
                    iconDescription: 'Insurance required',
                },
            ]}
        />
    ) : null;

    return (
        <Product
            path={'/trips'}
            label={'My Trips'}
            icon='plane'
            fetchModelData={handleFetchModelData}
            loadNavigationContent={() => navigationContent}
            header={header}
            root={
                <ProductRootWithMobileNavigationView
                    rootView={
                        <ProductDefaultView
                            navigationContent={navigationContent}
                        />
                    }
                />
            }
        >
            {isTripPreview ? (
                <Route
                    path={previewRoute}
                    render={(): React.ReactNode => <TripPreview />}
                />
            ) : (
                <TripsContextProvider>
                    <TripsTabsPanel />
                </TripsContextProvider>
            )}
        </Product>
    );
};

export default Trips;
