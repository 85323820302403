import { useMemo } from 'react';
import {
    isAvailableBookingAddonsDetails,
    isAvailableBookingPaymentsHistory,
    isAvailableBookingBalanceDetails,
    isAvailableBookingSpotsDetails,
    isAvailableBookingCancelButton,
} from '../bookingUtils';
import { TripValidStatuses } from '../../interfaces/Trips.types';
import { SavedBooking } from '../../interfaces/Booking.types';

interface DynamicContentType {
    shouldShowPaymentsHistory: boolean;
    shouldShowAddons: boolean;
    shouldShowBalanceCard: boolean;
    shouldShowBookingSpotsCard: boolean;
    shouldShowCancelBookingButton: boolean;
}

const useDynamicContent = (
    isPrimaryTraveler: boolean,
    tripStatus?: TripValidStatuses,
    booking?: SavedBooking,
): DynamicContentType =>
    useMemo(() => {
        const bookingStatus = booking?.status;

        if (!tripStatus || !bookingStatus) {
            return {
                shouldShowPaymentsHistory: false,
                shouldShowAddons: false,
                shouldShowBalanceCard: false,
                shouldShowBookingSpotsCard: false,
                shouldShowCancelBookingButton: false,
            };
        }
        return {
            shouldShowPaymentsHistory:
                isPrimaryTraveler &&
                isAvailableBookingPaymentsHistory(tripStatus, bookingStatus),
            shouldShowAddons: isAvailableBookingAddonsDetails(
                tripStatus,
                bookingStatus,
            ),
            shouldShowBalanceCard: isAvailableBookingBalanceDetails(
                tripStatus,
                bookingStatus,
            ),
            shouldShowBookingSpotsCard: isAvailableBookingSpotsDetails(
                tripStatus,
                bookingStatus,
            ),
            shouldShowCancelBookingButton: isAvailableBookingCancelButton(
                tripStatus,
                booking,
                isPrimaryTraveler,
            ),
        };
    }, [tripStatus, isPrimaryTraveler, booking]);
export default useDynamicContent;
