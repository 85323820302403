import { useState, useEffect, SyntheticEvent } from 'react';
import { Number as NumberInput } from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';
import InlineToggleInput from '../InlineToggleInput/InlineToggleInput';
import { getServiceIndexById } from '../../../../util/bookingUtils';
import { SelectedWorkshopSpaces } from './TripRequestForm.types';

type WorkshopSpace = models.services.WorkshopSpace;

interface WorkshopUiModel {
    workshopLength: number;
    service: WorkshopSpace;
    isSelected: boolean;
    dayNumber: number;
}

interface WorkshopsInputProps {
    workShops: Array<Array<models.services.DayService<WorkshopSpace>>>;
    selectedWorkshopSpaces: SelectedWorkshopSpaces[];
    onChange: (value: SelectedWorkshopSpaces[]) => void;
    editMode: boolean;
}

const createUiModels = (
    availableWorkshops: Array<Array<models.services.DayService<WorkshopSpace>>>,
    selectedWorkshops: SelectedWorkshopSpaces[],
): WorkshopUiModel[] => {
    const uiModelList = availableWorkshops.flatMap((workshopsDay, index) =>
        workshopsDay.map(({ service }) => {
            const actualService = service as models.services.WorkshopSpace;
            const workshopIndex = selectedWorkshops.findIndex(
                (dayService) => dayService.id === actualService._id,
            );
            const isWorkshopSelected = workshopIndex > -1;
            const dayNumber = index + 1;

            return {
                service: actualService,
                workshopLength: selectedWorkshops[workshopIndex]?.length || 0,
                isSelected: isWorkshopSelected,
                dayNumber,
            };
        }),
    );

    return uiModelList;
};

const WorkshopsInput = ({
    workShops,
    selectedWorkshopSpaces = [],
    onChange,
    editMode,
}: WorkshopsInputProps): JSX.Element => {
    const [hoursInputError, setHoursInputError] = useState({});
    const [currentUiModelData, setCurrentUiModelData] = useState<
        WorkshopUiModel[]
    >([]);
    const uiModelData = createUiModels(workShops, selectedWorkshopSpaces);

    useEffect(() => {
        setCurrentUiModelData(uiModelData);
    }, [selectedWorkshopSpaces]);

    const updateModelData = (
        data: WorkshopUiModel[],
    ): SelectedWorkshopSpaces[] => {
        setCurrentUiModelData(data);
        const selectedWorkshops = currentUiModelData
            .filter((item) => item.isSelected)
            .map((item) => {
                const { workshopLength, service } = item;

                return {
                    length: workshopLength,
                    service,
                    id: service._id || '',
                };
            });

        return selectedWorkshops;
    };

    const handleInlineToggleInputInputChange = (
        id: string,
        event: SyntheticEvent,
    ): void => {
        const eventValue = (event.target as HTMLInputElement).checked;
        const serviceIndex = getServiceIndexById(id, currentUiModelData);
        const updatedUiModelData = [...currentUiModelData];
        updatedUiModelData[serviceIndex].isSelected = eventValue;
        const selectedWorkshops = updateModelData(updatedUiModelData);

        onChange(selectedWorkshops);
    };

    const handleWorkshopLengthChange = (
        id: string,
        eventValue: number,
    ): void => {
        const updatedUiModelData = [...currentUiModelData];
        const serviceIndex = getServiceIndexById(id, currentUiModelData);
        const availableHours =
            updatedUiModelData[serviceIndex].service.hoursAvailable || 0;
        const exceedAvailableHours = availableHours < eventValue;
        const workshopHoursExceededErrorMessage = `${availableHours} Hour(s) Limit`;
        if (exceedAvailableHours) {
            setHoursInputError((currentState) => {
                return {
                    ...currentState,
                    [id]: workshopHoursExceededErrorMessage,
                };
            });
        } else {
            setHoursInputError((currentState) => {
                return { ...currentState, [id]: '' };
            });

            updatedUiModelData[serviceIndex].workshopLength = eventValue;
            const selectedWorkshops = updateModelData(updatedUiModelData);

            onChange(selectedWorkshops);
        }
    };

    return (
        <>
            {uiModelData.map(
                ({ service, workshopLength, isSelected, dayNumber }, index) => {
                    const {
                        name: activityName,
                        description: activityDescription,
                    } = service;
                    const isCustomInputDisabled = !isSelected || !editMode;

                    return (
                        <InlineToggleInput
                            key={service._id}
                            title={`Day ${dayNumber}:  ${activityName}`}
                            onChange={(event, name): void => {
                                handleInlineToggleInputInputChange(name, event);
                            }}
                            description={activityDescription}
                            name={service._id || ''}
                            value={isSelected}
                            label={'Reserve space'}
                            customInput={
                                <NumberInput
                                    label={'Hours'}
                                    name={'workshop-length'}
                                    value={workshopLength}
                                    onChange={(_, __, value): void => {
                                        handleWorkshopLengthChange(
                                            service._id || '',
                                            value || 0,
                                        );
                                    }}
                                    disabled={isCustomInputDisabled}
                                    error={hoursInputError[service._id || '']}
                                />
                            }
                            disabled={!editMode}
                        />
                    );
                },
            )}
        </>
    );
};

export default WorkshopsInput;
