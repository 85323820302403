import { useCallback } from "react";
import FakeEvent from "../form/FakeEvent";

const _transformValueForFormSaver = (photos = []) => {
    if (Array.isArray(photos)) {
        const clonedPhotos = [...photos];

        const hero = clonedPhotos.shift();

        const itineraryPhotos = clonedPhotos.map(photo => ({ photo }));

        return {
            hero,
            itineraryPhotos,
        };
    }

    return {
        hero: photos,
        itineraryPhotos: [],
    };
};

const useOnChangeForHeroAndItineraryPhotos = onChange =>
    useCallback(
        ({ target: { name, value } }) => {
            onChange(new FakeEvent(name, _transformValueForFormSaver(value)));
        },
        [onChange]
    );

export default useOnChangeForHeroAndItineraryPhotos;
