import { Alert, Stack, StackProps } from '@trova-trip/trova-components';
import noop from 'lodash/noop';
import { ManageBookingError } from '../../../../../../state/features/manageBooking/types';

interface ErrorAlertProps extends StackProps {
    error: ManageBookingError;
}

const ErrorAlert = (props: ErrorAlertProps): JSX.Element | null => {
    const { error, ...rest } = props;

    return error ? (
        <Stack {...rest}>
            <Alert
                status='error'
                variant='inline'
                showBorderLeft={false}
                title={error.title}
                description={error.description}
                onCloseAlert={noop}
            />
        </Stack>
    ) : null;
};

export default ErrorAlert;
