import React, { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Controller from './Controller';
import withSuspensefulLoader from '../hoc/withSuspensefulLoader';
import { resetAppState } from '../state';

import Applications from '../applications';
import { applicationRootPath } from '../navigation/NavigationContext';
import { useAnalyzeRouterPage } from '../analytics';
import { analytics } from '@trova-trip/trova-common';

const Login = React.lazy(() => import(`./Login`));
const Admin = React.lazy(() => import(`./Admin`));
const ChangePassword = React.lazy(() => import(`./Login/ChangePassword`));
const Signup = React.lazy(() => import(`./Login/Signup`));
const ChangePasswordFromToken = React.lazy(() =>
    import(`./Login/ChangePasswordFromToken`),
);
const CreateAccountFromToken = React.lazy(() =>
    import(`./Login/CreateAccountFromToken`),
);
const ActivationError = React.lazy(() => import(`./Login/ActivationError`));
const ForgotPassword = React.lazy(() => import(`./Login/ForgotPassword`));
const ErrorPage = React.lazy(() => import(`../components/ErrorPage/ErrorPage`));

const Logout = () => {
    const [hasRendered, setHasRendered] = useState(false);
    if (!hasRendered) {
        resetAppState();
        analytics.resetUser();
        setHasRendered(true);
        return null;
    }
    return <Redirect to={`/`} />;
};

const Main = () => {
    useAnalyzeRouterPage();

    return (
        <Switch>
            <Route path={applicationRootPath} component={Applications} />
            <Route
                exact
                path={`/signup`}
                component={withSuspensefulLoader(Signup)}
            />
            <Route
                exact
                path={`/login`}
                component={withSuspensefulLoader(Login)}
            />
            <Route
                exact
                path={`/change-password`}
                component={withSuspensefulLoader(ChangePassword)}
            />
            <Route
                exact
                path={`/change-password/:token`}
                component={withSuspensefulLoader(ChangePasswordFromToken)}
            />
            <Route
                exact
                path={`/create-account/:token`}
                component={withSuspensefulLoader(CreateAccountFromToken)}
            />
            <Route
                exact
                path={`/activation-error/:error`}
                component={withSuspensefulLoader(ActivationError)}
            />
            <Route
                exact
                path={`/forgot-password`}
                component={withSuspensefulLoader(ForgotPassword)}
            />

            <Route
                exact
                path={`/error/:statusCode?`}
                component={withSuspensefulLoader(ErrorPage)}
            />

            <Route path={`/logout`} component={Logout} />
            <Route path={`/admin`} component={withSuspensefulLoader(Admin)} />
            <Route component={Controller} />
        </Switch>
    );
};

export default Main;
