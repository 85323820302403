import { Label, Stack, Text } from '@trova-trip/trova-components';
import { GeoLocationComboBoxField } from '@trova-trip/trova-components/build/custom';

const GoogleApiKey = process.env.REACT_APP_GOOGLE_API_KEY || '';

interface AddressLocationFieldProps {
    name: string;
    label: string;
    placeholder?: string;
    types?: string[];
    isDisabled?: boolean;
    helperText?: string;
}

const AddressLocationField = ({
    name,
    label,
    placeholder,
    types = [],
    isDisabled = false,
    helperText,
}: AddressLocationFieldProps): JSX.Element => (
    <Stack direction='column' spacing={2} width='full' align='search'>
        <Label id={`field-${name}-label`} htmlFor={`field-${name}`}>
            <Text fontSize='sm' fontWeight='medium' color='blueGray.dark'>
                {label}
            </Text>
        </Label>
        <GeoLocationComboBoxField
            type='google'
            name={name}
            placeholder={placeholder}
            locationTypes={types}
            icon={{ as: 'search' }}
            size='lg'
            apiKey={GoogleApiKey}
            isClearable
            isDisabled={isDisabled}
            helperText={helperText}
        />
    </Stack>
);

export default AddressLocationField;
