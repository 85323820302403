import headerImage from '../../../../../../assets/img/beach.jpg';
import { useSelector } from 'state/hooks';
import { startCase } from 'lodash';
import { ProductHeader as Header } from 'applications/common/components/ProductHeader';
import { useHistory } from 'react-router-dom';
import { BaseBox } from '@trova-trip/trova-components';

const AccountHeader = (): JSX.Element => {
    const user = useSelector((store) => store.profile.current);
    const history = useHistory();
    const title = user
        ? `${startCase(user.firstName)} ${startCase(user.lastName)}`
        : 'Your profile';

    const subtitle = startCase(user?.group) || '';

    const onBackButtonClick = () => history.goBack();

    return (
        <BaseBox marginBottom={9}>
            <Header
                backgroundImage={headerImage}
                title={title}
                subtitle={subtitle}
                backButtonConfig={{
                    show: { base: true, md: false },
                    onClick: onBackButtonClick,
                }}
            />
        </BaseBox>
    );
};

export default AccountHeader;
