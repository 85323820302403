import { Hide, Stack, Text } from '@trova-trip/trova-components';
import { BorderedInnerLinkCard } from '../../../../dashboard/components/common/BorderedInnerCard';

const labels = {
    title: 'Total Responses',
    info: 'Total responses do not count toward itinerary tiers',
};

interface TotalResponsesProps {
    totalResponses: number;
    onClick?: () => void;
}

const TotalResponses = ({ totalResponses, onClick }: TotalResponsesProps) => (
    <BorderedInnerLinkCard
        title={labels.title}
        height='full'
        paddingX={{ base: 3, md: 5 }}
        paddingY={{ base: 3, md: 3 }}
        onClick={onClick}
    >
        <Stack direction='column' spacing={2}>
            <Text fontSize='4xl' lineHeight='normal' fontWeight='bold'>
                {totalResponses}
            </Text>
            <Hide breakpoint={{ min: 'md' }}>
                <Text fontSize='xs'>{labels.info}</Text>
            </Hide>
        </Stack>
    </BorderedInnerLinkCard>
);

export default TotalResponses;
