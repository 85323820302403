import { useSelector } from 'react-redux';
import { UserHostStoreSelector } from '../../../state/hosts';
import { ProfileStoreSelector } from '../../../state/profile';
import { useTrackingEvent } from '../../../analytics/hooks';
import { useCallback } from 'react';

enum PUBLIC_PROFILE_DIALOG_LOCATION {
    emailCollectionCard = 'Audience - Email Collection',
    surveyResponsesCard = 'Audience - Survey Responses',
    homeChecklist = 'Home Checklist',
    globalNav = 'Global Nav',
}

enum PUBLIC_PROFILE_TRACKING_EVENTS {
    skippedCreateYourProfile = 'User Skipped Create Your Profile onboarding step',
    completedCreateYourProfile = 'User Completed Create Your Profile onboarding step',
    shareProfile = 'User clicked Share Profile',
    copyPublicProfileLink = 'User clicked Copy Public Profile Link in share modal',
    copyProfileLink = 'User clicked Copy Profile Link',
    clickedPublicProfileInAvatarDrawer = 'User clicked Public Profile in avatar drawer',
    viewPublicProfile = 'User clicked View Public Profile',
    editProfile = 'User clicked Edit Profile',
    clickedEditImage = 'User clicked Edit Image in Public Profile',
    clickedEditBioIcon = 'User clicked edit bio icon in public profile',
    clickedEditDisplayNameIcon = 'User clicked edit display name icon in public profile',
    clickedEditCountryIcon = 'User clicked edit country icon in public profile',
    clickedEditLanguagesIcon = 'User clicked edit languages icon in public profile',
    clickedEditPronounsIcon = 'User clicked edit pronouns icon in public profile',
    clickedEditInterestsIcon = 'User clicked edit interests icon in public profile',
    clickedEditSocialChannelsIcon = 'User clicked edit social channels icon in public profile',
    savedPublicProfile = 'User saved public profile',
}

type UsePublicProfileAnalyticsReturn = {
    trackPublicProfileEvent: (
        name: PUBLIC_PROFILE_TRACKING_EVENTS,
        properties?: Record<string, any>,
    ) => void;
};

const usePublicProfileAnalytics = (): UsePublicProfileAnalyticsReturn => {
    const currentHost = useSelector(
        (state: UserHostStoreSelector) => state.userHosts.current,
    );
    const currentUser = useSelector(
        (state: ProfileStoreSelector) => state.profile.current,
    );

    const { trackUserEvent } = useTrackingEvent();

    const trackPublicProfileEvent = useCallback(
        (
            name: PUBLIC_PROFILE_TRACKING_EVENTS,
            properties: Record<string, any> = {},
        ) => {
            trackUserEvent({
                eventName: name,
                properties: {
                    userId: currentUser?.id,
                    hostId: currentHost?._id,
                    status: currentUser?.status,
                    ...properties,
                },
            });
        },
        [currentHost, currentUser, trackUserEvent],
    );

    return { trackPublicProfileEvent };
};

export {
    PUBLIC_PROFILE_TRACKING_EVENTS,
    PUBLIC_PROFILE_DIALOG_LOCATION,
    usePublicProfileAnalytics as default,
};
