type TransferTabName = keyof typeof paths.tab;

const paths = {
    product: '/transfer/:tripId',
    tab: {
        root: '/',
        travel_detail: '/travel-detail',
        vehicle: '/vehicle',
        contact: '/contact',
        payment: '/payment',
        success: '/success',
    },
} as const;

export { paths };
export type { TransferTabName };
