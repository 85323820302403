import { coreUtils } from '@trova-trip/trova-common';
import { IconProps } from '@trova-trip/trova-components/build/next';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import {
    SingleSupplementAvailability,
    ToggleActionType,
    ValidOccupanciesType,
} from '../../../../../state/features/manageBooking/types';
import {
    Accommodation,
    Accommodations,
    MANAGE_BOOKING_ADDON_STATUS,
    ManageBookingAddOn,
    Occupancy,
    OccupancyType,
    SavedBooking,
    Service,
    ServiceTiming,
    ServiceTimingType,
    ServiceType,
    Transfers,
    TravelersRoom,
    TravelersRooms,
    Trip,
} from '../types';
import {
    checkAccommodationStatus,
    getAddOnStatusAndType,
    isPrePostAccommodationSelected,
    isPrePostTransferSelected,
    isPrivateRoomUpgradeAvailable,
    isTravelersRoomsType,
    isUpgradeToPrivateRoomSelected,
} from './check.utils';

const {
    bookingUtils: { getPrePostAddOnsPrice },
} = coreUtils;

type AccommodationServiceTiming = Accommodations['PRE_TRIP' | 'POST_TRIP'];

export interface TravelerRoomWithTypeAndStatus {
    roomType: ValidOccupanciesType;
    status: MANAGE_BOOKING_ADDON_STATUS;
}

interface GetPrePostAccommodationAddOnsPriceParams {
    isUpgradeToPrivateRoomSelected: boolean;
    accommodations: Accommodations;
    roomsOccupancies: ValidOccupanciesType[];
    travelersRooms: TravelersRooms;
    travelersQuantity: number;
}

type PreAndPostPrices = {
    preTripPrice: number;
    postTripPrice: number;
};

type GetFirstPreAndPostTransfersAddOnReturn = {
    preTransferAddOn?: ManageBookingAddOn;
    postTransferAddOn?: ManageBookingAddOn;
};

type GetPrePostPendingAddOnsReturn = {
    preAccommodationPendingAddOns: ManageBookingAddOn[];
    postAccommodationPendingAddOns: ManageBookingAddOn[];
    preTransferPendingAddOns: ManageBookingAddOn[];
    postTransferPendingAddOns: ManageBookingAddOn[];
};

type GetPrePostRoomsOccupanciesParams = {
    rooms: TravelersRooms | ValidOccupanciesType[];
    travelersQuantity: number;
    singleSupplement: Accommodations['singleSupplement'];
    isUpgradeFormValue?: boolean;
    defaultRoomOccupancy: OccupancyType | undefined;
};

/**
 * Get the icon properties based on the add-on status
 *
 * @param status
 * @returns The icon properties object or undefined if the status is not confirmed or pending
 */
const getAddOnStatusIconProps = (
    status: MANAGE_BOOKING_ADDON_STATUS | undefined,
): IconProps | undefined => {
    const commonStatusProps: Pick<IconProps, 'size' | 'display'> = {
        size: 'lg',
        display: 'inline-flex',
    };

    const commonPendingProps: Pick<IconProps, 'as' | 'color'> = {
        as: 'shoppingCart',
        color: 'orange.800',
    };

    const pendingStatusProps = {
        ...commonStatusProps,
        ...commonPendingProps,
    };

    const statusConfigs: Record<
        MANAGE_BOOKING_ADDON_STATUS,
        IconProps | undefined
    > = {
        [MANAGE_BOOKING_ADDON_STATUS.CONFIRMED]: {
            ...commonStatusProps,
            as: 'checkedCircle',
            color: 'teal.trova',
        },
        [MANAGE_BOOKING_ADDON_STATUS.PENDING_ADDITION]: pendingStatusProps,
        [MANAGE_BOOKING_ADDON_STATUS.PENDING_REMOVAL]: pendingStatusProps,
        [MANAGE_BOOKING_ADDON_STATUS.AVAILABLE]: undefined,
    };

    return status ? statusConfigs[status] : undefined;
};

/**
 * Gets an array of selected traveler rooms from the given rooms object.
 *
 * @param rooms - The object containing room occupancies for travelers with room type
 * @param singleSupplement
 * @param travelersQuantity
 * @returns An array of traveler rooms.
 */
const getSelectedTravelersRooms = (
    rooms: TravelersRooms,
    singleSupplement?: Accommodations['singleSupplement'],
    travelersQuantity?: number,
): TravelerRoomWithTypeAndStatus[] => {
    const roomTypeMapping = {
        Single: Occupancy.single,
        Double: Occupancy.double,
        Twin: Occupancy.twin,
    };
    const isUpgradeSelected = isUpgradeToPrivateRoomSelected(
        singleSupplement,
        travelersQuantity || 0,
    );

    let selectedRooms: TravelerRoomWithTypeAndStatus[];

    /**
     * If the upgrade to private room is selected, we return an array of default rooms.
     *
     * There are two cases on this scenario:
     * 1. The travelers quantity is 1 (technically the upgrade doesn't exist, but the condition is the same), so we return a single room.
     * 2. The travelers quantity is greater than 1, so we return an array of twin rooms.
     *
     * Otherwise, we return an array of rooms based on the room occupancies from the state.
     */
    if (isUpgradeSelected) {
        selectedRooms = Object.keys(rooms).map(() => {
            const roomType =
                travelersQuantity === 1
                    ? roomTypeMapping['Single']
                    : roomTypeMapping['Twin'];

            return {
                status: MANAGE_BOOKING_ADDON_STATUS.CONFIRMED,
                roomType,
            };
        }) as TravelerRoomWithTypeAndStatus[];
    } else {
        selectedRooms = Object.values(rooms).reduce((acc, roomOccupancies) => {
            const confirmedRooms = Object.entries(roomOccupancies)
                .map(([roomType, roomStatus]) => ({
                    status: roomStatus,
                    roomType: roomTypeMapping[roomType] as ValidOccupanciesType,
                }))
                .filter(
                    (room) =>
                        room.status === MANAGE_BOOKING_ADDON_STATUS.CONFIRMED ||
                        room.status ===
                            MANAGE_BOOKING_ADDON_STATUS.PENDING_ADDITION,
                );
            return [...acc, ...confirmedRooms];
        }, []);
    }

    if (singleSupplement) {
        const { isConfirmedAddOn, isPendingAdditionAddOn } =
            getAddOnStatusAndType(singleSupplement);

        const shouldReplaceLastRoom =
            singleSupplement.quantity !== travelersQuantity &&
            (isConfirmedAddOn || isPendingAdditionAddOn);

        if (shouldReplaceLastRoom) {
            selectedRooms[selectedRooms.length - 1] = {
                status: singleSupplement.status,
                roomType: Occupancy.single,
            };
        }
    }

    return selectedRooms;
};

const statusOrder: MANAGE_BOOKING_ADDON_STATUS[] = [
    MANAGE_BOOKING_ADDON_STATUS.CONFIRMED,
    MANAGE_BOOKING_ADDON_STATUS.PENDING_ADDITION,
    MANAGE_BOOKING_ADDON_STATUS.PENDING_REMOVAL,
];

/**
 * Get the icon props based on the accommodation status.
 *
 * @param accommodationServiceTiming - Accommodation in a specific service timing.
 * @returns Icon props based on the matching status, or undefined if no match.
 */
const getPrePostAccommodationIconProps = (
    accommodationServiceTiming: AccommodationServiceTiming,
): IconProps | undefined => {
    const matchingStatus = statusOrder.find((status) =>
        checkAccommodationStatus(status, accommodationServiceTiming),
    );

    if (matchingStatus) {
        return getAddOnStatusIconProps(matchingStatus);
    }

    return undefined;
};

/**
 * Returns an array with the room configuration.
 *
 * @param travelersRooms
 * @returns An array of the room configuration.
 */
const getRoomsOccupancies = (
    travelersRooms: TravelersRooms,
): ValidOccupanciesType[] => {
    if (isEmpty(travelersRooms)) {
        return [];
    }

    return Object.values(travelersRooms).reduce((confirmedRooms, rooms) => {
        return confirmedRooms.concat(
            (Object.keys(rooms) as OccupancyType[]).filter((occupancy) => {
                const roomStatus = rooms[
                    occupancy
                ] as MANAGE_BOOKING_ADDON_STATUS;

                return (
                    roomStatus === MANAGE_BOOKING_ADDON_STATUS.CONFIRMED ||
                    roomStatus === MANAGE_BOOKING_ADDON_STATUS.PENDING_ADDITION
                );
            }) as ValidOccupanciesType[],
        );
    }, [] as ValidOccupanciesType[]);
};

/**
 * Get the first pre and post transfers addOn.
 *
 * @param transfers
 * @returns pre and post transfers addOn.
 */
const getFirstPreAndPostTransfersAddOn = (
    transfers: Transfers,
): GetFirstPreAndPostTransfersAddOnReturn => {
    const transfersKeys = Object.values(transfers);
    const preTransferAddOn = transfersKeys[0]?.[ServiceTiming.PRE_TRIP];
    const postTransferAddOn = transfersKeys[0]?.[ServiceTiming.POST_TRIP];

    return { preTransferAddOn, postTransferAddOn };
};

/**
 * Calculates the pre and post transfer add-ons prices.
 *
 * @param transfers
 * @returns pre and post transfer add-ons prices
 */
const getTransfersAddOnsPrices = (transfers: Transfers): PreAndPostPrices => {
    const { preTransferAddOn, postTransferAddOn } =
        getFirstPreAndPostTransfersAddOn(transfers);

    const travelersQuantity = Object.keys(transfers).length;

    const preTripPrice =
        (preTransferAddOn?.unitPriceWithFee ?? 0) * travelersQuantity;
    const postTripPrice =
        (postTransferAddOn?.unitPriceWithFee ?? 0) * travelersQuantity;

    return { preTripPrice, postTripPrice };
};

/**
 * Get the action type for toggling an add-on.
 *
 * @param isSelected - True if add-on status is pending addition or confirmed, false otherwise.
 * @returns The toggle add-on action type (ADD or REMOVE).
 */
const getToggleAddOnActionType = (isSelected: boolean): ToggleActionType => {
    return isSelected ? ToggleActionType.REMOVE : ToggleActionType.ADD;
};

/**
 * Calculate the prices for pre and post trip accommodations add-ons.
 * For old bookings (empty travelers rooms) we use the single occupancy add-on and for new ones
 * we calculate the price using getPrePostAddOnsPrice from Core
 *
 * @param {GetPrePostAccommodationAddOnsPriceParams} params - The parameters needed for the calculation.
 * @returns An object containing the prices for pre and post trip accommodations.
 */
const getPrePostAccommodationsPrice = ({
    isUpgradeToPrivateRoomSelected,
    accommodations,
    roomsOccupancies,
    travelersRooms,
    travelersQuantity,
}: GetPrePostAccommodationAddOnsPriceParams): PreAndPostPrices => {
    const { preTripPrice, postTripPrice } = isEmpty(travelersRooms)
        ? {
              preTripPrice:
                  (accommodations[ServiceTiming.PRE_TRIP]?.[Occupancy.single]
                      ?.unitPriceWithFee ?? 0) * travelersQuantity,
              postTripPrice:
                  (accommodations[ServiceTiming.POST_TRIP]?.[Occupancy.single]
                      ?.unitPriceWithFee ?? 0) * travelersQuantity,
          }
        : getPrePostAddOnsPrice(
              isUpgradeToPrivateRoomSelected,
              accommodations,
              roomsOccupancies,
              travelersQuantity,
          );

    return { preTripPrice, postTripPrice };
};

/**
 * Get the rooms occupancies for pre/post accommodations based on selected traveler rooms and quantity.
 *
 * @param params.rooms
 * @param params.travelersQuantity
 * @param params.singleSupplement
 * @param params.isUpgradeFormValue -  This is used to get the current upgrade to private room value from the form.
 * @returns An array of room occupancies for pre/post accommodations.
 */
const getPrePostRoomsOccupancies = ({
    rooms,
    travelersQuantity,
    singleSupplement,
    isUpgradeFormValue,
    defaultRoomOccupancy,
}: GetPrePostRoomsOccupanciesParams): ValidOccupanciesType[] => {
    const isUpgradeSelected = isUpgradeToPrivateRoomSelected(
        singleSupplement,
        travelersQuantity,
    );

    /**
     * We need to check if the upgrade to private room is selected on the state, or is selected as a current value on the form to avoid returning the
     * wrong room occupancies.
     *
     * - If the upgrade to private room is selected on the form, we return an array of single rooms.
     * - If the upgrade is selected on the state, we need to ensure the `rooms` are of type `TravelersRooms` and return an array of single rooms.
     */
    if (
        isUpgradeFormValue ||
        (isUpgradeSelected && isTravelersRoomsType(rooms))
    ) {
        return [...Array(travelersQuantity)].map(
            () => Occupancy.single,
        ) as ValidOccupanciesType[];
    }

    let roomsOccupancies: ValidOccupanciesType[] = [];

    if (isTravelersRoomsType(rooms)) {
        roomsOccupancies = getSelectedTravelersRooms(
            rooms,
            singleSupplement,
            travelersQuantity,
        ).map((travelerRoom) => travelerRoom.roomType);
    } else {
        roomsOccupancies = rooms;
    }

    const isEmptyRoomOccupancies = isEmpty(roomsOccupancies);

    if (isEmptyRoomOccupancies && defaultRoomOccupancy) {
        return getDefaultTravelersRooms(
            travelersQuantity,
            defaultRoomOccupancy,
        ).map((room) => room.roomType) as ValidOccupanciesType[];
    }

    return roomsOccupancies.map((roomType, index) => {
        const isLastRoom = index === roomsOccupancies.length - 1;
        const isTravelersQuantityOdd = travelersQuantity % 2 !== 0;
        const isLastRoomAndTravelersQuantityIsOdd =
            isLastRoom && isTravelersQuantityOdd;

        return isLastRoomAndTravelersQuantityIsOdd
            ? Occupancy.single
            : roomType;
    });
};

/**
 * Get the pre/post accommodation timings selected.
 *
 * @param accommodations
 * @returns An array of pre/post accommodation timings selected.
 */
const getPreAndPostAccommodationTimingSelected = (
    accommodations: Accommodations,
): ServiceTimingType[] => {
    const timingsToUpdate: ServiceTimingType[] = [];

    const isPreTripAccommodationSelected = isPrePostAccommodationSelected(
        accommodations[ServiceTiming.PRE_TRIP],
    );
    const isPostTripAccommodationSelected = isPrePostAccommodationSelected(
        accommodations[ServiceTiming.POST_TRIP],
    );

    if (isPreTripAccommodationSelected) {
        timingsToUpdate.push(ServiceTiming.PRE_TRIP);
    }

    if (isPostTripAccommodationSelected) {
        timingsToUpdate.push(ServiceTiming.POST_TRIP);
    }

    return timingsToUpdate;
};

/**
 * Get the pre/post transfers timings selected.
 *
 * @param transfers
 * @returns An array of pre/post transfers timings selected.
 */
const getPreAnPostTransfersTimingSelected = (
    transfers: Transfers,
): ServiceTimingType[] => {
    return Object.keys(transfers).reduce(
        (timingsToUpdate: ServiceTimingType[], travelerId) => {
            const travelerTransfers = transfers[travelerId];
            const isPreTransferSelected = isPrePostTransferSelected(
                travelerTransfers[ServiceTiming.PRE_TRIP],
            );
            const isPostTransferSelected = isPrePostTransferSelected(
                travelerTransfers[ServiceTiming.POST_TRIP],
            );

            return [
                ...timingsToUpdate,
                ...(isPreTransferSelected ? [ServiceTiming.PRE_TRIP] : []),
                ...(isPostTransferSelected ? [ServiceTiming.POST_TRIP] : []),
            ];
        },
        [],
    );
};

/**
 * Filter a list of add-ons by the provided status.
 *
 * @param addOns
 * @param status
 * @returns An array of filtered add-ons.
 */
const filterAddOnsByStatus = (
    addOns: ManageBookingAddOn[],
    status: MANAGE_BOOKING_ADDON_STATUS[],
): ManageBookingAddOn[] => {
    return addOns.filter((addOn) => status.includes(addOn.status));
};

/**
 * Get the default travelers rooms based on the travelers quantity and default room occupancy
 *
 * @param travelersQuantity
 * @param defaultOccupancy
 * @returns An array of travelers rooms with the default occupancy.
 */
const getDefaultTravelersRooms = (
    travelersQuantity: number,
    defaultOccupancy: OccupancyType,
): TravelersRoom[] => {
    if (defaultOccupancy === Occupancy.single) {
        return [...Array(travelersQuantity)].map(() => ({
            roomType: defaultOccupancy,
        }));
    }

    const defaultTravelersRooms = [...Array(travelersQuantity)].reduce(
        (travelersRooms, _, index) => {
            if (index % 2 == 0) {
                const travelerRoom: TravelersRoom = {
                    roomType: defaultOccupancy,
                };
                return [...travelersRooms, travelerRoom];
            }
            return travelersRooms;
        },
        [],
    );

    return defaultTravelersRooms;
};

/**
 * Get the travelers rooms on upgrade to private room.
 *
 * @param travelersQuantity
 * @returns An array of travelers rooms with single occupancy.
 */
const getTravelersRoomsOnUpgrade = (
    travelersQuantity: number,
): TravelersRoom[] => {
    const travelersRoomsWithUpgradeToPrivate = [
        ...Array(travelersQuantity),
    ].map(() => ({
        roomType: Occupancy.single,
    })) as TravelersRoom[];

    return travelersRoomsWithUpgradeToPrivate;
};

/**
 * Get the traveler room status based on the room type, occupancy and if the upgrade to private room is selected.
 *
 * @param roomType
 * @param occupancy
 * @param isUpgradeToPrivateRoomSelected
 * @param isMultipleTravelersBooking
 * @param hasSingleSupplementAddOn
 * @returns Traveler room status
 */
const getTravelerRoomStatus = (
    roomType: OccupancyType,
    occupancy: OccupancyType,
    isUpgradeToPrivateRoomSelected: boolean,
    isMultipleTravelersBooking: boolean,
    hasSingleSupplementAddOn: boolean,
): MANAGE_BOOKING_ADDON_STATUS => {
    if (isUpgradeToPrivateRoomSelected) {
        return MANAGE_BOOKING_ADDON_STATUS.AVAILABLE;
    }

    if (!isMultipleTravelersBooking && hasSingleSupplementAddOn) {
        return occupancy === Occupancy.single
            ? MANAGE_BOOKING_ADDON_STATUS.CONFIRMED
            : MANAGE_BOOKING_ADDON_STATUS.AVAILABLE;
    }

    return roomType === occupancy
        ? MANAGE_BOOKING_ADDON_STATUS.CONFIRMED
        : MANAGE_BOOKING_ADDON_STATUS.AVAILABLE;
};

/**
 * Extract all pending removal and pending addition activities add-ons.
 *
 * @param addOns
 * @returns An array of pending removal and pending addition activities add-ons.
 */
const getPendingActivitiesAddOns = (
    addOns: ManageBookingAddOn[],
): ManageBookingAddOn[] => {
    return addOns.filter((addOn) => {
        const { isActivityAddOn } = getAddOnStatusAndType(addOn);
        return (
            isActivityAddOn &&
            (addOn.status === MANAGE_BOOKING_ADDON_STATUS.PENDING_ADDITION ||
                addOn.status === MANAGE_BOOKING_ADDON_STATUS.PENDING_REMOVAL)
        );
    });
};

/**
 * Extract all add-ons with no sideEffect manageMode.
 *
 * @param addOns
 * @returns An array of add-ons with default manageMode.
 */
const getAddOnsWithNoSideEffects = (
    addOns: ManageBookingAddOn[],
): ManageBookingAddOn[] =>
    addOns?.filter((addOn) => addOn.manageMode === 'default');

/*
 * Extracts pre-trip and post-trip pending accommodations and transfers add-ons
 * @param addOns
 * @returns Pre-trip and post-trip accommodations and transfers pending add-ons.
 */
const getPrePostPendingAddOns = (
    addOns: ManageBookingAddOn[],
): GetPrePostPendingAddOnsReturn => {
    const getItemsByTimingAndServiceType = (
        timing: ServiceTimingType,
        serviceType: keyof typeof ServiceType,
    ): ManageBookingAddOn[] => {
        return addOns.filter((item) => {
            const itemService = item?.service as Service;
            return (
                itemService?.timing === ServiceTiming[timing] &&
                itemService?.type === ServiceType[serviceType]
            );
        });
    };

    return {
        preAccommodationPendingAddOns: getItemsByTimingAndServiceType(
            ServiceTiming.PRE_TRIP,
            'ACCOMMODATION',
        ),
        postAccommodationPendingAddOns: getItemsByTimingAndServiceType(
            ServiceTiming.POST_TRIP,
            'ACCOMMODATION',
        ),
        preTransferPendingAddOns: getItemsByTimingAndServiceType(
            ServiceTiming.PRE_TRIP,
            'AIRPORT_TRANSFER',
        ),
        postTransferPendingAddOns: getItemsByTimingAndServiceType(
            ServiceTiming.POST_TRIP,
            'AIRPORT_TRANSFER',
        ),
    };
};

/**
 * Extracts accommodations add-ons ids by service id
 *
 * @param booking
 * @returns Accommodations add-ons ids by service id
 */
const getAccommodationsAddOnsIds = (
    booking: SavedBooking,
): { [serviceId: string]: string[] } => {
    if (!booking.addOns) return {};

    const accommodationsAddOns = booking.addOns.filter((addOn) => {
        const { isAccommodationAddOn } = getAddOnStatusAndType(
            addOn as ManageBookingAddOn,
        );

        return isAccommodationAddOn;
    });

    const accommodationsIds = accommodationsAddOns.reduce((acc, addOn) => {
        const serviceId = (addOn.service as Service)._id;

        if (!serviceId) return acc;

        acc[serviceId] = acc[serviceId] || [];

        return {
            ...acc,
            [serviceId]: [...acc[serviceId], addOn.addOnId],
        };
    }, {});

    return accommodationsIds;
};

/**
 * Get the default room occupancy depending on the occupancies of the accommodation additional optional services.
 * @param additionalOptionalServices
 * @returns The default room occupancy type
 */
const getDefaultRoomOccupancy = (
    additionalOptionalServices: Trip['additionalOptionalServices'],
): OccupancyType => {
    const accommodationServices = additionalOptionalServices?.filter(
        (service: Service) =>
            service?.type === ServiceType.ACCOMMODATION &&
            service.bookable &&
            !service.deleted,
    );

    const uniqueOccupancies = new Set(
        accommodationServices?.map((service) =>
            capitalize((service as Accommodation).occupancy),
        ) || [],
    );

    if (uniqueOccupancies.has(Occupancy.twin)) {
        return Occupancy.twin;
    }

    if (uniqueOccupancies.has(Occupancy.double)) {
        return Occupancy.double;
    }

    return Occupancy.single;
};

/**
 * Gets the boolean values of the single supplement availability.
 *
 * @param travelersQuantity
 * @param availableSingleSupplements
 * @returns An object that contains the single supplement availability.
 */
const getSingleSupplementAvailability = (
    travelersQuantity: number,
    availableSingleSupplements: number,
): SingleSupplementAvailability => {
    return {
        isPrivateRoomUpgradeAvailable: isPrivateRoomUpgradeAvailable(
            availableSingleSupplements,
            travelersQuantity,
        ),
        isPrivateRoomAvailable: availableSingleSupplements > 0,
    };
};

export {
    filterAddOnsByStatus,
    getAccommodationsAddOnsIds,
    getAddOnStatusIconProps,
    getAddOnsWithNoSideEffects,
    getDefaultRoomOccupancy,
    getDefaultTravelersRooms,
    getFirstPreAndPostTransfersAddOn,
    getPendingActivitiesAddOns,
    getPreAnPostTransfersTimingSelected,
    getPreAndPostAccommodationTimingSelected,
    getPrePostAccommodationIconProps,
    getPrePostAccommodationsPrice,
    getPrePostPendingAddOns,
    getPrePostRoomsOccupancies,
    getRoomsOccupancies,
    getSelectedTravelersRooms,
    getToggleAddOnActionType,
    getTransfersAddOnsPrices,
    getTravelerRoomStatus,
    getTravelersRoomsOnUpgrade,
    getSingleSupplementAvailability,
};
