import createDayRefName from '../createDayRefName';
import { useEffect, useState } from 'react';

const useGetServiceByDayRefIds = data => {
    const [dayItemIds, setDayItemIds] = useState([]);

    useEffect(
        () => {
            if (data && data.servicesByDay) {
                setDayItemIds(
                    data.servicesByDay.map((service, index) =>
                        createDayRefName(index)
                    )
                );
            }
        },
        [data]
    );

    return dayItemIds;
};
export default useGetServiceByDayRefIds;
