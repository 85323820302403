import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import FetchState from './FetchState';

class ServiceTypeFetchState {
    static create(path, isStandardApi) {
        return new ServiceTypeFetchState(path, isStandardApi);
    }

    constructor(path, isStandardApi = true) {
        this.fetchState = new FetchState(path, isStandardApi);
        this.path = path;
    }

    getReducer() {
        return this.fetchState.getReducer();
    }

    listRecords(query, type, callbacks = {}) {
        this.fetchState.updatePath(`${this.path}/${type}`);
        return this.fetchState.listRecords(query, callbacks);
    }

    getRecord(recordId = ``, type, query, callbacks = {}) {
        this.fetchState.updatePath(`${this.path}/${type}`);
        return this.fetchState.getRecord(recordId, query, callbacks);
    }

    createRecord(recordData, type, callbacks = {}) {
        this.fetchState.updatePath(`${this.path}/${type}`);
        return this.fetchState.createRecord(recordData, callbacks);
    }

    updateRecord(recordId = ``, type, recordData, callbacks = {}) {
        this.fetchState.updatePath(`${this.path}/${type}`);
        return this.fetchState.updateRecord(recordId, recordData, callbacks);
    }

    search(limit, type, searchText, callbacks = {}) {
        this.fetchState.updatePath(`${this.path}/`);
        return this.fetchState.searchRecords(
            { limit, type, search: searchText },
            callbacks
        );
    }

    mapDispatchToProps = dispatch => {
        const self = this;
        return {
            listRecords(query, type, callbacks) {
                dispatch(self.listRecords(query, type, callbacks));
            },
            getRecord(recordId, type, query, callbacks) {
                dispatch(self.getRecord(recordId, type, query, callbacks));
            },
            createRecord(data, type, callbacks) {
                dispatch(self.createRecord(data, type, callbacks));
            },
            updateRecord(recordId, type, data, callbacks) {
                dispatch(self.updateRecord(recordId, type, data, callbacks));
            },
            search(limit, type, searchText, callbacks) {
                dispatch(self.search(limit, type, searchText, callbacks));
            },
        };
    };

    useDispatch = () => {
        const dispatch = useDispatch();
        return useMemo(() => this.mapDispatchToProps(dispatch), [dispatch]);
    };
}

export default ServiceTypeFetchState;
