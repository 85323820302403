import { useRouteMatch } from 'react-router-dom';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import NavigationContext from '../../navigation/NavigationContext';
import { APPLICATION_ROUTES_METADATA } from '../../config/constants';

interface UseProductHeaderVisibilityReturn {
    isProductHeaderVisible: boolean;
    setIsProductHeaderVisible: Dispatch<SetStateAction<boolean>>;
}

const useProductHeaderVisibility = (): UseProductHeaderVisibilityReturn => {
    const routesWithoutProductHeader = Object.keys(
        APPLICATION_ROUTES_METADATA,
    ).filter((key) => !APPLICATION_ROUTES_METADATA[key].showHeader);

    const isCurrentRouteWithoutProductHeader = !!useRouteMatch({
        path: routesWithoutProductHeader,
        exact: true,
    });

    const { isProductHeaderVisible, setIsProductHeaderVisible } =
        useContext(NavigationContext);

    useEffect(() => {
        setIsProductHeaderVisible(!isCurrentRouteWithoutProductHeader);
    }, [isCurrentRouteWithoutProductHeader]);

    return {
        isProductHeaderVisible,
        setIsProductHeaderVisible,
    };
};

export default useProductHeaderVisibility;
