import { useMemo } from 'react';
import { PricingCalculator, coreUtils } from '@trova-trip/trova-common';
import { models } from '@trova-trip/trova-models';

type Availability = models.itineraries.Availability;
type UseTripRequestPricing = {
    itinerary: models.itineraries.Itinerary;
    tripRequestCostDTO: PricingCalculator.TripRequestCostDTO;
    travelersTierNumber: number;
    packageAvailability: Availability;
};

export const useTripRequestPricing = ({
    itinerary,
    tripRequestCostDTO,
    travelersTierNumber,
    packageAvailability,
}: UseTripRequestPricing): number => {
    const tripPricing = useMemo(() => {
        if (tripRequestCostDTO.startDate && packageAvailability) {
            return coreUtils.tripRequestUtils.getSuggestedSellPriceFromTripRequest(
                itinerary,
                packageAvailability,
                tripRequestCostDTO,
            );
        }
    }, [itinerary, tripRequestCostDTO, travelersTierNumber]);

    return tripPricing ?? 0;
};
