import { useEffect } from 'react';
import {
    BaseUser,
    UserTripsTraveler,
} from '../../../state/features/transfer/types';
import { useSelector } from '../../../state/hooks';
import { isHost, isSecondaryUser, isTraveler } from '../../common/helpers';
import { getBookedGroundTransferIds } from '../../common/helpers/transfer';
import useGroundTransfersActions from './useGroundTransfersActions';

/**
 * Hook to fetch the user's booked ground transfers using a list of transfer IDs
 * from the booking, and to set them on the global store. The ground transfers will be fetched only if:
 *
 * - There is current list of IDs of ground transfers to fetch
 * - The ground transfers weren't fetched before or are not hydrated on the store
 * - The is a current booking on the store
 */
const useGroundTransferFetcher = (): void => {
    const { trip, booking, user, userGroundTransfers, tripTravelerUser } =
        useSelector((state) => ({
            trip: state.userTrips?.current,
            booking: state.userBookings?.current?.[0],
            user: state.profile.current,
            userGroundTransfers: state.userGroundTransfers.list,
            tripTravelerUser: state.userTripsTraveler.list?.records?.[0],
        }));

    const { loadGroundTransfers } = useGroundTransfersActions();

    const currentUser = user as BaseUser;

    const travelerType = (tripTravelerUser as UserTripsTraveler)?.type;

    useEffect(() => {
        const hasUserGroundTransfers = !!userGroundTransfers;
        const hasTripTravelerUser = !!tripTravelerUser;
        const isTravelerUser = isTraveler(currentUser);
        const isSecondaryTraveler = isSecondaryUser(travelerType);

        if (
            hasUserGroundTransfers ||
            !hasTripTravelerUser ||
            isSecondaryTraveler
        )
            return;

        /**
         * If the user is a traveler, we need to wait for the booking to be fetched before we can fetch the ground transfers
         * with fresh data, if the user is a host, we can fetch the ground transfers right away.
         */
        const hasCurrentBooking = !!booking;
        const shouldLoadTransfers =
            (isTravelerUser && hasCurrentBooking) || isHost(currentUser);

        const transferIdsList = getBookedGroundTransferIds(booking, trip, user);
        const hasTransferIds = transferIdsList && transferIdsList.length > 0;

        if (hasTransferIds && shouldLoadTransfers) {
            loadGroundTransfers(transferIdsList);
        }
    }, [
        booking,
        trip,
        user,
        userGroundTransfers,
        tripTravelerUser,
        currentUser,
    ]);
};

export default useGroundTransferFetcher;
