import { models } from '@trova-trip/trova-models';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import { getCountryAlpha3 } from './common';
import { APP_ENVIRONMENT } from '../../utils';
import { appConstants } from '@trova-trip/trova-common';

type Trip = models.trips.Trip;

const getEVisaLink = (trip: Trip, userCountry: string = ''): string => {
    const environment = APP_ENVIRONMENT as appConstants.Environment;
    const baseURL = appConstants.TrovaTripAppsDomainMap[environment].marketing;

    const destination = getCountryAlpha3(trip.country);
    const passportNationality = getCountryAlpha3(userCountry);

    const queryParams = { destination, passportNationality };

    const queryString = new URLSearchParams(
        omitBy(queryParams, isEmpty),
    ).toString();

    return `${baseURL}/travel-visa?${queryString}`;
};

export { getEVisaLink };
