import { constants } from '@trova-trip/trova-models';
import isEmpty from 'lodash/isEmpty';
import {
    OriginallyAvailableAccommodations,
    ValidOccupanciesType,
} from '../../../../../state/features/manageBooking/types';
import {
    Accommodations,
    BaseUser,
    BookingAddOn,
    InsuranceAddOnStatus,
    InsurancePolicyAddOnResponse,
    MANAGE_BOOKING_ADDON_STATUS,
    ManageBookingAddOn,
    Service,
    ServiceTiming,
    ServiceTimingType,
    ServiceType,
    Transfers,
    TravelersRooms,
} from '../types';

const { AddOnTypes } = constants.bookings;

type AccommodationServiceTiming = Accommodations['PRE_TRIP' | 'POST_TRIP'];

type TransfersServiceTiming = Transfers[0]['PRE_TRIP' | 'POST_TRIP'];

interface CheckTripHasPrePostAddOnsParams {
    accommodations: Accommodations;
    travelersQuantity: number;
    travelersRooms: TravelersRooms;
    roomsOccupancies: ValidOccupanciesType[];
    preTransferAddOn?: ManageBookingAddOn;
    postTransferAddOn?: ManageBookingAddOn;
}

type TripHasPrePostAddOns = {
    tripHasPreAccommodation: boolean;
    tripHasPostAccommodation: boolean;
    tripHasPreOrPostAccommodations: boolean;
    tripHasPreTransfer: boolean;
    tripHasPostTransfer: boolean;
};

type AddOnTypeFlags = {
    isAccommodationAddOn: boolean;
    isTransferAddOn: boolean;
    isActivityAddOn: boolean;
    isSingleSupplementAddOn: boolean;
};

type AddOnStatusFlags = {
    isPendingAdditionAddOn: boolean;
    isPendingRemovalAddOn: boolean;
    isConfirmedAddOn: boolean;
    isAvailableAddOn: boolean;
};

type AddOnStatusAndType = AddOnTypeFlags & AddOnStatusFlags;

/**
 * Gets the value of an add-on
 *
 * @param status
 * @returns Returns true if the addon status is CONFIRMED or PENDING_ADDITION, otherwise false.
 */
const isAddOnSelected = (
    status: MANAGE_BOOKING_ADDON_STATUS | undefined,
): boolean => {
    return (
        status === MANAGE_BOOKING_ADDON_STATUS.CONFIRMED ||
        status === MANAGE_BOOKING_ADDON_STATUS.PENDING_ADDITION
    );
};

/**
 * Checks if the add-ons is pending
 *
 * @param status
 * @returns Returns true if the addon status is PENDING_REMOVAL or PENDING_ADDITION, otherwise false.
 */
const isPendingStatusAddOn = (status: MANAGE_BOOKING_ADDON_STATUS): boolean => {
    return (
        status === MANAGE_BOOKING_ADDON_STATUS.PENDING_ADDITION ||
        status === MANAGE_BOOKING_ADDON_STATUS.PENDING_REMOVAL
    );
};

/**
 * Determines if there are any accommodations with confirmed or pending addition status.
 *
 * @param  accommodationServiceTiming - The accommodations for the specified timing.
 * @returns Returns `true` if any accommodations have confirmed or pending addition status, `false` otherwise.
 */
const isPrePostAccommodationSelected = (
    accommodationServiceTiming: AccommodationServiceTiming,
): boolean => {
    return Object.values(accommodationServiceTiming || []).some(
        (occupancy: ManageBookingAddOn) => isAddOnSelected(occupancy.status),
    );
};

/**
 * Determines if the transfer add-ons have confirmed or pending addition status.
 *
 * @param  transfersServiceTiming - The transfers addOn for the specified timing.
 * @returns Returns `true` if transfers addOn have confirmed or pending addition status, `false` otherwise
 */
const isPrePostTransferSelected = (
    transfersServiceTiming: TransfersServiceTiming,
): boolean => {
    return isAddOnSelected(transfersServiceTiming?.status);
};

/**
 * Checks if any accommodation within a specific service timing has a certain status.
 *
 * @param status
 * @param accommodations
 * @returns `True` if any accommodation within the service timing has the specified status, `false` otherwise.
 */
const checkAccommodationStatus = (
    status: MANAGE_BOOKING_ADDON_STATUS,
    accommodations: AccommodationServiceTiming,
): boolean => {
    return Object.values(accommodations || []).some(
        (occupancy: ManageBookingAddOn) => occupancy.status === status,
    );
};

/**
 * Checks if all accommodations are available for the specified timing.
 *
 * @param accommodations
 * @returns `true` if all accommodations are available, `false` otherwise.
 */
const areAllAccommodationsAvailable = (
    accommodations: AccommodationServiceTiming,
): boolean => {
    return Object.values(accommodations || []).every(
        (occupancy: ManageBookingAddOn) =>
            occupancy.status === MANAGE_BOOKING_ADDON_STATUS.AVAILABLE,
    );
};

/**
 * Checks if pre-trip and post-trip accommodations have available status.
 *
 * @param accommodations
 * @returns An object indicating if all pre-trip and post-trip accommodations have available status.
 */
const arePreAndPostAccommodationsAvailable = (
    accommodations: Accommodations,
): OriginallyAvailableAccommodations => {
    return {
        [ServiceTiming.PRE_TRIP]: areAllAccommodationsAvailable(
            accommodations[ServiceTiming.PRE_TRIP],
        ),
        [ServiceTiming.POST_TRIP]: areAllAccommodationsAvailable(
            accommodations[ServiceTiming.POST_TRIP],
        ),
    };
};

/**
 * Checks if upgrade to private room is selected
 *
 * @param singleSupplement
 * @returns `True` if the single supplement is confirmed or pending addition
 * and its quantity is the travelers quantity, `false` otherwise.
 */
const isUpgradeToPrivateRoomSelected = (
    singleSupplement: Accommodations['singleSupplement'],
    travelersQuantity: number,
): boolean => {
    if (!singleSupplement) return false;

    const { isConfirmedAddOn, isPendingAdditionAddOn } =
        getAddOnStatusAndType(singleSupplement);
    const isSingleTravelerBooking = travelersQuantity === 1;

    return (
        (isConfirmedAddOn || isPendingAdditionAddOn) &&
        singleSupplement.quantity === travelersQuantity &&
        !isSingleTravelerBooking
    );
};

/**
 * Checks if the given accommodations are available for the specified timing, travelers quantity, and room occupancies.
 *
 * @param accommodations
 * @param timing
 * @param travelersQuantity
 * @param roomsOccupancies
 * @return `true` if the given accommodations are available for the specified timing, `false` otherwise
 */
const hasAccommodation = (
    accommodations: Accommodations,
    timing: ServiceTimingType,
    travelersQuantity: number,
    roomsOccupancies: ValidOccupanciesType[],
): boolean => {
    if (!accommodations[timing]) {
        return false;
    }

    const uniqueOccupancies = new Set(roomsOccupancies);

    return (
        [...uniqueOccupancies].every(
            (occupancy) => occupancy in accommodations[timing],
        ) || accommodations.singleSupplement?.quantity === travelersQuantity
    );
};

/**
 * Checks if a trip has pre-trip and post-trip accommodations and transfers add-ons based on the selected room occupancies.
 * We match room occupancies with accommodations only for new bookings (travelers rooms is not empty).
 *
 * @param accommodations
 * @param travelersQuantity
 * @param roomsOccupancies
 * @param preTransferAddOn
 * @param postTransferAddOn
 * @returns An object indicating if the trip has pre/post accommodations and transfers add-ons.
 */
const checkTripHasPrePostAddOns = ({
    accommodations,
    travelersQuantity,
    roomsOccupancies,
    travelersRooms,
    preTransferAddOn,
    postTransferAddOn,
}: CheckTripHasPrePostAddOnsParams): TripHasPrePostAddOns => {
    const hasRoomsOccupancies = !!roomsOccupancies?.length;
    const travelersRoomsIsEmpty = isEmpty(travelersRooms);

    const hasPreAccommodation = hasAccommodation(
        accommodations,
        ServiceTiming.PRE_TRIP,
        travelersQuantity,
        roomsOccupancies,
    );
    const hasPostAccommodation = hasAccommodation(
        accommodations,
        ServiceTiming.POST_TRIP,
        travelersQuantity,
        roomsOccupancies,
    );

    return {
        tripHasPreAccommodation:
            (hasRoomsOccupancies || travelersRoomsIsEmpty) &&
            hasPreAccommodation,
        tripHasPostAccommodation:
            (hasRoomsOccupancies || travelersRoomsIsEmpty) &&
            hasPostAccommodation,
        tripHasPreOrPostAccommodations:
            (hasRoomsOccupancies || travelersRoomsIsEmpty) &&
            (hasPreAccommodation || hasPostAccommodation),
        tripHasPreTransfer:
            isPrePostAccommodationSelected(
                accommodations[ServiceTiming.PRE_TRIP],
            ) && !!preTransferAddOn,
        tripHasPostTransfer:
            isPrePostAccommodationSelected(
                accommodations[ServiceTiming.POST_TRIP],
            ) && !!postTransferAddOn,
    };
};

/**
 * Returns a set of flags indicating which is the type of `ManageBookingAddOn` object.
 * A flag is `true` if the add-on is of that particular type, `false` otherwise.
 *
 * @param addOn
 * @returns An object with flags indicating the type of the add-on.
 */
const getAddOnTypeFlags = (addOn: ManageBookingAddOn): AddOnTypeFlags => {
    const { type, service } = addOn;

    const addOnServiceType = (service as Service)?.type;
    const isAdditionalServiceAddOn = type === AddOnTypes.ADDITIONAL_SERVICE;

    const isAccommodationAddOn =
        isAdditionalServiceAddOn &&
        addOnServiceType === ServiceType.ACCOMMODATION;

    const isTransferAddOn =
        isAdditionalServiceAddOn &&
        addOnServiceType === ServiceType.AIRPORT_TRANSFER;

    const isSingleSupplementAddOn = type === AddOnTypes.SINGLE_SUPPLEMENT;

    const isActivityAddOn = type === AddOnTypes.ACTIVITY;

    return {
        isAccommodationAddOn,
        isTransferAddOn,
        isActivityAddOn,
        isSingleSupplementAddOn,
    };
};

/**
 * Returns a set of flags indicating which is the status of the add-on. A flag
 * is `true` if the add-on has that particular status, `false` otherwise.
 *
 * @param status
 * @return An object with flags indicating the status of the add-on.
 */
const getAddOnStatusFlags = (
    status: MANAGE_BOOKING_ADDON_STATUS,
): AddOnStatusFlags => {
    const isAvailableAddOn = status === MANAGE_BOOKING_ADDON_STATUS.AVAILABLE;

    const isConfirmedAddOn = status === MANAGE_BOOKING_ADDON_STATUS.CONFIRMED;

    const isPendingAdditionAddOn =
        status === MANAGE_BOOKING_ADDON_STATUS.PENDING_ADDITION;

    const isPendingRemovalAddOn =
        status === MANAGE_BOOKING_ADDON_STATUS.PENDING_REMOVAL;

    return {
        isAvailableAddOn,
        isConfirmedAddOn,
        isPendingAdditionAddOn,
        isPendingRemovalAddOn,
    };
};

/**
 * Check add-on status and type.
 *
 * @param addOn
 * @returns An object with the add-on status and type flags.
 */
const getAddOnStatusAndType = (
    addOn: ManageBookingAddOn,
): AddOnStatusAndType => {
    const { status } = addOn;

    const addOnTypeFlags = getAddOnTypeFlags(addOn);
    const addOnStatusFlags = getAddOnStatusFlags(status);

    return {
        ...addOnTypeFlags,
        ...addOnStatusFlags,
    };
};

/**
 * Checks if the provided `rooms` is of type `TravelersRooms`.
 *
 * @param rooms
 * @returns Returns `true` if the variable is of type `TravelersRooms`, `false` otherwise.
 */

const isTravelersRoomsType = (
    rooms: TravelersRooms | ValidOccupanciesType[],
): rooms is TravelersRooms => {
    return !Array.isArray(rooms);
};

/**
 * Checks if the insurance is cancelled for the provided user.
 *
 * @param addOns
 * @param user
 * @returns Returns `true` if the insurance is cancelled for the user, `false` otherwise.
 */
const isUserInsuranceCancelled = (
    addOns: BookingAddOn[],
    user: BaseUser,
): boolean => {
    const cancelledStatuses = [
        InsuranceAddOnStatus.CANCELLATION_PENDING,
        InsuranceAddOnStatus.CANCELLED,
    ];

    const insuranceAddOn = addOns.find(
        (addOn) => addOn.user === user.id,
    ) as InsurancePolicyAddOnResponse;

    if (!insuranceAddOn) return false;

    return cancelledStatuses.includes(insuranceAddOn.insurancePolicy.status);
};

const isPrivateRoomUpgradeAvailable = (
    availableSingleSupplements: number,
    travelersQuantity: number,
): boolean => {
    return availableSingleSupplements >= travelersQuantity;
};

interface ShouldAllowRoomSelectionEditionParams {
    isBookingEditable: boolean;
    isSingleTravelerBooking: boolean;
    travelerCanUpdatePrivateRoom: boolean;
    isSingleSupplementBooked: boolean;
}

/**
 * Determines whether the edit button should be displayed.
 * @param isBookingEditable
 * @param isSingleTravelerBooking
 * @param travelerCanUpdatePrivateRoom
 * @param isSingleSupplementBooked
 * @returns `True` if the edit button should be displayed, otherwise `false`.
 */
const shouldAllowRoomSelectionEdition = ({
    isBookingEditable,
    isSingleTravelerBooking,
    travelerCanUpdatePrivateRoom,
    isSingleSupplementBooked,
}: ShouldAllowRoomSelectionEditionParams): boolean => {
    if (!isBookingEditable) {
        return false;
    }

    if (!isSingleTravelerBooking || travelerCanUpdatePrivateRoom) {
        return true;
    }

    if (isSingleTravelerBooking && isSingleSupplementBooked) {
        return true;
    }

    return false;
};

export {
    areAllAccommodationsAvailable,
    arePreAndPostAccommodationsAvailable,
    checkAccommodationStatus,
    checkTripHasPrePostAddOns,
    getAddOnStatusAndType,
    getAddOnStatusFlags,
    getAddOnTypeFlags,
    hasAccommodation,
    isAddOnSelected,
    isPendingStatusAddOn,
    isPrePostAccommodationSelected,
    isPrePostTransferSelected,
    isPrivateRoomUpgradeAvailable,
    isTravelersRoomsType,
    isUpgradeToPrivateRoomSelected,
    isUserInsuranceCancelled,
    shouldAllowRoomSelectionEdition,
};
