import { ButtonProps, Link } from '@trova-trip/trova-components/build/next';
import { useNavigate } from '../../../../../../common/hooks/useNavigation';
import { ROUTE_TOKENS } from '../../../../../../common/constants';
import CardWithActions, {
    CardAction,
} from '../../../../../../common/components/CardWithActions';
import { useTrackingEvent } from '../../../../../../../analytics/hooks';
import { useSelector } from '../../../../../../../state/hooks';
import { applicationRootPath } from '../../../../../../../navigation/NavigationContext';
import useProfileCompletionStatus, {
    ProfileCompletionStatuses,
} from '../../../../../hooks/useProfileCompletionStatus';
import { editPublicProfileRoute } from '../../../../../products/publicProfile/PublicProfile';

const itineraryProfileDataLockedCardCopy = {
    title: 'Finish Your Profile to Request a Trip!',
    description:
        "To ensure that the Trova community is a safe place for everyone, we'd like to know a little more about you. Simply follow the link below and enter the required info. Once your profile is complete, you'll be free to request trips.",
};

const ItineraryProfileDataLockedCard = (): JSX.Element => {
    const navigateToAddressTab = useNavigate(
        `${applicationRootPath}${ROUTE_TOKENS.HOST_APP_ROUTE}/account/address`,
        { preserveCurrentPath: false },
    );

    const navigateToPublicProfileTab = useNavigate(editPublicProfileRoute, {
        preserveCurrentPath: false,
    });

    const { publicProfileStatus } = useProfileCompletionStatus();

    const isPublicProfileComplete =
        publicProfileStatus === ProfileCompletionStatuses.COMPLETE;
    const { trackUserEvent } = useTrackingEvent();
    const itineraryId = useSelector(
        (state) => state.userItinerary?.current?.id,
    );

    const onCompleteProfileClick = () => {
        trackUserEvent({
            eventName: 'Host Clicked Incomplete Profile Trip Request Card',
            properties: { itineraryId },
        });

        if (!isPublicProfileComplete) {
            navigateToPublicProfileTab();
        } else {
            navigateToAddressTab();
        }
    };

    const actions: CardAction[] = [
        {
            label: 'Complete Profile',
            onClick: onCompleteProfileClick,
            variant: 'primary' as ButtonProps['variant'],
        },
    ];
    return (
        <CardWithActions
            backgroundColor='neutral.97'
            {...itineraryProfileDataLockedCardCopy}
            actions={actions}
            footer={
                <>
                    <Link
                        size={{ base: 'sm', md: 'base' }}
                        href='mailto:hi@trovatrip.com'
                        variant='paragraph-link'
                        color='red.trova'
                    >
                        Contact us
                    </Link>{' '}
                    if you have any questions.
                </>
            }
        />
    );
};

export default ItineraryProfileDataLockedCard;
