import React, { useContext, Fragment } from 'react';
import { DayServiceUIModel } from '../../interfaces/UiModels.types';
import {
    StyledDraggableTarget,
    StyledSection,
} from './DailyActivities.components';
import DailyActivityCard from './DailyActivityCard';
import useCurrencyCode from '../../applications/common/hooks/currencies/useCurrencyCode';
import ConfigureServicesContext from 'applications/common/ConfigureServicesContext';
import { ServiceFormRegistry } from '../../applications/common/components/services/helpers';
import DaySectionTitle from './DayTitle';
import { ServiceTypesKeys } from '../../config/constants';

interface DailyActivitiesType {
    productName: string;
    dayServices: DayServiceUIModel[];
    dayIndex: number;
    id: string;
    currentCurrency?: string;
    tripLength: number;
    readOnly?: boolean;
    date?: string;
}

const DailyActivities: React.FC<DailyActivitiesType> = ({
    tripLength,
    productName,
    id,
    dayServices,
    dayIndex,
    date,
    currentCurrency,
    readOnly = false,
}) => {
    const context = useContext(ConfigureServicesContext);
    const currencyCode = useCurrencyCode(currentCurrency);

    return (
        <StyledSection>
            <DaySectionTitle id={id} dayNumber={dayIndex + 1} date={date} />
            {dayServices.map(({ service }, serviceIndex) => {
                const isEditMode =
                    ![
                        ServiceTypesKeys.LEGACY_FULL_DAY,
                        ServiceTypesKeys.INFORMAL_GATHERING,
                    ].includes(service.type) &&
                    !service.viewOnly &&
                    !readOnly;

                return (
                    <Fragment key={serviceIndex}>
                        {!readOnly &&
                        context?.shouldShowEditingForm?.(
                            dayIndex,
                            serviceIndex,
                        ) ? (
                            ServiceFormRegistry[context?.currentService](
                                context?.currentService,
                                context?.closeForm,
                                context?.updateServiceTypeWrapper,
                                service,
                                productName,
                                context?.errorText,
                                dayIndex,
                                tripLength,
                                currencyCode,
                                context.disabledFields,
                                context?.permissions,
                            )
                        ) : (
                            <DailyActivityCard
                                dayIndex={dayIndex}
                                serviceIndex={serviceIndex}
                                service={service}
                                handleDropAndDropCards={
                                    context?.handleDropAndDropCards
                                }
                                openForm={context?.openForm}
                                draggable={!readOnly}
                                editMode={isEditMode}
                            />
                        )}
                    </Fragment>
                );
            })}
            {!readOnly && context?.shouldShowCreateForm?.(dayIndex)
                ? ServiceFormRegistry[context?.currentService](
                      context?.currentService,
                      context?.closeForm,
                      context?.addServiceTypeWrapper,
                      context?.currentServiceItem,
                      productName,
                      context?.errorText,
                      dayIndex,
                      tripLength,
                      currencyCode,
                      context.disabledFields,
                  )
                : !readOnly && (
                      <StyledDraggableTarget
                          dropTargetConfiguration={{
                              accept: [
                                  `draggableLibraryServiceCard`,
                                  `draggableButton`,
                                  `draggableCard`,
                              ],
                              drop: (item) =>
                                  item.type === `draggableCard`
                                      ? context?.handleDropAndDropCards?.(
                                            dayIndex,
                                            dayServices.length,
                                            item,
                                        )
                                      : context?.handleDrop?.(dayIndex, item),
                          }}
                          onCollect={(item) => item}
                      >
                          <p>Add items</p>
                      </StyledDraggableTarget>
                  )}
        </StyledSection>
    );
};

export default DailyActivities;
