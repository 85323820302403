import {
    BaseBox,
    Form,
    Grid,
    Stack,
    Text,
    useToast,
} from '@trova-trip/trova-components';
import {
    Button,
    FeedbackMessage,
} from '@trova-trip/trova-components/build/next';
import { useEffect } from 'react';
import {
    BookedGroundTransferJourney,
    Traveler,
    UpdateGroundTransferApiRequest,
} from '../../../../../state/features/transfer/types';
import {
    isTransferErrorStatus,
    isTransferUpdateError,
    isTransferUpdatedStatus,
    isTransferUpdatingStatus,
} from '../../../../../state/features/transfer/utils';
import { useTransfer, useTransferOperationsAnalytics } from '../hooks';
import validationSchema from '../validation/editJourney';
import GridItemField, { GridItemFieldProps } from './GridItemField';

type JourneyFormFields = Traveler;

type JourneyFieldName = keyof JourneyFormFields;

type EditJourneyFieldProps = GridItemFieldProps<JourneyFieldName>;

const EditJourneyField = (props: EditJourneyFieldProps): JSX.Element => (
    <GridItemField {...props} />
);

type EditJourneyFormProps = {
    journey: BookedGroundTransferJourney;
    onBack?: () => void;
    onClose: () => void;
};

const EditJourneyForm = ({
    journey,
    onBack,
    onClose,
}: EditJourneyFormProps): JSX.Element => {
    const {
        actions: { updateTransfer, resetState },
        globalActions: { clearGroundTransfers },
        state: {
            metadata: { status, error },
        },
    } = useTransfer();

    const toast = useToast();

    const { trackUpdateGroundTransfersContactInformationEvent } =
        useTransferOperationsAnalytics();

    const fieldColumnSpan: EditJourneyFieldProps['columnSpan'] = {
        base: 12,
        md: 6,
    };

    const handleSubmit = (values: JourneyFormFields): void => {
        if (!journey.id) return;

        const updatePayload: UpdateGroundTransferApiRequest = {
            groundTransferId: journey.id,
            updateRequest: { traveler: values },
        };
        updateTransfer(updatePayload);
    };

    const closeDialogAndResetState = (): void => {
        onClose();
        resetState();
        clearGroundTransfers();
    };

    useEffect(() => {
        if (isTransferUpdatedStatus(status)) {
            closeDialogAndResetState();

            toast({
                description: 'Your ride was updated successfully.',
                title: 'Success',
                status: 'success',
                isClosable: true,
                position: 'top-right',
            });

            trackUpdateGroundTransfersContactInformationEvent({
                journeyType: journey.type,
            });
        }
    }, [status]);

    const shouldDisplayError =
        isTransferErrorStatus(status) && isTransferUpdateError(error);

    const isTransferUpdating = isTransferUpdatingStatus(status);

    if (shouldDisplayError) {
        return (
            <BaseBox width='full' padding={4}>
                <FeedbackMessage
                    type='error'
                    size='sm'
                    direction='vertical'
                    message={error?.message}
                    showContactSupport
                />
            </BaseBox>
        );
    }

    return (
        <Stack
            width='full'
            align='stretch'
            justify='space-between'
            spacing={0}
            direction='column'
            flexGrow={1}
            paddingBottom={2}
        >
            <Text marginBottom={4} color='blueGray.650'>
                {/* TODO: Replace this fragment with the actual copy when is available. */}
                <></>
            </Text>
            <Stack flexGrow={1} width='full' direction='column' align='stretch'>
                <Form
                    onSubmit={handleSubmit}
                    initialValues={journey.traveler}
                    validationSchema={validationSchema}
                    name='transfer-edit-information-form'
                    display='flex'
                    flexGrow={1}
                >
                    {({ isDirty }) => (
                        <Stack
                            flexGrow={1}
                            direction='column'
                            align='stretch'
                            justify='space-between'
                        >
                            <Grid maxHeight='max-content'>
                                <EditJourneyField
                                    name='flightNumber'
                                    label='Flight Number'
                                    isRequired
                                    as='text'
                                    columnSpan={fieldColumnSpan}
                                />
                                <EditJourneyField
                                    name='firstName'
                                    label='First Name'
                                    isRequired
                                    as='text'
                                    columnSpan={fieldColumnSpan}
                                    columnStart={1}
                                />
                                <EditJourneyField
                                    name='lastName'
                                    label='Last Name'
                                    isRequired
                                    as='text'
                                    columnSpan={fieldColumnSpan}
                                />
                                <EditJourneyField
                                    name='email'
                                    label='Email'
                                    isRequired
                                    as='text'
                                    columnSpan={fieldColumnSpan}
                                />
                                <EditJourneyField
                                    name='phone'
                                    label='Phone'
                                    isRequired
                                    as='text'
                                    columnSpan={fieldColumnSpan}
                                />
                            </Grid>
                            <Stack
                                spacing={3}
                                width={{ base: 'full', md: 'auto' }}
                                direction={{
                                    base: 'column-reverse',
                                    md: 'row',
                                }}
                                justify='flex-end'
                            >
                                {onBack ? (
                                    <Button
                                        variant='secondary'
                                        onClick={onBack}
                                        isFullWidth={{ base: true, lg: false }}
                                    >
                                        Back To Details
                                    </Button>
                                ) : null}
                                <Button
                                    variant='primary'
                                    type='submit'
                                    isFullWidth={{ base: true, lg: false }}
                                    isDisabled={!isDirty}
                                    isLoading={isTransferUpdating}
                                >
                                    Update Information
                                </Button>
                            </Stack>
                        </Stack>
                    )}
                </Form>
            </Stack>
        </Stack>
    );
};

export default EditJourneyForm;
export type { JourneyFormFields };
