import { constants } from '@trova-trip/trova-models';
import { BaseUser } from '../../../../state/features/transfer/types';
import useTripRoute, { TRIP_ACTION } from './useTripRoute';

const { Group } = constants.user;

interface UseTripRootPathByUserGroupParams {
    tripId: string | undefined;
    group: BaseUser['group'];
}

const useTripRootPathByUserGroup = ({
    tripId,
    group,
}: UseTripRootPathByUserGroupParams): string => {
    const isHost = group === Group.HOST;
    const queryParams = isHost ? '?model=trip' : undefined;
    const tripAction = isHost
        ? TRIP_ACTION.CONFIGURATION
        : TRIP_ACTION.TRIP_OVERVIEW;

    const tripPath = useTripRoute({
        action: tripAction,
        id: tripId,
        queryParams,
    });

    return tripPath;
};

export default useTripRootPathByUserGroup;
