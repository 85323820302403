import React, { SyntheticEvent } from 'react';
import {
    Card,
    theme,
    ComponentWidth,
    TextareaSize,
    useFormSaver,
    Stack,
    Input,
    NumberInput,
    Currency,
    TimePicker,
    Textarea,
    BaseBox,
} from '@trova-trip/trova-components';
import { constants } from '@trova-trip/trova-models';
import Danger from '../../../../../components/Typography/Danger';
import WorkshopHoursField from '../WorkshopHoursField';
import ServiceTitle from '../ServiceTitle';
import ActionButtons from './Shared/ActionButtons';
import SaveToLibraryCheckbox from './Shared/SaveToLibraryCheckbox';
import { StyledServiceFileInput } from './Form.components';
import {
    getColorFromServiceType,
    getServiceTitleLabel,
} from '../LibraryServiceFormatter';
import { includes } from 'lodash';
import { Moment } from 'moment';
import { ServiceFormPermissions, fieldNamesByServiceType } from '../helpers';
import { validationSchema } from './Shared/validationSchema';

const { ServiceType } = constants.services;
type ServiceType = constants.services.ServiceType;

interface ServiceInfoForCard {
    type: ServiceType;
    name: string;
    hoursAvailable: number;
    pricePerHour: number;
    location: string;
    timeAvailableStart: string;
    description: string;
    images: string[];
    saveToLibrary: boolean;
    deleted: boolean;

    // Allows additional properties not used by this component
    [key: string | number | symbol]: any;
}

interface WorkshopSpaceCardFormProps {
    serviceToEdit?: ServiceInfoForCard;
    closeForm: () => void;
    updateItinerary: (data: ServiceInfoForCard) => void;
    errorText: string;
    currencyCode: string;
    disabledFields: string[];
    permissions?: ServiceFormPermissions;
}

const WorkshopSpaceCardForm = (props: WorkshopSpaceCardFormProps) => {
    const {
        closeForm,
        currencyCode,
        disabledFields,
        errorText,
        serviceToEdit,
        updateItinerary,
        permissions,
    } = props;

    const defaultData: ServiceInfoForCard = serviceToEdit
        ? serviceToEdit
        : {
              type: ServiceType.WORKSHOP_SPACE,
              name: '',
              hoursAvailable: 1,
              pricePerHour: 0,
              location: '',
              timeAvailableStart: '00:00',
              description: '',
              images: [],
              saveToLibrary: false,
              deleted: false,
          };

    const { hoursRequested } = defaultData;

    const {
        formValues: { get: getValue, set: setValue },
        formState: allValues,
        handleFormSubmit: submitForm,
        formErrors,
    } = useFormSaver({
        onSubmit: async (data: ServiceInfoForCard) => {
            updateItinerary(data);
        },
        initialValues: defaultData,
        validationSchema: {
            schema: validationSchema,
        },
    });

    const setNumberValue = (
        _event: SyntheticEvent,
        name: string,
        value: number | undefined,
    ): void => {
        setValue.nested(name, Number(value));
    };

    const setCurrencyValue = (event: React.SyntheticEvent): void => {
        const { value, name } = event.target as HTMLInputElement;
        const numberValue = Number(value);

        const newValue = numberValue < 0 || isNaN(numberValue) ? 0 : value;

        setValue.nested(name, newValue);
    };

    const setTimeValue = (name: string, value: Moment | string): void => {
        setValue.nested(name, value);
    };

    const removeWorkshop = (): void => {
        setValue.nested('deleted', true);
        updateItinerary({
            ...allValues,
            deleted: true,
        } as unknown as ServiceInfoForCard);
    };

    const serviceType = getValue.nested('type');
    const isNew = !serviceToEdit;

    const { workshop: fieldNames } = fieldNamesByServiceType;

    const isHoursAvailableReadOnly = includes(
        disabledFields,
        fieldNames.hoursAvailable,
    );

    return (
        <Card backgroundColor={theme.colors.neutral.white}>
            <Stack direction='column' spacing={4}>
                <ServiceTitle
                    title={getServiceTitleLabel(serviceType)}
                    color={getColorFromServiceType(serviceType)}
                />
                <Input
                    name={fieldNames.name}
                    label='Name *'
                    size={ComponentWidth.Small}
                    value={getValue[fieldNames.name] as string}
                    onChange={setValue[fieldNames.name]}
                    disabled={includes(disabledFields, fieldNames.name)}
                />
                <BaseBox maxWidth='10rem'>
                    {isHoursAvailableReadOnly ? (
                        <WorkshopHoursField
                            type='available'
                            value={Number(getValue[fieldNames.hoursAvailable])}
                        />
                    ) : (
                        <NumberInput
                            name={fieldNames.hoursAvailable}
                            label='No. Hours Available *'
                            value={Number(getValue[fieldNames.hoursAvailable])}
                            onChange={setNumberValue}
                        />
                    )}
                </BaseBox>
                {hoursRequested ? (
                    <BaseBox maxWidth='10rem'>
                        <WorkshopHoursField
                            type='requested'
                            value={hoursRequested}
                        />
                    </BaseBox>
                ) : null}
                <Currency
                    name={fieldNames.pricePerHour}
                    label='Price per Hour *'
                    size={ComponentWidth.Small}
                    value={getValue[fieldNames.pricePerHour] as number}
                    onChange={setCurrencyValue}
                    currencyType={currencyCode}
                    disabled={includes(disabledFields, fieldNames.pricePerHour)}
                />
                <Input
                    name={fieldNames.location}
                    placeholder='Address'
                    label='Location *'
                    size={ComponentWidth.Medium}
                    value={getValue[fieldNames.location] as string}
                    onChange={setValue[fieldNames.location]}
                    disabled={includes(disabledFields, fieldNames.location)}
                />
                <TimePicker
                    name={fieldNames.timeAvailableStart}
                    placeholder='0:00'
                    label='Time Available Start'
                    value={getValue[fieldNames.timeAvailableStart] as string}
                    onChange={(value) =>
                        setTimeValue(fieldNames.timeAvailableStart, value)
                    }
                    size={ComponentWidth.Small}
                    disabled={includes(
                        disabledFields,
                        fieldNames.timeAvailableStart,
                    )}
                />
                <Textarea
                    name={fieldNames.description}
                    placeholder='Description'
                    label='Description *'
                    size={TextareaSize.Medium}
                    value={getValue[fieldNames.description] as string}
                    error={formErrors?.[fieldNames.description]}
                    onChange={setValue[fieldNames.description]}
                    expandable={true}
                    disabled={includes(disabledFields, fieldNames.description)}
                />
            </Stack>
            {/* Using 2 stacks prevents overriding the margin built into the FileInput below.
                Once that component is refactored and that built-in margin removed, 1 stack will work. */}
            <Stack direction='column' spacing={4}>
                {/*@ts-ignore - TrovaFileInput seems to be incorrectly typed/inferred here (works without "required" props) */}
                <StyledServiceFileInput
                    name={fieldNames.images}
                    value={getValue[fieldNames.images]}
                    onChange={setValue[fieldNames.images]}
                    label='Upload images'
                    maxFiles='10'
                    disabled={includes(disabledFields, fieldNames.images)}
                />
                {errorText && <Danger>{errorText}</Danger>}
                <SaveToLibraryCheckbox
                    paddingTop={0}
                    value={!!getValue[fieldNames.saveToLibrary]}
                    onChange={setValue[fieldNames.saveToLibrary]}
                    disabled={includes(
                        disabledFields,
                        fieldNames.saveToLibrary,
                    )}
                />
                <ActionButtons
                    marginTop={0}
                    serviceType={getValue.type as ServiceType}
                    closeForm={closeForm}
                    // submitForm (from useFormSaver) expects a FormEvent or undefined
                    submitForm={() => submitForm(undefined)}
                    onRemoveAction={removeWorkshop}
                    isNewEntity={isNew}
                    permissions={permissions}
                />
            </Stack>
        </Card>
    );
};

export default WorkshopSpaceCardForm;
