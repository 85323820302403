import { Field } from '@trova-trip/trova-components';

interface CurrencyFieldProps {
    name: string;
    label: string;
    currencyCode: string;
    isRequired?: boolean;
    isDisabled?: boolean;
}

const CurrencyField = ({
    name,
    label,
    currencyCode,
    isDisabled,
    isRequired = false,
}: CurrencyFieldProps) => (
    <Field
        as='currency'
        name={name}
        label={label}
        display={{ code: currencyCode }}
        min={0}
        isRequired={isRequired}
        isDisabled={isDisabled}
    />
);

export default CurrencyField;
