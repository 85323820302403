import useIsLargeScreen from 'applications/common/hooks/useIsLargeScreen';
import { useEffect } from 'react';

// hack overwrite of intercom styles
const useMobileIntercomStyles = (bottomPadding: number = 100): void => {
    const isLargeScreen = useIsLargeScreen();

    useEffect(() => {
        if (isLargeScreen) {
            return;
        }
        const intercomMobielCss = `
            .intercom-lightweight-app-launcher, .intercom-launcher-frame {
                bottom: ${bottomPadding}px !important;
            }

            .intercom-launcher-badge-frame {
                bottom: ${bottomPadding + 40}px !important;
            }
        `;
        const styleSheet = document.createElement(`style`);
        styleSheet.setAttribute(`id`, `intercom-dynamic-style-hack`);
        styleSheet.innerHTML = intercomMobielCss;
        document.head.appendChild(styleSheet);

        return () => {
            document.head.removeChild(styleSheet);
        };
    }, [isLargeScreen]);
};

export default useMobileIntercomStyles;
