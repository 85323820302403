import { coreUtils } from '@trova-trip/trova-common';
import { Heading, LineItem, Stack } from '@trova-trip/trova-components';
import { Card } from '@trova-trip/trova-components/build/next';
import { getTripRelatedStringDates } from '../../../../../common/helpers';
import { useManageBooking, usePricingData } from '../../hooks';
import {
    ManageBookingAddOn,
    ServiceTiming,
    ServiceTimingType,
    Trip,
} from '../../types';
import {
    checkTripHasPrePostAddOns,
    isAddOnSelected,
} from '../../utils/check.utils';
import {
    getAddOnStatusIconProps,
    getFirstPreAndPostTransfersAddOn,
    getSelectedTravelersRooms,
    getToggleAddOnActionType,
} from '../../utils/extract.utils';

const { formatUSD } = coreUtils.currencyUtils;

interface PrePostTransfersSectionProps {
    trip: Trip;
}

interface TransferItemProps {
    title: string;
    date: string;
    price: number;
    addOn: ManageBookingAddOn | undefined;
    description: string;
    hideControl: boolean;
    timing: ServiceTimingType;
}

const TransferItem = (props: TransferItemProps): JSX.Element => {
    const {
        actions: { updateTransfer },
    } = useManageBooking();

    const { price, date, timing, addOn, ...rest } = props;

    const isSelected = isAddOnSelected(addOn?.status);

    const handleChange = (): void => {
        updateTransfer({
            timing,
            actionType: getToggleAddOnActionType(isSelected),
        });
    };

    return (
        <LineItem
            name='prePostTransfer'
            type='button'
            id={`transfer-${timing}`}
            value={isSelected}
            onChange={handleChange}
            headerProps={{
                icon: getAddOnStatusIconProps(addOn?.status),
                price: formatUSD(price, 2),
                subtitle: date,
            }}
            {...rest}
        />
    );
};

const PrePostTransfersSection = ({
    trip,
}: PrePostTransfersSectionProps): JSX.Element | null => {
    const {
        state: {
            addOns: { accommodations, transfers },
            travelersRooms,
            travelersQuantity,
            isBookingEditable,
        },
    } = useManageBooking();

    const { preTripTransferPrice, postTripTransferPrice } = usePricingData();

    const { preTransferAddOn, postTransferAddOn } =
        getFirstPreAndPostTransfersAddOn(transfers);

    const roomsOccupancies = getSelectedTravelersRooms(
        travelersRooms,
        accommodations.singleSupplement,
        travelersQuantity,
    ).map((travelerRoom) => travelerRoom.roomType);

    const { tripHasPreTransfer, tripHasPostTransfer } =
        checkTripHasPrePostAddOns({
            accommodations,
            roomsOccupancies,
            travelersQuantity,
            travelersRooms,
            preTransferAddOn,
            postTransferAddOn,
        });

    if (!tripHasPreTransfer && !tripHasPostTransfer) {
        return null;
    }

    const { preTripDate, postTripDate } = getTripRelatedStringDates(trip);

    return (
        <Card width='full'>
            <Heading
                as='h4'
                fontWeight='medium'
                size={{ base: 'xl', lg: '2xl' }}
            >
                Airport Transfers
            </Heading>
            <Stack direction='column' marginTop={6} spacing={6}>
                {tripHasPreTransfer ? (
                    <TransferItem
                        title='Private Transfer upon Arrival'
                        description='A ride to the hotel upon arrival for Pre-Trip accommodations.'
                        date={preTripDate}
                        addOn={preTransferAddOn}
                        price={preTripTransferPrice}
                        timing={ServiceTiming.PRE_TRIP}
                        hideControl={!isBookingEditable}
                    />
                ) : null}

                {tripHasPostTransfer ? (
                    <TransferItem
                        title='Private Transfer upon Departure'
                        description='A ride to the airport upon departure for Post-Trip accommodations.'
                        date={postTripDate}
                        addOn={postTransferAddOn}
                        price={postTripTransferPrice}
                        timing={ServiceTiming.POST_TRIP}
                        hideControl={!isBookingEditable}
                    />
                ) : null}
            </Stack>
        </Card>
    );
};

export default PrePostTransfersSection;
