import { RemoveButtonWithAnalytics } from '../../TripRequestForm/TripRequestForm';

type RemoveRoomButtonProps = {
    handleRemove: () => void;
};

export const RemoveRoomButton = ({ handleRemove }: RemoveRoomButtonProps) => (
    <RemoveButtonWithAnalytics
        onClick={handleRemove}
        icon='trash'
        aria-label='Remove Room'
        iconVariant='filled'
        iconColor='red.coral'
        size='md'
        variant='solid'
        analyticsData={{
            type: 'onClick',
            eventName: 'Host Removed Room',
        }}
    />
);

export default RemoveRoomButton;
