import { coreUtils } from '@trova-trip/trova-common';
import { Alert, BaseBox, Heading } from '@trova-trip/trova-components';
import noop from 'lodash/noop';
import MobileFloatingAccordion from '../../../../../common/components/MobileFloatingAccordion';
import { useManageBooking, useTripSummaryContent } from '../../hooks';
import DifferentTransferDatesFormTripAlert from '../DifferentTransferDatesFormTripAlert';
import TripSummaryContent from '../TripSummaryContent';
import TripSummaryControls from '../TripSummaryControls';

const { formatUSD } = coreUtils.currencyUtils;

const TripSummaryAccordion = (): JSX.Element | null => {
    const {
        state: {
            tripSummary: { total },
            confirmation,
        },
    } = useManageBooking();
    const { pendingChanges } = useTripSummaryContent();

    if (!pendingChanges) return null;

    return (
        <>
            <MobileFloatingAccordion
                panel={
                    <>
                        <BaseBox
                            width='full'
                            paddingY={4}
                            paddingX={3}
                            borderBottom='1px'
                            borderColor='blueGray.200'
                        >
                            <Heading as='h6' fontWeight='bold'>
                                Summary
                            </Heading>
                        </BaseBox>
                        <TripSummaryContent showTotal={false} />
                    </>
                }
                header={{
                    title: 'Total',
                    rightElement: (
                        <Heading
                            as='h5'
                            fontSize='base'
                            fontWeight='medium'
                            color={
                                total < 0
                                    ? 'alerts.error.accent'
                                    : 'blueGray.650'
                            }
                        >
                            {formatUSD(total, 2)}
                        </Heading>
                    ),
                    boxShadow: 'none',
                    bottomElement: (
                        <BaseBox paddingY={4} paddingX={6} width='full'>
                            <DifferentTransferDatesFormTripAlert />
                            {confirmation.error ? (
                                <BaseBox paddingBottom={4}>
                                    <Alert
                                        status='error'
                                        variant='inline'
                                        showBorderLeft={false}
                                        title={confirmation.error.title}
                                        description={
                                            confirmation.error.description
                                        }
                                        onCloseAlert={noop}
                                    />
                                </BaseBox>
                            ) : null}
                            <TripSummaryControls />
                        </BaseBox>
                    ),
                }}
                padding={0}
                position='bottom'
            />
        </>
    );
};

export default TripSummaryAccordion;
