import React, { ReactElement } from 'react';
import { Graph } from './Chart.types';
import { theme } from '@trova-trip/trova-components';
import { ResponsiveContainer, Tooltip, PieChart, Pie, Cell } from 'recharts';
import { truncate, isNil } from 'lodash';
import { PieChartCSS } from './PieChart.styles';
import { capitalize } from '../../../utils';

const RADIAN = Math.PI / 180;

interface TrovaPieChartProps {
    graphData: Graph;
    fieldTitle: string;
    fieldValue: string;
    height?: number;
    colorArray?: string[];
    percentage?: boolean;
    maxLabelLength?: number;
}

const labelFormatter = (label: string, length?: number): string => {
    const text = length ? truncate(label, { length, omission: '' }) : label;
    return capitalize(text);
};

const TrovaPieChart = ({
    graphData,
    fieldTitle,
    fieldValue,
    height = 400,
    colorArray = [theme.colors.teal[300]],
    percentage = false,
    maxLabelLength,
}: TrovaPieChartProps): ReactElement => {
    const filteredGraphData = graphData.filter((dataPoint) => {
        const dataPointValue = dataPoint[fieldValue];
        return !isNil(dataPointValue) && dataPointValue !== 0;
    });

    return (
        <div className={PieChartCSS}>
            <ResponsiveContainer width='100%' height={height}>
                <PieChart
                    margin={{
                        top: 40,
                        right: 40,
                        left: 20,
                        bottom: 40,
                    }}
                >
                    <Pie
                        data={filteredGraphData}
                        dataKey={fieldValue}
                        nameKey={fieldTitle}
                        innerRadius={'40%'}
                        outerRadius={'75%'}
                        paddingAngle={3}
                        fill={theme.colors.teal[300]}
                        labelLine={false}
                        label={({
                            cx,
                            cy,
                            midAngle,
                            innerRadius,
                            outerRadius,
                            value,
                            index,
                        }): ReactElement => {
                            const radius =
                                10 + innerRadius + (outerRadius - innerRadius);
                            const x =
                                cx + radius * Math.cos(-midAngle * RADIAN);
                            const y =
                                cy + radius * Math.sin(-midAngle * RADIAN);

                            return (
                                <text
                                    x={x}
                                    y={y}
                                    fill={theme.colors.blueGray[650]}
                                    fontSize={theme.fontSizes.sm}
                                    fontFamily={theme.fonts.roboto}
                                    fontWeight={theme.fontWeights.bold}
                                    textAnchor={x > cx ? 'start' : 'end'}
                                    dominantBaseline='central'
                                >
                                    {labelFormatter(
                                        filteredGraphData[index][fieldTitle],
                                        maxLabelLength,
                                    )}
                                    : {percentage ? value * 100 + '%' : value}
                                </text>
                            );
                        }}
                    >
                        {filteredGraphData.map((item, index) => {
                            return (
                                <Cell
                                    key={`cell-${item.title}`}
                                    fill={colorArray[index % colorArray.length]}
                                />
                            );
                        })}
                    </Pie>
                    <Tooltip
                        formatter={(value): string => {
                            return percentage ? value * 100 + '%' : value;
                        }}
                        contentStyle={{
                            borderRadius: '6px',
                            background: theme.colors.neutral.white,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        itemStyle={{
                            color: theme.colors.blueGray[650],
                        }}
                    />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export { TrovaPieChart as default };
