import { Socials } from '../../host/components/SocialMediaAccounts';

const PROTOCOL_REGEX = /^[a-z]+:\/\//i;

const removeTrailingSlash = (url: string): string =>
    url.endsWith('/') ? url.slice(0, -1) : url;

type GetBaseUrlFromStringFormatOptions = {
    removeTrailingSlash?: boolean;
    removeProtocol?: boolean;
};
const getBaseUrlFromString = (
    url: string,
    formatOptions: GetBaseUrlFromStringFormatOptions = {
        removeTrailingSlash: true,
    },
): string => {
    if (!url) return url;

    let baseUrl = url;

    if (formatOptions.removeProtocol) {
        baseUrl = baseUrl.replace(PROTOCOL_REGEX, '');
    }
    if (formatOptions.removeTrailingSlash) {
        baseUrl = removeTrailingSlash(baseUrl);
    }

    return baseUrl;
};

const formatSocialChannels = (
    channels: Socials,
    formatter: (channels: Socials) => Socials,
): Socials => formatter(channels);

const addHttpsProtocolToPersonalSite = (socialChannels: Socials): Socials => {
    try {
        const { personalSite } = socialChannels;
        if (!personalSite) return socialChannels;

        const personalSiteBaseUrl = getBaseUrlFromString(personalSite, {
            removeProtocol: true,
            removeTrailingSlash: true,
        });

        if (!personalSiteBaseUrl) return socialChannels;

        const personalSiteWithHttpsProtocol = `https://${personalSiteBaseUrl}`;

        return {
            ...socialChannels,
            personalSite: personalSiteWithHttpsProtocol,
        };
    } catch {
        return socialChannels;
    }
};

const truncatePersonalSite = (socialChannels: Socials): Socials => {
    const { personalSite } = socialChannels;
    if (!personalSite) return socialChannels;

    const personalSiteBaseUrl = getBaseUrlFromString(personalSite, {
        removeProtocol: true,
        removeTrailingSlash: true,
    });

    if (!personalSiteBaseUrl) return socialChannels;

    return {
        ...socialChannels,
        personalSite: personalSiteBaseUrl,
    };
};

export {
    formatSocialChannels,
    getBaseUrlFromString,
    addHttpsProtocolToPersonalSite,
    truncatePersonalSite,
};
