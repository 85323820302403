import { createRef } from 'react';

export const useManageElementRefs = (
    elementIds: string[],
): {
        getElementRef: (
            elementId: string,
        ) => React.RefObject<HTMLElement> | undefined;
        scrollToElement: (elementId: string) => void;
    } => {
    const refList = new Map<string, React.RefObject<HTMLElement>>();

    elementIds.forEach((id) => {
        refList.set(id, createRef<HTMLElement>());
    });

    const getElementRef = (
        elementId: string,
    ): React.RefObject<HTMLElement> | undefined => {
        return refList.get(elementId);
    };

    const scrollToElement = (elementId: string): void => {
        const element = getElementRef(elementId)?.current;

        element?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });
    };

    return { getElementRef, scrollToElement };
};
