import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useDrawer } from '@trova-trip/trova-components';
import { useLocation } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

import useApplicationTitle from 'applications/common/hooks/useApplicationTitle';
import { scrollToTop } from 'util/scrollToTop';
import MainContent from './components/MainContent';
import { usePathLabels } from './hooks';
import NavigationContext, { applicationRootPath } from './NavigationContext';

const Application = ({
    profile,
    children,
    path,
    sidebarProps,
    pageLoading = false,
}) => {
    const { pathname, search, hash } = useLocation();
    const [products, addProduct] = usePathLabels();
    const [navigationContent, setNavigationContent] = useState([]);
    const [sidebarComponent, setSidebarComponent] = useState();
    const [modelId, setModelId] = useState();
    const [firstProduct] = products;
    const [showLayout, setShowLayout] = useState(true);
    const [showSidebarContent, setShowSidebarContent] = useState(true);
    const [showSidebarButton, setShowSidebarButton] = useState(true);
    const [isProductHeaderVisible, setIsProductHeaderVisible] = useState(true);
    const drawerHandler = useDrawer();

    const applicationPath = `${applicationRootPath}${path}`;

    useEffect(() => scrollToTop(), [pathname]);

    useApplicationTitle();

    const value = useMemo(
        () => ({
            applicationPath,
            products,
            drawer: drawerHandler,
            addProduct,
            navigationContent,
            setNavigationContent,
            modelId,
            setModelId,
            search,
            hash,
            setSidebarComponent,
            sidebarComponent,
            showLayout,
            setShowLayout,
            showSidebarContent,
            setShowSidebarContent,
            isProductHeaderVisible,
            setIsProductHeaderVisible,
            showSidebarButton,
            setShowSidebarButton,
            profile,
        }),
        [
            products,
            setNavigationContent,
            navigationContent,
            addProduct,
            modelId,
            search,
            hash,
            applicationPath,
            sidebarComponent,
            setSidebarComponent,
            showLayout,
            setShowLayout,
            showSidebarContent,
            setShowSidebarContent,
            isProductHeaderVisible,
            setIsProductHeaderVisible,
            showSidebarButton,
            setShowSidebarButton,
            drawerHandler,
            profile,
        ],
    );

    return (
        <Route
            path={applicationPath}
            render={({ match: { isExact: shouldRedirectToFirstProduct } }) => (
                <NavigationContext.Provider value={value}>
                    {shouldRedirectToFirstProduct && firstProduct && (
                        <Redirect to={firstProduct.path} />
                    )}
                    <MainContent
                        sidebarProps={{ ...sidebarProps, sidebarComponent }}
                        pageLoading={pageLoading}
                    >
                        {children}
                    </MainContent>
                </NavigationContext.Provider>
            )}
        />
    );
};

Application.propTypes = {
    profile: PropTypes.shape(),
    children: PropTypes.node,
    location: PropTypes.shape({
        search: PropTypes.string,
        hash: PropTypes.string,
    }),
    sidebarAction: PropTypes.element,
    path: PropTypes.string,
    sidebarProps: PropTypes.shape({
        buttonPath: PropTypes.string,
        buttonText: PropTypes.string,
    }),
};

export default Application;
