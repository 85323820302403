import { BaseComboBoxOption } from '@trova-trip/trova-components';
import { constants, models } from '@trova-trip/trova-models';

const { PackageAccommodationLevel, PackageMealLevel, PackageFoodOptions } =
    constants.itinerary;

type ValueOf<T> = T[keyof T];

export enum FormFieldsNames {
    ENABLED = 'enabled',
    FOOD_OPTIONS = 'food_options',
    ACCOMMODATION_LEVEL = 'accommodation_levels',
    MEAL_LEVEL = 'meal_level',
}

export type AccommodationLevelsValueType = ValueOf<
    typeof PackageAccommodationLevel
>;
export type MealLevelValueType = ValueOf<typeof PackageMealLevel>;
export type FoodOptionsValueType = ValueOf<typeof PackageFoodOptions>;

export type ItineraryPackageType = {
    type: string;
    enabled: boolean;
    accommodationLevels: BaseComboBoxOption[];
    mealLevels: BaseComboBoxOption[];
    foodOptions: BaseComboBoxOption[];
};

export type ItineraryPackageFormData = {
    enabled: boolean;
    accommodationLevels: AccommodationLevelsValueType[];
    mealLevels: MealLevelValueType[];
    foodOptions: FoodOptionsValueType[];
};

export type SelectedPackages = models.itineraries.ItineraryPackageLevels;
