import { Card } from '@trova-trip/trova-components/build/next';
import { Table } from '@trova-trip/trova-components/build/next';
import {
    Heading,
    IconButton,
    Stack,
    StatusIndicator,
    Text,
    theme,
} from '@trova-trip/trova-components';
import { useSelector } from '../../../../../../state/hooks/useSelector';
import { useMemo } from 'react';
import { models } from '@trova-trip/trova-models';
import { TripStatuses } from '../../../../../../config/constants';

type TripStatus = models.trips.Trip['status'];

const emailStatus = {
    pending: 'Pending',
    scheduled: 'Scheduled',
    sent: 'Sent',
    disabled: 'Disabled',
    canceled: 'Canceled',
};

const getDisplayDate = (
    scheduledDateLabel: string,
    scheduledDate?: Date | string,
    completedDate?: Date | string,
): string => {
    if (completedDate) {
        return new Date(completedDate).toLocaleString();
    } else if (scheduledDate) {
        return new Date(scheduledDate).toLocaleString();
    } else {
        return scheduledDateLabel;
    }
};

const isEmailEditable = (tripStatus: TripStatus, status: string): boolean =>
    !(
        tripStatus === TripStatuses.CANCELLED ||
        tripStatus === TripStatuses.CLOSED ||
        tripStatus === TripStatuses.COMPLETE ||
        status === emailStatus.sent ||
        status === emailStatus.canceled
    );

const statusColors = {
    [emailStatus.pending]: theme.colors.orange[900],
    [emailStatus.scheduled]: theme.colors.blue[300],
    [emailStatus.sent]: theme.colors.seaGreen.trova,
    [emailStatus.disabled]: theme.colors.red[300],
    [emailStatus.canceled]: theme.colors.neutral[70],
};

const getStatusColor = (status: string) => {
    return statusColors[status] || theme.colors.blue[300];
};

const getRate = (rate?: number): string => {
    return rate ? `${rate}` : '--';
};

type CampaignEmailStatus = models.common.CampaignEmailStatus;
type OnEditEmail = (emailId?: string) => void;

type EmailCampaignsTableRow = CampaignEmailStatus & {
    scheduledDate?: Date | string;
    completedDate?: Date | string;
    onEditEmailClick: OnEditEmail;
};

const EmailCampaignsTableRow = ({
    onEditEmailClick,
    ...emailCampaign
}: EmailCampaignsTableRow): JSX.Element => {
    const { current: currentTrip } = useSelector((state) => state.userTrips);
    const {
        name,
        scheduledDate,
        completedDate,
        scheduledDateLabel,
        clicked,
        opened,
        status,
    } = emailCampaign;

    const isEditEnabled =
        !!currentTrip?.status && isEmailEditable(currentTrip.status, status);

    return (
        <Table.Row>
            <Table.Cell>
                <Text size={'sm'}>{name}</Text>
            </Table.Cell>
            <Table.Cell>
                <Text size={'sm'}>
                    {getDisplayDate(
                        scheduledDateLabel,
                        completedDate,
                        scheduledDate,
                    )}
                </Text>
            </Table.Cell>
            <Table.Cell>
                <Text size={'sm'}>{getRate(clicked)}</Text>
            </Table.Cell>
            <Table.Cell>
                <Text size={'sm'}>{getRate(opened)}</Text>
            </Table.Cell>
            <Table.Cell>
                <Stack spacing={1} align={'center'} wrap='nowrap'>
                    <StatusIndicator
                        color={getStatusColor(status)}
                    ></StatusIndicator>
                    <Text size={'sm'}>{status}</Text>
                </Stack>
            </Table.Cell>
            <Table.Cell>
                <IconButton
                    aria-label='Edit promote email'
                    icon='pencil'
                    onClick={(): void => onEditEmailClick(name)}
                    iconColor='red.coral'
                    variant='ghost'
                    size='md'
                    isDisabled={!isEditEnabled}
                />
            </Table.Cell>
        </Table.Row>
    );
};

type EmailCampaignsTableProps = {
    onEditEmailClick: OnEditEmail;
};

const EmailCampaignsTable = ({
    onEditEmailClick,
}: EmailCampaignsTableProps): JSX.Element => {
    const {
        userTripDetails: { current: currentTripDetails },
    } = useSelector((state) => state);

    const emailCampaigns = useMemo(() => {
        return (
            currentTripDetails?.emailCampaigns?.sort(
                (a, b) => a.sortOrder - b.sortOrder,
            ) ?? []
        );
    }, [currentTripDetails]);

    return (
        <Card maxWidth='100%'>
            <Stack direction='column' spacing={5} align='stretch'>
                <Heading as='h4'>Email Campaign</Heading>
                <Table variant='striped' size='sm'>
                    <Table.Head>
                        <Table.Row>
                            <Table.Header>Email</Table.Header>
                            <Table.Header>Scheduled</Table.Header>
                            <Table.Header>Clicked</Table.Header>
                            <Table.Header>Opened</Table.Header>
                            <Table.Header>Status</Table.Header>
                        </Table.Row>
                    </Table.Head>
                    <Table.Body>
                        {emailCampaigns.map((emailCampaign) => (
                            <EmailCampaignsTableRow
                                {...emailCampaign}
                                onEditEmailClick={onEditEmailClick}
                                key={emailCampaign.name}
                            />
                        ))}
                    </Table.Body>
                </Table>
            </Stack>
        </Card>
    );
};

export default EmailCampaignsTable;
