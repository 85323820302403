import { useEffect } from 'react';

/**
 * Shows a confirm dialog before the user leaves/refreshes the page.
 *
 * @param when - When `true`, the prompt will be active and it will prevent leave/refresh the page.
 * @returns void
 */
const useBeforeUnloadPrompt = (when: boolean) =>
    useEffect(() => {
        const listener = (event) => {
            if (!when) {
                return true;
            }
            event.preventDefault();
            event.returnValue = '';
            return '';
        };

        window.addEventListener('beforeunload', listener);
        return () => window.removeEventListener('beforeunload', listener);
    }, [when]);

export default useBeforeUnloadPrompt;
