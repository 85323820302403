import { ApiRestClient } from '@trova-trip/trova-common';
import { BookingRepriceQuoteResponse, BookingRepriceUpdate } from './types';

const ADMIN_BASE_PATH = '/api/admin';
const TRAVELER_BASE_PATH = '/api/me';

export const repriceInsurance = async (
    bookingId: string,
    travelAmount: BookingRepriceUpdate['travelAmount'],
    additionalParticipants: BookingRepriceUpdate['additionalParticipants'],
    addOns: BookingRepriceUpdate['addOns'],
    status: BookingRepriceUpdate['status'],
    application: 'admin' | 'traveler' = 'admin',
): Promise<BookingRepriceQuoteResponse> => {
    const basePath =
        application === 'traveler' ? TRAVELER_BASE_PATH : ADMIN_BASE_PATH;
    const path = `${basePath}/bookings/${bookingId}/reprice-quotes`;

    const restClientInstance: ApiRestClient<BookingRepriceUpdate> =
        ApiRestClient.createInstance({
            path,
        });

    let result: BookingRepriceQuoteResponse = { success: false };
    try {
        const response = await restClientInstance.create({
            travelAmount,
            additionalParticipants,
            addOns,
            status,
        });
        result = response.data as unknown as BookingRepriceQuoteResponse;
    } catch (e) {
        console.error(e);
    }
    return result;
};
