import styled from '@emotion/styled';

import { theme } from '@trova-trip/trova-components';
import { DatePicker } from '@trova-trip/trova-components';

export const StyledDatePicker = styled(DatePicker)`
    > div {
        position: static;
        border-radius: 5px;
        padding: 5px 9px;
        min-height: 31px;
        margin-top: 0;
        font-size: 14px;
        .rdtPicker {
            top: 6.25rem;
        }
        input.form-control {
            min-height: auto;
            height: auto;
            font-size: 14px;
            padding: 0;
        }
    }
    @media (min-width: ${theme.breakpoints.base}) {
        width: 115px;
    }
`;
