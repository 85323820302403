import { coreUtils } from '@trova-trip/trova-common';
import { constants, models } from '@trova-trip/trova-models';
import { getSelectedPrePostAccommodations } from '../../AccommodationsForm/AccommodationsForm.helpers';
import { HostSelectedOptionalService } from '../TripRequestForm.types';

type Service = models.services.Service;
type Companion = models.common.Companion;
type HostRoom = models.tripRequest.HostRoom;
type Itinerary = models.itineraries.Itinerary;
type TripRequest = models.tripRequest.TripRequest;
type PackageLevel = constants.itinerary.PackageLevel;
type PrePostAccommodationsSelection =
    models.tripRequest.PrePostAccommodationsSelection;
type SavedItineraryInventoryItem =
    models.itineraryInventoryItems.SavedItineraryInventoryItem;
type HostSelectedOptionalServices = models.trips.HostSelectedOptionalServices;

const { ServiceType, ServiceTiming, PRE_TRIP_SERVICES, POST_TRIP_SERVICES } =
    constants.services;
const { buildPrePostHostOptionalServices } = coreUtils.tripRequestUtils;

const mapToHostSelectedOptionalServices = (
    hostSelectedOptionalServices: HostSelectedOptionalService[],
    focQuantity: number,
): {
    preTripTransferServices: HostSelectedOptionalServices[];
    postTripTransferServices: HostSelectedOptionalServices[];
} => {
    return hostSelectedOptionalServices.reduce(
        (acc, hostSelectedOptionalService) => {
            const service = hostSelectedOptionalService.service;
            const isPreTrip = service.timing === ServiceTiming.PRE_TRIP;

            const name = isPreTrip
                ? PRE_TRIP_SERVICES.TRANSFER_NAME
                : POST_TRIP_SERVICES.TRANSFER_NAME;

            const mappedService: HostSelectedOptionalServices = {
                activity: {
                    name,
                    price: service.price || 0,
                },
                numberOptingIn: focQuantity,
            };

            if (isPreTrip) {
                acc.preTripTransferServices.push(mappedService);
            } else {
                acc.postTripTransferServices.push(mappedService);
            }

            return acc;
        },
        {
            preTripTransferServices: [],
            postTripTransferServices: [],
        } as {
            preTripTransferServices: HostSelectedOptionalServices[];
            postTripTransferServices: HostSelectedOptionalServices[];
        },
    );
};

const isPrePostTransferSelected = (
    hostSelectedOptionalServices: HostSelectedOptionalService[],
): {
    isPreTransferSelected: boolean;
    isPostTransferSelected: boolean;
} => {
    const isPreTransferSelected = hostSelectedOptionalServices.some(
        ({ service: { type, timing } }) =>
            type === ServiceType.AIRPORT_TRANSFER &&
            timing === ServiceTiming.PRE_TRIP,
    );

    const isPostTransferSelected = hostSelectedOptionalServices.some(
        ({ service: { type, timing } }) =>
            type === ServiceType.AIRPORT_TRANSFER &&
            timing === ServiceTiming.POST_TRIP,
    );

    return { isPreTransferSelected, isPostTransferSelected };
};

interface GetAccommodationAndTransferServicesReturn {
    preTripAccommodationServices: HostSelectedOptionalServices[];
    postTripAccommodationServices: HostSelectedOptionalServices[];
    preTripTransferServices: HostSelectedOptionalServices[];
    postTripTransferServices: HostSelectedOptionalServices[];
}

const getAccommodationAndTransferServices = (
    hostSelectedOptionalServices: HostSelectedOptionalService[],
    inventoryItem: SavedItineraryInventoryItem | undefined,
    itinerary: Itinerary,
    hostRooms: HostRoom[],
    companionsLength: number,
): GetAccommodationAndTransferServicesReturn => {
    const focQuantity = companionsLength + 1;

    if (inventoryItem) {
        return buildPrePostHostOptionalServices(
            inventoryItem,
            hostRooms,
            focQuantity,
        );
    }

    const { preTripTransferServices, postTripTransferServices } =
        mapToHostSelectedOptionalServices(
            hostSelectedOptionalServices,
            focQuantity,
        );
    const {
        preTripSelectedAccommodationServices,
        postTripSelectedAccommodationServices,
    } = getSelectedPrePostAccommodations(
        itinerary.additionalOptionalServices as Service[],
        hostRooms,
    );

    return {
        preTripAccommodationServices: preTripSelectedAccommodationServices,
        postTripAccommodationServices: postTripSelectedAccommodationServices,
        preTripTransferServices,
        postTripTransferServices,
    };
};

export const createTripData = (
    itinerary: Itinerary,
    hostSelectedOptionalServices: HostSelectedOptionalService[],
    startDate: Date,
    companions: Companion[],
    hostRooms: HostRoom[],
    selectedPackage: PackageLevel,
    prePostAccommodationsSelection: PrePostAccommodationsSelection,
    inventoryItem: SavedItineraryInventoryItem | undefined,
    tripLength: number | undefined,
): TripRequest => {
    const {
        preTripAccommodationServices,
        postTripAccommodationServices,
        preTripTransferServices,
        postTripTransferServices,
    } = getAccommodationAndTransferServices(
        hostSelectedOptionalServices,
        inventoryItem,
        itinerary,
        hostRooms,
        companions.length,
    );

    const { isPreTransferSelected, isPostTransferSelected } =
        isPrePostTransferSelected(hostSelectedOptionalServices);
    const {
        beforeTrip: isPreAccommodationSelected,
        afterTrip: isPostAccommodationSelected,
    } = prePostAccommodationsSelection;

    const initialTripData = {
        itinerary,
        startDate,
        companions,
        hostRooms,
        hostSelectedOptionalServices: [
            ...(isPreTransferSelected ? preTripTransferServices : []),
            ...(isPostTransferSelected ? postTripTransferServices : []),
            ...(isPreAccommodationSelected ? preTripAccommodationServices : []),
            ...(isPostAccommodationSelected
                ? postTripAccommodationServices
                : []),
        ],
        selectedPackage,
        tripLength,
        inventoryItem,
    } as unknown as TripRequest;

    return initialTripData;
};
