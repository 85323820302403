import { Occupancy } from '../../../../types';
import BaseRoomOptionField, {
    BaseRoomOptionItem,
} from '../BaseRoomOptionField';
import { priceIncludedRoomOptionItem } from '../BaseRoomOptionField/BaseRoomOptionField';

const items: BaseRoomOptionItem[] = [
    {
        icon: 'doubleBed',
        text: 'One bed for two travelers',
    },
    priceIncludedRoomOptionItem,
];

type RoomSharedBedsFieldProps = {
    value: boolean;
    name: string;
};

const RoomSharedBedsField = ({
    value,
    name,
}: RoomSharedBedsFieldProps): JSX.Element => (
    <BaseRoomOptionField
        name={name}
        title='Shared beds'
        value={value}
        items={items}
        type={Occupancy.double}
    />
);

export default RoomSharedBedsField;
