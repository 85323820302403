import Product from 'navigation/Product';
import ReferralCard from './ReferralCard';

const Referral = (): JSX.Element => {
    return (
        <Product
            root={<ReferralCard />}
            path={'/referral'}
            label={'Refer-a-Friend'}
            hideNavigation={true}
        />
    );
};

export default Referral;
