import {
    DistanceMatrixRequest,
    GetDistanceMatrixResponse,
    Library,
    Services,
} from './types';

class GoogleService {
    public readonly apiKey: string;

    private services: Services;

    constructor({ apiKey }: { apiKey: string }) {
        this.services = {} as Services;
        this.apiKey = apiKey;
    }

    /**
     * Returns information about the distance between an origin and a destination.
     */
    public async getDistanceMatrix(
        props: DistanceMatrixRequest,
    ): Promise<GetDistanceMatrixResponse> {
        const { origins, destinations, units, travelMode } = props;

        await this.importLibrary('routes');

        return this.requestDistanceMatrix(
            origins,
            destinations,
            units,
            travelMode,
        );
    }

    /**
     * This method is used to import the Google Maps library needed for the service.
     * For more information on the libraries available, please
     * see https://developers.google.com/maps/documentation/javascript/libraries
     */
    private async importLibrary(library: Library): Promise<void> {
        this.setScript();

        if (!this.services[library]) {
            // @ts-ignore
            this.services[library] = await google.maps.importLibrary(library);
        }
    }

    /**
     * DistanceMatrixService is used to calculate travel distance and time for a matrix of origins and destinations.
     */
    private requestDistanceMatrix(
        origins: google.maps.Place[],
        destinations: google.maps.Place[],
        units: google.maps.UnitSystem = google.maps.UnitSystem.METRIC,
        travelMode: google.maps.TravelMode = google.maps.TravelMode.DRIVING,
    ): Promise<GetDistanceMatrixResponse> {
        if (!this.services.routes) {
            throw new Error('Google service not initialized');
        }

        const distanceMatrixService = new this.services.routes.DistanceMatrixService();

        return new Promise<GetDistanceMatrixResponse>((resolve, reject) => {
            distanceMatrixService.getDistanceMatrix(
                {
                    origins,
                    destinations,
                    unitSystem: units,
                    travelMode: travelMode,
                },
                (
                    response: google.maps.DistanceMatrixResponse,
                    status: google.maps.DistanceMatrixStatus,
                ) => {
                    if (status === google.maps.DistanceMatrixStatus.OK) {
                        resolve({ data: response, success: true });
                    } else {
                        reject({
                            error: `Distance matrix request failed with status: ${status}`,
                            success: false,
                        });
                    }
                },
            );
        });
    }

    /**
     * Get latitude and longitude from a place ID.
     */
    public async getLatLngFromPlaceId(
        placeId: string,
    ): Promise<google.maps.LatLng | null> {
        await this.importLibrary('geocoding');

        if (!this.services.geocoding) {
            throw new Error('Google service not initialized');
        }

        const geocoder = new this.services.geocoding.Geocoder();

        return new Promise<google.maps.LatLng | null>(
            (resolve, reject) => {
                geocoder.geocode(
                    {
                        placeId,
                    },
                    (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
                        const location = results.find(result => result.place_id === placeId)?.geometry?.location;
                        if (status === google.maps.GeocoderStatus.OK && location) {
                            resolve(location);
                        } else {
                            reject(null);
                        }
                    },
                );
            },
        );
    }

    /**
     * The script this method sets is taken from the Google Maps API documentation,
     * to have the importLibrary method available.
     * For more information, please see https://developers.google.com/maps/documentation/javascript/libraries
     */
    private setScript(): void {
        const params = {
            key: this.apiKey,
            v: 'weekly',
        };

        // @ts-ignore
        if (!window?.google?.maps?.importLibrary) {
            // -----------------------------------
            // SCRIPT FROM GOOGLE MAPS API DOCS
            // -----------------------------------
            /* eslint-disable */
            ((g) => {
                let h,
                    a,
                    k,
                    p = 'The Google Maps JavaScript API',
                    c = 'google',
                    l = 'importLibrary',
                    q = '__ib__',
                    m = document,
                    b = window;
                // @ts-ignore
                b = b[c] || (b[c] = {});
                // @ts-ignore
                const d = b.maps || (b.maps = {}),
                    r = new Set(),
                    e = new URLSearchParams(),
                    u = () =>
                        h ||
                        (h = new Promise(async (f, n) => {
                            await (a = m.createElement('script'));
                            e.set('libraries', [...r] + '');
                            for (k in g)
                                e.set(
                                    k.replace(
                                        /[A-Z]/g,
                                        (t) => '_' + t[0].toLowerCase(),
                                    ),
                                    g[k],
                                );
                            e.set('callback', c + '.maps.' + q);
                            a.src =
                                `https://maps.${c}apis.com/maps/api/js?` + e;
                            d[q] = f;
                            a.onerror = () =>
                                (h = n(Error(p + ' could not load.')));

                            a.nonce =
                                // @ts-ignore
                                m.querySelector('script[nonce]')?.nonce || '';
                            m.head.append(a);
                        }));
                d[l]
                    ? console.warn(p + ' only loads once. Ignoring:', g)
                    : (d[l] = (f, ...n) =>
                        r.add(f) && u().then(() => d[l](f, ...n)));
            })(params);
            /* eslint-enable */
        }
    }
}

export default GoogleService;
