import {
    ComboBox,
    ComboBoxProps,
    FormGroup,
} from '@trova-trip/trova-components';
import { constants } from '@trova-trip/trova-models';
import { useCallback } from 'react';
import { roomTypeInformation } from '../AccommodationsForm.helpers';

const TWO_BEDS = constants.tripRequest.HostRoomType.TWO_BEDS;
const ONE_BED = constants.tripRequest.HostRoomType.ONE_BED;
const SHARED_BED = constants.tripRequest.HostRoomType.SHARED_BED;

type BedQuantityOption = {
    label: constants.tripRequest.HostRoomType;
    value: constants.tripRequest.HostRoomType;
};

const options: BedQuantityOption[] = [
    {
        label: ONE_BED,
        value: ONE_BED,
    },
    {
        label: TWO_BEDS,
        value: TWO_BEDS,
    },
    {
        label: SHARED_BED,
        value: SHARED_BED,
    },
];

type BedQuantityComboBoxProps = {
    roomNumber: number;
    handleChange: (roomType: constants.tripRequest.HostRoomType) => void;
    roomType: constants.tripRequest.HostRoomType;
    isDisabled?: boolean;
};

const BedQuantityComboBox = ({
    roomNumber,
    isDisabled,
    handleChange,
    roomType,
}: BedQuantityComboBoxProps) => {
    const handleRoomTypeChange: ComboBoxProps['onChange'] = useCallback(
        (event) => {
            const { value } = event.metadata.value as BedQuantityOption;
            handleChange(value);
        },
        [handleChange],
    );

    const value = { label: roomType, value: roomType } as BedQuantityOption;

    const icon = roomTypeInformation.get(roomType)?.icon;

    const helperText = roomTypeInformation.get(roomType)?.helperText;

    return (
        <FormGroup helperText={helperText}>
            <ComboBox
                name='roomType'
                leftAddon={{
                    text: `Room ${roomNumber}`,
                }}
                placeholder='Choose one'
                isDisabled={isDisabled}
                onChange={handleRoomTypeChange}
                icon={
                    icon
                        ? {
                              as: icon,
                              color: 'blueGray.650',
                          }
                        : undefined
                }
                options={options}
                value={value}
            />
        </FormGroup>
    );
};

export default BedQuantityComboBox;
