import TabPage from '../components/TabPage';
import AddressForm from '../components/Forms/AddressForm/AddressForm';
import { Text } from '@trova-trip/trova-components';

const addressDescription = (
    <>
        <Text fontWeight='bold' fontSize='sm'>
            Why do we need this?
        </Text>
        <Text fontSize='sm'>
            We're committed to providing the safest travel experience possible,
            and we believe that starts long before your trip. Entering your
            address will allow Trova to verify your identity and ensure that our
            platform remains a secure and welcoming place for everyone.
        </Text>
    </>
);

const Address = (): JSX.Element => (
    <TabPage
        title='Address'
        description={addressDescription}
        content={<AddressForm />}
    />
);

export default Address;
