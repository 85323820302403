import { models } from '@trova-trip/trova-models';
import { createContext } from 'react';
import { ServiceFormPermissions } from './components/services/helpers';

type Service = models.services.Service;
export interface ConfigureServicesContextValue {
    handleDropAndDropCards: (
        destinationDay: number,
        serviceIndexToReplace: number,
        itemDragged: { service: { orderOrigin: number; dayOrigin: number } },
    ) => void;
    currentService?: any;
    currentServiceItem?: any;
    handleDrop: (destinationDay: number, service: any) => void;
    closeForm: () => void;
    shouldShowCreateForm: (dayIndex: number) => boolean;
    openForm: (day: number, order: number, type: string) => void;
    daySelected?: number;
    orderSelected?: number;
    shouldShowEditingForm: (dayIndex: number, serviceIndex: number) => boolean;
    addServiceTypeWrapper: (data: any, duration: number) => void;
    updateServiceTypeWrapper: (data: Service, duration: number) => void;
    disabledFields: string[];
    errorText?: string;
    permissions?: ServiceFormPermissions;
}

const ConfigureServicesContext =
    createContext<ConfigureServicesContextValue | null>(null);
ConfigureServicesContext.displayName = 'ConfigureServicesContext';

export default ConfigureServicesContext;
