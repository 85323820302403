import { css } from '@emotion/css';
import {
    BaseBox,
    ProgressBar,
    Stack,
    Text,
} from '@trova-trip/trova-components';
import { UseHostTierInfoReturn } from '../../../../dashboard/hooks/useHostTierInfo';
import { BorderedInnerLinkCard } from '../../../../dashboard/components/common/BorderedInnerCard';
import { constants } from '@trova-trip/trova-models';

interface QualifiedResponsesProps {
    hostTierInfo: UseHostTierInfoReturn;
    qualifiedResponses: number;
    onClick?: () => void;
}

const tierNames = constants.itinerary.TierNames;
const finalTier = Object.values(tierNames).pop();

const QualifiedResponses = ({
    hostTierInfo,
    qualifiedResponses,
    onClick,
}: QualifiedResponsesProps) => {
    const {
        currentTier: { name: currentTierName },
        nextTier,
    } = hostTierInfo;
    const { requiredResponses, color: nextTierColor } = nextTier;
    const title = 'Qualified Responses';

    const isFinalTier = currentTierName === finalTier;
    const info = isFinalTier
        ? 'You now have access to our full itinerary catalog'
        : 'Qualified respondents are age 18+ with a travel budget of $2,000+';

    const showTierProgress = !isFinalTier ? (
        <BaseBox className={css({ lineHeight: 0 })} position='relative'>
            <ProgressBar
                as='circular'
                size='lg'
                color={nextTierColor}
                min={0}
                max={requiredResponses}
                value={qualifiedResponses}
                showLabel
            />
        </BaseBox>
    ) : undefined;

    return (
        <BorderedInnerLinkCard
            title={title}
            paddingX={{ base: 3, md: 5 }}
            paddingY={{ base: 3, md: 3 }}
            height='full'
            onClick={onClick}
        >
            <Stack
                justify='space-between'
                align={{ base: 'center', md: 'flex-start' }}
                wrap='nowrap'
                width='full'
            >
                <Stack spacing={{ base: 0, md: 1 }} direction='column'>
                    <Text fontSize='4xl' lineHeight='normal' fontWeight='bold'>
                        {isFinalTier
                            ? qualifiedResponses
                            : `${qualifiedResponses} / ${requiredResponses}`}
                    </Text>
                    <Text fontSize='sm' lineHeight='normal'>
                        {info}
                    </Text>
                </Stack>
                {showTierProgress}
            </Stack>
        </BorderedInnerLinkCard>
    );
};

export default QualifiedResponses;
