import { models } from '@trova-trip/trova-models';
import { Dispatch, SetStateAction, useState } from 'react';
import { Stack, SectionCard } from '@trova-trip/trova-components';

import { InitialFormData } from '../TripRequestForm/TripRequestForm.types';
import { BedQuantityInput, PrePostAccommodationsInputs } from './components';
import { useAccommodationsAvailability } from '../../hooks/useAccommodationsAvailability';

type HostRoom = models.tripRequest.HostRoom;
type Itinerary = models.itineraries.Itinerary;
type Service = models.services.Service;
type PrePostAccommodationsSelection =
    models.tripRequest.PrePostAccommodationsSelection;
type ItineraryInventoryItem =
    models.itineraryInventoryItems.ItineraryInventoryItem;

type AccommodationsFormProps = {
    totalCompanionFOCs: number;
    onHostRoomsChange: (rooms: HostRoom[]) => void;
    isReadOnly?: boolean;
    initialValues: HostRoom[];
    prePostAccommodationsSelection: PrePostAccommodationsSelection;
    setFormData: Dispatch<SetStateAction<InitialFormData>>;
    itinerary: Itinerary;
    itineraryInventoryItem: ItineraryInventoryItem | undefined;
};

const AccommodationsForm = ({
    totalCompanionFOCs,
    initialValues,
    onHostRoomsChange,
    prePostAccommodationsSelection,
    setFormData,
    isReadOnly,
    itinerary,
    itineraryInventoryItem,
}: AccommodationsFormProps): JSX.Element => {
    const [rooms, setRooms] = useState<HostRoom[]>(initialValues);
    const { additionalOptionalServices } = itinerary;

    const accommodationAvailability = useAccommodationsAvailability(
        additionalOptionalServices as Service[],
        itineraryInventoryItem,
        rooms,
    );

    const { arePreAccommodationsAvailable, arePostAccommodationsAvailable } =
        accommodationAvailability;

    const arePrePostAccommodationsAvailable =
        arePreAccommodationsAvailable || arePostAccommodationsAvailable;

    return (
        <SectionCard size='md' title='Your Accommodations'>
            <Stack
                wrap='nowrap'
                justify='space-around'
                marginTop={{ base: 4, sm: 6 }}
                spacing={{ base: 5, sm: 6 }}
                direction={{ base: 'column', sm: 'row' }}
            >
                <BedQuantityInput
                    onChange={onHostRoomsChange}
                    rooms={rooms}
                    setRooms={setRooms}
                    totalCompanionFOCs={totalCompanionFOCs}
                    isReadOnly={!!isReadOnly}
                    width={{
                        base: 'full',
                        sm: arePrePostAccommodationsAvailable ? '50%' : 'full',
                    }}
                />
                <PrePostAccommodationsInputs
                    additionalOptionalServices={
                        additionalOptionalServices as Service[]
                    }
                    isReadOnly={!!isReadOnly}
                    prePostAccommodationsSelection={
                        prePostAccommodationsSelection
                    }
                    accommodationAvailability={accommodationAvailability}
                    rooms={rooms}
                    setFormData={setFormData}
                    itineraryInventoryItem={itineraryInventoryItem}
                />
            </Stack>
        </SectionCard>
    );
};

export default AccommodationsForm;
