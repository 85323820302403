import { get, set } from 'lodash';
const AUTO_SAVE_INCREMENT = 10000;

class FormSaver {
    constructor(
        formComponent,
        onSubmit,
        defaultFormData = {},
        autoSave = false,
    ) {
        this.formComponent = formComponent;
        this.onSubmit = onSubmit;
        this.defaultFormData = defaultFormData;
        this.formComponent.state = {
            ...this.formComponent.state,
            formData: { ...defaultFormData },
        };
        this._fieldProps = {};
        this.hasChanged = false;
        if (autoSave) {
            this.autoSave = true;
            this.triggerAutoSave(this.formComponent.state.formData);
        }
    }

    triggerAutoSave(data) {
        this.autoSaveTimeout = setTimeout(() => {
            if (data !== this.formComponent.state.formData) {
                this.onSubmit(this.formComponent.state.formData);
                this.hasChanged = false;
            }
            this.triggerAutoSave(this.formComponent.state.formData);
        }, AUTO_SAVE_INCREMENT);
    }

    reset() {
        this.formComponent.setState({
            formData: { ...this.defaultFormData },
        });
    }

    setDefaultFormData(defaultFormData) {
        this.defaultFormData = defaultFormData;
        this.formComponent.setState(() => ({ formData: defaultFormData }));
    }

    getData(name) {
        if (name) {
            return (
                this.formComponent.state.formData[name] ||
                get(this.formComponent.state.formData, name, '')
            );
        }
        return this.formComponent.state.formData;
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.stopAutoSave();
        this.onSubmit(this.formComponent.state.formData);
        this.hasChanged = false;
        if (this.autoSave) {
            this.triggerAutoSave(this.formComponent.state.formData);
        }
    };

    stopAutoSave() {
        clearTimeout(this.autoSaveTimeout);
    }

    getHasChanged() {
        return this.hasChanged;
    }

    updateValue(name, value, callback) {
        this.hasChanged = true;
        let formUpdatedValue = {
            ...this.formComponent.state.formData,
        };
        if (this.formComponent.state.formData[name]) {
            formUpdatedValue = {
                ...formUpdatedValue,
                [name]: value,
            };
        } else {
            set(formUpdatedValue, name, value);
        }

        this.formComponent.setState(
            () => ({
                formData: formUpdatedValue,
            }),
            callback,
        );
    }

    getFieldProps(fieldName, additionalFieldProps) {
        const value = this.getData(fieldName);
        const existingFieldProps = this._fieldProps[fieldName];
        if (existingFieldProps && value === existingFieldProps[value]) {
            return existingFieldProps;
        }
        this._fieldProps[fieldName] = {
            ...additionalFieldProps,
            name: fieldName,
            value,
            onChange: this.handleChange,
        };
        return this._fieldProps[fieldName];
    }

    handleChange = (event) => {
        const { name, value, type } = event.target;
        if (type === `checkbox`) {
            this.updateValue(
                name,
                !get(
                    this.formComponent.state.formData,
                    name,
                    this.formComponent.state.formData[name],
                ),
            );
            return;
        }

        if (type === `number`) {
            this.updateValue(name, Number(value));
            return;
        }

        this.updateValue(name, value);
    };

    handleChanges = (events) => {
        this.hasChanged = true;
        this.formComponent.setState(() => ({
            formData: {
                ...this.formComponent.state.formData,
                ...events,
            },
        }));
    };

    hasKey(name) {
        if (name) {
            return this.formComponent.state.formData.hasOwnProperty(name);
        }
        return false;
    }
}

export default FormSaver;
