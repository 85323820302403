import { Header, HeaderProps } from '@trova-trip/trova-components';
import NavigationContext from '../../../navigation/NavigationContext';
import useProductHeaderVisibility from '../../../navigation/hooks/useProductHeaderVisibility';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useWindowHostNameAndProtocol } from '../../../util/hooks/useWindowHostname';

export const ProductHeader = (props: HeaderProps): JSX.Element | null => {
    const { isProductHeaderVisible } = useProductHeaderVisibility();
    const {
        backgroundImage,
        backButtonConfig = {
            show: { base: true, lg: false },
        },
        ...restOfHeaderProps
    } = props;

    const history = useHistory();
    const hostAndProtocol = useWindowHostNameAndProtocol();
    const { productPath } = useContext(NavigationContext);

    const handleBackClick = useCallback(
        () => history.push(productPath),
        [history, productPath],
    );

    return isProductHeaderVisible ? (
        <Header
            backgroundImage={`${hostAndProtocol}${backgroundImage}`}
            backButtonConfig={{
                onClick: handleBackClick,
                ...backButtonConfig,
            }}
            {...restOfHeaderProps}
        />
    ) : null;
};
