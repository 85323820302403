import { LabelProps } from 'recharts';
import { theme } from '@trova-trip/trova-components';
import { ChartItemValue } from './BookingsChart';

interface CustomLabelProps extends LabelProps {
    data: ChartItemValue[];
    index?: number;
}

const getXYValue = (value: number | string | undefined): number => {
    return typeof value === 'string' ? parseInt(value) : value || 0;
};

const CustomLabel = ({
    x,
    y,
    stroke,
    value,
    data,
    index,
}: CustomLabelProps): JSX.Element => {
    const xValue = getXYValue(x);
    const yValue = getXYValue(y);

    return (
        <>
            <text
                x={xValue}
                y={yValue - 5}
                dy={-5}
                fill={stroke}
                fontSize={10}
                textAnchor='middle'
            >
                {value}
            </text>
            <circle
                cx={xValue}
                cy={yValue}
                r='5'
                fill={theme.colors.teal[300]}
            />
            <text
                x={xValue}
                y={yValue + 20}
                dy={-4}
                fill={stroke}
                fontSize={10}
                textAnchor='middle'
            >
                {index && data[index]?.name}
            </text>
        </>
    );
};

export default CustomLabel;
