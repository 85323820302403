import { get } from '../state/utils/api';
import { models } from '@trova-trip/trova-models';
import { appConstants } from '@trova-trip/trova-common';
import { APP_ENVIRONMENT } from '../util/appUtils';

type ReviewReport = models.reviewReports.ReviewReportResponse;

const uris =
    appConstants.TrovaTripAppsDomainMap[
        APP_ENVIRONMENT as keyof typeof appConstants.TrovaTripAppsDomainMap
    ];

const REVIEW_API_ENDPOINT = uris.api.review;
const REVIEW_API_URL = `${REVIEW_API_ENDPOINT}/api/public/review`;

const getItineraryReviewReport = async (itineraryId): Promise<ReviewReport> => {
    try {
        const response = await get(
            `${REVIEW_API_URL}/itinerary/${itineraryId}/report`,
        );
        if (response?.status === 'success') {
            return response?.data as ReviewReport;
        } else {
            throw new Error(response?.message);
        }
    } catch (error) {
        console.error(error);
        return {} as ReviewReport;
    }
};

export { getItineraryReviewReport };
