import { models } from '@trova-trip/trova-models';
import { capitalize } from '../../../utils';
import {
    LibraryServiceTypesLabels,
    ServiceTypesColors,
    ServiceTypesLabels,
    MealOptions,
} from '../../../../config/constants';

type ServiceType = models.services.ServiceType;
type Service = models.services.Service;
type LibraryServiceType = keyof typeof LibraryServiceTypesLabels;
type MealOption = keyof typeof MealOptions;

export interface Accommodation extends models.services.Accommodation {
    dayOfService: number;
}

const getColorFromServiceType = (serviceType: ServiceType): string =>
    ServiceTypesColors[ServiceTypesLabels[serviceType]];

const getServiceTitleLabel = (serviceType: ServiceType): string =>
    ServiceTypesLabels[serviceType];

const getLibraryServiceTitle = (serviceType: LibraryServiceType): string =>
    LibraryServiceTypesLabels[serviceType];

const getColorForLibraryService = (serviceType: LibraryServiceType): string =>
    ServiceTypesColors[LibraryServiceTypesLabels[serviceType]];

const getOptionTitleFromMeal = (options: MealOption[]): string[] =>
    options.map((currentOption: MealOption) => MealOptions[currentOption]);

const getTitleFromMeal = (mealType: string, options: MealOption[]): string => {
    const capitalizedMealType = capitalize(mealType);

    if (!options.length) {
        return capitalizedMealType;
    }

    return `${capitalizedMealType} - ${getOptionTitleFromMeal(options).join(
        `, `,
    )}`;
};

const getTitleFromActivity = (activity: models.services.Activity): string =>
    `${capitalize(activity?.name || '')}${
        !activity.includedActivity ? ' (Optional)' : ''
    }`;

const getTitleForService = (service: Service): string => {
    if (service.type === 'meal') {
        return getTitleFromMeal(
            service?.mealType || '',
            service?.options || [],
        );
    }

    if (service.type === 'activity') {
        return getTitleFromActivity(service);
    }

    if ((service as models.services.Info).title) {
        return (service as models.services.Info).title;
    }

    if (service.name) {
        return service.name;
    }

    return capitalize(service.type);
};

const appendDayOfServiceToTitle = (
    service: Accommodation,
    title: string,
): string => {
    if (!service.dayOfService) {
        return title;
    }

    return `${title} (${service.dayOfService}/${service.numberOfNights})`;
};

export {
    appendDayOfServiceToTitle,
    getColorFromServiceType,
    getServiceTitleLabel,
    getLibraryServiceTitle,
    getColorForLibraryService,
    getTitleForService,
};
