import React, { useState } from 'react';
import * as yup from 'yup';
import {
    BaseBox,
    Divider,
    Heading,
    FormSaver,
    Grid,
    useFormSaver,
    FormValues,
    Alert,
    Hide,
    Stack,
    useToast,
} from '@trova-trip/trova-components';
import {
    HostProfileFormValues,
    HostProfileResponse,
    PublicProfileFieldNames,
    FieldName,
} from '../utils/types';
import { profile } from '../../../../../state/profile';
import { saveHostPublicProfile } from '../../../../../state/hosts';
import { validationSchemas } from '@trova-trip/trova-models';
import {
    PublicProfileFormNavbar,
    PublicProfileFormMobileSave,
} from './PublicProfileNavigation';
import { Socials } from '../../../components/SocialMediaAccounts';
import FieldWrapper from './FieldWrapper';
import EditPublicProfilePopup from './EditPublicProfilePopup';
import { ProfilePicture } from './fields/ProfilePicture';
import usePublicProfileAnalytics, {
    PUBLIC_PROFILE_TRACKING_EVENTS,
} from '../../../hooks/usePublicProfileAnalytics';
import {
    formatSocialChannels,
    truncatePersonalSite,
    addHttpsProtocolToPersonalSite,
} from 'applications/common/helpers';

interface PublicProfileFormProps {
    initialValues: HostProfileFormValues;
    hostNameFallback: string;
    hostId?: string;
}

const {
    validationMessages: { requiredMessage },
    PersonalSiteSchema,
} = validationSchemas;

export const validationSchema = yup.object().shape({
    [PublicProfileFieldNames.BIO]: yup
        .string()
        .required(requiredMessage('Bio'))
        .test({
            name: 'allRequiredMissing',
            test: (value, ctx) => {
                if (
                    !value &&
                    !ctx.parent[PublicProfileFieldNames.PROFILE_PICTURE]
                ) {
                    return ctx.createError({
                        message: 'Bio and profile photo are required',
                    });
                }
                return true;
            },
        }),
    [PublicProfileFieldNames.DISPLAY_NAME]: yup.string(),
    [PublicProfileFieldNames.INTERESTS]: yup
        .array()
        .min(1, 'At least one interest is required')
        .required(requiredMessage('Interests')),
    [PublicProfileFieldNames.LANGUAGES]: yup.array().of(yup.object()),
    [PublicProfileFieldNames.COUNTRY]: yup.object(),
    [PublicProfileFieldNames.PRONOUNS]: yup.array().of(yup.string()),
    [PublicProfileFieldNames.PROFILE_PICTURE]: yup
        .string()
        .required(requiredMessage('Profile picture')),
    [PublicProfileFieldNames.SOCIAL_CHANNELS]: yup
        .object()
        .shape({
            facebook: yup.string(),
            personalSite: yup
                .string()
                .transform((value) => (value ? `https://${value}` : value))
                .concat(PersonalSiteSchema),
            instagram: yup.string(),
            tiktok: yup.string(),
            youtube: yup.string(),
        })
        .test({
            name: PublicProfileFieldNames.SOCIAL_CHANNELS,
            message: 'At least one social channel is required',
            test: (value) => value && Object.keys(value).length > 0,
        })
        .required(requiredMessage('Social channels')),
});

const PublicProfileForm = ({
    initialValues,
    hostNameFallback,
    hostId,
}: PublicProfileFormProps): JSX.Element => {
    const [isFormSubmitFailed, setIsFormSubmitFailed] =
        useState<boolean>(false);
    const toast = useToast();
    const { getRecord: getUserProfile } = profile.useDispatch();

    const [editField, setEditField] = useState<FieldName | null>(null);
    const { trackPublicProfileEvent } = usePublicProfileAnalytics();

    const openEditPopup = (fieldName: FieldName) => {
        setEditField(fieldName);
    };

    const closeEditPopup = () => setEditField(null);

    const saveField = (fieldName: FieldName, data) => {
        setValue[fieldName](data);
        closeEditPopup();
    };

    const onSubmit = async (values): Promise<void> => {
        trackPublicProfileEvent(
            PUBLIC_PROFILE_TRACKING_EVENTS.savedPublicProfile,
        );
        try {
            if (hostId) {
                const { error } = await saveHostPublicProfile(hostId, {
                    ...values,
                    socialChannels: formatSocialChannels(
                        values.socialChannels as Socials,
                        addHttpsProtocolToPersonalSite,
                    ),
                });
                if (!error) {
                    toast({
                        description:
                            'Your public host profile was updated successfully.',
                        title: 'Success',
                        status: 'success',
                        isClosable: true,
                        position: 'top-right',
                    });
                    return getUserProfile();
                }
                setIsFormSubmitFailed(true);
            }
        } catch (error) {
            setIsFormSubmitFailed(true);
            console.error(error);
            return error;
        }
    };

    const {
        formValues: { get: getValue, set: setValue },
        formErrors,
        handleFormSubmit,
        isFormSubmitting,
        isFormDirty,
    } = useFormSaver({
        onSubmit,
        initialValues: {
            ...initialValues,
            socialChannels: formatSocialChannels(
                initialValues.socialChannels,
                truncatePersonalSite,
            ),
        } as unknown as FormValues,
        validationSchema: {
            schema: validationSchema,
        },
    });

    const userCanSubmit = isFormDirty;
    const errorAlertCloseHandler = (): void => setIsFormSubmitFailed(false);

    const generateFormError = () => {
        if (isFormSubmitFailed) return 'Profile form failed to save.';
        if (formErrors) {
            if (formErrors.bio) {
                return formErrors.bio[0];
            } else if (formErrors.profilePictureUrl) {
                return formErrors.profilePictureUrl[0];
            }
        }

        return '';
    };

    return (
        <FormSaver name='edit-public-profile' onSubmit={handleFormSubmit}>
            <Grid gap={6} width='full' paddingBottom={{ base: 16, md: 0 }}>
                <Grid.Item columnSpan={{ base: 12 }}>
                    <PublicProfileFormNavbar
                        isLoading={isFormSubmitting}
                        isDisabled={!userCanSubmit}
                    />
                </Grid.Item>
                <Grid.Item columnSpan={{ base: 12, md: 5 }}>
                    <Stack
                        direction='column'
                        background='neutral.white'
                        padding={{ base: 4, md: 6 }}
                        spacing={8}
                        borderRadius='xl'
                        width='full'
                    >
                        <BaseBox width='full'>
                            <ProfilePicture
                                fieldData={
                                    getValue[
                                        PublicProfileFieldNames.PROFILE_PICTURE
                                    ] as HostProfileFormValues[PublicProfileFieldNames.PROFILE_PICTURE]
                                }
                                onChange={
                                    setValue[
                                        PublicProfileFieldNames.PROFILE_PICTURE
                                    ]
                                }
                            />
                        </BaseBox>
                        <Heading as='h4'>
                            {getValue[PublicProfileFieldNames.DISPLAY_NAME] ||
                                hostNameFallback}
                        </Heading>
                    </Stack>
                </Grid.Item>
                <Grid.Item columnSpan={{ base: 12, md: 7 }}>
                    <BaseBox
                        background='neutral.white'
                        paddingX={{ base: 4, md: 6 }}
                        borderRadius='xl'
                        paddingTop={isFormSubmitFailed || formErrors ? 6 : 0}
                    >
                        <Hide breakpoint={{ max: 'md' }}>
                            {formErrors || isFormSubmitFailed ? (
                                <Alert
                                    title={
                                        formErrors
                                            ? 'Required Fields are Missing'
                                            : 'Error'
                                    }
                                    variant='inline'
                                    description={generateFormError()}
                                    status='error'
                                    isClosable={!formErrors}
                                    onCloseAlert={errorAlertCloseHandler}
                                />
                            ) : null}
                        </Hide>
                        <Stack
                            width='full'
                            direction='column'
                            divider={<Divider />}
                        >
                            <FieldWrapper
                                fieldName={PublicProfileFieldNames.BIO}
                                formData={
                                    getValue[
                                        PublicProfileFieldNames.BIO
                                    ] as HostProfileFormValues[PublicProfileFieldNames.BIO]
                                }
                                onEditButtonClick={openEditPopup}
                            />
                            <FieldWrapper
                                fieldName={PublicProfileFieldNames.DISPLAY_NAME}
                                formData={
                                    getValue[
                                        PublicProfileFieldNames.DISPLAY_NAME
                                    ] as HostProfileFormValues[PublicProfileFieldNames.DISPLAY_NAME]
                                }
                                onEditButtonClick={openEditPopup}
                            />
                            <FieldWrapper
                                fieldName={PublicProfileFieldNames.INTERESTS}
                                formData={
                                    getValue[
                                        PublicProfileFieldNames.INTERESTS
                                    ] as unknown as HostProfileFormValues[PublicProfileFieldNames.INTERESTS]
                                }
                                onEditButtonClick={openEditPopup}
                            />
                            <FieldWrapper
                                fieldName={PublicProfileFieldNames.LANGUAGES}
                                formData={
                                    getValue[
                                        PublicProfileFieldNames.LANGUAGES
                                    ] as unknown as HostProfileFormValues[PublicProfileFieldNames.LANGUAGES]
                                }
                                onEditButtonClick={openEditPopup}
                            />
                            <FieldWrapper
                                fieldName={PublicProfileFieldNames.COUNTRY}
                                formData={
                                    getValue[
                                        PublicProfileFieldNames.COUNTRY
                                    ] as unknown as HostProfileFormValues[PublicProfileFieldNames.COUNTRY]
                                }
                                onEditButtonClick={openEditPopup}
                            />
                            <FieldWrapper
                                fieldName={PublicProfileFieldNames.PRONOUNS}
                                formData={
                                    getValue[
                                        PublicProfileFieldNames.PRONOUNS
                                    ] as unknown as HostProfileFormValues[PublicProfileFieldNames.PRONOUNS]
                                }
                                onEditButtonClick={openEditPopup}
                            />
                            <FieldWrapper
                                fieldName={
                                    PublicProfileFieldNames.SOCIAL_CHANNELS
                                }
                                formData={
                                    getValue[
                                        PublicProfileFieldNames.SOCIAL_CHANNELS
                                    ] as Socials
                                }
                                onEditButtonClick={openEditPopup}
                            />
                        </Stack>
                    </BaseBox>
                </Grid.Item>
            </Grid>
            <Hide breakpoint={{ min: 'md' }}>
                <PublicProfileFormMobileSave
                    isFormSubmitFailed={isFormSubmitFailed}
                    isFormSubmitting={isFormSubmitting}
                    userCanSubmit={userCanSubmit}
                    formErrorMessage={generateFormError()}
                    errorAlertCloseHandler={errorAlertCloseHandler}
                />
            </Hide>
            <EditPublicProfilePopup
                fieldName={editField}
                formData={
                    editField
                        ? (getValue[
                              editField
                          ] as HostProfileResponse[FieldName])
                        : undefined
                }
                onSave={saveField}
                onCancel={closeEditPopup}
            />
        </FormSaver>
    );
};

export default PublicProfileForm;
