import { Text, Heading, BaseBox, Stack } from '@trova-trip/trova-components';
import {
    Button,
    ButtonProps,
    Card,
    CardProps,
} from '@trova-trip/trova-components/build/next';

export interface CardAction {
    label: string;
    onClick: () => void;
    variant?: ButtonProps['variant'];
}

interface CardWithActionsProps extends CardProps {
    topAddon?: React.ReactElement;
    title: string;
    description: React.ReactNode;
    footer?: React.ReactNode;
    actions: CardAction[];
}

const CardWithActions = (props: CardWithActionsProps) => {
    const { topAddon, title, description, actions, footer, ...rest } = props;
    return (
        <Card width='full' {...rest}>
            <Stack direction='column' spacing={5}>
                {topAddon ? topAddon : null}
                <Heading
                    fontSize={{ base: 'xl', md: '3xl' }}
                    color='blueGray.600'
                    fontWeight='bold'
                    lineHeight='short'
                    as='h4'
                >
                    {title}
                </Heading>
                <BaseBox width='full' paddingRight={4}>
                    <Text fontSize={{ base: 'sm', md: 'base' }}>
                        {description}
                    </Text>
                </BaseBox>
                <Stack
                    direction={{ base: 'column', md: 'row' }}
                    align='center'
                    spacing={5}
                >
                    {actions?.map((action) => (
                        <Button
                            key={action.label}
                            size={{ base: 'md', md: 'md' }}
                            {...action}
                        >
                            {action.label}
                        </Button>
                    ))}
                </Stack>
                {footer ? (
                    <Text
                        fontSize={{ base: 'sm', lg: 'base' }}
                        lineHeight={{ base: 'normal', lg: 'tall' }}
                    >
                        {footer}
                    </Text>
                ) : null}
            </Stack>
        </Card>
    );
};

export default CardWithActions;
