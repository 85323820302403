import React, { ComponentProps, useEffect, useState } from 'react';
import {
    BaseBox,
    Text,
    Textarea,
    TextareaSize,
    theme,
} from '@trova-trip/trova-components';
import styled from '@emotion/styled';

type TextAreaProps = ComponentProps<typeof Textarea>;

const ProfileTextarea = (props: TextAreaProps): JSX.Element => {
    const [charCount, setCharCount] = useState(0);

    useEffect(() => {
        setCharCount(props.value?.length || 0);
    }, [props.value]);

    return (
        <BaseBox position='relative'>
            <StyledTextarea
                size={TextareaSize.Flexible}
                expandable={false}
                {...props}
            />
            <BaseBox
                position='absolute'
                right='0'
                bottom={props.error ? '0' : '-1.5rem'}
            >
                <Text size='sm' color='blueGray.400' fontWeight='medium'>
                    {charCount} / 350
                </Text>
            </BaseBox>
        </BaseBox>
    );
};

const StyledTextarea = styled(Textarea)`
    textarea {
        min-height: 10rem;
        margin: 0;
        border-color: ${theme.colors.blueGray[300]};
        border-radius: ${theme.radii.md};

        &:hover,
        &:focus {
            border-color: ${theme.colors.blueGray[500]};
        }
    }
`;

export default ProfileTextarea;
