import usePublishSystem from 'applications/common/hooks/trips/usePublishSystem';
import useTripPageUrl from 'applications/common/hooks/trips/useTripPageUrl';
import CopyTextButton from 'components/CopyTextButton';
import StickyContentBase from '../StickyContent/StickyContentBase';
import TripReviewPageButton from '../TripReviewPageButton/TripReviewPageButton';
import { styledTripPublishStatusCard } from './TripPublishStatusCard.styles';

const TripPublishStatusCard = (): JSX.Element => {
    const {
        currentStatusBadge,
        isPublished,
        isPending,
        isInReview,
        isMarketing,
        isLive,
    } = usePublishSystem();

    const { text, color } = currentStatusBadge;

    const tripPageUrl = useTripPageUrl();

    const shouldShowPageLink = isInReview || isLive;

    return (
        <div className={styledTripPublishStatusCard}>
            <StickyContentBase
                title='Trip Page Status'
                subtitle={text}
                statusIndicatorColor={color}
                action={
                    <>
                        {isPending && (
                            <TripReviewPageButton
                                text='Review Page'
                                icon='eye'
                            />
                        )}
                        {isMarketing && (
                            <TripReviewPageButton
                                text='Preview Trip'
                                icon='arrow-right'
                            />
                        )}
                        {shouldShowPageLink && (
                            <CopyTextButton
                                textToCopy={tripPageUrl}
                                onCopyTitle='Link copied'
                                onCopyMessage='The trip page was successfully copied'
                                size={{ base: 'sm', md: 'md' }}
                                isDisabled={!isPublished}
                            >
                                Copy Trip Page
                            </CopyTextButton>
                        )}
                    </>
                }
            />
        </div>
    );
};

export default TripPublishStatusCard;
