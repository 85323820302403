import { useState } from 'react';
import { css } from '@emotion/css';
import { useSelector } from 'react-redux';
import { models } from '@trova-trip/trova-models';
import StickyContentLayout from '../../../../../../components/StickyContentLayout/StickyContentLayout';
import { Grid } from '@material-ui/core';
import Tab from '../../../../../../navigation/Tab';
import StickyContent from './StickyContent';
import UpdateRequestForm from '../../../../components/TripRequestForm/UpdateRequestForm';
import { TripRequestStoreSelector } from '../../../../../../state/tripRequests';

type SuggestedSellPrice = models.itineraries.SuggestedSellPrice;

export const styledMainContainer = css`
    margin-top: 2rem;
`;

const RequestTab = (): JSX.Element => {
    const {
        userTripRequests: { current: currentTripRequest },
    } = useSelector((state: TripRequestStoreSelector) => state);

    const { maximumSpots } = currentTripRequest.itinerary;

    const [travelersTierNumber] = useState<number>(maximumSpots || 0);
    const [estimatedPrice, setEstimatedPrice] = useState<SuggestedSellPrice>();

    return (
        <Tab label={'Request'} path={'/request'} hideNavigation>
            <StickyContentLayout
                containerClassName={styledMainContainer}
                stickyContent={
                    <StickyContent
                        value={estimatedPrice}
                        isRequestEditable={false}
                    />
                }
                mainContent={
                    <StickyContentLayout observableContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <UpdateRequestForm
                                    onChangePrice={(price): void => {
                                        setEstimatedPrice(price);
                                    }}
                                    travelersTierNumber={travelersTierNumber}
                                    editMode={false}
                                />
                            </Grid>
                        </Grid>
                    </StickyContentLayout>
                }
            />
        </Tab>
    );
};

export default RequestTab;
