import { analytics, analyticsTypes } from '@trova-trip/trova-common';
import { models } from '@trova-trip/trova-models';
import {
    Journey,
    TransferDetail,
} from '../../../../../state/features/transfer/types';
import { isTransferDetailCompleteOrWithoutQuote } from '../../../../../state/features/transfer/utils';

type User = models.users.User;
type Trip = models.trips.Trip;
type Service = models.services.Service;
type Booking = models.bookings.Booking;
type Itinerary = models.itineraries.Itinerary;
type UserGroupName = models.users.ValidGroupNames;

interface DefaultProps {
    userId: User['_id'] | undefined;
    tripId: Trip['_id'] | undefined;
    tripName: Trip['name'] | undefined;
    bookingId: Booking['_id'] | undefined;
    itineraryId: Itinerary['_id'] | undefined;
    userGroup: UserGroupName | undefined;
}

type TrackEventProperties = analyticsTypes.TrackEventParameters['properties'];

interface Event<T extends DefaultProps> {
    name: string;
    payload?: T;
}

const trackEvent = <T extends DefaultProps>(event: Event<T>): void => {
    const { name, payload } = event;
    analytics.trackEvent({
        eventName: name,
        properties: payload as TrackEventProperties,
    });
};

interface StartGroundTransferBookingPayload extends DefaultProps {
    tripStatus: Trip['status'];
}
const trackStartGroundTransferBookingEvent = (
    payload: StartGroundTransferBookingPayload,
): void =>
    trackEvent({
        name: 'User Started Ground Transfers Booking',
        payload,
    });

const getGroundTransferOptionName = (
    arrival: TransferDetail,
    departure: TransferDetail,
): string => {
    const isArrivalComplete = isTransferDetailCompleteOrWithoutQuote(arrival);
    const isDepartureComplete =
        isTransferDetailCompleteOrWithoutQuote(departure);

    if (isArrivalComplete && !isDepartureComplete) {
        return 'Arrival';
    }

    if (isDepartureComplete && !isArrivalComplete) {
        return 'Return';
    }

    return 'Arrival & Return';
};

interface TransferProperties {
    groundTransferService: Service;
    arrival: TransferDetail;
    departure: TransferDetail;
    cartBalance: number;
}

interface CompleteTravelDetailStepPayload
    extends DefaultProps,
        Omit<TransferProperties, 'cartBalance'> {}

const trackCompleteTravelDetailStepEvent = (
    payload: CompleteTravelDetailStepPayload,
): void => {
    const { groundTransferService, arrival, departure, ...rest } = payload;

    trackEvent({
        name: 'User Completed Ground Transfers Pickup and Drop-off',
        payload: {
            selectedServices: [{ ...groundTransferService }],
            groundTransferOption: getGroundTransferOptionName(
                arrival,
                departure,
            ),
            ...rest,
        },
    });
};

interface CompleteVehicleStepPayload extends DefaultProps, TransferProperties {}

const trackCompleteVehicleStepEvent = (
    payload: CompleteVehicleStepPayload,
): void => {
    const { groundTransferService, arrival, departure, ...rest } = payload;

    trackEvent({
        name: 'User Selected Ground Transfers Vehicle',
        payload: {
            selectedServices: [{ ...groundTransferService }],
            groundTransferOption: getGroundTransferOptionName(
                arrival,
                departure,
            ),
            ...rest,
        },
    });
};

interface CompleteContactStepPayload extends DefaultProps, TransferProperties {}

const trackCompleteContactStepEvent = (
    payload: CompleteContactStepPayload,
): void => {
    const { groundTransferService, arrival, departure, ...rest } = payload;

    trackEvent({
        name: 'User Completed Ground Transfers Contact Information',
        payload: {
            selectedServices: [{ ...groundTransferService }],
            groundTransferOption: getGroundTransferOptionName(
                arrival,
                departure,
            ),
            ...rest,
        },
    });
};

interface BookGroundTransferPayload extends DefaultProps, TransferProperties {
    paidBalance?: number;
}

const trackBookGroundTransferEvent = (
    payload: BookGroundTransferPayload,
): void => {
    const { groundTransferService, arrival, departure, ...rest } = payload;

    trackEvent({
        name: 'User Booked Ground Transfers',
        payload: {
            selectedServices: [{ ...groundTransferService }],
            groundTransferOption: getGroundTransferOptionName(
                arrival,
                departure,
            ),
            ...rest,
        },
    });
};

interface PurchaseServicePayload extends DefaultProps {
    groundTransferService: Service;
    servicePrice: number;
}

const trackPurchaseServiceEvent = (payload: PurchaseServicePayload): void => {
    const { groundTransferService, servicePrice, ...rest } = payload;

    trackEvent({
        name: 'User Purchased Service',
        payload: {
            serviceId: groundTransferService._id,
            serviceName: groundTransferService.name,
            price: servicePrice,
            servicePrice,
            ...rest,
        },
    });
};

interface CancelGroundTransfersBookingPayload extends DefaultProps {
    groundTransferService: Service;
    journeyType: Journey['type'];
}

const trackCancelGroundTransfersBookingEvent = (
    payload: CancelGroundTransfersBookingPayload,
): void => {
    const { journeyType, groundTransferService, ...rest } = payload;

    const groundTransferOption =
        journeyType === 'arrival' ? 'Arrival' : 'Return';

    trackEvent({
        name: 'User Cancelled Ground Transfers Booking',
        payload: {
            groundTransferOption,
            cartBalance: -(groundTransferService?.price ?? 0),
            removedServices: [groundTransferService],
            ...rest,
        },
    });
};

interface UpdateGroundTransfersContactInformationPayload extends DefaultProps {
    journeyType: Journey['type'];
}

const trackUpdateGroundTransfersContactInformationEvent = (
    payload: UpdateGroundTransfersContactInformationPayload,
): void => {
    const { journeyType, ...rest } = payload;

    const groundTransferOption =
        journeyType === 'arrival' ? 'Arrival' : 'Return';

    trackEvent({
        name: 'User Updated Ground Transfers Contact Information',
        payload: {
            groundTransferOption,
            ...rest,
        },
    });
};

export type { DefaultProps };

export {
    trackBookGroundTransferEvent,
    trackCancelGroundTransfersBookingEvent,
    trackCompleteContactStepEvent,
    trackCompleteTravelDetailStepEvent,
    trackCompleteVehicleStepEvent,
    trackPurchaseServiceEvent,
    trackStartGroundTransferBookingEvent,
    trackUpdateGroundTransfersContactInformationEvent,
};
