import { BaseBox, Stack } from '@trova-trip/trova-components';
import { getDateColorsById } from '../../../common/utils';
import ImportantDateCard from './ImportantDateCard';
import { models } from '@trova-trip/trova-models';
import useIsLargeScreen from '../../../../../../common/hooks/useIsLargeScreen';

interface ImportantDatesListProps {
    importantDates: models.hostHomeTab.TripImportantDate[];
}

const MOBILE_MAX_CARDS = 4;
const MAX_CARDS = 9;
const CARD_HEIGHT = 60;
const MOBILE_CARD_HEIGHT = 76;

const ImportantDatesList = (props: ImportantDatesListProps) => {
    const { importantDates } = props;
    const isMobile = !useIsLargeScreen({ includeTabletResolution: true });
    const dateColors = getDateColorsById(importantDates);

    const maxCards = isMobile ? MOBILE_MAX_CARDS : MAX_CARDS;

    const importantDatesCapped = importantDates.slice(0, maxCards);

    return (
        <BaseBox overflow='hidden' height='full' paddingX={{ base: 0, md: 4 }}>
            <Stack
                height='full'
                direction='column'
                spacing={{ base: 3, md: 8 }}
                wrap={{ base: 'nowrap', md: 'wrap' }}
                overflow='hidden'
            >
                {importantDatesCapped.map((date, idx) => {
                    return (
                        <BaseBox
                            key={`${date.tripId}-${idx}`}
                            height={{
                                base: `${MOBILE_CARD_HEIGHT}px`,
                                md: `${CARD_HEIGHT}px`,
                            }}
                            width='full'
                        >
                            <ImportantDateCard
                                importantDate={date}
                                color={dateColors[date.tripId]}
                            />
                        </BaseBox>
                    );
                })}
            </Stack>
        </BaseBox>
    );
};

export default ImportantDatesList;
