import { Text } from '@trova-trip/trova-components';
import { Dialog } from '@trova-trip/trova-components/build/next';
import Iframe from 'iframe-resizer-react';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import {
    acceptTravelerTerms,
    confirmMinorDisclaimer,
} from '../../../../state/trips';
import { userTrips } from '../../../../state/userTrips';
import useWindowHostName from '../../../../util/hooks/useWindowHostname';

const updateMinorDisclaimer = async (tripId) => {
    const terms = { terms: true };
    await confirmMinorDisclaimer(tripId, terms);
};

const IframeTravelerWaiver = ({ trip }) => {
    const { getRecord: refreshTrip } = userTrips.useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const host = useWindowHostName();
    const tripId = trip?.id;

    const onClickTravelerTerms = useCallback(
        async (terms) => {
            await acceptTravelerTerms(tripId, { terms });
            refreshTrip(tripId);
        },
        [tripId, refreshTrip],
    );

    const onClickMinorDisclaimer = useCallback(async () => {
        await updateMinorDisclaimer(tripId);
        setOpenDialog(false);
        refreshTrip(tripId);
    }, [tripId, refreshTrip, setOpenDialog]);

    useEffect(() => {
        const getMessage = async ({
            data: { termsAccepted, minorDisclaimer },
        }) => {
            if (termsAccepted) {
                onClickTravelerTerms(termsAccepted);
            } else if (minorDisclaimer) {
                setOpenDialog(true);
            }
        };

        window.addEventListener(`message`, getMessage);
        return () => {
            window.removeEventListener(`message`, getMessage);
        };
    }, [trip, tripId, setOpenDialog, onClickTravelerTerms]);

    return (
        <>
            <Iframe
                src={`//${host}/api/me/pages/traveler-terms?tripId=${tripId}`}
                frameBorder={0}
                style={{ width: `100%`, minWidth: `100%`, overflow: `hidden` }}
            />
            <Dialog
                isOpen={openDialog}
                showCloseButton={false}
                onClose={() => {
                    setOpenDialog(false);
                }}
            >
                <Dialog.Body>
                    <Text
                        fontWeight='medium'
                        textAlign='center'
                        marginY={4}
                        size='lg'
                    >
                        I confirm I am not 18+ years of age
                    </Text>
                </Dialog.Body>
                <Dialog.Footer
                    actions={[
                        {
                            children: 'Cancel',
                            variant: 'secondary',
                            onClick: () => setOpenDialog(false),
                        },
                        {
                            children: 'Confirm',
                            variant: 'primary',
                            onClick: onClickMinorDisclaimer,
                        },
                    ]}
                />
            </Dialog>
        </>
    );
};

IframeTravelerWaiver.propTypes = {
    trip: PropTypes.shape({
        id: PropTypes.string,
    }),
};

export default IframeTravelerWaiver;
