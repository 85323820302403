import {
    BaseBox,
    Field,
    Form,
    Grid,
    SectionCard,
    Stack,
    useToast,
} from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import { validationSchemas } from '@trova-trip/trova-models';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useSelector } from '../../../../../state/hooks';
import { userItinerary } from '../../../../../state/userItinerary';
import useCurrencies from '../../../../common/hooks/currencies/useCurrencies';
import { redirectToTab } from '../../utils/RedirectToTab';
import ItineraryInventoryCard from './ItineraryInventoryCard';
import { ItineraryInventoryItem } from './ItineraryInventoryForm/types';

const { requiredMessage } = validationSchemas.validationMessages;

enum FieldName {
    MinimumSpots = 'minimumSpots',
    MaximumSpots = 'maximumSpots',
    Currency = 'currency',
    TermsAndConditions = 'termsAndConditions',
}

const generateItineraryReviewPricingSchema = (
    minSpotsLimit: number | undefined,
) => {
    return yup.object({
        [FieldName.MinimumSpots]: yup
            .number()
            .required(requiredMessage('Minimum spots'))
            .test(
                'min-spots-limit',
                `Minimum spots cannot be lower than ${minSpotsLimit || 1}`,
                function (value) {
                    if (!value) return true;

                    if (!minSpotsLimit) return true;

                    return value >= minSpotsLimit;
                },
            ),
        [FieldName.MaximumSpots]: yup
            .number()
            .required(requiredMessage('Maximum spots')),
        [FieldName.Currency]: yup
            .object()
            .shape({
                label: yup.string(),
                value: yup.string().required(),
            })
            .required(requiredMessage('Currency'))
            .nullable(),
        [FieldName.TermsAndConditions]: yup
            .boolean()
            .required(requiredMessage('Terms & conditions'))
            .oneOf([true], 'Please accept the terms and conditions'),
    });
};

const getMinSpotsLimit = (inventoryItems: ItineraryInventoryItem[]): number => {
    const initial = {
        minValues: [] as number[],
    };

    const { minValues } = inventoryItems.reduce((acc, item) => {
        const numberOfTravelers = item.costSchedule.map(
            (cs) => cs.numberOfTravelers,
        );

        const minTravelers = Math.min(...numberOfTravelers);

        return {
            minValues: [...acc.minValues, minTravelers],
        };
    }, initial);

    const minSpotsLimit = Math.max(...minValues);

    return minSpotsLimit;
};

type CurrencyOption = {
    label: string;
    value: string;
};

interface ItineraryReviewPricingFormValues {
    [FieldName.MinimumSpots]: number;
    [FieldName.MaximumSpots]: number;
    [FieldName.Currency]?: CurrencyOption;
    [FieldName.TermsAndConditions]: boolean;
}

const NumberOfTravelersSection = (): JSX.Element => (
    <SectionCard size='lg' title='Number of Travelers' height='100%'>
        <Stack spacing={{ xs: 2, sm: 6 }} paddingTop={4} wrap='nowrap'>
            <BaseBox maxWidth='152px'>
                <Field
                    showButtons
                    isRequired
                    as='number'
                    name={FieldName.MinimumSpots}
                    label='Minimum'
                    size='md'
                    min={1}
                />
            </BaseBox>
            <BaseBox maxWidth='152px'>
                <Field
                    isRequired
                    showButtons
                    as='number'
                    name={FieldName.MaximumSpots}
                    label='Maximum'
                    size='md'
                    min={1}
                />
            </BaseBox>
        </Stack>
    </SectionCard>
);

const CurrencySection = (): JSX.Element => {
    const { currencies } = useCurrencies();
    const currenciesDropdownOptions = currencies?.map((currency) => ({
        value: currency.id,
        label: currency.name,
    }));

    return (
        <SectionCard size='lg' title='Currency' height='100%'>
            <Stack paddingTop={4} direction='column' align='stretch'>
                <Field
                    as='comboBox'
                    name={FieldName.Currency}
                    label='Currency'
                    size='md'
                    isRequired
                    options={currenciesDropdownOptions || []}
                />
            </Stack>
        </SectionCard>
    );
};

const ItineraryReviewPricingNewForm = (): JSX.Element => {
    const itinerary = useSelector((state) => state.userItinerary.current);
    const { updateRecord: updateItinerary } = userItinerary.useDispatch();
    const { getCurrency } = useCurrencies();
    const history = useHistory();
    const toast = useToast();

    const [isLoading, setIsLoading] = useState(false);

    const validationSchemaRef = useRef(
        generateItineraryReviewPricingSchema(undefined),
    );

    const { id, minimumSpots, maximumSpots, currency } = itinerary || {};

    const currencyObject = currency ? getCurrency(currency) : null;

    const currencyValue: CurrencyOption = {
        label: currencyObject?.name,
        value: currencyObject?.id,
    };

    const initialValues: ItineraryReviewPricingFormValues = {
        minimumSpots: minimumSpots || 0,
        maximumSpots: maximumSpots || 0,
        currency: currencyObject ? currencyValue : undefined,
        termsAndConditions: true,
    };

    const onSubmitForm = (values): void => {
        setIsLoading(true);
        const updatedItinerary = {
            minimumSpots: values.minimumSpots,
            maximumSpots: values.maximumSpots,
            currency: values.currency.value,
        };
        updateItinerary(id, updatedItinerary, {
            successCallback: () => {
                setIsLoading(false);
                redirectToTab(history, 'success');
            },

            errorCallback: (error) => {
                setIsLoading(false);
                toast({
                    title: 'Error',
                    description: error,
                    status: 'error',
                    isClosable: true,
                });
            },
        });
    };

    const handleInventoryItemLoad = (items: ItineraryInventoryItem[]): void => {
        const minSpotsLimit = getMinSpotsLimit(items);
        validationSchemaRef.current =
            generateItineraryReviewPricingSchema(minSpotsLimit);
    };

    return (
        <>
            <Form
                validateOnChange
                name='itinerary-review-pricing-form'
                initialValues={initialValues}
                onSubmit={onSubmitForm}
                validationSchema={validationSchemaRef.current}
            >
                {({ isSubmitting, isDirty, isValid, values }): JSX.Element => {
                    const currentCurrency = values[FieldName.Currency]?.value;
                    const currencyCode = getCurrency(currentCurrency)?.code;

                    return (
                        <Stack
                            direction='column'
                            spacing={6}
                            paddingBottom={20}
                        >
                            <Grid width='full' height='full'>
                                <Grid.Item
                                    columnSpan={{
                                        base: 12,
                                        md: 6,
                                    }}
                                >
                                    <NumberOfTravelersSection />
                                </Grid.Item>
                                <Grid.Item
                                    columnSpan={{
                                        base: 12,
                                        md: 6,
                                    }}
                                >
                                    <CurrencySection />
                                </Grid.Item>
                            </Grid>

                            <ItineraryInventoryCard
                                pricingValues={{
                                    minSpots: values[FieldName.MinimumSpots],
                                    maxSpots: values[FieldName.MaximumSpots],
                                    currencyCode: currencyCode,
                                }}
                                onInventoryItemLoad={handleInventoryItemLoad}
                            />

                            <Field
                                as='checkbox'
                                size='lg'
                                name={FieldName.TermsAndConditions}
                                label='Terms & Conditions'
                                helperText='I confirm all trip details, including inventory pricing for the dates provided, are accurate and in accordance with our agreed upon terms.'
                                flexWrap='nowrap'
                            />

                            <Button
                                variant='primary'
                                type='submit'
                                isFullWidth={{ base: true, sm: false }}
                                isDisabled={
                                    isSubmitting || !isDirty || !isValid
                                }
                                isLoading={isLoading}
                            >
                                Approve Itinerary
                            </Button>
                        </Stack>
                    );
                }}
            </Form>
        </>
    );
};

export default ItineraryReviewPricingNewForm;

export type { ItineraryReviewPricingFormValues };
