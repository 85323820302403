import { coreUtils } from '@trova-trip/trova-common';
import { DatePicker } from '@trova-trip/trova-components';
import { constants } from '@trova-trip/trova-models';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import subDays from 'date-fns/subDays';
import capitalize from 'lodash/capitalize';
import range from 'lodash/range';
import { MutableRefObject } from 'react';
import { getEnabledPackagesLevels } from '../../../../../common/helpers/itinerary';
import { PricingValues } from '../ItineraryInventoryCard';
import {
    AccommodationOccupancy,
    DefaultSSQuantity,
    FieldName,
    FieldPrefix,
    generateShortId,
    PackageLevel,
    ServiceTiming,
} from './constants';
import type {
    AccommodationOccupancy as AccommodationOccupancyType,
    AccommodationPrice,
    BaseItinerary,
    CostScheduleItem,
    CostScheduleViewItem,
    CreateItineraryInventoryItemInput,
    ItineraryInventoryFormValues,
    ItineraryInventoryItem,
    Option,
    PackageLevel as PackageLevelType,
    ServiceTiming as ServiceTimingType,
    UpdateItineraryInventoryItemInput,
} from './types';

const { addMonthsToDate } = coreUtils.dateUtils;
const { INVENTORY_AVAILABILITY_MONTHS_IN_ADVANCE } = constants.itinerary;

// #region Initial Values

/**
 * Get the initial values for the itinerary inventory form.
 *
 * @param itinerary
 * @param inventoryItem - The inventory item being edited or `undefined` for creation.
 * @returns { ItineraryInventoryFormValues }.
 */
const getInitialValues = (
    itinerary: BaseItinerary,
    pricingValues: PricingValues,
    inventoryItem?: ItineraryInventoryItem,
): ItineraryInventoryFormValues => {
    if (!inventoryItem) {
        return getDefaultInitialValues(itinerary, pricingValues);
    }
    return getInventoryItemInitialValues(itinerary, inventoryItem);
};

const getDefaultInitialValues = (
    itinerary: BaseItinerary,
    pricingValues: PricingValues,
): ItineraryInventoryFormValues => {
    const defaultPackageLevel = getDefaultPackageLevel(itinerary);

    const defaultPackageOption = getPackageLevelOption(defaultPackageLevel);

    const defaultAccommodationPrice = {
        [AccommodationOccupancy.SINGLE]: undefined,
        [AccommodationOccupancy.DOUBLE]: undefined,
    };

    const travelersQtyRange = getTravelersQtyRange(
        pricingValues.minSpots,
        pricingValues.maxSpots,
    );

    const defaultCostScheduleViewItems: CostScheduleViewItem[] =
        travelersQtyRange.map((qty) => ({
            id: generateShortId(),
            numberOfTravelers: qty,
        }));

    const defaultStartDate = getDefaultStartDate();

    return {
        [FieldPrefix]: {
            [FieldName.Itinerary]: itinerary.id,
            [FieldName.StartDate]: defaultStartDate as unknown as string,
            [FieldName.QtyAvailable]: 1,
            [FieldName.PackageLevel]: defaultPackageOption,
            [FieldName.SsPrice]: undefined,
            [FieldName.SsQtyAvailable]: DefaultSSQuantity,
            [FieldName.PreTransferPrice]: undefined,
            [FieldName.PostTransferPrice]: undefined,
            [FieldName.PreAccPrice]: { ...defaultAccommodationPrice },
            [FieldName.PostAccPrice]: { ...defaultAccommodationPrice },
            [FieldName.CostSchedule]: defaultCostScheduleViewItems,
        },
    };
};

const getInventoryItemInitialValues = (
    itinerary: BaseItinerary,
    item: ItineraryInventoryItem,
): ItineraryInventoryFormValues => {
    const defaultCostScheduleViewItems: CostScheduleViewItem[] =
        item.costSchedule
            .sort((a, b) => a.numberOfTravelers - b.numberOfTravelers)
            .map((item) => ({
                ...item,
                id: generateShortId(),
            }));

    return {
        [FieldPrefix]: {
            [FieldName.Id]: item._id,
            [FieldName.Itinerary]: itinerary.id,
            [FieldName.StartDate]: utcToZonedTime(
                item.startDate,
                'UTC',
            ) as unknown as string,
            [FieldName.QtyAvailable]: item.quantityAvailable,
            [FieldName.PackageLevel]: getPackageLevelOption(item.packageLevel),
            [FieldName.SsPrice]: item.singleSupplementPrice,
            [FieldName.SsQtyAvailable]: item.singleSupplementQuantityAvailable,
            [FieldName.PreTransferPrice]: item.preTransferPrice,
            [FieldName.PostTransferPrice]: item.postTransferPrice,
            [FieldName.PreAccPrice]: item.preAccommodationPrice,
            [FieldName.PostAccPrice]: item.postAccommodationPrice,
            [FieldName.CostSchedule]: defaultCostScheduleViewItems,
        },
    };
};

const getPackageLevelOption = (
    packageLevel: PackageLevelType,
): Option<PackageLevelType> => ({
    label: capitalize(packageLevel),
    value: packageLevel,
});

const getTravelersQtyRange = (min: number, max: number): number[] => {
    const rangeArray = range(min, max + 1);

    const result = rangeArray.filter((num) => {
        if (num === min || num === max) {
            return true;
        }

        if (min % 2 !== 0 && num > min && num % 2 === 0) {
            return true;
        }

        if (num > min && num < max && num % 2 === 0) {
            return true;
        }

        return false;
    });

    return result;
};

const getDefaultPackageLevel = (itinerary: BaseItinerary): PackageLevelType => {
    const priorities = [
        PackageLevel.STANDARD,
        PackageLevel.ECONOMY,
        PackageLevel.PREMIUM,
    ];

    const enabledPackagesLevels = getEnabledPackagesLevels(itinerary);

    if (enabledPackagesLevels.length === 1) {
        return enabledPackagesLevels[0];
    }

    return priorities.find((priority) =>
        enabledPackagesLevels.includes(priority),
    ) as PackageLevelType;
};

const getDefaultStartDate = (): Date => {
    const now = new Date();
    return addMonthsToDate(now, INVENTORY_AVAILABILITY_MONTHS_IN_ADVANCE);
};

// #endregion

// #region Transforms

const transformCostScheduleViewItemToCostScheduleItem = (
    items: CostScheduleViewItem[],
): CostScheduleItem[] => {
    return items.map((item) => ({
        numberOfTravelers: parseInt(
            item.numberOfTravelers as unknown as string,
        ),
        price: item.price as number,
    }));
};

const sanitizeEmptyNumberValue = (
    value: string | number | undefined,
): number | undefined => {
    if (!value) {
        return undefined;
    }
    return typeof value === 'number' ? value : parseFloat(value as string);
};

const sanitizeAccommodationPrice = (
    accommodation?: AccommodationPrice,
): AccommodationPrice | undefined => {
    if (!accommodation) {
        return undefined;
    }
    return {
        single: sanitizeEmptyNumberValue(accommodation.single),
        double: sanitizeEmptyNumberValue(accommodation.double),
    };
};

const sanitizeFormValues = (
    values: ItineraryInventoryFormValues,
): ItineraryInventoryFormValues => {
    const item = values.inventoryItem;

    const {
        preTransferPrice,
        postTransferPrice,
        preAccommodationPrice,
        postAccommodationPrice,
    } = item;

    const { id, ...sanitizedItem } = item;

    return {
        [FieldPrefix]: {
            ...sanitizedItem,
            preTransferPrice: sanitizeEmptyNumberValue(preTransferPrice),
            postTransferPrice: sanitizeEmptyNumberValue(postTransferPrice),
            preAccommodationPrice: sanitizeAccommodationPrice(
                preAccommodationPrice,
            ),
            postAccommodationPrice: sanitizeAccommodationPrice(
                postAccommodationPrice,
            ),
        },
    };
};

const transformFormValuesToCreatePayload = (
    values: ItineraryInventoryFormValues,
): CreateItineraryInventoryItemInput => {
    const sanitizedValues = sanitizeFormValues(values);
    const item = sanitizedValues.inventoryItem as Required<
        typeof values.inventoryItem
    >;
    const costSchedule = transformCostScheduleViewItemToCostScheduleItem(
        item.costSchedule,
    );
    return {
        ...item,
        startDate: item.startDate as unknown as Date,
        packageLevel: item.packageLevel.value,
        costSchedule,
    };
};

const transformFormValuesToUpdatePayload = (
    values: ItineraryInventoryFormValues,
): UpdateItineraryInventoryItemInput => {
    const transformedValues = transformFormValuesToCreatePayload(values);
    const { itinerary, startDate, ...rest } = transformedValues;
    return rest as UpdateItineraryInventoryItemInput;
};

// #endregion

const fieldNameWithPrefix = (name: FieldName | string): string => {
    return `${FieldPrefix}.${name}`;
};

const getAccommodationTimingPrefix = (timing: ServiceTimingType): string => {
    return timing === ServiceTiming.PRE_TRIP ? 'pre' : 'post';
};

const getAccommodationFieldName = (
    timing: ServiceTimingType,
    occupancy: AccommodationOccupancyType,
): string => {
    const prefix = getAccommodationTimingPrefix(timing);
    return `${prefix}AccommodationPrice.${occupancy}`;
};

const getCostScheduleFieldItemName = (
    index: number,
    field: 'numberOfTravelers' | 'price',
): string => {
    const prefix = `${FieldName.CostSchedule}[${index}]`;
    return `${prefix}.${field}`;
};

const scrollTo = (
    ref: MutableRefObject<HTMLElement | null>,
    position: 'bottom' | 'error',
) => {
    const element = ref.current;

    if (!element) {
        return;
    }

    const behavior: ScrollBehavior = 'smooth';

    if (position === 'bottom') {
        return element.scrollIntoView({ behavior, block: 'end' });
    }

    const target = element.querySelector('[data-invalid="true"]');
    target?.scrollIntoView({ behavior, block: 'center' });
};

const getDisabledStartDates = (): Array<[Date, Date]> => {
    const defaultStartDate = getDefaultStartDate();
    return [[DatePicker.ABSOLUTE_MINIMUM_DATE, subDays(defaultStartDate, 1)]];
};

export {
    fieldNameWithPrefix,
    getAccommodationFieldName,
    getAccommodationTimingPrefix,
    getCostScheduleFieldItemName,
    getDisabledStartDates,
    getInitialValues,
    scrollTo,
    transformFormValuesToCreatePayload,
    transformFormValuesToUpdatePayload,
};
