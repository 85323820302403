import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { theme } from '@trova-trip/trova-components';

export const StyledApplication = styled.div`
    display: flex;
    flex-grow: 1;
    background-color: ${theme.colors.neutral.white};
    font-family: ${theme.fonts.roboto};
    font-weight: ${theme.fontWeights.medium};
    text-align: left;
    div[data-testid='sidebar'] {
        z-index: 0;
    }

    div[data-content-simple] {
        width: 100%;
    }

    div[data-full-width='true'] {
    }

    @media (max-width: ${theme.breakpoints.lg}) {
        display: block;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        min-height: 100vh;
        height: 100%;
    }
`;

export const mainContentStyles = (isLargeScreen) => css`
    &&& {
        height: 100%;
        @media (min-width: ${theme.breakpoints.lg}) {
            width: ${isLargeScreen ? 'calc(100% - 274px)' : '100%'};
        }
        @media (max-width: ${theme.breakpoints.lg}) {
            padding-bottom: ${theme.space[24]};
        }
    }
`;

export const noSidebarStyles = () => css`
    &&& {
        height: 100%;
        width: 100%;
        border-top-left-radius: 0;
        @media (max-width: ${theme.breakpoints.lg}) {
            padding-bottom: ${theme.space[24]};
        }
    }
`;

export const floatingElement = () => css`
    box-shadow: 0 0.3rem 0.3rem #00000030;
`;
