import { Grid } from '@trova-trip/trova-components';
import {
    EmailCollectionCard,
    SurveyCollectionCard,
    EmailCollectionInfo,
} from './AudienceCollectionCards';
import { AudienceCollectionInfo } from './AudienceCollectionCards/AudienceCollectionCards.types';
import { ResponsesSummary, ResponsesSummaryProps } from './ResponsesSummary';
import TotalAudienceCard from './TotalAudienceCard/';

export interface AudienceSummaryProps {
    surveyCollections: AudienceCollectionInfo;
    emailCollections: EmailCollectionInfo;
    totalAudience: number;
    responsesSummary: ResponsesSummaryProps;
}

const AudienceSummary = ({
    surveyCollections,
    emailCollections,
    totalAudience,
    responsesSummary,
}: AudienceSummaryProps): JSX.Element => {
    return (
        <Grid gap={{ base: 3, md: 6 }} marginTop={{ base: 4, md: 6, lg: 0 }}>
            <Grid.Item columnSpan={{ base: 12, md: 6 }} rowSpan={2}>
                <TotalAudienceCard
                    totalAudience={totalAudience}
                    surveyResponses={surveyCollections?.total}
                />
            </Grid.Item>
            <Grid.Item columnSpan={{ base: 12, md: 6 }} rowSpan={1}>
                <SurveyCollectionCard {...surveyCollections} />
            </Grid.Item>
            <Grid.Item columnSpan={{ base: 12, md: 6 }} rowSpan={1}>
                <EmailCollectionCard {...emailCollections} />
            </Grid.Item>
            <Grid.Item columnSpan={12}>
                <ResponsesSummary {...responsesSummary} />
            </Grid.Item>
        </Grid>
    );
};

export default AudienceSummary;
