import { coreUtils } from '@trova-trip/trova-common';
import { Text, theme } from '@trova-trip/trova-components';
import { useMemo } from 'react';
import { getTripRelatedDates } from '../../../../../applications/common/helpers';

const { reformatUtcDateString } = coreUtils.dateUtils;

interface TripDatesDisplayProps {
    startDate: string | Date;
    lengthOfTrip?: number;
    fontSize?: keyof typeof theme.fontSizes;
}

const TripDatesDisplay = ({
    startDate,
    lengthOfTrip,
    fontSize,
}: TripDatesDisplayProps) => {
    const parsedStartDate =
        typeof startDate === 'string' ? new Date(startDate) : startDate;
    const { endTripDate } = getTripRelatedDates(parsedStartDate, lengthOfTrip);

    /**
     * These dates need to be parsed and formatted in UTC, as they're expected to
     * represent the trip dates in the local time of the trip.
     */
    const format = 'PP'; // MMM DD, yyyy
    const startDateFormatted = useMemo(
        () => reformatUtcDateString(parsedStartDate.toISOString(), format),
        [parsedStartDate],
    );
    const endDateFormatted = useMemo(
        () =>
            endTripDate
                ? reformatUtcDateString(endTripDate.toISOString(), format)
                : '',
        [endTripDate],
    );

    return (
        <Text fontSize={fontSize || 'base'} color='blueGray.500'>
            {`${startDateFormatted} - ${endDateFormatted}`}
        </Text>
    );
};

export default TripDatesDisplay;
