import { constants } from '@trova-trip/trova-models';

type TripStatus = constants.trips.TRIP_STATUS;
interface TripStatusItem {
    title: string;
    briefDescription: string;
    description: string;
    label: string;
    statuses: string[];
}

const { TRIP_STATUS } = constants.trips;

export const MARKETING_ITEM = (): TripStatusItem => ({
    title: 'Marketing',
    briefDescription:
        'If your trip page is in marketing status travelers can sign up for your email list.',
    description:
        'Your trip page is currently collecting emails of potential travelers. Share your trip page with your audience to continue growing your email list.',
    label: 'Collecting Emails',
    statuses: [TRIP_STATUS.HOST_APPROVED],
});

export const LIVE_ITEM = (): TripStatusItem => ({
    title: 'Live',
    briefDescription:
        'When your trip page goes live, travelers are able to book spots on your trip.',
    description:
        'Your trip page is now live and accepting bookings! Share it with your audience through all your favorite channels to keep the momentum going.',
    label: 'Booking Spots',
    statuses: [TRIP_STATUS.LIVE, TRIP_STATUS.READY_TO_CONFIRM],
});

export const CONFIRMED_ITEM = (): TripStatusItem => ({
    title: 'Confirmed',
    briefDescription:
        'Your trip will be confirmed when the minimum number of travelers has been met.',
    description:
        'It’s on! You’ve booked the minimum number of travelers to confirm your trip. Keep promoting your trip to sell out and increase your earnings.',
    label: 'Booking Spots',
    statuses: [TRIP_STATUS.CONFIRMED, TRIP_STATUS.EARLY_CONFIRMED],
});

export const CLOSED_ITEM = (
    closeDate: string,
    currentStatus: TripStatus,
): TripStatusItem => ({
    title: 'Closed',
    briefDescription:
        currentStatus === TRIP_STATUS.CONFIRMED
            ? `Your trip will close for new bookings on ${closeDate}.`
            : `Unless confirmed, your trip will be cancelled on ${closeDate}.`,
    description:
        'This trip is closed. You can return here anytime to review your trip page and promotions.',
    label: '',
    statuses: [TRIP_STATUS.CLOSED],
});
