import { constants } from '@trova-trip/trova-models/';
import AccommodationCardForm from './ServiceCardForms/AccommodationCardForm';
import ActivityCardForm from './ServiceCardForms/ActivityCardForm';
import AirportTransferCardForm from './ServiceCardForms/AirportTransferCardForm';
import InfoCardForm from './ServiceCardForms/InfoCardForm';
import MealCardForm from './ServiceCardForms/MealCardForm';
import TransferCardForm from './ServiceCardForms/TransferCardForm';
import WorkshopSpaceCardForm from './ServiceCardForms/WorkshopSpaceCardForm';

const { ServiceType: Type } = constants.services;

type ServiceFormPermissions = {
    canSave: boolean;
    canRemove: boolean;
};

type RenderServiceCardFormType = (
    serviceType: constants.services.ServiceType,
) => (
    serviceName: string,
    closeForm: () => void,

    // All the CardForm components has the same type for this function except
    // the AirportTransferCardForm
    updateItinerary: (...args: any) => void,

    // This should be models.services.Service but the AccommodationCardForm
    // has the specific type models.services.Accommodation and they are incompatible.
    serviceToEdit: any,

    productName: 'itinerary' | 'trip',
    errorText: string,
    dayIndex: number,
    totalDays: number,
    currencyCode: string,
    disabledFields: string[],
    permissions?: ServiceFormPermissions,
) => JSX.Element | undefined;

const fieldNamesByServiceType = {
    accommodation: {
        name: 'name',
        accommodationType: 'accommodationType',
        price: 'price',
        occupancy: 'occupancy',
        starRating: 'starRating',
        numberOfNights: 'numberOfNights',
        location: 'location',
        addressLocation: 'addressLocation',
        orSimilar: 'orSimilar',
        description: 'description',
        checkInDay: 'checkInDay',
        images: 'images',
        saveToLibrary: 'saveToLibrary',
    },
    info: {
        title: 'title',
        description: 'description',
        images: 'images',
        saveToLibrary: 'saveToLibrary',
        type: 'type',
    },
    meal: {
        mealType: 'mealType',
        options: 'options',
        description: 'description',
        images: 'images',
        saveToLibrary: 'saveToLibrary',
        type: 'type',
    },
    activity: {
        name: 'name',
        location: 'location',
        description: 'description',
        startTime: 'startTime',
        length: 'length',
        includedActivity: 'includedActivity',
        price: 'price',
        cutoffDate: 'cutoffDate',
        cutoffLeadTime: 'cutoffLeadTime',
        minPax: 'minPax',
        bookable: 'bookable',
        images: 'images',
        saveToLibrary: 'saveToLibrary',
        type: 'type',
    },
    workshop: {
        name: 'name',
        hoursAvailable: 'hoursAvailable',
        hoursRequested: 'hoursRequested',
        pricePerHour: 'pricePerHour',
        location: 'location',
        timeAvailableStart: 'timeAvailableStart',
        description: 'description',
        images: 'images',
        saveToLibrary: 'saveToLibrary',
    },
    transfer: {
        name: 'name',
        fromCity: 'fromCity',
        toCity: 'toCity',
        price: 'price',
        vehicleType: 'vehicleType',
        length: 'length',
        description: 'description',
        airportTransfer: 'airportTransfer',
        numberOfTransfers: 'numberOfTransfers',
        flightWindowStart: 'flightWindow.start',
        flightWindowEnd: 'flightWindow.end',
        images: 'images',
        saveToLibrary: 'saveToLibrary',
    },
    airportTransfer: {
        name: 'name',
        airport: 'airport',
        price: 'price',
        description: 'description',
        saveToLibrary: 'saveToLibrary',
    },
} as const;

const renderServiceCardForm: RenderServiceCardFormType =
    (serviceType) =>
    (
        serviceName,
        closeForm,
        updateItinerary,
        serviceToEdit,
        productName,
        errorText,
        dayIndex,
        totalDays,
        currencyCode,
        disabledFields,
        permissions,
    ) => {
        const props = {
            serviceName,
            closeForm,
            updateItinerary,
            serviceToEdit,
            productName,
            errorText,
            dayIndex,
            totalDays,
            currencyCode,
            disabledFields,
            permissions,
        };

        switch (serviceType) {
            case Type.ACCOMMODATION:
                return <AccommodationCardForm {...props} />;
            case Type.INFO:
                return <InfoCardForm {...props} />;
            case Type.MEAL:
                return <MealCardForm {...props} />;
            case Type.ACTIVITY:
                return <ActivityCardForm {...props} />;
            case Type.WORKSHOP_SPACE:
                return <WorkshopSpaceCardForm {...props} />;
            case Type.TRANSFER:
                return <TransferCardForm {...props} />;
            case Type.AIRPORT_TRANSFER:
                return <AirportTransferCardForm {...props} />;
        }
    };

const ServiceFormRegistry = {
    [Type.ACCOMMODATION]: renderServiceCardForm(Type.ACCOMMODATION),
    [Type.INFO]: renderServiceCardForm(Type.INFO),
    [Type.MEAL]: renderServiceCardForm(Type.MEAL),
    [Type.ACTIVITY]: renderServiceCardForm(Type.ACTIVITY),
    [Type.WORKSHOP_SPACE]: renderServiceCardForm(Type.WORKSHOP_SPACE),
    [Type.TRANSFER]: renderServiceCardForm(Type.TRANSFER),
    [Type.AIRPORT_TRANSFER]: renderServiceCardForm(Type.AIRPORT_TRANSFER),
};

export { ServiceFormRegistry, fieldNamesByServiceType };
export type { ServiceFormPermissions };
