import { css } from '@emotion/css';
import { theme } from '@trova-trip/trova-components';

export const PrintableServiceCardStyle = css`
    @media print {
        border: 1px solid ${theme.colors.neutral[95]};
        border-radius: ${theme.radii.lg};
        padding: ${theme.space[3]};
    }
`;

export const PrintableServiceTitleStyle = css`
    @media print {
        color: ${theme.colors.neutral[50]} !important;
    }
`;

export const PrinterFriendlyText = css`
    @media print {
        &[data-screen-text] {
            display: none !important;
        }
    }
    @media screen {
        &[data-print-text] {
            display: none !important;
        }
    }
`;

export const HideFromPrint = css`
    @media print {
        display: none !important;
    }
`;
