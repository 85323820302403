import * as yup from 'yup';

import { CompanionFormMap } from './CompanionsForm.types';

function requiredMessage(fieldName: string): string {
    return `${fieldName} is required`;
}

function emailMessage(): string {
    return `Invalid email format`;
}

interface CompanionSchemaFields {
    firstName: string;
    lastName: string;
    email: string;
    dateOfBirth: string;
}

const buildSchemaFromFields = (
    formRows: CompanionFormMap,
    rule: yup.SchemaOf<CompanionSchemaFields>,
) =>
    Object.keys(formRows).reduce(
        (newMap, key) => ({ ...newMap, [key]: rule }),
        {},
    );

export const CompanionFormSchema = (formRows: CompanionFormMap) =>
    yup.object(
        buildSchemaFromFields(
            formRows,
            yup.object({
                firstName: yup.string().required(requiredMessage(`First Name`)),
                lastName: yup.string().required(requiredMessage(`Last Name`)),
                email: yup
                    .string()
                    .required(requiredMessage(`Email`))
                    .email(emailMessage()),
                dateOfBirth: yup
                    .string()
                    .required(requiredMessage(`Date Of Birth`)),
            }),
        ),
    );
