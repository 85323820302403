import { Grid } from '@material-ui/core';
import { UseFormSaverReturn } from '@trova-trip/trova-components';
import { UserGroup } from '../../../../../../types';
import ContentForUserGroup from '../../../../../components/ContentForUserGroup';
import GenderDropdown from '../../../../../components/Dropdown/GenderDropdown';
import ProfileFormTextField from './ProfileFormTextField';
import { profileFieldVisibility } from './utils';
import { ProfileFieldsNames } from '../../../types/ProfileForm.types';

interface ProfileFormFieldsProps {
    formSaver: UseFormSaverReturn;
}

const ProfileFormFields = ({
    formSaver,
}: ProfileFormFieldsProps): JSX.Element => {
    return (
        <Grid container spacing={32}>
            <ContentForUserGroup
                matchingUserGroup={
                    profileFieldVisibility[ProfileFieldsNames.DISPLAY_NAME]
                }
                render={(): JSX.Element => (
                    <Grid container item spacing={32}>
                        <Grid item xs={12} md={6}>
                            <ProfileFormTextField
                                label='Display Name'
                                name={ProfileFieldsNames.DISPLAY_NAME}
                                value={
                                    formSaver.formValues.get
                                        .displayName as string
                                }
                                error={formSaver.formErrors?.displayName}
                                onChange={formSaver.formValues.set.displayName}
                            />
                        </Grid>
                    </Grid>
                )}
            />
            <Grid item xs={12} md={6}>
                <ProfileFormTextField
                    label='First Name'
                    name={ProfileFieldsNames.FIRST_NAME}
                    required
                    value={formSaver.formValues.get.firstName as string}
                    error={formSaver.formErrors?.firstName}
                    onChange={formSaver.formValues.set.firstName}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <ProfileFormTextField
                    label='Last Name'
                    name={ProfileFieldsNames.LAST_NAME}
                    required
                    value={formSaver.formValues.get.lastName as string}
                    error={formSaver.formErrors?.lastName}
                    onChange={formSaver.formValues.set.lastName}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <ProfileFormTextField
                    disabled
                    label='Email Address'
                    name={ProfileFieldsNames.EMAIL}
                    required
                    value={formSaver.formValues.get.email as string}
                    error={formSaver.formErrors?.email}
                    onChange={formSaver.formValues.set.email}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <ProfileFormTextField
                    label='Phone Number'
                    name={ProfileFieldsNames.PHONE_NUMBER}
                    value={formSaver.formValues.get.phoneNumber as string}
                    error={formSaver.formErrors?.phoneNumber}
                    onChange={formSaver.formValues.set.phoneNumber}
                />
            </Grid>
            <ContentForUserGroup
                matchingUserGroup={
                    profileFieldVisibility[ProfileFieldsNames.WEBSITE_URL]
                }
                render={(): JSX.Element => (
                    <Grid item xs={12} md={6}>
                        <ProfileFormTextField
                            label='Website URL'
                            name={ProfileFieldsNames.WEBSITE_URL}
                            value={
                                formSaver.formValues.get.websiteUrl as string
                            }
                            error={formSaver.formErrors?.websiteUrl}
                            onChange={formSaver.formValues.set.websiteUrl}
                        />
                    </Grid>
                )}
            />
            <ContentForUserGroup
                matchingUserGroup={
                    profileFieldVisibility[ProfileFieldsNames.INSTAGRAM_HANDLE]
                }
                render={(): JSX.Element => (
                    <Grid item xs={12} md={6}>
                        <ProfileFormTextField
                            label='Instagram Handle'
                            name={ProfileFieldsNames.INSTAGRAM_HANDLE}
                            placeholder='@name'
                            value={
                                formSaver.formValues.get
                                    .instagramHandle as string
                            }
                            error={formSaver.formErrors?.instagramHandle}
                            onChange={formSaver.formValues.set.instagramHandle}
                        />
                    </Grid>
                )}
            />
            <Grid item xs={12} md={6}>
                <GenderDropdown
                    name={ProfileFieldsNames.GENDER}
                    value={formSaver.formValues.get.gender as string}
                    error={formSaver.formErrors?.gender}
                    onChange={(_, __, value) => {
                        formSaver.formValues.set.gender(value);
                    }}
                />
            </Grid>
            <ContentForUserGroup
                matchingUserGroup={
                    profileFieldVisibility[ProfileFieldsNames.BIO]
                }
                render={(): JSX.Element => (
                    <Grid item xs={12}>
                        <ProfileFormTextField
                            type='textarea'
                            label='Short Bio'
                            name={ProfileFieldsNames.BIO}
                            value={formSaver.formValues.get.bio as string}
                            error={formSaver.formErrors?.bio}
                            onChange={formSaver.formValues.set.bio}
                        />
                    </Grid>
                )}
            />
            <ContentForUserGroup
                matchingUserGroup={UserGroup.HOST}
                render={(): JSX.Element => (
                    <Grid item xs={12}>
                        <ProfileFormTextField
                            type='textarea'
                            label='Email Signature'
                            name={ProfileFieldsNames.EMAIL_SIGNATURE}
                            value={
                                formSaver.formValues.get
                                    .emailSignature as string
                            }
                            error={formSaver.formErrors?.emailSignature}
                            onChange={formSaver.formValues.set.emailSignature}
                        />
                    </Grid>
                )}
            />
        </Grid>
    );
};

export default ProfileFormFields;
