import { Dropdown, OptionProps } from '@trova-trip/trova-components';
import { useMemo } from 'react';
import { noop } from 'lodash';

interface DaysDropdownProps {
    numberOfDays: number;
    onDaySelect: (day: number) => void;
    show: boolean;
}

const DaysDropdown: React.FC<DaysDropdownProps> = ({
    numberOfDays,
    onDaySelect,
    show,
}) => {
    const onChange = (event, name, value) => {
        onDaySelect(Number(value));
    };
    const dayOptions: OptionProps[] = useMemo(
        () =>
            Array(numberOfDays)
                .fill(undefined)
                .map((_, index) => {
                    const option: OptionProps = {
                        value: index.toString(),
                        children: `Day ${index + 1}`,
                    };
                    return option;
                }),
        [numberOfDays],
    );
    return show ? (
        <Dropdown
            onChange={onChange}
            placeholder='Select day to insert service'
            onSearch={noop}
            name={`daysDropdown`}
        >
            {dayOptions}
        </Dropdown>
    ) : null;
};

export default DaysDropdown;
