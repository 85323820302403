export enum PhylloErrorKeys {
    FAILED_USER_DATA = 'failed-to-fetch-phyllo-user-data',
    FAILED_INITIALIZE_SDK = 'failed-to-initialize-phyllo-sdk',
    FAILED_ACCOUNT_CONNECT = 'failed-to-connect-account',
}

export enum PhylloConnectionFailureReason {
    CONNECTION_ABANDONED = 'CONNECTION_ABANDONED',
    INCORRECT_ACCOUNT_TYPE = 'INCORRECT_ACCOUNT_TYPE',
    INADEQUATE_PERMISSIONS = 'INADEQUATE_PERMISSIONS',
}

export enum PhylloExitReason {
    BACK_PRESSED = 'BACK_PRESSED',
    DONE_CLICKED = 'DONE_CLICKED',
    EXIT_FROM_PLATFORM_SELECTION = 'EXIT_FROM_PLATFORM_SELECTION',
    EXIT_FROM_CONNECTION_FAILED = 'EXIT_FROM_CONNECTION_FAILED',
}
