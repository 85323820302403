import {
    BaseBox,
    Field,
    Grid,
    Heading,
    IconButton,
    List,
    Stack,
    Text,
} from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { generateShortId } from '../constants';
import { fieldNameWithPrefix, getCostScheduleFieldItemName } from '../helpers';
import { CostScheduleViewItem } from '../types';
import CurrencyField from './CurrencyField';
import HelpTooltip from './HelpTooltip';

interface CostScheduleListItemProps {
    index: number;
    currencyCode: string;
    isRemoveEnabled: boolean;
    onRemove: () => void;
    isDisabled?: boolean;
}

const CostScheduleListItem = forwardRef<
    HTMLDivElement,
    CostScheduleListItemProps
>((props, ref) => {
    const { index, currencyCode, onRemove, isRemoveEnabled, isDisabled } =
        props;

    const numberOfTravelersFieldName = getCostScheduleFieldItemName(
        index,
        'numberOfTravelers',
    );

    const priceFieldName = getCostScheduleFieldItemName(index, 'price');

    return (
        <List.Item>
            <Stack
                justify='stretch'
                align='flex-start'
                width='full'
                wrap='nowrap'
                marginBottom={4}
                spacing={4}
            >
                <BaseBox flexGrow={2} ref={ref}>
                    <Field
                        as='number'
                        name={fieldNameWithPrefix(numberOfTravelersFieldName)}
                        label='Travelers'
                        min={0}
                        isDisabled={isDisabled}
                    />
                </BaseBox>
                <BaseBox flexGrow={1}>
                    <CurrencyField
                        name={fieldNameWithPrefix(priceFieldName)}
                        label='Price'
                        currencyCode={currencyCode}
                        isDisabled={isDisabled}
                    />
                </BaseBox>
                {isRemoveEnabled ? (
                    <BaseBox>
                        <IconButton
                            size='md'
                            aria-label='Remove item'
                            variant='outlined'
                            icon='trash'
                            marginTop={6}
                            onClick={onRemove}
                            isDisabled={isDisabled}
                        />
                    </BaseBox>
                ) : null}
            </Stack>
        </List.Item>
    );
});

interface CostScheduleFieldProps {
    initialItems: CostScheduleViewItem[];
    currencyCode: string;
    onAddItem?: (items: CostScheduleViewItem[]) => void;
    onRemoveItem?: (items: CostScheduleViewItem[]) => void;
    isDisabled?: boolean;
}

const CostScheduleField = ({
    initialItems,
    currencyCode,
    onAddItem,
    onRemoveItem,
    isDisabled,
}: CostScheduleFieldProps) => {
    const [items, setItems] = useState(initialItems);
    const totalOfItems = items.length;

    const prevTotalOfItemsRef = useRef(totalOfItems);
    const numberOfTravelersContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => setItems(initialItems), [initialItems]);

    useEffect(() => {
        const prevTotalOfItems = prevTotalOfItemsRef.current;

        const isNewItemAdded = totalOfItems > prevTotalOfItems;
        const isItemRemoved = totalOfItems < prevTotalOfItems;

        if (isNewItemAdded) {
            const containerElement = numberOfTravelersContainerRef.current;
            const input = containerElement?.querySelector('input');
            input?.focus();
            onAddItem?.(items);
        }

        if (isItemRemoved) {
            onRemoveItem?.(items);
        }

        prevTotalOfItemsRef.current = totalOfItems;
    }, [totalOfItems]);

    const addItem = () => {
        setItems((prevItems) => [
            ...prevItems,
            {
                id: generateShortId(),
                numberOfTravelers: undefined,
                price: undefined,
            },
        ]);
    };

    const removeItem = (id) => {
        setItems((prevItems) => prevItems.filter((item) => item.id !== id));
    };

    return (
        <Grid.Item columnSpan={12}>
            <Grid.Item columnSpan={12} marginBottom={8}>
                <Stack align='center' paddingTop={4}>
                    <Heading
                        as='h5'
                        fontWeight='medium'
                        fontSize={{ xs: 'base', md: 'lg' }}
                    >
                        Price Schedule
                    </Heading>
                    <HelpTooltip text='Set pricing for each traveler threshold.' />
                </Stack>
                <Text as='span' size='sm' color='blueGray.500'>
                    Minimum PAX is set at the itinerary level.
                </Text>
            </Grid.Item>
            <Grid.Item columnSpan={12}>
                <List listStyleType='none'>
                    {items.map(({ id }) => (
                        <CostScheduleListItem
                            key={id}
                            ref={numberOfTravelersContainerRef}
                            currencyCode={currencyCode}
                            index={items.findIndex((item) => item.id === id)}
                            onRemove={() => removeItem(id)}
                            isRemoveEnabled={items.length > 2}
                            isDisabled={isDisabled}
                        />
                    ))}
                </List>
                <BaseBox textAlign='center' marginTop={8}>
                    <Button
                        id='cost-schedule-add-cta'
                        onClick={addItem}
                        variant='secondary'
                        size='sm'
                        rightIcon='plus'
                        isDisabled={isDisabled}
                    >
                        Add Tier
                    </Button>
                </BaseBox>
            </Grid.Item>
        </Grid.Item>
    );
};

export default CostScheduleField;
