import { useState } from 'react';
import {
    IconButtonBox,
    ComponentWidth,
    IconName,
    ButtonType,
} from '@trova-trip/trova-components';
import Snackbar from '../../../../components/Snackbar/Snackbar';
import useClipboard from '../../../common/hooks/useClipboard';

interface CopyLinkProps {
    label: string;
    size: ComponentWidth;
    buttonType?: ButtonType;
    buttonIconColor?: string;
    disabled?: boolean;
    onClick?: () => void;
}

const CopyLink = (props: CopyLinkProps): JSX.Element => {
    const { label, buttonType, buttonIconColor, disabled, onClick } = props;
    const { copyTextToClipboard } = useClipboard();

    const [snackBar, setSnackBar] = useState({
        message: '',
        color: 'info',
        show: false,
    });

    const handleClick = async (): Promise<void> => {
        try {
            await copyTextToClipboard(label);
            setSnackBar({
                message: 'Link copied successfully',
                color: 'success',
                show: true,
            });
        } catch (error) {
            setSnackBar({ message: error, color: 'danger', show: true });
        }
        if (onClick) onClick();
    };

    return (
        <>
            <IconButtonBox
                {...props}
                iconButton={{
                    icon: IconName.Copy,
                    onClick: handleClick,
                    buttonType,
                    color: buttonIconColor,
                    disabled,
                }}
            />
            <Snackbar
                place='tr'
                color={snackBar.color}
                message={snackBar.message}
                open={snackBar.show}
                autoHideDuration={4000}
                onClose={(): void => {
                    setSnackBar({ message: '', color: 'info', show: false });
                }}
            />
        </>
    );
};

export default CopyLink;
