import { Stack } from '@trova-trip/trova-components';
import { BaseUser, Booking, Trip } from '../../types';
import AccommodationsSection from '../AccommodationsSection';
import ActivitiesSection from '../ActivitiesSection';
import PrePostAccommodationsSection from '../PrePostAccommodationsSection';
import PrePostTransfersSection from '../PrePostTransfersSection';

interface AddOnsListProps {
    booking: Booking;
    trip: Trip;
}

const AddOnsList = ({ booking, trip }: AddOnsListProps): JSX.Element => {
    const { customer, additionalParticipants } = booking;
    const { startDate, servicesByDay } = trip;

    const travelers = [
        customer as BaseUser,
        ...(additionalParticipants as BaseUser[]),
    ];

    return (
        <Stack direction='column' spacing={6} marginTop={6}>
            <ActivitiesSection
                startDate={startDate}
                servicesByDay={servicesByDay}
                travelers={travelers}
            />
            <AccommodationsSection trip={trip} />
            <PrePostAccommodationsSection trip={trip} />
            <PrePostTransfersSection trip={trip} />
        </Stack>
    );
};

export default AddOnsList;
