import React from 'react';
import PropTypes from 'prop-types';
import Application from '../../navigation/Application';
import Itineraries from './products/Itineraries';
import Trips from './products/Trips';
import Account from 'applications/common/products/account';

const Operator = ({ profile }) => (
    <Application
        profile={profile}
        path={`/operator`}
        sidebarProps={{
            buttonPath: `/app/operator/itineraries/new/review-details`,
            buttonText: `Add new itinerary`,
            type: 'operator',
        }}
    >
        <Trips />
        <Itineraries />
        <Account />
    </Application>
);

Operator.propTypes = {
    profile: PropTypes.shape(),
};

export default Operator;
