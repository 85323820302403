import FetchState from './utils/FetchState';
import { get, post } from './utils/api';

const travelers = FetchState.create(`api/admin/travelers`);
const travelersMetadata = FetchState.create(`api/metadata/travelers`);
const userTravelers = FetchState.create(`api/me/travelers`);
const travelersReducer = travelers.getReducer();
const travelersMetadataReducer = travelersMetadata.getReducer();
const userTravelersReducer = userTravelers.getReducer();

const findRelatedTraveler = (travelerId, relatedTravelerId) =>
    get(
        `api/me/travelers/${travelerId}/related-travelers/${relatedTravelerId}`
    );
const updateRelatedTraveler = (travelerId, relatedTravelerId, data) =>
    post(
        `api/me/travelers/${travelerId}/related-travelers/${relatedTravelerId}`,
        {
            travelerId,
            relatedTravelerId,
            ...data,
        }
    );

export {
    travelers,
    travelersMetadata,
    userTravelers,
    travelersReducer,
    travelersMetadataReducer,
    userTravelersReducer,
    findRelatedTraveler,
    updateRelatedTraveler,
};



