import { models } from '@trova-trip/trova-models';
import FetchState from './utils/FetchState';

export type TripRequestStoreSelector = {
    userTripRequests: { current: models.tripRequest.TripRequest };
};

const tripRequests = FetchState.create(`api/admin/trip-requests`);
const tripRequestsMetadata = FetchState.create(`api/metadata/trip-requests`);

const tripRequestsMetadataReducer = tripRequestsMetadata.getReducer();
const tripRequestsReducer = tripRequests.getReducer();

export {
    tripRequests,
    tripRequestsMetadata,
    tripRequestsReducer,
    tripRequestsMetadataReducer,
};
