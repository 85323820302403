import styled from '@emotion/styled';
import { NumberInput, theme } from '@trova-trip/trova-components';

export const StyledStickyContentContainer = styled.div`
    && {
        margin-top: -${theme.space[12]};

        h3::after {
            content: ' *';
            position: inherit;
            color: ${theme.colors.red.trova};
        }

        @media (min-width: ${theme.breakpoints.md}) {
            margin-top: 0;

            h3 {
                margin: 0;
            }

            p {
                margin: 1rem 0px 0.2rem;
                line-height: 2rem;
            }
        }
    }
`;

export const StyledDescription = styled.span`
    display: none;

    @media (min-width: ${theme.breakpoints.md}) {
        display: block;
        font-size: 12px;
        line-height: 14px;
        font-family: ${theme.fonts.roboto};
        color: ${theme.colors.neutral[80]};

        &::before {
            content: '* ';
            position: inherit;
            color: ${theme.colors.red.trova};
        }
    }
`;

export const StyledNumberInput = styled(NumberInput)`
    @media (max-width: ${theme.breakpoints.md}) {
        border: 1px solid ${theme.colors.blueGray[200]};
        border-radius: 10px;
        overflow: hidden;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
        width: 9rem;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        justify-content: center;
        align-items: center;
        min-height: 50px;
    }
`;
