import { BaseBox, Stack } from '@trova-trip/trova-components';
import useIsLargeScreen from 'applications/common/hooks/useIsLargeScreen';
import NavigationContext from 'navigation/NavigationContext';
import { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { BottomNavBarLink } from './BottomNavBarLink';

const BottomNavBar = (): JSX.Element | null => {
    const history = useHistory();
    const { products } = useContext(NavigationContext);
    const isLargeScreen = useIsLargeScreen();

    const links = useMemo(
        () =>
            products.map((product) => {
                const { path, icon, label } = product;
                const currentPath = history.location.pathname;
                const isActive = currentPath
                    .toLowerCase()
                    .includes(path.toLowerCase());
                return (
                    <BottomNavBarLink
                        isActive={isActive}
                        icon={icon}
                        label={label}
                        path={path}
                        key={path}
                    />
                );
            }),
        [products, history.location.pathname],
    );

    if (isLargeScreen) return null;

    return (
        <BaseBox
            position='fixed'
            width='100%'
            bottom='0'
            backgroundColor='neutral.white'
            zIndex='1300'
            borderTop='1px'
            borderColor='blueGray.200'
            borderTopRadius='xl'
            boxShadow='5'
            paddingTop='.375rem'
            paddingBottom='.125rem'
            paddingX={2}
        >
            <Stack spacing={4} justify='center' align='center'>
                {links}
            </Stack>
        </BaseBox>
    );
};

export default BottomNavBar;
