import { userGroundTransfers } from '../../../state/userGroundTransfers';

interface UseGroundTransfersActionsReturn {
    /**
     * Fetches ground transfers based on provided IDs and sets them on the global store.
     *
     * @param ids - An array of string IDs representing the ground transfers to fetch.
     * @return void
     */
    loadGroundTransfers: (ids: string[]) => void;

    /**
     * Clears the current list of ground transfers from the global store.
     */
    clearGroundTransfers: () => void;
}

/**
 * Hook to get functions to interact with the `userGroundTransfers` global store slice.
 *
 * @returns UseGroundTransfersActionsReturn - An object containing functions to fetch and clear ground transfers.
 */
const useGroundTransfersActions = (): UseGroundTransfersActionsReturn => {
    const { listRecords, clearCurrentList: clearGroundTransfers } =
        userGroundTransfers.useDispatch();

    const loadGroundTransfers = (ids: string[]): void => {
        listRecords({ ids: ids.join(',') });
    };

    return { loadGroundTransfers, clearGroundTransfers };
};

export default useGroundTransfersActions;
