import { Spinner, Stack } from '@trova-trip/trova-components';
import { MainContent } from '@trova-trip/trova-components/build/next';
import { Suspense, lazy, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { isTransfersEnabled } from '../../../../config/constants';
import { NavigationCategory } from '../../../../interfaces/Navigation.types';
import { applicationRootPath as appRootPath } from '../../../../navigation/NavigationContext';
import Product from '../../../../navigation/Product';
import Tab from '../../../../navigation/Tab';
import { useSelector } from '../../../../state/hooks';
import { userTrips } from '../../../../state/userTrips';
import { isTransferProductReady } from '../../../common/helpers';
import useTripIdUrlParam from '../../hooks/trips/useTripIdUrlParam';
import { TabLayout } from './components';
import { paths } from './config';
import { useTransferPath } from './hooks';
import { useTransferFlowAnalytics } from '../transfer/hooks';

const TravelDetail = lazy(() => import('./tabs/TravelDetail'));
const Vehicle = lazy(() => import('./tabs/Vehicle'));
const Contact = lazy(() => import('./tabs/Contact'));
const Payment = lazy(() => import('./tabs/Payment'));
const Success = lazy(() => import('./tabs/Success'));

interface TransferProps {
    loadNavigationContent?: () => NavigationCategory[];
}

const tabs = [
    {
        label: 'Travel Detail',
        path: paths.tab.travel_detail,
        component: TravelDetail,
    },
    {
        label: 'Vehicle',
        path: paths.tab.vehicle,
        component: Vehicle,
    },
    {
        label: 'Contact',
        path: paths.tab.contact,
        component: Contact,
    },
    {
        label: 'Payment',
        path: paths.tab.payment,
        component: Payment,
    },
    {
        label: 'Success',
        path: paths.tab.success,
        component: Success,
    },
];

const LoadingBox = () => (
    <Stack justify='center' paddingY={10}>
        <Spinner color='teal.trova' />
    </Stack>
);

const TabList = () => {
    useTransferFlowAnalytics();

    return (
        <>
            {tabs.map(({ label, path, component: Component }) => (
                <Tab key={path} label={label} path={path} hideNavigation>
                    <Suspense fallback={<LoadingBox />}>
                        <TabLayout>
                            <Component />
                        </TabLayout>
                    </Suspense>
                </Tab>
            ))}
        </>
    );
};

const Content = () => {
    const tripId = useTripIdUrlParam();
    const history = useHistory();

    const dispatch = userTrips.useDispatch();

    const { user, trip, booking } = useSelector((state) => ({
        user: state.profile.current,
        trip: state.userTrips.current,
        booking: state.userBookings?.current?.[0],
    }));

    const redirectToRootPath = () => history.push(appRootPath);

    useEffect(() => {
        if (!trip || trip.id !== tripId) {
            dispatch.getRecord(tripId, undefined, {
                errorCallback: () => redirectToRootPath(),
            });
        }
    }, [tripId, trip?.id]);

    const shouldRender = isTransferProductReady(user, trip, booking);

    return shouldRender ? <TabList /> : <LoadingBox />;
};

const Root = () => {
    const path = useTransferPath('travel_detail');
    return path ? <Redirect to={path} /> : null;
};

const Transfer = ({
    loadNavigationContent,
}: TransferProps): JSX.Element | null => {
    if (!isTransfersEnabled) {
        return null;
    }
    return (
        <Product
            path={paths.product}
            label={'Transfer'}
            root={<Root />}
            loadNavigationContent={loadNavigationContent}
            hideNavigation={true}
        >
            <MainContent.Inner maxWidth='container.2xl' margin='0 auto'>
                <Content />
            </MainContent.Inner>
        </Product>
    );
};

export default Transfer;
