import PropTypes from 'prop-types';
import Tab from '../../../../navigation/Tab';
import {
    StyledParagraph,
    PreparationContentWrapper,
} from './Preparation.components';
import PDFViewer from '../../../../components/PDFViewer/PDFViewer';
import { Heading } from '@trova-trip/trova-components';
import Iframe from 'iframe-resizer-react';
import { ROUTE_TOKENS } from 'applications/common/constants';

const { TRAVELER_APP_TAB_ROUTE } = ROUTE_TOKENS;

function isPdf(url: string): boolean {
    return url.endsWith(`.pdf`);
}

const Preparation = ({ url }: { url: string }): JSX.Element => (
    <Tab label={'Preparation'} path={TRAVELER_APP_TAB_ROUTE.PREPARATION}>
        <>
            <Heading as={'h2'}>Preparation</Heading>
            <div>
                {url ? (
                    <PreparationContentWrapper>
                        {isPdf(url) ? (
                            <PDFViewer
                                url={url}
                                description={`Download the preparation guide to get ready for your trip`}
                                downloadButton={true}
                            />
                        ) : (
                            <Iframe
                                src={`${url}?contentOnly=true`}
                                style={{
                                    backgroundColor: '#fff',
                                    borderTopLeftRadius: '1.5rem',
                                    borderTopRightRadius: '1.5rem',

                                    paddingLeft: '1.7rem',
                                    width: '1px',
                                    minWidth: '100%',
                                    minHeight: '1000px',
                                }}
                                frameBorder={0}
                            />
                        )}
                    </PreparationContentWrapper>
                ) : (
                    <StyledParagraph>
                        No preparation file specified
                    </StyledParagraph>
                )}
            </div>
        </>
    </Tab>
);

Preparation.propTypes = {
    url: PropTypes.string,
};

export default Preparation;
