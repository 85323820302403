import { errorObjects } from '@trova-trip/trova-common';

const { SingleSupplementUnavailable } = errorObjects;

type Application = 'admin' | 'portal';

interface ErrorMessagesMap {
    [key: number]: Partial<Record<Application, string>>;
}

const errorMessagesMap: ErrorMessagesMap = {
    [SingleSupplementUnavailable.code]: {
        admin: "The number of travelers is higher than the available Single Supplement supply or the 'Bookable by Travelers' flag is false",
        portal: "Sorry, we have run out of private rooms at this time. Don't worry, you haven't been charged.",
    },
};

/**
 * Retrieves the error message to display based on the provided error, code and application.
 *
 * @param error
 * @param code - custom error code
 * @param application - either 'admin' or 'portal'.
 *
 * @return The error message to display.
 */
const getErrorMessageToDisplay = (
    error: string,
    code: number = 0,
    application: Application = 'admin',
) => {
    const errorMessage = error || '';

    if (!code || !errorMessagesMap[code]) return errorMessage;

    return errorMessagesMap[code][application] || errorMessage;
};

export { errorMessagesMap, getErrorMessageToDisplay };
