import { coreUtils } from '@trova-trip/trova-common';
import capitalize from 'lodash/capitalize';
import differenceBy from 'lodash/differenceBy';
import flatMap from 'lodash/flatMap';
import keys from 'lodash/keys';
import map from 'lodash/map';
import omit from 'lodash/omit';
import { ManageBookingState } from '../../../../../state/features/manageBooking/types';
import { extractAddOnsFromState } from '../../../../../state/features/manageBooking/utils';
import { UpdateBookingParams } from '../../../../../state/userBookings';
import {
    BaseUser,
    BookingAddOn,
    BookingWithTrip,
    MANAGE_BOOKING_ADDON_STATUS,
    ManageBookingAddOn,
    SavedBooking,
    Service,
} from '../types';
import { getTravelersIdsFromBooking } from '../viewmodel/utils';
import {
    getAddOnStatusAndType,
    isUpgradeToPrivateRoomSelected,
} from './check.utils';
import {
    filterAddOnsByStatus,
    getAccommodationsAddOnsIds,
    getPendingActivitiesAddOns,
    getPrePostPendingAddOns,
    getTravelersRoomsOnUpgrade,
} from './extract.utils';

const {
    idGeneratorUtils: { generateShortId },
} = coreUtils;

/**
 * Prepares the travelers rooms for calling updateBooking endpoint.
 *
 * @param travelerRooms
 * @returns Array of travelers rooms to update.
 */
const prepareTravelersRoomsToUpdate = (
    state: ManageBookingState,
): BookingWithTrip['travelersRooms'] => {
    const {
        travelersRooms,
        addOns: {
            accommodations: { singleSupplement },
        },
        travelersQuantity,
    } = state;

    const isUpgradeSelected = isUpgradeToPrivateRoomSelected(
        singleSupplement,
        travelersQuantity,
    );

    /**
     * If the upgrade to private room was selected, we need to return all single occupancy rooms.
     * @see getTravelersRoomsOnUpgrade
     */
    if (isUpgradeSelected) {
        return getTravelersRoomsOnUpgrade(travelersQuantity);
    }

    const selectedStatuses = [
        MANAGE_BOOKING_ADDON_STATUS.CONFIRMED,
        MANAGE_BOOKING_ADDON_STATUS.PENDING_ADDITION,
    ];

    /**
     * Otherwise we return the travelers rooms that have the selected statuses.
     */
    const result = flatMap(travelersRooms, (roomStatus) =>
        map(keys(roomStatus), (roomType) => ({
            roomType,
        })).filter((room) =>
            selectedStatuses.includes(roomStatus[room.roomType]),
        ),
    ) as BookingWithTrip['travelersRooms'];

    return result;
};

/**
 * Transforms the add-ons from ManageBookingAddOn to BookingAddOn removing the status and manageMode properties and updating the quantity.
 *
 * @param addOns
 * @returns An array of BookingAddOn.
 */
const transformManageBookingAddOnsToBookingAddOns = (
    addOns: ManageBookingAddOn[],
): BookingAddOn[] => {
    const transformedAddOns = addOns.map((addOn) => {
        const { isSingleSupplementAddOn } = getAddOnStatusAndType(addOn);

        const updatedAddOn = isSingleSupplementAddOn
            ? addOn
            : updateAddOnQuantity(addOn);

        return omit(updatedAddOn, ['status', 'manageMode']);
    });
    return transformedAddOns as BookingAddOn[];
};

const createSingleSupplementAddOns = (
    addOn: ManageBookingAddOn | undefined,
    booking: SavedBooking,
    state: ManageBookingState,
): ManageBookingAddOn[] => {
    if (!addOn) return [];

    const {
        addOns: {
            accommodations: { singleSupplement: originalSingleSupplement },
        },
        travelersQuantity,
    } = state.originalState;

    const { quantity: currentQuantity, status: currentStatus } = addOn;
    const { quantity: originalQuantity } =
        originalSingleSupplement as ManageBookingAddOn;

    const upgradeWasOriginallySelected = isUpgradeToPrivateRoomSelected(
        originalSingleSupplement,
        travelersQuantity,
    );

    /**
     * If the upgrade was originally selected, we need to create the same quantity of single supplements as the original quantity.
     * otherwise we create the same quantity of single supplements as the current quantity.
     */
    const quantityOnMultipleTravelers = upgradeWasOriginallySelected
        ? originalQuantity
        : currentQuantity;

    const quantityToCreate =
        travelersQuantity === 1
            ? travelersQuantity
            : quantityOnMultipleTravelers;

    const travelersId = getTravelersIdsFromBooking(booking);
    const singleSupplementsFromBooking = booking?.addOns?.filter((addOn) => {
        const { isSingleSupplementAddOn } = getAddOnStatusAndType(
            addOn as ManageBookingAddOn,
        );
        return isSingleSupplementAddOn && !addOn.deleted;
    });

    const singleSupplements = [...Array(quantityToCreate)].map((_, index) => {
        const isFirstAddOn = index === 0;
        let statusToUpdate = currentStatus;

        /**
         * If upgrade was originally selected, the status of the first add-on is the current status,
         * otherwise is pending removal in order to be deleted
         * once we process them with updateDeletedAddOnsByStatus function.
         */
        if (upgradeWasOriginallySelected) {
            if (currentQuantity === 0) {
                statusToUpdate = MANAGE_BOOKING_ADDON_STATUS.PENDING_REMOVAL;
            }

            statusToUpdate = isFirstAddOn
                ? currentStatus
                : MANAGE_BOOKING_ADDON_STATUS.PENDING_REMOVAL;
        }

        return {
            ...addOn,
            addOnId:
                singleSupplementsFromBooking?.[index]?.addOnId ||
                generateShortId(),
            quantity: 1,
            user: travelersId[index],
            status: statusToUpdate,
        };
    });

    return singleSupplements;
};

const createPreAndPostAddOns = (
    addOns: ManageBookingAddOn[],
    booking: SavedBooking,
): ManageBookingAddOn[] => {
    if (!addOns) return [];
    const travelersId = getTravelersIdsFromBooking(booking);

    const accommodationsAddOnsIds = getAccommodationsAddOnsIds(booking);

    /**
     * Create the pre and post accommodations add-ons for each traveler.
     * We ensure that the first add-on has the same addOnId as the original add-on.
     */
    const createdAddOns = addOns.map((addOn) => {
        const { quantity } = addOn;
        const addOnServiceId = (addOn.service as Service)._id as string;

        const serviceIdMatchingAddOnIds =
            accommodationsAddOnsIds[addOnServiceId] || [];

        return [...Array(quantity)].map((_, index) => {
            return {
                ...addOn,
                addOnId: serviceIdMatchingAddOnIds[index] || generateShortId(),
                quantity: 1,
                user: travelersId[index],
            };
        });
    });

    return flatMap(createdAddOns);
};

const extractPendingSingleSupplementAddOn = (
    addOns: ManageBookingAddOn[],
    originalSingleSupplement: ManageBookingAddOn | undefined,
): ManageBookingAddOn | undefined => {
    if (!addOns || !originalSingleSupplement) return undefined;

    const pendingSingleSupplement = addOns
        .filter((addOn) => {
            const {
                isSingleSupplementAddOn,
                isPendingAdditionAddOn,
                isPendingRemovalAddOn,
                isConfirmedAddOn,
            } = getAddOnStatusAndType(addOn);

            const isOriginalQuantityGreaterThanCurrent =
                originalSingleSupplement?.quantity > addOn.quantity;

            return (
                isSingleSupplementAddOn &&
                (isPendingAdditionAddOn ||
                    isPendingRemovalAddOn ||
                    (isConfirmedAddOn && isOriginalQuantityGreaterThanCurrent))
            );
        })
        .shift();
    return pendingSingleSupplement;
};

const extractPendingPreAndPostAccommodationsByStatus = (
    addOns: ManageBookingAddOn[],
    status: MANAGE_BOOKING_ADDON_STATUS[],
): ManageBookingAddOn[] | undefined => {
    const pendingPreAndPostAccommodations = addOns.filter((addOn) => {
        const {
            isAccommodationAddOn,
            isPendingAdditionAddOn,
            isPendingRemovalAddOn,
        } = getAddOnStatusAndType(addOn);
        return (
            (isAccommodationAddOn && isPendingAdditionAddOn) ||
            (isAccommodationAddOn && isPendingRemovalAddOn)
        );
    });

    const pendingAdditionAddOns = pendingPreAndPostAccommodations.filter(
        (addOn) => status.includes(addOn.status),
    );
    return pendingAdditionAddOns;
};

const extractPendingTransferAddOns = (
    addOnsForUpdate: ManageBookingAddOn[],
): ManageBookingAddOn[] => {
    return addOnsForUpdate.filter((addOn) => {
        const {
            isTransferAddOn,
            isPendingAdditionAddOn,
            isPendingRemovalAddOn,
        } = getAddOnStatusAndType(addOn);

        return (
            isTransferAddOn && (isPendingAdditionAddOn || isPendingRemovalAddOn)
        );
    });
};

const updateTransfersAddOnsWithUserId = (
    addOns: ManageBookingAddOn[],
    booking: SavedBooking,
): ManageBookingAddOn[] => {
    const travelersId = getTravelersIdsFromBooking(booking);

    const { preTransferPendingAddOns, postTransferPendingAddOns } =
        getPrePostPendingAddOns(addOns);

    const updateAddOnId = (
        addOn: ManageBookingAddOn,
        index: number,
    ): ManageBookingAddOn => {
        return {
            ...addOn,
            user: travelersId[index],
        };
    };

    const updatedPreTransferPendingAddOns = preTransferPendingAddOns.map(
        (addOn, index) => updateAddOnId(addOn, index),
    );

    const updatedPostTransferPendingAddOns = postTransferPendingAddOns.map(
        (addOn, index) => updateAddOnId(addOn, index),
    );

    return [
        ...updatedPreTransferPendingAddOns,
        ...updatedPostTransferPendingAddOns,
    ];
};

const updateDeletedAddOnsByStatus = (
    addOns: ManageBookingAddOn[],
): ManageBookingAddOn[] => {
    const updatedAddOns = addOns.map((addOn) => {
        const { isPendingRemovalAddOn } = getAddOnStatusAndType(addOn);

        return {
            ...addOn,
            deleted: isPendingRemovalAddOn,
        };
    });
    return updatedAddOns;
};

/**
 * Prepares the add-ons for calling updateBooking endpoint.
 *
 * @param booking
 * @param state
 * @returns An array of add-ons to update.
 */
const prepareAddOnsForUpdate = (
    booking: SavedBooking,
    state: ManageBookingState,
): BookingAddOn[] => {
    /**
     * First we extract the add-ons from the state and filter them by status.
     */
    const extractedAddOns = extractAddOnsFromState(state.addOns);

    const addOnsForUpdate = filterAddOnsByStatus(extractedAddOns, [
        MANAGE_BOOKING_ADDON_STATUS.PENDING_ADDITION,
        MANAGE_BOOKING_ADDON_STATUS.PENDING_REMOVAL,
        MANAGE_BOOKING_ADDON_STATUS.CONFIRMED,
    ]);

    const originalSingleSupplement =
        state.originalState.addOns.accommodations.singleSupplement;

    /**
     * Process the single supplement add-ons
     */
    const singleSupplementAddOn = extractPendingSingleSupplementAddOn(
        addOnsForUpdate,
        originalSingleSupplement,
    );

    const singleSupplements = createSingleSupplementAddOns(
        singleSupplementAddOn,
        booking,
        state,
    );

    /**
     * Process the pre and post accommodations add-ons
     */
    const pendingPreAndPostAccommodations =
        extractPendingPreAndPostAccommodationsByStatus(addOnsForUpdate, [
            MANAGE_BOOKING_ADDON_STATUS.PENDING_ADDITION,
            MANAGE_BOOKING_ADDON_STATUS.PENDING_REMOVAL,
        ]) as ManageBookingAddOn[];

    const createdPreAndPostAccommodationsAddOns = createPreAndPostAddOns(
        pendingPreAndPostAccommodations,
        booking,
    );

    /**
     * Process the activities add-ons
     */
    const extractedPendingActivitiesAddOns =
        getPendingActivitiesAddOns(extractedAddOns);

    /**
     * Process the transfer add-ons
     */
    const pendingAdditionTransfers =
        extractPendingTransferAddOns(addOnsForUpdate);

    const updatedPendingTransfersAddOns = updateTransfersAddOnsWithUserId(
        pendingAdditionTransfers,
        booking,
    );

    /**
     * Filter the add-ons that are not needed to be updated
     */
    const filteredTransformedAddOns = addOnsForUpdate.filter((addOn) => {
        const {
            isSingleSupplementAddOn,
            isAccommodationAddOn,
            isTransferAddOn,
            isActivityAddOn,
        } = getAddOnStatusAndType(addOn);

        const filteredAddOns =
            isSingleSupplementAddOn ||
            isAccommodationAddOn ||
            isTransferAddOn ||
            isActivityAddOn;

        return !filteredAddOns;
    });

    /**
     * Group the add-ons to update.
     */
    const manageAddOnGroup = [
        ...filteredTransformedAddOns,
        ...createdPreAndPostAccommodationsAddOns,
        ...updatedPendingTransfersAddOns,
        ...singleSupplements,
        ...extractedPendingActivitiesAddOns,
    ] as ManageBookingAddOn[];

    /**
     * Update the deleted property of the add-ons by status.
     */
    const addOnsWithUpdatedDeleteProp =
        updateDeletedAddOnsByStatus(manageAddOnGroup);

    /**
     * Transform the add-ons to BookingAddOn
     * and add the missing add-ons from the booking.
     * This is needed because the add-ons from the booking are not in the state.
     */
    const transformedAddOns = transformManageBookingAddOnsToBookingAddOns(
        addOnsWithUpdatedDeleteProp,
    );

    const addOnsFromBooking = booking.addOns;

    const missingAddOnsFromBooking = differenceBy(
        addOnsFromBooking,
        transformedAddOns,
        'addOnId',
    );

    const addOnsToUpdate = [...transformedAddOns, ...missingAddOnsFromBooking];

    return addOnsToUpdate;
};

const getUpdateBookingData = (
    state: ManageBookingState,
    booking: SavedBooking,
): Omit<UpdateBookingParams, 'payment'> => {
    const preparedPendingAddOns = prepareAddOnsForUpdate(booking, state);

    const preparedTravelersRooms = prepareTravelersRoomsToUpdate(state);

    return {
        addOns: preparedPendingAddOns,
        travelersRooms: preparedTravelersRooms,
    };
};

/**
 * Creates a record of travelers names based on the booking
 * @param travelers - An array of travelers.
 *
 * @returns An object where the keys are traveler IDs and the values are the full names of the travelers.
 */
const getTravelersNames = (travelers: BaseUser[]): Record<string, string> => {
    const travelersNames: Record<string, string> = {};

    travelers.forEach((participant) => {
        if (!participant?._id) {
            return;
        }

        travelersNames[participant._id] = `${capitalize(
            participant.firstName,
        )} ${capitalize(participant.lastName)}`;
    });

    return travelersNames;
};

/**
 * Updates the quantity of an add-on.
 *
 * @param addOn
 * @param quantity
 * @returns The updated add-on with the new quantity.
 */
const updateAddOnQuantity = (
    addOn: ManageBookingAddOn,
    quantity: number = 1,
): ManageBookingAddOn => {
    return {
        ...addOn,
        quantity,
    };
};

export {
    createPreAndPostAddOns,
    createSingleSupplementAddOns,
    extractPendingPreAndPostAccommodationsByStatus,
    extractPendingSingleSupplementAddOn,
    extractPendingTransferAddOns,
    getTravelersNames,
    getUpdateBookingData,
    prepareAddOnsForUpdate,
    prepareTravelersRoomsToUpdate,
    transformManageBookingAddOnsToBookingAddOns,
    updateAddOnQuantity,
    updateDeletedAddOnsByStatus,
    updateTransfersAddOnsWithUserId,
};
