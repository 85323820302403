import { PUBLIC_PROFILE_DIALOG_LOCATION } from '../../../../../hooks/usePublicProfileAnalytics';
import AudienceCollectionCard from './AudienceCollectionCard';
import { AudienceCollectionInfo } from './AudienceCollectionCards.types';

const SurveyCollectionCard = (props: AudienceCollectionInfo) => {
    const { total, link, isLocked = false } = props;

    return (
        <AudienceCollectionCard
            title='Survey Responses'
            description='Survey your audience to understand where they want to go,
            their budget, preferred travel dates, and more.'
            responses={total}
            isLocked={isLocked}
            copyConfig={{
                link: link,
                label: 'Profile Link',
            }}
            location={PUBLIC_PROFILE_DIALOG_LOCATION.surveyResponsesCard}
        />
    );
};

export default SurveyCollectionCard;
