import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import Iframe from 'iframe-resizer-react';

import Loading from '../../../../../components/Loading';
import {
    StyledDetailsContainer,
    StyledTierInfoContainer,
} from './IFramedItineraryDetails.styles';
import queryString from 'query-string';
import { StatefulModel } from '../../../../types';
import useModelStatusMatcher from '../../../../common/hooks/useModelStatusMatcher';
import {
    ItineraryProfileDataLockedCard,
    ItineraryTierLockedCard,
} from '../components/ItineraryLockedCards';
import useWindowHostName from '../../../../../util/hooks/useWindowHostname';
import { UserStatuses } from '../../../../../config/constants';
import useItineraryDetailsLockedStates, {
    LockedStates,
} from '../hooks/useItineraryDetailsLockedStates';
import { useTrackingEvent } from '../../../../../analytics/hooks';

interface IFramedItineraryDetailsProps {
    itineraryId: string;
}

interface LockedInformationProps {
    lockedStates: LockedStates;
}

const preQualifiedStatuses = new Set([
    UserStatuses.CREATED,
    UserStatuses.READY_TO_QUALIFY,
    UserStatuses.QUALIFYING,
]);

const LockedInformation = ({
    lockedStates,
}: LockedInformationProps): JSX.Element | null => {
    const { tierLocked } = lockedStates;

    return (
        <StyledTierInfoContainer>
            {tierLocked ? (
                <ItineraryTierLockedCard />
            ) : (
                <ItineraryProfileDataLockedCard />
            )}
        </StyledTierInfoContainer>
    );
};

const IFramedItineraryDetails: React.FC<IFramedItineraryDetailsProps> = ({
    itineraryId,
}) => {
    const history = useHistory();
    const host = useWindowHostName();
    const lockedStates = useItineraryDetailsLockedStates();
    const { trackUserEvent } = useTrackingEvent();

    useEffect(() => {
        const onItineraryClick = ({
            data: { itineraryId: ctaItineraryId, startSurveying },
        }) => {
            if (ctaItineraryId) {
                const itineraryUrl = `/app/host/itineraries/${itineraryId}/request`;
                history.push(itineraryUrl);
            } else if (startSurveying) {
                const audienceUrl = `/app/host/audience/research`;
                history.push(audienceUrl);
            }
        };

        window.addEventListener('message', onItineraryClick, false);
        return () => {
            window.removeEventListener('message', onItineraryClick);
        };
    }, [itineraryId]);

    useEffect(() => {
        const { tierLocked, incompleteProfile } = lockedStates;
        if (!tierLocked && incompleteProfile) {
            trackUserEvent({
                eventName:
                    'Host Viewed Blocked Trip Request Profile Completion Card',
                properties: { itineraryId },
            });
        }
    }, [lockedStates.incompleteProfile, lockedStates.tierLocked]);

    const isQualifying: boolean = useModelStatusMatcher({
        matchingStatuses: preQualifiedStatuses,
        model: StatefulModel.PROFILE,
    });

    const isLockedItinerary = Object.values(lockedStates).some(
        (state: boolean) => state,
    );

    const urlParams = {
        itineraryId: itineraryId,
        itineraryPreview: true,
        isHostQualifying: isQualifying,
        isRequestable: !isLockedItinerary,
    };

    const urlParamsQueryString = useMemo(
        () => queryString.stringify(urlParams),
        [itineraryId],
    );

    const itineraryUrl = `//${host}/api/me/pages/itinerary-portal?${urlParamsQueryString}`;

    return itineraryId ? (
        <StyledDetailsContainer>
            {isLockedItinerary ? (
                <LockedInformation lockedStates={lockedStates} />
            ) : null}
            <Iframe
                src={itineraryUrl}
                style={{
                    backgroundColor: '#fff',
                    borderTopLeftRadius: '1.5rem',
                    borderTopRightRadius: '1.5rem',
                    paddingTop: '2rem',
                    paddingLeft: '1.7rem',
                    width: '1px',
                    minWidth: '100%',
                    minHeight: '100vh',
                }}
                frameBorder={0}
            />
        </StyledDetailsContainer>
    ) : (
        <Loading />
    );
};

export default IFramedItineraryDetails;
