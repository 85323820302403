import { useEffect, useState } from 'react';
import {
    Stack,
    Text,
    IconButton,
    ComponentWidth,
    BaseBox,
    Input,
} from '@trova-trip/trova-components';
import { OnTiersChange } from '../../../components/PricingTable/common/types';

interface CostThresholdsCellProps {
    index: number;
    numberOfTravelers: number;
    onTiersChange: OnTiersChange;
    disabled: boolean;
}

const CostThresholdsCell = ({
    index,
    numberOfTravelers,
    onTiersChange,
    disabled,
}: CostThresholdsCellProps) => {
    const [tierValue, setTierValue] = useState(numberOfTravelers);
    useEffect(() => setTierValue(numberOfTravelers), [numberOfTravelers]);

    const onBlurHandler = (event) => {
        onTiersChange('update', {
            index,
            newValue: Number((event.target as HTMLInputElement).value),
        });
        setTierValue(numberOfTravelers);
    };

    return (
        <Stack spacing={3} align='center' wrap='nowrap' paddingRight='15%'>
            <Input
                name={`[${index}].numberOfTravelers`}
                type='number'
                size={ComponentWidth.ExtraSmall}
                value={String(tierValue)}
                onChange={(event) =>
                    setTierValue(
                        Number((event.target as HTMLInputElement).value),
                    )
                }
                onBlur={(event) => onBlurHandler(event)}
                disabled={disabled}
            />
            <Text fontSize='sm' fontWeight='bold' letterSpacing='wide'>
                Travelers{' '}
            </Text>
            <BaseBox flexShrink={0}>
                <IconButton
                    icon='trash'
                    aria-label='delete-tier'
                    onClick={() => onTiersChange('remove', { index })}
                />
            </BaseBox>
        </Stack>
    );
};

export default CostThresholdsCell;
