import { Text, TextProps } from '@trova-trip/trova-components';
import { Table } from '@trova-trip/trova-components/build/next';

type InfoRowVariants = 'text' | 'link';

type InfoRowProps = {
    title: string;
    value: string;
    variant?: InfoRowVariants;
    textAlign?: TextProps['textAlign'];
};

const specificTextPropsFor: Record<
    InfoRowVariants,
    Pick<TextProps, 'color' | 'fontWeight'>
> = {
    text: {
        color: 'blueGray.650',
        fontWeight: 'regular',
    },
    link: {
        color: 'red.600',
        fontWeight: 'medium',
    },
};

const InfoRowProps = ({
    title,
    value,
    variant = 'text',
    textAlign = { xs: 'right', md: 'left' },
}: InfoRowProps): JSX.Element => (
    <Table.Row>
        <Table.Cell paddingX={0}>
            <Text color='blueGray.650' fontWeight='regular' fontSize='sm'>
                {title}
            </Text>
        </Table.Cell>
        <Table.Cell paddingX={0}>
            <Text
                fontSize='sm'
                whiteSpace='pre-line'
                wordBreak='break-word'
                textAlign={textAlign}
                {...specificTextPropsFor[variant]}
            >
                {value}
            </Text>
        </Table.Cell>
    </Table.Row>
);

export default InfoRowProps;
