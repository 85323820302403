import { Grid } from '@material-ui/core';
import { PricingCalculator, coreUtils } from '@trova-trip/trova-common';
import {
    FormDataObjectType,
    FormDataValueType,
    FormSaver,
    useFormSaver,
} from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import { constants, models } from '@trova-trip/trova-models';
import isNumber from 'lodash/isNumber';
import last from 'lodash/last';
import {
    FormEvent,
    SyntheticEvent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { StatefulModel } from '../../../../../../applications/types';
import Snackbar from '../../../../../../components/Snackbar/Snackbar';
import {
    FlightAdvanceDisabled,
    HostApprovedActiveTrip,
    InactiveTrip,
} from '../../../../../../config/constants';
import Tab from '../../../../../../navigation/Tab';
import { BaseUser } from '../../../../../../state/features/transfer/types';
import { ProfileStoreSelector } from '../../../../../../state/profile';
import {
    TripStoreSelector,
    acceptTermsAndUpdateTrip,
} from '../../../../../../state/trips';
import { userTrips } from '../../../../../../state/userTrips';
import useWindowHostName from '../../../../../../util/hooks/useWindowHostname';
import AgreementConfirmationDialog from '../../../../../common/components/AgreementConfirmationDialog';
import useModelStatusMatcher from '../../../../../common/hooks/useModelStatusMatcher';
import PricingInputs from '../../../../components/PricingTab//PricingInputs';
import Documents from '../../../../components/PricingTab/Documents';
import FlightAdvanceInput from '../../../../components/PricingTab/FlightAdvanceInput';
import ProjectedEarnings from '../../../../components/PricingTab/ProjectedEarningsTable';
import { BaseTabProps } from '../Tabs.types';
import { useTrackingEvent } from './../../../../../../analytics/hooks';
import PricingTabStickyLayout from './PricingTabStickyLayout';

const TRIP_STATUS = constants.trips.TRIP_STATUS;

type ThresholdEarningsForTrip = PricingCalculator.ThresholdEarningsForTrip;
const { formatUSD } = coreUtils.currencyUtils;
const { getMaxEarningsAdvanceLimit } = coreUtils.hostUtils;

interface FieldsToUpdate {
    id?: string;
    prices?: { initial: number; remainingPrice: number };
    flightStipend?: number;
}

interface TermsFieldsToUpdate extends FieldsToUpdate {
    terms: string;
}

interface EarningsStateType extends ThresholdEarningsForTrip {
    minimumEarnings: number;
}

const submitTerms = async (
    data: TermsFieldsToUpdate,
    onSuccess: () => void,
    onError: (error: string) => void,
): Promise<void> => {
    const { id, prices, flightStipend, terms } = data;
    const acceptTermsResult = await acceptTermsAndUpdateTrip(id, {
        terms,
        prices,
        flightStipend,
    });
    if (acceptTermsResult?.success) {
        onSuccess();
    } else {
        onError(acceptTermsResult?.error);
    }
};

const updateTripFromForm = (
    data: FieldsToUpdate,
    updateTrip: (
        id: string,
        dataFormatted: Omit<FieldsToUpdate, 'id'>,
        any,
    ) => void,
    onSuccess: () => void,
    onError: (error: string) => void,
): void => {
    if (!data.id || !data.prices) {
        return;
    }

    const {
        id,
        prices: { initial, remainingPrice },
        flightStipend,
    } = data;

    const dataFormatted = {
        prices: {
            ...data.prices,
            initial,
            remainingPrice,
        },
        flightStipend,
    };

    updateTrip(id, dataFormatted, {
        successCallback: onSuccess,
        errorCallback: onError,
    });
};

const getMinimumEarningsForTrip = (
    costThresholdsEarnings: PricingCalculator.ThresholdEarning[],
    minimumSpots?: number,
): number => {
    if (!costThresholdsEarnings.length) {
        return 0;
    }

    const minimumEarnings = costThresholdsEarnings?.find(
        (threshold) => threshold.travelerRowNumber === minimumSpots,
    )?.totalEarnings;

    if (!isNumber(minimumEarnings)) {
        throw new Error(
            `No threshold earnings defined for ${minimumSpots} spots.`,
        );
    }

    return minimumEarnings;
};

const checkIfShouldCalculateHostCosts = (
    trip: models.trips.Trip,
    initialPrice: number,
    remainingPrice: number,
    minimumSpots: number,
    maximumSpots: number,
): boolean => {
    const lastAcceptedTerms = last(trip?.hostTerms);

    const pricesChanged = !(
        lastAcceptedTerms?.costOutputs &&
        initialPrice === lastAcceptedTerms?.prices?.initial &&
        remainingPrice === lastAcceptedTerms?.prices?.remainingPrice
    );

    let minMaxSpotsChanged = false;
    if (lastAcceptedTerms?.minimumSpots && lastAcceptedTerms?.maximumSpots) {
        const minimumSpotsAreEqual =
            lastAcceptedTerms?.minimumSpots === minimumSpots;
        const maximumSpotsAreEqual =
            lastAcceptedTerms?.maximumSpots === maximumSpots;

        minMaxSpotsChanged = !minimumSpotsAreEqual || !maximumSpotsAreEqual;
    }

    return pricesChanged || minMaxSpotsChanged;
};

const getValuesFromCostOutputsToCalculateEarnings = (
    hostTerms: models.trips.HostTerm[],
    minimumSpots: number,
    maximumSpots: number,
): {
    minimumSpots: number;
    maximumSpots: number;
    prices: models.trips.Prices | undefined;
} => {
    const lastAcceptedTerms = last(hostTerms);
    const minSpots = lastAcceptedTerms?.minimumSpots ?? minimumSpots;
    const maxSpots = lastAcceptedTerms?.maximumSpots ?? maximumSpots;

    return {
        minimumSpots: minSpots,
        maximumSpots: maxSpots,
        prices: lastAcceptedTerms?.prices,
    };
};

const validateFlightAdvance = (
    flightAdvance: number,
    minimumEarnings: number,
    currentUser: BaseUser | undefined,
): string => {
    const negativeEarnings = flightAdvance < 0;
    const maxLimit = getMaxEarningsAdvanceLimit(currentUser) || 0;
    const exceedMaxLimit = flightAdvance > maxLimit;
    const exceedEarnings =
        flightAdvance !== 0 ? flightAdvance > minimumEarnings : false;

    if (negativeEarnings) {
        return 'Earnings advance can not be negative';
    }
    if (exceedMaxLimit) {
        return `Cannot exceed ${formatUSD(maxLimit)}`;
    }
    if (exceedEarnings) {
        return `Cannot exceed min. earnings (${formatUSD(minimumEarnings)})`;
    }
    return '';
};

const PricingTab: React.FC<BaseTabProps> = (props) => {
    const { hideNavigation, disabled } = props;
    const host = useWindowHostName();
    const currentUser = useSelector(
        (state: ProfileStoreSelector) => state.profile.current,
    );
    const currentTrip = useSelector(
        (state: TripStoreSelector) => state.userTrips.current,
    );

    const { updateRecord: updateTrip } =
        userTrips.useDispatch.bind(currentTrip)();

    const [isOpenAgreementDialog, setIsOpenAgreementDialog] = useState(false);
    const [agreementDocument, setAgreementDocument] = useState<
        string | undefined
    >(undefined);
    const [isInAgreement, setIsInAgreement] = useState<boolean>(false);
    const [snackBar, setSnackBar] = useState({
        message: '',
        color: 'info',
        show: false,
    });
    const [flightAdvanceErrorMessage, setFlightAdvanceErrorMessage] =
        useState('');

    const [thresholdEarnings, setThresholdEarnings] =
        useState<EarningsStateType>({
            costThresholdsEarnings: [],
            maxValue: 0,
            minimumEarnings: 0,
        });

    const { costThresholdsEarnings, maxValue, minimumEarnings } =
        thresholdEarnings;

    useEffect(() => {
        const setDocument = ({ data: { termsAccepted } }): void => {
            if (termsAccepted) {
                setAgreementDocument(termsAccepted);
            }
        };

        window.addEventListener(`message`, setDocument);
        return (): void => {
            window.removeEventListener(`message`, setDocument);
        };
    });

    const isTripApproved = useModelStatusMatcher({
        model: StatefulModel.TRIP,
        matchingStatuses: HostApprovedActiveTrip,
    });

    const isTripInactive = useModelStatusMatcher({
        model: StatefulModel.TRIP,
        matchingStatuses: InactiveTrip,
    });

    const { trackUserEvent } = useTrackingEvent();

    const onFormSuccess = (): void => {
        setSnackBar({
            message: 'Prices saved!',
            color: 'success',
            show: true,
        });
    };
    const onFormError = (error: string): void => {
        console.error(`[Pricing tab] updateTrip error: ${error}`);
        setSnackBar({
            message: 'There was an error saving the prices. Please try again.',
            color: 'danger',
            show: true,
        });
    };
    //@ts-ignore
    const initialValues: FormDataObjectType<FormDataValueType> = {
        ...currentTrip,
        flightStipend: currentTrip?.flightStipend || 0,
    };

    const formSaver = useFormSaver({
        onSubmit: async (values) => {
            const pricingTabSubmitProps = {
                tripId: currentTrip?.id,
                prices: {
                    initial: initialPrice,
                    remaining: remainingPrice,
                },
            };
            trackUserEvent({
                eventName: 'Host Saved Pricing',
                properties: pricingTabSubmitProps,
            });
            if (isInAgreement) {
                if (!agreementDocument) {
                    onFormError('No agreement document provided');
                    return;
                }
                trackUserEvent({
                    eventName: 'Host Accepted Updated Agreement',
                    properties: pricingTabSubmitProps,
                });
                return submitTerms(
                    { terms: agreementDocument, ...values },
                    onFormSuccess,
                    onFormError,
                );
            } else {
                const updatedTrip = { ...currentTrip, ...values };
                return Promise.resolve(
                    updateTripFromForm(
                        updatedTrip,
                        updateTrip,
                        onFormSuccess,
                        onFormError,
                    ),
                );
            }
        },
        initialValues,
    });

    const onFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        const hasNegativeEarnings =
            costThresholdsEarnings
                .filter(
                    (thresholdEarning) =>
                        thresholdEarning.travelerRowNumber >= minimumSpots,
                )
                .some(
                    (thresholdEarning) => thresholdEarning.totalEarnings < 0,
                ) ?? true;

        if (hasNegativeEarnings) {
            return setSnackBar({
                message: 'Could not save pricing - Negative earnings',
                color: 'danger',
                show: true,
            });
        }

        if (!isNumber(initialPrice) || !isNumber(remainingPrice)) {
            return setSnackBar({
                message:
                    'Could not save pricing - Initial Price and/or Remaining Price is invalid',
                color: 'danger',
                show: true,
            });
        }

        if (isTripApproved) {
            setIsOpenAgreementDialog(true);
        } else {
            formSaver.handleFormSubmit(event);
        }
    };

    const isFormInvalid =
        !formSaver.isFormDirty || !!flightAdvanceErrorMessage || isTripInactive;

    const isFormEditMode = !isTripInactive;

    const handleDialogConfirm = async (
        e: FormEvent<HTMLFormElement>,
    ): Promise<void> => {
        try {
            formSaver.handleFormSubmit(e);
            setIsOpenAgreementDialog(false);
        } catch (error) {
            setSnackBar({
                message: error.message,
                color: 'danger',
                show: true,
            });
        }
    };

    const tripId = formSaver.formValues.get.id;

    const initialPrice = formSaver.formValues.get.nested('prices.initial');

    const remainingPrice = formSaver.formValues.get.nested(
        'prices.remainingPrice',
    );

    const minimumSpots = formSaver.formValues.get.minimumSpots as number;
    const maximumSpots = formSaver.formValues.get.maximumSpots as number;
    const flightAdvance = formSaver.formValues.get.flightStipend;

    const agreementUrl = `//${host}/api/me/pages/host-terms?tripId=${currentTrip.id}&hideFooterButtons=true&postTermsDocument=true&initialPrice=${initialPrice}&remainingPrice=${remainingPrice}&flightAdvance=${flightAdvance}`;
    const signedAgreementUrl = `//${host}/api/me/trips/${currentTrip.id}/host-terms`;

    useEffect(() => {
        calculateThresholdEarnings();
    }, [tripId]);

    const calculateThresholdEarnings = useCallback(() => {
        const { error } = formSaver.formState;
        if (
            !error ||
            (typeof error === 'string' && error.split(' ')[0] === 'launchDate')
        ) {
            let hostCosts;
            let tripToCalculateThresholdEarnings = formSaver.formState;
            const shouldCalculateCosts = checkIfShouldCalculateHostCosts(
                currentTrip,
                initialPrice,
                remainingPrice,
                minimumSpots,
                maximumSpots,
            );
            if (shouldCalculateCosts) {
                hostCosts =
                    PricingCalculator.calculateHostCostsWithFixedCostsAndPrices(
                        formSaver.formState,
                    );
            } else {
                if (currentTrip?.hostTerms) {
                    hostCosts = PricingCalculator.getCostOutputs(
                        currentTrip.hostTerms,
                    );
                    // @ts-ignore
                    tripToCalculateThresholdEarnings =
                        getValuesFromCostOutputsToCalculateEarnings(
                            currentTrip.hostTerms,
                            minimumSpots,
                            maximumSpots,
                        );
                }
            }

            const thresholdEarnings =
                PricingCalculator.calculateThresholdEarningsForTrip(
                    tripToCalculateThresholdEarnings,
                    hostCosts,
                );
            const minimumEarnings = getMinimumEarningsForTrip(
                thresholdEarnings.costThresholdsEarnings,
                minimumSpots,
            );
            setThresholdEarnings({ ...thresholdEarnings, minimumEarnings });
        }
    }, [initialPrice, remainingPrice, minimumSpots, tripId]);

    useEffect(() => {
        setFlightAdvanceErrorMessage(
            validateFlightAdvance(
                formSaver.formValues.get.flightStipend as number,
                minimumEarnings,
                currentUser,
            ),
        );
    }, [minimumEarnings]);

    const handlePricingInputBlur = useCallback(() => {
        calculateThresholdEarnings();
    }, [calculateThresholdEarnings]);

    const isFlightAdvanceInputDisabled = useModelStatusMatcher({
        model: StatefulModel.TRIP,
        matchingStatuses: FlightAdvanceDisabled,
    });

    const isServiceAgreementAccepted =
        currentTrip.status !== TRIP_STATUS.TROVA_PRICING_APPROVED;

    return (
        <Tab
            label={'Pricing'}
            path={'/pricing'}
            hideNavigation={hideNavigation}
            disabled={disabled}
        >
            <PricingTabStickyLayout>
                <FormSaver name={'pricing-tab-form'} onSubmit={onFormSubmit}>
                    <Grid container spacing={32}>
                        <Grid item xs={12}>
                            <PricingInputs
                                initialPrice={initialPrice as number}
                                remainingPrice={remainingPrice as number}
                                onChange={(
                                    _: SyntheticEvent,
                                    inputName: string,
                                    eventValue: number,
                                ): void => {
                                    formSaver.formValues.set.nested(
                                        inputName,
                                        eventValue,
                                    );
                                }}
                                onBlur={handlePricingInputBlur}
                                disabled={!isFormEditMode}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ProjectedEarnings
                                costThresholdsEarnings={costThresholdsEarnings}
                                maxValue={maxValue}
                                transactionFee={
                                    formSaver.formValues.get.nested(
                                        'prices.transactionFee',
                                    ) as number
                                }
                                serviceFee={
                                    formSaver.formValues.get.nested(
                                        'prices.serviceFee',
                                    ) as number
                                }
                                minimumSpots={
                                    formSaver.formValues.get
                                        .minimumSpots as number
                                }
                                maximumSpots={
                                    formSaver.formValues.get
                                        .maximumSpots as number
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FlightAdvanceInput
                                flightAdvance={
                                    formSaver.formValues.get
                                        .flightStipend as number
                                }
                                onChange={(value: number): void => {
                                    setFlightAdvanceErrorMessage(
                                        validateFlightAdvance(
                                            value,
                                            minimumEarnings,
                                            currentUser,
                                        ),
                                    );

                                    formSaver.formValues.set.flightStipend(
                                        value.toString(),
                                    );
                                }}
                                errorMessage={flightAdvanceErrorMessage}
                                minimumEarnings={minimumEarnings}
                                disabled={isFlightAdvanceInputDisabled}
                            />
                        </Grid>
                        {isServiceAgreementAccepted && (
                            <Grid item xs={12}>
                                <Documents agreementUrl={signedAgreementUrl} />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Button
                                variant='primary'
                                type='submit'
                                isDisabled={isFormInvalid}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </FormSaver>
            </PricingTabStickyLayout>
            <AgreementConfirmationDialog
                open={isOpenAgreementDialog}
                agreementUrl={agreementUrl}
                onCancel={(): void => {
                    setIsOpenAgreementDialog(false);
                }}
                isInAgreement={isInAgreement}
                setIsInAgreement={setIsInAgreement}
                onConfirmation={handleDialogConfirm}
            />
            <Snackbar
                place='tr'
                color={snackBar.color}
                message={snackBar.message}
                open={snackBar.show}
                autoHideDuration={4000}
                onClose={(): void => {
                    setSnackBar({ message: '', color: 'info', show: false });
                }}
            />
        </Tab>
    );
};

export default PricingTab;
