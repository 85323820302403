import Product from 'navigation/Product';
import AccountHeader from './components/AccountHeader';
import TabList from './components/TabList';
import { PRODUCT_PATH, TAB_PATH } from './config';
import ProductRootWithMobileNavigationView from '../../components/ProductRootWithMobileNavigationView';
import { Redirect } from 'react-router-dom';
import { useTabs } from './hooks';

const AccountRoot = (): JSX.Element => {
    const { tabs, getTab } = useTabs();
    const profileTab = getTab(TAB_PATH.PROFILE);
    const hasMultipleTabs =
        tabs.filter((tab) => tab.isVisibleOnNavigation).length > 1;

    return hasMultipleTabs ? (
        <ProductRootWithMobileNavigationView
            rootView={<Redirect to={profileTab.fullPath} />}
        />
    ) : (
        <Redirect to={profileTab.fullPath} />
    );
};

const Account = (): JSX.Element => {
    return (
        <Product
            path={PRODUCT_PATH}
            label={'Account'}
            header={<AccountHeader />}
            root={<AccountRoot />}
            // An empty array is returned by the function in order to set an empty navigation content
            loadNavigationContent={() => []}
            hideNavigation={true}
        >
            <TabList />
        </Product>
    );
};

export default Account;
