import { Stack, StackProps, Heading, Text } from '@trova-trip/trova-components';

interface FormHeaderProps extends StackProps {
    title: string;
    description?: string;
}

const FormHeader = (props: FormHeaderProps): JSX.Element => {
    const { title, description, ...stackProps } = props;
    return (
        <Stack direction='column' {...stackProps}>
            <Heading as={'h2'} marginBottom={2} size='2xl'>
                {title}
            </Heading>
            {description && <Text>{description}</Text>}
        </Stack>
    );
};

export default FormHeader;
