import { Dispatch } from 'redux';
import {
    MANAGE_BOOKING_ACTION,
    ManageBookingActions,
    ActionCreatorReturnType,
    INIT_VIEW_MODEL,
    SET_IS_LOADING,
    RESET_STATE,
    TOGGLE_ACCOMMODATION,
    TOGGLE_ACTIVITY,
    TOGGLE_TRANSFER,
    UPDATE_ROOM_OCCUPANCIES,
    TOGGLE_TRIP_SUMMARY_VISIBILITY,
    ToggleActionType,
    UPDATE_TRIP_SUMMARY_SECTIONS,
    TOGGLE_CONFIRMATION_MODAL,
    ResetRoomSelectionPayload,
    SET_INSURANCE_ADJUSTMENT,
    UpdateRoomSelectionComposedParams,
    SET_BOOKING_PAYMENT_DATA,
    SET_CONFIRMATION_DATA,
    SET_TRIP_SUMMARY_IS_LOADING,
    UpdateBookingPayload,
    ConfirmBookingPayload,
    RepriceInsurancePayload,
    SET_IS_MANAGE_BOOKING_FLOW_FINISHED,
    CancelInsurancePayload,
    CANCEL_INSURANCE,
    SET_INSURANCE_DATA,
    SET_CANCEL_INSURANCE_ADDON_ID,
    SetCancelPopupOpenedIdPayload,
    VerifySingleSupplementInventoryPayload,
    SET_SINGLE_SUPPLEMENT_INVENTORY,
    ManageBookingError,
    VERIFY_SERVICE_INVENTORY,
} from './types';
import {
    INSURANCE_API_ERROR,
    SINGLE_SUPPLEMENT_INVENTORY_ERROR,
    UPDATE_BOOKING_ERROR,
    getUpdateRoomOccupancyPayloadOnReset,
    repriceInsuranceAddOns,
} from './utils';
import { cancelAddOn, updateBooking, UpdateBooking } from '../../userBookings';
import noop from 'lodash/noop';
import { repriceInsurance } from '../../../apis/insurance';
import pick from 'lodash/pick';
import { BookingRepriceQuote } from '../../../apis/types';
import { SavedBooking } from '../../../applications/traveler/tabs/ManageBooking/types';
import { getErrorMessageToDisplay } from '../../utils/errors';
import { getUserTripInventory } from '../../userTrips';
import { errorObjects } from '@trova-trip/trova-common';
import {
    getAddOnStatusAndType,
    isPrivateRoomUpgradeAvailable,
} from '../../../applications/traveler/tabs/ManageBooking/utils/check.utils';

const { SingleSupplementUnavailable } = errorObjects;

const initViewModelAction = (
    payload: INIT_VIEW_MODEL['payload'],
): INIT_VIEW_MODEL => {
    return { type: MANAGE_BOOKING_ACTION.INIT_VIEW_MODEL, payload };
};

const resetStateAction = (payload?: RESET_STATE['payload']): RESET_STATE => {
    return { type: MANAGE_BOOKING_ACTION.RESET_STATE, payload };
};

const setIsLoadingAction = (
    payload: SET_IS_LOADING['payload'],
): SET_IS_LOADING => {
    return { type: MANAGE_BOOKING_ACTION.SET_IS_LOADING, payload };
};

const toggleAccommodationAction = (
    payload: TOGGLE_ACCOMMODATION['payload'],
): TOGGLE_ACCOMMODATION => {
    return { type: MANAGE_BOOKING_ACTION.TOGGLE_ACCOMMODATION, payload };
};

const toggleActivityAction = (
    payload: TOGGLE_ACTIVITY['payload'],
): TOGGLE_ACTIVITY => {
    return { type: MANAGE_BOOKING_ACTION.TOGGLE_ACTIVITY, payload };
};

const toggleTransferAction = (
    payload: TOGGLE_TRANSFER['payload'],
): TOGGLE_TRANSFER => {
    return { type: MANAGE_BOOKING_ACTION.TOGGLE_TRANSFER, payload };
};

const updateRoomOccupanciesAction = (
    payload: UPDATE_ROOM_OCCUPANCIES['payload'],
): UPDATE_ROOM_OCCUPANCIES => {
    return { type: MANAGE_BOOKING_ACTION.UPDATE_ROOM_OCCUPANCIES, payload };
};

const toggleTripSummaryVisibilityAction = (
    payload: TOGGLE_TRIP_SUMMARY_VISIBILITY['payload'],
): TOGGLE_TRIP_SUMMARY_VISIBILITY => {
    return {
        type: MANAGE_BOOKING_ACTION.TOGGLE_TRIP_SUMMARY_VISIBILITY,
        payload,
    };
};

const updateTripSummarySectionsAction = (
    payload?: UPDATE_TRIP_SUMMARY_SECTIONS['payload'],
): UPDATE_TRIP_SUMMARY_SECTIONS => {
    return {
        type: MANAGE_BOOKING_ACTION.UPDATE_TRIP_SUMMARY_SECTIONS,
        payload,
    };
};

const setInsuranceAdjustmentAction = (
    payload: SET_INSURANCE_ADJUSTMENT['payload'],
): SET_INSURANCE_ADJUSTMENT => {
    return {
        type: MANAGE_BOOKING_ACTION.SET_INSURANCE_ADJUSTMENT,
        payload,
    };
};

const toggleConfirmationModalAction = (
    payload: TOGGLE_CONFIRMATION_MODAL['payload'],
): TOGGLE_CONFIRMATION_MODAL => {
    return {
        type: MANAGE_BOOKING_ACTION.TOGGLE_CONFIRMATION_MODAL,
        payload,
    };
};

const setConfirmationDataAction = (
    payload: SET_CONFIRMATION_DATA['payload'],
): SET_CONFIRMATION_DATA => {
    return { type: MANAGE_BOOKING_ACTION.SET_CONFIRMATION_DATA, payload };
};

const setTripSummaryIsLoadingAction = (
    payload: SET_TRIP_SUMMARY_IS_LOADING['payload'],
): SET_TRIP_SUMMARY_IS_LOADING => {
    return { type: MANAGE_BOOKING_ACTION.SET_TRIP_SUMMARY_IS_LOADING, payload };
};

const setBookingPaymentDataAction = (
    payload: SET_BOOKING_PAYMENT_DATA['payload'],
): SET_BOOKING_PAYMENT_DATA => {
    return { type: MANAGE_BOOKING_ACTION.SET_BOOKING_PAYMENT_DATA, payload };
};

const setCancelInsuranceAddOnIdAction = (
    payload?: SET_CANCEL_INSURANCE_ADDON_ID['payload'],
): SET_CANCEL_INSURANCE_ADDON_ID => {
    return {
        type: MANAGE_BOOKING_ACTION.SET_CANCEL_INSURANCE_ADDON_ID,
        payload: payload || { addOnId: undefined },
    };
};

const cancelInsuranceAction = (): CANCEL_INSURANCE => {
    return { type: MANAGE_BOOKING_ACTION.CANCEL_INSURANCE };
};

const setInsuranceDataAction = (
    payload: SET_INSURANCE_DATA['payload'],
): SET_INSURANCE_DATA => {
    return {
        type: MANAGE_BOOKING_ACTION.SET_INSURANCE_DATA,
        payload,
    };
};

const verifyServiceInventoryAction = (): VERIFY_SERVICE_INVENTORY => ({
    type: MANAGE_BOOKING_ACTION.VERIFY_SERVICE_INVENTORY,
});

const setSingleSupplementInventoryAction = (
    payload: SET_SINGLE_SUPPLEMENT_INVENTORY['payload'],
): SET_SINGLE_SUPPLEMENT_INVENTORY => {
    return {
        type: MANAGE_BOOKING_ACTION.SET_SINGLE_SUPPLEMENT_INVENTORY,
        payload,
    };
};

const setIsManageBookingFlowFinishedAction =
    (): SET_IS_MANAGE_BOOKING_FLOW_FINISHED => {
        return {
            type: MANAGE_BOOKING_ACTION.SET_IS_MANAGE_BOOKING_FLOW_FINISHED,
        };
    };

const removePreviousPreAndPostConfig = (
    params: UpdateRoomSelectionComposedParams,
): void => {
    const {
        dispatch,
        payload: {
            preAndPostRoomSelection,
            timingsToUpdate,
            transfersTimingsToUpdate,
            isUpgradeToPrivateRoomSelected,
            roomSelection,
        },
    } = params;
    timingsToUpdate.forEach((timing) => {
        updateAccommodationsComposedAction({
            dispatch,
            payload: {
                actionType: ToggleActionType.REMOVE,
                timing,
                transfersTimingsToUpdate,
                roomSelection: isUpgradeToPrivateRoomSelected
                    ? roomSelection
                    : preAndPostRoomSelection,
                manageMode: 'secondarySideEffect',
            },
        });
    });
};

const updateAddOnsWithRoomSelection = (
    params: UpdateRoomSelectionComposedParams,
): void => {
    const {
        dispatch,
        payload: {
            isUpgradeToPrivateRoomSelected,
            preAndPostRoomSelection,
            currentPreAndPostRoomSelection,
            timingsToUpdate,
            transfersTimingsToUpdate,
            accommodationsWereOriginallyAvailable,
        },
    } = params;

    timingsToUpdate.forEach((timing) => {
        updateAccommodationsComposedAction({
            dispatch,
            payload: {
                actionType: ToggleActionType.ADD,
                timing,
                transfersTimingsToUpdate,
                roomSelection: isUpgradeToPrivateRoomSelected
                    ? preAndPostRoomSelection
                    : currentPreAndPostRoomSelection,
                isUpgradeToPrivateRoomSelected,
                manageMode: !accommodationsWereOriginallyAvailable[timing]
                    ? 'secondarySideEffect'
                    : 'default',
            },
        });
    });
};

const updatePreAndPostAccommodations = (
    params: UpdateRoomSelectionComposedParams,
): void => {
    removePreviousPreAndPostConfig(params);
    updateAddOnsWithRoomSelection(params);
};

const updateRoomSelectionComposedAction = (
    params: UpdateRoomSelectionComposedParams,
): void => {
    const { dispatch, payload } = params;

    dispatch(updateRoomOccupanciesAction(payload));
    updatePreAndPostAccommodations(params);
    dispatch(updateTripSummarySectionsAction());
};

type UpdateAccommodationsComposedParams = {
    dispatch: Dispatch<ManageBookingActions>;
    payload: TOGGLE_ACCOMMODATION['payload'];
};

const updateAccommodationsComposedAction = (
    params: UpdateAccommodationsComposedParams,
): void => {
    const { payload, dispatch } = params;

    const { manageMode, transfersTimingsToUpdate, timing } = payload;

    dispatch(toggleAccommodationAction(payload));

    const matchingTiming = (transfersTimingsToUpdate || []).includes(timing);

    if (matchingTiming) {
        dispatch(
            toggleTransferAction({
                ...payload,
                manageMode: manageMode || 'sideEffect',
            }),
        );
    }
};

const requoteInsurance = async ({
    bookingId,
    insuranceParams: { additionalParticipants, travelAmount, status },
    addOns,
}: RepriceInsurancePayload): Promise<
    Pick<BookingRepriceQuote, 'insuranceChange' | 'repricedAddOns'>
> => {
    const response = await repriceInsurance(
        bookingId,
        travelAmount,
        additionalParticipants,
        addOns,
        status,
        'traveler',
    );

    if (!response.success) {
        throw new Error(response.error ?? 'Repricing insurance error');
    }

    const changes = pick(response.data!, 'insuranceChange', 'repricedAddOns');
    return changes;
};

const processResponseErrorCode = (
    response: UpdateBooking,
    dispatch: Dispatch<ManageBookingActions>,
): void => {
    const { errorCode, error = '' } = response;

    const errorMessage = getErrorMessageToDisplay(error, errorCode, 'portal');

    const isSingleSupplementInventoryError =
        errorCode === SingleSupplementUnavailable.code;

    if (isSingleSupplementInventoryError) {
        const SINGLE_SUPPLEMENT_ERROR_ON_UPDATE = {
            ...SINGLE_SUPPLEMENT_INVENTORY_ERROR,
            title: UPDATE_BOOKING_ERROR?.title,
            description: errorMessage,
        } as ManageBookingError;

        dispatch(
            setConfirmationDataAction({
                error: SINGLE_SUPPLEMENT_ERROR_ON_UPDATE,
            }),
        );
        return;
    }

    dispatch(
        setConfirmationDataAction({
            error: UPDATE_BOOKING_ERROR,
        }),
    );
};

const callUpdateBooking = async (
    params: UpdateBookingPayload,
    dispatch: Dispatch<ManageBookingActions>,
): Promise<void> => {
    const {
        bookingId,
        onSuccessNavigateTo = noop,
        onSuccessTrackEvent = noop,
        ...data
    } = params;

    const response = await updateBooking(bookingId, {
        addOns: data.addOns,
        travelersRooms: data.travelersRooms,
        payment: data.payment,
    });

    if (response.error) {
        const { errorCode } = response;

        if (errorCode) {
            return processResponseErrorCode(response, dispatch);
        }

        dispatch(
            setConfirmationDataAction({
                isDisabled: true,
                error: UPDATE_BOOKING_ERROR,
            }),
        );
        return;
    }

    const getLastPaymentAmount = (
        payments: SavedBooking['payments'],
    ): number => {
        if (!payments?.length) return 0;

        const lastPayment = payments[payments.length - 1];
        return lastPayment.amount;
    };

    dispatch(
        setBookingPaymentDataAction({
            paidAmount: getLastPaymentAmount(response?.data?.payments),
            dueAmount: response?.data?.dueAmount,
        }),
    );
    dispatch(setIsManageBookingFlowFinishedAction());

    onSuccessTrackEvent();
    onSuccessNavigateTo('success');
};

const setVerifySingleSupplementInventoryErrorAction = (
    dispatch: Dispatch<ManageBookingActions>,
): void => {
    dispatch(
        setConfirmationDataAction({
            isDisabled: false,
            error: SINGLE_SUPPLEMENT_INVENTORY_ERROR,
        }),
    );
    dispatch(setTripSummaryIsLoadingAction(false));
};

const actionCreator = (
    dispatch: Dispatch<ManageBookingActions>,
): ActionCreatorReturnType => ({
    initViewModel: (payload: INIT_VIEW_MODEL['payload']): INIT_VIEW_MODEL =>
        dispatch(initViewModelAction(payload)),

    setIsLoading: (payload: SET_IS_LOADING['payload']): SET_IS_LOADING =>
        dispatch(setIsLoadingAction(payload)),

    openTripSummary: (): TOGGLE_TRIP_SUMMARY_VISIBILITY =>
        dispatch(toggleTripSummaryVisibilityAction(true)),

    closeTripSummary: (): TOGGLE_TRIP_SUMMARY_VISIBILITY =>
        dispatch(toggleTripSummaryVisibilityAction(false)),

    clearTripSummary: (): void => {
        dispatch(resetStateAction());
        dispatch(
            setConfirmationDataAction({
                isDisabled: false,
                error: null,
            }),
        );
    },

    updateActivity: (payload: TOGGLE_ACTIVITY['payload']): void => {
        dispatch(toggleActivityAction(payload));
        dispatch(updateTripSummarySectionsAction());
    },

    updateAccommodation: (payload: TOGGLE_ACCOMMODATION['payload']): void => {
        updateAccommodationsComposedAction({ dispatch, payload });
        dispatch(updateTripSummarySectionsAction());
    },

    updateRoomSelection: (
        payload: UPDATE_ROOM_OCCUPANCIES['payload'],
    ): void => {
        updateRoomSelectionComposedAction({ dispatch, payload });
    },

    updateTransfer: (payload: TOGGLE_TRANSFER['payload']): void => {
        dispatch(toggleTransferAction(payload));
        dispatch(updateTripSummarySectionsAction());
    },

    resetRoomSelection: (payload: ResetRoomSelectionPayload): void => {
        const { originalState } = payload;

        if (!originalState) return;

        const resetPayload =
            getUpdateRoomOccupancyPayloadOnReset(originalState);

        updateRoomSelectionComposedAction({ dispatch, payload: resetPayload });
    },

    openConfirmationModal: (): void => {
        dispatch(toggleConfirmationModalAction(true));
    },

    closeConfirmationModal: (): void => {
        dispatch(toggleConfirmationModalAction(false));
    },

    processBookingUpdate: async (
        params: UpdateBookingPayload,
    ): Promise<void> => {
        dispatch(setTripSummaryIsLoadingAction(true));

        await callUpdateBooking(params, dispatch);

        dispatch(setTripSummaryIsLoadingAction(false));
    },

    processConfirmBooking: async (
        payload: ConfirmBookingPayload,
    ): Promise<void> => {
        dispatch(setTripSummaryIsLoadingAction(true));

        const {
            insuranceParams,
            bookingId,
            addOnsToRequoteInsurance,
            singleSupplementAvailability: {
                isPrivateRoomUpgradeAvailable,
                isPrivateRoomAvailable,
            },
            isSingleSupplementPendingAddition,
        } = payload;

        const bookingHasInsurance = insuranceParams !== null;

        let updatedPayload = payload;

        const shouldShowSingleSupplementInventoryError =
            isSingleSupplementPendingAddition &&
            (!isPrivateRoomUpgradeAvailable || !isPrivateRoomAvailable);

        if (shouldShowSingleSupplementInventoryError) {
            setVerifySingleSupplementInventoryErrorAction(dispatch);
        }

        if (bookingHasInsurance) {
            try {
                const changes = await requoteInsurance({
                    insuranceParams,
                    bookingId,
                    addOns: addOnsToRequoteInsurance,
                } as RepriceInsurancePayload);

                updatedPayload = {
                    ...payload,
                    addOns: repriceInsuranceAddOns(
                        payload.addOns,
                        changes.repricedAddOns,
                    ),
                };

                dispatch(
                    setInsuranceAdjustmentAction({
                        insuranceAdjustment: {
                            description: 'Insurance price update',
                            price: changes.insuranceChange,
                        },
                        repricedAddOns: changes.repricedAddOns,
                    }),
                );
                dispatch(updateTripSummarySectionsAction());
            } catch (e) {
                dispatch(
                    setConfirmationDataAction({
                        isDisabled: true,
                        error: INSURANCE_API_ERROR,
                    }),
                );
                dispatch(setTripSummaryIsLoadingAction(false));
                return;
            }
        }

        const { confirmationMode, onSuccessNavigateTo = noop } = payload;
        const shouldConfirmWithNoPayment = !!confirmationMode;

        if (shouldConfirmWithNoPayment) {
            await callUpdateBooking(updatedPayload, dispatch);

            dispatch(setTripSummaryIsLoadingAction(false));
            return;
        }

        onSuccessNavigateTo('payment');
        dispatch(setTripSummaryIsLoadingAction(false));
    },

    setBookingPaymentData: (
        payload: SET_BOOKING_PAYMENT_DATA['payload'],
    ): SET_BOOKING_PAYMENT_DATA =>
        dispatch(setBookingPaymentDataAction(payload)),

    setIsManageBookingFlowFinished: (): SET_IS_MANAGE_BOOKING_FLOW_FINISHED =>
        dispatch(setIsManageBookingFlowFinishedAction()),

    openCancelInsuranceModal: (
        payload: SetCancelPopupOpenedIdPayload,
    ): void => {
        const { addOnId } = payload;
        dispatch(setCancelInsuranceAddOnIdAction({ addOnId }));
    },

    closeCancelInsuranceModal: (): void => {
        dispatch(setCancelInsuranceAddOnIdAction());
    },

    cancelInsurance: async (payload: CancelInsurancePayload): Promise<void> => {
        dispatch(cancelInsuranceAction());

        const { bookingId, addOnId } = payload;

        if (!bookingId || !addOnId) return;

        const response = await cancelAddOn(bookingId, addOnId);

        if (!response.success) {
            dispatch(
                setInsuranceDataAction({
                    status: 'error',
                    error: {
                        title: 'Something went wrong.',
                        description:
                            'We had trouble cancelling your insurance.',
                    },
                }),
            );
            return;
        }

        dispatch(
            setInsuranceDataAction({
                status: 'cancelled',
            }),
        );

        dispatch(setCancelInsuranceAddOnIdAction());
    },

    resetInsuranceState: (): void => {
        dispatch(
            setInsuranceDataAction({
                status: 'initial',
                error: undefined,
            }),
        );
    },

    verifySingleSupplementInventory: async (
        payload: VerifySingleSupplementInventoryPayload,
    ): Promise<void> => {
        const { state } = payload;

        if (!state) return;

        const {
            addOns: {
                accommodations: { singleSupplement },
            },
            travelersQuantity,
            currentTripId,
        } = state;

        dispatch(verifyServiceInventoryAction());

        if (!currentTripId) return;

        const response = await getUserTripInventory(currentTripId);

        if (!response.success) {
            dispatch(
                setSingleSupplementInventoryAction({
                    singleSupplement: { available: 0 },
                }),
            );
            return;
        }

        dispatch(
            setSingleSupplementInventoryAction({
                singleSupplement: response.data.singleSupplement,
            }),
        );

        if (!singleSupplement) return;

        const { isPendingAdditionAddOn: isSingleSupplementPendingAddition } =
            getAddOnStatusAndType(singleSupplement);

        const isAvailablePrivateRoomUpgrade = isPrivateRoomUpgradeAvailable(
            response.data.singleSupplement.available,
            travelersQuantity,
        );

        if (
            !isAvailablePrivateRoomUpgrade &&
            isSingleSupplementPendingAddition
        ) {
            setVerifySingleSupplementInventoryErrorAction(dispatch);
        }
    },

    resetManageBookingState: (): void => {
        dispatch(resetStateAction());
    },
});

export default actionCreator;
