import { useState, useEffect, SyntheticEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import TravelersTable from './TravelersTable';
import WaitlistedTable from './WaitlistedTable';
import CancelBookingsDialog, {
    BookingsStepConfirmationDialogProps,
} from '../BookingsDialog/BookingsStepConfirmationDialog';
import ConfirmBookingsDialog from '../BookingsDialog/BookingsStepConfirmationDialog';
import DialogTable from '../BookingsDialog/DialogTable';
import Snackbar from '../../../../components/Snackbar/Snackbar';
import { getAgeFromBirthday } from '../../../../util/getAgeFromBirthday';
import { filterBookingOrdersByStatus } from '../../../../util/bookingUtils';
import { userTrips, userTripDetails } from '../../../../state/userTrips';
import {
    cancelMultipleBookings,
    confirmMultipleBookings,
} from '../../../../state/bookings';
import {
    BookingStatuses,
    BookingStatusesNames,
} from '../../../../config/constants';
import { models, constants } from '@trova-trip/trova-models';
import { useTrackingEvent } from '../../../../analytics/hooks';
import { coreUtils } from '@trova-trip/trova-common';

type storeSelector = {
    userTrips: { current: models.trips.Trip };
    userTripDetails: { current: models.tripDetails.TripDetails };
};

interface FormattedTravelerInfo {
    completeName: string;
    lastName: string;
    age: string;
    email: string;
    gender: string;
    instagram: string;
    location: string;
    paidInFull: string;
}

interface OrderTrackInfo {
    orderId: string;
    numberOfTravelers: number;
    travelerEmails: string[];
}

export interface FormattedBookingOrder {
    orderId: string;
    status: string;
    statusName: string;
    totalSpotsBooked: number;
    travelers: FormattedTravelerInfo[];
    pendingCancellation: boolean;
}

interface theRestDialogProps
    extends Pick<
        BookingsStepConfirmationDialogProps,
        'title' | 'subtitle' | 'confirmStepButtonName'
    > {}

type DialogPropParams = {
    actionTitle: string;
    subtitle?: string;
};

const getFormattedTableData = (
    ordersList: models.tripDetails.BookingOrder[],
): FormattedBookingOrder[] => {
    const formattedData: FormattedBookingOrder[] = [];

    ordersList?.forEach((order) => {
        const {
            traveler,
            orderId,
            status = '',
            totalSpotsBooked,
            pendingCancellation,
            totalAmount = 0,
            paidAmount,
            dueAmount = 0,
        } = order;

        const orderIndex = formattedData.findIndex(
            (formattedOrder) => formattedOrder.orderId === orderId,
        );

        const isPendingRefund = dueAmount < 0;
        const isPaidInFull = totalAmount > 0 && paidAmount === totalAmount;
        const isUnpaid = !paidAmount || paidAmount === 0;

        const paymentStatus =
            isPaidInFull || isPendingRefund
                ? 'Paid'
                : isUnpaid
                ? 'Unpaid'
                : 'Partial';

        let travelerAge;
        if (traveler.dateOfBirth) {
            travelerAge = getAgeFromBirthday(new Date(traveler.dateOfBirth));
        }
        const formattedTraveler = {
            completeName: `${traveler.firstName} ${traveler.lastName}`,
            lastName: traveler.lastName,
            age: travelerAge,
            email: traveler.email,
            gender: traveler.gender?.toLowerCase() || '',
            instagram: traveler.instagramHandle,
            location: traveler.countryCode?.toUpperCase() || '',
            paidInFull: paymentStatus,
        };

        if (orderIndex < 0) {
            const formattedOrder = {
                orderId: orderId || '',
                statusName: pendingCancellation
                    ? `Pending Cancel`
                    : BookingStatusesNames[status],
                status,
                pendingCancellation,
                totalSpotsBooked,
                travelers: [formattedTraveler],
            };

            formattedData.push(formattedOrder);
        } else {
            formattedData[orderIndex].travelers.push(formattedTraveler);
        }
    });
    return formattedData;
};

const TravelerTableStatuses = new Set([
    BookingStatuses.PENDING,
    BookingStatuses.CONFIRMED,
]);

const WaitlistedTableStatuses = new Set([
    BookingStatuses.WAITLIST,
    BookingStatuses.AWAITING,
]);

const getOrderConfirmationEventTrackInfo = (
    confirmedOrderIds: string[],
    travelerDetails: models.tripDetails.BookingOrder[],
): OrderTrackInfo[] => {
    const orderConfirmationeEventTrackInfo = confirmedOrderIds.map(
        (orderId) => {
            const orderTravelers = travelerDetails.filter(
                (traveler) => traveler?.orderId === orderId,
            );
            const travelerEmails = orderTravelers.map(
                (traveler) => traveler?.traveler?.email,
            );
            return {
                orderId,
                numberOfTravelers: orderTravelers.length,
                travelerEmails,
            };
        },
    );
    return orderConfirmationeEventTrackInfo;
};

const launchAndConfirmProps = {
    actionTitle: `Launch and Confirm `,
    subtitle: `Trips must launch before confirming. To continue confirming your trip, the launch date will 
    automatically be updated to the current time (now). Any previously scheduled launch promotions will be 
     sent to your audience.`,
};

const BookingsTables = (): JSX.Element => {
    const [isBookingAutoConfirmation, setIsBookingAutoConfirmation] =
        useState(false);
    const [snackBar, setSnackBar] = useState({
        message: '',
        color: 'info',
        show: false,
    });
    const [isOpenConfirmBookingsDialog, setIsOpenConfirmBookingsDialog] =
        useState(false);
    const [isOpenCancelBookingsDialog, setIsOpenCancelBookingsDialog] =
        useState(false);
    const [canceledOrders, setCanceledOrders] = useState<string[]>([]);
    const [confirmedOrders, setConfirmedOrders] = useState<string[]>([]);

    const {
        userTrips: { current: currentTrip },
        userTripDetails: { current: currentTripDetails },
    } = useSelector((state: storeSelector) => state);

    const [tripDetails, setTripDetails] =
        useState<models.tripDetails.TripDetails>(currentTripDetails);

    const { getRecord: getTripDetails } =
        userTripDetails.useDispatch.bind(currentTripDetails)();
    const { updateRecord } = userTrips.useDispatch.bind(currentTrip)();

    const { trackUserEvent } = useTrackingEvent();

    useEffect(() => {
        setTripDetails(currentTripDetails);
    }, [setTripDetails, currentTripDetails]);

    useEffect(() => {
        if (currentTrip.bookingAutoConfirmation) {
            setIsBookingAutoConfirmation(true);
        }
    }, [currentTrip, setIsBookingAutoConfirmation]);

    const isEarlyLaunchAvailable = useCallback((): boolean => {
        const isEarlyLaunchAvailable =
            currentTrip?.status ===
                constants.trips.TRIP_STATUS.READY_TO_CONFIRM &&
            currentTrip?.launchDate &&
            !coreUtils.tripUtils.hasTripLaunched(currentTrip);
        return !!isEarlyLaunchAvailable;
    }, [currentTrip]);

    const travelersTableData =
        getFormattedTableData(
            filterBookingOrdersByStatus(
                tripDetails?.travelers || [],
                TravelerTableStatuses,
            ),
        ) || [];

    const waitlistedTableData =
        getFormattedTableData(
            filterBookingOrdersByStatus(
                tripDetails?.travelers || [],
                WaitlistedTableStatuses,
            ),
        ) || [];

    const getDialogTableData = (orders: string[]): FormattedBookingOrder[] => {
        return getFormattedTableData(
            tripDetails?.travelers?.filter((item) => {
                return orders.some((item2) => item2 === item.orderId);
            }) || [],
        );
    };

    const getTheRestDialogProps = ({
        actionTitle,
        subtitle,
    }: DialogPropParams): theRestDialogProps => ({
        title: `${actionTitle} Traveler(s)`,
        subtitle:
            subtitle ||
            `Are you sure that you want to ${actionTitle.toLowerCase()} these traveler(s)? `,
        confirmStepButtonName: `${actionTitle} Selection`,
    });

    const getConfirmDialogProps = (): theRestDialogProps => {
        const dialogProps = isEarlyLaunchAvailable()
            ? launchAndConfirmProps
            : { actionTitle: 'Confirm' };

        return getTheRestDialogProps(dialogProps);
    };

    const confirmBookings = async (
        ordersId: string[],
        tripId: string,
    ): Promise<void> => {
        const response = await confirmMultipleBookings(tripId, ordersId);

        if (response.success) {
            getTripDetails(tripId);
            setSnackBar({
                message: 'The bookings were confirmed!',
                color: 'success',
                show: true,
            });
        } else {
            setSnackBar({
                message: response.error,
                color: 'danger',
                show: true,
            });
        }
        trackUserEvent({
            eventName: 'Host Confirmed Traveler(s)',
            properties: {
                tripId: currentTrip?.id,
                confirmedOrders: getOrderConfirmationEventTrackInfo(
                    ordersId,
                    currentTripDetails.travelers || [],
                ),
            },
        });
    };

    const cancelBookings = async (
        ordersId: string[],
        tripId: string,
    ): Promise<void> => {
        const response = await cancelMultipleBookings(tripId, ordersId);

        if (response.success) {
            getTripDetails(tripId);
            setSnackBar({
                message: 'The bookings were cancelled',
                color: 'success',
                show: true,
            });
        } else {
            setSnackBar({
                message: response.error,
                color: 'danger',
                show: true,
            });
        }
    };

    const handleConfirmBookings = (
        orders: string[],
        resetSelectedOrders: () => void,
    ): void => {
        setConfirmedOrders(orders);
        setIsOpenConfirmBookingsDialog(true);
        resetSelectedOrders();
    };

    const handleCancelBookings = (
        orders: string[],
        resetSelectedOrders: () => void,
    ): void => {
        setCanceledOrders(orders);
        setIsOpenCancelBookingsDialog(true);
        resetSelectedOrders();
    };

    const handleAutoConfirmToggleChange = (event: SyntheticEvent): void => {
        const toggleValue = (event.target as HTMLInputElement).checked;
        updateRecord(
            tripDetails?.id,
            {
                bookingAutoConfirmation: toggleValue,
            },
            {
                successCallback: (): void => {
                    setIsBookingAutoConfirmation(toggleValue);
                    trackUserEvent({
                        eventName: toggleValue
                            ? 'Host Enabled Auto-Confirm'
                            : 'Host Disabled Auto-Confirm',
                        properties: {
                            tripId: currentTrip?.id,
                        },
                    });
                },
                errorCallback: (error: string): void => {
                    setSnackBar({
                        message: error,
                        color: 'danger',
                        show: true,
                    });
                },
            },
        );
    };

    return (
        <div>
            <TravelersTable
                data={travelersTableData}
                bookingAutoConfirmation={isBookingAutoConfirmation}
                onAutoConfirmationToggleChange={handleAutoConfirmToggleChange}
                onConfirmBookings={handleConfirmBookings}
                onCancelBookings={handleCancelBookings}
            />

            <WaitlistedTable
                data={waitlistedTableData}
                onCancelBookings={handleCancelBookings}
            />

            <CancelBookingsDialog
                {...getTheRestDialogProps({ actionTitle: 'Reject' })}
                open={isOpenCancelBookingsDialog}
                onConfirmStep={(): void => {
                    tripDetails.id &&
                        cancelBookings(canceledOrders, tripDetails.id);
                    setIsOpenCancelBookingsDialog(false);
                }}
                onClose={(): void => {
                    setIsOpenCancelBookingsDialog(false);
                }}
            >
                <DialogTable data={getDialogTableData(canceledOrders)} />
            </CancelBookingsDialog>
            <ConfirmBookingsDialog
                {...getConfirmDialogProps()}
                open={isOpenConfirmBookingsDialog}
                onConfirmStep={(): void => {
                    tripDetails.id &&
                        confirmBookings(confirmedOrders, tripDetails.id);
                    setIsOpenConfirmBookingsDialog(false);
                }}
                onClose={(): void => {
                    setIsOpenConfirmBookingsDialog(false);
                }}
            >
                <DialogTable data={getDialogTableData(confirmedOrders)} />
            </ConfirmBookingsDialog>

            <Snackbar
                place='tr'
                color={snackBar.color}
                message={snackBar.message}
                open={snackBar.show}
                autoHideDuration={4000}
                onClose={(): void => {
                    setSnackBar({
                        message: '',
                        color: 'info',
                        show: false,
                    });
                }}
            />
        </div>
    );
};

export default BookingsTables;
