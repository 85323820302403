import { constants } from '@trova-trip/trova-models';
import { coreUtils } from '@trova-trip/trova-common';
import { Stack } from '@trova-trip/trova-components';
import PrivateRoomUpgrade from '../PrivateRoomUpgrade';
import RoomSelection from '../RoomSelection';
import { useManageBooking } from '../../../../hooks';
import { isSingleSupplementInventoryError } from '../../../../../../../../state/features/manageBooking/utils';

export const { ServiceTiming, Occupancy } = constants.services;
type OccupancyType = coreUtils.bookingUtils.OccupancyType;

interface RoomOptionsProps {
    singleSupplementUnitPrice?: number;
    isUpgradeToPrivateRoomSelected: boolean;
    roomsOccupancies: OccupancyType[];
    travelersQuantity: number;
    tripDates: string;
}

const RoomOptions = ({
    singleSupplementUnitPrice,
    isUpgradeToPrivateRoomSelected,
    roomsOccupancies,
    travelersQuantity,
    tripDates,
}: RoomOptionsProps): JSX.Element => {
    const {
        state: {
            serviceAvailability: {
                singleSupplement: { isPrivateRoomUpgradeAvailable },
            },
            confirmation: { error },
        },
    } = useManageBooking();

    /**
     * We need to show the private room upgrade on single supplement inventory error
     * to allow the user to remove the private room upgrade.
     */
    const showPrivateRoomUpgrade =
        travelersQuantity !== 1 &&
        singleSupplementUnitPrice &&
        (isPrivateRoomUpgradeAvailable ||
            isSingleSupplementInventoryError(error));

    return (
        <Stack direction='column' spacing={6} align='stretch'>
            {showPrivateRoomUpgrade ? (
                <PrivateRoomUpgrade
                    price={singleSupplementUnitPrice * travelersQuantity}
                    tripDates={tripDates}
                />
            ) : null}

            {!isUpgradeToPrivateRoomSelected ? (
                <RoomSelection
                    roomsOccupancies={roomsOccupancies}
                    travelersQuantity={travelersQuantity}
                    singleSupplementUnitPrice={singleSupplementUnitPrice}
                />
            ) : null}
        </Stack>
    );
};

export default RoomOptions;
