import {
    Image,
    Stack,
    Text,
    Heading,
    Grid,
} from '@trova-trip/trova-components';
import { Dialog, Button } from '@trova-trip/trova-components/build/next';
import exportingDataImage from '../../../../../../../../assets/img/audience-summary/exporting-data.svg';

const responseSuccessCopy = {
    header: 'We are exporting your data!',
    description:
        'You will receive an email with a link to download your audience data shortly.',
    primaryButton: {
        text: 'Close',
    },
};

interface SuccessContentProps {
    onClickClose: () => void;
}

const SuccessContent = ({ onClickClose }: SuccessContentProps): JSX.Element => {
    const { header, description, primaryButton } = responseSuccessCopy;
    return (
        <>
            <Dialog.Body>
                <Grid gap={6}>
                    <Grid.Item
                        columnSpan={12}
                        justifySelf='center'
                        width={{ base: 52, md: 72 }}
                        height={{ base: 56, md: 80 }}
                    >
                        <Image
                            src={exportingDataImage}
                            alt=''
                            width={{ base: 52, md: 72 }}
                            height={{ base: 56, md: 80 }}
                            marginTop={4}
                            marginBottom={6}
                            data-gumlet='false'
                        />
                    </Grid.Item>
                    <Grid.Item columnSpan={12}>
                        <Heading as='h5' paddingTop={14}>
                            {header}
                        </Heading>
                        <Text as='p'>{description}</Text>
                    </Grid.Item>
                </Grid>
            </Dialog.Body>
            <Dialog.Footer>
                <Stack
                    justify={{ base: 'center', md: 'flex-end' }}
                    align='flex-end'
                    width='full'
                    paddingX={2}
                    paddingTop={{ base: 24, md: 24 }}
                    wrap='nowrap'
                >
                    <Button
                        paddingX={{ base: 36, md: 4 }}
                        paddingY={2}
                        onClick={onClickClose}
                    >
                        {primaryButton.text}
                    </Button>
                </Stack>
            </Dialog.Footer>
        </>
    );
};

export default SuccessContent;
