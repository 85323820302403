import React from 'react';
import {
    BaseBox,
    BaseBoxProps,
    Collapse,
    Heading,
    Stack,
    Overlay,
    useReveal,
} from '@trova-trip/trova-components';
import { Icon, IconProps } from '@trova-trip/trova-components/build/next';

export interface CatalogMobileBannerProps {
    containerProps?: Omit<BaseBoxProps, 'children'>;
    children: React.ReactNode;
    iconProps?: IconProps;
    title?: string;
    onBannerClick?: (isContentExpanded: boolean) => void;
}

const CatalogMobileBanner = ({
    containerProps,
    children,
    iconProps,
    title,
    onBannerClick,
}: CatalogMobileBannerProps): JSX.Element => {
    const {
        isOpen: isContentExpanded,
        triggerOpen: expandContent,
        triggerClose: closeContent,
    } = useReveal();

    const toggleCollapse = () => {
        isContentExpanded ? closeContent() : expandContent();
        onBannerClick && onBannerClick(isContentExpanded);
    };

    return (
        <>
            <BaseBox
                position='fixed'
                width='100%'
                bottom='0'
                left='0'
                backgroundColor='neutral.white'
                zIndex={1250}
                borderBottom='1px'
                borderColor='blueGray.200'
                boxShadow='5'
                {...containerProps}
            >
                <BaseBox onClick={toggleCollapse} cursor='pointer'>
                    <Stack spacing={2} padding={4} align='center'>
                        {iconProps ? <Icon {...iconProps} /> : null}
                        <BaseBox flex='1 1 auto'>
                            <Heading
                                as='h6'
                                fontWeight='regular'
                                color='blueGray.650'
                            >
                                {title}
                            </Heading>
                        </BaseBox>
                        <Icon
                            display='flex'
                            as={isContentExpanded ? 'arrow-down' : 'arrow-up'}
                            size='lg'
                            color='blueGray.900'
                        />
                    </Stack>
                </BaseBox>
                <Collapse isOpen={isContentExpanded}>{children}</Collapse>
            </BaseBox>
            {isContentExpanded ? (
                <Overlay zIndex={1200} onClick={closeContent} />
            ) : null}
        </>
    );
};

export default CatalogMobileBanner;
