import { getErrorMessageToDisplay } from './utils/errors';
import FetchState from './utils/FetchState';
import { get, post, put } from './utils/api';

const bookings = FetchState.create(`api/admin/bookings`, false);

bookings.setCustomPreprocessor((response) => {
    if (!response.success) {
        const errorMessage = getErrorMessageToDisplay(
            response.error,
            response.errorCode,
        );
        throw new Error(errorMessage);
    }

    return response.data;
});

const bookingsMetadata = FetchState.create(`api/metadata/bookings`);

const ME_TRIP_DETAILS_PATH = 'api/me/trip-details';
const ME_BOOKINGS_PATH = 'api/me/bookings';

const bookingsReducer = bookings.getReducer();
const bookingsMetadataReducer = bookingsMetadata.getReducer();

const cancelBooking = (bookingId, cancellationDetails) =>
    post(`${ME_BOOKINGS_PATH}/${bookingId}/cancel`, {
        cancellationDetails,
    });

const cancelMultipleBookings = (tripId, ordersId) => {
    const data = {
        orderIds: [...ordersId],
    };

    return put(
        `${ME_TRIP_DETAILS_PATH}/${tripId}/cancelBookings`,
        undefined,
        data,
    );
};

const confirmMultipleBookings = (tripId, ordersId) => {
    const data = {
        orderIds: [...ordersId],
    };

    return put(
        `${ME_TRIP_DETAILS_PATH}/${tripId}/confirmBookings`,
        undefined,
        data,
    );
};

const refundPayment = async (bookingId, paymentId, affectedItems) =>
    put(
        `/api/admin/bookings/${bookingId}/payments/${paymentId}/refund`,
        undefined,
        {
            affectedItems,
        },
    );

const calculateDiscount = async (bookingId, code) =>
    get(`/api/admin/bookings/${bookingId}/discount/calculate`, { code });

const applyLateDiscount = async (bookingId, code) =>
    post(`/api/admin/bookings/${bookingId}/discount/apply`, undefined, {
        code,
    });

const removeDiscount = async (bookingId, code) =>
    post(`/api/admin/bookings/${bookingId}/discount/remove`, undefined, {
        code,
    });

export {
    applyLateDiscount,
    bookings,
    bookingsMetadata,
    bookingsMetadataReducer,
    bookingsReducer,
    calculateDiscount,
    cancelBooking,
    cancelMultipleBookings,
    confirmMultipleBookings,
    refundPayment,
    removeDiscount,
};
