import { businessConstants } from '@trova-trip/trova-common';
import { models } from '@trova-trip/trova-models';
import { cloneDeep } from 'lodash';
import {
    FormatPreferencesReturn,
    TravelerEditableFields,
    TravelerUiModel,
} from './TripForm.types';
import { formatPassportInfo, formatStringDate } from '../../../common/helpers';

export type TravelerDTO = models.travelers.TravelerDTO;
type UserProfile = models.users.BaseUser;

const { TRAVELER_PREFERENCES } = businessConstants;

export const updateTravelerModelData = (
    data: TravelerUiModel,
): TravelerEditableFields => {
    const {
        disclosuresAndPreferences: { preferences, description },
        passportInformation,
        ...theRestDataFields
    } = data;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { passportRequired, ...theRestPassportInformationFields } =
        passportInformation;

    const formattedPreferences = Object.keys(preferences)
        .map((key) => {
            if (preferences[key]) {
                return TRAVELER_PREFERENCES[key];
            }
        })
        .filter(Boolean);

    return {
        ...theRestDataFields,
        passportInformation: { ...theRestPassportInformationFields },
        disclosuresAndPreferences: {
            description,
            preferences: formattedPreferences,
        },
    };
};

export const getInitialValues = (
    travelerData?: TravelerDTO,
    isPassportRequired?: boolean,
    profile?: UserProfile,
): TravelerUiModel => {
    const {
        flightInformation,
        passportInformation,
        disclosuresAndPreferences,
        endOfTripPlans,
        emergencyContact,
        specialRequests,
        travelInsurance,
    } = travelerData || {};

    const { arrivalDetails, departureDetails } = flightInformation || {};

    const formatPreferences = (): FormatPreferencesReturn => {
        const clonedDisclosuresAndPreferences = cloneDeep(
            disclosuresAndPreferences,
        );
        const formattedPreferences = {};

        clonedDisclosuresAndPreferences?.preferences?.forEach((preference) => {
            Object.entries(TRAVELER_PREFERENCES).forEach(
                ([preferenceKey, preferenceValue]) => {
                    if (preference === preferenceValue) {
                        formattedPreferences[preferenceKey] = true;
                    }
                },
            );
        });
        return formattedPreferences;
    };

    return {
        flightInformation: {
            arrivalDetails: {
                airline: arrivalDetails?.airline || '',
                flightNumber: arrivalDetails?.flightNumber || '',
                date: formatStringDate(arrivalDetails?.date),
                time: arrivalDetails?.time || '',
            },
            departureDetails: {
                airline: departureDetails?.airline || '',
                flightNumber: departureDetails?.flightNumber || '',
                date: formatStringDate(departureDetails?.date),
                time: departureDetails?.time || '',
            },
        },
        endOfTripPlans: endOfTripPlans || '',
        travelInsurance: {
            carrier: travelInsurance?.carrier || '',
            policyNumber: travelInsurance?.policyNumber || '',
        },
        passportInformation: {
            passportRequired: isPassportRequired || false,
            ...formatPassportInfo(passportInformation, profile),
        },
        specialRequests: {
            description: specialRequests?.description || '',
            roomMate: specialRequests?.roomMate || '',
        },
        emergencyContact: {
            email: emergencyContact?.email || '',
            name: emergencyContact?.name || '',
            phone: emergencyContact?.phone || '',
            relationship: emergencyContact?.relationship || '',
        },
        disclosuresAndPreferences: {
            preferences: formatPreferences(),
            description: disclosuresAndPreferences?.description || '',
        },
    };
};
