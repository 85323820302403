import { css } from '@emotion/css';
import { theme } from '@trova-trip/trova-components';
import { hexToRgb } from '../../../../assets/jss/trova-color';

export const styledFloatingBanner = css`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${theme.colors.blueGray[100]};
    padding: 15px 32px;
    padding-bottom: 24px;
    box-shadow: 0px -2px 10px 2px rgba(${hexToRgb(theme.colors.neutral.black)}, 5%);
`;
