import {
    Heading,
    Text,
    BaseBox,
    Stack,
    BaseBoxProps,
    WithTooltip,
    Spinner,
    theme,
} from '@trova-trip/trova-components';
import { Card, Icon } from '@trova-trip/trova-components/build/next';
import { coreUtils } from '@trova-trip/trova-common';
import { models } from '@trova-trip/trova-models';
import useIsLargeScreen from '../../../common/hooks/useIsLargeScreen';

type SuggestedSellPrice = models.itineraries.SuggestedSellPrice;

interface StickyContentSellPriceProps {
    title: string;
    suggestedPrice?: SuggestedSellPrice;
    isLoading?: boolean;
}

interface PriceViewProps {
    suggestedPrice?: SuggestedSellPrice;
    isLoading?: boolean;
}

const BaseBoxWithTooltip = WithTooltip<BaseBoxProps>(BaseBox);
const TooltipText = (): JSX.Element => (
    <Text as='span' color='blueGray.offWhite' fontSize='sm' lineHeight={5}>
        Price your trip within this range for the highest chance to successfully
        sell your trip. Once your request is approved, you can increase/decrease
        your sell price to meet your earnings goals.
    </Text>
);

const PriceView = ({ isLoading, suggestedPrice }: PriceViewProps) => {
    const { formatUSD } = coreUtils.currencyUtils;

    if (isLoading) {
        return (
            <BaseBox paddingTop={4}>
                <Spinner size='md' />
            </BaseBox>
        );
    } else if (!suggestedPrice) {
        return null;
    }

    return (
        <Text
            fontWeight={{ base: 'bold', md: 'bold' }}
            fontSize={{ base: 'lg', md: '3xl' }}
            textAlign={{ base: 'left', md: 'center' }}
            color='blueGray.800'
        >
            {formatUSD(suggestedPrice.minPrice)} -{' '}
            {formatUSD(suggestedPrice.maxPrice)}
        </Text>
    );
};

const StickyContentSellPrice = ({
    title,
    suggestedPrice,
    isLoading,
}: StickyContentSellPriceProps): JSX.Element => {
    const isMobile = !useIsLargeScreen({ includeTabletResolution: true });
    const tooltipToggleMode = isMobile ? 'click' : 'hover';

    return (
        <Card
            boxShadow={{
                base: 'none',
                md: '4',
            }}
            borderRadius={{
                base: 'none',
                md: 'md',
            }}
            padding={4}
            margin={{
                base: -4,
                md: 0,
            }}
            border={0}
            borderBottom={{
                base: `1px solid ${theme.colors.blueGray['300']}`,
                md: 'none',
            }}
            marginBottom={{
                base: 4,
                md: 0,
            }}
        >
            <Stack
                direction={{
                    base: 'row',
                    md: 'column',
                }}
                align='center'
                justify={{
                    base: 'space-between',
                    md: 'center',
                }}
                spacing={0}
            >
                <Stack
                    direction='row'
                    align='center'
                    justify={{
                        base: 'start',
                        md: 'center',
                    }}
                >
                    <Heading
                        fontSize={{ base: 'sm', md: 'lg' }}
                        color='blueGray.650'
                        textAlign='center'
                        textTransform={{
                            base: 'uppercase',
                            md: 'none',
                        }}
                        fontWeight='medium'
                        as='h4'
                    >
                        {title}
                    </Heading>
                    <BaseBoxWithTooltip
                        tooltipConfigProps={{
                            placement: 'top',
                            tooltip: <TooltipText />,
                            tooltipToggleMode,
                            closeOnEscape: true,
                        }}
                        display='flex'
                        paddingTop={{
                            base: 1,
                            md: 1.5,
                        }}
                    >
                        <Icon
                            as='help'
                            color='blueGray.650'
                            variant='outlined'
                            size='sm'
                        />
                    </BaseBoxWithTooltip>
                </Stack>
                <PriceView
                    isLoading={isLoading}
                    suggestedPrice={suggestedPrice}
                />
            </Stack>
        </Card>
    );
};

export default StickyContentSellPrice;
