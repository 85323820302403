import { useMemo } from 'react';
import { proxyPort } from '../../../package.json';

const useWindowHostName = (): string => {
    const { host: windowHostName } = window.location;
    return useMemo(
        () =>
            windowHostName.includes(`localhost:3000`)
                ? `localhost:${proxyPort}`
                : windowHostName,
        [windowHostName],
    );
};

export const useWindowHostNameAndProtocol = (): string => {
    const hostName = useWindowHostName();
    const protocol = window.location.protocol;
    return `${protocol}//${hostName}`;
};
export default useWindowHostName;
