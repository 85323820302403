import { ComponentType, useEffect } from 'react';

import { useNavigate } from '../../../../../common/hooks/useNavigation';
import { useManageBookingNavigation, useTripSummaryContent } from '../../hooks';

const WithRouteValidation = (
    WrappedComponent: ComponentType,
): ComponentType => {
    const WrappedComponentWithValidation = () => {
        const { pendingChanges } = useTripSummaryContent();

        const { getManageBookingTabPath } = useManageBookingNavigation();

        const manageBooking = getManageBookingTabPath('root');

        const navigateToManageBooking = useNavigate(manageBooking, {
            preserveCurrentPath: false,
        });

        useEffect(() => {
            if (!pendingChanges) {
                return navigateToManageBooking();
            }
        }, []);

        return <WrappedComponent />;
    };

    WrappedComponentWithValidation.displayName = 'WithRouteValidation';

    return WrappedComponentWithValidation;
};

export default WithRouteValidation;
