import { coreUtils } from '@trova-trip/trova-common';
import { DatePicker } from '@trova-trip/trova-components/build/next';
import { constants, models } from '@trova-trip/trova-models';
import {
    addDays,
    isAfter,
    isBefore,
    isSameDay,
    isValid,
    parse,
    startOfDay,
    subDays,
} from 'date-fns';
import { last } from 'lodash';
import moment from 'moment';
import * as yup from 'yup';
import { TIME_FORMATS } from '../../common/helpers';

const { TRIP_STATUS } = constants.trips;

type Trip = models.trips.Trip;
type DisabledDates = Array<[Date, Date]>;
export type LaunchDateObject = {
    launchDate: string;
    launchTime: string;
};

const { formatDateUSShort, formatTime24Short, parseLocalDateTimeToUtc } =
    coreUtils.dateUtils;

const DAYS_UNTIL_LAUNCH_DATE = 3;

export type LaunchDateInitialValues = {
    launchDate: string;
    launchTime: string;
};

/**
 * If the UTCDate is provided it returns a date string and a time string with
 * the format required for the DatePicker and the Time Dropdown. If the UTCDate
 * is not provided it returns an object with the lanchDate and LaunchTime as
 * empty strings.
 * @param {Date | undefined} UTCDate - this date should come directly from the
 *   launchDate trip response. Since it will be used to fill the initial values
 *   of the DatePicker and Time Dropdown.
 * @returns {LaunchDateInitialValues}
 */
export const getLaunchDateInitialValues = (
    UTCDate: Date | undefined,
): LaunchDateInitialValues => {
    const emptyInitialValues: LaunchDateInitialValues = {
        launchDate: '',
        launchTime: '',
    };

    if (!UTCDate) return emptyInitialValues;

    const launchDate = formatDateUSShort(UTCDate);
    const launchTime = formatTime24Short(UTCDate);

    return {
        launchDate,
        launchTime,
    };
};

/**
 * Builds a Date from the given launchDate and splitted launchTime string, adjusted by the timezone offset. If there is an invalid value, the function will return null and log the corresponding error in the console.
 * @param {string} launchDate - date of the trip launch. Its format should be "MM/DD/YYYY".
 * @param {string} launchTime - time of the trip launch. Its format should be "HH:mm".
 * @returns {Date | null}
 */
export const createLaunchDate = (
    launchDate: string,
    launchTime: string,
): Date | null => {
    const [hours, minutes]: string[] = launchTime.split(':');

    if (!hours || !minutes) {
        console.error('The provided launch time was invalid');
        return null;
    }

    const [month, day, year] = launchDate.split('/');
    if (!month || !day || !year) {
        console.error('The provided launch date was invalid');
        return null;
    }

    const dateString = `${year}-${month}-${day}`;
    const localTimeString = dateString + ' ' + launchTime;
    const utc = parseLocalDateTimeToUtc(localTimeString);

    return utc;
};

type PostLaunchDate = { date: string; hour: string };

/** Formats an ISO date into separate Date (DD MMM, YYYY) and Hour (12 HOURS FORMAT) for trip publish date displayed at PostLaunch Info text
 * @param { Date | undefined } publishDate - The ISO Date String to be formatted
 */
export const formatDateForPostLaunchInfo = (
    publishDate: Date | undefined,
): PostLaunchDate | null => {
    if (!publishDate) {
        return null;
    }

    const formattedDate = moment(publishDate).format(TIME_FORMATS.DATE_LONG);
    const formattedHour = moment(publishDate).format(TIME_FORMATS['12_HOURS']);

    return { date: formattedDate, hour: formattedHour };
};

export const shouldDisplayLaunchDateFields = (
    trip: Trip | undefined,
): boolean => {
    if (!trip) return false;
    const { status } = trip;
    return [
        TRIP_STATUS.HOST_APPROVED,
        TRIP_STATUS.EARLY_CONFIRMED,
        TRIP_STATUS.LIVE,
    ].includes(status);
};

export const shouldDisplayLaunchDateForm = (
    trip: Trip | undefined,
): boolean => {
    if (!trip) return false;
    const { status } = trip;

    return ![
        TRIP_STATUS.CREATED,
        TRIP_STATUS.PARTNER_APPROVED,
        TRIP_STATUS.TROVA_PRICING_APPROVED,
    ].includes(status);
};

/** Checks if a Trip is currently Live
 * @param { Trip } trip - Trip to be checked.
 */
export const shouldDisplayPostLaunchInfo = (trip: Trip | undefined) => {
    if (!trip) {
        return false;
    }
    const { publishStatus, launchDate } = trip;

    const isPastLaunchDate =
        launchDate && isAfter(new Date(), new Date(launchDate));

    return (
        isPastLaunchDate &&
        publishStatus === constants.trips.TRIP_PUBLISH_STATUS.LIVE
    );
};

export const getShortTimeZone = (): string =>
    new Date()
        .toLocaleDateString('en-US', {
            day: '2-digit',
            timeZoneName: 'short',
        })
        .slice(4);

export const launchDateSchemaShape = {
    launchDate: yup.string().when('launchTime', {
        is: (value) => value?.length > 0,
        then: yup.string().required('Launch date is required'),
    }),
    launchTime: yup.string().when('launchDate', {
        is: (value) => value?.length > 0,
        then: yup.string().required('Launch time is required'),
    }),
};

/**
 * Calculates the start launch date for a trip based on the host terms
 * acceptance date, the rule is to create a launch window of 3 days
 * after the host terms acceptance date.
 * @param {Trip} trip - Trip to be checked.
 * @returns {
 * startDateDate} - The calculated start launch date.
 */
export const calculateStartLaunchDate = (trip: Trip): Date => {
    const { hostTerms } = trip;

    let startPickDate = startOfDay(new Date());

    const lastDateTermsAccepted =
        hostTerms?.length && last(hostTerms)?.dateAccepted;

    if (lastDateTermsAccepted) {
        // reset time to 00:00:00 on the signd terms date to calcualte exactly the length of days
        const startOfDaySignedterms = startOfDay(
            new Date(lastDateTermsAccepted),
        );

        const startScheduledDate = addDays(
            startOfDaySignedterms,
            DAYS_UNTIL_LAUNCH_DATE,
        );

        return isBefore(startPickDate, startScheduledDate)
            ? startScheduledDate
            : startPickDate;
    }

    return startPickDate;
};

/**
 * Calculate the disable dates for the LaunchDate picker
 * the picked adds the range date inclusively (disabled also the first and last date on the range)
 * @param trip
 * @returns Array<[Date, Date]>
 */
export const calculateDisabledDates = (trip: Trip): DisabledDates => {
    const disableStartDate = calculateStartLaunchDate(trip);

    // if the launchDate window rule already passed, disable today as a selectable date on the picker
    const disabledlastDate = isSameDay(disableStartDate, startOfDay(new Date()))
        ? disableStartDate
        : subDays(disableStartDate, 1);
    const disabledDates: DisabledDates = [
        [DatePicker.ABSOLUTE_MINIMUM_DATE, disabledlastDate],
    ];

    return disabledDates;
};

export const isLaunchDateValid = (
    { launchDate, launchTime }: LaunchDateObject,
    trip: Trip,
): boolean => {
    const minimumLaunchDate = calculateStartLaunchDate(trip);
    const launchDateFormatted = parse(
        `${launchDate} ${launchTime}`,
        'MM/dd/yyyy HH:mm',
        new Date(),
    );

    if (!isValid(launchDateFormatted)) {
        console.warn(
            `bad date(${launchDate}) and time (${launchTime}) formatted`,
        );

        return false;
    }

    return launchDateFormatted.valueOf() >= minimumLaunchDate.valueOf();
};
