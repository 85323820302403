import { analyticsUtils } from '../../../analytics';

const { trackEvent } = analyticsUtils;

interface InsuranceDefaultPayload {
    tripId: string;
    bookingId: string;
}

enum TravelerAnalyticsEvent {
    FILE_A_CLAIM = 'Insurance File a Claim Clicked',
    POLICY_VIEWED = 'Insurance COB Viewed',
    CLICKED_ANDROID_DOWNLOAD_APP = 'User Clicked Android Download App',
    CLICKED_IOS_DOWNLOAD_APP = 'User Clicked IOS Download App',
    VIEWED_DOWNLOAD_APP_CARD = 'User Viewed Download App Card',
}

const trackInsuranceFileAClaimEvent = (
    payload: InsuranceDefaultPayload,
): void => {
    const eventName = TravelerAnalyticsEvent.FILE_A_CLAIM;

    trackEvent({ eventName, payload });
};

const trackInsurancePolicyViewedEvent = (
    payload: InsuranceDefaultPayload,
): void => {
    const eventName = TravelerAnalyticsEvent.POLICY_VIEWED;

    trackEvent({ eventName, payload });
};

const trackClickedAndroidDownloadApp = (): void => {
    const eventName = TravelerAnalyticsEvent.CLICKED_ANDROID_DOWNLOAD_APP;

    trackEvent({ eventName });
};

const trackClickedIosDownloadApp = (): void => {
    const eventName = TravelerAnalyticsEvent.CLICKED_IOS_DOWNLOAD_APP;

    trackEvent({ eventName });
};

const trackViewedDownloadAppCard = (): void => {
    const eventName = TravelerAnalyticsEvent.VIEWED_DOWNLOAD_APP_CARD;

    trackEvent({ eventName });
};

export {
    trackInsuranceFileAClaimEvent,
    trackInsurancePolicyViewedEvent,
    trackClickedAndroidDownloadApp,
    trackClickedIosDownloadApp,
    trackViewedDownloadAppCard,
};
export type { InsuranceDefaultPayload };
