import { Grid } from '@material-ui/core';
import {
    Cell,
    Checkbox,
    Row,
    Table,
    TableBody,
    TableHead,
    Stack,
} from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import { noop } from 'lodash';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TripDetailsStoreSelector, TripStoreSelector } from 'state/userTrips';
import { TripStatuses, BookingStatuses } from '../../../../config/constants';
import BookingsEmptyMessage from '../BookingsEmptyMessage/BookingsEmptyMessage';
import BookingsTableRow from './BookingsTableRow';
import { FormattedBookingOrder } from './BookingsTables';
import { containerTableStyles, tableStyles } from './BookingsTables.styles';

interface TableAction {
    callback?: (ordersId: string[], resetSelectedOrders: () => void) => void;
    visible?: boolean;
}

interface TableWithActionsProps {
    data: FormattedBookingOrder[];
    confirmAction: TableAction;
    rejectAction: TableAction;
    emptyMessage: string;
    orderStatusSelectable?: string;
}

const TravelersSelectableTable = (
    props: TableWithActionsProps,
): JSX.Element => {
    const {
        data,
        confirmAction,
        rejectAction,
        emptyMessage,
        orderStatusSelectable,
    } = props;

    const hasData = data.length > 0;

    const { callback: confirmCallback = noop, visible: confirmVisible = true } =
        confirmAction;

    const { callback: rejectCallback = noop, visible: rejectVisible = true } =
        rejectAction;

    const {
        userTripDetails: { current: tripDetails },
    } = useSelector((state: TripDetailsStoreSelector) => state);

    const {
        userTrips: { current: trip },
    } = useSelector((state: TripStoreSelector) => state);

    const isTripCompleted = trip?.status === TripStatuses.COMPLETE;

    const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
    const [totalTravelersCount, setTotalTravelersCount] = useState<number>(0);
    const [areAllOrdersSelected, setAreAllOrdersSelected] =
        useState<boolean>(false);

    const { minimumTripThreshold } = tripDetails;
    const { status: tripStatus } = trip;

    useEffect(() => {
        const totalTravelers = selectedOrders.reduce<number>(
            (acc, selectedOrderId) => {
                const order = data.find(
                    (order) => order.orderId === selectedOrderId,
                );
                return !order ? acc : acc + order?.totalSpotsBooked;
            },
            0,
        );
        setTotalTravelersCount(totalTravelers);
    }, [data, selectedOrders]);

    useEffect(() => {
        const selectableOrders = getSelectableOrders();
        const areSameLength = selectableOrders.length === selectedOrders.length;
        setAreAllOrdersSelected(areSameLength);
    }, [data, selectedOrders]);

    const getCheckboxValue = (orderId: string): boolean => {
        return selectedOrders.some((item) => item === orderId);
    };

    const getSelectableOrders = (): FormattedBookingOrder[] => {
        if (!orderStatusSelectable) {
            return data;
        }
        return data.filter(
            (order) =>
                order.status === orderStatusSelectable &&
                !order.pendingCancellation,
        );
    };

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        const ordersClone: string[] = [...selectedOrders];

        const currentOrderId = event.target.name;
        const orderIndex = selectedOrders.findIndex(
            (item) => item === currentOrderId,
        );

        if (orderIndex > -1) {
            ordersClone.splice(orderIndex, 1);
        } else {
            ordersClone.push(currentOrderId);
        }

        setSelectedOrders(ordersClone);
    };

    const handleHeaderCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        const areSelectedAll = event.target.checked;
        if (!areSelectedAll) {
            return setSelectedOrders([]);
        }
        const selectableOrders = getSelectableOrders();
        const pendingOrdersNumbers = selectableOrders.map(
            (order) => order.orderId,
        );
        setSelectedOrders(pendingOrdersNumbers);
    };

    const isRejectButtonDisabled = !(totalTravelersCount > 0);

    const isConfirmButtonDisabled = (): boolean => {
        switch (tripStatus) {
            case TripStatuses.CONFIRMED:
            case TripStatuses.EARLY_CONFIRMED:
                return totalTravelersCount <= 0;

            case TripStatuses.HOST_APPROVED:
            case TripStatuses.READY_TO_CONFIRM:
                return totalTravelersCount < minimumTripThreshold;

            default:
                return true;
        }
    };

    const isOrderCheckboxDisplayed = (
        orderStatus: string,
        isPendingCancellation: boolean,
    ): boolean => {
        const isPendingStatus = orderStatus === BookingStatuses.PENDING;

        if (!orderStatusSelectable) {
            return true;
        }

        return isPendingStatus && !isPendingCancellation;
    };

    const resetSelectedOrders = (): void => {
        setSelectedOrders([]);
    };

    const isHeaderCheckboxDisplayed: boolean =
        !isTripCompleted &&
        (!orderStatusSelectable ||
            data.some((order) => order.status === orderStatusSelectable));

    if (!hasData) {
        return (
            <Grid item className={containerTableStyles} lg={12} xs={12}>
                <BookingsEmptyMessage message={emptyMessage} />
            </Grid>
        );
    }

    return (
        <>
            <Grid item xs={12} className={containerTableStyles}>
                <Table className={tableStyles}>
                    <TableHead>
                        <Row>
                            <Cell width={25}>
                                {isHeaderCheckboxDisplayed && (
                                    <Checkbox
                                        name='checkAllPendingOrders'
                                        value={areAllOrdersSelected}
                                        onChange={handleHeaderCheckboxChange}
                                    />
                                )}
                            </Cell>
                            <Cell>Name</Cell>
                            <Cell>Email</Cell>
                            <Cell>Order #</Cell>
                            <Cell>Gender</Cell>
                            <Cell>Age</Cell>
                            <Cell>Instagram</Cell>
                            <Cell>Location</Cell>
                            <Cell>Status</Cell>
                            <Cell>Payment Status</Cell>
                        </Row>
                    </TableHead>
                    <TableBody>
                        {data.map((order, index) => {
                            return (
                                <BookingsTableRow
                                    key={order.orderId + index}
                                    order={order}
                                    renderActionCell={(): React.ReactNode =>
                                        isOrderCheckboxDisplayed(
                                            order.status,
                                            order.pendingCancellation,
                                        ) &&
                                        !isTripCompleted && (
                                            <Checkbox
                                                name={order.orderId}
                                                value={getCheckboxValue(
                                                    order.orderId,
                                                )}
                                                onChange={handleCheckboxChange}
                                            />
                                        )
                                    }
                                />
                            );
                        })}
                    </TableBody>
                </Table>
            </Grid>
            {!isTripCompleted ? (
                <Grid item xs={12} sm={8}>
                    <Stack spacing={3}>
                        {confirmVisible && (
                            <Button
                                variant='primary'
                                isDisabled={isConfirmButtonDisabled()}
                                onClick={(): void => {
                                    confirmCallback(
                                        selectedOrders,
                                        resetSelectedOrders,
                                    );
                                }}
                            >
                                Confirm Selected
                            </Button>
                        )}
                        {rejectVisible && (
                            <Button
                                variant='secondary'
                                isDisabled={isRejectButtonDisabled}
                                onClick={(): void => {
                                    rejectCallback(
                                        selectedOrders,
                                        resetSelectedOrders,
                                    );
                                }}
                            >
                                Reject Selected
                            </Button>
                        )}
                    </Stack>
                </Grid>
            ) : null}
        </>
    );
};

export default TravelersSelectableTable;
