import { Stack, Text } from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import { Loader, LoaderSize, theme } from '@trova-trip/trova-components';
import { css } from '@emotion/css';
import { constants } from '@trova-trip/trova-models';

interface PreviewProps {
    emailPreview: string;
    isPreviewLoading: boolean;
}

const convertStringToHTML = (str: string): Document => {
    const parser = new DOMParser();
    const document = parser.parseFromString(str, 'text/html');

    return document;
};

const applyStyles = (
    element: HTMLElement,
    styles: Record<string, string>,
): void => {
    Object.entries(styles).forEach(([property, value]) => {
        element.style[property] = value;
    });
};

const addStylesToBlurb = (blurbId: string, previewString: string): string => {
    const emailPreviewTemplate: Document = convertStringToHTML(previewString);
    const blurbElement: HTMLElement | null =
        emailPreviewTemplate.getElementById(blurbId);

    if (blurbElement) {
        applyStyles(blurbElement, {
            border: `2px solid ${theme.colors.alerts.success.accent}`,
            padding: theme.space[2],
            borderRadius: theme.radii.md,
            marginTop: theme.space[2],
        });

        const editableCopy = emailPreviewTemplate.createElement('p');
        editableCopy.innerText = 'Customizable Copy';
        blurbElement.insertAdjacentElement('beforebegin', editableCopy);
        applyStyles(editableCopy, {
            color: theme.colors.alerts.success.accent,
            textAlign: 'right',
            margin: '0',
            fontWeight: String(theme.fontWeights.medium),
            fontSize: theme.fontSizes.xs,
        });
    }

    const document: string = emailPreviewTemplate.documentElement.outerHTML;

    return document;
};

const { PROMOTE_EMAIL_HOST_BLURB_PREVIEW_ID } = constants.emails;

const Preview = (props: PreviewProps): JSX.Element => {
    const { emailPreview, isPreviewLoading } = props;

    return (
        <Stack direction='column' height='full' align='stretch' spacing={3}>
            <Stack justify='space-between' spacing={0}>
                <Text fontWeight='medium'>Preview</Text>
                <Button
                    variant='tertiary'
                    rightIcon='offsite'
                    onClick={(): void => {
                        const win = window.open();
                        win!.document.body.innerHTML = emailPreview;
                    }}
                >
                    Open
                </Button>
            </Stack>
            <Stack
                flexGrow={1}
                border='1px solid var(--tc-colors-blueGray-400)'
                padding={1}
                align='center'
                justify='center'
                className={css`
                    border-radius: var(--tc-radii-lg);
                    background-color: #f4f4f4;
                `}
            >
                {isPreviewLoading ? (
                    <Loader
                        size={LoaderSize.Md}
                        color={theme.colors.teal.trova}
                    />
                ) : (
                    <iframe
                        className={css`
                            width: 100%;
                            height: 100%;
                        `}
                        srcDoc={addStylesToBlurb(
                            PROMOTE_EMAIL_HOST_BLURB_PREVIEW_ID,
                            emailPreview,
                        )}
                    />
                )}
            </Stack>
        </Stack>
    );
};

export default Preview;
