import { SyntheticEvent } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { Toggle, Heading, Text, BaseBox } from '@trova-trip/trova-components';
export interface InlineToggleInputProps {
    name: string;
    value: boolean;
    label: string;
    title: string;
    description?: string;
    customInput?: JSX.Element;
    disabled?: boolean;
    onChange: (event: SyntheticEvent, name: string, value: boolean) => void;
}

const InlineToggleInput = ({
    title,
    description,
    disabled = false,
    name,
    value,
    customInput,
    onChange,
}: InlineToggleInputProps): JSX.Element => {
    const handleChange = (
        event: SyntheticEvent,
        name: string,
        value: boolean,
    ): void => {
        onChange(event, name, value);
    };

    return (
        <Grid container spacing={32}>
            <Grid item xs={12} sm={5}>
                <Heading as={'h5'}>{title}</Heading>
                {description && (
                    <BaseBox marginTop={2}>
                        <Text color={'neutral.60'}>{description}</Text>
                    </BaseBox>
                )}
            </Grid>
            <Hidden xsDown>
                <Grid item sm={1}></Grid>
            </Hidden>
            <Grid item xs={12} sm={6}>
                <Grid container item spacing={32}>
                    <Grid item xs={'auto'}>
                        <Toggle
                            value={value}
                            name={name}
                            disabled={disabled}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={9} sm={'auto'}>
                        {customInput && <>{customInput}</>}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default InlineToggleInput;
