/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useRef } from 'react';
import { coreUtils } from '@trova-trip/trova-common';
import { Hide, Stack, useToast } from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import PropTypes from 'prop-types';
import Tab from '../../../../navigation/Tab';
import TripDays from '../../../../components/TripDays/TripDays';
import DailyActivities from '../../../../components/DailyActivities/DailyActivities';
import TabDetailsInformation from '../../../../components/TabDetailsInformation/TabDetailsInformation';
import { getTripDatesIfApplicable } from '../../../../util/ModelDataHelper';
import createDayRefName from '../../../../util/createDayRefName';
import useFormattedServices from '../../../../util/hooks/useFormattedServices';
import useGetServiceByDayRefIds from '../../../../util/hooks/useGetServiceByDayRefIds';
import scrollToElement from '../../../../util/scrollToElement';
import { noop } from 'lodash';
import TabPageDescription from '../../../common/components/TabPageDescription';
import {
    ItineraryGridContainerStyle,
    ItineraryGridHeaderColStyle,
    ItineraryGridDAWrapperStyle,
} from './Itinerary.styles';
import { ROUTE_TOKENS } from '../../../../applications/common/constants';
import ItineraryStickyLayout from './ItineraryStickyLayout/ItineraryStickyLayout';
import PrintableItineraryHeader from './PrintableItineraryHeader/PrintableItineraryHeader';
import { usePrintPage } from '../../../common/hooks/usePrintPage';
import { useTrackingEvent } from '../../../../analytics/hooks';

const { TRAVELER_APP_TAB_ROUTE } = ROUTE_TOKENS;

const TAB_LABEL = 'Itinerary';

const { dateUtils } = coreUtils;

const Itinerary = ({ data, disabled = false }) => {
    const { trackUserEvent } = useTrackingEvent();
    const toast = useToast();

    const { servicesByDay, startDate } = data;
    const readOnlyServicesByDay = useFormattedServices(servicesByDay);
    const tripDates = getTripDatesIfApplicable(startDate, servicesByDay);
    const dayItemIds = useGetServiceByDayRefIds(data);

    const dailyActivitiesRef = useRef(null);

    const formattedDate = dateUtils.formatDateTime(new Date(), 'MM-dd-yyyy');

    const documentTitle = `${data?.name}-${data?.destination}-${
        tripDates.length && tripDates[0]
    }.pdf`;

    const showErrorToast = (error) =>
        toast({
            title: 'Something went wrong printing your itinerary. Please try again.',
            description: error,
            status: 'error',
            isClosable: true,
        });

    const handlePrint = usePrintPage({
        ref: dailyActivitiesRef,
        documentTitle,
        onPrintError: (error) => showErrorToast(error),
        onAfterPrint: () =>
            trackUserEvent({
                eventName: 'Traveler downloaded Itinerary PDF',
                properties: {
                    tripId: data?.id,
                    dateDownloaded: formattedDate,
                },
            }),
    });

    if (!servicesByDay) {
        return (
            <Tab
                label={TAB_LABEL}
                path={TRAVELER_APP_TAB_ROUTE.ITINERARY}
                disabled={true}
            />
        );
    }

    return (
        <Tab
            label={TAB_LABEL}
            path={TRAVELER_APP_TAB_ROUTE.ITINERARY}
            disabled={disabled}
        >
            <ItineraryStickyLayout>
                <div className={ItineraryGridContainerStyle}>
                    <header className={ItineraryGridHeaderColStyle}>
                        <Stack
                            direction='row'
                            justify='space-between'
                            align='center'
                        >
                            <TabDetailsInformation title={TAB_LABEL} />
                            <Button
                                rightIcon='download'
                                variant='secondary'
                                size='sm'
                                backgroundColor='gray.100'
                                onClick={handlePrint}
                            >
                                Download PDF
                            </Button>
                        </Stack>
                        <TabPageDescription>
                            Review the day-to-day of your trip to see
                            accommodations, included meals and activities, and
                            transportation information.
                        </TabPageDescription>
                    </header>
                    <Hide breakpoint={{ max: 'md' }}>
                        <section>
                            <TripDays
                                servicesByDay={readOnlyServicesByDay}
                                tripDates={tripDates}
                                dayItemIds={dayItemIds}
                                scrollToDay={(dayIndex) => {
                                    const elementId =
                                        createDayRefName(dayIndex);
                                    scrollToElement(elementId);
                                }}
                                cleanErrors={noop}
                            />
                        </section>
                    </Hide>
                    <section
                        className={ItineraryGridDAWrapperStyle}
                        ref={dailyActivitiesRef}
                    >
                        <PrintableItineraryHeader itinerary={data} />
                        {readOnlyServicesByDay &&
                            readOnlyServicesByDay.map((day, dayIndex) => (
                                <DailyActivities
                                    readOnly
                                    key={dayIndex}
                                    id={createDayRefName(dayIndex)}
                                    dayServices={day}
                                    dayIndex={dayIndex}
                                    productName={'trip'}
                                    date={tripDates[dayIndex]}
                                    currentCurrency={data.currency}
                                />
                            ))}
                    </section>
                </div>
            </ItineraryStickyLayout>
        </Tab>
    );
};

Itinerary.propTypes = {
    data: PropTypes.shape(),
    disabled: PropTypes.bool,
};

export default Itinerary;
