import React from 'react';
import {
    Stack,
    Image,
    Text,
    Heading,
    ResponsiveValue,
    HeadingProps,
    ImageProps,
    useBreakpointValue,
} from '@trova-trip/trova-components';
import {
    CardProps,
    Icon,
    LinkCard,
} from '@trova-trip/trova-components/build/next';

export interface ItineraryRecommendationCardProps {
    imageSrc?: string;
    name?: string;
    isTopRecommended?: boolean;
    onClick?: CardProps['onClick'];
    layoutConfig?: ResponsiveValue<{
        titleFontSize?: HeadingProps['fontSize'];
        imageSize?: Pick<ImageProps, 'width' | 'height'>;
    }>;
}

const defaultLayoutConfig: ItineraryRecommendationCardProps['layoutConfig'] = {
    base: {
        titleFontSize: 'base',
        imageSize: { width: 32, height: 24 },
    },
};

const ItineraryRecommendationCard = ({
    imageSrc,
    name,
    isTopRecommended,
    onClick,
    layoutConfig: layoutConfigProp = defaultLayoutConfig,
}: ItineraryRecommendationCardProps): JSX.Element => {
    const { imageSize, titleFontSize } =
        useBreakpointValue(layoutConfigProp) || {};

    return (
        <LinkCard
            spacing='sm'
            variant='unstyled'
            onClick={onClick}
            width='full'
        >
            <Stack wrap='nowrap' spacing={2}>
                {imageSrc ? (
                    <Image
                        data-gumlet='false'
                        src={imageSrc}
                        borderRadius='lg'
                        width={imageSize?.width}
                        height={imageSize?.height}
                        fit='cover'
                        flexShrink={0}
                    />
                ) : null}
                <Stack direction='column' spacing={1} wrap='nowrap'>
                    <Stack align='center' spacing={0.5} wrap='nowrap'>
                        <Icon
                            as='star'
                            size='sm'
                            variant='filled'
                            color='red.trova'
                            display='inline-flex'
                        />
                        <Text
                            color='red.trova'
                            fontWeight='bold'
                            fontSize='xs'
                            lineHeight='normal'
                        >
                            {isTopRecommended
                                ? 'TOP RECOMMENDED'
                                : 'RECOMMENDED'}
                        </Text>
                    </Stack>
                    <Heading
                        as='h5'
                        fontWeight='medium'
                        fontSize={titleFontSize}
                        lineHeight='normal'
                        noOfLines={3}
                    >
                        {name}
                    </Heading>
                </Stack>
            </Stack>
        </LinkCard>
    );
};

export default ItineraryRecommendationCard;
