import { Stack } from '@trova-trip/trova-components';
import { Button, Table } from '@trova-trip/trova-components/build/next';
import format from 'date-fns/format';
import { BookedGroundTransferJourney } from '../../../../../state/features/transfer/types';
import IconText from './shared/IconText';
import JourneyPrice from './shared/JourneyPrice';
import InfoRow from './shared/InfoRow';

interface BookingDetailsProps {
    journey: BookedGroundTransferJourney;
    onEditInfo?: () => void;
}

const BookingDetails = ({
    journey,
    onEditInfo,
}: BookingDetailsProps): JSX.Element => {
    const {
        code,
        passengers,
        luggage,
        traveler: { flightNumber },
        price,
        date,
        origin: { fullResolvedAddress: originFullResolvedAddress },
        destination: { fullResolvedAddress: destinationFullResolvedAddress },
    } = journey;

    const pickupAddress =
        originFullResolvedAddress.establishment ||
        originFullResolvedAddress.region ||
        '';

    const dropOffAddress =
        destinationFullResolvedAddress.establishment ||
        originFullResolvedAddress.region ||
        '';

    return (
        <Stack
            direction='column'
            align='stretch'
            width='full'
            paddingBottom={{ base: 4, md: 8 }}
            spacing={0}
        >
            <Stack
                justify='space-between'
                borderBottom='1px solid'
                borderColor='blueGray.200'
                paddingBottom={3}
            >
                <Stack spacing={3}>
                    <IconText icon='flight' text={flightNumber} />
                    <IconText icon='person' text={passengers} />
                    <IconText icon='luggage' text={luggage} />
                </Stack>
                <JourneyPrice price={price} />
            </Stack>
            <Table variant='simple' borderOutline={false}>
                <Table.Body>
                    <InfoRow title='Journey Code' value={code} />
                    <InfoRow
                        title='Date & Time'
                        value={format(new Date(date), 'MM/dd/yyyy HH:mm')}
                    />
                    <InfoRow title='Pickup' value={pickupAddress} />
                    <InfoRow title='Drop-off' value={dropOffAddress} />
                </Table.Body>
            </Table>
            {onEditInfo ? (
                <Stack
                    width='full'
                    borderTop='1px solid'
                    borderColor='blueGray.200'
                    paddingTop={3}
                >
                    <Button
                        variant='tertiary'
                        onClick={onEditInfo}
                        marginLeft='auto'
                        marginRight={0}
                        size={{ base: 'md', sm: 'sm' }}
                    >
                        Edit Information
                    </Button>
                </Stack>
            ) : null}
        </Stack>
    );
};

export default BookingDetails;
