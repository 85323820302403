import { Heading, Hide, IconButton, Stack } from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import styles from './Header.module.scss';

type HeaderProps = {
    onCloseClick: () => void;
    title?: string;
};

const Header = ({ onCloseClick, title }: HeaderProps): JSX.Element => {
    return (
        <Stack
            align='center'
            wrap='nowrap'
            justify={{ base: 'start', md: 'end' }}
            className={styles.titleContainer}
            paddingX={{ base: 4, md: 6 }}
            paddingY={{ base: 3, md: 4 }}
        >
            <Hide breakpoint={{ min: 'md' }}>
                <Button onClick={onCloseClick} variant='tertiary'>
                    Cancel
                </Button>
            </Hide>
            <Heading
                textAlign='center'
                as='h4'
                fontSize={{ base: 'base', md: '2xl' }}
                fontWeight={{ base: 'regular', md: 'bold' }}
                lineHeight='normal'
                className={styles.formTitle}
            >
                {title}
            </Heading>
            <Hide breakpoint={{ max: 'md' }}>
                <IconButton
                    onClick={onCloseClick}
                    icon='close'
                    aria-label='close'
                    variant='outlined'
                    size='md'
                />
            </Hide>
        </Stack>
    );
};

export default Header;
