import { models } from '@trova-trip/trova-models';
import {
    FormGroup,
    Grid,
    Heading,
    Stack,
    Switch,
    SwitchProps,
} from '@trova-trip/trova-components';
import { Card } from '@trova-trip/trova-components/build/next';
import { SURVEY_LIST } from './AudienceMetrics.constants';
import Metric from './Metric';
import { SURVEY_TYPES } from './Metric/Metric.constants';
import {
    MetricNotPopulated,
    PopularDestinationNotPopulated,
} from './Metric/NotEnoughData';
import { MetricProps } from './Metric/Metric.types';
import { SurveyItem } from './AudienceMetrics.types';

interface AudienceMetricsProps {
    surveyData: models.survey.AudienceSurveySubmissionsData | null;
    onToggleMetricsType: SwitchProps['onChange'];
    showQualifiedMetrics: boolean;
    isMetricPopulated: boolean;
    isHostQualified: boolean;
}

const destinationsToHide = ['other', null, undefined, ''];

const AudienceMetrics = (props: AudienceMetricsProps): JSX.Element => {
    const {
        surveyData,
        isMetricPopulated,
        onToggleMetricsType,
        showQualifiedMetrics,
        isHostQualified,
    } = props;

    const specificPopularDestinations =
        surveyData?.graphs.popularDestinations.filter(
            (dataPoint) =>
                !destinationsToHide.includes(
                    dataPoint.label?.trim().toLowerCase(),
                ),
        );

    const SURVEY_METRICS = {
        PopularDestSection: specificPopularDestinations || [],
        TimeOfYear: surveyData?.graphs.timeOfYear || [],
        Budget: surveyData?.graphs.budget || [],
        Age: surveyData?.graphs.age || [],
        Gender: surveyData?.graphs.gender || [],
    };

    return (
        <Grid>
            {SURVEY_LIST.map((surveyItem: SurveyItem) => {
                const { surveyName, surveyType, columnSpan } = surveyItem;
                const isPopularDestinationSection =
                    surveyType === 'PopularDestSection';
                const graphData = SURVEY_METRICS[surveyType];
                const metricData = {
                    ...SURVEY_TYPES[surveyType],
                    graphData,
                } as MetricProps;

                return (
                    <Grid.Item key={surveyName} columnSpan={columnSpan}>
                        <Stack
                            paddingTop={6}
                            paddingBottom={4}
                            justify='space-between'
                            align={{ base: 'flex-start', md: 'center' }}
                            direction={{ base: 'column-reverse', md: 'row' }}
                        >
                            <Heading as='h4'>{surveyName}</Heading>

                            {isPopularDestinationSection ? (
                                <Stack
                                    paddingTop={6}
                                    paddingBottom={4}
                                    width={{ base: 'full', md: 'auto' }}
                                    justify={{ base: 'center', md: 'flex-end' }}
                                >
                                    <FormGroup
                                        label='Show Qualified Only'
                                        direction='row-reverse'
                                    >
                                        <Switch
                                            name='showQualified'
                                            isDisabled={
                                                !(isHostQualified && surveyData)
                                            }
                                            value={showQualifiedMetrics}
                                            onChange={onToggleMetricsType}
                                        />
                                    </FormGroup>
                                </Stack>
                            ) : null}
                        </Stack>
                        <Card backgroundColor='neutral.white'>
                            {isMetricPopulated ? (
                                <Metric {...metricData} />
                            ) : (
                                <Stack
                                    paddingY={16}
                                    paddingX={0}
                                    align='center'
                                    justify='center'
                                >
                                    {isPopularDestinationSection ? (
                                        <PopularDestinationNotPopulated />
                                    ) : (
                                        <MetricNotPopulated />
                                    )}
                                </Stack>
                            )}
                        </Card>
                    </Grid.Item>
                );
            })}
        </Grid>
    );
};

export default AudienceMetrics;
