import { models } from '@trova-trip/trova-models';
import useItineraryInventory from 'applications/common/hooks/useItineraryInventory';
import { useEffect, useState } from 'react';
import Snackbar from '../../../../components/Snackbar/Snackbar';
import { useSelector } from '../../../../state/hooks';
import { userTripRequests } from '../../../../state/userTripRequests';
import TripRequestForm from './TripRequestForm';
import { InitialFormData } from './TripRequestForm.types';
import {
    getInitialTripRequestDataFromTripRequest,
    prepareTripRequestUpdateAvailability,
} from './tripRequestFormHelper';
import useGetItineraryAvailability from './useGetItineraryAvailability';

type SuggestedSellPrice = models.itineraries.SuggestedSellPrice;

type TripRequest = models.tripRequest.TripRequest;

interface UpdateRequestFormProps {
    onChangePrice: (price: SuggestedSellPrice) => void;
    travelersTierNumber: number;
    editMode: boolean;
}

const UpdateRequestForm = (props: UpdateRequestFormProps) => {
    const { onChangePrice, editMode, travelersTierNumber } = props;
    const [snackBar, setSnackBar] = useState({
        message: '',
        color: 'info',
        show: false,
    });

    const { inventoryItem, fetchInventoryItem } = useItineraryInventory();

    const currentTripRequest = useSelector(
        ({ userTripRequests }) => userTripRequests.current as TripRequest,
    );

    const { itineraryInventoryItem: inventoryItemId, itinerary } =
        currentTripRequest as TripRequest;

    const { getRecord: getTripRequest, updateRecord: updateTripRequest } =
        userTripRequests.useDispatch.bind(currentTripRequest)();

    const itineraryAvailability = useGetItineraryAvailability(itinerary);

    useEffect(() => {
        if (!inventoryItemId || !itinerary?._id) {
            return;
        }
        fetchInventoryItem(inventoryItemId as string, itinerary._id, true);
    }, [inventoryItemId, itinerary?._id]);

    const handleFormSubmit = (formData: InitialFormData): void => {
        const { startDate, additionalRequests } = formData;

        const updatedData = {
            startDate,
            additionalRequests,
        };

        updateTripRequest(currentTripRequest.id, updatedData, {
            successCallback: (): void => {
                getTripRequest(currentTripRequest.id);
                setSnackBar({
                    message: 'Trip Request saved!',
                    color: 'success',
                    show: true,
                });
            },
            errorCallback: (error: string): void => {
                setSnackBar({
                    message:
                        'There was an error updating the trip request. Please try again.',
                    color: 'danger',
                    show: true,
                });
                console.error(
                    '@Trova: Error updating the trip request.',
                    error,
                );
            },
        });
    };

    const shouldFetchInventoryItem = Boolean(inventoryItemId);

    if (
        !itineraryAvailability ||
        (shouldFetchInventoryItem && !inventoryItem)
    ) {
        return null;
    }

    const initialTripRequestData = getInitialTripRequestDataFromTripRequest(
        currentTripRequest,
        inventoryItem,
    );

    const preparedItineraryAvailability = prepareTripRequestUpdateAvailability(
        itineraryAvailability,
        currentTripRequest.selectedPackage,
        inventoryItem,
    );

    return (
        <>
            <TripRequestForm
                onChangePrice={onChangePrice}
                travelersTierNumber={travelersTierNumber}
                editMode={editMode}
                initialTripRequestData={initialTripRequestData}
                itineraryAvailability={preparedItineraryAvailability}
                onSubmit={handleFormSubmit}
                showDialogBeforeSubmit={false}
                showAdditionalRequests={true}
            />
            <Snackbar
                place='tr'
                color={snackBar.color}
                message={snackBar.message}
                open={snackBar.show}
                autoHideDuration={4000}
                onClose={(): void => {
                    setSnackBar({
                        message: '',
                        color: 'info',
                        show: false,
                    });
                }}
            />
        </>
    );
};

export default UpdateRequestForm;
