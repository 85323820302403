export const hostInterestColorSchemes = {
    green: {
        light: '#508F69',
        strong: '#508F69',
        background: '#F3F4F1',
        hover: '#E3E7E0',
    },
    lightPurple: {
        light: '#AD749D',
        strong: '#A1267F',
        background: 'purple.50',
        hover: '#EFD3E9',
    },
    yellow: {
        light: '#CFAF5B',
        strong: '#AC8215',
        background: '#F6F4E6',
        hover: '#F0EBCF',
    },
    blue: {
        light: 'blue.600',
        strong: 'blue.600',
        background: 'blue.20',
        hover: '#E1E8F5',
    },
    blueGray: {
        light: 'blueGray.600',
        strong: 'blueGray.600',
        background: 'blueGray.100',
        hover: '#DCE7F2',
    },
    seaGreen: {
        light: 'seaGreen.trova',
        strong: 'seaGreen.600',
        background: 'seaGreen.20',
        hover: '#E2F3E5',
    },
    red: {
        light: 'red.400',
        strong: 'red.700',
        background: 'red.100',
        hover: '#F8DCD6',
    },
    purple: {
        light: 'purple.400',
        strong: 'purple.700',
        background: 'purple.50',
        hover: '#E8D7E4',
    },
    orange: {
        light: 'orange.800',
        strong: 'orange.900',
        background: 'orange.100',
        hover: '#F5E4D4',
    },
    teal: {
        light: 'teal.trova',
        strong: 'teal.800',
        background: 'teal.100',
        hover: '#CDEDF1',
    },
};
