import { StatefulModel } from 'applications/types';
import { useSelector } from 'state/hooks';

interface useGetCurrentStatefulModelStatusProps {
    model: StatefulModel;
}

const useGetCurrentStatefulModelStatus = (
    props: useGetCurrentStatefulModelStatusProps,
): string => {
    const { model } = props;

    const currentState = useSelector((state) => {
        switch (model) {
            case StatefulModel.TRIP:
                return state.userTrips.current?.status;
            case StatefulModel.ITINERARY:
                return state.userItinerary.current?.status;
            case StatefulModel.TRIP_REQUEST:
                return state.userTripRequests.current?.status;
            case StatefulModel.PROFILE:
                return state.profile.current?.status;
            default:
                throw new Error('Model Not Supported');
        }
    });

    return currentState || '';
};

export default useGetCurrentStatefulModelStatus;
