import { useHistory } from 'react-router-dom';
import { useTrackingEvent } from '../../../../analytics/hooks';
import {
    getAdminRecommendationTrackingData,
    AdminRecommendationLocations,
} from '../../utils/trackingEvents.utils';
import RecommendationBanner from './RecommendationBanner';
import { applicationRootPath } from '../../../../navigation/NavigationContext';
import { ROUTE_TOKENS } from '../../../common/constants';
import { UseHostRecommendationsReturn } from '../../hooks/useHostRecommendations';
import type { RecommendationBannerLayoutTypes } from './recommendationBannerLayoutConfig';

interface RecommendationBannerWrapperProps
    extends Pick<
        UseHostRecommendationsReturn,
        'recommendations' | 'getItineraryRecommendation'
    > {
    layoutType: RecommendationBannerLayoutTypes;
    trackingDataLocation: AdminRecommendationLocations;
}
const hostPath = `${applicationRootPath}${ROUTE_TOKENS.HOST_APP_ROUTE}`;

const RecommendationBannerWrapper = ({
    recommendations,
    layoutType,
    getItineraryRecommendation,
    trackingDataLocation,
}: RecommendationBannerWrapperProps): JSX.Element => {
    const history = useHistory();
    const { trackUserEvent } = useTrackingEvent();
    const {
        options = [],
        recommendationNote,
        recommendedBy,
    } = recommendations || {};

    const onClickHandler = (itineraryId: string): void => {
        const adminRecommendationTrackingData =
            getAdminRecommendationTrackingData(
                recommendations,
                getItineraryRecommendation(itineraryId),
                trackingDataLocation,
            );

        if (adminRecommendationTrackingData) {
            trackUserEvent({
                eventName: 'Host Clicked Recommended Itinerary',
                properties: adminRecommendationTrackingData,
            });
        }

        history.push(
            `${hostPath}${ROUTE_TOKENS.HOST_APP_PRODUCT_ROUTE.ITINERARIES}/${itineraryId}/description`,
        );
    };

    return (
        <RecommendationBanner
            layoutType={layoutType}
            recommendations={options
                ?.filter(({ itinerary }) => itinerary.active)
                .map(({ itinerary, topRecommended }) => ({
                    imageSrc: itinerary?.heroImage,
                    isTopRecommended: topRecommended,
                    name: itinerary?.name,
                    onClick: () => onClickHandler(itinerary?.id || ''),
                }))}
            recommendedBy={{
                name: recommendedBy?.firstName,
                profilePictureUrl: recommendedBy?.profilePictureUrl,
                quote: recommendationNote,
            }}
        />
    );
};

export default RecommendationBannerWrapper;
