import ConfirmationDialog, {
    ConfirmationDialogProps,
} from '../../../../../common/components/ConfirmationDialog';
import { useAnalytics, useManageBooking } from '../../hooks';

const confirmationDialogTextProps = {
    title: 'Are you sure you want to clear your selection?',
    description: 'Your trip changes will be removed.',
    cancelButtonProps: { children: 'Cancel' },
    confirmButtonProps: { children: 'Clear All' },
};

interface ClearAllConfirmationModalProps
    extends Pick<ConfirmationDialogProps, 'onCancel' | 'open'> {}

const ClearAllConfirmationModal = ({
    onCancel,
    open,
}: ClearAllConfirmationModalProps): JSX.Element => {
    const {
        actions: { clearTripSummary },
        state: {
            tripSummary: {
                total,
                sections: { added, removed, roomSelection },
            },
            addOns: {
                accommodations: { singleSupplement },
            },
        },
    } = useManageBooking();

    const { trackClearCartEvent } = useAnalytics();

    const handleConfirmation = () => {
        clearTripSummary();
        trackClearCartEvent({
            sections: { added, removed, roomSelection },
            singleSupplement,
            cartBalance: total,
        });
    };

    return (
        <ConfirmationDialog
            open={open}
            onConfirmation={handleConfirmation}
            onCancel={onCancel}
            {...confirmationDialogTextProps}
        />
    );
};

export default ClearAllConfirmationModal;
