import FetchState from './utils/FetchState';
import { get } from './utils/api';

const userOperators = FetchState.create(`api/me/operators`);

const userOperatorsReducer = userOperators.getReducer();

const getOperatorForUser = () => get(`api/me/operator`);

export { userOperators, userOperatorsReducer, getOperatorForUser };
