import {
    Stack,
    BaseBox,
    Heading,
    Image,
    ImageProps,
    HeadingProps,
} from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';

type ItinerariesPhotos = models.itineraries.Photos;

interface ReadOnlyPhotosProps {
    tripPhotos: ItinerariesPhotos;
}

const readOnlyPhotoProperties: {
    image: Pick<ImageProps, 'borderRadius' | 'width' | 'height' | 'display'>;
    heading: Pick<HeadingProps, 'as' | 'fontSize' | 'fontWeight'>;
} = {
    image: {
        borderRadius: 10,
        width: 36,
        height: 28,
        display: 'flex',
    },
    heading: {
        as: 'h5',
        fontSize: 'sm',
        fontWeight: 'regular',
    },
};

const ReadOnlyPhotos = ({ tripPhotos }: ReadOnlyPhotosProps) => {
    return (
        <Stack spacing={8}>
            <BaseBox>
                <Image
                    {...readOnlyPhotoProperties.image}
                    src={tripPhotos.hero}
                />
                <Heading {...readOnlyPhotoProperties.heading}>Featured</Heading>
            </BaseBox>
            {tripPhotos.itineraryPhotos?.length > 0
                ? tripPhotos.itineraryPhotos.map((itineraryPhoto, index) => {
                      return (
                          <BaseBox key={itineraryPhoto.photo}>
                              <Image
                                  {...readOnlyPhotoProperties.image}
                                  src={itineraryPhoto.photo}
                              />
                              <Heading {...readOnlyPhotoProperties.heading}>
                                  Photos - {index + 1}
                              </Heading>
                          </BaseBox>
                      );
                  })
                : null}
        </Stack>
    );
};

export default ReadOnlyPhotos;
