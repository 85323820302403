import styled from '@emotion/styled';
import { Dropdown, Input, Textarea, theme } from '@trova-trip/trova-components';
import TrovaHeroFileInput from '../../../../components/TrovaFileInput/TrovaHeroFileInput';

export const StyledInput = styled(Input)({
    width: `auto`,
});

export const StyledTextarea = styled(Textarea)({
    width: `auto`,
});

export const StyledDropdown = styled(Dropdown)(`
    width: auto;
    > div:last-child{
        input{
            width: 100%;
        }
    }
`);

export const StyledTrovaFileInput = styled(TrovaHeroFileInput)`
    margin: 2rem 0;

    .fileuploader-items {
        background: ${theme.colors.blueGray[100]};
    }

    .fileuploader-theme-gallery .fileuploader-items {
        margin: -16px 0 0 -16px;

        .fileuploader-input-inner {
            min-height: 148px;
        }

        .fileuploader-input-inner,
        .fileuploader-input-inner:hover {
            border: 2px dashed ${theme.colors.neutral[80]};
        }

        .upload-failed {
            background: ${theme.colors.neutral.white};
        }

        .fileuploader-item {
            width: 100%;

            .fileuploader-action i {
                font-weight: 700;
            }

            .fileuploader-item-image img,
            .fileuploader-item-image canvas {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform 400ms ease-out;
            }

            .fileuploader-item-inner {
                overflow: inherit;
                box-shadow: none;
                background: transparent;

                .actions-holder {
                    top: -6px;
                    right: -11px;

                    .fileuploader-action i {
                        font-weight: 700;
                    }
                }

                .thumbnail-holder {
                    border-radius: 6px;
                    padding-top: 67%;
                }

                .content-holder {
                    text-align: left;
                    padding: 8px;
                    h5 {
                        font-weight: inherit;
                        line-height: 16px;
                    }
                }
            }
        }
    }

    @media (min-width: ${theme.breakpoints.sm}) {
        .fileuploader-theme-gallery .fileuploader-items {
            .fileuploader-item {
                width: calc(50% - 16px);

                .fileuploader-item-inner {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                }
            }
        }
    }

    @media (min-width: ${theme.breakpoints.md}) {
        .fileuploader-theme-gallery .fileuploader-items {
            .fileuploader-input {
                margin: 0 0 1rem 16px;
            }

            .fileuploader-input-inner {
                width: 100%;
            }

            .fileuploader-item {
                .fileuploader-item-inner {
                    display: block;
                    width: auto;

                    .thumbnail-holder {
                        padding-top: 85%;
                    }
                }
            }
        }
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        .fileuploader-theme-gallery .fileuploader-items {
            .fileuploader-item {
                width: calc(33.3333% - 16px);
            }
        }
    }

    @media (min-width: ${theme.breakpoints.xl}) {
        .fileuploader-theme-gallery .fileuploader-items {
            .fileuploader-item {
                width: calc(25% - 16px);
            }
        }
    }
`;
