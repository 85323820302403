import { Grid } from '@material-ui/core';
import { PricingCalculator } from '@trova-trip/trova-common';
import { BaseBox, Heading, Stack, Text } from '@trova-trip/trova-components';
import { constants, models } from '@trova-trip/trova-models';
import { useMemo } from 'react';

import {
    AvailabilityViewModelByPackage,
    InitialTripRequestData,
} from './TripRequestForm.types';
import TripRequestPackageCard from './TripRequestPackageCard';

type PackageLevel = constants.itinerary.PackageLevel;
type ItineraryPackages = models.itineraries.ItineraryPackageLevels;
type ItineraryPackage = models.itineraries.ItineraryPackage;

type ItineraryPackageWithName = ItineraryPackage & {
    name: PackageLevel;
};

interface TripRequestPackagesContainerProps {
    selectedPackage: PackageLevel;
    travelersTierNumber: number;
    packages: ItineraryPackages;
    itinerary: InitialTripRequestData['itinerary'];
    onPackageSelected: (packageName: PackageLevel) => void;
    isDisabled?: boolean;
    tripRequestCostDTO: Omit<PricingCalculator.TripRequestCostDTO, 'host'>;
    itineraryAvailability: AvailabilityViewModelByPackage;
}

const getEnabledPackages = (
    packages: ItineraryPackages,
    availability: AvailabilityViewModelByPackage,
): ItineraryPackageWithName[] => {
    return Object.keys(packages).reduce((acc, key: PackageLevel) => {
        const currentPackage: ItineraryPackage = packages[key];
        const currentAvailability = availability[key];

        if (currentAvailability?.isPackageEnabled) {
            acc.push({ ...currentPackage, name: key as PackageLevel });
        }

        return acc;
    }, [] as ItineraryPackageWithName[]);
};

const TripRequestPackagesContainer = ({
    tripRequestCostDTO,
    selectedPackage,
    packages,
    itinerary,
    travelersTierNumber,
    onPackageSelected,
    isDisabled,
    itineraryAvailability,
}: TripRequestPackagesContainerProps): JSX.Element | null => {
    const enabledPackages = getEnabledPackages(packages, itineraryAvailability);

    if (enabledPackages.length < 2) {
        return null;
    }

    const packagesCards = useMemo(() => {
        return enabledPackages.map((currentPackage) => (
            <TripRequestPackageCard
                key={currentPackage.name}
                tripRequestCostDTO={tripRequestCostDTO}
                packageLevel={currentPackage.name}
                isDisabled={isDisabled}
                onPackageSelected={onPackageSelected}
                isSelected={currentPackage.name === selectedPackage}
                itinerary={itinerary}
                travelersTierNumber={travelersTierNumber}
                packageAvailability={itineraryAvailability[currentPackage.name]}
                {...currentPackage}
            />
        ));
    }, [travelersTierNumber, selectedPackage, tripRequestCostDTO, isDisabled]);

    return (
        <Grid item xs={12}>
            <BaseBox
                backgroundColor={isDisabled ? 'gray.100' : 'neutral.white'}
                padding={6}
                borderRadius={12}
                width='100%'
                marginBottom={8}
            >
                <Heading as='h4' marginBottom={2}>
                    Trip Package
                </Heading>
                <Text marginBottom={4}>
                    Package prices are based on the estimated cost per Traveler
                    of a sold out trip. Actual cost per Traveler may vary
                    depending on how many spots are booked.
                </Text>
                <Stack
                    spacing={4}
                    direction={{
                        lg: 'row',
                        base: 'column',
                    }}
                    justify='space-evenly'
                    align='stretch'
                    wrap='nowrap'
                >
                    {packagesCards}
                </Stack>
            </BaseBox>
        </Grid>
    );
};

export default TripRequestPackagesContainer;
