import { BaseBox, Drawer, Heading, Stack } from '@trova-trip/trova-components';
import useIsLargeScreen from '../../../../../common/hooks/useIsLargeScreen';
import { useManageBooking, useTripSummaryContent } from '../../hooks';
import TripSummaryContent from '../TripSummaryContent';
import TripSummaryControls from '../TripSummaryControls';
import ErrorAlert from '../ErrorAlert';
import DifferentTransferDatesFormTripAlert from '../DifferentTransferDatesFormTripAlert';

const TripSummaryDrawer = (): JSX.Element | null => {
    const {
        state: {
            tripSummary: { isOpen },
            confirmation,
        },
        actions: { closeTripSummary },
    } = useManageBooking();
    const { pendingChanges } = useTripSummaryContent();

    const isDesktop = useIsLargeScreen({ includeTabletResolution: true });

    if (!isDesktop || !pendingChanges) return null;

    return (
        <>
            <Drawer
                placement='right'
                size='sm'
                hideOverlay={true}
                isOpen={isOpen}
                onClose={() => closeTripSummary()}
            >
                <Drawer.Header
                    borderTop='1px solid'
                    borderBottom='1px solid'
                    borderColor='blueGray.200'
                >
                    <Heading as='h5' fontWeight='bold'>
                        Summary
                    </Heading>
                </Drawer.Header>
                <Drawer.Body paddingY={0}>
                    <BaseBox paddingBottom={4}>
                        <TripSummaryContent />
                    </BaseBox>
                    <DifferentTransferDatesFormTripAlert />
                    <ErrorAlert error={confirmation.error} paddingTop={1} />
                    <Stack paddingTop={6}>
                        <TripSummaryControls />
                    </Stack>
                </Drawer.Body>
            </Drawer>
        </>
    );
};

export default TripSummaryDrawer;
