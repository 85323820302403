import { useEffect, useState } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import {
    Cell,
    Row,
    Table,
    TableBody,
    TableHead,
    HorizontalLineGraph,
    Heading,
    BaseBox,
    Text,
    theme,
    Toggle,
} from '@trova-trip/trova-components';
import { GridSize } from '@material-ui/core/Grid';
import { css } from '@emotion/css';
import type { ThresholdEarning } from '@trova-trip/trova-common/dist/PricingCalculator/PricingCalculator.types';

const MIN = 0;

const containerTableStyles = css`
    > div {
        margin: 0;
        @media (max-width: ${theme.breakpoints.md}) {
            padding: 0.5rem 0.75rem;
            max-width: calc(100vw - 32px);
            overflow-x: scroll;
        }
    }
`;

const tableStyles = (isExpanded) => css`
    table {
        white-space: inherit;
    }
    th,
    td {
        text-align: center;
    }
    td {
        @media (max-width: ${theme.breakpoints.md}) {
            padding: 16px 6px;
        }
    }
    && {
        thead > tr > th {
            padding: 16px 12px;
            @media (max-width: ${theme.breakpoints.md}) {
                min-width: ${isExpanded ? '120' : '100'}px;
                padding: 12px 6px;
            }
        }
        th:nth-child(2) {
            padding: 16px 7px 16px 20px;
            @media (max-width: ${theme.breakpoints.md}) {
                padding: 12px 6px;
            }
        }
    }

    th:nth-child(2),
    td:nth-child(2) {
        box-shadow: inset 5px -1px 6px -4px rgb(32 33 36 / 8%);
        border-radius: 0;
    }
    th:nth-child(4),
    td:nth-child(4) {
        border-radius: 0;
        ${isExpanded &&
        `box-shadow: inset -5px -1px 6px -4px rgb(32 33 36 / 8%);`}
    }

    div[data-toggle] {
        text-align: left;
    }
`;

const collapsibleColumnStyles = css`
    && {
        background: #f6f7f8;
        border-right: 1px solid #e6e8eb !important;
        border-left: 1px solid #e6e8eb !important;
        border-radius: 0;
        animation: transition 1s ease-out;
    }

    @keyframes transition {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

const getEveryOtherThresholdEarnings = (
    costThresholdsEarnings: ThresholdEarning[],
    minimumSpots: number,
    maximumSpots: number,
): ThresholdEarning[] => {
    const isStartOdd = minimumSpots % 2 > 0;
    return costThresholdsEarnings.filter((currentCostThreshold, index) => {
        if (
            currentCostThreshold.travelerRowNumber < minimumSpots ||
            currentCostThreshold.travelerRowNumber > maximumSpots
        ) {
            return false;
        }

        // we always want to display the last tier
        if (costThresholdsEarnings.length - 1 === index) {
            return true;
        }

        return isStartOdd
            ? currentCostThreshold.travelerRowNumber % 2 > 0
            : currentCostThreshold.travelerRowNumber % 2 === 0;
    });
};

const CONTAINER_WIDTH_EXPANDED = 12;
const CONTAINER_WIDTH_COLLAPSED = 10;

interface ProjectedEarningsProps {
    costThresholdsEarnings: ThresholdEarning[];
    maxValue: number;
    serviceFee: number;
    transactionFee: number;
    minimumSpots: number;
    maximumSpots: number;
}

const ProjectedEarnings: React.FC<ProjectedEarningsProps> = ({
    costThresholdsEarnings,
    maxValue,
    serviceFee,
    transactionFee,
    minimumSpots,
    maximumSpots,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [containerSize, setContainerSize] = useState<GridSize>(
        CONTAINER_WIDTH_COLLAPSED,
    );
    const [thresholdEarnings, setThresholdEarnings] = useState(
        costThresholdsEarnings,
    );

    useEffect(() => {
        const everyOtherThresholdEarnings = getEveryOtherThresholdEarnings(
            costThresholdsEarnings,
            minimumSpots,
            maximumSpots,
        );
        setThresholdEarnings(everyOtherThresholdEarnings);
    }, [costThresholdsEarnings, minimumSpots, maximumSpots]);

    const onShowExpanded = (): void => {
        const updatedShow = !isExpanded;
        setIsExpanded(updatedShow);
        setContainerSize(
            updatedShow ? CONTAINER_WIDTH_EXPANDED : CONTAINER_WIDTH_COLLAPSED,
        );
    };

    const trovatripFeeTableHeader = `TrovaTrip ${serviceFee}% fee`;
    const transactionFeeTableHeader = `Transaction ${transactionFee}% fee`;

    return (
        <Grid container spacing={32}>
            <Grid item xs={12} sm={10}>
                <BaseBox marginY={4}>
                    <Heading as={'h4'}>Potential Earnings</Heading>
                </BaseBox>
                <Text>
                    This chart breaks down your potential earnings at various
                    group sizes. Adjust your early bird and regular price until
                    you reach your earnings goals.
                </Text>
            </Grid>
            <Grid
                item
                sm={containerSize}
                md={containerSize}
                lg={containerSize}
                className={containerTableStyles}
            >
                <Table className={tableStyles(isExpanded)}>
                    <TableHead>
                        <Row>
                            <Cell
                                width={135}
                                colSpan={2}
                                style={{ padding: 0 }}
                            >
                                <BaseBox data-toggle paddingY={2}>
                                    <Toggle
                                        name={'fees-toggle'}
                                        detail={
                                            isExpanded
                                                ? 'Hide Cost Breakdown'
                                                : 'Show Cost Breakdown'
                                        }
                                        onChange={onShowExpanded}
                                        value={isExpanded}
                                    />
                                </BaseBox>
                            </Cell>
                        </Row>
                        <Row>
                            <Cell width={100}>Number of Travelers</Cell>

                            {isExpanded && (
                                <>
                                    <Cell
                                        width={120}
                                        className={collapsibleColumnStyles}
                                    >
                                        Base Cost per Traveler
                                    </Cell>
                                    <Cell
                                        width={90}
                                        className={collapsibleColumnStyles}
                                    >
                                        {trovatripFeeTableHeader}
                                    </Cell>
                                    <Cell
                                        width={90}
                                        className={collapsibleColumnStyles}
                                    >
                                        {transactionFeeTableHeader}
                                    </Cell>
                                </>
                            )}
                            <Cell width={125}>Cost per Traveler</Cell>
                            <Cell width={125}>Total Projected Earnings</Cell>
                            <Hidden smDown>
                                <Cell width={'auto'}>{''}</Cell>
                            </Hidden>
                        </Row>
                    </TableHead>
                    <TableBody>
                        {thresholdEarnings.map((costThreshold) => {
                            const {
                                travelerRowNumber,
                                totalEarnings,
                                costPerTraveler,
                                serviceCost,
                                transactionCost,
                                priceWithFixedCost,
                            } = costThreshold;

                            const formattedTotalEarnings =
                                totalEarnings < 0
                                    ? `-$${Math.abs(totalEarnings)}`
                                    : `$${totalEarnings}`;

                            return (
                                <Row key={travelerRowNumber}>
                                    <Cell>{travelerRowNumber}</Cell>
                                    {isExpanded && (
                                        <>
                                            <Cell
                                                width={90}
                                                className={
                                                    collapsibleColumnStyles
                                                }
                                            >{`$${priceWithFixedCost}`}</Cell>
                                            <Cell
                                                width={90}
                                                className={
                                                    collapsibleColumnStyles
                                                }
                                            >{`$${serviceCost}`}</Cell>

                                            <Cell
                                                width={90}
                                                className={
                                                    collapsibleColumnStyles
                                                }
                                            >{`$${transactionCost}`}</Cell>
                                        </>
                                    )}
                                    <Cell>{`$${costPerTraveler}`}</Cell>
                                    <Cell>{formattedTotalEarnings}</Cell>
                                    <Hidden smDown>
                                        <Cell>
                                            {totalEarnings > 0 ? (
                                                <HorizontalLineGraph
                                                    minValue={MIN}
                                                    maxValue={maxValue}
                                                    value={totalEarnings}
                                                />
                                            ) : null}
                                        </Cell>
                                    </Hidden>
                                </Row>
                            );
                        })}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
};

export default ProjectedEarnings;
