import React, { useLayoutEffect } from 'react';
import { microApps } from '@trova-trip/trova-common';
import { noop } from 'lodash';
import { StyledMicroAppHostWrapper } from './MicroAppHostWrapper.styles';

export const TrovaTripMicroAppsConfig = microApps.TrovaTripMicroApps;
interface MicroAppCatalogOptions {
    catalogType: 'itineraries' | 'trips';
    actionSubscriptions: {
        onCatalogListItemSelected?: (args: any) => void;
        onItineraryCardRender?: (args: any) => void;
    };
}
export interface MicroAppHostWrapperProps
    extends Partial<MicroAppCatalogOptions> {
    renderTargetId: string;
    microAppLocationUri: string;
    microAppModuleType: microApps.MicroAppsModuleTypes;
    microAppProps?: Record<string, any>;
    onModuleImported: (moduleObject: Record<string, any>) => void;
    header?: React.ReactNode;
}

const MicroAppHostWrapper: React.FC<MicroAppHostWrapperProps> = ({
    renderTargetId,
    microAppLocationUri,
    microAppModuleType = 'esm',
    microAppProps = {},
    onModuleImported = noop,
    children,
    header,
    ...catalogOptions
}) => {
    const modulePath = `${microAppLocationUri}?type=${microAppModuleType}`;
    useLayoutEffect(() => {
        const errorMessage = 'Could not load TrovaTripModule';
        (async () => {
            try {
                const { TrovaTripModule } = await import(
                    /* webpackIgnore: true */ modulePath
                );
                if (TrovaTripModule) {
                    TrovaTripModule.renderComponentAs({
                        renderTargetId,
                        catalogType:
                            catalogOptions.catalogType || 'itineraries',
                        actionSubscriptions:
                            catalogOptions.actionSubscriptions || {},
                    })({ ...microAppProps });
                    onModuleImported(TrovaTripModule);
                } else {
                    throw new Error(errorMessage);
                }
            } catch (err) {
                throw err;
            }
        })();
    }, []);

    return (
        <StyledMicroAppHostWrapper>
            {header ? header : null}
            <section id={renderTargetId} />
            {children}
        </StyledMicroAppHostWrapper>
    );
};

export default MicroAppHostWrapper;
