import { useState } from 'react';
import { models } from '@trova-trip/trova-models';
import { Number as NumberInput } from '@trova-trip/trova-components';

import InlineToggleInput from '../InlineToggleInput/InlineToggleInput';
import FakeEvent from '../../../../util/form/FakeEvent';
import {
    FormInputNames,
    HostSelectedOptionalService,
} from './TripRequestForm.types';

type DayService = models.services.DayService & { day: number };

interface OptionalActivitiesInputProps {
    optionalActivities: DayService[];
    hostSelectedOptionalActivities: HostSelectedOptionalService[];
    numberOfHostsAndCompanions: number;
    onChange: (event: FakeEvent) => void;
    editMode: boolean;
}

const getServiceIndex = (
    id: string,
    hostSelectedOptionalActivities: HostSelectedOptionalService[],
): number => {
    return hostSelectedOptionalActivities.findIndex(
        (dayService) => dayService.id === id,
    );
};

const getFreeOfChargeValue = (
    id: string,
    hostSelectedOptionalActivities: HostSelectedOptionalService[],
): number => {
    const serviceIndex = getServiceIndex(id, hostSelectedOptionalActivities);
    const serviceValue =
        hostSelectedOptionalActivities[serviceIndex]?.numberOptingIn || 0;

    return serviceValue;
};

const OptionalActivitiesInput = ({
    optionalActivities,
    hostSelectedOptionalActivities = [],
    numberOfHostsAndCompanions,
    onChange,
    editMode,
}: OptionalActivitiesInputProps): JSX.Element => {
    const [numberOfHostsError, setNumberOfHostsError] = useState({});

    const handleOptionalActivitiesInputChange = (
        id: string,
        service: models.services.Service,
    ): void => {
        const hostSelectedOptionalServicesValue = [
            ...hostSelectedOptionalActivities,
        ];
        const serviceIndex = getServiceIndex(
            id,
            hostSelectedOptionalActivities,
        );

        if (serviceIndex > -1) {
            hostSelectedOptionalServicesValue.splice(serviceIndex, 1);
        } else {
            hostSelectedOptionalServicesValue.push({
                id: id,
                service,
                numberOptingIn: 0,
            });
        }

        const inputEvent = new FakeEvent(
            FormInputNames.HOST_SELECTED_OPTIONAL_SERVICES,
            hostSelectedOptionalServicesValue,
        );
        onChange(inputEvent);
    };

    const handleOptionalActivitiesCompanionChange = (
        value: number,
        id: string,
    ): void => {
        const numberOfHostsErrorMessage = `${numberOfHostsAndCompanions} Host + Companions Limit`;
        if (value && value > numberOfHostsAndCompanions) {
            setNumberOfHostsError((currentState) => {
                return {
                    ...currentState,
                    [id]: numberOfHostsErrorMessage,
                };
            });
        } else {
            setNumberOfHostsError((currentState) => {
                return { ...currentState, [id]: '' };
            });

            const hostSelectedOptionalServicesValue = [
                ...hostSelectedOptionalActivities,
            ];

            const serviceIndex = getServiceIndex(
                id,
                hostSelectedOptionalActivities,
            );

            hostSelectedOptionalServicesValue[serviceIndex].numberOptingIn =
                value || 0;

            const inputEvent = new FakeEvent(
                FormInputNames.HOST_SELECTED_OPTIONAL_SERVICES,
                hostSelectedOptionalServicesValue,
            );

            onChange(inputEvent);
        }
    };
    return (
        <>
            {optionalActivities.map(({ service, day }) => {
                const actualService = service as models.services.Service;
                const {
                    name: activityName,
                    description: activityDescription,
                    _id,
                } = actualService;
                const actualId = _id as string;
                const value =
                    getServiceIndex(actualId, hostSelectedOptionalActivities) >
                    -1;
                const isCustomInputDisabled = !value || !editMode;

                return (
                    <InlineToggleInput
                        key={actualId}
                        title={`Day ${day}:  ${activityName}`}
                        onChange={(_, name): void => {
                            handleOptionalActivitiesInputChange(
                                name,
                                actualService,
                            );
                        }}
                        description={activityDescription}
                        name={actualId}
                        value={value}
                        label={'Add Activity'}
                        customInput={
                            <NumberInput
                                label={'Attending Host + Companions'}
                                name={'free-of-charge'}
                                value={getFreeOfChargeValue(
                                    actualId,
                                    hostSelectedOptionalActivities,
                                )}
                                onChange={(_, __, value): void => {
                                    const actualValue = value ? value : 0;
                                    handleOptionalActivitiesCompanionChange(
                                        actualValue,
                                        actualId,
                                    );
                                }}
                                disabled={isCustomInputDisabled}
                                minValue={0}
                                error={numberOfHostsError[actualId]}
                            />
                        }
                        disabled={!editMode}
                    />
                );
            })}
        </>
    );
};

export default OptionalActivitiesInput;
