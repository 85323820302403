import { businessConstants } from '@trova-trip/trova-common';
import { useSelector } from '../../../state/hooks';
import useModelStatusMatcher from '../../common/hooks/useModelStatusMatcher';
import { StatefulModel } from '../../types';

export enum ProfileCompletionStatuses {
    COMPLETE = 'COMPLETE',
    INCOMPLETE = 'INCOMPLETE',
    REQUIRED = 'REQUIRED',
}
interface UseProfileCompletionStatusReturn {
    generalStatus: ProfileCompletionStatuses;
    addressStatus: ProfileCompletionStatuses;
    publicProfileStatus: ProfileCompletionStatuses;
    aggregatedStatus: ProfileCompletionStatuses;
}

const defaultProfileCompletionStatus = {
    generalStatus: ProfileCompletionStatuses.COMPLETE,
    addressStatus: ProfileCompletionStatuses.COMPLETE,
    publicProfileStatus: ProfileCompletionStatuses.COMPLETE,
    aggregatedStatus: ProfileCompletionStatuses.COMPLETE,
};

const useProfileCompletionStatus = (): UseProfileCompletionStatusReturn => {
    const { current: currentProfile } = useSelector((state) => state.profile);
    const isHostQualified = useModelStatusMatcher({
        model: StatefulModel.PROFILE,
        matchingStatuses: businessConstants.QualifiedHostStatuses,
    });

    if (!currentProfile?.profileCompletionStatus) {
        return defaultProfileCompletionStatus;
    }

    const { general, address, aggregated, publicProfile } =
        currentProfile.profileCompletionStatus;

    const warningStatus = isHostQualified
        ? ProfileCompletionStatuses.REQUIRED
        : ProfileCompletionStatuses.INCOMPLETE;

    const getProfileCompletionStatus = (profileStatus: boolean) =>
        profileStatus ? ProfileCompletionStatuses.COMPLETE : warningStatus;

    return {
        generalStatus: getProfileCompletionStatus(general),
        addressStatus: getProfileCompletionStatus(address),
        publicProfileStatus: getProfileCompletionStatus(publicProfile),
        aggregatedStatus: getProfileCompletionStatus(aggregated),
    };
};

export default useProfileCompletionStatus;
