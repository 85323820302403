import FailureContent from './FailureContent';
import SuccessContent from './SuccessContent';

interface ExportDataResponseProps {
    isExportSuccess: boolean;
    onClickExport: () => void;
    onClickClose: () => void;
}

const ExportDataResponseContent = ({
    isExportSuccess,
    onClickExport,
    onClickClose,
}: ExportDataResponseProps): JSX.Element => {
    return isExportSuccess ? (
        <SuccessContent onClickClose={onClickClose} />
    ) : (
        <FailureContent
            onClickPrimary={onClickExport}
            onClickSecondary={onClickClose}
        />
    );
};

export default ExportDataResponseContent;
