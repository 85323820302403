import FetchState from './utils/FetchState';
import { post } from './utils/api';
import { models } from '@trova-trip/trova-models';

type User = models.users.User;

type BaseUser = models.users.BaseUser;

export type UserStoreSelector = {
    users: { current?: BaseUser; fetching?: boolean };
};

const users = FetchState.create(`api/admin/users`);
const usersMetadata = FetchState.create(`api/metadata/users`);

const usersMetadataReducer = usersMetadata.getReducer();
const usersReducer = users.getReducer();

const resetUserToken = (userId: string): Promise<unknown> =>
    post(`api/admin/users/reset-token`, { userId });

const impersonate = (userId): Promise<User> =>
    post(`api/admin/users/impersonate/${userId}`, {}) as Promise<User>;

export {
    users,
    usersMetadata,
    usersReducer,
    usersMetadataReducer,
    impersonate,
    resetUserToken,
};
