import {
    BaseBox,
    Cell,
    Heading,
    Row,
    TableBody,
    TableHead,
    Text,
} from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import { constants } from '@trova-trip/trova-models';
import PropTypes from 'prop-types';
import TooltipButton from '../../../../../common/components/TooltipButton';
import TableHeader from '../../../../components/TableHeader/TableHeader';
import {
    useAddRow,
    useAddTier,
    useDeleteRow,
    useDeleteTier,
    useUpdateCostThresholdsHandler,
    useUpdateDateTimeHandler,
    useUpdateDaysOfTheWeekHandler,
    useUpdateHandler,
    useValidateDaysOfWeek,
} from '../../../hooks/useValidityPeriods';
import CardSection from '../../common/CardSection';
import CostThresholdsCell from './CostThresholdsCell';
import ValidityPeriodRow from './ValidityPeriodRow';
import {
    StyledMainContainerTable,
    StyleTable,
} from './ValidityPeriods.components';

const packageLevels = constants.itinerary.PackageLevel;

const BlackoutDatesTooltip = () => (
    <Text as='p' color='neutral.white' size='sm'>
        These are days when trips cannot begin. While trips can still occur on
        blackout dates, they are restricted from <strong>starting</strong> on
        these specific days.
    </Text>
);

const ValidityPeriods = ({
    validityPeriods,
    updateValidityPeriods,
    currentCurrency,
    type,
}) => {
    const addRow = useAddRow(validityPeriods, updateValidityPeriods);
    const deleteRow = useDeleteRow(validityPeriods, updateValidityPeriods);
    const addTier = useAddTier(validityPeriods, updateValidityPeriods);
    const deleteTier = useDeleteTier(validityPeriods, updateValidityPeriods);
    const updateCostThresholdsHandler = useUpdateCostThresholdsHandler(
        validityPeriods,
        updateValidityPeriods,
    );
    const updateHandler = useUpdateHandler(
        validityPeriods,
        updateValidityPeriods,
    );
    const validateDaysOfWeek = useValidateDaysOfWeek(
        validityPeriods,
        updateValidityPeriods,
        addRow,
        deleteRow,
    );
    const updateDaysOfTheWeekHandler = useUpdateDaysOfTheWeekHandler(
        validityPeriods,
        updateValidityPeriods,
        validateDaysOfWeek,
    );
    const updateDateTimeHandler = useUpdateDateTimeHandler(updateHandler);

    const indexedValidityPeriods =
        (validityPeriods && validityPeriods.length && validityPeriods) || [];

    const indexedCostThresholds =
        (validityPeriods &&
            validityPeriods.length &&
            validityPeriods[0].costThresholds) ||
        [];

    const hideDeleteButton = validityPeriods.length === 1;
    const shouldShowTier = !!validityPeriods.length;

    const title = `${type.charAt(0).toLocaleUpperCase()}${type.slice(
        1,
    )} Package Pricing`;

    const packageLevelsWithoutIA = [
        packageLevels.ECONOMY,
        packageLevels.PREMIUM,
    ];

    return (
        <StyledMainContainerTable>
            <TableHeader>
                <Heading as={'h4'}>{title}</Heading>
                {shouldShowTier && (
                    <Button
                        variant='tertiary'
                        color='blueGray.600'
                        rightIcon='plus'
                        onClick={addTier}
                    >
                        Add tier
                    </Button>
                )}
            </TableHeader>

            <CardSection width='full' marginTop={2}>
                <StyleTable>
                    <TableHead>
                        <Row>
                            <Cell>Start Date</Cell>
                            <Cell>End Date</Cell>
                            <Cell className='blackout-th'>
                                Blackout Start Dates
                                <TooltipButton
                                    icon='help'
                                    ariaLabel='Additional Blackout Start Dates Information'
                                    tooltip={<BlackoutDatesTooltip />}
                                />
                            </Cell>
                            <Cell>Instant Approval</Cell>
                            <Cell>Days of week</Cell>
                            <Cell>Single supplement</Cell>
                            <Cell>Travelers</Cell>
                        </Row>
                        <Row>
                            <Cell />
                            <Cell />
                            <Cell />
                            <Cell />
                            <Cell />
                            <Cell />
                            {indexedCostThresholds.map(
                                (threshold, thresholdIndex) => (
                                    <CostThresholdsCell
                                        index={thresholdIndex}
                                        key={threshold._id}
                                        numberOfTravelers={
                                            threshold.numberOfTravelers
                                        }
                                        deleteTier={deleteTier}
                                        updateHandler={
                                            updateCostThresholdsHandler
                                        }
                                    />
                                ),
                            )}
                        </Row>
                    </TableHead>
                    <TableBody>
                        {indexedValidityPeriods.map((validityPeriod, index) => (
                            <ValidityPeriodRow
                                key={validityPeriod.id}
                                index={index}
                                disableInstantApproval={packageLevelsWithoutIA.includes(
                                    type,
                                )}
                                validityPeriod={validityPeriod}
                                deleteRow={deleteRow}
                                updateHandler={updateHandler}
                                updateDateTimeHandler={updateDateTimeHandler}
                                updateDaysOfTheWeekHandler={
                                    updateDaysOfTheWeekHandler
                                }
                                hideDeleteButton={hideDeleteButton}
                                currentCurrency={currentCurrency}
                            />
                        ))}
                    </TableBody>
                </StyleTable>
            </CardSection>
            <BaseBox marginY={4}>
                <Button
                    variant='secondary'
                    rightIcon='plus'
                    size='sm'
                    onClick={() =>
                        addRow(validityPeriods, updateValidityPeriods)
                    }
                >
                    Add validity period
                </Button>
            </BaseBox>
        </StyledMainContainerTable>
    );
};

ValidityPeriods.propTypes = {
    validityPeriods: PropTypes.array,
    updateValidityPeriods: PropTypes.func,
    currentCurrency: PropTypes.string,
};

export default ValidityPeriods;
