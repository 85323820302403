import { coreUtils } from '@trova-trip/trova-common';
import { constants, models } from '@trova-trip/trova-models';
import { useCallback } from 'react';
import {
    TravelerTripNavigationCategories,
    modelTypes,
} from '../../../config/constants';
import { NavigationCategory } from '../../../interfaces/Navigation.types';
import { useSelector } from '../../../state/hooks/useSelector';
import { useListModelsRecords } from '../../../util/hooks/useListModelsRecords';
import { createNavigationCategoriesMapFromObject } from '../../../util/navigationUtils';
import {
    getNavigationCategoryStatus,
    getTravelerPathByProductAndTab,
} from '../utils';

const { getStartEndDates } = coreUtils.dateUtils;

type SavedBooking = models.bookings.SavedBooking;
type TravelerStatus = constants.travelers.TravelerStatus;
type TripModel = models.trips.Trip;

interface TripWithBooking extends TripModel {
    booking?: SavedBooking;
}

const createSortableTripCategories = (
    tripsRecords: TripWithBooking[],
    travelerStatus: TravelerStatus,
): NavigationCategory[] => {
    const navigationCategories = createNavigationCategoriesMapFromObject(
        TravelerTripNavigationCategories,
    );

    tripsRecords.forEach((tripData) => {
        const { name, id, startDate, servicesByDay, booking } = tripData;
        const tripId = id as string;

        const bookingStatus = getNavigationCategoryStatus(
            tripData,
            booking,
            travelerStatus,
        );

        const category =
            bookingStatus && navigationCategories.get(bookingStatus);
        const [formattedStartDate, formattedEndDate] = getStartEndDates(
            startDate?.toString() || '',
            servicesByDay?.length || 0,
            'll',
        );

        if (category) {
            category.items.push({
                id: tripId,
                title: name,
                subtitle: `${formattedStartDate} - ${formattedEndDate}`,
                link: getTravelerPathByProductAndTab('TRIPS', 'TRIP_OVERVIEW', {
                    tripId,
                }),
                query: { model: modelTypes.TRIP },
            });
        }
    });

    const navigationCategoriesWithContent = Array.from(
        navigationCategories.values(),
    ).filter(({ items }) => items.length);

    return navigationCategoriesWithContent;
};

const formatNavigationData = (
    tripsRecords: TripModel[],
    travelerStatus: TravelerStatus,
): NavigationCategory[] => {
    const sortableTrips = createSortableTripCategories(
        tripsRecords as TripWithBooking[],
        travelerStatus,
    );

    return sortableTrips;
};

const useLoadNavigationContent = (): (() => Promise<
    NavigationCategory[] | undefined
>) => {
    const listModels = useListModelsRecords({
        trip: { fetch: true },
    });

    const currentTraveler = useSelector(
        (state) => state.userTripsTraveler.list?.records?.[0],
    );

    //@ts-expect-error
    // This const has the TS annotation, to avoid a type error, because the
    // type on the root store does not match with what it actually returns.
    const { status: travelerStatus } = currentTraveler || {};

    const loadNavigationContent = useCallback(async () => {
        const { tripResult } = await listModels();

        return (
            (tripResult &&
                formatNavigationData(tripResult.records, travelerStatus)) ||
            []
        );
    }, []);

    return loadNavigationContent;
};

export default useLoadNavigationContent;
