class FakeEvent {
    constructor(name, value) {
        this.target = {
            name,
            value,
        };
    }
    preventDefault() {}
}
export default FakeEvent;
