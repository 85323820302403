import { coreUtils } from '@trova-trip/trova-common';
import {
    Heading,
    IconSvgProps,
    Stack,
    SummaryDetailItem,
    Text,
} from '@trova-trip/trova-components';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import format from 'date-fns/format';
import {
    JourneyStage,
    Quote,
} from '../../../../../../state/features/transfer/types';
import { pluralize } from '../../../../../utils';
import { isTraveler, parseQuoteVehicleCategory } from '../../../../helpers';
import { useTransfer } from '../../hooks';
import FromToSummaryItem, { FromToProps } from '../FromToSummaryItem';

const { formatUSD } = coreUtils.currencyUtils;

type SectionHeaderProps = {
    stage: string;
    subtotal: number;
};

const SectionHeader = (props: SectionHeaderProps): JSX.Element => {
    const { stage, subtotal } = props;

    const {
        globalState: { user },
    } = useTransfer();

    const shouldShowSubtotal = subtotal > 0 && isTraveler(user);

    return (
        <Stack width='full' align='center' justify='space-between'>
            <Heading
                as='h5'
                color='blueGray.800'
                fontWeight='medium'
                fontSize='base'
            >
                {stage}
            </Heading>
            {shouldShowSubtotal ? (
                <Text color='blueGray.800' fontWeight='medium' fontSize='base'>
                    {formatUSD(subtotal, 2)}
                </Text>
            ) : null}
        </Stack>
    );
};

type SectionItemProps = {
    icon: IconSvgProps['as'];
    description: string;
};

const SectionItem = (props: SectionItemProps): JSX.Element => (
    <SummaryDetailItem
        leftIconProps={{
            color: 'teal.trova',
            as: props.icon,
            spacing: 2,
        }}
        description={props.description}
    />
);

type JourneyDetailsSectionProps = SectionHeaderProps & {
    date: Date;
    from: FromToProps;
    to: FromToProps;
    vehicleCategory?: Quote['vehicleCategory'];
    passengers: Quote['passengerCapacity'];
    showDivider?: boolean;
};

const JourneyDetailsSection = (
    props: JourneyDetailsSectionProps,
): JSX.Element => {
    const {
        date,
        from,
        to,
        vehicleCategory,
        showDivider,
        passengers,
        stage,
        subtotal,
    } = props;

    const localDate = utcToZonedTime(date, 'UTC');
    const formattedDate = format(localDate, 'MM/dd/yyyy, hh:mm a');

    return (
        <Stack
            align='flex-start'
            direction='column'
            spacing={3}
            borderTop={showDivider ? '1px solid' : undefined}
            paddingTop={showDivider ? 5 : undefined}
            marginTop={showDivider ? 5 : undefined}
            borderColor='blueGray.300'
        >
            <SectionHeader stage={stage} subtotal={subtotal} />
            <SectionItem icon='timer' description={formattedDate} />
            <FromToSummaryItem from={from} to={to} />
            {vehicleCategory ? (
                <SectionItem
                    icon='directionsCar'
                    description={vehicleCategory}
                />
            ) : null}
            <SectionItem
                icon='people'
                description={pluralize(passengers, 'Passenger', 'Passengers')}
            />
        </Stack>
    );
};

type JourneyStageSummarySectionProps = {
    stage: JourneyStage;
    showDivider?: boolean;
};

const JourneyStageSummarySection = (
    props: JourneyStageSummarySectionProps,
): JSX.Element | null => {
    const { stage, showDivider } = props;

    const {
        state: {
            [stage]: {
                pickupDateTime,
                pickup,
                dropOff,
                passengers,
                selectedQuote,
            },
        },
    } = useTransfer();

    const stageTitle =
        stage === 'arrival' ? 'Arriving Transfer' : 'Departing Transfer';

    if (!pickupDateTime || !pickup || !dropOff || !passengers) return null;

    const fromProps: FromToProps = {
        description: pickup.name,
        additionalInformation: pickup.description,
        types: pickup.types,
        icon: stage === 'arrival' ? 'flightLand' : 'pinDrop',
    };

    const toProps: FromToProps = {
        description: dropOff.name,
        additionalInformation: dropOff.description,
        types: dropOff.types,
        icon: stage === 'arrival' ? 'pinDrop' : 'flightTakeoff',
    };

    const parsedVehicleCategory = parseQuoteVehicleCategory(
        selectedQuote?.vehicleCategory || '',
    );

    return (
        <JourneyDetailsSection
            date={pickupDateTime}
            from={fromProps}
            to={toProps}
            vehicleCategory={parsedVehicleCategory}
            passengers={passengers}
            stage={stageTitle}
            subtotal={selectedQuote?.price || 0}
            showDivider={showDivider}
        />
    );
};

export default JourneyStageSummarySection;
