import React from 'react';
import { models } from '@trova-trip/trova-models';
import { Language } from '@trova-trip/country-data';
import { ValidationError } from '../../../utils/formErrors.utils';

export type HostProfileResponse = models.hostProfiles.HostProfileResponse;
export type HostProfileRequest = models.hostProfiles.HostProfileRequest;

export enum PublicProfileFieldNames {
    DISPLAY_NAME = 'displayName',
    INTERESTS = 'categoryInterests',
    COUNTRY = 'country',
    PROFILE_PICTURE = 'profilePictureUrl',
    BIO = 'bio',
    LANGUAGES = 'languages',
    PRONOUNS = 'pronouns',
    SOCIAL_CHANNELS = 'socialChannels',
}

export type FieldName = Exclude<
    `${PublicProfileFieldNames}`,
    'profilePictureUrl'
>;

export type HostProfileFormValues = Pick<
    HostProfileResponse,
    | 'displayName'
    | 'categoryInterests'
    | 'country'
    | 'profilePictureUrl'
    | 'bio'
    | 'languages'
    | 'pronouns'
    | 'socialChannels'
>;

export type PreviewProps<Field extends FieldName> = {
    [key in Field]?: HostProfileResponse[key];
};

export interface EditFieldProps<Field extends FieldName> {
    fieldData?: HostProfileResponse[Field] | undefined;
    errors?: ValidationError;
    onChange: (
        data: HostProfileResponse[Field] | Language,
        shouldDisable?: boolean,
    ) => void;
}

export interface FieldConfiguration<Field extends FieldName> {
    title: string;
    description?: {
        subtitle?: string;
        text: string;
    };
    isRequired?: boolean;
    preview: React.ComponentType<PreviewProps<Field>>;
    editField: React.ComponentType<EditFieldProps<Field>>;
    formatDataBeforeSubmit?: (
        data: HostProfileResponse[Field],
    ) => HostProfileResponse[Field];
}

export type FieldMap = {
    [key in FieldName]: FieldConfiguration<key>;
};
