import { ComponentProps, useEffect, useState } from 'react';
import { StickyContentLayout as StickyContentLayoutTC } from '@trova-trip/trova-components';
import useIsLargeScreen from 'applications/common/hooks/useIsLargeScreen';

type StickyContentLayoutTCProps = ComponentProps<typeof StickyContentLayoutTC>;

const offsetTopInitialValue = 20;

const StickyContentLayout = (
    props: StickyContentLayoutTCProps,
): JSX.Element => {
    const [offsetTop, setOffsetTop] = useState(offsetTopInitialValue);
    const isLargeScreen = useIsLargeScreen();

    useEffect(() => {
        if (!isLargeScreen) {
            return setOffsetTop(100);
        }
        setOffsetTop(offsetTopInitialValue);
    }, [isLargeScreen]);

    return <StickyContentLayoutTC {...props} offsetTop={offsetTop} />;
};

export default StickyContentLayout;
