/*issue with CRA4 and React 17: https://github.com/emotion-js/emotion/issues/2041*/
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import useIsLargeScreen from 'applications/common/hooks/useIsLargeScreen';
import { Card, Text, BaseBox } from '@trova-trip/trova-components';
import { innerCard } from './ServicesSearch.styles';
import {
    getColorFromServiceType,
    getServiceTitleLabel,
    getTitleForService,
} from '../../../common/components/services/LibraryServiceFormatter';

const LibraryServiceCard = ({ service, onServiceCardClicked }) => {
    const color = getColorFromServiceType(service.type);
    const title = getServiceTitleLabel(service.type);
    const name = getTitleForService(service);

    const isLargeScreen = useIsLargeScreen();
    const onCardClick = !isLargeScreen ? onServiceCardClicked : () => {};

    return (
        <div onClick={onCardClick}>
            <Card css={innerCard()}>
                <Text fontWeight={'bold'} size={'sm'} color={color}>
                    {title}
                </Text>
                <BaseBox marginY={2}>
                    <Text fontWeight={'bold'} size={'sm'}>
                        {name}
                    </Text>
                </BaseBox>
                <Text size={'sm'} color={'blueGray.700'}>
                    {service.description}
                </Text>
            </Card>
        </div>
    );
};

LibraryServiceCard.propTypes = {
    service: PropTypes.shape(),
    onServiceCardClicked: PropTypes.func,
};

export default LibraryServiceCard;
