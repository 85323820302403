import { userBookings } from '../../../state/userBookings';

interface UseUserBookingsActionsReturn {
    /**
     * Fetches user bookings for a provided trip and sets them on the global store.
     *
     * @param tripId - The id of the trip for which bookings are to be fetched.
     * @return void
     */
    loadUserBookings: (tripId: string) => void;

    /**
     * Clears the current user bookings from the global store.
     */
    clearUserBookings: () => void;
}

/**
 * Hook to get the functions to interact with the `userBookings` global store slice.
 *
 * @returns UseUserBookingsActionsReturn - An object containing functions to fetch and clear user bookings.
 */
const useUserBookingsActions = (): UseUserBookingsActionsReturn => {
    const { getRecord, clearCurrentRecord: clearUserBookings } =
        userBookings.useDispatch();

    const loadUserBookings = (tripId: string): void => {
        getRecord(undefined, { trip: tripId });
    };

    return {
        loadUserBookings,
        clearUserBookings,
    };
};

export default useUserBookingsActions;
