import { models } from '@trova-trip/trova-models';
import {
    HostNavigationCategoriesKeys,
    HostNavigationCategories,
    HostNavigationCategoriesTripStatuses,
    HostNavigationCategoriesTripRequestStatuses,
    modelTypes,
} from 'config/constants';
import { NavigationCategory } from 'interfaces/Navigation.types';
import { useEffect, useState } from 'react';
import { useListModelsRecords } from 'util/hooks/useListModelsRecords';
import {
    addItemToNavigationCategory,
    createNavigationCategoriesMapFromObject,
} from 'util/navigationUtils';
import { getTripLink } from '../utils';

const { requests, pendingTrips, liveTrips, completedTrips, cancelledTrips } =
    HostNavigationCategoriesKeys;

const getNavigationContent = (
    tripRecords: models.trips.Trip[] | undefined,
    tripRequestRecords: models.tripRequest.TripRequest[] | undefined,
): NavigationCategory[] => {
    const navigationCategories = createNavigationCategoriesMapFromObject(
        HostNavigationCategories,
    );

    tripRecords?.forEach((tripItem: models.trips.Trip) => {
        if (tripItem.id) {
            let matchingCategoryKey: undefined | string = undefined;
            const tripLink = getTripLink({
                model: modelTypes.TRIP,
                id: tripItem.id,
                status: tripItem.status,
            });
            const model = modelTypes.TRIP;

            switch (true) {
                case HostNavigationCategoriesTripStatuses[requests].has(
                    tripItem.status,
                ):
                    matchingCategoryKey = requests;
                    break;
                case HostNavigationCategoriesTripStatuses[pendingTrips].has(
                    tripItem.status,
                ):
                    matchingCategoryKey = pendingTrips;
                    break;
                case HostNavigationCategoriesTripStatuses[liveTrips].has(
                    tripItem.status,
                ):
                    matchingCategoryKey = liveTrips;
                    break;
                case HostNavigationCategoriesTripStatuses[completedTrips].has(
                    tripItem.status,
                ):
                    matchingCategoryKey = completedTrips;
                    break;
                case HostNavigationCategoriesTripStatuses[cancelledTrips].has(
                    tripItem.status,
                ):
                    matchingCategoryKey = cancelledTrips;
                    break;

                default:
                    matchingCategoryKey = undefined;
                    break;
            }

            if (matchingCategoryKey) {
                const matchingCategory =
                    navigationCategories.get(matchingCategoryKey);

                matchingCategory &&
                    addItemToNavigationCategory(
                        tripItem,
                        model,
                        matchingCategory,
                        tripLink,
                    );
            }
        }
    });

    tripRequestRecords?.forEach(
        (tripRequestItem: models.tripRequest.TripRequest) => {
            if (tripRequestItem.id) {
                let matchingCategoryKey: undefined | string = undefined;
                const tripRequestLink = getTripLink({
                    model: modelTypes.TRIP_REQUEST,
                    id: tripRequestItem.id,
                    status: tripRequestItem.status,
                });
                const model = modelTypes.TRIP_REQUEST;

                HostNavigationCategoriesTripRequestStatuses[requests].has(
                    tripRequestItem.status,
                )
                    ? (matchingCategoryKey = requests)
                    : (matchingCategoryKey = undefined);

                if (matchingCategoryKey) {
                    const matchingCategory =
                        navigationCategories.get(matchingCategoryKey);
                    matchingCategory &&
                        addItemToNavigationCategory(
                            tripRequestItem,
                            model,
                            matchingCategory,
                            tripRequestLink,
                        );
                }
            }
        },
    );

    const categories = Array.from(navigationCategories.values()).filter(
        ({ items }) => items.length,
    );
    return categories;
};

export default function useLoadNavigationContent(): [
    NavigationCategory[] | undefined,
    () => void,
] {
    const [navigationContent, setNavigationContent] = useState<
    NavigationCategory[] | undefined
    >();

    const listModels = useListModelsRecords({
        trip: { fetch: true },
        tripRequest: { fetch: true },
    });
    const loadNavigationContent = async (): Promise<void> => {
        const { tripResult, tripRequestResult } = await listModels();

        const navigationContent = getNavigationContent(
            tripResult?.records,
            tripRequestResult?.records,
        );
        setNavigationContent(navigationContent);
    };

    useEffect(() => {
        loadNavigationContent();
    }, []);

    const refreshNavigationContent = (): void => {
        loadNavigationContent();
    };

    return [navigationContent, refreshNavigationContent];
}
