import { Text, TextProps, BaseBox } from '@trova-trip/trova-components';

interface TabPageDescriptionProps extends TextProps {}

const TabPageDescription = (props: TabPageDescriptionProps): JSX.Element => {
    const { children } = props;

    return (
        <BaseBox marginTop={'5'} maxWidth={'1024px'}>
            <Text>{children}</Text>
        </BaseBox>
    );
};

export default TabPageDescription;
