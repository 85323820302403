import { coreUtils } from '@trova-trip/trova-common';
import { constants } from '@trova-trip/trova-models';
import capitalize from 'lodash/capitalize';
import differenceBy from 'lodash/differenceBy';
import isEmpty from 'lodash/isEmpty';
import { validOccupancies } from '../../../../../state/features/manageBooking/utils';
import { filterInsuranceAddOns } from '../../../utils';
import {
    Accommodation,
    Accommodations,
    Activities,
    AddOnManageMode,
    AddOnServiceType,
    AddOnType,
    AirportTransfer,
    BaseUser,
    Booking,
    BookingAddOn,
    BookingWithTrip,
    MANAGE_BOOKING_ADDON_STATUS,
    ManageBookingAddOn,
    ManageBookingAddOnMap,
    OccupancyType,
    SavedBooking,
    Service,
    ServiceTiming,
    ServiceType,
    Transfers,
    TravelersRooms,
    Trip,
    TripAddOn,
} from '../types';
import {
    getDefaultRoomOccupancy,
    getDefaultTravelersRooms,
    getTravelerRoomStatus,
} from '../utils/extract.utils';

type CommonMapAddOnsParams = {
    bookingAddOns: ManageBookingAddOn[];
    tripAddOns: ManageBookingAddOn[];
};

type MapTransfersAndActivitiesAddOnsParams = CommonMapAddOnsParams & {
    booking: Booking;
};

type FilterAddOnsByTypeAndServiceTypeParams = {
    addOns: ManageBookingAddOn[];
    type: AddOnType;
    serviceType?: AddOnServiceType;
};

type FilterAndMergeAddOnsParams = {
    bookingAddOns?: ManageBookingAddOn[];
    tripAddOns?: ManageBookingAddOn[];
    filterParams: {
        type: AddOnType;
        serviceType?: AddOnServiceType;
    };
};

type FilterAndMergeAddOnsReturn = {
    addOnsFromBooking: ManageBookingAddOn[];
    addOnsFromTrip: ManageBookingAddOn[];
    missingAddOnsFromTrip: ManageBookingAddOn[];
    mergedAddOns: ManageBookingAddOn[];
};

const {
    idGeneratorUtils: { generateShortId },
} = coreUtils;

const { AddOnTypes } = constants.bookings;

const VALID_ADDON_TYPES = [
    AddOnTypes.ACTIVITY,
    AddOnTypes.SINGLE_SUPPLEMENT,
    AddOnTypes.ADDITIONAL_SERVICE,
] as const;

/**
 * Retrieves an array of unique traveler IDs from a booking.
 * @param booking
 * @returns An array containing unique traveler IDs.
 */
const getTravelersIdsFromBooking = (booking: SavedBooking): string[] => {
    const mainTravelerId = (booking?.customer as BaseUser)?._id as string;

    const additionalParticipantsIds = booking?.additionalParticipants?.map(
        (participant) => {
            return (participant as BaseUser)?._id as string;
        },
    );

    const uniqueIdSet = new Set<string>(additionalParticipantsIds);

    return [mainTravelerId, ...uniqueIdSet];
};

/**
 * Adds status and manage mode to a booking add-on.
 * @param addOn
 * @param status
 * @param manageMode
 * @returns A new booking add-on object with added status and manage mode.
 */
const addStatusAndManageModeToAddOn = (
    addOn: BookingAddOn,
    status: MANAGE_BOOKING_ADDON_STATUS,
    manageMode: AddOnManageMode,
): ManageBookingAddOn => ({
    ...addOn,
    status,
    manageMode,
});

/**
 * Creates a manage booking add-on object from a trip add-on.
 * @param addon
 * @returns A new manage booking add-on object.
 */
const createManageBookingAddOn = (addon: TripAddOn): ManageBookingAddOn => ({
    ...addon,
    addOnId: generateShortId(),
    quantity: 1,
    deleted: false,
    status: MANAGE_BOOKING_ADDON_STATUS.AVAILABLE,
    user: undefined,
    manageMode: 'default',
});

/**
 * Removes deleted add-ons from the provided add-ons array.
 * @param addOns
 * @returns An array containing non-deleted booking add-ons.
 */
const removeDeletedAddOns = (addOns: BookingAddOn[]): BookingAddOn[] => {
    return addOns?.filter((addOn) => !addOn.deleted);
};

/**
 * Checks if the provided add-on is of type `BookingAddOn`, depending on if it has the `addOnId` property.
 * @param addOn
 * @returns `true` if the add-on is of type `BookingAddOn`, otherwise `false`.
 */
const isAddOnType = (
    addOn: TripAddOn | BookingAddOn,
): addOn is BookingAddOn => {
    return 'addOnId' in addOn;
};

/**
 * Transform diverse addOn types into ManageBookingAddOns with status.
 *
 * @param addOns - Addons to be transformed.
 * @returns An array of ManageBookingAddOns with associated status.
 */
const transformAddOnsToManageBookingAddOns = (
    addOns: Array<TripAddOn | BookingAddOn>,
): ManageBookingAddOn[] => {
    return addOns?.reduce((transformedAddOns, addOn) => {
        const isValidAddOnType = VALID_ADDON_TYPES.includes(
            addOn.type as AddOnType,
        );

        if (!isValidAddOnType) {
            return [...transformedAddOns, addOn] as ManageBookingAddOn[];
        }

        if (isAddOnType(addOn)) {
            return [
                ...transformedAddOns,
                addStatusAndManageModeToAddOn(
                    addOn,
                    MANAGE_BOOKING_ADDON_STATUS.CONFIRMED,
                    'default',
                ),
            ];
        }

        return [...transformedAddOns, createManageBookingAddOn(addOn)];
    }, []);
};

/**
 * Filters addons by type and or service type
 *
 * @param param.addOns - array of addons to filter
 * @param param.type - addon type to filter by
 * @param param.serviceType - service type to filter by
 * @returns array of filtered addons
 */
const filterAddOnsByTypeAndServiceType = ({
    addOns,
    type,
    serviceType,
}: FilterAddOnsByTypeAndServiceTypeParams): ManageBookingAddOn[] => {
    return addOns.filter((addOn) =>
        !serviceType
            ? addOn.type === type
            : addOn.type === type &&
              (addOn.service as Service)?.type === serviceType,
    );
};

/**
 * Gets the difference between two arrays of addons
 *
 * @param bookingAddOns - Array of addons from booking
 * @param tripAddOns - Array of addons from trip
 * @param comparatorKey - Key to compare addons by
 * @returns An array of addons that are in tripAddOns but not in bookingAddOns
 */
const getDifferenceBetweenAddOns = (
    bookingAddOns: ManageBookingAddOn[],
    tripAddOns: ManageBookingAddOn[],
    comparatorKey: string,
): ManageBookingAddOn[] => {
    return differenceBy(tripAddOns, bookingAddOns, comparatorKey);
};

/**
 * Checks if the timing of a service is `PRE_TRIP` or `POST_TRIP`
 * @param timing
 * @returns `true` if the timing is `PRE_TRIP` or `POST_TRIP`, `false` otherwise.
 */
const isPreOrPostTripServiceTiming = (timing: string | undefined): boolean => {
    return (
        timing === ServiceTiming.PRE_TRIP || timing === ServiceTiming.POST_TRIP
    );
};

/**
 * Filters addons from booking and trip by type and service type, and gets the difference between the two.
 * Also returns the booking addons merged with the difference between the two.
 *
 * @param param.bookingAddOns - addons from booking
 * @param param.tripAddOns - addons from trip
 * @param param.filter - filter params
 * @returns an object with filtered addons from booking, filtered addons from trip, the difference between the two and the merged addons
 */
const filterAndMergeAddOns = ({
    bookingAddOns,
    tripAddOns,
    filterParams,
}: FilterAndMergeAddOnsParams): FilterAndMergeAddOnsReturn => {
    const { type, serviceType } = filterParams;

    const addOnsFromBooking = filterAddOnsByTypeAndServiceType({
        addOns: bookingAddOns || [],
        type,
        serviceType,
    });

    const addOnsFromTrip = filterAddOnsByTypeAndServiceType({
        addOns: tripAddOns || [],
        type,
        serviceType,
    });

    const missingAddOnsFromTrip = getDifferenceBetweenAddOns(
        addOnsFromBooking,
        addOnsFromTrip,
        'service._id',
    );

    const mergedAddOns = [...addOnsFromBooking, ...missingAddOnsFromTrip];

    return {
        addOnsFromBooking,
        addOnsFromTrip,
        missingAddOnsFromTrip,
        mergedAddOns,
    };
};

const filterAddOnsByUserId = (
    addOns: ManageBookingAddOn[],
    userId: string,
): ManageBookingAddOn[] => {
    return addOns.filter((addOn) => addOn.user === userId);
};

/**
 * Maps activities addons array to an object of activities addons
 *
 * @param param.booking - booking object
 * @param param.bookingAddOns - addons from booking
 * @param param.tripAddOns - addons from trip
 * @returns map of activities addons
 */
const mapActivitiesAddOns = ({
    booking,
    bookingAddOns,
    tripAddOns,
}: MapTransfersAndActivitiesAddOnsParams): Activities => {
    const travelersIds = getTravelersIdsFromBooking(booking);

    const {
        addOnsFromBooking: activitiesFromBooking,
        addOnsFromTrip: activitiesFromTrip,
    } = filterAndMergeAddOns({
        tripAddOns,
        bookingAddOns,
        filterParams: {
            type: AddOnTypes.ACTIVITY,
        },
    });

    const getActivitiesAddOnsMap = (
        mergedActivitiesAddOns: ManageBookingAddOn[],
    ): ManageBookingAddOnMap => {
        if (!mergedActivitiesAddOns.length) {
            return {};
        }
        const mergedActivitiesMap = mergedActivitiesAddOns.reduce(
            (activitiesMap, activity) => {
                const addOnIdKey = activity.addOnId;
                activitiesMap[addOnIdKey] = activity;
                return activitiesMap;
            },
            {},
        );
        return mergedActivitiesMap;
    };

    const activitiesAddOnsMap: Activities = travelersIds.reduce(
        (activitiesMap, userId) => {
            const activitiesFromBookingByUser = filterAddOnsByUserId(
                activitiesFromBooking,
                userId,
            );

            const missingActivitiesAddOnsFromTripByUser =
                getDifferenceBetweenAddOns(
                    activitiesFromBookingByUser,
                    activitiesFromTrip,
                    'service._id',
                );

            const mergedActivitiesAddOns = [
                ...activitiesFromBookingByUser,
                ...missingActivitiesAddOnsFromTripByUser,
            ];

            activitiesMap[userId] = getActivitiesAddOnsMap(
                mergedActivitiesAddOns,
            );
            return activitiesMap;
        },
        {},
    );

    return activitiesAddOnsMap;
};

/**
 * Maps accommodation addons array to an object of accommodation addons
 *
 * @param param.bookingAddOns - addons from booking
 * @param param.tripAddOns - addons from trip
 * @returns map of accommodation addons
 */
const mapAccommodationsAddOns = ({
    bookingAddOns,
    tripAddOns,
}: CommonMapAddOnsParams): Accommodations => {
    const accommodationsFilterParams = {
        type: AddOnTypes.ADDITIONAL_SERVICE as AddOnType,
        serviceType: ServiceType.ACCOMMODATION,
    };

    const { mergedAddOns: mergedAccommodationsAddOns } = filterAndMergeAddOns({
        tripAddOns,
        bookingAddOns,
        filterParams: accommodationsFilterParams,
    });

    const accommodationsAddOnsMap: Accommodations =
        mergedAccommodationsAddOns.reduce(
            (accommodationsMap, accommodation) => {
                const { service } = accommodation;
                const { timing, occupancy } = service as Accommodation;
                const occupancyKey = capitalize(occupancy);

                if (!timing || !isPreOrPostTripServiceTiming(timing)) {
                    return accommodationsMap;
                }

                if (!accommodationsMap[timing]) {
                    accommodationsMap[timing] = {};
                }

                if (!accommodationsMap[timing][occupancyKey]) {
                    accommodationsMap[timing][occupancyKey] = {
                        ...accommodation,
                        quantity: 0,
                    };
                }

                accommodationsMap[timing][occupancyKey].quantity +=
                    accommodation.quantity;

                return accommodationsMap;
            },
            {},
        );

    return accommodationsAddOnsMap;
};

/**
 * Maps single supplement addons array to an object of single supplement addons
 *
 * @param param.bookingAddOns - addons from booking
 * @param param.tripAddOns - addons from trip
 * @returns map of single supplement addons
 */
const mapSingleSupplementAddOns = ({
    bookingAddOns,
    tripAddOns,
}: CommonMapAddOnsParams): Accommodations['singleSupplement'] => {
    const {
        addOnsFromBooking: singleSupplementFromBooking,
        addOnsFromTrip: singleSupplementFromTrip,
    } = filterAndMergeAddOns({
        tripAddOns,
        bookingAddOns,
        filterParams: {
            type: AddOnTypes.SINGLE_SUPPLEMENT,
        },
    });

    const isSingleSupplementConfirmed = !!singleSupplementFromBooking.length;
    const isSingleSupplementAvailable = !!singleSupplementFromTrip.length;
    const shouldMapSingleSupplement =
        isSingleSupplementConfirmed || isSingleSupplementAvailable;

    if (!shouldMapSingleSupplement) {
        return undefined;
    }

    const singleSupplementAddOnsQuantity = singleSupplementFromBooking.length;

    const singleSupplementAddOnsToMap = isSingleSupplementConfirmed
        ? singleSupplementFromBooking
        : singleSupplementFromTrip;

    return singleSupplementAddOnsToMap
        .slice(0, 1)
        .map((addOn) => {
            const hasUser = !!addOn.user;

            return {
                ...addOn,
                quantity: hasUser ? singleSupplementAddOnsQuantity : 0,
            };
        })
        .shift();
};

/**
 * Maps transfers addons array to an object of transfers addons,
 *
 * @param param.bookingAddOns - addons from booking
 * @param param.tripAddOns - addons from trip
 * @returns Map of transfers addons
 */
const mapTransfersAddOns = ({
    booking,
    bookingAddOns,
    tripAddOns,
}: MapTransfersAndActivitiesAddOnsParams): Transfers => {
    const travelersIds = getTravelersIdsFromBooking(booking);

    const transfersFilterParams = {
        type: AddOnTypes.ADDITIONAL_SERVICE as AddOnType,
        serviceType: ServiceType.AIRPORT_TRANSFER,
    };

    const {
        addOnsFromBooking: transfersFromBooking,
        addOnsFromTrip: transfersFromTrip,
    } = filterAndMergeAddOns({
        tripAddOns,
        bookingAddOns,
        filterParams: transfersFilterParams,
    });

    const transfersAvailable =
        !!transfersFromTrip.length || !!transfersFromBooking.length;

    if (!transfersAvailable) {
        return {};
    }

    const getTransfersAddOnsMap = (
        mergedTransfersAddOns: ManageBookingAddOn[],
    ): ManageBookingAddOnMap => {
        if (!mergedTransfersAddOns.length) {
            return {};
        }
        const mergedTransfersMap = mergedTransfersAddOns.reduce(
            (transfersMap, transfer) => {
                const { service } = transfer;
                const { timing } = service as AirportTransfer;

                if (!timing) return transfersMap;

                transfersMap[timing] = transfer;

                return transfersMap;
            },
            {},
        );
        return mergedTransfersMap;
    };

    const transfersAddOnsMap: Transfers = travelersIds.reduce(
        (transfersMap, userId) => {
            const transfersFromBookingByUser = filterAddOnsByUserId(
                transfersFromBooking,
                userId,
            );

            const missingTransfersAddOnsFromTripByUser =
                getDifferenceBetweenAddOns(
                    transfersFromBookingByUser,
                    transfersFromTrip,
                    'service._id',
                );

            const mergedTransfersAddOns = [
                ...transfersFromBookingByUser,
                ...missingTransfersAddOnsFromTripByUser,
            ];

            transfersMap[userId] = getTransfersAddOnsMap(mergedTransfersAddOns);

            return transfersMap;
        },
        {},
    );

    return transfersAddOnsMap;
};

/**
 * Determines travelers rooms from booking to a object of travelers rooms with room id and occupancy status
 *
 * @param booking - booking object
 * @returns Map of travelers rooms
 */
const determineTravelersRooms = (
    booking: BookingWithTrip,
    trip: Trip,
): TravelersRooms => {
    if (!booking?.travelersRooms) return {};

    const travelersQuantity = booking.totalSpotsBooked;

    const singleSupplementAddOns = booking?.addOns.filter(
        (addOn) =>
            addOn.type === AddOnTypes.SINGLE_SUPPLEMENT && !addOn.deleted,
    );

    const isMultipleTravelersBooking = travelersQuantity > 1;

    const isUpgradeToPrivateRoomSelected =
        singleSupplementAddOns.length === travelersQuantity &&
        isMultipleTravelersBooking;

    const hasSingleSupplementAddOn = !!singleSupplementAddOns.length;

    /**
     * If the upgrade to private room is confirmed from the booking, we ned to get the default travelers rooms
     * because, in case of a downgrade, we need the default to map the correct travelers rooms.
     */

    let travelersRoomsToMap = booking.travelersRooms;

    if (isUpgradeToPrivateRoomSelected) {
        const defaultOccupancy = getDefaultRoomOccupancy(
            trip.additionalOptionalServices,
        );

        travelersRoomsToMap = getDefaultTravelersRooms(
            travelersQuantity,
            defaultOccupancy,
        );
    }

    const travelersRoomsWithRoomId = travelersRoomsToMap.map(
        (travelerRoom, index) => ({
            ...travelerRoom,
            roomId: index,
        }),
    );

    const travelersRoomsMap = travelersRoomsWithRoomId.reduce(
        (travelerRoomsMap, travelerRoom) => {
            const { roomId, roomType } = travelerRoom;

            return validOccupancies.reduce((occupanciesMap, occupancy) => {
                occupanciesMap[roomId] = occupanciesMap[roomId] || {};

                occupanciesMap[roomId][occupancy] = getTravelerRoomStatus(
                    roomType,
                    occupancy,
                    isUpgradeToPrivateRoomSelected,
                    isMultipleTravelersBooking,
                    hasSingleSupplementAddOn,
                );

                return occupanciesMap;
            }, travelerRoomsMap);
        },
        {},
    );

    return travelersRoomsMap;
};

/**
 * Maps the default room occupancy based on the `travelersRooms` of the booking and trip `additionalOptionalServices`.
 *
 * @param booking
 * @param trip
 * @returns The default room occupancy type, or `undefined` if it cannot be determined.
 */
const mapDefaultRoomOccupancy = (
    booking: Booking,
    trip: Trip,
): OccupancyType | undefined => {
    // If the booking has travelers rooms, we don't need to map the default room occupancy because it's already set.
    if (!isEmpty(booking.travelersRooms)) {
        return undefined;
    }

    const defaultRoomOccupancy = getDefaultRoomOccupancy(
        trip?.additionalOptionalServices,
    );

    return defaultRoomOccupancy;
};

/**
 * Maps the insurance add-ons to an array.
 *
 * @param booking
 * @return An array of insurance booking add-ons.
 */
const mapInsuranceAddOns = (booking: Booking): BookingAddOn[] => {
    return filterInsuranceAddOns(booking);
};

export {
    VALID_ADDON_TYPES,
    addStatusAndManageModeToAddOn,
    createManageBookingAddOn,
    determineTravelersRooms,
    filterAddOnsByTypeAndServiceType,
    filterAddOnsByUserId,
    filterAndMergeAddOns,
    getDifferenceBetweenAddOns,
    getTravelersIdsFromBooking,
    isAddOnType,
    isPreOrPostTripServiceTiming,
    mapAccommodationsAddOns,
    mapActivitiesAddOns,
    mapDefaultRoomOccupancy,
    mapInsuranceAddOns,
    mapSingleSupplementAddOns,
    mapTransfersAddOns,
    removeDeletedAddOns,
    transformAddOnsToManageBookingAddOns,
};
