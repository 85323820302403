import { Grid } from '@material-ui/core';
import {
    BaseBox,
    ComponentWidth,
    DatePicker,
    Dropdown,
    FormGroup,
    Heading,
    Input,
    Stack,
    Text,
    TimePicker,
    useToast,
} from '@trova-trip/trova-components';
import { Button, Checkbox } from '@trova-trip/trova-components/build/next';
import { constants, models } from '@trova-trip/trova-models';
import GenderDropdown from '../../../common/components/Dropdown/GenderDropdown';
import { ROUTE_TOKENS } from '../../../common/constants';
import cloneDeep from 'lodash/cloneDeep';
import noop from 'lodash/noop';
import startCase from 'lodash/startCase';
import moment, { Moment } from 'moment';
import React, { SyntheticEvent } from 'react';
import { withRouter } from 'react-router';
import { analyticsTypes, withAnalytics } from '../../../../analytics';
import { createCountriesDropdownOptions } from '../../../../config/constants';
import Tab from '../../../../navigation/Tab';
import { updateTripTraveler } from '../../../../state/tripsTraveler';
import FormSaver from '../../../../util/form/FormSaver';
import { generateDropdownOptionsFromObject } from '../../../../util/form/dropdown';
import SimpleAutoCompleteDropdown from '../../../common/components/Dropdown/SimpleAutoCompleteDropdown';
import { Traveler } from '../../../types';
import FormError from '../../components/FormError/FormError';
import {
    SectionSubTitle,
    SectionTitle,
    StyledInputHeight,
} from './TripForm.components';
import { END_OF_TRIP_PLANS, PREFERENCES } from './constants';
import { TRIP_FORM_FIELD_NAME } from '../../../common/types/tripForms.types';
import { formatPassportInfo, formatStringDate } from '../../../common/helpers';

type UserProfile = models.users.BaseUser;

const Gender = constants.user.Gender;

const { TRAVELER_APP_TAB_ROUTE } = ROUTE_TOKENS;

interface ButtonProps
    extends analyticsTypes.WithAnalyticsProps,
        React.ComponentProps<typeof Button> {}

const ButtonWithAnalytics = withAnalytics<ButtonProps>(Button);

const formFields = [
    'flightInformation.arrivalDetails.flightNumber',
    'flightInformation.arrivalDetails.date',
    'flightInformation.arrivalDetails.time',
    'flightInformation.departureDetails.flightNumber',
    'flightInformation.departureDetails.date',
    'flightInformation.departureDetails.time',
    'emergencyContact.name',
    'emergencyContact.relationship',
    'emergencyContact.phone',
    'emergencyContact.email',
    'endOfTripPlans',
];
const passportInformation = [
    'passportInformation.passportNumber',
    'passportInformation.nationality',
    'passportInformation.issueDate',
    'passportInformation.expirationDate',
    'passportInformation.firstName',
    'passportInformation.lastName',
    'passportInformation.dateOfBirth',
    'passportInformation.gender',
];

interface TripPropsType {
    tripId: string;
    passportRequired: string;
    formSaver: Traveler;
    user: Traveler & { id: string };
    profile: UserProfile;
    showToast: (props: any) => void;
    onSubmit: () => void;
}

interface TripState {
    error: Record<string, string> | null;
}

const getDefaultData = (
    dataObject: Traveler,
    profile: UserProfile,
): Traveler => {
    const { flightInformation, passportInformation } = dataObject || {};
    const { arrivalDetails, departureDetails } = flightInformation || {};

    return {
        ...dataObject,
        flightInformation: {
            arrivalDetails: {
                airline: arrivalDetails?.airline,
                flightNumber: arrivalDetails?.flightNumber,
                date: arrivalDetails?.date
                    ? formatStringDate(arrivalDetails?.date)
                    : '',
                time: arrivalDetails?.time,
            },
            departureDetails: {
                airline: departureDetails?.airline,
                flightNumber: departureDetails?.flightNumber,
                date: departureDetails?.date
                    ? formatStringDate(departureDetails?.date)
                    : '',
                time: departureDetails?.time,
            },
        },
        passportInformation: formatPassportInfo(passportInformation, profile),
    };
};

const withToast = (Component) => {
    const WrappedComponent = (props): JSX.Element => {
        const toast = useToast();
        return <Component showToast={toast} {...props} />;
    };

    WrappedComponent.displayName = 'withToast';

    return WrappedComponent;
};

class TripForm extends React.Component<TripPropsType, TripState> {
    formSaver: FormSaver;

    constructor(props: any) {
        super(props);
        this.state = {
            error: null,
        };
        const dataObject = this.convertPreferencesArraysToObjects(
            this.props.user,
        );
        const defaultData = getDefaultData(dataObject, this.props.profile);
        this.formSaver = new FormSaver(
            this,
            (data: any) => {
                this.updateUser(this.props.tripId, this.props.user.id, data);
            },
            defaultData,
        );
    }

    componentDidUpdate(prevProps: TripPropsType): void {
        const user = this.props.user;
        const tripId = this.props.tripId;
        if (user?.id !== prevProps.user?.id || tripId !== prevProps.tripId) {
            const dataObject = this.convertPreferencesArraysToObjects(
                this.props.user,
            );
            const defaultData = getDefaultData(dataObject, this.props.profile);

            this.formSaver.setDefaultFormData(defaultData);
            this.formSaver.reset();
        }
    }

    // The Record<string, never> type is from the Traveler type.
    convertPreferenceArrayToObject = (
        preferences: string[] | Record<string, never>,
        preferenceMap: Record<string, string>,
    ): Record<string, never> => {
        const preferencesObject = {};
        if (preferences && Array.isArray(preferences)) {
            preferences.forEach((preference) => {
                Object.entries(preferenceMap).forEach(
                    ([preferenceKey, preferenceValue]) => {
                        if (preference === preferenceValue) {
                            preferencesObject[preferenceKey] = true;
                        }
                    },
                );
            });
        }
        return preferencesObject;
    };

    convertPreferencesArraysToObjects = (data: Traveler) => {
        const cloneData = cloneDeep(data);

        if (!cloneData.disclosuresAndPreferences) {
            cloneData.disclosuresAndPreferences = {
                description: '',
            };
        }

        const currentPreferences =
            cloneData.disclosuresAndPreferences.preferences || [];
        cloneData.disclosuresAndPreferences.preferences =
            this.convertPreferenceArrayToObject(
                currentPreferences,
                PREFERENCES,
            );

        const currentRoommateGenderPreferences =
            cloneData.disclosuresAndPreferences.roommateGenderPreferences || [];
        cloneData.disclosuresAndPreferences.roommateGenderPreferences =
            this.convertPreferenceArrayToObject(
                currentRoommateGenderPreferences,
                Gender,
            );

        return cloneData;
    };

    convertPreferencesObjectToArray = (
        data: Traveler,
        preference: 'preferences' | 'roommateGenderPreferences',
        preferenceMap: Record<string, string>,
    ) => {
        const currentPreferences =
            data.disclosuresAndPreferences?.[preference] || {};
        return Object.entries(currentPreferences)
            .filter(([_key, isChecked]) => isChecked)
            .map(([key]) => preferenceMap[key]);
    };

    updateUser = (tripId: string, travelerId: string, data: Traveler) => {
        const cloneData = cloneDeep(data);

        if (!cloneData.disclosuresAndPreferences) {
            cloneData.disclosuresAndPreferences = {
                description: '',
            };
        }

        const currentPreferences =
            cloneData.disclosuresAndPreferences.preferences;
        if (currentPreferences) {
            cloneData.disclosuresAndPreferences.preferences =
                this.convertPreferencesObjectToArray(
                    cloneData,
                    'preferences',
                    PREFERENCES,
                );
        }

        const currentRoommateGenderPreferences =
            cloneData.disclosuresAndPreferences.roommateGenderPreferences;
        if (currentRoommateGenderPreferences) {
            cloneData.disclosuresAndPreferences.roommateGenderPreferences =
                this.convertPreferencesObjectToArray(
                    cloneData,
                    'roommateGenderPreferences',
                    Gender,
                );
        }

        updateTripTraveler(tripId, travelerId, cloneData).then((response) => {
            const data = this.convertPreferencesArraysToObjects(response.data);
            this.formSaver.setDefaultFormData({
                ...getDefaultData(data, this.props.profile),
            });
            this.props.showToast({
                title: 'Trip Form saved',
                description: 'Your Trip Form has been successfully saved!',
                status: 'success',
                isClosable: true,
            });
            this.props.onSubmit();
        });
    };

    checkInputField = (field: string) => {
        if (this.formSaver.getData(field)) {
            return true;
        }
        return false;
    };

    checkPassportRequired = () => {
        return !!this.props.passportRequired;
    };

    validateFormFields = () => {
        const error: Record<string, string> = {};
        let missingFields = false;

        const formFieldsRequired = this.checkPassportRequired()
            ? [...formFields, ...passportInformation]
            : formFields;

        formFieldsRequired.forEach((field: string) => {
            const response = this.checkInputField(field);
            if (!response) {
                missingFields = true;
                error[field] = 'Required';
            }
        });

        if (missingFields) {
            return error;
        }
        return null;
    };

    submit = (event: SyntheticEvent) => {
        const error = this.validateFormFields();
        this.setState({ error });
        if (!error) {
            this.formSaver.handleFormSubmit(event);
        }
    };

    handleDropdownChangeEvent = (
        event: SyntheticEvent,
        name: string,
        value: string,
    ) => {
        const wrappedEvent = { ...event, target: { value, name } };
        this.formSaver.handleChange(wrappedEvent);
    };

    handleDatePickerChangeEvent = (event: Moment | string, name: string) => {
        const value = moment.isMoment(event)
            ? event.utc().startOf('day')
            : moment(event, 'MM/DD/YYYY').utc().startOf('day');
        this.formSaver.handleChange({
            target: { name, value: value.isValid() ? value : '' },
        });
    };

    handleTimePickerChangeEvent = (
        event: string | React.SyntheticEvent<Element, Event> | Moment,
        name: string,
    ) => {
        this.formSaver.handleChange({
            target: {
                name,
                value: event,
            },
        });
    };

    render() {
        const { error } = this.state;
        return (
            <Tab label={'Trip Form'} path={TRAVELER_APP_TAB_ROUTE.TRIP_FORM}>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Heading as={'h2'}>Trip Form</Heading>
                    </Grid>
                    <Grid item xs={12}>
                        <SectionTitle>Flight Information</SectionTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <SectionSubTitle>Arrival</SectionSubTitle>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={4} lg={3}>
                                <Input
                                    name='flightInformation.arrivalDetails.airline'
                                    label='Airline'
                                    value={this.formSaver.getData(
                                        'flightInformation.arrivalDetails.airline',
                                    )}
                                    onChange={this.formSaver.handleChange}
                                    size={ComponentWidth.Flexible}
                                />
                                <FormError
                                    error={error}
                                    formField={
                                        'flightInformation.arrivalDetails.airline'
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} lg={3}>
                                <Input
                                    name='flightInformation.arrivalDetails.flightNumber'
                                    label='Flight Number'
                                    value={this.formSaver.getData(
                                        'flightInformation.arrivalDetails.flightNumber',
                                    )}
                                    onChange={this.formSaver.handleChange}
                                    size={ComponentWidth.Flexible}
                                />
                                <FormError
                                    error={error}
                                    formField={
                                        'flightInformation.arrivalDetails.flightNumber'
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} lg={3}>
                                <DatePicker
                                    name='flightInformation.arrivalDetails.date'
                                    placeholder='MM/DD/YYYY'
                                    label='Date of Arrival'
                                    value={this.formSaver.getData(
                                        'flightInformation.arrivalDetails.date',
                                    )}
                                    onChange={(event) => {
                                        this.handleDatePickerChangeEvent(
                                            event,
                                            'flightInformation.arrivalDetails.date',
                                        );
                                    }}
                                    size={ComponentWidth.Flexible}
                                />
                                <FormError
                                    error={error}
                                    formField={
                                        'flightInformation.arrivalDetails.date'
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} lg={3}>
                                <TimePicker
                                    name='flightInformation.arrivalDetails.time'
                                    placeholder='Time of Arrival'
                                    label='Time of Arrival'
                                    value={this.formSaver.getData(
                                        'flightInformation.arrivalDetails.time',
                                    )}
                                    onChange={(event) => {
                                        this.handleTimePickerChangeEvent(
                                            event,
                                            'flightInformation.arrivalDetails.time',
                                        );
                                    }}
                                    size={ComponentWidth.Flexible}
                                    ampm
                                />
                                <FormError
                                    error={error}
                                    formField={
                                        'flightInformation.arrivalDetails.time'
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <SectionSubTitle>Departure</SectionSubTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={4} lg={3}>
                                <Input
                                    name='flightInformation.departureDetails.airline'
                                    label='Airline'
                                    value={this.formSaver.getData(
                                        'flightInformation.departureDetails.airline',
                                    )}
                                    onChange={this.formSaver.handleChange}
                                    size={ComponentWidth.Flexible}
                                />
                                <FormError
                                    error={error}
                                    formField={
                                        'flightInformation.departureDetails.airline'
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                                <Input
                                    name='flightInformation.departureDetails.flightNumber'
                                    label='Flight Number'
                                    value={this.formSaver.getData(
                                        'flightInformation.departureDetails.flightNumber',
                                    )}
                                    onChange={this.formSaver.handleChange}
                                    size={ComponentWidth.Flexible}
                                />
                                <FormError
                                    error={error}
                                    formField={
                                        'flightInformation.departureDetails.flightNumber'
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                                <DatePicker
                                    name='flightInformation.departureDetails.date'
                                    placeholder='MM/DD/YYYY'
                                    label='Date of Departure'
                                    value={this.formSaver.getData(
                                        'flightInformation.departureDetails.date',
                                    )}
                                    onChange={(event) => {
                                        this.handleDatePickerChangeEvent(
                                            event,
                                            'flightInformation.departureDetails.date',
                                        );
                                    }}
                                    size={ComponentWidth.Flexible}
                                />
                                <FormError
                                    error={error}
                                    formField={
                                        'flightInformation.departureDetails.date'
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                                <TimePicker
                                    name='flightInformation.departureDetails.time'
                                    placeholder='Time of Departure'
                                    label='Time of Departure'
                                    value={this.formSaver.getData(
                                        'flightInformation.departureDetails.time',
                                    )}
                                    onChange={(event) => {
                                        this.handleTimePickerChangeEvent(
                                            event,
                                            'flightInformation.departureDetails.time',
                                        );
                                    }}
                                    size={ComponentWidth.Flexible}
                                    ampm
                                />
                                <FormError
                                    error={error}
                                    formField={
                                        'flightInformation.departureDetails.time'
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={4} lg={3}>
                                <Dropdown
                                    name='endOfTripPlans'
                                    label='What are your post-trip plans?'
                                    value={this.formSaver.getData(
                                        'endOfTripPlans',
                                    )}
                                    onChange={this.handleDropdownChangeEvent}
                                    onSearch={noop}
                                    size={ComponentWidth.Flexible}
                                >
                                    {generateDropdownOptionsFromObject(
                                        END_OF_TRIP_PLANS,
                                    )}
                                </Dropdown>
                                <FormError
                                    error={error}
                                    formField={'endOfTripPlans'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <SectionTitle>Travel Insurance</SectionTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={4} lg={3}>
                                <Input
                                    name='travelInsurance.policyNumber'
                                    placeholder='Policy #'
                                    label='Travel Insurance Policy #'
                                    value={this.formSaver.getData(
                                        'travelInsurance.policyNumber',
                                    )}
                                    onChange={this.formSaver.handleChange}
                                    size={ComponentWidth.Flexible}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                                <Input
                                    name='travelInsurance.carrier'
                                    label='Travel Insurance Carrier'
                                    value={this.formSaver.getData(
                                        'travelInsurance.carrier',
                                    )}
                                    onChange={this.formSaver.handleChange}
                                    size={ComponentWidth.Flexible}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <SectionTitle>Passport Information</SectionTitle>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Text>
                            Please complete your passport details. This helps us
                            maintain consistency with your legal identification.
                        </Text>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={4} lg={3}>
                                <Input
                                    name='passportInformation.firstName'
                                    label='Passport First Name'
                                    value={this.formSaver.getData(
                                        'passportInformation.firstName',
                                    )}
                                    onChange={this.formSaver.handleChange}
                                    size={ComponentWidth.Flexible}
                                />
                                <FormError
                                    error={error}
                                    formField={'passportInformation.firstName'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                                <Input
                                    name='passportInformation.lastName'
                                    label='Passport Last Name'
                                    value={this.formSaver.getData(
                                        'passportInformation.lastName',
                                    )}
                                    size={ComponentWidth.Flexible}
                                    onChange={this.formSaver.handleChange}
                                ></Input>
                                <FormError
                                    error={error}
                                    formField={'passportInformation.lastName'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={4} lg={3}>
                                <Input
                                    name='passportInformation.passportNumber'
                                    label='Passport Number'
                                    value={this.formSaver.getData(
                                        'passportInformation.passportNumber',
                                    )}
                                    onChange={this.formSaver.handleChange}
                                    size={ComponentWidth.Flexible}
                                />
                                <FormError
                                    error={error}
                                    formField={
                                        'passportInformation.passportNumber'
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                                <SimpleAutoCompleteDropdown
                                    name='passportInformation.nationality'
                                    label='Nationality'
                                    value={this.formSaver.getData(
                                        'passportInformation.nationality',
                                    )}
                                    size={ComponentWidth.Flexible}
                                    onChange={this.handleDropdownChangeEvent}
                                >
                                    {createCountriesDropdownOptions()}
                                </SimpleAutoCompleteDropdown>
                                <FormError
                                    error={error}
                                    formField={
                                        'passportInformation.nationality'
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={4} lg={3}>
                                <DatePicker
                                    name='passportInformation.issueDate'
                                    placeholder='MM/DD/YYYY'
                                    label='Passport Issue Date'
                                    value={this.formSaver.getData(
                                        'passportInformation.issueDate',
                                    )}
                                    onChange={(event) => {
                                        this.handleDatePickerChangeEvent(
                                            event,
                                            'passportInformation.issueDate',
                                        );
                                    }}
                                    size={ComponentWidth.Flexible}
                                />
                                <FormError
                                    error={error}
                                    formField={'passportInformation.issueDate'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                                <DatePicker
                                    name='passportInformation.expirationDate'
                                    placeholder='MM/DD/YYYY'
                                    label='Passport Expiration Date'
                                    value={this.formSaver.getData(
                                        'passportInformation.expirationDate',
                                    )}
                                    onChange={(event) => {
                                        this.handleDatePickerChangeEvent(
                                            event,
                                            'passportInformation.expirationDate',
                                        );
                                    }}
                                    size={ComponentWidth.Flexible}
                                />
                                <FormError
                                    error={error}
                                    formField={
                                        'passportInformation.expirationDate'
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={4} lg={3}>
                                <DatePicker
                                    name='passportInformation.dateOfBirth'
                                    placeholder='MM/DD/YYYY'
                                    label='Date of Birth'
                                    value={this.formSaver.getData(
                                        'passportInformation.dateOfBirth',
                                    )}
                                    onChange={(event): void => {
                                        this.handleDatePickerChangeEvent(
                                            event,
                                            'passportInformation.dateOfBirth',
                                        );
                                    }}
                                    size={ComponentWidth.Flexible}
                                />
                                <FormError
                                    error={error}
                                    formField={
                                        'passportInformation.dateOfBirth'
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                                <GenderDropdown
                                    name='passportInformation.gender'
                                    label='Gender Marker'
                                    value={this.formSaver.getData(
                                        'passportInformation.gender',
                                    )}
                                    onChange={(_, __, value): void => {
                                        this.formSaver.handleChange({
                                            target: {
                                                name: 'passportInformation.gender',
                                                value,
                                            },
                                        });
                                    }}
                                />
                                <FormError
                                    error={error}
                                    formField={'passportInformation.gender'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <SectionTitle>Emergency Contact</SectionTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={4} lg={3}>
                                <Input
                                    name='emergencyContact.name'
                                    placeholder='Contact Name'
                                    label='Emergency Contact Name'
                                    value={this.formSaver.getData(
                                        'emergencyContact.name',
                                    )}
                                    onChange={this.formSaver.handleChange}
                                    size={ComponentWidth.Flexible}
                                />
                                <FormError
                                    error={error}
                                    formField={'emergencyContact.name'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                                <Input
                                    name='emergencyContact.relationship'
                                    placeholder='Contact Relationship'
                                    label='Emergency Contact Relationship'
                                    value={this.formSaver.getData(
                                        'emergencyContact.relationship',
                                    )}
                                    onChange={this.formSaver.handleChange}
                                    size={ComponentWidth.Flexible}
                                />
                                <FormError
                                    error={error}
                                    formField={'emergencyContact.relationship'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={4} lg={3}>
                                <Input
                                    name='emergencyContact.phone'
                                    placeholder='(###) - ### - ####'
                                    label='Emergency Contact Phone'
                                    value={this.formSaver.getData(
                                        'emergencyContact.phone',
                                    )}
                                    onChange={this.formSaver.handleChange}
                                    size={ComponentWidth.Flexible}
                                />
                                <FormError
                                    error={error}
                                    formField={'emergencyContact.phone'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                                <Input
                                    name='emergencyContact.email'
                                    placeholder='example@gmail.com'
                                    label='Emergency Contact Email'
                                    value={this.formSaver.getData(
                                        'emergencyContact.email',
                                    )}
                                    onChange={this.formSaver.handleChange}
                                    size={ComponentWidth.Flexible}
                                />
                                <FormError
                                    error={error}
                                    formField={'emergencyContact.email'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <SectionTitle>
                            Allergies and Dietary Preferences
                        </SectionTitle>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={3}>
                        <StyledInputHeight
                            name='disclosuresAndPreferences.description'
                            label='Do you have any known food allergies or medical-related injuries we should be aware of? Please also note any dietary preferences you may have (vegan, pescatarian, etc).'
                            value={this.formSaver.getData(
                                'disclosuresAndPreferences.description',
                            )}
                            onChange={this.formSaver.handleChange}
                            size={ComponentWidth.Flexible}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <SectionTitle>Roommate Preferences</SectionTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <BaseBox marginTop={4}>
                                    <Heading
                                        as='h6'
                                        fontWeight='medium'
                                        lineHeight={6}
                                    >
                                        Select all that apply to help us match
                                        you with the best available roommates.
                                    </Heading>
                                </BaseBox>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={24}>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <FormGroup
                                            label='Are you an early riser?'
                                            direction='row-reverse'
                                        >
                                            <Checkbox
                                                name={
                                                    TRIP_FORM_FIELD_NAME.DISCLOSURES_PREFERENCES_EARLY_BIRD
                                                }
                                                value={this.formSaver.getData(
                                                    TRIP_FORM_FIELD_NAME.DISCLOSURES_PREFERENCES_EARLY_BIRD,
                                                )}
                                                onChange={
                                                    this.formSaver.handleChange
                                                }
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <FormGroup
                                            label='Are you a night owl?'
                                            direction='row-reverse'
                                        >
                                            <Checkbox
                                                name={
                                                    TRIP_FORM_FIELD_NAME.DISCLOSURES_PREFERENCES_NIGHT_OWL
                                                }
                                                value={this.formSaver.getData(
                                                    TRIP_FORM_FIELD_NAME.DISCLOSURES_PREFERENCES_NIGHT_OWL,
                                                )}
                                                onChange={
                                                    this.formSaver.handleChange
                                                }
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <FormGroup
                                            label='Are you an adrenaline junkie?'
                                            direction='row-reverse'
                                        >
                                            <Checkbox
                                                name={
                                                    TRIP_FORM_FIELD_NAME.DISCLOSURES_PREFERENCES_ADRENALINE_JUNKIE
                                                }
                                                value={this.formSaver.getData(
                                                    TRIP_FORM_FIELD_NAME.DISCLOSURES_PREFERENCES_ADRENALINE_JUNKIE,
                                                )}
                                                onChange={
                                                    this.formSaver.handleChange
                                                }
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <FormGroup
                                            label='Are you a leisure enthusiast?'
                                            direction='row-reverse'
                                        >
                                            <Checkbox
                                                name={
                                                    TRIP_FORM_FIELD_NAME.DISCLOSURES_PREFERENCES_LEISURE_ENTHUSIAST
                                                }
                                                value={this.formSaver.getData(
                                                    TRIP_FORM_FIELD_NAME.DISCLOSURES_PREFERENCES_LEISURE_ENTHUSIAST,
                                                )}
                                                onChange={
                                                    this.formSaver.handleChange
                                                }
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <FormGroup
                                            label='Are you a planner?'
                                            direction='row-reverse'
                                        >
                                            <Checkbox
                                                name={
                                                    TRIP_FORM_FIELD_NAME.DISCLOSURES_PREFERENCES_PLANNER
                                                }
                                                value={this.formSaver.getData(
                                                    TRIP_FORM_FIELD_NAME.DISCLOSURES_PREFERENCES_PLANNER,
                                                )}
                                                onChange={
                                                    this.formSaver.handleChange
                                                }
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <FormGroup
                                            label='Are you a wanderer?'
                                            direction='row-reverse'
                                        >
                                            <Checkbox
                                                name={
                                                    TRIP_FORM_FIELD_NAME.DISCLOSURES_PREFERENCES_WANDERER
                                                }
                                                value={this.formSaver.getData(
                                                    TRIP_FORM_FIELD_NAME.DISCLOSURES_PREFERENCES_WANDERER,
                                                )}
                                                onChange={
                                                    this.formSaver.handleChange
                                                }
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <FormGroup
                                            label='Life of the party?'
                                            direction='row-reverse'
                                        >
                                            <Checkbox
                                                name={
                                                    TRIP_FORM_FIELD_NAME.DISCLOSURES_PREFERENCES_LIFE_OF_THE_PARTY
                                                }
                                                value={this.formSaver.getData(
                                                    TRIP_FORM_FIELD_NAME.DISCLOSURES_PREFERENCES_LIFE_OF_THE_PARTY,
                                                )}
                                                onChange={
                                                    this.formSaver.handleChange
                                                }
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <FormGroup
                                            label='Lover of alone time?'
                                            direction='row-reverse'
                                        >
                                            <Checkbox
                                                name={
                                                    TRIP_FORM_FIELD_NAME.DISCLOSURES_PREFERENCES_LOVER_OF_ALONE_TIME
                                                }
                                                value={this.formSaver.getData(
                                                    TRIP_FORM_FIELD_NAME.DISCLOSURES_PREFERENCES_LOVER_OF_ALONE_TIME,
                                                )}
                                                onChange={
                                                    this.formSaver.handleChange
                                                }
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <BaseBox marginTop={4}>
                                    <Heading
                                        as='h6'
                                        fontWeight='medium'
                                        lineHeight={6}
                                    >
                                        I'd prefer a roommate who is:
                                    </Heading>
                                </BaseBox>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction='row' spacing={10}>
                                    {Object.entries(Gender).map(
                                        ([genderKey, genderLabel]) => (
                                            <FormGroup
                                                key={genderKey}
                                                label={startCase(genderLabel)}
                                                direction='row-reverse'
                                            >
                                                <Checkbox
                                                    name={TRIP_FORM_FIELD_NAME.DISCLOSURES_PREFERENCES_ROOMMATE_GENDER_PREFERENCES.replace(
                                                        '{gender}',
                                                        genderKey,
                                                    )}
                                                    value={this.formSaver.getData(
                                                        TRIP_FORM_FIELD_NAME.DISCLOSURES_PREFERENCES_ROOMMATE_GENDER_PREFERENCES.replace(
                                                            '{gender}',
                                                            genderKey,
                                                        ),
                                                    )}
                                                    onChange={
                                                        this.formSaver
                                                            .handleChange
                                                    }
                                                />
                                            </FormGroup>
                                        ),
                                    )}
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <BaseBox marginTop={8}>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12} sm={6} lg={4}>
                                            <Input
                                                name='specialRequests.roomMate'
                                                label='Request a Specific Roommate (optional)'
                                                value={this.formSaver.getData(
                                                    'specialRequests.roomMate',
                                                )}
                                                onChange={
                                                    this.formSaver.handleChange
                                                }
                                                size={ComponentWidth.Flexible}
                                            />
                                        </Grid>
                                    </Grid>
                                </BaseBox>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <SectionTitle>Special Requests</SectionTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={24}>
                            <Grid item xs={12} md={6}>
                                <Input
                                    name='specialRequests.description'
                                    label='Do you have any special requests?'
                                    value={this.formSaver.getData(
                                        'specialRequests.description',
                                    )}
                                    onChange={this.formSaver.handleChange}
                                    size={ComponentWidth.Flexible}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <ButtonWithAnalytics
                                    variant='primary'
                                    onClick={this.submit}
                                    isDisabled={!this.formSaver.hasChanged}
                                    analyticsData={{
                                        type: 'onClick',
                                        eventName:
                                            'Traveler Submitted Trip Form',
                                    }}
                                >
                                    Save
                                </ButtonWithAnalytics>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Tab>
        );
    }
}

export default withRouter(withToast(TripForm));
