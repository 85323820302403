import React, { useContext } from 'react';

import ServicesSearch from '../../../components/ServicesSearch/ServicesSearch';
import ServicesModalWrapper from './ServicesModalWrapper';
import ServicesButtons from './ServicesButtons';
import ConfigureServicesContext, {
    ConfigureServicesContextValue,
} from 'applications/common/ConfigureServicesContext';
import { servicesSearchStyles } from './MobileServices.styles';

interface MobileServicesProps {
    showNewServicesModal: boolean;
    showServicesSearchModal: boolean;
    onCloseNewServicesModal: () => void;
    onCloseLibraryServicesSearch: () => void;
    numberOfDays: number;
    scrollToDay: () => void;
}

const MobileServices: React.FC<MobileServicesProps> = ({
    showNewServicesModal,
    showServicesSearchModal,
    onCloseNewServicesModal,
    onCloseLibraryServicesSearch,
    numberOfDays,
    scrollToDay,
}) => {
    const context = useContext(
        ConfigureServicesContext,
    ) as ConfigureServicesContextValue;
    const onAddService = (
        destinationDay: number,
        service: { type: string; serviceType: string },
    ) => {
        context?.handleDrop?.(destinationDay, service);
    };
    return (
        <>
            <ServicesModalWrapper
                show={showNewServicesModal}
                onClose={onCloseNewServicesModal}
            >
                <ServicesButtons
                    onDaySelected={onAddService}
                    numberOfDays={numberOfDays}
                    onClose={onCloseNewServicesModal}
                    scrollToDay={scrollToDay}
                />
            </ServicesModalWrapper>
            <ServicesModalWrapper
                show={showServicesSearchModal}
                onClose={onCloseLibraryServicesSearch}
            >
                <ServicesSearch
                    onDaySelected={onAddService}
                    numberOfDays={numberOfDays}
                    onClose={onCloseLibraryServicesSearch}
                    scrollToDay={scrollToDay}
                    className={servicesSearchStyles}
                />
            </ServicesModalWrapper>
        </>
    );
};

export default MobileServices;
