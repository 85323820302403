import { theme } from '@trova-trip/trova-components';
import { SurveyTypes } from './Metric.types';

const SURVEY_TYPES: SurveyTypes = {
    PopularDestSection: {
        color: theme.colors.teal.trova,
        xAxisHeight: 60,
        chartType: 'BarChart',
        shadeSteps: [
            50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700,
            750,
        ],
    },
    TimeOfYear: {
        chartType: 'PieChart',
        color: theme.colors.purple[400],
        shadeSteps: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600],
        maxLabelLength: 3,
    },
    Budget: {
        chartType: 'BarChart',
        color: theme.colors.blue[500],
        shadeSteps: [400, 450, 500, 550, 600, 650],
    },
    Age: {
        chartType: 'BarChart',
        color: theme.colors.orange[900],
        shadeSteps: [400, 450, 500, 550, 600, 650],
    },
    Gender: {
        chartType: 'PieChart',
        color: theme.colors.seaGreen.trova,
        shadeSteps: [400, 500, 600],
    },
};

export { SURVEY_TYPES };
