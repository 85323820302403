import {
    InterestCards,
    useCategoryInterests,
} from 'applications/host/components/InterestCards';
import { EditFieldProps, PreviewProps } from '../../utils/types';
import { models } from '@trova-trip/trova-models';
import { BaseBox, Stack, Text } from '@trova-trip/trova-components';
import { Icon, IconProps } from '@trova-trip/trova-components/build/next';

type Category = models.hostProfiles.SelectedSavedCategoriesProp;

interface InterestsPreviewProps extends PreviewProps<'categoryInterests'> {}

interface CategoryInterestTagProps
    extends Pick<Category, 'icon' | 'colorScheme' | 'name'> {}

const CategoryInterestTag = ({
    icon,
    colorScheme,
    name,
}: CategoryInterestTagProps): JSX.Element => {
    return (
        <Stack
            align='center'
            marginRight={{ base: 5, md: 6 }}
            marginBottom={2}
            spacing={3}
        >
            {icon ? (
                <Icon
                    as={icon as IconProps['as']}
                    color={colorScheme}
                    size={{ base: 'md', md: 'lg' }}
                />
            ) : null}
            <Text fontSize={{ base: 'sm', md: 'base' }}>{name}</Text>
        </Stack>
    );
};

export const InterestsPreview = ({
    categoryInterests = [],
}: InterestsPreviewProps): JSX.Element | null => {
    return (
        <BaseBox
            display='flex'
            flexWrap='wrap'
            paddingTop={{ base: 2, md: 3 }}
            paddingBottom={1}
        >
            {categoryInterests.map((interest: Category) => (
                <CategoryInterestTag
                    key={interest.name}
                    icon={interest.icon}
                    colorScheme={interest.colorScheme}
                    name={interest.name}
                />
            ))}
        </BaseBox>
    );
};

export const InterestsEditField = ({
    fieldData = [],
    onChange,
}: EditFieldProps<'categoryInterests'>): JSX.Element | null => {
    const { categoryInterests } = useCategoryInterests();

    if (!categoryInterests) return null;

    const onInterestCategorySelected = (hostInterest: Category): void => {
        if (hostInterest._id) {
            const interestsList = [...fieldData];
            const interestSelectedIndex = interestsList.findIndex(
                (interest) => interest._id === hostInterest._id,
            );

            const shouldAddInterest = interestSelectedIndex === -1;

            if (shouldAddInterest) {
                interestsList.push(hostInterest);
            } else {
                interestsList.splice(interestSelectedIndex, 1);
            }

            return onChange(interestsList, !interestsList.length);
        }
    };

    return (
        <InterestCards
            selectedInterests={fieldData.map((category) => category._id)}
            onInterestClick={(category): void =>
                onInterestCategorySelected({
                    ...category,
                    _id: category._id as string,
                })
            }
        />
    );
};
