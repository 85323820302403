import { models } from '@trova-trip/trova-models';

import useDailyActivities from 'components/DailyActivities/useDailyActivities';
import ConfigureServicesContext from 'applications/common/ConfigureServicesContext';

interface ConfigureServicesContextProviderProps {
    servicesByDay: models.services.ServicesByDay;
    model: string;
    id: string;
}

const ConfigureServicesContextProvider: React.FC<ConfigureServicesContextProviderProps> =
    ({ servicesByDay, model, id, children }) => {
        const dailyActivities = useDailyActivities(servicesByDay, model, id);

        return (
            <ConfigureServicesContext.Provider value={dailyActivities}>
                {children}
            </ConfigureServicesContext.Provider>
        );
    };

export default ConfigureServicesContextProvider;
