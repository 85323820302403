import { Overlay } from '@trova-trip/trova-components';
import noop from 'lodash/noop';

interface MobileAccordionBackdropProps {
    display: boolean;
    onClick?: () => void;
}

const MobileAccordionBackdrop = ({
    display,
    onClick = noop,
}: MobileAccordionBackdropProps) => {
    return display ? (
        <Overlay
            position='fixed'
            background='blackAlpha.400'
            backdropFilter='blur(.1875rem)'
            zIndex={1000}
            onClick={onClick}
        />
    ) : null;
};

export default MobileAccordionBackdrop;
