import { memo } from 'react';
import { Card, Text, theme } from '@trova-trip/trova-components';
import { styledEmptyMessage } from './BookingsEmptyMessage.styles';

interface BookingsEmptyMessageProps {
    title?: string;
    message: string;
}

export const BookingsEmptyMessage = memo((props: BookingsEmptyMessageProps) => {
    const { message, title } = props;
    return (
        <Card
            backgroundColor={theme.colors.neutral.white}
            className={styledEmptyMessage}
        >
            {title && (
                <header>
                    <Text fontWeight={'bold'}>{title}</Text>
                </header>
            )}
            <Text size={'lg'} color={'neutral.60'}>
                {message}
            </Text>
        </Card>
    );
});

BookingsEmptyMessage.displayName = 'BookingsEmptyMessage';

export default BookingsEmptyMessage;
