import { models } from '@trova-trip/trova-models';

import FetchState from './utils/FetchState';

const operators = FetchState.create(`api/admin/operators`);
const operatorsMetadata = FetchState.create(`api/metadata/operators`);

const operatorsMetadataReducer = operatorsMetadata.getReducer();
const operatorsReducer = operators.getReducer();

export type OperatorStoreSelector = {
    operators: { current: models.operators.Operator };
};

export {
    operators,
    operatorsMetadata,
    operatorsReducer,
    operatorsMetadataReducer,
};
