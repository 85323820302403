import useProfileFormAPI from '../../../hooks/useProfileFormAPI';
import { Form, Grid, useToast } from '@trova-trip/trova-components';
import { AddressFormModel } from '../../../types/ProfileForm.types';
import { Button } from '@trova-trip/trova-components/build/next';
import AddressFormFields from './AddressFormFields';
import {
    getProfileSectionToastConfig,
    preProcessAddressData,
} from '../../../utils/address.utils';
import { validationSchemas } from '@trova-trip/trova-models';
import { useTrackingEvent } from '../../../../../../../analytics/hooks';

const { AddressFormValidationSchema } = validationSchemas;

const AddressForm = (): JSX.Element => {
    const { initialValues, isLoading, handleSubmit } = useProfileFormAPI();
    const { trackUserEvent } = useTrackingEvent();
    const toast = useToast();

    const handleAddressSubmit = async (
        data: AddressFormModel,
    ): Promise<void> => {
        const newAddress = preProcessAddressData(data);
        const response = await handleSubmit({
            address: [newAddress],
        });
        const isSuccess = !!response.profile;
        if (isSuccess) {
            trackUserEvent({ eventName: 'User Updated Address Section' });
        }
        const toastConfig = getProfileSectionToastConfig({
            section: 'address',
            isSuccess,
        });
        toast(toastConfig);
    };

    return (
        <Form
            name='address-form'
            initialValues={initialValues.address}
            validationSchema={AddressFormValidationSchema}
            onSubmit={handleAddressSubmit}
        >
            {({ values, isDirty, handleChange }): JSX.Element => (
                <Grid>
                    <AddressFormFields
                        values={values}
                        handleChange={handleChange}
                    />
                    <Grid.Item marginTop={{ base: 4, md: 0 }}>
                        <Button
                            isDisabled={isLoading || !isDirty}
                            type='submit'
                            variant='primary'
                        >
                            Save
                        </Button>
                    </Grid.Item>
                </Grid>
            )}
        </Form>
    );
};

export default AddressForm;
