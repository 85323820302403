import { useState } from 'react';
import { BaseBox, Stack, Text } from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';
import {
    Icon,
    Card,
    IconProps,
} from '@trova-trip/trova-components/build/next/';
import { hostInterestColorSchemes } from './Interests.colorSchemes';

type Category = models.categories.Category;

type InterestCardProps = {
    isChecked?: boolean;
    hostInterest: Category;
    onClick: () => void;
};

const InterestCard = (props: InterestCardProps): JSX.Element | null => {
    const { hostInterest, onClick } = props;
    const [isChecked, setChecked] = useState(props.isChecked);
    const handleOnClick = () => {
        setChecked((prevState) => !prevState);
        onClick();
    };

    if (!hostInterest.colorScheme) {
        return null;
    }
    const hostInterestColorScheme =
        hostInterestColorSchemes[hostInterest.colorScheme];

    const icon = hostInterest.icon as IconProps['as'];
    return (
        <Card
            borderRadius='lg'
            borderColor={
                isChecked ? hostInterestColorScheme.strong : 'blueGray.200'
            }
            backgroundColor={
                isChecked ? hostInterestColorScheme.background : 'transparent'
            }
            borderStyle='solid'
            borderWidth='1px'
            padding={{ base: 2, md: 2.5 }}
            onClick={handleOnClick}
            hoverStyles={{
                backgroundColor: isChecked
                    ? hostInterestColorScheme.hover
                    : 'blueGray.25',
            }}
        >
            <Stack
                direction='column'
                justify='space-between'
                spacing={0}
                minHeight={24}
            >
                {icon ? (
                    <Icon
                        as={icon}
                        color={
                            isChecked
                                ? hostInterestColorScheme.strong
                                : hostInterestColorScheme.light
                        }
                        size='xl'
                    />
                ) : null}
                <BaseBox maxWidth={{ base: '75%', md: '100%' }}>
                    <Text
                        as='span'
                        variant='body'
                        fontWeight='medium'
                        fontSize={{ base: 'base', md: 'sm', xl: 'base' }}
                        color='blueGray.600'
                    >
                        {hostInterest.name}
                    </Text>
                </BaseBox>
            </Stack>
        </Card>
    );
};

export default InterestCard;
