import { UserGroup } from 'applications/types';
import useCurrentUserGroupMatcher from '../hooks/useCurrentUserGroupMatcher';

interface ContentForUserGroupProps {
    matchingUserGroup: UserGroup | Set<UserGroup>;
    render: () => JSX.Element;
}

const ContentForUserGroup = (
    props: ContentForUserGroupProps,
): JSX.Element | null => {
    const { matchingUserGroup, render } = props;
    const predicate = useCurrentUserGroupMatcher(matchingUserGroup);
    return predicate ? render() : null;
};

export default ContentForUserGroup;
