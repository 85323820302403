import FetchState from './utils/FetchState';

const listUsersMetadata = FetchState.create(`api/metadata/listUsers`);

const listUsersMetadataReducer = listUsersMetadata.getReducer();

export {
    listUsersMetadata,
    listUsersMetadataReducer,
};
