import { css } from '@emotion/css';
import { models } from '@trova-trip/trova-models';
import { theme } from '@trova-trip/trova-components';
import {
    BaseBox,
    Heading,
    Image,
    Stack,
    Text,
} from '@trova-trip/trova-components';
import TrovaLogo from '../../../../../assets/img/trova-logo.png';
import { getTripDatesIfApplicable } from '../../../../../util/ModelDataHelper';

type Trip = models.trips.Trip;

interface PrintableItineraryHeaderProps {
    itinerary: Trip;
}

const PrintableItineraryHeaderStyle = css`
    @media screen {
        display: none;
    }
    @media print {
        @page {
            margin: ${theme.space[8]};
        }
    }
`;

const PrintableItineraryHeader = ({
    itinerary,
}: PrintableItineraryHeaderProps): JSX.Element => {
    const { servicesByDay, startDate } = itinerary;
    const tripDates = getTripDatesIfApplicable(startDate, servicesByDay);

    const tripDatesFormatted =
        tripDates.length > 0
            ? `${tripDates[0]} - ${tripDates[tripDates.length - 1]}`
            : '';

    return (
        <BaseBox
            className={PrintableItineraryHeaderStyle}
            textAlign='center'
            marginBottom={7}
        >
            <Image width='110px' src={TrovaLogo} />
            <Stack direction='row' justify='space-between' marginTop={7}>
                <Heading fontSize='lg' fontWeight='bold' lineHeight={5}>
                    {itinerary.name}
                </Heading>
                <Stack direction='column'>
                    <Text>{`${itinerary.country}, ${itinerary.destination}`}</Text>
                    <Text>{`${tripDatesFormatted}`}</Text>
                </Stack>
            </Stack>
        </BaseBox>
    );
};

export default PrintableItineraryHeader;
