import {
    Switch,
    Image,
    FormGroup,
    Stack,
    Text,
    Heading,
    Grid,
} from '@trova-trip/trova-components';
import { Dialog, Button, Link } from '@trova-trip/trova-components/build/next';
import { ButtonWithAnalytics } from '../../../../../../../components/ButtonWithAnalytics';
import exportDataImage from '../../../../../../../assets/img/audience-summary/export-data.svg';

const DATA_POLICY_APPROVAL_URL =
    'https://trovatrip.com/host/data-policy-approval';

interface ExportDataContentProps {
    totalAudience: number;
    surveyResponses: number;
    isDataExtended: boolean;
    isRequestPending: boolean;
    onClickExport: () => void;
    onClickCancel: () => void;
    onToggleExtendData: () => void;
}

const analyticsData = {
    eventName: 'Host downloaded audience data',
    type: 'onClick',
};

const ExportDataContent = ({
    totalAudience,
    surveyResponses,
    onClickExport,
    onClickCancel,
    onToggleExtendData,
    isDataExtended,
    isRequestPending,
}: ExportDataContentProps): JSX.Element => {
    return (
        <>
            <Dialog.Body>
                <Grid gap={6}>
                    <Grid.Item
                        columnSpan={12}
                        justifySelf='center'
                        width={{ base: 56, md: 72 }}
                        height={{ base: 52, md: 72 }}
                    >
                        <Image
                            marginTop={4}
                            src={exportDataImage}
                            alt=''
                            data-gumlet='false'
                        />
                    </Grid.Item>
                    <Grid.Item columnSpan={12}>
                        <Heading
                            as='h5'
                            size={{ base: 'xl', md: '2xl' }}
                            paddingBottom={{ base: 2, md: 4 }}
                        >
                            Export {totalAudience} Audience Emails
                        </Heading>
                        <Text as='p' paddingTop={2}>
                            Export a CSV containing names and emails of{' '}
                            {totalAudience} audience members. Additionally, we
                            can include survey data for users that responded to
                            your survey.
                        </Text>
                    </Grid.Item>
                    <Grid.Item columnSpan={12}>
                        <FormGroup
                            label={`Include ${surveyResponses} survey responses`}
                            direction='row-reverse'
                        >
                            <Switch
                                onChange={onToggleExtendData}
                                value={isDataExtended}
                                name='externalData'
                            />
                        </FormGroup>
                    </Grid.Item>
                    <Grid.Item columnSpan={12}>
                        <Text as='p' color='blueGray.500' size='sm'>
                            By clicking download, you agree to our &nbsp;
                            <Link
                                size='sm'
                                href={DATA_POLICY_APPROVAL_URL}
                                isExternal={true}
                            >
                                Terms and conditions
                            </Link>
                        </Text>
                    </Grid.Item>
                </Grid>
            </Dialog.Body>
            <Dialog.Footer>
                {!isRequestPending ? (
                    <Stack
                        justify={{ base: 'space-between', md: 'flex-end' }}
                        width='full'
                        paddingTop={{ base: 0, md: 6 }}
                        spacing={{ base: 2, md: 4 }}
                        wrap='nowrap'
                    >
                        <Button
                            paddingX={{ base: 12, md: 4 }}
                            paddingY={2}
                            size='md'
                            variant='secondary'
                            onClick={onClickCancel}
                        >
                            Cancel
                        </Button>
                        <ButtonWithAnalytics
                            paddingX={{ base: 8, md: 4 }}
                            paddingY={2}
                            rightIcon='fileDownload'
                            onClick={onClickExport}
                            analyticsData={analyticsData}
                        >
                            Export
                        </ButtonWithAnalytics>
                    </Stack>
                ) : null}
            </Dialog.Footer>
        </>
    );
};

export default ExportDataContent;
