import {
    BaseBox,
    BaseBoxProps,
    WithTooltip
} from '@trova-trip/trova-components';
import { Icon, IconProps } from '@trova-trip/trova-components/build/next';
import { ReactNode } from 'react';

export interface TooltipButtonProps {
    icon: IconProps['as'];
    tooltip: ReactNode;
    ariaLabel: string;
}

const TooltipButton = ({
    icon,
    tooltip,
    ariaLabel,
}: TooltipButtonProps): JSX.Element => {
    const ButtonWithTooltip = WithTooltip<BaseBoxProps>(BaseBox);

    return (
        <ButtonWithTooltip
            aria-label={ariaLabel}
            role='button'
            tabIndex={0}
            tooltipConfigProps={{
                tooltip,
                tooltipToggleMode: 'click',
                closeOnEscape: true,
                placement: 'bottom',
            }}
        >
            <Icon as={icon} display='flex' size='md' />
        </ButtonWithTooltip>
    );
};

export default TooltipButton;
