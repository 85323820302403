import { HeadingProps } from '@trova-trip/trova-components';
import WidgetHeading from '../../../dashboard/components/common/WidgetHeading';

const CardHeadingWidget = ({
    audienceSurveyCount,
}: {
    audienceSurveyCount: number;
}) => {
    const label =
        audienceSurveyCount > 0 ? 'Audience' : 'Connect with your Community';
    const props: HeadingProps['textAlign'] =
        audienceSurveyCount > 0
            ? { textAlign: 'left' }
            : { textAlign: 'center' };
    return <WidgetHeading {...props}>{label}</WidgetHeading>;
};

export default CardHeadingWidget;
