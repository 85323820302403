import { BaseBox, Stack } from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';
import { Dispatch, SetStateAction } from 'react';
import { categories } from '../../../../../state/categories';
import { useSelector } from '../../../../../state/hooks/useSelector';
import OnboardingStepHeader from './common/OnboardingStepHeader';
import { InterestCards } from '../../../components/InterestCards';

type Category = models.categories.Category;

export type InterestsProps = {
    interests: string[];
    setInterests: Dispatch<SetStateAction<string[]>>;
};

const Interests = ({ interests = [], setInterests }: InterestsProps) => {
    const {
        categories: { fetching, list: categoryList },
    } = useSelector((state) => state);

    const { listRecords: getAllHostInterests } = categories.useDispatch();

    if (!fetching && !categoryList) {
        getAllHostInterests();
    }

    const removeHostInterest = (hostInterestIndex: number) => {
        setInterests(
            interests.filter((interest, index) => index !== hostInterestIndex),
        );
    };

    const handleHostInterest = (hostInterest: Category) => {
        if (hostInterest._id) {
            const hostInterestIndex = interests.indexOf(hostInterest._id);
            if (hostInterestIndex === -1) {
                setInterests(interests.concat([hostInterest._id]));
            } else {
                removeHostInterest(hostInterestIndex);
            }
        }
    };

    return (
        <Stack direction='column' spacing={{ base: 4, md: 6 }}>
            <OnboardingStepHeader
                title='Tell Us About Your Interests'
                lead='Select at least one interest to personalize your TrovaTrip
                experience.'
            />
            <BaseBox width='full' paddingRight={{ sm: 2, xl: 4 }}>
                <InterestCards
                    selectedInterests={interests}
                    onInterestClick={handleHostInterest}
                />
            </BaseBox>
        </Stack>
    );
};

export default Interests;
