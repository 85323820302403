import ContentForModelStatus from 'applications/common/components/ContentForModelStatus';
import useTripRoute, {
    TRIP_ACTION,
} from 'applications/common/hooks/trips/useTripRoute';
import StickyContentSimpleNav from 'applications/host/components/StickyContent/StickyContentSimpleNav';
import TripPublishStatusCard from 'applications/host/components/TripPublishStatusCard/TripPublishStatusCard';
import { StatefulModel } from 'applications/types';
import { HostTripApproved, TripStatuses } from 'config/constants';
import { useSelector } from 'react-redux';
import { TripStoreSelector } from 'state/trips';

const StickyContentTripPageStatus = (): JSX.Element => {
    const currentTrip = useSelector(
        (state: TripStoreSelector) => state.userTrips.current,
    );

    const routeToUrl = useTripRoute({
        action: TRIP_ACTION.PRICING,
        id: currentTrip.id,
        preserveQueryParams: true,
    });

    return (
        <>
            <ContentForModelStatus
                model={StatefulModel.TRIP}
                matchingStatuses={HostTripApproved}
                render={(): JSX.Element => <TripPublishStatusCard />}
            />
            <ContentForModelStatus
                model={StatefulModel.TRIP}
                matchingStatuses={[TripStatuses.TROVA_PRICING_APPROVED]}
                render={(): JSX.Element => (
                    <StickyContentSimpleNav
                        title={'Next: Price'}
                        description={
                            "Once you've configured your trip, choose your trip price."
                        }
                        buttonText={'Pricing'}
                        routeToUrl={routeToUrl}
                    />
                )}
            />
        </>
    );
};

export default StickyContentTripPageStatus;
