import { CheckListProps } from '@trova-trip/trova-components';
import tripRequestIllustration from '../../../../../../assets/img/tripRequestIllustration.svg';
import instantApprovalIllustration from '../../../../../../assets/img/instantApprovalIllustration.svg';
import { ROUTE_TOKENS } from '../../../../../../applications/common/constants';
import { applicationRootPath } from '../../../../../../navigation/NavigationContext';

type TripRequestDialogContent = {
    illustration: string;
    description: string;
    buttonText: string;
    checkListSteps: CheckListProps['items'];
    analyticsOnClickEventName: string;
    getRedirectUrl: (tripId?: string) => string;
};

export enum SuccessDialogTypes {
    TRIP_REQUEST = 'tripRequest',
    INSTANT_APPROVAL = 'instantApprovalTrip',
}

const hostPath = `${applicationRootPath}${ROUTE_TOKENS.HOST_APP_ROUTE}`;

export const tripRequestDialogContent: Record<
    SuccessDialogTypes,
    TripRequestDialogContent
> = {
    [SuccessDialogTypes.TRIP_REQUEST]: {
        illustration: tripRequestIllustration,
        description:
            'Your trip request can take up to 2-7 business days to process',
        buttonText: 'Email Collection',
        getRedirectUrl: () =>
            `${hostPath}${ROUTE_TOKENS.HOST_APP_PRODUCT_ROUTE.AUDIENCE}${ROUTE_TOKENS.HOST_APP_AUDIENCE_TAB_ROUTE.RESEARCH}`,
        checkListSteps: [
            {
                title: 'Trip Request Submitted',
                description:
                    'We will notify you once your request has been approved.',
                isActive: true,
            },
            {
                title: 'Email Collection Link',
                description:
                    'Continue to build your audience list while you wait for your trip request to process.',
            },
            {
                title: 'Home Dashboard',
                description:
                    'Your dedicated place to access your trips and see upcoming important dates.',
            },
        ],
        analyticsOnClickEventName:
            'Host clicked "Email Collection" on post request modal',
    },
    [SuccessDialogTypes.INSTANT_APPROVAL]: {
        illustration: instantApprovalIllustration,
        description:
            "You've selected an Instant Approval itinerary and can immediately begin planning your trip. Let's walk through the next steps to set up your trip:",
        buttonText: 'Configure Trip',
        getRedirectUrl: (tripId) =>
            `${hostPath}${ROUTE_TOKENS.HOST_APP_PRODUCT_ROUTE.TRIPS}/${tripId}${ROUTE_TOKENS.HOST_APP_TAB_ROUTE.CONFIGURATION}?model=trip`,
        checkListSteps: [
            {
                title: 'Trip Request Submitted',
                description: 'Your trip request has been approved.',
                isActive: true,
            },
            {
                title: 'Configure & Price Your Trip',
                description:
                    'Personalize your trip page, add informal gatherings and price your trip.',
            },
            {
                title: 'Sign Your Agreement',
                description:
                    'You will be able to promote and launch your trip after signing your service agreement.',
            },
        ],
        analyticsOnClickEventName:
            'Host clicked "Configure Trip" on post request modal',
    },
};
