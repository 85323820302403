import { BaseBox, Form, Grid } from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import {
    getSelectedTravelersRooms,
    getPrePostRoomsOccupancies,
    getPreAndPostAccommodationTimingSelected,
    getPreAnPostTransfersTimingSelected,
} from '../../../utils/extract.utils';
import {
    arePreAndPostAccommodationsAvailable,
    isUpgradeToPrivateRoomSelected,
} from '../../../utils/check.utils';
import RoomOptions from './RoomOptions';
import { useManageBooking } from '../../../hooks';
import { ValidOccupanciesType } from '../../../../../../../state/features/manageBooking/types';
import { Dispatch, SetStateAction, useCallback } from 'react';

interface AccommodationsInlineEditProps {
    tripDates: string;
    setIsInlineEditOpen: Dispatch<SetStateAction<boolean>>;
}

interface RoomOptionsFormValues {
    isUpgradeToPrivateRoomSelected: boolean;
    roomsOccupancies: ValidOccupanciesType[];
}

const AccommodationsInlineEdit = ({
    tripDates,
    setIsInlineEditOpen,
}: AccommodationsInlineEditProps): JSX.Element | null => {
    const {
        state: {
            addOns: { accommodations, transfers },
            travelersRooms,
            travelersQuantity,
            defaultRoomOccupancy,
            originalState,
        },
        actions: { updateRoomSelection },
    } = useManageBooking();

    const timingsToUpdate =
        getPreAndPostAccommodationTimingSelected(accommodations);

    const transfersTimingsToUpdate =
        getPreAnPostTransfersTimingSelected(transfers);

    const isTravelersQuantityOdd = travelersQuantity % 2 !== 0;

    const handleSubmit = useCallback(
        async (values: RoomOptionsFormValues): Promise<boolean> => {
            const preAndPostRoomSelection = getPrePostRoomsOccupancies({
                rooms: travelersRooms,
                travelersQuantity,
                singleSupplement: accommodations?.singleSupplement,
                isUpgradeFormValue: values.isUpgradeToPrivateRoomSelected,
                defaultRoomOccupancy,
            });

            const currentPreAndPostRoomSelection = getPrePostRoomsOccupancies({
                rooms: values.roomsOccupancies,
                travelersQuantity,
                singleSupplement: accommodations?.singleSupplement,
                isUpgradeFormValue: values.isUpgradeToPrivateRoomSelected,
                defaultRoomOccupancy,
            });

            const {
                addOns: { accommodations: originalAccommodations },
            } = originalState;

            /**
             * We need to check if the accommodations were originally available
             * to determine if we use the logic of `Price Adjustments`.
             *
             * - If the accommodations were originally available (it means that the booking did not have any confirmed pre/post)
             * and we need to use the logic of `Price Adjustments`, if not we use the Added and Removed sections.
             *
             * This helper checks for status !== 'AVAILABLE', but as we are using the original state
             * it will never contain any `PENDING_ADDITION` or `PENDING_REMOVAL` status.
             */
            const accommodationsWereOriginallyAvailable =
                arePreAndPostAccommodationsAvailable(originalAccommodations);

            updateRoomSelection({
                roomSelection: values.roomsOccupancies,
                preAndPostRoomSelection,
                currentPreAndPostRoomSelection,
                timingsToUpdate,
                transfersTimingsToUpdate,
                isTravelersQuantityOdd,
                isUpgradeToPrivateRoomSelected:
                    values.isUpgradeToPrivateRoomSelected,
                accommodationsWereOriginallyAvailable,
            });

            setIsInlineEditOpen(false);
            return true;
        },
        [],
    );

    const singleSupplement = accommodations.singleSupplement;

    const selectedTravelersRooms = getSelectedTravelersRooms(
        travelersRooms,
        singleSupplement,
        travelersQuantity,
    );

    const initialValues: RoomOptionsFormValues = {
        isUpgradeToPrivateRoomSelected:
            isUpgradeToPrivateRoomSelected(
                singleSupplement,
                travelersQuantity,
            ) && travelersQuantity !== 1,
        roomsOccupancies: selectedTravelersRooms.map(
            (travelerRoom) => travelerRoom.roomType,
        ),
    };

    return (
        <BaseBox
            border={{ md: '1px solid' }}
            borderRadius='12px'
            borderColor={{ base: 'none', md: 'blueGray.200' }}
            padding={{ md: 4, lg: 8 }}
        >
            <Form
                name='room-options-form'
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ values, isDirty }) => (
                    //TODO: Replace this component with the custom one imported from micro-apps
                    <>
                        <RoomOptions
                            singleSupplementUnitPrice={
                                accommodations.singleSupplement
                                    ?.unitPriceWithFee
                            }
                            isUpgradeToPrivateRoomSelected={
                                values.isUpgradeToPrivateRoomSelected
                            }
                            roomsOccupancies={values.roomsOccupancies}
                            travelersQuantity={travelersQuantity}
                            tripDates={tripDates}
                        />
                        <Grid marginTop={6}>
                            <Grid.Item columnSpan={{ base: 6, md: 1 }}>
                                <Button
                                    variant='secondary'
                                    onClick={() => setIsInlineEditOpen(false)}
                                    isFullWidth={{ base: true, md: false }}
                                >
                                    Cancel
                                </Button>
                            </Grid.Item>
                            <Grid.Item columnSpan={{ base: 6, md: 1 }}>
                                <Button
                                    type='submit'
                                    isDisabled={!isDirty}
                                    isFullWidth={{ base: true, md: false }}
                                >
                                    Update
                                </Button>
                            </Grid.Item>
                        </Grid>
                    </>
                )}
            </Form>
        </BaseBox>
    );
};

export default AccommodationsInlineEdit;
