import { css } from '@emotion/css';
import { theme } from '@trova-trip/trova-components';

export const styledTripPublishStatusCard = css`
    && {
        @media (max-width: ${theme.breakpoints.md}) {
            h3 {
                font-family: ${theme.fonts.roboto};
                font-weight: ${theme.fontWeights.medium};
                font-size: 1rem;
            }
        }

        @media (min-width: ${theme.breakpoints.md}) {
            p {
                margin-top: 1rem;
                margin-bottom: 1.8rem;
            }

            button {
                display: flex;
                width: 165px;
                min-width: 0;
                max-width: none;
                margin: 0 auto;
            }
        }
    }
`;
