import * as yup from 'yup';
import { validationSchemas } from '@trova-trip/trova-models';
import { STEP_NAME } from './types';

const { PersonalSiteSchema } = validationSchemas;

const SocialsSchema = yup
    .object()
    .shape({
        channels: yup.object().shape({
            facebook: yup.string(),
            personalSite: yup
                .string()
                .transform((value) => (value ? `https://${value}` : value))
                .concat(PersonalSiteSchema),
            instagram: yup.string(),
            tiktok: yup.string(),
            youtube: yup.string(),
        }),
        socialEngagementUserId: yup.string(),
        isManualConnect: yup.boolean(),
    })
    .test(
        'at-least-one-social',
        'At least one social media account is required',
        ({ channels, isManualConnect, socialEngagementUserId }) => {
            return isManualConnect
                ? Object.values(channels).filter((val) => !!val).length > 0
                : !!socialEngagementUserId;
        },
    );

const validationSchema = yup.object().shape({
    [STEP_NAME.INTERESTS]: yup.array().of(yup.string()).min(1),
    [STEP_NAME.SOCIALS]: SocialsSchema,
    [STEP_NAME.BIO]: yup.object().shape({
        bio: yup.string().max(350),
        profilePictureUrl: yup.string(),
    }),
});

const getValidationSchemaForStep = (
    identifier: STEP_NAME,
): yup.ObjectSchema<any> | undefined =>
    (validationSchema as yup.ObjectSchema<any>).fields?.[identifier];

export { getValidationSchemaForStep };
