import { Grid } from '@trova-trip/trova-components';
import { Card } from '@trova-trip/trova-components/build/next';
import InfoItem from './InfoItem';

export interface ResponsesSummaryProps {
    qualifiedResponses: number;
    medianBudget: string;
}

const ResponsesSummary = ({
    qualifiedResponses,
    medianBudget,
}: ResponsesSummaryProps): JSX.Element => {
    const summaryItems = [
        {
            label: 'Qualified Survey Responses',
            value: qualifiedResponses,
        },
        {
            label: 'Median Budget',
            value: medianBudget,
        },
    ];

    return (
        <Card backgroundColor='neutral.white' padding={6} width='full'>
            <Grid>
                {summaryItems.map((infoItemProps) => (
                    <Grid.Item
                        key={infoItemProps.label}
                        columnSpan={{ base: 12, md: 6 }}
                    >
                        <InfoItem {...infoItemProps} />
                    </Grid.Item>
                ))}
            </Grid>
        </Card>
    );
};

export default ResponsesSummary;
