import { proxyPort } from '../../package.json';

const LOCALHOST = `localhost:3000`;

export const windowHostName = (asFullOrigin = false) => {
    const { host: windowHostName, protocol } = window.location;
    const createUri = (hostUri, port) => {
        const p = port ? ':' + port : '';
        return asFullOrigin ? `${protocol}//${hostUri}${p}` : `${hostUri}${p}`;
    };
    return windowHostName === LOCALHOST
        ? createUri('localhost', proxyPort)
        : createUri(windowHostName, undefined);
};

export const websiteHostName = () => {
    const { host: windowHostName } = window.location;
    switch (windowHostName) {
        case (windowHostName.indexOf(LOCALHOST) > -1 ? windowHostName : null):
            return `staging-trovatrip.com`;
        case `my.staging-trovatrip.com`:
            return `staging-trovatrip.com`;
        case `my.trovatrip.com`:
            return `trovatrip.com`;
        default:
            return `staging-trovatrip.com`;
    }
};
