import { useCallback, useMemo, useState } from 'react';
import {
    Heading,
    Text,
    BaseBox,
    Input,
    DatePicker,
    Grid,
    useFormSaver,
    FormDataObjectType,
    FormSaver,
} from '@trova-trip/trova-components';
import { Dialog, Card, Button } from '@trova-trip/trova-components/build/next';
import { styledContainer } from '../BookingsTables/BookingsTables.styles';
import Snackbar from '../../../../components/Snackbar/Snackbar';
import { useSelector } from 'react-redux';
import { models } from '@trova-trip/trova-models';
import { Moment } from 'moment';
import { CompanionFormSchema } from './CompanionFormSchema';
import {
    useCompanionFormAPI,
    useGetCompanionsInformation,
} from './useCompanionFormAPI';
import { FormCompanion } from './CompanionsForm.types';

const CompanionForm = (): JSX.Element => {
    const {
        userTrips: { current: currentTrip },
    } = useSelector(
        (state: { userTrips: { current: models.trips.Trip } }) => state,
    );
    const { id: tripId } = currentTrip || {};
    const [open, setOpen] = useState(false);
    const [snackBar, setSnackBar] = useState({
        message: '',
        color: 'info',
        show: false,
    });

    const showToastMessage = useCallback(({ message, color, show = true }) => {
        setSnackBar({
            message,
            color,
            show,
        });
    }, []);

    const { formCompanions, editMode } = useGetCompanionsInformation();
    const { initialValues, handleSubmit, parseDateChange } =
        useCompanionFormAPI();

    const formSaver = useFormSaver({
        onSubmit: async (formattedCompanions) => {
            if (tripId) {
                const { response } = await handleSubmit(
                    tripId,
                    formattedCompanions as unknown as FormDataObjectType,
                );
                const isSuccess = response?.success;
                const message = {
                    message: isSuccess
                        ? 'Companions were submitted'
                        : response.error,
                    color: isSuccess ? 'success' : 'danger',
                };
                showToastMessage(message);
            }
        },
        validationSchema: {
            schema: CompanionFormSchema(initialValues),
            validateFieldOnChange: false,
        },
        initialValues: initialValues as unknown as FormDataObjectType,
    });

    const onFormSubmit = (): void => {
        if (editMode) {
            setOpen(true);
        }
    };

    const handleDialogConfirm = (): void => {
        formSaver.handleFormSubmit(undefined);
        setOpen(false);
    };

    const handleDateInputChange = (date: Moment, inputName: string): void => {
        const value = parseDateChange(date);
        if (value) {
            formSaver.formValues.set.nested(inputName, value);
        }
    };

    const getCompanionsFormFeedbackMessage = (
        formCompanions: FormCompanion[],
        editMode: boolean,
    ): string => {
        const numberOfCompanions = formCompanions?.length || 0;
        if (numberOfCompanions === 0) {
            return `No companions were added to this trip.`;
        }
        if (!editMode) {
            return `Your companion information has been submitted and can’t be changed. Contact support if companion information needs to be updated.`;
        }
        return `Please submit your companion(s) contact information. After submission, your companion(s) will receive an email to confirm their Traveler account and gain access to the traveler portal where they will manage their trip preparation.`;
    };

    const companionsFormMessage = useMemo(
        () => getCompanionsFormFeedbackMessage(formCompanions, editMode),
        [formCompanions, editMode],
    );

    return (
        <Grid className={styledContainer}>
            <Grid.Item columnSpan={12}>
                <Heading as={'h4'}>Companion</Heading>
                <BaseBox marginY={4}>
                    <Text>{companionsFormMessage}</Text>
                </BaseBox>
            </Grid.Item>
            {formCompanions?.length > 0 && (
                <Grid.Item columnSpan={12}>
                    <Card width='full' padding={6}>
                        <>
                            <FormSaver
                                name='trip-host-companions-form'
                                onSubmit={onFormSubmit}
                            >
                                <>
                                    {formCompanions.map((companion) => (
                                        <Grid
                                            key={companion._id}
                                            width='full'
                                            justifyItems='stretch'
                                            marginBottom={{
                                                base: 12,
                                                lg: 6,
                                            }}
                                        >
                                            <Grid.Item
                                                columnSpan={{
                                                    base: 6,
                                                    lg: 3,
                                                }}
                                            >
                                                <Input
                                                    name={`${companion._id}.firstName`}
                                                    type='name'
                                                    value={
                                                        formSaver.formValues.get.nested(
                                                            `${companion._id}.firstName`,
                                                        ) as string
                                                    }
                                                    error={
                                                        formSaver.formErrors?.[
                                                            `${companion._id}.firstName`
                                                        ]
                                                    }
                                                    onChange={(event): void => {
                                                        formSaver.formValues.set.nested(
                                                            (
                                                                event.target as HTMLInputElement
                                                            ).name,
                                                            (
                                                                event.target as HTMLInputElement
                                                            ).value,
                                                        );
                                                    }}
                                                    label='First Name*'
                                                    disabled={!editMode}
                                                />
                                            </Grid.Item>
                                            <Grid.Item
                                                columnSpan={{
                                                    base: 6,
                                                    lg: 3,
                                                }}
                                            >
                                                <Input
                                                    name={`${companion._id}.lastName`}
                                                    type='name'
                                                    value={
                                                        formSaver.formValues.get.nested(
                                                            `${companion._id}.lastName`,
                                                        ) as string
                                                    }
                                                    error={
                                                        formSaver.formErrors?.[
                                                            `${companion._id}.lastName`
                                                        ]
                                                    }
                                                    onChange={(event): void => {
                                                        formSaver.formValues.set.nested(
                                                            (
                                                                event.target as HTMLInputElement
                                                            ).name,
                                                            (
                                                                event.target as HTMLInputElement
                                                            ).value,
                                                        );
                                                    }}
                                                    label='Last Name*'
                                                    disabled={!editMode}
                                                />
                                            </Grid.Item>
                                            <Grid.Item
                                                columnSpan={{
                                                    base: 12,
                                                    lg: 4,
                                                }}
                                            >
                                                <Input
                                                    name={`${companion._id}.email`}
                                                    type='email'
                                                    value={
                                                        formSaver.formValues.get.nested(
                                                            `${companion._id}.email`,
                                                        ) as string
                                                    }
                                                    error={
                                                        formSaver.formErrors?.[
                                                            `${companion._id}.email`
                                                        ]
                                                    }
                                                    onChange={(event): void => {
                                                        formSaver.formValues.set.nested(
                                                            (
                                                                event.target as HTMLInputElement
                                                            ).name,
                                                            (
                                                                event.target as HTMLInputElement
                                                            ).value,
                                                        );
                                                    }}
                                                    label='Email Address*'
                                                    disabled={!editMode}
                                                />
                                            </Grid.Item>
                                            <Grid.Item
                                                columnSpan={{
                                                    base: 12,
                                                    lg: 2,
                                                }}
                                                width={{
                                                    base: '100%',
                                                    lg: '144px',
                                                }}
                                            >
                                                <DatePicker
                                                    css={{
                                                        width: '100%',
                                                        position: 'relative',
                                                    }}
                                                    name={`${companion._id}.dateOfBirth`}
                                                    placeholder={'MM/DD/YYYY'}
                                                    value={
                                                        formSaver.formValues.get.nested(
                                                            `${companion._id}.dateOfBirth`,
                                                        ) as string
                                                    }
                                                    error={
                                                        formSaver.formErrors?.[
                                                            `${companion._id}.dateOfBirth`
                                                        ]
                                                    }
                                                    onChange={(date): void => {
                                                        handleDateInputChange(
                                                            // This was typed incorrectly in the legacy TC component,
                                                            // and is always a valid Moment object.
                                                            date as Moment,
                                                            `${companion._id}.dateOfBirth`,
                                                        );
                                                    }}
                                                    label={'Date of Birth*'}
                                                    disabled={!editMode}
                                                />
                                            </Grid.Item>
                                        </Grid>
                                    ))}
                                </>
                                {editMode && (
                                    <Grid>
                                        <Grid.Item>
                                            <Button
                                                variant='primary'
                                                isDisabled={!editMode}
                                                type='submit'
                                            >
                                                Submit
                                            </Button>
                                        </Grid.Item>
                                    </Grid>
                                )}
                            </FormSaver>
                            <Dialog
                                isOpen={open}
                                onClose={(): void => {
                                    setOpen(false);
                                }}
                            >
                                <Dialog.Header title='Ready to submit companion information?' />
                                <Dialog.Body>
                                    Companion information can’t be changed once
                                    submitted. Double-check all information
                                    looks correct before submitting.
                                </Dialog.Body>
                                <Dialog.Footer
                                    actions={[
                                        {
                                            children: 'No, go back',
                                            variant: 'secondary',
                                            isFullWidth: {
                                                base: true,
                                                md: false,
                                            },
                                            onClick: (): void => {
                                                setOpen(false);
                                            },
                                        },
                                        {
                                            children: 'Yes',
                                            variant: 'primary',
                                            isFullWidth: {
                                                base: true,
                                                md: false,
                                            },
                                            onClick: handleDialogConfirm,
                                        },
                                    ]}
                                />
                            </Dialog>
                            <Snackbar
                                place='tr'
                                color={snackBar.color}
                                message={snackBar.message}
                                open={snackBar.show}
                                autoHideDuration={4000}
                                onClose={(): void => {
                                    setSnackBar({
                                        message: '',
                                        color: 'info',
                                        show: false,
                                    });
                                }}
                            />
                        </>
                    </Card>
                </Grid.Item>
            )}
        </Grid>
    );
};

export default CompanionForm;
