import {
    BaseBox,
    BaseBoxProps,
    Heading,
    Hide,
    Stack,
    StatusIndicator,
    Text,
} from '@trova-trip/trova-components';

interface StickyContentBaseProps extends BaseBoxProps {
    title?: string;
    subtitle?: string;
    description?: string;
    action?: JSX.Element;
    statusIndicatorColor?: string;
}

const StickyContentBase = ({
    title,
    subtitle,
    description,
    action,
    statusIndicatorColor,
    marginTop = { base: -8, md: 0 },
    ...rest
}: StickyContentBaseProps): JSX.Element => {
    const containsMobileContent = !!title || !!subtitle || !!action;

    return (
        <BaseBox
            display={{
                base: !containsMobileContent ? 'none' : 'block',
                md: 'block',
            }}
            marginX={{ base: -4, md: 0 }}
            marginTop={marginTop}
            borderBottom={{ base: '1px', md: 'none' }}
            borderColor='blueGray.300'
            backgroundColor={{ md: 'neutral.white' }}
            borderRadius={{ md: 'xl' }}
            boxShadow={{ md: '5' }}
            {...rest}
        >
            <Stack
                spacing={{ base: 0, md: 5 }}
                direction={{ md: 'column' }}
                align='center'
                justify={{ base: 'space-between', md: 'center' }}
                paddingY={{ base: 3, md: 6 }}
                paddingX={{ base: 4 }}
            >
                <Stack
                    direction='column'
                    spacing={{ base: 0, md: 2 }}
                    align={{ md: 'center' }}
                >
                    {title && (
                        <Stack spacing={2} align='center' justify='center'>
                            <Heading
                                fontSize={{ base: 'sm', md: '2xl' }}
                                lineHeight={{ base: '5', md: '9' }}
                                color={{
                                    base: 'blueGray.500',
                                    md: 'blueGray.650',
                                }}
                                textAlign={{ md: 'center' }}
                                textTransform={{
                                    base: 'uppercase',
                                    md: 'none',
                                }}
                                fontWeight={{ base: 'regular', md: 'bold' }}
                                as={'h4'}
                            >
                                {title}
                            </Heading>
                            {statusIndicatorColor && (
                                <StatusIndicator color={statusIndicatorColor} />
                            )}
                        </Stack>
                    )}
                    {subtitle && (
                        <Text
                            fontWeight={{ base: 'medium', md: 'bold' }}
                            fontSize={{ base: 'sm', md: '4xl' }}
                            textAlign={{ base: 'left', md: 'center' }}
                            color='blueGray.700'
                        >
                            {subtitle}
                        </Text>
                    )}
                    {description && (
                        <Hide breakpoint={{ max: 'md' }}>
                            <Text
                                color={'blueGray.dark'}
                                textAlign={{ md: 'center' }}
                            >
                                {description}
                            </Text>
                        </Hide>
                    )}
                </Stack>
                {action && <>{action}</>}
            </Stack>
        </BaseBox>
    );
};

export default StickyContentBase;
