import NavigationContext from 'navigation/NavigationContext';
import Tab from 'navigation/Tab';
import { useContext, useEffect, useMemo } from 'react';
import { useTabs } from '../../hooks';
import SidebarItemList from '../SidebarItemList';

const TabList = (): JSX.Element => {
    const { tabs } = useTabs();
    const { setShowSidebarContent, setSidebarComponent, setShowSidebarButton } =
        useContext(NavigationContext);

    useEffect(() => {
        setShowSidebarContent(false);
        setShowSidebarButton(false);
        setSidebarComponent(<SidebarItemList />);
        return (): void => {
            setShowSidebarContent(true);
            setShowSidebarButton(true);
            setSidebarComponent(null);
        };
    }, [tabs]);

    const tabsFiltered = useMemo(
        () => tabs.filter((tab) => tab.isVisibleOnNavigation),
        [tabs],
    );

    return (
        <>
            {tabsFiltered.map(({ label, path, element }) => (
                <Tab label={label} path={path} key={path} hideNavigation>
                    {element}
                </Tab>
            ))}
        </>
    );
};

export default TabList;
