import { useMemo } from 'react';
import { DropdownOption } from '../../interfaces/FormComponents';

export const useGetDropdownOptionsFromStringArray = (
    payload: string[] = [],
): DropdownOption[] =>
    useMemo(
        () => {
            return payload.map((arrayItem: string) => {
                return {
                    value: arrayItem,
                    children: arrayItem,
                };
            });
        },
        [payload],
    );
