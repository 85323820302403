import FetchState from './utils/FetchState';
import { models } from '@trova-trip/trova-models';

type BaseUser = models.users.BaseUser;

export type ProfileStoreSelector = { profile: { current: BaseUser } };

const profile = FetchState.create(`api/me/profile`);

const profileReducer = profile.getReducer();

export { profile, profileReducer };
