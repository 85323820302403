import { SyntheticEvent } from 'react';
import { models } from '@trova-trip/trova-models';

import {
    BaseComboBoxOption,
    Checkbox,
    Collapse,
    ComboBox,
    FormGroup,
    Grid,
    Heading,
    Stack,
    Toggle,
    Text,
    theme,
} from '@trova-trip/trova-components';
import {
    FoodOptionsValueType,
    FormFieldsNames,
    ItineraryPackageType,
} from './ItineraryPackage.types';
import styles from './ItineraryPackageComponent.module.scss';

interface ItineraryPackageComponentProps extends ItineraryPackageType {
    onlyPackageAvailable: boolean;
    updateValue: (type: string, packageType: string, value: any) => void;
    selectedValues: models.itineraries.ItineraryPackage;
}

/**
 *
 * form component that will render formFields for the interary package selected
 * it depends on the useItineraryPackageInputs to expose the data to the form handler as values required to the API
 */
const ItineraryPackageComponent = ({
    updateValue,
    accommodationLevels,
    mealLevels,
    foodOptions,
    type,
    onlyPackageAvailable,
    selectedValues,
}: ItineraryPackageComponentProps) => {
    const {
        enabled,
        accommodationLevels: selectedAccommodationLevel,
        mealLevels: selecteadMealLevel,
        foodOptions: selectedFoodOptions,
    } = selectedValues;
    const isPackageEnabled = onlyPackageAvailable || enabled;

    // initialize the internal comboBoxes value from the option to be consumable for the TC ComboBox
    const accommodationLevelSelected = (accommodationLevels || []).filter(
        (elem) => elem.value === selectedAccommodationLevel[0],
    );

    const mealLevelSelected = (mealLevels || []).filter(
        (elem) => elem.value === selecteadMealLevel[0],
    );

    const updatedFoodOptions = foodOptions.map((foodOption) => ({
        ...foodOption,
        selected: (selectedFoodOptions || []).includes(
            foodOption.label as FoodOptionsValueType,
        ),
    }));

    const handleComboBoxChange = (event: SyntheticEvent) => {
        const eventName: string = (event.target as HTMLInputElement).name;
        const eventValue: string = (event.target as HTMLInputElement).value;
        const parsedValue = JSON.parse(eventValue) as BaseComboBoxOption;

        updateValue(eventName, type, parsedValue);
    };

    const handleToggleChange = (event: SyntheticEvent) => {
        const eventName: string = (event.target as HTMLInputElement).name;
        const eventValue: boolean = (event.target as HTMLInputElement).checked;

        updateValue(eventName, type, eventValue);
    };

    const handleFoodOptionChange = (event: SyntheticEvent) => {
        const eventName: string = (event.target as HTMLInputElement).name;
        const eventValue: boolean = (event.target as HTMLInputElement).checked;

        updateValue(FormFieldsNames.FOOD_OPTIONS, type, {
            label: eventName,
            selected: eventValue,
        });
    };

    const packageTitle = `${type.charAt(0).toLocaleUpperCase()}${type.slice(
        1,
    )} Package`;

    return (
        <>
            <Stack
                direction='row'
                spacing={3}
                align='center'
                justify='space-between'
            >
                <Heading as='h5'>{packageTitle}</Heading>
                <Toggle
                    value={isPackageEnabled}
                    name={FormFieldsNames.ENABLED}
                    className={styles.toggleIcon}
                    onChange={handleToggleChange}
                    disabled={onlyPackageAvailable}
                />
            </Stack>
            <Stack direction='column' width='100%'>
                <Collapse isOpen={isPackageEnabled}>
                    <Stack
                        direction='column'
                        width='100%'
                        maxWidth={{
                            sm: '100%',
                        }}
                        marginTop={4}
                    >
                        <Grid width='80%' marginBottom={6}>
                            <Grid.Item
                                columnSpan={{
                                    md: 6,
                                    xl: 6,
                                    sm: 12,
                                    base: 12,
                                }}
                            >
                                <FormGroup
                                    label='Available Accommodation Levels'
                                    isRequired
                                >
                                    <ComboBox
                                        name={
                                            FormFieldsNames.ACCOMMODATION_LEVEL
                                        }
                                        options={accommodationLevels}
                                        value={accommodationLevelSelected}
                                        onChange={handleComboBoxChange}
                                        placeholder='Choose one...'
                                    />
                                </FormGroup>
                            </Grid.Item>
                            <Grid.Item
                                columnSpan={{
                                    md: 6,
                                    xl: 6,
                                    sm: 12,
                                    base: 12,
                                }}
                            >
                                <FormGroup label='Meal Level' isRequired>
                                    <ComboBox
                                        name={FormFieldsNames.MEAL_LEVEL}
                                        options={mealLevels}
                                        value={mealLevelSelected}
                                        onChange={handleComboBoxChange}
                                        placeholder='Choose one...'
                                    />
                                </FormGroup>
                            </Grid.Item>
                        </Grid>
                        <Text fontWeight='medium'>Available Food Options</Text>
                        <Grid
                            width='100%'
                            maxWidth={{
                                sm: '100%',
                            }}
                            gap={theme.space['7']}
                        >
                            {updatedFoodOptions.map((option) => (
                                <Grid.Item
                                    columnSpan={{
                                        base: 6,
                                        md: 4,
                                        xl: 4,
                                        sm: 6,
                                    }}
                                >
                                    <Checkbox
                                        name={option.label}
                                        label={option.label}
                                        detail={option.label}
                                        value={option.selected}
                                        onChange={handleFoodOptionChange}
                                    />
                                </Grid.Item>
                            ))}
                        </Grid>
                    </Stack>
                </Collapse>
            </Stack>
        </>
    );
};

export default ItineraryPackageComponent;
