import {
    ApiRestClient,
    RestClientConfigParams,
    HttpClientResponse,
    LoggerFactory,
    isHttpClientError,
} from '@trova-trip/trova-common';
import {
    redirectToLoginWithDeepLinkParam,
    redirectToErrorPage,
} from 'util/deepLinker';

const logger = LoggerFactory.create('ApiInterceptorLogger @TROVA');

type QueryStringParams = { [key: string]: any };

const convertMapFields = (data: any): any => {
    if (!data) {
        return data;
    }
    const serializableData: any = {};
    Object.keys(data).forEach((key) => {
        if (data[key] instanceof Map) {
            serializableData[key] = Array.from(data[key].entries());
        } else {
            serializableData[key] = data[key];
        }
    });
    return serializableData;
};

const responseInterceptor: [
    (response: HttpClientResponse) => HttpClientResponse,
    (error: unknown) => void,
] = [
    (response: HttpClientResponse): HttpClientResponse => {
        // TODO: For development purposes -> remove later on
        logger.info(`${response['config'].url}`);

        const isLoggedOff = response.headers.loggedin;
        if (isLoggedOff) {
            redirectToLoginWithDeepLinkParam();
        }
        return response;
    },
    async (error: unknown): Promise<void> => {
        logger.error('ERROR', error);
        if (isHttpClientError(error)) {
            const status = error.response?.status;
            if (status === 401 || status == 403) {
                redirectToErrorPage(status);
            }
        }

        return Promise.reject(error);
    },
];

export const config: RestClientConfigParams = {
    baseUrl: '/',
    path: '',
    responseInterceptor,
};

export const upload = async (
    path: string,
    data: any,
    query: QueryStringParams = {},
    url: string = '/',
    headers?: { [key: string]: string },
): Promise<any> => {
    path = path.replace(/\/*$/, ``);
    const formData = new FormData();
    Object.entries(data).forEach((datum: [string, any]) =>
        formData.append(...datum),
    );

    const queryString = new URLSearchParams(query).toString();
    const fullUrl = `${url}${path}${
        queryString.length ? `?${queryString}` : ''
    }`;

    const restClientInstance: ApiRestClient = ApiRestClient.createInstance({
        ...config,
        path: fullUrl,
        headers,
    });
    const result = await restClientInstance.create(formData);
    return result.data;
};

export const search = async (
    model: string,
    query: QueryStringParams,
): Promise<any> => {
    const path = `api/admin/search/${model}`;
    return get(path, query);
};

const executePutOrPatch = async (
    path: string,
    id: string = '',
    data: any = undefined,
    qsParams: { [key: string]: any },
    isPATCH: boolean = false,
): Promise<any> => {
    const restClientInstance: ApiRestClient = ApiRestClient.createInstance({
        ...config,
        path,
    });
    const serializedData = convertMapFields(data);
    const result = await restClientInstance.update(
        id,
        serializedData,
        qsParams,
        isPATCH,
    );
    return result.data;
};

export const get = async (
    path: string,
    qsParams?: QueryStringParams,
): Promise<any> => {
    const restClientInstance: ApiRestClient = ApiRestClient.createInstance({
        ...config,
        path,
    });
    const stopRequests: boolean = updatePathCount(path);

    if (!stopRequests) {
        const result = await restClientInstance.get(qsParams);
        return result.data;
    }

    throw new Error(`Requests limit reached`);
};

export const post = async (
    path: string,
    data: any,
    qsParams: QueryStringParams = {},
): Promise<any> => {
    const restClientInstance: ApiRestClient = ApiRestClient.createInstance({
        ...config,
        path,
    });
    const serializedData = convertMapFields(data);
    const result = await restClientInstance.create(serializedData, qsParams);
    return result.data;
};

export const patch = async (
    path: string,
    id: string = '',
    data: any = undefined,
    qsParams: QueryStringParams = {},
): Promise<any> => executePutOrPatch(path, id, data, qsParams, true);

export const put = async (
    path: string,
    id: string = '',
    data: any = undefined,
    qsParams: QueryStringParams = {},
): Promise<any> => executePutOrPatch(path, id, data, qsParams);

export const deleteRequest = async (
    path: string,
    id: string = '',
    qsParams: QueryStringParams = {},
): Promise<any> => {
    const restClientInstance: ApiRestClient = ApiRestClient.createInstance({
        ...config,
        path,
    });
    const result = await restClientInstance.delete(id, qsParams);
    return result.data;
};

// #region private methods
const REDIRECT_LIMIT = 5;
let currentPath = ``;
let retriesNumber = 0;

const updatePathCount = (path: string): boolean => {
    if (path === currentPath) {
        retriesNumber = retriesNumber++;
        return Boolean(retriesNumber >= REDIRECT_LIMIT);
    }
    currentPath = path;
    retriesNumber = 1;
    return false;
};
// #endregion
