import {
    Hide,
    HideProps,
    ProgressBar,
    Stack,
    Text,
} from '@trova-trip/trova-components';
import {
    Button,
    IconProps,
    Link,
} from '@trova-trip/trova-components/build/next';
import { constants } from '@trova-trip/trova-models';
import { useHistory } from 'react-router-dom';
import { useTrackingEvent } from '../../../../../../../../analytics/hooks';
import { buildHostProductRoute } from '../../../../common/utils';
import { BorderedInnerCard } from '../../../../dashboard/components/common/BorderedInnerCard';
import { UseHostTierInfoReturn } from '../../../../dashboard/hooks/useHostTierInfo';

const TierTooltip = () => (
    <Text as='p' color='neutral.white' size='sm'>
        Itinerary Tiers are unlocked by surveying your audience. Learn more
        about tiers&nbsp;
        <Link
            href='https://trovatrip.com/host/itinerary-tiers'
            color='neutral.white'
            size='sm'
            isExternal
        >
            here
        </Link>
    </Text>
);

const tierTooltipProps = {
    icon: 'help' as IconProps['as'],
    tooltip: <TierTooltip />,
    ariaLabel: 'Additional Tier Information',
};

interface NextItineraryTierProps {
    hostTierInfo: UseHostTierInfoReturn;
    progressValue: number;
}

interface ViewItinerariesButtonProps {
    color: string;
    nextTierName: string;
    hide: HideProps['breakpoint'];
    onClick: () => void;
}

const {
    itinerary: { TierIds },
} = constants;

const ViewItinerariesButton = (
    props: ViewItinerariesButtonProps,
): JSX.Element => {
    const { color, nextTierName, onClick, hide } = props;
    return (
        <Hide breakpoint={hide}>
            <Button
                color={color}
                variant='tertiary'
                size='sm'
                rightIcon='arrow-right'
                onClick={onClick}
            >
                View {nextTierName} Itineraries
            </Button>
        </Hide>
    );
};

const NextItineraryTier = ({
    hostTierInfo,
    progressValue,
}: NextItineraryTierProps): JSX.Element => {
    const { trackUserEvent } = useTrackingEvent();
    const history = useHistory();

    const itinerariesRoute = buildHostProductRoute('ITINERARIES');
    const {
        currentTier: { name: currentTierName },
        nextTier,
    } = hostTierInfo;
    const { color, requiredResponses, name: nextTierName } = nextTier;
    const finalTier = currentTierName === nextTierName;
    const title = `${finalTier ? 'Your' : 'Next'} Itinerary Tier`;

    const goToItineraries = (): void => {
        trackUserEvent({
            eventName: 'Host Viewed Itineraries in Next Tier',
            properties: {
                tierName: nextTierName,
            },
        });

        history.push(`${itinerariesRoute}?nextTier=${TierIds[nextTierName]}`);
    };

    return (
        <BorderedInnerCard
            title={title}
            tooltipProps={tierTooltipProps}
            variant='simple'
            paddingX={{ base: 3, md: 0 }}
            paddingY={{ base: 3, md: 0 }}
            marginBottom={{ base: 0, md: 3 }}
            forceTitleDisplay
            cursor={'default'}
        >
            <Stack direction='column' spacing={4} width='full'>
                <Stack
                    justify='space-between'
                    align='center'
                    width='full'
                    flexShrink={0}
                    paddingY={1}
                >
                    <Text fontSize='4xl' fontWeight='bold' color={color}>
                        {nextTierName.toUpperCase()}
                    </Text>
                    <ViewItinerariesButton
                        hide={{ max: 'md' }}
                        onClick={goToItineraries}
                        color={color}
                        nextTierName={nextTierName}
                    />
                </Stack>
                <ProgressBar
                    color={color}
                    size='sm'
                    borderRadius='sm'
                    min={0}
                    max={requiredResponses}
                    as='linear'
                    value={progressValue}
                />
                <ViewItinerariesButton
                    hide={{ min: 'md' }}
                    onClick={goToItineraries}
                    color={color}
                    nextTierName={nextTierName}
                />
            </Stack>
        </BorderedInnerCard>
    );
};

export default NextItineraryTier;
