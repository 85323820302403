import { coreUtils } from '@trova-trip/trova-common';
import { Heading, LineItem, Stack } from '@trova-trip/trova-components';
import { Card, IconProps } from '@trova-trip/trova-components/build/next';
import { constants } from '@trova-trip/trova-models';
import noop from 'lodash/noop';

const { formatUSD } = coreUtils.currencyUtils;

const { ServiceTiming } = constants.services;
type ServiceTimingType = typeof ServiceTiming[keyof typeof ServiceTiming];

interface ReadOnlyAccommodationProps {
    date: string;
    price?: number;
    description?: string;
    iconProps?: IconProps;
}

interface ActionableAccommodationProps extends ReadOnlyAccommodationProps {
    isSelected?: boolean;
}

interface AccommodationItemProps extends ActionableAccommodationProps {
    title: string;
    hideControl: boolean;
    timing: ServiceTimingType;
    onChange?: (timing: ServiceTimingType) => void;
}

const AccommodationItem = (
    props: AccommodationItemProps,
): JSX.Element | null => {
    const {
        price,
        date,
        timing,
        isSelected = false,
        onChange = noop,
        iconProps,
        ...rest
    } = props;

    const handleChange = () => onChange(timing);

    return (
        <LineItem
            name='prePostAccommodation'
            type='button'
            id={`accommodation-${timing}`}
            value={isSelected}
            onChange={handleChange}
            headerProps={{
                icon: iconProps,
                price: price ? formatUSD(price, 2) : undefined,
                subtitle: date,
            }}
            {...rest}
        />
    );
};

interface SharedPrePostAccommodationCardProps<
    T extends ReadOnlyAccommodationProps,
> {
    preAccommodation?: T;
    postAccommodation?: T;
}

interface ReadOnlyPrePostAccommodationCardProps
    extends SharedPrePostAccommodationCardProps<ReadOnlyAccommodationProps> {
    mode: 'read-only';
}

interface ActionablePrePostAccommodationCardProps
    extends SharedPrePostAccommodationCardProps<ActionableAccommodationProps> {
    mode: 'actionable';
    onChange: (timing: ServiceTimingType) => void;
    isDisabled?: boolean;
}

type PrePostAccommodationCardProps =
    | ReadOnlyPrePostAccommodationCardProps
    | ActionablePrePostAccommodationCardProps;

const PrePostAccommodationCard = (props: PrePostAccommodationCardProps) => {
    const { preAccommodation, postAccommodation } = props;

    if (!preAccommodation && !postAccommodation) {
        return null;
    }

    return (
        <Card width='full'>
            <Heading
                as='h4'
                fontWeight='medium'
                size={{ base: 'xl', lg: '2xl' }}
            >
                Pre and Post Trip Accommodations
            </Heading>
            <Stack direction='column' marginTop={6} spacing={6}>
                {preAccommodation ? (
                    <AccommodationItem
                        {...getAccommodationItemProps(
                            props,
                            ServiceTiming.PRE_TRIP,
                        )}
                    />
                ) : null}

                {postAccommodation ? (
                    <AccommodationItem
                        {...getAccommodationItemProps(
                            props,
                            ServiceTiming.POST_TRIP,
                        )}
                    />
                ) : null}
            </Stack>
        </Card>
    );
};

/**
 * Helper function to get the props for each timing accommodation item
 * (pre & post) on the card component.
 *
 * @param props - All the accommodation card props
 * @param timing - The service timing (pre or post)
 * @returns AccommodationItemProps
 */
const getAccommodationItemProps = (
    props: PrePostAccommodationCardProps,
    timing: ServiceTimingType,
): AccommodationItemProps => {
    const isActionable = props.mode === 'actionable';

    const sharedProps = {
        hideControl: isActionable ? Boolean(props.isDisabled) : true,
        onChange: isActionable ? props.onChange : undefined,
    };

    switch (timing) {
        case ServiceTiming.PRE_TRIP:
            return {
                title: 'Pre-Trip Accommodation',
                description: 'Accommodation one night before the trip starts.',
                timing,
                ...sharedProps,
                ...(props.preAccommodation as ReadOnlyAccommodationProps),
            };
        case ServiceTiming.POST_TRIP:
            return {
                title: 'Post-Trip Accommodation',
                description: 'Accommodation one night after the trip ends.',
                timing,
                ...sharedProps,
                ...(props.postAccommodation as ReadOnlyAccommodationProps),
            };
        default:
            return {} as AccommodationItemProps;
    }
};

export type {
    ActionableAccommodationProps,
    PrePostAccommodationCardProps,
    ReadOnlyAccommodationProps,
};
export default PrePostAccommodationCard;
