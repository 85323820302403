import { BaseBox, Stack } from '@trova-trip/trova-components';
import { isEmpty } from 'lodash';
import useHomeWidgetData from '../../hooks/useHomeWidgetData';
import {
    HostImportantDates,
    WidgetCallbacks,
    WidgetNames,
} from '../../../common/types';
import WidgetCard from '../common/WidgetCard';
import WidgetEmptyCard from '../common/WidgetEmptyCard';
import WidgetHeading from '../common/WidgetHeading';
import WidgetLoadingFallback from '../WidgetLoadingFallback/WidgetLoadingFallback';
import ImportantDatesList from './ImportantDatesList';
import { useEffect } from 'react';

interface ImportantDatesProps extends WidgetCallbacks {}

const ImportantDates = (props: ImportantDatesProps): JSX.Element => {
    const { onError, onLoad } = props;
    const { load, data: importantDates } = useHomeWidgetData<HostImportantDates>(WidgetNames.IMPORTANT_DATES);

    useEffect(() => {
        load().then((res) => {
            if (res.success) {
                onLoad?.();
            } else {
                onError?.();
            }
        });
    }, []);

    if (!importantDates) {
        return (
            <WidgetLoadingFallback renderCount={4} skeletonLoaderHeight='25%' />
        );
    }
    const hasDates = !isEmpty(importantDates);

    return (
        <WidgetCard
            padding={0}
            paddingX={{ base: 0, md: 6 }}
            paddingTop={{ base: 0, md: 4 }}
            height='full'
            overflow='hidden'
        >
            <Stack
                direction='column'
                height='full'
                wrap='nowrap'
                align='stretch'
                spacing={{ base: 0, md: 7 }}
            >
                <BaseBox
                    paddingBottom={{ base: 0, md: 3 }}
                    borderBottom={{ base: 0, md: '1px' }}
                    borderColor={{ md: 'blueGray.300' }}
                >
                    <WidgetHeading>Important Dates</WidgetHeading>
                </BaseBox>
                {hasDates ? (
                    <ImportantDatesList importantDates={importantDates} />
                ) : (
                    <WidgetEmptyCard type='importantDates' />
                )}
            </Stack>
        </WidgetCard>
    );
};

export default ImportantDates;
