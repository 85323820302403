import { GooglePlacesOption } from '@trova-trip/trova-components/build/custom';
import { validationSchemas } from '@trova-trip/trova-models';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';
import * as yup from 'yup';
import { JourneyStage } from '../../../../../state/features/transfer/types';
import {
    ArrivalDepartureTravelDetailFormValues,
    RequiredTravelDetailFormValues,
    SelectableDateRange,
} from '../types/travelDetail';

type LocationType = 'pickup' | 'dropOff';

const { flightNumberValidation } = validationSchemas;
const { requiredMessage } = validationSchemas.validationMessages;

const locationLabelMap: Record<LocationType, string> = {
    pickup: 'Pickup',
    dropOff: 'Drop-off',
};

const getSameLocationErrorMessage = (
    locationName: LocationType,
    oppositeLocationName: LocationType,
) =>
    `${locationLabelMap[locationName]} cannot be the same as ${locationLabelMap[oppositeLocationName]}`;

const createLocationSchema = (locationName: LocationType) => {
    const oppositeLocationName =
        locationName === 'pickup' ? 'dropOff' : 'pickup';
    const locationRequiredMessage = requiredMessage(
        `${locationLabelMap[locationName]} Location`,
    );

    return yup
        .object()
        .shape({
            description: yup.string(),
            label: yup.string(),
            type: yup.string(),
            value: yup.string(),
        })
        .nullable()
        .test(
            'is-completed',
            locationRequiredMessage,
            (location: GooglePlacesOption | null) => {
                if (!location) return false;

                const { label, type, value } = location ?? {};
                return !!(label && type && value);
            },
        )
        .test(
            'is-different-location',
            getSameLocationErrorMessage(locationName, oppositeLocationName),
            (location: GooglePlacesOption | null, context) => {
                if (!location || !context?.parent[oppositeLocationName]) {
                    return true;
                }

                return (
                    location.value !==
                    context.parent[oppositeLocationName].value
                );
            },
        )
        .required(locationRequiredMessage);
};

const transferFieldsValidation = yup.object({
    flightNumber: yup
        .string()
        .required(requiredMessage('Flight Number'))
        .matches(
            flightNumberValidation.regex,
            flightNumberValidation.options.message,
        ),
    pickupTime: yup
        .object()
        .shape({
            label: yup.string(),
            value: yup.string().required(),
        })
        .required(requiredMessage('Pickup Time'))
        .nullable(),
    passengers: yup
        .number()
        .required(requiredMessage('Passengers'))
        .min(1, 'Minimum 1 passenger')
        .max(20, 'Max 20 passengers'),
    luggage: yup.object({
        hand: yup.number(),
        checked: yup.number(),
    }),
});

const createTransferSchema = (validDates: SelectableDateRange) => {
    const transferSchema: yup.SchemaOf<RequiredTravelDetailFormValues> = yup
        .object({
            pickup: createLocationSchema('pickup'),
            dropOff: createLocationSchema('dropOff'),
            pickupDate: yup
                .date()
                .required(requiredMessage('Pickup Date'))
                .typeError('Please enter a valid date')
                .test(
                    'within-range',
                    'Please enter a date inside the range',
                    (value) => {
                        if (!value || !validDates[0] || !validDates[1]) {
                            return false;
                        }

                        const isCurrentlyInTimeRange =
                            (isEqual(value, validDates[0]) ||
                                isAfter(value, validDates[0])) &&
                            (isEqual(value, validDates[1]) ||
                                isBefore(value, validDates[1]));

                        return isCurrentlyInTimeRange;
                    },
                ),
        })
        .concat(transferFieldsValidation);

    return transferSchema;
};

const getValidationSchema = (
    visibleStages: Record<JourneyStage, boolean>,
    validDateRanges: Record<JourneyStage, SelectableDateRange>,
) => {
    const schema: yup.SchemaOf<ArrivalDepartureTravelDetailFormValues> =
        yup.object({
            arrival: (visibleStages.arrival
                ? createTransferSchema(validDateRanges.arrival)
                : yup.object()
            ).optional(),
            departure: (visibleStages.departure
                ? createTransferSchema(validDateRanges.departure)
                : yup.object()
            ).optional(),
        });

    return schema;
};

export { getValidationSchema, transferFieldsValidation };
