import {
    Accordion,
    Alert,
    BaseBox,
    Divider,
    Grid,
    Heading,
    Stack,
    Text,
} from '@trova-trip/trova-components';
import {
    Button,
    Card,
    Icon,
    Table,
} from '@trova-trip/trova-components/build/next';
import format from 'date-fns/format';
import noop from 'lodash/noop';
import { useState } from 'react';
import {
    BookedGroundTransferJourney,
    CancellationType,
} from '../../../../../state/features/transfer/types';
import {
    parseQuoteVehicleCategory,
    userHasGroundTransferBooked,
} from '../../../helpers/transfer';
import { isTraveler } from '../../../helpers/user';
import useIsLargeScreen from '../../../hooks/useIsLargeScreen';
import { useTransfer } from '../hooks';
import RideOperationsPopup from './RideOperationsPopup';
import IconText from './shared/IconText';
import InfoRow from './shared/InfoRow';
import JourneyPrice from './shared/JourneyPrice';
import JourneyStatusBadge from './shared/JourneyStatusBadge';
import { constants } from '@trova-trip/trova-models';

type GroundTransferBookingStatus = constants.groundTransfers.GroundTransferBookingStatus;
export const { GroundTransferBookingStatus } = constants.groundTransfers;

/**
 * Returns the heading for the journeys section based on their type and quantity.
 *
 * @param journeyType - Union type with possible values to ensure that pluralization works correctly
 * @param journeysQuantity
 * @return The heading for the journeys section, pluralized if there are multiple journeys.
 */
const getJourneysSectionHeading = (
    journeyType: 'Arrival' | 'Departure',
    journeysQuantity: number,
): string => {
    const isSingleJourney = journeysQuantity === 1;
    return isSingleJourney ? journeyType : journeyType + 's';
};

interface StatusRowProps {
    status: GroundTransferBookingStatus;
}

const StatusRow = ({ status }: StatusRowProps): JSX.Element => (
    <Table.Row>
        <Table.Cell padding={0}>
            <Text fontSize='sm' color='blueGray.650'>
                Status
            </Text>
        </Table.Cell>
        <Table.Cell textAlign='right' paddingRight={0}>
            <JourneyStatusBadge status={status} />
        </Table.Cell>
    </Table.Row>
);

interface JourneyPassengersProps {
    passengers: number;
}

const JourneyPassengers = ({
    passengers,
}: JourneyPassengersProps): JSX.Element => (
    <Stack align='start' spacing={1}>
        <IconText icon='people' text={passengers} variant='journey-preview' />
    </Stack>
);

interface JourneyPreviewProps {
    journey: BookedGroundTransferJourney;
}

const JourneyPreview = ({
    journey,
}: JourneyPreviewProps): JSX.Element | null => {
    const [isRideDetailsOpen, setIsRideDetailsOpen] = useState(false);

    const {
        id,
        code,
        date,
        status,
        price,
        origin: { fullResolvedAddress: originFullResolvedAddress },
        passengers,
        cancellationType,
        vehicleCategory,
    } = journey;

    if (!id) return null;

    const formattedDate = format(new Date(date), 'MM/dd/yyyy HH:mm');
    const journeyTitle =
        originFullResolvedAddress.establishment ||
        originFullResolvedAddress.region;
    const shouldDisplayCancellationAlert =
        status === GroundTransferBookingStatus.CANCELLED &&
        cancellationType === CancellationType.PROVIDER;

    return (
        <Stack
            direction='column'
            width='full'
            spacing={4}
            height='full'
            paddingBottom={{ base: 8, lg: 0 }}
        >
            <Stack
                direction='column'
                align='stretch'
                justify='space-between'
                spacing={4}
                width='full'
                borderBottom='1px solid'
                borderColor='blueGray.200'
                flexGrow={1}
            >
                <Stack justify='space-between' width='full'>
                    <BaseBox flex={1}>
                        <Text
                            fontWeight='bold'
                            fontSize='sm'
                            lineHeight='5'
                            color='blueGray.800'
                        >
                            {journeyTitle}
                        </Text>
                    </BaseBox>
                    <Stack align='center' spacing={3}>
                        <JourneyPassengers passengers={passengers} />
                        <JourneyPrice price={price} variant='journey-preview' />
                    </Stack>
                </Stack>

                {shouldDisplayCancellationAlert ? (
                    <Alert
                        marginTop={6}
                        title='Ride cancelled by transfer provider'
                        onCloseAlert={noop}
                        isClosable={false}
                        variant='inline'
                        status='warning'
                        size='sm'
                        description="If you think there's an error, please reach out to customer support for assistance."
                    />
                ) : null}

                <Table variant='simple' borderOutline={false}>
                    <Table.Body>
                        <StatusRow status={status} />
                        <InfoRow
                            title='Journey Code'
                            value={code}
                            textAlign='right'
                        />
                        <InfoRow
                            title='Date & Time'
                            value={formattedDate}
                            textAlign='right'
                        />
                        <InfoRow
                            title='Vehicle'
                            value={parseQuoteVehicleCategory(vehicleCategory)}
                            textAlign='right'
                        />
                    </Table.Body>
                </Table>
            </Stack>

            <BaseBox width='full'>
                <Button
                    isFullWidth={{ base: true, md: false }}
                    size={{ base: 'md', sm: 'sm' }}
                    variant='tertiary'
                    as='button'
                    onClick={() => setIsRideDetailsOpen(true)}
                >
                    View Details
                </Button>
            </BaseBox>

            <RideOperationsPopup
                isOpen={isRideDetailsOpen}
                onClose={() => setIsRideDetailsOpen(false)}
                journey={journey}
            />
        </Stack>
    );
};

interface JourneyInfoGridProps {
    journeys: BookedGroundTransferJourney[];
}

const JourneyInfoGrid = ({ journeys }: JourneyInfoGridProps): JSX.Element => (
    <Grid
        width='full'
        paddingTop={{ base: 6, lg: 8 }}
        gap={{ base: 0, md: 12 }}
    >
        {journeys.map((journeyInfo) => (
            <Grid.Item
                key={journeyInfo.id}
                columnSpan={{
                    base: 12,
                    lg: 4,
                }}
            >
                <JourneyPreview journey={journeyInfo} />
            </Grid.Item>
        ))}
    </Grid>
);

const JourneySectionDivider = (): JSX.Element => (
    <Divider color='blueGray.300' marginTop={2} />
);

interface JourneysSectionProps {
    journeys: BookedGroundTransferJourney[] | undefined;
    heading: string;
}

const JourneysSection = ({
    journeys,
    heading,
}: JourneysSectionProps): JSX.Element | null => {
    if (!journeys) return null;

    return (
        <Stack direction='column'>
            <Heading as='h6' marginTop={{ base: 2, lg: 4 }}>
                {heading}
            </Heading>

            <JourneySectionDivider />

            <JourneyInfoGrid journeys={journeys} />
        </Stack>
    );
};

const JourneysSectionAccordion = ({
    journeys,
    heading,
}: JourneysSectionProps): JSX.Element | null => {
    if (!journeys) return null;

    return (
        <Accordion allowToggle defaultIndex={0}>
            <Accordion.Item>
                {({ isExpanded }) => (
                    <>
                        <Accordion.Header
                            marginTop={{ base: 2, lg: 4 }}
                            text={heading}
                            fontSize='lg'
                            fontWeight='bold'
                            rightElement={
                                <Icon
                                    as={isExpanded ? 'arrow-up' : 'arrow-down'}
                                    size='lg'
                                />
                            }
                        />

                        <JourneySectionDivider />

                        <Accordion.Panel>
                            <JourneyInfoGrid journeys={journeys} />
                        </Accordion.Panel>
                    </>
                )}
            </Accordion.Item>
        </Accordion>
    );
};

const ScheduledTransferCard = (): JSX.Element | null => {
    const {
        globalState: { trip, booking, user, groundTransfersByStage },
    } = useTransfer();

    const isLargeScreen = useIsLargeScreen();

    const hasGroundTransferBooked = userHasGroundTransferBooked(
        user,
        trip,
        booking,
    );

    const shouldDisplayScheduledTransferCard =
        hasGroundTransferBooked && groundTransfersByStage;

    if (!shouldDisplayScheduledTransferCard) return null;

    const arrivalJourneys = groundTransfersByStage.arrival;
    const departureJourneys = groundTransfersByStage.departure;

    const arrivalsQuantity = arrivalJourneys?.length || 0;
    const departuresQuantity = departureJourneys?.length || 0;

    const isSingleArrival = arrivalsQuantity === 1;
    const isSingleDeparture = departuresQuantity === 1;

    const isSingleRide = arrivalsQuantity + departuresQuantity === 1;
    const title = isSingleRide
        ? 'Your Ride is Scheduled'
        : 'Your Rides are Scheduled';

    const arrivalJourneysSectionProps: JourneysSectionProps = {
        journeys: arrivalJourneys,
        heading: getJourneysSectionHeading('Arrival', arrivalsQuantity),
    };
    const departureJourneysSectionProps: JourneysSectionProps = {
        journeys: departureJourneys,
        heading: getJourneysSectionHeading('Departure', departuresQuantity),
    };

    const isTravelerUser = isTraveler(user);

    return (
        <Card marginTop={6} width='full'>
            <Heading
                as='h4'
                fontWeight='medium'
                size={{ base: 'xl', lg: '2xl' }}
            >
                {title}
            </Heading>
            {isTravelerUser ? (
                <Text marginY={4} color='blueGray.650'>
                    {`Change of plans? No problem! Cancel for free up to 24 hours before your ride's date and rebook at any time. Within 24 hours, cancelled rides will still be charged and may not be rebooked.`}
                </Text>
            ) : null}

            <Stack align='stretch' direction='column' spacing={3}>
                {isLargeScreen || isSingleArrival ? (
                    <JourneysSection {...arrivalJourneysSectionProps} />
                ) : (
                    <JourneysSectionAccordion
                        {...arrivalJourneysSectionProps}
                    />
                )}
                {isLargeScreen || isSingleDeparture ? (
                    <JourneysSection {...departureJourneysSectionProps} />
                ) : (
                    <JourneysSectionAccordion
                        {...departureJourneysSectionProps}
                    />
                )}
            </Stack>
        </Card>
    );
};

export default ScheduledTransferCard;
