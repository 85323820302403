import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

// the css import order matters; this should be the order: from the most general to the most specific
import '../../assets/css/file-uploader/fileuploader.min.css';
import '../../assets/css/file-uploader/fileuploader-theme-gallery.css';
import '../../assets/css/file-uploader/font-fileuploader.css';
import '../../assets/css/file-uploader/trova-file-uploader.css';

import FakeEvent from '../../util/form/FakeEvent';
import configuration from './fileUploaderConfiguration';

const $ = window.jQuery;

class Fileuploader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {},
            name: props.name,
            files: [],
        };

        document.addEventListener(`image:uploaded`, this.onImagesChange);
        document.addEventListener(`image:deleted`, this.onImagesChange);

        if (props.sortable) {
            document.addEventListener(`image:sort`, this.onImagesChange);
        }

        for (const key in props) {
            const val = props[key];

            if (typeof val !== `string`) continue;

            if (
                [
                    `limit`,
                    `maxSize`,
                    `fileMaxSize`,
                    `theme`,
                    `listInput`,
                ].indexOf(key) > -1
            )
                this.state[key] = val;
            if (`extensions` === key)
                this.state[key] = val.replace(/ /g, ``).split(`,`);
            if (`files` === key) this.state[key] = JSON.parse(val);
        }
        if (props[`disabled`]) this.state[`limit`] = 0;
    }

    onImagesChange = (event) => {
        event.preventDefault();

        if (!this.isUploading()) {
            const files = this.getFilesForInput();

            const filesResult = this.getProperFilesObject(files);
            this.props.onChange(new FakeEvent(this.state.name, filesResult));
        }
    };

    isUploading = () => {
        if (!this.api) return false;
        return this.api
            .getFiles()
            .some(
                (file) => 
                    file.uploaded !== undefined && file.uploaded === false,
            );
    };

    getFilesForInput = () => {
        if (!this.api) return [];
        
        let files = this.api.getFiles();
        files = files.filter(file => !file.deleted).map((file, index) => {
            this.renameFileByPosition(index, file);

            if (file.data.originalFileName) {
                return file.data.originalFileName;
            }

            return file.url;
        });

        return files;
    }

    renameFileByPosition(index, file) {
        let newName;
        if (index === 0) {
            newName = `Featured`;
        } else {
            newName = `${this.state.name.replace(/^\w/, c => c.toUpperCase())} - ${index}`;
        }

        // As long as the HTML is not changed, this will work as expected.
        // Couldn't find the way to change it programmatically via their API
        file.html[0].firstChild.children[2].firstChild.firstChild.data = newName;
    }

    getProperFilesObject(files) {
        let filesResult;
        if (this.state.limit === `1` && Array.isArray(files)) {
            filesResult = files.length > 0 ? files[0] : ``;
        } else {
            filesResult = files.length === 1 ? files[0] : files;
        }

        return filesResult;
    }

    componentDidMount() {
        const configurationFn = configuration($)(this.state.name);

        // will get the default config and then overwritten with the state (previously loaded with props)
        const config = { ...configurationFn.getDefault(), ...this.state };

        const label = this.props.label;
        const captions = configurationFn.getCaptions();

        if (label) {
            captions.feedback = label;
        }

        this.$el = $(this.el);
        this.$el.fileuploader({
            ...config,
            thumbnails: configurationFn.getThumbnailsTemplate(this.props.classes, !this.props.disabled),
            dragDrop: configurationFn.getDragAndDrop(),
            upload: configurationFn.getUploadConfig({ apiUrl: this.props.uploadUrl, isSortable: this.props.sortable }),
            sorter: this.props.sortable ? configurationFn.getSorter() : false,
            afterRender: configurationFn.getAfterRenderFn(),
            onRemove: configurationFn.getOnRemoveFn(),
            captions,
            files: configurationFn.preloadFiles(this.props.value),
        });

        this.api = $.fileuploader.getInstance(this.el);

        this.setState({ files: this.props.value });
    }

    componentDidUpdate() {
        if (!this.api) return;
        
        const currentFiles = this.state.files;
        const newFiles = this.props.value;

        if (isEqual(currentFiles, newFiles)) return;

        const configurationFn = configuration($)(this.state.name);
        this.api.reset();
        this.api.append(configurationFn.preloadFiles(newFiles));
        this.setState({ files: newFiles });
    }

    componentWillUnmount() {
        if (this.api) this.api.destroy();

        document.removeEventListener(`image:uploaded`, this.onImagesChange);
        document.removeEventListener(`image:deleted`, this.onImagesChange);
        
        if (this.props.sortable) {
            document.removeEventListener(`image:sort`, this.onImagesChange);
        }
    }

    render() {
        return (
            <input
                type="file"
                name={this.state.name}
                ref={(el) => (this.el = el)}
                id={this.state.name}
            />
        );
    }
}

Fileuploader.defaultProps = {
    sortable: true,
};

Fileuploader.propTypes = {
    limit: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    uploadUrl: PropTypes.string.isRequired,
    sortable: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    extensions: PropTypes.string,
    maxSize: PropTypes.string,
    fileMaxSize: PropTypes.string,
    classes: PropTypes.string, // will be applied to the list of image elements and the input of the component (used to fit the component to different places)
    theme: PropTypes.oneOf([`gallery`]), // will only work for the gallery theme ATM. Additional css files needed to support other themes
    label: PropTypes.string
};

export default Fileuploader;
