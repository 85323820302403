import {
    buildHomeRoute,
    buildHostProductRoute,
} from 'applications/host/products/home/common/utils';
import { HOST_PUBLIC_PROFILE_ROUTES } from '../../applications/common/constants/routesTokens';

const noSidebarPaths = [
    buildHomeRoute('DASHBOARD', true),
    buildHomeRoute('PROGRESS', true),
    '/app/host/audience/research',
    '/app/host/itineraries',
    `${buildHostProductRoute('ITINERARIES')}/:itineraryId/request`,
    `${buildHostProductRoute('PUBLIC_PROFILE')}`,
    `${buildHostProductRoute('PUBLIC_PROFILE')}${
        HOST_PUBLIC_PROFILE_ROUTES.EDIT
    }`,
];

export { noSidebarPaths };
