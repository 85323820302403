import { Text, BaseBox } from '@trova-trip/trova-components';

export function QualifyingSidebar(): JSX.Element {
    return (
        <>
            <BaseBox paddingBottom={2}>
                <Text size={'lg'} fontWeight={'bold'}>
                    Survey your audience!
                </Text>
            </BaseBox>
            <Text size={'lg'}>
                Start surveying to gain insights about what itineraries fit your
                audience best.
            </Text>
        </>
    );
}
