import { constants } from '@trova-trip/trova-models';
import { useSelector } from 'state/hooks';

const {
    itinerary: { TIER },
} = constants;

export const useGetHostDefaultTiers = (nextTier: string | null): string[] => {
    const { current: host } = useSelector((state) => state.profile);
    const tiers = Object.keys(TIER);

    const hostTiers = tiers.filter((tier) =>
        host?.itineraryTier ? host.itineraryTier >= Number(tier) : false,
    );

    return nextTier ? [nextTier] : hostTiers;
};
