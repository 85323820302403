import { coreUtils } from '@trova-trip/trova-common';
import {
    Badge,
    BaseBox,
    BaseBoxProps,
    Grid,
    Heading,
    Hide,
    List,
    Stack,
    Text,
} from '@trova-trip/trova-components';
import {
    Button,
    Card,
    Icon,
    IconProps,
} from '@trova-trip/trova-components/build/next';
import { constants, models } from '@trova-trip/trova-models';
import includes from 'lodash/includes';
import {
    isInsuranceCancellingStatus,
    isInsuranceErrorStatus,
} from '../../../../../../state/features/manageBooking/utils';
import { useSelector } from '../../../../../../state/hooks';
import {
    TripWithBooking,
    isPrimaryTraveler,
} from '../../../../../common/helpers';
import { useTravelerAnalytics } from '../../../../hooks/index';
import { getInsuranceAddOnsByUser } from '../../../../utils';
import { useManageBooking } from '../../hooks';
import CancelInsurancePolicyPopup from '../CancelInsurancePolicyPopup';

const { isInsurancePolicyCancellable } = coreUtils.insuranceUtils;

type InsurancePolicyResponse = models.insurance.InsurancePolicyResponse;

const { InsuranceStatus } = constants.insurance;
const { InternalCancellationReason } = constants.bookings;

const Copy = {
    PROCESSING:
        'Your insurance policy is currently being processed and can take up to 30 days to receive. Once processed, you can view your policy details and file a claim here.',
    COMPLETE: 'This plan is purchased through {carrier}. {name} plan includes:',
    CANCELLED: {
        LOCK_PERIOD:
            'Your insurance policy has been canceled and we are processing your refund. Refunds may take 5-10 business days to process. Please reach out to our Support with any questions.',
        INTERNAL_REASON_FULL_REFUND:
            'Your booking has been cancelled and fully refunded, including your insurance premium. Please contact us if you have any questions.',
    },
};

const fullRefundReasons = [
    InternalCancellationReason.HOST_FULL_REFUND,
    InternalCancellationReason.TROVA_FULL_REFUND,
    InternalCancellationReason.OPERATOR_FULL_REFUND,
];

interface GenerateCopyParams {
    isCompletePolicy: boolean;
    isCancelledPolicy: boolean;
    isBookingCancelledFullRefund: boolean;
    carrier: string | undefined;
    name: string;
}

const getCopy = ({
    isCompletePolicy,
    isCancelledPolicy,
    isBookingCancelledFullRefund,
    carrier,
    name,
}: GenerateCopyParams): string => {
    if (isCompletePolicy) {
        return `Your policy with ${
            carrier ?? 'your carrier'
        }, ${name}, is complete.`;
    }

    if (isBookingCancelledFullRefund) {
        return Copy.CANCELLED.INTERNAL_REASON_FULL_REFUND;
    }

    if (isCancelledPolicy) {
        return Copy.CANCELLED.LOCK_PERIOD;
    }

    return Copy.PROCESSING;
};

export interface InsurancePolicySummary
    extends Pick<
        InsurancePolicyResponse,
        | 'coverage'
        | 'policyNumber'
        | 'claimsUrl'
        | 'confirmationOfBenefitsUrl'
        | 'status'
        | 'firstName'
        | 'lastName'
        | 'createdDate'
    > {
    // Allow for additional info we don't need.
    [key: string | number | symbol]: any;
}

interface InsurancePolicyCardProps {
    policySummary: InsurancePolicySummary;
    addOnId: string;
    onOpenCancelInsuranceModal: (addOnId: string) => void;
}

const InsurancePolicyCard = ({
    policySummary,
    onOpenCancelInsuranceModal,
    addOnId,
}: InsurancePolicyCardProps): JSX.Element => {
    const {
        state: {
            insurance: { status: insuranceSectionStatus },
        },
        globalState: { traveler, trip },
    } = useManageBooking();

    const { trackInsuranceFileAClaimEvent, trackInsurancePolicyViewedEvent } =
        useTravelerAnalytics();

    const {
        policyNumber,
        coverage,
        claimsUrl,
        confirmationOfBenefitsUrl,
        status,
        firstName,
        lastName,
        createdDate,
    } = policySummary;

    const { name, carrier } = InsurancePlanInfoByCoverage[coverage];

    const isCompletePolicy =
        policyNumber !== undefined &&
        (status === InsuranceStatus.CONFIRMED ||
            status === InsuranceStatus.MODIFICATION_PENDING);

    const isCancelledPolicy =
        status === InsuranceStatus.CANCELLED ||
        status === InsuranceStatus.CANCELLATION_PENDING;

    const internalCancellationReason = (trip as TripWithBooking)?.booking
        ?.internalCancellationReason;

    const isBookingCancelledFullRefund = includes(
        fullRefundReasons,
        internalCancellationReason,
    );

    const isCancellingPolicy = isInsuranceCancellingStatus(
        insuranceSectionStatus,
    );

    const badge = isCompletePolicy ? (
        <Badge
            backgroundColor='teal.trova'
            color='neutral.white'
            variant='rounded'
        >
            POLICY # {policyNumber}
        </Badge>
    ) : isCancelledPolicy || isBookingCancelledFullRefund ? (
        <Badge colorScheme='purple' variant='rounded'>
            CANCELLED
        </Badge>
    ) : (
        <Badge
            backgroundColor='orange.200'
            color='orange.900'
            variant='rounded'
        >
            PROCESSING
        </Badge>
    );

    const shouldDisplayCancelPolicyButton =
        isPrimaryTraveler(traveler.type) &&
        isInsurancePolicyCancellable(createdDate);

    const fullName = `${firstName} ${lastName}`;

    return (
        <Card width='full' marginTop={6}>
            <Stack align='center' justify='space-between'>
                <Heading
                    as='h4'
                    fontWeight='medium'
                    size={{ base: 'xl', lg: '2xl' }}
                >
                    {name}
                </Heading>
                <Hide breakpoint={{ max: 'sm' }}>{badge}</Hide>
            </Stack>
            <Stack>
                <Text
                    fontSize={{
                        md: 'base',
                        lg: 'lg',
                    }}
                    fontWeight='medium'
                    lineHeight='normal'
                    marginTop={{ base: 2, md: 2 }}
                    textTransform='capitalize'
                >
                    {fullName}
                </Text>
            </Stack>
            <Stack direction='column' spacing={4} paddingTop={2}>
                <Hide breakpoint={{ min: 'sm' }}>
                    <BaseBox>{badge}</BaseBox>
                </Hide>
                <Text color='blueGray.650'>
                    {getCopy({
                        isCompletePolicy,
                        isCancelledPolicy,
                        isBookingCancelledFullRefund,
                        carrier,
                        name,
                    })}
                </Text>
                {isCompletePolicy ? (
                    <InsuranceBenefitBullets
                        coverage={coverage}
                        paddingLeft={3}
                        paddingRight={3}
                    />
                ) : null}
            </Stack>
            {!isCancelledPolicy ? (
                <Grid paddingTop={{ base: 6, sm: 8 }} gap={4}>
                    <Grid.Item columnSpan={{ base: 4, md: 1 }}>
                        <Button
                            isFullWidth={{ base: true, md: false }}
                            size='sm'
                            variant='secondary'
                            as='a'
                            linkProps={{
                                rel: 'noreferrer',
                                href: claimsUrl,
                                target: '_blank',
                            }}
                            isDisabled={!isCompletePolicy}
                            onClick={() => trackInsuranceFileAClaimEvent()}
                        >
                            File a Claim
                        </Button>
                    </Grid.Item>
                    <Grid.Item columnSpan={{ base: 4, md: 1 }}>
                        <Button
                            isFullWidth={{ base: true, md: false }}
                            size='sm'
                            variant='secondary'
                            as='a'
                            linkProps={{
                                rel: 'noreferrer',
                                href: confirmationOfBenefitsUrl,
                                target: '_blank',
                            }}
                            isDisabled={!isCompletePolicy}
                            onClick={() => trackInsurancePolicyViewedEvent()}
                        >
                            View Details
                        </Button>
                    </Grid.Item>
                    {shouldDisplayCancelPolicyButton ? (
                        <Grid.Item columnSpan={{ base: 4, md: 1 }}>
                            <Button
                                isFullWidth={{ base: true, md: false }}
                                size='sm'
                                variant='secondary'
                                isLoading={isCancellingPolicy}
                                onClick={() =>
                                    onOpenCancelInsuranceModal(addOnId)
                                }
                            >
                                Cancel Policy
                            </Button>
                        </Grid.Item>
                    ) : null}
                </Grid>
            ) : null}
        </Card>
    );
};

type InsuranceCoverage = constants.insurance.InsuranceCoverage;
const { InsurancePlanInfoByCoverage } = constants.insurance;

type InsuranceBenefitBulletsProps = {
    coverage: InsuranceCoverage;
} & BaseBoxProps;

const InsuranceBenefitBullets = ({
    coverage,
    ...baseBoxProps
}: InsuranceBenefitBulletsProps): JSX.Element => {
    const benefits = InsurancePlanInfoByCoverage[coverage].details ?? [];

    return (
        <BaseBox {...baseBoxProps}>
            <List listStyleType='none'>
                {benefits.map(({ icon, text }, index) => (
                    <List.Item key={text}>
                        <Stack
                            spacing={2}
                            align='center'
                            paddingTop={index !== 0 ? 0.5 : undefined}
                        >
                            <Icon
                                as={icon as IconProps['as']}
                                size='xs'
                                variant='outlined'
                                color='blueGray.800'
                                aria-hidden='true'
                                display='flex'
                            />
                            <Text size='sm' color='blueGray.650'>
                                {text}
                            </Text>
                        </Stack>
                    </List.Item>
                ))}
            </List>
        </BaseBox>
    );
};

interface InsuranceSectionProps {
    userType: string;
}

const InsuranceSection = ({ userType }: InsuranceSectionProps): JSX.Element => {
    const { user } = useSelector((state) => ({
        user: state.profile.current,
    }));

    const {
        state: {
            addOns: { insurance: insuranceAddOns },
            insurance: { cancelAddOnId, status: insuranceSectionStatus },
        },
        actions: {
            closeCancelInsuranceModal,
            resetInsuranceState,
            openCancelInsuranceModal,
        },
    } = useManageBooking();

    const insuranceData = getInsuranceAddOnsByUser(
        insuranceAddOns,
        user,
        userType,
    );

    const handleOpenCancelInsuranceModal = (addOnId: string): void => {
        openCancelInsuranceModal({ addOnId });
    };

    const handleCloseCancelInsuranceModal = (): void => {
        closeCancelInsuranceModal();

        if (isInsuranceErrorStatus(insuranceSectionStatus)) {
            resetInsuranceState();
        }
    };

    return (
        <>
            <Stack direction='column' spacing={6}>
                {insuranceData?.map((insurance) => {
                    const { addOnId, insurancePolicy } = insurance;
                    return (
                        <InsurancePolicyCard
                            key={addOnId}
                            policySummary={insurancePolicy}
                            addOnId={addOnId}
                            onOpenCancelInsuranceModal={
                                handleOpenCancelInsuranceModal
                            }
                        />
                    );
                })}
            </Stack>
            <CancelInsurancePolicyPopup
                isOpen={!!cancelAddOnId}
                onClose={handleCloseCancelInsuranceModal}
                addOnId={cancelAddOnId}
            />
        </>
    );
};

export default InsuranceSection;
