import {
    PendingTrovaApprovalTrip,
    modelTypes,
} from '../../../config/constants';

interface GetTripLinkConfig {
    model: string;
    id: string;
    status: string;
}

/**
 * Returns the URL to a trip page based on the trip/tripRequest model and status.
 * @param {string} model - The model of the trip.
 * @param {string} id - The id of the trip.
 * @param {string} status - The status of the trip.
 * @returns {string} - The link to the trip page.
 * @throws {Error} - If the model, status or id are not provided.
 */
const getTripLink = (args: GetTripLinkConfig): string => {
    Object.keys(args).forEach((key) => {
        if (!args[key]) {
            throw new Error(`Missing required argument: ${key}`);
        }
    });
    const { model, id, status } = args;
    const isTripRequest = model === modelTypes.TRIP_REQUEST;
    const isTripPendingTrovaApproval = PendingTrovaApprovalTrip.has(status);
    const tab =
        isTripRequest || isTripPendingTrovaApproval
            ? 'request'
            : 'configuration';

    return `/app/host/trips/${id}/${tab}?model=${model}`;
};

export { getTripLink };
