import React from 'react';
import PropTypes from 'prop-types';

import Tab from '../../../../navigation/Tab';
import { StyledImage, messageContainer } from './SuccessMessage.components';
import successImg from '../../../../assets/img/img-success.svg';
import { Heading, BaseBox } from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';

const SuccessMessage = ({ title, subtitle, onClickButton, buttonMessage }) => (
    <Tab label={``} path={`/success`} hideNavigation={true}>
        <div className={messageContainer()}>
            <StyledImage
                src={successImg}
                alt={`Success Message Image`}
                data-gumlet='false'
            />
            <BaseBox marginBottom={4}>
                <Heading as={'h4'}>{title}</Heading>
            </BaseBox>
            <BaseBox marginBottom={20}>
                <Heading as={'h5'}>{subtitle}</Heading>
            </BaseBox>
            <Button variant='primary' onClick={onClickButton}>
                {buttonMessage}
            </Button>
        </div>
    </Tab>
);

SuccessMessage.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    image: PropTypes.string,
    buttonMessage: PropTypes.string,
    onClickButton: PropTypes.func,
};

export default SuccessMessage;
