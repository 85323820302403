import { analytics } from '@trova-trip/trova-common';
import flatMap from 'lodash/flatMap';
import times from 'lodash/times';

import {
    AddedAndRemovedSectionsType,
    AnalyticsEvent,
    AnalyticsEventNameMap,
    ClearCartPayload,
    DefaultProps,
    ManageBookingAddOn,
    ModifyBookingPayload,
    Service,
    TrackEventProperties,
    TrackEventProps,
    UpdateCartPayload,
    ViewManageBookingTabPayload,
    ViewPortalCheckoutPagePayload,
} from '../types';
import { getAddOnStatusAndType } from './check.utils';
import { updateAddOnQuantity } from './transform.utils';

interface AnalyticsAddedAndRemovedAddOns {
    selectedServices: ManageBookingAddOn[];
    removedServices: ManageBookingAddOn[];
}

/**
 * Creates an object containing added and removed add-ons for analytics.
 *
 * @param sections - `added` and `removed`
 * @param singleSupplement
 * @returns An object with selected and removed add-ons for analytics.
 */
const createAnalyticsStandardAddOns = (
    sections: AddedAndRemovedSectionsType,
    singleSupplement: ManageBookingAddOn | undefined,
): AnalyticsAddedAndRemovedAddOns => {
    const analyticsAddOns: AnalyticsAddedAndRemovedAddOns = {
        selectedServices: [],
        removedServices: [],
    };

    const createSingleAddOnsFromAddOnWithQuantity = (
        addOn: ManageBookingAddOn,
    ): ManageBookingAddOn[] => {
        const { isSingleSupplementAddOn } = getAddOnStatusAndType(addOn);

        return times(addOn.quantity, () => ({
            ...updateAddOnQuantity(addOn),
            name: isSingleSupplementAddOn ? 'Single Supplement' : undefined,
        }));
    };

    if (singleSupplement) {
        const { isPendingAdditionAddOn, isPendingRemovalAddOn } =
            getAddOnStatusAndType(singleSupplement);

        if (isPendingAdditionAddOn || isPendingRemovalAddOn) {
            if (isPendingAdditionAddOn) {
                const singleSupplementAddOnsByQuantity =
                    createSingleAddOnsFromAddOnWithQuantity(singleSupplement);

                analyticsAddOns.selectedServices.push(
                    ...singleSupplementAddOnsByQuantity,
                );
            }

            if (isPendingRemovalAddOn && sections?.roomSelection?.price) {
                //To get the quantity of the removed single supplement add-ons, we divide the price of the
                // room selection summary section by the unit price of the single supplement add-on.
                const roomSelectionTotal = Math.abs(
                    sections.roomSelection.price,
                );
                const quantity =
                    roomSelectionTotal / singleSupplement.unitPriceWithFee;

                const singleSupplementAddOnsByQuantity =
                    createSingleAddOnsFromAddOnWithQuantity({
                        ...singleSupplement,
                        quantity,
                    });

                analyticsAddOns.removedServices.push(
                    ...singleSupplementAddOnsByQuantity,
                );
            }
        }
    }

    const pushStandardAddOnsByQuantity = (
        addOns: ManageBookingAddOn[],
        targetArray: ManageBookingAddOn[],
    ): void => {
        const addOnsByQuantity = flatMap(addOns, (addOn) =>
            createSingleAddOnsFromAddOnWithQuantity(addOn),
        );
        targetArray.push(...addOnsByQuantity);
    };

    pushStandardAddOnsByQuantity(
        sections.added,
        analyticsAddOns.selectedServices,
    );
    pushStandardAddOnsByQuantity(
        sections.removed,
        analyticsAddOns.removedServices,
    );

    return analyticsAddOns;
};

const trackEvent = <T extends DefaultProps>(
    props: TrackEventProps<T>,
): void => {
    const { eventName, payload } = props;
    analytics.trackEvent({
        eventName,
        properties: payload as TrackEventProperties,
    });
};

const trackViewManageBookingTabEvent = (
    payload: ViewManageBookingTabPayload,
): void => {
    const eventName =
        AnalyticsEventNameMap[AnalyticsEvent.VIEW_MANAGE_BOOKING_TAB];
    trackEvent({ eventName, payload });
};

const trackUpdateCartEvent = (payload: UpdateCartPayload): void => {
    const { sections, singleSupplement, ...rest } = payload;

    const { selectedServices, removedServices } = createAnalyticsStandardAddOns(
        sections,
        singleSupplement,
    );

    trackEvent({
        eventName: AnalyticsEventNameMap[AnalyticsEvent.UPDATE_CART],
        payload: { selectedServices, removedServices, ...rest },
    });
};

const trackViewPortalCheckoutPageEvent = (
    payload: ViewPortalCheckoutPagePayload,
): void => {
    const { sections, singleSupplement, ...rest } = payload;

    const { selectedServices, removedServices } = createAnalyticsStandardAddOns(
        sections,
        singleSupplement,
    );

    trackEvent({
        eventName:
            AnalyticsEventNameMap[AnalyticsEvent.VIEW_PORTAL_CHECKOUT_PAGE],
        payload: { selectedServices, removedServices, ...rest },
    });
};

const trackClearCartEvent = (payload: ClearCartPayload): void => {
    const { sections, singleSupplement, ...rest } = payload;

    const { selectedServices, removedServices } = createAnalyticsStandardAddOns(
        sections,
        singleSupplement,
    );

    trackEvent({
        eventName: AnalyticsEventNameMap[AnalyticsEvent.CLEAR_CART],
        payload: { selectedServices, removedServices, ...rest },
    });
};

const getPurchaseServicePayload = (addOn: ManageBookingAddOn): any => {
    const { isSingleSupplementAddOn } = getAddOnStatusAndType(addOn);
    const service = addOn?.service as Service;

    return {
        price: addOn.unitPriceWithFee,
        serviceId: service?.id,
        serviceName: isSingleSupplementAddOn
            ? 'Single Supplement'
            : service?.name,
        servicePrice: service?.price,
    };
};

const trackModifyBookingEvent = (payload: ModifyBookingPayload): void => {
    const { sections, singleSupplement, ...rest } = payload;

    const { selectedServices, removedServices } = createAnalyticsStandardAddOns(
        sections,
        singleSupplement,
    );

    trackEvent({
        eventName: AnalyticsEventNameMap[AnalyticsEvent.MODIFY_BOOKING],
        payload: {
            selectedServices,
            removedServices,
            ...rest,
        },
    });

    const trackServices = (
        eventName: AnalyticsEvent,
        services: ManageBookingAddOn[],
    ): void => {
        if (services.length > 0) {
            services.forEach((addOn) => {
                trackEvent({
                    eventName: AnalyticsEventNameMap[eventName],
                    payload: {
                        ...getPurchaseServicePayload(addOn),
                        ...rest,
                    },
                });
            });
        }
    };

    trackServices(AnalyticsEvent.PURCHASE_SERVICE, selectedServices);
    trackServices(AnalyticsEvent.REMOVE_SERVICE, removedServices);
};

export type { AnalyticsAddedAndRemovedAddOns };
export {
    createAnalyticsStandardAddOns,
    getPurchaseServicePayload,
    trackClearCartEvent,
    trackModifyBookingEvent,
    trackUpdateCartEvent,
    trackViewManageBookingTabEvent,
    trackViewPortalCheckoutPageEvent,
};
