import { SurveyList } from './AudienceMetrics.types';

const SURVEY_LIST: SurveyList = [
    {
        surveyType: 'PopularDestSection',
        surveyName: 'Popular Destinations',
        columnSpan: {
            base: 12,
            md: 12,
        },
    },
    {
        surveyType: 'TimeOfYear',
        surveyName: 'Time of Year',
        columnSpan: {
            base: 12,
            md: 6,
        },
    },
    {
        surveyType: 'Budget',
        surveyName: 'Budget',
        columnSpan: {
            base: 12,
            md: 6,
        },
    },
    {
        surveyType: 'Age',
        surveyName: 'Age',
        columnSpan: {
            base: 12,
            md: 6,
        },
    },
    {
        surveyType: 'Gender',
        surveyName: 'Gender',
        columnSpan: {
            base: 12,
            md: 6,
        },
    },
];

export { SURVEY_LIST };
