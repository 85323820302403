import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { BaseBox, LineItem, Stack, theme } from '@trova-trip/trova-components';
import { models } from '@trova-trip/trova-models';
import { coreUtils } from '@trova-trip/trova-common';
import { getAccommodationServices } from '../../../../common/helpers';
import { getSelectedAccommodationsCost } from '../AccommodationsForm.helpers';

import type { CheckboxProps } from '@trova-trip/trova-components/build/next';
import type { InitialFormData } from '../../TripRequestForm/TripRequestForm.types';
import { AccommodationsAvailability } from '../../../hooks/useAccommodationsAvailability';
import { ItineraryInventoryItem } from 'applications/operator/tabs/ReviewPricing/Itinerary/ItineraryInventoryForm/types';

type Service = models.services.Service;
type HostRoom = models.tripRequest.HostRoom;
type PrePostAccommodationsSelection =
    models.tripRequest.PrePostAccommodationsSelection;

type PrePostAccommodationsInputsProps = {
    additionalOptionalServices: Service[];
    prePostAccommodationsSelection: PrePostAccommodationsSelection;
    accommodationAvailability: AccommodationsAvailability;
    setFormData: Dispatch<SetStateAction<InitialFormData>>;
    rooms: HostRoom[];
    isReadOnly: boolean;
    itineraryInventoryItem: ItineraryInventoryItem | undefined;
};

const formatUSD = coreUtils.currencyUtils.formatUSD;

const prePostAccommodationInputs = {
    pre: {
        name: 'beforeTrip',
        title: 'Pre-trip Accommodation',
        description: 'Arrive one night before your trip starts.',
    },
    post: {
        name: 'afterTrip',
        description: 'Stay one night after your trip ends.',
        title: 'Post-trip Accommodation',
    },
};

const PrePostAccommodationsInputs = (
    props: PrePostAccommodationsInputsProps,
): JSX.Element | null => {
    const {
        additionalOptionalServices,
        prePostAccommodationsSelection,
        accommodationAvailability,
        setFormData,
        rooms,
        isReadOnly,
        itineraryInventoryItem,
    } = props;

    const { arePreAccommodationsAvailable, arePostAccommodationsAvailable } =
        accommodationAvailability;

    const { postTripAccommodationServices, preTripAccommodationsServices } =
        useMemo(
            () => getAccommodationServices(additionalOptionalServices),
            [additionalOptionalServices],
        );

    if (!arePreAccommodationsAvailable && !arePostAccommodationsAvailable) {
        return null;
    }

    const handleUpdateAccommodations: CheckboxProps['onChange'] = (
        event,
    ): void => {
        const {
            metadata: { name, value },
        } = event;

        setFormData((currentData) => ({
            ...currentData,
            prePostAccommodationsSelection: {
                ...currentData.prePostAccommodationsSelection,
                [name]: value,
            },
        }));
    };

    const preAccommodationCost = arePreAccommodationsAvailable
        ? getSelectedAccommodationsCost(
              rooms,
              itineraryInventoryItem?.preAccommodationPrice,
              preTripAccommodationsServices,
          )
        : 0;
    const postAccommodationCost = arePostAccommodationsAvailable
        ? getSelectedAccommodationsCost(
              rooms,
              itineraryInventoryItem?.postAccommodationPrice,
              postTripAccommodationServices,
          )
        : 0;

    return (
        <>
            <BaseBox
                alignSelf='stretch'
                borderLeft={`1px solid ${theme.colors.blueGray[300]}`}
                borderBottom={`1px solid ${theme.colors.blueGray[300]}`}
                width={{ base: 'full', sm: 0 }}
            />
            <Stack
                direction='column'
                spacing={4}
                width={{ base: 'full', sm: '50%' }}
            >
                {arePreAccommodationsAvailable ? (
                    <LineItem
                        {...prePostAccommodationInputs.pre}
                        value={prePostAccommodationsSelection.beforeTrip}
                        onChange={handleUpdateAccommodations}
                        isDisabled={isReadOnly}
                        headerProps={{
                            price: formatUSD(preAccommodationCost),
                        }}
                    />
                ) : null}
                {arePostAccommodationsAvailable ? (
                    <LineItem
                        {...prePostAccommodationInputs.post}
                        value={prePostAccommodationsSelection.afterTrip}
                        onChange={handleUpdateAccommodations}
                        isDisabled={isReadOnly}
                        headerProps={{
                            price: formatUSD(postAccommodationCost),
                        }}
                    />
                ) : null}
            </Stack>
        </>
    );
};

export default PrePostAccommodationsInputs;
