import {
    BaseBox,
    ComponentWidth,
    Dropdown,
    Heading,
    Input,
    Stack,
    Text,
} from '@trova-trip/trova-components';
import { Button, Dialog, Link } from '@trova-trip/trova-components/build/next';
import { SyntheticEvent, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useTripRootPathByUserGroup from '../../../../../../applications/common/hooks/trips/useTripRootPathByUserGroup';
import {
    OTHER_CANCEL_REASON,
    TROVA_BOOKING_TERMS_URL,
    TROVA_TERMS_URL,
} from '../../../../../../config/constants';
import { DropdownOption } from '../../../../../../interfaces/FormComponents';
import {
    CancelBookingRefundMethod,
    TripDetails,
} from '../../../../../../interfaces/Trips.types';
import useCancelBooking from '../../../../../common/hooks/bookings/useCancelBooking';
import { useManageBooking } from '../../hooks';

const REFUND_METHOD_DROPDOWN_NAME = 'cancel-booking-refund-method';
const CANCEL_REASON_DROPDOWN_NAME = 'cancel-booking-reason';

interface CancellationLegendPropsType {
    daysTillTrip: number;
    isRezdyTrip: boolean;
}

interface CancelBookingDialogProps {
    isDialogOpen: boolean;
    setIsDialogOpen: (value: boolean) => void;
}

const CancellationLegend = ({
    daysTillTrip,
    isRezdyTrip,
}: CancellationLegendPropsType): JSX.Element => {
    return daysTillTrip > 60 ? (
        <Heading as='h6' fontWeight='regular' fontSize='base'>
            All passengers will be removed from this Trip. Cancellation Fees may
            apply.
        </Heading>
    ) : (
        <Heading as='h6' fontWeight='regular' fontSize='base'>
            All passengers will be removed from this Trip. This trip is within
            {isRezdyTrip ? ' 60' : ' 90'} days of departure. As per our{' '}
            <Link href={TROVA_BOOKING_TERMS_URL}>cancellation policy</Link>
            <Text as='span' fontWeight='bold'>
                , this booking is now non-refundable.
            </Text>
        </Heading>
    );
};

interface RefundMethodInputProps {
    name: string;
    refundMethods: CancelBookingRefundMethod[];
    refundMethodValue: string;
    refundMethodDropdownOptions: DropdownOption[];
    setRefundMethodValue: (value: string) => void;
    onSearch: (event: SyntheticEvent, name: string, value: string) => void;
}

const RefundMethodInput = ({
    name,
    refundMethods,
    refundMethodDropdownOptions,
    refundMethodValue,
    setRefundMethodValue,
    onSearch,
}: RefundMethodInputProps): JSX.Element => {
    const handleOnSearch = useCallback(
        (event: SyntheticEvent, name: string, searchValue: string) => {
            onSearch(event, name, searchValue);
        },
        [onSearch],
    );

    const handleOnchange = useCallback(
        (_: SyntheticEvent, __: string, value: string): void => {
            value && setRefundMethodValue(value);
        },
        [setRefundMethodValue],
    );

    const showPaymentMethodDropdown = refundMethods.length > 1;
    const uniqueRefundMethodValue = refundMethodDropdownOptions?.[0].value;
    if (!showPaymentMethodDropdown) {
        setRefundMethodValue(uniqueRefundMethodValue);
    }

    return (
        <BaseBox marginTop={4}>
            {showPaymentMethodDropdown ? (
                <Dropdown
                    label='Please choose preferred payment method'
                    name={name}
                    value={refundMethodValue}
                    onSearch={handleOnSearch}
                    placeholder='Select one'
                    onChange={handleOnchange}
                >
                    {refundMethodDropdownOptions}
                </Dropdown>
            ) : (
                <>
                    <Text color='blueGray.dark' fontWeight='bold'>
                        Refund Policy:
                    </Text>
                    <BaseBox marginTop={2}>
                        <Text>{uniqueRefundMethodValue}</Text>
                    </BaseBox>
                </>
            )}
        </BaseBox>
    );
};

type CancelBookingSuccessModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const CancelBookingSuccessModal = ({
    isOpen,
    onClose,
}: CancelBookingSuccessModalProps): JSX.Element => {
    return (
        <Dialog isOpen={isOpen} onClose={onClose} closeOnOverlayClick>
            <BaseBox>
                <Heading as='h4' textAlign='center'>
                    Your spot has been cancelled.
                </Heading>
            </BaseBox>
            <BaseBox marginTop={8} marginBottom={12}>
                <Text>
                    Thank you! We are processing your request. You will receive
                    a cancellation confirmation shortly.
                </Text>
            </BaseBox>
            <Dialog.Footer>
                <Stack
                    wrap='nowrap'
                    width='full'
                    justify='flex-end'
                    align='center'
                >
                    <Button variant='primary' onClick={onClose}>
                        Close
                    </Button>
                </Stack>
            </Dialog.Footer>
        </Dialog>
    );
};

const CancelBookingDialog = ({
    isDialogOpen,
    setIsDialogOpen,
}: CancelBookingDialogProps): JSX.Element => {
    const {
        globalState: { user, trip, booking },
    } = useManageBooking();

    const tripDetails: TripDetails = useSelector(
        (state: { userTripDetails: { current: TripDetails } }) =>
            state.userTripDetails?.current,
    );

    const tripRootPath = useTripRootPathByUserGroup({
        group: user?.group,
        tripId: trip.id,
    });

    const history = useHistory();

    const {
        refundMethodDropdownOptions,
        cancelReasonDropdownOptions,
        otherReasonsInputValue,
        cancelReasonValue,
        setCancelReasonValue,
        refundMethodValue,
        setRefundMethodValue,
        handleDropdownOnSearch,
        handleYesButtonClick,
        handleCloseDialog,
        handleCancelBookingModal,
        handleOtherReasonsInputChange,
        isCancelButtonDisabled,
        shouldShowAttemptMessage,
        handleOnSearch,
        shouldShowCancelBookingModal,
        daysTillTrip,
    } = useCancelBooking();

    useEffect(() => {
        if (isDialogOpen) {
            handleCancelBookingModal({ firstAttemptModal: true });
        }
    }, [isDialogOpen, handleCancelBookingModal]);

    const isRefundable = daysTillTrip > 90;

    const showCancellationFeeLegend =
        !!tripDetails?.cancellationFee && isRefundable;

    return (
        <>
            <Dialog
                isOpen={shouldShowCancelBookingModal.firstAttemptModal}
                onClose={() => {
                    setIsDialogOpen(false);
                    handleCloseDialog();
                }}
            >
                <Dialog.Header title='Cancel Your Booking' />
                <Dialog.Body width={{ base: 'full', md: '30vw' }}>
                    <BaseBox marginBottom={4} marginRight={6}>
                        <CancellationLegend
                            isRezdyTrip={!!booking?.rezdyTripId}
                            daysTillTrip={daysTillTrip}
                        />
                    </BaseBox>
                    <BaseBox paddingY={2}>
                        <Dropdown
                            label='What is your reason for cancelling?'
                            name={CANCEL_REASON_DROPDOWN_NAME}
                            value={cancelReasonValue}
                            onSearch={handleOnSearch}
                            placeholder='Select one'
                            onChange={(
                                _: SyntheticEvent,
                                __: string,
                                value: string,
                            ): void => {
                                value && setCancelReasonValue(value);
                            }}
                            size={ComponentWidth.Flexible}
                        >
                            {cancelReasonDropdownOptions}
                        </Dropdown>
                    </BaseBox>
                    {cancelReasonValue === OTHER_CANCEL_REASON.value && (
                        <BaseBox maxWidth={60} marginTop={1}>
                            <Input
                                name='other-reason-input'
                                value={otherReasonsInputValue}
                                placeholder='Describe reason here...'
                                size={ComponentWidth.Flexible}
                                onChange={handleOtherReasonsInputChange}
                            />
                        </BaseBox>
                    )}

                    <RefundMethodInput
                        name={REFUND_METHOD_DROPDOWN_NAME}
                        refundMethods={tripDetails?.refundMethods}
                        refundMethodValue={refundMethodValue}
                        refundMethodDropdownOptions={
                            refundMethodDropdownOptions
                        }
                        setRefundMethodValue={setRefundMethodValue}
                        onSearch={handleDropdownOnSearch}
                    />

                    {showCancellationFeeLegend ? (
                        <BaseBox margin={1}>
                            <Text size='sm'>
                                {`There will be a $${tripDetails?.cancellationFee.toFixed(
                                    2,
                                )} fee to
                        cancel this booking`}
                            </Text>
                        </BaseBox>
                    ) : null}

                    <BaseBox textAlign='center' marginTop={8} marginBottom={2}>
                        {shouldShowAttemptMessage && (
                            <Text fontWeight='bold'>
                                The following step is final. Are you sure?
                            </Text>
                        )}
                    </BaseBox>
                </Dialog.Body>
                <Dialog.Footer>
                    <Stack
                        direction={{ base: 'column', md: 'row' }}
                        justify={{ base: 'space-between', md: 'flex-end' }}
                        width='full'
                        flex={1}
                    >
                        <Stack
                            direction={{ base: 'column', md: 'row' }}
                            justify={{ md: 'flex-end' }}
                            spacing={3}
                            width='full'
                        >
                            <Button
                                variant='secondary'
                                onClick={() => {
                                    setIsDialogOpen(false);
                                    handleCloseDialog();
                                }}
                                isFullWidth={{ base: true, md: false }}
                            >
                                Go Back
                            </Button>
                            <Button
                                variant='primary'
                                onClick={handleYesButtonClick}
                                isFullWidth={{ base: true, md: false }}
                                isDisabled={isCancelButtonDisabled}
                            >
                                Cancel Booking
                            </Button>
                        </Stack>
                        <Stack
                            paddingTop={2}
                            width='full'
                            justify={{ base: 'center', md: 'flex-end' }}
                        >
                            <Link
                                href={TROVA_TERMS_URL}
                                size='sm'
                                color='neutral.70'
                            >
                                Terms & Conditions
                            </Link>
                        </Stack>
                    </Stack>
                </Dialog.Footer>
            </Dialog>

            <CancelBookingSuccessModal
                isOpen={shouldShowCancelBookingModal.successModal}
                onClose={(): void => {
                    handleCancelBookingModal({ successModal: false });
                    history.push(tripRootPath);
                }}
            />
        </>
    );
};

export default CancelBookingDialog;
