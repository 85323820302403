import { Grid } from '@material-ui/core';
import { Heading, Text } from '@trova-trip/trova-components';
import { styledListItem } from '../../../../../components/SuccessPage/SuccessPage.styles';

const SuccessPageMainContent = (): JSX.Element => (
    <Grid container item xs={12} sm={10} spacing={32}>
        <Grid item xs={12}>
            <Heading as={'h2'}>We&apos;re excited to travel with you!</Heading>
        </Grid>
        <Grid item xs={12}>
            <Text>
                Now that you&apos;ve signed your agreement, your trip page is
                available and ready to be shared to build some hype and capture
                more email addresses for your launch!
            </Text>
        </Grid>
        <Grid item xs={12}>
            <Text>
                If you haven&apos;t already, you should be considering when you
                want to make your trip live for bookings! We recommend you do
                some promoting before you go live to get your audience excited
                about the potential of traveling with you.
            </Text>
        </Grid>
        <Grid item xs={12}>
            <Text>Here are some tips for next steps:</Text>
        </Grid>
        <Grid item xs={12}>
            <ul>
                <li className={styledListItem}>
                    <Text>
                        If you haven&apos;t already, announce your destination
                        on Instagram stories and throw that trip page link there
                        to drive engagement.
                    </Text>
                </li>
                <li className={styledListItem}>
                    <Text>
                        Call out what you&apos;re excited about! The more pumped
                        you are, the more pumped your audience will be.
                    </Text>
                </li>
                <li className={styledListItem}>
                    <Text>
                        Dedicate a post with a clear call to action to add their
                        email.
                    </Text>
                </li>
                <li className={styledListItem}>
                    <Text>
                        Create urgency and exclusivity by announcing your trip
                        to your email list first.
                    </Text>
                </li>
                <li className={styledListItem}>
                    <Text>
                        Leverage engagement tools like Instagram Q&A to drive
                        interest.
                    </Text>
                </li>
            </ul>
        </Grid>
    </Grid>
);

export default SuccessPageMainContent;
