/* eslint-disable react/no-unescaped-entities */
import styles from '../home-container.styles.module.scss';
import {
    BaseBox,
    Heading,
    Grid,
    Image,
    Text,
    Stack,
    StackProps,
    BaseBoxProps,
    Divider,
    useBreakpointValue,
    ResponsiveValue,
    THEME_TOKENS,
} from '@trova-trip/trova-components';
import { Card, Button } from '@trova-trip/trova-components/build/next';
import TrovieFullBody from '../../../../../assets/img/trovie-full-body.svg';

// TODO: Hacky & scrappy AF, so need to talk to Mati about this
type HeaderSizeType =
    | ResponsiveValue<keyof typeof THEME_TOKENS['fontSizes']>
    | undefined;
// #region Raw Styles
const travelListStepContentLayoutInnerStackStyles: StackProps = {
    maxWidth: 600,
    direction: 'row',
    wrap: 'wrap',
    marginTop: 0,
    className: 'travel-list-step-content-layout-inner-stack',
    // @ts-ignore
    gap: 'var(--tc-space-6)',
};
const travelListStepDescriptionBoxStyles: BaseBoxProps = {
    flex: 'auto',
    minWidth: '18rem',
    maxWidth: '26rem',
};
const travelListStepIconButtonBoxStyles: BaseBoxProps = {
    marginLeft: 'auto',
    alignContent: 'flex-end',
};

// #endregion Raw Styles
const LearnMoreLinkButton = ({ linkto }: { linkto: string }) => {
    return (
        <Button
            className={styles.travelListStepActionButton}
            as='a'
            iconVariant='outlined'
            rightIcon='offsite'
            size={'sm'}
            type='button'
            variant='plain'
            linkProps={{ href: linkto, target: '_blank' }}
            title={linkto}
        >
            Learn More
        </Button>
    );
};

const HomeLandingPage = () => {
    const headerResponsiveSize: HeaderSizeType = useBreakpointValue({
        base: 'xl',
        sm: 'xl',
        md: '3xl',
    });
    return (
        <>
            <Card
                className={styles.landingPageCardContainer}
                width={{ base: 'auto', md: '2xl' }}
                margin={'0 auto'}
                padding={8}
            >
                <Grid className={styles.gridHomeLandingPage}>
                    <BaseBox className={styles.trovieImage}>
                        <Image
                            alt='Trovie'
                            maxWidth={54}
                            maxHeight={66}
                            src={TrovieFullBody}
                            data-gumlet='false'
                        />
                    </BaseBox>
                    <BaseBox className={styles.gridItemWelcomeMessage}>
                        <Heading as='h3' size={headerResponsiveSize}>
                            Welcome to TrovaTrip!
                        </Heading>
                        <Text>
                            4 simple steps to take you from here to traveling
                            the world
                        </Text>
                    </BaseBox>
                    <BaseBox className={styles.gridItemBodyContent}>
                        <ol className={styles.travelListSteps} start={1}>
                            <li>
                                <Stack
                                    direction={'column'}
                                    className={
                                        styles.travelListStepContentLayout
                                    }
                                >
                                    <Text>Plan your trip</Text>
                                    <Stack
                                        {...travelListStepContentLayoutInnerStackStyles}
                                    >
                                        <BaseBox
                                            {...travelListStepDescriptionBoxStyles}
                                        >
                                            <Text>
                                                Review{' '}
                                                <a
                                                    className={
                                                        styles.stepDescTextAsLink
                                                    }
                                                    title='/app/host/itineraries'
                                                    href='/app/host/itineraries'
                                                >
                                                    itinerary
                                                </a>{' '}
                                                options and reserve the perfect
                                                trip for your group.
                                            </Text>
                                            <Text
                                                fontSize={'sm'}
                                                paddingBlockStart={4}
                                            >
                                                Need help choosing?{' '}
                                                <a
                                                    className={
                                                        styles.stepDescTextAsLink
                                                    }
                                                    title='/app/host/audience/research'
                                                    href='/app/host/audience/research'
                                                >
                                                    Survey
                                                </a>{' '}
                                                your audience!
                                            </Text>
                                        </BaseBox>
                                        <BaseBox
                                            {...travelListStepIconButtonBoxStyles}
                                        >
                                            <LearnMoreLinkButton linkto='https://help.trovatrip.com/en/articles/9774630-how-to-plan-your-trip' />
                                        </BaseBox>
                                    </Stack>
                                </Stack>
                                <Divider />
                            </li>

                            <li>
                                <Stack
                                    direction={'column'}
                                    className={
                                        styles.travelListStepContentLayout
                                    }
                                >
                                    <Text>Fill your trip</Text>
                                    <Stack
                                        {...travelListStepContentLayoutInnerStackStyles}
                                    >
                                        <BaseBox
                                            {...travelListStepDescriptionBoxStyles}
                                        >
                                            <Text>
                                                Promote your trip to your
                                                community and get enough people
                                                booked to make it happen.
                                            </Text>
                                        </BaseBox>
                                        <BaseBox
                                            {...travelListStepIconButtonBoxStyles}
                                        >
                                            <LearnMoreLinkButton linkto='https://help.trovatrip.com/en/articles/9204415-promotional-best-practices' />
                                        </BaseBox>
                                    </Stack>
                                </Stack>
                                <Divider />
                            </li>
                            <li>
                                <Stack
                                    direction={'column'}
                                    className={
                                        styles.travelListStepContentLayout
                                    }
                                >
                                    <Text>Prep for your trip</Text>
                                    <Stack
                                        {...travelListStepContentLayoutInnerStackStyles}
                                    >
                                        <BaseBox
                                            {...travelListStepDescriptionBoxStyles}
                                        >
                                            <Text>
                                                We're here to help with trip
                                                prep, logistic wrangling, and
                                                support. We provide the
                                                on-the-ground Guide, but the
                                                Host sets the vibe.
                                            </Text>
                                        </BaseBox>
                                        <BaseBox
                                            {...travelListStepIconButtonBoxStyles}
                                        >
                                            <LearnMoreLinkButton linkto='https://help.trovatrip.com/en/articles/9236318-hosts-preparing-for-your-trip' />
                                        </BaseBox>
                                    </Stack>
                                </Stack>
                                <Divider />
                            </li>
                            <li>
                                <Stack
                                    direction={'column'}
                                    className={
                                        styles.travelListStepContentLayout
                                    }
                                >
                                    <Text>Travel and earn</Text>
                                    <Stack
                                        {...travelListStepContentLayoutInnerStackStyles}
                                    >
                                        <BaseBox
                                            {...travelListStepDescriptionBoxStyles}
                                        >
                                            <Text>
                                                Get paid to travel the world
                                                with incredible people. What
                                                could be better?
                                            </Text>
                                        </BaseBox>
                                        <BaseBox
                                            {...travelListStepIconButtonBoxStyles}
                                        >
                                            <LearnMoreLinkButton linkto='https://help.trovatrip.com/en/articles/9774631-what-to-expect-on-trip-as-a-trova-host' />
                                        </BaseBox>
                                    </Stack>
                                </Stack>
                            </li>
                        </ol>
                    </BaseBox>
                </Grid>
            </Card>
            <BaseBox width={28} marginTop={6} marginX={'auto'}>
                <Button
                    title='Find you trip'
                    as='a'
                    linkProps={{ href: '/app/host/itineraries' }}
                >
                    Find your Trip
                </Button>
            </BaseBox>
        </>
    );
};

export default HomeLandingPage;
