import styled from '@emotion/styled';
import {
    BaseBox,
    Grid,
    Heading,
    IconButton,
    Stack,
} from '@trova-trip/trova-components';
import React, { useContext } from 'react';
import { OnboardingStepperContext } from '../OnboardingStepperContext';

interface BaseContentProps {
    title: string;
    leftContent: React.ReactNode;
    rightContent: React.ReactNode;
    footerContent: React.ReactNode;
}

const BaseContent = (props: BaseContentProps): JSX.Element => {
    const { title, leftContent, rightContent, footerContent } = props;
    const { currentStep, goPrevStep } = useContext(OnboardingStepperContext);

    return (
        <Stack
            direction='column'
            justify='space-between'
            spacing={0}
            height='100%'
        >
            <BaseBox>
                {currentStep > 0 && (
                    <IconButton
                        icon='arrow-left'
                        aria-label='Go back'
                        size='md'
                        variant='ghost'
                        onClick={goPrevStep}
                    />
                )}
            </BaseBox>

            <AnimatedGrid alignItems='center'>
                <Grid.Item
                    columnSpan={{ base: 12, xl: 6 }}
                    textAlign={{ base: 'center', xl: 'left' }}
                >
                    {leftContent}
                </Grid.Item>
                <Grid.Item columnSpan={{ base: 12, xl: 6 }}>
                    <BaseBox paddingLeft={{ xl: 6 }}>
                        <Heading
                            as='h2'
                            marginBottom={6}
                            size={{ base: 'xl', md: '3xl', xl: '4xl' }}
                        >
                            {title}
                        </Heading>
                        {rightContent}
                    </BaseBox>
                </Grid.Item>
            </AnimatedGrid>

            <Stack spacing={8} justify='flex-end' align='center' width='100%'>
                {footerContent}
            </Stack>
        </Stack>
    );
};

const AnimatedGrid = styled(Grid)`
    @keyframes stepper--content-animation {
        0% {
            opacity: 0.1;
            transform: translateX(15px);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
    animation: stepper--content-animation;
    animation-duration: 0.3s;
    animation-timing-function: cubic-bezier(0.25, 0.75, 0.5, 1.25);
    backface-visibility: hidden;
`;

export default BaseContent;
