import { Heading, Stack } from '@trova-trip/trova-components';
import { ReactElement, useEffect } from 'react';
import { BookedGroundTransferJourney } from '../../../../../state/features/transfer/types';
import {
    areGroundTransfersOperationsEnabled,
    isCompanyDataAvailable,
    isDriverDataAvailable,
    objectKeys,
} from '../../../helpers';
import useIsLargeScreen from '../../../hooks/useIsLargeScreen';
import { useOptionSwitcher, useTransfer } from '../hooks';
import BookingDetails from './BookingDetails';
import CancelTransfer from './CancelTransfer';
import EditJourneyForm from './EditJourneyForm';
import { isTransferErrorStatus } from '../../../../../state/features/transfer/utils';
import RideDetails from './RideDetails';
import RideOperationsActionSheet from './RideOperationsActionSheet';
import RideOperationsDialog from './RideOperationsDialog';

interface RideOperationsPopupProps {
    isOpen: boolean;
    onClose: () => void;
    journey: BookedGroundTransferJourney;
}

interface ContentProps {
    journey: BookedGroundTransferJourney;
    onEditInfo?: () => void;
    driverDataIsAvailable: boolean;
    companyDataIsAvailable: boolean;
}

type ViewName = 'bookingDetails' | 'rideDetails' | 'editInformation' | 'cancel';

type MobileViewName = Exclude<ViewName, 'rideDetails'>;

type ViewsMap<T extends string> = Partial<Record<T, View>>;

type View = { element: ReactElement; title?: string };

const Content = ({
    journey,
    onEditInfo,
    driverDataIsAvailable,
    companyDataIsAvailable,
}: ContentProps): JSX.Element => {
    if (driverDataIsAvailable) {
        return (
            <Stack width='full' direction='column' spacing={6} paddingTop={6}>
                <RideDetails journey={journey} />
                <Heading as='h6'>Booking Details</Heading>
                <BookingDetails journey={journey} onEditInfo={onEditInfo} />
            </Stack>
        );
    }

    return (
        <Stack width='full' direction='column' spacing={6} paddingTop={6}>
            <BookingDetails journey={journey} onEditInfo={onEditInfo} />
            {companyDataIsAvailable ? (
                <>
                    <Heading as='h6'>Ride Details</Heading>
                    <RideDetails journey={journey} />
                </>
            ) : null}
        </Stack>
    );
};

const RideOperationsPopup = ({
    isOpen,
    onClose,
    journey,
}: RideOperationsPopupProps): JSX.Element | null => {
    const isDesktop = useIsLargeScreen();
    const {
        actions: { resetState },
        state: {
            metadata: { status },
        },
        globalState: { traveler },
    } = useTransfer();

    const driverDataIsAvailable = isDriverDataAvailable(journey.driver);
    const companyDataIsAvailable = isCompanyDataAvailable(
        journey.transferCompany,
    );

    const areOperationsEnabled = areGroundTransfersOperationsEnabled(
        traveler?.type,
        journey.status,
    );

    const mobileViews: ViewsMap<MobileViewName> = {
        bookingDetails: {
            element: (
                <Content
                    journey={journey}
                    onEditInfo={
                        areOperationsEnabled
                            ? () => setCurrentView('editInformation')
                            : undefined
                    }
                    driverDataIsAvailable={driverDataIsAvailable}
                    companyDataIsAvailable={companyDataIsAvailable}
                />
            ),
            title: driverDataIsAvailable ? 'Ride Details' : 'Booking Details',
        },
    };

    const desktopViews: ViewsMap<ViewName> = {
        bookingDetails: {
            element: <BookingDetails journey={journey} />,
            title: 'Booking Details',
        },
    };

    if (companyDataIsAvailable) {
        const rideDetails = {
            element: <RideDetails journey={journey} />,
            title: 'Ride Details',
        };
        desktopViews.rideDetails = rideDetails;
    }

    if (areOperationsEnabled) {
        const cancelView = {
            element: (
                <CancelTransfer
                    journeyId={journey.id}
                    onBack={() => {
                        setCurrentView('bookingDetails');
                        resetState();
                    }}
                />
            ),
        };

        mobileViews.editInformation = {
            element: (
                <EditJourneyForm
                    journey={journey}
                    onBack={() => backToPreviousView()}
                    onClose={onClose}
                />
            ),
            title: 'Edit Information',
        };
        mobileViews.cancel = cancelView;

        desktopViews.editInformation = {
            element: <EditJourneyForm journey={journey} onClose={onClose} />,
            title: 'Edit Information',
        };
        desktopViews.cancel = cancelView;
    }

    const views = isDesktop ? desktopViews : mobileViews;

    const {
        current: { value: currentView, key: currentViewKey },
        setCurrent: setCurrentView,
        backToPrevious: backToPreviousView,
        clearOptionHistory: clearViewHistory,
    } = useOptionSwitcher({
        options: views,
        initialOption: 'bookingDetails',
    });

    const handleResetState = () => {
        if (isTransferErrorStatus(status) || currentViewKey === 'cancel') {
            resetState();
        }
    };

    const handleClose = () => {
        handleResetState();
        onClose();
        setCurrentView('bookingDetails');
    };

    useEffect(() => {
        return () => clearViewHistory();
    }, [clearViewHistory]);

    const tabs = objectKeys(views)
        .filter((key) => key !== 'cancel')
        .map((key) => ({
            key,
            title: views[key]?.title as string,
        }));

    const commonProps = {
        isOpen,
        onClose: handleClose,
        content: currentView?.element,
        title: currentView?.title,
        journey,
    };

    if (!journey.transferCompany) return null;

    if (isDesktop)
        return (
            <RideOperationsDialog
                onChangeTab={setCurrentView}
                tabs={tabs}
                activeTab={currentViewKey}
                {...commonProps}
            />
        );

    return (
        <RideOperationsActionSheet
            activeView={currentViewKey}
            onCancel={() => setCurrentView('cancel')}
            {...commonProps}
        />
    );
};

export default RideOperationsPopup;
export type { MobileViewName, View, ViewName };
