import {
    Heading,
    Stack,
    Text,
    ProgressBar,
} from '@trova-trip/trova-components';
import { Card } from '@trova-trip/trova-components/build/next';
import { coreUtils } from '@trova-trip/trova-common';

interface StickyContentTripEarningsProps {
    title: string;
    earnings: number;
    potentialEarnings: number;
}

const StickyContentTripEarnings = ({
    title,
    earnings,
    potentialEarnings,
}: StickyContentTripEarningsProps): JSX.Element => {
    const earningTitle = coreUtils.currencyUtils.formatUSD(earnings);
    const potentialEarningTitle =
        coreUtils.currencyUtils.formatUSD(potentialEarnings);

    return (
        <Card boxShadow='4'>
            {title ? (
                <Heading
                    fontSize={{ base: 'sm', md: '2xl' }}
                    lineHeight={{ base: '5', md: '9' }}
                    color='blueGray.800'
                    textAlign='center'
                    textTransform={{
                        base: 'uppercase',
                        md: 'none',
                    }}
                    fontWeight={{ base: 'regular', md: 'bold' }}
                    marginBottom={{ base: 3, md: 5 }}
                    as='h4'
                >
                    {title}
                </Heading>
            ) : null}
            {potentialEarningTitle ? (
                <Stack
                    direction='column'
                    align='center'
                    spacing={{ base: 0, md: 2 }}
                >
                    <Text
                        fontWeight={{ base: 'medium', md: 'bold' }}
                        fontSize={{ base: 'sm', md: '4xl' }}
                        textAlign={{ base: 'left', md: 'center' }}
                        color='blueGray.800'
                    >
                        {potentialEarningTitle}
                    </Text>
                    <Text fontSize='sm' textAlign='center'>
                        {'Potential Earnings'}
                    </Text>
                </Stack>
            ) : null}
            {!!earnings || earnings === 0 ? (
                <Stack
                    direction='column'
                    align='center'
                    width='100%'
                    borderTop='1px solid'
                    borderColor='blueGray.200'
                    paddingTop={3}
                    marginTop={2}
                    spacing={{ base: 0, md: 1 }}
                >
                    <Text
                        fontWeight={{ base: 'medium', md: 'bold' }}
                        fontSize={{ base: 'sm', md: '2xl' }}
                        textAlign={{ base: 'left', md: 'center' }}
                        color='blueGray.800'
                    >
                        {earningTitle}
                    </Text>
                    <Text
                        color='blueGray.650'
                        fontSize={{ base: 'sm', md: 'sm' }}
                        textAlign={{ md: 'center' }}
                    >
                        {'Earnings Collected'}
                    </Text>
                    <ProgressBar
                        color='teal.trova'
                        size='sm'
                        borderRadius='sm'
                        min={0}
                        max={
                            !potentialEarnings && !earnings
                                ? 1
                                : potentialEarnings
                        }
                        as='linear'
                        value={earnings}
                    />
                </Stack>
            ) : null}
        </Card>
    );
};

export default StickyContentTripEarnings;
