import { useEffect, useState } from 'react';
import { PricingCalculator } from '@trova-trip/trova-common';
import { constants, models } from '@trova-trip/trova-models';
import { getSuggestedSellPrice } from '../../../state/itineraries';
import { isSuggestedSellPriceEnabled } from '../../../config/constants';
type PackageLevel = constants.itinerary.PackageLevel;
type SuggestedSellPrice = models.itineraries.SuggestedSellPrice;

type UseSuggestedSellPrice = {
    itinerary: models.itineraries.Itinerary;
    tripRequestCostDTO: PricingCalculator.TripRequestCostDTO;
    tripPrice: number;
};

type UseSuggestedSellPriceReturn = {
    suggestedSellPrice?: SuggestedSellPrice;
    fetching: boolean;
};

export const useSuggestedSellPrice = ({
    itinerary,
    tripRequestCostDTO,
    tripPrice,
}: UseSuggestedSellPrice): UseSuggestedSellPriceReturn => {
    const [suggestedSellPrice, setSuggestedSellPrice] =
        useState<SuggestedSellPrice>();
    const [fetching, setFetching] = useState<boolean>(false);

    const fetchData = async (): Promise<void> => {
        setFetching(true);
        const suggestedPriceResponse = await getSuggestedSellPrice(
            itinerary?.id || '',
            {
                minimumPrice: tripPrice,
                tripStartDate: new Date(tripRequestCostDTO.startDate || ''),
                selectedPackage:
                    tripRequestCostDTO.selectedPackage as PackageLevel,
            },
        );
        if (suggestedPriceResponse?.success) {
            setSuggestedSellPrice(suggestedPriceResponse.data);
        }
        setFetching(false);
    };

    useEffect(() => {
        if (!tripPrice) {
            return;
        }

        if (!isSuggestedSellPriceEnabled) {
            setSuggestedSellPrice({
                minPrice: tripPrice,
                maxPrice: tripPrice,
            });
            return;
        }

        if (itinerary?.id) {
            fetchData();
        }
    }, [itinerary?.id, tripPrice]);

    return { suggestedSellPrice, fetching };
};
