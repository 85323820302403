import { DistanceMatrixRequest } from 'applications/common/services/types';
import GoogleService from '../applications/common/services/google';
import {
    BookApiRequest,
    BookApiResponse,
    CancelGroundTransferApiRequest,
    EstimatedDurationByStage,
    GetEstimatedDurationRequest,
    GetEstimatedDurationResponse,
    GroundTransfersOperationsApiResponse,
    QuotesApiRequest,
    QuotesApiResponse,
    UpdateGroundTransferApiRequest,
} from '../state/features/transfer/types';
import { post, put } from '../state/utils/api';

const GROUND_TRANSFER_PATH = '/api/me/ground-transfers';

const getQuotes = (data: QuotesApiRequest): Promise<QuotesApiResponse> =>
    post(`${GROUND_TRANSFER_PATH}/quotes`, data);

const getEstimatedDuration = async (
    params: GetEstimatedDurationRequest,
): Promise<GetEstimatedDurationResponse> => {
    const { arrival, departure } = params;

    const googleService = new GoogleService({
        apiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
    });

    const origins: DistanceMatrixRequest['origins'] = [];
    const destinations: DistanceMatrixRequest['destinations'] = [];

    if (arrival) {
        origins.push(arrival.origin);
        destinations.push(arrival.destination);
    }

    if (departure) {
        origins.push(departure.origin);
        destinations.push(departure.destination);
    }

    const response = await googleService.getDistanceMatrix({
        origins,
        destinations,
    });

    if (response.success) {
        const { data } = response;

        const estimatedDurationByStage: EstimatedDurationByStage = {
            arrival: {
                estimatedDuration: data.rows[0].elements[0].duration.text,
            },
            departure: {
                estimatedDuration: data.rows[1].elements[1].duration.text,
            },
        };

        return { data: estimatedDurationByStage, success: response.success };
    }

    return response;
};

const bookGroundTransfer = (data: BookApiRequest): Promise<BookApiResponse> =>
    post(GROUND_TRANSFER_PATH, data);

const cancelGroundTransfer = (
    data: CancelGroundTransferApiRequest,
): Promise<GroundTransfersOperationsApiResponse> => {
    const { groundTransferId, cancelRequest } = data;

    return post(
        `${GROUND_TRANSFER_PATH}/${groundTransferId}/cancel`,
        cancelRequest,
    );
};

const updateGroundTransfer = (
    data: UpdateGroundTransferApiRequest,
): Promise<GroundTransfersOperationsApiResponse> => {
    const { groundTransferId, updateRequest } = data;

    return put(
        `${GROUND_TRANSFER_PATH}/${groundTransferId}`,
        undefined,
        updateRequest,
    );
};

export {
    bookGroundTransfer,
    cancelGroundTransfer,
    getEstimatedDuration,
    getQuotes,
    updateGroundTransfer,
};
