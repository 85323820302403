import {
    Stack,
    BaseBox,
    TextDivider,
    Skeleton,
} from '@trova-trip/trova-components';
import OnboardingStepHeader from '../common/OnboardingStepHeader';
import SocialConnect from './SocialConnect';
import { Button } from '@trova-trip/trova-components/build/next';
import { isHostPhylloOnboardingEnabled } from '../../../../../../config/constants';
import { UsePhylloReturn } from '../../hooks/usePhyllo';
import { AnalyticsEventNames, Socials, STEP_NAME } from '../../types';
import { ValidationError } from '../../../../utils/formErrors.utils';
import { getValidationSchemaForStep } from '../../onboardingStepperErrors.util';
import SocialMediaAccounts from 'applications/host/components/SocialMediaAccounts';
import { useTrackingEvent } from 'analytics/hooks';
import { useEffect } from 'react';
import { isFailedToFetchPhylloUserDataError } from '../../utils';

export interface SocialMediaProps {
    socials: Socials;
    onUpdateSocials: (updatedSocials: Socials) => void;
    errors?: ValidationError;
    phyllo: Omit<UsePhylloReturn, 'init'> & { isLoading: boolean };
}

const SkeletonComponent = (): JSX.Element => (
    <Stack spacing={3} width='full' align='stretch' direction='column'>
        {Array.from({ length: 4 }).map((_, index) => (
            <Skeleton key={index} height={16} borderRadius='lg' />
        ))}
    </Stack>
);

const SocialMedia = ({
    socials,
    onUpdateSocials,
    errors,
    phyllo,
}: SocialMediaProps): JSX.Element => {
    const { isManualConnect, channels } = socials;
    const { isLoading, connectedAccounts, statusByPlatform, open, error } =
        phyllo;
    const { trackUserEvent } = useTrackingEvent();

    useEffect(() => {
        if (error) {
            if (isFailedToFetchPhylloUserDataError(error)) {
                onUpdateSocials({
                    ...socials,
                    isManualConnect: true,
                });
            }
            trackUserEvent({
                eventName: AnalyticsEventNames.HostGotSocialAccountConnectError,
                properties: {
                    error,
                },
            });
        }
    }, [error]);

    const onSocialMediaManualUpdate = (
        newSocialMedia: Socials['channels'],
    ): void => {
        onUpdateSocials({ ...socials, channels: newSocialMedia });
    };

    const handleSocialConnect: UsePhylloReturn['open'] = (platform) => {
        trackUserEvent({
            eventName: AnalyticsEventNames.HostClickedOnSocialAccountConnect,
            properties: {
                platform,
            },
        });
        open(platform);
    };

    const viewToggleProps = {
        children: isManualConnect
            ? 'Link Your Social Accounts'
            : 'Connect Manually',
        isDisabled: getValidationSchemaForStep(STEP_NAME.SOCIALS)?.isValidSync(
            socials || isFailedToFetchPhylloUserDataError(error),
        ),
        onClick: (): void => {
            if (!isManualConnect) {
                trackUserEvent({
                    eventName:
                        AnalyticsEventNames.HostClickedOnConnectSocialManually,
                });
            }
            onUpdateSocials({
                ...socials,
                isManualConnect: !isManualConnect,
            });
        },
    };

    return (
        <Stack direction='column' align='stretch' spacing={4}>
            <OnboardingStepHeader
                title='Social Channels'
                lead='Your audience and engagement info help us tailor trip recommendations and personalize your promotion plans. Be sure to "select all" when granting permissions on the following screens. Link as many accounts as you like!'
            />
            <BaseBox maxWidth={{ md: '32rem' }}>
                {isLoading ? (
                    <SkeletonComponent />
                ) : (
                    <BaseBox>
                        {isManualConnect ? (
                            <SocialMediaAccounts
                                onUpdateSocials={onSocialMediaManualUpdate}
                                socials={channels}
                                errors={errors}
                            />
                        ) : (
                            <SocialConnect
                                connectedAccounts={connectedAccounts}
                                statusByPlatform={statusByPlatform}
                                onConnect={handleSocialConnect}
                                error={error}
                            />
                        )}
                        {isHostPhylloOnboardingEnabled ? (
                            <Stack
                                width='full'
                                direction='column'
                                align='center'
                                justify='center'
                                spacing={4}
                                marginTop={isManualConnect ? 5 : 2}
                            >
                                <TextDivider width='full'>or</TextDivider>
                                <Button
                                    aria-label={viewToggleProps.children}
                                    variant='tertiary'
                                    {...viewToggleProps}
                                />
                            </Stack>
                        ) : null}
                    </BaseBox>
                )}
            </BaseBox>
        </Stack>
    );
};

export default SocialMedia;
