import {
    TRAVELER_APP_MANAGE_BOOKING_ROUTES,
    TRAVELER_APP_TAB_ROUTE,
} from '../../../../common/constants/routesTokens';

type ManageBookingTabName = keyof typeof paths.tab;

const manageBookingPath = TRAVELER_APP_TAB_ROUTE.MANAGE_BOOKING;

const paths = {
    product: '/trips/:tripId',
    tab: {
        root: `${manageBookingPath}`,
        payment: `${manageBookingPath}${TRAVELER_APP_MANAGE_BOOKING_ROUTES.PAYMENT}`,
        success: `${manageBookingPath}${TRAVELER_APP_MANAGE_BOOKING_ROUTES.SUCCESS}`,
        ['pay-balance']: `${manageBookingPath}${TRAVELER_APP_MANAGE_BOOKING_ROUTES.PAY_BALANCE}`,
    },
} as const;

export { paths };
export type { ManageBookingTabName };
