import { coreUtils } from '@trova-trip/trova-common';
import {
    BaseBox,
    Collapse,
    Grid,
    Heading,
    LineItem,
    Stack,
    Text,
} from '@trova-trip/trova-components';
import {
    Button,
    Card,
    Icon,
    IconProps,
} from '@trova-trip/trova-components/build/next';
import { getTripRelatedStringDates } from '../../../../../common/helpers';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { useState } from 'react';
import { ValidOccupanciesType } from '../../../../../../state/features/manageBooking/types';
import useIsLargeScreen from '../../../../../common/hooks/useIsLargeScreen';
import { useManageBooking } from '../../hooks';
import { ManageBookingAddOn, Occupancy, Trip } from '../../types';
import {
    isUpgradeToPrivateRoomSelected as checkIsUpgradeToPrivateRoomSelected,
    shouldAllowRoomSelectionEdition,
} from '../../utils/check.utils';
import {
    TravelerRoomWithTypeAndStatus,
    getAddOnStatusIconProps,
    getSelectedTravelersRooms,
} from '../../utils/extract.utils';
import AccommodationsInlineEdit from './AccommodationsInlineEdit';
import { useEffect } from 'react';
import {
    isServiceInventoryNeedsVerificationStatus,
    isSingleSupplementInventoryError,
} from '../../../../../../state/features/manageBooking/utils';

const { formatUSD } = coreUtils.currencyUtils;

interface BaseRoomOptionItem {
    icon?: IconProps['as'];
    text: string;
}

interface RoomItemProps {
    room: TravelerRoomWithTypeAndStatus;
    roomIndex: number;
    roomsQuantity: number;
    tripDates: string;
}

const occupancyMap: Record<ValidOccupanciesType, string> = {
    [Occupancy.single]: 'Private Room',
    [Occupancy.double]: 'Shared beds',
    [Occupancy.twin]: 'Two beds',
};

const priceIncludedRoomOptionItem: BaseRoomOptionItem = {
    icon: 'attachMoney',
    text: 'Included in trip price',
};

const descriptionItemsMap = {
    [Occupancy.single]: [
        {
            icon: 'kingSizeBed',
            text: 'Private room for one traveler',
        },
        { icon: 'attachMoney', text: 'Additional trip cost' },
    ],
    [Occupancy.double]: [
        {
            icon: 'doubleBed',
            text: 'One bed for two travelers',
        },
        priceIncludedRoomOptionItem,
    ],
    [Occupancy.twin]: [
        {
            icon: 'trovaTwoBeds',
            text: 'Shared room with a roommate',
        },
        priceIncludedRoomOptionItem,
    ],
};

interface AccommodationsSectionProps {
    trip: Trip;
}

const RoomItem = ({
    room,
    roomIndex,
    roomsQuantity,
    tripDates,
}: RoomItemProps): JSX.Element | null => {
    const {
        state: {
            addOns: { accommodations },
        },
    } = useManageBooking();
    const isDesktop = useIsLargeScreen({ includeTabletResolution: true });

    const { roomType, status } = room;
    const occupancy = occupancyMap[roomType];
    const iconProps = getAddOnStatusIconProps(status);
    const descriptionItems = descriptionItemsMap[roomType];

    const isLastRoom = roomIndex === roomsQuantity - 1;

    const showDivider =
        isDesktop && roomIndex % 2 === 0 && !isLastRoom && roomsQuantity > 1;

    const roomPrice =
        roomType === Occupancy.single
            ? accommodations?.singleSupplement?.unitPriceWithFee
            : 0;

    return (
        <Grid.Item
            columnSpan={{
                base: 12,
                md: roomsQuantity > 1 ? 6 : 12,
            }}
            marginY={2}
            justifySelf='left'
            width='full'
        >
            <BaseBox
                borderRight={showDivider ? '1px solid' : 'none'}
                borderColor='blueGray.300'
                paddingRight={{ base: 0, md: 6 }}
            >
                <LineItem
                    name='room'
                    title={occupancy}
                    content={
                        <>
                            {descriptionItems.map((item, index) => (
                                <Stack key={index} align='center'>
                                    <Icon
                                        as={item.icon}
                                        display='inline-flex'
                                        variant='outlined'
                                        size='xs'
                                        color='blueGray.650'
                                    />
                                    <Text as='span' color='blueGray.650'>
                                        {item.text}
                                    </Text>
                                </Stack>
                            ))}
                        </>
                    }
                    hideControl={true}
                    value={false}
                    onChange={noop}
                    headerProps={{
                        icon: iconProps,
                        price: formatUSD(roomPrice || 0, 2),
                        subtitle: tripDates,
                    }}
                />
            </BaseBox>
        </Grid.Item>
    );
};

interface PrivateUpgradeRoomsProps {
    singleSupplementStatus?: ManageBookingAddOn['status'];
    travelersQuantity: number;
    tripDates: string;
}

const PrivateUpgradeRooms = ({
    singleSupplementStatus,
    travelersQuantity,
    tripDates,
}: PrivateUpgradeRoomsProps): JSX.Element | null => {
    if (!singleSupplementStatus) return null;

    return (
        <>
            {[...Array(travelersQuantity)].map((_, index) => (
                <RoomItem
                    key={index}
                    room={{
                        roomType: Occupancy.single,
                        status: singleSupplementStatus,
                    }}
                    roomIndex={index}
                    roomsQuantity={travelersQuantity}
                    tripDates={tripDates}
                />
            ))}
        </>
    );
};

const AccommodationsSection = ({
    trip,
}: AccommodationsSectionProps): JSX.Element | null => {
    const [isInlineEditOpen, setIsInlineEditOpen] = useState(false);
    const {
        state,
        actions: { verifySingleSupplementInventory },
    } = useManageBooking();

    const {
        addOns: { accommodations },
        isBookingEditable,
        travelersRooms,
        travelersQuantity,
        serviceInventory: { status },
        serviceAvailability: {
            singleSupplement: { isPrivateRoomAvailable },
        },
        confirmation: { error },
    } = state;

    useEffect(() => {
        if (
            !!accommodations.singleSupplement &&
            isServiceInventoryNeedsVerificationStatus(status)
        ) {
            verifySingleSupplementInventory({ state });
        }
    }, [accommodations.singleSupplement, status]);

    if (isEmpty(travelersRooms)) return null;

    const singleSupplementAddOn = accommodations.singleSupplement;
    const rooms = getSelectedTravelersRooms(
        travelersRooms,
        singleSupplementAddOn,
        travelersQuantity,
    );

    const { tripDates } = getTripRelatedStringDates(trip);

    const isUpgradeToPrivateRoomSelected = checkIsUpgradeToPrivateRoomSelected(
        singleSupplementAddOn,
        travelersQuantity,
    );

    const isSingleTravelerBooking = travelersQuantity === 1;

    const travelerCanUpdatePrivateRoom =
        (isSingleTravelerBooking && isPrivateRoomAvailable) ||
        isSingleSupplementInventoryError(error);

    const shouldDisplayEditButton = shouldAllowRoomSelectionEdition({
        isBookingEditable,
        isSingleTravelerBooking,
        travelerCanUpdatePrivateRoom,
        isSingleSupplementBooked: !!accommodations.singleSupplement,
    });

    return (
        <Card width='full'>
            <Heading
                as='h4'
                fontWeight='medium'
                size={{ base: 'xl', lg: '2xl' }}
                marginBottom={6}
            >
                Room Selection
            </Heading>
            {!isInlineEditOpen ? (
                <>
                    <Grid width='full' paddingBottom={6} justifyItems='center'>
                        {isUpgradeToPrivateRoomSelected ? (
                            <PrivateUpgradeRooms
                                singleSupplementStatus={
                                    singleSupplementAddOn?.status
                                }
                                travelersQuantity={travelersQuantity}
                                tripDates={tripDates}
                            />
                        ) : (
                            Object.entries(rooms).map(
                                ([roomId, room], index) => (
                                    <RoomItem
                                        key={roomId}
                                        room={room}
                                        roomIndex={index}
                                        roomsQuantity={rooms.length}
                                        tripDates={tripDates}
                                    />
                                ),
                            )
                        )}
                    </Grid>
                    {shouldDisplayEditButton ? (
                        <Button
                            size='sm'
                            variant='secondary'
                            onClick={() => setIsInlineEditOpen(true)}
                            isFullWidth={{ base: true, md: false }}
                        >
                            Edit Room Selection
                        </Button>
                    ) : null}
                </>
            ) : (
                <Collapse isOpen={isInlineEditOpen} unmountOnExit>
                    <AccommodationsInlineEdit
                        tripDates={tripDates}
                        setIsInlineEditOpen={setIsInlineEditOpen}
                    />
                </Collapse>
            )}
        </Card>
    );
};

export default AccommodationsSection;
