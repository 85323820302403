import { createContext } from 'react';

interface OnboardingStepperContextProps {
    currentStep: number;
    goNextStep: () => void;
    goPrevStep: () => void;
}

export const OnboardingStepperContext =
    createContext<OnboardingStepperContextProps>(
        {} as OnboardingStepperContextProps,
    );
