import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from '@trova-trip/trova-components';

interface TrovaFileInputStylesProps {
    buttonText: string;
    feedbackText: string;
}

export const mainContainer = () => css`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const labelContainer = () => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
`;
export const ErrorSpan = styled.span(`
    box-sizing: border-box;
    font-size: 14px;
    line-height: 16px;
    padding-top: 9px;
    color: ${theme.colors.red.trova};
`);

export const LabelSpan = styled.span(`
    font-size: 14px;
    line-height: 16px;
    color: ${theme.colors.blueGray.dark};`);

export const DetailSpan = styled.span(`
    font-family: ${theme.fonts.roboto};
    font-size: 14px;
    line-height: 16px;
    margin-top: 9px;
    color: ${theme.colors.blueGray.dark};`);

export const trovaFileInputStyles = (
    props: TrovaFileInputStylesProps,
): SerializedStyles => css`
    .fileuploader-theme-gallery .fileuploader-input {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 134px;
        background: ${theme.colors.neutral.white};
        border: 2px dashed ${theme.colors.neutral[80]};
        border-radius: 14px;
        padding: 0 80px;
        margin: 0 0 10px 0;

        &::after {
            content: '${props.feedbackText}';
            display: block;
            text-transform: lowercase;
            text-align: center;
            font-family: ${theme.fonts.roboto};
            font-weight: ${theme.fontWeights.medium};
            color: ${theme.colors.neutral[60]};
        }
    }
    .fileuploader-theme-gallery .fileuploader-items {
        background: ${theme.colors.blueGray[100]};

        .fileuploader-input-inner {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            max-width: none;
            width: 100%;
            max-width: 157px;
            height: 48px;
            min-height: 0;
            border: 0;
            margin-bottom: 10px;
            padding: 0 10px;
            color: ${theme.colors.red.coral};
            background: ${theme.colors.red[100]};
            border-radius: 14px;

            &::before {
                content: '${props.buttonText}';
                display: block;
                margin-right: 17px;
            }

            > span {
                display: none;
            }

            .fileuploader-icon-main {
                font-size: 21px;
                margin: 0;
            }

            &:hover {
                background: ${theme.colors.red[200]};
                box-shadow: none;
            }
        }

        .fileuploader-items-list {
            justify-content: space-between;
        }

        .fileuploader-item {
            width: calc(50% - 10px);
            margin-left: 0;

            .fileuploader-item-image img,
            .fileuploader-item-image canvas {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform 400ms ease-out;
            }

            .fileuploader-item-inner {
                position: relative;
                height: auto;
                overflow: inherit;
                box-shadow: none;
                background: transparent;

                .actions-holder {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: calc(100% - 30px);

                    .fileuploader-action-sort {
                        top: calc(100% - 10px);
                        right: -24px;
                        transform: translateY(-100%);
                    }

                    .fileuploader-action-remove {
                        top: 0;
                        background: ${theme.colors.neutral.white};
                        color: ${theme.colors.red.coral};
                        box-shadow: none;

                        > i {
                            font-weight: 700;
                        }
                    }
                }

                .thumbnail-holder {
                    border-radius: 10px;
                    width: 100%;
                }

                .content-holder {
                    text-align: left;
                    max-height: 30px;
                    padding: 8px 0 0 0;
                    color: ${theme.colors.blueGray[650]};

                    h5 {
                        font-weight: inherit;
                        line-height: 16px;
                    }
                }
            }
        }
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        .fileuploader-theme-gallery .fileuploader-input {
            padding: 0 20px;
            width: calc(50% - 15px);
            margin: 0 0 15px 15px;
        }

        .fileuploader-theme-gallery .fileuploader-items {
            .fileuploader-item {
                width: calc(25% - 15px);
                margin-left: 15px;
                margin-top: 10px;
            }

            .fileuploader-items-list {
                justify-content: stretch;
                margin-left: -15px;

                .fileuploader-item:nth-child(2),
                .fileuploader-item:nth-child(3) {
                    margin-top: 0;
                }
            }
        }
    }

    @media (min-width: ${theme.breakpoints.xl}) {
        .fileuploader-theme-gallery .fileuploader-input {
            padding: 0 30px;
            width: calc(50% - 30px);
            margin-left: 30px;
            margin-bottom: 30px;
        }

        .fileuploader-theme-gallery .fileuploader-items {
            .fileuploader-item {
                width: calc(25% - 30px);
                margin-left: 30px;
            }

            .fileuploader-input-inner {
                font-size: 16px;

                .fileuploader-icon-main {
                    font-size: 23px;
                }
            }

            .fileuploader-items-list {
                margin-left: -30px;
            }
        }
    }
`;
