import { useEffect, useState } from 'react';
import { useSelector } from '../../../../state/hooks';
import getQueryParam from '../../../../util/getQueryParam';
import { coreUtils } from '@trova-trip/trova-common';
import { models } from '@trova-trip/trova-models';

const {
    getWorkshopDetailsFromServicesByDay,
    filterWorkshopDetailsBySelectedWorkshops,
} = coreUtils.serviceUtils;

type Trip = models.trips.Trip;
type TripRequest = models.tripRequest.TripRequest;

type ValidModel = 'trip' | 'tripRequest';

const isTrip = (model: ValidModel | null): boolean => {
    if (!model) return false;
    return model === 'trip';
};

const isTripRequest = (model: ValidModel | null): boolean => {
    if (!model) return false;
    return model === 'tripRequest';
};

const getTripRequestData = (tripRequest: TripRequest) => {
    const {
        itinerary: currentItinerary,
        selectedWorkshopSpaces,
        startDate,
    } = tripRequest;

    const workshopDetails = getWorkshopDetailsFromServicesByDay(
        currentItinerary?.servicesByDay || [],
        startDate as unknown as Date,
        selectedWorkshopSpaces,
    );

    const filteredWorkshopDetails = filterWorkshopDetailsBySelectedWorkshops(
        workshopDetails,
        selectedWorkshopSpaces,
    );

    return {
        ...tripRequest,
        itinerary: currentItinerary,
        servicesByDay: currentItinerary?.servicesByDay || [],
        workshopDetails: filteredWorkshopDetails,
    };
};

const getTripData = (trip: Trip) => {
    const {
        tripRequest: currentTripRequest,
        itinerary,
        servicesByDay,
        hostRooms,
        selectedPackage,
        dietaryRestriction,
        startDate,
    } = trip;

    const workshopDetails = getWorkshopDetailsFromServicesByDay(
        servicesByDay,
        startDate as Date,
        currentTripRequest.selectedWorkshopSpaces,
    );

    return {
        ...currentTripRequest,
        itinerary,
        servicesByDay,
        host: currentTripRequest?.host || {},
        hostRooms,
        selectedPackage,
        dietaryRestriction,
        workshopDetails,
    };
};

const useGetHostSummaryData = (history, data) => {
    const { tripRequest, trip } = useSelector((state) => ({
        tripRequest: state.userTripRequests.current,
        trip: state.userTrips.current,
    }));

    const model = getQueryParam(history, 'model') as ValidModel | null;

    let displayData = data;

    if (isTrip(model) && trip) {
        displayData = getTripData(trip);
    }

    if (isTripRequest(model) && tripRequest) {
        displayData = getTripRequestData(tripRequest);
    }

    return displayData;
};

export default useGetHostSummaryData;
