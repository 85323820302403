import PropTypes from 'prop-types';
import {
    Badge,
    Cell,
    Heading,
    List,
    Row,
    Stack,
    TableBody,
    TableHead,
    Text,
} from '@trova-trip/trova-components';
import { Grid } from '@material-ui/core';
import { StyledTable, PaddedGrid, ListItem } from './HostSummary.components';
import { SectionTitle } from './HostSummary';
import { coreUtils } from '@trova-trip/trova-common';
import { Card } from '@trova-trip/trova-components/build/next';

const { pluralize } = coreUtils.objectUtils;
const { formatDateTime } = coreUtils.dateUtils;

type SelectedWorkshopsByHost = coreUtils.serviceUtils.SelectedWorkshops;
type WorkshopDetail = coreUtils.serviceUtils.WorkshopDetail;

interface WorkshopDetailCardProps {
    workshopDetail: WorkshopDetail;
}

const WorkshopDetailListItem = ({ icon, label, value }) => (
    <List.Item bulletIcon={{ as: icon, color: 'purple.400' }}>
        <Text as='span' size='sm' color='blueGray.650'>
            <Text as='span' fontWeight='medium' size='sm' color='blueGray.650'>
                {label}:
            </Text>{' '}
            {value}
        </Text>
    </List.Item>
);

const WorkshopDetailCard = ({ workshopDetail }: WorkshopDetailCardProps) => {
    const {
        id,
        name,
        day,
        date,
        hoursAvailable,
        hoursRequestedByHost,
        description,
    } = workshopDetail;

    const dateString = formatDateTime(date, 'EEEE, MMMM dd, yyyy', 'UTC');

    return (
        <Card data-service={id}>
            <Stack
                direction={{ base: 'column-reverse', sm: 'row' }}
                align={{ base: 'flex-start', sm: 'center' }}
                justify='space-between'
            >
                <Heading as='h5' size='xl'>
                    {name}
                </Heading>
                <Badge colorScheme='purple' variant='rounded'>
                    Workshop Space
                </Badge>
            </Stack>
            <Stack
                direction='column'
                justify='stretch'
                width='full'
                spacing={4}
                marginTop={2}
            >
                <List listStyleType='none' spacing='0.25rem'>
                    <WorkshopDetailListItem
                        icon='calendar'
                        label='Day'
                        value={`${day} - ${dateString}`}
                    />
                    <WorkshopDetailListItem
                        icon='timer'
                        label='Hours Available'
                        value={hoursAvailable}
                    />
                    {hoursRequestedByHost ? (
                        <WorkshopDetailListItem
                            icon='accessTime'
                            label='Hours Requested'
                            value={hoursRequestedByHost}
                        />
                    ) : null}
                </List>
                <Text>{description}</Text>
            </Stack>
        </Card>
    );
};

interface WorkshopDetailsListProps {
    workshopDetails: WorkshopDetail[];
}

const WorkshopDetailsList = ({ workshopDetails }: WorkshopDetailsListProps) => {
    return (
        <Grid item xs={12} md={8} lg={8}>
            <Stack
                direction='column'
                align='stretch'
                width='full'
                marginY={4}
                spacing={4}
            >
                {workshopDetails.map((workshopDetail) => (
                    <WorkshopDetailCard
                        key={workshopDetail.id}
                        workshopDetail={workshopDetail}
                    />
                ))}
            </Stack>
        </Grid>
    );
};

interface WorkshopRequestsProps {
    workshops: any[];
    selectedWorkshops: SelectedWorkshopsByHost;
    workshopDetails: WorkshopDetail[];
}

const WorkshopRequests = ({
    workshops = [],
    selectedWorkshops = [],
    workshopDetails = [],
}: WorkshopRequestsProps) => {
    const areWorkshops = workshops.length > 0;
    const areSelectedWorkshops = selectedWorkshops.length > 0;
    if (!areWorkshops && !areSelectedWorkshops) {
        return null;
    }

    const workshopCount = selectedWorkshops.length;
    const totalWorkshopHours = selectedWorkshops.reduce(
        (totalHours, workshopWithId) => {
            // Format of each workshopWithId is [serviceId, {_id, length}]
            const workshop = workshopWithId[1];
            return totalHours + workshop.length;
        },
        0,
    );

    const hasWorkshopDetails = workshopDetails.length > 0;

    return (
        <PaddedGrid item xs={12}>
            <Grid item xs={12}>
                <SectionTitle>Workshop Spaces</SectionTitle>
            </Grid>
            {areSelectedWorkshops ? (
                <Grid item xs={12} md={8} lg={8}>
                    <ListItem>
                        {` ${pluralize(
                            workshopCount,
                            'workshop',
                            'workshops',
                        )} - ${pluralize(totalWorkshopHours, 'hour', 'hours')}
                        total `}
                    </ListItem>
                </Grid>
            ) : null}
            {hasWorkshopDetails ? (
                <WorkshopDetailsList workshopDetails={workshopDetails} />
            ) : null}
            {areWorkshops ? (
                <Grid item xs={12} md={8} lg={8}>
                    <StyledTable>
                        <TableHead>
                            <Row>
                                <Cell>Type</Cell>
                                <Cell>Duration</Cell>
                                <Cell>Description</Cell>
                            </Row>
                        </TableHead>
                        <TableBody>
                            {workshops.map(
                                ({ workshopType, time, resources }, index) => (
                                    <Row key={index}>
                                        <Cell>{workshopType}</Cell>
                                        <Cell>{time}</Cell>
                                        <Cell>{resources}</Cell>
                                    </Row>
                                ),
                            )}
                        </TableBody>
                    </StyledTable>
                </Grid>
            ) : null}
        </PaddedGrid>
    );
};

WorkshopRequests.propTypes = { workshops: PropTypes.array };
export default WorkshopRequests;
