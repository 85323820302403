import PrePostAccommodationCard, {
    ActionableAccommodationProps,
} from '../../../../../common/components/PrePostAccommodationCard';
import { getTripRelatedStringDates } from '../../../../../common/helpers';
import { useManageBooking, usePricingData } from '../../hooks';
import { ServiceTiming, ServiceTimingType, Trip } from '../../types';
import {
    isUpgradeToPrivateRoomSelected as checkIsUpdateToPrivateRoomSelected,
    checkTripHasPrePostAddOns,
    isPrePostAccommodationSelected,
} from '../../utils/check.utils';
import {
    getPrePostAccommodationIconProps,
    getPrePostRoomsOccupancies,
    getToggleAddOnActionType,
} from '../../utils/extract.utils';

interface PrePostAccommodationsSectionProps {
    trip: Trip;
}

const PrePostAccommodationsSection = ({
    trip,
}: PrePostAccommodationsSectionProps): JSX.Element | null => {
    const {
        state: {
            addOns: { accommodations },
            travelersRooms,
            travelersQuantity,
            isBookingEditable,
            defaultRoomOccupancy,
        },
        actions: { updateAccommodation },
    } = useManageBooking();

    const roomsOccupancies = getPrePostRoomsOccupancies({
        rooms: travelersRooms,
        travelersQuantity,
        singleSupplement: accommodations?.singleSupplement,
        defaultRoomOccupancy,
    });

    const {
        tripHasPreAccommodation,
        tripHasPostAccommodation,
        tripHasPreOrPostAccommodations,
    } = checkTripHasPrePostAddOns({
        accommodations,
        travelersRooms,
        roomsOccupancies,
        travelersQuantity,
    });

    const { preTripAccommodationsPrice, postTripAccommodationsPrice } =
        usePricingData();

    if (!tripHasPreOrPostAccommodations) {
        return null;
    }

    const { preTripDate, postTripDate } = getTripRelatedStringDates(trip);

    const isUpgradeToPrivateRoomSelected = checkIsUpdateToPrivateRoomSelected(
        accommodations.singleSupplement,
        travelersQuantity,
    );

    const handleChange = (timing: ServiceTimingType): void => {
        const isSelected = isPrePostAccommodationSelected(
            accommodations[timing],
        );

        updateAccommodation({
            timing,
            transfersTimingsToUpdate: [timing],
            isUpgradeToPrivateRoomSelected,
            roomSelection: roomsOccupancies,
            actionType: getToggleAddOnActionType(isSelected),
        });
    };

    const preAccommodationProps: ActionableAccommodationProps | undefined =
        tripHasPreAccommodation
            ? {
                  date: preTripDate,
                  price: preTripAccommodationsPrice,
                  iconProps: getPrePostAccommodationIconProps(
                      accommodations[ServiceTiming.PRE_TRIP],
                  ),
                  isSelected: isPrePostAccommodationSelected(
                      accommodations[ServiceTiming.PRE_TRIP],
                  ),
              }
            : undefined;

    const postAccommodationProps: ActionableAccommodationProps | undefined =
        tripHasPostAccommodation
            ? {
                  date: postTripDate,
                  price: postTripAccommodationsPrice,
                  iconProps: getPrePostAccommodationIconProps(
                      accommodations[ServiceTiming.POST_TRIP],
                  ),
                  isSelected: isPrePostAccommodationSelected(
                      accommodations[ServiceTiming.POST_TRIP],
                  ),
              }
            : undefined;

    return (
        <PrePostAccommodationCard
            mode='actionable'
            preAccommodation={preAccommodationProps}
            postAccommodation={postAccommodationProps}
            onChange={handleChange}
            isDisabled={!isBookingEditable}
        />
    );
};

export default PrePostAccommodationsSection;
