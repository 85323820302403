import { Heading, Stack } from '@trova-trip/trova-components';
import {
    Card,
    LinkCard,
    LinkCardProps,
    CardProps,
} from '@trova-trip/trova-components/build/next';
import TooltipButton, {
    TooltipButtonProps,
} from '../../../../../../common/components/TooltipButton';
import useIsLargeScreen from '../../../../../../common/hooks/useIsLargeScreen';

interface BorderedInnerCardContentProps {
    children: React.ReactNode;
    title?: string;
    tooltipProps?: TooltipButtonProps;
    forceTitleDisplay?: boolean;
}

type BorderedInnerLinkCardProps = BorderedInnerCardContentProps & LinkCardProps;

interface BorderedInnerCardProps
    extends BorderedInnerCardContentProps,
        Omit<CardProps, 'children'> {}

const BorderedInnerCardContent = ({
    title,
    tooltipProps,
    children,
    forceTitleDisplay = false,
}: BorderedInnerCardContentProps): JSX.Element => {
    const isLargeScreen = useIsLargeScreen({ includeTabletResolution: true });
    const showHeading = forceTitleDisplay || isLargeScreen;

    return (
        <Stack direction='column' height='full' justify='stretch' spacing={2}>
            {showHeading ? (
                <Stack align='center'>
                    <Heading
                        fontSize={{ base: 'base', md: 'lg' }}
                        color='blueGray.650'
                        lineHeight='normal'
                        fontWeight='regular'
                    >
                        {title}
                    </Heading>
                    {tooltipProps ? <TooltipButton {...tooltipProps} /> : null}
                </Stack>
            ) : null}
            {children}
        </Stack>
    );
};

export const BorderedInnerLinkCard = ({
    title,
    tooltipProps,
    children,
    forceTitleDisplay = false,
    ...rest
}: BorderedInnerLinkCardProps): JSX.Element => {
    return (
        <LinkCard width='full' {...rest}>
            <BorderedInnerCardContent
                forceTitleDisplay={forceTitleDisplay}
                title={title}
                tooltipProps={tooltipProps}
            >
                {children}
            </BorderedInnerCardContent>
        </LinkCard>
    );
};

export const BorderedInnerCard = ({
    title,
    tooltipProps,
    children,
    forceTitleDisplay = false,
    ...rest
}: BorderedInnerCardProps): JSX.Element => {
    return (
        <Card
            variant={{ base: 'simple', md: 'bordered' }}
            paddingX={{ base: 3, md: 5 }}
            paddingY={{ base: 3, md: 3 }}
            width='full'
            {...rest}
        >
            <BorderedInnerCardContent
                forceTitleDisplay={forceTitleDisplay}
                title={title}
                tooltipProps={tooltipProps}
            >
                {children}
            </BorderedInnerCardContent>
        </Card>
    );
};
