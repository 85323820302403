import ImageUpload from '../../../../components/CustomUpload/ImageUpload';
import StyledImageUploaderContainer from './StyledImageUploaderContainer';
import { SyntheticEvent } from 'react';

export type Variants = 'rounded' | 'horizontal';

interface SingleImageUploadProps {
    name: string;
    label?: string;
    value: string;
    onChange: (value: SyntheticEvent) => void;
    variant?: Variants;
}

const API_UPLOAD_URL = '/api/me/files/new-upload-image';

const ProfileImageUploader = ({
    name,
    label,
    value,
    onChange,
    variant = 'rounded',
}: SingleImageUploadProps): JSX.Element => (
    <StyledImageUploaderContainer hasImage={!!value} variant={variant}>
        <ImageUpload
            name={name}
            value={[value]}
            onChange={onChange}
            label={label}
            imageAmountLimit='1'
            sortable={false}
            uploadUrl={API_UPLOAD_URL}
        />
    </StyledImageUploaderContainer>
);

export default ProfileImageUploader;
