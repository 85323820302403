import { TextInput, Stack, Text } from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import ChipsDisplay from '../ChipsDisplay';
import { useState } from 'react';
import { EditFieldProps, PreviewProps } from '../../utils/types';

interface PronounsPreviewProps extends PreviewProps<'pronouns'> {}

export const PronounsPreview = ({
    pronouns = [],
}: PronounsPreviewProps): JSX.Element | null => {
    return pronouns.length ? (
        <ChipsDisplay list={pronouns} />
    ) : (
        <>No pronouns shared.</>
    );
};

export const PronounsEditField = ({
    fieldData = [],
    onChange,
}: EditFieldProps<'pronouns'>): JSX.Element => {
    const [inputText, setInputText] = useState<string>('');

    const onRemovePronoun = (value: string) => {
        const updatedPronounList = fieldData.filter((chip) => chip !== value);
        onChange(updatedPronounList);
    };

    const onAddPronoun = () => {
        const updatedPronounList = !fieldData.includes(inputText)
            ? [...fieldData, inputText]
            : fieldData;

        onChange(updatedPronounList);

        setInputText('');
    };

    const onChangeText = (event) => {
        const value = event.target.value.trim();
        setInputText(value);
    };

    return (
        <Stack
            direction='column'
            height='full'
            width='full'
            paddingTop={5}
            spacing={5}
        >
            {fieldData.length ? (
                <ChipsDisplay list={fieldData} onRemove={onRemovePronoun} />
            ) : null}
            <Stack width='full' wrap='nowrap' align='flex-end' spacing={5}>
                <Stack width='full' align='stretch' direction='column'>
                    <Text>Add Your Pronouns</Text>
                    <TextInput
                        name='pronouns'
                        placeholder='She/Her'
                        type='text'
                        size='md'
                        value={inputText}
                        onChange={onChangeText}
                    />
                </Stack>
                <Button
                    size='md'
                    isDisabled={inputText === ''}
                    onClick={onAddPronoun}
                >
                    Add
                </Button>
            </Stack>
        </Stack>
    );
};
