import {
    BaseBox,
    Heading,
    Image,
    Stack,
    Text,
} from '@trova-trip/trova-components';
import TrovaLogo from 'assets/img/trova-logo.png';
import { ReactNode } from 'react';

interface OnboardingStepHeaderProps {
    title: string | ReactNode;
    lead?: string | ReactNode;
    showTrovaTripLogo?: boolean;
}

const OnboardingStepHeader = (props: OnboardingStepHeaderProps) => {
    const { title, lead, showTrovaTripLogo } = props;
    return (
        <Stack direction='column' spacing={{ base: 4, md: 6 }}>
            <Stack direction='column' spacing={{ base: 6, md: 10 }}>
                {showTrovaTripLogo && <Image width={36} src={TrovaLogo} />}
                <Heading size={{ base: 'xl', md: '4xl' }}>{title}</Heading>
            </Stack>
            {lead && (
                <BaseBox maxWidth='32rem'>
                    <Text
                        fontSize={{ base: 'sm', md: 'base' }}
                        color='blueGray.600'
                    >
                        {lead}
                    </Text>
                </BaseBox>
            )}
        </Stack>
    );
};

export default OnboardingStepHeader;
