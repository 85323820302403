import { Card, CardProps } from '@trova-trip/trova-components/build/next';
import React from 'react';

interface WidgetCardProps extends CardProps {
    children: React.ReactNode;
}

const WidgetCard = (props: WidgetCardProps) => {
    const { children, ...rest } = props;
    return (
        <Card
            padding={{ base: 0, md: 6 }}
            backgroundColor={{ base: 'transparent', md: 'white' }}
            {...rest}
        >
            {children}
        </Card>
    );
};

export default WidgetCard;
