import { styledFloatingBanner } from './TripFloatingBanner.styles';

interface TripFloatingBannerProps {
    children: React.ReactNode;
    className?: string;
}

export const TripFloatingBanner = (
    props: TripFloatingBannerProps,
): JSX.Element => {
    const { className = '', children } = props;

    return (
        <div className={`${styledFloatingBanner} ${className}`}>{children}</div>
    );
};

export default TripFloatingBanner;
