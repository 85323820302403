import { TRIP_CATALOG_PAGE_URI } from 'applications/utils';
import { History } from 'history';
import { MainContentProps } from '../MainContent';
import { ButtonProps } from '@trova-trip/trova-components/build/next';

const defaultButtonProps: Record<string, ButtonProps> = {
    hostQualified: {
        variant: 'primary',
        rightIcon: 'arrow-right',
        children: 'Request a Trip',
    },
    hostNonQualified: {
        variant: 'primary',
        rightIcon: 'plus',
        children: 'Start Surveying',
    },
    operator: {
        variant: 'primary',
        rightIcon: 'plus',
        children: 'Add new itinerary',
    },
    traveler: {
        as: 'a',
        rightIcon: 'offsite',
        isFullWidth: true,
        linkProps: {
            href: TRIP_CATALOG_PAGE_URI,
            target: '_blank',
            rel: 'noreferrer',
        },
        children: 'Book new trip',
    },
};

// interface and format the current sidebarProps to be consumables for the new sidebar
export const getButtonSidebarProps = (
    history: History<unknown>,
    { type, buttonPath, buttonText }: MainContentProps['sidebarProps'],
): ButtonProps => {
    const buttonProps = {
        ...defaultButtonProps[type],
        variant: 'primary' as ButtonProps['variant'],
        onClick: () => {
            history.push(buttonPath);
        },
        isFullWidth: { lg: true },
        ...(type !== 'traveler' && {
            isDisabled: history.location.pathname === buttonPath,
        }),
        children: buttonText || defaultButtonProps[type].children,
    };

    return buttonProps;
};
