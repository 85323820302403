import ProfileForm from '../components/Forms/ProfileForm';
import TabPage from '../components/TabPage';

interface ProfileProps {
    isHost: boolean;
}

const Profile = ({ isHost }: ProfileProps): JSX.Element => {
    const title = isHost ? 'Personal Information' : 'Update Your Profile';

    return <TabPage title={title} content={<ProfileForm />} />;
};

export default Profile;
