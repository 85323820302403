import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { models } from '@trova-trip/trova-models';
import { userLinks } from '../../state/links';
import { useWindowHostNameAndProtocol } from './useWindowHostname';

type storeSelector = {
    userLinks: { current: models.links.Link[]; fetching: boolean };
};

type LinkObject = {
    surveyLink: string | undefined;
    emailLink: string | undefined;
    referralLink: string | undefined;
};

const useGetUserLinks = (): LinkObject => {
    const [linkObject, setLinkObject] = useState<LinkObject>({} as LinkObject);
    const links = useSelector((state: storeSelector) => state.userLinks);
    const { getRecord: getUserLinks } = userLinks.useDispatch.bind(links)();
    const domain = useWindowHostNameAndProtocol();

    useEffect(() => {
        if (!links.current && links.fetching !== true) {
            getUserLinks();
        } else if (links.fetching !== true) {
            const getLinkPathFor = (linkType: string): string | undefined =>
                links.current.find((link) => link.linkType === linkType)?.path;

            const surveyLinkPath = getLinkPathFor('survey');
            const emailLinkPath = getLinkPathFor('emailCapture');
            const referralLinkPath = getLinkPathFor('referral');

            const newLinkObject = {
                surveyLink: surveyLinkPath
                    ? `${domain}/public/l/${surveyLinkPath}`
                    : undefined,
                emailLink: emailLinkPath
                    ? `${domain}/public/l/${emailLinkPath}`
                    : undefined,
                referralLink: referralLinkPath
                    ? `${domain}/public/l/${referralLinkPath}`
                    : undefined,
            };
            setLinkObject(newLinkObject);
        }
    }, [getUserLinks, links, domain]);

    return linkObject;
};

export default useGetUserLinks;
