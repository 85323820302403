import React from 'react';
import { Skeleton, SkeletonProps, Stack } from '@trova-trip/trova-components';

type WidgetLoadingFallbackProps = {
    skeletonLoaderHeight: SkeletonProps['height'];
    skeletonLoaderWidth?: SkeletonProps['width'];
    renderCount?: number;
};

const WidgetLoadingFallback: React.FC<WidgetLoadingFallbackProps> = (props) => {
    const width = props.skeletonLoaderWidth
        ? props.skeletonLoaderWidth
        : 'full';
    const renderCount = props.renderCount || 1;
    const renderSkeletonComponent = (count: number): JSX.Element[] =>
        [...Array(count).keys()].map((key) => {
            return (
                <Skeleton
                    key={key}
                    borderRadius='xl'
                    height={props.skeletonLoaderHeight}
                    width={width}
                />
            );
        });
    if (renderCount > 1) {
        return (
            <Stack
                direction={'column'}
                spacing={4}
                height={{ base: '80', md: 'full' }}
                wrap='nowrap'
            >
                {renderSkeletonComponent(renderCount)}
            </Stack>
        );
    }

    return <>{renderSkeletonComponent(renderCount)}</>;
};
export { WidgetLoadingFallback as default };
