import { useSelector } from 'state/hooks';

interface ScreenshotOptions {
    width?: number;
    height?: number;
    cacheHours?: number;
}

export const useTripPageScreenshot = (
    options?: ScreenshotOptions,
): string | undefined => {
    const trip = useSelector((state) => state.userTrips.current);

    if (!trip) return;

    const API_URL = `public/trips/${trip.id}/screenshot`;
    const { width = 1280, height = 1000, cacheHours = 1 } = options || {};

    const queryParams = new URLSearchParams({
        width: width.toString(),
        height: height.toString(),
        cacheHours: cacheHours.toString(),
    });

    return `/${API_URL}?${queryParams.toString()}`;
};
