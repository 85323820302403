import { coreUtils } from '@trova-trip/trova-common';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'state/hooks';
import {
    CLOSED_ITEM,
    CONFIRMED_ITEM,
    LIVE_ITEM,
    MARKETING_ITEM,
} from './tripStatusItems';

const {
    tripUtils: { getTripCloseDate },
} = coreUtils;

export const useTripStatusItems = () => {
    const currentTrip = useSelector((state) => state.userTrips.current);

    const closeDate = useMemo(() => {
        if (!currentTrip) return '';
        const date = getTripCloseDate(currentTrip);
        return moment(date).utc().format('ll');
    }, [currentTrip]);

    const statusItems = useMemo(() => {
        if (!currentTrip) return [];
        const items = [
            MARKETING_ITEM(),
            LIVE_ITEM(),
            CONFIRMED_ITEM(),
            CLOSED_ITEM(closeDate, currentTrip.status),
        ];
        return items.map((statusContent) => {
            const { statuses, ...rest } = statusContent;
            return {
                ...rest,
                isActive: statuses.includes(currentTrip?.status || ''),
            };
        });
    }, [currentTrip, closeDate]);

    return statusItems;
};
