import { models, validationSchemas } from '@trova-trip/trova-models';
import {
    Dropdown,
    FormSaver,
    Input,
    Textarea,
    TextareaSize,
    useFormSaver,
} from '@trova-trip/trova-components';
import { ServiceTypesKeys } from 'config/constants';
import { DropdownOption } from 'interfaces/FormComponents';
import { noop } from 'lodash';
import { useSelector } from 'react-redux';
import { getTripDatesIfApplicable } from 'util/ModelDataHelper';
import ButtonsContainer, {
    cancelButtonDefault,
    saveButtonDefault,
} from '../../../common/components/services/ButtonsContainer';
import WorkshopHoursField from '../../../common/components/services/WorkshopHoursField';
import {
    styledFieldWrapper,
    styledFormWrapper,
} from './TripServiceForm.styles';

type Service = models.services.Service;
type WorkshopSpace = models.services.WorkshopSpace;

interface TripServiceFormProps {
    service: Service;
    serviceDayIndex?: string;
    isDisabledByFormDirty?: boolean;
    onSubmit: (service: Service) => void;
    onCancel?: (service: Service, resetForm: () => void) => void;
}

type TripStoreSelector = { userTrips: { current?: models.trips.Trip } };

const { ServiceFormSchema, GatheringFormSchema } = validationSchemas;

const fieldNames = {
    name: 'name',
    description: 'description',
    dayIndex: 'dayIndex',
};

const TripServiceForm = ({
    service,
    serviceDayIndex,
    onSubmit,
    onCancel,
    isDisabledByFormDirty = true,
}: TripServiceFormProps): JSX.Element => {
    const currentTrip = useSelector(
        (state: TripStoreSelector) => state.userTrips.current,
    );

    const { name = '', type, description, hoursRequested } = service;
    const isGathering = type === ServiceTypesKeys.INFORMAL_GATHERING;

    const { servicesByDay, startDate } = currentTrip || {};

    const schema = isGathering
        ? ServiceFormSchema.concat(GatheringFormSchema)
        : ServiceFormSchema;

    const initialValues = {
        name,
        description,
        dayIndex: serviceDayIndex,
    };

    const formSaver = useFormSaver({
        onSubmit: async (values) => {
            onSubmit({ ...service, ...values });
        },
        validationSchema: {
            schema,
            validateFieldOnChange: false,
        },
        initialValues,
    });

    const tripDates = getTripDatesIfApplicable(startDate, servicesByDay);

    const dayDropdownOptions: DropdownOption[] =
        (isGathering &&
            servicesByDay?.map((_, idx) => {
                return {
                    value: idx.toString(),
                    children: `Day ${idx + 1} - ${tripDates[idx]}`,
                };
            })) ||
        [];

    const isDaysDropdownVisible = dayDropdownOptions.length > 0;

    return (
        <div className={styledFormWrapper}>
            <FormSaver
                name='form-service'
                onSubmit={formSaver.handleFormSubmit}
            >
                <div className={styledFieldWrapper}>
                    <Input
                        name={fieldNames.name}
                        value={formSaver.formValues.get.name as string}
                        error={formSaver.formErrors?.name}
                        label={'Name'}
                        onChange={formSaver.formValues.set.name}
                    />
                </div>
                {hoursRequested ? (
                    <div className={styledFieldWrapper}>
                        <WorkshopHoursField
                            type='requested'
                            value={hoursRequested}
                        />
                    </div>
                ) : null}
                {isDaysDropdownVisible && (
                    <div className={styledFieldWrapper}>
                        <Dropdown
                            name={fieldNames.dayIndex}
                            label={'Day'}
                            value={formSaver.formValues.get.dayIndex as string}
                            error={formSaver.formErrors?.dayIndex}
                            onSearch={(): void => undefined}
                            onChange={(_, __, eventValue): void => {
                                formSaver.formValues.set.dayIndex(
                                    eventValue as string,
                                );
                            }}
                        >
                            {dayDropdownOptions}
                        </Dropdown>
                    </div>
                )}
                <div className={styledFieldWrapper}>
                    <Textarea
                        name={fieldNames.description}
                        value={formSaver.formValues.get.description as string}
                        error={formSaver.formErrors?.description}
                        label={'Description'}
                        size={TextareaSize.Flexible}
                        onChange={formSaver.formValues.set.description}
                    />
                </div>
                <ButtonsContainer
                    buttons={[
                        cancelButtonDefault({
                            onClick: () =>
                                onCancel && onCancel(service, formSaver.reset),
                        }),
                        saveButtonDefault({
                            text: 'Save',
                            type: 'submit',
                            isDisabled:
                                isDisabledByFormDirty &&
                                !formSaver.isFormDirty &&
                                (service as WorkshopSpace)?.acceptedByHost !==
                                    false,
                            onClick: noop,
                        }),
                    ]}
                />
            </FormSaver>
        </div>
    );
};

export default TripServiceForm;
