import { css } from '@emotion/css';

export const styledListItem = css`
    list-style-type: disc;
    line-height: 2em;
    margin-left: 1.5rem;
`;
export const styledContentWrapper = css`
    margin-top: 2.3rem;
`;
