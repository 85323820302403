import { models } from '@trova-trip/trova-models';
import { Spinner } from '@trova-trip/trova-components';
import StickyContentSellPrice from '../StickyContent/StickyContentSellPrice';
import { StyledStickyContentContainer } from './TripPricingCard.styles';

type SuggestedSellPrice = models.itineraries.SuggestedSellPrice;

interface TripPricingCardProps {
    title: string;
    value?: SuggestedSellPrice;
    isLoading?: boolean;
}

const TripPricingCard = ({
    title,
    value,
    isLoading,
}: TripPricingCardProps): React.ReactElement => {
    return (
        <StyledStickyContentContainer>
            <StickyContentSellPrice
                title={title}
                suggestedPrice={value}
                isLoading={isLoading}
            />
        </StyledStickyContentContainer>
    );
};

export default TripPricingCard;
