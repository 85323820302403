import { ButtonProps } from '@trova-trip/trova-components/build/next';
import { ReactElement } from 'react';
import { BookedGroundTransferJourney } from '../../../../../state/features/transfer/types';
import FullScreenActionSheet from '../../../../common/components/FullScreenActionSheet';
import {
    isCompanyDataAvailable,
    isDriverDataAvailable,
} from '../../../../common/helpers';
import { MobileViewName } from './RideOperationsPopup';
import JourneyStatusBadge from './shared/JourneyStatusBadge';

interface RideOperationsActionSheet {
    isOpen: boolean;
    onClose: () => void;
    onCancel: () => void;
    journey: BookedGroundTransferJourney;
    content: ReactElement | undefined;
    activeView: MobileViewName;
    title?: string;
}

const RideOperationsActionSheet = (
    props: RideOperationsActionSheet,
): JSX.Element => {
    const { onCancel, journey, activeView, ...rest } = props;

    const isCancelView = activeView === 'cancel';
    const driverDataIsAvailable = isDriverDataAvailable(journey.driver);
    const companyDataIsAvailable = isCompanyDataAvailable(
        journey.transferCompany,
    );

    const phoneNumber =
        journey.driver?.phoneNumber || journey.transferCompany?.phoneNumber;

    const actionsMap: Record<string, ButtonProps[]> = {
        bookingDetails: [
            {
                children: 'Cancel Ride',
                isFullWidth: true,
                variant: 'secondary',
                onClick: onCancel,
            },
        ],
    };

    if (companyDataIsAvailable) {
        actionsMap['bookingDetails'].push({
            children: driverDataIsAvailable
                ? 'Call The Driver'
                : 'Call The Company',
            isFullWidth: true,
            as: 'a',
            leftIcon: 'call',
            linkProps: {
                target: '_blank',
                href: `tel:${phoneNumber}`,
            },
        });
    }

    return (
        <FullScreenActionSheet
            titleRightAddOn={
                !isCancelView ? (
                    <JourneyStatusBadge status={journey.status} />
                ) : undefined
            }
            actions={actionsMap[activeView]}
            {...rest}
        />
    );
};

export default RideOperationsActionSheet;
