/*issue with CRA4 and React 17: https://github.com/emotion-js/emotion/issues/2041*/
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, Fragment } from 'react';
import { Button } from '@trova-trip/trova-components/build/next';

import DaysDropdown from './DaysDropdown';
import { ItineraryServices } from 'config/constants';
import { Stack } from '@trova-trip/trova-components';

interface ServicesButtonsProps {
    onDaySelected: (
        dayNumber: number,
        item: { type: string; serviceType: string },
    ) => void;
    numberOfDays: number;
    onClose: () => void;
    scrollToDay: (dayNumber: number) => void;
}

const ServicesButtons: React.FC<ServicesButtonsProps> = ({
    onDaySelected,
    numberOfDays,
    onClose,
    scrollToDay,
}) => {
    const [showDaysDropdown, setShowDaysDropdown] = useState<boolean[]>(
        Array(ItineraryServices.length).fill(false),
    );
    const onClickButton = (index: number) => {
        setShowDaysDropdown((prevValue) => {
            const newValue = [...prevValue];
            newValue[index] = true;
            return newValue;
        });
    };
    return (
        <Stack paddingY={2} spacing={3} direction='column'>
            {ItineraryServices.map((service, index) => {
                const { serviceName, colorScheme, serviceType } = service;
                const item = {
                    type: `draggableButton`,
                    serviceType,
                };
                return (
                    <Fragment key={serviceName}>
                        <Button
                            variant='plain'
                            colorScheme={colorScheme}
                            onClick={() => onClickButton(index)}
                            rightIcon='plus'
                        >
                            {serviceName}
                        </Button>
                        <DaysDropdown
                            numberOfDays={numberOfDays}
                            onDaySelect={(dayNumber) => {
                                onDaySelected(dayNumber, item);
                                onClose();
                                scrollToDay(dayNumber);
                            }}
                            show={showDaysDropdown[index]}
                        />
                    </Fragment>
                );
            })}
        </Stack>
    );
};

export default ServicesButtons;
