import { get, post } from './api';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { coreUtils } from '@trova-trip/trova-common';

const fetchStates = {};

const createActions = (path) => {
    return {
        getRequest: `${path}/GET_REQUEST`,
        getSuccess: `${path}/GET_SUCCESS`,
        getFailure: `${path}/GET_FAILURE`,
        listRequest: `${path}/LIST_REQUEST`,
        listSuccess: `${path}/LIST_SUCCESS`,
        listFailure: `${path}/LIST_FAILURE`,
        searchRequest: `${path}/SEARCH_REQUEST`,
        searchSuccess: `${path}/SEARCH_SUCCESS`,
        searchFailure: `${path}/SEARCH_FAILURE`,
        createRequest: `${path}/CREATE_REQUEST`,
        createSuccess: `${path}/CREATE_SUCCESS`,
        createFailure: `${path}/CREATE_FAILURE`,
        updateRequest: `${path}/UPDATE_REQUEST`,
        updateSuccess: `${path}/UPDATE_SUCCESS`,
        updateFailure: `${path}/UPDATE_FAILURE`,
        clearCurrentRecord: `${path}/CLEAR_ERROR`,
        clearCurrentList: `${path}/CLEAR_LIST_ERROR`,
        loggedOut: `LOGGED_OUT`,
        firstTime: `${path}/FIRST_TIME`,
        autoConfirmation: `${path}/AUTO_CONFIRMATION`,
        onSendingEmail: `${path}/SEND_EMAIL`,
    };
};

const clearCurrentRecord = (state) => {
    const newState = {
        ...state,
    };
    delete newState.current;
    return newState;
};

const clearCurrentList = (state) => {
    const newState = {
        ...state,
    };
    delete newState.list;
    return newState;
};
class FetchState {
    static create(path, isStandardApi) {
        return new FetchState(path, isStandardApi);
    }

    constructor(path, isStandardApi = true) {
        const existingFetchState = fetchStates[path];
        if (fetchStates[path]) {
            return existingFetchState;
        }
        fetchStates[path] = this;
        this.path = path;
        this.actions = createActions(path);
        this.isStandardApi = isStandardApi;
        this.customPreprocessor = (response) => response;
    }

    validateIfTokenAppliesToPath = (path, tokenKey) => {
        if (!path.includes(`:${tokenKey}`)) {
            throw new Error(
                `FetchState query path ${path} does not include token key ${tokenKey}`,
            );
        }
    };

    getPathTokens = (path) =>
        path
            .split('/')
            .filter((pathSegment) => pathSegment.includes(':'))
            .map((token) => token.replace(':', ''));

    validateAllPathTokensAreCovered = (path, tokens = {}) => {
        const tokenArray = Object.keys(tokens);
        const pathTokens = this.getPathTokens(path);
        pathTokens.forEach((pathToken) => {
            if (!tokenArray.includes(pathToken)) {
                throw new Error(
                    `Token value for ${pathToken} is undefined for path ${path}`,
                );
            }
        });
    };

    preprocessRequestPath = (path, tokens) => {
        if (path) {
            this.validateAllPathTokensAreCovered(path, tokens);
            const result = Object.entries(tokens).reduce(
                (processedPath, [tokenKey, tokenValue]) => {
                    this.validateIfTokenAppliesToPath(processedPath, tokenKey);
                    return processedPath.replace(`:${tokenKey}`, tokenValue);
                },
                path,
            );
            return result;
        }
    };

    preprocessResponse = (response) => {
        if (!this.isStandardApi) {
            return this.customPreprocessor(response);
        }
        if (!response.success) {
            throw new Error(response.error);
        }
        return response.data;
    };

    setCustomPreprocessor(processor) {
        this.customPreprocessor = processor;
    }

    getActions() {
        return this.actions;
    }
    updatePath(newPath) {
        this.path = newPath;
    }
    getReducer() {
        return (
            state = { fetching: false, firstTime: true },
            { type, data },
        ) => {
            switch (type) {
                case this.actions.clearCurrentList:
                    return {
                        ...clearCurrentList(state),
                    };
                case this.actions.clearCurrentRecord:
                    return {
                        ...clearCurrentRecord(state),
                    };
                case this.actions.listRequest:
                    return {
                        ...state,
                        fetching: true,
                    };
                case this.actions.listSuccess:
                    // eventually may want to normalize especially if we get
                    // into some array searches . . . but for now only storing
                    // 50 of the list and just getting more on pagination
                    return {
                        ...state,
                        list: data,
                        fetching: false,
                    };
                case this.actions.listFailure:
                    return {
                        ...state,
                        list: {
                            error: data,
                        },
                    };
                case this.actions.searchRequest:
                    return {
                        ...state,
                        fetching: true,
                    };
                case this.actions.searchSuccess:
                    return {
                        ...state,
                        search: data,
                        fetching: false,
                    };
                case this.actions.searchFailure:
                    return {
                        ...state,
                        search: {
                            error: data,
                        },
                    };
                case this.actions.getRequest:
                    return {
                        ...state,
                        fetching: true,
                    };
                case this.actions.getSuccess:
                    return {
                        ...state,
                        fetching: false,
                        current: data,
                    };
                case this.actions.getFailure:
                    return {
                        ...state,
                        fetching: false,
                        current: {
                            error: data,
                        },
                    };
                case this.actions.updateRequest:
                    return {
                        ...state,
                        fetching: true,
                    };
                case this.actions.updateSuccess:
                    return {
                        ...state,
                        fetching: false,
                        current: data,
                    };
                case this.actions.updateFailure:
                    return {
                        ...state,
                        fetching: false,
                        current: {
                            ...state.current,
                            error: data,
                        },
                    };
                case this.actions.createRequest:
                    return {
                        ...state,
                        fetching: true,
                    };
                case this.actions.createSuccess:
                    return {
                        ...state,
                        fetching: false,
                        current: data,
                    };
                case this.actions.createFailure:
                    return {
                        ...state,
                        fetching: false,
                        current: {
                            error: data,
                        },
                    };
                case this.actions.firstTime:
                    return {
                        ...state,
                        firstTime: false,
                    };
                case this.actions.autoConfirmation:
                    return {
                        ...state,
                        current: {
                            bookingAutoConfirmation: data,
                        },
                    };
                case this.actions.onSendingEmail:
                    return {
                        ...state,
                        onSendingEmail: data,
                    };
                default:
                    return state;
            }
        };
    }

    clearCurrentRecord() {
        return (dispatch) => {
            dispatch({ type: this.actions.clearCurrentRecord });
        };
    }

    clearCurrentList() {
        return (dispatch) => {
            dispatch({ type: this.actions.clearCurrentList });
        };
    }

    listRecords(
        query,
        {
            tokens = {},
            errorCallback = () => undefined,
            successCallback = () => undefined,
        } = {},
    ) {
        return (dispatch) => {
            dispatch({
                type: this.actions.listRequest,
                data: query,
            });
            const preprocessedRequestPath = this.preprocessRequestPath(
                this.path,
                tokens,
            );

            if (query && query.serializedSearchQuery) {
                query = {
                    ...query,
                    serializedSearchQuery: coreUtils.searchUtils.serializeQuery(
                        query.serializedSearchQuery,
                    ),
                };
            }
            return get(preprocessedRequestPath, query)
                .then(this.preprocessResponse)
                .then((response) => {
                    dispatch({
                        type: this.actions.listSuccess,
                        data: response,
                    });
                    successCallback(response);
                })
                .catch((error) => {
                    if (error && error.message) {
                        error = error.message;
                    }
                    dispatch({
                        type: this.actions.listFailure,
                        data: error,
                    });
                    errorCallback(error);
                });
        };
    }

    getRecord(
        recordId = ``,
        query,
        {
            tokens = {},
            errorCallback = () => undefined,
            successCallback = () => undefined,
        } = {},
    ) {
        return (dispatch) => {
            dispatch({
                type: this.actions.getRequest,
                data: {
                    recordId,
                    query,
                },
            });
            const preprocessedRequestPath = this.preprocessRequestPath(
                this.path,
                tokens,
            );
            return get(`${preprocessedRequestPath}/${recordId}`, query)
                .then(this.preprocessResponse)
                .then((response) => {
                    dispatch({
                        type: this.actions.getSuccess,
                        data: response,
                    });
                    successCallback(response);
                })
                .catch((error) => {
                    if (error && error.message) {
                        error = error.message;
                    }
                    dispatch({
                        type: this.actions.getFailure,
                        data: error,
                    });
                    errorCallback(error);
                });
        };
    }

    createRecord(
        recordData,
        {
            tokens = {},
            errorCallback = () => undefined,
            successCallback = () => undefined,
        } = {},
    ) {
        return (dispatch) => {
            dispatch({
                type: this.actions.createRequest,
                data: recordData,
            });
            const preprocessedRequestPath = this.preprocessRequestPath(
                this.path,
                tokens,
            );
            return post(preprocessedRequestPath, recordData)
                .then(this.preprocessResponse)
                .then((response) => {
                    dispatch({
                        type: this.actions.createSuccess,
                        data: response,
                    });
                    successCallback(response);
                })
                .catch((error) => {
                    if (error && error.message) {
                        error = error.message;
                    }
                    dispatch({
                        type: this.actions.createFailure,
                        data: error,
                    });
                    errorCallback(error);
                });
        };
    }

    updateRecord(
        recordId = ``,
        recordData,
        {
            tokens = {},
            errorCallback = undefined,
            successCallback = () => undefined,
            qsParams = {},
        } = {},
    ) {
        return async (dispatch) => {
            dispatch({
                type: this.actions.updateRequest,
                data: {
                    recordId,
                    recordData,
                },
            });
            const preprocessedRequestPath = this.preprocessRequestPath(
                this.path,
                tokens,
            );
            try {
                const response = await post(
                    `${preprocessedRequestPath}/${recordId}`,
                    recordData,
                    qsParams,
                );
                const data = this.preprocessResponse(response);
                dispatch({
                    type: this.actions.updateSuccess,
                    data: data,
                });
                successCallback && successCallback(data);
                return data;
            } catch (error) {
                dispatch({
                    type: this.actions.updateFailure,
                    data: error.message,
                });
                errorCallback && errorCallback(error.message);
                if (!errorCallback) throw error;
            }
        };
    }

    searchRecords(
        query,
        {
            tokens = {},
            errorCallback = () => undefined,
            successCallback = () => undefined,
        } = {},
    ) {
        return (dispatch) => {
            dispatch({
                type: this.actions.searchRequest,
                data: {
                    query,
                },
            });
            const preprocessedRequestPath = this.preprocessRequestPath(
                this.path,
                tokens,
            );
            return get(`${preprocessedRequestPath}/`, query)
                .then(this.preprocessResponse)
                .then((response) => {
                    dispatch({
                        type: this.actions.searchSuccess,
                        data: response,
                    });
                    successCallback(response);
                })
                .catch((error) => {
                    if (error && error.message) {
                        error = error.message;
                    }
                    dispatch({
                        type: this.actions.searchFailure,
                        data: error,
                    });
                    errorCallback(error);
                });
        };
    }

    clearFirstTime() {
        return (dispatch) => {
            dispatch({ type: this.actions.firstTime });
        };
    }

    setAutoConfirmation(autoConfirmationEnabled) {
        return (dispatch) => {
            dispatch({
                type: this.actions.autoConfirmation,
                data: autoConfirmationEnabled,
            });
        };
    }

    onSendingEmail(sending) {
        return (dispatch) => {
            dispatch({
                type: this.actions.onSendingEmail,
                data: sending,
            });
        };
    }

    mapDispatchToProps = (dispatch) => {
        const self = this;
        return {
            listRecords(query, options, type) {
                dispatch(self.listRecords(query, options, type));
            },
            getRecord(recordId, query, options) {
                dispatch(self.getRecord(recordId, query, options));
            },
            createRecord(data, options) {
                dispatch(self.createRecord(data, options));
            },
            updateRecord(recordId, data, options) {
                return dispatch(self.updateRecord(recordId, data, options));
            },
            clearCurrentRecord() {
                dispatch(self.clearCurrentRecord());
            },
            clearCurrentList() {
                dispatch(self.clearCurrentList());
            },
            clearFirstTime() {
                dispatch(self.clearFirstTime());
            },
            setAutoConfirmation() {
                dispatch(self.setAutoConfirmation());
            },
            onSendingEmail(data) {
                dispatch(self.onSendingEmail(data));
            },
        };
    };

    useDispatch = () => {
        const dispatch = useDispatch();
        return useMemo(() => this.mapDispatchToProps(dispatch), [dispatch]);
    };
}
export default FetchState;
