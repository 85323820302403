import useIsLargeScreen from '../../../../common/hooks/useIsLargeScreen';
import { BaseBox, Stack } from '@trova-trip/trova-components';
import { Card } from '@trova-trip/trova-components/build/next';
import CatalogMobileBanner, {
    CatalogMobileBannerProps,
} from './CatalogMobileBanner';

interface CatalogHeaderBannerProps {
    children: React.ReactNode;
    mobileBannerProps?: Omit<CatalogMobileBannerProps, 'children'>;
}

/**
 * CatalogBanner is a component that will rendera banner on the top of the itineraries catalog container,
 * and will be setted as sticky banner on mobile in order to add any content we want
 */
const CatalogHeaderBanner = ({
    children,
    mobileBannerProps,
}: CatalogHeaderBannerProps) => {
    const isLargeScreen = useIsLargeScreen({
        includeTabletResolution: true,
    });

    return isLargeScreen ? (
        <Stack width='full' justify='center'>
            <Card spacing='lg' width='full' maxWidth='container.xl'>
                {children}
            </Card>
        </Stack>
    ) : (
        <CatalogMobileBanner {...mobileBannerProps}>
            <BaseBox paddingX={3} paddingY={4}>
                {children}
            </BaseBox>
        </CatalogMobileBanner>
    );
};

export default CatalogHeaderBanner;
