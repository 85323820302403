import { theme } from '@trova-trip/trova-components';
import { uniq } from 'lodash';
import { applicationRootPath } from '../../../../../../navigation/NavigationContext';
import {
    HOST_APP_PRODUCT_ROUTE,
    HOST_APP_ROUTE,
    HOST_HOME_ROUTES,
} from '../../../../../common/constants/routesTokens';
import { HostImportantDates } from '../types';

type DateColorsById = Record<string, string>;

const colors = [
    theme.colors.orange[800],
    theme.colors.teal[700],
    theme.colors.red[700],
    theme.colors.seaGreen[600],
    theme.colors.blue[600],
    theme.colors.seaGreen['trova'],
    theme.colors.purple[500],
];

/**
 * constructs a mapping between date tripIds and a color, in order to assign a unique color to each tripId
 * @param {HostImportantDates} importantDates
 * @returns an object with an tripId and a color as key-value pairs
 */
const getDateColorsById = (
    importantDates: HostImportantDates,
): DateColorsById => {
    const datesId = importantDates.map((date) => date.tripId);
    const dedupedDatesId = uniq(datesId);
    const idToColorMap: DateColorsById = dedupedDatesId.reduce(
        (acc, id, index) => {
            return { ...acc, [id]: colors[index] };
        },
        {},
    );
    return idToColorMap;
};

const buildHostProductRoute = (
    product: keyof typeof HOST_APP_PRODUCT_ROUTE,
): string => {
    const productRoute = HOST_APP_PRODUCT_ROUTE[product];
    return `${applicationRootPath}${HOST_APP_ROUTE}${productRoute}`;
};

export const buildHomeRoute = (
    type: keyof typeof HOST_HOME_ROUTES,
    includeRoot: boolean = false,
) =>
    `${
        includeRoot
            ? buildHostProductRoute('HOME')
            : HOST_APP_PRODUCT_ROUTE.HOME
    }${HOST_HOME_ROUTES[type]}`;

export { getDateColorsById, buildHostProductRoute };
