import { coreUtils } from '@trova-trip/trova-common';
import SocialInput from './SocialInput';
import { socialArray } from './SocialMediaAccounts.constants';
import {
    SocialKeys,
    SocialMediaAccountsProps,
} from './SocialMediaAccounts.types';

const { sanitizeSocialHandle } = coreUtils.hostUtils;

const SocialMediaAccounts = (props: SocialMediaAccountsProps): JSX.Element => {
    const { socials, onUpdateSocials, errors } = props;
    const onChangeSocialInput = (channel: SocialKeys, value: string) => {
        onUpdateSocials({
            ...socials,
            [channel]: sanitizeSocialHandle(value).replace(/\s+/g, ''),
        });
    };

    return (
        <>
            {socialArray.map((channel: SocialKeys) => (
                <SocialInput
                    key={channel}
                    type={channel}
                    name={`social-${channel}`}
                    value={socials[channel] || ''}
                    error={errors && errors[channel]}
                    onChange={(e) => {
                        onChangeSocialInput(
                            channel,
                            (e.target as HTMLInputElement).value,
                        );
                    }}
                />
            ))}
        </>
    );
};

export default SocialMediaAccounts;
