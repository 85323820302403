import React, { MouseEvent } from 'react';
import { constants } from '@trova-trip/trova-models';
import { Stack, BaseBox } from '@trova-trip/trova-components';
import { Button } from '@trova-trip/trova-components/build/next';
import { ServiceTypesLabels } from '../../../../../../config/constants';
import { ServiceFormPermissions } from '../../helpers';

type ServiceType = constants.services.ServiceType;

interface ActionButtonsProps {
    serviceType: ServiceType;
    closeForm: (e: MouseEvent<HTMLButtonElement>) => void;
    submitForm: (e: MouseEvent<HTMLButtonElement>) => void;
    onRemoveAction: (e: MouseEvent<HTMLButtonElement>) => void;
    isNewEntity: boolean;
    marginTop?: number;
    permissions?: ServiceFormPermissions;
}

const ActionButtons = ({
    serviceType,
    closeForm,
    submitForm,
    onRemoveAction,
    isNewEntity,
    marginTop = 4,
    permissions,
}: ActionButtonsProps) => {
    const { canSave = true, canRemove = true } = permissions || {};
    return (
        <>
            <Stack spacing={3} marginTop={marginTop}>
                <Button
                    variant='primary'
                    onClick={submitForm}
                    isDisabled={!canSave}
                >
                    Save {ServiceTypesLabels[serviceType]}
                </Button>
                <Button variant='secondary' onClick={closeForm}>
                    Cancel
                </Button>
            </Stack>
            {canRemove && !isNewEntity ? (
                <BaseBox marginTop={4}>
                    <Button
                        variant='tertiary'
                        color='neutral.60'
                        size='sm'
                        onClick={onRemoveAction}
                    >
                        Remove {ServiceTypesLabels[serviceType]}
                    </Button>
                </BaseBox>
            ) : null}
        </>
    );
};

export default ActionButtons;
