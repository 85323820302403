import { coreUtils } from '@trova-trip/trova-common';
import { models } from '@trova-trip/trova-models';
import { TripStatuses } from '../../../../config/constants';

type Itinerary = models.itineraries.Itinerary;

export const createFormattedTrip = (itinerary: Itinerary, data, formData) => {
    const {
        id: itineraryId,
        cities,
        logistics,
        inclusions,
        exclusions,
        singleSupplement,
        costThresholds,
        maximumSpots,
        minimumSpots,
        servicesByDay,
        packages,
    } = itinerary;

    const {
        id: tripRequestId,
        host,
        quantityHostRooms,
        hostSelectedOptionalServices,
        selectedWorkshopSpaces,
        startDate,
        companions,
    } = data;

    const {
        country,
        tripSummary,
        destination,
        operatorCode,
        travelerInsuranceRequired,
        arrivalLocation,
        departureLocation,
    } = formData;

    const name = coreUtils.tripUtils.generateTripName(host, country);

    const formattedData = {
        itinerary: itineraryId,
        tripRequestId,
        country,
        destination,
        tripSummary,
        name,
        operatorCode,
        accountManager: data.host.accountManager,
        status: TripStatuses.CREATED,
        emailCampaigns: [],
        packages,
        quantityHostRooms,
        hostSelectedOptionalServices,
        selectedWorkshopSpaces,
        itineraryData: {
            cities,
            logistics,
            inclusions,
            exclusions,
            servicesByDay,
            costThresholds,
            singleSupplement,
            host: [data.host._id],
            maximumSpots,
            minimumSpots,
            travelerInsuranceRequired,
            arrivalLocation,
            departureLocation,
        },
        startDate,
        companions,
    };
    return formattedData;
};
