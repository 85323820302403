import { models } from '@trova-trip/trova-models';
import { useTrackingEvent } from 'analytics/hooks';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Snackbar from '../../../../components/Snackbar/Snackbar';
import { ItineraryStoreSelector } from '../../../../state/itineraries';
import { userTripRequests } from '../../../../state/userTripRequests';
import { getAdminRecommendationTrackingData } from '../../../host/utils/trackingEvents.utils';
import useHostRecommendations from '../../hooks/useHostRecommendations';
import TripRequestSuccessDialog, {
    TripRequestSuccessDialogProps,
} from '../../products/itinerariesCatalog/components/TripRequestSuccessDialog';
import EmptyRequestForm from './EmptyRequestForm';
import TripRequestForm from './TripRequestForm';
import { InitialFormData } from './TripRequestForm.types';
import { getInitialTripRequestDataFromItinerary } from './tripRequestFormHelper';
import useGetItineraryAvailability from './useGetItineraryAvailability';
import useGetItineraryOverallRating from './useGetItineraryOverallRating';
import useModelStatusMatcher from '../../../common/hooks/useModelStatusMatcher';
import { StatefulModel } from '../../../types';
import { UserStatuses } from '../../../../config/constants';
import useHasAccountManager from '../../../host/hooks/useHasAccountManager';

type SuggestedSellPrice = models.itineraries.SuggestedSellPrice;

interface CreateRequestFormProps {
    onChangePrice: (price: SuggestedSellPrice, fetching?: boolean) => void;
    travelersTierNumber: number;
    refreshSidebarContent: () => void;
}

const limitedAccessStatuses = new Set([
    UserStatuses.CREATED,
    UserStatuses.READY_TO_QUALIFY,
    UserStatuses.QUALIFYING,
]);

const CreateRequestForm = (
    props: CreateRequestFormProps,
): JSX.Element | null => {
    const { onChangePrice, travelersTierNumber, refreshSidebarContent } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dialogTripRequest, setDialogTripRequest] =
        useState<TripRequestSuccessDialogProps['tripRequest']>();

    const [snackBar, setSnackBar] = useState({
        message: '',
        color: 'info',
        show: false,
    });

    const {
        userItinerary: { current: currentItinerary },
    } = useSelector((state: ItineraryStoreSelector) => state);

    const hasAccountManager = useHasAccountManager();

    const isLimitedStatus = useModelStatusMatcher({
        model: StatefulModel.PROFILE,
        matchingStatuses: limitedAccessStatuses,
    });

    // TODO add more conditions based on host tier
    const showAdditionalRequests = hasAccountManager && !isLimitedStatus;

    const { createRecord: createTripRequest } =
        userTripRequests.useDispatch.bind(currentItinerary)();

    const itineraryAvailability = useGetItineraryAvailability(
        currentItinerary,
        currentItinerary?.itineraryInventoryEnabled,
    );
    const itineraryOverallRating =
        useGetItineraryOverallRating(currentItinerary);

    const { trackUserEvent } = useTrackingEvent();
    const { recommendations, getItineraryRecommendation } =
        useHostRecommendations();

    const handleFormSubmit = (formData: InitialFormData): void => {
        const updatedData = { ...formData, itinerary: currentItinerary.id };
        setIsLoading(true);
        createTripRequest(updatedData, {
            successCallback: (response): void => {
                setIsLoading(false);
                if (response.error) {
                    return setSnackBar({
                        message: response.error,
                        color: 'danger',
                        show: true,
                    });
                }

                const adminRecommendationTrackingData =
                    getAdminRecommendationTrackingData(
                        recommendations,
                        getItineraryRecommendation(currentItinerary?.id || ''),
                    );

                const itineraryTrackingData: Record<string, unknown> = {
                    itineraryId: currentItinerary?.id,
                    ...adminRecommendationTrackingData,
                };

                if (itineraryOverallRating) {
                    itineraryTrackingData.overallReviewScore =
                        itineraryOverallRating;
                }

                trackUserEvent({
                    eventName: 'Host Requested Trip',
                    properties: {
                        tripRequestId: response?.id,
                        ...itineraryTrackingData,
                    },
                });

                if (response?.instantlyApproved) {
                    trackUserEvent({
                        eventName: 'Host Requested Instant Approval Itinerary',
                        properties: {
                            itineraryCategory: currentItinerary.categories,
                            ...itineraryTrackingData,
                        },
                    });
                }

                try {
                    setDialogTripRequest(response);
                    refreshSidebarContent();
                } catch (e) {
                    console.error(e);
                }
            },
            errorCallback: (error: string): void => {
                setIsLoading(false);
                setSnackBar({
                    message:
                        'There was an error creating the trip request. Please try again.',
                    color: 'danger',
                    show: true,
                });
                console.error(
                    '@Trova: Error creating the trip request.',
                    error,
                );
            },
        });
    };

    if (!currentItinerary || !itineraryAvailability) {
        return null;
    }

    const isTripRequestAvailable = !isEmpty(itineraryAvailability);

    const initialTripRequestData = getInitialTripRequestDataFromItinerary(
        currentItinerary,
        itineraryAvailability,
    );

    return (
        <>
            {isTripRequestAvailable ? (
                <TripRequestForm
                    onChangePrice={onChangePrice}
                    travelersTierNumber={travelersTierNumber}
                    initialTripRequestData={initialTripRequestData}
                    itineraryAvailability={itineraryAvailability}
                    onSubmit={handleFormSubmit}
                    showDialogBeforeSubmit
                    editMode
                    showAdditionalRequests={showAdditionalRequests}
                    isSubmitting={isLoading}
                />
            ) : (
                <EmptyRequestForm />
            )}
            <Snackbar
                place='tr'
                color={snackBar.color}
                message={snackBar.message}
                open={snackBar.show}
                autoHideDuration={4000}
                onClose={(): void => {
                    setSnackBar({
                        message: '',
                        color: 'info',
                        show: false,
                    });
                }}
            />
            <TripRequestSuccessDialog
                isOpen={!!dialogTripRequest}
                tripRequest={dialogTripRequest}
            />
        </>
    );
};

export default CreateRequestForm;
