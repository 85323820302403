import { useSelector } from '../../../state/hooks/useSelector';
import {
    InsuranceDefaultPayload,
    trackInsuranceFileAClaimEvent,
    trackInsurancePolicyViewedEvent,
} from '../../common/analytics/traveler';

const useTravelerAnalytics = () => {
    const trip = useSelector((state) => state.userTrips.current);

    const defaultPayload: InsuranceDefaultPayload = {
        tripId: trip?.id || '',
        //@ts-expect-error - The Trip type doesn't have the booking on its signature.
        bookingId: trip?.booking._id,
    };

    return {
        trackInsuranceFileAClaimEvent: () => {
            trackInsuranceFileAClaimEvent(defaultPayload);
        },
        trackInsurancePolicyViewedEvent: () => {
            trackInsurancePolicyViewedEvent(defaultPayload);
        },
    };
};

export default useTravelerAnalytics;
