import React from 'react';
import PropTypes from 'prop-types';
import { theme, DraggableCard } from '@trova-trip/trova-components';
import { StyledCard } from './MainContainer.components';

const Card = (props) => {
    const extendedProps = {
        backgroundColor: theme.colors.neutral.white,
        ...props,
    };
    return props.draggableContainer ? (
        <DraggableCard {...extendedProps} />
    ) : (
        <StyledCard {...extendedProps} />
    );
};

Card.propTypes = {
    props: PropTypes.shape({
        key: PropTypes.string,
        draggableContainer: PropTypes.bool,
        onCollect: PropTypes.func,
        backgroundColor: PropTypes.string,
        dropTargetConfiguration: PropTypes.shape({
            accept: PropTypes.string,
            drop: PropTypes.func,
        }),
        dragTargetConfiguration: PropTypes.shape({
            item: PropTypes.shape({
                type: PropTypes.string,
                service: PropTypes.shape({
                    orderOrigin: PropTypes.number,
                    dayOrigin: PropTypes.number,
                }),
            }),
        }),
    }),
};

export default Card;
