import { Heading } from '@trova-trip/trova-components';
import { useSelector } from 'react-redux';
import StickyContentLayout from '../../../../../components/StickyContentLayout/StickyContentLayout';
import { TripStoreSelector } from '../../../../../state/trips';
import ShowDuringTimeRange from '../../../../common/components/ShowDuringTimeRange';
import TripContactCard, {
    TripContactCardType,
} from '../../../../common/components/TripContactCard';
import { getTripRelatedDates } from '../../../../common/helpers';

type ItineraryStickyLayoutProps = {
    children: React.ReactNode;
};

const ItineraryStickyLayout: React.FC<ItineraryStickyLayoutProps> = ({
    children,
}: ItineraryStickyLayoutProps): JSX.Element => {
    const currentTrip = useSelector(
        (state: TripStoreSelector) => state.userTrips.current,
    );

    const { preTripDate, postTripDate } = getTripRelatedDates(
        currentTrip?.startDate ? new Date(currentTrip?.startDate) : undefined,
        currentTrip?.servicesByDay?.length,
    );
    const hasPreAndPostTripDates = !!preTripDate && !!postTripDate;

    return (
        <StickyContentLayout
            stickyContent={
                <>
                    <Heading as='h5' marginBottom={4}>
                        How can we help?
                    </Heading>
                    <TripContactCard
                        type={TripContactCardType.CUSTOMER_SERVICE}
                    />
                    {hasPreAndPostTripDates ? (
                        <ShowDuringTimeRange
                            start={preTripDate}
                            end={postTripDate}
                        >
                            <TripContactCard
                                type={TripContactCardType.TRIP_SUPPORT}
                            />
                        </ShowDuringTimeRange>
                    ) : null}
                </>
            }
            mainContent={
                <StickyContentLayout observableContent>
                    {children}
                </StickyContentLayout>
            }
        />
    );
};

export default ItineraryStickyLayout;
