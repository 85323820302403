import { coreUtils } from '@trova-trip/trova-common';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import isValid from 'date-fns/isValid';

const { formatDateTime } = coreUtils.dateUtils;

/**
 * Formats a string date with the provided timezone and format.
 *
 * @param stringDate - It expected the standard ISO 8601 format - Example: 2022-04-04T17:07:50.540+00:00
 * @param timezone
 * @param format - Allowed formats: https://date-fns.org/v3.3.1/docs/format
 * @returns string
 */
const formatStringDate = (
    stringDate?: string,
    timezone: string = 'UTC',
    format: string = 'MM/dd/yyyy',
): string => {
    if (!stringDate) return '';

    return formatInTimeZone(stringDate, timezone, format);
};

/**
 * Formats a date into a string with the specified format.
 *
 * @param date
 * @param format
 * @param timeZone
 * @returns string
 */

const formatDateToString = (
    date: Date,
    format: string = 'MMM dd',
    timeZone?: string,
): string => {
    if (!isValid(date)) {
        return '';
    }
    return formatDateTime(date, format, timeZone);
};

export { formatStringDate, formatDateToString };
