import { Alert } from '@trova-trip/trova-components';
import noop from 'lodash/noop';
import { useSelector } from '../../../../../state/hooks';
import { hasDifferentTransferDatesFromTrip } from '../../../../common/helpers';
import { useManageBooking } from '../hooks';
import { MANAGE_BOOKING_ADDON_STATUS, ServiceTiming } from '../types';
import { checkAccommodationStatus } from '../utils/check.utils';

const DifferentTransferDatesFormTripAlert = (): JSX.Element | null => {
    const {
        state: {
            addOns: { accommodations },
        },
    } = useManageBooking();

    const { trip, userGroundTransfers } = useSelector((state) => ({
        trip: state.userTrips?.current,
        userGroundTransfers: state.userGroundTransfers.list,
    }));

    const isPreAccommodationPendingRemoval = checkAccommodationStatus(
        MANAGE_BOOKING_ADDON_STATUS.PENDING_REMOVAL,
        accommodations[ServiceTiming.PRE_TRIP],
    );
    const isPostAccommodationPendingRemoval = checkAccommodationStatus(
        MANAGE_BOOKING_ADDON_STATUS.PENDING_REMOVAL,
        accommodations[ServiceTiming.POST_TRIP],
    );

    const shouldShowAlert = hasDifferentTransferDatesFromTrip({
        trip,
        userGroundTransfers,
        shouldCheckPreTripDate: !isPreAccommodationPendingRemoval,
        shouldCheckPostTripDate: !isPostAccommodationPendingRemoval,
    });

    if (!shouldShowAlert) return null;

    return (
        <Alert
            marginTop={6}
            status='warning'
            variant='inline'
            title=''
            description='Your changes will affect your currently booked transfer, please update your transfer to avoid any delays.'
            onCloseAlert={noop}
        />
    );
};

export default DifferentTransferDatesFormTripAlert;
