import { useEffect } from 'react';
import { useSelector } from '../../../../../state/hooks';
import { categories } from '../../../../../state/categories';
import { models } from '@trova-trip/trova-models';

type Category = models.categories.Category;

interface UseCategoryInterestsReturn {
    isLoading: boolean;
    categoryInterests: Category[];
}

const useCategoryInterests = (): UseCategoryInterestsReturn => {
    const {
        categories: { fetching, list: categoryList },
    } = useSelector((state) => state);
    const { listRecords: getAllHostInterests } = categories.useDispatch();

    useEffect(() => {
        if (!categoryList) getAllHostInterests();
    }, [categoryList, getAllHostInterests]);

    return {
        isLoading: fetching,
        categoryInterests: categoryList?.records || [],
    };
};

export { useCategoryInterests };
