import { Prompt } from 'react-router-dom';

interface LeaveConfirmationPromptProps {
    message?: string;

    /**
     * When `true`, the `Prompt` will be active and will prevent navigation when there are
     * unsaved changes. When `false`, the `Prompt` will be inactive. Defaults to true if not provided.
     */
    when?: boolean;

    /**
     * An array of paths for which the confirm dialog should be skipped when navigating.
     * If the current route matches any of these paths, the confirmation prompt will be bypassed.
     * If not provided, the confirmation prompt will be shown for all routes.
     */
    bypassConfirmPaths?: string[];
}

const LeaveConfirmationPrompt = ({
    message = 'Changes you made may not be saved',
    when = true,
    bypassConfirmPaths = [],
}: LeaveConfirmationPromptProps) => {
    const handleMessage = (location) => {
        const shouldBypassConfirmation = bypassConfirmPaths.find((route) =>
            location.pathname.includes(route),
        );

        return shouldBypassConfirmation ? true : message;
    };

    if (!when) {
        return null;
    }

    return <Prompt message={handleMessage} />;
};

export default LeaveConfirmationPrompt;
