import { Badge, BadgeProps, MenuItem } from '@trova-trip/trova-components';

export type ProfileNavigationDrawerItemType = {
    onItemClicked: () => void;
    isVisible?: boolean;
    title: string;
    badgeConfig?: BadgeProps;
};

interface ProfileNavigationDrawerProps {
    item: ProfileNavigationDrawerItemType;
}

const ProfileNavigationDrawerItem = ({
    item: { title, onItemClicked, isVisible = true, badgeConfig },
}: ProfileNavigationDrawerProps): JSX.Element | null => {
    if (!isVisible) return null;

    const rightAddon = badgeConfig ? (
        <Badge variant='rounded' {...badgeConfig} />
    ) : undefined;

    return (
        <MenuItem
            paddingY={3}
            paddingX={6}
            onClick={onItemClicked}
            title={title}
            divider={false}
            textProps={{
                size: 'lg',
            }}
            rightAddon={rightAddon}
        />
    );
};

export default ProfileNavigationDrawerItem;
