import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from '@trova-trip/trova-components';
import TabDetailsInformation from 'components/TabDetailsInformation/TabDetailsInformation';

export const stickyContainer = () => css`
    position: -webkit-sticky;
    position: sticky;
    top: 0;
`;

export const scrollContainer = () => css`
    position: -webkit-sticky;
    position: sticky;
    top: 0.5rem;
    display: flex;
    -webkit-box-orient: vertical;
    flex-direction: column;
    height: 100vh;
`;

export const scrollContainerInner = () => css`
    overflow-y: scroll;
    min-height: 0;
    -webkit-box-flex: 1;
    flex: 1;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const PaddedColumn = styled.div`
    padding-bottom: calc(100vh - 172px);

    @media (max-width: ${theme.breakpoints.lg}) {
        padding-bottom: 0;
    }
`;

export const StyledTabDetailsInformation = styled(TabDetailsInformation)(`
    flex: 1;
    h2 {
        @media (max-width: ${theme.breakpoints.sm}) {
            padding: .5rem 0 0 0;
        }
    }
`);
