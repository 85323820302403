import moment from 'moment';

const groupNames = {
    admin: `admin`,
    host: `host`,
    tripManager: `tripManager`,
    accountManager: `accountManager`,
    operator: `operator`,
    traveler: `traveler`,
    basic: `basic`,
};
class ProfileReader {
    static create(profile) {
        return new ProfileReader(profile);
    }

    constructor(profile) {
        this.profile = profile;
    }

    _isGroupMatch(target) {
        return this.profile.group === target;
    }

    isAdmin() {
        return this._isGroupMatch(groupNames.admin);
    }

    isHost() {
        return this._isGroupMatch(groupNames.host);
    }

    isOperator() {
        return this._isGroupMatch(groupNames.operator);
    }

    isTraveler() {
        return this._isGroupMatch(groupNames.traveler);
    }

    isImpersonating() {
        return this.profile.impersonate;
    }

    needChangePassword() {
        return (
            moment(this.profile.expires).diff(moment(), `days`) <= 0 &&
            !this.isImpersonating()
        );
    }
}

export default ProfileReader;
