import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Cell,
    ComponentWidth,
    Checkbox,
    MultipleDropdown,
    Currency,
    IconButton,
} from '@trova-trip/trova-components';

import moment from 'moment';

import { getDropdownOptionsByKey } from '../../../../../../util/form/dropdown';
import { StyledDatePicker } from './ValidityPeriodRow.components';
import useCurrencyCode from '../../../../../common/hooks/currencies/useCurrencyCode';

const ValidityPeriodRow = ({
    index,
    validityPeriod,
    deleteRow,
    updateHandler,
    updateDaysOfTheWeekHandler,
    updateDateTimeHandler,
    hideDeleteButton,
    currentCurrency,
    disableInstantApproval,
}) => {
    const currencyCode = useCurrencyCode(currentCurrency);
    return (
        <Row>
            <Cell>
                <StyledDatePicker
                    size={ComponentWidth.Small}
                    value={moment(validityPeriod.startDate).utc()}
                    onChange={(event) =>
                        updateDateTimeHandler(
                            event,
                            event,
                            `[${index}].startDate`,
                        )
                    }
                    placeholder='Select start date'
                />
            </Cell>
            <Cell>
                <StyledDatePicker
                    name={`[${index}].endDate`}
                    size={ComponentWidth.Small}
                    value={moment(validityPeriod.endDate).utc()}
                    onChange={(event) =>
                        updateDateTimeHandler(
                            event,
                            event,
                            `[${index}].endDate`,
                        )
                    }
                    placeholder='Select end date'
                />
            </Cell>
            <Cell>
                <Checkbox
                    name={`[${index}].blackoutStartDate.isBlackout`}
                    value={
                        validityPeriod.blackoutStartDate &&
                        validityPeriod.blackoutStartDate.isBlackout
                    }
                    onChange={updateHandler}
                />
            </Cell>
            <Cell>
                <Checkbox
                    name={`[${index}].instantApprovalAllowed`}
                    value={validityPeriod.instantApprovalAllowed}
                    onChange={updateHandler}
                    disabled={disableInstantApproval}
                />
            </Cell>
            <Cell>
                <MultipleDropdown
                    name={index}
                    size={ComponentWidth.ExtraSmall}
                    value={
                        (validityPeriod.costThresholds &&
                            validityPeriod.costThresholds[0] &&
                            validityPeriod.costThresholds[0].daysOfTheWeek.map(
                                (day) => day.toString(),
                            )) ||
                        []
                    }
                    onChange={updateDaysOfTheWeekHandler}
                >
                    {getDropdownOptionsByKey(`DaysOfTheWeek`)}
                </MultipleDropdown>
            </Cell>
            <Cell>
                <Currency
                    name={`[${index}].additionalCosts.singleSupplement.price`}
                    size={ComponentWidth.ExtraSmall}
                    value={
                        validityPeriod.additionalCosts.singleSupplement.price
                    }
                    currencyType={currencyCode}
                    onChange={updateHandler}
                />
            </Cell>
            {validityPeriod.costThresholds.map(({ price }, thresholdIndex) => (
                <Fragment key={thresholdIndex}>
                    <Cell>
                        <Currency
                            name={`[${index}].costThresholds.[${thresholdIndex}].price`}
                            size={ComponentWidth.ExtraSmall}
                            value={price}
                            currencyType={currencyCode}
                            onChange={updateHandler}
                        />
                    </Cell>
                </Fragment>
            ))}
            <Cell>
                {!hideDeleteButton && (
                    <IconButton
                        icon='trash'
                        iconColor='red.coral'
                        iconVariant='filled'
                        variant='outlined'
                        onClick={() => deleteRow(index)}
                    />
                )}
            </Cell>
        </Row>
    );
};

ValidityPeriodRow.propTypes = {
    index: PropTypes.number,
    id: PropTypes.number,
    validityPeriod: PropTypes.object,
    disableInstantApproval: PropTypes.bool,
    updateValidityPeriods: PropTypes.func,
    deleteRow: PropTypes.func,
    updateHandler: PropTypes.func,
    updateDaysOfTheWeekHandler: PropTypes.func,
    updateDateTimeHandler: PropTypes.func,
    hideDeleteButton: PropTypes.bool,
    currentCurrency: PropTypes.string,
};

export default ValidityPeriodRow;
