import { Grid } from '@material-ui/core';
import { constants, models } from '@trova-trip/trova-models';
import {
    getMessageFromTrip,
    getMessageFromTripWithBooking,
} from 'applications/traveler/utils';
import TabDetailsInformation from 'components/TabDetailsInformation/TabDetailsInformation';
import { useEffect } from 'react';
import Tab from '../../../../navigation/Tab';
import { useSelector } from '../../../../state/hooks/useSelector';
import { userTripDetails } from '../../../../state/userTrips';
import TabPageDescription from '../../../common/components/TabPageDescription';
import { ROUTE_TOKENS } from '../../../common/constants';
import {
    isPrimaryHost,
    isPrimaryTraveler,
    isSecondaryHost,
    isTripComplete,
} from '../../../common/helpers';
import { UpcomingServicesCard } from '../../../common/products/transfer/components';
import BalanceCard from '../../components/TripCards/BalanceCard/BalanceCard';
import StatusCard from '../../components/TripCards/StatusCard/StatusCard';
import { StyledCardContainerGrid } from '../../components/TripCards/TripCards.components';
import WelcomeCard from '../../components/TripCards/WelcomeCard/WelcomeCard';

const { TRAVELER_APP_TAB_ROUTE } = ROUTE_TOKENS;
const { BookingStatuses } = constants.bookings;

const TAB_LABEL = 'Trip Overview';

type Booking = models.bookings.Booking;
type BookingCustomerProfile = models.bookings.BookingCustomerProfile;

interface TravelerDTO extends models.travelers.TravelerDTO {
    status: string;
}
export interface modelData extends models.trips.Trip {
    tripTravelerUser: TravelerDTO;
    booking: Booking;
}

interface TripOverviewProps {
    tripData: modelData;
    disabled?: boolean;
    profile: models.users.User;
}

interface ServicesCardProps {
    data: modelData;
}

const ServicesCard = ({ data }: ServicesCardProps) => {
    const { itinerary, user, booking } = useSelector((state) => ({
        itinerary: state.userItinerary.current,
        user: state.profile.current,
        booking: state.userBookings?.current?.[0],
    }));

    const isBookingConfirmed = booking?.status === BookingStatuses.CONFIRMED;

    if (!isBookingConfirmed || isTripComplete(data)) {
        return null;
    }

    const customer = booking.customer as BookingCustomerProfile;
    const props = {
        trip: data,
        itinerary,
        userCountryCode: customer.countryCode,
        booking,
        user: user,
    };

    if (!booking) {
        return null;
    }

    return <UpcomingServicesCard {...props} />;
};

const TripOverview = (props: TripOverviewProps): JSX.Element => {
    const { tripData, disabled = false, profile } = props;
    //TODO: update traveler 's Trip DTO model https://asanal.ink/21/1526
    const { booking, tripTravelerUser } = tripData;
    const { getRecord: getTripDetails } = userTripDetails.useDispatch();

    useEffect(() => {
        if (tripData.id) getTripDetails(tripData.id);
    }, [tripData.id, getTripDetails]);

    const waitlistedNumber = useSelector((state) => {
        const tripDetails = state.userTripDetails.current;
        return tripDetails && tripDetails.waitlistedNumber;
    });
    const modelData = { ...tripData, waitlistedNumber };

    const shouldRenderBalanceCard =
        //TODO: update travelerDTO model https://asanal.ink/21/1528
        //@ts-expect-error
        isPrimaryTraveler(tripTravelerUser.type) || //@ts-expect-error
        isPrimaryHost(tripTravelerUser.type);

    const isSecondaryHostTraveler: boolean = isSecondaryHost(
        //@ts-expect-error
        tripTravelerUser.type,
    );

    if (!booking && !isSecondaryHostTraveler) {
        return (
            <Tab
                label={TAB_LABEL}
                path={TRAVELER_APP_TAB_ROUTE.TRIP_OVERVIEW}
                disabled={true}
            />
        );
    }

    const messagePayload = isSecondaryHostTraveler
        ? getMessageFromTrip(modelData)
        : getMessageFromTripWithBooking(modelData);

    return (
        <Tab
            label={TAB_LABEL}
            path={TRAVELER_APP_TAB_ROUTE.TRIP_OVERVIEW}
            disabled={disabled}
        >
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <TabDetailsInformation title={TAB_LABEL} />
                    <TabPageDescription>
                        Review the status of your booking(s), remaining balance
                        due, and required tasks to complete before your trip.
                    </TabPageDescription>
                </Grid>
                <Grid item xs={12}>
                    <StyledCardContainerGrid>
                        <WelcomeCard
                            trip={modelData}
                            firstName={profile.firstName}
                            travelerStatus={tripTravelerUser.status}
                        />
                        <StatusCard statusPayload={messagePayload} />
                        {shouldRenderBalanceCard && (
                            // This error should be fixed using the
                            // correct types. We should stop using
                            // types from /src/interfaces/* and
                            // always use from Core.
                            <BalanceCard trip={modelData} />
                        )}
                    </StyledCardContainerGrid>
                    <ServicesCard data={tripData} />
                </Grid>
            </Grid>
        </Tab>
    );
};

export default TripOverview;
