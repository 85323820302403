import { coreUtils } from '@trova-trip/trova-common';
import { constants } from '@trova-trip/trova-models';
import moment from 'moment';
import { transformAddressLocationToFormDataValueType } from '../applications/common/helpers';
import headerImage from '../assets/img/trovatrip-arial-isaland-2.jpg';
import { modelTypes } from '../config/constants';
import getQueryParam from './getQueryParam';
import { getItineraryLength } from './navigationUtils';

const { getStartEndDates } = coreUtils.dateUtils;

const { PackageLevel } = constants.itinerary;

const setItineraryPackageDefaultData = () => {
    const sharedPackagesOptions = {
        accommodationLevels: [],
        mealLevels: [],
        foodOptions: [],
    };

    /**
     * DEFAULT ITINERARY PACKAGES DATA SET
     */
    const packagesSet = {};
    Object.keys(PackageLevel).forEach((elem) => {
        const packageType = elem.toLowerCase();

        packagesSet[packageType] = {
            enabled: PackageLevel[elem] === PackageLevel.STANDARD,
            ...sharedPackagesOptions,
        };
    });

    return packagesSet;
};

const itineraryPackages = setItineraryPackageDefaultData();

const getDataFormatted = (data, model) => {
    if (!data) {
        return {};
    }
    if (model === modelTypes.TRIP_REQUEST) {
        const { category, itinerary } = data;
        if (!itinerary) {
            return {};
        }
        const lengthInDays = itinerary.servicesByDay
            ? itinerary.servicesByDay.length
            : 0;
        return {
            name: itinerary.name,
            country: itinerary.country,
            destination: itinerary.destination,
            tripSummary: itinerary.tripSummary,
            categories: [category],
            lengthInDays,
            photos: itinerary.photos,
            travelerInsuranceRequired: itinerary.travelerInsuranceRequired,
            packages: itinerary?.packages,
            arrivalLocation: transformAddressLocationToFormDataValueType(
                itinerary?.arrivalLocation,
            ),
            departureLocation: transformAddressLocationToFormDataValueType(
                itinerary?.departureLocation,
            ),
        };
    }

    const {
        operatorCode,
        servicesByDay,
        name,
        country,
        destination,
        tripSummary,
        categories,
        id,
        photos,
        status,
        activityLevel,
        travelerInsuranceRequired,
        packages = itineraryPackages,
        arrivalLocation,
        departureLocation,
    } = data;

    return {
        operatorCode,
        name,
        country,
        destination,
        tripSummary,
        categories,
        lengthInDays: (servicesByDay && servicesByDay.length) || 0,
        servicesByDay,
        id,
        photos,
        status,
        activityLevel,
        travelerInsuranceRequired,
        packages,
        arrivalLocation:
            transformAddressLocationToFormDataValueType(arrivalLocation),
        departureLocation:
            transformAddressLocationToFormDataValueType(departureLocation),
    };
};

const getTripDatesIfApplicable = (startDate, servicesByDay, format = 'll') => {
    const length = servicesByDay ? servicesByDay.length : 0;
    if (startDate) {
        return Array(length)
            .fill()
            .map((_, index) =>
                moment(startDate).utc().add(index, `day`).format(format),
            );
    }

    return [];
};

const getTripDataFromModel = (modelData, history) => {
    const model = getQueryParam(history, `model`);

    let actualModelData = modelData;
    if (actualModelData && model === modelTypes.TRIP_REQUEST) {
        actualModelData = modelData.itinerary;
    }
    let tripHeaderPhoto = headerImage;
    const tripName = actualModelData
        ? actualModelData.name
        : 'Find your next adventure to host';
    const tripRequestGeneratedName =
        model === 'tripRequest' && modelData?.itinerary
            ? coreUtils.tripUtils.generateTripName(
                  modelData.host,
                  modelData.itinerary.country,
              )
            : null;
    const tripCountry = actualModelData
        ? actualModelData.country
        : 'An easy 4-step process';
    const tripDestination = actualModelData ? actualModelData.destination : '';
    const itineraryLength = getItineraryLength(modelData, model);
    const tripsDates = getStartEndDates(
        modelData?.startDate,
        itineraryLength,
        'll',
    );
    const tripActivityLevel =
        actualModelData && actualModelData.activityLevel
            ? `- Activity Level: ${actualModelData.activityLevel}`
            : '';

    const tripInsuranceRequired = actualModelData?.travelerInsuranceRequired;

    if (model === modelTypes.TRIP) {
        tripHeaderPhoto = actualModelData?.itinerary?.photos?.hero
            ? actualModelData.itinerary.photos.hero
            : headerImage;
    } else {
        tripHeaderPhoto = actualModelData?.photos?.hero
            ? actualModelData.photos.hero
            : headerImage;
    }

    return {
        tripHeaderPhoto,
        tripName,
        tripRequestGeneratedName,
        tripCountry,
        tripDestination,
        tripsDates,
        tripActivityLevel,
        tripInsuranceRequired,
    };
};

export { getDataFormatted, getTripDataFromModel, getTripDatesIfApplicable };
