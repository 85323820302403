import FetchState from './utils/FetchState';
import { get, post } from './utils/api';

const emails = FetchState.create(`api/admin/emails`);
const emailsMetadata = FetchState.create(`api/metadata/emails`);
const emailsMetadataReducer = emailsMetadata.getReducer();
const emailsReducer = emails.getReducer();

const sendTestEmail = email => post(`api/me/emails/send-test-email`, email);
const sendManualEmailToTravelers = data =>
    post(`api/me/emails/send-email-to-travelers`, data);
const sendManualCampaign = email =>
    post(`api/me/emails/send-manual-campaign`, email);

const fetchEmailMarketingListSize = () =>
    get(`api/me/emails/marketing-list-size`);

export {
    emails,
    emailsMetadata,
    emailsReducer,
    emailsMetadataReducer,
    sendTestEmail,
    sendManualEmailToTravelers,
    sendManualCampaign,
    fetchEmailMarketingListSize,
};
