import styled from '@emotion/styled';
import { css } from '@emotion/css';
import React from 'react';
import { Grid } from '@material-ui/core';
import { H2, P, Button, ButtonSize } from '@trova-trip/trova-components';

export const StyledHeaderContainer = styled((props) => (
    <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        {...props}
    />
))({
    overflow: 'hidden',
});

export const StyledItem = styled((props) => <Grid item {...props} />)({
    padding: '0',
});

export const StyledDownloadButton = styled((props) => (
    <Button size={ButtonSize.Fixed} {...props} />
))({
    margin: '15px 0',
    width: 'fit-content',
});

export const StyledPDFContainer = styled((props) => (
    <Grid container direction="column" {...props} />
))({
    '& > div:first-child': {
        width: '100%',
    },
    '& canvas': {
        width: '100% !important',
        height: 'auto !important',
    },
});

export const StyledPaginationContainer = styled((props) => (
    <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        {...props}
    />
))({
    overflow: 'hidden',
});

export const StyledPaginationButton = styled((props) => (
    <Button size={ButtonSize.Fixed} buttonType={4} {...props} />
))({
    margin: '15px 0',
    textAlign: 'center',
});

export const shouldBeVisible = (condition) => css`
    visibility: ${condition ? 'visible' : 'hidden'};
`;
