import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { userCurrencies } from '../../../../state/currencies';

export const useCurrencies = () => {
    const { listRecords: getAllCurrencies } = userCurrencies.useDispatch();
    const currencySelector = useSelector((state) => state.userCurrencies || {});
    const { records: currencies } = currencySelector.list || [];

    const getCurrencies = useCallback(async () => {
        if (!currencySelector.fetching) {
            getAllCurrencies({});
        }
    }, [currencySelector]);

    const getCurrency = useCallback(
        (currencyId) => {
            if (currencies) {
                return currencies.find(
                    (currency) => currency.id === currencyId,
                );
            }
        },
        [currencies],
    );

    useEffect(() => {
        getCurrencies();
    }, []);

    return { currencies, getCurrency };
};

export default useCurrencies;
