import { models } from '@trova-trip/trova-models';
import { useEffect, useState } from 'react';
import { availability as getItineraryAvailability } from '../../../../state/itineraries';
import { AvailabilityByPackage } from './TripRequestForm.types';

type Itinerary = models.itineraries.BaseItinerary;

const useGetItineraryAvailability = (
    itinerary: Itinerary,
    includeInventoryItemsInUse: boolean = false,
): AvailabilityByPackage | undefined => {
    const [itineraryAvailability, setItineraryAvailability] =
        useState<AvailabilityByPackage>();

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            if (itinerary?.id) {
                const availabilityResponse = await getItineraryAvailability(
                    itinerary.id,
                    includeInventoryItemsInUse,
                );
                if (availabilityResponse.success) {
                    setItineraryAvailability(availabilityResponse?.data);
                }
            }
        };
        fetchData();
    }, [itinerary?.id]);

    return itineraryAvailability;
};

export default useGetItineraryAvailability;
