import styled from '@emotion/styled';
import { Image } from '@trova-trip/trova-components';

const AnimatedImage = styled(Image)`
    @keyframes stepper--image-animation {
        0% {
            opacity: 0;
            transform: translateY(20px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    animation: stepper--image-animation;
    animation-duration: 0.3s;
    animation-delay: 0.2s;
    animation-timing-function: cubic-bezier(0.25, 0.75, 0.5, 1.25);
    animation-fill-mode: both;
    backface-visibility: hidden;
    opacity: 0;
`;

export default AnimatedImage;
