/*issue with CRA4 and React 17: https://github.com/emotion-js/emotion/issues/2041*/
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { dayTitle, date as dateStyles } from './DailyActivities.components';

interface DaySectionTitleProps {
    id: string;
    dayNumber: number;
    date?: string;
}

const DaySectionTitle: React.FC<DaySectionTitleProps> = ({
    id,
    dayNumber,
    date,
}) => {
    return (
        <div id={id} css={dayTitle()}>
            <div>
                <span>Day {dayNumber}</span>
                {date && <span css={dateStyles()}>{date}</span>}
            </div>
        </div>
    );
};

export default DaySectionTitle;
