import { UserGroup } from 'applications/types';
import { useSelector } from 'react-redux';
import { ProfileStoreSelector } from 'state/profile';

const useCurrentUserGroupMatcher = (
    userGroup: UserGroup | Set<UserGroup>,
): boolean => {
    const currentUser = useSelector(
        (state: ProfileStoreSelector) => state.profile.current,
    );
    if (typeof userGroup === 'string') {
        return currentUser?.group === userGroup;
    }
    return userGroup.has(currentUser?.group as UserGroup);
};

export default useCurrentUserGroupMatcher;
