import { Stack, Text } from '@trova-trip/trova-components';
import {
    Button,
    ButtonProps,
    Dialog,
    DialogProps,
} from '@trova-trip/trova-components/build/next';
import React, { SyntheticEvent } from 'react';

export interface ConfirmationDialogProps {
    open: boolean;
    onConfirmation: (e?: SyntheticEvent) => void;
    onCancel: () => void;
    title: string;
    description?: string;
    size?: DialogProps['size'];
    footerLeftContent?: React.ReactNode;
    confirmButtonProps?: ButtonProps;
    cancelButtonProps?: ButtonProps;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    open,
    onCancel,
    onConfirmation,
    title,
    description = '',
    size,
    footerLeftContent,
    confirmButtonProps,
    cancelButtonProps,
    children,
}) => (
    <Dialog
        isOpen={open}
        size={size}
        closeOnOverlayClick={true}
        onClose={onCancel}
        position={{ base: 'top', lg: 'centered' }}
        showCloseButton={false}
    >
        <Dialog.Header title={title} />
        <Dialog.Body>
            <Text>{description}</Text>
            {children}
        </Dialog.Body>
        <Dialog.Footer>
            <Stack
                spacing={0}
                justify={footerLeftContent ? 'space-between' : 'flex-end'}
                align='center'
                width='100%'
            >
                {footerLeftContent}
                <Stack
                    spacing={4}
                    width={{ base: '100%', md: 'auto' }}
                    justify='flex-end'
                >
                    <Button
                        variant='secondary'
                        onClick={onCancel}
                        {...cancelButtonProps}
                    >
                        {cancelButtonProps?.children || 'Cancel'}
                    </Button>
                    <Button
                        variant='primary'
                        onClick={onConfirmation}
                        {...confirmButtonProps}
                    >
                        {confirmButtonProps?.children || 'Yes'}
                    </Button>
                </Stack>
            </Stack>
        </Dialog.Footer>
    </Dialog>
);

export default ConfirmationDialog;
