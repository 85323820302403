import { userTripRequests } from '../../state/userTripRequests';
import { userTrips } from '../../state/userTrips';
import { models } from '@trova-trip/trova-models';

interface TripRequestFetchResult {
    records: models.tripRequest.TripRequest[];
}
interface TripFetchResult {
    records: models.trips.Trip[];
}

interface queryParams {
    status?: string;
}

interface modelParams {
    fetch: boolean;
    queryParams?: queryParams;
}

interface modelRequests {
    trip?: modelParams;
    tripRequest?: modelParams;
}
interface response {
    tripResult: TripFetchResult | undefined;
    tripRequestResult: TripRequestFetchResult | undefined;
}

export const useListModelsRecords = (
    modelRequests: modelRequests,
): (() => Promise<response>) => {
    const { listRecords: userTripsRecords } = userTrips.useDispatch();
    const { listRecords: userTripRequestsRecords } =
        userTripRequests.useDispatch();

    const fetchResults = async (): Promise<response> => {
        const getUserTripRequestsPromise = ():
        | Promise<TripRequestFetchResult>
        | undefined => {
            if (modelRequests.tripRequest?.fetch) {
                const { queryParams = {} } = modelRequests.tripRequest;
                return new Promise((resolve) => {
                    userTripRequestsRecords(queryParams, {
                        successCallback: resolve,
                    });
                });
            }
        };

        const getUserTripsPromise = ():
        | Promise<TripFetchResult>
        | undefined => {
            if (modelRequests.trip?.fetch) {
                const { queryParams = {} } = modelRequests.trip;
                return new Promise((resolve) => {
                    userTripsRecords(queryParams, {
                        successCallback: resolve,
                    });
                });
            }
        };

        const userTripsPromise = getUserTripsPromise();
        const userTripRequestsPromise = getUserTripRequestsPromise();

        const [userTripsResult, userTripRequestsResult] = await Promise.all([
            userTripsPromise,
            userTripRequestsPromise,
        ]);

        return {
            tripResult: userTripsResult,
            tripRequestResult: userTripRequestsResult,
        };
    };
    return fetchResults;
};
