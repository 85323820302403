import TabDetailsInformation from 'components/TabDetailsInformation/TabDetailsInformation';
import Tab from '../../../../../../navigation/Tab';
import BookingsTables from '../../../../components/BookingsTables/BookingsTables';
import BookingsTopCards from '../../../../components/BookingsTopCards/BookingsTopCards';
import BookingsTabStickyContent from './BookingsTabStickyContent';
import { BaseTabProps } from '../Tabs.types';
import CompanionsForm from 'applications/host/components/CompanionsForm/CompanionsForm';
import { MainContent } from '@trova-trip/trova-components/build/next';

const BookingsTab = (props: BaseTabProps): JSX.Element => {
    const { hideNavigation, disabled } = props;

    return (
        <Tab
            label='Bookings'
            path='/bookings'
            disabled={disabled}
            hideNavigation={hideNavigation}
        >
            <MainContent.Inner
                sidebarElement={<BookingsTabStickyContent />}
                sidebarConfig={{
                    placement: { lg: 'top', '2lg': 'right' },
                }}
                height='inherit'
            >
                <TabDetailsInformation
                    title='Bookings'
                    description='Review the status of your estimated earnings, travelers, and more.'
                />
                <BookingsTopCards />
            </MainContent.Inner>
            <CompanionsForm />
            <BookingsTables />
        </Tab>
    );
};

export default BookingsTab;
