export const TRAVELER_APP_ROUTE = '/traveler';
export const HOST_APP_ROUTE = '/host';
export const OPERATOR_APP_ROUTE = '/operator';

export enum TRAVELER_APP_PRODUCT_ROUTE {
    TRIPS = '/trips',
    EXPLORE = '/explore',
}

export enum HOST_APP_PRODUCT_ROUTE {
    HOME = '/home',
    TRIPS = '/trips',
    AUDIENCE = '/audience',
    ITINERARIES = '/itineraries',
    REFERRAL = '/referral',
    PUBLIC_PROFILE = '/public-profile',
}

export enum HOST_APP_AUDIENCE_TAB_ROUTE {
    RESEARCH = '/research',
}

export enum HOST_APP_TAB_ROUTE {
    CONFIGURATION = '/configuration',
    PRICING = '/pricing',
    ITINERARY = '/itinerary',
    PROMOTE = '/promote',
    BOOKINGS = '/bookings',
    TRAVEL_INFO = '/travel-info',
}

export enum HOST_HOME_ROUTES {
    DASHBOARD = '/dashboard',
    PROGRESS = '/progress',
}

export enum HOST_PUBLIC_PROFILE_ROUTES {
    EDIT = '/edit',
}

export enum TRAVELER_APP_TAB_ROUTE {
    ITINERARY = '/itinerary',
    MANAGE_BOOKING = '/manage-booking',
    PREPARATION = '/preparation',
    TRIP_FORM = '/trip-form',
    TRIP_OVERVIEW = '/trip-overview',
}

export enum OPERATOR_APP_TAB_ROUTE {
    REVIEW_DETAILS = 'review-details',
    CONFIGURE_SERVICES = 'configure-services',
    REVIEW_PRICING = 'review-pricing',
}

export const TRAVELER_APP_MAIN_TAB_ROUTE = TRAVELER_APP_TAB_ROUTE.TRIP_OVERVIEW;

export enum TRAVELER_APP_MANAGE_BOOKING_ROUTES {
    PAYMENT = '/payment',
    SUCCESS = '/success',
    PAY_BALANCE = '/pay-balance',
}
