import { useEffect } from 'react';

const useClipboard = ({
    initialClipboardText = '',
}: {
    initialClipboardText?: string;
} = {}): any => {
    useEffect(() => {
        if(initialClipboardText?.length > 0) { 
            copyTextToClipboard(initialClipboardText);
        }
    }, [initialClipboardText]);

    const copyTextToClipboard = async (text: string): Promise<void> => {
        return navigator.clipboard.writeText(text);
    };
    const readTextFromClipboard = async (): Promise<string> => { 
        return navigator.clipboard.readText();
    }
          
    return {
        copyTextToClipboard,
        readTextFromClipboard
    };
};

export default useClipboard;
