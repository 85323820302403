import {
    Calendar,
    CalendarDateMatcher,
    CalendarProps,
    Stack,
    Text,
    theme,
} from '@trova-trip/trova-components';
import { Icon } from '@trova-trip/trova-components/build/next';
import isSameDay from 'date-fns/isSameDay';
import { isEqual } from 'lodash';
import React from 'react';

export interface CalendarViewProps<TDayModifiers extends string> {
    editMode: boolean;
    date: Date | undefined;
    startDate: Date;
    dayModifiers: CalendarProps<TDayModifiers>['dayModifiers'];
    dayModifiersTemplate: any;
    disabledDates?: Date[];
    enabledDates?: Date[];
    onChange: (date: Date) => void;
    showInstantApproval: boolean;
    tripLength: number;
}

interface CalendarViewFooterProps<TDayModifiers extends string> {
    tripLength: CalendarViewProps<TDayModifiers>['tripLength'];
    showInstantApprovalLegend: CalendarViewProps<TDayModifiers>['showInstantApproval'];
}

const CalendarViewFooter = <TDayModifiers extends string>({
    tripLength,
    showInstantApprovalLegend,
}: CalendarViewFooterProps<TDayModifiers>): JSX.Element | null => {
    if (!showInstantApprovalLegend && !tripLength) {
        return null;
    }

    return (
        <Stack
            padding={4}
            wrap='nowrap'
            align='center'
            justify='space-between'
            borderTop={`1px solid ${theme.colors.blueGray[200]}`}
        >
            {Boolean(tripLength) ? (
                <Stack
                    justify='center'
                    spacing={1}
                    wrap='nowrap'
                    align='center'
                >
                    <Icon
                        color='red.trova'
                        as='flight'
                        size='sm'
                        display='flex'
                    />
                    <Text color='blueGray.500' fontWeight='medium'>
                        Trip duration:{' '}
                        {`${tripLength} ${tripLength > 1 ? 'Days' : 'Day'}`}
                    </Text>
                </Stack>
            ) : null}
            {showInstantApprovalLegend ? (
                <Stack
                    justify='center'
                    spacing={1}
                    wrap='nowrap'
                    align='center'
                >
                    <Icon
                        as='bolt'
                        size='sm'
                        color='teal.trova'
                        display='flex'
                    />
                    <Text color='teal.trova' fontWeight='medium'>
                        Instant Approval
                    </Text>
                </Stack>
            ) : null}
        </Stack>
    );
};

const CalendarWrapper = React.memo(
    Calendar,
    (prevProps: CalendarProps<string>, nextProps: CalendarProps<string>) =>
        isEqual(prevProps.dayModifiers, nextProps.dayModifiers),
);

const CalendarView = ({
    editMode,
    date,
    startDate,
    dayModifiers,
    dayModifiersTemplate,
    onChange,
    disabledDates = [],
    enabledDates = [],
    showInstantApproval,
    tripLength,
}: CalendarViewProps<string>): JSX.Element => {
    let disabledDays: Date[] | CalendarDateMatcher = disabledDates;

    if (enabledDates.length > 0) {
        disabledDays = (date) => {
            return !enabledDates.some((enabledDate) =>
                isSameDay(date, enabledDate),
            );
        };
    }

    return (
        <>
            <Stack justify='center' marginBottom={6}>
                <CalendarWrapper
                    disabled={!editMode}
                    showDualCalendar={{ base: false, md: true }}
                    mode='single'
                    value={date}
                    fromDate={startDate}
                    dayModifiers={dayModifiers}
                    dayModifiersTemplate={dayModifiersTemplate}
                    disabledDays={disabledDays}
                    onChange={onChange}
                />
            </Stack>
            <CalendarViewFooter
                tripLength={tripLength}
                showInstantApprovalLegend={showInstantApproval}
            />
        </>
    );
};

export default CalendarView;
