import { useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';

const expandServicesByDuration = (
    serviceObject,
    currentDayIndex,
    newServicesByDay,
) => {
    const { service, duration, _id } = serviceObject;
    let durationDay = 1;
    service.dayOfService = durationDay;
    while (
        durationDay < duration &&
        currentDayIndex + durationDay < newServicesByDay.length
    ) {
        const copiedService = {
            ...service,
            viewOnly: true,
            _id: `${service._id}${durationDay}`,
            dayOfService: durationDay + 1,
        };
        newServicesByDay[currentDayIndex + durationDay].push({
            service: copiedService,
            duration,
            _id,
        });
        durationDay++;
    }
};

const useFormattedServices = (servicesByDay) =>
    useMemo(() => {
        if (!servicesByDay) {
            return null;
        }
        const newServicesByDay = cloneDeep(servicesByDay);
        newServicesByDay.forEach((day, currentDayIndex) => {
            day.forEach((service) => {
                if (service.duration <= 1 || service.service.viewOnly) {
                    return;
                }
                expandServicesByDuration(
                    service,
                    currentDayIndex,
                    newServicesByDay,
                );
            });
        });
        return newServicesByDay;
    }, [servicesByDay]);

export default useFormattedServices;
