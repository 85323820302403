import React from 'react';
import CardSection from '../../common/CardSection';
import { Stack } from '@trova-trip/trova-components';
import { NumberInput } from '@trova-trip/trova-components/build/next';
import { FullWidthFormGroup } from '../../ReviewPricing.components';
import FormSaver from '../../../../../../util/form/FormSaver';

type TravelersInputsProps = {
    formSaver: FormSaver;
    onChange: (event: any, name: string, value?: number) => void;
};

export const TravelersInputs = (props: TravelersInputsProps): JSX.Element => {
    const { formSaver, onChange } = props;

    return (
        <CardSection title='Number of Travelers'>
            <Stack spacing={{ xs: 2, sm: 6 }} wrap='nowrap'>
                <FullWidthFormGroup label='Minimum'>
                    <NumberInput
                        name='minimumSpots'
                        showButtons
                        size='lg'
                        min={0}
                        value={formSaver.getData('minimumSpots') || 0}
                        onChange={(event): void =>
                            onChange(
                                event,
                                event.metadata.name,
                                event.metadata.value,
                            )
                        }
                    />
                </FullWidthFormGroup>
                <FullWidthFormGroup label='Maximum'>
                    <NumberInput
                        name='maximumSpots'
                        showButtons
                        size='lg'
                        min={0}
                        value={formSaver.getData('maximumSpots') || 0}
                        onChange={(event): void =>
                            onChange(
                                event,
                                event.metadata.name,
                                event.metadata.value,
                            )
                        }
                    />
                </FullWidthFormGroup>
            </Stack>
        </CardSection>
    );
};

export default TravelersInputs;
