import React from 'react';
import { models } from '@trova-trip/trova-models';
import {
    Heading,
    BaseBox,
    IconButton,
    Stack,
    Text,
} from '@trova-trip/trova-components';

import { ServiceTypesLabels } from '../../../../config/constants';
import {
    Accommodation,
    appendDayOfServiceToTitle,
    getTitleForService,
} from './LibraryServiceFormatter';
import ServiceTitle from './ServiceTitle';
import { getColorFromServiceType } from './LibraryServiceFormatter';
import { CollapsibleText } from '../CollapsibleText';
import {
    HideFromPrint,
    PrintableServiceCardStyle,
    PrinterFriendlyText,
} from './services.styles';

export interface ServiceCardProps {
    collapsed?: boolean;
    title: string;
    order?: number;
    day?: number;
    service: models.services.Service;
    children?: JSX.Element;
    actionsBarConfig?: {
        remove?: {
            shouldRender: boolean;
            handler: (service: models.services.Service) => void;
        };
        toggleChildren?: {
            shouldRender: boolean;
            handler: ({
                day,
                order,
                type,
                serviceId,
            }: {
                day: number;
                order: number;
                type: keyof typeof ServiceTypesLabels;
                serviceId?: string;
            }) => void;
        };
    };
}

const ServiceCard = React.forwardRef<HTMLDivElement, ServiceCardProps>(
    (props, ref) => {
        const {
            collapsed = true,
            service,
            order = -1,
            day = -1,
            children,
            title,
            actionsBarConfig,
        } = props;

        const serviceName = appendDayOfServiceToTitle(
            service as Accommodation,
            getTitleForService(service),
        );

        let actionButton: JSX.Element | undefined;
        if (actionsBarConfig) {
            const { remove, toggleChildren } = actionsBarConfig;

            if (collapsed && toggleChildren?.shouldRender) {
                actionButton = (
                    <IconButton
                        icon='pencil'
                        aria-label='edit'
                        iconVariant='filled'
                        iconColor='red.coral'
                        variant='outlined'
                        onClick={(): void =>
                            toggleChildren.handler({
                                day,
                                order,
                                type: service.type,
                                serviceId: service._id,
                            })
                        }
                        className={HideFromPrint}
                    />
                );
            } else if (!collapsed && remove?.shouldRender) {
                actionButton = (
                    <IconButton
                        icon='trash'
                        aria-label='delete'
                        iconVariant='filled'
                        variant='outlined'
                        iconColor='red.coral'
                        onClick={(): void => remove.handler(service)}
                    />
                );
            }
        }

        return (
            <BaseBox ref={ref} className={PrintableServiceCardStyle}>
                <Stack justify='space-between'>
                    <BaseBox maxWidth='80%'>
                        <ServiceTitle
                            color={getColorFromServiceType(service.type)}
                            title={title}
                        />
                    </BaseBox>
                    {actionButton}
                </Stack>

                {collapsed ? (
                    <>
                        <BaseBox marginTop={2} marginBottom={3}>
                            <Heading as={'h5'}>{serviceName}</Heading>
                        </BaseBox>
                        <CollapsibleText
                            data-screen-text
                            wordBreak='break-word'
                            className={PrinterFriendlyText}
                        >
                            {service.description ?? ''}
                        </CollapsibleText>
                        <Text data-print-text className={PrinterFriendlyText}>
                            {service.description}
                        </Text>
                    </>
                ) : (
                    <>{children}</>
                )}
            </BaseBox>
        );
    },
);

ServiceCard.displayName = 'ServiceCard';

export default ServiceCard;
