import { BaseBox, Heading, List } from '@trova-trip/trova-components';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTabs } from '../../hooks';
import { ProfileCompletionStatuses } from '../../../../../host/hooks/useProfileCompletionStatus';
import {
    SidebarItem,
    SidebarItemProps,
} from '@trova-trip/trova-components/build/next';
import useCurrentUserGroupMatcher from '../../../../hooks/useCurrentUserGroupMatcher';
import { UserGroup } from '../../../../../types';
import { isProfileAddressEnabled } from '../../../../../../config/constants';
import { TAB_LABELS } from '../../hooks/useTabs';
import { useTrackingEvent } from '../../../../../../analytics/hooks';

type LabelConfiguration = Required<SidebarItemProps>['labels'][number];

interface HandleLinkClickParams {
    label: TAB_LABELS;
    path: string;
    completionStatus: ProfileCompletionStatuses;
}

type ProfileCompletionLabelsConfig = Record<
    ProfileCompletionStatuses,
    LabelConfiguration
>;
const profileCompletionLabelsConfig: ProfileCompletionLabelsConfig = {
    [ProfileCompletionStatuses.REQUIRED]: {
        text: 'Required',
        color: 'blueGray.500',
        leftIconProps: { as: 'warning', color: 'alerts.error.icon' },
    },
    [ProfileCompletionStatuses.INCOMPLETE]: {
        text: 'Incomplete',
        color: 'blueGray.500',
        leftIconProps: { as: 'warning', color: 'alerts.warning.icon' },
    },
    [ProfileCompletionStatuses.COMPLETE]: {
        text: 'Complete',
        color: 'blueGray.500',
        leftIconProps: { as: 'checkedCircle', color: 'alerts.success.icon' },
    },
} as const;

const SidebarItemList = (): JSX.Element => {
    const { tabs } = useTabs();
    const history = useHistory();
    const isCurrentUserHost = useCurrentUserGroupMatcher(UserGroup.HOST);

    const showValidationLabels = isCurrentUserHost && isProfileAddressEnabled;

    const tabsFiltered = useMemo(
        () => tabs.filter((tab) => tab.isVisibleOnNavigation),
        [tabs],
    );

    const { trackUserEvent } = useTrackingEvent();

    const handleLinkClick = useCallback(
        ({ label, path, completionStatus }: HandleLinkClickParams): void => {
            if (label === TAB_LABELS.ADDRESS) {
                trackUserEvent({
                    eventName: 'User Viewed Address Section',
                    properties: { completionStatus },
                });
            }
            history.push(path);
        },
        [history],
    );

    const getValidationLabels = (
        label: TAB_LABELS,
        completionStatus: ProfileCompletionStatuses,
    ): LabelConfiguration[] | undefined => {
        if (showValidationLabels) {
            switch (label) {
                case TAB_LABELS.ADDRESS:
                    return [profileCompletionLabelsConfig[completionStatus]];

                // currently there is none check for profile account completition status
                // and will always be complete the completition label requirement must be complete
                case TAB_LABELS.PROFILE:
                case TAB_LABELS.HOST_PROFILE:
                    return [
                        profileCompletionLabelsConfig[
                            ProfileCompletionStatuses.COMPLETE
                        ],
                    ];
                default:
                    return undefined;
            }
        }
        return undefined;
    };

    return (
        <BaseBox padding={2}>
            <BaseBox
                display='block'
                paddingY={2}
                paddingX={{ md: 3 }}
                marginBottom={3}
                minHeight='48px'
            >
                <Heading
                    as='h5'
                    color='blueGray.dark'
                    letterSpacing='0.8px'
                    textTransform='uppercase'
                >
                    Account
                </Heading>
            </BaseBox>
            <List as='ul' listStyleType='none' spacing={{ base: 6, md: 2 }}>
                {tabsFiltered.map(
                    ({
                        label,
                        path,
                        fullPath,
                        isCurrent,
                        completionStatus,
                    }) => {
                        const labels = getValidationLabels(
                            label,
                            completionStatus,
                        );
                        return (
                            <SidebarItem
                                key={path}
                                title={label}
                                subtitle=''
                                onClick={(): void =>
                                    handleLinkClick({
                                        label,
                                        path: fullPath,
                                        completionStatus,
                                    })
                                }
                                isSelected={isCurrent}
                                labels={labels}
                            />
                        );
                    },
                )}
            </List>
        </BaseBox>
    );
};

export default SidebarItemList;
