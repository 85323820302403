import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import { theme, Icon, IconName } from '@trova-trip/trova-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import Product from '../../../navigation/Product';
import { userTripRequests } from '../../../state/userTripRequests';
import { userTrips } from '../../../state/userTrips';
import { TripStatuses } from '../../../config/constants';
import ReviewDetails from '../tabs/ReviewDetails/ReviewDetails';
import ConfigureTripServices from '../tabs/ConfigureService/ConfigureTripServices';
import TripReviewPricing from '../tabs/ReviewPricing/Trip/TripReviewPricing';
import { createNavigationCategory } from '../../../util/navigationUtils';
import TabDetailsInformation from '../../../components/TabDetailsInformation/TabDetailsInformation';
import { redirectToTab } from '../tabs/utils/RedirectToTab';
import getQueryParam from '../../../util/getQueryParam';
import SuccessMessage from '../tabs/SuccessMessage/SuccessMessage';
import { getTripDataFromModel } from '../../../util/ModelDataHelper';
import { coreUtils } from '@trova-trip/trova-common';
import { ProductHeader } from '../../../applications/common/components/ProductHeader';
import ProductRootWithMobileNavigationView from '../../../applications/common/components/ProductRootWithMobileNavigationView';
import { getInstantApprovalLabel } from '../../common/helpers';

import Snackbar from '../../../components/Snackbar/Snackbar';
import { models, constants } from '@trova-trip/trova-models';
import { useSelector } from '../../../state/hooks';
import { NavigationCategoryItem } from '../../../interfaces/Navigation.types';
import { tripHasItineraryInventoryItem } from 'applications/common/helpers/inventory';

type TripRequest = models.tripRequest.TripRequest;
type Trip = models.trips.Trip;
type BaseUser = models.users.BaseUser;
type Model = 'trip' | 'tripRequest';
type TripStatus = constants.trips.TRIP_STATUS;
interface ListRecords<T> {
    records: T[];
}

const hiddenInventoriedTripStatuses =
    constants.trips.OPERATOR_HIDDEN_INVENTORIED_TRIP_STATUSES;

const needsOperatorReviewTripStatuses = new Set([TripStatuses.CREATED]);
const needsAdminReviewTripStatuses = new Set([
    TripStatuses.TROVA_PRICING_APPROVED,
    TripStatuses.HOST_APPROVED,
    TripStatuses.PARTNER_APPROVED,
    TripStatuses.EARLY_CONFIRMED,
]);
const liveTripStatuses = new Set([
    TripStatuses.LIVE,
    TripStatuses.READY_TO_CONFIRM,
]);
const confirmedTripStatuses = new Set([
    TripStatuses.CONFIRMED,
    TripStatuses.CLOSED,
]);
const completedTripStatuses = new Set([TripStatuses.COMPLETE]);

// TODO: move this logic to createSortableTrips function
const formatSidebarDataForNewTripRequestCategory = (
    tripRequestRecords: TripRequest[] = [],
    tripRecords: Trip[] = [],
) => {
    const newTripRequest = createNavigationCategory(`New Trip Requests`, 1);

    if (tripRequestRecords.length) {
        newTripRequest.items = tripRequestRecords.map(
            ({
                id,
                itinerary: { country },
                startDate,
                tripLength,
                host,
                instantlyApproved,
            }): NavigationCategoryItem => {
                const formattedEndDate = moment(startDate)
                    .utc()
                    .add(tripLength - 1, `days`)
                    .format(`ll`);
                const formattedStartDate = moment(startDate).utc().format(`ll`);

                const instantApprovalLabel = getInstantApprovalLabel(
                    Boolean(instantlyApproved),
                );

                return {
                    id,
                    title: coreUtils.tripUtils.generateTripName(
                        host as BaseUser,
                        country,
                    ),
                    subtitle: `${formattedStartDate} - ${formattedEndDate}`,
                    query: { model: `tripRequest` },
                    labelItems: instantApprovalLabel,
                };
            },
        );
    }

    if (tripRecords.length) {
        tripRecords.forEach(({ status, ...trip }) => {
            if (needsOperatorReviewTripStatuses.has(status)) {
                addTripToCategory(trip, newTripRequest);
            }
        });
    }
    if (tripRecords.length || tripRequestRecords.length) {
        return [newTripRequest];
    }

    return [];
};

const addTripToCategory = (
    {
        name,
        id,
        startDate,
        servicesByDay = [],
        instantlyApproved,
    }: Partial<Trip>,
    category,
) => {
    const formattedEndDate = moment(startDate)
        .utc()
        .add(servicesByDay.length - 1, `days`)
        .format(`ll`);
    const formattedStartDate = moment(startDate).utc().format(`ll`);

    const instantApprovalLabel = getInstantApprovalLabel(
        Boolean(instantlyApproved),
    );

    category.items.push({
        id,
        title: name,
        subtitle: `${formattedStartDate} - ${formattedEndDate}`,
        query: { model: `trip` },
        labelItems: instantApprovalLabel,
    });
};

const createSortableTrips = (tripRecords) => {
    const needsAdminReview = createNavigationCategory(`Pending Review`, 2);
    const live = createNavigationCategory(`Live`, 3);
    const confirmed = createNavigationCategory(`Confirmed`, 4);
    const completed = createNavigationCategory(`Complete`, 5);
    tripRecords.forEach(({ status, ...trip }) => {
        if (needsAdminReviewTripStatuses.has(status)) {
            addTripToCategory(trip, needsAdminReview);
        }
        if (liveTripStatuses.has(status)) {
            addTripToCategory(trip, live);
        }
        if (confirmedTripStatuses.has(status)) {
            addTripToCategory(trip, confirmed);
        }
        if (completedTripStatuses.has(status)) {
            addTripToCategory(trip, completed);
        }
    });
    const categories = [needsAdminReview, live, confirmed, completed].filter(
        ({ items }) => items.length,
    );

    return categories;
};

const sortTripsByPriority = (trips) =>
    trips.sort((sidebarA, sidebarB) => sidebarA.priority - sidebarB.priority);

const sortTripsByStartDate = (trips) =>
    trips.sort((sidebarA, sidebarB) =>
        moment(sidebarA.startDate).diff(moment(sidebarB.startDate)),
    );

const formatSidebarDataFromTrips = (tripRecords) => {
    const sortableTrips = createSortableTrips(tripRecords);

    return sortableTrips;
};

const filterInventoriedTrips = (
    trips: Array<Trip | TripRequest>,
): Array<Trip | TripRequest> => {
    return trips.filter((trip) => {
        const hasInventoryItem = tripHasItineraryInventoryItem(trip);
        return !(
            hasInventoryItem &&
            hiddenInventoriedTripStatuses.includes(trip.status as TripStatus)
        );
    });
};

const useModelData = () => {
    const {
        getRecord: getTrip,
        createRecord: createTrip,
        updateRecord: updateTrip,
        clearCurrentRecord: clearTrip,
    } = userTrips.useDispatch();
    const {
        getRecord: getTripRequest,
        createRecord: createTripRequest,
        updateRecord: updateTripRequest,
        clearCurrentRecord: clearTripRequest,
    } = userTripRequests.useDispatch();
    const [model, setModel] = useState<Model | undefined>();
    const fetchModelData = useCallback(
        (id, { model }) => {
            setModel(model);
            if (model === `trip`) {
                clearTripRequest();
                getTrip(id);
            } else {
                clearTrip();
                getTripRequest(id);
            }
        },
        [clearTripRequest, getTrip, clearTrip, getTripRequest],
    );

    const saveModelData = useCallback(
        (data) => {
            if (model === `trip`) {
                createTrip(data);
            } else {
                createTripRequest(data);
            }
        },
        [createTrip, createTripRequest, model],
    );

    const updateModelData = useCallback(
        (id, data, callbacks) => {
            if (model === `trip`) {
                updateTrip(id, data, callbacks);
            } else {
                updateTripRequest(id, data, callbacks);
            }
        },
        [updateTrip, updateTripRequest, model],
    );

    const clearModelData = useCallback(() => {
        clearTripRequest();
        clearTrip();
    }, []);

    const tripRequest = useSelector((state) => state.userTripRequests.current);
    const trip = useSelector((state) => state.userTrips.current);

    useEffect(() => {
        if (trip && !tripRequest) {
            const { tripRequest: trip_request } = trip;
            if (!trip_request) {
                return;
            }
            getTripRequest(trip_request.id);
        }
    }, [trip, tripRequest, getTripRequest]);

    const modelData = { trip, tripRequest }[model as 'trip' | 'tripRequest'];

    return {
        fetchModelData,
        saveModelData,
        updateModelData,
        modelData,
        clearModelData,
    };
};

const useGetLoadNavigationContent = () => {
    const { listRecords: userTripsRecords } = userTrips.useDispatch();
    const { listRecords: userTripRequestsRecords } =
        userTripRequests.useDispatch();

    const loadNavigationContent = useCallback(async () => {
        const userTripsPromise: Promise<ListRecords<Trip>> = new Promise(
            (resolve) => {
                userTripsRecords({}, { successCallback: resolve });
            },
        );

        const userTripRequestsPromise: Promise<ListRecords<TripRequest>> =
            new Promise((resolve) => {
                userTripRequestsRecords(
                    { status: `pending` },
                    { successCallback: resolve },
                );
            });

        const [userTripsResult, userTripRequestsResult] = await Promise.all([
            userTripsPromise,
            userTripRequestsPromise,
        ]);

        const tripRequests = sortTripsByStartDate(
            filterInventoriedTrips(userTripRequestsResult.records),
        );
        const trips = sortTripsByStartDate(
            filterInventoriedTrips(userTripsResult.records),
        );

        // TODO get these categories exactly right, this is rough based on review
        // TODO probably should be combining some trip/request data into Needs Review
        return [
            ...formatSidebarDataForNewTripRequestCategory(tripRequests, trips),
            ...formatSidebarDataFromTrips(trips),
        ];
    }, [userTripRequestsRecords, userTripsRecords]);

    return loadNavigationContent;
};

const useGetRootTrip = (setRootTrip) => {
    const loadNavigationContent = useGetLoadNavigationContent();

    return useCallback(async () => {
        const sidebarContent = await loadNavigationContent();
        const prioritizedTrips = sortTripsByPriority(sidebarContent);
        const defaultTrip =
            prioritizedTrips &&
            prioritizedTrips.length > 0 &&
            prioritizedTrips[0].items[0];
        setRootTrip(defaultTrip);
    }, [loadNavigationContent, setRootTrip]);
};

const RootTrip = () => {
    const [rootTrip, setRootTrip] = useState<
        NavigationCategoryItem | undefined
    >();
    const getRootTrip = useGetRootTrip(setRootTrip);
    getRootTrip();

    return rootTrip ? (
        <Redirect
            to={`trips/${rootTrip.id}/review-details?model=${rootTrip.query?.model}`}
        />
    ) : (
        <TabDetailsInformation
            title='Welcome!'
            description='Just click on Create Itinerary to start the process'
        />
    );
};

const shouldTabBeDisabled = (history) => {
    const model = getQueryParam(history, `model`);

    return model === `tripRequest` ? true : false;
};

const Trips = () => {
    const history = useHistory();
    const loadNavigationContent = useGetLoadNavigationContent();
    const [error, setError] = useState<string | null>();
    const [snackBar, setSnackBar] = useState({
        message: '',
        color: 'info',
        show: false,
    });
    const {
        fetchModelData,
        saveModelData,
        updateModelData,
        modelData,
        clearModelData,
    } = useModelData();

    const {
        tripHeaderPhoto,
        tripName,
        tripCountry,
        tripDestination,
        tripRequestGeneratedName,
    } = getTripDataFromModel(modelData, history);

    const onConfigureServicesConfirmation = (id, data) => {
        updateModelData(id, data, {
            successCallback: () => {
                setError(undefined);
                redirectToTab(history, `review-pricing?model=trip`);
            },

            errorCallback: (error) => {
                setError(`Error - ${error}`);
            },
        });
    };

    const renderSuccess = () => {
        setSnackBar({
            message: 'Your changes have been saved!',
            color: 'success',
            show: true,
        });
    };

    const cleanErrors = () => {
        setError(null);
    };

    const isTabDisabled = shouldTabBeDisabled(history);

    return (
        <Product
            path={`/trips`}
            label={`Trips`}
            icon='plane'
            loadNavigationContent={loadNavigationContent}
            fetchModelData={fetchModelData}
            clearModelData={clearModelData}
            header={
                <ProductHeader
                    backgroundImage={tripHeaderPhoto}
                    title={tripRequestGeneratedName ?? tripName}
                    icon={
                        <Icon
                            name={IconName.Location}
                            color={theme.colors.red[400]}
                        />
                    }
                    badges={
                        (modelData as Trip)?.shortId
                            ? [
                                  {
                                      backgroundColor:
                                          theme.colors.blueGray[600],
                                      children: `ID# ${
                                          (modelData as Trip)?.shortId
                                      }`,
                                  },
                              ]
                            : []
                    }
                    iconDescription={`${tripCountry}, ${tripDestination}`}
                />
            }
            root={
                <ProductRootWithMobileNavigationView rootView={<RootTrip />} />
            }
        >
            <ReviewDetails
                data={modelData as TripRequest}
                showHostSummary={true}
                saveModelData={saveModelData}
                updateModelData={updateModelData}
                disabled={false}
            />
            <ConfigureTripServices
                data={modelData}
                renderError={setError}
                renderSuccess={renderSuccess}
                updateModelData={updateModelData}
                onConfirmation={onConfigureServicesConfirmation}
                errorText={error}
                cleanErrors={cleanErrors}
                disabled={isTabDisabled}
            />
            <TripReviewPricing
                data={modelData}
                renderError={setError}
                updateModelData={updateModelData}
                errorText={error}
                cleanErrors={cleanErrors}
                disabled={isTabDisabled}
            />
            <SuccessMessage
                title={`Done!`}
                subtitle={`Trip has been successfully created!`}
                onClickButton={() =>
                    redirectToTab(history, `review-details?model=trip`)
                }
                buttonMessage={`Go back to Review Details`}
            />
            <Snackbar
                place='tr'
                color={snackBar.color}
                message={snackBar.message}
                open={snackBar.show}
                autoHideDuration={3000}
                onClose={() => {
                    setSnackBar({
                        message: '',
                        color: 'success',
                        show: false,
                    });
                }}
            />
        </Product>
    );
};

Trips.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }),
    }),
};

export default Trips;
