import { Badge, BadgeProps } from '@trova-trip/trova-components';
import {
    BookedGroundTransferJourney,
} from '../../../../../../state/features/transfer/types';
import { constants } from '@trova-trip/trova-models';

type GroundTransferBookingStatus = constants.groundTransfers.GroundTransferBookingStatus;
const { GroundTransferBookingStatus } = constants.groundTransfers;

const BADGE_COLOR: Record<
    GroundTransferBookingStatus,
    BadgeProps['colorScheme']
> = {
    [GroundTransferBookingStatus.PENDING]: 'lightGrey',
    [GroundTransferBookingStatus.CONFIRMED]: 'green',
    [GroundTransferBookingStatus.DRIVER_ASSIGNED]: 'purple',
    [GroundTransferBookingStatus.DRIVER_UNDERWAY]: 'teal',
    [GroundTransferBookingStatus.DRIVER_ARRIVED]: 'teal',
    [GroundTransferBookingStatus.JOURNEY_IN_PROGRESS]: 'teal',
    [GroundTransferBookingStatus.COMPLETED]: 'blue',
    [GroundTransferBookingStatus.CANCELLED]: 'red',
    [GroundTransferBookingStatus.PROCESSING]: 'warning',
};

interface JourneyStatusBadgeProps {
    status: BookedGroundTransferJourney['status'];
}
const JourneyStatusBadge = ({ status }: JourneyStatusBadgeProps) => {
    return (
        <Badge variant='rounded' colorScheme={BADGE_COLOR[status]}>
            {status.replace(/-/g, ' ')}
        </Badge>
    );
};

export default JourneyStatusBadge;
