import React from 'react';
import { Stack, StackProps } from '@trova-trip/trova-components';

interface LayoutProps {
    centerContent?: boolean;
    spacing?: StackProps['spacing'];
    margin?: StackProps['margin'];
}

const Layout: React.FC<LayoutProps> = ({
    children,
    centerContent,
    spacing,
    margin,
}) => {
    return (
        <Stack
            direction='column'
            maxWidth='75rem'
            align={centerContent ? 'center' : 'stretch'}
            spacing={spacing}
            margin={margin}
        >
            {children}
        </Stack>
    );
};

export default Layout;
