import { css } from '@emotion/css';

export const styledCard = css`
    position: relative;
    margin-top: 13px;
`;

export const styledAddButtonWrapper = css`
    margin-top: 16px;
    @media print {
        display: none !important;
    }
`;
