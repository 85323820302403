import { css } from '@emotion/css';
import { BaseBox, BaseBoxProps, Text } from '@trova-trip/trova-components';
import { Table } from '@trova-trip/trova-components/build/next';
import PricingRow from '../../common/PricingRow';
import { Pricing, PricingBreakdown } from '../../utils';
import { Currency } from '@trova-trip/country-data';

interface BreakdownTableProps extends BaseBoxProps {
    title: string;
    thresholds: number[];
    pricingBreakdown: Pricing[];
    customBreakdown?: React.ReactElement;
    currencyInfo: Currency;
}

const BreakdownTable = (props: BreakdownTableProps): JSX.Element => {
    const {
        title,
        thresholds,
        pricingBreakdown,
        currencyInfo,
        ...baseBoxProps
    } = props;

    return (
        <BaseBox {...baseBoxProps} overflow='visible'>
            <Table
                className={css({ overflow: 'visible' })}
                variant='simple'
                borderOutline={true}
            >
                <Table.Head>
                    <Table.Row>
                        <Table.Cell>
                            <Text
                                fontSize='xs'
                                fontWeight='medium'
                                color='blueGray.500'
                            >
                                {title}
                            </Text>
                        </Table.Cell>
                        {(thresholds || []).map((travelers) => {
                            return (
                                <Table.Cell key={travelers}>
                                    <Text
                                        size='xs'
                                        fontWeight='medium'
                                        letterSpacing='wide'
                                    >
                                        {travelers} Travelers
                                    </Text>
                                </Table.Cell>
                            );
                        })}
                    </Table.Row>
                </Table.Head>
                <Table.Body>
                    {(pricingBreakdown || []).map((pricing) => {
                        return (
                            <PricingRow
                                pricing={pricing}
                                key={pricing.label}
                                travelerThresholds={thresholds}
                                currencyInfo={currencyInfo}
                                completeMissingCells
                                justifyRight
                            />
                        );
                    })}
                </Table.Body>
            </Table>
        </BaseBox>
    );
};

interface PricingTableExpandedProps {
    pricingBreakdown: PricingBreakdown;
    currencyInfo: Currency;
}

const PricingTableExpanded = (
    props: PricingTableExpandedProps,
): JSX.Element => {
    const { pricingBreakdown, currencyInfo } = props;

    const {
        travelerThresholds,
        baseCostsBreakdown,
        travelerCostsBreakdown,
        variableCostsBreakdown,
        operatorCostsBreakdown,
    } = pricingBreakdown;

    return (
        <>
            <BreakdownTable
                title='BASE COSTS'
                pricingBreakdown={baseCostsBreakdown}
                thresholds={travelerThresholds}
                marginBottom={6}
                currencyInfo={currencyInfo}
            />
            <BreakdownTable
                title='VARIABLE COSTS'
                pricingBreakdown={variableCostsBreakdown}
                thresholds={travelerThresholds}
                marginBottom={6}
                currencyInfo={currencyInfo}
            />
            <BreakdownTable
                title='OPERATOR COSTS'
                pricingBreakdown={operatorCostsBreakdown}
                thresholds={travelerThresholds}
                marginBottom={6}
                currencyInfo={currencyInfo}
            />
            <BreakdownTable
                title='TRAVELER PAYS'
                pricingBreakdown={travelerCostsBreakdown}
                thresholds={travelerThresholds}
                currencyInfo={currencyInfo}
            />
        </>
    );
};

export default PricingTableExpanded;
