import { TAB_PATH } from '../config';
import { TabInterface } from '../types';
import { Address, Profile } from '../tabs';
import { matchPath, useHistory } from 'react-router-dom';
import useProfileCompletionStatus from '../../../../host/hooks/useProfileCompletionStatus';
import { isProfileAddressEnabled } from '../../../../../config/constants';
import { useSelector } from '../../../../../state/hooks';
import { useMemo } from 'react';
import { getAccountPath } from '../utils/routes.utils';
import { models } from '@trova-trip/trova-models';
import useCurrentUserGroupMatcher from '../../../../common/hooks/useCurrentUserGroupMatcher';
import { UserGroup } from '../../../../types';

interface UseTabsReturn {
    tabs: TabInterface[];
    getTab: (path: TAB_PATH) => TabInterface;
}

type User = models.users.User;

export enum TAB_LABELS {
    HOST_PROFILE = 'Personal Information',
    PROFILE = 'Profile',
    ADDRESS = 'Address',
}

const useTabs = (): UseTabsReturn => {
    const {
        location: { pathname },
    } = useHistory();

    const { current: profile } = useSelector((state) => state.profile);

    const isCurrentUserHost = useCurrentUserGroupMatcher(UserGroup.HOST);

    const createFullPath = (tabPath: TAB_PATH): string => {
        if (!profile) {
            return '';
        }
        const path = getAccountPath(profile as User);
        return path + tabPath;
    };

    const { generalStatus, addressStatus } = useProfileCompletionStatus();

    const isCurrent = (path: TAB_PATH): boolean => {
        const fullPath = createFullPath(path);
        return !!matchPath(pathname, {
            path: fullPath,
            exact: true,
        });
    };

    const getTab = (path: TAB_PATH): TabInterface => {
        return tabs.find((tab) => tab.path === path) as TabInterface;
    };

    const tabs = useMemo(
        () => [
            {
                label: isCurrentUserHost
                    ? TAB_LABELS.HOST_PROFILE
                    : TAB_LABELS.PROFILE,
                path: TAB_PATH.PROFILE,
                fullPath: createFullPath(TAB_PATH.PROFILE),
                element: <Profile isHost={isCurrentUserHost} />,
                isVisibleOnNavigation: true,
                isCurrent: isCurrent(TAB_PATH.PROFILE),
                completionStatus: generalStatus,
            },
            {
                label: TAB_LABELS.ADDRESS,
                path: TAB_PATH.ADDRESS,
                fullPath: createFullPath(TAB_PATH.ADDRESS),
                element: <Address />,
                isVisibleOnNavigation: isProfileAddressEnabled,
                isCurrent: isCurrent(TAB_PATH.ADDRESS),
                completionStatus: addressStatus,
            },
        ],
        [pathname, addressStatus, generalStatus],
    );

    return { tabs, getTab };
};

export default useTabs;
