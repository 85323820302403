import { SavedBooking } from '../applications/traveler/tabs/ManageBooking/types';
import FetchState from './utils/FetchState';
import { put, deleteRequest } from './utils/api';
import { models } from '@trova-trip/trova-models';

const userBookings = FetchState.create(`api/me/bookings`);

const userBookingsReducer = userBookings.getReducer();

export { userBookings, userBookingsReducer };

const ME_BOOKINGS_PATH = 'api/me/bookings';

type BookingOperationResponse<T> = {
    data: T;
    success: boolean;
    error?: string;
    errorCode?: number;
};

type Payment = models.checkout.BookingCreateParams.Payment;

type UpdateBookingParams = Pick<SavedBooking, 'travelersRooms'> &
    Required<Pick<SavedBooking, 'addOns'>> & { payment?: Payment };

type UpdateBooking = BookingOperationResponse<SavedBooking>;

const updateBooking = (
    bookingId: string,
    data: UpdateBookingParams,
): Promise<UpdateBooking> =>
    put(`${ME_BOOKINGS_PATH}/${bookingId}`, undefined, data);

const cancelAddOn = (
    bookingId: string,
    addOnId: string,
): Promise<UpdateBooking> =>
    deleteRequest(`${ME_BOOKINGS_PATH}/${bookingId}/add-ons`, addOnId);

export { updateBooking, cancelAddOn };
export type { UpdateBookingParams, UpdateBooking };
