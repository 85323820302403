import useTripRoute, {
    TRIP_ACTION,
} from 'applications/common/hooks/trips/useTripRoute';
import StickyContentSimpleNav from 'applications/host/components/StickyContent/StickyContentSimpleNav';
import StickyContentTripPageLink from 'applications/host/components/StickyContent/StickyContentTripPageLink';
import { StatefulModel } from 'applications/types';
import { TripStatuses } from 'config/constants';
import { useSelector } from 'react-redux';
import { TripStoreSelector } from 'state/trips';
import ContentForModelStatus from '../../../../../common/components/ContentForModelStatus';

const PricingTabStickyLayoutContent = (): JSX.Element => {
    const model = StatefulModel.TRIP;
    const currentTrip = useSelector(
        (state: TripStoreSelector) => state.userTrips.current,
    );
    const itineraryUrl = useTripRoute({
        id: currentTrip.id,
        action: TRIP_ACTION.ITINERARY,
        preserveQueryParams: true,
    });

    return (
        <>
            <ContentForModelStatus
                model={model}
                matchingStatuses={[TripStatuses.TROVA_PRICING_APPROVED]}
                render={(): JSX.Element => (
                    <StickyContentSimpleNav
                        title={'Next: Review your Itinerary!'}
                        description={
                            "Once you've priced your trip you can now view your final itinerary"
                        }
                        buttonText={'Itinerary'}
                        routeToUrl={itineraryUrl}
                    />
                )}
            />
            <ContentForModelStatus
                model={model}
                matchingStatuses={[TripStatuses.HOST_APPROVED]}
                render={(): JSX.Element => (
                    <StickyContentTripPageLink
                        title={'Trip Page'}
                        description={
                            'Promote your trip by sharing this link with your audience!'
                        }
                    />
                )}
            />
        </>
    );
};

export default PricingTabStickyLayoutContent;
