import { SummaryGroup } from '@trova-trip/trova-components';
import { useMemo } from 'react';
import { SummaryItem } from '../../../../../state/features/manageBooking/types';
import {
    getAddedAndRemovedTripSummarySections,
    getPriceAdjustmentsSection,
    getRoomSelectionSection,
    getTripSummaryPendingChanges,
} from '../utils/summary.utils';
import useManageBooking from './useManageBooking';

export type UseTripSummaryContentReturn = {
    sections: SummaryGroup[];
    pendingChanges: number | undefined;
    bookingHasInsurance: boolean;
    isLoading: boolean;
    insuranceAdjustment: SummaryItem | null;
};

const useTripSummaryContent = (): UseTripSummaryContentReturn => {
    const { state, actions } = useManageBooking();

    const {
        sections: { added, removed, roomSelection, priceAdjustments },
        isLoading,
        insuranceAdjustment,
    } = state.tripSummary;

    const { bookingHasInsurance } = state;

    const {
        resetRoomSelection,
        updateAccommodation,
        updateActivity,
        updateTransfer,
    } = actions;

    const roomSelectionSection = useMemo(() => {
        return getRoomSelectionSection(
            roomSelection,
            resetRoomSelection,
            state.originalState,
        );
    }, [roomSelection?.price]);

    const addedAndRemovedSections = useMemo(() => {
        return getAddedAndRemovedTripSummarySections(
            added,
            removed,
            {
                updateAccommodation,
                updateActivity,
                updateTransfer,
            },
            state,
        );
    }, [added?.length, removed?.length, state]);

    const priceAdjustmentsSection = useMemo(() => {
        return getPriceAdjustmentsSection(priceAdjustments);
    }, [priceAdjustments?.length]);

    const pendingChanges = useMemo(() => {
        return getTripSummaryPendingChanges([
            ...addedAndRemovedSections,
            ...roomSelectionSection,
            ...priceAdjustmentsSection,
        ]);
    }, [
        addedAndRemovedSections,
        roomSelectionSection,
        priceAdjustmentsSection,
    ]);

    return {
        sections: [
            ...roomSelectionSection,
            ...addedAndRemovedSections,
            ...priceAdjustmentsSection,
        ],
        pendingChanges,
        bookingHasInsurance,
        isLoading,
        insuranceAdjustment,
    };
};

export default useTripSummaryContent;
